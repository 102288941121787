import axios from "axios";

export interface CarryDispoNote {
    readonly id: string;
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly creatorCtUserId: string | null;
    readonly externOrderId: number;
    readonly content: string;
}

export interface CarryDispoNoteForm {
    readonly content: string;
}

interface CarryDispoNoteApi {
    add(externOrderId: number, carryDispoNoteForm: CarryDispoNoteForm): Promise<void>;
}

export const carryDispoNoteApi: CarryDispoNoteApi = {
    async add(externOrderId, carryDispoNoteForm): Promise<void> {
        await axios.post(`/api/carry-dispo-notes/${externOrderId}`, carryDispoNoteForm);
    },
};
