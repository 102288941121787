
import FinanceContractsDataTableRow from "./FinanceContractsDataTableRow.vue";
import { financeContractOverviewState } from "./financeContractOverview";
import { financeContractSearchApi, FinanceContractSearchOrder } from "@/api/financeContractSearch";
import { financeContractUploadsApi, FinanceContractUploadSource } from "@/api/financeContractUploads";
import { FinanceContract, financeContractsApi, ProcessingState } from "@/api/financeContracts";
import { Permission } from "@/api/userSession";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { showConfirm, showInfo } from "@/app/messageUtil";
import DealerPicker from "@/app/pages/DealerPicker.vue";
import ProcessingProgressDialog from "@/app/pages/ProcessingProgressDialog.vue";
import { ProcessingProgressDialogProps } from "@/app/pages/processingProgressDialog";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { PickMutable, SelectOption } from "@/util/types";
import Vue from "vue";

interface Item {
    selected: boolean;
    readonly financeContract: FinanceContract;
}

export default Vue.extend({
    data() {
        return {
            financeContractOverviewState,
            uploadSources: [] as FinanceContractUploadSource[],
            items: [] as Item[],
            paging: {
                page: 1,
                pageSize: 50,
                pageSizes: [50, 100, 250, 500, 1000],
                totalSize: 0,
                maxTotalSize: Number.MAX_SAFE_INTEGER,
                maxPage: Number.MAX_SAFE_INTEGER,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            loading: true,
            Permission,
            processing: false,
            searchCounter: 1,
            FinanceContractSearchOrder,
            ProcessingState,
            processingProgressDialogProps: null as ProcessingProgressDialogProps<FinanceContract> | null,
        };
    },

    computed: {
        canSelect(): boolean {
            return userSession.isCtUser();
        },

        canSelectAllDealers(): boolean {
            return userSession.hasPermission(Permission.MANAGE_FINANCE_CONTRACTS);
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("ID"),
                    width: "10%",
                },
                {
                    text: this.$t("Vertrag"),
                    width: "25%",
                },
                {
                    text: this.$t("Kontakt"),
                    width: "30%",
                },
                {
                    text: this.$t("Fahrzeug"),
                    width: "30%",
                },
                {
                    text: this.$t("Aktionen"),
                    width: "5%",
                },
            ];
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        selectableDealerIds(): string[] {
            if (!this.canSelectAllDealers) {
                return userSession.profile?.dealerIds || [];
            }

            return dealersStore.dealers.map((d) => d.id);
        },

        uploadSourceOptions(): SelectOption[] {
            return this.uploadSources.map((s) => ({
                text: s.name,
                value: s.id,
            }));
        },
    },

    watch: {
        async "financeContractOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    methods: {
        async closeProcessingProgressDialog() {
            this.unselectAll();
            this.processingProgressDialogProps = null;
            this.paging.page = 1;
            await this.loadItems();
        },

        async deleteSelected() {
            const contracts = this.items.filter((item) => item.selected).map((item) => item.financeContract);

            if (!contracts.length) {
                return;
            }

            if (
                !(await showConfirm(
                    this.$t("Bankdaten löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Bankdaten löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.processingProgressDialogProps = {
                items: contracts,
                process: (contract: FinanceContract) => financeContractsApi.delete(contract.id),
                title: this.$t("Lösche Bankdaten") as string,
            };
        },

        getFinanceContractUploadSourceById(id: string): FinanceContractUploadSource | null {
            return this.uploadSources.find((s) => s.id === id) ?? null;
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;

            try {
                const financeContractSearchResults = await financeContractSearchApi.search(
                    (this.paging.page - 1) * this.paging.pageSize,
                    this.paging.pageSize,
                    this.financeContractOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (financeContractSearchResults.searchId === this.searchCounter) {
                    this.items = financeContractSearchResults.results.map((financeContract) => ({
                        selected: false,
                        financeContract,
                    }));
                    this.paging.totalSize = financeContractSearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async markAsProcessManually(id: string) {
            this.processing = true;
            try {
                await financeContractsApi.markAsProcessManually(id);
            } finally {
                this.processing = false;
            }
            await this.loadItems();
        },

        async markSelectedAsProcessManually() {
            const selected = this.items.filter((item) => item.selected).map((item) => item.financeContract);
            const updatable = selected.filter((item) => item.processingState !== ProcessingState.PROCESSED);

            if (selected.length && updatable.length < selected.length) {
                showInfo(
                    this.$t("Es wurden {0} von {1} Bankdaten ignoriert, da sie schon verarbeitet wurden.", [
                        selected.length - updatable.length,
                        selected.length,
                    ]) as string
                );
            }

            if (!updatable.length) {
                return;
            }

            if (
                !(await showConfirm(
                    this.$t("Automatische Verarbeitung verhindern") as string,
                    this.$t("Sind Sie sicher, dass Sie die automatische Verarbeitung verhindern möchten?") as string
                ))
            ) {
                return;
            }

            this.processingProgressDialogProps = {
                items: updatable,
                process: (contract: FinanceContract) => financeContractsApi.markAsProcessManually(contract.id),
                title: this.$t("Verhindere automatische Verarbeitung") as string,
            };
        },

        async markSelectedAsUnprocessed() {
            const selected = this.items.filter((item) => item.selected).map((item) => item.financeContract);
            const updatable = selected.filter((item) => item.processingState !== ProcessingState.PROCESSED);

            if (selected.length && updatable.length < selected.length) {
                showInfo(
                    this.$t("Es wurden {0} von {1} Bankdaten ignoriert, da sie schon verarbeitet wurden.", [
                        selected.length - updatable.length,
                        selected.length,
                    ]) as string
                );
            }

            if (!updatable.length) {
                return;
            }

            if (
                !(await showConfirm(
                    this.$t("Verarbeitungsstatus auf unverarbeitet setzen") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie den Verarbeitungsstatus auf unverarbeitet setzen möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            this.processingProgressDialogProps = {
                items: updatable,
                process: (contract: FinanceContract) => financeContractsApi.markAsUnprocessed(contract.id),
                title: this.$t("Setze Verarbeitungsstatus auf unverarbeitet") as string,
            };
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        async process(id: string) {
            this.processing = true;
            try {
                await financeContractsApi.process(id);
            } finally {
                this.processing = false;
            }
            await this.loadItems();
        },

        async processSelected() {
            const selected = this.items.filter((item) => item.selected).map((item) => item.financeContract);
            const updatable = selected.filter((item) => item.processingState !== ProcessingState.PROCESSED);

            if (selected.length && updatable.length < selected.length) {
                showInfo(
                    this.$t("Es wurden {0} von {1} Bankdaten ignoriert, da sie schon verarbeitet wurden.", [
                        selected.length - updatable.length,
                        selected.length,
                    ]) as string
                );
            }

            if (!updatable.length) {
                return;
            }

            if (
                !(await showConfirm(
                    this.$t("Bankdaten verarbeiten") as string,
                    this.$t("Sind Sie sicher, dass Sie die Bankdaten verarbeiten möchten?") as string
                ))
            ) {
                return;
            }

            this.processingProgressDialogProps = {
                items: updatable,
                process: (contract: FinanceContract) => financeContractsApi.process(contract.id, true),
                title: this.$t("Verarbeite Bankdaten") as string,
            };
        },

        selectAll() {
            for (const item of this.items) {
                item.selected = true;
            }
        },

        unselectAll() {
            for (const item of this.items) {
                item.selected = false;
            }
        },
    },

    async mounted() {
        this.uploadSources = await financeContractUploadsApi.getAllFinanceContractUploadSources();

        await this.loadItems();
    },

    components: {
        DataTable,
        DatePicker,
        DealerPicker,
        EnumField,
        FinanceContractsDataTableRow,
        ProcessingProgressDialog,
    },
});
