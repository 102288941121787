import { EMPTY_SEARCH_REQUEST } from "./numberBlacklistSearchUtils";
import { ActorType, NumberBlacklistSearchOrder, NumberBlacklistSearchRequest } from "@/api/numbersBlacklist";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";

export interface NumberBlacklistOverviewState {
    readonly searchRequest: NumberBlacklistSearchRequest;

    number: string | null;
    creatorActorTypes: ActorType[];
    blockCalls: boolean | null;
    blockTextMessages: boolean | null;
    createdFrom: string | null;
    createdTo: string | null;
    sortBy: NumberBlacklistSearchOrder;

    refresh(): void;
}

@reactive
class NumberBlacklistOverviewStateImpl implements NumberBlacklistOverviewState {
    private searchRequest_: NumberBlacklistSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);

    get searchRequest() {
        return this.searchRequest_;
    }

    get number() {
        return this.searchRequest.number;
    }

    set number(number: string | null) {
        this.searchRequest_ = { ...this.searchRequest, number };
    }

    get creatorActorTypes() {
        return this.searchRequest.creatorActorTypes;
    }

    set creatorActorTypes(creatorActorTypes: ActorType[]) {
        this.searchRequest_ = { ...this.searchRequest, creatorActorTypes };
    }

    set blockCalls(blockCalls: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest, blockCalls };
    }

    get blockCalls() {
        return this.searchRequest.blockCalls;
    }

    set blockTextMessages(blockTextMessages: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest, blockTextMessages };
    }

    get blockTextMessages() {
        return this.searchRequest.blockTextMessages;
    }

    set createdFrom(createdFrom: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdFrom: createdFrom ? toDateObject(this.timeZone, createdFrom) : null,
        };
    }

    get createdFrom() {
        return this.searchRequest.createdFrom ? getDate(this.searchRequest.createdFrom, this.timeZone) : null;
    }

    set createdTo(createdTo: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdTo: createdTo ? toDateObject(this.timeZone, createdTo, 1) : null,
        };
    }

    get createdTo() {
        return this.searchRequest.createdTo ? getDate(this.searchRequest.createdTo, this.timeZone, -1) : null;
    }

    set sortBy(sortBy: NumberBlacklistSearchOrder) {
        this.searchRequest_ = { ...this.searchRequest, sortBy };
    }

    get sortBy() {
        return this.searchRequest.sortBy;
    }

    refresh() {
        this.searchRequest_ = cloneObject(this.searchRequest);
    }

    private get timeZone() {
        return userSession.timeZone;
    }
}

export const numberBlacklistOverviewState: NumberBlacklistOverviewState = new NumberBlacklistOverviewStateImpl();
