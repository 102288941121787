import { CaseChannel } from "./cases";
import { OpportunityChannel } from "./opportunities";
import axios from "axios";

export enum QualityAssuranceLevel {
    NONE = "NONE",
    DATA_MAINTENANCE = "DATA_MAINTENANCE",
    DATA_MAINTENANCE_WITH_PRE_QUALIFICATION = "DATA_MAINTENANCE_WITH_PRE_QUALIFICATION",
}

export enum TypeHint {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
}

export interface SystemEmailAlias {
    readonly id: string;
    readonly localPart: string;
    readonly type: TypeHint;
    readonly dealerId: string;
    readonly overrideDealerIdByVehicleLocation: boolean;
    readonly individualReceivers: string[];
    readonly qualityAssuranceLevel: QualityAssuranceLevel;
    readonly autoresponderOfficeHoursId: string | null;
    readonly autoresponderInsideOfficeHoursEmailTemplateId: string | null;
    readonly autoresponderOutsideOfficeHoursEmailTemplateId: string | null;
    readonly autoresponderFromAddressLocalPart: string | null;
    readonly autoresponderFromAddressDomainId: string | null;
    readonly ignoreParsedContent: boolean;
    readonly caseChannel: CaseChannel | null;
    readonly caseType: string | null;
    readonly escalationGroupReceivers: string[];
    readonly opportunityChannel: OpportunityChannel | null;
    readonly sourceId: string | null;
    readonly opportunityTeamReceivers: string[];
}

export interface SystemEmailAliasForm {
    readonly localPart: string;
    readonly type: TypeHint;
    readonly dealerId: string;
    readonly overrideDealerIdByVehicleLocation: boolean;
    readonly individualReceivers: string[];
    readonly qualityAssuranceLevel: QualityAssuranceLevel;
    readonly autoresponderOfficeHoursId: string | null;
    readonly autoresponderInsideOfficeHoursEmailTemplateId: string | null;
    readonly autoresponderOutsideOfficeHoursEmailTemplateId: string | null;
    readonly autoresponderFromAddressLocalPart: string | null;
    readonly autoresponderFromAddressDomainId: string | null;
    readonly ignoreParsedContent: boolean;
    readonly caseChannel: CaseChannel | null;
    readonly caseType: string | null;
    readonly escalationGroupReceivers: string[];
    readonly opportunityChannel: OpportunityChannel | null;
    readonly sourceId: string | null;
    readonly opportunityTeamReceivers: string[];
}

interface SystemEmailAliasesApi {
    add(systemEmailAliasForm: SystemEmailAliasForm): Promise<void>;
    edit(id: string, systemEmailAliasForm: SystemEmailAliasForm): Promise<void>;
    delete(id: string): Promise<void>;
    getAll(): Promise<SystemEmailAlias[]>;
    getByDealer(dealerId: string): Promise<SystemEmailAlias[]>;
    getById(id: string): Promise<SystemEmailAlias | null>;
}

export const systemEmailAliasesApi: SystemEmailAliasesApi = {
    async add(systemEmailAliasForm) {
        await axios.post(`/api/system-email-aliases`, systemEmailAliasForm);
    },

    async edit(id, systemEmailAliasForm) {
        await axios.put(`/api/system-email-aliases/_/${id}`, systemEmailAliasForm);
    },

    async delete(id) {
        await axios.delete(`/api/system-email-aliases/_/${id}`);
    },

    async getAll() {
        return (await axios.get(`/api/system-email-aliases`)).data;
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/system-email-aliases/${dealerId}`)).data;
    },

    async getById(id) {
        return (await axios.get(`/api/system-email-aliases/_/${id}`)).data;
    },
};
