import { OutgoingCallForm } from "@/api/outgoingCalls";
import { userSession } from "@/store/userSession";
import { reactive } from "@/util/reactive";

const EMPTY_OUTGOING_CALL_FORM: OutgoingCallForm = {
    callerId: "",
    inboundNumber: "",
    outboundNumber: "",
};

@reactive
export class OutgoingCallFormCardState {
    private initialized_ = false;
    private outgoingCallForm_: OutgoingCallForm = EMPTY_OUTGOING_CALL_FORM;

    get initialized() {
        return this.initialized_;
    }

    get callerId() {
        return this.outgoingCallForm_.callerId;
    }

    set callerId(callerId: string) {
        this.outgoingCallForm_ = { ...this.outgoingCallForm_, callerId };
    }

    get inboundNumber() {
        return this.outgoingCallForm_.inboundNumber;
    }

    set inboundNumber(inboundNumber: string) {
        this.outgoingCallForm_ = { ...this.outgoingCallForm_, inboundNumber };
    }

    get outboundNumber() {
        return this.outgoingCallForm_.outboundNumber;
    }

    set outboundNumber(outboundNumber: string) {
        this.outgoingCallForm_ = { ...this.outgoingCallForm_, outboundNumber };
    }

    get outgoingCallForm() {
        return this.outgoingCallForm_;
    }

    initialize(receiver: string | null) {
        if (receiver) {
            this.outboundNumber = receiver;
        }

        if (userSession.profile) {
            this.callerId = userSession.profile.defaultCallerId || "";
            this.inboundNumber = userSession.profile.terminalPhoneNumber || "";
        }

        this.initialized_ = true;
    }

    reset() {
        this.outgoingCallForm_ = EMPTY_OUTGOING_CALL_FORM;
        this.initialized_ = false;
    }
}
