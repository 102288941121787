
import OpportunityDataCheckCard from "./OpportunityDataCheckCard.vue";
import { opportunityAssistantState } from "./opportunityAssistant";
import OpportunityAcquisitionCard from "./singlepage/OpportunityAcquisitionCard.vue";
import OpportunityAdditionalDataCard from "./singlepage/OpportunityAdditionalDataCard.vue";
import OpportunityContactCard from "./singlepage/OpportunityContactCard.vue";
import OpportunityDataCard from "./singlepage/OpportunityDataCard.vue";
import OpportunityInventoryVehiclePickerCard from "./singlepage/OpportunityInventoryVehiclePickerCard.vue";
import OpportunityLocationCard from "./singlepage/OpportunityLocationCard.vue";
import OpportunityPreferredContactPicker from "./singlepage/OpportunityPreferredContactPicker.vue";
import OpportunityRequestedVehicleCard from "./singlepage/OpportunityRequestedVehicleCard.vue";
import OpportunityTradeInCard from "./singlepage/OpportunityTradeInCard.vue";
import { ContactType } from "@/api/contacts";
import { DealerInstructionCategory, IssueType } from "@/api/dealerInstructions";
import { InventoryVehicle } from "@/api/inventory";
import { opportunitiesApi, OpportunityChannel, OpportunityCreationForm } from "@/api/opportunities";
import { VehicleSummary } from "@/app/inventoryUtils";
import { processQueryParameters } from "@/app/issueAssistantUtils";
import { showConfirm, showError } from "@/app/messageUtil";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import FilePickerCard from "@/app/pages/FilePickerCard.vue";
import DealerInstructions from "@/app/pages/dealerinstructions/DealerInstructions.vue";
import OpportunityTeamPickerCard from "@/app/pages/opportunities/OpportunityTeamPickerCard.vue";
import UserPickerCard from "@/app/pages/users/UserPickerCard.vue";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            IssueType,
            adding: false,
            inventoryVehiclesPickerInventoryVehicles: [] as InventoryVehicle[],
            opportunityAssistantState,
            progress: 0,
        };
    },

    computed: {
        contactType(): ContactType | null {
            return this.opportunityAssistantState.contactData?.type ?? null;
        },

        dealerId() {
            return userSession.dealerId;
        },

        dealerInstructionCategories(): DealerInstructionCategory[] {
            if (this.opportunityAssistantState.contactAndVehicleId) {
                return [DealerInstructionCategory.DATA_ACQUISITION, DealerInstructionCategory.RECEIVER_SELECTION];
            }

            return [
                DealerInstructionCategory.CONTACT_SEARCH,
                DealerInstructionCategory.DATA_ACQUISITION,
                DealerInstructionCategory.RECEIVER_SELECTION,
            ];
        },

        isAgent(): boolean {
            return userSession.isAgent();
        },

        phoneticSpellingLocale(): string {
            return configStore.configuration.defaultLocale;
        },

        vehicleSummaries(): VehicleSummary[] {
            const vehicleSummaries: VehicleSummary[] = this.inventoryVehiclesPickerInventoryVehicles.map((v) => ({
                internalId: v.data.internalId,
                vehicleClass: v.data.vehicleClass,
                make: v.data.make,
                model: v.data.model,
                modelDescription: v.data.modelDescription,
                usageType: v.data.usageType,
                mileage: v.data.mileage,
                fuel: v.data.fuel,
                preOffer: v.data.preOffer,
            }));

            vehicleSummaries.push({
                internalId: trimAndReturnNullIfEmpty(opportunityAssistantState.internalId),
                vehicleClass: trimAndReturnNullIfEmpty(opportunityAssistantState.vehicleClass),
                make: trimAndReturnNullIfEmpty(opportunityAssistantState.make),
                model: trimAndReturnNullIfEmpty(opportunityAssistantState.model),
                modelDescription: trimAndReturnNullIfEmpty(opportunityAssistantState.modelDescription),
                usageType: opportunityAssistantState.usageType,
                mileage: opportunityAssistantState.mileage,
                fuel: opportunityAssistantState.fuel,
                preOffer: opportunityAssistantState.preOffer,
            });

            return vehicleSummaries;
        },
    },

    methods: {
        async reset() {
            if (
                await showConfirm(
                    this.$t("Eingaben zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?") as string
                )
            ) {
                opportunityAssistantState.reset(null);
            }
        },

        async addOpportunity() {
            if (!(this.$refs.form as any).validate()) {
                showError(this.$t("Bitte korrigieren Sie Ihre fehlerhaften Eingaben.") as string);
                return;
            }

            if (!opportunityAssistantState.contactAndVehicleId?.contactId && userSession.isCtUser()) {
                showError(this.$t("Es ist kein Kontakt ausgewählt!") as string);
                return;
            }

            if (
                !opportunityAssistantState.opportunityTeamReceivers.length &&
                !opportunityAssistantState.opportunityIndividualReceivers.userIds.length
            ) {
                showError(this.$t("Es ist kein Empfänger ausgewählt!") as string);
                return;
            }

            if (
                !opportunityAssistantState.contactAndVehicleId?.contactId &&
                !(await showConfirm(
                    this.$t("Es ist kein Kontakt ausgewählt!") as string,
                    this.$t("Sind Sie sicher, dass Sie ohne Kontakt fortfahren möchten?") as string
                ))
            ) {
                return;
            }

            if (
                !opportunityAssistantState.inventoryVehicles.length &&
                !(await showConfirm(
                    this.$t("Es sind keine Bestandsfahrzeuge ausgewählt!") as string,
                    this.$t("Sind Sie sicher, dass Sie ohne Bestandsfahrzeug fortfahren möchten?") as string
                ))
            ) {
                return;
            }

            const assignToSelf =
                !opportunityAssistantState.opportunityTeamReceivers.length &&
                opportunityAssistantState.opportunityIndividualReceivers.userIds.length === 1 &&
                opportunityAssistantState.opportunityIndividualReceivers.userIds[0] === userSession.userId &&
                (await showConfirm(
                    this.$t("Sie sind der einzige Empfänger!") as string,
                    this.$t("Möchten Sie sich die Verkaufschance direkt zuteilen?") as string,
                    this.$t("Ja") as string,
                    this.$t("Nein") as string
                ));

            const opportunityCreationForm: OpportunityCreationForm = {
                dealerId: opportunityAssistantState.dealerId!,
                channel: opportunityAssistantState.channel!,
                sourceId: opportunityAssistantState.sourceId!,
                sentiment: opportunityAssistantState.sentiment,
                urgency: opportunityAssistantState.urgency,
                appointmentTime: opportunityAssistantState.appointmentTime,
                subject: opportunityAssistantState.subject,
                opportunityIndividualReceivers: opportunityAssistantState.opportunityIndividualReceivers.userIds,
                opportunityTeamReceivers: opportunityAssistantState.opportunityTeamReceivers,
                contactId: opportunityAssistantState.contactAndVehicleId?.contactId ?? null,
                preferredContactMethod: opportunityAssistantState.preferredContact.preferredContactMethod,
                preferredContactDetails: opportunityAssistantState.preferredContact.preferredContactDetails,
                earliestContactTime: opportunityAssistantState.preferredContact.earliestContactTime,
                latestContactTime: opportunityAssistantState.preferredContact.latestContactTime,
                relativeSuccessProbability: null,
                inventoryVehicles: opportunityAssistantState.inventoryVehicles,
                urls: [],
                externalReferences: [],
                registrationRequested: opportunityAssistantState.registrationRequested,
                insuranceRequested: opportunityAssistantState.insuranceRequested,
                deliveryRequested: opportunityAssistantState.deliveryRequested,
                testdriveRequested: opportunityAssistantState.testdriveRequested,
                preferredTestdriveDateTime: opportunityAssistantState.preferredTestdriveDateTime,
                counterOffer: opportunityAssistantState.counterOffer,
                dealName: opportunityAssistantState.dealName,
                acquisitionType: opportunityAssistantState.acquisitionType,
                bank: opportunityAssistantState.bank,
                paybackPeriodMonths: opportunityAssistantState.paybackPeriodMonths,
                totalMileage: opportunityAssistantState.totalMileage,
                firstInstallment: opportunityAssistantState.firstInstallment,
                monthlyInstallment: opportunityAssistantState.monthlyInstallment,
                finalInstallment: opportunityAssistantState.finalInstallment,
                internalId: opportunityAssistantState.internalId,
                link: opportunityAssistantState.link,
                vehicleClass: opportunityAssistantState.vehicleClass,
                make: opportunityAssistantState.make,
                model: opportunityAssistantState.model,
                modelDescription: opportunityAssistantState.modelDescription,
                usageType: opportunityAssistantState.usageType,
                mileage: opportunityAssistantState.mileage,
                price: opportunityAssistantState.price,
                firstRegistrationDay: opportunityAssistantState.firstRegistration.day,
                firstRegistrationMonth: opportunityAssistantState.firstRegistration.month,
                firstRegistrationYear: opportunityAssistantState.firstRegistration.year,
                fuel: opportunityAssistantState.fuel,
                power: opportunityAssistantState.power,
                exteriorColor: opportunityAssistantState.exteriorColor,
                cubicCapacity: opportunityAssistantState.cubicCapacity,
                preOffer: opportunityAssistantState.preOffer,
                tradeInRequested: opportunityAssistantState.tradeInRequested,
                contactVehicleLink: opportunityAssistantState.contactVehicleLink,
                contactVehicleClass: opportunityAssistantState.contactVehicleClass,
                contactVehicleMake: opportunityAssistantState.contactVehicleMake,
                contactVehicleModel: opportunityAssistantState.contactVehicleModel,
                contactVehicleModelDescription: opportunityAssistantState.contactVehicleModelDescription,
                contactVehicleLicensePlate: opportunityAssistantState.contactVehicleLicensePlate,
                contactVehicleVin: opportunityAssistantState.contactVehicleVin,
                contactVehicleModelYear: opportunityAssistantState.contactVehicleModelYear,
                contactVehicleMileage: opportunityAssistantState.contactVehicleMileage,
                contactVehicleFirstRegistrationDay: opportunityAssistantState.contactVehicleFirstRegistration.day,
                contactVehicleFirstRegistrationMonth: opportunityAssistantState.contactVehicleFirstRegistration.month,
                contactVehicleFirstRegistrationYear: opportunityAssistantState.contactVehicleFirstRegistration.year,
                contactVehiclePower: opportunityAssistantState.contactVehiclePower,
                contactVehicleColor: opportunityAssistantState.contactVehicleColor,
                contactVehicleTransmission: opportunityAssistantState.contactVehicleTransmission,
                contactVehicleFeatures: opportunityAssistantState.contactVehicleFeatures,
                contactVehiclePreviousOwners: opportunityAssistantState.contactVehiclePreviousOwners,
                contactVehicleLoanRedemption: opportunityAssistantState.contactVehicleLoanRedemption,
                contactVehiclePriceSuggestion: opportunityAssistantState.contactVehiclePriceSuggestion,
            };

            this.adding = true;
            try {
                const opportunityId = await opportunitiesApi.addOpportunity(
                    opportunityCreationForm,
                    opportunityAssistantState.initialNoteContent,
                    assignToSelf ? [userSession.userId!] : [],
                    opportunityAssistantState.files,
                    ({ total, loaded }) => (this.progress = (100 * loaded) / total)
                );

                opportunityAssistantState.reset(null);
                await this.$router.push(`/opportunity/${opportunityId}`);
            } finally {
                this.adding = false;
            }
        },

        setPreferredContactTimeZoneIfNull() {
            if (!opportunityAssistantState.preferredContactTimeZone && this.dealerId) {
                opportunityAssistantState.preferredContactTimeZone =
                    dealersStore.dealerById(this.dealerId)?.timeZone ?? null;
            }
        },

        setInventoryVehiclesPickerInventoryVehicles(inventoryVehicles: InventoryVehicle[]) {
            this.inventoryVehiclesPickerInventoryVehicles = inventoryVehicles;
        },
    },

    watch: {
        dealerId(newDealerId: string) {
            this.opportunityAssistantState.dealerId = newDealerId;
            this.setPreferredContactTimeZoneIfNull();
        },
    },

    created() {
        if (!opportunityAssistantState.dealerId && this.dealerId) {
            opportunityAssistantState.dealerId = this.dealerId;
        }
        if (opportunityAssistantState.channel === null) {
            opportunityAssistantState.channel = userSession.isCtUser()
                ? OpportunityChannel.PHONE_BDC
                : OpportunityChannel.PHONE_INTERNAL;
        }

        this.setPreferredContactTimeZoneIfNull();
    },

    beforeRouteEnter(to, _, next) {
        processQueryParameters(to, next);
    },

    components: {
        DealerContextWarning,
        DealerInstructions,
        FilePickerCard,
        OpportunityAcquisitionCard,
        OpportunityAdditionalDataCard,
        OpportunityContactCard,
        OpportunityDataCard,
        OpportunityDataCheckCard,
        OpportunityInventoryVehiclePickerCard,
        OpportunityLocationCard,
        OpportunityPreferredContactPicker,
        OpportunityRequestedVehicleCard,
        OpportunityTeamPickerCard,
        OpportunityTradeInCard,
        UserPickerCard,
    },
});
