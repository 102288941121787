
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            canceling: false,
            notEmpty,
            reason: null as string | null,
        };
    },

    methods: {
        submit() {
            if (!(this.$refs.cancelForm as any).validate()) {
                return;
            }

            this.canceling = true;
            this.$emit("submit", this.reason);
        },
    },
});
