
import CarryDispoExternOrderDataTableRow from "./CarryDispoExternOrderDataTableRow.vue";
import { OrderType, Status } from "@/api/carryDispo";
import { carryDispoSearchApi, ExternOrderResult, ExternOrderSearchRequest } from "@/api/carryDispoSearch";
import { carryDispoSettingsApi } from "@/api/carryDispoSettings";
import {
    isExternOrderCarryDispoNoteUpdatedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate } from "@/util/dateTimeUtils";
import { PickMutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const itemsPerPage = 50;
        const ts = now();

        return {
            baseUrl: null as string | null,
            checkingConfiguration: true,
            isConfigured: false,
            items: [] as ExternOrderResult[],
            itemsPerPage,
            loading: true,
            notificationHandler: null as ((n: Notification) => void) | null,
            OrderType,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
            searchRequest: {
                orderTypes: [],
                status: null,
                createdFrom: getDate(ts, userSession.timeZone, -60),
                createdTo: getDate(ts, userSession.timeZone),
            } as Omit<ExternOrderSearchRequest, "dealerId">,
            Status,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Auftrag"),
                    width: "16%",
                },
                {
                    text: this.$t("Einsatzort"),
                    width: "16%",
                },
                {
                    text: this.$t("Zielort"),
                    width: "16%",
                },
                {
                    text: this.$t("Kurzbericht und Diagnose"),
                    width: "16%",
                },
                {
                    text: this.$t("Interne Notizen"),
                    width: "16%",
                },
                {
                    text: this.$t("Kontakt und Fahrzeug"),
                    width: "16%",
                },
                {
                    width: "1%",
                },
            ];
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];

            if (!this.isConfigured || !this.dealerId) {
                return;
            }

            this.loading = true;

            try {
                const start = (this.paging.page - 1) * this.itemsPerPage;
                const externOrderSearchResults = await carryDispoSearchApi.searchExternOrders(
                    {
                        ...this.searchRequest,
                        dealerId: this.dealerId!,
                    },
                    start,
                    this.itemsPerPage,
                    ++this.searchCounter
                );

                if (externOrderSearchResults.searchId === this.searchCounter) {
                    this.items = externOrderSearchResults.results;
                    this.paging.totalSize = externOrderSearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async loadSettings() {
            this.isConfigured = false;
            this.baseUrl = null;
            this.checkingConfiguration = true;

            try {
                if (this.dealerId) {
                    this.isConfigured = await carryDispoSettingsApi.isEndpointConfigured(this.dealerId);
                } else {
                    this.isConfigured = false;
                }

                if (this.isConfigured) {
                    this.baseUrl = await carryDispoSettingsApi.getBaseUrl(this.dealerId!);
                }
            } finally {
                this.checkingConfiguration = false;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        async refresh() {
            await this.loadItems();
        },
    },

    watch: {
        async dealerId() {
            this.paging.page = 1;
            try {
                await this.loadSettings();
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        searchRequest: {
            deep: true,
            async handler() {
                this.paging.page = 1;
                try {
                    await this.refresh();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadSettings();
        await this.loadItems();

        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isExternOrderCarryDispoNoteUpdatedNotification(n)) {
                await this.refresh();
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        CarryDispoExternOrderDataTableRow,
        DatePicker,
        DataTable,
        DealerContextWarning,
        EnumField,
    },
});
