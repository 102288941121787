
import Vue from "vue";

export default Vue.extend({
    props: {
        darkModeColor: {
            type: String as () => string | null,
            default: null,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        lightModeColor: {
            type: String as () => string | null,
            default: "primary",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        toolbarPosition: {
            type: String as () => "TOP" | "BOTH",
            default: "TOP",
        },
    },

    data() {
        return {
            expandedProp: this.expanded,
        };
    },

    computed: {
        isContentVisible(): boolean {
            return !this.expandable || this.expandedProp;
        },
    },

    watch: {
        expanded() {
            if (this.expandedProp === this.expanded) {
                return;
            }

            this.expandedProp = this.expanded;
        },

        expandedProp() {
            this.$emit("update:expanded", this.expandedProp);
        },
    },
});
