
import OpportunitiesDataTable from "./OpportunitiesDataTable.vue";
import OpportunitiesDataTableRow from "./OpportunitiesDataTableRow.vue";
import OpportunitiesOverviewFilterDialog from "./OpportunitiesOverviewFilterDialog.vue";
import OpportunityOutcomeDialog from "./OpportunityOutcomeDialog.vue";
import { opportunitiesOverviewState } from "./opportunitiesOverview";
import {
    filterIsValid,
    filterMatchesOpportunitiesOverviewState,
    mapToOpportunitiesOverviewFilters,
    mapToSearchFilterForm,
    OpportunitySearchFilterOptions,
} from "./opportunitySearchFilterUtils";
import { UsageType } from "@/api/inventory";
import {
    opportunitiesApi,
    OpportunityChannel,
    OpportunityCloseForm,
    OpportunityPriority,
    OpportunityResult,
    OpportunityStatus,
    OpportunityUrgency,
} from "@/api/opportunities";
import {
    ActorType,
    opportunitySearchApi,
    OpportunitySearchOrder,
    OpportunitySearchResult,
    SentimentType,
} from "@/api/opportunitySearch";
import {
    UserSearchFilter,
    userSearchFilterApi,
    UserSearchFilterForm,
    UserSearchFilterType,
} from "@/api/userSearchFilters";
import { Permission } from "@/api/userSession";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { downloadOrOpenFile } from "@/app/fileUtils";
import { getModelOptions } from "@/app/makeModelsUtils";
import { showConfirm, showInfo } from "@/app/messageUtil";
import MakePicker from "@/app/pages/MakePicker.vue";
import ProcessingProgressDialog from "@/app/pages/ProcessingProgressDialog.vue";
import { ProcessingProgressDialogProps } from "@/app/pages/processingProgressDialog";
import UserPickerDialog from "@/app/pages/users/UserPickerDialog.vue";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { makeModelsStore } from "@/store/makeModels";
import { opportunityOutcomeReasonsStore } from "@/store/opportunityOutcomeReasons";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

interface OpportunitySearchFilterSummary {
    readonly id: string;
    readonly name: string;
    readonly filterJson: { [key: string]: any };
    readonly isValid: boolean;
}

export default Vue.extend({
    props: {
        canSeeBatchUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            ActorType,
            opportunitiesOverviewState,
            OpportunityChannel,
            OpportunityPriority,
            OpportunitySearchOrder,
            OpportunityStatus,
            OpportunityUrgency,
            SentimentType,
            UsageType,
            assigneePickerVisible: false,
            downloadingCsv: false,
            filterDialogVisible: false,
            opportunityOutcomeDialogVisible: false,
            processing: false,
            processingProgressDialogProps: null as ProcessingProgressDialogProps<OpportunityResult> | null,
            searchFilters: [] as OpportunitySearchFilterSummary[],
            selectedItems: [] as OpportunitySearchResult[],
        };
    },

    computed: {
        assigneeOptions(): SelectOptions {
            return [
                {
                    value: null,
                    text: this.$t("Nicht zugeteilt"),
                },
                ...this.userOptions,
            ];
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        individualReceiverOptions(): SelectOptions {
            return [
                {
                    value: null,
                    text: this.$t("Ohne Einzelempfänger"),
                },
                ...this.userOptions,
            ];
        },

        outcomeReasonOptions(): SelectOptions {
            const won = opportunityOutcomeReasonsStore.opportunityOutcomeReasons.filter((r) => r.won);
            const notWon = opportunityOutcomeReasonsStore.opportunityOutcomeReasons.filter((r) => !r.won);
            const options: SelectOptions = [
                {
                    value: null,
                    text: this.$t("Kein Grund angegeben"),
                },
            ];
            if (won.length) {
                options.push(
                    { header: this.$t("Erfolgreich") },
                    ...won.map((r) => ({
                        value: r.id,
                        text: `${r.name}`,
                    }))
                );
            }
            if (notWon.length) {
                options.push(
                    { header: this.$t("Erfolglos") },
                    ...notWon.map((r) => ({
                        value: r.id,
                        text: `${r.name}`,
                    }))
                );
            }
            return options;
        },

        opportunityTeamOptions(): SelectOptions {
            return [
                {
                    value: null,
                    text: this.$t("Ohne Verkäuferteam"),
                },
                ...opportunityTeamsStore.opportunityTeams.map((t) => ({
                    value: t.id,
                    text: `${t.name} (${dealersStore.dealerById(t.dealerId)!.name})`,
                })),
            ];
        },

        sourceOptions(): SelectOptions {
            return opportunitySourcesStore.opportunitySources.map((s) => ({
                value: s.id,
                text: `${s.name}`,
            }));
        },

        userOptions(): SelectOptions {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },

        isFiltersVisible(): boolean {
            return !userSession.isCtUser();
        },

        modelOptions(): SelectOptions {
            return getModelOptions(
                this.opportunitiesOverviewState.makes.length
                    ? this.opportunitiesOverviewState.makes
                    : makeModelsStore.makes
            );
        },

        canBatchUpdate(): boolean {
            return (
                this.canSeeBatchUpdate &&
                userSession.isCtUser() &&
                userSession.hasPermission(Permission.MANAGE_ALL_OPPORTUNITIES)
            );
        },

        canDeleteOpportunities(): boolean {
            return userSession.hasPermission(Permission.DELETE_OPPORTUNITY);
        },

        canDownloadCsv(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_CASES);
        },
    },

    methods: {
        refresh() {
            this.opportunitiesOverviewState.refresh();
        },

        filterMatchesOverviewState(filter: UserSearchFilter): boolean {
            return filterMatchesOpportunitiesOverviewState(filter, this.opportunitiesOverviewState);
        },

        applyFilter(filter: UserSearchFilter | UserSearchFilterForm) {
            this.opportunitiesOverviewState.restoreFilters(
                mapToOpportunitiesOverviewFilters(filter, this.opportunitiesOverviewState)
            );
        },

        showAddFilter() {
            this.filterDialogVisible = true;
        },

        hideAddFilter() {
            this.filterDialogVisible = false;
        },

        async addFilter(options: OpportunitySearchFilterOptions) {
            this.hideAddFilter();
            const filterForm = mapToSearchFilterForm(options, this.opportunitiesOverviewState);
            await userSearchFilterApi.addFilter(filterForm);
            await this.loadSearchFilters();
            this.applyFilter(filterForm);
        },

        async deleteFilter(filterId: string) {
            if (
                await showConfirm(
                    this.$t("Schnellauswahl löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Schnellauswahl löschen wollen?") as string
                )
            ) {
                await userSearchFilterApi.deleteFilter(filterId);
                await this.loadSearchFilters();
            }
        },

        async loadSearchFilters() {
            this.searchFilters = (
                await userSearchFilterApi.getAllFilters(UserSearchFilterType.OPPORTUNITY)
            ).map((f) => ({ ...f, isValid: filterIsValid(f) }));
        },

        updateAssignees(assigneeIds: string[]) {
            this.assigneePickerVisible = false;

            const updatable = this.getUnclosedSelectedOpportunityResults();

            if (!updatable.length) {
                return;
            }

            this.processingProgressDialogProps = {
                items: updatable,
                process: (opportunityResult: OpportunityResult) =>
                    opportunitiesApi.updateAssignees(opportunityResult.opportunity.id, assigneeIds, true),
                title: this.$t("Ändere Zuteilung") as string,
            };
        },

        closeOpportunities(opportunityCloseForm: OpportunityCloseForm) {
            this.opportunityOutcomeDialogVisible = false;

            const updatable = this.getUnclosedSelectedOpportunityResults();

            if (!updatable.length) {
                return;
            }

            this.processingProgressDialogProps = {
                items: updatable,
                process: (opportunityResult: OpportunityResult) =>
                    opportunitiesApi.closeOpportunity(opportunityResult.opportunity.id, opportunityCloseForm, true),
                title: this.$t("Schließe Verkaufschancen") as string,
            };
        },

        async deleteOpportunities() {
            const updatable = this.selectedItems
                .filter((item) => !!item.opportunityResult)
                .map((item) => item.opportunityResult!);

            if (this.selectedItems.length && updatable.length < this.selectedItems.length) {
                showInfo(
                    this.$t("Es wurden {0} von {1} Verkaufschancen ignoriert.", [
                        this.selectedItems.length - updatable.length,
                        this.selectedItems.length,
                    ]) as string
                );
            }

            if (!updatable.length) {
                return;
            }

            if (
                !(await showConfirm(
                    this.$t("Lösche Verkaufschancen") as string,
                    this.$t("Sind Sie sicher, dass Sie die ausgewählten Verkaufschancen löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.processingProgressDialogProps = {
                items: updatable,
                process: (opportunityResult: OpportunityResult) =>
                    opportunitiesApi.deleteOpportunity(opportunityResult.opportunity.id),
                title: this.$t("Lösche Verkaufschancen") as string,
            };
        },

        getUnclosedSelectedOpportunityResults(): OpportunityResult[] {
            const unclosed = this.selectedItems
                .filter((item) => !!item.opportunityResult && !item.opportunityResult.opportunity.closed)
                .map((item) => item.opportunityResult!);

            if (this.selectedItems.length && unclosed.length < this.selectedItems.length) {
                showInfo(
                    this.$t("Es wurden {0} von {1} Verkaufschancen ignoriert, da sie bereits geschlossen sind.", [
                        this.selectedItems.length - unclosed.length,
                        this.selectedItems.length,
                    ]) as string
                );
            }

            return unclosed;
        },

        closeProcessingProgressDialog() {
            this.processingProgressDialogProps = null;
            this.refresh();
        },

        async downloadCsv() {
            this.downloadingCsv = true;
            try {
                downloadOrOpenFile(
                    await opportunitySearchApi.downloadCsv(opportunitiesOverviewState.searchRequest),
                    true
                );
            } finally {
                this.downloadingCsv = false;
            }
        },
    },

    watch: {
        dealerId() {
            this.opportunitiesOverviewState.dealerIds = this.dealerId ? [this.dealerId] : [];
        },
    },

    async mounted() {
        if (!this.opportunitiesOverviewState.initialized) {
            this.opportunitiesOverviewState.setAllQuickfilter();
        }
        if (this.isFiltersVisible) {
            await this.loadSearchFilters();
        }
    },

    components: {
        DAutocomplete,
        DatePicker,
        EnumField,
        MakePicker,
        OpportunitiesDataTable,
        OpportunitiesDataTableRow,
        OpportunitiesOverviewFilterDialog,
        ProcessingProgressDialog,
        OpportunityOutcomeDialog,
        UserPickerDialog,
    },
});
