import { EMPTY_SEARCH_REQUEST } from "./outgoingSmsSearchUtils";
import { IssueType, OutgoingSmsSearchOrder, OutgoingSmsSearchRequest } from "@/api/outgoingSms";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";

export interface OutgoingSmsOverviewState {
    readonly searchRequest: OutgoingSmsSearchRequest;
    readonly initialized: boolean;
    initiatorUserIds: string[];
    issueTypes: IssueType[];
    createdFrom: string | null;
    createdTo: string | null;
    sortBy: OutgoingSmsSearchOrder;
    readonly todayQuickfilter: OutgoingSmsSearchRequest;
    readonly isTodayQuickfilter: boolean;
    readonly allQuickfilter: OutgoingSmsSearchRequest;
    readonly isAllQuickfilter: boolean;

    tz(): string;
    refresh(): void;
    setTodayQuickfilter(): void;
    setAllQuickfilter(): void;
}

@reactive
class OutgoingSmsOverviewStateImpl implements OutgoingSmsOverviewState {
    private searchRequest_: OutgoingSmsSearchRequest = EMPTY_SEARCH_REQUEST;
    private initialized_ = false;

    get searchRequest() {
        return this.searchRequest_;
    }

    get initialized() {
        return this.initialized_;
    }

    get initiatorUserIds() {
        return this.searchRequest.initiatorUserIds;
    }

    set initiatorUserIds(initiatorUserIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest, initiatorUserIds };
    }

    get issueTypes() {
        return this.searchRequest.issueTypes;
    }

    set issueTypes(issueTypes: IssueType[]) {
        this.searchRequest_ = { ...this.searchRequest, issueTypes };
    }

    set createdFrom(createdFrom: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdFrom: createdFrom ? toDateObject(this.tz(), createdFrom) : null,
        };
    }

    get createdFrom() {
        return this.searchRequest.createdFrom ? getDate(this.searchRequest.createdFrom, this.tz()) : null;
    }

    set createdTo(createdTo: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdTo: createdTo ? toDateObject(this.tz(), createdTo, 1) : null,
        };
    }

    get createdTo() {
        return this.searchRequest.createdTo ? getDate(this.searchRequest.createdTo, this.tz(), -1) : null;
    }

    set sortBy(sortBy: OutgoingSmsSearchOrder) {
        this.searchRequest_ = { ...this.searchRequest, sortBy };
    }

    get sortBy() {
        return this.searchRequest.sortBy;
    }

    tz() {
        if (!userSession.mainDealerId) {
            return configStore.configuration.defaultTimeZone;
        } else {
            return (
                dealersStore.dealerById(userSession.mainDealerId)?.timeZone || configStore.configuration.defaultTimeZone
            );
        }
    }

    refresh() {
        this.searchRequest_ = cloneObject(this.searchRequest);
    }

    get todayQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            createdFrom: toDateObject(this.tz(), getDate(now(), this.tz())),
            createdTo: toDateObject(this.tz(), getDate(now(), this.tz(), 1)),
        };
    }

    setTodayQuickfilter() {
        this.searchRequest_ = this.todayQuickfilter;
        this.initialized_ = true;
    }

    get isTodayQuickfilter() {
        return JSON.stringify(this.todayQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get allQuickfilter() {
        return EMPTY_SEARCH_REQUEST;
    }

    setAllQuickfilter() {
        this.searchRequest_ = this.allQuickfilter;
    }

    get isAllQuickfilter() {
        return JSON.stringify(this.allQuickfilter) === JSON.stringify(this.searchRequest);
    }
}

export const outgoingSmsOverviewState: OutgoingSmsOverviewState = new OutgoingSmsOverviewStateImpl();
