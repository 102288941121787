
import { fileHostingApi } from "@/api/fileHosting";
import { renderFilesize } from "@/app/filters";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { formatLocalDate, getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        file: {
            type: File,
            required: true,
        },
    },

    data() {
        return {
            isUploading: false,
        };
    },

    computed: {
        locale(): string {
            return configStore.configuration.defaultLocale;
        },

        timeZone(): string {
            return configStore.configuration.defaultTimeZone;
        },
    },

    methods: {
        renderDownloadLink(url: string) {
            const formattedFilesize = renderFilesize(this.file.size, 1, this.locale);
            const formattedAvailableUntil = this.$t("Die Datei ist bis zum {0} verfügbar.", this.locale, [
                this.renderLocalDate(now(), 29),
            ]);

            return `<div><a href="${url}">${this.file.name}</a> <small>(${formattedFilesize}, ${formattedAvailableUntil})</small></div>`;
        },

        renderLocalDate(date: Date, daysOffset: number) {
            return formatLocalDate(getDate(date, this.timeZone, daysOffset), this.locale, "S");
        },

        async uploadFile() {
            this.isUploading = true;

            try {
                const url = await fileHostingApi.uploadFile(this.file, () => undefined);
                this.$emit("success", this.renderDownloadLink(url));
            } finally {
                this.isUploading = false;
            }
        },
    },
});
