
import { getFirstDayOfWeek } from "@/util/dateTimeUtils";
import { YearMonthDay } from "@/util/types/Date";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: {
            type: Object as () => YearMonthDay,
            required: true,
        },
        mindate: {
            type: String,
            required: false,
        },
        maxdate: {
            type: String,
            required: false,
        },
        rules: {
            type: Array as () => Function[] | undefined,
            required: false,
        },
    },

    data() {
        return {
            menu: false,
            valueProp: {
                year: null,
                month: null,
                day: null,
            } as YearMonthDay,
        };
    },

    computed: {
        date: {
            get(): string | null {
                if (this.valueProp.year === null) {
                    return null;
                }

                let date = this.valueProp.year.toString();

                if (this.valueProp.month !== null) {
                    date += "-" + this.valueProp.month.toString().padStart(2, "0");

                    if (this.valueProp.day !== null) {
                        date += "-" + this.valueProp.day.toString().padStart(2, "0");
                    }
                }

                return date;
            },
            set(date: string | number) {
                this.updateDate(date);
            },
        },
        firstDayOfWeek() {
            return getFirstDayOfWeek(this.$i18n.locale);
        },
        rulez(): Function[] {
            const rs = this.rules || [];
            return rs.map((r) => () => r(this.date));
        },
    },

    methods: {
        save() {
            (this.$refs.menu as any).save({
                year: this.valueProp.year,
                month: this.valueProp.month,
                day: this.valueProp.day,
            });
        },
        handleInput() {
            this.$emit("input", {
                year: this.valueProp.year,
                month: this.valueProp.month,
                day: this.valueProp.day,
            });
        },
        clear() {
            this.setValueProp({
                year: null,
                month: null,
                day: null,
            });

            this.handleInput();
        },
        updateDate(date: string | number) {
            if (typeof date === "number") {
                this.setValueProp({
                    year: date,
                    month: null,
                    day: null,
                });
            } else {
                const parts = date.split("-");

                this.setValueProp({
                    year: parts.length >= 1 ? Number.parseInt(parts[0]) : null,
                    month: parts.length >= 2 ? Number.parseInt(parts[1]) : null,
                    day: parts.length >= 3 ? Number.parseInt(parts[2]) : null,
                });
            }
        },
        updateDateByActivePickerChange() {
            if (!this.$refs.picker) {
                return;
            }

            const activePicker = (this.$refs.picker as any).activePicker;

            this.setValueProp({
                year: this.valueProp.year,
                month: activePicker === "MONTH" || activePicker === "DATE" ? this.valueProp.month : null,
                day: activePicker === "DATE" ? this.valueProp.day : null,
            });
        },
        isYearAndMonth(yearMonthDay: YearMonthDay): boolean {
            return yearMonthDay.year !== null && yearMonthDay.month !== null && yearMonthDay.day === null;
        },
        isFullDate(yearMonthDay: YearMonthDay): boolean {
            return yearMonthDay.year !== null && yearMonthDay.month !== null && yearMonthDay.day !== null;
        },
        setValueProp(yearMonthDay: YearMonthDay) {
            if (
                this.valueProp.year === yearMonthDay.year &&
                this.valueProp.month === yearMonthDay.month &&
                this.valueProp.day === yearMonthDay.day
            ) {
                return;
            }

            this.valueProp = {
                year: yearMonthDay.year,
                month: yearMonthDay.year !== null ? yearMonthDay.month : null,
                day: this.isFullDate(yearMonthDay) ? yearMonthDay.day : null,
            };
        },
    },

    watch: {
        menu(visible) {
            if (visible) {
                const activePicker = this.isFullDate(this.valueProp)
                    ? "DATE"
                    : this.isYearAndMonth(this.valueProp)
                    ? "MONTH"
                    : "YEAR";

                setTimeout(() => ((this.$refs.picker as any).activePicker = activePicker));
            } else {
                this.save();
            }
        },
        valueProp() {
            if (this.isFullDate(this.valueProp)) {
                this.save();
            }
            this.handleInput();
        },
        value() {
            this.setValueProp(this.value);
        },
    },

    mounted() {
        this.setValueProp(this.value);
    },
});
