
import { welcomeEmailPlaceholders } from "./welcomeEmailPlaceholders";
import { welcomeEmailApi } from "@/api/welcomeEmail";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import FileDropZone from "@/app/components/FileDropZone.vue";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { downloadOrOpenFile, fileDialog } from "@/app/fileUtils";
import { showConfirm } from "@/app/messageUtil";
import { getFullName } from "@/app/userUtils";
import { maxLength, notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            content: this.$t("email.welcomeEmail.content").toString(),
            contentRules: maxLength(4_194_303),
            dealerIds: userSession.dealerId ? [userSession.dealerId] : dealersStore.dealers.map((d) => d.id),
            receiverIds: [] as string[],
            sent: false,
            sentToUserIds: [] as string[],
            subject: this.$t("email.welcomeEmail.subject").toString(),
            subjectRules: notEmpty(),
            files: [] as File[],
            previewFile: null as File | null,
            isWorking: false,
        };
    },

    computed: {
        dealerIdsRules() {
            return [() => this.dealerIds.length > 0 || this.$t("Es muss mindestens ein Standort ausgewählt werden.")];
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        placeholders(): string[] {
            return welcomeEmailPlaceholders;
        },

        sentToUserFullNames(): string[] {
            return this.sentToUserIds
                .map((userId) => usersStore.getUserById(userId))
                .map((u) => getFullName(u))
                .sort((a, b) => a.localeCompare(b, userSession.locale));
        },

        userOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        appendToContent(placeholder: string) {
            this.content += placeholder;
        },

        async sendWelcomeMail() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            if (
                !(await showConfirm(
                    this.$t("Willkommens-E-Mail senden") as string,
                    this.$t("Sind Sie sicher, dass Sie die Willkommens-E-Mail senden möchten?") as string
                ))
            ) {
                return;
            }

            this.isWorking = true;

            try {
                this.content = trimAndReturnNullIfEmpty(this.content) || "";

                this.sentToUserIds = await welcomeEmailApi.send(
                    {
                        locale: userSession.locale,
                        dealerIds: this.dealerIds,
                        receiverIds: this.receiverIds,
                        content: this.content,
                        subject: this.subject,
                    },
                    this.files
                );
                this.sent = true;
            } finally {
                this.isWorking = false;
            }
        },

        showFileDialog() {
            fileDialog(true, this.addFiles);
        },

        addFiles(files: File[]) {
            for (const file of files) {
                this.files.push(file);
            }
        },

        showFile(file: File, download: boolean) {
            const fname = file.name.toLowerCase();
            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.previewFile = file;
            } else {
                downloadOrOpenFile(file, download);
            }
        },

        removeFile(file: File) {
            this.files = this.files.filter((f) => f !== file);
        },
    },

    components: {
        DAutocomplete,
        FileDropZone,
        ImageCarousel,
        WysiwygEditor: () => import("@/app/components/WysiwygEditor.vue"),
    },
});
