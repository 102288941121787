
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        canSelectPhoneNumber: {
            type: Boolean,
            default: false,
        },
        canTriggerTelLink: {
            type: Boolean,
            default: false,
        },
        inheritColor: {
            type: Boolean,
            default: false,
        },
        number: {
            type: String,
            required: true,
        },
    },

    computed: {
        canUseTelLink(): boolean {
            return configStore.configuration.canUseTelLink;
        },
    },
});
