import { InventoryVehicle } from "@/api/inventory";
import {
    OutgoingEmailForm,
    TransactionEmailHeader,
    TransactionEmailReceiver,
    TransactionEmailReceiverType,
} from "@/api/outgoingEmails";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { reactive } from "@/util/reactive";

export enum OutgoingEmailFormCardAttachmentScope {
    ADDED_BY_USER = "ADDED_BY_USER",
    INVENTORY_VEHICLE_EXPOSE = "INVENTORY_VEHICLE_EXPOSE",
    EMAIL_TEMPLATE_ATTACHMENT = "EMAIL_TEMPLATE_ATTACHMENT",
}

export enum OutgoingEmailFormCardAttachmentFileStatus {
    AVAILABLE,
    DOWNLOAD_FAILED,
    DOWNLOADING,
}

export interface OutgoingEmailFormCardAttachment {
    readonly id: string;
    readonly scope: OutgoingEmailFormCardAttachmentScope;

    active: boolean;
    file: File | null;
    name: string | null;
    fileStatus: OutgoingEmailFormCardAttachmentFileStatus;
    inventoryVehicle: InventoryVehicle | null;
}

const EMPTY_OUTGOING_EMAIL_FORM: OutgoingEmailForm = {
    fromAddress: "",
    replyToAddress: null,
    receivers: [],
    subject: null,
    htmlBody: "",
    headers: [],
};

@reactive
export class OutgoingEmailFormCardState {
    private initialized_ = false;
    private outgoingEmailForm_ = EMPTY_OUTGOING_EMAIL_FORM;
    public attachments: OutgoingEmailFormCardAttachment[] = [];

    get initialized() {
        return this.initialized_;
    }

    get fromAddress() {
        return this.outgoingEmailForm_.fromAddress;
    }

    set fromAddress(fromAddress: string) {
        this.outgoingEmailForm_ = { ...this.outgoingEmailForm_, fromAddress };
    }

    get replyToAddress() {
        return this.outgoingEmailForm_.replyToAddress;
    }

    set replyToAddress(replyToAddress: string | null) {
        this.outgoingEmailForm_ = { ...this.outgoingEmailForm_, replyToAddress };
    }

    get receivers() {
        return this.outgoingEmailForm_.receivers;
    }

    get subject() {
        return this.outgoingEmailForm_.subject;
    }

    set subject(subject: string | null) {
        this.outgoingEmailForm_ = { ...this.outgoingEmailForm_, subject };
    }

    get htmlBody() {
        return this.outgoingEmailForm_.htmlBody;
    }

    set htmlBody(htmlBody: string) {
        this.outgoingEmailForm_ = { ...this.outgoingEmailForm_, htmlBody };

        const exposeAttachments = this.attachments.filter(
            (a) => a.scope === OutgoingEmailFormCardAttachmentScope.INVENTORY_VEHICLE_EXPOSE
        );

        for (const attachment of exposeAttachments) {
            attachment.active = htmlBody.indexOf(attachment.id) >= 0;
        }
    }

    get headers() {
        return this.outgoingEmailForm_.headers;
    }

    set headers(headers: TransactionEmailHeader[]) {
        this.outgoingEmailForm_ = { ...this.outgoingEmailForm_, headers };
    }

    get outgoingEmailForm(): OutgoingEmailForm {
        return {
            fromAddress: this.fromAddress,
            replyToAddress: this.replyToAddress === this.fromAddress ? null : this.replyToAddress,
            receivers: this.receivers,
            subject: this.subject,
            htmlBody: this.htmlBody,
            headers: this.headers,
        };
    }

    get ccReceivers() {
        return this.receivers.filter((r) => r.type === TransactionEmailReceiverType.CC);
    }

    set ccReceivers(ccReceivers: TransactionEmailReceiver[]) {
        this.outgoingEmailForm_ = {
            ...this.outgoingEmailForm_,
            receivers: [
                ...this.receivers.filter((r) => r.type !== TransactionEmailReceiverType.CC),
                ...ccReceivers.filter((r) => r.type === TransactionEmailReceiverType.CC),
            ],
        };
    }

    get bccReceivers() {
        return this.receivers.filter((r) => r.type === TransactionEmailReceiverType.BCC);
    }

    set bccReceivers(bccReceivers: TransactionEmailReceiver[]) {
        this.outgoingEmailForm_ = {
            ...this.outgoingEmailForm_,
            receivers: [
                ...this.receivers.filter((r) => r.type !== TransactionEmailReceiverType.BCC),
                ...bccReceivers.filter((r) => r.type === TransactionEmailReceiverType.BCC),
            ],
        };
    }

    get toReceivers() {
        return this.receivers.filter((r) => r.type === TransactionEmailReceiverType.TO);
    }

    set toReceivers(toReceivers: TransactionEmailReceiver[]) {
        this.outgoingEmailForm_ = {
            ...this.outgoingEmailForm_,
            receivers: [
                ...this.receivers.filter((r) => r.type !== TransactionEmailReceiverType.TO),
                ...toReceivers.filter((r) => r.type === TransactionEmailReceiverType.TO),
            ],
        };
    }

    initialize(receivers: TransactionEmailReceiver[], subject: string | null, emailSignature: string | null) {
        // set default subject
        this.subject = subject;

        // set default receiver
        if (receivers.length) {
            this.toReceivers = receivers.filter((r) => r.type === TransactionEmailReceiverType.TO);
            this.ccReceivers = receivers.filter((r) => r.type === TransactionEmailReceiverType.CC);
            this.bccReceivers = receivers.filter((r) => r.type === TransactionEmailReceiverType.BCC);
        }

        // add email signature
        if (emailSignature) {
            this.htmlBody += `<br><br>${emailSignature}`;
        }

        if (userSession.profile) {
            // set default sender as sender
            this.fromAddress =
                userSession.profile.emailAliases
                    .filter((a) => a.defaultSender)
                    .map((a) => `${a.localPart}@${a.emailDomainId}`)
                    .shift() || "";

            // if no default sender is specified, just use the first address
            if (!this.fromAddress && userSession.profile.emailAliases.length) {
                const alias = userSession.profile.emailAliases[0];

                this.fromAddress = `${alias.localPart}@${alias.emailDomainId}`;
            }

            // set default reply to
            this.replyToAddress =
                userSession.profile.emailAliases
                    .filter((a) => a.defaultReplyTo)
                    .map((a) => `${a.localPart}@${a.emailDomainId}`)
                    .shift() || null;
        }

        this.initialized_ = true;
    }

    refreshReceivers() {
        this.outgoingEmailForm_ = {
            ...this.outgoingEmailForm_,
            receivers: cloneObject(this.outgoingEmailForm_.receivers),
        };
    }

    reset() {
        this.outgoingEmailForm_ = EMPTY_OUTGOING_EMAIL_FORM;
        this.attachments = [];
        this.initialized_ = false;
    }
}
