
import Vue from "vue";

export default Vue.extend({
    props: {
        itemsCount: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
});
