
import InternalIncomingPhoneNumberFormDialog from "./InternalIncomingPhoneNumberFormDialog.vue";
import { IncomingPhoneNumber, incomingPhoneNumbersApi, IncomingPhoneNumberStatus } from "@/api/incomingPhoneNumbers";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { userSession } from "@/store/userSession";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            formIncomingPhoneNumber: null as IncomingPhoneNumber | null,
            editMode: false,
            items: [] as IncomingPhoneNumber[],
            loading: true,
            parseAndFormatNumber,
        };
    },

    computed: {
        sortedItems(): IncomingPhoneNumber[] {
            const items = this.items.filter(
                (p) =>
                    p.status !== IncomingPhoneNumberStatus.INSTALLED_DEALERDESK_LEGACY &&
                    p.status !== IncomingPhoneNumberStatus.INSTALLED_OTHER
            );
            items.sort((a, b) => a.number.localeCompare(b.number, userSession.locale));
            return items;
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Rufnummer"),
                    width: "30%",
                },
                {
                    text: this.$t("Features"),
                    width: "30%",
                },
                {
                    text: this.$t("Status"),
                    width: "30%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "10%",
                },
            ];
        },
    },

    async mounted() {
        await this.loadItems();
    },

    methods: {
        async loadItems() {
            this.loading = true;
            try {
                this.items = await incomingPhoneNumbersApi.getAll();
            } finally {
                this.loading = false;
            }
        },

        showInstallForm(incomingPhoneNumber: IncomingPhoneNumber) {
            this.editMode = false;
            this.formIncomingPhoneNumber = incomingPhoneNumber;
        },

        async fixIncomingPhoneNumber(incomingPhoneNumber: IncomingPhoneNumber) {
            this.loading = true;
            try {
                await incomingPhoneNumbersApi.fixIncomingPhoneNumber(incomingPhoneNumber.id);
                await this.loadItems();
            } finally {
                this.loading = false;
            }
        },

        async fixAllInvalidIncomingPhoneNumbers() {
            this.loading = true;
            try {
                await incomingPhoneNumbersApi.fixAllInvalidIncomingPhoneNumbers();
                await this.loadItems();
            } finally {
                this.loading = false;
            }
        },

        showEditForm(incomingPhoneNumber: IncomingPhoneNumber) {
            this.editMode = true;
            this.formIncomingPhoneNumber = incomingPhoneNumber;
        },

        async uninstallPhoneNumber(incomingPhoneNumber: IncomingPhoneNumber) {
            this.loading = true;
            try {
                await incomingPhoneNumbersApi.uninstallIncomingPhoneNumber(incomingPhoneNumber.id);
                await this.loadItems();
            } finally {
                this.loading = false;
            }
        },

        async saved() {
            this.formIncomingPhoneNumber = null;
            await this.loadItems();
        },

        getStatusColor(incomingPhoneNumberStatus: IncomingPhoneNumberStatus) {
            if (incomingPhoneNumberStatus === IncomingPhoneNumberStatus.AVAILABLE) {
                return "success";
            }
            if (incomingPhoneNumberStatus === IncomingPhoneNumberStatus.INSTALLED) {
                return "";
            }
            if (incomingPhoneNumberStatus === IncomingPhoneNumberStatus.INSTALLED_INVALID) {
                return "error";
            }
            if (incomingPhoneNumberStatus === IncomingPhoneNumberStatus.INSTALLED_NOT_FOUND) {
                return "error";
            }

            return "warning";
        },

        getStatusIcon(incomingPhoneNumberStatus: IncomingPhoneNumberStatus) {
            if (incomingPhoneNumberStatus === IncomingPhoneNumberStatus.AVAILABLE) {
                return "mdi-checkbox-blank-circle-outline";
            }
            if (incomingPhoneNumberStatus === IncomingPhoneNumberStatus.INSTALLED) {
                return "mdi-checkbox-marked-circle-outline";
            }
            if (incomingPhoneNumberStatus === IncomingPhoneNumberStatus.INSTALLED_INVALID) {
                return "mdi-alert-circle-outline";
            }
            if (incomingPhoneNumberStatus === IncomingPhoneNumberStatus.INSTALLED_NOT_FOUND) {
                return "mdi-alert-circle-outline";
            }

            return "mdi-close-circle-outline";
        },

        isInstalled(incomingPhoneNumberStatus: IncomingPhoneNumberStatus) {
            return incomingPhoneNumberStatus === IncomingPhoneNumberStatus.INSTALLED;
        },

        isAvailable(incomingPhoneNumberStatus: IncomingPhoneNumberStatus) {
            return incomingPhoneNumberStatus === IncomingPhoneNumberStatus.AVAILABLE;
        },

        isInvalid(incomingPhoneNumberStatus: IncomingPhoneNumberStatus) {
            return incomingPhoneNumberStatus === IncomingPhoneNumberStatus.INSTALLED_INVALID;
        },
    },

    components: {
        DealerGlobalContextHint,
        InternalIncomingPhoneNumberFormDialog,
        DataTable,
    },
});
