import { MakeModelSettings, makeModelSettingsApi } from "@/api/makeModelSettings";
import { isMakeModelSettingsChangedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class MakeModelSettingsStore {
    private makeModelSettings: MakeModelSettings | null = null;
    private refreshLimiter = new ActionLimiter(true);

    get preferredMakes(): string[] {
        return this.makeModelSettings?.preferredMakes ?? [];
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.makeModelSettings = await makeModelSettingsApi.getMakeModelSettings();
        });
    }

    handleNotification(n: Notification) {
        if (isMakeModelSettingsChangedNotification(n)) {
            this.makeModelSettings = n.makeModelSettings;
        }
    }
}

export const makeModelSettingsStore = new MakeModelSettingsStore();

notificationEventSource.addDataHandler((n) => makeModelSettingsStore.handleNotification(n));
