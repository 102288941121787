import axios from "axios";

export interface CaseSettings {
    readonly dealerId: string;
    readonly outcomeReasonMandatory: boolean;
}

export interface CaseSettingsForm {
    readonly outcomeReasonMandatory: boolean;
}

interface CaseSettingsApi {
    editCaseSettings(dealerId: string, caseSettingsForm: CaseSettingsForm): Promise<void>;
    getAllCaseSettings(): Promise<CaseSettings[]>;
    getCaseSettings(dealerId: string): Promise<CaseSettings>;
}

export const caseSettingsApi: CaseSettingsApi = {
    async editCaseSettings(dealerId, caseSettingsForm: CaseSettingsForm) {
        await axios.put(`/api/case-settings/${dealerId}`, caseSettingsForm);
    },

    async getAllCaseSettings() {
        return (await axios.get("/api/case-settings")).data;
    },

    async getCaseSettings(dealerId) {
        return (await axios.get(`/api/case-settings/${dealerId}`)).data;
    },
};
