import { escalationGroupsApi, EscalationGroupSummary } from "@/api/escalationGroups";
import {
    isEscalationGroupChangedNotification,
    isEscalationGroupDeletedNotification,
    isEscalationGroupsOrderChangedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { removeMatchingItems, reorderItems, updateItem } from "@/util/storeUtils";

@reactive
class EscalationGroupsStore {
    private escalationGroups_: EscalationGroupSummary[] = [];
    private refreshLimiter = new ActionLimiter(true);

    get escalationGroups() {
        return this.escalationGroups_;
    }

    escalationGroupById(id: string): EscalationGroupSummary | null {
        for (const escalationGroup of this.escalationGroups_) {
            if (escalationGroup.id === id) {
                return escalationGroup;
            }
        }
        return null;
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.escalationGroups_ = await escalationGroupsApi.getAll();
        });
    }

    handleNotification(n: Notification) {
        if (isEscalationGroupChangedNotification(n)) {
            updateItem(this.escalationGroups_, n.escalationGroup, (e) => e.id);
        } else if (isEscalationGroupDeletedNotification(n)) {
            removeMatchingItems(this.escalationGroups_, (e) => e.id === n.escalationGroupId);
        } else if (isEscalationGroupsOrderChangedNotification(n)) {
            reorderItems(this.escalationGroups_, n.orderedEscalationGroupIds, (e) => e.id);
        }
    }
}

export const escalationGroupsStore = new EscalationGroupsStore();

notificationEventSource.addDataHandler((n) => escalationGroupsStore.handleNotification(n));
