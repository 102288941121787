
import ContactAndVehicleFormCard from "./ContactAndVehicleFormCard.vue";
import ContactAndVehicleSearchCard from "./ContactAndVehicleSearchCard.vue";
import { EMPTY_SEARCH_REQUEST } from "./contactSearchUtils";
import { ContactSearchRequest } from "@/api/contactSearch";
import { ContactForm, contactsApi, ContactSource, ContactType, ContactVehicleData, NumberType } from "@/api/contacts";
import { ContactAndVehicleId } from "@/app/contactUtils";
import { email, validate } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { isMobile, parseAndFormatNumber, PhoneNumberCountryCode } from "@/util/phoneNumberUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canExitEditMode: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
        canSelectContactVehicle: {
            type: Boolean,
            default: false,
        },
        canSelectEmailAddress: {
            type: Boolean,
            default: false,
        },
        canSelectPhoneNumber: {
            type: Boolean,
            default: false,
        },
        canSendEmail: {
            type: Boolean,
            default: false,
        },
        canSendSms: {
            type: Boolean,
            default: false,
        },
        canStartCall: {
            type: Boolean,
            default: false,
        },
        contactAndVehicleId: {
            type: Object as () => ContactAndVehicleId | null,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editMode: {
            type: Boolean,
            default: true,
        },
        editModeOnSelect: {
            type: Boolean,
            default: true,
        },
        phoneticSpellingLocale: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
        searchRequest: {
            type: Object as () => ContactSearchRequest | null,
            required: false,
        },
        showIssues: {
            type: Boolean,
            default: false,
        },
        showPhoneticSpelling: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            contactAndVehicleIdProp: cloneObject(this.contactAndVehicleId),
            creating: false,
            editModeProp: this.canEdit && this.editMode,
            loading: false,
            searchRequestProp: cloneObject(this.searchRequest ?? EMPTY_SEARCH_REQUEST),
        };
    },

    methods: {
        async createContactAndVehicle() {
            const contactForm: ContactForm = {
                source: ContactSource.UI,
                dealerId: userSession.dealerId,
                status: null,
                type: trimAndReturnNullIfEmpty(this.searchRequestProp.companyName) ? ContactType.BUSINESS : null,
                salutation: null,
                knownSince: null,
                dateOfBirth: null,
                companyName: this.searchRequestProp.companyName,
                position: null,
                department: null,
                namePrefix: null,
                givenName: null,
                middleName: null,
                familyName: this.searchRequestProp.fullName,
                nameSuffix: null,
                address1: null,
                address2: this.searchRequestProp.address,
                zip: null,
                city: null,
                state: null,
                country: null,
                numbers: [this.searchRequestProp.number]
                    .map((number) =>
                        parseAndFormatNumber(
                            number ?? "",
                            "E.164",
                            configStore.configuration.defaultCountry as PhoneNumberCountryCode
                        )
                    )
                    .filter((number) => !!number)
                    .map((number) => number!)
                    .map((number) => ({
                        type: isMobile(number) ? NumberType.MOBILE : NumberType.LANDLINE,
                        number,
                        label: null,
                    })),
                emailAddresses: [this.searchRequestProp.emailAddress]
                    .map((address) => trimAndReturnNullIfEmpty(address))
                    .filter((address) => !!address && validate(email(), address))
                    .map((address) => address!)
                    .map((address) => ({
                        address,
                        label: null,
                    })),
                website: null,
                notes: null,
                url: null,
                externalReferences: [],
            };

            const contactVehicleData: ContactVehicleData = {
                vin: this.searchRequestProp.vin,
                licensePlate: this.searchRequestProp.licensePlate,
                vehicleClass: null,
                make: null,
                model: null,
                modelDescription: null,
                modelYear: null,
                firstRegistration: null,
                nextGeneralInspection: null,
                nextInspection: null,
                nextInspectionMileage: null,
                nextInspectionMileageUnit: null,
                warrantyUntil: null,
                warrantyUntilMileage: null,
                warrantyUntilMileageUnit: null,
                warrantyPaintingUntil: null,
                warrantyRustUntil: null,
                records: [],
                notes: null,
                url: null,
                externalReference: null,
            };

            this.creating = true;
            try {
                const contactId = await contactsApi.addContact(contactForm);

                this.selectContactAndVehicle({
                    contactId,
                    contactVehicleId: this.canSelectContactVehicle
                        ? await contactsApi.addContactVehicle(contactId, contactVehicleData)
                        : null,
                });
            } finally {
                this.creating = false;
            }
        },

        async handleSearchInput(searchRequest: ContactSearchRequest) {
            this.searchRequestProp = cloneObject(searchRequest);
            this.$emit("update:search-request", cloneObject(searchRequest));
        },

        selectContactAndVehicle(contactAndVehicleId: ContactAndVehicleId | null, keepCurrentEditMode?: boolean) {
            this.contactAndVehicleIdProp = contactAndVehicleId;
            this.$emit("update:contact-and-vehicle-id", this.contactAndVehicleIdProp);

            if (!keepCurrentEditMode) {
                this.setEditMode(this.editModeOnSelect);
            }
        },

        async setEditMode(editMode: boolean) {
            this.editModeProp = this.canEdit && editMode;
            this.$emit("edit-mode", this.editModeProp);
        },
    },

    watch: {
        canEdit() {
            this.setEditMode(this.editModeProp);
        },

        contactAndVehicleId: {
            deep: true,
            handler() {
                if (JSON.stringify(this.contactAndVehicleIdProp) !== JSON.stringify(this.contactAndVehicleId)) {
                    this.selectContactAndVehicle(this.contactAndVehicleId);
                }
            },
        },

        editMode() {
            this.setEditMode(this.editMode);
        },

        searchRequest: {
            deep: true,
            handler() {
                if (!this.searchRequest) {
                    return;
                }

                if (JSON.stringify(this.searchRequestProp) !== JSON.stringify(this.searchRequest)) {
                    this.searchRequestProp = cloneObject(this.searchRequest!);
                }
            },
        },
    },

    components: {
        ContactAndVehicleFormCard,
        ContactAndVehicleSearchCard,
    },
});
