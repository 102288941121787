import { EMPTY_SEARCH_REQUEST } from "./noteSearchUtils";
import { ActorType, FeedbackType, IssueType, NoteSearchOrder, NoteSearchRequest } from "@/api/notes";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";

export interface NotesOverviewState {
    readonly searchRequest: NoteSearchRequest;
    readonly initialized: boolean;
    dealerIds: string[];
    creatorUserIds: string[];
    creatorActorTypes: ActorType[];
    issueTypes: IssueType[];
    createdFrom: string | null;
    createdTo: string | null;
    feedbackTypes: (FeedbackType | null)[];
    sortBy: NoteSearchOrder;
    readonly todayQuickfilter: NoteSearchRequest;
    readonly isTodayQuickfilter: boolean;
    readonly allQuickfilter: NoteSearchRequest;
    readonly isAllQuickfilter: boolean;

    tz(): string;
    refresh(): void;
    setTodayQuickfilter(): void;
    setAllQuickfilter(): void;
}

@reactive
class NotesOverviewStateImpl implements NotesOverviewState {
    private searchRequest_: NoteSearchRequest = EMPTY_SEARCH_REQUEST;
    private initialized_ = false;

    get searchRequest() {
        return this.searchRequest_;
    }

    get initialized() {
        return this.initialized_;
    }

    get dealerIds() {
        return this.searchRequest.dealerIds;
    }

    set dealerIds(dealerIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest, dealerIds };
    }

    get creatorUserIds() {
        return this.searchRequest.creatorUserIds;
    }

    set creatorUserIds(creatorUserIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest, creatorUserIds };
    }

    get creatorActorTypes() {
        return this.searchRequest.creatorActorTypes;
    }

    set creatorActorTypes(creatorActorTypes: ActorType[]) {
        this.searchRequest_ = { ...this.searchRequest, creatorActorTypes };
    }

    get issueTypes() {
        return this.searchRequest.issueTypes;
    }

    set issueTypes(issueTypes: IssueType[]) {
        this.searchRequest_ = { ...this.searchRequest, issueTypes };
    }

    set createdFrom(createdFrom: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdFrom: createdFrom ? toDateObject(this.tz(), createdFrom) : null,
        };
    }

    get createdFrom() {
        return this.searchRequest.createdFrom ? getDate(this.searchRequest.createdFrom, this.tz()) : null;
    }

    set createdTo(createdTo: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdTo: createdTo ? toDateObject(this.tz(), createdTo, 1) : null,
        };
    }

    get createdTo() {
        return this.searchRequest.createdTo ? getDate(this.searchRequest.createdTo, this.tz(), -1) : null;
    }

    get feedbackTypes() {
        return this.searchRequest.feedbackTypes;
    }

    set feedbackTypes(feedbackTypes: (FeedbackType | null)[]) {
        this.searchRequest_ = { ...this.searchRequest, feedbackTypes };
    }

    set sortBy(sortBy: NoteSearchOrder) {
        this.searchRequest_ = { ...this.searchRequest, sortBy };
    }

    get sortBy() {
        return this.searchRequest.sortBy;
    }

    tz() {
        if (!userSession.mainDealerId) {
            return configStore.configuration.defaultTimeZone;
        } else {
            return (
                dealersStore.dealerById(userSession.mainDealerId)?.timeZone || configStore.configuration.defaultTimeZone
            );
        }
    }

    refresh() {
        this.searchRequest_ = cloneObject(this.searchRequest);
    }

    get todayQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            createdFrom: toDateObject(this.tz(), getDate(now(), this.tz())),
            createdTo: toDateObject(this.tz(), getDate(now(), this.tz(), 1)),
        };
    }

    setTodayQuickfilter() {
        this.searchRequest_ = this.todayQuickfilter;
        this.initialized_ = true;
    }

    get isTodayQuickfilter() {
        return JSON.stringify(this.todayQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get allQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
        };
    }

    setAllQuickfilter() {
        this.searchRequest_ = this.allQuickfilter;
    }

    get isAllQuickfilter() {
        return JSON.stringify(this.allQuickfilter) === JSON.stringify(this.searchRequest);
    }
}

export const notesOverviewState: NotesOverviewState = new NotesOverviewStateImpl();
