
import OpportunityAcquisitionDetailsFormCard from "./OpportunityAcquisitionDetailsFormCard.vue";
import { Opportunity } from "@/api/opportunities";
import { AcquisitionType } from "@/api/opportunityTeams";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import { renderUnit } from "@/app/filters";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },

        canEdit: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            AcquisitionType,
            acquisitionFormVisible: false,
            expanded: null as boolean | null,
            renderUnit,
        };
    },

    computed: {
        hasContent(): boolean {
            return (
                this.opportunityProp.registrationRequested !== null ||
                this.opportunityProp.insuranceRequested !== null ||
                this.opportunityProp.deliveryRequested !== null ||
                this.opportunityProp.testdriveRequested !== null ||
                !!this.opportunityProp.dealName ||
                !!this.opportunityProp.acquisitionType ||
                !!this.opportunityProp.bank ||
                this.opportunityProp.counterOffer !== null ||
                this.opportunityProp.firstInstallment !== null ||
                this.opportunityProp.monthlyInstallment !== null ||
                this.opportunityProp.finalInstallment !== null ||
                this.opportunityProp.paybackPeriodMonths !== null ||
                this.opportunityProp.totalMileage !== null
            );
        },
    },

    watch: {
        hasContent() {
            if (this.expanded === null && this.hasContent) {
                // expand only if the user has not changed the expansion state manually
                this.expanded = true;
            }
        },
    },

    methods: {
        showEditForm() {
            this.acquisitionFormVisible = true;
        },

        closeEditForm() {
            this.acquisitionFormVisible = false;
        },
    },

    created() {
        this.expanded = this.hasContent || null;
    },

    components: {
        DToolbarCard,
        OpportunityAcquisitionDetailsFormCard,
    },
});
