var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('dealer-global-context-hint'),_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("Allgemeine Anrufeinstellungen"))+" ")]),_c('v-spacer'),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.saving),expression:"saving"}],staticClass:"mr-2",attrs:{"width":2,"indeterminate":"","size":18}}),_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-card-text',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.loading)?_c('v-form',{ref:"inboundcalleridform",attrs:{"autocomplete":"off"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","clearable":"","hide-details":"auto","id":_vm.$id(),"items":_vm.inboundCallerIdOptions,"label":_vm.$t('Eingehende Anruferkennung beim Verbindungsaufbau')},on:{"change":_vm.updateCallSettings},model:{value:(_vm.inboundCallerId),callback:function ($$v) {_vm.inboundCallerId=$$v},expression:"inboundCallerId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","hide-details":"auto","hint":_vm.$t(
                                    'Wenn diese Option aktiviert ist, wird der Status-Code 180 oder 183 geschickt, sobald der eingehende Anruf eintrifft. Es wird erst dann Status-Code 200 zurückgeliefert, wenn der Anruf angenommen wird.'
                                ),"items":[
                                { value: false, text: _vm.$t('Nein') },
                                { value: true, text: _vm.$t('Ja') },
                            ],"label":_vm.$t(
                                    'Bei eingehenden Anrufen SIP-Status 180 (Ringing) / 183 (Session Progress) statt 200 schicken'
                                ),"persistent-hint":""},on:{"change":_vm.updateCallSettings},model:{value:(_vm.answerOnBridge),callback:function ($$v) {_vm.answerOnBridge=$$v},expression:"answerOnBridge"}})],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }