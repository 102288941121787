
import { BadRequest, Unauthorized } from "@/api/errors";
import { passwordPolicyApi } from "@/api/passwordPolicy";
import { userActionLinkApi } from "@/api/userActionLinkApi";
import { showAlert } from "@/app/messageUtil";
import { notEmpty, ValidationHelper } from "@/app/validation";
import { ActionLimiter } from "@/util/debounce";
import Vue from "vue";

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            validationHelper,
            isWorking: false,
            newPassword: "",
            confirmedPassword: "",
            newPasswordRules: notEmpty().and(validationHelper, "password"),
            validationActionLimiter: new ActionLimiter(),
        };
    },

    computed: {
        confirmedPasswordRules() {
            return [
                (v: string) => v === this.newPassword || this.$t("Die eingegebenen Passwörter stimmen nicht überein."),
            ];
        },
    },

    watch: {
        async newPassword() {
            if (this.newPassword.trim()) {
                try {
                    await this.validationActionLimiter.execute(() => passwordPolicyApi.validate(this.newPassword));
                } catch (e) {
                    if (e instanceof BadRequest) {
                        this.validationHelper.update(e, this.$refs.form);
                    }
                }
            }
        },
    },

    methods: {
        async changePassword() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }
            this.isWorking = true;
            try {
                await userActionLinkApi.changePassword(this.newPassword, this.$route.query.ualt as string);
                await showAlert(
                    this.$t("Passwort geändert") as string,
                    this.$t(
                        "Ihr Passwort wurde erfolgreich geändert. Sie werden nun zur Login-Seite weitergeleitet."
                    ) as string
                );
            } catch (e) {
                if (e instanceof BadRequest) {
                    this.validationHelper.update(e, this.$refs.form);
                    return;
                }

                if (!(e instanceof Unauthorized)) {
                    throw e;
                }

                await showAlert(
                    this.$t("Ein Fehler ist aufgetreten") as string,
                    this.$t(
                        "Das Ändern des Passwortes ist nicht mehr möglich. Lassen Sie das Passwort ggf. erneut zurücksetzen."
                    ) as string
                );
                this.newPassword = "";
                this.confirmedPassword = "";
                return;
            } finally {
                this.isWorking = false;
            }
            await this.$router.replace("/");
        },
    },
});
