
import CarryDispoExternOrderCancelDialog from "./CarryDispoExternOrderCancelDialog.vue";
import CarryDispoExternOrderNoteDialog from "./CarryDispoExternOrderNoteDialog.vue";
import CarryDispoExternOrderStatusResultsDialog from "./CarryDispoExternOrderStatusResultsDialog.vue";
import { getCreator as getNoteCreator } from "./carryDispoNoteUtils";
import { carryDispoApi, RemarkType, Status, Target, TargetType, Vehicle, VehicleType } from "@/api/carryDispo";
import { ExternOrderResult } from "@/api/carryDispoSearch";
import { PhoneNumberType } from "@/api/users";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { showInfo } from "@/app/messageUtil";
import UserLink from "@/app/pages/UserLink.vue";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import { isMobile, parseAndFormatNumber, PhoneNumberCountryCode } from "@/util/phoneNumberUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        baseUrl: {
            type: String,
            required: false,
        },
        externOrderResult: {
            type: Object as () => ExternOrderResult,
            required: true,
        },
    },

    data() {
        return {
            allNotesVisible: false,
            cancelDialogVisible: false,
            externOrderProp: this.externOrderResult.externOrder,
            externalViewerAvailabilityInMilliseconds: 60 * 60 * 24 * 30 * 1000, // 30 days
            generatingAccessToken: false,
            getNoteCreator,
            noteDialogVisible: false,
            parseAndFormatNumber,
            statusResultsDialogVisible: false,
        };
    },

    computed: {
        cancelable(): boolean {
            return (
                this.externOrderProp.status === Status.NO_CLEARANCE || this.externOrderProp.status === Status.CLEARANCE
            );
        },

        canGenerateAccessToken(): boolean {
            return (
                !this.externOrderProp.accessToken &&
                this.isLastActivityInExternalViewerAvailabilityPeriod &&
                !this.generatingAccessToken
            );
        },

        carryDispoExternalViewerUrl(): string | null {
            if (!this.externOrderProp.accessToken) {
                return null;
            }

            if (!this.isLastActivityInExternalViewerAvailabilityPeriod) {
                return null;
            }

            return `${this.baseUrl}/externviewer/?token=${this.externOrderProp.accessToken}`;
        },

        contractorCustomerPhoneE164(): string | null {
            if (!this.externOrderProp.contractorCustomerPhone) {
                return null;
            }

            return parseAndFormatNumber(
                this.externOrderProp.contractorCustomerPhone,
                "E.164",
                this.defaultCountry as PhoneNumberCountryCode
            );
        },

        contractorCustomerPhoneType(): string {
            if (this.contractorCustomerPhoneE164 && isMobile(this.contractorCustomerPhoneE164)) {
                return PhoneNumberType.MOBILE;
            } else {
                return PhoneNumberType.LANDLINE;
            }
        },

        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },

        deliveryPoint(): Target | null {
            const deliveryPoint =
                this.externOrderProp.targets.find((t) => t.type === TargetType.DELIVERY_POINT) || null;

            if (!deliveryPoint) {
                return null;
            }
            if (
                !deliveryPoint.description &&
                !deliveryPoint.name &&
                !deliveryPoint.street &&
                !deliveryPoint.houseNumber &&
                !deliveryPoint.zip &&
                !deliveryPoint.city &&
                !deliveryPoint.region &&
                !deliveryPoint.country &&
                !deliveryPoint.additionalInfo
            ) {
                return null;
            }

            return deliveryPoint;
        },

        diagnosis(): string | null {
            return this.externOrderProp.remarks.find((r) => r.type === RemarkType.DIAGNOSIS)?.text || null;
        },

        isLastActivityInExternalViewerAvailabilityPeriod(): boolean {
            // external viewer only available for 30 days after last activity
            const lastActivity = this.externOrderProp.lastmodified || this.externOrderProp.createdDate;
            const minLastActivityTime = now().getTime() - this.externalViewerAvailabilityInMilliseconds;

            return !!lastActivity && minLastActivityTime <= lastActivity.getTime();
        },

        servicePoint(): Target | null {
            const servicePoint = this.externOrderProp.targets.find((t) => t.type === TargetType.SERVICE_POINT) || null;

            if (!servicePoint) {
                return null;
            }

            if (
                !servicePoint.description &&
                !servicePoint.name &&
                !servicePoint.street &&
                !servicePoint.houseNumber &&
                !servicePoint.zip &&
                !servicePoint.city &&
                !servicePoint.region &&
                !servicePoint.country &&
                !servicePoint.additionalInfo
            ) {
                return null;
            }

            return servicePoint;
        },

        shortReport(): string | null {
            return this.externOrderProp.remarks.find((r) => r.type === RemarkType.SHORTREPORT)?.text || null;
        },

        statusColor(): string | null {
            if (this.externOrderProp.status === Status.NO_CLEARANCE) {
                return "warning";
            } else if (this.externOrderProp.status === Status.CLEARANCE) {
                return "success";
            } else if (this.externOrderProp.status === Status.TIMEOUT) {
                return "error";
            } else {
                return null;
            }
        },

        vehicle(): Vehicle {
            return this.externOrderProp.vehicle;
        },

        vehicleIcon(): string | null {
            const cars = [VehicleType.PKW, VehicleType.VAN, VehicleType.SUV];
            const motorbikes = [VehicleType.MOTORRAD, VehicleType.MOTORRAD_MIT_BEIWAGEN];
            const motorhomes = [VehicleType.WOHNMOBIL, VehicleType.WOHNWAGEN];
            const trucks = [VehicleType.LKW, VehicleType.BUS];
            const trailer = [VehicleType.ANHAENGER, VehicleType.ANHAENGER_MIT_BOOT, VehicleType.BOOTSANHAENGER];

            if (this.vehicle.vehicleType === null) {
                return null;
            } else if (cars.includes(this.vehicle.vehicleType)) {
                return "mdi-car-side";
            } else if (motorbikes.includes(this.vehicle.vehicleType)) {
                return "mdi-motorbike";
            } else if (motorhomes.includes(this.vehicle.vehicleType)) {
                return "mdi-rv-truck";
            } else if (trucks.includes(this.vehicle.vehicleType)) {
                return "mdi-truck";
            } else if (trailer.includes(this.vehicle.vehicleType)) {
                return "mdi-truck-trailer";
            } else {
                return null;
            }
        },

        vehicleTitle(): string {
            const title = [this.vehicle.brand, this.vehicle.model, this.vehicle.description]
                .map((v) => trimAndReturnNullIfEmpty(v))
                .filter((v) => !!v)
                .join(" ");

            return title || (this.$t("Unbenanntes Fahrzeug") as string);
        },
    },

    methods: {
        async cancelExternOrder(reason: string) {
            await carryDispoApi.cancelExternOrder(userSession.dealerId!, this.externOrderProp.id, reason);

            this.cancelable = false;
            this.cancelDialogVisible = false;

            this.$emit("cancel");
        },

        async generateAccessToken() {
            this.generatingAccessToken = true;

            try {
                const accessToken = await carryDispoApi.generateAccessToken(
                    userSession.dealerId!,
                    this.externOrderProp.id
                );

                if (!accessToken) {
                    showInfo(this.$t("Die Detailansicht konnte nicht generiert werden.") as string);
                }

                this.externOrderProp = { ...this.externOrderProp, accessToken };
            } finally {
                this.generatingAccessToken = false;
            }
        },

        getPhoneNumberTypeIcon(phoneNumberType: PhoneNumberType): string {
            if (phoneNumberType === PhoneNumberType.MOBILE) {
                return "mdi-cellphone";
            } else if (phoneNumberType === PhoneNumberType.LANDLINE) {
                return "mdi-deskphone";
            } else if (phoneNumberType === PhoneNumberType.FAX) {
                return "mdi-fax";
            }

            return "mdi-phone-classic";
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },
    },

    watch: {
        externOrderResult() {
            this.externOrderProp = this.externOrderResult.externOrder;
        },
    },

    components: {
        CarryDispoExternOrderStatusResultsDialog,
        CarryDispoExternOrderCancelDialog,
        CarryDispoExternOrderNoteDialog,
        CopyToClipboardIcon,
        UserLink,
    },
});
