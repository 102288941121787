
import MyIncomingCallsDataTableRow from "./MyIncomingCallsDataTableRow.vue";
import { IncomingCallResultAndContact, incomingCallsApi } from "@/api/incomingCalls";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { PickMutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        itemsPerPage: {
            type: Number,
            default: 100,
        },
    },

    data() {
        return {
            items: [] as IncomingCallResultAndContact[],
            loading: true,
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Anruf"),
                    width: "30%",
                },
                {
                    text: this.$t("Gesprächsnotiz"),
                    width: "35%",
                },
                {
                    text: this.$t("Kontakt und Vorgang"),
                    width: "35%",
                },
            ];
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const incomingCallSearchResults = await incomingCallsApi.getMyCalls(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    ++this.searchCounter
                );

                if (incomingCallSearchResults.searchId === this.searchCounter) {
                    this.items = incomingCallSearchResults.results;
                    this.paging.totalSize = incomingCallSearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },
    },

    watch: {
        async searchRequest() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DataTable,
        MyIncomingCallsDataTableRow,
    },
});
