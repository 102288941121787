
import { InventoryVehicle, InventoryVehicleStatusType } from "@/api/inventory";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { renderUnit } from "@/app/filters";
import {
    getLastInventoryVehicleStatus,
    getStatusColor,
    renderFirstRegistration,
    renderInventoryVehicleLatestConsumerPrice,
    renderInventoryVehicleTitle,
    renderMileage,
    renderPower,
} from "@/app/inventoryUtils";
import { renderExternalInventoryManagementVehicleLink } from "@/app/pages/externalinventorymanagementsettings/externalInventoryManagementPlaceholders";
import InventoryVehicleImageAndCarousel, {
    InventoryVehicleImage,
} from "@/app/pages/inventory/InventoryVehicleImageAndCarousel.vue";
import InventoryVehicleStatusFormDialog from "@/app/pages/inventory/InventoryVehicleStatusFormDialog.vue";
import { OpportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import { dealersStore } from "@/store/dealers";
import { externalInventoryManagementSettingsStore } from "@/store/externalInventoryManagementSettings";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        inventoryVehicle: {
            type: Object as () => InventoryVehicle,
            required: false,
        },
        inventoryVehicleId: {
            type: String,
            required: true,
        },
        opportunityAssistantState: {
            type: Object as () => OpportunityAssistantState,
            required: true,
        },
    },

    data() {
        return {
            getLastInventoryVehicleStatus,
            renderFirstRegistration,
            renderInventoryVehicleLatestConsumerPrice,
            renderInventoryVehicleTitle,
            renderMileage,
            renderPower,
            renderUnit,
            opportunityInventoryVehicle: this.opportunityAssistantState.inventoryVehicles.find(
                (i) => i.inventoryVehicleId === this.inventoryVehicleId
            )!,
            statusDialogVisible: false,
        };
    },

    computed: {
        dealerName(): string | null {
            if (!this.inventoryVehicle) {
                return null;
            }

            return dealersStore.dealerById(this.inventoryVehicle.dealerId)?.name ?? null;
        },

        externalInventoryManagementVehicleLink(): string | null {
            if (!this.inventoryVehicle) {
                return null;
            }

            return renderExternalInventoryManagementVehicleLink(
                externalInventoryManagementSettingsStore.settings.vehicleLinkPattern,
                this.inventoryVehicle.data
            );
        },

        externalInventoryManagementVehicleTooltip(): string | null {
            return externalInventoryManagementSettingsStore.settings.vehicleLinkLabel;
        },

        inventoryVehicleImages(): InventoryVehicleImage[] {
            if (!this.inventoryVehicle) {
                return [];
            }

            return this.inventoryVehicle.data.images.map((image) => ({
                inventoryVehicleId: this.inventoryVehicle!.id,
                image,
            }));
        },

        inventoryVehicleStatus(): InventoryVehicleStatusType | null {
            if (!this.inventoryVehicle) {
                return null;
            }

            return getLastInventoryVehicleStatus(this.inventoryVehicle);
        },

        inventoryVehicleStatusColor(): string | null {
            if (!this.inventoryVehicleStatus) {
                return null;
            }

            return getStatusColor(this.inventoryVehicleStatus);
        },
    },

    methods: {
        showInExternalInventoryManagement() {
            if (!this.externalInventoryManagementVehicleLink) {
                return;
            }

            window.open(this.externalInventoryManagementVehicleLink);
        },
    },

    components: {
        CopyToClipboardIcon,
        InventoryVehicleImageAndCarousel,
        InventoryVehicleStatusFormDialog,
    },
});
