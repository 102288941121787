
import { EmergencyIncident, EmergencyIncidentResult } from "@/api/emergencyIncidents";
import DLink from "@/app/components/DLink.vue";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import UserLink from "@/app/pages/UserLink.vue";
import { getCloser, getCreator } from "@/app/pages/emergencyincidents/emergencyIncidentUtils";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        compact: {
            type: Boolean,
            required: true,
        },
        emergencyIncidentResult: {
            type: Object as () => EmergencyIncidentResult,
            required: true,
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            required: true,
        },
        showDamage: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        closedTimestamp(): string | null {
            if (!this.emergencyIncidentResult.emergencyIncident.closed) {
                return null;
            }
            return formatInstant(
                this.emergencyIncidentResult.emergencyIncident.closed!,
                userSession.timeZone,
                userSession.locale,
                "S"
            );
        },

        closer(): string {
            return getCloser(this.emergencyIncidentResult.emergencyIncident);
        },

        creationTimestamp(): string {
            return formatInstant(
                this.emergencyIncidentResult.emergencyIncident.created,
                userSession.timeZone,
                userSession.locale,
                "S"
            );
        },

        creator(): string {
            return getCreator(this.emergencyIncidentResult.emergencyIncident);
        },

        dealerName(): string | null {
            return dealersStore.dealerById(this.emergencyIncidentResult.emergencyIncident.dealerId)?.name || null;
        },

        emergencyIncident(): EmergencyIncident {
            return this.emergencyIncidentResult.emergencyIncident;
        },

        lastActivityTimestamp(): string | null {
            if (!this.emergencyIncidentResult.emergencyIncident.lastActivity) {
                return null;
            }
            return formatInstant(
                this.emergencyIncidentResult.emergencyIncident.lastActivity,
                userSession.timeZone,
                userSession.locale,
                "S"
            );
        },
    },

    components: {
        DLink,
        IconWithTooltip,
        UserLink,
    },
});
