import { CaseSearchOrder, CaseSearchRequest, SentimentType } from "@/api/caseSearch";

export const EMPTY_SEARCH_REQUEST: CaseSearchRequest = {
    caseIds: [],
    excludedCaseIds: [],
    dealerIds: [],
    caseTypes: [],
    status: [],
    channels: [],
    sentimentTypes: [],
    urgencies: [],
    priority: [],
    creatorActorType: [],
    createdBy: [],
    closerActorType: [],
    closedBy: [],
    outcomeReasonIds: [],
    assignees: [],
    contactIds: [],
    createdFrom: null,
    createdTo: null,
    lastActivityFrom: null,
    lastActivityTo: null,
    closedFrom: null,
    closedTo: null,
    individualReceivers: [],
    escalationGroupReceivers: [],
    hasReceivers: null,
    externalReference: null,
    search: null,
    sortBy: CaseSearchOrder.CREATED_DESC,
};

export function getSentimentType(sentiment: number | null): SentimentType {
    if (sentiment === null) {
        return SentimentType.UNKNOWN;
    } else if (sentiment > 0) {
        return SentimentType.POSITIVE;
    } else if (sentiment === 0) {
        return SentimentType.NEUTRAL;
    } else {
        return SentimentType.NEGATIVE;
    }
}
