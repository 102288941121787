
import { RenaultRaceLeadSourceMappingForm, renaultRaceLeadSourceMappingsApi } from "@/api/renaultRaceLeadSourceMapping";
import { showConfirm } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { notEmpty } from "@/app/validation";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { Mutable, PickNullable, SelectOption } from "@/util/types";
import Vue from "vue";

type MutableRenaultRaceLeadSourceMappingForm = PickNullable<
    Mutable<RenaultRaceLeadSourceMappingForm>,
    "renaultRaceLeadSource" | "opportunitySourceId"
>;

const EMPTY_MUTABLE_RENAULT_RACE_LEAD_SOURCE_MAPPING_FORM: MutableRenaultRaceLeadSourceMappingForm = {
    renaultRaceLeadSource: null,
    opportunitySourceId: null,
};

export default Vue.extend({
    data() {
        return {
            notEmpty,

            items: [] as MutableRenaultRaceLeadSourceMappingForm[],
            loading: true,
            saving: false,
        };
    },

    computed: {
        leadSourceRules(): Function[] {
            return [
                ...((notEmpty().maxLength(20) as unknown) as Function[]),
                (v: string | null) =>
                    (!!v && this.items.filter((m) => m.renaultRaceLeadSource?.trim() === v.trim()).length <= 1) ||
                    this.$t("Die Quelle ist bereits zugeordnet."),
            ];
        },

        opportunitySourceOptions(): SelectOption[] {
            return opportunitySourcesStore.opportunitySources.map((s) => ({
                text: s.name,
                value: s.id,
            }));
        },
    },

    methods: {
        addEmptyItem() {
            this.items.push({ renaultRaceLeadSource: null, opportunitySourceId: null });
        },

        isEmptyForm(form: MutableRenaultRaceLeadSourceMappingForm): boolean {
            return JSON.stringify(EMPTY_MUTABLE_RENAULT_RACE_LEAD_SOURCE_MAPPING_FORM) === JSON.stringify(form);
        },

        async loadItems() {
            this.items = [];
            this.loading = true;

            try {
                this.items = await renaultRaceLeadSourceMappingsApi.getAll();
            } finally {
                this.loading = false;
            }
        },

        async removeItem(index: number) {
            if (
                !this.isEmptyForm(this.items[index]) &&
                !(await showConfirm(
                    this.$t("Quellenzuordnung löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Quellenzuordnung löschen möchten?") as string
                ))
            ) {
                return false;
            }

            this.items.splice(index, 1);
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await renaultRaceLeadSourceMappingsApi.edit(this.items as RenaultRaceLeadSourceMappingForm[]);
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DealerGlobalContextHint,
    },
});
