import { Contact, ContactMethod } from "./contacts";
import { ExteriorColor, Fuel, InventoryVehicleStatusForm, InventoryVehicleStatusType, UsageType } from "./inventory";
import { VehicleClass } from "./makeModels";
import { NoteForm } from "./notes";
import { OpportunityLastActivityType } from "./opportunityPrioritySettings";
import { AcquisitionType } from "./opportunityTeams";
import { OutgoingCallForm } from "./outgoingCalls";
import { OutgoingCitnowVideoCreationForm } from "./outgoingCitnowVideos";
import { OutgoingEmailForm } from "./outgoingEmails";
import { OutgoingSmsForm } from "./outgoingSms";
import { OutgoingWhatsAppMessageForm } from "./outgoingWhatsApp";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum Bank {
    AUDI_BANK = "AUDI_BANK",
    BANK11 = "BANK11",
    BDK = "BDK",
    BMW_BANK = "BMW_BANK",
    CONSORS_FINANZ = "CONSORS_FINANZ",
    CREDITPLUS = "CREDITPLUS",
    DAIMLER_MOBILITY = "DAIMLER_MOBILITY",
    FIAT_BANK = "FIAT_BANK",
    FORD_BANK = "FORD_BANK",
    HONDA_BANK = "HONDA_BANK",
    MAZDA_BANK = "MAZDA_BANK",
    MERCEDES_BENZ_BANK = "MERCEDES_BENZ_BANK",
    NISSAN_BANK = "NISSAN_BANK",
    OPEL_BANK = "OPEL_BANK",
    PORSCHE_BANK = "PORSCHE_BANK",
    PSA_BANK = "PSA_BANK",
    RENAULT_BANK = "RENAULT_BANK",
    SANTANDER = "SANTANDER",
    SEAT_BANK = "SEAT_BANK",
    SKODA_BANK = "SKODA_BANK",
    STELLANTIS_BANK = "STELLANTIS_BANK",
    TARGOBANK = "TARGOBANK",
    TOYOTA_BANK = "TOYOTA_BANK",
    VW_BANK = "VW_BANK",
}

export interface OpportunityAttachment {
    readonly id: number;
    readonly filename: string;
    readonly size: number;
    readonly dataHash: string;
}

export enum OpportunityChannel {
    FAX = "FAX",
    EMAIL = "EMAIL",
    WEBSITE = "WEBSITE",
    PHONE_INTERNAL = "PHONE_INTERNAL",
    PHONE_BDC = "PHONE_BDC",
    SMS = "SMS",
    LIVE_CHAT = "LIVE_CHAT",
    PERSONAL_APPEARANCE = "PERSONAL_APPEARANCE",
    WHATSAPP = "WHATSAPP",
}

export enum OpportunityRelativeSuccessProbability {
    VERY_LOW = "VERY_LOW",
    LOW = "LOW",
    AVERAGE = "AVERAGE",
    HIGH = "HIGH",
    VERY_HIGH = "VERY_HIGH",
}

export enum OpportunityStatus {
    OPEN = "OPEN",
    ASSIGNED = "ASSIGNED",
    POSTPONED = "POSTPONED",
    CLOSED = "CLOSED",
}

export enum OpportunityPriority {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

export enum OpportunityUrgency {
    NORMAL = "NORMAL",
    HIGH = "HIGH",
    PERSONAL_APPEARANCE = "PERSONAL_APPEARANCE",
}

export interface OpportunityInventoryVehicle {
    readonly inventoryVehicleId: string;
    readonly link: string | null;
}

export interface OpportunityTeamReceiver {
    readonly opportunityTeamId: string;
    readonly added: Date;
    readonly pickedUserId: string | null;
    readonly pickedAt: Date | null;
}

export interface OpportunityIndividualReceiver {
    readonly userId: string;
    readonly added: Date;
}

export interface OpportunityUrl {
    readonly url: string;
    readonly key: string | null;
    readonly description: string | null;
}

export interface OpportunityUser {
    readonly opportunityId: string;
    readonly userId: string;
    readonly originalUserId: string | null;
    readonly visibilityBegin: Date;
    readonly visibilityEnd: Date | null;
    readonly activitiesRead: number;
    readonly creator: boolean;
    readonly assignee: boolean;
    readonly closer: boolean;
    readonly opportunityActivitiesCount: number;
    readonly opportunityCreated: Date;
    readonly opportunityPostponedUntil: Date | null;
    readonly opportunityClosed: Date | null;
    readonly opportunityLastActivity: Date | null;
    readonly opportunityLastActivityWithType: Date | null;
    readonly opportunityUrgencySortKey: number;
    readonly opportunityAppointmentTime: Date | null;
    readonly opportunityPrioritySortKey: number;
    readonly opportunityAssigned: boolean;
}

export interface Opportunity {
    readonly id: string;
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly creatorCtUserId: string | null;
    readonly updated: Date;
    readonly closed: Date | null;
    readonly closerUserId: string | null;
    readonly closerCtUserId: string | null;
    readonly outcome: boolean | null;
    readonly outcomeReasonId: string | null;
    readonly outcomeDetails: string | null;
    readonly postponedUntil: Date | null;
    readonly opportunityUsers: OpportunityUser[];
    readonly assigneeIds: string[];
    readonly dealerId: string;
    readonly channel: OpportunityChannel;
    readonly sourceId: string;
    readonly sentiment: number | null;
    readonly urgency: OpportunityUrgency;
    readonly appointmentTime: Date | null;
    readonly priority: OpportunityPriority;
    readonly subject: string | null;
    readonly opportunityIndividualReceivers: OpportunityIndividualReceiver[];
    readonly opportunityTeamReceivers: OpportunityTeamReceiver[];
    readonly contactId: string | null;
    readonly attachments: OpportunityAttachment[];
    readonly preferredContactMethod: ContactMethod | null;
    readonly preferredContactDetails: string | null;
    readonly earliestContactTime: Date | null;
    readonly latestContactTime: Date | null;
    readonly lastActivity: Date | null;
    readonly lastActivityWithType: Date | null;
    readonly lastActivityType: OpportunityLastActivityType | null;
    readonly activitiesCount: number;
    readonly relativeSuccessProbability: OpportunityRelativeSuccessProbability | null;
    readonly inventoryVehicles: OpportunityInventoryVehicle[];
    readonly nextStep: string | null;
    readonly projectedCloseDate: string | null;
    readonly urls: OpportunityUrl[];
    readonly externalReferences: string[];
    readonly registrationRequested: boolean | null;
    readonly insuranceRequested: boolean | null;
    readonly deliveryRequested: boolean | null;
    readonly testdriveRequested: boolean | null;
    readonly preferredTestdriveDateTime: string | null;
    readonly counterOffer: number | null;
    readonly dealName: string | null;
    readonly acquisitionType: AcquisitionType | null;
    readonly bank: Bank | null;
    readonly paybackPeriodMonths: number | null;
    readonly totalMileage: number | null;
    readonly firstInstallment: number | null;
    readonly monthlyInstallment: number | null;
    readonly finalInstallment: number | null;
    readonly internalId: string | null;
    readonly link: string | null;
    readonly vehicleClass: string | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly usageType: UsageType | null;
    readonly mileage: number | null;
    readonly price: number | null;
    readonly firstRegistrationDay: number | null;
    readonly firstRegistrationMonth: number | null;
    readonly firstRegistrationYear: number | null;
    readonly fuel: Fuel | null;
    readonly power: number | null;
    readonly exteriorColor: ExteriorColor | null;
    readonly cubicCapacity: number | null;
    readonly preOffer: boolean | null;
    readonly tradeInRequested: boolean | null;
    readonly contactVehicleLink: string | null;
    readonly contactVehicleClass: VehicleClass | null;
    readonly contactVehicleMake: string | null;
    readonly contactVehicleModel: string | null;
    readonly contactVehicleModelDescription: string | null;
    readonly contactVehicleLicensePlate: string | null;
    readonly contactVehicleVin: string | null;
    readonly contactVehicleModelYear: string | null;
    readonly contactVehicleMileage: number | null;
    readonly contactVehicleFirstRegistrationDay: number | null;
    readonly contactVehicleFirstRegistrationMonth: number | null;
    readonly contactVehicleFirstRegistrationYear: number | null;
    readonly contactVehiclePower: number | null;
    readonly contactVehicleColor: string | null;
    readonly contactVehicleTransmission: string | null;
    readonly contactVehicleFeatures: string | null;
    readonly contactVehiclePreviousOwners: number | null;
    readonly contactVehicleLoanRedemption: boolean | null;
    readonly contactVehiclePriceSuggestion: number | null;
}

export interface OpportunityResult {
    readonly opportunity: Opportunity;
    readonly contact: Contact | null;
    readonly vehicleSummaries: VehicleSummary[];
}

export interface OpportunityConversionForm {
    readonly caseType: string;
    readonly individualReceivers: string[];
    readonly escalationGroupReceivers: string[];
}

export interface OpportunityCreationForm {
    readonly dealerId: string;
    readonly channel: OpportunityChannel;
    readonly sourceId: string;
    readonly sentiment: number | null;
    readonly urgency: OpportunityUrgency;
    readonly appointmentTime: Date | null;
    readonly subject: string | null;
    readonly opportunityIndividualReceivers: string[];
    readonly opportunityTeamReceivers: string[];
    readonly contactId: string | null;
    readonly preferredContactMethod: ContactMethod | null;
    readonly preferredContactDetails: string | null;
    readonly earliestContactTime: Date | null;
    readonly latestContactTime: Date | null;
    readonly relativeSuccessProbability: OpportunityRelativeSuccessProbability | null;
    readonly inventoryVehicles: OpportunityInventoryVehicle[];
    readonly urls: string[];
    readonly externalReferences: string[];
    readonly registrationRequested: boolean | null;
    readonly insuranceRequested: boolean | null;
    readonly deliveryRequested: boolean | null;
    readonly testdriveRequested: boolean | null;
    readonly preferredTestdriveDateTime: string | null;
    readonly counterOffer: number | null;
    readonly dealName: string | null;
    readonly acquisitionType: AcquisitionType | null;
    readonly bank: Bank | null;
    readonly paybackPeriodMonths: number | null;
    readonly totalMileage: number | null;
    readonly firstInstallment: number | null;
    readonly monthlyInstallment: number | null;
    readonly finalInstallment: number | null;
    readonly internalId: string | null;
    readonly link: string | null;
    readonly vehicleClass: string | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly usageType: UsageType | null;
    readonly mileage: number | null;
    readonly price: number | null;
    readonly firstRegistrationDay: number | null;
    readonly firstRegistrationMonth: number | null;
    readonly firstRegistrationYear: number | null;
    readonly fuel: Fuel | null;
    readonly power: number | null;
    readonly exteriorColor: ExteriorColor | null;
    readonly cubicCapacity: number | null;
    readonly preOffer: boolean | null;
    readonly tradeInRequested: boolean | null;
    readonly contactVehicleLink: string | null;
    readonly contactVehicleClass: VehicleClass | null;
    readonly contactVehicleMake: string | null;
    readonly contactVehicleModel: string | null;
    readonly contactVehicleModelDescription: string | null;
    readonly contactVehicleLicensePlate: string | null;
    readonly contactVehicleVin: string | null;
    readonly contactVehicleModelYear: string | null;
    readonly contactVehicleMileage: number | null;
    readonly contactVehicleFirstRegistrationDay: number | null;
    readonly contactVehicleFirstRegistrationMonth: number | null;
    readonly contactVehicleFirstRegistrationYear: number | null;
    readonly contactVehiclePower: number | null;
    readonly contactVehicleColor: string | null;
    readonly contactVehicleTransmission: string | null;
    readonly contactVehicleFeatures: string | null;
    readonly contactVehiclePreviousOwners: number | null;
    readonly contactVehicleLoanRedemption: boolean | null;
    readonly contactVehiclePriceSuggestion: number | null;
}

export interface UserOpportunityStatus {
    readonly openOpportunities: number;
    readonly openOpportunitiesWithUnreadActivities: number;
    readonly assignedOpportunities: number;
    readonly assignedOpportunitiesWithUnreadActivities: number;
    readonly postponedOpportunities: number;
    readonly postponedOpportunitiesWithUnreadActivities: number;
}

export enum OpportunityVisibilityArea {
    ACTIVE = "ACTIVE",
    POSTPONED = "POSTPONED",
    CREATED = "CREATED",
    CLOSED = "CLOSED",
}

export enum OpportunitiesSort {
    LAST_ACTIVITY_DESC = "LAST_ACTIVITY_DESC",
    LAST_ACTIVITY_ASC = "LAST_ACTIVITY_ASC",
    POSTPONED_UNTIL_DESC = "POSTPONED_UNTIL_DESC",
    POSTPONED_UNTIL_ASC = "POSTPONED_UNTIL_ASC",
    CLOSED_DESC = "CLOSED_DESC",
    CLOSED_ASC = "CLOSED_ASC",
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    VISIBILITY_BEGIN_DESC = "VISIBILITY_BEGIN_DESC",
    VISIBILITY_BEGIN_ASC = "VISIBILITY_BEGIN_ASC",
    VISIBILITY_END_DESC = "VISIBILITY_END_DESC",
    VISIBILITY_END_ASC = "VISIBILITY_END_ASC",
    PRIORITY_DESC = "PRIORITY_DESC",
}

export interface MyOpportunitiesResult {
    readonly totalSize: number;
    readonly opportunityUsers: OpportunityUser[];
    readonly searchId: number;
}

export interface PreferredContactForm {
    readonly preferredContactMethod: ContactMethod | null;
    readonly preferredContactDetails: string | null;
    readonly earliestContactTime: Date | null;
    readonly latestContactTime: Date | null;
}

export interface OpportunityGeneralInfoForm {
    readonly dealerId: string;
    readonly channel: OpportunityChannel;
    readonly sourceId: string;
    readonly sentiment: number | null;
    readonly urgency: OpportunityUrgency;
    readonly appointmentTime: Date | null;
    readonly subject: string | null;
    readonly nextStep: string | null;
    readonly projectedCloseDate: string | null;
}

export interface OpportunityOutcomeForm {
    readonly outcome: boolean;
    readonly outcomeReasonId: string | null;
    readonly outcomeDetails: string | null;
}

export interface OpportunityTradeInForm {
    readonly tradeInRequested: boolean | null;
    readonly contactVehicleLink: string | null;
    readonly contactVehicleClass: VehicleClass | null;
    readonly contactVehicleMake: string | null;
    readonly contactVehicleModel: string | null;
    readonly contactVehicleModelDescription: string | null;
    readonly contactVehicleLicensePlate: string | null;
    readonly contactVehicleVin: string | null;
    readonly contactVehicleModelYear: string | null;
    readonly contactVehicleMileage: number | null;
    readonly contactVehicleFirstRegistrationDay: number | null;
    readonly contactVehicleFirstRegistrationMonth: number | null;
    readonly contactVehicleFirstRegistrationYear: number | null;
    readonly contactVehiclePower: number | null;
    readonly contactVehicleColor: string | null;
    readonly contactVehicleTransmission: string | null;
    readonly contactVehicleFeatures: string | null;
    readonly contactVehiclePreviousOwners: number | null;
    readonly contactVehicleLoanRedemption: boolean | null;
    readonly contactVehiclePriceSuggestion: number | null;
}

export interface OpportunityRequestedVehicleForm {
    readonly internalId: string | null;
    readonly link: string | null;
    readonly vehicleClass: string | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly usageType: UsageType | null;
    readonly mileage: number | null;
    readonly price: number | null;
    readonly firstRegistrationDay: number | null;
    readonly firstRegistrationMonth: number | null;
    readonly firstRegistrationYear: number | null;
    readonly fuel: Fuel | null;
    readonly power: number | null;
    readonly exteriorColor: ExteriorColor | null;
    readonly cubicCapacity: number | null;
    readonly preOffer: boolean | null;
}

export interface OpportunityAcquisitionDetailsForm {
    readonly registrationRequested: boolean | null;
    readonly insuranceRequested: boolean | null;
    readonly deliveryRequested: boolean | null;
    readonly testdriveRequested: boolean | null;
    readonly preferredTestdriveDateTime: string | null;
    readonly counterOffer: number | null;
    readonly dealName: string | null;
    readonly acquisitionType: AcquisitionType | null;
    readonly bank: Bank | null;
    readonly paybackPeriodMonths: number | null;
    readonly totalMileage: number | null;
    readonly firstInstallment: number | null;
    readonly monthlyInstallment: number | null;
    readonly finalInstallment: number | null;
}

export interface OpportunityReceiversForm {
    readonly individualReceivers: string[];
    readonly opportunityTeamReceivers: string[];
}

export enum OpportunityAuditEventType {
    CLOSED = "CLOSED",
    REOPENED = "REOPENED",
    POSTPONED = "POSTPONED",
    UNPOSTPONED = "UNPOSTPONED",
    UPDATED_ASSIGNEES = "UPDATED_ASSIGNEES",
    MERGED = "MERGED",
    INVENTORY_VEHICLE_STATUS = "INVENTORY_VEHICLE_STATUS",
    UPDATED_OUTCOME = "UPDATED_OUTCOME",
    CONVERTED = "CONVERTED",
    INVENTORY_VEHICLE_DELETED = "INVENTORY_VEHICLE_DELETED",
    INVENTORY_VEHICLE_ACTIVATED = "INVENTORY_VEHICLE_ACTIVATED",
    UPDATED_RECEIVERS = "UPDATED_RECEIVERS",
}

export interface OpportunityAuditEvent {
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly creatorCtUserId: string | null;
    readonly opportunityId: string;
    readonly originalIssueId: string | null;
    readonly type: OpportunityAuditEventType;
    readonly postponedUntil: Date | null;
    readonly mergedIssueId: string | null;
    readonly oldAssignedUserIds: string[];
    readonly newAssignedUserIds: string[];
    readonly inventoryVehicleId: string | null;
    readonly oldInventoryVehicleStatusType: InventoryVehicleStatusType | null;
    readonly newInventoryVehicleStatusType: InventoryVehicleStatusType | null;
    readonly outcome: boolean | null;
    readonly outcomeReasonId: string | null;
    readonly outcomeDetails: string | null;
    readonly convertedIssueId: string | null;
    readonly oldIndividualReceiverUserIds: string[];
    readonly newIndividualReceiverUserIds: string[];
    readonly oldOpportunityTeamReceiverIds: string[];
    readonly newOpportunityTeamReceiverIds: string[];
}

export interface OpportunityInventoryVehicleStatusForm {
    readonly id: string;
    readonly form: InventoryVehicleStatusForm;
}

export interface OpportunityCloseForm {
    readonly outcomeForm: OpportunityOutcomeForm;
    readonly statusForms: OpportunityInventoryVehicleStatusForm[];
}

export interface VehicleSummary {
    readonly id: string;
    readonly internalId: string | null;
    readonly vehicleClass: string | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly usageType: UsageType | null;
    readonly mileage: number | null;
    readonly vin: string | null;
    readonly price: number | null;
    readonly firstRegistrationDay: number | null;
    readonly firstRegistrationMonth: number | null;
    readonly firstRegistrationYear: number | null;
    readonly fuel: Fuel | null;
    readonly power: number | null;
    readonly exteriorColor: ExteriorColor | null;
    readonly cubicCapacity: number | null;
    readonly preOffer: boolean | null;
    readonly images: string[];
}

interface OpportunitiesApi {
    addOpportunity(
        opportunityCreationForm: OpportunityCreationForm,
        initialNoteContent: string,
        assignees: string[],
        files: File[],
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<string>;
    getMyOpportunityStatus(): Promise<UserOpportunityStatus>;
    getMyOpportunities(
        opportunityVisibilityArea: OpportunityVisibilityArea,
        opportunitiesSort: OpportunitiesSort,
        start: number,
        size: number,
        searchId: number
    ): Promise<MyOpportunitiesResult>;
    getByIds(ids: string[]): Promise<OpportunityResult[]>;
    getById(id: string): Promise<OpportunityResult | null>;
    getPrintView(id: string): Promise<string>;
    updateRead(id: string): Promise<void>;
    closeOpportunity(
        id: string,
        opportunityCloseForm: OpportunityCloseForm,
        disableNotifications?: boolean
    ): Promise<void>;
    reopenOpportunity(id: string): Promise<void>;
    postponeOpportunity(id: string, postponedUntil: Date): Promise<void>;
    unpostponeOpportunity(id: string): Promise<void>;
    updateAssignees(id: string, assignees: string[], disableNotifications?: boolean): Promise<void>;
    assignToSelf(id: string): Promise<void>;
    deleteOpportunity(id: string): Promise<void>;
    mergeOpportunity(targetOpportunityId: string, sourceOpportunityId: string): Promise<void>;
    updatePreferredContact(id: string, preferredContactForm: PreferredContactForm): Promise<void>;
    updateGeneralInfo(id: string, generalInfoForm: OpportunityGeneralInfoForm): Promise<void>;
    updateOutcome(id: string, opportunityOutcomeForm: OpportunityOutcomeForm): Promise<void>;
    updateNextStep(id: string, nextStep: string | null): Promise<void>;
    updateProjectedCloseDate(id: string, projectedCloseDate: string | null): Promise<void>;
    updateTradeIn(id: string, form: OpportunityTradeInForm): Promise<void>;
    updateRequestedVehicle(id: string, form: OpportunityRequestedVehicleForm): Promise<void>;
    updateAcquisitionDetails(id: string, form: OpportunityAcquisitionDetailsForm): Promise<void>;
    addInventoryVehicle(id: string, opportunityInventoryVehicle: OpportunityInventoryVehicle): Promise<void>;
    updateInventoryVehicle(id: string, inventoryVehicleId: string, link: string | null): Promise<void>;
    deleteInventoryVehicle(id: string, inventoryVehicleId: string): Promise<void>;
    updateUrls(id: string, urls: OpportunityUrl[]): Promise<void>;
    addAttachments(id: string, files: File[], progressCallback: (progressEvent: ProgressEvent) => void): Promise<void>;
    deleteAttachment(id: string, attachmentId: number): Promise<void>;
    generateAttachmentLink(id: string, attachment: OpportunityAttachment, download?: boolean): string;
    editReceivers(id: string, form: OpportunityReceiversForm): Promise<void>;
    editContact(id: string, contactId: string | null): Promise<void>;
    addNote(id: string, noteForm: NoteForm): Promise<void>;
    startCall(id: string, outgoingCallForm: OutgoingCallForm): Promise<void>;
    sendSms(id: string, outgoingSmsForm: OutgoingSmsForm): Promise<void>;
    sendEmail(
        id: string,
        outgoingEmailForm: OutgoingEmailForm,
        files: File[],
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
    sendWhatsAppMessage(
        id: string,
        accountId: string,
        form: OutgoingWhatsAppMessageForm,
        files: File[],
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
    requestCitnowVideo(id: string, dealerId: string, lid: string, form: OutgoingCitnowVideoCreationForm): Promise<void>;
    getAuditEvents(id: string): Promise<OpportunityAuditEvent[]>;
    resolveAlias(alias: string): Promise<string | null>;
    convertOpportunity(id: string, opportunityConversionForm: OpportunityConversionForm): Promise<string>;
    getLockedUntil(): Promise<Date | null>;
}

export const opportunitiesApi: OpportunitiesApi = {
    async addOpportunity(opportunityCreationForm, initialNoteContent, assignees, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }
        formData.append("form", new Blob([JSON.stringify(opportunityCreationForm)], { type: "application/json" }));
        formData.append("initialNoteContent", initialNoteContent);
        formData.append("assignees", new Blob([JSON.stringify(assignees)], { type: "application/json" }));
        return (await axios.post(`/api/opportunities`, formData, { onUploadProgress })).data;
    },

    async getMyOpportunityStatus() {
        return (await axios.get(`/api/opportunities/_my-opportunity-status`)).data;
    },

    async getMyOpportunities(opportunityVisibilityArea, opportunitiesSort, start, size, searchId) {
        return cloneObject(
            (
                await axios.get(`/api/opportunities/_my-opportunities`, {
                    params: { opportunityVisibilityArea, opportunitiesSort, start, size, searchId },
                })
            ).data
        );
    },

    async getByIds(ids) {
        return cloneObject((await axios.get(`/api/opportunities`, { params: { id: ids.join(",") } })).data);
    },

    async getById(id) {
        const result: OpportunityResult = (await axios.get(`/api/opportunities/${id}`)).data;

        if (!result) {
            return null;
        }

        return cloneObject(result);
    },

    async getPrintView(id) {
        const result: string = (await axios.get(`/api/opportunities/${id}/_print`)).data;
        return result || "";
    },

    async updateRead(id) {
        await axios.post(`/api/opportunities/${id}/_update-read`);
    },

    async closeOpportunity(id, opportunityCloseForm, disableNotifications) {
        await axios.post(`/api/opportunities/${id}/_close`, opportunityCloseForm, { params: { disableNotifications } });
    },

    async reopenOpportunity(id) {
        await axios.post(`/api/opportunities/${id}/_reopen`);
    },

    async postponeOpportunity(id, postponedUntil) {
        await axios.post(`/api/opportunities/${id}/_postpone`, null, { params: { postponedUntil } });
    },

    async unpostponeOpportunity(id) {
        await axios.post(`/api/opportunities/${id}/_unpostpone`);
    },

    async updateAssignees(id, assignees, disableNotifications) {
        await axios.post(`/api/opportunities/${id}/_update-assignees`, assignees, { params: { disableNotifications } });
    },

    async assignToSelf(id) {
        await axios.post(`/api/opportunities/${id}/_assign-to-self`);
    },

    async deleteOpportunity(id) {
        await axios.delete(`/api/opportunities/${id}`);
    },

    async mergeOpportunity(targetOpportunityId, sourceOpportunityId) {
        await axios.post(`/api/opportunities/${targetOpportunityId}/_merge`, null, {
            params: {
                sourceOpportunityId,
            },
        });
    },

    async updatePreferredContact(id, preferredContactForm) {
        await axios.post(`/api/opportunities/${id}/_update-preferred-contact`, preferredContactForm);
    },

    async updateGeneralInfo(id, form) {
        await axios.post(`/api/opportunities/${id}/_update-general-info`, form);
    },

    async updateOutcome(id, form) {
        await axios.post(`/api/opportunities/${id}/_update-outcome`, form);
    },

    async updateNextStep(id, nextStep) {
        await axios.post(`/api/opportunities/${id}/_update-next-step`, null, { params: { nextStep } });
    },

    async updateProjectedCloseDate(id, projectedCloseDate) {
        await axios.post(`/api/opportunities/${id}/_update-projected-close-date`, null, {
            params: { projectedCloseDate },
        });
    },

    async updateTradeIn(id, form) {
        await axios.post(`/api/opportunities/${id}/_update-trade-in`, form);
    },

    async updateRequestedVehicle(id, form) {
        await axios.post(`/api/opportunities/${id}/_update-requested-vehicle`, form);
    },

    async updateAcquisitionDetails(id, form) {
        await axios.post(`/api/opportunities/${id}/_update-acquisition-details`, form);
    },

    async addInventoryVehicle(id, opportunityInventoryVehicle) {
        await axios.post(`/api/opportunities/${id}/inventory-vehicles`, opportunityInventoryVehicle);
    },

    async updateInventoryVehicle(id, inventoryVehicleId, link) {
        await axios.put(`/api/opportunities/${id}/inventory-vehicles/${inventoryVehicleId}`, null, {
            params: { link },
        });
    },

    async deleteInventoryVehicle(id, inventoryVehicleId) {
        await axios.delete(`/api/opportunities/${id}/inventory-vehicles/${inventoryVehicleId}`);
    },

    async updateUrls(id, urls) {
        await axios.post(`/api/opportunities/${id}/urls`, urls);
    },

    async addAttachments(id, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }
        await axios.post(`/api/opportunities/${id}/attachments`, formData, { onUploadProgress });
    },

    async deleteAttachment(id, attachmentId) {
        await axios.delete(`/api/opportunities/${id}/attachments/${attachmentId}`);
    },

    generateAttachmentLink(id, attachment, download) {
        return `/api/opportunities/${id}/attachments/${attachment.id}/${attachment.dataHash}/${encodeURIComponent(
            attachment.filename.replace(/\//g, "-")
        )}${download ? "?dl=true" : ""}`;
    },

    async editReceivers(id, form) {
        await axios.post(`/api/opportunities/${id}/_edit-receivers`, form);
    },

    async editContact(id, contactId) {
        await axios.post(`/api/opportunities/${id}/_edit-contact`, null, { params: { contactId } });
    },

    async addNote(id, noteForm) {
        await axios.post(`/api/opportunities/${id}/_add-note`, noteForm);
    },

    async startCall(id, outgoingCallForm) {
        await axios.post(`/api/opportunities/${id}/_start-call`, outgoingCallForm);
    },

    async sendSms(id, outgoingSmsForm) {
        await axios.post(`/api/opportunities/${id}/_send-sms`, outgoingSmsForm);
    },

    async sendEmail(id, outgoingEmailForm, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }
        formData.append("form", new Blob([JSON.stringify(outgoingEmailForm)], { type: "application/json" }));
        await axios.post(`/api/opportunities/${id}/_send-email`, formData, { onUploadProgress });
    },

    async sendWhatsAppMessage(id, accountId, form, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("file", file);
        }
        formData.append("accountId", accountId);
        formData.append("form", new Blob([JSON.stringify(form)], { type: "application/json" }));
        await axios.post(`/api/opportunities/${id}/_send-whatsapp`, formData, { onUploadProgress });
    },

    async requestCitnowVideo(id, dealerId, lid, form) {
        await axios.post(`/api/opportunities/${id}/_request-citnow-video`, form, { params: { dealerId, lid } });
    },

    async getAuditEvents(id) {
        return cloneObject((await axios.get(`/api/opportunities/${id}/_audit-events`)).data);
    },

    async resolveAlias(alias) {
        return (await axios.get(`/api/opportunities/${alias}/_alias`)).data;
    },

    async convertOpportunity(id, opportunityConversionForm) {
        return (await axios.post(`/api/opportunities/${id}/_convert`, opportunityConversionForm)).data;
    },

    async getLockedUntil() {
        return cloneObject((await axios.get("/api/opportunities/_/locked-until")).data);
    },
};
