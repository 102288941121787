
import {
    CarryDispoLocation,
    CarryDispoLocationForm,
    CarryDispoSettings,
    carryDispoSettingsApi,
} from "@/api/carryDispoSettings";
import { Permission } from "@/api/userSession";
import CountryPicker from "@/app/components/CountryPicker.vue";
import StatePicker from "@/app/components/StatePicker.vue";
import { getCountryCaption, getStateCaption, getStateLabel } from "@/app/countryStateUtils";
import { showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { maxLength, notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            carryDispoSettings: {
                baseUrl: null,
                username: null,
                password: null,
            } as CarryDispoSettings,
            getCountryCaption,
            getStateCaption,
            getStateLabel,
            loading: true,
            maxLength,
            notEmpty,
            Permission,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        notEmptyIfOtherSettings(): Function {
            return (v: string | undefined | null) => {
                const hasSettings =
                    !!this.carryDispoSettings.baseUrl ||
                    !!this.carryDispoSettings.username ||
                    !!this.carryDispoSettings.password;

                return !hasSettings || !!v || this.$t("Der Endpunkt kann nicht partiell konfiguriert werden.");
            };
        },
    },

    methods: {
        async addCarryDispoLocation(carryDispoLocationForm: CarryDispoLocationForm) {
            try {
                await carryDispoSettingsApi.addCarryDispoLocation(this.dealerId!, carryDispoLocationForm);
                return true;
            } catch (e) {
                return false;
            }
        },

        async deleteCarryDispoLocation(id: string) {
            if (
                await showConfirm(
                    this.$t("Adresse löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Adresse löschen möchten?") as string
                )
            ) {
                await carryDispoSettingsApi.deleteCarryDispoLocation(id);
                return true;
            } else {
                return false;
            }
        },

        async editCarryDispoLocation(id: string, carryDispoLocationForm: CarryDispoLocationForm) {
            try {
                await carryDispoSettingsApi.editCarryDispoLocation(id, carryDispoLocationForm);
                return true;
            } catch (e) {
                return false;
            }
        },

        emptyCarryDispoLocationForm(): CarryDispoLocationForm {
            return {
                name: "",
                description: null,
                street: null,
                houseNumber: null,
                city: null,
                country: null,
                state: null,
                zip: null,
            };
        },

        async getCarryDispoLocations(dealerId: string) {
            return (await carryDispoSettingsApi.getCarryDispoLocationsByDealer(dealerId)).sort((a, b) =>
                a.name.localeCompare(b.name, userSession.locale)
            );
        },

        async loadSettings() {
            if (!this.dealerId) {
                return;
            }

            this.loading = true;
            try {
                this.carryDispoSettings = await carryDispoSettingsApi.getCarryDispoSettingsByDealer(this.dealerId);
            } finally {
                this.loading = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.loading = true;
            try {
                await carryDispoSettingsApi.editCarryDispoSettings(this.dealerId!, this.carryDispoSettings);
            } finally {
                this.loading = false;
            }
        },

        toCarryDispoLocationForm(carryDispoLocation: CarryDispoLocation): CarryDispoLocationForm {
            return cloneObject(carryDispoLocation);
        },
    },

    watch: {
        carryDispoSettings: {
            deep: true,
            handler() {
                (this.$refs.form as any).validate();
            },
        },

        async dealerId() {
            try {
                await this.loadSettings();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadSettings();
    },

    components: {
        CountryPicker,
        CrudPage,
        DealerContextGuard,
        StatePicker,
    },
});
