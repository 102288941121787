
import OutgoingEmailAssistant from "@/app/pages/outgoingemails/assistant/OutgoingEmailAssistant.vue";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        videochatId: {
            type: String,
            required: true,
        },
    },

    computed: {
        videochatLink(): string {
            return `https://${configStore.configuration.appHost}/v/${this.videochatId}`;
        },

        videochatLinkText(): string {
            return `<p>${this.$t("Bitte klicken Sie auf folgenden Link, um dem Videochat beizutreten:")} <a href="${
                this.videochatLink
            }">${this.videochatLink}</a></p>`;

            // TODO displayName ?
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },
    },

    components: {
        OutgoingEmailAssistant,
    },
});
