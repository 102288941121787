
import { Case, CaseResult, CaseUser } from "@/api/cases";
import DLink from "@/app/components/DLink.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import UserLink from "@/app/pages/UserLink.vue";
import { getCaseChannelColor, getCaseChannelIcon, getCloser, getCreator } from "@/app/pages/cases/caseUtils";
import { getSentimentIcon, getSentimentIconColor, getSentimentText } from "@/app/sentimentUtils";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseResult: {
            type: Object as () => CaseResult,
            required: true,
        },
        caseUser: {
            type: Object as () => CaseUser,
            required: false,
        },
        compact: {
            type: Boolean,
            required: true,
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            required: true,
        },
        showSubject: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        caseObj(): Case {
            return this.caseResult.caseObj;
        },

        channelColor(): string | null {
            return getCaseChannelColor(this.caseObj.channel);
        },

        channelIcon(): string | null {
            return getCaseChannelIcon(this.caseObj.channel);
        },

        closedTimestamp(): string | null {
            if (!this.caseObj.closed) {
                return null;
            }

            return formatInstant(this.caseObj.closed, userSession.timeZone, userSession.locale, "S");
        },

        closer(): string {
            return getCloser(this.caseObj);
        },

        creationTimestamp(): string {
            return formatInstant(this.caseObj.created, userSession.timeZone, userSession.locale, "S");
        },

        creator(): string {
            return getCreator(this.caseObj);
        },

        dealerName(): string | null {
            return dealersStore.dealerById(this.caseObj.dealerId)?.name || null;
        },

        lastActivityWithTypeTimestamp(): string | null {
            if (!this.caseObj.lastActivityWithType) {
                return null;
            }

            return formatInstant(this.caseObj.lastActivityWithType, userSession.timeZone, userSession.locale, "S");
        },

        postponedUntilTimestamp(): string | null {
            if (!this.caseObj.postponedUntil) {
                return null;
            }

            return formatInstant(this.caseObj.postponedUntil, userSession.timeZone, userSession.locale, "S");
        },

        sentimentIcon(): string {
            return getSentimentIcon(this.caseObj.sentiment);
        },

        sentimentIconColor(): string | null {
            return getSentimentIconColor(this.caseObj.sentiment);
        },

        sentimentText(): string {
            return getSentimentText(this.caseObj.sentiment);
        },

        visibilityBegin(): Date | null {
            return this.caseUser?.visibilityBegin ?? null;
        },

        visibilityBeginTimestamp(): string | null {
            if (!this.visibilityBegin) {
                return null;
            }

            return formatInstant(this.visibilityBegin, userSession.timeZone, userSession.locale, "S");
        },

        visibilityEnd(): Date | null {
            return this.caseUser?.visibilityEnd ?? null;
        },
    },

    components: {
        DLink,
        IconWithTooltip,
        TimeSpan,
        UserLink,
    },
});
