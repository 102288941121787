
import { Dealer, ExternalBdcTarget } from "@/api/dealers";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerId: {
            type: String,
            required: true,
        },
    },

    computed: {
        dealer(): Dealer | null {
            return dealersStore.dealerById(this.dealerId);
        },

        items(): ExternalBdcTarget[] {
            if (!this.dealer) {
                return [];
            }

            return [...this.dealer.externalBdcTargets].sort((a, b) =>
                a.number.localeCompare(b.number, userSession.locale)
            );
        },
    },
});
