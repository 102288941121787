
import { citnowVideoSettingsApi } from "@/api/citnowVideoSettings";
import { Contact } from "@/api/contacts";
import { InventoryVehicle } from "@/api/inventory";
import { OutgoingCitnowVideoCreationForm } from "@/api/outgoingCitnowVideos";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { renderFullName } from "@/app/contactUtils";
import { EditModeMixin } from "@/app/editModeUtils";
import { showInfo } from "@/app/messageUtil";
import { e164, email, maxLength, notEmpty } from "@/app/validation";
import { isMobile } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        defaultCountry: {
            type: String,
            required: true,
        },
        inventoryVehicles: {
            type: Array as () => InventoryVehicle[],
            required: true,
        },
        mostRelevantEmailAddress: {
            type: String as () => string | null,
            required: false,
        },
        mostRelevantPhoneNumber: {
            type: String as () => string | null,
            required: false,
        },
        opportunityDealerId: {
            type: String,
            required: true,
        },
        working: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            lids: [] as string[],
            loadingLids: true,
            dealerId: this.opportunityDealerId,
            lid: null as string | null,
            vehicleIdentifier: "",
            contactName: this.contact ? renderFullName(this.contact.contactData) : "",
            contactPhoneNumber: "",
            contactEmailAddress: this.mostRelevantEmailAddress || "",
            additionalInfo: "",
            maxLength,
            notEmpty,
        };
    },

    computed: {
        contactEmailAddressRules(): any {
            return this.contactPhoneNumber.trim() ? email() : notEmpty().email();
        },

        contactPhoneNumberRules(): any {
            return this.contactEmailAddress.trim() ? e164() : notEmpty().e164();
        },

        vins(): string[] {
            return this.inventoryVehicles.map((v) => v.data.vin!).filter((v) => !!v);
        },
    },

    methods: {
        submit() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            const form: OutgoingCitnowVideoCreationForm = {
                vehicleIdentifier: this.vehicleIdentifier,
                contactName: this.contactName,
                contactEmailAddress: this.contactEmailAddress,
                contactPhoneNumber: this.contactPhoneNumber,
                additionalInfo: this.additionalInfo,
            };

            this.$emit("submit", this.dealerId, this.lid, form);
        },
    },

    async mounted() {
        try {
            this.lids = (await citnowVideoSettingsApi.getLocationSettings(this.dealerId)).map((s) => s.lid);
        } finally {
            this.loadingLids = false;
        }

        if (!this.lids.length) {
            showInfo(
                this.$t(
                    "CitNOW ist für den aktuellen Standort nicht konfiguriert. Bitte wenden Sie sich an unseren Support."
                ) as string
            );
            this.$emit("close");
            return;
        }

        if (this.lids.length === 1) {
            this.lid = this.lids[0];
        }

        this.vehicleIdentifier = this.vins.length ? this.vins[0] : "";

        if (this.mostRelevantPhoneNumber) {
            if (isMobile(this.mostRelevantPhoneNumber)) {
                this.contactPhoneNumber = this.mostRelevantPhoneNumber;
            } else if (this.contact) {
                const mobileNumbers = this.contact.contactData.numbers.map((n) => n.number).filter((n) => isMobile(n));
                this.contactPhoneNumber = mobileNumbers.length ? mobileNumbers[0] : "";
            }
        }
    },

    mixins: [EditModeMixin],

    components: {
        PhoneNumberField,
    },
});
