
import {
    opportunitiesApi,
    Opportunity,
    OpportunityChannel,
    OpportunityGeneralInfoForm,
    OpportunityUrgency,
} from "@/api/opportunities";
import DatePicker from "@/app/components/DatePicker.vue";
import DateTimePicker from "@/app/components/DateTimePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { dealerOptions } from "@/app/dealerUtils";
import ActionCard from "@/app/pages/ActionCard.vue";
import { getSentimentText, SENTIMENTS } from "@/app/sentimentUtils";
import { dealersStore } from "@/store/dealers";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
    },

    data() {
        return {
            generalInfoFormData: {
                dealerId: this.opportunityProp.dealerId,
                channel: this.opportunityProp.channel,
                sourceId: this.opportunityProp.sourceId,
                sentiment: this.opportunityProp.sentiment,
                urgency: this.opportunityProp.urgency,
                appointmentTime: this.opportunityProp.appointmentTime,
                subject: this.opportunityProp.subject,
                nextStep: this.opportunityProp.nextStep,
                projectedCloseDate: this.opportunityProp.projectedCloseDate,
            } as OpportunityGeneralInfoForm,
            OpportunityChannel,
            OpportunityUrgency,
            working: false,
        };
    },

    computed: {
        dealerOptions() {
            return dealerOptions();
        },

        sentimentOptions(): SelectOptions {
            return SENTIMENTS.map((s) => ({
                value: s,
                text: getSentimentText(s),
            }));
        },

        sourceOptions() {
            return opportunitySourcesStore.opportunitySources.map((s) => ({ value: s.id, text: s.name }));
        },

        timeZone(): string {
            return dealersStore.dealerById(this.opportunityProp.dealerId)!.timeZone;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async save() {
            this.working = true;
            try {
                await opportunitiesApi.updateGeneralInfo(this.opportunityProp.id, this.generalInfoFormData);
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        EnumField,
        DatePicker,
        DateTimePicker,
    },
});
