
import { Permission } from "@/api/userSession";
import { VideochatGroup, videochatGroupApi, VideochatGroupForm } from "@/api/videochatGroups";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import { showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { getFullName } from "@/app/userUtils";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            nameRules: notEmpty().maxLength(300),
        };
    },

    computed: {
        api() {
            return videochatGroupApi;
        },

        userOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        async getByDealer(dealerId: string) {
            return await videochatGroupApi.getByDealer(dealerId);
        },

        async add(dealerId: string, form: VideochatGroupForm) {
            try {
                await videochatGroupApi.add(dealerId, form);
            } catch (e) {
                return false;
            }
        },

        async deleteGroup(item: VideochatGroup) {
            if (
                await showConfirm(
                    this.$t("Videochat-Gruppe löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Videochat-Gruppe löschen möchten?") as string
                )
            ) {
                await videochatGroupApi.delete(item.id);
                return true;
            }
            return false;
        },

        toForm(item: VideochatGroup): VideochatGroupForm {
            return cloneObject(item);
        },

        emptyForm(): VideochatGroupForm {
            return {
                name: "",
                preferForIntenvoryVehicles: false,
                userIds: [],
            };
        },

        getUserFullNameById(userId: string) {
            return (getFullName(usersStore.getUserById(userId)) || this.$t("Unbekannt")).toString();
        },
    },

    components: {
        CopyToClipboardIcon,
        CrudPage,
        DAutocomplete,
        DealerContextGuard,
    },
});
