import { ContactType } from "./contacts";
import axios from "axios";

export enum EscalationGroupVisibility {
    DEALER = "DEALER",
    SELECTED_DEALERS = "SELECTED_DEALERS",
    DEALERGROUP = "DEALERGROUP",
}

export interface EscalationGroupLevel {
    readonly escalationTimeMinutes: number;
    readonly members: string[];
}

export interface EscalationGroup {
    readonly id: string;
    readonly dealerId: string;
    readonly officeHoursId: string | null;
    readonly visibility: EscalationGroupVisibility;
    readonly visibleForDealerIds: string[];
    readonly visibleForAgents: boolean;
    readonly name: string;
    readonly caseTypeSuggestions: string[];
    readonly escalationGroupLevels: EscalationGroupLevel[];
    readonly contactTypes: ContactType[];
    readonly makes: string[];
    readonly vehicleClasses: string[];
}

export interface EscalationGroupSummary {
    readonly id: string;
    readonly dealerId: string;
    readonly name: string;
    readonly visibility: EscalationGroupVisibility;
    readonly visibleForDealerIds: string[];
    readonly visibleForAgents: boolean;
    readonly caseTypeSuggestions: string[];
    readonly contactTypes: ContactType[];
    readonly makes: string[];
    readonly vehicleClasses: string[];
}

export interface EscalationGroupForm {
    readonly officeHoursId: string | null;
    readonly visibility: EscalationGroupVisibility;
    readonly visibleForDealerIds: string[];
    readonly visibleForAgents: boolean;
    readonly name: string;
    readonly caseTypeSuggestions: string[];
    readonly escalationGroupLevels: EscalationGroupLevel[];
    readonly contactTypes: ContactType[];
    readonly makes: string[];
    readonly vehicleClasses: string[];
}

export interface EscalationGroupUsage {
    readonly catchAllSms: boolean;
    readonly catchAllUserEmails: boolean;
    readonly incomingPhoneNumberIds: string[];
    readonly individualEscalationUserIds: string[];
    readonly systemEmailAliasIds: string[];
    readonly whatsAppAccountIds: string[];
}

interface EscalationGroupsApi {
    getAll(): Promise<EscalationGroupSummary[]>;
    getByDealer(dealerId: string): Promise<EscalationGroup[]>;
    getById(id: string): Promise<EscalationGroup>;
    add(dealerId: string, escalationGroupForm: EscalationGroupForm): Promise<void>;
    edit(id: string, escalationGroupForm: EscalationGroupForm): Promise<void>;
    delete(id: string): Promise<void>;
    getUsage(id: string): Promise<EscalationGroupUsage>;
    updateOrder(dealerId: string, ids: string[]): Promise<void>;
}

export const escalationGroupsApi: EscalationGroupsApi = {
    async getAll() {
        return (await axios.get(`/api/escalation-groups`)).data;
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/escalation-groups/${dealerId}`)).data;
    },

    async getById(id) {
        return (await axios.get(`/api/escalation-groups/_/${id}`)).data;
    },

    async getUsage(id) {
        return (await axios.get(`/api/escalation-groups/_/${id}/_usage`)).data;
    },

    async add(dealerId, escalationGroupForm) {
        await axios.post(`/api/escalation-groups/${dealerId}`, escalationGroupForm);
    },

    async edit(id, escalationGroupForm) {
        await axios.put(`/api/escalation-groups/_/${id}`, escalationGroupForm);
    },

    async delete(id) {
        await axios.delete(`/api/escalation-groups/_/${id}`);
    },

    async updateOrder(dealerId, ids) {
        await axios.post(`/api/escalation-groups/${dealerId}/_update-order`, ids);
    },
};
