
import {
    FtpAccountView,
    inventoryImportApi,
    InventoryImportSource,
    InventoryImportSourceForm,
    InventoryImportSourceType,
} from "@/api/inventoryImport";
import { notEmpty } from "@/app/validation";
import { SelectOptions } from "@/util/types";
import { generateRandomUUID } from "@/util/uuidUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerId: {
            type: String,
            required: true,
        },
        inventoryImportSource: {
            type: Object as () => InventoryImportSource | null,
            required: false,
        },
        ftpAccounts: {
            type: Array as () => FtpAccountView[],
            required: true,
        },
    },

    data() {
        return {
            isWorking: false,
            InventoryImportSourceType,

            // FORM FIELDS
            type: this.inventoryImportSource?.type || InventoryImportSourceType.MOBILE_DE,
            credentials1: this.inventoryImportSource?.credentials1 || "",
            credentials2: this.inventoryImportSource?.credentials2 || "",
            dealerCode: this.inventoryImportSource?.dealerCode || "",

            // RULES
            credentialsRules: notEmpty().maxLength(300),
        };
    },

    computed: {
        inventoryImportSourceTypes(): SelectOptions {
            return [
                ...Object.keys(InventoryImportSourceType).map((k) => ({
                    value: k,
                    text: this.$t(`enum.InventoryImportSourceType.${k}`),
                })),
            ];
        },

        ftpAccountsOptions(): SelectOptions {
            return [
                { value: "", text: this.$t("keine Angabe") },
                ...(this.ftpAccounts as FtpAccountView[]).map((ftpAccount) => ({
                    value: ftpAccount.id,
                    text: ftpAccount.label,
                })),
            ];
        },
    },

    watch: {
        type(val: InventoryImportSourceType) {
            this.credentials1 = "";
            this.credentials2 = "";
            this.dealerCode = "";
            if (val === InventoryImportSourceType.FTP) {
                this.credentials2 = `${generateRandomUUID()}.zip`;
            }
            (this.$refs.form as any).resetValidation();
        },
    },

    methods: {
        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            const form: InventoryImportSourceForm = {
                type: this.type,
                credentials1: this.credentials1,
                credentials2: this.credentials2,
                dealerCode: this.dealerCode,
            };

            try {
                if (this.inventoryImportSource) {
                    await inventoryImportApi.edit(this.inventoryImportSource.id, form);
                } else {
                    await inventoryImportApi.add(this.dealerId, form);
                }
            } finally {
                this.isWorking = false;
            }
            this.$emit("success");
        },

        close() {
            this.$emit("close");
        },
    },
});
