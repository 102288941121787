import { ContactAndVehicleId } from "@/app/contactUtils";
import { EMPTY_SEARCH_REQUEST } from "@/app/pages/contacts/contactSearchUtils";
import { OutgoingEmailFormCardState } from "@/app/pages/outgoingemails/outgoingEmailFormCard";
import { cloneObject } from "@/util/cloneUtils";
import { reactive } from "@/util/reactive";

@reactive
export class OutgoingEmailAssistantState {
    public contactAndVehicleId: ContactAndVehicleId | null = null;
    public contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
    public outgoingEmailFormCardState = new OutgoingEmailFormCardState();

    reset() {
        this.contactAndVehicleId = null;
        this.contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
        this.outgoingEmailFormCardState.reset();
    }
}

export const outgoingEmailAssistantState = new OutgoingEmailAssistantState();
