
import ContactSearchResultPreviewCard from "./ContactSearchResultPreviewCard.vue";
import { ContactSearchResultAndIssueSummaries, SearchResult } from "@/app/pages/contacts/contactSearchUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canSelectContactVehicle: {
            type: Boolean,
            default: false,
        },
        canSelectEmailAddress: {
            type: Boolean,
            default: false,
        },
        canSelectPhoneNumber: {
            type: Boolean,
            default: false,
        },
        searching: {
            type: Boolean,
            default: false,
        },
        searchResult: {
            type: Object as () => SearchResult | null,
            required: false,
        },
    },

    computed: {
        isEmptySearchRequest(): boolean {
            return this.searchResult?.isSearchRequestEmpty ?? true;
        },

        items(): ContactSearchResultAndIssueSummaries[] {
            return this.searchResult?.items ?? [];
        },

        itemsLimit(): number {
            return this.searchResult?.itemsLimit ?? 0;
        },

        totalSize(): number {
            return this.searchResult?.totalSize ?? 0;
        },
    },

    components: {
        ContactSearchResultPreviewCard,
    },
});
