import { ContactSearchRequest, ContactSearchResult } from "@/api/contactSearch";

export const EMPTY_SEARCH_REQUEST: ContactSearchRequest = {
    dealerIds: [],
    contactSources: [],
    fullName: null,
    companyName: null,
    number: null,
    emailAddress: null,
    licensePlate: null,
    vin: null,
    address: null,
    externalReference: null,
    search: null,
    updatedFrom: null,
    updatedTo: null,
};

export enum ContactSearchResultIssueType {
    CASE,
    OPPORTUNITY,
}

export interface ContactSearchResultIssueSummary {
    readonly issueType: ContactSearchResultIssueType;
    readonly id: string;
    readonly contactId: string;
    readonly contactVehicleId: string | null;
    readonly lastActivity: Date | null;
}

export interface ContactSearchResultAndIssueSummaries {
    readonly contactSearchResult: ContactSearchResult;
    readonly issueSummaries: ContactSearchResultIssueSummary[];
}

export interface SearchResult {
    readonly isSearchRequestEmpty: boolean;
    readonly items: ContactSearchResultAndIssueSummaries[];
    readonly itemsLimit: number;
    readonly totalSize: number;
}
