import { AwayForm, EmailAlias, Gender, PhoneNumber } from "./users";
import axios from "axios";

export enum Permission {
    CT_IS_AGENT = "CT_IS_AGENT",
    CT_IS_USER = "CT_IS_USER",
    CT_VIEW_DEALER_DIRECTORY = "CT_VIEW_DEALER_DIRECTORY",
    ABSENCE_MANAGEMENT = "ABSENCE_MANAGEMENT",
    CHANGE_OWN_ABSENCES = "CHANGE_OWN_ABSENCES",
    CHANGE_OWN_NOTIFICATIONS = "CHANGE_OWN_NOTIFICATIONS",
    CHANGE_OWN_PASSWORD = "CHANGE_OWN_PASSWORD",
    CHANGE_OWN_STATUS = "CHANGE_OWN_STATUS",
    CHANGE_OWN_USER_PROFILE = "CHANGE_OWN_USER_PROFILE",
    CHANGE_OWN_WORKING_HOURS = "CHANGE_OWN_WORKING_HOURS",
    CONFIGURE_DEALER_INCOMING_PHONE_NUMBERS = "CONFIGURE_DEALER_INCOMING_PHONE_NUMBERS",
    CONFIGURE_INCOMING_PHONE_NUMBERS = "CONFIGURE_INCOMING_PHONE_NUMBERS",
    CREATE_CASE = "CREATE_CASE",
    CREATE_OPPORTUNITY = "CREATE_OPPORTUNITY",
    DEALER_MANAGEMENT = "DEALER_MANAGEMENT",
    DEALER_STUB_MANAGEMENT = "DEALER_STUB_MANAGEMENT",
    DELETE_CASE = "DELETE_CASE",
    DELETE_CONTACT_UPLOADS = "DELETE_CONTACT_UPLOADS",
    DELETE_EMAIL_DOMAIN = "DELETE_EMAIL_DOMAIN",
    DELETE_OPPORTUNITY = "DELETE_OPPORTUNITY",
    HOUSEKEEP_STORAGE = "HOUSEKEEP_STORAGE",
    INITIALIZE_PARTITION = "INITIALIZE_PARTITION",
    LOGIN_AS_USER = "LOGIN_AS_USER",
    MANAGE_ALL_CASES = "MANAGE_ALL_CASES",
    MANAGE_ALL_INVENTORY_VEHICLE_INQUIRIES = "MANAGE_ALL_INVENTORY_VEHICLE_INQUIRIES",
    MANAGE_ALL_OPPORTUNITIES = "MANAGE_ALL_OPPORTUNITIES",
    MANAGE_ANNOUNCEMENTS = "MANAGE_ANNOUNCEMENTS",
    MANAGE_AUTO_CRM_LEAD_MAILER_SETTINGS = "MANAGE_AUTO_CRM_LEAD_MAILER_SETTINGS",
    MANAGE_BILLING = "MANAGE_BILLING",
    MANAGE_CALLER_IDS = "MANAGE_CALLER_IDS",
    MANAGE_CALL_SETTINGS = "MANAGE_CALL_SETTINGS",
    MANAGE_CARRY_DISPO_ORDERS = "MANAGE_CARRY_DISPO_ORDERS",
    MANAGE_CARRY_DISPO_SETTINGS = "MANAGE_CARRY_DISPO_SETTINGS",
    MANAGE_CASE_NOTIFICATION_SETTINGS = "MANAGE_CASE_NOTIFICATION_SETTINGS",
    MANAGE_CASE_PRIORITY_SETTINGS = "MANAGE_CASE_PRIORITY_SETTINGS",
    MANAGE_CASE_SETTINGS = "MANAGE_CASE_SETTINGS",
    MANAGE_CITNOW_VIDEO_SETTINGS = "MANAGE_CITNOW_VIDEO_SETTINGS",
    MANAGE_CONTACT_UPLOADS = "MANAGE_CONTACT_UPLOADS",
    MANAGE_DEALER_ABSENCES = "MANAGE_DEALER_ABSENCES",
    MANAGE_DEALER_ANNOUNCEMENTS = "MANAGE_DEALER_ANNOUNCEMENTS",
    MANAGE_DEALER_CASE_NOTIFICATION_SETTINGS = "MANAGE_DEALER_CASE_NOTIFICATION_SETTINGS",
    MANAGE_DEALER_CASE_PRIORITY_SETTINGS = "MANAGE_DEALER_CASE_PRIORITY_SETTINGS",
    MANAGE_DEALER_CASE_SETTINGS = "MANAGE_DEALER_CASE_SETTINGS",
    MANAGE_DEALER_EMAIL_SIGNATURE_TEMPLATE = "MANAGE_DEALER_EMAIL_SIGNATURE_TEMPLATE",
    MANAGE_DEALER_EMERGENCY_INCIDENT_NOTIFICATION_SETTINGS = "MANAGE_DEALER_EMERGENCY_INCIDENT_NOTIFICATION_SETTINGS",
    MANAGE_DEALER_ESCALATION = "MANAGE_DEALER_ESCALATION",
    MANAGE_DEALER_FINANCE_CONTRACTS = "MANAGE_DEALER_FINANCE_CONTRACTS",
    MANAGE_DEALER_INSTRUCTIONS = "MANAGE_DEALER_INSTRUCTIONS",
    MANAGE_DEALER_INVENTORY_IMPORT = "MANAGE_DEALER_INVENTORY_IMPORT",
    MANAGE_DEALER_OFFICE_HOURS = "MANAGE_DEALER_OFFICE_HOURS",
    MANAGE_DEALER_OPPORTUNITY_ESCALATION = "MANAGE_DEALER_OPPORTUNITY_ESCALATION",
    MANAGE_DEALER_OPPORTUNITY_NOTIFICATION_SETTINGS = "MANAGE_DEALER_OPPORTUNITY_NOTIFICATION_SETTINGS",
    MANAGE_DEALER_OPPORTUNITY_PRIORITY_SETTINGS = "MANAGE_DEALER_OPPORTUNITY_PRIORITY_SETTINGS",
    MANAGE_DEALER_OPPORTUNITY_SETTINGS = "MANAGE_DEALER_OPPORTUNITY_SETTINGS",
    MANAGE_DEALER_OPPORTUNITY_TEAM_SETTINGS = "MANAGE_DEALER_OPPORTUNITY_TEAM_SETTINGS",
    MANAGE_DEALER_PARTNERS = "MANAGE_DEALER_PARTNERS",
    MANAGE_DEALER_REPORTING_SETTINGS = "MANAGE_DEALER_REPORTING_SETTINGS",
    MANAGE_DEALER_SERVICES_OFFERED = "MANAGE_DEALER_SERVICES_OFFERED",
    MANAGE_DEALER_SMS_SIGNATURE_TEMPLATE = "MANAGE_DEALER_SMS_SIGNATURE_TEMPLATE",
    MANAGE_DEALER_SYSTEM_EMAIL_ALIASES = "MANAGE_DEALER_SYSTEM_EMAIL_ALIASES",
    MANAGE_DEALER_USERS = "MANAGE_DEALER_USERS",
    MANAGE_DEALER_VIDEOCHAT_GROUP_SETTINGS = "MANAGE_DEALER_VIDEOCHAT_GROUP_SETTINGS",
    MANAGE_DEALER_WHATSAPP_SETTINGS = "MANAGE_DEALER_WHATSAPP_SETTINGS",
    MANAGE_DEALER_WORKING_HOURS = "MANAGE_DEALER_WORKING_HOURS",
    MANAGE_EMAIL_DOMAINS = "MANAGE_EMAIL_DOMAINS",
    MANAGE_EMAIL_SIGNATURE_TEMPLATE = "MANAGE_EMAIL_SIGNATURE_TEMPLATE",
    MANAGE_EMAIL_TEMPLATES = "MANAGE_EMAIL_TEMPLATES",
    MANAGE_EMERGENCY_INCIDENTS = "MANAGE_EMERGENCY_INCIDENTS",
    MANAGE_EMERGENCY_INCIDENT_NOTIFICATION_SETTINGS = "MANAGE_EMERGENCY_INCIDENT_NOTIFICATION_SETTINGS",
    MANAGE_ESCALATION = "MANAGE_ESCALATION",
    MANAGE_EXTERNAL_INVENTORY_MANAGEMENT_SETTINGS = "MANAGE_EXTERNAL_INVENTORY_MANAGEMENT_SETTINGS",
    MANAGE_FILE_SETTINGS = "MANAGE_FILE_SETTINGS",
    MANAGE_FINANCE_CONTRACTS = "MANAGE_FINANCE_CONTRACTS",
    MANAGE_FINANCE_CONTRACT_SETTINGS = "MANAGE_FINANCE_CONTRACT_SETTINGS",
    MANAGE_FINANCE_CONTRACT_UPLOADS = "MANAGE_FINANCE_CONTRACT_UPLOADS",
    MANAGE_FRONTEND_SETTINGS = "MANAGE_FRONTEND_SETTINGS",
    MANAGE_INCOMING_CALLS = "MANAGE_INCOMING_CALLS",
    MANAGE_INCOMING_EMAILS = "MANAGE_INCOMING_EMAILS",
    MANAGE_INCOMING_EMAIL_SETTINGS = "MANAGE_INCOMING_EMAIL_SETTINGS",
    MANAGE_INCOMING_PHONE_NUMBERS = "MANAGE_INCOMING_PHONE_NUMBERS",
    MANAGE_INCOMING_SMS = "MANAGE_INCOMING_SMS",
    MANAGE_INCOMING_WHATSAPP = "MANAGE_INCOMING_WHATSAPP",
    MANAGE_INVENTORY_IMPORT = "MANAGE_INVENTORY_IMPORT",
    MANAGE_IP_RESTRICTIONS = "MANAGE_IP_RESTRICTIONS",
    MANAGE_MAKE_MODEL_SETTINGS = "MANAGE_MAKE_MODEL_SETTINGS",
    MANAGE_NOTES = "MANAGE_NOTES",
    MANAGE_NOTE_TEMPLATES = "MANAGE_NOTE_TEMPLATES",
    MANAGE_NUMBERS_BLACKLIST = "MANAGE_NUMBERS_BLACKLIST",
    MANAGE_OFFICE_HOURS = "MANAGE_OFFICE_HOURS",
    MANAGE_OPPORTUNITY_NOTIFICATION_SETTINGS = "MANAGE_OPPORTUNITY_NOTIFICATION_SETTINGS",
    MANAGE_OPPORTUNITY_PRIORITY_SETTINGS = "MANAGE_OPPORTUNITY_PRIORITY_SETTINGS",
    MANAGE_OPPORTUNITY_SETTINGS = "MANAGE_OPPORTUNITY_SETTINGS",
    MANAGE_OPPORTUNITY_TEAM_SETTINGS = "MANAGE_OPPORTUNITY_TEAM_SETTINGS",
    MANAGE_OUTGOING_CALLS = "MANAGE_OUTGOING_CALLS",
    MANAGE_OUTGOING_EMAILS = "MANAGE_OUTGOING_EMAILS",
    MANAGE_OUTGOING_EMAIL_SETTINGS = "MANAGE_OUTGOING_EMAIL_SETTINGS",
    MANAGE_OUTGOING_SMS = "MANAGE_OUTGOING_SMS",
    MANAGE_OUTGOING_WHATSAPP = "MANAGE_OUTGOING_WHATSAPP",
    MANAGE_OWN_ACTIVITIES = "MANAGE_OWN_ACTIVITIES",
    MANAGE_OWN_CASES = "MANAGE_OWN_CASES",
    MANAGE_OWN_DEALER = "MANAGE_OWN_DEALER",
    MANAGE_OWN_INVENTORY_VEHICLE_INQUIRIES = "MANAGE_OWN_INVENTORY_VEHICLE_INQUIRIES",
    MANAGE_OWN_OPPORTUNITIES = "MANAGE_OWN_OPPORTUNITIES",
    MANAGE_OWN_VIDEOCHATS = "MANAGE_OWN_VIDEOCHATS",
    MANAGE_PARTNERS = "MANAGE_PARTNERS",
    MANAGE_PASSWORD_POLICY = "MANAGE_PASSWORD_POLICY",
    MANAGE_READ_RECEIPT_SETTINGS = "MANAGE_READ_RECEIPT_SETTINGS",
    MANAGE_RENAULT_RACE_ACCOUNTS = "MANAGE_RENAULT_RACE_ACCOUNTS",
    MANAGE_RENAULT_RACE_LEAD_RECEIVER_MAPPINGS = "MANAGE_RENAULT_RACE_LEAD_RECEIVER_MAPPINGS",
    MANAGE_RENAULT_RACE_LEAD_SOURCE_MAPPINGS = "MANAGE_RENAULT_RACE_LEAD_SOURCE_MAPPINGS",
    MANAGE_REPORTING_SETTINGS = "MANAGE_REPORTING_SETTINGS",
    MANAGE_SERVICES_OFFERED = "MANAGE_SERVICES_OFFERED",
    MANAGE_SERVICE_ACCOUNTS = "MANAGE_SERVICE_ACCOUNTS",
    MANAGE_SMS_SETTINGS = "MANAGE_SMS_SETTINGS",
    MANAGE_SMS_SIGNATURE_TEMPLATE = "MANAGE_SMS_SIGNATURE_TEMPLATE",
    MANAGE_SMS_TEMPLATES = "MANAGE_SMS_TEMPLATES",
    MANAGE_SYSTEM_EMAIL_ALIASES = "MANAGE_SYSTEM_EMAIL_ALIASES",
    MANAGE_TWO_FACTOR_AUTHENTICATION_SETTINGS = "MANAGE_TWO_FACTOR_AUTHENTICATION_SETTINGS",
    MANAGE_VIDEOCHAT_GROUP_SETTINGS = "MANAGE_VIDEOCHAT_GROUP_SETTINGS",
    MANAGE_VIDEOCHAT_SETTINGS = "MANAGE_VIDEOCHAT_SETTINGS",
    MANAGE_WHATSAPP_SETTINGS = "MANAGE_WHATSAPP_SETTINGS",
    MIGRATE_DATA = "MIGRATE_DATA",
    REBUILD_INDEXES = "REBUILD_INDEXES",
    REMOVE_INVENTORY_VEHICLE_DUPLICATES = "REMOVE_INVENTORY_VEHICLE_DUPLICATES",
    SEND_WELCOME_EMAIL = "SEND_WELCOME_EMAIL",
    SUBMIT_AUTO_CRM_LEAD_MAIL = "SUBMIT_AUTO_CRM_LEAD_MAIL",
    TEST_FEATURE_A = "TEST_FEATURE_A",
    TEST_FEATURE_B = "TEST_FEATURE_B",
    USER_MANAGEMENT = "USER_MANAGEMENT",
    VIEW_ALL_CASES = "VIEW_ALL_CASES",
    VIEW_ALL_CASES_ACTIVITY_CONTENT = "VIEW_ALL_CASES_ACTIVITY_CONTENT",
    VIEW_ALL_EXTERNAL_BDC_REPORTS = "VIEW_ALL_EXTERNAL_BDC_REPORTS",
    VIEW_ALL_INVENTORY_VEHICLE_INQUIRIES = "VIEW_ALL_INVENTORY_VEHICLE_INQUIRIES",
    VIEW_ALL_OPPORTUNITIES = "VIEW_ALL_OPPORTUNITIES",
    VIEW_ALL_OPPORTUNITIES_ACTIVITY_CONTENT = "VIEW_ALL_OPPORTUNITIES_ACTIVITY_CONTENT",
    VIEW_ALL_REPORTS = "VIEW_ALL_REPORTS",
    VIEW_CONTACTS = "VIEW_CONTACTS",
    VIEW_DEALER_EXTERNAL_BDC_REPORTS = "VIEW_DEALER_EXTERNAL_BDC_REPORTS",
    VIEW_DEALER_REPORTS = "VIEW_DEALER_REPORTS",
    VIEW_LOCATION_DETAILS = "VIEW_LOCATION_DETAILS",
    VIEW_PRIVATE_PHONE_NUMBERS = "VIEW_PRIVATE_PHONE_NUMBERS",
    VIEW_SUPPORT_PAGE = "VIEW_SUPPORT_PAGE",
    VIEW_USER_DIRECTORY = "VIEW_USER_DIRECTORY",
    VIEW_VEHICLE_INVENTORY = "VIEW_VEHICLE_INVENTORY",
    WORKING_HOURS_MANAGEMENT = "WORKING_HOURS_MANAGEMENT",
}

export interface UserProfile {
    readonly id: string;
    readonly username: string;
    readonly permissions: Permission[];
    readonly timeZone: string;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly emailSignatureHash: string | null;
    readonly smsSignature: string | null;
    readonly languages: string[];
    readonly dateOfBirth: string | null;
    readonly darkmode: boolean;
    readonly compactMode: boolean;
    readonly broadcastEmailsEnabled: boolean;
    readonly volume: number;
    readonly mainDealerId: string | null;
    readonly dealerIds: string[];
    readonly phoneNumbers: PhoneNumber[];
    readonly profileImageHash: string | null;
    readonly defaultCallerId: string | null;
    readonly terminalPhoneNumber: string | null;
    readonly realId: string | null;
    readonly realUsername: string | null;
    readonly csrfToken: string;
    readonly emailAliases: EmailAlias[];
    readonly defaultSubstituteUserId: string | null;
}

export interface UserProfileForm {
    timeZone: string;
    locale: string;
    darkmode: boolean;
    compactMode: boolean;
    broadcastEmailsEnabled: boolean;
    volume: number;
    dateOfBirth: string | null;
    gender: Gender | null;
    namePrefix: string | null;
    givenName: string;
    familyName: string;
    emailSignature: string | null;
    smsSignature: string | null;
    languages: string[];
    phoneNumbers: PhoneNumber[];
    defaultCallerId: string | null;
    terminalPhoneNumber: string | null;
    defaultSubstituteUserId: string | null;
}

export interface PasswordForm {
    readonly oldPassword: string;
    readonly newPassword: string;
}

export interface SignaturePreviewRequest {
    readonly subject: string;
    readonly signature: string;
}

interface LoginForm {
    readonly username: string;
    readonly password: string;
    readonly permanent: boolean;
}

interface UserSessionApi {
    login(loginForm: LoginForm): Promise<UserProfile>;
    verifyToken(loginForm: LoginForm, token: string): Promise<UserProfile>;
    logout(): Promise<void>;
    currentUser(): Promise<UserProfile>;
    edit(
        userProfileForm: UserProfileForm,
        profileImage: File | null,
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<UserProfile>;
    changePassword(passwordForm: PasswordForm): Promise<UserProfile>;
    updateAway(awayForm: AwayForm | null): Promise<void>;
    updateAvailableForVideochat(availableForVideochat: boolean): Promise<void>;
    sendTestMail(signaturePreviewRequest: SignaturePreviewRequest): Promise<void>;
    resetLoginAttempts(username: string, challengeResponse: string): Promise<boolean>;
}

export const userSessionApi: UserSessionApi = {
    async login(loginForm) {
        return (await axios.post("/api/user/_login", loginForm)).data;
    },

    async verifyToken(loginForm, token) {
        return (await axios.post("/api/user/_verify", loginForm, { params: { token } })).data;
    },

    async logout() {
        await axios.post("/api/user/_logout");
    },

    async currentUser() {
        return (await axios.get("/api/user")).data;
    },

    async edit(userProfileForm, profileImageFile, onUploadProgress) {
        const formData = new FormData();

        if (profileImageFile) {
            formData.append("profileImageFile", profileImageFile);
        }

        formData.append("userProfileForm", new Blob([JSON.stringify(userProfileForm)], { type: "application/json" }));

        return (await axios.put("/api/user", formData, { onUploadProgress })).data;
    },

    async changePassword(passwordForm) {
        return (await axios.put("/api/user/password", passwordForm)).data;
    },

    async sendTestMail(signaturePreviewRequest) {
        await axios.post("/api/user/_test-mail", signaturePreviewRequest);
    },

    async updateAway(awayForm) {
        if (!awayForm) {
            await axios.put("/api/user/away");
        } else {
            await axios.put("/api/user/away", awayForm);
        }
    },

    async updateAvailableForVideochat(availableForVideochat) {
        await axios.put("/api/user/available-for-videochat", null, {
            params: {
                availableForVideochat,
            },
        });
    },

    async resetLoginAttempts(username, challengeResponse) {
        return (
            await axios.post("/api/user/_reset-login-attempts", undefined, {
                params: {
                    username,
                    challengeResponse,
                },
            })
        ).data;
    },
};
