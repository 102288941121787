
import { callSettingsApi } from "@/api/callSettings";
import { callerIdsApi } from "@/api/callerIds";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            callerIds: [] as string[],
            inboundCallerId: null as string | null,
            answerOnBridge: false,
            loading: true,
            saving: false,
        };
    },

    computed: {
        inboundCallerIdOptions(): SelectOption[] {
            return this.callerIds.map((callerId) => ({
                text: callerId,
                value: callerId,
            }));
        },
    },

    methods: {
        async updateCallSettings() {
            if (!(this.$refs.inboundcalleridform as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await callSettingsApi.editCallSettings({
                    inboundCallerId: this.inboundCallerId,
                    answerOnBridge: this.answerOnBridge,
                });
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        try {
            this.callerIds = (await callerIdsApi.getCallerIds()).map((cid) => cid.phoneNumber);

            const callSettings = await callSettingsApi.getCallSettings();
            this.inboundCallerId = callSettings.inboundCallerId;
            this.answerOnBridge = callSettings.answerOnBridge;
        } finally {
            this.loading = false;
        }
    },

    components: {
        DealerGlobalContextHint,
    },
});
