import { IncomingEmailSearchOrder, IncomingEmailSearchRequest } from "@/api/incomingEmails";

export const EMPTY_SEARCH_REQUEST: IncomingEmailSearchRequest = {
    issueTypes: [],
    processingStates: [],
    user: null,
    createdFrom: null,
    createdTo: null,
    originalRecipients: [],
    spamReported: null,
    sortBy: IncomingEmailSearchOrder.CREATED_DESC,
};
