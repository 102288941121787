
import {
    Opportunity,
    OpportunityResult,
    OpportunityUrgency,
    OpportunityUser,
    VehicleSummary,
} from "@/api/opportunities";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DLink from "@/app/components/DLink.vue";
import { getVehicleClassIcon, renderVehicleSummaryTitle } from "@/app/inventoryUtils";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import OpportunityTeamLink from "@/app/pages/OpportunityTeamLink.vue";
import UserLink from "@/app/pages/UserLink.vue";
import {
    getAssigneeIdsSortedByUserFullName,
    getSelectedReceiversCount,
    getStatusCaption,
    getStatusColor,
    getUrgencyColor,
    getUrgencyTextColor,
} from "@/app/pages/opportunities/opportunityUtils";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        compact: {
            type: Boolean,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            required: true,
        },
        opportunityResult: {
            type: Object as () => OpportunityResult,
            required: true,
        },
        opportunityUser: {
            type: Object as () => OpportunityUser,
            required: false,
        },
    },

    data() {
        return {
            allVehiclesVisible: false,
            getVehicleClassIcon,
            OpportunityUrgency,
            renderVehicleSummaryTitle,
        };
    },

    computed: {
        assigneeIdsSortedByUserFullName(): string[] {
            return getAssigneeIdsSortedByUserFullName(this.opportunityObj);
        },

        opportunityObj(): Opportunity {
            return this.opportunityResult.opportunity;
        },

        opportunityStatusCaption(): string {
            return getStatusCaption(this.opportunityObj);
        },

        opportunityStatusColor(): string | null {
            return getStatusColor(this.opportunityObj);
        },

        selectedReceiversCount(): number {
            return getSelectedReceiversCount(this.opportunityObj);
        },

        unreadActivities(): number | null {
            if (!this.opportunityUser) {
                return null;
            }

            return this.opportunityObj.activitiesCount - this.opportunityUser.activitiesRead;
        },

        vehicleSummaries(): VehicleSummary[] {
            return this.opportunityResult.vehicleSummaries;
        },

        urgencyColor(): string | null {
            return getUrgencyColor(this.opportunityObj);
        },

        urgencyTextColor(): string | null {
            return getUrgencyTextColor(this.opportunityObj);
        },
    },

    methods: {
        isCurrentUser(userId: string): boolean {
            return userId === userSession.userId;
        },
    },

    components: {
        CopyToClipboardIcon,
        DLink,
        IconWithTooltip,
        OpportunityTeamLink,
        UserLink,
    },
});
