import axios from "axios";

export interface Invoice {
    readonly id: number;
    readonly billingAccountId: number;
    readonly created: string;
    readonly periodBegin: string;
    readonly periodEnd: string;
    readonly netAmount: number;
    readonly grossAmount: number;
    readonly paid: boolean;
    readonly reverseInvoiceId: number | null;
}

interface InvoicesApi {
    getInvoices(dealerId: string, month: string): Promise<Invoice[]>;
    generateInvoicePdfLink(id: number, dealerId: string): string;
}

export const invoicesApi: InvoicesApi = {
    async getInvoices(dealerId, month) {
        return (
            await axios.get(`/api/invoices/${dealerId}`, {
                params: {
                    month,
                },
            })
        ).data;
    },

    generateInvoicePdfLink(id, dealerId) {
        return `/api/invoices/${dealerId}/${id}.pdf`;
    },
};
