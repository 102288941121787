
import DepartmentFormDialog from "./DepartmentFormDialog.vue";
import DepartmentUsers from "./DepartmentUsers.vue";
import { Department, departmentsApi } from "@/api/departments";
import { Permission } from "@/api/userSession";
import { showConfirm } from "@/app/messageUtil";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { userSession } from "@/store/userSession";
import Sortable from "sortablejs";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            departmentFormDialogVisible: false,
            departmentFormDialogDepartment: null as Department | null,
            departments: [] as Department[],
            loading: true,
            sortableInitialized: false,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },
    },

    watch: {
        async dealerId() {
            this.sortableInitialized = false;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        departments() {
            const items = this.$refs.items as HTMLElement;
            if (!this.sortableInitialized && this.departments.length && items) {
                Sortable.create(items, {
                    animation: 150,
                    handle: ".drag-handle",
                    ghostClass: "accent",
                    onEnd: async ({ oldIndex, newIndex }) => {
                        if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                            const item = this.departments.splice(oldIndex, 1)[0];
                            this.departments.splice(newIndex, 0, item);
                            await departmentsApi.updateOrder(
                                this.departments[0].dealerId,
                                this.departments.map((d) => d.id)
                            );
                        }
                    },
                });
                this.sortableInitialized = true;
            }
        },
    },

    methods: {
        async loadItems() {
            this.loading = true;

            try {
                if (this.dealerId) {
                    this.departments = await departmentsApi.getByDealer(this.dealerId);
                } else {
                    this.departments = [];
                }
            } finally {
                this.loading = false;
            }
        },

        showCreateForm() {
            this.departmentFormDialogDepartment = null;
            this.departmentFormDialogVisible = true;
        },

        showEditForm(department: Department) {
            this.departmentFormDialogDepartment = department;
            this.departmentFormDialogVisible = true;
        },

        async deleteDepartment(department: Department) {
            if (
                await showConfirm(
                    this.$t("Abteilung löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Abteilung {0} löschen möchten?", [department.name]) as string
                )
            ) {
                await departmentsApi.delete(department.dealerId, department.id);
                await this.loadItems();
            }
        },

        async savedDepartment() {
            this.departmentFormDialogVisible = false;
            await this.loadItems();
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DepartmentFormDialog,
        DepartmentUsers,
        DealerContextGuard,
    },
});
