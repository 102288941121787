import { Contact } from "./contacts";
import {
    Climatisation,
    Drive,
    ExteriorColor,
    Feature,
    Fuel,
    Gearbox,
    HeadlightType,
    InteriorType,
    ParkingAssistant,
    SpeedControl,
    TrailerCouplingType,
    UsageType,
} from "./inventory";
import { OpportunityChannel } from "./opportunities";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum InventoryVehicleInquirySearchOrder {
    CREATED_ASC = "CREATED_ASC",
    CREATED_DESC = "CREATED_DESC",
}

export interface InventoryVehicleInquiry {
    readonly id: string;
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly disabled: Date | null;
    readonly data: InventoryVehicleInquiryData;
}

export interface InventoryVehicleInquiryAndContact {
    readonly inventoryVehicleInquiry: InventoryVehicleInquiry;
    readonly contact: Contact | null;
}

export interface InventoryVehicleInquiryData {
    readonly validUntil: Date | null;
    readonly disableOnMatch: boolean;
    readonly comment: string | null;
    readonly contactId: string;
    readonly dealerIds: string[];
    readonly vehicleClasses: string[];
    readonly categories: string[];
    readonly makes: string[];
    readonly models: string[];
    readonly usageTypes: UsageType[];
    readonly firstRegistrationFrom: string | null;
    readonly firstRegistrationTo: string | null;
    readonly mileageFrom: number | null;
    readonly mileageTo: number | null;
    readonly cubicCapacityFrom: number | null;
    readonly cubicCapacityTo: number | null;
    readonly powerFrom: number | null;
    readonly powerTo: number | null;
    readonly gearboxes: Gearbox[];
    readonly drives: Drive[];
    readonly fuels: Fuel[];
    readonly exteriorColors: ExteriorColor[];
    readonly interiorTypes: InteriorType[];
    readonly climatisations: Climatisation[];
    readonly speedControls: SpeedControl[];
    readonly headlightTypes: HeadlightType[];
    readonly parkingAssistants: ParkingAssistant[];
    readonly trailerCouplingTypes: TrailerCouplingType[];
    readonly priceFrom: number | null;
    readonly priceTo: number | null;
    readonly features: Feature[];
    readonly dealerId: string;
    readonly channel: OpportunityChannel;
    readonly sourceId: string;
    readonly individualReceivers: string[];
    readonly opportunityTeamReceivers: string[];
}

export interface InventoryVehicleInquiryMatch {
    readonly id: string;
    readonly created: Date;
    readonly opportunityId: string;
    readonly inventoryVehicleInquiryId: string | null;
    readonly inventoryVehicleId: string;
}

export interface InventoryVehicleInquirySearchResults {
    readonly results: InventoryVehicleInquiryAndContact[];
    readonly totalSize: number;
    readonly searchId: number;
}

export interface InventoryVehicleInquirySearchRequest {
    readonly enabledAndValid: boolean | null;
    readonly dealerIds: string[];
    readonly sortBy: InventoryVehicleInquirySearchOrder;
}

interface InventoryVehicleInquiryApi {
    getMatchesByOpportunity(opportunityId: string): Promise<InventoryVehicleInquiryMatch[]>;
    add(data: InventoryVehicleInquiryData): Promise<string>;
    edit(id: string, data: InventoryVehicleInquiryData): Promise<void>;
    getById(id: string): Promise<InventoryVehicleInquiry | null>;
    enable(id: string): Promise<void>;
    disable(id: string): Promise<void>;
    delete(id: string): Promise<void>;
    search(
        start: number,
        size: number,
        searchRequest: InventoryVehicleInquirySearchRequest,
        searchId: number
    ): Promise<InventoryVehicleInquirySearchResults>;
}

export const inventoryVehicleInquiryApi: InventoryVehicleInquiryApi = {
    async getMatchesByOpportunity(opportunityId) {
        return cloneObject((await axios.get("/api/inventory-vehicle-inquiries", { params: { opportunityId } })).data);
    },

    async add(data) {
        return (await axios.post("/api/inventory-vehicle-inquiries", data)).data;
    },

    async edit(id, data) {
        await axios.put(`/api/inventory-vehicle-inquiries/${id}`, data);
    },

    async getById(id) {
        return cloneObject((await axios.get(`/api/inventory-vehicle-inquiries/${id}`)).data);
    },

    async enable(id) {
        await axios.post(`/api/inventory-vehicle-inquiries/${id}/_enable`);
    },

    async disable(id) {
        await axios.post(`/api/inventory-vehicle-inquiries/${id}/_disable`);
    },

    async delete(id) {
        await axios.delete(`/api/inventory-vehicle-inquiries/${id}`);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/inventory-vehicle-inquiries/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },
};
