import axios from "axios";

export interface EmailSignatureTemplate {
    dealerId: string;
    content: string | null;
}

interface EmailSignatureTemplateApi {
    editEmailSignatureTemplate(emailSignatureTemplate: EmailSignatureTemplate): Promise<void>;
    getByDealer(dealerId: string): Promise<EmailSignatureTemplate>;
}

export const emailSignatureTemplateApi: EmailSignatureTemplateApi = {
    async editEmailSignatureTemplate(emailSignatureTemplate) {
        await axios.post("/api/email-signature-template", emailSignatureTemplate);
    },

    async getByDealer(dealerId: string) {
        return (await axios.get(`/api/email-signature-template/${dealerId}`)).data;
    },
};
