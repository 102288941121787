
import { VehicleClass } from "@/api/makeModels";
import { opportunitiesApi, Opportunity, OpportunityChannel, OpportunityTradeInForm } from "@/api/opportunities";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import PartialDatePicker from "@/app/components/PartialDatePicker.vue";
import ActionCard from "@/app/pages/ActionCard.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import ModelPicker from "@/app/pages/ModelPicker.vue";
import { decimal, defaultCurrency, integer } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getCurrentUtcYear, getDate } from "@/util/dateTimeUtils";
import { YearMonthDay } from "@/util/types";
import Vue from "vue";

const MIN_MODEL_YEAR = 1900;

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
    },

    data() {
        return {
            contactVehicleFirstRegistration: {
                year: this.opportunityProp.contactVehicleFirstRegistrationYear,
                month: this.opportunityProp.contactVehicleFirstRegistrationMonth,
                day: this.opportunityProp.contactVehicleFirstRegistrationDay,
            } as YearMonthDay,
            currencyRules: decimal(defaultCurrency()),
            now: now(),
            positiveIntegerRules: integer(0),
            tradeInFormData: {
                tradeInRequested: this.opportunityProp.tradeInRequested,
                contactVehicleLink: this.opportunityProp.contactVehicleLink,
                contactVehicleClass: this.opportunityProp.contactVehicleClass,
                contactVehicleMake: this.opportunityProp.contactVehicleMake,
                contactVehicleModel: this.opportunityProp.contactVehicleModel,
                contactVehicleModelDescription: this.opportunityProp.contactVehicleModelDescription,
                contactVehicleLicensePlate: this.opportunityProp.contactVehicleLicensePlate,
                contactVehicleVin: this.opportunityProp.contactVehicleVin,
                contactVehicleModelYear: this.opportunityProp.contactVehicleModelYear,
                contactVehicleMileage: this.opportunityProp.contactVehicleMileage,
                contactVehicleFirstRegistrationDay: null,
                contactVehicleFirstRegistrationMonth: null,
                contactVehicleFirstRegistrationYear: null,
                contactVehiclePower: this.opportunityProp.contactVehiclePower,
                contactVehicleColor: this.opportunityProp.contactVehicleColor,
                contactVehicleTransmission: this.opportunityProp.contactVehicleTransmission,
                contactVehicleFeatures: this.opportunityProp.contactVehicleFeatures,
                contactVehiclePreviousOwners: this.opportunityProp.contactVehiclePreviousOwners,
                contactVehicleLoanRedemption: this.opportunityProp.contactVehicleLoanRedemption,
                contactVehiclePriceSuggestion: this.opportunityProp.contactVehiclePriceSuggestion,
            } as OpportunityTradeInForm,
            OpportunityChannel,
            VehicleClass,
            working: false,
        };
    },

    computed: {
        currentDate(): string {
            return getDate(this.now, userSession.timeZone);
        },

        modelYearOptions() {
            const currentYear = getCurrentUtcYear(this.now);
            const result = [];

            for (let i = currentYear + 5; i >= MIN_MODEL_YEAR; i--) {
                result.push(i.toString());
            }

            return result;
        },

        defaultCurrency(): string {
            return configStore.configuration.defaultCurrency;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async save() {
            this.working = true;
            try {
                await opportunitiesApi.updateTradeIn(this.opportunityProp.id, {
                    ...this.tradeInFormData,
                    contactVehicleFirstRegistrationDay: this.contactVehicleFirstRegistration.day,
                    contactVehicleFirstRegistrationMonth: this.contactVehicleFirstRegistration.month,
                    contactVehicleFirstRegistrationYear: this.contactVehicleFirstRegistration.year,
                });
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        EnumField,
        MakePicker,
        ModelPicker,
        NumberField,
        PartialDatePicker,
    },
});
