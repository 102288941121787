
import { Announcement, announcementsApi } from "@/api/announcements";
import { isAnnouncementNotification, Notification, notificationEventSource } from "@/api/notifications";
import UserLink from "@/app/pages/UserLink.vue";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            items: [] as Announcement[],
            loading: true,
            notificationHandler: null as ((n: Notification) => void) | null,
        };
    },

    methods: {
        formatDate(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "L");
        },

        getDealerNameById(dealerId: string): string | null {
            return dealersStore.dealerById(dealerId)?.name ?? null;
        },

        async loadItems() {
            this.items = [];
            this.loading = true;

            try {
                this.items = await announcementsApi.getByDealer(this.dealerId, true);
            } finally {
                this.loading = false;
            }
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();

        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isAnnouncementNotification(n)) {
                this.loadItems();
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        UserLink,
    },
});
