import axios from "axios";

export interface WelcomeEmailForm {
    readonly locale: string;
    readonly dealerIds: string[];
    readonly receiverIds: string[];
    readonly content: string;
    readonly subject: string;
}

interface WelcomeEmailApi {
    send(
        welcomeEmailForm: WelcomeEmailForm,
        files: File[],
        progressCallback?: (progressEvent: ProgressEvent) => void
    ): Promise<string[]>;
}

export const welcomeEmailApi: WelcomeEmailApi = {
    async send(welcomeEmailForm, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }
        formData.append("form", new Blob([JSON.stringify(welcomeEmailForm)], { type: "application/json" }));
        return (await axios.post("/api/welcome-email/_send", formData, { onUploadProgress })).data;
    },
};
