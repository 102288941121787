
import {
    DealerInstruction,
    DealerInstructionCategory,
    DealerInstructionPriority,
    dealerInstructionsApi,
    IssueType,
} from "@/api/dealerInstructions";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { toDateObject } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseType: {
            type: String as () => string | null,
            default: null,
        },
        dealerId: {
            type: String,
            required: true,
        },
        dealerInstructionCategories: {
            type: Array as () => DealerInstructionCategory[],
            required: true,
        },
        issueType: {
            type: String as () => IssueType,
            required: true,
        },
    },

    data() {
        return {
            instructions: [] as DealerInstruction[],
            loading: false,
            now: now(),
        };
    },

    computed: {
        activeInstructions(): DealerInstruction[] {
            return this.instructions
                .filter((i) => !i.dealerIds.length || i.dealerIds.includes(this.dealerId))
                .filter(
                    (i) =>
                        !i.categories.length ||
                        i.categories.some((category) => this.dealerInstructionCategories.includes(category))
                )
                .filter((i) => !i.issueTypes.length || i.issueTypes.includes(this.issueType))
                .filter(
                    (i) =>
                        this.issueType !== IssueType.CASE ||
                        !i.caseTypes.length ||
                        (!!this.caseType && i.caseTypes.includes(this.caseType))
                )
                .filter((i) => {
                    if (!this.dealerTimeZone) {
                        return true;
                    }

                    const visibleFromTime = i.visibleFromTime ?? undefined;
                    const visibleFrom = i.visibleFromDate
                        ? toDateObject(this.dealerTimeZone, i.visibleFromDate, 0, visibleFromTime)
                        : null;

                    if (visibleFrom && this.now.getTime() < visibleFrom.getTime()) {
                        return false;
                    }

                    const visibleToTime = i.visibleToTime ?? "23:59:59.999";
                    const visibleTo = i.visibleToDate
                        ? toDateObject(this.dealerTimeZone, i.visibleToDate, 0, visibleToTime)
                        : null;

                    return !visibleTo || this.now.getTime() <= visibleTo.getTime();
                });
        },

        dealerTimeZone(): string | null {
            return dealersStore.dealerById(this.dealerId)?.timeZone ?? null;
        },
    },

    methods: {
        getDealerInstructionPriorityColor(priority: DealerInstructionPriority): string | null {
            if (priority === DealerInstructionPriority.HIGH) {
                return "error";
            } else if (priority === DealerInstructionPriority.MEDIUM) {
                return "warning";
            } else if (priority === DealerInstructionPriority.LOW) {
                return "info";
            } else {
                return null;
            }
        },

        async loadItems() {
            this.loading = true;
            try {
                this.instructions = await dealerInstructionsApi.getAll();
            } finally {
                this.loading = false;
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },
});
