import { OutgoingSms, SmsStatus } from "@/api/outgoingSms";
import { $t } from "@/app/i18n";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";

export function getCreator(outgoingSms: OutgoingSms): string {
    if (outgoingSms.initiatorCtUserId) {
        return $t("Externer Agent") as string;
    } else if (outgoingSms.initiatorUserId) {
        return (getFullName(usersStore.getUserById(outgoingSms.initiatorUserId)) || $t("Unbekannt")) as string;
    } else {
        return $t("System") as string;
    }
}

export function getSenderId(outgoingSms: OutgoingSms): string {
    return outgoingSms.fromNumber
        ? parseAndFormatNumber(outgoingSms.fromNumber, "INTERNATIONAL") || outgoingSms.fromNumber
        : outgoingSms.fromAddress!;
}

export function getStatusCaption(smsStatus: SmsStatus | null) {
    if (!smsStatus) {
        return $t("unbekannt");
    }

    return $t(`enum.SmsStatus.${smsStatus}`);
}

export function getStatusColor(smsStatus: SmsStatus | null): string | null {
    if (smsStatus === SmsStatus.DELIVERED) {
        return "success";
    } else if (smsStatus === SmsStatus.EXPIRED) {
        return "error";
    } else if (smsStatus === SmsStatus.FAILED) {
        return "error";
    } else if (smsStatus === SmsStatus.REJECTED) {
        return "error";
    } else if (smsStatus === SmsStatus.SENT) {
        return "success";
    } else if (smsStatus === SmsStatus.UNKNOWN) {
        return "warning";
    } else {
        return null;
    }
}
