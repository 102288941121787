import { CallStatus, OutgoingCall } from "@/api/outgoingCalls";
import { $t } from "@/app/i18n";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";

export function canAddNote(outgoingCall: OutgoingCall): boolean {
    // only the initiator can add a note
    if (outgoingCall.initiatorUserId) {
        return outgoingCall.initiatorUserId === userSession.userId;
    } else if (outgoingCall.initiatorCtUserId) {
        return outgoingCall.initiatorCtUserId === userSession.userId;
    } else {
        return false;
    }
}

export function getCreator(outgoingCall: OutgoingCall): string {
    if (outgoingCall.initiatorCtUserId) {
        return $t("Externer Agent") as string;
    } else if (outgoingCall.initiatorUserId) {
        return (getFullName(usersStore.getUserById(outgoingCall.initiatorUserId)) || $t("Unbekannt")) as string;
    } else {
        return $t("System") as string;
    }
}

export function getStatusColor(callStatus: CallStatus | null): string | null {
    if (callStatus === CallStatus.INIT) {
        return "warning";
    } else if (callStatus === CallStatus.RINGING_INBOUND) {
        return "warning";
    } else if (callStatus === CallStatus.CANCELED) {
        return "error";
    } else if (callStatus === CallStatus.FAILED_INBOUND) {
        return "error";
    } else if (callStatus === CallStatus.CONNECTING) {
        return "warning";
    } else if (callStatus === CallStatus.RINGING_OUTBOUND) {
        return "warning";
    } else if (callStatus === CallStatus.IN_PROGRESS) {
        return "success";
    } else if (callStatus === CallStatus.BUSY) {
        return "error";
    } else if (callStatus === CallStatus.FAILED_OUTBOUND) {
        return "error";
    } else if (callStatus === CallStatus.NO_ANSWER) {
        return "error";
    } else if (callStatus === CallStatus.COMPLETED) {
        return "success";
    } else if (callStatus === CallStatus.UNKNOWN) {
        return "error";
    } else {
        return null;
    }
}
