
import OpportunityTeamPickerCard from "./OpportunityTeamPickerCard.vue";
import { ContactType } from "@/api/contacts";
import { opportunitiesApi, OpportunityReceiversForm, OpportunityStatus } from "@/api/opportunities";
import { AcquisitionType } from "@/api/opportunityTeams";
import { VehicleSummary } from "@/app/inventoryUtils";
import { showConfirm, showError } from "@/app/messageUtil";
import ActionCard from "@/app/pages/ActionCard.vue";
import UserPickerCard from "@/app/pages/users/UserPickerCard.vue";
import { UserPickerData } from "@/app/pages/users/userPickerCardTypes";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

function containsOtherElements<T>(a: T[], b: T[]) {
    const s = new Set(a);
    for (const e of b) {
        s.delete(e);
    }
    return !!s.size;
}

export default Vue.extend({
    props: {
        acquisitionType: {
            type: String as () => AcquisitionType | null,
            default: null,
        },
        contactType: {
            type: String as () => ContactType | null,
            default: null,
        },
        dealerId: {
            type: String,
            required: true,
        },
        individualReceivers: {
            type: Array as () => string[],
            required: true,
        },
        opportunityId: {
            type: String,
            required: true,
        },
        opportunitySourceId: {
            type: String as () => string | null,
            default: null,
        },
        opportunityStatus: {
            type: String as () => OpportunityStatus,
            required: true,
        },
        opportunityTeamReceivers: {
            type: Array as () => string[],
            required: true,
        },
        tradeInRequested: {
            type: Boolean,
            default: null,
        },
        vehicleSummaries: {
            type: Array as () => VehicleSummary[],
            default: () => [],
        },
    },

    data() {
        return {
            working: false,
            opportunityReceiversForm: {
                individualReceivers: cloneObject(this.individualReceivers),
                opportunityTeamReceivers: cloneObject(this.opportunityTeamReceivers),
            } as OpportunityReceiversForm,
            userPickerData: new UserPickerData(cloneObject(this.individualReceivers)),
        };
    },

    methods: {
        setOpportunityTeamReceivers(newValue: string[]) {
            this.opportunityReceiversForm = {
                ...this.opportunityReceiversForm,
                opportunityTeamReceivers: newValue,
            };
        },

        setIndividualReceivers(newValue: UserPickerData) {
            this.opportunityReceiversForm = {
                ...this.opportunityReceiversForm,
                individualReceivers: newValue.userIds,
            };
            this.userPickerData = newValue;
        },

        close() {
            this.$emit("close");
        },

        async save() {
            if (
                !this.opportunityReceiversForm.opportunityTeamReceivers.length &&
                !this.opportunityReceiversForm.individualReceivers.length
            ) {
                showError(this.$t("Es ist kein Empfänger ausgewählt!") as string);
                return;
            }

            const removeAssignees =
                this.opportunityStatus === OpportunityStatus.ASSIGNED &&
                (containsOtherElements(
                    this.opportunityReceiversForm.opportunityTeamReceivers,
                    this.opportunityTeamReceivers
                ) ||
                    containsOtherElements(
                        this.opportunityReceiversForm.individualReceivers,
                        this.individualReceivers
                    )) &&
                (await showConfirm(
                    this.$t("Zuteilungen entfernen ?") as string,
                    this.$t(
                        "Für die neu hinzugefügten Empfänger wird der Vorgang erst dann sichtbar, wenn die bestehenden Zuteilungen entfernt werden. Möchten Sie die Zuteilungen entfernen?"
                    ) as string,
                    this.$t("Ja") as string,
                    this.$t("Nein") as string
                ));

            this.working = true;
            try {
                await opportunitiesApi.editReceivers(this.opportunityId, this.opportunityReceiversForm);
                this.$emit("close");
                if (removeAssignees) {
                    await opportunitiesApi.updateAssignees(this.opportunityId, []);
                }
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        OpportunityTeamPickerCard,
        UserPickerCard,
    },
});
