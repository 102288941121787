
import { EmergencyIncidentAssistantState } from "./emergencyIncidentAssistant";
import IssueDataCheckCard from "@/app/pages/IssueDataCheckCard.vue";
import IssueDataCheckCardDataTableRow from "@/app/pages/IssueDataCheckCardDataTableRow.vue";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        emergencyIncidentAssistantState: {
            type: Object as () => EmergencyIncidentAssistantState,
            required: true,
        },
        phoneticSpellingLocale: {
            type: String,
            required: true,
        },
        singlePageAssistant: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            parseAndFormatNumber,
        };
    },

    components: {
        IssueDataCheckCard,
        IssueDataCheckCardDataTableRow,
    },
});
