
import { supportApi, SupportMessageForm, SupportMessageTopic } from "@/api/support";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { showInfo } from "@/app/messageUtil";
import { e164, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import {
    formatTime,
    getDateByDayOfWeek,
    getDayOfWeekByDate,
    getDayOfWeekByDayOfWeekName,
    getDayOfWeekName,
    getWeek,
    toDateObject,
    Week,
} from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

interface PhoneAvailability {
    readonly firstDay: string;
    readonly lastDay: string;
    readonly startTime: string;
    readonly endTime: string;
}

export default Vue.extend({
    data() {
        return {
            e164,
            notEmpty,
            parseAndFormatNumber,
            SupportMessageTopic,
            topic: null as SupportMessageTopic | null,
            message: "",
            now: now(),
            userPhoneNumber: userSession.profile?.terminalPhoneNumber || "",
            sendingMessage: false,
        };
    },

    computed: {
        currentWeekForOrganization(): Week {
            return getWeek(this.now, 0, this.orgTimeZone, configStore.configuration.defaultLocale);
        },

        defaultCountry(): string {
            return configStore.configuration.defaultCountry;
        },

        email(): string {
            return userSession.username!;
        },

        fullName(): string {
            return userSession.fullName!;
        },

        orgQualityManagementPhone(): string {
            return configStore.configuration.orgQualityManagementPhone;
        },

        orgQualityManagementPhoneAvailability(): PhoneAvailability | null {
            return this.getPhoneAvailability(
                configStore.configuration.orgQualityManagementAvailabilityFirstDay,
                configStore.configuration.orgQualityManagementAvailabilityLastDay,
                configStore.configuration.orgQualityManagementAvailabilityStartTime,
                configStore.configuration.orgQualityManagementAvailabilityEndTime
            );
        },

        orgSupportPhone(): string {
            return configStore.configuration.orgSupportPhone;
        },

        orgSupportPhoneAvailability(): PhoneAvailability | null {
            return this.getPhoneAvailability(
                configStore.configuration.orgSupportAvailabilityFirstDay,
                configStore.configuration.orgSupportAvailabilityLastDay,
                configStore.configuration.orgSupportAvailabilityStartTime,
                configStore.configuration.orgSupportAvailabilityEndTime
            );
        },

        orgTimeZone(): string {
            return configStore.configuration.defaultTimeZone;
        },
    },

    methods: {
        getDateObjectForOrganizationByDayOfCurrentWeek(dayOfWeekName: string, time: string): Date | null {
            return (
                [dayOfWeekName]
                    .map((v) => getDayOfWeekByDayOfWeekName(v, configStore.configuration.defaultLocale, "en"))
                    .filter((v): v is number => v !== null)
                    .map((dayOfWeek) => getDateByDayOfWeek(this.currentWeekForOrganization, dayOfWeek))
                    .filter((v): v is string => v !== null)
                    .map((dateString) => toDateObject(this.orgTimeZone, dateString, 0, time))
                    .pop() ?? null
            );
        },

        getPhoneAvailability(
            firstDay: string | null,
            lastDay: string | null,
            startTime: string | null,
            endTime: string | null
        ): PhoneAvailability | null {
            if (!firstDay || !lastDay || !startTime || !endTime) {
                return null;
            }

            const fromDate = this.getDateObjectForOrganizationByDayOfCurrentWeek(firstDay, startTime);
            const toDate = this.getDateObjectForOrganizationByDayOfCurrentWeek(lastDay, endTime);

            if (fromDate === null || toDate === null) {
                return null;
            }

            const timeZone = userSession.timeZone;
            const locale = userSession.locale;

            return {
                firstDay: getDayOfWeekName(getDayOfWeekByDate(fromDate, locale), locale, "S"),
                lastDay: getDayOfWeekName(getDayOfWeekByDate(toDate, locale), locale, "S"),
                startTime: formatTime(fromDate, timeZone, locale, false),
                endTime: formatTime(toDate, timeZone, locale, false),
            };
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.sendingMessage = true;
            try {
                const form: SupportMessageForm = {
                    topic: this.topic!,
                    phoneNumber: this.userPhoneNumber,
                    message: this.message,
                };

                await supportApi.submitSupportMessage(form);

                this.topic = null;
                this.message = "";
                showInfo(
                    this.$t(
                        "Ihre Anfrage wurde erfolgreich verarbeitet. Ein Mitarbeiter von uns meldet sich in Kürze bei Ihnen."
                    ) as string
                );
                if (!(this.$refs.form as any).resetValidation()) {
                    return;
                }
            } finally {
                this.sendingMessage = false;
            }
        },
    },

    components: {
        PhoneNumberField,
        EnumField,
    },
});
