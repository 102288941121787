
import AnnouncementFormDialog from "./AnnouncementFormDialog.vue";
import AnnouncementsOverviewDataTableRow from "./AnnouncementsOverviewDataTableRow.vue";
import { Announcement, announcementsApi } from "@/api/announcements";
import { Permission } from "@/api/userSession";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import { showConfirm } from "@/app/messageUtil";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { announcementsStore } from "@/store/announcements";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            announcementsStore,
            formDialogVisible: false,
            items: [] as Announcement[],
            loading: true,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Ankündigung"),
                    width: "40%",
                },
                {
                    text: this.$t("Empfänger"),
                    width: "30%",
                },
                {
                    text: this.$t("Sonstiges"),
                    width: "30%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "1%",
                },
            ];
        },
    },

    methods: {
        async deleteItem(id: string) {
            if (
                !(await showConfirm(
                    this.$t("Ankündigung löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Ankündigung löschen möchten?") as string
                ))
            ) {
                return;
            }

            await announcementsApi.delete(id);
            await this.refresh();
        },

        async loadItems() {
            this.items = [];
            this.loading = true;

            try {
                this.items = await announcementsApi.getByDealer(this.dealerId, false);
            } finally {
                this.loading = false;
            }
        },

        async refresh() {
            await this.loadItems();
        },

        async saved() {
            this.formDialogVisible = false;
            await this.refresh();
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.refresh();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        AnnouncementFormDialog,
        AnnouncementsOverviewDataTableRow,
        DataTable,
        DealerContextGuard,
    },
});
