
import { ContactData } from "@/api/contacts";
import { Dealer } from "@/api/dealers";
import { EmailTemplate, emailTemplatesApi } from "@/api/emailTemplates";
import { renderVehicleSummaryTitle, VehicleSummary } from "@/app/inventoryUtils";
import { renderEmailPlaceholders } from "@/app/pages/emailtemplates/emailPlaceholders";
import { RequestedVehiclePlaceholderInfo, UrlWithKey } from "@/app/placeholderUtils";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        buttonClass: {
            type: String,
            default: "",
        },
        contactData: {
            type: Object as () => ContactData | null,
            required: false,
        },
        dealerId: {
            type: String as () => string | null,
            default: null,
        },
        emailQuote: {
            type: String,
            default: "",
        },
        emailSignature: {
            type: String,
            default: "",
        },
        fragment: {
            type: Boolean,
            default: false,
        },
        issueId: {
            type: String as () => string | null,
            default: null,
        },
        urls: {
            type: Array as () => UrlWithKey[],
            default: () => [],
        },
        vehicleSummaries: {
            type: Array as () => VehicleSummary[],
            default: () => [],
        },
    },

    data() {
        return {
            loading: true,
            templates: [] as EmailTemplate[],
        };
    },

    computed: {
        dealer(): Dealer | null {
            if (!this.dealerId) {
                return null;
            }

            return dealersStore.dealerById(this.dealerId);
        },
    },

    methods: {
        loadPreviewContent(e: Event, template: EmailTemplate) {
            if (!e.target) {
                return;
            }

            const iframe = (e.target as HTMLIFrameElement).contentWindow?.document!;

            iframe.write(this.renderTemplate(template) || "<div></div>");

            const style = iframe.createElement("style");
            style.innerHTML = "body { font-family: sans-serif; font-size: 10.5pt; background: white; }";

            const head = iframe.head;
            head.insertBefore(style, head.firstChild);
        },

        async loadTemplates() {
            this.loading = true;
            this.templates = [];
            this.$emit("update:templates");
            try {
                this.templates = await emailTemplatesApi.list(this.fragment);
                this.$emit("update:templates");
            } finally {
                this.loading = false;
            }
        },

        renderTemplate(template: EmailTemplate) {
            const contactNameData = {
                salutation: this.contactData?.salutation || null,
                companyName: this.contactData?.companyName || null,
                namePrefix: this.contactData?.namePrefix || null,
                givenName: this.contactData?.givenName || null,
                middleName: this.contactData?.middleName || null,
                familyName: this.contactData?.familyName || null,
                nameSuffix: this.contactData?.nameSuffix || null,
            };

            const vehicleSummary: VehicleSummary | null = this.vehicleSummaries.length
                ? this.vehicleSummaries[0]
                : null;

            const requestedPlaceholderInfo: RequestedVehiclePlaceholderInfo = {
                caption: vehicleSummary ? renderVehicleSummaryTitle({ ...vehicleSummary, internalId: null }) : null,
                make: vehicleSummary?.make ?? null,
                model: vehicleSummary?.model ?? null,
                modelDescription: vehicleSummary?.modelDescription ?? null,
                internalId: vehicleSummary?.internalId ?? null,
            };

            return renderEmailPlaceholders(
                template.content,
                this.dealer,
                template.locale,
                contactNameData,
                {
                    ...userSession.profile!,
                    emailSignature: this.emailSignature,
                },
                requestedPlaceholderInfo,
                this.urls,
                this.emailQuote,
                this.issueId
            );
        },

        selectTemplate(template: EmailTemplate) {
            this.$emit("select:template", { template, renderedContent: this.renderTemplate(template) });
        },
    },

    async mounted() {
        await this.loadTemplates();
    },
});
