
import { Contact } from "@/api/contacts";
import {
    IncomingWhatsAppAndContact,
    incomingWhatsAppApi,
    IncomingWhatsAppMessage,
    ProcessingState,
} from "@/api/incomingWhatsApp";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { renderContactCaption } from "@/app/contactUtils";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        incomingWhatsAppAndContact: {
            type: Object as () => IncomingWhatsAppAndContact,
            required: true,
        },
    },

    data() {
        return {
            image: "",
            ProcessingState,
            reactivating: false,
        };
    },

    computed: {
        contact(): Contact | null {
            return this.incomingWhatsAppAndContact.contact;
        },

        contactCaption(): string | null {
            if (!this.contact) {
                return null;
            }

            return renderContactCaption(this.contact.contactData);
        },

        incomingWhatsAppMessage(): IncomingWhatsAppMessage {
            return this.incomingWhatsAppAndContact.incomingWhatsAppMessage;
        },

        isContactDeleted(): boolean {
            return !!this.contact?.deleted;
        },

        statusClass(): string | null {
            if (this.incomingWhatsAppMessage.processingState === ProcessingState.PROCESSED) {
                return "success--text";
            } else if (this.incomingWhatsAppMessage.processingState === ProcessingState.FILTERED) {
                return "error--text";
            } else {
                return null;
            }
        },
    },

    methods: {
        formatInstant(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        async reactivate() {
            this.reactivating = true;
            try {
                await incomingWhatsAppApi.reactivate(this.incomingWhatsAppMessage.id);
            } finally {
                this.reactivating = false;
            }
        },

        showMedia(download: boolean) {
            const mediaType = this.incomingWhatsAppMessage.mediaType!;

            if (!download && mediaType.startsWith("image/")) {
                this.image = incomingWhatsAppApi.generateMediaLink(this.incomingWhatsAppMessage, {});
            } else {
                window.open(incomingWhatsAppApi.generateMediaLink(this.incomingWhatsAppMessage, {}, download));
            }
        },
    },

    components: {
        ImageCarousel,
    },
});
