import { OpportunitiesOverviewFilters, OpportunitiesOverviewState } from "./opportunitiesOverview";
import { UserSearchFilter, UserSearchFilterForm, UserSearchFilterType } from "@/api/userSearchFilters";
import { createFilterDate, sortFilterValues, UserSearchFilterUnitOfTime } from "@/app/userSearchFilterUtils";
import { makeModelsStore } from "@/store/makeModels";
import { opportunityOutcomeReasonsStore } from "@/store/opportunityOutcomeReasons";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { usersStore } from "@/store/users";

export interface OpportunitySearchFilterOptions {
    readonly name: string;
    readonly createdFrom: number | null;
    readonly createdTo: number | null;
    readonly createdUnit: UserSearchFilterUnitOfTime | null;
}

export function mapToSearchFilterForm(
    options: OpportunitySearchFilterOptions,
    opportunitiesOverviewState: OpportunitiesOverviewState
): UserSearchFilterForm {
    return {
        name: options.name,
        filterType: UserSearchFilterType.OPPORTUNITY,
        filterJson: {
            createdFrom: options.createdFrom,
            createdTo: options.createdTo,
            createdUnit: options.createdUnit,
            sourceIds: opportunitiesOverviewState.sourceIds,
            status: opportunitiesOverviewState.status,
            channels: opportunitiesOverviewState.channels,
            sentimentTypes: opportunitiesOverviewState.sentimentTypes,
            urgencies: opportunitiesOverviewState.urgencies,
            priority: opportunitiesOverviewState.priority,
            creatorActorType: opportunitiesOverviewState.creatorActorType,
            createdBy: opportunitiesOverviewState.createdBy,
            closerActorType: opportunitiesOverviewState.closerActorType,
            closedBy: opportunitiesOverviewState.closedBy,
            outcome: opportunitiesOverviewState.outcome,
            outcomeReasonIds: opportunitiesOverviewState.outcomeReasonIds,
            assignees: opportunitiesOverviewState.assignees,
            individualReceivers: opportunitiesOverviewState.individualReceivers,
            opportunityTeamReceivers: opportunitiesOverviewState.opportunityTeamReceivers,
            hasReceivers: opportunitiesOverviewState.hasReceivers,
            makes: opportunitiesOverviewState.makes,
            models: opportunitiesOverviewState.models,
            usageTypes: opportunitiesOverviewState.usageTypes,
            search: opportunitiesOverviewState.search,
        },
    };
}

export function mapToOpportunitiesOverviewFilters(
    { filterJson }: UserSearchFilter | UserSearchFilterForm,
    opportunitiesOverviewState: OpportunitiesOverviewState
): OpportunitiesOverviewFilters {
    return {
        dealerIds: opportunitiesOverviewState.dealerIds,
        createdFrom: createFilterDate(filterJson.createdFrom, filterJson.createdUnit, true),
        createdTo: createFilterDate(filterJson.createdTo, filterJson.createdUnit, false),
        sourceIds: filterJson.sourceIds,
        status: filterJson.status,
        channels: filterJson.channels,
        sentimentTypes: filterJson.sentimentTypes,
        urgencies: filterJson.urgencies,
        priority: filterJson.priority,
        creatorActorType: filterJson.creatorActorType,
        createdBy: filterJson.createdBy,
        closerActorType: filterJson.closerActorType,
        closedBy: filterJson.closedBy,
        outcome: filterJson.outcome,
        outcomeReasonIds: filterJson.outcomeReasonIds,
        assignees: filterJson.assignees,
        individualReceivers: filterJson.individualReceivers,
        opportunityTeamReceivers: filterJson.opportunityTeamReceivers,
        hasReceivers: filterJson.hasReceivers,
        makes: filterJson.makes,
        models: filterJson.models,
        usageTypes: filterJson.usageTypes,
        search: filterJson.search,
    };
}

function stringifyFilter(filter: OpportunitiesOverviewFilters): string {
    return JSON.stringify({
        sourceIds: sortFilterValues(filter.sourceIds),
        status: filter.status,
        channels: sortFilterValues(filter.channels),
        sentimentTypes: sortFilterValues(filter.sentimentTypes),
        urgencies: sortFilterValues(filter.urgencies),
        priority: filter.priority,
        creatorActorType: filter.creatorActorType,
        createdBy: filter.createdBy,
        closerActorType: filter.closerActorType,
        closedBy: filter.closedBy,
        outcome: filter.outcome,
        outcomeReasonIds: sortFilterValues(filter.outcomeReasonIds),
        assignees: sortFilterValues(filter.assignees),
        individualReceivers: sortFilterValues(filter.individualReceivers),
        opportunityTeamReceivers: sortFilterValues(filter.opportunityTeamReceivers),
        hasReceivers: filter.hasReceivers,
        search: filter.search,
    });
}

export function filterMatchesOpportunitiesOverviewState(
    filter: UserSearchFilter,
    opportunitiesOverviewState: OpportunitiesOverviewState
): boolean {
    const createdFrom = createFilterDate(filter.filterJson.createdFrom, filter.filterJson.createdUnit, true);
    const createdTo = createFilterDate(filter.filterJson.createdTo, filter.filterJson.createdUnit, false);
    const datesMatch =
        createdFrom === opportunitiesOverviewState.createdFrom && createdTo === opportunitiesOverviewState.createdTo;

    return datesMatch && stringifyFilter(filter.filterJson) === stringifyFilter(opportunitiesOverviewState);
}

export function filterIsValid(filter: UserSearchFilter): boolean {
    if (filter.filterJson.createdBy?.length) {
        const users = usersStore.users.map((u) => u.id);
        if (filter.filterJson.createdBy.some((e: string) => !users.includes(e))) {
            return false;
        }
    }
    if (filter.filterJson.closedBy?.length) {
        const users = usersStore.users.map((u) => u.id);
        if (filter.filterJson.closedBy.some((e: string) => !users.includes(e))) {
            return false;
        }
    }
    if (filter.filterJson.sourceIds?.length) {
        const sources = opportunitySourcesStore.opportunitySources.map((e) => e.id);
        if (filter.filterJson.sourceIds.some((e: string) => !sources.includes(e))) {
            return false;
        }
    }
    if (filter.filterJson.asignees?.length) {
        const users = usersStore.users.map((u) => u.id);
        if (
            filter.filterJson.asignees.filter((e: string | null) => e !== null).some((e: string) => !users.includes(e))
        ) {
            return false;
        }
    }
    if (filter.filterJson.individualReceivers?.length) {
        const users = usersStore.users.map((u) => u.id);
        if (
            filter.filterJson.individualReceivers
                .filter((e: string | null) => e !== null)
                .some((e: string) => !users.includes(e))
        ) {
            return false;
        }
    }
    if (filter.filterJson.opportunityTeamReceivers?.length) {
        const opportunityTeams = opportunityTeamsStore.opportunityTeams.map((e) => e.id);
        if (
            filter.filterJson.opportunityTeamReceivers
                .filter((e: string | null) => e !== null)
                .some((e: string) => !opportunityTeams.includes(e))
        ) {
            return false;
        }
    }
    if (filter.filterJson.outcomeReasonIds?.length) {
        const outcomeReasons = opportunityOutcomeReasonsStore.opportunityOutcomeReasons.map((r) => r.id);
        if (
            filter.filterJson.outcomeReasonIds
                .filter((e: string | null) => e !== null)
                .some((e: string) => !outcomeReasons.includes(e))
        ) {
            return false;
        }
    }
    if (filter.filterJson.makes?.length) {
        if (filter.filterJson.makes.some((e: string) => !makeModelsStore.makes.includes(e))) {
            return false;
        }
    }
    if (filter.filterJson.models?.length) {
        const allowedModels = filter.filterJson.makes?.length
            ? filter.filterJson.makes
                  .map((m: string) => makeModelsStore.getModelsByMake(m))
                  .reduce((p: string[], c: string[]) => [...p, ...c])
            : makeModelsStore.models;
        if (filter.filterJson.models.some((e: string) => !allowedModels.includes(e))) {
            return false;
        }
    }
    return true;
}
