
import Vue from "vue";

export default Vue.extend({
    props: {
        maxWidth: {
            type: [String, Number],
            default: "none",
        },
        show: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            animate: false,
            animateTimeout: null as number | null,
        };
    },

    methods: {
        playDialogAnimation() {
            this.animate = false;

            this.$nextTick(() => {
                this.animate = true;

                if (this.animateTimeout !== null) {
                    clearTimeout(this.animateTimeout);
                }

                this.animateTimeout = window.setTimeout(() => (this.animate = false), 150);
            });
        },
    },

    beforeDestroy() {
        if (this.animateTimeout !== null) {
            clearTimeout(this.animateTimeout);
        }
    },
});
