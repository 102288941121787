
import { ContactType } from "@/api/contacts";
import EscalationGroupPickerCard from "@/app/pages/cases/EscalationGroupPickerCard.vue";
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        contactType: {
            type: String as () => ContactType | null,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        vehicleClass: {
            type: String as () => string | null,
            required: false,
        },
        vehicleMake: {
            type: String as () => string | null,
            required: false,
        },
    },

    data() {
        return {
            expanded: !!this.caseAssistantState.escalationGroupReceivers.length,
        };
    },

    components: {
        EscalationGroupPickerCard,
    },
});
