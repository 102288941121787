
import { announcementsApi, AnnouncementUserView } from "@/api/announcements";
import { isAnnouncementNotification, Notification, notificationEventSource } from "@/api/notifications";
import UserLink from "@/app/pages/UserLink.vue";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            items: [] as AnnouncementUserView[],
            loadingCount: 0,
            notificationHandler: null as ((n: Notification) => void) | null,
        };
    },

    methods: {
        canConfirm(announcementUserView: AnnouncementUserView): boolean {
            if (!announcementUserView.announcementUser) {
                return false;
            }

            return (
                announcementUserView.announcementUser.confirmable && !announcementUserView.announcementUser.confirmed
            );
        },

        async confirm(announcementUserView: AnnouncementUserView) {
            await announcementsApi.confirm(announcementUserView.id);
        },

        getConfirmationDate(announcementUserView: AnnouncementUserView): Date | null {
            return announcementUserView.announcementUser?.confirmed ?? null;
        },

        getDealerNameById(dealerId: string): string | null {
            return dealersStore.dealerById(dealerId)?.name ?? null;
        },

        formatDate(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "L");
        },

        async loadItems() {
            this.items = [];
            this.loadingCount++;

            try {
                this.items = await announcementsApi.getAnnouncements();

                await announcementsApi.read();
            } finally {
                this.loadingCount--;
            }
        },
    },

    async mounted() {
        await this.loadItems();

        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isAnnouncementNotification(n)) {
                this.loadItems();
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        UserLink,
    },
});
