
import { callerIdsApi } from "@/api/callerIds";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { showConfirm } from "@/app/messageUtil";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            phoneNumber: "",
            saving: false,
            notEmpty,
        };
    },

    computed: {
        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },
    },

    methods: {
        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                for (;;) {
                    const validationCode = await callerIdsApi.addCallerId({
                        phoneNumber: this.phoneNumber,
                        internal: false,
                    });
                    if (!validationCode) {
                        this.$emit("success", this.phoneNumber);
                        return;
                    }

                    if (
                        !(await showConfirm(
                            `${this.$t("Validierungscode")}: ${validationCode.code}`,
                            this.$t("Drücken Sie OK nachdem die Anruferkennung validiert wurde.") as string
                        ))
                    ) {
                        return;
                    }
                }
            } finally {
                this.saving = false;
            }
        },
    },

    components: {
        PhoneNumberField,
    },
});
