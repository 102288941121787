import axios from "axios";

export interface OpportunityOutcomeReasonForm {
    readonly name: string;
    readonly description: string | null;
    readonly won: boolean;
}

export interface OpportunityOutcomeReason {
    readonly id: string;
    readonly name: string;
    readonly description: string | null;
    readonly won: boolean;
}

interface OpportunityOutcomeReasonsApi {
    getAll(): Promise<OpportunityOutcomeReason[]>;
    updateOrder(ids: string[]): Promise<void>;
    add(opportunityOutcomeReasonForm: OpportunityOutcomeReasonForm): Promise<void>;
    edit(id: string, opportunityOutcomeReasonForm: OpportunityOutcomeReasonForm): Promise<void>;
    delete(id: string): Promise<void>;
}

export const opportunityOutcomeReasonsApi: OpportunityOutcomeReasonsApi = {
    async getAll() {
        return (await axios.get(`/api/opportunity-outcome-reasons`)).data;
    },

    async updateOrder(ids) {
        await axios.post(`/api/opportunity-outcome-reasons/_update-order`, ids);
    },

    async add(opportunityOutcomeReasonForm) {
        await axios.post(`/api/opportunity-outcome-reasons`, opportunityOutcomeReasonForm);
    },

    async edit(id, opportunityOutcomeReasonForm) {
        await axios.put(`/api/opportunity-outcome-reasons/${id}`, opportunityOutcomeReasonForm);
    },

    async delete(id) {
        await axios.delete(`/api/opportunity-outcome-reasons/${id}`);
    },
};
