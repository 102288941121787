import { CaseTypeGroupEntry, caseTypesApi } from "@/api/caseTypes";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class CaseTypesStore {
    private caseTypeGroups_: CaseTypeGroupEntry[] | null = null;
    private initLimiter = new ActionLimiter(true);

    async initialize() {
        await this.initLimiter.execute(async () => {
            this.caseTypeGroups_ = await caseTypesApi.getCaseTypes();
        });
    }

    get caseTypeGroups() {
        return this.caseTypeGroups_!;
    }
}

export const caseTypesStore = new CaseTypesStore();
