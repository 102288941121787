
import WorkingHoursTemplateFormDialog from "./WorkingHoursTemplateFormDialog.vue";
import WorkingHoursWeek from "./WorkingHoursWeek.vue";
import { Permission } from "@/api/userSession";
import { User, usersApi } from "@/api/users";
import { WorkingHours, workingHoursApi, WorkingHoursTemplate, WorkingHoursTemplateEntry } from "@/api/workingHours";
import { showConfirm } from "@/app/messageUtil";
import { getFullName } from "@/app/userUtils";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { getDaysOfWeek } from "@/util/dateTimeUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            formVisble: false,
            formItem: null as WorkingHoursTemplate | null,
            dealersStore,
            previewCount: 2,
            loading: true,
            user: null as User | null,
            workingHours: null as WorkingHours | null,
            getFullName,
        };
    },

    computed: {
        userId() {
            return this.$route.query.userId as string;
        },

        daysOfWeek(): SelectOptions {
            return getDaysOfWeek(configStore.configuration.defaultLocale, userSession.locale);
        },

        canEditWorkingHours() {
            return (
                userSession.hasPermission(Permission.WORKING_HOURS_MANAGEMENT) ||
                userSession.hasPermission(Permission.CHANGE_OWN_WORKING_HOURS)
            );
        },
    },

    methods: {
        closeDialog() {
            this.formVisble = false;
        },

        async savedItem() {
            this.formVisble = false;
            await this.load();
        },

        addTemplate() {
            this.formItem = null;
            this.formVisble = true;
        },

        editTemplate(workingHoursTemplate: WorkingHoursTemplate) {
            this.formItem = workingHoursTemplate;
            this.formVisble = true;
        },

        async load() {
            this.loading = true;

            try {
                this.workingHours = await workingHoursApi.getByUser(this.userId);
            } finally {
                this.loading = false;
            }
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },

        async setAsDefaultTemplate(id: string) {
            await workingHoursApi.updateTemplateSetDefault(this.userId, id);
            await this.load();
        },

        async deleteTemplate(id: string) {
            if (
                await showConfirm(
                    this.$t("Schichtvorlage löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Schichtvorlage löschen möchten?") as string
                )
            ) {
                this.loading = true;
                await workingHoursApi.deleteTemplate(this.userId, id);
                await this.load();
            }
        },

        increasePreviewCount(increment: number) {
            this.previewCount += increment;
        },

        getEntriesForDayOfWeek(
            workingHoursTemplate: WorkingHoursTemplate,
            dayOfWeek: number
        ): WorkingHoursTemplateEntry[] {
            const result = [] as WorkingHoursTemplateEntry[];

            for (const workingHoursTemplateEntry of workingHoursTemplate.workingHoursTemplateEntries) {
                if (workingHoursTemplateEntry.dayOfWeek === dayOfWeek) {
                    result.push(workingHoursTemplateEntry);
                }
            }

            return result.sort((a, b) => a.from.localeCompare(b.from, userSession.locale));
        },
    },

    watch: {
        async userId() {
            try {
                await this.load();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.load();
        this.user = await usersApi.getById(this.userId);
    },

    components: {
        WorkingHoursWeek,
        WorkingHoursTemplateFormDialog,
    },
});
