import { CaseChannel, CaseCreationForm, CaseUrgency } from "@/api/cases";
import { ContactSearchRequest } from "@/api/contactSearch";
import { ContactData, ContactMethod, ContactVehicleData } from "@/api/contacts";
import { ContactAndVehicleId } from "@/app/contactUtils";
import { $t } from "@/app/i18n";
import { EMPTY_SEARCH_REQUEST } from "@/app/pages/contacts/contactSearchUtils";
import {
    EMPTY_PREFERRED_CONTACT,
    getContactDetailsOptions,
    PreferredContact,
} from "@/app/pages/contacts/preferredContact";
import { UserPickerData } from "@/app/pages/users/userPickerCardTypes";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { reactive } from "@/util/reactive";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";

export enum CaseAssistantStep {
    CONTACT_SEARCH,
    CONTACT_DATA_ACQUISITION,
    CASE_DATA_ACQUISITION,
    RECEIVER_SELECTION,
}

export interface CaseAssistantState {
    readonly step: CaseAssistantStep;
    preferredContact: PreferredContact;
    preferredContactTimeZone: string | null;
    escalationBegin: Date | null;
    escalationBeginTimeZone: string | null;
    files: File[];
    dealerId: string | null;
    channel: CaseChannel | null;
    individualReceivers: UserPickerData;
    escalationGroupReceivers: string[];
    sentiment: number | null;
    appointmentTime: Date | null;
    subject: string;
    initialNoteContent: string | null;
    contactAndVehicleId: ContactAndVehicleId | null;
    contactSearchRequest: ContactSearchRequest;
    contactVehicleData: ContactVehicleData | null;
    contactData: ContactData | null;
    caseType: string | null;
    urgency: CaseUrgency;
    readonly isActivityContentDirty: boolean;

    resetActivityContent(): void;

    reset(contactId: string | null, contactVehicleId: string | null): void;
}

@reactive
class CaseAssistantStateImpl implements CaseAssistantState {
    step = CaseAssistantStep.CONTACT_SEARCH;
    preferredContact: PreferredContact = {
        ...EMPTY_PREFERRED_CONTACT,
        preferredContactMethod: ContactMethod.PHONE,
    };
    preferredContactTimeZone: string | null = null;
    escalationBegin: Date | null = null;
    escalationBeginTimeZone: string | null = null;
    files: File[] = [];
    dealerId: string | null = null;
    channel: CaseChannel | null = null;
    individualReceivers = new UserPickerData();
    escalationGroupReceivers: string[] = [];
    sentiment: number | null = null;
    appointmentTime: Date | null = null;
    subject = "";
    initialNoteContent: string | null = null;
    contactAndVehicleId: ContactAndVehicleId | null = null;
    contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
    contactVehicleData: ContactVehicleData | null = null;

    private contactData_: ContactData | null = null;
    private caseType_: string | null = null;
    private urgency_: CaseUrgency = CaseUrgency.NORMAL;

    get contactData() {
        return this.contactData_;
    }

    set contactData(contactData: ContactData | null) {
        this.contactData_ = contactData;

        const contactDetailsOptions = getContactDetailsOptions(
            this.preferredContact.preferredContactMethod,
            contactData?.emailAddresses ?? [],
            contactData?.numbers ?? []
        );

        if (!contactDetailsOptions.some((o) => o.value === this.preferredContact.preferredContactDetails)) {
            this.preferredContact.preferredContactDetails = null;
        }

        if (this.preferredContact.preferredContactDetails === null && contactDetailsOptions.length === 1) {
            this.preferredContact.preferredContactDetails = contactDetailsOptions[0].value;
        }
    }

    get caseType() {
        return this.caseType_;
    }

    set caseType(newValue) {
        const oldInitialNoteContentTemplate = this.caseType
            ? ($t(`caseTypeInitialNoteTemplate.${this.caseType}`) as string)
            : "";

        const wasDirty = this.isActivityContentDirty;
        this.caseType_ = newValue;

        const initialNoteContent = trimAndReturnNullIfEmpty(this.initialNoteContent);

        if (!wasDirty || initialNoteContent === null) {
            this.resetActivityContent();
            return;
        }

        if (initialNoteContent.indexOf(oldInitialNoteContentTemplate) !== 0) {
            return;
        }

        const newInitialNoteContentTemplate = newValue ? ($t(`caseTypeInitialNoteTemplate.${newValue}`) as string) : "";

        this.initialNoteContent = initialNoteContent.replace(
            oldInitialNoteContentTemplate,
            newInitialNoteContentTemplate
        );
    }

    get urgency() {
        return this.urgency_;
    }

    set urgency(newValue) {
        this.urgency_ = newValue;
        if (newValue !== CaseUrgency.PERSONAL_APPEARANCE) {
            this.appointmentTime = null;
        }
    }

    get isActivityContentDirty() {
        return this.caseType
            ? this.initialNoteContent !== ($t(`caseTypeInitialNoteTemplate.${this.caseType}`) as string)
            : !!this.initialNoteContent;
    }

    resetActivityContent() {
        this.initialNoteContent = this.caseType ? ($t(`caseTypeInitialNoteTemplate.${this.caseType}`) as string) : null;
    }

    get caseCreationForm(): CaseCreationForm | null {
        if (!this.dealerId || !this.channel || !this.caseType) {
            return null;
        }

        return {
            dealerId: this.dealerId,
            channel: this.channel,
            caseType: this.caseType,
            sentiment: this.sentiment,
            urgency: this.urgency,
            appointmentTime: this.appointmentTime,
            subject: this.subject,
            individualReceivers: this.individualReceivers.userIds,
            escalationGroupReceivers: this.escalationGroupReceivers,
            contactId: this.contactAndVehicleId?.contactId ?? null,
            contactVehicleId: this.contactAndVehicleId?.contactVehicleId ?? null,
            escalationBegin: this.escalationBegin,
            preferredContactMethod: this.preferredContact.preferredContactMethod,
            preferredContactDetails: this.preferredContact.preferredContactDetails,
            earliestContactTime: this.preferredContact.earliestContactTime,
            latestContactTime: this.preferredContact.latestContactTime,
            externalReferences: [],
        };
    }

    reset(contactId: string | null, contactVehicleId: string | null) {
        this.contactData = contactId !== this.contactAndVehicleId?.contactId ? null : this.contactData;
        this.contactVehicleData =
            contactVehicleId !== this.contactAndVehicleId?.contactVehicleId ? null : this.contactVehicleData;
        this.step = CaseAssistantStep.CONTACT_SEARCH;
        this.contactAndVehicleId = contactId ? { contactId, contactVehicleId } : null;
        this.contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);

        const dealerTimeZone = userSession.dealerId
            ? dealersStore.dealerById(userSession.dealerId)?.timeZone ?? null
            : null;

        this.preferredContact = {
            ...EMPTY_PREFERRED_CONTACT,
            preferredContactMethod: ContactMethod.PHONE,
        };
        this.preferredContactTimeZone = dealerTimeZone;
        this.escalationBegin = null;
        this.escalationBeginTimeZone = dealerTimeZone;

        this.files = [];
        this.dealerId = userSession.dealerId;
        this.channel = userSession.isCtUser() ? CaseChannel.PHONE_BDC : CaseChannel.PHONE_INTERNAL;
        this.caseType = null;
        this.individualReceivers = new UserPickerData();
        this.escalationGroupReceivers = [];
        this.sentiment = null;
        this.urgency_ = CaseUrgency.NORMAL;
        this.appointmentTime = null;
        this.subject = "";
        this.initialNoteContent = null;
    }
}

export const caseAssistantState = new CaseAssistantStateImpl();
