import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface Announcement {
    readonly id: string;
    readonly created: Date;
    readonly creatorUserId: string;
    readonly dealerIds: string[];
    readonly escalationGroupIds: string[];
    readonly opportunityTeamIds: string[];
    readonly confirmable: boolean;
    readonly announcementUsers: AnnouncementUser[];
    readonly expiry: Date | null;
    readonly title: string;
    readonly message: string;
}

export interface AnnouncementCreationForm {
    readonly dealerIds: string[];
    readonly escalationGroupIds: string[];
    readonly opportunityTeamIds: string[];
    readonly confirmable: boolean;
    readonly expiry: Date | null;
    readonly title: string;
    readonly message: string;
}

export interface AnnouncementModificationForm {
    readonly expiry: Date | null;
    readonly title: string;
    readonly message: string;
}

export interface AnnouncementUser {
    readonly userId: string;
    readonly read: Date | null;
    readonly confirmable: boolean;
    readonly confirmed: Date | null;
}

export interface AnnouncementUserStatus {
    readonly toRead: number;
    readonly toConfirm: number;
}

export interface AnnouncementUserView {
    readonly id: string;
    readonly created: Date;
    readonly creatorUserId: string;
    readonly dealerIds: string[];
    readonly confirmable: boolean;
    readonly announcementUser: AnnouncementUser | null;
    readonly expiry: Date | null;
    readonly title: string;
    readonly message: string;
}

interface AnnouncementsApi {
    add(announcementCreationForm: AnnouncementCreationForm): Promise<void>;
    confirm(id: string): Promise<void>;
    delete(id: string): Promise<void>;
    edit(id: string, announcementModificationForm: AnnouncementModificationForm): Promise<void>;
    getAnnouncements(): Promise<AnnouncementUserView[]>;
    getByDealer(dealerId: string | null, includeGlobalAnnouncements: boolean): Promise<Announcement[]>;
    getStatus(): Promise<AnnouncementUserStatus>;
    read(): Promise<void>;
}

export const announcementsApi: AnnouncementsApi = {
    async add(announcementCreationForm) {
        return await axios.post(`/api/announcement-management`, announcementCreationForm);
    },

    async confirm(id) {
        await axios.post(`/api/announcements/${id}/_confirm`);
    },

    async delete(id) {
        await axios.delete(`/api/announcement-management/${id}`);
    },

    async edit(id, announcementModificationForm) {
        await axios.put(`/api/announcement-management/${id}`, announcementModificationForm);
    },

    async getAnnouncements() {
        return cloneObject((await axios.get(`/api/announcements`)).data);
    },

    async getByDealer(dealerId, includeGlobalAnnouncements) {
        if (dealerId) {
            return cloneObject(
                (await axios.get(`/api/announcement-management`, { params: { dealerId, includeGlobalAnnouncements } }))
                    .data
            );
        } else {
            return cloneObject(
                (await axios.get(`/api/announcement-management`, { params: { includeGlobalAnnouncements } })).data
            );
        }
    },

    async getStatus() {
        return (await axios.get(`/api/announcements/status`)).data;
    },

    async read() {
        await axios.post(`/api/announcements/_read`);
    },
};
