
import { passwordPolicyApi } from "@/api/passwordPolicy";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { ActionLimiter } from "@/util/debounce";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            saveActionLimiter: new ActionLimiter(),
            length: 8,
            lowercase: 0,
            uppercase: 0,
            digit: 0,
            special: 0,
            forbiddenWords: [] as string[],
        };
    },

    computed: {
        forbiddenWordsAsText(): string {
            return this.forbiddenWords.join("\n");
        },
    },

    methods: {
        async normalizeAndSave(text: string) {
            this.forbiddenWords = text
                .split(/\s+/)
                .filter((s) => !!s)
                .map((s) => s.substring(0, 63));
            await this.save();
        },

        async save() {
            await this.saveActionLimiter.execute(() =>
                passwordPolicyApi.editPasswordPolicy({
                    minimumLength: this.length,
                    minimumRequiredLowercases: this.lowercase,
                    minimumRequiredUppercases: this.uppercase,
                    minimumRequiredDigits: this.digit,
                    minimumRequiredSpecials: this.special,
                    forbiddenWords: this.forbiddenWords,
                })
            );
        },
    },

    async mounted() {
        const passwordPolicy = await passwordPolicyApi.passwordPolicy();
        this.length = passwordPolicy.minimumLength;
        this.lowercase = passwordPolicy.minimumRequiredLowercases;
        this.uppercase = passwordPolicy.minimumRequiredUppercases;
        this.digit = passwordPolicy.minimumRequiredDigits;
        this.special = passwordPolicy.minimumRequiredSpecials;
        this.forbiddenWords = passwordPolicy.forbiddenWords;
    },

    components: {
        DealerGlobalContextHint,
    },
});
