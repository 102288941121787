export type DataHandler<T> = (data: T) => void;
export type ConnectionHandler = (connected: boolean) => void;
export type DataMapper<T> = (data: any) => T;

const INITIAL_RECONNECT_TIMEOUT_MIN = 5 * 1000;
const INITIAL_RECONNECT_TIMEOUT_MAX = 30 * 1000;
const ERROR_RECONNECT_TIMEOUT_MIN = 1 * 60 * 1000;
const ERROR_RECONNECT_TIMEOUT_MAX = 2 * 60 * 1000;
const REFRESH_TIMEOUT = 55 * 60 * 1000;
const MISSED_HEARTBEAT_TIMEOUT = 25 * 1000;

function randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export class ReconnectingEventSource<T> {
    private dataHandlers: Set<DataHandler<T>> = new Set();
    private connectionHandlers: Set<ConnectionHandler> = new Set();
    private eventSource?: EventSource;
    private pendingReconnect?: number;
    private pendingHeartbeatMissed?: number;
    private pendingRefresh?: number;

    constructor(private url: string, private dataMapper: DataMapper<T> = (data) => data) {}

    connect() {
        if (!this.eventSource) {
            this.eventSource = new EventSource(this.url, { withCredentials: true });
            this.registerHeartbeatMissedHandler();

            this.eventSource.onopen = () => {
                this.notifyConnectionHandlers(true);
                this.pendingRefresh = setTimeout(() => this.refresh(), REFRESH_TIMEOUT);
            };

            this.eventSource.onmessage = (e) => {
                this.deregisterHeartbeatMissedHandler();
                if (e.data) {
                    this.notifyDataHandlers(JSON.parse(e.data));
                }
                this.registerHeartbeatMissedHandler();
            };

            this.eventSource.onerror = (e) => {
                const state = (e.target as EventSource).readyState;
                this.disconnect();
                this.pendingReconnect = setTimeout(
                    () => this.connect(),
                    state === EventSource.CLOSED
                        ? randomInteger(INITIAL_RECONNECT_TIMEOUT_MIN, INITIAL_RECONNECT_TIMEOUT_MAX)
                        : randomInteger(ERROR_RECONNECT_TIMEOUT_MIN, ERROR_RECONNECT_TIMEOUT_MAX)
                );
            };
        }
    }

    disconnect() {
        this.deregisterHeartbeatMissedHandler();

        if (this.pendingReconnect !== undefined) {
            clearTimeout(this.pendingReconnect);
            this.pendingReconnect = undefined;
        }

        if (this.pendingRefresh !== undefined) {
            clearTimeout(this.pendingRefresh);
            this.pendingRefresh = undefined;
        }

        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = undefined;
            this.notifyConnectionHandlers(false);
        }
    }

    addDataHandler(dataHandler: DataHandler<T>) {
        this.dataHandlers.add(dataHandler);
        return dataHandler;
    }

    removeDataHandler(dataHandler: DataHandler<T>) {
        return this.dataHandlers.delete(dataHandler);
    }

    addConnectionHandler(connectionHandler: ConnectionHandler) {
        this.connectionHandlers.add(connectionHandler);
        return connectionHandler;
    }

    removeConnectionHandler(connectionHandler: ConnectionHandler) {
        return this.connectionHandlers.delete(connectionHandler);
    }

    private registerHeartbeatMissedHandler() {
        this.pendingHeartbeatMissed = setTimeout(() => {
            // heartbeat not received in time, reconnect to event source
            this.disconnect();
            this.pendingReconnect = setTimeout(
                () => this.connect(),
                randomInteger(ERROR_RECONNECT_TIMEOUT_MIN, ERROR_RECONNECT_TIMEOUT_MAX)
            );
        }, MISSED_HEARTBEAT_TIMEOUT);
    }

    private deregisterHeartbeatMissedHandler() {
        if (this.pendingHeartbeatMissed !== undefined) {
            clearTimeout(this.pendingHeartbeatMissed);
            this.pendingHeartbeatMissed = undefined;
        }
    }

    private notifyDataHandlers(data: any) {
        data = this.dataMapper(data);
        for (const dataHandler of this.dataHandlers) {
            try {
                dataHandler(data);
            } catch (e) {
                // ignore
            }
        }
    }

    private notifyConnectionHandlers(connected: boolean) {
        for (const connectionHandler of this.connectionHandlers) {
            try {
                connectionHandler(connected);
            } catch (e) {
                // ignore
            }
        }
    }

    private refresh() {
        const newEventSource = new EventSource(this.url, { withCredentials: true });
        newEventSource.onopen = () => {
            if (this.eventSource) {
                newEventSource.onmessage = this.eventSource.onmessage;
                newEventSource.onerror = this.eventSource.onerror;
                this.eventSource.close();
                this.eventSource = newEventSource;
                this.pendingRefresh = setTimeout(() => this.refresh(), REFRESH_TIMEOUT);
            } else {
                newEventSource.close();
            }
        };
    }
}
