import { FinanceContract, ProcessingState } from "./financeContracts";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum FinanceContractSearchOrder {
    FINAL_INSTALLMENT_PAYMENT_DATE_ASC = "FINAL_INSTALLMENT_PAYMENT_DATE_ASC",
    FINAL_INSTALLMENT_PAYMENT_DATE_DESC = "FINAL_INSTALLMENT_PAYMENT_DATE_DESC",
}

export interface FinanceContractSearchRequest {
    readonly dealerIds: string[];
    readonly uploadSourceIds: string[];
    readonly processingStates: ProcessingState[];
    readonly finalInstallmentPaymentDateFrom: string | null;
    readonly finalInstallmentPaymentDateTo: string | null;
    readonly processFrom: string | null;
    readonly processTo: string | null;
    readonly hasFinalInstallmentPaymentDate: boolean | null;
    readonly search: string | null;
    readonly sortBy: FinanceContractSearchOrder;
}

export interface FinanceContractSearchResults {
    readonly results: FinanceContract[];
    readonly searchId: number;
    readonly totalSize: number;
}

export interface FinanceContractSearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

interface FinanceContractSearchApi {
    search(
        start: number,
        size: number,
        searchRequest: FinanceContractSearchRequest,
        searchId: number
    ): Promise<FinanceContractSearchResults>;
    rebuild(): Promise<void>;
    size(): Promise<FinanceContractSearchIndexSize>;
}

export const financeContractSearchApi: FinanceContractSearchApi = {
    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/finance-contract-search/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },

    async rebuild() {
        await axios.post(`/api/finance-contract-search/_rebuild`);
    },

    async size() {
        return (await axios.get(`/api/finance-contract-search/_size`)).data;
    },
};
