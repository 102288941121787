import { IncomingCallDistributionType } from "./callTrackingGroups";
import axios from "axios";

export enum AutoAnswerType {
    NONE = "NONE",
    PLAY_ANNOUNCEMENT = "PLAY_ANNOUNCEMENT",
    TRANSFER_TO_CUSTOM_NUMBER = "TRANSFER_TO_CUSTOM_NUMBER",
    TRANSFER_TO_EXTERNAL_BDC = "TRANSFER_TO_EXTERNAL_BDC",
    TRANSFER_VIA_DTMF_MENU = "TRANSFER_VIA_DTMF_MENU",
}

export enum IncomingPhoneNumberStatus {
    AVAILABLE = "AVAILABLE",
    INSTALLED = "INSTALLED",
    INSTALLED_INVALID = "INSTALLED_INVALID",
    INSTALLED_NOT_FOUND = "INSTALLED_NOT_FOUND",
    INSTALLED_DEALERDESK_LEGACY = "INSTALLED_DEALERDESK_LEGACY",
    INSTALLED_OTHER = "INSTALLED_OTHER",
}

export enum IssueType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
}

export interface IncomingPhoneNumber {
    readonly id: string;
    readonly number: string;
    readonly aliasNumber: string | null;
    readonly voiceCapability: boolean;
    readonly smsCapability: boolean;
    readonly fallbackNumber: string;
    readonly fallbackMessage: string;
    readonly status: IncomingPhoneNumberStatus;
}

export interface DtmfMenuOption {
    readonly index: number;
    readonly phoneNumber: string;
}

export interface InternalIncomingPhoneNumber {
    readonly id: string;
    readonly number: string;
    readonly aliasNumber: string | null;
    readonly voiceCapability: boolean;
    readonly smsCapability: boolean;
    readonly fallbackNumber: string;
    readonly fallbackMessage: string;
    readonly name: string | null;
    readonly dealerId: string | null;
    readonly callTrackingGroupId: string | null;
    readonly distributeToAssignees: boolean | null;
    readonly targetUserIds: string[];
    readonly distributionOfficeHoursId: string | null;
    readonly distributeOnlyIfAvailable: boolean | null;
    readonly distributeOnlyIfInWorkingHours: boolean | null;
    readonly displayCallersId: boolean | null;
    readonly callAttemptTimeoutSeconds: number | null;
    readonly distributionType: IncomingCallDistributionType | null;
    readonly distributionTimeoutSeconds: number | null;
    readonly autoAnswerType: AutoAnswerType | null;
    readonly autoAnswerAnnouncementHash: string | null;
    readonly autoAnswerAnnouncementContentType: string | null;
    readonly autoAnswerAnnouncementText: string | null;
    readonly autoAnswerCustomNumber: string | null;
    readonly autoAnswerDtmfMenuOptions: DtmfMenuOption[];
    readonly autoAnswerInParallelDuringDistribution: boolean | null;
    readonly assignIssueToAcceptor: boolean | null;
    readonly createIssueIfAnswered: boolean | null;
    readonly createIssueIfUnanswered: boolean | null;
    readonly issueType: IssueType | null;
    readonly individualReceivers: string[];
    readonly caseType: string | null;
    readonly escalationGroupReceivers: string[];
    readonly opportunitySourceId: string | null;
    readonly opportunityTeamReceivers: string[];
}

export interface InternalIncomingPhoneNumberConfigurationForm {
    readonly name: string;
    readonly dealerId: string;
    readonly callTrackingGroupId: string | null;
    readonly distributeToAssignees: boolean;
    readonly targetUserIds: string[];
    readonly distributionOfficeHoursId: string | null;
    readonly distributeOnlyIfAvailable: boolean | null;
    readonly distributeOnlyIfInWorkingHours: boolean | null;
    readonly displayCallersId: boolean;
    readonly callAttemptTimeoutSeconds: number | null;
    readonly distributionType: IncomingCallDistributionType | null;
    readonly distributionTimeoutSeconds: number | null;
    readonly autoAnswerType: AutoAnswerType;
    readonly autoAnswerAnnouncementHash: string | null;
    readonly autoAnswerAnnouncementContentType: string | null;
    readonly autoAnswerAnnouncementText: string | null;
    readonly autoAnswerCustomNumber: string | null;
    readonly autoAnswerDtmfMenuOptions: DtmfMenuOption[];
    readonly autoAnswerInParallelDuringDistribution: boolean | null;
    readonly assignIssueToAcceptor: boolean;
    readonly createIssueIfAnswered: boolean;
    readonly createIssueIfUnanswered: boolean;
    readonly issueType: IssueType;
    readonly individualReceivers: string[];
    readonly caseType: string | null;
    readonly escalationGroupReceivers: string[];
    readonly opportunitySourceId: string | null;
    readonly opportunityTeamReceivers: string[];
}

export interface InternalIncomingPhoneNumberInstallationForm {
    readonly id: string;
    readonly number: string;
    readonly aliasNumber: string | null;
    readonly voiceCapability: boolean;
    readonly smsCapability: boolean;
    readonly fallbackNumber: string;
    readonly fallbackMessage: string;
}

export interface InternalIncomingPhoneNumberForm {
    aliasNumber: string | null;
    fallbackNumber: string;
    fallbackMessage: string;
}

export interface IncomingPhoneNumbersApi {
    deleteIncomingPhoneNumberConfiguration(id: string): Promise<void>;
    editIncomingPhoneNumber(
        id: string,
        internalIncomingPhoneNumberForm: InternalIncomingPhoneNumberForm
    ): Promise<void>;
    editInternalIncomingPhoneNumberConfiguration(
        id: string,
        internalIncomingPhoneNumberConfigurationForm: InternalIncomingPhoneNumberConfigurationForm,
        announcementFile?: File | null
    ): Promise<void>;
    getAll(): Promise<IncomingPhoneNumber[]>;
    getAllInternal(): Promise<InternalIncomingPhoneNumber[]>;
    installIncomingPhoneNumber(form: InternalIncomingPhoneNumberInstallationForm): Promise<void>;
    uninstallIncomingPhoneNumber(id: string): Promise<void>;
    fixIncomingPhoneNumber(id: string): Promise<void>;
    fixAllInvalidIncomingPhoneNumbers(): Promise<void>;
    generateAutoAnswerAnnouncementLink(id: string, hash: string): string;
}

export const incomingPhoneNumbersApi: IncomingPhoneNumbersApi = {
    async deleteIncomingPhoneNumberConfiguration(id) {
        await axios.delete(`/api/incoming-phone-numbers/${id}/_edit/internal`);
    },

    async editIncomingPhoneNumber(id, internalIncomingPhoneNumberForm) {
        await axios.patch(`/api/incoming-phone-numbers/${id}/_edit`, internalIncomingPhoneNumberForm);
    },

    async editInternalIncomingPhoneNumberConfiguration(
        id,
        internalIncomingPhoneNumberConfigurationForm,
        announcementFile
    ) {
        const formData = new FormData();
        if (announcementFile) {
            formData.append("announcement", announcementFile);
        }
        formData.append(
            "form",
            new Blob([JSON.stringify(internalIncomingPhoneNumberConfigurationForm)], { type: "application/json" })
        );
        await axios.patch(`/api/incoming-phone-numbers/${id}/_edit/internal`, formData);
    },

    async getAll() {
        return (await axios.get("/api/incoming-phone-numbers")).data;
    },

    async getAllInternal() {
        return (await axios.get("/api/incoming-phone-numbers/_/internal")).data;
    },

    async installIncomingPhoneNumber(form) {
        await axios.post("/api/incoming-phone-numbers", form);
    },

    async uninstallIncomingPhoneNumber(id) {
        await axios.delete(`/api/incoming-phone-numbers/${id}/_uninstall`);
    },

    async fixIncomingPhoneNumber(id) {
        await axios.post(`/api/incoming-phone-numbers/${id}/_fix`);
    },

    async fixAllInvalidIncomingPhoneNumbers() {
        await axios.post("/api/incoming-phone-numbers/_fix");
    },

    generateAutoAnswerAnnouncementLink(id, hash) {
        return `/api/incoming-phone-numbers/${id}/auto-answer-announcement/${hash}`;
    },
};
