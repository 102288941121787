
import DataTablePagination from "./DataTablePagination.vue";
import { DataTableHeader, DataTablePaging } from "./dataTable";
import Vue from "vue";
import { DataTableHeader as VuetifyDataTableHeader } from "vuetify";

export default Vue.extend({
    props: {
        canSelectPageSize: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        hideTopPagination: {
            type: Boolean,
            default: false,
        },
        headers: {
            type: Array as () => DataTableHeader[],
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        noItemsText: {
            type: String as () => string | null,
            default: null,
        },
        paging: {
            type: Object as () => DataTablePaging | undefined,
            required: false,
        },
        search: {
            type: String as () => string | undefined,
            required: false,
        },
        searchFilter: {
            type: Function as () => Function | undefined,
            required: false,
        },
    },

    computed: {
        vDataTableHeaders(): VuetifyDataTableHeader[] {
            return this.headers.map((header) => ({
                text: (header.text ?? "") as string,
                value: "",
                align:
                    header.align === "left"
                        ? "start"
                        : header.align === "center"
                        ? "center"
                        : header.align === "right"
                        ? "end"
                        : undefined,
                sortable: false,
                width: header.width,
            }));
        },

        showSelectHeader(): boolean {
            return !!this.$slots["select-header"];
        },
    },

    methods: {
        filter(_: unknown, search: string, item: unknown) {
            return this.searchFilter!(search, item);
        },
    },

    components: {
        DataTablePagination,
    },
});
