import { EMPTY_SEARCH_REQUEST } from "./opportunitySearchUtils";
import { UsageType } from "@/api/inventory";
import { OpportunityChannel, OpportunityPriority, OpportunityStatus, OpportunityUrgency } from "@/api/opportunities";
import { ActorType, OpportunitySearchOrder, OpportunitySearchRequest, SentimentType } from "@/api/opportunitySearch";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { makeModelsStore } from "@/store/makeModels";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";

export interface OpportunitiesOverviewFilters {
    readonly dealerIds?: string[];
    readonly sourceIds?: string[];
    readonly status?: OpportunityStatus[];
    readonly channels?: OpportunityChannel[];
    readonly sentimentTypes?: SentimentType[];
    readonly urgencies?: OpportunityUrgency[];
    readonly priority?: OpportunityPriority[];
    readonly creatorActorType?: ActorType[];
    readonly createdBy?: string[];
    readonly closerActorType?: ActorType[];
    readonly closedBy?: string[];
    readonly outcome?: boolean | null;
    readonly outcomeReasonIds?: (string | null)[];
    readonly assignees?: (string | null)[];
    readonly contactId?: string | null;
    readonly createdFrom?: string | null;
    readonly createdTo?: string | null;
    readonly lastActivityFrom?: string | null;
    readonly lastActivityTo?: string | null;
    readonly individualReceivers?: (string | null)[];
    readonly opportunityTeamReceivers?: (string | null)[];
    readonly hasReceivers?: boolean | null;
    readonly inventoryVehicleIds?: (string | null)[];
    readonly makes?: string[];
    readonly models?: string[];
    readonly usageTypes?: UsageType[];
    readonly search?: string | null;
}

export interface OpportunitiesOverviewState {
    readonly searchRequest: OpportunitySearchRequest;
    readonly initialized: boolean;
    dealerIds: string[];
    sourceIds: string[];
    status: OpportunityStatus[];
    channels: OpportunityChannel[];
    sentimentTypes: SentimentType[];
    urgencies: OpportunityUrgency[];
    priority: OpportunityPriority[];
    creatorActorType: ActorType[];
    createdBy: string[];
    closerActorType: ActorType[];
    closedBy: string[];
    outcome: boolean | null;
    outcomeReasonIds: (string | null)[];
    assignees: (string | null)[];
    contactId: string | null;
    createdFrom: string | null;
    createdTo: string | null;
    lastActivityFrom: string | null;
    lastActivityTo: string | null;
    individualReceivers: (string | null)[];
    opportunityTeamReceivers: (string | null)[];
    hasReceivers: boolean | null;
    inventoryVehicleIds: (string | null)[];
    makes: string[];
    models: string[];
    usageTypes: UsageType[];
    search: string | null;
    sortBy: OpportunitySearchOrder;
    readonly tz: string;
    readonly todayQuickfilter: OpportunitySearchRequest;
    readonly isTodayQuickfilter: boolean;
    readonly unclosedQuickfilter: OpportunitySearchRequest;
    readonly isUnclosedQuickfilter: boolean;
    readonly openQuickfilter: OpportunitySearchRequest;
    readonly isOpenQuickfilter: boolean;
    readonly allQuickfilter: OpportunitySearchRequest;
    readonly isAllQuickfilter: boolean;
    readonly highPriorityQuickfilter: OpportunitySearchRequest;
    readonly isHighPriorityQuickfilter: boolean;
    readonly openNoReceiversQuickfilter: OpportunitySearchRequest;
    readonly isOpenNoReceiversQuickfilter: boolean;

    refresh(): void;
    setTodayQuickfilter(): void;
    setUnclosedQuickfilter(): void;
    setOpenQuickfilter(): void;
    setAllQuickfilter(): void;
    setHighPriorityQuickfilter(): void;
    setOpenNoReceiversQuickfilter(): void;
    restoreFilters(settings: OpportunitiesOverviewFilters): void;
}

@reactive
class OpportunitiesOverviewStateImpl implements OpportunitiesOverviewState {
    private searchRequest_: OpportunitySearchRequest = EMPTY_SEARCH_REQUEST;
    private initialized_ = false;

    get searchRequest() {
        return this.searchRequest_;
    }

    get initialized() {
        return this.initialized_;
    }

    set dealerIds(dealerIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest_, dealerIds };
    }

    get dealerIds() {
        return this.searchRequest_.dealerIds;
    }

    set sourceIds(sourceIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest_, sourceIds };
    }

    get sourceIds() {
        return this.searchRequest_.sourceIds;
    }

    set status(status: OpportunityStatus[]) {
        this.searchRequest_ = { ...this.searchRequest_, status };
    }

    get status() {
        return this.searchRequest_.status;
    }

    set channels(channels: OpportunityChannel[]) {
        this.searchRequest_ = { ...this.searchRequest_, channels };
    }

    get channels() {
        return this.searchRequest_.channels;
    }

    set sentimentTypes(sentimentTypes: SentimentType[]) {
        this.searchRequest_ = { ...this.searchRequest_, sentimentTypes };
    }

    get sentimentTypes() {
        return this.searchRequest_.sentimentTypes;
    }

    set urgencies(urgencies: OpportunityUrgency[]) {
        this.searchRequest_ = { ...this.searchRequest, urgencies };
    }

    get urgencies() {
        return this.searchRequest.urgencies;
    }

    set priority(priority: OpportunityPriority[]) {
        this.searchRequest_ = { ...this.searchRequest, priority };
    }

    get priority() {
        return this.searchRequest.priority;
    }

    set creatorActorType(creatorActorType: ActorType[]) {
        this.searchRequest_ = { ...this.searchRequest_, creatorActorType };
    }

    get creatorActorType() {
        return this.searchRequest_.creatorActorType;
    }

    set createdBy(createdBy: string[]) {
        this.searchRequest_ = { ...this.searchRequest_, createdBy };
    }

    get createdBy() {
        return this.searchRequest_.createdBy;
    }

    set closerActorType(closerActorType: ActorType[]) {
        this.searchRequest_ = { ...this.searchRequest_, closerActorType };
    }

    get closerActorType() {
        return this.searchRequest_.closerActorType;
    }

    set closedBy(closedBy: string[]) {
        this.searchRequest_ = { ...this.searchRequest_, closedBy };
    }

    get closedBy() {
        return this.searchRequest_.closedBy;
    }

    set outcome(outcome: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest_, outcome };
    }

    get outcome() {
        return this.searchRequest_.outcome;
    }

    set outcomeReasonIds(outcomeReasonIds: (string | null)[]) {
        this.searchRequest_ = { ...this.searchRequest_, outcomeReasonIds };
    }

    get outcomeReasonIds() {
        return this.searchRequest_.outcomeReasonIds;
    }

    set assignees(assignees: (string | null)[]) {
        this.searchRequest_ = { ...this.searchRequest_, assignees };
    }

    get assignees() {
        return this.searchRequest_.assignees;
    }

    set contactId(contactId: string | null) {
        this.searchRequest_ = { ...this.searchRequest_, contactIds: contactId ? [contactId] : [] };
    }

    get contactId() {
        return this.searchRequest.contactIds.length ? this.searchRequest.contactIds[0] : null;
    }

    set createdFrom(createdFrom: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest_,
            createdFrom: createdFrom ? toDateObject(this.tz, createdFrom) : null,
        };
    }

    get createdFrom() {
        return this.searchRequest_.createdFrom ? getDate(this.searchRequest_.createdFrom, this.tz) : null;
    }

    set createdTo(createdTo: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest_,
            createdTo: createdTo ? toDateObject(this.tz, createdTo, 1) : null,
        };
    }

    get createdTo() {
        return this.searchRequest_.createdTo ? getDate(this.searchRequest_.createdTo, this.tz, -1) : null;
    }

    set lastActivityFrom(lastActivityFrom: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest_,
            lastActivityFrom: lastActivityFrom ? toDateObject(this.tz, lastActivityFrom) : null,
        };
    }

    get lastActivityFrom() {
        return this.searchRequest_.lastActivityFrom ? getDate(this.searchRequest_.lastActivityFrom, this.tz) : null;
    }

    set lastActivityTo(lastActivityTo: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest_,
            lastActivityTo: lastActivityTo ? toDateObject(this.tz, lastActivityTo, 1) : null,
        };
    }

    get lastActivityTo() {
        return this.searchRequest_.lastActivityTo ? getDate(this.searchRequest_.lastActivityTo, this.tz, -1) : null;
    }

    set individualReceivers(individualReceivers: (string | null)[]) {
        this.searchRequest_ = { ...this.searchRequest_, individualReceivers };
    }

    get individualReceivers() {
        return this.searchRequest_.individualReceivers;
    }

    set opportunityTeamReceivers(opportunityTeamReceivers: (string | null)[]) {
        this.searchRequest_ = { ...this.searchRequest_, opportunityTeamReceivers };
    }

    get opportunityTeamReceivers() {
        return this.searchRequest_.opportunityTeamReceivers;
    }

    set hasReceivers(hasReceivers: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest_, hasReceivers };
    }

    get hasReceivers() {
        return this.searchRequest_.hasReceivers;
    }

    set inventoryVehicleIds(inventoryVehicleIds: (string | null)[]) {
        this.searchRequest_ = { ...this.searchRequest_, inventoryVehicleIds };
    }

    get inventoryVehicleIds() {
        return this.searchRequest_.inventoryVehicleIds;
    }

    set makes(makes: string[]) {
        if (makes.length) {
            const allowedModels = makes.map((m) => makeModelsStore.getModelsByMake(m)).reduce((p, c) => [...p, ...c]);
            this.searchRequest_ = {
                ...this.searchRequest_,
                models: this.searchRequest_.models.filter((m) => allowedModels.includes(m)),
            };
        }
        this.searchRequest_ = { ...this.searchRequest_, makes };
    }

    get makes() {
        return this.searchRequest_.makes;
    }

    set models(models: string[]) {
        this.searchRequest_ = { ...this.searchRequest_, models };
    }

    get models() {
        return this.searchRequest_.models;
    }

    set usageTypes(usageTypes: UsageType[]) {
        this.searchRequest_ = { ...this.searchRequest_, usageTypes };
    }

    get usageTypes() {
        return this.searchRequest_.usageTypes;
    }

    set search(search: string | null) {
        this.searchRequest_ = { ...this.searchRequest_, search };
    }

    get search() {
        return this.searchRequest_.search;
    }

    set sortBy(sortBy: OpportunitySearchOrder) {
        this.searchRequest_ = { ...this.searchRequest_, sortBy };
    }

    get sortBy() {
        return this.searchRequest_.sortBy;
    }

    get tz() {
        if (!userSession.mainDealerId) {
            return configStore.configuration.defaultTimeZone;
        } else {
            return (
                dealersStore.dealerById(userSession.mainDealerId)?.timeZone || configStore.configuration.defaultTimeZone
            );
        }
    }

    refresh() {
        this.searchRequest_ = cloneObject(this.searchRequest_);
    }

    get todayQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            createdFrom: toDateObject(this.tz, getDate(now(), this.tz)),
            createdTo: toDateObject(this.tz, getDate(now(), this.tz, 1)),
        };
    }

    setTodayQuickfilter() {
        this.searchRequest_ = this.todayQuickfilter;
        this.initialized_ = true;
    }

    get isTodayQuickfilter() {
        return JSON.stringify(this.todayQuickfilter) === JSON.stringify(this.searchRequest_);
    }

    get unclosedQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            status: [OpportunityStatus.OPEN, OpportunityStatus.ASSIGNED, OpportunityStatus.POSTPONED],
        };
    }

    setUnclosedQuickfilter() {
        this.searchRequest_ = this.unclosedQuickfilter;
        this.initialized_ = true;
    }

    get isUnclosedQuickfilter() {
        return JSON.stringify(this.unclosedQuickfilter) === JSON.stringify(this.searchRequest_);
    }

    get openQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            status: [OpportunityStatus.OPEN],
        };
    }

    setOpenQuickfilter() {
        this.searchRequest_ = this.openQuickfilter;
        this.initialized_ = true;
    }

    get isOpenQuickfilter() {
        return JSON.stringify(this.openQuickfilter) === JSON.stringify(this.searchRequest_);
    }

    get allQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
        };
    }

    setAllQuickfilter() {
        this.searchRequest_ = this.allQuickfilter;
        this.initialized_ = true;
    }

    get isAllQuickfilter() {
        return JSON.stringify(this.allQuickfilter) === JSON.stringify(this.searchRequest_);
    }

    get highPriorityQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            priority: [OpportunityPriority.HIGH],
        };
    }

    setHighPriorityQuickfilter() {
        this.searchRequest_ = this.highPriorityQuickfilter;
        this.initialized_ = true;
    }

    get isHighPriorityQuickfilter() {
        return JSON.stringify(this.highPriorityQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get openNoReceiversQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            hasReceivers: false,
            status: [OpportunityStatus.OPEN],
        };
    }

    setOpenNoReceiversQuickfilter() {
        this.searchRequest_ = this.openNoReceiversQuickfilter;
        this.initialized_ = true;
    }

    get isOpenNoReceiversQuickfilter() {
        return JSON.stringify(this.openNoReceiversQuickfilter) === JSON.stringify(this.searchRequest_);
    }

    restoreFilters(filters: OpportunitiesOverviewFilters) {
        this.searchRequest_ = EMPTY_SEARCH_REQUEST;

        this.dealerIds = filters.dealerIds ?? this.dealerIds;
        this.sourceIds = filters.sourceIds ?? this.sourceIds;
        this.status = filters.status ?? this.status;
        this.channels = filters.channels ?? this.channels;
        this.sentimentTypes = filters.sentimentTypes ?? this.sentimentTypes;
        this.urgencies = filters.urgencies ?? this.urgencies;
        this.priority = filters.priority ?? this.priority;
        this.creatorActorType = filters.creatorActorType ?? this.creatorActorType;
        this.createdBy = filters.createdBy ?? this.createdBy;
        this.closerActorType = filters.closerActorType ?? this.closerActorType;
        this.closedBy = filters.closedBy ?? this.closedBy;
        this.outcome = filters.outcome ?? this.outcome;
        this.outcomeReasonIds = filters.outcomeReasonIds ?? this.outcomeReasonIds;
        this.assignees = filters.assignees ?? this.assignees;
        this.contactId = filters.contactId ?? this.contactId;
        this.createdFrom = filters.createdFrom ?? this.createdFrom;
        this.createdTo = filters.createdTo ?? this.createdTo;
        this.lastActivityFrom = filters.lastActivityFrom ?? this.lastActivityFrom;
        this.lastActivityTo = filters.lastActivityTo ?? this.lastActivityTo;
        this.individualReceivers = filters.individualReceivers ?? this.individualReceivers;
        this.opportunityTeamReceivers = filters.opportunityTeamReceivers ?? this.opportunityTeamReceivers;
        this.hasReceivers = filters.hasReceivers ?? this.hasReceivers;
        this.inventoryVehicleIds = filters.inventoryVehicleIds ?? this.inventoryVehicleIds;
        this.makes = filters.makes ?? this.makes;
        this.models = filters.models ?? this.models;
        this.usageTypes = filters.usageTypes ?? this.usageTypes;
        this.search = filters.search ?? this.search;

        this.initialized_ = true;
    }
}

export const opportunitiesOverviewState: OpportunitiesOverviewState = new OpportunitiesOverviewStateImpl();
