
import { Dealer } from "@/api/dealers";
import { EmergencyIncidentType } from "@/api/emergencyIncidents";
import { Partner, partnersApi } from "@/api/partners";
import EnumField from "@/app/components/EnumField.vue";
import { getCountryCaption, getStateCaption } from "@/app/countryStateUtils";
import { EmergencyIncidentAssistantState } from "@/app/pages/emergencyincidents/assistant/emergencyIncidentAssistant";
import { maxLength } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        emergencyIncidentAssistantState: {
            type: Object as () => EmergencyIncidentAssistantState,
            required: true,
        },
    },

    data() {
        return {
            EmergencyIncidentType,
            partners: [] as Partner[],
        };
    },

    computed: {
        dealerAutocompleteValues(): string[] {
            return dealersStore.dealers.filter((d) => d.active).map((d) => this.getAutocompleteValue(d));
        },

        maxLength() {
            return maxLength;
        },

        partnerAutocompleteValues(): string[] {
            return this.partners.map((p) => this.getAutocompleteValue(p));
        },
    },

    methods: {
        getAutocompleteValue(address: Dealer | Partner): string {
            return [
                address.name,
                address.address1,
                address.address2,
                address.zip,
                address.city,
                address.state && address.country ? getStateCaption(address.country, address.state) : null,
                address.country ? getCountryCaption(address.country) : null,
            ]
                .filter((v) => !!v)
                .filter((v, pos, array) => pos === 0 || array[pos - 1] !== v)
                .join(", ");
        },

        async loadPartners() {
            this.partners = [];

            if (this.emergencyIncidentAssistantState.dealerId) {
                this.partners = await partnersApi.getByDealer(this.emergencyIncidentAssistantState.dealerId);
            }
        },
    },

    watch: {
        async "emergencyIncidentAssistantState.dealerId"() {
            try {
                await this.loadPartners();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadPartners();
    },

    components: {
        EnumField,
    },
});
