
import { CaseUrgency } from "@/api/cases";
import { ContactMethod } from "@/api/contacts";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import CountDown from "@/app/components/CountDown.vue";
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import { getContactDetailsLabel, PreferredContact } from "@/app/pages/contacts/preferredContact";
import { dealersStore } from "@/store/dealers";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            CaseUrgency,
            ContactMethod,
            parseAndFormatNumber,
        };
    },

    computed: {
        contactDetailsLabel(): string | null {
            return getContactDetailsLabel(this.preferredContact.preferredContactMethod);
        },

        dealerTimeZone(): string {
            return dealersStore.dealerById(this.caseAssistantState.dealerId!)!.timeZone;
        },

        preferredContact(): PreferredContact {
            return this.caseAssistantState.preferredContact;
        },
    },

    components: {
        CopyToClipboardIcon,
        CountDown,
    },
});
