
import { Contact } from "@/api/contacts";
import { Forbidden, NotFound } from "@/api/errors";
import {
    IncomingEmail,
    IncomingEmailAndContact,
    IncomingEmailAttachment,
    IncomingEmailReceiver,
    IncomingEmailReceiverType,
    incomingEmailsApi,
    IssueId,
    ProcessingState,
} from "@/api/incomingEmails";
import { isIncomingEmailUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { Permission } from "@/api/userSession";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { renderContactCaption } from "@/app/contactUtils";
import { canReportSpam, writeHtmlToIframe } from "@/app/emailUtils";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

enum HtmlBodyError {
    LOADING_HTML_BODY_FAILED = "LOADING_HTML_BODY_FAILED",
    HTML_BODY_EMPTY = "HTML_BODY_EMPTY",
    WRITING_HTML_BODY_TO_IFRAME_FAILED = "WRITING_HTML_BODY_TO_IFRAME_FAILED",
}

export default Vue.extend({
    data() {
        return {
            activeTab: 0,
            forbidden: false,
            htmlBody: null as string | null,
            htmlBodyError: null as HtmlBodyError | null,
            htmlBodyLoaded: false,
            incomingEmailAndContact: null as IncomingEmailAndContact | null,
            image: "",
            loading: true,
            loadingHtmlBody: true,
            notificationHandler: null as ((n: Notification) => void) | null,
            ProcessingState,
            reportingSpam: false,
            writing: false,
        };
    },

    computed: {
        canManageIncomingEmail(): boolean {
            if (!this.incomingEmail) {
                return false;
            }

            return userSession.hasPermission(Permission.MANAGE_INCOMING_EMAILS);
        },

        canReportSpam(): boolean {
            if (!this.incomingEmail) {
                return false;
            }

            return canReportSpam(this.incomingEmail);
        },

        contact(): Contact | null {
            return this.incomingEmailAndContact?.contact || null;
        },

        creationTimestamp(): string | null {
            if (!this.incomingEmail) {
                return null;
            }

            return formatInstant(this.incomingEmail.created, userSession.timeZone, userSession.locale, "S");
        },

        bccReceivers(): IncomingEmailReceiver[] {
            if (!this.incomingEmail) {
                return [];
            }

            return this.incomingEmail.receivers.filter((r) => r.type === IncomingEmailReceiverType.BCC);
        },

        ccReceivers(): IncomingEmailReceiver[] {
            if (!this.incomingEmail) {
                return [];
            }

            return this.incomingEmail.receivers.filter((r) => r.type === IncomingEmailReceiverType.CC);
        },

        incomingEmail(): IncomingEmail | null {
            return this.incomingEmailAndContact?.incomingEmail || null;
        },

        htmlBodyErrorColor(): string | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return "error";
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return "info";
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return "error";
            } else {
                return null;
            }
        },

        htmlBodyErrorRetry(): Function | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return () => this.loadHtmlBody();
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return () => this.loadHtmlBody();
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return () => this.writeHtmlBodyToIframe();
            } else {
                return null;
            }
        },

        htmlBodyErrorRetrying(): boolean {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return this.loading;
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return this.loading;
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return this.writing;
            } else {
                return false;
            }
        },

        htmlBodyErrorText(): string | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return this.$t("Es ist ein Fehler beim Laden der E-Mail aufgetreten.") as string;
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return this.$t("Der Inhalt der E-Mail wurde nicht gefunden.") as string;
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return this.$t("Es ist ein Fehler beim Darstellen der E-Mail aufgetreten.") as string;
            } else {
                return null;
            }
        },

        issueId(): IssueId | null {
            if (!this.incomingEmail) {
                return null;
            } else if (this.incomingEmail.caseId) {
                return { caseId: this.incomingEmail.caseId };
            } else if (this.incomingEmail.opportunityId) {
                return { opportunityId: this.incomingEmail.opportunityId };
            } else {
                return null;
            }
        },

        spamReportedTimestamp(): string | null {
            if (!this.incomingEmail?.spamReported) {
                return null;
            }

            return formatInstant(this.incomingEmail.spamReported, userSession.timeZone, userSession.locale, "S");
        },

        toReceivers(): IncomingEmailReceiver[] {
            if (!this.incomingEmail) {
                return [];
            }

            return this.incomingEmail.receivers.filter((r) => r.type === IncomingEmailReceiverType.TO);
        },

        statusColor(): string | null {
            if (!this.incomingEmail) {
                return null;
            }

            if (this.incomingEmail.processingState === ProcessingState.UNPROCESSED) {
                return null;
            } else if (this.incomingEmail.processingState === ProcessingState.UNPROCESSED_IGNORE_FILTER) {
                return null;
            } else if (this.incomingEmail.processingState === ProcessingState.PROCESSED) {
                return "success";
            } else if (this.incomingEmail.processingState === ProcessingState.PROCESS_MANUALLY) {
                return "warning";
            } else if (this.incomingEmail.processingState === ProcessingState.FILTERED) {
                return "error";
            } else {
                return null;
            }
        },
    },

    methods: {
        downloadEmail() {
            if (!this.incomingEmail) {
                return;
            }

            window.open(
                incomingEmailsApi.generateEmailDownloadLink(
                    this.incomingEmail.id,
                    this.incomingEmail.rawEmailHash,
                    this.issueId!
                )
            );
        },

        downloadEmailForm() {
            if (!this.incomingEmail) {
                return;
            }

            window.open(incomingEmailsApi.generateEmailFormDownloadLink(this.incomingEmail.id, this.issueId!));
        },

        getContactCaption(contact: Contact | null): string | null {
            if (!contact) {
                return null;
            }

            return renderContactCaption(contact.contactData);
        },

        async loadHtmlBody() {
            if (!this.incomingEmail || this.htmlBody !== null) {
                return;
            }

            this.loadingHtmlBody = true;

            try {
                this.htmlBody = await incomingEmailsApi.getHtmlBody(this.incomingEmail.id, this.issueId!);
                this.htmlBodyError = !this.htmlBody ? HtmlBodyError.HTML_BODY_EMPTY : null;
            } catch (e) {
                this.htmlBodyError = HtmlBodyError.LOADING_HTML_BODY_FAILED;

                if (!(e instanceof NotFound)) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            } finally {
                this.loadingHtmlBody = false;
            }

            if (this.htmlBody !== null) {
                this.$nextTick(() => {
                    this.writeHtmlBodyToIframe();
                });
            }
        },

        isContactDeleted(contact: Contact | null): boolean {
            return !!contact?.deleted;
        },

        async reactivate() {
            if (!this.incomingEmail) {
                return;
            }

            await incomingEmailsApi.reactivate(this.incomingEmail.id);
        },

        async reportSpam() {
            if (!this.incomingEmail) {
                return;
            }

            this.reportingSpam = true;
            try {
                const spamReported = await incomingEmailsApi.reportSpam(this.incomingEmail.id);

                this.incomingEmail = { ...this.incomingEmail, spamReported };
            } finally {
                this.reportingSpam = false;
            }
        },

        showAttachment(attachment: IncomingEmailAttachment, download: boolean) {
            if (!this.incomingEmail) {
                return;
            }

            const fname = attachment.filename.toLowerCase();

            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.image = incomingEmailsApi.generateAttachmentLink(this.incomingEmail.id, attachment, this.issueId!);
            } else {
                window.open(
                    incomingEmailsApi.generateAttachmentLink(this.incomingEmail.id, attachment, this.issueId!, download)
                );
            }
        },

        writeHtmlBodyToIframe() {
            if (!this.htmlBody) {
                return;
            }

            this.writing = true;
            try {
                const success = writeHtmlToIframe(this.$refs.iframe as HTMLIFrameElement, this.htmlBody);

                this.htmlBodyError = !success ? HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED : null;
            } catch (e) {
                this.htmlBodyError = HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED;

                this.$nextTick(() => {
                    throw e;
                });
            } finally {
                this.writing = false;
            }
        },
    },

    async mounted() {
        const incomingEmailId = this.$route.params.incomingemailid;

        if (!incomingEmailId) {
            this.loading = false;
            return;
        }

        try {
            this.incomingEmailAndContact = await incomingEmailsApi.getIncomingEmailAndContact(incomingEmailId);
        } catch (e) {
            if (!(e instanceof Forbidden)) {
                throw e;
            }
            this.forbidden = true;
        } finally {
            this.loading = false;
        }

        if (!this.incomingEmail) {
            return;
        }

        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (
                isIncomingEmailUpdatedNotification(n) &&
                this.incomingEmailAndContact &&
                n.id === this.incomingEmailAndContact.incomingEmail.id
            ) {
                this.incomingEmailAndContact = {
                    ...this.incomingEmailAndContact,
                    incomingEmail: {
                        ...this.incomingEmailAndContact.incomingEmail,
                        caseId: n.caseId,
                        opportunityId: n.opportunityId,
                        spamReported: n.spamReported,
                        processingState: n.processingState,
                        processedAt: n.processedAt,
                    },
                };
            }
        });

        await this.loadHtmlBody();
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        ImageCarousel,
    },
});
