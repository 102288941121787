import axios from "axios";

export interface Dealer {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly active: boolean;
    readonly billingAccountId: number;
    readonly citnowAccountNumber: string | null;
    readonly name: string;
    readonly externalBdcTargets: ExternalBdcTarget[];
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string;
    readonly timeZone: string;
    readonly numbers: DealerNumber[];
    readonly emailAddresses: EmailAddress[];
    readonly mailboxInfo: string | null;
    readonly legalInspectionType: LegalInspectionType;
    readonly legalInspectionExecutionHours: string | null;
    readonly takeOverCallerId: boolean;
    readonly incidentServiceRadius: number | null;
    readonly breakdownInstructions: string | null;
    readonly accidentInstructions: string | null;
    readonly logoHash: string | null;
}

export interface DealerForm {
    readonly id: string;
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string;
    readonly timeZone: string;
    readonly numbers: DealerNumber[];
    readonly emailAddresses: EmailAddress[];
    readonly mailboxInfo: string | null;
    readonly legalInspectionType: LegalInspectionType;
    readonly legalInspectionExecutionHours: string | null;
    readonly incidentServiceRadius: number | null;
    readonly breakdownInstructions: string | null;
    readonly accidentInstructions: string | null;
    readonly logoHash: string | null;
}

export interface DealerStubForm {
    readonly billingAccountId: number;
    readonly citnowAccountNumber: string | null;
    readonly name: string;
    readonly externalBdcTargets: ExternalBdcTarget[];
    readonly takeOverCallerId: boolean;
    readonly active: boolean;
}

export interface ExternalBdcTarget {
    number: string;
    greeting: string | null;
    instructions: string | null;
}

export enum LegalInspectionType {
    NOT_POSSIBLE = "NOT_POSSIBLE",
    POSSIBLE_WITH_APPOINTMENT_ONLY = "POSSIBLE_WITH_APPOINTMENT_ONLY",
    POSSIBLE_WITHOUT_APPOINTMENT = "POSSIBLE_WITHOUT_APPOINTMENT",
}

export enum NumberType {
    LANDLINE = "LANDLINE",
    MOBILE = "MOBILE",
    FAX = "FAX",
}

export interface DealerNumber {
    type: NumberType;
    number: string;
    label: string | null;
}

export interface EmailAddress {
    address: string;
    label: string | null;
}

interface DealersApi {
    list(): Promise<Dealer[]>;
    addStub(dealerStubForm: DealerStubForm): Promise<boolean>;
    editStub(id: string, dealerStubForm: DealerStubForm): Promise<boolean>;
    getById(id: string): Promise<Dealer>;
    edit(dealerForm: DealerForm, logo?: File, progressCallback?: (progressEvent: ProgressEvent) => void): Promise<void>;
    getLogoByHashAsBase64(id: string, hash: string): Promise<string>;
}

export const dealersApi: DealersApi = {
    async list() {
        const result: Dealer[] = (await axios.get("/api/dealers")).data;

        return result.map((v) => ({
            ...v,
            created: new Date(v.created),
            updated: new Date(v.updated),
        }));
    },

    async addStub(dealerStubForm) {
        return await axios.post("/api/dealers", dealerStubForm);
    },

    async editStub(id, dealerStubForm) {
        return await axios.put(`/api/dealers/${id}`, dealerStubForm);
    },

    async getById(id) {
        const result: Dealer = (await axios.get(`/api/dealers/${id}`)).data;

        return {
            ...result,
            created: new Date(result.created),
            updated: new Date(result.updated),
        };
    },

    async edit(dealerForm, logo, onUploadProgress) {
        const formData = new FormData();
        if (logo) {
            formData.append("logo", logo);
        }
        formData.append("form", new Blob([JSON.stringify(dealerForm)], { type: "application/json" }));
        return await axios.put("/api/dealers", formData, { onUploadProgress });
    },

    async getLogoByHashAsBase64(id, hash) {
        return (await axios.get(`/api/dealers/${id}/logo/${hash}.b64`, { responseType: "text" })).data;
    },
};
