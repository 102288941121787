
import { Away } from "@/api/users";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOptions } from "@/util/types/SelectOptions";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: {
            type: [String, Array],
            required: false,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        submitButtonCaption: {
            type: String,
            required: false,
        },
        disabledUserIds: {
            type: Array as () => string[],
            default: () => [],
        },
    },

    data() {
        return {
            selectedUserIds: this.value ? cloneObject(this.value) : this.multiple ? [] : null,
        };
    },

    computed: {
        allUsersOptions(): SelectOptions {
            return usersStore.users.map((u) => ({
                value: u.id,
                text: getFullName(u),
                online: u.status.online,
                away: u.away,
                disabled: this.disabledUserIds.includes(u.id),
            }));
        },
    },

    methods: {
        async submitForm() {
            this.$emit("save", this.selectedUserIds);
        },

        close() {
            this.$emit("close");
        },

        updateValue(v: string | string[] | null) {
            if (v && (!Array.isArray(v) || v.length)) {
                this.selectedUserIds = v;
            } else if (Array.isArray(this.selectedUserIds)) {
                this.selectedUserIds = this.selectedUserIds.filter((i) => this.disabledUserIds.includes(i as string));
            } else if (!this.disabledUserIds.includes(this.selectedUserIds!)) {
                this.selectedUserIds = null;
            }
        },

        statusIcon(online: boolean, away: Away | null): string {
            if (!online) {
                return "mdi-circle-outline";
            } else if (away) {
                return "mdi-circle";
            } else {
                return "mdi-circle";
            }
        },

        statusIconColor(online: boolean, away: Away | null): string {
            if (!online) {
                return "";
            } else if (away) {
                return "warning";
            } else {
                return "success";
            }
        },
    },

    components: {
        DAutocomplete,
    },
});
