import { InventorySearchOrder, InventorySearchRequest, InventoryVehicleDeletionStatus } from "@/api/inventorySearch";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { reactive } from "@/util/reactive";

const EMPTY_INVENTORY_SEARCH_REQUEST: InventorySearchRequest = {
    dealerIds: [],
    vehicleClasses: [],
    categories: [],
    makes: [],
    models: [],
    usageTypes: [],
    firstRegistrationFrom: null,
    firstRegistrationTo: null,
    mileageFrom: null,
    mileageTo: null,
    cubicCapacityFrom: null,
    cubicCapacityTo: null,
    powerFrom: null,
    powerTo: null,
    gearboxes: [],
    drives: [],
    fuels: [],
    exteriorColors: [],
    interiorTypes: [],
    climatisations: [],
    speedControls: [],
    headlightTypes: [],
    parkingAssistants: [],
    trailerCouplingTypes: [],
    priceFrom: null,
    priceTo: null,
    features: [],
    deletionStatuses: [InventoryVehicleDeletionStatus.ACTIVE],
    search: null,
    sortBy: InventorySearchOrder.PRICE_ASC,
};

@reactive
export class InventoryOverviewState {
    public searchRequest: InventorySearchRequest = EMPTY_INVENTORY_SEARCH_REQUEST;
    public initialized = false;

    refresh() {
        this.searchRequest = cloneObject(this.searchRequest);
    }

    reset() {
        this.initialized = true;
        this.searchRequest = {
            ...EMPTY_INVENTORY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
        };
    }

    set dealerIds(dealerIds) {
        this.searchRequest = { ...this.searchRequest, dealerIds };
    }

    get dealerIds() {
        return this.searchRequest.dealerIds;
    }

    set vehicleClasses(vehicleClasses) {
        this.searchRequest = { ...this.searchRequest, vehicleClasses };
    }

    get vehicleClasses() {
        return this.searchRequest.vehicleClasses;
    }

    set categories(categories) {
        this.searchRequest = { ...this.searchRequest, categories };
    }

    get categories() {
        return this.searchRequest.categories;
    }

    set makes(makes) {
        this.searchRequest = { ...this.searchRequest, makes };
    }

    get makes() {
        return this.searchRequest.makes;
    }

    set models(models) {
        this.searchRequest = { ...this.searchRequest, models };
    }

    get models() {
        return this.searchRequest.models;
    }

    set usageTypes(usageTypes) {
        this.searchRequest = { ...this.searchRequest, usageTypes };
    }

    get usageTypes() {
        return this.searchRequest.usageTypes;
    }

    set firstRegistrationFrom(firstRegistrationFrom) {
        this.searchRequest = { ...this.searchRequest, firstRegistrationFrom };
    }

    get firstRegistrationFrom() {
        return this.searchRequest.firstRegistrationFrom;
    }

    set firstRegistrationTo(firstRegistrationTo) {
        this.searchRequest = { ...this.searchRequest, firstRegistrationTo };
    }

    get firstRegistrationTo() {
        return this.searchRequest.firstRegistrationTo;
    }

    set mileageFrom(mileageFrom) {
        this.searchRequest = { ...this.searchRequest, mileageFrom };
    }

    get mileageFrom() {
        return this.searchRequest.mileageFrom;
    }

    set mileageTo(mileageTo) {
        this.searchRequest = { ...this.searchRequest, mileageTo };
    }

    get mileageTo() {
        return this.searchRequest.mileageTo;
    }

    set cubicCapacityFrom(cubicCapacityFrom) {
        this.searchRequest = { ...this.searchRequest, cubicCapacityFrom };
    }

    get cubicCapacityFrom() {
        return this.searchRequest.cubicCapacityFrom;
    }

    set cubicCapacityTo(cubicCapacityTo) {
        this.searchRequest = { ...this.searchRequest, cubicCapacityTo };
    }

    get cubicCapacityTo() {
        return this.searchRequest.cubicCapacityTo;
    }

    set powerFrom(powerFrom) {
        this.searchRequest = { ...this.searchRequest, powerFrom };
    }

    get powerFrom() {
        return this.searchRequest.powerFrom;
    }

    set powerTo(powerTo) {
        this.searchRequest = { ...this.searchRequest, powerTo };
    }

    get powerTo() {
        return this.searchRequest.powerTo;
    }

    set gearboxes(gearboxes) {
        this.searchRequest = { ...this.searchRequest, gearboxes };
    }

    get gearboxes() {
        return this.searchRequest.gearboxes;
    }

    set drives(drives) {
        this.searchRequest = { ...this.searchRequest, drives };
    }

    get drives() {
        return this.searchRequest.drives;
    }

    set fuels(fuels) {
        this.searchRequest = { ...this.searchRequest, fuels };
    }

    get fuels() {
        return this.searchRequest.fuels;
    }

    set exteriorColors(exteriorColors) {
        this.searchRequest = { ...this.searchRequest, exteriorColors };
    }

    get exteriorColors() {
        return this.searchRequest.exteriorColors;
    }

    set interiorTypes(interiorTypes) {
        this.searchRequest = { ...this.searchRequest, interiorTypes };
    }

    get interiorTypes() {
        return this.searchRequest.interiorTypes;
    }

    set climatisations(climatisations) {
        this.searchRequest = { ...this.searchRequest, climatisations };
    }

    get climatisations() {
        return this.searchRequest.climatisations;
    }

    set speedControls(speedControls) {
        this.searchRequest = { ...this.searchRequest, speedControls };
    }

    get speedControls() {
        return this.searchRequest.speedControls;
    }

    set headlightTypes(headlightTypes) {
        this.searchRequest = { ...this.searchRequest, headlightTypes };
    }

    get headlightTypes() {
        return this.searchRequest.headlightTypes;
    }

    set parkingAssistants(parkingAssistants) {
        this.searchRequest = { ...this.searchRequest, parkingAssistants };
    }

    get parkingAssistants() {
        return this.searchRequest.parkingAssistants;
    }

    set trailerCouplingTypes(trailerCouplingTypes) {
        this.searchRequest = { ...this.searchRequest, trailerCouplingTypes };
    }

    get trailerCouplingTypes() {
        return this.searchRequest.trailerCouplingTypes;
    }

    set priceFrom(priceFrom) {
        this.searchRequest = { ...this.searchRequest, priceFrom };
    }

    get priceFrom() {
        return this.searchRequest.priceFrom;
    }

    set priceTo(priceTo) {
        this.searchRequest = { ...this.searchRequest, priceTo };
    }

    get priceTo() {
        return this.searchRequest.priceTo;
    }

    set features(features) {
        this.searchRequest = { ...this.searchRequest, features };
    }

    get features() {
        return this.searchRequest.features;
    }

    set deletionStatuses(deletionStatuses) {
        this.searchRequest = { ...this.searchRequest, deletionStatuses };
    }

    get deletionStatuses() {
        return this.searchRequest.deletionStatuses;
    }

    set search(search) {
        this.searchRequest = { ...this.searchRequest, search };
    }

    get search() {
        return this.searchRequest.search;
    }

    set sortBy(sortBy) {
        this.searchRequest = { ...this.searchRequest, sortBy };
    }

    get sortBy() {
        return this.searchRequest.sortBy;
    }
}

export const inventoryOverviewState = new InventoryOverviewState();
