
import { Case, CaseResult, CaseUrgency, CaseUser } from "@/api/cases";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DLink from "@/app/components/DLink.vue";
import { renderVehicleTitle } from "@/app/contactUtils";
import EscalationGroupLink from "@/app/pages/EscalationGroupLink.vue";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import UserLink from "@/app/pages/UserLink.vue";
import {
    getAssigneeIdsSortedByUserFullName,
    getSelectedReceiversCount,
    getStatusCaption,
    getStatusColor,
    getUrgencyColor,
    getUrgencyTextColor,
    getVehicleDeleted,
    getVehicleIcon,
    getVehicleTooltip,
} from "@/app/pages/cases/caseUtils";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseResult: {
            type: Object as () => CaseResult,
            required: true,
        },
        caseUser: {
            type: Object as () => CaseUser,
            required: false,
        },
        compact: {
            type: Boolean,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            CaseUrgency,
        };
    },

    computed: {
        assigneeIdsSortedByUserFullName(): string[] {
            return getAssigneeIdsSortedByUserFullName(this.caseObj);
        },

        caseObj(): Case {
            return this.caseResult.caseObj;
        },

        caseStatusCaption(): string {
            return getStatusCaption(this.caseObj);
        },

        caseStatusColor(): string | null {
            return getStatusColor(this.caseObj);
        },

        selectedReceiversCount(): number {
            return getSelectedReceiversCount(this.caseObj);
        },

        unreadActivities(): number | null {
            if (!this.caseUser) {
                return null;
            }

            return this.caseObj.activitiesCount - this.caseUser.activitiesRead;
        },

        urgencyColor(): string | null {
            return getUrgencyColor(this.caseObj);
        },

        urgencyTextColor(): string | null {
            return getUrgencyTextColor(this.caseObj);
        },

        vehicleCaption(): string | null {
            if (!this.caseResult.contactVehicle) {
                return null;
            }

            return renderVehicleTitle(this.caseResult.contactVehicle.contactVehicleData, false);
        },

        vehicleDeleted(): boolean {
            return getVehicleDeleted(this.caseResult);
        },

        vehicleIcon(): string {
            return getVehicleIcon(this.caseResult);
        },

        vehicleTooltip(): string {
            return getVehicleTooltip(this.caseResult);
        },
    },

    methods: {
        isCurrentUser(userId: string): boolean {
            return userId === userSession.userId;
        },
    },

    components: {
        CopyToClipboardIcon,
        DLink,
        EscalationGroupLink,
        IconWithTooltip,
        UserLink,
    },
});
