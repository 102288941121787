import axios from "axios";

export interface IndividualEscalationData {
    readonly escalationGroupId: string;
    readonly escalationTimeMinutes: number;
    readonly instantEscalationIfOffline: boolean;
}

export interface IndividualEscalations {
    readonly userId: string;
    readonly individualEscalationData: IndividualEscalationData | null;
}

interface IndividualEscalationsApi {
    deleteByUser(userId: string): Promise<void>;
    editIndividualEscalation(userId: string, individualEscalationData: IndividualEscalationData | null): Promise<void>;
    getByDealer(dealerId: string): Promise<IndividualEscalations[]>;
}

export const individualEscalationsApi: IndividualEscalationsApi = {
    async deleteByUser(userId) {
        await axios.delete(`/api/individual-escalations/_/${userId}`);
    },

    async editIndividualEscalation(userId, individualEscalationData) {
        await axios.put(`/api/individual-escalations/_/${userId}`, individualEscalationData, {
            headers: { "Content-Type": "application/json" },
        });
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/individual-escalations/${dealerId}`)).data;
    },
};
