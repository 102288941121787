
import { Forbidden } from "@/api/errors";
import {
    isCaseReadReceiptsUpdatedNotification,
    isOpportunityReadReceiptsUpdatedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { ReadReceipt, readReceiptsApi } from "@/api/readReceipts";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import UserDirectoryEntrySpan from "@/app/pages/users/UserDirectoryEntrySpan.vue";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseId: {
            type: String,
            required: false,
        },
        opportunityId: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            loading: true,
            forbidden: false,
            expanded: null as boolean | null,
            readReceipts: [] as ReadReceipt[],
            notificationHandler: null as ((n: Notification) => void) | null,
        };
    },

    methods: {
        async loadReadReceipts() {
            this.loading = true;
            try {
                if (this.caseId) {
                    this.readReceipts = await readReceiptsApi.getReadReceiptsByCase(this.caseId);
                } else if (this.opportunityId) {
                    this.readReceipts = await readReceiptsApi.getReadReceiptsByOpportunity(this.opportunityId);
                }

                this.forbidden = false;
            } catch (e) {
                if (!(e instanceof Forbidden)) {
                    throw e;
                }

                this.readReceipts = [];
                this.forbidden = true;
            } finally {
                this.loading = false;
            }
        },

        formatDate(date: Date) {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },
    },

    watch: {
        readReceipts() {
            if (this.expanded === null && this.readReceipts.length) {
                // expand only if the user has not changed the expansion state manually
                this.expanded = true;
            }
        },
    },

    async mounted() {
        await this.loadReadReceipts();

        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isCaseReadReceiptsUpdatedNotification(n)) {
                if (n.caseId === this.caseId) {
                    await this.loadReadReceipts();
                }
            }
            if (isOpportunityReadReceiptsUpdatedNotification(n)) {
                if (n.opportunityId === this.opportunityId) {
                    await this.loadReadReceipts();
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        DToolbarCard,
        UserDirectoryEntrySpan,
    },
});
