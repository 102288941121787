
import { Invoice, invoicesApi } from "@/api/invoices";
import DataTable from "@/app/components/DataTable.vue";
import MonthPicker from "@/app/components/MonthPicker.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getCurrentMonth } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            items: null as Invoice[] | null,
            loading: false,
            month: (this.$route.query.month ||
                getCurrentMonth(now(), configStore.configuration.defaultTimeZone)) as string,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        currency() {
            return configStore.configuration.orgCurrency;
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Rechnung"),
                    width: "30%",
                },
                {
                    text: this.$t("Rechnungsdetails"),
                    width: "40%",
                },
                {
                    text: this.$t("Status"),
                    width: "30%",
                },
            ];
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        async month(newValue) {
            await this.$router.push({ query: { ...this.$route.query, month: newValue } });
            this.loading = true;
            try {
                this.items = await invoicesApi.getInvoices(this.dealerId!, this.month);
            } finally {
                this.loading = false;
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    methods: {
        async loadItems() {
            this.items = null;
            if (this.dealerId) {
                this.items = await invoicesApi.getInvoices(this.dealerId, this.month);
            }
        },

        generateDownloadLink(invoiceId: number) {
            return invoicesApi.generateInvoicePdfLink(invoiceId, this.dealerId!);
        },
    },

    components: {
        DealerContextWarning,
        MonthPicker,
        DataTable,
    },
});
