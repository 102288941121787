var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('d-autocomplete',{attrs:{"autocomplete":"no","disabled":_vm.hasAudioAnnouncement || _vm.hasTextAnnouncement,"id":_vm.$id(),"items":[
            {
                text: _vm.$t('Audiodatei'),
                value: _vm.AnnouncementType.AUDIO_FILE,
            },
            {
                text: _vm.$t('Sprachausgabe'),
                value: _vm.AnnouncementType.TEXT_TO_SPEECH,
            },
        ],"label":_vm.$t('Art der Ansage'),"value":_vm.announcementType},on:{"input":_vm.handleAnnouncementTypeInput}}),(_vm.announcementType === _vm.AnnouncementType.AUDIO_FILE)?_c('div',[_c('v-input',{attrs:{"hide-details":"auto","value":_vm.announcement,"rules":_vm.announcementAudioRules}},[(!_vm.hasAudioAnnouncement)?_c('v-btn',{attrs:{"x-small":"","color":"secondary"},on:{"click":_vm.selectAnnouncementFile}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("Audiodatei"))+" ")],1):_vm._e()],1),(_vm.announcementLink)?_c('audio',{attrs:{"src":_vm.announcementLink,"controls":""}}):_vm._e(),(_vm.announcementAudioFileUrl)?_c('audio',{attrs:{"src":_vm.announcementAudioFileUrl,"controls":""}}):_vm._e(),(_vm.hasAudioAnnouncement)?_c('v-btn',{staticClass:"ml-4",staticStyle:{"margin-top":"-45px"},attrs:{"x-small":""},on:{"click":_vm.unselectAnnouncement}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$t("Ansage entfernen"))+" ")],1):_vm._e()],1):_vm._e(),(_vm.announcementType === _vm.AnnouncementType.TEXT_TO_SPEECH)?_c('v-textarea',{staticClass:"mt-4",attrs:{"clearable":"","label":_vm.$t('Ansagetext'),"rules":_vm.notEmpty().maxLength(65535),"value":_vm.announcement.text},on:{"input":_vm.handleTextInput}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }