import { CaseChannel } from "@/api/cases";
import { OpportunityChannel } from "@/api/opportunities";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum RenaultRaceApiErrorType {
    FORBIDDEN = "FORBIDDEN",
    GENERAL = "GENERAL",
    HTTP_BASIC_AUTH_CREDENTIALS_INCORRECT = "HTTP_BASIC_AUTH_CREDENTIALS_INCORRECT",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    LEAD_NOT_FOUND = "LEAD_NOT_FOUND",
    LEAD_PROCESSING_FAILED = "LEAD_PROCESSING_FAILED",
    TLS_CLIENT_CERTIFICATE_DECODING_FAILED = "TLS_CLIENT_CERTIFICATE_DECODING_FAILED",
    TLS_CLIENT_CERTIFICATE_INCORRECT = "TLS_CLIENT_CERTIFICATE_INCORRECT",
    TLS_CLIENT_CERTIFICATE_INCORRECT_PASSWORD = "TLS_CLIENT_CERTIFICATE_INCORRECT_PASSWORD",
}

export interface RenaultRaceAccount {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly name: string;
    readonly dealers: RenaultRaceAccountDealer[];
    readonly httpBasicAuthUsername: string;
    readonly httpBasicAuthPassword: string;
    readonly currentTryBegin: Date | null;
    readonly nextTryBegin: Date | null;
    readonly lastSuccessfulImport: Date | null;
    readonly lastUnsuccessfulImport: Date | null;
    readonly lastUnsuccessfulImportError: string | null;
    readonly lastUnsuccessfulImportErrorType: RenaultRaceApiErrorType | null;
    readonly consecutiveUnsuccessfulImports: number;
}

export interface RenaultRaceAccountDealer {
    readonly renaultRaceDealerNumber: string;
    readonly dealerId: string;
    readonly fallback: boolean;
    readonly caseChannel: CaseChannel | null;
    readonly caseType: string | null;
    readonly caseIndividualReceivers: string[];
    readonly escalationGroupReceivers: string[];
    readonly opportunityChannel: OpportunityChannel | null;
    readonly opportunitySourceId: string;
    readonly opportunityIndividualReceivers: string[];
    readonly opportunityTeamReceivers: string[];
}

export interface RenaultRaceAccountDealerForm {
    readonly renaultRaceDealerNumber: string;
    readonly dealerId: string;
    readonly fallback: boolean;
    readonly caseChannel: CaseChannel | null;
    readonly caseType: string | null;
    readonly caseIndividualReceivers: string[];
    readonly escalationGroupReceivers: string[];
    readonly opportunityChannel: OpportunityChannel | null;
    readonly opportunitySourceId: string;
    readonly opportunityIndividualReceivers: string[];
    readonly opportunityTeamReceivers: string[];
}

export interface RenaultRaceAccountForm {
    readonly name: string;
    readonly dealerForms: RenaultRaceAccountDealerForm[];
    readonly httpBasicAuthUsername: string;
    readonly httpBasicAuthPassword: string;
}

interface RenaultRaceAccountsApi {
    add(renaultRaceAccountForm: RenaultRaceAccountForm): Promise<string>;
    delete(renaultRaceAccountId: string): Promise<void>;
    edit(renaultRaceAccountId: string, renaultRaceAccountForm: RenaultRaceAccountForm): Promise<void>;
    getAll(): Promise<RenaultRaceAccount[]>;
    pingRenaultRaceApi(renaultRaceAccountId: string): Promise<Date>;
}

export const renaultRaceAccountsApi: RenaultRaceAccountsApi = {
    async add(renaultRaceAccountForm) {
        return (await axios.post("/api/renault-race-accounts", renaultRaceAccountForm)).data;
    },

    async delete(renaultRaceAccountId) {
        await axios.delete(`/api/renault-race-accounts/${renaultRaceAccountId}`);
    },

    async edit(renaultRaceAccountId, renaultRaceAccountForm) {
        await axios.put(`/api/renault-race-accounts/${renaultRaceAccountId}`, renaultRaceAccountForm);
    },

    async getAll() {
        return cloneObject((await axios.get("/api/renault-race-accounts")).data);
    },

    async pingRenaultRaceApi(renaultRaceAccountId) {
        return cloneObject(
            (await axios.post(`/api/renault-race-accounts/${renaultRaceAccountId}/_ping-renault-race-api`)).data
        );
    },
};
