
import { Dealer } from "@/api/dealers";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { renderPhoneNumberTypeIcon } from "@/app/contactUtils";
import { getCountryCaption, getStateCaption, getStateLabel } from "@/app/countryStateUtils";
import { getGoogleMapsLink, GoogleMapsAddress } from "@/app/googleServicesUtils";
import PhoneNumberLink from "@/app/pages/PhoneNumberLink.vue";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            renderPhoneNumberTypeIcon,
            getStateLabel,
            getStateCaption,
            getCountryCaption,
        };
    },

    computed: {
        dealer(): Dealer | null {
            return dealersStore.dealerById(this.dealerId);
        },

        googleMapsLink(): string | null {
            return getGoogleMapsLink(this.dealer as GoogleMapsAddress, this.dealer?.name);
        },
    },

    methods: {
        openLink(link: string) {
            window.open(link);
        },
    },

    components: {
        CopyToClipboardIcon,
        PhoneNumberLink,
    },
});
