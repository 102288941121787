import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum UserSearchFilterType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
}

export interface UserSearchFilterForm {
    readonly name: string;
    readonly filterType: UserSearchFilterType;
    readonly filterJson: { [key: string]: any };
}

export interface UserSearchFilter {
    readonly id: string;
    readonly name: string;
    readonly filterJson: { [key: string]: any };
}

interface UserSearchFilterApi {
    getAllFilters(type: UserSearchFilterType): Promise<UserSearchFilter[]>;
    addFilter(form: UserSearchFilterForm): Promise<void>;
    deleteFilter(filterId: string): Promise<void>;
}

export const userSearchFilterApi: UserSearchFilterApi = {
    async getAllFilters(type: UserSearchFilterType) {
        return cloneObject((await axios.get("/api/user-search-filters", { params: { type } })).data);
    },

    async addFilter(form: UserSearchFilterForm) {
        await axios.post("/api/user-search-filters", form);
    },

    async deleteFilter(filterId: string) {
        await axios.delete(`/api/user-search-filters/${filterId}`);
    },
};
