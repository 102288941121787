import { ContactSalutation } from "./contacts";
import { ExteriorColor } from "./inventory";
import axios from "axios";

export interface AutoCrmLead {
    readonly contact: AutoCrmContact;
    readonly vehicle: AutoCrmVehicle;
    readonly receiverId: string | null;
    readonly category: string | null;
    readonly message: string | null;
}

export interface AutoCrmContact {
    readonly salutation: ContactSalutation | null;
    readonly companyName: string | null;
    readonly givenName: string | null;
    readonly familyName: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly emailAddress: string | null;
    readonly phoneNumber: string | null;
}

export interface AutoCrmVehicle {
    readonly internalId: string | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly power: number | null;
    readonly firstRegistrationDay: number | null;
    readonly firstRegistrationMonth: number | null;
    readonly firstRegistrationYear: number | null;
    readonly mileage: number | null;
    readonly price: number | null;
    readonly exteriorColor: ExteriorColor | null;
}

interface AutoCrmLeadMailerSettingsApi {
    submitLead(dealerId: string, autoCrmLead: AutoCrmLead): Promise<void>;
}

export const autoCrmLeadMailerApi: AutoCrmLeadMailerSettingsApi = {
    async submitLead(dealerId, autoCrmLead): Promise<void> {
        await axios.post(`/api/auto-crm-lead-mailer/${dealerId}/_submit-lead`, autoCrmLead);
    },
};
