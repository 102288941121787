import { casesApi, UserCaseStatus } from "@/api/cases";
import { isCaseVisibilityNotification, notificationEventSource } from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class CaseStatusStore {
    private refreshLimiter = new ActionLimiter(true);
    private userCaseStatus_: UserCaseStatus = {
        openCases: 0,
        openCasesWithUnreadActivities: 0,
        assignedCases: 0,
        assignedCasesWithUnreadActivities: 0,
        postponedCases: 0,
        postponedCasesWithUnreadActivities: 0,
    };

    get userCaseStatus() {
        return this.userCaseStatus_;
    }

    get activeUnreadCasesCount() {
        return (
            this.userCaseStatus_.openCasesWithUnreadActivities + this.userCaseStatus_.assignedCasesWithUnreadActivities
        );
    }

    get activeCasesCount() {
        return this.userCaseStatus_.openCases + this.userCaseStatus_.assignedCases;
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.userCaseStatus_ = await casesApi.getMyCaseStatus();
        });
    }
}

export const caseStatusStore = new CaseStatusStore();

notificationEventSource.addDataHandler((n) => {
    if (isCaseVisibilityNotification(n)) {
        caseStatusStore.refresh();
    }
});
