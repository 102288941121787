
import { VehicleClass } from "@/api/makeModels";
import EnumField from "@/app/components/EnumField.vue";
import ExpansionPanel from "@/app/components/ExpansionPanel.vue";
import NumberField from "@/app/components/NumberField.vue";
import PartialDatePicker from "@/app/components/PartialDatePicker.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import ModelPicker from "@/app/pages/ModelPicker.vue";
import { OpportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import { decimal, defaultCurrency, integer } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getCurrentUtcYear, getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

const MIN_MODEL_YEAR = 1900;

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        opportunityAssistantState: {
            type: Object as () => OpportunityAssistantState,
            required: true,
        },
    },

    data() {
        return {
            VehicleClass,
            currencyRules: decimal(defaultCurrency()),
            now: now(),
            positiveIntegerRules: integer(0),
        };
    },

    computed: {
        currentDate(): string {
            return getDate(this.now, userSession.timeZone);
        },

        defaultCurrency(): string {
            return configStore.configuration.defaultCurrency;
        },

        modelYearOptions() {
            const currentYear = getCurrentUtcYear(this.now);
            const result = [];

            for (let i = currentYear + 5; i >= MIN_MODEL_YEAR; i--) {
                result.push(i.toString());
            }

            return result;
        },
    },

    components: {
        EnumField,
        ExpansionPanel,
        MakePicker,
        ModelPicker,
        NumberField,
        PartialDatePicker,
    },
});
