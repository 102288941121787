
import { getStatus, getStatusColor, getStatusDate } from "./outgoingWhatsAppUtils";
import { outgoingWhatsAppsOverviewState } from "./outgoingWhatsAppsOverview";
import { Contact } from "@/api/contacts";
import {
    IssueType,
    OutgoingWhatsAppAndContact,
    outgoingWhatsAppApi,
    OutgoingWhatsAppMessage,
    OutgoingWhatsAppSearchOrder,
} from "@/api/outgoingWhatsApp";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { renderContactCaption } from "@/app/contactUtils";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import { PickMutable, SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const itemsPerPage = 100;

        return {
            getStatus,
            getStatusDate,
            image: "",
            IssueType,
            items: [] as OutgoingWhatsAppAndContact[],
            itemsPerPage,
            loading: false,
            outgoingWhatsAppsOverviewState: outgoingWhatsAppsOverviewState,
            OutgoingWhatsAppSearchOrder,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("WhatsApp"),
                    width: "30%",
                },
                {
                    text: this.$t("Nachricht"),
                    width: "35%",
                },
                {
                    text: this.$t("Kontakt und Vorgang"),
                    width: "35%",
                },
            ];
        },

        initiatorUserOptions(): SelectOption[] {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },
    },

    methods: {
        getContactCaption(contact: Contact | null): string | null {
            if (!contact) {
                return null;
            }

            return renderContactCaption(contact.contactData);
        },

        getStatusClass(outgoingWhatsAppMessage: OutgoingWhatsAppMessage): string | null {
            const color = getStatusColor(getStatus(outgoingWhatsAppMessage));

            return color ? `${color}--text` : null;
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        isContactDeleted(contact: Contact | null): boolean {
            return !!contact?.deleted;
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const outgoingWhatsAppSearchResults = await outgoingWhatsAppApi.search(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    this.outgoingWhatsAppsOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (outgoingWhatsAppSearchResults.searchId === this.searchCounter) {
                    this.items = outgoingWhatsAppSearchResults.results;
                    this.paging.totalSize = outgoingWhatsAppSearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        refresh() {
            this.outgoingWhatsAppsOverviewState.refresh();
        },

        showMedia(outgoingWhatsAppMessage: OutgoingWhatsAppMessage, download: boolean) {
            const mediaType = outgoingWhatsAppMessage.mediaType!;
            if (!download && mediaType.startsWith("image/")) {
                this.image = outgoingWhatsAppApi.generateMediaLink(outgoingWhatsAppMessage, {});
            } else {
                window.open(outgoingWhatsAppApi.generateMediaLink(outgoingWhatsAppMessage, {}, download));
            }
        },
    },

    watch: {
        async "outgoingWhatsAppsOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        if (!this.outgoingWhatsAppsOverviewState.initialized) {
            this.outgoingWhatsAppsOverviewState.setTodayQuickfilter();
        }

        await this.loadItems();
    },

    components: {
        DAutocomplete,
        DataTable,
        DatePicker,
        EnumField,
        ImageCarousel,
        UserLink,
    },
});
