import { EmergencyIncident, EmergencyIncidentResult } from "@/api/emergencyIncidents";
import { BadRequest } from "@/api/errors";
import { getContactVehicleIcon, renderContactCaption, renderVehicleTitle } from "@/app/contactUtils";
import { $t } from "@/app/i18n";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";

export function getContactDeleted(eir: EmergencyIncidentResult | null) {
    if (!eir) {
        return false;
    }

    return !!eir.contact?.deleted;
}

export function getContactCaption(eir: EmergencyIncidentResult | null) {
    if (!eir) {
        return null;
    }

    if (eir.contact) {
        return renderContactCaption(eir.contact.contactData) as string;
    }

    return null;
}

export function getVehicleDeleted(eir: EmergencyIncidentResult | null) {
    if (!eir) {
        return false;
    }

    return !!eir.contactVehicle?.deleted || getContactDeleted(eir);
}

export function getVehicleCaption(eir: EmergencyIncidentResult | null, prependLicensePlate: boolean) {
    if (!eir) {
        return null;
    }

    if (eir.contactVehicle) {
        return renderVehicleTitle(eir.contactVehicle.contactVehicleData, prependLicensePlate);
    }

    return null;
}

export function getVehicleIcon(eir: EmergencyIncidentResult | null) {
    return getContactVehicleIcon(eir?.contactVehicle?.contactVehicleData?.vehicleClass ?? null);
}

export function getVehicleTooltip(eir: EmergencyIncidentResult | null) {
    if (getVehicleDeleted(eir) || !eir) {
        return $t("Fahrzeug") as string;
    }
    if (eir.contactVehicle!.contactVehicleData.vehicleClass) {
        return $t(`enum.VehicleClass.${eir.contactVehicle!.contactVehicleData.vehicleClass}`) as string;
    } else {
        return $t("Fahrzeug") as string;
    }
}

export function getEmergencyIncidentStatusColor(closed: boolean): string | null {
    if (closed) {
        return null;
    } else {
        return "error";
    }
}

export function getStatusColor(ei: EmergencyIncident | null): string | null {
    return getEmergencyIncidentStatusColor(!!ei?.closed);
}

export function getStatusCaption(ei: EmergencyIncident | null) {
    if (!ei) {
        return $t("unbekannt") as string;
    } else if (!ei.closed) {
        return $t("offen") as string;
    } else {
        return $t("geschlossen") as string;
    }
}

export function getCreator(ei: EmergencyIncident | null) {
    if (!ei) {
        return "";
    }
    if (ei.creatorUserId) {
        const user = usersStore.getUserById(ei.creatorUserId);
        if (!user) {
            return $t("Gelöschter Benutzer") as string;
        }
        return getFullName(user);
    }
    if (ei.creatorCtUserId) {
        return $t("Externer Agent") as string;
    }
    return $t("System") as string;
}

export function getCloser(ei: EmergencyIncident | null) {
    if (!ei) {
        return "";
    }
    if (ei.closerUserId) {
        const user = usersStore.getUserById(ei.closerUserId);
        if (!user) {
            return $t("Gelöschter Benutzer") as string;
        }
        return getFullName(user);
    }
    if (ei.closerCtUserId) {
        return $t("Externer Agent") as string;
    }
    return $t("System") as string;
}

export function isInvalidEmergencyIncidentStatusException(e: BadRequest): boolean {
    if (!e.details.length) {
        return false;
    }

    const badRequestDetails = e.details[0];

    return badRequestDetails.messageKey === "validation.invalid-emergency-incident-status";
}
