import { $t } from "./i18n";

export const SENTIMENTS = [10, 0, -10];

export function getSentimentIcon(sentiment: number | null): string {
    if (sentiment === null) {
        return "mdi-help-circle-outline";
    } else if (sentiment > 0) {
        return "mdi-emoticon-excited-outline";
    } else if (sentiment === 0) {
        return "mdi-emoticon-happy-outline";
    } else {
        return "mdi-emoticon-frown-outline";
    }
}

export function getSentimentIconColor(sentiment: number | null): string | null {
    if (sentiment === null) {
        return null;
    } else if (sentiment > 0) {
        return "success";
    } else if (sentiment === 0) {
        return null;
    } else {
        return "error";
    }
}

export function getSentimentText(sentiment: number | null): string {
    if (sentiment === null) {
        return $t("unbekannt") as string;
    } else if (sentiment > 0) {
        return $t("glücklich") as string;
    } else if (sentiment === 0) {
        return $t("neutral") as string;
    } else {
        return $t("unglücklich") as string;
    }
}
