
import { CaseSearchOrder, CaseSearchRequest } from "@/api/caseSearch";
import { EmergencyIncidentSearchOrder, EmergencyIncidentSearchRequest } from "@/api/emergencyIncidentSearch";
import { OpportunitySearchOrder, OpportunitySearchRequest } from "@/api/opportunitySearch";
import { Permission } from "@/api/userSession";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import CasesDataTable from "@/app/pages/cases/CasesDataTable.vue";
import { EMPTY_SEARCH_REQUEST as EMPTY_CASE_SEARCH_REQUEST } from "@/app/pages/cases/caseSearchUtils";
import EmergencyIncidentsDataTable from "@/app/pages/emergencyincidents/EmergencyIncidentsDataTable.vue";
import { EMPTY_SEARCH_REQUEST as EMPTY_EMERGENCY_INCIDENT_SEARCH_REQUEST } from "@/app/pages/emergencyincidents/emergencyIncidentSearchUtils";
import OpportunitiesDataTable from "@/app/pages/opportunities/OpportunitiesDataTable.vue";
import { EMPTY_SEARCH_REQUEST as EMPTY_OPPORTUNITY_SEARCH_REQUEST } from "@/app/pages/opportunities/opportunitySearchUtils";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        contactId: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            casesLoadedCount: null as number | null,
            emergencyIncidentsLoadedCount: null as number | null,
            opportunitiesLoadedCount: null as number | null,
        };
    },

    computed: {
        canViewAllCases(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_CASES);
        },

        canViewCases(): boolean {
            return (
                userSession.hasPermission(Permission.MANAGE_OWN_CASES) ||
                userSession.hasPermission(Permission.VIEW_ALL_CASES)
            );
        },

        canViewEmergencyIncidents(): boolean {
            return userSession.hasPermission(Permission.MANAGE_EMERGENCY_INCIDENTS);
        },

        canViewAllOpportunities(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES);
        },

        canViewOpportunities(): boolean {
            return (
                userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES) ||
                userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES)
            );
        },

        caseSearchRequest(): CaseSearchRequest {
            return {
                ...EMPTY_CASE_SEARCH_REQUEST,
                contactIds: this.contactId ? [this.contactId] : [],
                sortBy: CaseSearchOrder.LAST_ACTIVITY_DESC,
            };
        },

        emergencyIncidentSearchRequest(): EmergencyIncidentSearchRequest {
            return {
                ...EMPTY_EMERGENCY_INCIDENT_SEARCH_REQUEST,
                contactId: this.contactId,
                sortBy: EmergencyIncidentSearchOrder.LAST_ACTIVITY_DESC,
            };
        },

        hasCases(): boolean {
            return !!this.casesLoadedCount;
        },

        hasEmergencyIncidents(): boolean {
            return !!this.emergencyIncidentsLoadedCount;
        },

        hasOpportunities(): boolean {
            return !!this.opportunitiesLoadedCount;
        },

        loading(): boolean {
            return (
                (this.canViewCases && this.casesLoadedCount === null) ||
                (this.canViewEmergencyIncidents && this.emergencyIncidentsLoadedCount === null) ||
                (this.canViewOpportunities && this.opportunitiesLoadedCount === null)
            );
        },

        opportunitySearchRequest(): OpportunitySearchRequest {
            return {
                ...EMPTY_OPPORTUNITY_SEARCH_REQUEST,
                contactIds: this.contactId ? [this.contactId] : [],
                sortBy: OpportunitySearchOrder.LAST_ACTIVITY_DESC,
            };
        },
    },

    components: {
        CasesDataTable,
        DToolbarCard,
        EmergencyIncidentsDataTable,
        OpportunitiesDataTable,
    },
});
