import axios from "axios";

export enum DealerInstructionCategory {
    CONTACT_SEARCH = "CONTACT_SEARCH",
    DATA_ACQUISITION = "DATA_ACQUISITION",
    RECEIVER_SELECTION = "RECEIVER_SELECTION",
}

export enum DealerInstructionPriority {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

export enum IssueType {
    CARRY_DISPO = "CARRY_DISPO",
    CASE = "CASE",
    EMERGENCY_INCIDENT = "EMERGENCY_INCIDENT",
    OPPORTUNITY = "OPPORTUNITY",
}

export interface DealerInstruction {
    readonly id: string;
    readonly dealerIds: string[];
    readonly priority: DealerInstructionPriority;
    readonly categories: DealerInstructionCategory[];
    readonly issueTypes: IssueType[];
    readonly caseTypes: string[];
    readonly visibleFromDate: string | null;
    readonly visibleFromTime: string | null;
    readonly visibleToDate: string | null;
    readonly visibleToTime: string | null;
    readonly headline: string;
    readonly text: string | null;
}

export interface DealerInstructionForm {
    dealerIds: string[];
    priority: DealerInstructionPriority;
    categories: DealerInstructionCategory[];
    issueTypes: IssueType[];
    caseTypes: string[];
    visibleFromDate: string | null;
    visibleFromTime: string | null;
    visibleToDate: string | null;
    visibleToTime: string | null;
    headline: string;
    text: string | null;
}

interface DealerInstructionsApi {
    add(form: DealerInstructionForm): Promise<void>;
    delete(id: string): Promise<void>;
    edit(id: string, form: DealerInstructionForm): Promise<void>;
    getAll(): Promise<DealerInstruction[]>;
    updateOrder(ids: string[]): Promise<void>;
}

export const dealerInstructionsApi: DealerInstructionsApi = {
    async add(form) {
        await axios.post("/api/dealer-instructions", form);
    },

    async delete(id) {
        await axios.delete(`/api/dealer-instructions/${id}`);
    },

    async edit(id, form) {
        await axios.put(`/api/dealer-instructions/${id}`, form);
    },

    async getAll() {
        return (await axios.get("/api/dealer-instructions")).data;
    },

    async updateOrder(ids) {
        await axios.post("/api/dealer-instructions/_update-order", ids);
    },
};
