
import { CitnowVideoLocationSettingsForm, citnowVideoSettingsApi } from "@/api/citnowVideoSettings";
import { BadRequest } from "@/api/errors";
import CrudPage from "@/app/pages/CrudPage.vue";
import { notEmpty, ValidationHelper } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            lidRules: notEmpty()
                .maxLength(63)
                .and(validationHelper, "lid"),
            validationHelper,
            notEmpty,
        };
    },

    methods: {
        async getLocationSettings(dealerId: string) {
            return await citnowVideoSettingsApi.getLocationSettings(dealerId);
        },

        async addLocationSettings(dealerId: string, form: CitnowVideoLocationSettingsForm, formRef: any) {
            try {
                await citnowVideoSettingsApi.addLocationSettings(dealerId, form);
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, formRef);
                return false;
            }
            return true;
        },

        async editLocationSettings(dealerId: string, form: CitnowVideoLocationSettingsForm) {
            await citnowVideoSettingsApi.editLocationSettings(dealerId, form);
        },

        async deleteLocationSettings(dealerId: string, lid: string) {
            await citnowVideoSettingsApi.deleteLocationSettings(dealerId, lid);
        },
    },

    components: {
        CrudPage,
    },
});
