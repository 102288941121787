
import { getCreator, getSenderId, getStatusColor } from "./outgoingSmsUtils";
import { isOutgoingSmsUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { OutgoingSms } from "@/api/outgoingSms";
import TimeSpan from "@/app/components/TimeSpan.vue";
import UserLink from "@/app/pages/UserLink.vue";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canViewContent: {
            type: Boolean,
            required: true,
        },
        outgoingSms: {
            type: Object as () => OutgoingSms,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            getCreator,
            getSenderId,
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingSmsObj: this.outgoingSms,
        };
    },

    computed: {
        creationTimestamp(): string {
            return formatInstant(this.outgoingSmsObj.created, userSession.timeZone, userSession.locale, "S");
        },

        statusColor(): string {
            return getStatusColor(this.outgoingSms.smsStatus) || "error";
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isOutgoingSmsUpdatedNotification(n)) {
                if (n.id === this.outgoingSmsObj.id) {
                    this.outgoingSmsObj = {
                        ...this.outgoingSmsObj,
                        smsStatus: n.status,
                        updated: n.timestamp,
                    };
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        TimeSpan,
        UserLink,
    },
});
