import axios from "axios";

export enum ExternalAbsenceProviderType {
    ATOSS = "ATOSS",
    ATOSS_DEFAULT = "ATOSS_DEFAULT",
    AUTOSYS = "AUTOSYS",
    KDV_ZDE = "KDV_ZDE",
    PERSONIO = "PERSONIO",
    SAGE_HR = "SAGE_HR",
    SOFT_CLOCK = "SOFT_CLOCK",
    STARTIME = "STARTIME",
    TIME_IX = "TIME_IX",
    TIMEBUTLER = "TIMEBUTLER",
    TIMEMETER_PLUS = "TIMEMETER_PLUS",
    WEBCARE = "WEBCARE",
}

export interface ExternalAbsenceProvider {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly dealerId: string | null;
    readonly resolveSubstitutesGlobally: boolean;
    readonly type: ExternalAbsenceProviderType;
    readonly credential1: string;
    readonly credential2: string | null;
}

export interface ExternalAbsenceProviderForm {
    readonly dealerId: string | null;
    readonly resolveSubstitutesGlobally: boolean;
    readonly type: ExternalAbsenceProviderType;
    readonly credential1: string;
    readonly credential2: string | null;
}

interface ExternalAbsenceProvidersApi {
    list(): Promise<ExternalAbsenceProvider[]>;
    add(externalAbsenceProviderForm: ExternalAbsenceProviderForm): Promise<void>;
    edit(id: string, externalAbsenceProviderForm: ExternalAbsenceProviderForm): Promise<void>;
    delete(id: string): Promise<void>;
}

export const externalAbsenceProvidersApi: ExternalAbsenceProvidersApi = {
    async list() {
        const result: ExternalAbsenceProvider[] = (await axios.get(`/api/external-absence-providers`)).data;

        return result.map((v) => ({
            ...v,
            created: new Date(v.created),
            updated: new Date(v.updated),
        }));
    },

    async edit(id, externalAbsenceProviderForm) {
        await axios.put(`/api/external-absence-providers/${id}`, externalAbsenceProviderForm);
    },

    async add(externalAbsenceProviderForm) {
        return (await axios.post("/api/external-absence-providers", externalAbsenceProviderForm)).data;
    },

    async delete(id) {
        await axios.delete(`/api/external-absence-providers/${id}`);
    },
};
