
import { CarryDispoAssistantState } from "./carryDispoAssistant";
import { MileageUnit } from "@/api/contacts";
import { Dealer } from "@/api/dealers";
import { renderMileage } from "@/app/contactUtils";
import IssueDataCheckCardDataTableRow from "@/app/pages/IssueDataCheckCardDataTableRow.vue";
import { dealersStore } from "@/store/dealers";
import { makeModelsStore } from "@/store/makeModels";
import { userSession } from "@/store/userSession";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        carryDispoAssistantState: {
            type: Object as () => CarryDispoAssistantState,
            required: true,
        },
        dealerId: {
            type: String as () => string | null,
            default: null,
        },
        phoneticSpellingLocale: {
            type: String,
            required: true,
        },
        singlePageAssistant: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            MileageUnit,
            parseAndFormatNumber,
            renderMileage,
        };
    },

    computed: {
        dealer(): Dealer | null {
            if (!this.dealerId) {
                return null;
            }

            return dealersStore.dealerById(this.dealerId);
        },

        isPhoneticSpellingTooltipVisible(): boolean {
            return userSession.isCtUser();
        },

        servicePoint(): string | null {
            return trimAndReturnNullIfEmpty(
                [
                    this.carryDispoAssistantState.servicePointName,
                    this.carryDispoAssistantState.servicePointDescription,
                    this.carryDispoAssistantState.servicePointStreet,
                    this.carryDispoAssistantState.servicePointHouseNumber,
                    this.carryDispoAssistantState.servicePointZip,
                    this.carryDispoAssistantState.servicePointCity,
                ]
                    .filter((s) => !!s)
                    .join(", ")
            );
        },

        vehicleMakeHasModels(): boolean {
            if (!this.carryDispoAssistantState.vehicleBrand) {
                return false;
            }

            return !!makeModelsStore.getModelsByMake(this.carryDispoAssistantState.vehicleBrand).length;
        },
    },

    components: {
        IssueDataCheckCardDataTableRow,
    },
});
