import axios from "axios";

export interface EmailAliasCreationForm {
    readonly dealerIds: string[];
    readonly userIds: string[];
}

export interface EmailDomainView {
    readonly id: string;
    readonly custom: boolean;
    readonly dkimDnsName: string;
    readonly dkimDnsData: string;
    readonly dkimVerified: boolean;
    readonly returnPathDnsName: string;
    readonly returnPathDnsData: string;
    readonly returnPathVerified: boolean;
    readonly mxDnsData: string | null;
    readonly mxVerified: boolean;
}

export interface EmailDomainDnsValues {
    readonly dkim: string | null;
    readonly returnPath: string | null;
}

interface EmailDomainsApi {
    getAllIds(): Promise<string[]>;
    getDomains(): Promise<EmailDomainView[]>;
    addDomain(prefix: string): Promise<void>;
    addCustomDomain(fqdn: string, receive: boolean): Promise<void>;
    deleteDomain(id: string): Promise<void>;
    addEmailAliases(id: string, emailAliasCreationForm: EmailAliasCreationForm): Promise<void>;
    getDnsValues(id: string): Promise<EmailDomainDnsValues>;
}

export const emailDomainsApi: EmailDomainsApi = {
    async getAllIds() {
        return (await axios.get("/api/email-domains/_ids")).data;
    },

    async getDomains() {
        return (await axios.get("/api/email-domains")).data;
    },

    async addDomain(prefix) {
        return axios.post("/api/email-domains", null, { params: { prefix } });
    },

    async addCustomDomain(fqdn, receive) {
        return axios.post("/api/email-domains/_custom", null, { params: { fqdn, receive } });
    },

    async deleteDomain(id) {
        await axios.delete(`/api/email-domains/${id}`);
    },

    async addEmailAliases(id, emailAliasCreationForm: EmailAliasCreationForm) {
        await axios.post(`/api/email-domains/${id}/_add-email-aliases`, emailAliasCreationForm);
    },

    async getDnsValues(id) {
        return (await axios.get(`/api/email-domains/${id}/_dns-values`)).data;
    },
};
