var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-0",staticStyle:{"overflow-x":"auto"}},[(_vm.dealer && !_vm.dealer.active)?_c('v-alert',{staticClass:"body-2 mb-0 text-center",attrs:{"dense":"","icon":false,"prominent":"","tile":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t("Der Standort ist nicht aktiv."))+" ")]):_vm._e(),_c('table',[_c('tbody',[(_vm.contactType === _vm.ContactType.BUSINESS || _vm.contactType === _vm.ContactType.CAR_DEALER)?_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Firma'),"phonetic-spelling":_vm.isPhoneticSpellingTooltipVisible,"phonetic-spelling-locale":_vm.phoneticSpellingLocale,"text":_vm.contactCompanyName,"valid":!!_vm.contactCompanyName}}):_vm._e(),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Anrede'),"text":_vm.contactSalutation ? _vm.$t(`enum.ContactSalutation.${_vm.contactSalutation}`) : null,"valid":!!_vm.contactSalutation}}),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Vorname'),"phonetic-spelling":_vm.isPhoneticSpellingTooltipVisible,"phonetic-spelling-locale":_vm.phoneticSpellingLocale,"text":_vm.contactGivenName,"valid":!!_vm.contactGivenName}}),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Nachname'),"phonetic-spelling":_vm.isPhoneticSpellingTooltipVisible,"phonetic-spelling-locale":_vm.phoneticSpellingLocale,"text":_vm.contactFamilyName,"valid":!!_vm.contactFamilyName}}),(_vm.checkContactVehicleData)?[_c('issue-data-check-card-data-table-row',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.contactVehicleMake)?_c('v-icon',{attrs:{"color":"error","x-small":""}},[_vm._v(" mdi-cancel ")]):(_vm.loadingServicesOffered)?_c('v-progress-circular',{attrs:{"width":1,"indeterminate":"","size":10}}):(
                                    _vm.isServiceOfferedForContactVehicle === false ||
                                        (!_vm.contactVehicleModel && _vm.contactVehicleMakeHasModels)
                                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"warning","x-small":""}},on),[_vm._v(" mdi-alert ")])]}}],null,false,1260467584)},[_c('div',[(_vm.isServiceOfferedForContactVehicle === false)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Der Standort bietet für diese Marke keine Dienstleistung an."))+" ")]):_vm._e(),(!_vm.contactVehicleModel && _vm.contactVehicleMakeHasModels)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Es ist kein Modell ausgewählt."))+" ")]):_vm._e()])]):_c('v-icon',{attrs:{"color":"success","x-small":""}},[_vm._v(" mdi-check ")])]},proxy:true},{key:"header",fn:function(){return [_c('span',{class:_vm.isServiceOfferedForContactVehicle === false ? 'warning--text' : ''},[_vm._v(_vm._s(_vm.$t("Marke")))]),_c('span',[_vm._v(", ")]),_c('span',{class:!_vm.contactVehicleModel && _vm.contactVehicleMakeHasModels ? 'warning--text' : ''},[_vm._v(_vm._s(_vm.$t("Modell")))]),_c('span',[_vm._v(":")])]},proxy:true},{key:"data",fn:function(){return [(_vm.contactVehicleMake)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.contactVehicleMake))]):_vm._e(),(_vm.contactVehicleMake && _vm.contactVehicleModel)?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.contactVehicleMake && _vm.contactVehicleModel)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.contactVehicleModel))]):_vm._e(),(!_vm.contactVehicleMake)?_c('span',[_vm._v("-")]):_vm._e()]},proxy:true}],null,false,1315628155)}),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Kennzeichen'),"phonetic-spelling":_vm.isPhoneticSpellingTooltipVisible,"phonetic-spelling-locale":_vm.phoneticSpellingLocale,"text":_vm.contactVehicleLicensePlate,"text-class":"text-no-wrap","valid":!!_vm.contactVehicleLicensePlate}}),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Laufleistung'),"text":_vm.contactVehicleTodaysRecord
                                ? _vm.renderMileage(
                                      _vm.contactVehicleTodaysRecord.mileage,
                                      _vm.contactVehicleTodaysRecord.mileageUnit
                                  )
                                : null,"text-class":"text-no-wrap","valid":!!_vm.contactVehicleTodaysRecord && _vm.contactVehicleTodaysRecord.mileage !== null}})]:_vm._e(),(_vm.contactDetailsLabel)?_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.contactDetailsLabel,"phonetic-spelling":_vm.isPhoneticSpellingTooltipVisible && _vm.contactDetailsMethod === _vm.ContactMethod.EMAIL,"phonetic-spelling-locale":_vm.phoneticSpellingLocale,"text":_vm.contactDetails,"text-class":"text-no-wrap","valid":!!_vm.contactDetails}}):_vm._e(),(!!_vm.preferredEarliestContactTime && !!_vm.preferredContactTimeZone)?_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Frühestens'),"header-color":_vm.preferredLatestContactTime &&
                        !_vm.isBefore(_vm.preferredEarliestContactTime, _vm.preferredLatestContactTime)
                            ? 'error--text'
                            : !_vm.isInFuture(_vm.preferredEarliestContactTime)
                            ? 'warning--text'
                            : null,"text":_vm.formatInstant(_vm.preferredEarliestContactTime, _vm.preferredContactTimeZone),"text-class":"text-no-wrap"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(
                                _vm.preferredEarliestContactTime &&
                                    _vm.preferredLatestContactTime &&
                                    !_vm.isBefore(_vm.preferredEarliestContactTime, _vm.preferredLatestContactTime)
                            )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"error","x-small":""}},on),[_vm._v(" mdi-alert ")])]}}],null,false,2334051410)},[_c('span',[_vm._v(_vm._s(_vm.$t( "Der früheste gewünschte Kontaktzeitpunkt muss vor dem spätesten gewünschten Kontaktzeitpunkt liegen." )))])]):(!_vm.isInFuture(_vm.preferredEarliestContactTime))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"warning","x-small":""}},on),[_vm._v(" mdi-alert ")])]}}],null,false,1260467584)},[_c('span',[_vm._v(_vm._s(_vm.$t("Der Zeitpunkt liegt in der Vergangenheit.")))])]):_c('v-icon',{attrs:{"color":"success","x-small":""}},[_vm._v(" mdi-check ")])]},proxy:true}],null,false,4058931994)}):_vm._e(),(_vm.preferredLatestContactTime && _vm.preferredContactTimeZone)?_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Spätestens'),"header-color":_vm.preferredEarliestContactTime &&
                        !_vm.isBefore(_vm.preferredEarliestContactTime, _vm.preferredLatestContactTime)
                            ? 'error--text'
                            : !_vm.isInFuture(_vm.preferredLatestContactTime)
                            ? 'warning--text'
                            : null,"text":_vm.formatInstant(_vm.preferredLatestContactTime, _vm.preferredContactTimeZone),"text-class":"text-no-wrap"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(
                                _vm.preferredEarliestContactTime &&
                                    _vm.preferredLatestContactTime &&
                                    !_vm.isBefore(_vm.preferredEarliestContactTime, _vm.preferredLatestContactTime)
                            )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"error","x-small":""}},on),[_vm._v(" mdi-alert ")])]}}],null,false,2334051410)},[_c('span',[_vm._v(_vm._s(_vm.$t( "Der früheste gewünschte Kontaktzeitpunkt muss vor dem spätesten gewünschten Kontaktzeitpunkt liegen." )))])]):(!_vm.isInFuture(_vm.preferredLatestContactTime))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"warning","x-small":""}},on),[_vm._v(" mdi-alert ")])]}}],null,false,1260467584)},[_c('span',[_vm._v(_vm._s(_vm.$t("Der Zeitpunkt liegt in der Vergangenheit.")))])]):_c('v-icon',{attrs:{"color":"success","x-small":""}},[_vm._v(" mdi-check ")])]},proxy:true}],null,false,284086992)}):_vm._e(),_vm._t("table:append"),(!!_vm.individualReceivers.length)?_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Einzelempfänger'),"valid":""},scopedSlots:_vm._u([{key:"data",fn:function(){return _vm._l((_vm.individualReceivers),function(user,index){return _c('div',{staticClass:"text-no-wrap"},[_c('div',{key:index},[(user)?[(!_vm.isUserOfDealer(user))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":"warning","x-small":""}},on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "Der Einzelempfänger ist am ausgewählten Standort nicht verfügbar." ))+" ")])]):_vm._e(),_c('user-link',{attrs:{"user-id":user.id}})]:_c('div',[_vm._v(" "+_vm._s(_vm.$t("Unbekannter Benutzer"))+" ")])],2)])})},proxy:true}],null,false,2072741371)}):_vm._e()],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }