
import UserDetailsBottomSheet from "./UserDetailsBottomSheet.vue";
import { UserDirectoryEntry } from "@/api/users";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import Vue from "vue";

export default Vue.extend({
    props: {
        inheritColor: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String as () => string | null,
            default: null,
        },
    },

    data() {
        return {
            bottomSheetVisible: false,
            getFullName,
        };
    },

    computed: {
        user(): UserDirectoryEntry | null {
            return this.userId ? usersStore.getUserById(this.userId) : null;
        },
    },

    methods: {
        showBottomSheet() {
            this.bottomSheetVisible = true;
        },
    },

    components: {
        UserDetailsBottomSheet,
    },
});
