import { caseStatusStore } from "./caseStatus";
import { opportunityStatusStore } from "./opportunityStatus";
import { reactive } from "@/util/reactive";

@reactive
class Title {
    private title_ = "";

    get title() {
        return this.titlePrefix + this.title_;
    }

    set title(title: string) {
        this.title_ = title;
    }

    private get titlePrefix() {
        const n = caseStatusStore.activeUnreadCasesCount + opportunityStatusStore.activeUnreadOpportunitiesCount;
        return n > 0 ? `(${n}) ` : "";
    }
}

export const titleStore = new Title();
