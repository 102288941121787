import { VehicleClass } from "./makeModels";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum ContactMethod {
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    SMS = "SMS",
}

export enum ContactSalutation {
    MR = "MR",
    MS = "MS",
    MRS = "MRS",
    FAMILY = "FAMILY",
    MARRIED_COUPLE = "MARRIED_COUPLE",
    MR_AND_MRS = "MR_AND_MRS",
}

export enum ContactSource {
    UNKNOWN = "UNKNOWN",
    UPLOAD = "UPLOAD",
    UI = "UI",
    SYSTEM = "SYSTEM",
    API = "API",
    DELETED_UPLOAD = "DELETED_UPLOAD",
}

export enum ContactStatus {
    PROSPECT = "PROSPECT",
    CLIENT = "CLIENT",
}

export enum ContactType {
    PRIVATE = "PRIVATE",
    BUSINESS = "BUSINESS",
    CAR_DEALER = "CAR_DEALER",
}

export enum ContactVehicleRecordType {
    DELIVERY = "DELIVERY",
    WORKSHOP_APPOINTMENT = "WORKSHOP_APPOINTMENT",
    CALL_CENTER = "CALL_CENTER",
}

export enum MileageUnit {
    KM = "KM",
    MI = "MI",
}

export enum NumberType {
    LANDLINE = "LANDLINE",
    MOBILE = "MOBILE",
    FAX = "FAX",
}

export interface Contact {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly deleted: Date | null;
    readonly contactData: ContactData;
}

export interface ContactData {
    readonly source: ContactSource;
    readonly dealerId: string | null;
    readonly status: ContactStatus | null;
    readonly type: ContactType | null;
    readonly salutation: ContactSalutation | null;
    readonly knownSince: string | null;
    readonly dateOfBirth: string | null;
    readonly companyName: string | null;
    readonly position: string | null;
    readonly department: string | null;
    readonly namePrefix: string | null;
    readonly givenName: string | null;
    readonly middleName: string | null;
    readonly familyName: string | null;
    readonly nameSuffix: string | null;
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
    readonly numbers: ContactNumber[];
    readonly emailAddresses: EmailAddress[];
    readonly website: string | null;
    readonly notes: string | null;
    readonly url: string | null;
    readonly externalReferences: string[];
}

export interface ContactForm {
    readonly source: ContactSource;
    readonly dealerId: string | null;
    readonly status: ContactStatus | null;
    readonly type: ContactType | null;
    readonly salutation: ContactSalutation | null;
    readonly knownSince: string | null;
    readonly dateOfBirth: string | null;
    readonly companyName: string | null;
    readonly position: string | null;
    readonly department: string | null;
    readonly namePrefix: string | null;
    readonly givenName: string | null;
    readonly middleName: string | null;
    readonly familyName: string | null;
    readonly nameSuffix: string | null;
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
    readonly numbers: ContactNumber[];
    readonly emailAddresses: EmailAddress[];
    readonly website: string | null;
    readonly notes: string | null;
    readonly url: string | null;
    readonly externalReferences: string[];
}

export interface ContactNumber {
    readonly type: NumberType;
    readonly number: string;
    readonly label: string | null;
}

export interface ContactResult {
    readonly contact: Contact;
    readonly contactVehicles: ContactVehicle[];
}

export interface ContactVehicle {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly deleted: Date | null;
    readonly contactVehicleData: ContactVehicleData;
}

export interface ContactVehicleData {
    readonly vin: string | null;
    readonly licensePlate: string | null;
    readonly vehicleClass: VehicleClass | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly modelYear: string | null;
    readonly firstRegistration: string | null;
    readonly nextGeneralInspection: string | null;
    readonly nextInspection: string | null;
    readonly nextInspectionMileage: number | null;
    readonly nextInspectionMileageUnit: MileageUnit | null;
    readonly warrantyUntil: string | null;
    readonly warrantyUntilMileage: number | null;
    readonly warrantyUntilMileageUnit: MileageUnit | null;
    readonly warrantyPaintingUntil: string | null;
    readonly warrantyRustUntil: string | null;
    readonly records: ContactVehicleRecord[];
    readonly notes: string | null;
    readonly url: string | null;
    readonly externalReference: string | null;
}

export interface ContactVehicleForm {
    readonly vin: string | null;
    readonly licensePlate: string | null;
    readonly vehicleClass: VehicleClass | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly modelYear: string | null;
    readonly firstRegistration: string | null;
    readonly nextGeneralInspection: string | null;
    readonly nextInspection: string | null;
    readonly nextInspectionMileage: number | null;
    readonly nextInspectionMileageUnit: MileageUnit | null;
    readonly warrantyUntil: string | null;
    readonly warrantyUntilMileage: number | null;
    readonly warrantyUntilMileageUnit: MileageUnit | null;
    readonly warrantyPaintingUntil: string | null;
    readonly warrantyRustUntil: string | null;
    readonly records: ContactVehicleRecordForm[];
    readonly notes: string | null;
    readonly url: string | null;
    readonly externalReference: string | null;
}

export interface ContactVehicleRecord {
    readonly date: string;
    readonly type: ContactVehicleRecordType;
    readonly mileage: number | null;
    readonly mileageUnit: MileageUnit | null;
}

export interface ContactVehicleRecordForm {
    readonly date: string;
    readonly type: ContactVehicleRecordType;
    readonly mileage: number | null;
    readonly mileageUnit: MileageUnit | null;
}

export interface EmailAddress {
    readonly address: string;
    readonly label: string | null;
}

interface ContactsApi {
    addContact(contactForm: ContactForm): Promise<string>;
    addContactVehicle(contactId: string, contactVehicleForm: ContactVehicleForm): Promise<string>;
    deleteUnusedContactsByContactSource(contactSource: ContactSource): Promise<void>;
    editContact(contactId: string, contactForm: ContactForm): Promise<void>;
    editContactVehicle(
        contactId: string,
        contactVehicleId: string,
        contactVehicleForm: ContactVehicleForm
    ): Promise<void>;
    getContactById(contactId: string): Promise<Contact | null>;
    getContactResultById(contactId: string): Promise<ContactResult | null>;
    getContactVehicleById(contactVehicleId: string): Promise<ContactVehicle | null>;
    markContactAsDeleted(contactId: string): Promise<void>;
    markContactVehicleAsDeleted(contactId: string, contactVehicleId: string): Promise<void>;
}

export const contactsApi: ContactsApi = {
    async addContact(contactForm) {
        return (await axios.post("/api/contacts", contactForm)).data;
    },

    async addContactVehicle(contactId, contactVehicleForm) {
        return (await axios.post(`/api/contacts/${contactId}/vehicles`, contactVehicleForm)).data;
    },

    async deleteUnusedContactsByContactSource(contactSource: ContactSource) {
        await axios.post(`/api/contacts/_delete-unused/${contactSource}`);
    },

    async editContact(contactId, contactForm) {
        await axios.put(`/api/contacts/${contactId}`, contactForm);
    },

    async editContactVehicle(contactId, contactVehicleId, contactVehicleForm) {
        await axios.put(`/api/contacts/${contactId}/vehicles/${contactVehicleId}`, contactVehicleForm);
    },

    async getContactById(contactId) {
        return cloneObject((await axios.get(`/api/contacts/${contactId}/contact`)).data || null);
    },

    async getContactResultById(contactId) {
        return cloneObject((await axios.get(`/api/contacts/${contactId}`)).data || null);
    },

    async getContactVehicleById(contactVehicleId) {
        return cloneObject((await axios.get(`/api/contacts/_/vehicles/${contactVehicleId}`)).data || null);
    },

    async markContactAsDeleted(contactId) {
        await axios.delete(`/api/contacts/${contactId}`);
    },

    async markContactVehicleAsDeleted(contactId, contactVehicleId) {
        await axios.delete(`/api/contacts/${contactId}/vehicles/${contactVehicleId}`);
    },
};
