import { EmailAlias, Gender, PhoneNumber } from "@/api/users";
import { ContactNameData } from "@/app/contactUtils";
import {
    contactPlaceholders,
    contactSalutationPlaceholders,
    renderContactPlaceholders,
    renderContactSalutationPlaceholders,
    renderLinkPlaceholders,
    renderUserPlaceholders,
    UrlWithKey,
    userPlaceholders,
} from "@/app/placeholderUtils";

export const smsPlaceholders = [
    ...contactSalutationPlaceholders,
    ...contactPlaceholders,
    ...userPlaceholders,
    "USER_SIGNATURE",
    "ISSUE_ID",
];

interface UserSmsPlaceholderInfo {
    readonly username: string | null;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly languages: string[];
    readonly phoneNumbers: PhoneNumber[];
    readonly emailAliases: EmailAlias[];
    readonly smsSignature: string | null;
}

export function renderSmsPlaceholders(
    content: string,
    locale: string,
    contactNameData: ContactNameData,
    user: UserSmsPlaceholderInfo,
    urls: UrlWithKey[],
    issueId: string | null
) {
    return [
        (content: string) => renderContactSalutationPlaceholders(content, contactNameData, locale),
        (content: string) => renderContactPlaceholders(content, contactNameData, locale),
        (content: string) => renderUserPlaceholders(content, user, locale),
        (content: string) => content.replace(/%USER_SIGNATURE%/g, user.smsSignature || ""),
        (content: string) => renderLinkPlaceholders(content, urls, false),
        (content: string) => content.replace(/%ISSUE_ID%/g, issueId || ""),
    ].reduce((content, fn) => fn(content), content);
}
