
import OutgoingEmailReceiverViewChip from "./OutgoingEmailReceiverViewChip.vue";
import { getAttachments, getBccReceivers, getCcReceivers, getToReceivers } from "./outgoingEmailUtils";
import { Contact } from "@/api/contacts";
import { isOutgoingEmailEventsUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { OutgoingEmail, OutgoingEmailAndContact } from "@/api/outgoingEmails";
import { renderContactCaption } from "@/app/contactUtils";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        outgoingEmailAndContact: {
            type: Object as () => OutgoingEmailAndContact,
            required: true,
        },
    },

    data() {
        return {
            getAttachments,
            getBccReceivers,
            getCcReceivers,
            getToReceivers,
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingEmailAndContactProp: cloneObject(this.outgoingEmailAndContact),
        };
    },

    computed: {
        contact(): Contact | null {
            return this.outgoingEmailAndContactProp.contact;
        },

        contactCaption(): string | null {
            if (!this.contact) {
                return null;
            }

            return renderContactCaption(this.contact.contactData);
        },

        isContactDeleted(): boolean {
            return !!this.contact?.deleted;
        },

        outgoingEmail(): OutgoingEmail {
            return this.outgoingEmailAndContactProp.outgoingEmail;
        },
    },

    methods: {
        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },
    },

    watch: {
        outgoingEmailAndContact() {
            this.outgoingEmailAndContactProp = cloneObject(this.outgoingEmailAndContact);
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isOutgoingEmailEventsUpdatedNotification(n) && this.outgoingEmail.id === n.id) {
                this.outgoingEmailAndContactProp.outgoingEmail.events.push(n.event);
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        OutgoingEmailReceiverViewChip,
    },
});
