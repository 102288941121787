
import { editModeState } from "@/app/editModeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            toLocation: null as string | null,
        };
    },

    methods: {
        openNewTab() {
            window.open(this.toLocation!);
            this.toLocation = null;
        },

        async proceed() {
            const to = this.toLocation!;
            this.toLocation = null;
            editModeState.reset();
            await this.$router.push(to);
        },
    },

    created() {
        this.$router.beforeEach((to, _, next) => {
            if (editModeState.dirty) {
                this.toLocation = to.fullPath;
                next(false);
            } else {
                next();
            }
        });
    },
});
