
import {
    externalInventoryManagementUserLinkPlaceholders,
    externalInventoryManagementVehicleLinkPlaceholders,
} from "./externalInventoryManagementPlaceholders";
import {
    ExternalInventoryManagementSettings,
    externalInventoryManagementSettingsApi,
} from "@/api/externalInventoryManagementSettings";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            saving: false,
            settings: {
                userLinkPattern: null,
                userLinkLabel: null,
                vehicleLinkPattern: null,
                vehicleLinkLabel: null,
            } as ExternalInventoryManagementSettings,
        };
    },

    computed: {
        userLinkPlaceholders(): string[] {
            return externalInventoryManagementUserLinkPlaceholders;
        },

        vehicleLinkPlaceholders(): string[] {
            return externalInventoryManagementVehicleLinkPlaceholders;
        },
    },

    methods: {
        appendToPattern(pattern: "USER" | "VEHICLE", placeholder: string) {
            if (pattern === "USER") {
                this.settings = {
                    ...this.settings,
                    userLinkPattern: (this.settings.userLinkPattern ?? "") + placeholder,
                };
            } else if (pattern === "VEHICLE") {
                this.settings = {
                    ...this.settings,
                    vehicleLinkPattern: (this.settings.vehicleLinkPattern ?? "") + placeholder,
                };
            }
        },

        async loadSettings() {
            this.loading = true;
            try {
                this.settings = await externalInventoryManagementSettingsApi.getSettings();
            } finally {
                this.loading = false;
            }
        },

        async updateSettings() {
            this.saving = true;
            try {
                await externalInventoryManagementSettingsApi.updateSettings(this.settings);
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        await this.loadSettings();
    },

    components: {
        DealerGlobalContextHint,
    },
});
