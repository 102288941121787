import { announcementsStore } from "./announcements";
import { caseOutcomeReasonsStore } from "./caseOutcomeReasons";
import { caseSettingsStore } from "./caseSettings";
import { caseStatusStore } from "./caseStatus";
import { changelogsStore } from "./changelogs";
import { configStore } from "./config";
import { dealersStore } from "./dealers";
import { departmentsStore } from "./departments";
import { emergencyIncidentStatusStore } from "./emergencyIncidentStatus";
import { escalationGroupsStore } from "./escalationGroups";
import { externalInventoryManagementSettingsStore } from "./externalInventoryManagementSettings";
import { incomingCallsStore } from "./incomingCalls";
import { makeModelSettingsStore } from "./makeModelSettingsStore";
import { officeHoursStore } from "./officeHours";
import { opportunityOutcomeReasonsStore } from "./opportunityOutcomeReasons";
import { opportunitySettingsStore } from "./opportunitySettings";
import { opportunitySourcesStore } from "./opportunitySources";
import { opportunityStatusStore } from "./opportunityStatus";
import { opportunityTeamsStore } from "./opportunityTeams";
import { outgoingCallsStore } from "./outgoingCalls";
import { reportingSettingsStore } from "./reportingSettingsStore";
import { UserPreferencesState } from "./userPreferences";
import { usersStore } from "./users";
import { Unauthorized } from "@/api/errors";
import { isUserProfileChangedNotification, notificationEventSource } from "@/api/notifications";
import { userPreferencesApi } from "@/api/userPreferences";
import { PasswordForm, Permission, UserProfile, userSessionApi } from "@/api/userSession";
import { getFullName } from "@/app/userUtils";
import serviceWorker from "@/registerServiceWorker";
import { reactive } from "@/util/reactive";

@reactive
export class UserSession {
    connected = false;
    detectReconnect = false;
    refreshCounter = 0;
    dealerId: string | null = null;
    preferences: UserPreferencesState | null = null;
    profile: UserProfile | null = null;

    get userId() {
        return this.profile?.id;
    }

    get username() {
        return this.profile?.username;
    }

    get fullName() {
        if (!this.profile) {
            return undefined;
        }

        return getFullName(this.profile);
    }

    get permissions() {
        return this.profile?.permissions;
    }

    get timeZone() {
        if (this.profile) {
            return this.profile.timeZone;
        }
        return configStore.configuration.defaultTimeZone;
    }

    get locale() {
        if (this.profile) {
            return this.profile.locale;
        }
        return configStore.configuration.defaultLocale;
    }

    get dateOfBirth() {
        return this.profile?.dateOfBirth;
    }

    get darkmode() {
        return !!this.profile?.darkmode;
    }

    get compactMode() {
        return !!this.profile?.compactMode;
    }

    get profileImageHash() {
        return this.profile?.profileImageHash;
    }

    get realUsername() {
        return this.profile?.realUsername || undefined;
    }

    get mainDealerId() {
        return this.profile?.mainDealerId || undefined;
    }

    get csrfToken() {
        return this.profile?.csrfToken;
    }

    isCtUser(): boolean {
        return this.hasPermission(Permission.CT_IS_USER);
    }

    isAgent(): boolean {
        return this.hasPermission(Permission.CT_IS_AGENT);
    }

    hasPermission(permission: Permission): boolean {
        return (this.permissions || []).some((p) => p === permission);
    }

    async login(username: string, password: string, permanent: boolean) {
        return await this.doLogin(
            userSessionApi.login({
                username,
                password,
                permanent,
            })
        );
    }

    async verifyToken(username: string, password: string, permanent: boolean, token: string) {
        return await this.doLogin(
            userSessionApi.verifyToken(
                {
                    username,
                    password,
                    permanent,
                },
                token
            )
        );
    }

    async changePassword(passwordForm: PasswordForm) {
        this.profile = await userSessionApi.changePassword(passwordForm);
    }

    private async doLogin(loginPromise: Promise<UserProfile>) {
        try {
            this.profile = await loginPromise;
            this.preferences = new UserPreferencesState(await userPreferencesApi.currentUserPreferences());
            this.dealerId = this.profile.mainDealerId;
            await this.refreshStores();
            notificationEventSource.connect();
            serviceWorker.subscribe();
        } catch (e) {
            if (!(e instanceof Unauthorized)) {
                throw e;
            }
        }
        return !!this.profile;
    }

    async logout() {
        try {
            await userSessionApi.logout();
        } catch (e) {
            // ignore
        }
        this.preferences = null;
        this.profile = null;
        this.detectReconnect = false;
        notificationEventSource.disconnect();
        await serviceWorker.unsubscribe();
    }

    async initialize() {
        try {
            this.profile = await userSessionApi.currentUser();
            this.preferences = new UserPreferencesState(await userPreferencesApi.currentUserPreferences());
            await this.refreshStores();
            notificationEventSource.connect();
        } catch (e) {
            if (!(e instanceof Unauthorized)) {
                throw e;
            }
        }
    }

    async refreshStores(ignoreErrors = false) {
        const refreshId = ++this.refreshCounter;

        const promiseSuppliers = [
            () => caseOutcomeReasonsStore.refresh(),
            () => caseSettingsStore.refresh(),
            () => dealersStore.refresh(),
            () => departmentsStore.refresh(),
            () => escalationGroupsStore.refresh(),
            () => incomingCallsStore.init(),
            () => makeModelSettingsStore.refresh(),
            () => opportunityOutcomeReasonsStore.refresh(),
            () => opportunitySourcesStore.refresh(),
            () => opportunityTeamsStore.refresh(),
            () => outgoingCallsStore.init(),
            () => opportunitySettingsStore.refresh(),
            () => usersStore.refresh(this.profile!.id),
            () => announcementsStore.refresh(),
            () => caseStatusStore.refresh(),
            () => changelogsStore.refresh(),
            () => emergencyIncidentStatusStore.refresh(),
            () => externalInventoryManagementSettingsStore.initialize(),
            () => opportunityStatusStore.refresh(),
            () => reportingSettingsStore.refresh(),
            () => officeHoursStore.refresh(),
        ].sort(() => Math.random() - 0.5);

        for (const promiseSupplier of promiseSuppliers) {
            try {
                await promiseSupplier();
            } catch (e) {
                if (!ignoreErrors) {
                    throw e;
                }
            }

            if (refreshId !== this.refreshCounter) {
                break;
            }
        }
    }
}

export const userSession = new UserSession();

notificationEventSource.addConnectionHandler(async (connected) => {
    userSession.connected = connected;

    if (!userSession.connected) {
        return;
    }

    if (!userSession.detectReconnect) {
        userSession.detectReconnect = true;
        return;
    }

    userSession.profile = await userSessionApi.currentUser();
    userSession.preferences = new UserPreferencesState(await userPreferencesApi.currentUserPreferences());

    await userSession.refreshStores(true);
});

notificationEventSource.addDataHandler(async (n) => {
    if (isUserProfileChangedNotification(n)) {
        userSession.profile = await userSessionApi.currentUser();

        await emergencyIncidentStatusStore.refresh();
    }
});
