import { ContactAndVehicleId } from "@/app/contactUtils";
import { EMPTY_SEARCH_REQUEST } from "@/app/pages/contacts/contactSearchUtils";
import { OutgoingCallFormCardState } from "@/app/pages/outgoingcalls/outgoingCallFormCard";
import { cloneObject } from "@/util/cloneUtils";
import { reactive } from "@/util/reactive";

@reactive
class OutgoingCallAssistantState {
    public contactAndVehicleId: ContactAndVehicleId | null = null;
    public contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
    public outgoingCallFormCardState = new OutgoingCallFormCardState();

    reset() {
        this.contactAndVehicleId = null;
        this.contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
        this.outgoingCallFormCardState.reset();
    }
}

export const outgoingCallAssistantState = new OutgoingCallAssistantState();
