import { NoteSearchOrder, NoteSearchRequest } from "@/api/notes";

export const EMPTY_SEARCH_REQUEST: NoteSearchRequest = Object.freeze({
    dealerIds: [],
    creatorUserIds: [],
    creatorActorTypes: [],
    issueTypes: [],
    createdFrom: null,
    createdTo: null,
    feedbackTypes: [],
    sortBy: NoteSearchOrder.CREATED_DESC,
});
