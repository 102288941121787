
import { CaseUrgency } from "@/api/cases";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            CaseUrgency,
            notEmpty,
        };
    },

    components: {
        CaseTypePicker,
    },
});
