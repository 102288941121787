
import OpportunityReceiversFormCard from "./OpportunityReceiversFormCard.vue";
import { ContactType } from "@/api/contacts";
import { OpportunityIndividualReceiver, OpportunityStatus, OpportunityTeamReceiver } from "@/api/opportunities";
import { AcquisitionType } from "@/api/opportunityTeams";
import { VehicleSummary } from "@/app/inventoryUtils";
import OpportunityTeamLink from "@/app/pages/OpportunityTeamLink.vue";
import UserDirectoryEntrySpan from "@/app/pages/users/UserDirectoryEntrySpan.vue";
import { getSortedByUserFullName } from "@/app/userUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        acquisitionType: {
            type: String as () => AcquisitionType | null,
            default: null,
        },
        contactType: {
            type: String as () => ContactType | null,
            default: null,
        },
        dealerId: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        individualReceivers: {
            type: Array as () => OpportunityIndividualReceiver[],
            required: true,
        },
        opportunityId: {
            type: String,
            required: true,
        },
        opportunitySourceId: {
            type: String as () => string | null,
            default: null,
        },
        opportunityStatus: {
            type: String as () => OpportunityStatus,
            required: true,
        },
        opportunityTeamReceivers: {
            type: Array as () => OpportunityTeamReceiver[],
            required: true,
        },
        tradeInRequested: {
            type: Boolean,
            default: null,
        },
        vehicleSummaries: {
            type: Array as () => VehicleSummary[],
            default: () => [],
        },
    },

    data() {
        return {
            editMode: false,
        };
    },

    computed: {
        individualReceiverIds(): string[] {
            return this.individualReceiversSortedByUserFullname.map((r) => r.userId);
        },

        individualReceiversSortedByUserFullname(): OpportunityIndividualReceiver[] {
            return getSortedByUserFullName(this.individualReceivers, (r) => r.userId);
        },

        opportunityTeamReceiverIds(): string[] {
            return this.opportunityTeamReceivers.map((r) => r.opportunityTeamId);
        },
    },

    components: {
        OpportunityReceiversFormCard,
        OpportunityTeamLink,
        UserDirectoryEntrySpan,
    },
});
