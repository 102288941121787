var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('crud-page',{attrs:{"no-items-text":_vm.$t('Es wurden keine Standorteinstellungen gefunden.'),"add-dialog-title":_vm.$t('Standorteinstellung hinzufügen'),"edit-dialog-title":_vm.$t('Standorteinstellung bearbeiten'),"page-title":_vm.$t('CitNOW Standorteinstellungen'),"callbacks":{
        load: (dealerId) => _vm.getLocationSettings(dealerId),
        add: (dealerId, form, formRef) => _vm.addLocationSettings(dealerId, form, formRef),
        edit: (item, form) => _vm.editLocationSettings(item.dealerId, form),
        delete: (item) => _vm.deleteLocationSettings(item.dealerId, item.lid),
        key: (item) => item.lid,
        toForm: (item) => ({ ...item }),
        emptyForm: () => ({}),
    }},scopedSlots:_vm._u([{key:"title",fn:function({ item }){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.lid))])]}},{key:"content",fn:function({ item }){return [_c('v-card-text',{staticClass:"pb-2"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("Host"))+":")]),_vm._v(" "+_vm._s(item.host)+" ")]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("Type ID"))+":")]),_vm._v(" "+_vm._s(item.typeId)+" ")])])]}},{key:"dialog",fn:function({ form }){return [_c('v-text-field',{attrs:{"autocomplete":"no","id":_vm.$id(),"hide-details":"auto","label":_vm.$t('LID'),"rules":_vm.lidRules,"disabled":!!form.dealerId},model:{value:(form.lid),callback:function ($$v) {_vm.$set(form, "lid", $$v)},expression:"form.lid"}}),_c('v-text-field',{attrs:{"autocomplete":"no","id":_vm.$id(),"hide-details":"auto","label":_vm.$t('Host'),"rules":_vm.notEmpty().maxLength(255)},model:{value:(form.host),callback:function ($$v) {_vm.$set(form, "host", $$v)},expression:"form.host"}}),_c('v-text-field',{attrs:{"autocomplete":"no","id":_vm.$id(),"hide-details":"auto","label":_vm.$t('Type ID'),"rules":_vm.notEmpty().maxLength(63)},model:{value:(form.typeId),callback:function ($$v) {_vm.$set(form, "typeId", $$v)},expression:"form.typeId"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }