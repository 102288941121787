
import { inventoryVehicleInquiryAssistantState } from "./inventoryVehicleInquiryAssistantState";
import {
    Climatisation,
    Drive,
    ExteriorColor,
    Feature,
    Fuel,
    Gearbox,
    HeadlightType,
    InteriorType,
    ParkingAssistant,
    SpeedControl,
    TrailerCouplingType,
    UsageType,
} from "@/api/inventory";
import EnumField from "@/app/components/EnumField.vue";
import MonthPicker from "@/app/components/MonthPicker.vue";
import NumberField from "@/app/components/NumberField.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { getModelOptions } from "@/app/makeModelsUtils";
import MakePicker from "@/app/pages/MakePicker.vue";
import { integer } from "@/app/validation";
import { configStore } from "@/store/config";
import { makeModelsStore } from "@/store/makeModels";
import { userSession } from "@/store/userSession";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: Boolean,
    },

    data() {
        return {
            inventoryVehicleInquiryAssistantState,
            UsageType,
            Gearbox,
            Drive,
            Fuel,
            ExteriorColor,
            InteriorType,
            Climatisation,
            SpeedControl,
            HeadlightType,
            ParkingAssistant,
            TrailerCouplingType,
            positiveIntegerRules: integer(0),
        };
    },

    computed: {
        vehicleClassOptions(): SelectOption[] {
            return makeModelsStore.vehicleClasses
                .map((vc) => ({
                    value: vc,
                    text: this.$t(`vehicle.class.${vc}`) as string,
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        categoryOptions(): SelectOption[] {
            return makeModelsStore.categories
                .map((vc) => ({
                    value: vc,
                    text: this.$t(`vehicle.category.${vc}`) as string,
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        modelOptions(): SelectOptions {
            return getModelOptions(
                this.inventoryVehicleInquiryAssistantState.makes.length
                    ? this.inventoryVehicleInquiryAssistantState.makes
                    : makeModelsStore.makes
            );
        },

        featureOptions(): SelectOption[] {
            return Object.keys(Feature)
                .map((f) => ({
                    value: f,
                    text: this.$t(`enum.Feature.${f}`) as string,
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        dealerOptions() {
            return dealerOptions();
        },

        defaultCurrency(): string {
            return configStore.configuration.defaultCurrency;
        },
    },

    components: {
        EnumField,
        MakePicker,
        MonthPicker,
        NumberField,
    },
});
