import { Contact } from "./contacts";
import { toQueryString } from "@/util/apiUtils";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum IncomingEmailReceiverType {
    TO = "TO",
    CC = "CC",
    BCC = "BCC",
}

export enum IncomingEmailSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    FROM_NAME_DESC = "FROM_NAME_DESC",
    FROM_NAME_ASC = "FROM_NAME_ASC",
    FROM_ADDRESS_DESC = "FROM_ADDRESS_DESC",
    FROM_ADDRESS_ASC = "FROM_ADDRESS_ASC",
    SUBJECT_DESC = "SUBJECT_DESC",
    SUBJECT_ASC = "SUBJECT_ASC",
}

export enum IssueType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
    UNKNOWN = "UNKNOWN",
}

export enum ProcessingState {
    UNPROCESSED = "UNPROCESSED",
    UNPROCESSED_IGNORE_FILTER = "UNPROCESSED_IGNORE_FILTER",
    PROCESSED = "PROCESSED",
    PROCESS_MANUALLY = "PROCESS_MANUALLY",
    FILTERED = "FILTERED",
}

export interface IncomingEmailReceiver {
    readonly name: string | null;
    readonly address: string;
    readonly type: IncomingEmailReceiverType;
}

export interface IncomingEmailHeader {
    readonly name: string;
    readonly value: string;
}

export interface IncomingEmailAttachment {
    readonly id: number;
    readonly filename: string;
    readonly contentId: string | null;
    readonly contentHash: string;
    readonly contentType: string;
    readonly size: number;
}

export interface IncomingEmail {
    readonly id: string;
    readonly created: Date;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly spamReported: Date | null;
    readonly providerReference: string;
    readonly user: boolean;
    readonly fromName: string | null;
    readonly fromAddress: string;
    readonly replyToName: string | null;
    readonly replyToAddress: string | null;
    readonly originalRecipient: string;
    readonly receivers: IncomingEmailReceiver[];
    readonly subject: string | null;
    readonly textBodyHash: string | null;
    readonly htmlBodyHash: string | null;
    readonly strippedTextReplyHash: string | null;
    readonly rawEmailHash: string;
    readonly headers: IncomingEmailHeader[];
    readonly attachments: IncomingEmailAttachment[];
    readonly processingState: ProcessingState;
    readonly processedAt: Date | null;
    readonly processContentTrusted: boolean;
    readonly processContentEmailAddresses: string[];
}

export interface IncomingEmailAndContact {
    readonly incomingEmail: IncomingEmail;
    readonly contact: Contact | null;
}

export interface IncomingEmailSearchRequest {
    readonly issueTypes: IssueType[];
    readonly processingStates: ProcessingState[];
    readonly user: boolean | null;
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly originalRecipients: string[];
    readonly spamReported: boolean | null;
    readonly sortBy: IncomingEmailSearchOrder;
}

export interface IncomingEmailSearchResults {
    readonly results: IncomingEmailAndContact[];
    readonly searchId: number;
    readonly totalSize: number;
}

export type IssueId = { readonly caseId: string } | { readonly opportunityId: string };

interface IncomingEmailsApi {
    generateAttachmentLink(
        id: string,
        attachment: IncomingEmailAttachment,
        issueId: IssueId | null,
        download?: boolean
    ): string;
    generateEmailDownloadLink(id: string, hash: string, issueId: IssueId | null): string;
    generateEmailFormDownloadLink(id: string, issueId: IssueId | null): string;
    getHtmlBody(id: string, issueId: IssueId | null, inlineImages?: boolean): Promise<string>;
    getIncomingEmailAndContact(id: string): Promise<IncomingEmailAndContact>;
    getIncomingEmails(issueId: IssueId): Promise<IncomingEmail[]>;
    reactivate(id: string): Promise<void>;
    reportSpam(id: string): Promise<Date>;
    search(
        start: number,
        size: number,
        searchRequest: IncomingEmailSearchRequest,
        searchId: number
    ): Promise<IncomingEmailSearchResults>;
    generateRawEmailsLink(searchRequest: IncomingEmailSearchRequest): string;
}

export const incomingEmailsApi: IncomingEmailsApi = {
    generateAttachmentLink(id, attachment, issueId, download) {
        return `/api/incoming-emails/${id}/${attachment.id}/${attachment.contentHash}/${encodeURIComponent(
            attachment.filename.replace(/\//g, "-")
        )}?${Object.entries(issueId || {})
            .map((e) => `${e[0]}=${e[1]}`)
            .join("&")}${download ? "&dl=true" : ""}`;
    },

    generateEmailDownloadLink(id, hash, issueId) {
        return `/api/incoming-emails/${id}/${hash}.eml?${Object.entries(issueId || {})
            .map((e) => `${e[0]}=${e[1]}`)
            .join("&")}`;
    },

    generateEmailFormDownloadLink(id, issueId) {
        return `/api/incoming-emails/${id}.json?${Object.entries(issueId || {})
            .map((e) => `${e[0]}=${e[1]}`)
            .join("&")}`;
    },

    async getHtmlBody(id, issueId, inlineImages) {
        return (
            await axios.get(`/api/incoming-emails/${id}/body`, {
                params: { ...issueId, inlineImages },
                headers: { Accept: "text/html" },
            })
        ).data;
    },

    async getIncomingEmailAndContact(id) {
        return cloneObject((await axios.get(`/api/incoming-emails/${id}`)).data);
    },

    async getIncomingEmails(issueId) {
        return cloneObject((await axios.get("/api/incoming-emails", { params: issueId })).data);
    },

    async reactivate(id) {
        await axios.post(`/api/incoming-emails/${id}/_reactivate`);
    },

    async reportSpam(id) {
        return cloneObject((await axios.post(`/api/incoming-emails/${id}/_report-spam`)).data);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/incoming-emails/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },

    generateRawEmailsLink(searchRequest) {
        return `/api/incoming-emails/_mbox?${toQueryString(searchRequest)}`;
    },
};
