
import { ContactSearchRequest } from "@/api/contactSearch";
import {
    ContactForm,
    ContactNumber,
    ContactSalutation,
    ContactSource,
    ContactStatus,
    ContactType,
    EmailAddress,
    NumberType,
} from "@/api/contacts";
import BirthdayPicker from "@/app/components/BirthdayPicker.vue";
import CountryPicker from "@/app/components/CountryPicker.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import ExpansionPanel from "@/app/components/ExpansionPanel.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import StatePicker from "@/app/components/StatePicker.vue";
import { showConfirm } from "@/app/messageUtil";
import { email, notEmpty, validate } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { isMobile, isRealNumber, parseAndFormatNumber, PhoneNumberCountryCode } from "@/util/phoneNumberUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

function trimAndAddUndefinedFields(contactForm: ContactForm): ContactForm {
    // clears might result in undefined fields
    return {
        source: contactForm.source || ContactSource.UI,
        dealerId: trimAndReturnNullIfEmpty(contactForm.dealerId),
        status: contactForm.status || null,
        type: contactForm.type || null,
        salutation: contactForm.salutation || null,
        knownSince: trimAndReturnNullIfEmpty(contactForm.knownSince),
        dateOfBirth: trimAndReturnNullIfEmpty(contactForm.dateOfBirth),
        companyName: trimAndReturnNullIfEmpty(contactForm.companyName),
        position: trimAndReturnNullIfEmpty(contactForm.position),
        department: trimAndReturnNullIfEmpty(contactForm.department),
        namePrefix: trimAndReturnNullIfEmpty(contactForm.namePrefix),
        givenName: trimAndReturnNullIfEmpty(contactForm.givenName),
        middleName: trimAndReturnNullIfEmpty(contactForm.middleName),
        familyName: trimAndReturnNullIfEmpty(contactForm.familyName),
        nameSuffix: trimAndReturnNullIfEmpty(contactForm.nameSuffix),
        address1: trimAndReturnNullIfEmpty(contactForm.address1),
        address2: trimAndReturnNullIfEmpty(contactForm.address2),
        zip: trimAndReturnNullIfEmpty(contactForm.zip),
        city: trimAndReturnNullIfEmpty(contactForm.city),
        state: trimAndReturnNullIfEmpty(contactForm.state),
        country: trimAndReturnNullIfEmpty(contactForm.country),
        numbers: (contactForm.numbers ?? []).map((n) => ({
            type: n.type,
            number: trimAndReturnNullIfEmpty(n.number) ?? "",
            label: trimAndReturnNullIfEmpty(n.label),
        })), // keep empty numbers, they need to be filtered in parent component
        emailAddresses: (contactForm.emailAddresses ?? []).map((e) => ({
            address: trimAndReturnNullIfEmpty(e.address) ?? "",
            label: trimAndReturnNullIfEmpty(e.label),
        })), // keep empty addresses, they need to be filtered in parent component
        website: trimAndReturnNullIfEmpty(contactForm.website),
        notes: trimAndReturnNullIfEmpty(contactForm.notes),
        url: trimAndReturnNullIfEmpty(contactForm.url),
        externalReferences: (contactForm.externalReferences ?? [])
            .map((r) => trimAndReturnNullIfEmpty(r))
            .filter((r) => r) as string[],
    };
}

export default Vue.extend({
    props: {
        canExitEditMode: {
            type: Boolean,
            default: false,
        },
        canUnselect: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        searchRequest: {
            type: Object as () => ContactSearchRequest | null,
            required: false,
        },
        value: {
            type: Object as () => ContactForm | null,
            required: false,
        },
        working: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            contactForm: this.value ? trimAndAddUndefinedFields(cloneObject(this.value)) : null,
            ContactSalutation,
            ContactStatus,
            ContactType,
            emailRules: notEmpty().email(),
            numberRules: notEmpty().e164(),
            parseAndFormatNumber,
        };
    },

    computed: {
        canAddSearchRequestContactNumber(): boolean {
            return (
                userSession.isCtUser() &&
                !!this.contactForm &&
                !!this.searchRequestContactNumber &&
                this.contactForm.numbers.every((n) => !n.number.startsWith(this.searchRequestContactNumber!.number))
            );
        },

        canAddSearchRequestEmailAddress(): boolean {
            return (
                userSession.isCtUser() &&
                !!this.contactForm &&
                !!this.searchRequestEmailAddress &&
                this.contactForm.emailAddresses.every(
                    (e) => !e.address.startsWith(this.searchRequestEmailAddress!.address)
                )
            );
        },

        defaultCountry(): string {
            return this.contactForm?.country || configStore.configuration.defaultCountry;
        },

        phoneNumberTypes(): SelectOptions {
            return Object.keys(NumberType).map((k) => ({ value: k, text: this.$t(`enum.NumberType.${k}`) }));
        },

        searchRequestContactNumber(): ContactNumber | null {
            const e164 = parseAndFormatNumber(
                this.searchRequest?.number ?? "",
                "E.164",
                configStore.configuration.defaultCountry as PhoneNumberCountryCode
            );

            if (!e164 || !isRealNumber(e164)) {
                return null;
            }

            return {
                type: isMobile(e164) ? NumberType.MOBILE : NumberType.LANDLINE,
                number: e164,
                label: null,
            };
        },

        searchRequestEmailAddress(): EmailAddress | null {
            if (
                !trimAndReturnNullIfEmpty(this.searchRequest?.emailAddress) ||
                !validate(email(), this.searchRequest?.emailAddress ?? "")
            ) {
                return null;
            }

            return {
                address: this.searchRequest!.emailAddress!,
                label: null,
            };
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        addEmailAddress() {
            this.contactForm!.emailAddresses.push({
                address: "",
                label: "",
            });
        },

        addPhoneNumber() {
            this.contactForm!.numbers.push({
                type: NumberType.LANDLINE,
                number: "",
                label: "",
            });
        },

        addSearchRequestContactNumber() {
            if (!this.canAddSearchRequestContactNumber) {
                return;
            }

            this.contactForm!.numbers.push(this.searchRequestContactNumber!);
            this.handleUpdate();
        },

        addSearchRequestEmailAddress() {
            if (!this.canAddSearchRequestEmailAddress) {
                return;
            }

            this.contactForm!.emailAddresses.push(this.searchRequestEmailAddress!);
            this.handleUpdate();
        },

        async changeCountry() {
            if (!this.contactForm) {
                return;
            }

            this.contactForm = {
                ...this.contactForm,
                state: null,
            };

            this.handleUpdate();
        },

        handleUpdate() {
            this.$emit("input", trimAndAddUndefinedFields(cloneObject(this.contactForm!)));
        },

        async removePhoneNumber(index: number) {
            if (
                await showConfirm(
                    this.$t("Rufnummer löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Rufnummer löschen möchten?") as string
                )
            ) {
                this.contactForm!.numbers.splice(index, 1);
                this.handleUpdate();
            }
        },

        async removeEmailAddress(index: number) {
            if (
                await showConfirm(
                    this.$t("E-Mail-Adresse löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die E-Mail-Adresse löschen möchten?") as string
                )
            ) {
                this.contactForm!.emailAddresses.splice(index, 1);
                this.handleUpdate();
            }
        },
    },

    watch: {
        value() {
            this.contactForm = this.value ? trimAndAddUndefinedFields(cloneObject(this.value)) : null;
        },
    },

    components: {
        BirthdayPicker,
        CountryPicker,
        DatePicker,
        EnumField,
        ExpansionPanel,
        PhoneNumberField,
        StatePicker,
    },
});
