
import OfficeHoursFormPresenceCard from "./OfficeHoursFormPresenceCard.vue";
import { BadRequest } from "@/api/errors";
import { ExceptionalDay, ExceptionalDayEntry, officeHoursApi } from "@/api/officeHours";
import DDialog from "@/app/components/DDialog.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import { showInfo } from "@/app/messageUtil";
import { notEmpty, ValidationHelper } from "@/app/validation";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        officeHoursId: {
            type: String,
            required: true,
        },
        reasonProp: {
            type: String,
            required: false,
        },
        dateProp: {
            type: String,
            required: false,
        },
        show: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        const validationHelper = new ValidationHelper();

        return {
            validationHelper,
            valid: true,
            isWorking: false,
            presenceCardVisible: false,

            reason: this.reasonProp ? this.reasonProp : "",
            date: this.dateProp ? this.dateProp : "",
            entries: [] as ExceptionalDayEntry[],
            reasonRules: notEmpty().maxLength(300),
            dateRules: notEmpty().and(validationHelper, "date"),
        };
    },

    computed: {
        sortedEntries(): ExceptionalDayEntry[] {
            return [...this.entries].sort((a, b) => a.from.localeCompare(b.from, userSession.locale));
        },
    },

    methods: {
        addEntry(exceptionalDayEntry: ExceptionalDayEntry) {
            this.entries.push(exceptionalDayEntry);
            this.presenceCardVisible = false;
        },

        hidePresenceCard() {
            this.presenceCardVisible = false;
        },

        showPresenceCard() {
            this.presenceCardVisible = true;
        },

        deleteEntry(exceptionalDayEntry: ExceptionalDayEntry) {
            for (let i = 0; i < this.entries.length; i++) {
                if (JSON.stringify(exceptionalDayEntry) === JSON.stringify(this.entries[i])) {
                    this.entries.splice(i, 1);
                    return;
                }
            }
        },

        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            if (this.presenceCardVisible) {
                showInfo(this.$t("Bitte schließen oder speichern Sie Ihre Änderung bevor Sie fortfahren.") as string);
                return;
            }

            this.isWorking = true;

            const exceptionalDay: ExceptionalDay = {
                date: this.date,
                reason: this.reason,
                entries: this.entries,
            };

            try {
                await officeHoursApi.addExceptionalDay(this.officeHoursId, exceptionalDay);
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, this.$refs.form);
                return;
            } finally {
                this.isWorking = false;
            }
            (this.$refs.form as any).reset();
            this.$emit("success");
        },

        close() {
            (this.$refs.form as any).reset();
            this.$emit("close");
        },
    },

    components: {
        DatePicker,
        DDialog,
        OfficeHoursFormPresenceCard,
    },
});
