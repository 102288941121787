
import DealerContextWarning from "./DealerContextWarning.vue";
import DealerGlobalContextHint from "./DealerGlobalContextHint.vue";
import { Action, Callbacks } from "./crudPage";
import { userSession } from "@/store/userSession";
import Sortable from "sortablejs";
import Vue from "vue";

export default Vue.extend({
    props: {
        addDialogTitle: {
            type: String as () => string | null,
            default: null,
        },
        autoRefresh: {
            type: Boolean,
            default: true,
        },
        callbacks: {
            type: Object as () => Callbacks<unknown, unknown, unknown, unknown>,
            required: true,
        },
        dealerIdOptional: {
            type: Boolean,
            default: false,
        },
        dialogMaxWidth: {
            type: Number as () => number | null,
            default: 600,
        },
        editDialogTitle: {
            type: String as () => string | null,
            default: null,
        },
        global: {
            type: Boolean,
            default: false,
        },
        hideDealerContextWarning: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            required: false,
        },
        noItemsText: {
            type: String as () => string | null,
            default: null,
        },
        pageTitle: {
            type: String as () => string | null,
            default: null,
        },
        persistentDialog: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            items: [] as unknown[],
            form: null as unknown,
            editedItem: null as unknown,
            dialogVisible: false,
            loading: true,
            saving: false,
            deleting: false,
            handlingAction: false,
            sortableInitialized: false,
        };
    },

    computed: {
        actions(): Action<any>[] {
            return this.callbacks.actions || [];
        },

        dealerId() {
            return userSession.dealerId;
        },
    },

    methods: {
        async handleAction<T>(action: Action<T>, item: T) {
            this.handlingAction = true;
            try {
                const result = await action.callback(this.dealerId, item);
                if (this.autoRefresh && (result === undefined || result === true)) {
                    await this.loadItems();
                }
            } finally {
                this.handlingAction = false;
            }
        },

        async loadItems() {
            this.loading = true;
            try {
                if (this.dealerId || this.dealerIdOptional || this.global) {
                    this.items = await this.callbacks.load(this.dealerId);
                } else {
                    this.items = [];
                }
            } finally {
                this.loading = false;
            }
        },

        showCreateDialog() {
            const form = this.callbacks.emptyForm();
            if (form) {
                this.editedItem = null;
                this.form = form;
                this.dialogVisible = true;
            }
        },

        showEditDialog(item: unknown) {
            this.editedItem = item;
            this.form = this.callbacks.toForm!(item);
            this.dialogVisible = true;
        },

        async deleteItem(item: unknown) {
            this.deleting = true;
            try {
                const result = await this.callbacks.delete!(item);
                if (this.autoRefresh && (result === undefined || result === true)) {
                    await this.loadItems();
                }
            } finally {
                this.deleting = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                let result: boolean | void;
                if (this.editedItem) {
                    result = await this.callbacks.edit!(this.editedItem, this.form, this.$refs.form);
                } else {
                    result = await this.callbacks.add!(userSession.dealerId, this.form, this.$refs.form);
                }
                if (result === false) {
                    return;
                }
            } finally {
                this.saving = false;
            }

            this.dialogVisible = false;
            if (this.autoRefresh) {
                await this.loadItems();
            }
        },

        async executeAndRefresh(action: () => Promise<void> | void) {
            this.handlingAction = true;
            try {
                await action();
            } finally {
                try {
                    await this.loadItems();
                } finally {
                    this.handlingAction = false;
                }
            }
        },
    },

    watch: {
        async dealerId() {
            this.items = [];
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        items() {
            const items = this.$refs.items as HTMLElement;
            if (!this.sortableInitialized && this.items.length && items && this.callbacks.updateOrder) {
                Sortable.create(items, {
                    animation: 150,
                    handle: ".drag-handle",
                    ghostClass: "accent",
                    onEnd: async ({ oldIndex, newIndex }) => {
                        if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                            const item = this.items.splice(oldIndex, 1)[0];
                            this.items.splice(newIndex, 0, item);
                            await this.callbacks.updateOrder!(this.items);
                        }
                    },
                });
                this.sortableInitialized = true;
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DealerContextWarning,
        DealerGlobalContextHint,
    },
});
