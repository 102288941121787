
import SmsTemplateFormDialog from "./SmsTemplateFormDialog.vue";
import { SmsTemplate, smsTemplatesApi } from "@/api/smsTemplates";
import DataTable from "@/app/components/DataTable.vue";
import { showConfirm } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import Sortable from "sortablejs";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
    data() {
        return {
            loadingTemplates: true,
            smsTemplates: [] as SmsTemplate[],
            loadingFragments: true,
            smsFragments: [] as SmsTemplate[],
            formItem: null as SmsTemplate | null,
            formVisible: false,
            formFragment: false,
            templatesSortableInitialized: false,
            fragmentsSortableInitialized: false,
        };
    },

    components: {
        DealerGlobalContextHint,
        SmsTemplateFormDialog,
        DataTable,
    },

    computed: {
        headers() {
            return [
                {
                    text: "",
                    sortable: false,
                    width: "1%",
                },
                {
                    text: this.$t("Name"),
                    sortable: false,
                    width: "18%",
                },
                {
                    text: this.$t("Sprache/Region"),
                    sortable: false,
                    width: "20%",
                },
                {
                    text: this.$t("Inhalt"),
                    sortable: false,
                    width: "60%",
                },
                {
                    text: this.$t("Aktionen"),
                    sortable: false,
                    width: "1%",
                },
            ] as DataTableHeader[];
        },
    },

    watch: {
        smsTemplates() {
            const templateitems = document.getElementById("templateitems")?.firstChild?.firstChild?.childNodes?.item(3);
            if (!this.templatesSortableInitialized && this.smsTemplates.length && templateitems) {
                Sortable.create(templateitems as HTMLElement, {
                    animation: 150,
                    handle: ".drag-handle",
                    ghostClass: "accent",
                    onEnd: async ({ oldIndex, newIndex }) => {
                        if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                            this.loadingTemplates = true;
                            try {
                                const item = this.smsTemplates.splice(oldIndex, 1)[0];
                                this.smsTemplates.splice(newIndex, 0, item);
                                await smsTemplatesApi.editOrder(this.smsTemplates.map((d) => d.id));
                            } finally {
                                this.loadingTemplates = false;
                            }
                        }
                    },
                });
                this.templatesSortableInitialized = true;
            }
        },

        smsFragments() {
            const fragmentitems = document.getElementById("fragmentitems")?.firstChild?.firstChild?.childNodes?.item(3);
            if (!this.fragmentsSortableInitialized && this.smsFragments.length && fragmentitems) {
                Sortable.create(fragmentitems as HTMLElement, {
                    animation: 150,
                    handle: ".drag-handle",
                    ghostClass: "accent",
                    onEnd: async ({ oldIndex, newIndex }) => {
                        if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                            this.loadingFragments = true;
                            try {
                                const item = this.smsFragments.splice(oldIndex, 1)[0];
                                this.smsFragments.splice(newIndex, 0, item);
                                await smsTemplatesApi.editOrder(this.smsFragments.map((d) => d.id));
                            } finally {
                                this.loadingFragments = false;
                            }
                        }
                    },
                });
                this.fragmentsSortableInitialized = true;
            }
        },
    },

    methods: {
        async deleteItem(smsTemplate: SmsTemplate, fragment: boolean) {
            if (
                await showConfirm(
                    this.$t("Bestätigung") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie die SMS-Vorlage bzw. den SMS-Textbaustein löschen möchten?"
                    ) as string
                )
            ) {
                await smsTemplatesApi.delete(smsTemplate.id);
                if (fragment) {
                    await this.loadFragments();
                } else {
                    await this.loadTemplates();
                }
            }
        },

        async savedItem() {
            this.formVisible = false;
            if (this.formFragment) {
                await this.loadFragments();
            } else {
                await this.loadTemplates();
            }
        },

        async loadTemplates() {
            this.loadingTemplates = true;
            try {
                this.smsTemplates = await smsTemplatesApi.list(false);
            } finally {
                this.loadingTemplates = false;
            }
        },

        async loadFragments() {
            this.loadingFragments = true;
            try {
                this.smsFragments = await smsTemplatesApi.list(true);
            } finally {
                this.loadingFragments = false;
            }
        },

        showForm(formItem: SmsTemplate | null, fragment: boolean) {
            this.formItem = formItem;
            this.formVisible = true;
            this.formFragment = fragment;
        },
    },

    async mounted() {
        await this.loadTemplates();
        await this.loadFragments();
    },
});
