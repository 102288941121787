
import { Contact } from "@/api/contacts";
import { Dealer } from "@/api/dealers";
import {
    EmergencyIncident,
    emergencyIncidentsApi,
    EmergencyIncidentType,
    GeneralInfoForm,
} from "@/api/emergencyIncidents";
import { Partner, partnersApi } from "@/api/partners";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { getCountryCaption, getStateCaption } from "@/app/countryStateUtils";
import { dealerOptions } from "@/app/dealerUtils";
import ActionCard from "@/app/pages/ActionCard.vue";
import { e164, maxLength } from "@/app/validation";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        contact: {
            type: Object as () => Contact,
            required: false,
        },
        emergencyIncidentProp: {
            type: Object as () => EmergencyIncident,
            required: true,
        },
    },

    data() {
        return {
            EmergencyIncidentType,
            generalInfoFormData: {
                dealerId: this.emergencyIncidentProp.dealerId,
                type: this.emergencyIncidentProp.type,
                contactNumber: this.emergencyIncidentProp.contactNumber,
                costIndicationGiven: this.emergencyIncidentProp.costIndicationGiven,
                caller: this.emergencyIncidentProp.caller,
                externalIncidentNumber: this.emergencyIncidentProp.externalIncidentNumber,
                damage: this.emergencyIncidentProp.damage,
                location: this.emergencyIncidentProp.location,
                serviceProvider: this.emergencyIncidentProp.serviceProvider,
                towingTarget: this.emergencyIncidentProp.towingTarget,
            } as GeneralInfoForm,
            partners: [] as Partner[],
            working: false,
        };
    },

    computed: {
        contactNumberAutocompleteNumbers(): string[] {
            if (!this.contact || this.contact.deleted) {
                return [];
            }

            return this.contact.contactData.numbers.map((n) => n.number);
        },

        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },

        dealerAutocompleteValues(): string[] {
            return dealersStore.dealers.filter((d) => d.active).map((d) => this.getAutocompleteValue(d));
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        e164() {
            return e164;
        },

        maxLength() {
            return maxLength;
        },

        partnerAutocompleteValues(): string[] {
            return this.partners.map((p) => this.getAutocompleteValue(p));
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        getAutocompleteValue(address: Dealer | Partner): string {
            return [
                address.name,
                address.address1,
                address.address2,
                address.zip,
                address.city,
                address.state && address.country ? getStateCaption(address.country, address.state) : null,
                address.country ? getCountryCaption(address.country) : null,
            ]
                .filter((v) => !!v)
                .filter((v, pos, array) => pos === 0 || array[pos - 1] !== v)
                .join(", ");
        },

        async loadPartners() {
            this.partners = [];

            if (this.generalInfoFormData.dealerId) {
                this.partners = await partnersApi.getByDealer(this.generalInfoFormData.dealerId);
            }
        },

        async save() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.working = true;
            try {
                await emergencyIncidentsApi.editGeneralInfo(this.emergencyIncidentProp.id, {
                    ...this.generalInfoFormData,
                    contactNumber: this.generalInfoFormData.contactNumber || null,
                });
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },
    },

    watch: {
        async "generalInfoFormData.dealerId"() {
            try {
                await this.loadPartners();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadPartners();
    },

    components: {
        ActionCard,
        EnumField,
        PhoneNumberField,
    },
});
