import { Contact } from "./contacts";
import { toQueryString } from "@/util/apiUtils";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum IssueType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
    UNKNOWN = "UNKNOWN",
}

export enum OutgoingEmailEventType {
    DELIVERED = "DELIVERED",
    OPENED = "OPENED",
    CLICKED = "CLICKED",
    BOUNCED_HARD = "BOUNCED_HARD",
    BOUNCED_TRANSIENT = "BOUNCED_TRANSIENT",
    BOUNCED_AUTO_RESPONDER = "BOUNCED_AUTO_RESPONDER",
    BOUNCED_SPAM_NOTIFICATION = "BOUNCED_SPAM_NOTIFICATION",
    BOUNCED_SOFT = "BOUNCED_SOFT",
    BOUNCED_VIRUS_NOTIFICATION = "BOUNCED_VIRUS_NOTIFICATION",
    BOUNCED_SPAM_COMPLAINT = "BOUNCED_SPAM_COMPLAINT",
    BOUNCED_BLOCKED = "BOUNCED_BLOCKED",
    BOUNCED_OTHER = "BOUNCED_OTHER",
}

export enum OutgoingEmailSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    FROM_NAME_DESC = "FROM_NAME_DESC",
    FROM_NAME_ASC = "FROM_NAME_ASC",
    FROM_ADDRESS_DESC = "FROM_ADDRESS_DESC",
    FROM_ADDRESS_ASC = "FROM_ADDRESS_ASC",
    SUBJECT_DESC = "SUBJECT_DESC",
    SUBJECT_ASC = "SUBJECT_ASC",
}

export enum TransactionEmailReceiverType {
    TO = "TO",
    CC = "CC",
    BCC = "BCC",
}

export type IssueId = { readonly caseId: string } | { readonly opportunityId: string };

export interface OutgoingEmail {
    readonly id: string;
    readonly created: Date;
    readonly initiatorUserId: string | null;
    readonly initiatorCtUserId: string | null;
    readonly contactId: string | null;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly fromName: string | null;
    readonly fromAddress: string;
    readonly replyToName: string | null;
    readonly replyToAddress: string | null;
    readonly receivers: TransactionEmailReceiver[];
    readonly subject: string | null;
    readonly textBodyHash: string | null;
    readonly htmlBodyHash: string | null;
    readonly headers: TransactionEmailHeader[];
    readonly attachments: OutgoingEmailAttachment[];
    readonly events: OutgoingEmailEvent[];
}

export interface OutgoingEmailAndContact {
    readonly outgoingEmail: OutgoingEmail;
    readonly contact: Contact | null;
}

export interface OutgoingEmailAttachment {
    readonly id: number;
    readonly filename: string;
    readonly contentId: string | null;
    readonly contentHash: string;
    readonly contentType: string;
    readonly size: number;
}

export interface OutgoingEmailEvent {
    readonly eventType: OutgoingEmailEventType;
    readonly occuredAt: Date;
    readonly address: string | null;
    readonly link: string | null;
}

export interface OutgoingEmailForm {
    readonly fromAddress: string;
    readonly replyToAddress: string | null;
    readonly receivers: TransactionEmailReceiver[];
    readonly subject: string | null;
    readonly htmlBody: string;
    readonly headers: TransactionEmailHeader[];
}

export interface OutgoingEmailSearchRequest {
    readonly initiatorUserIds: string[];
    readonly issueTypes: IssueType[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly sortBy: OutgoingEmailSearchOrder;
}

export interface OutgoingEmailSearchResults {
    readonly results: OutgoingEmailAndContact[];
    readonly searchId: number;
    readonly totalSize: number;
}

export interface TransactionEmailHeader {
    readonly name: string;
    readonly value: string;
}

export interface TransactionEmailReceiver {
    readonly name: string | null;
    readonly address: string;
    readonly type: TransactionEmailReceiverType;
}

interface OutgoingEmailsApi {
    generateAttachmentLink(
        id: string,
        attachment: OutgoingEmailAttachment,
        issueId: IssueId | null,
        download?: boolean
    ): string;
    getHtmlBody(id: string, issueId: IssueId | null, inlineImages?: boolean): Promise<string>;
    getMyEmails(start: number, size: number, searchId: number): Promise<OutgoingEmailSearchResults>;
    getOutgoingEmailAndContact(id: string): Promise<OutgoingEmailAndContact>;
    getOutgoingEmails(issueId: IssueId): Promise<OutgoingEmail[]>;
    search(
        start: number,
        size: number,
        searchRequest: OutgoingEmailSearchRequest,
        searchId: number
    ): Promise<OutgoingEmailSearchResults>;
    generateRawEmailsLink(searchRequest: OutgoingEmailSearchRequest): string;
    sendEmail(
        contactId: string | null,
        outgoingEmailForm: OutgoingEmailForm,
        files: File[],
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
    prepareEmail(
        outgoingEmailForm: OutgoingEmailForm,
        files: File[],
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<File>;
}

export const outgoingEmailsApi: OutgoingEmailsApi = {
    generateAttachmentLink(id, attachment, issueId, download) {
        return `/api/outgoing-emails/${id}/${attachment.id}/${attachment.contentHash}/${encodeURIComponent(
            attachment.filename.replace(/\//g, "-")
        )}?${Object.entries(issueId || {})
            .map((e) => `${e[0]}=${e[1]}`)
            .join("&")}${download ? "&dl=true" : ""}`;
    },

    async getHtmlBody(id, issueId, inlineImages) {
        return (
            await axios.get(`/api/outgoing-emails/${id}/body`, {
                params: { ...issueId, inlineImages },
                headers: { Accept: "text/html" },
            })
        ).data;
    },

    async getMyEmails(start, size, searchId) {
        return cloneObject(
            (
                await axios.get("/api/outgoing-emails/_my-emails", {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },

    async getOutgoingEmailAndContact(id) {
        return cloneObject((await axios.get(`/api/outgoing-emails/${id}`)).data);
    },

    async getOutgoingEmails(issueId) {
        return cloneObject((await axios.get("/api/outgoing-emails", { params: issueId })).data);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/outgoing-emails/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },

    generateRawEmailsLink(searchRequest) {
        return `/api/outgoing-emails/_mbox?${toQueryString(searchRequest)}`;
    },

    async sendEmail(contactId, outgoingEmailForm, files, onUploadProgress) {
        const formData = new FormData();

        for (const file of files) {
            formData.append("files", file);
        }

        if (contactId) {
            formData.append("contactId", contactId);
        }

        formData.append("form", new Blob([JSON.stringify(outgoingEmailForm)], { type: "application/json" }));

        await axios.post("/api/outgoing-emails/_send-email", formData, { onUploadProgress });
    },

    async prepareEmail(outgoingEmailForm, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }
        formData.append("form", new Blob([JSON.stringify(outgoingEmailForm)], { type: "application/json" }));
        const response = await axios.post("/api/outgoing-emails/_prepare-email", formData, {
            onUploadProgress,
            responseType: "blob",
        });
        return new File([response.data], "", { type: response.headers["content-type"] });
    },
};
