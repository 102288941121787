import axios from "axios";

export enum SupportMessageTopic {
    QUALITY_MANAGEMENT = "QUALITY_MANAGEMENT",
    SUPPORT = "SUPPORT",
    BILLING = "BILLING",
}

export interface SupportMessageForm {
    readonly topic: SupportMessageTopic;
    readonly phoneNumber: string | null;
    readonly message: string;
}

interface SupportApi {
    submitSupportMessage(supportMessageForm: SupportMessageForm): Promise<void>;
}

export const supportApi: SupportApi = {
    async submitSupportMessage(supportMessageForm) {
        await axios.post(`/api/support/_submit`, supportMessageForm);
    },
};
