
import OutgoingSmsAssistant from "@/app/pages/outgoingsms/assistant/OutgoingSmsAssistant.vue";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        videochatId: {
            type: String,
            required: true,
        },
    },

    computed: {
        videochatLink(): string {
            return `https://${configStore.configuration.appHost}/v/${this.videochatId}`;
        },

        videochatLinkText(): string {
            return `${this.$t("Bitte klicken Sie auf folgenden Link, um dem Videochat beizutreten:")} ${
                this.videochatLink
            }`;

            // TODO displayName ?
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },
    },

    components: {
        OutgoingSmsAssistant,
    },
});
