
import { Case, CaseConversionForm } from "@/api/cases";
import { Contact, ContactType } from "@/api/contacts";
import { showError } from "@/app/messageUtil";
import OpportunityTeamPickerCard from "@/app/pages/opportunities/OpportunityTeamPickerCard.vue";
import UserPickerCard from "@/app/pages/users/UserPickerCard.vue";
import { UserPickerData } from "@/app/pages/users/userPickerCardTypes";
import { notEmpty } from "@/app/validation";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseProp: {
            type: Object as () => Case,
            required: true,
        },
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
    },

    data() {
        return {
            converting: false,
            notEmpty,
            opportunityIndividualReceivers: new UserPickerData(this.caseProp.individualReceivers.map((i) => i.userId)),
            opportunityTeamReceivers: [] as string[],
            sourceId: null as string | null,
        };
    },

    computed: {
        contactType(): ContactType | null {
            if (this.contact?.deleted) {
                return null;
            }

            return this.contact?.contactData?.type ?? null;
        },

        sourceOptions(): SelectOptions {
            return opportunitySourcesStore.opportunitySources.map((s) => ({
                value: s.id,
                text: s.name,
            }));
        },
    },

    methods: {
        submit() {
            if (!(this.$refs.convertForm as any).validate()) {
                return;
            }

            if (!this.opportunityTeamReceivers.length && !this.opportunityIndividualReceivers.userIds.length) {
                showError(this.$t("Es ist kein Empfänger ausgewählt!") as string);
                return;
            }

            this.converting = true;
            this.$emit("submit", {
                sourceId: this.sourceId!,
                opportunityIndividualReceivers: this.opportunityIndividualReceivers.userIds,
                opportunityTeamReceivers: this.opportunityTeamReceivers,
            } as CaseConversionForm);
        },
    },

    components: {
        OpportunityTeamPickerCard,
        UserPickerCard,
    },
});
