
import { ServiceAccount, ServiceAccountForm, serviceAccountsApi } from "@/api/serviceAccounts";
import { showAlert, showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    computed: {
        api() {
            return serviceAccountsApi;
        },
        notEmpty() {
            return notEmpty;
        },
    },

    methods: {
        async addServiceAccount(form: ServiceAccountForm) {
            const secret = await serviceAccountsApi.addServiceAccount(form);
            await showAlert(
                this.$t('Berechtigungsschlüssel für Dienstkonto "{0}"', [form.name]) as string,
                `${this.$t("Kopieren Sie den Schlüssel. Er wird hier letztmalig angezeigt.")}\n\n${secret}`
            );
        },

        async deleteServiceAccount(item: ServiceAccount) {
            if (
                await showConfirm(
                    this.$t("Dienstkonto löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie das Dienstkonto löschen möchten?") as string
                )
            ) {
                await serviceAccountsApi.deleteServiceAccount(item.id);
                return true;
            }
            return false;
        },

        emptyForm(): ServiceAccountForm {
            return {
                name: "",
                readOnly: false,
            };
        },

        async deleteSubscription(serviceAccountId: string, subscriptionId: string) {
            if (
                !(await showConfirm(
                    this.$t("API Subscription löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die API Subscription löschen möchten?") as string
                ))
            ) {
                return;
            }

            await (this.$refs.crudPage as any).executeAndRefresh(() =>
                serviceAccountsApi.deleteServiceAccountSubscription(serviceAccountId, subscriptionId)
            );
        },
    },

    components: {
        CrudPage,
    },
});
