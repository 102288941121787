
import EmailAliasCreationDialog from "./EmailAliasCreationDialog.vue";
import { emailDomainsApi, EmailDomainView } from "@/api/emailDomains";
import { BadRequest } from "@/api/errors";
import { isEmailDomainUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { showAlert, showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import { regEx, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

interface EmailDomainForm {
    custom: boolean;
    domain: string;
    receive: boolean;
}

interface Record {
    readonly verified: boolean;
    readonly subdomain: string | null;
    readonly type: string;
    readonly value: string;
}

interface EmailDomainViewWithRecords extends EmailDomainView {
    readonly records: Record[];
}

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            comparingDnsData: false,
            emailAliasCreationDialogEmailDomainId: null as string | null,
            validationHelper,
            prefixRule: regEx(/^(?=.{1,32}$)[a-z][a-z0-9]*(-[a-z0-9]+)*$/).and(validationHelper, "fqdn"),
            fqdnRule: regEx(/^(?=.{1,300}$)[a-z][a-z0-9]*(-[a-z0-9]+)*(\.[a-z][a-z0-9]*(-[a-z0-9]+)*)+$/).and(
                validationHelper,
                "fqdn"
            ),
            notificationHandler: null as ((n: Notification) => void) | null,
        };
    },

    computed: {
        api() {
            return emailDomainsApi;
        },

        emailDomain() {
            return configStore.configuration.emailDomain;
        },

        isDeletionAllowed() {
            return !!userSession.permissions && userSession.permissions.includes(Permission.DELETE_EMAIL_DOMAIN);
        },

        isAddingEmailAliasesAllowed() {
            return !!userSession.permissions && userSession.permissions.includes(Permission.INITIALIZE_PARTITION);
        },
    },

    methods: {
        async deleteDomain(item: EmailDomainView) {
            if (
                await showConfirm(
                    this.$t("Benutzer-E-Mail-Domain löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Benutzer-E-Mail-Domain löschen möchten?") as string
                )
            ) {
                await emailDomainsApi.deleteDomain(item.id);
                return true;
            }
            return false;
        },

        async addDomain({ custom, domain, receive }: EmailDomainForm, formRef: unknown) {
            try {
                if (custom) {
                    await emailDomainsApi.addCustomDomain(domain, receive);
                } else {
                    await emailDomainsApi.addDomain(domain);
                }
                return true;
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, formRef);
                return false;
            }
        },

        async compareWithDns(emailDomain: EmailDomainView) {
            this.comparingDnsData = true;

            try {
                const values = await emailDomainsApi.getDnsValues(emailDomain.id);

                const errors: TranslateResult[] = [];
                if (!values.dkim) {
                    errors.push(this.$t("Der TXT-Eintrag ist nicht gesetzt."));
                } else if (values.dkim !== emailDomain.dkimDnsData) {
                    errors.push(this.$t("Der TXT-Eintrag enthält einen nicht erwarteten Wert: {0}", [values.dkim]));
                }
                if (!values.returnPath) {
                    errors.push(this.$t("Der CNAME-Eintrag ist nicht gesetzt."));
                } else if (values.returnPath !== emailDomain.returnPathDnsData) {
                    errors.push(
                        this.$t("Der CNAME-Eintrag enthält einen nicht erwarteten Wert: {0}", [values.returnPath])
                    );
                }

                await showAlert(
                    this.$t("DNS-Daten") as string,
                    errors.length
                        ? (this.$t("Die Daten im DNS stimmen nicht mit den erwarteten Werten überein.\n\n{0}", [
                              errors.join("\n\n"),
                          ]) as string)
                        : (this.$t("Die Daten im DNS stimmen mit den erwarteten Werten überein.") as string)
                );
            } finally {
                this.comparingDnsData = false;
            }
        },

        downloadDnsData(item: EmailDomainViewWithRecords) {
            const content = [
                `${this.$t("Domain")}: ${item.id}`,
                ...item.records.map((i) => [
                    [
                        i.subdomain ? `${this.$t("Subdomain")}: ${i.subdomain}` : `${this.$t("Domain")}: ${item.id}`,
                        `${this.$t("Typ")}: ${i.type}`,
                        `${this.$t("Wert")}: ${i.value}`,
                    ].join("\n"),
                ]),
            ].join("\n\n");

            const element = document.createElement("a");
            element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
            element.setAttribute("download", item.id + ".txt");
            element.click();
        },

        emptyForm(): EmailDomainForm {
            return {
                custom: false,
                domain: "",
                receive: false,
            };
        },

        async loadDomains(): Promise<EmailDomainViewWithRecords[]> {
            return (await emailDomainsApi.getDomains()).map((d) => ({
                ...d,
                records: [
                    {
                        verified: d.dkimVerified,
                        subdomain: d.dkimDnsName.substring(0, d.dkimDnsName.length - d.id.length - 1),
                        type: "TXT",
                        value: d.dkimDnsData,
                    },
                    {
                        verified: d.returnPathVerified,
                        subdomain: d.returnPathDnsName.substring(0, d.returnPathDnsName.length - d.id.length - 1),
                        type: "CNAME",
                        value: d.returnPathDnsData,
                    },
                    {
                        verified: d.mxVerified,
                        subdomain: null,
                        type: "MX",
                        value: d.mxDnsData,
                    },
                ].filter((r): r is Record => !!r.value),
            }));
        },
    },

    mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isEmailDomainUpdatedNotification(n)) {
                const crud = this.$refs.crud;
                if (crud) {
                    Vue.nextTick(() => (crud as any).loadItems());
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        CopyToClipboardIcon,
        CrudPage,
        EmailAliasCreationDialog,
    },
});
