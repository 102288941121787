import axios from "axios";

export interface OpportunitySource {
    readonly id: string;
    readonly name: string;
    readonly notes: string | null;
}

export interface OpportunitySourceForm {
    readonly name: string;
    readonly notes: string | null;
}

interface OpportunitySourcesApi {
    getAll(): Promise<OpportunitySource[]>;
    updateOrder(ids: string[]): Promise<void>;
    add(opportunitySourceForm: OpportunitySourceForm): Promise<void>;
    edit(id: string, opportunitySourceForm: OpportunitySourceForm): Promise<void>;
    merge(id: string, newId: string): Promise<void>;
}

export const opportunitySourcesApi: OpportunitySourcesApi = {
    async getAll() {
        return (await axios.get(`/api/opportunity-sources`)).data;
    },

    async updateOrder(ids) {
        await axios.post(`/api/opportunity-sources/_update-order`, ids);
    },

    async add(opportunitySourceForm) {
        await axios.post(`/api/opportunity-sources`, opportunitySourceForm);
    },

    async edit(id, opportunitySourceForm) {
        await axios.put(`/api/opportunity-sources/${id}`, opportunitySourceForm);
    },

    async merge(id, newId) {
        await axios.delete(`/api/opportunity-sources/${id}`, { params: { newId } });
    },
};
