
import { OpportunityActionResult, userActionLinkApi } from "@/api/userActionLinkApi";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            authorized: false,
            opportunityActionResult: null as OpportunityActionResult | null,
            connecting: true,
            dialog: true,
        };
    },

    computed: {
        closedText(): string | null {
            if (!this.opportunityActionResult) {
                return null;
            }

            if (this.opportunityActionResult.hasOpportunityChanged) {
                return this.$t("Verkaufschance {0} wurde geschlossen.", [
                    this.opportunityActionResult.opportunityId,
                ]) as string;
            } else if (this.opportunityActionResult.wasClosedBySelf) {
                return this.$t("Verkaufschance {0} wurde bereits am {1} von Ihnen geschlossen.", [
                    this.opportunityActionResult.opportunityId,
                    this.renderInstant(this.opportunityActionResult.closed!),
                ]) as string;
            } else if (this.opportunityActionResult.isCloserUser) {
                if (this.opportunityActionResult.closerFullName) {
                    return this.$t("Verkaufschance {0} wurde bereits am {1} von {2} geschlossen.", [
                        this.opportunityActionResult.opportunityId,
                        this.renderInstant(this.opportunityActionResult.closed!),
                        this.opportunityActionResult.closerFullName,
                    ]) as string;
                } else {
                    return this.$t(
                        "Verkaufschance {0} wurde bereits am {1} von einem gelöschten Benutzer geschlossen.",
                        [
                            this.opportunityActionResult.opportunityId,
                            this.renderInstant(this.opportunityActionResult.closed!),
                        ]
                    ) as string;
                }
            } else if (this.opportunityActionResult.isCloserCtUser) {
                return this.$t("Verkaufschance {0} wurde bereits am {1} von einem externen Agenten geschlossen.", [
                    this.opportunityActionResult.opportunityId,
                    this.renderInstant(this.opportunityActionResult.closed!),
                ]) as string;
            } else {
                return this.$t("Verkaufschance {0} wurde bereits am {1} vom System geschlossen.", [
                    this.opportunityActionResult.opportunityId,
                    this.renderInstant(this.opportunityActionResult.closed!),
                ]) as string;
            }
        },

        closedTitle(): string | null {
            if (!this.opportunityActionResult) {
                return null;
            }

            if (this.opportunityActionResult.wasClosedBySelf) {
                if (this.opportunityActionResult.hasOpportunityChanged) {
                    return this.$t("Die Verkaufschance wurde Ihnen zugeteilt.") as string;
                } else {
                    return this.$t("Die Verkaufschance ist Ihnen bereits zugeteilt.") as string;
                }
            } else {
                return this.$t("Die Verkaufschance konnte Ihnen nicht zugeteilt werden.") as string;
            }
        },

        errorText(): string | null {
            if (this.connecting) {
                return null;
            }

            if (!this.authorized) {
                return this.$t("Dieser Link ist abgelaufen.") as string;
            } else if (!this.opportunityActionResult || !this.opportunityActionResult.opportunityId) {
                return this.$t("Die Verkaufschance wurde nicht gefunden.") as string;
            } else if (!this.opportunityActionResult.closed) {
                return this.$t("Es ist ein Fehler beim Schließen der Verkaufschance aufgetreten.") as string;
            } else {
                return null;
            }
        },

        hasError(): boolean {
            if (this.connecting) {
                return false;
            }

            return (
                !this.authorized ||
                !this.opportunityActionResult ||
                !this.opportunityActionResult.opportunityId ||
                !this.opportunityActionResult.closed
            );
        },

        ualt(): string {
            return (this.$route.query.ualt || "") as string;
        },
    },

    methods: {
        renderInstant(instant: Date): string | null {
            if (!this.opportunityActionResult) {
                return null;
            }

            return formatInstant(
                instant,
                this.opportunityActionResult.timeZone,
                this.opportunityActionResult.locale,
                "S"
            );
        },
    },

    async created() {
        try {
            this.connecting = true;
            this.opportunityActionResult = await userActionLinkApi.closeOpportunity(this.ualt);

            this.$i18n.locale = this.$vuetify.lang.current = this.opportunityActionResult.locale;
            this.$vuetify.theme.dark = this.opportunityActionResult.darkmode;
        } catch (e) {
            // noop
        } finally {
            this.authorized = !!this.opportunityActionResult;
            this.connecting = false;
        }
    },
});
