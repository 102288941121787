import { $t } from "./i18n";
import { VehicleClass } from "@/api/makeModels";
import { makeModelsStore } from "@/store/makeModels";
import { SelectOptions } from "@/util/types";

export function getVehicleClassIcon(vehicleClass: VehicleClass): string {
    if (vehicleClass === VehicleClass.CAR) {
        return "mdi-car-side";
    } else if (vehicleClass === VehicleClass.MOTORBIKE) {
        return "mdi-motorbike";
    } else if (vehicleClass === VehicleClass.MOTORHOME) {
        return "mdi-rv-truck";
    } else if (vehicleClass === VehicleClass.TRUCK) {
        return "mdi-truck";
    } else {
        return "mdi-car";
    }
}

export function getModelOptions(makes: string[]): SelectOptions {
    const modelgroups = makeModelsStore.getModelgroupsByMakes(makes);
    const models = makeModelsStore.getModelsByMakes(makes);

    const otherModels = models.filter((model) => !modelgroups.some((g) => g.models.includes(model)));

    const selectionGroups = [...modelgroups, { name: $t("Sonstige Modelle"), models: otherModels }].filter(
        (group) => group.models.length
    );

    return selectionGroups.reduce(
        (options, group) =>
            options.concat([
                ...(selectionGroups.length > 1 ? [{ header: group.name }] : []),
                ...group.models.map((model) => ({
                    text: model,
                    value: model,
                })),
            ]),
        [] as SelectOptions
    );
}
