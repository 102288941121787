import { announcementsApi } from "@/api/announcements";
import { isAnnouncementNotification, notificationEventSource } from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class AnnouncementsStore {
    private refreshLimiter = new ActionLimiter(true);
    private toRead_ = 0;
    private toConfirm_ = 0;

    get toRead() {
        return this.toRead_;
    }

    get toConfirm() {
        return this.toConfirm_;
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            const status = await announcementsApi.getStatus();

            this.toRead_ = status.toRead;
            this.toConfirm_ = status.toConfirm;
        });
    }
}

export const announcementsStore = new AnnouncementsStore();

notificationEventSource.addDataHandler((n) => {
    if (isAnnouncementNotification(n)) {
        announcementsStore.refresh();
    }
});
