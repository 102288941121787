import axios from "axios";

export interface CallSettings {
    readonly inboundCallerId: string | null;
    readonly answerOnBridge: boolean;
}

interface CallSettingsApi {
    getCallSettings(): Promise<CallSettings>;
    editCallSettings(callSettings: CallSettings): Promise<void>;
    isInboundCallerIdConfigured(): Promise<boolean>;
}

export const callSettingsApi: CallSettingsApi = {
    async getCallSettings() {
        return (await axios.get("/api/call-settings")).data;
    },

    async editCallSettings(callSettings) {
        await axios.put("/api/call-settings", callSettings);
    },

    async isInboundCallerIdConfigured() {
        return (await axios.get("/api/call-settings/_inbound-caller-id-configured")).data;
    },
};
