
import InventoryVehicleInquiryDataTableRow from "./InventoryVehicleInquiryDataTableRow.vue";
import { inventoryVehicleInquiryOverviewState } from "./inventoryVehicleInquiryOverview";
import {
    InventoryVehicleInquiryAndContact,
    inventoryVehicleInquiryApi,
    InventoryVehicleInquirySearchOrder,
} from "@/api/inventoryVehicleInquiries";
import DataTable from "@/app/components/DataTable.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { dealerOptions } from "@/app/dealerUtils";
import { showConfirm } from "@/app/messageUtil";
import { userSession } from "@/store/userSession";
import { PickMutable, SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

const ITEMS_PER_PAGE = 25;

export default Vue.extend({
    data() {
        return {
            inventoryVehicleInquiryOverviewState,
            InventoryVehicleInquirySearchOrder,
            items: [] as InventoryVehicleInquiryAndContact[],
            loading: true,
            paging: {
                page: 1,
                pageSize: ITEMS_PER_PAGE,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / ITEMS_PER_PAGE,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
        };
    },

    computed: {
        dealerId(): string | null {
            return userSession.dealerId;
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Suchauftrag"),
                    width: "25%",
                },
                {
                    text: this.$t("Kommentar"),
                    width: "25%",
                },
                {
                    text: this.$t("Fahrzeug"),
                    width: "49%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "1%",
                },
            ];
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        enabledAndValidOptions(): SelectOption[] {
            return [
                {
                    value: true,
                    text: this.$t("Gültig und aktiv"),
                },
                {
                    value: false,
                    text: this.$t("Ungültig oder inaktiv"),
                },
            ];
        },
    },

    methods: {
        async deleteInquiry(id: string) {
            if (
                !(await showConfirm(
                    this.$t("Suchauftrag löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Suchauftrag löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.loading = true;
            try {
                await inventoryVehicleInquiryApi.delete(id);
            } finally {
                this.loading = false;
            }
            this.refresh();
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const inventoryVehicleInquirySearchResults = await inventoryVehicleInquiryApi.search(
                    (this.paging.page - 1) * ITEMS_PER_PAGE,
                    ITEMS_PER_PAGE,
                    this.inventoryVehicleInquiryOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (inventoryVehicleInquirySearchResults.searchId === this.searchCounter) {
                    this.items = inventoryVehicleInquirySearchResults.results;
                    this.paging.totalSize = inventoryVehicleInquirySearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        refresh() {
            this.inventoryVehicleInquiryOverviewState.refresh();
        },

        reset() {
            this.inventoryVehicleInquiryOverviewState.reset();
        },
    },

    watch: {
        dealerId() {
            this.inventoryVehicleInquiryOverviewState.dealerIds = this.dealerId ? [this.dealerId] : [];
        },

        async "inventoryVehicleInquiryOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    mounted() {
        if (!this.inventoryVehicleInquiryOverviewState.initialized) {
            this.reset();
        } else {
            this.refresh();
        }
    },

    components: {
        EnumField,
        DataTable,
        InventoryVehicleInquiryDataTableRow,
    },
});
