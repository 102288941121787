
import { CaseSearchOrder, CaseSearchRequest } from "@/api/caseSearch";
import { EmergencyIncidentSearchOrder, EmergencyIncidentSearchRequest } from "@/api/emergencyIncidentSearch";
import { OpportunitySearchOrder, OpportunitySearchRequest } from "@/api/opportunitySearch";
import { Permission } from "@/api/userSession";
import CasesDataTable from "@/app/pages/cases/CasesDataTable.vue";
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import { EMPTY_SEARCH_REQUEST as EMPTY_CASE_SEARCH_REQUEST } from "@/app/pages/cases/caseSearchUtils";
import ContactAndVehiclePicker from "@/app/pages/contacts/ContactAndVehiclePicker.vue";
import EmergencyIncidentsDataTable from "@/app/pages/emergencyincidents/EmergencyIncidentsDataTable.vue";
import { EMPTY_SEARCH_REQUEST as EMPTY_EMERGENCY_INCIDENT_SEARCH_REQUEST } from "@/app/pages/emergencyincidents/emergencyIncidentSearchUtils";
import OpportunitiesDataTable from "@/app/pages/opportunities/OpportunitiesDataTable.vue";
import { EMPTY_SEARCH_REQUEST as EMPTY_OPPORTUNITY_SEARCH_REQUEST } from "@/app/pages/opportunities/opportunitySearchUtils";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            loadedCases: null as number | null,
            loadedEmergencyIncidents: null as number | null,
            loadedOpportunities: null as number | null,
        };
    },

    computed: {
        contactId(): string | null {
            return this.caseAssistantState.contactAndVehicleId?.contactId ?? null;
        },

        hasCases(): boolean {
            return !!this.loadedCases;
        },

        hasEmergencyIncidents(): boolean {
            return !!this.loadedEmergencyIncidents;
        },

        hasOpportunities(): boolean {
            return !!this.loadedOpportunities;
        },

        showContactCases(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_CASES) && !!this.contactId;
        },

        caseSearchRequest(): CaseSearchRequest {
            return {
                ...EMPTY_CASE_SEARCH_REQUEST,
                contactIds: this.contactId ? [this.contactId] : [],
                sortBy: CaseSearchOrder.LAST_ACTIVITY_DESC,
            };
        },

        showContactEmergencyIncidents(): boolean {
            return userSession.hasPermission(Permission.MANAGE_EMERGENCY_INCIDENTS) && !!this.contactId;
        },

        emergencyIncidentSearchRequest(): EmergencyIncidentSearchRequest {
            return {
                ...EMPTY_EMERGENCY_INCIDENT_SEARCH_REQUEST,
                contactId: this.contactId,
                sortBy: EmergencyIncidentSearchOrder.LAST_ACTIVITY_DESC,
            };
        },

        showContactOpportunities(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES) && !!this.contactId;
        },

        opportunitySearchRequest(): OpportunitySearchRequest {
            return {
                ...EMPTY_OPPORTUNITY_SEARCH_REQUEST,
                contactIds: this.contactId ? [this.contactId] : [],
                sortBy: OpportunitySearchOrder.LAST_ACTIVITY_DESC,
            };
        },

        isAgent(): boolean {
            return userSession.isAgent();
        },

        phoneticSpellingLocale(): string {
            return configStore.configuration.defaultLocale;
        },
    },

    watch: {
        contactId(newContactId: string | null, oldContactId: string | null) {
            if (newContactId !== oldContactId) {
                this.loadedCases = null;
                this.loadedEmergencyIncidents = null;
                this.loadedOpportunities = null;
            }
        },
    },

    components: {
        CasesDataTable,
        ContactAndVehiclePicker,
        EmergencyIncidentsDataTable,
        OpportunitiesDataTable,
    },
});
