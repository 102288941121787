
import { Dealer } from "@/api/dealers";
import EnhancedText from "@/app/components/EnhancedText.vue";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    components: { EnhancedText },
    props: {
        dealerId: {
            type: String,
            required: true,
        },
    },

    computed: {
        dealer(): Dealer | null {
            return dealersStore.dealerById(this.dealerId);
        },
    },
});
