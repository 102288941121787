var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('dealer-context-guard',{attrs:{"permission":_vm.Permission.MANAGE_REPORTING_SETTINGS}},[_c('v-form',{ref:"reportingsettingsform",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',{staticClass:"mb-3",attrs:{"outlined":"","disabled":_vm.loading}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("Falleinstellungen"))+" ")])],1),_c('v-card-text',[(!_vm.reportingDealerSettings)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_c('v-row',{staticClass:"my-n3"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","clearable":"","hide-details":"auto","hint":_vm.$t(
                                        'Nur der Zeitraum innerhalb der gewählten Öffnungszeiten wird zur Berechnung der Reaktionszeit herangezogen. Falls keine Öffnungszeiten hinterlegt sind, wird der gesamte Tag berücksichtigt.'
                                    ),"id":_vm.$id(),"items":_vm.officeHoursOptions,"label":_vm.$t('Öffnungszeiten für die Bestimmung der Reaktionszeit'),"persistent-hint":""},model:{value:(_vm.reportingDealerSettings.caseReactionTimeOfficeHoursId),callback:function ($$v) {_vm.$set(_vm.reportingDealerSettings, "caseReactionTimeOfficeHoursId", $$v)},expression:"reportingDealerSettings.caseReactionTimeOfficeHoursId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","clearable":"","hide-details":"auto","hint":_vm.$t(
                                        'Nur der Zeitraum innerhalb der gewählten Öffnungszeiten wird zur Berechnung der Reaktionszeit herangezogen. Falls keine Öffnungszeiten hinterlegt sind, wird der gesamte Tag berücksichtigt.'
                                    ),"id":_vm.$id(),"items":_vm.officeHoursOptions,"label":_vm.$t('Öffnungszeiten für die Bestimmung der Reaktionszeit aus Sicht des Kontakts'),"persistent-hint":""},model:{value:(_vm.reportingDealerSettings.caseReactionTimeAsPerceivedExternallyOfficeHoursId),callback:function ($$v) {_vm.$set(_vm.reportingDealerSettings, "caseReactionTimeAsPerceivedExternallyOfficeHoursId", $$v)},expression:"reportingDealerSettings.caseReactionTimeAsPerceivedExternallyOfficeHoursId"}})],1)],1)],1)],1),_c('v-card',{staticClass:"mb-3",attrs:{"outlined":"","disabled":_vm.loading}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("Verkaufschanceneinstellungen"))+" ")])],1),_c('v-card-text',[(!_vm.reportingDealerSettings)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_c('v-row',{staticClass:"my-n3"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","clearable":"","hide-details":"auto","hint":_vm.$t(
                                        'Nur der Zeitraum innerhalb der gewählten Öffnungszeiten wird zur Berechnung der Reaktionszeit herangezogen. Falls keine Öffnungszeiten hinterlegt sind, wird der gesamte Tag berücksichtigt.'
                                    ),"id":_vm.$id(),"items":_vm.officeHoursOptions,"label":_vm.$t('Öffnungszeiten für die Bestimmung der Reaktionszeit'),"persistent-hint":""},model:{value:(_vm.reportingDealerSettings.opportunityReactionTimeOfficeHoursId),callback:function ($$v) {_vm.$set(_vm.reportingDealerSettings, "opportunityReactionTimeOfficeHoursId", $$v)},expression:"reportingDealerSettings.opportunityReactionTimeOfficeHoursId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","clearable":"","hide-details":"auto","hint":_vm.$t(
                                        'Nur der Zeitraum innerhalb der gewählten Öffnungszeiten wird zur Berechnung der Reaktionszeit herangezogen. Falls keine Öffnungszeiten hinterlegt sind, wird der gesamte Tag berücksichtigt.'
                                    ),"id":_vm.$id(),"items":_vm.officeHoursOptions,"label":_vm.$t('Öffnungszeiten für die Bestimmung der Reaktionszeit aus Sicht des Kontakts'),"persistent-hint":""},model:{value:(
                                    _vm.reportingDealerSettings.opportunityReactionTimeAsPerceivedExternallyOfficeHoursId
                                ),callback:function ($$v) {_vm.$set(_vm.reportingDealerSettings, "opportunityReactionTimeAsPerceivedExternallyOfficeHoursId", $$v)},expression:"\n                                    reportingDealerSettings.opportunityReactionTimeAsPerceivedExternallyOfficeHoursId\n                                "}})],1)],1)],1)],1),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.working,"small":""},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("Speichern")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }