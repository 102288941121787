import axios from "axios";

export enum VehicleClass {
    CAR = "CAR",
    MOTORBIKE = "MOTORBIKE",
    MOTORHOME = "MOTORHOME",
    TRUCK = "TRUCK",
}

export interface VehicleFullClass {
    readonly classKey: string;
    readonly categoriesAndMakes: CategoriesAndMakes;
}

export interface CategoriesAndMakes {
    readonly categories: string[];
    readonly makes: MakeWithModels[];
}

export interface MakeWithModels {
    readonly name: string;
    readonly models: string[];
    readonly modelgroups: ModelgroupWithModels[];
}

export interface ModelgroupWithModels {
    readonly name: string;
    readonly models: string[];
}

interface MakeModelsApi {
    getFullClasses(): Promise<VehicleFullClass[]>;
}

export const makeModelsApi: MakeModelsApi = {
    async getFullClasses() {
        return (await axios.get("/api/makes")).data;
    },
};
