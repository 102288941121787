import moment, { DurationInputArg2, tz } from "moment-timezone";

export enum UnitOfTime {
    SECOND,
    MINUTE,
    HOUR,
    DAY,
    WEEK,
    MONTH,
    QUARTER,
    YEAR,
}

export type DateTimeOutputFormat = "L" | "S";

export function availableTimeZones(): string[] {
    return tz.names();
}

export function is24hrFormat(locale: string): boolean {
    return moment("2000-01-01T23:00:00Z")
        .tz("UTC")
        .locale(locale)
        .format("LT")
        .startsWith("23");
}

function toMomentDurationUnitOfTime(unitOfTime: UnitOfTime): DurationInputArg2 {
    if (unitOfTime === UnitOfTime.SECOND) {
        return "second";
    } else if (unitOfTime === UnitOfTime.MINUTE) {
        return "minute";
    } else if (unitOfTime === UnitOfTime.HOUR) {
        return "hour";
    } else if (unitOfTime === UnitOfTime.DAY) {
        return "day";
    } else if (unitOfTime === UnitOfTime.WEEK) {
        return "week";
    } else if (unitOfTime === UnitOfTime.MONTH) {
        return "month";
    } else if (unitOfTime === UnitOfTime.QUARTER) {
        return "quarter";
    } else {
        return "year";
    }
}

export function addDuration(date: Date, timeZone: string, offset: number, unitOfTime: UnitOfTime): Date {
    if (offset === 0) {
        return new Date(date);
    }

    return moment(date)
        .tz(timeZone)
        .add(offset, toMomentDurationUnitOfTime(unitOfTime))
        .toDate();
}

function addDurations(date: Date, tz: string, durations: { offset: number; unitOfTime: UnitOfTime }[]): Date {
    return durations.reduce(
        (d, duration) => (duration.offset ? addDuration(d, tz, duration.offset, duration.unitOfTime) : d),
        new Date(date)
    );
}

export function endOf(date: Date, timeZone: string, locale: string, unitOfTime: UnitOfTime): Date {
    return moment(date)
        .tz(timeZone)
        .locale(locale)
        .endOf(toMomentDurationUnitOfTime(unitOfTime))
        .toDate();
}

export function startOf(date: Date, timeZone: string, locale: string, unitOfTime: UnitOfTime): Date {
    return moment(date)
        .tz(timeZone)
        .locale(locale)
        .startOf(toMomentDurationUnitOfTime(unitOfTime))
        .toDate();
}

export function getFirstDayOfWeek(locale: string): number {
    return moment()
        .locale(locale)
        .weekday(0)
        .day();
}

export function getDayOfWeekByDate(date: Date, local: string): number {
    return moment(date)
        .locale(local)
        .day();
}

export function getDayOfWeekName(dayOfWeek: number, locale: string, format?: DateTimeOutputFormat): string {
    return moment("2000-01-02T23:00:00Z")
        .tz("UTC")
        .locale(locale)
        .add(dayOfWeek, "d")
        .format(format === "S" ? "ddd" : "dddd");
}

export function getMonthName(month: number, locale: string, format: DateTimeOutputFormat): string {
    return moment()
        .locale(locale)
        .month(month)
        .format(format === "S" ? "MMM" : "MMMM");
}

export function getDate(
    date: Date,
    tz: string,
    daysOffset?: number,
    hoursOffset?: number,
    minutesOffset?: number
): string {
    const d = addDurations(date, tz, [
        { offset: daysOffset ?? 0, unitOfTime: UnitOfTime.DAY },
        { offset: hoursOffset ?? 0, unitOfTime: UnitOfTime.HOUR },
        { offset: minutesOffset ?? 0, unitOfTime: UnitOfTime.MINUTE },
    ]);

    return moment(d)
        .tz(tz)
        .format()
        .substring(0, 10);
}

export function getTime(
    date: Date,
    tz: string,
    daysOffset?: number,
    hoursOffset?: number,
    minutesOffset?: number
): string {
    const d = addDurations(date, tz, [
        { offset: daysOffset ?? 0, unitOfTime: UnitOfTime.DAY },
        { offset: hoursOffset ?? 0, unitOfTime: UnitOfTime.HOUR },
        { offset: minutesOffset ?? 0, unitOfTime: UnitOfTime.MINUTE },
    ]);

    return moment(d)
        .tz(tz)
        .format()
        .substring(11, 16);
}

export function toDateObject(tz: string, date: string, daysOffset?: number, time?: string): Date {
    const m = time ? moment.tz(`${date}T${time}`, tz) : moment.tz(`${date}T00:00`, tz);
    return m.add(daysOffset ? daysOffset : 0, "d").toDate();
}

export function getCurrentMonth(now: Date, tz: string): string {
    return moment(now)
        .tz(tz)
        .format()
        .substring(0, 7);
}

export function formatInstant(date: Date | string, tz: string, locale: string, format: DateTimeOutputFormat): string {
    return moment(date)
        .tz(tz)
        .locale(locale)
        .format(format === "S" ? "LLL" : "LLLL");
}

export function formatLocalDate(localDate: string, locale: string, format: DateTimeOutputFormat): string {
    return moment(localDate)
        .locale(locale)
        .format(format === "S" ? "L" : "LL");
}

export function formatLocalTime(localTime: string, locale: string, format: DateTimeOutputFormat): string {
    return moment(`2000-01-01T${localTime}`)
        .locale(locale)
        .format(format === "S" ? "LT" : "LTS");
}

export function formatTime(date: Date | string, tz: string, locale: string, includeSeconds = true): string {
    return moment(date)
        .tz(tz)
        .locale(locale)
        .format(includeSeconds ? "LTS" : "LT");
}

export function addHours(date: Date, hours: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
}

export function truncateToHours(date: Date): Date {
    const newDate = new Date(date.getTime());
    newDate.setMinutes(0, 0, 0);
    return newDate;
}

export function truncateToDate(date: Date): Date {
    const newDate = new Date(date.getTime());
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

export function getAge(now: Date, localDate: string, tz: string): number {
    return moment(now)
        .tz(tz)
        .diff(localDate, "years");
}

export function fromNow(now: Date, localDate: string, tz: string, locale: string): string | null {
    const currentDate = getDate(now, tz);

    if (currentDate === localDate) {
        return null;
    }

    return moment(localDate)
        .tz(tz)
        .locale(locale)
        .from(currentDate);
}

export function formatHumanizedDuration(seconds: number, locale: string, event?: boolean): string {
    return moment
        .duration(seconds, "seconds")
        .locale(locale)
        .humanize(!!event);
}

export function formatDuration(seconds: number, signed: boolean, format?: DateTimeOutputFormat): string {
    const duration = moment.duration(Math.trunc(seconds), "seconds");

    const isNegative = signed && duration.asSeconds() < 0;

    return (
        (isNegative ? "-" : "") +
        [duration.asHours(), duration.minutes(), duration.seconds()]
            .map((value) => Math.trunc(Math.abs(value)))
            .filter((value, index) => value || format === "L" || index > 0) // hide hour if zero and short format
            .map((value, index) => value.toString().padStart(index === 0 ? 1 : 2, "0"))
            .join(":")
    );
}

export function formatDifference(from: Date, to: Date, sign: boolean, locale?: string): string {
    const duration = Math.trunc((to.getTime() - from.getTime()) / 1000);

    return locale ? formatHumanizedDuration(duration, locale, sign) : formatDuration(duration, sign);
}

export function getDateByDayOfWeek(week: Week, dayOfWeek: number): string | null {
    for (let i = 0; i < 7; i++) {
        const day = moment(week.begin).add(i, "d");

        if (day.weekday() === dayOfWeek) {
            return day.format().substring(0, 10);
        }
    }

    return null;
}

export function getWeek(date: Date, weeksOffsetFromCurrentWeek: number, tz: string, locale: string): Week {
    const beginOfWeek = moment(date)
        .tz(tz)
        .locale(locale)
        .add(weeksOffsetFromCurrentWeek * 7, "d")
        .startOf("week");

    return {
        weekNumber: beginOfWeek.week(),
        monday: beginOfWeek
            .clone()
            .day(1)
            .format()
            .substring(0, 10),
        begin: beginOfWeek.format().substring(0, 10),
        end: beginOfWeek
            .clone()
            .add(6, "d")
            .format()
            .substring(0, 10),
    };
}

export interface Week {
    readonly weekNumber: number;
    readonly monday: string;
    readonly begin: string;
    readonly end: string;
}

interface DayOfWeek {
    readonly text: string;
    readonly value: string;
}

export function getDaysOfWeek(localeForFirstDay: string, localeForDayNames: string): DayOfWeek[] {
    const result: DayOfWeek[] = [];
    const firstDayOfWeek = getFirstDayOfWeek(localeForFirstDay);
    for (let i = 0; i < 7; i++) {
        const n = (firstDayOfWeek + i) % 7;
        result.push({ value: n.toString(), text: getDayOfWeekName(n, localeForDayNames) });
    }
    return result;
}

export function getCurrentUtcYear(now: Date): number {
    return moment(now).year();
}

export function getWeekDayOfDate(date: string): number {
    return moment(date).weekday();
}

export function parseTime(time: string): string | null {
    let parsed = moment(time, "hma");
    if (!parsed.isValid()) {
        parsed = moment(time, "Hm");
    }
    if (!parsed.isValid()) {
        return null;
    }
    return parsed.format("HH:mm");
}

export function getDayOfWeekByDayOfWeekName(
    dayOfWeekName: string,
    localeForFirstDay: string,
    localeForDayNames: string
): number | null {
    return (
        getDaysOfWeek(localeForFirstDay, localeForDayNames)
            .filter((v) => v.text === dayOfWeekName)
            .map((v) => parseInt(v.value))
            .pop() ?? null
    );
}
