
import { getCreator } from "./noteUtils";
import { FeedbackType, Note, notesApi, ReportFeedbackForm } from "@/api/notes";
import EnhancedText from "@/app/components/EnhancedText.vue";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { e164, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        canManageIssue: {
            type: Boolean,
            required: true,
        },
        note: {
            type: Object as () => Note,
            required: true,
        },
    },

    data() {
        return {
            FeedbackType,
            e164,
            notEmpty,
            feedbackFormVisible: false,
            feedbackType: null as FeedbackType | null,
            feedbackComment: "",
            feedbackPhone: "",
            savingFeedback: false,
        };
    },

    computed: {
        canReportFeedback(): boolean {
            if (userSession.isCtUser() || !this.note.creatorCtUserId || !!this.note.feedbackCreated) {
                return false;
            }

            return this.canManageIssue;
        },

        creator(): string {
            return getCreator(this.note);
        },

        creationTimestamp() {
            return formatInstant(this.note.created, userSession.timeZone, userSession.locale, "S");
        },

        feedbackCreationTimestamp() {
            if (!this.note.feedbackCreated) {
                return null;
            }

            return formatInstant(this.note.feedbackCreated, userSession.timeZone, userSession.locale, "S");
        },

        fullName(): string {
            return userSession.fullName!;
        },

        email(): string {
            return userSession.username!;
        },

        defaultCountry(): string {
            return configStore.configuration.defaultCountry;
        },
    },

    methods: {
        getFullNameById(id: string) {
            return getFullName(usersStore.getUserById(id)) || (this.$t("Unbekannt") as string);
        },

        showFeedbackForm() {
            if (!this.feedbackFormVisible) {
                this.feedbackType = null;
                this.feedbackComment = "";
                this.feedbackPhone = userSession.profile?.terminalPhoneNumber || "";
                this.feedbackFormVisible = true;
            }
        },

        hideFeedbackForm() {
            this.feedbackFormVisible = false;
        },

        async saveFeedback() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.savingFeedback = true;

            const reportFeedbackForm: ReportFeedbackForm = {
                resourceId: this.note.id,
                feedbackType: this.feedbackType!,
                comment: this.feedbackComment,
                reporterPhone: this.feedbackPhone,
            };

            try {
                await notesApi.reportFeedback(reportFeedbackForm);
            } finally {
                this.feedbackFormVisible = false;
                this.savingFeedback = false;
            }
        },
    },

    components: {
        EnhancedText,
        UserLink,
        TimeSpan,
        EnumField,
        PhoneNumberField,
    },
});
