import { isOpportunityVisibilityNotification, notificationEventSource } from "@/api/notifications";
import { opportunitiesApi, UserOpportunityStatus } from "@/api/opportunities";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class OpportunityStatusStore {
    private refreshLimiter = new ActionLimiter(true);
    private userOpportunityStatus_: UserOpportunityStatus = {
        openOpportunities: 0,
        openOpportunitiesWithUnreadActivities: 0,
        assignedOpportunities: 0,
        assignedOpportunitiesWithUnreadActivities: 0,
        postponedOpportunities: 0,
        postponedOpportunitiesWithUnreadActivities: 0,
    };

    get userOpportunityStatus() {
        return this.userOpportunityStatus_;
    }

    get activeUnreadOpportunitiesCount() {
        return (
            this.userOpportunityStatus_.openOpportunitiesWithUnreadActivities +
            this.userOpportunityStatus_.assignedOpportunitiesWithUnreadActivities
        );
    }

    get activeOpportunitiesCount() {
        return this.userOpportunityStatus_.openOpportunities + this.userOpportunityStatus_.assignedOpportunities;
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.userOpportunityStatus_ = await opportunitiesApi.getMyOpportunityStatus();
        });
    }
}

export const opportunityStatusStore = new OpportunityStatusStore();

notificationEventSource.addDataHandler((n) => {
    if (isOpportunityVisibilityNotification(n)) {
        opportunityStatusStore.refresh();
    }
});
