import { YearMonthDay } from "./types";

export function getYearMonthDay(yearMonthDay: YearMonthDay): string | null {
    if (yearMonthDay.year === null) {
        return null;
    }

    let date = yearMonthDay.year.toString();

    if (yearMonthDay.month !== null) {
        date += "-" + yearMonthDay.month.toString().padStart(2, "0");

        if (yearMonthDay.day !== null) {
            date += "-" + yearMonthDay.day.toString().padStart(2, "0");
        }
    }

    return date;
}

export function toYearMonthDayObject(date: string | null): YearMonthDay {
    const parts = (date || "").split("-").filter((p) => p !== "");

    return {
        year: parts.length >= 1 ? Number.parseInt(parts[0]) : null,
        month: parts.length >= 2 ? Number.parseInt(parts[1]) : null,
        day: parts.length >= 3 ? Number.parseInt(parts[2]) : null,
    };
}
