import axios from "axios";

export enum CaseLastActivityType {
    INCOMING_EMAIL = "INCOMING_EMAIL",
    OUTGOING_EMAIL = "OUTGOING_EMAIL",
    INCOMING_CALL_ACCEPTED = "INCOMING_CALL_ACCEPTED",
    INCOMING_CALL_UNANSWERED = "INCOMING_CALL_UNANSWERED",
    OUTGOING_CALL_ACCEPTED = "OUTGOING_CALL_ACCEPTED",
    OUTGOING_CALL_UNANSWERED = "OUTGOING_CALL_UNANSWERED",
    NOTE_BY_CT_USER = "NOTE_BY_CT_USER",
    NOTE_BY_PARTITION_USER = "NOTE_BY_PARTITION_USER",
    INCOMING_SMS = "INCOMING_SMS",
    OUTGOING_SMS = "OUTGOING_SMS",
    INCOMING_WHATSAPP = "INCOMING_WHATSAPP",
    OUTGOING_WHATSAPP = "OUTGOING_WHATSAPP",
}

export interface CasePrioritySettingsEntry {
    readonly lastActivityType: CaseLastActivityType;
    readonly mediumEscalation: number | null;
    readonly highEscalation: number | null;
}

export interface CasePrioritySettings {
    readonly escalationHoursId: string | null;
    readonly settingEntries: CasePrioritySettingsEntry[];
}

interface CasePrioritySettingsApi {
    getSettings(dealerId: string): Promise<CasePrioritySettings>;
    editSettings(dealerId: string, settings: CasePrioritySettings): Promise<void>;
}

export const casePrioritySettingsApi: CasePrioritySettingsApi = {
    async getSettings(dealerId) {
        return (await axios.get(`/api/case-priority-settings/${dealerId}`)).data;
    },

    async editSettings(dealerId, settings) {
        await axios.put(`/api/case-priority-settings/${dealerId}`, settings);
    },
};
