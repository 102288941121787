import { Contact } from "./contacts";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum IssueType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
    UNKNOWN = "UNKNOWN",
}

export enum OutgoingSmsSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
}

export enum SmsStatus {
    SENT = "SENT",
    DELIVERED = "DELIVERED",
    REJECTED = "REJECTED",
    EXPIRED = "EXPIRED",
    FAILED = "FAILED",
    UNKNOWN = "UNKNOWN",
}

export interface OutgoingSms {
    readonly id: string;
    readonly created: Date;
    readonly initiatorUserId: string | null;
    readonly initiatorCtUserId: string | null;
    readonly contactId: string | null;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly fromNumber: string | null;
    readonly fromAddress: string | null;
    readonly toNumber: string;
    readonly messageBody: string;
    readonly smsStatus: SmsStatus;
    readonly updated: Date;
}

export interface OutgoingSmsAndContact {
    readonly outgoingSms: OutgoingSms;
    readonly contact: Contact | null;
}

export interface OutgoingSmsForm {
    fromNumber: string | null;
    fromAddress: string | null;
    toNumber: string;
    messageBody: string;
}

export interface OutgoingSmsSearchRequest {
    readonly initiatorUserIds: string[];
    readonly issueTypes: IssueType[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly sortBy: OutgoingSmsSearchOrder;
}

export interface OutgoingSmsSearchResults {
    readonly results: OutgoingSmsAndContact[];
    readonly searchId: number;
    readonly totalSize: number;
}

interface OutgoingSmsApi {
    getMySms(start: number, size: number, searchId: number): Promise<OutgoingSmsSearchResults>;
    getOutgoingSmsByCase(caseId: string): Promise<OutgoingSms[]>;
    getOutgoingSmsByOpportunity(opportunityId: string): Promise<OutgoingSms[]>;
    search(
        start: number,
        size: number,
        searchRequest: OutgoingSmsSearchRequest,
        searchId: number
    ): Promise<OutgoingSmsSearchResults>;
    sendSms(contactId: string | null, outgoingSmsForm: OutgoingSmsForm): Promise<void>;
}

export const outgoingSmsApi: OutgoingSmsApi = {
    async getMySms(start, size, searchId) {
        return cloneObject(
            (
                await axios.get("/api/outgoing-sms/_my-sms", {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },

    async getOutgoingSmsByCase(caseId) {
        return cloneObject((await axios.get(`/api/outgoing-sms/cases/${caseId}`)).data);
    },

    async getOutgoingSmsByOpportunity(opportunityId) {
        return cloneObject((await axios.get(`/api/outgoing-sms/opportunities/${opportunityId}`)).data);
    },

    async sendSms(contactId, outgoingSmsForm) {
        await axios.post("/api/outgoing-sms/_send-sms", outgoingSmsForm, {
            params: {
                contactId,
            },
        });
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/outgoing-sms/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },
};
