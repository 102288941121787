import { ContactAndVehicleId } from "@/app/contactUtils";
import { EMPTY_SEARCH_REQUEST } from "@/app/pages/contacts/contactSearchUtils";
import { OutgoingSmsFormCardState } from "@/app/pages/outgoingsms/OutgoingSmsFormCard";
import { cloneObject } from "@/util/cloneUtils";
import { reactive } from "@/util/reactive";

@reactive
export class OutgoingSmsAssistantState {
    public contactAndVehicleId: ContactAndVehicleId | null = null;
    public contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
    public outgoingSmsFormCardState = new OutgoingSmsFormCardState();

    reset() {
        this.contactAndVehicleId = null;
        this.contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
        this.outgoingSmsFormCardState.reset();
    }
}

export const outgoingSmsAssistantState = new OutgoingSmsAssistantState();
