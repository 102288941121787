
import { dealerOptions } from "@/app/dealerUtils";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        clearable: {
            type: Boolean,
            default: false,
        },
        selectableDealerIds: {
            type: Array as () => string[],
            required: false,
        },
        value: {
            type: Array as () => string[],
            required: true,
        },
    },

    computed: {
        dealerId(): string | null {
            return userSession.dealerId;
        },

        dealerOptions(): SelectOptions {
            return dealerOptions(
                () => true,
                (d) => !this.realSelectableDealerIds.includes(d.id)
            );
        },

        defaultDealerIds(): string[] {
            if (!this.dealerId && this.clearable) {
                return [];
            }

            if (!!this.dealerId && this.realSelectableDealerIds.includes(this.dealerId)) {
                return [this.dealerId];
            }

            if (!!userSession.mainDealerId && this.realSelectableDealerIds.includes(userSession.mainDealerId)) {
                return [userSession.mainDealerId];
            }

            if (!this.clearable && this.realSelectableDealerIds.length) {
                return this.realSelectableDealerIds.slice(0, 1);
            }

            return [];
        },

        realSelectableDealerIds(): string[] {
            if (this.selectableDealerIds !== undefined) {
                return this.selectableDealerIds;
            }

            return dealersStore.dealers.map((d) => d.id);
        },

        validValueDealerIdsOrDefaultDealerIdsIfEmpty(): string[] {
            const validDealerIds: string[] = this.getValidDealerIds(this.value);

            if (validDealerIds.length) {
                return validDealerIds;
            }

            return this.defaultDealerIds;
        },
    },

    methods: {
        getValidDealerIds(dealerIds: string[]): string[] {
            return [...new Set(dealerIds.filter((dealerId) => this.realSelectableDealerIds.includes(dealerId)))];
        },

        input(dealerIds: string[]) {
            let validDealerIds = this.getValidDealerIds(dealerIds);

            if (!this.clearable && validDealerIds.length === 0) {
                validDealerIds = [...this.validValueDealerIdsOrDefaultDealerIdsIfEmpty];
            }

            this.$emit("input", validDealerIds);
        },
    },

    watch: {
        dealerId() {
            if (!this.dealerId && !this.clearable) {
                this.$emit("input", [...this.defaultDealerIds]);
                return;
            }

            if (this.dealerId && !this.realSelectableDealerIds.includes(this.dealerId)) {
                return;
            }

            this.$emit("input", this.dealerId ? [this.dealerId] : []);
        },

        value: {
            immediate: true,
            handler(newValue: string[], oldValue: string[]) {
                if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
                    return;
                }

                this.input(this.value);
            },
        },
    },
});
