
import {
    IncomingPhoneNumber,
    incomingPhoneNumbersApi,
    InternalIncomingPhoneNumberForm,
} from "@/api/incomingPhoneNumbers";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { e164, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        incomingPhoneNumber: {
            type: Object as () => IncomingPhoneNumber,
            required: true,
        },
        editMode: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            incomingPhoneNumberId: this.incomingPhoneNumber.id,
            incomingPhoneNumberObj: cloneObject(this.incomingPhoneNumber),
            internalIncomingPhoneNumberForm: {
                aliasNumber: this.incomingPhoneNumber.aliasNumber || "",
                fallbackNumber: this.incomingPhoneNumber.fallbackNumber || "",
                fallbackMessage:
                    this.incomingPhoneNumber.fallbackMessage ||
                    this.$t(
                        "Ihre Nachricht konnte aufgrund einer technischen Störung nicht verarbeitet werden. Bitte versuchen Sie es später nochmal."
                    ),
            } as InternalIncomingPhoneNumberForm,
            working: false,
            notEmpty,
            e164,
        };
    },

    computed: {
        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },
    },

    methods: {
        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.working = true;
            try {
                if (this.editMode) {
                    await incomingPhoneNumbersApi.editIncomingPhoneNumber(
                        this.incomingPhoneNumberObj.id,
                        this.internalIncomingPhoneNumberForm
                    );
                } else {
                    await incomingPhoneNumbersApi.installIncomingPhoneNumber({
                        ...this.incomingPhoneNumberObj,
                        ...this.internalIncomingPhoneNumberForm,
                    });
                }

                this.$emit("saved");
            } finally {
                this.working = false;
            }
        },

        close() {
            this.$emit("close");
        },
    },

    components: { PhoneNumberField },
});
