
import GlobalDialog from "./GlobalDialog.vue";
import Snackbar from "./Snackbar.vue";
import { showInfo } from "@/app/messageUtil";
import { configStore } from "@/store/config";
import { titleStore } from "@/store/title";
import { userSession } from "@/store/userSession";
import Vue from "vue";
import VueFinalModal from "vue-final-modal";

export default Vue.use(VueFinalModal).extend({
    data() {
        return {
            userSession,
            titleStore,
        };
    },

    computed: {
        routerTitle() {
            const title = this.$route.matched
                .map((r) => r.meta.title as string | undefined)
                .reduceRight((p, c) => p || c, "");
            return title ? (this.$t(title) as string) : "";
        },

        outdated() {
            return configStore.outdated;
        },
    },

    watch: {
        "userSession.locale"(locale: string) {
            this.$i18n.locale = this.$vuetify.lang.current = locale;
        },

        "userSession.darkmode"(value: boolean) {
            this.$vuetify.theme.dark = value;
        },

        "titleStore.title"(title: string) {
            window.document.title = title;
        },

        routerTitle() {
            titleStore.title = this.routerTitle;
        },

        outdated() {
            this.showUpdateInfo();
        },
    },

    methods: {
        showUpdateInfo() {
            showInfo(this.$t("Die Anwendung wurde aktualisiert.") as string);
        },
    },

    beforeMount() {
        this.$i18n.locale = this.$vuetify.lang.current = userSession.locale!;
        this.$vuetify.theme.dark = userSession.darkmode;
        titleStore.title = this.routerTitle;
    },

    mounted() {
        if (this.outdated) {
            this.showUpdateInfo();
        }
    },

    components: {
        GlobalDialog,
        Snackbar,
    },
});
