import CitnowSalesIcon from "./icons/CitnowSalesIcon.vue";
import Vue from "vue";
import Vuetify from "vuetify";
import de from "vuetify/src/locale/de";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi",
        values: {
            "citnow-sales": {
                component: CitnowSalesIcon,
            },
        },
    },
    theme: {
        themes: {
            dark: {
                primary: "#FFFFFF",
                accent: "#868686",
                secondary: "#505050",
                anchor: "#FFFFFF",
            },
            light: {
                primary: "#214A8B",
                accent: "#457CD3",
                secondary: "#2C62BA",
                anchor: "#000000",
            },
        },
    },
    lang: {
        locales: { de, "de-CH": de },
    },
});
