import { carryDispoAssistantState } from "./pages/carrydispo/assistant/carryDispoAssistant";
import { caseAssistantState } from "./pages/cases/assistant/caseAssistant";
import { emergencyIncidentAssistantState } from "./pages/emergencyincidents/assistant/emergencyIncidentAssistant";
import { opportunityAssistantState } from "./pages/opportunities/assistant/opportunityAssistant";
import { NavigationGuardNext, Route } from "vue-router";

export function processQueryParameters(to: Route, next: NavigationGuardNext) {
    const number = to.query.number;

    if (!number || typeof number !== "string") {
        next();
        return;
    }

    carryDispoAssistantState.contactSearchRequest = {
        ...carryDispoAssistantState.contactSearchRequest,
        number,
    };
    caseAssistantState.contactSearchRequest = {
        ...caseAssistantState.contactSearchRequest,
        number,
    };
    emergencyIncidentAssistantState.contactSearchRequest = {
        ...emergencyIncidentAssistantState.contactSearchRequest,
        number,
    };
    opportunityAssistantState.contactSearchRequest = {
        ...opportunityAssistantState.contactSearchRequest,
        number,
    };

    const { path, query, hash, params } = to;
    next({ path, query: { ...query, number: undefined }, hash, params });
}
