import axios from "axios";

export enum PartnerType {
    EMERGENCY_MECHANIC = "EMERGENCY_MECHANIC",
    ASSISTANCE = "ASSISTANCE",
    TOWING_SERVICE = "TOWING_SERVICE",
    CAR_RENTAL = "CAR_RENTAL",
    MOTOR_VEHICLE_ASSESSOR = "MOTOR_VEHICLE_ASSESSOR",
    EMERGENCY_CONTACT = "EMERGENCY_CONTACT",
    OTHERS = "OTHERS",
}

export enum NumberType {
    LANDLINE = "LANDLINE",
    MOBILE = "MOBILE",
    FAX = "FAX",
}

export interface PartnerNumber {
    type: NumberType;
    number: string;
    label: string | null;
}

export interface PartnerForm {
    readonly type: PartnerType;
    readonly name: string;
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
    readonly numbers: PartnerNumber[];
    readonly emailAddress: string | null;
    readonly notes: string | null;
}

export interface Partner extends PartnerForm {
    readonly id: string;
    readonly dealerId: string;
}

interface PartnersApi {
    getByDealer(dealerId: string): Promise<Partner[]>;
    updateOrder(dealerId: string, ids: string[]): Promise<void>;
    add(dealerId: string, form: PartnerForm): Promise<void>;
    edit(dealerId: string, id: string, form: PartnerForm): Promise<void>;
    delete(dealerId: string, id: string): Promise<void>;
}

export const partnersApi: PartnersApi = {
    async getByDealer(dealerId) {
        return (await axios.get(`/api/partners/${dealerId}`)).data;
    },

    async updateOrder(dealerId, ids) {
        await axios.post(`/api/partners/${dealerId}/_update-order`, ids);
    },

    async add(dealerId, form) {
        await axios.post(`/api/partners/${dealerId}`, form);
    },

    async edit(dealerId, id, form) {
        await axios.put(`/api/partners/${dealerId}/${id}`, form);
    },

    async delete(dealerId, id) {
        await axios.delete(`/api/partners/${dealerId}/${id}`);
    },
};
