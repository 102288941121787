import {
    ExternOrderForm,
    OrderType,
    PropulsionType,
    RemarkForm,
    RemarkType,
    TargetForm,
    TargetType,
    TransmissionType,
    VehicleType,
} from "@/api/carryDispo";
import { ContactSearchRequest } from "@/api/contactSearch";
import { ContactData, ContactVehicleData, MileageUnit } from "@/api/contacts";
import { ContactAndVehicleId, renderContactCaption } from "@/app/contactUtils";
import { getCountryCaption, getStateCaption } from "@/app/countryStateUtils";
import { EMPTY_SEARCH_REQUEST } from "@/app/pages/contacts/contactSearchUtils";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";

export interface CarryDispoAssistantState {
    contactAndVehicleId: ContactAndVehicleId | null;
    contactSearchRequest: ContactSearchRequest;
    contactData: ContactData | null;
    contactVehicleData: ContactVehicleData | null;
    contractorCustomerName: string | null;
    contractorCustomerNumber: string | null;
    contractorCustomerPhone: string | null;
    orderType: OrderType;
    requireTargetDate: boolean;
    targetTimeZone: string | null;
    targetDate: string | null;
    targetTime: string | null;
    requireEndDate: boolean;
    endDateArbitrary: boolean;
    endTimeZone: string | null;
    endDate: string | null;
    endTime: string | null;
    diagnosisRemark: string | null;
    shortReportRemark: string | null;
    servicePointName: string | null;
    servicePointDescription: string | null;
    servicePointStreet: string | null;
    servicePointHouseNumber: string | null;
    servicePointZip: string | null;
    servicePointCity: string | null;
    servicePointState: string | null;
    servicePointCountry: string | null;
    servicePointAdditionalInfo: string | null;
    deliveryPointName: string | null;
    deliveryPointDescription: string | null;
    deliveryPointStreet: string | null;
    deliveryPointHouseNumber: string | null;
    deliveryPointZip: string | null;
    deliveryPointCity: string | null;
    deliveryPointState: string | null;
    deliveryPointCountry: string | null;
    deliveryPointAdditionalInfo: string | null;
    vehicleBrand: string | null;
    vehicleChassisNumber: string | null;
    vehicleColor: string | null;
    vehicleComment: string | null;
    vehicleDescription: string | null;
    vehicleDriver: string | null;
    vehicleLicensePlate: string | null;
    vehicleModel: string | null;
    vehicleOdometerKilometers: number | null;
    vehiclePropulsionType: PropulsionType;
    vehicleRegistrationDate: string | null;
    vehicleTransmissionType: TransmissionType;
    vehiclePassengers: number | null;
    vehicleType: VehicleType;
    readonly hasDeliveryPointData: boolean;
    readonly hasServicePointData: boolean;
    readonly externOrderForm: ExternOrderForm;

    reset(): void;
}

@reactive
class CarryDispoAssistantStateImpl implements CarryDispoAssistantState {
    contactAndVehicleId: ContactAndVehicleId | null = null;
    contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
    private contactData_: ContactData | null = null;
    private contactVehicleData_: ContactVehicleData | null = null;

    // extern order
    contractorCustomerName: string | null = null;
    contractorCustomerNumber: string | null = null;
    contractorCustomerPhone: string | null = null;
    orderType: OrderType = OrderType.UNFALL;
    requireTargetDate = false;
    targetTimeZone: string | null = null;
    targetDate: string | null = null;
    targetTime: string | null = null;
    requireEndDate = false;
    endDateArbitrary = true;
    endTimeZone: string | null = null;
    endDate: string | null = null;
    endTime: string | null = null;
    diagnosisRemark: string | null = null;
    shortReportRemark: string | null = null;

    // SERVICE_POINT
    servicePointName: string | null = null;
    servicePointDescription: string | null = null;
    servicePointStreet: string | null = null;
    servicePointHouseNumber: string | null = null;
    servicePointZip: string | null = null;
    servicePointCity: string | null = null;
    servicePointState: string | null = null;
    servicePointCountry: string | null = null;
    servicePointAdditionalInfo: string | null = null;

    // DELIVERY_POINT
    deliveryPointName: string | null = null;
    deliveryPointDescription: string | null = null;
    deliveryPointStreet: string | null = null;
    deliveryPointHouseNumber: string | null = null;
    deliveryPointZip: string | null = null;
    deliveryPointCity: string | null = null;
    deliveryPointState: string | null = null;
    deliveryPointCountry: string | null = null;
    deliveryPointAdditionalInfo: string | null = null;

    // vehicle
    vehicleBrand: string | null = null;
    vehicleChassisNumber: string | null = null;
    vehicleColor: string | null = null;
    vehicleComment: string | null = null;
    vehicleDescription: string | null = null;
    vehicleDriver: string | null = null;
    vehicleLicensePlate: string | null = null;
    vehicleModel: string | null = null;
    vehicleOdometerKilometers: number | null = null;
    vehiclePropulsionType: PropulsionType = PropulsionType.UNKNOWN;
    vehicleRegistrationDate: string | null = null;
    vehicleTransmissionType: TransmissionType = TransmissionType.UNKNOWN;
    vehiclePassengers: number | null = null;
    vehicleType: VehicleType = VehicleType.PKW;

    get contactData() {
        return this.contactData_;
    }

    set contactData(contactData: ContactData | null) {
        this.contactData_ = contactData;

        if (!contactData) {
            return;
        }

        this.contractorCustomerName = renderContactCaption(contactData);
        this.servicePointName = null;
        this.servicePointDescription = contactData.address1;
        this.servicePointStreet = contactData.address2;
        this.servicePointHouseNumber = null;
        this.servicePointZip = contactData.zip;
        this.servicePointCity = contactData.city;
        this.servicePointState = contactData.state;
        this.servicePointCountry = contactData.country;
        this.servicePointAdditionalInfo = null;
    }

    get contactVehicleData() {
        return this.contactVehicleData_;
    }

    set contactVehicleData(contactVehicleData: ContactVehicleData | null) {
        this.contactVehicleData_ = contactVehicleData;

        if (!contactVehicleData) {
            return;
        }

        const record = [...contactVehicleData.records]
            .sort((a, b) => {
                const aTime = toDateObject(userSession.timeZone, a.date).getTime();
                const bTime = toDateObject(userSession.timeZone, b.date).getTime();

                return aTime - bTime;
            })
            .pop();

        this.vehicleBrand = contactVehicleData.make || null;
        this.vehicleChassisNumber = contactVehicleData.vin || null;
        this.vehicleDescription = contactVehicleData.modelDescription || null;
        this.vehicleLicensePlate = contactVehicleData.licensePlate || null;
        this.vehicleModel = contactVehicleData.model || null;
        this.vehicleOdometerKilometers = record && record.mileageUnit === MileageUnit.KM ? record.mileage : null;
        this.vehicleRegistrationDate = contactVehicleData.firstRegistration || null;
    }

    get hasDeliveryPointData(): boolean {
        return (
            [
                this.deliveryPointName,
                this.deliveryPointDescription,
                this.deliveryPointStreet,
                this.deliveryPointHouseNumber,
                this.deliveryPointZip,
                this.deliveryPointCity,
                this.deliveryPointState,
                this.deliveryPointCountry,
                this.deliveryPointAdditionalInfo,
            ].filter((v) => !!v).length > 0
        );
    }

    get hasServicePointData(): boolean {
        return (
            [
                this.servicePointName,
                this.servicePointDescription,
                this.servicePointStreet,
                this.servicePointHouseNumber,
                this.servicePointZip,
                this.servicePointCity,
                this.servicePointState,
                this.servicePointCountry,
                this.servicePointAdditionalInfo,
            ].filter((v) => !!v).length > 0
        );
    }

    get externOrderForm(): ExternOrderForm {
        const remarks: RemarkForm[] = [];
        const targets: TargetForm[] = [];

        if (!!this.diagnosisRemark) {
            remarks.push({
                type: RemarkType.DIAGNOSIS,
                text: this.diagnosisRemark,
            });
        }

        if (!!this.shortReportRemark) {
            remarks.push({
                type: RemarkType.SHORTREPORT,
                text: this.shortReportRemark,
            });
        }

        if (this.hasServicePointData) {
            const serviceState = trimAndReturnNullIfEmpty(this.servicePointState);
            const serviceCountry = trimAndReturnNullIfEmpty(this.servicePointCountry);

            targets.push({
                type: TargetType.SERVICE_POINT,
                name: this.servicePointName,
                description: this.servicePointDescription,
                street: this.servicePointStreet,
                houseNumber: this.servicePointHouseNumber,
                zip: this.servicePointZip,
                city: this.servicePointCity,
                region:
                    serviceCountry !== null && serviceState !== null
                        ? getStateCaption(serviceCountry, serviceState)
                        : null,
                country: serviceCountry !== null ? getCountryCaption(serviceCountry) : null,
                additionalInfo: this.servicePointAdditionalInfo,
            });
        }

        if (this.hasDeliveryPointData) {
            const deliveryState = trimAndReturnNullIfEmpty(this.deliveryPointState);
            const deliveryCountry = trimAndReturnNullIfEmpty(this.deliveryPointCountry);

            targets.push({
                type: TargetType.DELIVERY_POINT,
                name: this.deliveryPointName,
                description: this.deliveryPointDescription,
                street: this.deliveryPointStreet,
                houseNumber: this.deliveryPointHouseNumber,
                zip: this.deliveryPointZip,
                city: this.deliveryPointCity,
                region:
                    deliveryCountry !== null && deliveryState !== null
                        ? getStateCaption(deliveryCountry, deliveryState)
                        : null,
                country: deliveryCountry !== null ? getCountryCaption(deliveryCountry) : null,
                additionalInfo: this.deliveryPointAdditionalInfo,
            });
        }

        return {
            contractorCustomerName: this.contractorCustomerName!,
            contractorCustomerNumber: this.contractorCustomerNumber,
            contractorCustomerPhone: this.contractorCustomerPhone!,
            branch: null,
            orderType: this.orderType!,
            targetDate: this.requireTargetDate
                ? toDateObject(this.targetTimeZone!, this.targetDate!, 0, this.targetTime!)
                : null,
            endDate: this.requireEndDate ? toDateObject(this.endTimeZone!, this.endDate!, 0, this.endTime!) : null,
            vehicle: {
                brand: this.vehicleBrand,
                chassisNumber: this.vehicleChassisNumber,
                color: this.vehicleColor,
                comment: this.vehicleComment,
                description: this.vehicleDescription,
                driver: this.vehicleDriver,
                licensePlate: this.vehicleLicensePlate,
                model: this.vehicleModel,
                odometerKilometers: this.vehicleOdometerKilometers,
                propulsionType: this.vehiclePropulsionType,
                registrationDate: this.vehicleRegistrationDate,
                transmissionType: this.vehicleTransmissionType,
                passengers: this.vehiclePassengers,
                vehicleType: this.vehicleType,
            }!,
            targets,
            remarks,
            clearance: true,
            source: configStore.configuration.appName,
        };
    }

    reset() {
        this.contactAndVehicleId = null;
        this.contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
        this.contactData = null;
        this.contactVehicleData = null;

        this.contractorCustomerName = null;
        this.contractorCustomerNumber = null;
        this.contractorCustomerPhone = null;
        this.orderType = OrderType.UNFALL;
        this.requireTargetDate = false;
        this.targetTimeZone = null;
        this.targetDate = null;
        this.targetTime = null;
        this.requireEndDate = false;
        this.endTimeZone = null;
        this.endDate = null;
        this.endTime = null;
        this.diagnosisRemark = null;
        this.shortReportRemark = null;

        this.servicePointName = null;
        this.servicePointDescription = null;
        this.servicePointStreet = null;
        this.servicePointHouseNumber = null;
        this.servicePointZip = null;
        this.servicePointCity = null;
        this.servicePointState = null;
        this.servicePointCountry = null;
        this.servicePointAdditionalInfo = null;

        this.deliveryPointName = null;
        this.deliveryPointDescription = null;
        this.deliveryPointStreet = null;
        this.deliveryPointHouseNumber = null;
        this.deliveryPointZip = null;
        this.deliveryPointCity = null;
        this.deliveryPointState = null;
        this.deliveryPointCountry = null;
        this.deliveryPointAdditionalInfo = null;

        this.vehicleBrand = null;
        this.vehicleChassisNumber = null;
        this.vehicleColor = null;
        this.vehicleComment = null;
        this.vehicleDescription = null;
        this.vehicleDriver = null;
        this.vehicleLicensePlate = null;
        this.vehicleModel = null;
        this.vehicleOdometerKilometers = null;
        this.vehiclePropulsionType = PropulsionType.UNKNOWN;
        this.vehicleRegistrationDate = null;
        this.vehicleTransmissionType = TransmissionType.UNKNOWN;
        this.vehiclePassengers = null;
        this.vehicleType = VehicleType.PKW;
    }
}

export const carryDispoAssistantState = new CarryDispoAssistantStateImpl();
