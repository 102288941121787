
import { getSenderId, getStatusColor } from "./outgoingSmsUtils";
import { Contact } from "@/api/contacts";
import { isOutgoingSmsUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { OutgoingSms, OutgoingSmsAndContact } from "@/api/outgoingSms";
import { renderContactCaption } from "@/app/contactUtils";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        outgoingSmsAndContact: {
            type: Object as () => OutgoingSmsAndContact,
            required: true,
        },
    },

    data() {
        return {
            getSenderId,
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingSmsAndContactProp: this.outgoingSmsAndContact,
        };
    },

    computed: {
        contact(): Contact | null {
            return this.outgoingSmsAndContactProp.contact;
        },

        contactCaption(): string | null {
            if (!this.contact) {
                return null;
            }

            return renderContactCaption(this.contact.contactData);
        },

        isContactDeleted(): boolean {
            return !!this.contact?.deleted;
        },

        outgoingSms(): OutgoingSms {
            return this.outgoingSmsAndContactProp.outgoingSms;
        },

        statusClass(): string {
            const color = getStatusColor(this.outgoingSms.smsStatus);

            return color ? `${color}--text` : "error--text";
        },
    },

    methods: {
        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },
    },

    watch: {
        outgoingSmsAndContact() {
            this.outgoingSmsAndContactProp = this.outgoingSmsAndContact;
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isOutgoingSmsUpdatedNotification(n) && this.outgoingSms.id === n.id) {
                this.outgoingSmsAndContactProp = {
                    ...this.outgoingSmsAndContactProp,
                    outgoingSms: {
                        ...this.outgoingSmsAndContactProp.outgoingSms,
                        smsStatus: n.status,
                        updated: n.timestamp,
                    },
                };
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },
});
