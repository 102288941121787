
import OutgoingWhatsAppMedia from "./OutgoingWhatsAppMedia.vue";
import { isOutgoingWhatsAppUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import {
    OptionalIssueId,
    outgoingWhatsAppApi,
    OutgoingWhatsAppMessage,
    OutgoingWhatsAppMessageStatus,
} from "@/api/outgoingWhatsApp";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import UserLink from "@/app/pages/UserLink.vue";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
    props: {
        canViewContent: {
            type: Boolean,
            required: true,
        },
        outgoingMessage: {
            type: Object as () => OutgoingWhatsAppMessage,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            image: "",
            delivered: this.outgoingMessage.delivered || this.outgoingMessage.read,
            read: this.outgoingMessage.read,
            failed: this.outgoingMessage.failed,
            notificationHandler: null as ((n: Notification) => void) | null,
        };
    },

    computed: {
        creationTimestamp(): string {
            return formatInstant(this.outgoingMessage.created, userSession.timeZone, userSession.locale, "S");
        },

        issueId(): OptionalIssueId {
            if (this.outgoingMessage.caseId) {
                return { caseId: this.outgoingMessage.caseId };
            }
            if (this.outgoingMessage.opportunityId) {
                return { opportunityId: this.outgoingMessage.opportunityId };
            }
            return {};
        },

        statusColor(): string | undefined {
            if (this.failed) {
                return "error";
            }
            if (this.delivered) {
                return "success";
            }
            return undefined;
        },

        statusIcon(): string {
            if (this.failed) {
                return "mdi-close";
            }
            if (this.read) {
                return "mdi-check-all";
            }
            if (this.delivered) {
                return "mdi-check";
            }
            return "mdi-clock-outline";
        },

        statusTooltip(): TranslateResult {
            if (this.failed) {
                return this.$t("Zustellung der Nachricht ist fehlgeschlagen. ({0})", [
                    formatInstant(this.failed, userSession.timeZone, userSession.locale, "S"),
                ]);
            }
            if (this.delivered) {
                let tooltip = this.$t("Nachricht wurde zugestellt. ({0})", [
                    formatInstant(this.delivered, userSession.timeZone, userSession.locale, "S"),
                ]);
                if (this.read) {
                    tooltip += "\n";
                    tooltip += this.$t("Nachricht wurde gelesen. ({0})", [
                        formatInstant(this.read, userSession.timeZone, userSession.locale, "S"),
                    ]) as string;
                }
                return tooltip;
            }
            return this.$t("Nachricht befindet sich in Zustellung.");
        },
    },

    methods: {
        showMedia(download: boolean) {
            const mediaType = this.outgoingMessage.mediaType!;
            if (!download && mediaType.startsWith("image/")) {
                this.image = outgoingWhatsAppApi.generateMediaLink(this.outgoingMessage, this.issueId);
            } else {
                window.open(outgoingWhatsAppApi.generateMediaLink(this.outgoingMessage, this.issueId, download));
            }
        },
    },

    mounted() {
        if (!this.failed && !this.read) {
            this.notificationHandler = notificationEventSource.addDataHandler((n) => {
                if (isOutgoingWhatsAppUpdatedNotification(n)) {
                    if (n.id === this.outgoingMessage.id) {
                        if (n.status === OutgoingWhatsAppMessageStatus.FAILED) {
                            this.failed = n.timestamp;
                            notificationEventSource.removeDataHandler(this.notificationHandler!);
                            this.notificationHandler = null;
                        } else if (n.status === OutgoingWhatsAppMessageStatus.READ) {
                            this.read = n.timestamp;
                            if (!this.delivered) {
                                this.delivered = n.timestamp;
                            }
                            notificationEventSource.removeDataHandler(this.notificationHandler!);
                            this.notificationHandler = null;
                        } else if (n.status === OutgoingWhatsAppMessageStatus.DELIVERED) {
                            this.delivered = n.timestamp;
                        }
                    }
                }
            });
        }
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        OutgoingWhatsAppMedia,
        TimeSpan,
        UserLink,
        ImageCarousel,
    },
});
