import axios from "axios";

export interface CallerId {
    readonly phoneNumber: string;
    readonly internal: boolean;
}

export interface ValidationCode {
    readonly code: string;
}

interface CallerIdsApi {
    getCallerIds(): Promise<CallerId[]>;
    addCallerId(callerId: CallerId): Promise<ValidationCode | null>;
    editCallerId(callerId: CallerId): Promise<void>;
    deleteCallerId(phoneNumber: string): Promise<void>;
}

export const callerIdsApi: CallerIdsApi = {
    async getCallerIds() {
        return (await axios.get("/api/caller-ids")).data;
    },

    async addCallerId(callerId) {
        return (await axios.post("/api/caller-ids", callerId)).data;
    },

    async editCallerId(callerId) {
        await axios.post("/api/caller-ids/_edit", callerId);
    },

    async deleteCallerId(phoneNumber) {
        await axios.delete("/api/caller-ids", { params: { phoneNumber } });
    },
};
