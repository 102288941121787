
import { ContactMethod, ContactNumber, EmailAddress, NumberType } from "@/api/contacts";
import { Opportunity, OpportunityResult } from "@/api/opportunities";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import CountDown from "@/app/components/CountDown.vue";
import DLink from "@/app/components/DLink.vue";
import { renderPhoneNumberTypeIcon } from "@/app/contactUtils";
import PhoneNumberLink from "@/app/pages/PhoneNumberLink.vue";
import {
    canSendEmail,
    canSendSms,
    canStartCall,
    getContactCaption,
    getContactDeleted,
    isOpportunityActive,
} from "@/app/pages/opportunities/opportunityUtils";
import { configStore } from "@/store/config";
import { isMobile } from "@/util/phoneNumberUtils";
import Vue from "vue";

interface PreferableContactNumber {
    readonly visible?: boolean;
    readonly preferred: boolean;
    readonly contactNumber: ContactNumber;
}

interface PreferableEmailAddress {
    readonly visible?: boolean;
    readonly preferred: boolean;
    readonly emailAddress: EmailAddress;
}

export default Vue.extend({
    props: {
        compact: {
            type: Boolean,
            required: true,
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            required: true,
        },
        opportunityResult: {
            type: Object as () => OpportunityResult,
            required: true,
        },
    },

    data() {
        return {
            allContactMethodsVisible: false,
            ContactMethod,
            renderPhoneNumberTypeIcon,
        };
    },

    computed: {
        canSendEmail(): boolean {
            return canSendEmail(this.opportunityObj);
        },

        canSendSms(): boolean {
            return canSendSms(this.opportunityObj);
        },

        canStartCall(): boolean {
            return canStartCall(this.opportunityObj);
        },

        canUseTelLink(): boolean {
            return configStore.configuration.canUseTelLink;
        },

        contactCaption(): string | null {
            return getContactCaption(this.opportunityResult);
        },

        contactDeleted(): boolean {
            return getContactDeleted(this.opportunityResult);
        },

        contactEmailAddresses(): PreferableEmailAddress[] {
            const firstItemVisible = !this.hasPreferredEmailAddress && (!this.compact || !this.contactNumbers.length);

            return this.preferableEmailAddresses.map((emailAddress, index) => ({
                visible: this.allContactMethodsVisible || emailAddress.preferred || (index === 0 && firstItemVisible),
                ...emailAddress,
            }));
        },

        contactNumbers(): PreferableContactNumber[] {
            const firstItemVisible =
                !this.hasPreferredContactNumber && (!this.compact || !this.hasPreferredEmailAddress);

            return this.preferableContactNumbers.map((contactNumber, index) => ({
                visible: this.allContactMethodsVisible || contactNumber.preferred || (index === 0 && firstItemVisible),
                ...contactNumber,
            }));
        },

        hasPreferredContactNumber(): boolean {
            return this.preferableContactNumbers.some((e) => e.preferred);
        },

        hasPreferredEmailAddress(): boolean {
            return this.preferableEmailAddresses.some((e) => e.preferred);
        },

        isOpportunityActive(): boolean {
            return isOpportunityActive(this.opportunityObj);
        },

        opportunityObj(): Opportunity {
            return this.opportunityResult.opportunity;
        },

        preferableEmailAddresses(): PreferableEmailAddress[] {
            const emailAddresses: PreferableEmailAddress[] = this.opportunityResult.contact?.deleted
                ? []
                : (this.opportunityResult.contact?.contactData.emailAddresses ?? []).map((emailAddress) => ({
                      preferred:
                          this.opportunityObj.preferredContactMethod === ContactMethod.EMAIL &&
                          this.opportunityObj.preferredContactDetails === emailAddress.address,
                      emailAddress,
                  }));

            if (
                this.opportunityObj.preferredContactMethod === ContactMethod.EMAIL &&
                this.opportunityObj.preferredContactDetails &&
                !emailAddresses.some((e) => e.preferred)
            ) {
                emailAddresses.unshift({
                    preferred: true,
                    emailAddress: {
                        address: this.opportunityObj.preferredContactDetails,
                        label: null,
                    },
                });
            }

            return [...emailAddresses.filter((e) => e.preferred), ...emailAddresses.filter((e) => !e.preferred)];
        },

        preferableContactNumbers(): PreferableContactNumber[] {
            const contactNumbers: PreferableContactNumber[] = this.opportunityResult.contact?.deleted
                ? []
                : (this.opportunityResult.contact?.contactData.numbers ?? []).map((contactNumber) => ({
                      preferred:
                          (this.opportunityObj.preferredContactMethod === ContactMethod.PHONE ||
                              this.opportunityObj.preferredContactMethod === ContactMethod.SMS) &&
                          this.opportunityObj.preferredContactDetails === contactNumber.number,
                      contactNumber,
                  }));

            if (
                (this.opportunityObj.preferredContactMethod === ContactMethod.PHONE ||
                    this.opportunityObj.preferredContactMethod === ContactMethod.SMS) &&
                this.opportunityObj.preferredContactDetails &&
                !contactNumbers.some((n) => n.preferred)
            ) {
                contactNumbers.unshift({
                    preferred: true,
                    contactNumber: {
                        type: isMobile(this.opportunityObj.preferredContactDetails)
                            ? NumberType.MOBILE
                            : NumberType.LANDLINE,
                        number: this.opportunityObj.preferredContactDetails,
                        label: null,
                    },
                });
            }

            return [...contactNumbers.filter((n) => n.preferred), ...contactNumbers.filter((n) => !n.preferred)];
        },
    },

    methods: {
        async redirectToOpportunityDetailPage(id: string, params?: { [param: string]: string }) {
            const query = Object.entries(params ?? {})
                .filter((e) => !!e[1])
                .map((e) => `${e[0]}=${encodeURIComponent(e[1])}`)
                .join("&");

            await this.$router.push(`/opportunity/${id}` + (query ? "?" + query : ""));
        },

        async sendEmail(emailAddress: string) {
            await this.redirectToOpportunityDetailPage(this.opportunityObj.id, {
                action: "SEND_EMAIL",
                emailAddress,
            });
        },

        async sendSms(phoneNumber: string) {
            await this.redirectToOpportunityDetailPage(this.opportunityObj.id, {
                action: "SEND_SMS",
                phoneNumber,
            });
        },

        async startCall(phoneNumber: string) {
            await this.redirectToOpportunityDetailPage(this.opportunityObj.id, {
                action: "START_CALL",
                phoneNumber,
            });
        },
    },

    components: {
        CopyToClipboardIcon,
        CountDown,
        DLink,
        PhoneNumberLink,
    },
});
