
import { getContactDetailsLabel, getContactDetailsOptions, PreferredContact } from "./preferredContact";
import { ContactMethod, ContactNumber, EmailAddress } from "@/api/contacts";
import DateTimePicker from "@/app/components/DateTimePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        emailAddresses: {
            type: Array as () => EmailAddress[],
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        numbers: {
            type: Array as () => ContactNumber[],
            default: () => [],
        },
        timeZone: {
            type: String,
            required: true,
        },
        value: {
            type: Object as () => PreferredContact,
            required: true,
        },
    },

    data() {
        return {
            ContactMethod,
            earliestFlexible: !this.value.earliestContactTime,
            latestFlexible: !this.value.latestContactTime,
            preferredContact: cloneObject(this.value),
            timeZoneProp: this.timeZone,
        };
    },

    computed: {
        contactDetailsLabel(): string | null {
            return getContactDetailsLabel(this.preferredContact.preferredContactMethod);
        },

        contactDetailsOptions(): SelectOption[] {
            return getContactDetailsOptions(
                this.preferredContact.preferredContactMethod,
                this.emailAddresses,
                this.numbers
            );
        },
    },

    methods: {
        setEarliestFlexibleAndContactTime(flexible: boolean) {
            this.earliestFlexible = flexible;

            if (!flexible && !this.preferredContact.earliestContactTime) {
                this.preferredContact.earliestContactTime = new Date(
                    this.preferredContact.latestContactTime ?? Date.now()
                );
            }
        },

        setLatestFlexibleAndContactTime(flexible: boolean) {
            this.latestFlexible = flexible;

            if (!flexible && !this.preferredContact.latestContactTime) {
                this.preferredContact.latestContactTime = new Date(
                    this.preferredContact.earliestContactTime ?? Date.now()
                );
            }
        },

        handleInput() {
            this.$emit("input", {
                preferredContactMethod: this.preferredContact.preferredContactMethod || null,
                preferredContactDetails: this.preferredContact.preferredContactDetails || null,
                earliestContactTime: this.earliestFlexible ? null : this.preferredContact.earliestContactTime,
                latestContactTime: this.latestFlexible ? null : this.preferredContact.latestContactTime,
            } as PreferredContact);
        },
    },

    watch: {
        contactDetailsOptions: {
            deep: true,
            immediate: true,
            handler() {
                if (
                    !this.contactDetailsOptions.some((o) => this.preferredContact.preferredContactDetails === o.value)
                ) {
                    this.preferredContact.preferredContactDetails = null;
                }
            },
        },

        earliestFlexible() {
            this.handleInput();
        },

        latestFlexible() {
            this.handleInput();
        },

        preferredContact: {
            deep: true,
            immediate: true,
            handler() {
                this.handleInput();
            },
        },

        timeZone() {
            this.timeZoneProp = this.timeZone;
        },

        timeZoneProp() {
            this.$emit("update:time-zone", this.timeZoneProp);
        },

        value: {
            deep: true,
            handler() {
                if (JSON.stringify(this.preferredContact) !== JSON.stringify(this.value)) {
                    this.preferredContact = cloneObject(this.value);
                    this.earliestFlexible = !this.value.earliestContactTime;
                    this.latestFlexible = !this.value.latestContactTime;
                }
            },
        },
    },

    components: {
        EnumField,
        DateTimePicker,
        TimeZonePicker,
    },
});
