
import AuditEventTimelineItem from "./AuditEventTimelineItem.vue";
import { canAddNote, getCaseStatus } from "./caseUtils";
import { Case, CaseAuditEvent, casesApi, CaseStatus } from "@/api/cases";
import { Contact, ContactMethod } from "@/api/contacts";
import { Forbidden } from "@/api/errors";
import { IncomingCallResult, incomingCallsApi } from "@/api/incomingCalls";
import { IncomingEmail, incomingEmailsApi } from "@/api/incomingEmails";
import { IncomingSms, incomingSmsApi } from "@/api/incomingSms";
import { incomingWhatsAppApi, IncomingWhatsAppMessage } from "@/api/incomingWhatsApp";
import { Note, NoteForm, notesApi } from "@/api/notes";
import {
    isCaseAuditEventsUpdatedNotification,
    isCaseIncomingCallsUpdatedNotification,
    isCaseIncomingEmailsUpdatedNotification,
    isCaseIncomingSmsUpdatedNotification,
    isCaseIncomingWhatsAppUpdatedNotification,
    isCaseNotesUpdatedNotification,
    isCaseOutgoingCallsUpdatedNotification,
    isCaseOutgoingEmailsUpdatedNotification,
    isCaseOutgoingSmsUpdatedNotification,
    isCaseOutgoingWhatsAppUpdatedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { OutgoingCall, OutgoingCallForm, outgoingCallsApi } from "@/api/outgoingCalls";
import {
    OutgoingEmail,
    OutgoingEmailForm,
    outgoingEmailsApi,
    TransactionEmailHeader,
    TransactionEmailReceiver,
    TransactionEmailReceiverType,
} from "@/api/outgoingEmails";
import { OutgoingSms, outgoingSmsApi, OutgoingSmsForm } from "@/api/outgoingSms";
import { outgoingWhatsAppApi, OutgoingWhatsAppMessage } from "@/api/outgoingWhatsApp";
import { WhatsAppTemplate } from "@/api/whatsAppAccounts";
import { getReplyHeaders, getReplySubject, ReplyToBaseEmail } from "@/app/emailUtils";
import {
    getMostRelevantEmailAddress,
    isIncomingCallTimelineItem,
    isIncomingEmailTimelineItem,
    isIncomingSmsTimelineItem,
    isIncomingWhatsAppMessageTimelineItem,
    isMainOutgoingEmailTimelineItem,
    isOutgoingCallTimelineItem,
    isOutgoingSmsTimelineItem,
    isOutgoingWhatsAppMessageTimelineItem,
    TimelineItem,
    TimelineItemType,
} from "@/app/issueUtils";
import { showConfirm, showInfo } from "@/app/messageUtil";
import IncomingCallTimelineItem from "@/app/pages/incomingcalls/IncomingCallTimelineItem.vue";
import IncomingEmailTimelineItem from "@/app/pages/incomingemails/IncomingEmailTimelineItem.vue";
import IncomingSmsTimelineItem from "@/app/pages/incomingsms/IncomingSmsTimelineItem.vue";
import IncomingWhatsAppTimelineItem from "@/app/pages/incomingwhatsapp/IncomingWhatsAppTimelineItem.vue";
import NoteFormTimelineItem from "@/app/pages/notes/NoteFormTimelineItem.vue";
import NoteTimelineItem from "@/app/pages/notes/NoteTimelineItem.vue";
import OutgoingCallFormTimelineItem from "@/app/pages/outgoingcalls/OutgoingCallFormTimelineItem.vue";
import OutgoingCallTimelineItem from "@/app/pages/outgoingcalls/OutgoingCallTimelineItem.vue";
import OutgoingEmailFormTimelineItem from "@/app/pages/outgoingemails/OutgoingEmailFormTimelineItem.vue";
import OutgoingEmailTimelineItem from "@/app/pages/outgoingemails/OutgoingEmailTimelineItem.vue";
import OutgoingSmsFormTimelineItem from "@/app/pages/outgoingsms/OutgoingSmsFormTimelineItem.vue";
import OutgoingSmsTimelineItem from "@/app/pages/outgoingsms/OutgoingSmsTimelineItem.vue";
import OutgoingWhatsAppFormTimelineItem from "@/app/pages/outgoingwhatsapp/OutgoingWhatsAppFormTimelineItem.vue";
import OutgoingWhatsAppTemplateFormTimelineItem from "@/app/pages/outgoingwhatsapp/OutgoingWhatsAppTemplateFormTimelineItem.vue";
import OutgoingWhatsAppTimelineItem from "@/app/pages/outgoingwhatsapp/OutgoingWhatsAppTimelineItem.vue";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { ActionLimiter } from "@/util/debounce";
import Vue from "vue";

export default Vue.extend({
    props: {
        canManageCase: {
            type: Boolean,
            required: true,
        },
        canSendEmail: {
            type: Boolean,
            required: true,
        },
        canSendSms: {
            type: Boolean,
            required: true,
        },
        canSendWhatsapp: {
            type: Boolean,
            required: true,
        },
        canStartCall: {
            type: Boolean,
            required: true,
        },
        canViewContent: {
            type: Boolean,
            required: true,
        },
        caseProp: {
            type: Object as () => Case,
            required: true,
        },
        contact: {
            type: Object as () => Contact,
            required: false,
        },
    },

    data() {
        return {
            // action limiters
            loadAuditEventsLimiter: new ActionLimiter(true),
            loadIncomingCallsLimiter: new ActionLimiter(true),
            loadIncomingEmailsLimiter: new ActionLimiter(true),
            loadIncomingSmsLimiter: new ActionLimiter(true),
            loadIncomingWhatsAppMessagesLimiter: new ActionLimiter(true),
            loadNotesLimiter: new ActionLimiter(true),
            loadOutgoingCallsLimiter: new ActionLimiter(true),
            loadOutgoingEmailsLimiter: new ActionLimiter(true),
            loadOutgoingSmsLimiter: new ActionLimiter(true),
            loadOutgoingWhatsAppMessagesLimiter: new ActionLimiter(true),
            showOutgoingEmailFormLimiter: new ActionLimiter(true),
            updateReadLimiter: new ActionLimiter(true),

            // activities
            auditEvents: [] as CaseAuditEvent[],
            incomingCalls: [] as IncomingCallResult[],
            incomingEmails: [] as IncomingEmail[],
            incomingSms: [] as IncomingSms[],
            incomingWhatsApps: [] as IncomingWhatsAppMessage[],
            notes: [] as Note[],
            outgoingCalls: [] as OutgoingCall[],
            outgoingEmails: [] as OutgoingEmail[],
            outgoingSms: [] as OutgoingSms[],
            outgoingWhatsApps: [] as OutgoingWhatsAppMessage[],

            // work indicators
            activeWorkers: 0,
            addingNote: false,
            sendingEmail: false,
            sendingSms: false,
            sendingWhatsApp: false,
            startingCall: false,

            // misc
            emailAttachmentsUploadProgress: 0,
            outgoingEmailReceivers: [] as TransactionEmailReceiver[],
            outgoingEmailHeaders: [] as TransactionEmailHeader[],
            outgoingEmailDefaultSubject: "",
            outgoingEmailContentForQuote: "",
            outgoingEmailAutomaticQuote: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            outboundNumber: null as string | null,
            smsReceiver: null as string | null,
            timelineForm: null as "note" | "call" | "sms" | "email" | "whatsapp" | null,
            TimelineItemType,
        };
    },

    computed: {
        canAddNote(): boolean {
            return canAddNote(this.caseProp);
        },

        caseStatus(): CaseStatus | null {
            return getCaseStatus(this.caseProp);
        },

        defaultCountry(): string {
            if (this.contact && !this.contact.deleted && this.contact.contactData.country) {
                return this.contact.contactData.country;
            }

            return dealersStore.dealerById(this.caseProp.dealerId)?.country || configStore.configuration.defaultCountry;
        },

        defaultEmailSubject(): string {
            return `${this.$t("Ihre Anfrage")} [${this.caseProp.id}]`;
        },

        latestIncomingWhatsAppMessage(): IncomingWhatsAppMessage | null {
            const msgs = [...this.incomingWhatsApps].sort((a, b) => b.created.getTime() - a.created.getTime());

            if (!msgs.length) {
                return null;
            }

            return msgs[0];
        },

        timelineItems(): TimelineItem[] {
            return [
                ...this.toTimelineItems(
                    this.auditEvents.map((a) => ({ id: JSON.stringify(a), ...a })),
                    TimelineItemType.AUDIT_EVENT
                ),
                ...this.toTimelineItems(this.incomingCalls, TimelineItemType.INCOMING_CALL),
                ...this.toTimelineItems(this.incomingEmails, TimelineItemType.INCOMING_EMAIL),
                ...this.toTimelineItems(this.incomingSms, TimelineItemType.INCOMING_SMS),
                ...this.toTimelineItems(this.incomingWhatsApps, TimelineItemType.INCOMING_WHATSAPP),
                ...this.toTimelineItems(this.notes, TimelineItemType.NOTE),
                ...this.toTimelineItems(this.outgoingCalls, TimelineItemType.OUTGOING_CALL),
                ...this.toTimelineItems(this.outgoingEmails, TimelineItemType.OUTGOING_EMAIL),
                ...this.toTimelineItems(this.outgoingSms, TimelineItemType.OUTGOING_SMS),
                ...this.toTimelineItems(this.outgoingWhatsApps, TimelineItemType.OUTGOING_WHATSAPP),
            ].sort((a, b) => b.created.getTime() - a.created.getTime());
        },

        whatsAppAccountId(): string | null {
            if (!this.latestIncomingWhatsAppMessage) {
                return null;
            }

            const diff = now().getTime() - this.latestIncomingWhatsAppMessage.created.getTime();

            if (diff > 23.5 * 60 * 60 * 1000) {
                return null;
            }

            return this.latestIncomingWhatsAppMessage.accountId;
        },

        whatsAppReceiver(): string | null {
            return this.latestIncomingWhatsAppMessage?.fromNumber || null;
        },

        whatsAppSender(): string | null {
            return this.latestIncomingWhatsAppMessage?.toNumber || null;
        },
    },

    methods: {
        async addNote(noteForm: NoteForm) {
            this.addingNote = true;
            try {
                await casesApi.addNote(this.caseProp.id, noteForm);
                this.timelineForm = null;
            } finally {
                this.addingNote = false;
            }
        },

        async assignYourselfOrIsCtUserForOutgoingCommunication(text: string) {
            if (userSession.isCtUser() || this.caseProp.assigneeIds.includes(userSession.userId!)) {
                return true;
            }

            if (!this.canManageCase) {
                return false;
            }

            if (!(await showConfirm(this.$t("Der Fall muss Ihnen zugeteilt sein") as string, text))) {
                return false;
            }

            this.$emit("worker:start");
            try {
                if (this.caseStatus === CaseStatus.CLOSED) {
                    await casesApi.reopenCase(this.caseProp.id);
                }

                await casesApi.updateAssignees(this.caseProp.id, [...this.caseProp.assigneeIds, userSession.userId!]);
            } finally {
                this.$emit("worker:end");
            }

            return true;
        },

        getMostRelevantPhone(): string | null {
            for (const timelineItem of this.timelineItems) {
                if (isIncomingWhatsAppMessageTimelineItem(timelineItem)) {
                    return timelineItem.fromNumber;
                } else if (isOutgoingWhatsAppMessageTimelineItem(timelineItem)) {
                    return timelineItem.toNumber;
                } else if (isIncomingCallTimelineItem(timelineItem)) {
                    return timelineItem.fromNumber;
                } else if (isOutgoingCallTimelineItem(timelineItem)) {
                    return timelineItem.outboundNumber;
                } else if (isIncomingSmsTimelineItem(timelineItem)) {
                    return timelineItem.fromNumber;
                } else if (isOutgoingSmsTimelineItem(timelineItem)) {
                    return timelineItem.toNumber;
                }
            }

            if (
                (this.caseProp.preferredContactMethod === ContactMethod.PHONE ||
                    this.caseProp.preferredContactMethod === ContactMethod.SMS) &&
                this.caseProp.preferredContactDetails
            ) {
                return this.caseProp.preferredContactDetails;
            }

            if (this.contact && !this.contact.deleted && this.contact.contactData.numbers.length) {
                return this.contact.contactData.numbers[0].number;
            }

            return null;
        },

        hideTimelineForm() {
            this.timelineForm = null;
        },

        async sendEmail(outgoingEmailForm: OutgoingEmailForm, files: File[]) {
            if (
                !(await this.assignYourselfOrIsCtUserForOutgoingCommunication(
                    this.$t(
                        "Wenn Sie eine E-Mail senden, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?"
                    ) as string
                ))
            ) {
                return;
            }

            this.sendingEmail = true;
            try {
                await casesApi.sendEmail(
                    this.caseProp.id,
                    {
                        ...outgoingEmailForm,
                        htmlBody: outgoingEmailForm.htmlBody + `\n<p><small>[CASE#${this.caseProp.id}]</small></p>`,
                    },
                    files,
                    ({ total, loaded }) => (this.emailAttachmentsUploadProgress = (100 * loaded) / total)
                );
                this.timelineForm = null;
            } catch (e) {
                Vue.nextTick(() => {
                    throw e;
                });
            } finally {
                this.sendingEmail = false;
            }
        },

        async sendSms(form: OutgoingSmsForm) {
            if (
                !(await this.assignYourselfOrIsCtUserForOutgoingCommunication(
                    this.$t(
                        "Wenn Sie eine SMS senden, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?"
                    ) as string
                ))
            ) {
                return;
            }

            this.sendingSms = true;
            try {
                await casesApi.sendSms(this.caseProp.id, form);
                this.timelineForm = null;
            } finally {
                this.sendingSms = false;
            }
        },

        async sendWhatsApp({
            text,
            files,
            toNumber,
            accountId,
            template,
        }: {
            text?: string;
            files?: File[];
            toNumber?: string;
            accountId?: string;
            template?: WhatsAppTemplate;
        }) {
            if (
                !(await this.assignYourselfOrIsCtUserForOutgoingCommunication(
                    this.$t(
                        "Wenn Sie eine WhatsApp-Nachricht senden, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?"
                    ) as string
                ))
            ) {
                return;
            }

            this.sendingWhatsApp = true;
            try {
                await casesApi.sendWhatsAppMessage(
                    this.caseProp.id,
                    accountId || this.whatsAppAccountId!,
                    {
                        toNumber: toNumber || this.whatsAppReceiver!,
                        text: template?.text || text || null,
                        templateNamespace: template?.namespace || null,
                        templateName: template?.name || null,
                        templateLanguage: template?.language || null,
                    },
                    files || [],
                    () => undefined
                );
                this.timelineForm = null;
            } finally {
                this.sendingWhatsApp = false;
            }
        },

        showNoteForm() {
            this.timelineForm = "note";
        },

        showOutgoingCallForm(phone: string | null) {
            if (!this.canStartCall) {
                showInfo(
                    this.$t("Sie haben nicht die Berechtigung, für diesen Vorgang einen Anruf zu starten.") as string
                );
                return;
            }

            this.outboundNumber = phone || this.getMostRelevantPhone();
            this.timelineForm = "call";
        },

        async showOutgoingEmailFormForReply(reply: ReplyToBaseEmail) {
            await this.showOutgoingEmailFormLimiter.execute(async () => {
                if (!this.canSendEmail) {
                    showInfo(
                        this.$t("Sie haben nicht die Berechtigung, für diesen Vorgang eine E-Mail zu senden.") as string
                    );
                    return;
                }

                this.timelineForm = null;

                await this.$nextTick();

                const receivers: TransactionEmailReceiver[] = [
                    ...reply.toReceivers.map((r) => ({
                        ...r,
                        type: TransactionEmailReceiverType.TO,
                    })),
                    ...reply.ccReceivers.map((r) => ({
                        ...r,
                        type: TransactionEmailReceiverType.CC,
                    })),
                    ...reply.bccReceivers.map((r) => ({
                        ...r,
                        type: TransactionEmailReceiverType.BCC,
                    })),
                ];

                const receiver = reply.replyToAddress
                    ? { name: reply.replyToName, address: reply.replyToAddress }
                    : { name: reply.fromName, address: reply.fromAddress };

                if (!receivers.some((r) => r.address === receiver.address)) {
                    receivers.unshift({ ...receiver, type: TransactionEmailReceiverType.TO });
                }

                this.outgoingEmailReceivers = receivers;

                this.outgoingEmailHeaders = getReplyHeaders([reply]);

                this.outgoingEmailDefaultSubject = reply.subject
                    ? getReplySubject(reply.subject)
                    : this.defaultEmailSubject;
                this.outgoingEmailContentForQuote = reply.htmlBodyForQuote;
                this.outgoingEmailAutomaticQuote = true;

                this.timelineForm = "email";

                await this.$nextTick();
                await this.$vuetify.goTo(this.$refs.emailForm as Vue);
            });
        },

        async showOutgoingEmailForm(email: string | null) {
            await this.showOutgoingEmailFormLimiter.execute(async () => {
                if (!this.canSendEmail) {
                    showInfo(
                        this.$t("Sie haben nicht die Berechtigung, für diesen Vorgang eine E-Mail zu senden.") as string
                    );
                    return;
                }

                this.outgoingEmailReceivers = [];

                if (email) {
                    this.outgoingEmailReceivers = [
                        {
                            name: null,
                            address: email,
                            type: TransactionEmailReceiverType.TO,
                        },
                    ];
                } else {
                    const mostRelevantReceiver = getMostRelevantEmailAddress(
                        this.caseProp,
                        this.contact,
                        this.timelineItems
                    );

                    if (mostRelevantReceiver) {
                        this.outgoingEmailReceivers = [
                            {
                                ...mostRelevantReceiver,
                                type: TransactionEmailReceiverType.TO,
                            },
                        ];
                    }
                }

                this.outgoingEmailHeaders = getReplyHeaders(
                    (this.timelineItems.filter(
                        (i) => isMainOutgoingEmailTimelineItem(i) || isIncomingEmailTimelineItem(i)
                    ) as unknown[]) as (IncomingEmail | OutgoingEmail)[]
                );

                this.outgoingEmailDefaultSubject =
                    [
                        ...this.timelineItems.filter(isMainOutgoingEmailTimelineItem),
                        ...this.timelineItems.filter(isIncomingEmailTimelineItem).filter((i) => i.user),
                    ]
                        .sort((a, b) => b.created.getTime() - a.created.getTime())
                        .map((i) => i.subject)
                        .filter((subject) => !!subject)
                        .map((subject) => getReplySubject(subject!))
                        .pop() ?? this.defaultEmailSubject;

                const lastEmail = this.timelineItems.find(
                    (i) => isIncomingEmailTimelineItem(i) || isMainOutgoingEmailTimelineItem(i)
                );

                if (lastEmail && isMainOutgoingEmailTimelineItem(lastEmail)) {
                    this.outgoingEmailContentForQuote = await outgoingEmailsApi.getHtmlBody(
                        lastEmail.id,
                        {
                            caseId: this.caseProp.id,
                        },
                        true
                    );
                } else if (lastEmail && isIncomingEmailTimelineItem(lastEmail)) {
                    this.outgoingEmailContentForQuote = await incomingEmailsApi.getHtmlBody(
                        lastEmail.id,
                        {
                            caseId: this.caseProp.id,
                        },
                        true
                    );
                } else {
                    this.outgoingEmailContentForQuote = "";
                }
                this.outgoingEmailAutomaticQuote = false;

                this.timelineForm = "email";
            });
        },

        showOutgoingSmsForm(phone: string | null) {
            if (!this.canSendSms) {
                showInfo(this.$t("Sie haben nicht die Berechtigung, für diesen Vorgang eine SMS zu senden.") as string);
                return;
            }

            this.smsReceiver = phone || this.getMostRelevantPhone();
            this.timelineForm = "sms";
        },

        showOutgoingWhatsAppForm() {
            if (!this.canSendWhatsapp) {
                showInfo(
                    this.$t(
                        "Sie haben nicht die Berechtigung, für diesen Vorgang eine WhatsApp-Nachricht zu senden."
                    ) as string
                );
                return;
            }

            this.timelineForm = "whatsapp";
        },

        async startCall(outgoingCallForm: OutgoingCallForm) {
            if (
                !(await this.assignYourselfOrIsCtUserForOutgoingCommunication(
                    this.$t(
                        "Wenn Sie einen Anruf starten, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?"
                    ) as string
                ))
            ) {
                return;
            }

            this.startingCall = true;
            try {
                await casesApi.startCall(this.caseProp.id, outgoingCallForm);
                this.timelineForm = null;
            } finally {
                this.startingCall = false;
            }
        },

        toTimelineItems(items: { id: string; created: Date }[], type: TimelineItemType): TimelineItem[] {
            return items.map((i) => ({ ...i, timelineItemType: type }));
        },
    },

    async mounted() {
        casesApi.updateRead(this.caseProp.id);

        this.activeWorkers++;
        try {
            const [
                auditEvents,
                incomingCalls,
                incomingEmails,
                incomingSms,
                incomingWhatApps,
                notes,
                outgoingCalls,
                outgoingEmails,
                outgoingSms,
                outgoingWhatsApps,
            ] = await Promise.all([
                casesApi.getAuditEvents(this.caseProp.id),
                incomingCallsApi.getIncomingCallsByCase(this.caseProp.id),
                incomingEmailsApi.getIncomingEmails({ caseId: this.caseProp.id }),
                incomingSmsApi.getIncomingSmsByCase(this.caseProp.id),
                incomingWhatsAppApi.getMessagesByCase(this.caseProp.id),
                notesApi.getByCase(this.caseProp.id),
                outgoingCallsApi.getOutgoingCallsByCase(this.caseProp.id),
                outgoingEmailsApi.getOutgoingEmails({ caseId: this.caseProp.id }),
                outgoingSmsApi.getOutgoingSmsByCase(this.caseProp.id),
                outgoingWhatsAppApi.getMessagesByCase(this.caseProp.id),
            ]);

            this.auditEvents = auditEvents!;
            this.incomingCalls = incomingCalls!;
            this.incomingEmails = incomingEmails!;
            this.incomingSms = incomingSms!;
            this.incomingWhatsApps = incomingWhatApps!;
            this.notes = notes!;
            this.outgoingCalls = outgoingCalls!;
            this.outgoingEmails = outgoingEmails!;
            this.outgoingSms = outgoingSms!;
            this.outgoingWhatsApps = outgoingWhatsApps!;
        } catch (e) {
            if (!(e instanceof Forbidden)) {
                throw e;
            }

            this.$emit("issue:forbidden");
        } finally {
            this.$emit("incoming-emails", this.incomingEmails);
            this.$emit("incoming-whats-app-messages", this.incomingWhatsApps);
            this.$emit("outgoing-emails", this.outgoingEmails);
            this.$emit("outgoing-whats-app-messages", this.outgoingWhatsApps);

            this.activeWorkers--;
        }

        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            const isActivityUpdateNotification =
                isCaseAuditEventsUpdatedNotification(n) ||
                isCaseIncomingCallsUpdatedNotification(n) ||
                isCaseIncomingEmailsUpdatedNotification(n) ||
                isCaseIncomingSmsUpdatedNotification(n) ||
                isCaseIncomingWhatsAppUpdatedNotification(n) ||
                isCaseNotesUpdatedNotification(n) ||
                isCaseOutgoingCallsUpdatedNotification(n) ||
                isCaseOutgoingEmailsUpdatedNotification(n) ||
                isCaseOutgoingSmsUpdatedNotification(n) ||
                isCaseOutgoingWhatsAppUpdatedNotification(n);

            if (isActivityUpdateNotification) {
                if (this.caseProp.id === n.caseId) {
                    this.updateReadLimiter.execute(async () => await casesApi.updateRead(this.caseProp.id));

                    this.activeWorkers++;
                    try {
                        if (isCaseAuditEventsUpdatedNotification(n)) {
                            await this.loadAuditEventsLimiter.execute(async () => {
                                this.auditEvents = await casesApi.getAuditEvents(n.caseId);
                            });
                        } else if (isCaseIncomingCallsUpdatedNotification(n)) {
                            await this.loadIncomingCallsLimiter.execute(async () => {
                                this.incomingCalls = await incomingCallsApi.getIncomingCallsByCase(n.caseId);
                            });
                        } else if (isCaseIncomingEmailsUpdatedNotification(n)) {
                            await this.loadIncomingEmailsLimiter.execute(async () => {
                                this.incomingEmails = await incomingEmailsApi.getIncomingEmails({ caseId: n.caseId });
                                this.$emit("incoming-emails", this.incomingEmails);
                            });
                        } else if (isCaseIncomingSmsUpdatedNotification(n)) {
                            await this.loadIncomingSmsLimiter.execute(async () => {
                                this.incomingSms = await incomingSmsApi.getIncomingSmsByCase(n.caseId);
                            });
                        } else if (isCaseIncomingWhatsAppUpdatedNotification(n)) {
                            await this.loadIncomingWhatsAppMessagesLimiter.execute(async () => {
                                this.incomingWhatsApps = await incomingWhatsAppApi.getMessagesByCase(n.caseId);
                                this.$emit("incoming-whats-app-messages", this.incomingWhatsApps);
                            });
                        } else if (isCaseNotesUpdatedNotification(n)) {
                            await this.loadNotesLimiter.execute(async () => {
                                this.notes = await notesApi.getByCase(n.caseId);
                            });
                        } else if (isCaseOutgoingCallsUpdatedNotification(n)) {
                            await this.loadOutgoingCallsLimiter.execute(async () => {
                                this.outgoingCalls = await outgoingCallsApi.getOutgoingCallsByCase(n.caseId);
                            });
                        } else if (isCaseOutgoingEmailsUpdatedNotification(n)) {
                            await this.loadOutgoingEmailsLimiter.execute(async () => {
                                this.outgoingEmails = await outgoingEmailsApi.getOutgoingEmails({ caseId: n.caseId });
                                this.$emit("outgoing-emails", this.outgoingEmails);
                            });
                        } else if (isCaseOutgoingSmsUpdatedNotification(n)) {
                            await this.loadOutgoingSmsLimiter.execute(async () => {
                                this.outgoingSms = await outgoingSmsApi.getOutgoingSmsByCase(n.caseId);
                            });
                        } else if (isCaseOutgoingWhatsAppUpdatedNotification(n)) {
                            await this.loadOutgoingWhatsAppMessagesLimiter.execute(async () => {
                                this.outgoingWhatsApps = await outgoingWhatsAppApi.getMessagesByCase(n.caseId);
                                this.$emit("outgoing-whats-app-messages", this.outgoingWhatsApps);
                            });
                        }

                        this.$emit("issue:allowed");
                    } catch (e) {
                        if (!(e instanceof Forbidden)) {
                            throw e;
                        }

                        this.$emit("issue:forbidden");
                    } finally {
                        this.activeWorkers--;
                    }
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        AuditEventTimelineItem,
        IncomingCallTimelineItem,
        IncomingEmailTimelineItem,
        IncomingSmsTimelineItem,
        IncomingWhatsAppTimelineItem,
        NoteFormTimelineItem,
        NoteTimelineItem,
        OutgoingCallFormTimelineItem,
        OutgoingCallTimelineItem,
        OutgoingEmailFormTimelineItem,
        OutgoingEmailTimelineItem,
        OutgoingSmsFormTimelineItem,
        OutgoingSmsTimelineItem,
        OutgoingWhatsAppTimelineItem,
        OutgoingWhatsAppFormTimelineItem,
        OutgoingWhatsAppTemplateFormTimelineItem,
    },
});
