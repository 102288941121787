
import InitializeDealerSettingsDialog from "./InitializeDealerSettingsDialog.vue";
import { initializationApi } from "@/api/initialization";
import { fileDialog } from "@/app/fileUtils";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            initializeDealerSettingsDialogVisible: false,
            initializingGlobalSettings: false,
        };
    },

    computed: {
        dealerIds(): string[] {
            return dealersStore.dealers.map((d) => d.id);
        },

        working(): boolean {
            return this.initializingGlobalSettings;
        },
    },

    methods: {
        async initializeGlobalSettings() {
            fileDialog(
                false,
                async (files) => {
                    this.initializingGlobalSettings = true;
                    try {
                        await initializationApi.initializeGlobalSettings(files[0], () => {
                            // empty
                        });
                    } finally {
                        this.initializingGlobalSettings = false;
                    }
                },
                [".xml"]
            );
        },
    },

    components: {
        InitializeDealerSettingsDialog,
    },
});
