
import { BadRequest, Forbidden, TwoFactorAuthenticationRequired } from "@/api/errors";
import { userSessionApi } from "@/api/userSession";
import CaptchaDialog from "@/app/components/CaptchaDialog.vue";
import { showInfo } from "@/app/messageUtil";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { formatDifference } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            username: "",
            usernameRules: notEmpty().msg(() => this.$t("E-Mail-Adresse ist erforderlich")),
            password: "",
            passwordRules: notEmpty().msg(() => this.$t("Passwort ist erforderlich")),
            permanent: false,
            loading: false,
            error: false,
            errorMessage: "",
            twoFactorAuthenticationDialogVisible: null as Date | null,
            verificationNumber: "",
        };
    },

    computed: {
        config() {
            return configStore.configuration;
        },

        tokenResendTimeout(): string | null {
            if (!this.twoFactorAuthenticationDialogVisible) {
                return null;
            }
            const ts = now();
            const lockedUntil = new Date(this.twoFactorAuthenticationDialogVisible.getTime() + 30000);
            if (ts.getTime() < lockedUntil.getTime()) {
                return formatDifference(ts, lockedUntil, true);
            }
            return null;
        },
    },

    methods: {
        async login() {
            this.error = false;
            this.twoFactorAuthenticationDialogVisible = null;

            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.loading = true;
            try {
                if (await userSession.login(this.username, this.password, this.permanent)) {
                    const location = this.$route.query.d as string;
                    await this.$router.replace(location || "/");
                    return;
                }
            } catch (e) {
                if (e instanceof Forbidden) {
                    this.errorMessage = this.$t(
                        "Ein Zugriff auf die Anwendung ist aus Ihrem Netzwerk nicht gestattet."
                    ) as string;
                    this.error = true;
                    return;
                }

                if (e instanceof BadRequest) {
                    const challengeResponse = await (this.$refs.captcha as any).getChallengeResponse();
                    if (await userSessionApi.resetLoginAttempts(this.username, challengeResponse)) {
                        this.$nextTick(() => this.login());
                    } else {
                        this.errorMessage = this.$t(e.details[0].messageKey) as string;
                        this.error = true;
                    }
                    return;
                }

                if (e instanceof TwoFactorAuthenticationRequired) {
                    this.verificationNumber = e.verificationStatus.number;
                    this.twoFactorAuthenticationDialogVisible = now();
                    if (e.verificationStatus.maxSendAttemptsReached) {
                        showInfo(
                            this.$t("Die maximale Anzahl an Zustellversuchen für den Token wurde erreicht.") as string
                        );
                    }
                    return;
                }

                throw e;
            } finally {
                this.loading = false;
            }
            this.errorMessage = this.$t("E-Mail-Adresse oder Passwort sind nicht korrekt.") as string;
            this.error = true;
        },

        async verifyToken() {
            const token = (this.$refs.tokenField as any).internalValue.trim();
            if (!token) {
                return;
            }

            this.loading = true;
            try {
                if (await userSession.verifyToken(this.username, this.password, this.permanent, token)) {
                    this.twoFactorAuthenticationDialogVisible = null;
                    const location = this.$route.query.d as string;
                    await this.$router.replace(location || "/");
                    return;
                }
            } catch (e) {
                if (e instanceof BadRequest) {
                    this.errorMessage = this.$t(e.details[0].messageKey) as string;
                    this.error = true;
                    this.twoFactorAuthenticationDialogVisible = null;
                    return;
                }
                throw e;
            } finally {
                this.loading = false;
            }

            showInfo(this.$t("Der eingegebene Token ist nicht korrekt.") as string);
        },
    },

    beforeRouteEnter(to, _, next) {
        if (userSession.permissions) {
            next((to.query.d as string) || "/");
        } else {
            next();
        }
    },

    components: {
        CaptchaDialog,
    },
});
