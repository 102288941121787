import axios from "axios";

export interface CaseOutcomeReasonForm {
    readonly name: string;
    readonly description: string | null;
}

export interface CaseOutcomeReason {
    readonly id: string;
    readonly name: string;
    readonly description: string | null;
}

interface CaseOutcomeReasonsApi {
    add(caseOutcomeReasonForm: CaseOutcomeReasonForm): Promise<void>;
    edit(id: string, caseOutcomeReasonForm: CaseOutcomeReasonForm): Promise<void>;
    delete(id: string): Promise<void>;
    getAll(): Promise<CaseOutcomeReason[]>;
    updateOrder(ids: string[]): Promise<void>;
}

export const caseOutcomeReasonsApi: CaseOutcomeReasonsApi = {
    async add(caseOutcomeReasonForm) {
        await axios.post(`/api/case-outcome-reasons`, caseOutcomeReasonForm);
    },

    async edit(id, caseOutcomeReasonForm) {
        await axios.put(`/api/case-outcome-reasons/${id}`, caseOutcomeReasonForm);
    },

    async delete(id) {
        await axios.delete(`/api/case-outcome-reasons/${id}`);
    },

    async getAll() {
        return (await axios.get(`/api/case-outcome-reasons`)).data;
    },

    async updateOrder(ids) {
        await axios.post(`/api/case-outcome-reasons/_update-order`, ids);
    },
};
