
import PhoneNumberLink from "./PhoneNumberLink.vue";
import { Absence, absencesApi } from "@/api/absences";
import { ExternalAbsenceProvider, externalAbsenceProvidersApi } from "@/api/externalAbsenceProviders";
import { Permission } from "@/api/userSession";
import { Away, AwayType, PhoneNumber, PhoneNumberType, Status, usersApi, UserSummary } from "@/api/users";
import { WorkingHours, workingHoursApi, WorkingHoursTemplate } from "@/api/workingHours";
import { renderAbsenceTime } from "@/app/absenceUtils";
import { getFullName } from "@/app/userUtils";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { departmentsStore } from "@/store/departments";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, getDateByDayOfWeek, getDaysOfWeek, getTime, getWeek, toDateObject, Week } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import moment from "moment-timezone";
import Vue from "vue";

interface UserSummaryWithStatus extends UserSummary {
    away: Away | null;
    status: Status;
}

interface CalendarEvent {
    readonly name: string;
    readonly start: Date;
    readonly end: Date;
    readonly color: string;
    readonly timed: boolean;
    readonly entry: WorkingEntry;
}

interface CalendarClickDateEvent {
    readonly event: any;
    readonly nativeEvent: Event;
}

interface WorkingEntry {
    readonly dealerId: string | null;
    readonly absence: Absence | null;
    readonly from: string;
    readonly to: string;
}

export default Vue.extend({
    props: {
        userId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            absences: [] as Absence[],
            apiUserSummary: null as UserSummary | null,
            calendarType: "week",
            calendarDate: "",
            currentDate: now(),
            externalAbsenceProviders: [] as ExternalAbsenceProvider[],
            loading: true,
            selectedEvent: null as CalendarEvent | null,
            selectedCalendarElement: null as EventTarget | null,
            selectedEventOpen: false,
            weekOffset: 0,
            weekWorkingHours: null as WorkingHours | null,
            getFullName,
        };
    },

    computed: {
        calendarEvents(): CalendarEvent[] {
            const events = [] as CalendarEvent[];

            this.calendarWeekdays.forEach((dayOfWeek) => {
                const workingEntries = this.getEntriesForDayOfWeek(this.weekWorkingHoursTemplate, dayOfWeek, true);
                const dateString = getDate(this.getMainDealerDate(this.week, dayOfWeek), this.mainDealerTimeZone);

                workingEntries.forEach((w) => {
                    let name;
                    let color;

                    if (w.absence) {
                        name = w.absence.type
                            ? this.$t(`enum.AbsenceType.${w.absence.type}`).toString()
                            : this.$t("Abwesenheit").toString();
                        color = "error";
                    } else {
                        name = w.dealerId ? this.getDealerNameById(w.dealerId) : this.$t("Anwesenheit").toString();
                        color = "success";
                    }

                    events.push({
                        start: new Date(dateString + "T" + w.from),
                        end: new Date(dateString + "T" + w.to),
                        timed: true,
                        color,
                        name,
                        entry: w,
                    });
                });
            });

            return events;
        },

        calendarLocale(): string {
            return userSession.locale;
        },

        calendarNow(): string {
            return moment(now()).format("YYYY-MM-DD HH:mm:00");
        },

        calendarWeekdays(): number[] {
            return this.daysOfWeek.map((d: SelectOption) => Number.parseInt(d.value!));
        },

        daysOfWeek(): SelectOption[] {
            return getDaysOfWeek(configStore.configuration.defaultLocale, userSession.locale);
        },

        firstDayOfWeek(): number {
            return Number.parseInt(this.daysOfWeek[0].value!);
        },

        isConfirmed(): boolean {
            for (const confirmedWeek of this.weekWorkingHours!.confirmedWeeks) {
                if (confirmedWeek === this.week.monday) {
                    return true;
                }
            }

            return false;
        },

        mainDealerTimeZone(): string {
            return this.getDealerTimeZoneById(this.user!.mainDealerId);
        },

        profileImageUrl(): string {
            if (!this.user.profileImageHash) {
                return "/img/user_placeholder.png";
            }

            return usersApi.generateProfileImageLink(this.user.id, this.user.profileImageHash);
        },

        statusIcon(): string | null {
            if (!this.user) {
                return null;
            }

            if (!this.user.status.online) {
                return "mdi-circle-outline";
            } else if (this.user.away) {
                return "mdi-circle";
            }

            return "mdi-circle";
        },

        statusIconColor(): string | null {
            if (!this.user) {
                return null;
            }

            if (!this.user.status.online) {
                return "";
            } else if (this.user.away) {
                return "warning";
            }

            return "success";
        },

        statusIconTooltip(): string | null {
            if (!this.user) {
                return null;
            }

            if (!this.user.status.online) {
                return this.$t("offline") as string;
            } else if (this.user.away) {
                if (this.user.away.type === AwayType.OTHER) {
                    if (this.user.away.notes) {
                        return this.user.away.notes;
                    } else {
                        return this.$t("abwesend") as string;
                    }
                } else {
                    if (this.user.away.notes) {
                        return `${this.$t(`enum.AwayType.${this.user.away.type}`)} / ${this.user.away.notes}`;
                    } else {
                        return this.$t(`enum.AwayType.${this.user.away.type}`) as string;
                    }
                }
            }

            return this.$t("online") as string;
        },

        user(): UserSummaryWithStatus {
            const userDirectoryEntry = usersStore.getUserById(this.userId)!;
            return {
                ...this.apiUserSummary!,
                ...userDirectoryEntry,
            };
        },

        week(): Week {
            return this.getMainDealerWeek(this.weekOffset);
        },

        weekWorkingHoursTemplateId(): string | null {
            for (const customWeek of this.weekWorkingHours!.customWeeks) {
                if (customWeek.mondayOfWeek === this.week.monday) {
                    return customWeek.workingHoursTemplateId;
                }
            }

            return null;
        },

        weekWorkingHoursTemplate(): WorkingHoursTemplate | null {
            const workingHoursTemplateId = this.weekWorkingHoursTemplateId;

            for (const workingHoursTemplate of this.weekWorkingHours!.workingHoursTemplates) {
                if (workingHoursTemplateId && workingHoursTemplate.id === workingHoursTemplateId) {
                    return workingHoursTemplate;
                }

                if (!workingHoursTemplateId) {
                    if (workingHoursTemplate.defaultTemplate) {
                        return workingHoursTemplate;
                    }
                }
            }

            return null;
        },

        calendarNowIndicatorPosition(): string {
            const cal = this.$refs.workingHoursCalendar as any;

            if (!cal) {
                return "-10px";
            }

            return cal.timeToY(cal.times.now) + "px";
        },
    },

    methods: {
        canViewNumber(number: PhoneNumber): boolean {
            return number.publishable || userSession.hasPermission(Permission.VIEW_PRIVATE_PHONE_NUMBERS);
        },

        getCalendarEventColor(event: any): string {
            return event.color;
        },

        getDailyAbsences(dayOfWeek: number): Absence[] {
            const date = moment(this.getMainDealerDate(this.week, dayOfWeek));
            const dayStart = date.clone().startOf("day");
            const dayEnd = date.clone().endOf("day");

            return this.absences.filter((a) => {
                const from = moment(a.from).tz(this.mainDealerTimeZone);
                const to = a.to ? moment(a.to).tz(this.mainDealerTimeZone) : null;

                return from.isBefore(dayEnd) && (!to || to.isAfter(dayStart));
            });
        },

        getDealerNameById(dealerId: string): string {
            return dealersStore.dealerById(dealerId)?.name || this.$t("Unbekannter Standort").toString();
        },

        getDepartmentNameById(departmentId: string): string {
            return departmentsStore.departmentById(departmentId)?.name || (this.$t("Unbekannte Abteilung") as string);
        },

        getDealerTimeZoneById(dealerId: string): string {
            return dealersStore.dealerById(dealerId)!.timeZone;
        },

        getEntriesForDayOfWeek(
            workingHoursTemplate: WorkingHoursTemplate | null,
            dayOfWeek: number,
            includeAbsences: boolean
        ): WorkingEntry[] {
            let result = [] as WorkingEntry[];
            const absences = includeAbsences ? this.getDailyAbsences(dayOfWeek) : [];
            const date = this.getMainDealerDate(this.week, dayOfWeek);
            const dateString = getDate(date, this.mainDealerTimeZone);
            const workingHoursTemplateEntries = workingHoursTemplate
                ? workingHoursTemplate.workingHoursTemplateEntries
                : [];

            for (const workingHoursTemplateEntry of workingHoursTemplateEntries) {
                if (workingHoursTemplateEntry.dayOfWeek !== dayOfWeek) {
                    continue;
                }

                let entries = [
                    {
                        dealerId: workingHoursTemplateEntry.dealerId,
                        absence: null,
                        from: workingHoursTemplateEntry.from,
                        to: workingHoursTemplateEntry.to,
                    },
                ];

                absences.forEach((absence) => {
                    entries = entries
                        .map((e) => {
                            const from = toDateObject(this.mainDealerTimeZone, dateString, 0, e.from);
                            const to = toDateObject(this.mainDealerTimeZone, dateString, 0, e.to);

                            const updated = [];

                            if (
                                absence.to &&
                                this.isBetween(absence.from, from, to, true, true) &&
                                this.isBetween(absence.to, from, to, true, true)
                            ) {
                                // absence strictly in working hour, split entry

                                const l = cloneObject(e);
                                const r = cloneObject(e);

                                l.to = getTime(absence.from, this.mainDealerTimeZone);
                                r.from = getTime(absence.to, this.mainDealerTimeZone);

                                updated.push(l);
                                updated.push(r);
                            } else if (
                                this.isBetween(from, absence.from, absence.to) &&
                                this.isBetween(to, absence.from, absence.to)
                            ) {
                                // absence includes working hour, drop entry
                            } else if (this.isBetween(absence.from, from, to, false, true)) {
                                // absence starts in working hour, reduce end of entry
                                e.to = getTime(absence.from, this.mainDealerTimeZone);
                                updated.push(e);
                            } else if (absence.to && this.isBetween(absence.to, from, to, true, false)) {
                                // absence ends in working hour, postpone start of entry
                                e.from = getTime(absence.to, this.mainDealerTimeZone);
                                updated.push(e);
                            } else {
                                // absence and working hour are unrelated, keep entry
                                updated.push(e);
                            }

                            return updated;
                        })
                        .reduce((l, r) => l.concat(r), []);
                });

                result = result.concat(entries);
            }

            absences.forEach((absence) => {
                let from = getTime(absence.from, this.mainDealerTimeZone);
                let to = absence.to ? getTime(absence.to, this.mainDealerTimeZone) : "23:59:00";

                if (absence.from.getTime() <= date.getTime()) {
                    from = "00:00:00";
                }

                if (absence.to && getDate(absence.to, this.mainDealerTimeZone) !== dateString) {
                    to = "23:59:00";
                }

                result.push({
                    absence: absence,
                    dealerId: null,
                    from,
                    to,
                });
            });

            return result.sort((a, b) => {
                const result = a.from.localeCompare(b.from, userSession.locale);

                if (result !== 0 || !a.dealerId || !b.dealerId) {
                    return result;
                }

                return this.getDealerNameById(a.dealerId).localeCompare(
                    this.getDealerNameById(b.dealerId),
                    userSession.locale
                );
            });
        },

        getExternalAbsenceProviderById(externalAbsenceProviderId: string): ExternalAbsenceProvider | null {
            for (const externalAbsenceProvider of this.externalAbsenceProviders) {
                if (externalAbsenceProvider.id === externalAbsenceProviderId) {
                    return externalAbsenceProvider;
                }
            }

            return null;
        },

        getMainDealerDate(week: Week, dayOfWeek: number): Date {
            return toDateObject(this.mainDealerTimeZone, getDateByDayOfWeek(week, dayOfWeek) as string);
        },

        getMainDealerWeek(offset: number): Week {
            return getWeek(this.currentDate, offset, this.mainDealerTimeZone, configStore.configuration.defaultLocale);
        },

        getPhoneNumberTypeIcon(phoneNumberType: PhoneNumberType): string {
            if (phoneNumberType === PhoneNumberType.MOBILE) {
                return "mdi-cellphone";
            } else if (phoneNumberType === PhoneNumberType.LANDLINE) {
                return "mdi-deskphone";
            } else if (phoneNumberType === PhoneNumberType.FAX) {
                return "mdi-fax";
            }

            return "mdi-phone-classic";
        },

        getUserFullNameById(userId: string): string {
            return getFullName(usersStore.getUserById(userId)!);
        },

        isAbsenceActive(absence: Absence): boolean {
            return this.isActive(absence.from, absence.to);
        },

        isActive(start: Date, end: Date | null): boolean {
            return this.isBetween(new Date(), start, end);
        },

        isBetween(date: Date, start: Date, end: Date | null, strictStart?: boolean, strictEnd?: boolean) {
            const lb = strictStart ? start.getTime() < date.getTime() : start.getTime() <= date.getTime();
            const ub = !end || (strictEnd ? date.getTime() < end.getTime() : date.getTime() <= end.getTime());

            return lb && ub;
        },

        renderAbsenceTime(absence: Absence): string {
            return renderAbsenceTime(this.mainDealerTimeZone, absence);
        },

        renderLanguages(languages: string[]): string {
            if (!languages) {
                return "";
            }

            return languages
                .map((k) => this.$t(`language.${k}`) as string)
                .sort((a, b) => a.localeCompare(b, userSession.locale))
                .join(", ");
        },

        showCalendarEvent(e: CalendarClickDateEvent) {
            const open = () => {
                this.selectedEvent = e.event;
                this.selectedCalendarElement = e.nativeEvent.target;
                setTimeout(() => {
                    this.selectedEventOpen = true;
                }, 10);
            };

            if (this.selectedEventOpen) {
                this.selectedEventOpen = false;
                setTimeout(open, 10);
            } else {
                open();
            }

            e.nativeEvent.stopPropagation();
        },

        close() {
            this.$emit("close");
        },
    },

    watch: {
        weekOffset() {
            this.calendarDate = getDate(
                this.getMainDealerDate(this.week, this.firstDayOfWeek),
                this.mainDealerTimeZone
            );
        },
    },

    async mounted() {
        try {
            this.apiUserSummary = await usersApi.getUserSummaryById(this.userId);
            this.weekWorkingHours = await workingHoursApi.getByUser(this.userId);
            this.absences = await absencesApi.getByUser(this.userId);
            this.externalAbsenceProviders = await externalAbsenceProvidersApi.list();
        } finally {
            this.loading = false;
        }
    },

    components: {
        PhoneNumberLink,
    },
});
