import { getCountryCaption, getStateCaption } from "./countryStateUtils";

export interface GoogleMapsAddress {
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
}

function getUrlEncodedAddressString(address: GoogleMapsAddress, name?: string): string | null {
    const addressArray = [
        name,
        address.address1,
        address.address2,
        address.zip,
        address.city,
        address.state && address.country ? getStateCaption(address.country, address.state) : null,
        address.country ? getCountryCaption(address.country) : null,
    ];

    const addressString = addressArray
        .map((v) => (v ? v.trim() : ""))
        .filter((v) => !!v)
        .filter((v, pos, array) => pos === 0 || array[pos - 1] !== v)
        .reduce((l, r) => l + ", " + r, "");

    if (!addressString.length) {
        return null;
    }

    return encodeURIComponent(addressString.substr(2));
}

export function getGoogleMapsLink(address: GoogleMapsAddress, name?: string): string | null {
    const urlEncodedAddress = getUrlEncodedAddressString(address, name);

    if (!urlEncodedAddress) {
        return null;
    }

    return "https://www.google.com/maps/search/?api=1&query=" + urlEncodedAddress;
}
