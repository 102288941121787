
import { caseSearchApi, CaseSearchIndexSize } from "@/api/caseSearch";
import { contactSearchApi, ContactSearchIndexSize } from "@/api/contactSearch";
import { emergencyIncidentSearchApi, EmergencyIncidentSearchIndexSize } from "@/api/emergencyIncidentSearch";
import { financeContractSearchApi, FinanceContractSearchIndexSize } from "@/api/financeContractSearch";
import { inventorySearchApi, InventorySearchIndexSize } from "@/api/inventorySearch";
import { opportunitySearchApi, OpportunitySearchIndexSize } from "@/api/opportunitySearch";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            rebuildingCases: false,
            rebuildingContacts: false,
            rebuildingEmergencyIncidents: false,
            rebuildingInventory: false,
            rebuildingOpportunities: false,
            rebuildingFinanceContracts: false,
            caseIndexSize: null as CaseSearchIndexSize | null,
            contactIndexSize: null as ContactSearchIndexSize | null,
            emergencyIncidentIndexSize: null as EmergencyIncidentSearchIndexSize | null,
            inventoryIndexSize: null as InventorySearchIndexSize | null,
            opportunityIndexSize: null as OpportunitySearchIndexSize | null,
            financeContractIndexSize: null as FinanceContractSearchIndexSize | null,
        };
    },

    computed: {
        rebuilding(): boolean {
            return (
                this.rebuildingCases ||
                this.rebuildingContacts ||
                this.rebuildingEmergencyIncidents ||
                this.rebuildingInventory ||
                this.rebuildingOpportunities ||
                this.rebuildingFinanceContracts
            );
        },
    },

    methods: {
        getSizeText(
            searchIndexSize:
                | CaseSearchIndexSize
                | ContactSearchIndexSize
                | EmergencyIncidentSearchIndexSize
                | InventorySearchIndexSize
                | OpportunitySearchIndexSize
                | FinanceContractSearchIndexSize
                | null
        ): string | null {
            if (!searchIndexSize || searchIndexSize.actualSize === null) {
                return null;
            }

            const formattedActualSize = this.$tc(
                "keine Einträge | 1 Eintrag | {count} Einträge",
                searchIndexSize.actualSize,
                {
                    count: this.$n(searchIndexSize.actualSize),
                }
            );

            const delta = searchIndexSize.actualSize - searchIndexSize.expectedSize;

            if (!delta) {
                return formattedActualSize;
            }

            const formattedDelta = this.$tc("keine Einträge | 1 Eintrag | {count} Einträge", delta, {
                count: this.$n(delta),
            });

            return `${formattedActualSize} [${formattedDelta}]`;
        },

        async rebuildCases() {
            this.rebuildingCases = true;
            try {
                await caseSearchApi.rebuild();
                this.caseIndexSize = await caseSearchApi.size();
            } finally {
                this.rebuildingCases = false;
            }
        },

        async rebuildContacts() {
            this.rebuildingContacts = true;
            try {
                await contactSearchApi.rebuild();
                this.contactIndexSize = await contactSearchApi.size();
            } finally {
                this.rebuildingContacts = false;
            }
        },

        async rebuildEmergencyIncidents() {
            this.rebuildingEmergencyIncidents = true;
            try {
                await emergencyIncidentSearchApi.rebuild();
                this.emergencyIncidentIndexSize = await emergencyIncidentSearchApi.size();
            } finally {
                this.rebuildingEmergencyIncidents = false;
            }
        },

        async rebuildInventory() {
            this.rebuildingInventory = true;
            try {
                await inventorySearchApi.rebuild();
                this.inventoryIndexSize = await inventorySearchApi.size();
            } finally {
                this.rebuildingInventory = false;
            }
        },

        async rebuildOpportunities() {
            this.rebuildingOpportunities = true;
            try {
                await opportunitySearchApi.rebuild();
                this.opportunityIndexSize = await opportunitySearchApi.size();
            } finally {
                this.rebuildingOpportunities = false;
            }
        },

        async rebuildFinanceContracts() {
            this.rebuildingFinanceContracts = true;
            try {
                await financeContractSearchApi.rebuild();
                this.financeContractIndexSize = await financeContractSearchApi.size();
            } finally {
                this.rebuildingFinanceContracts = false;
            }
        },
    },

    async mounted() {
        this.caseIndexSize = await caseSearchApi.size();
        this.contactIndexSize = await contactSearchApi.size();
        this.emergencyIncidentIndexSize = await emergencyIncidentSearchApi.size();
        this.inventoryIndexSize = await inventorySearchApi.size();
        this.opportunityIndexSize = await opportunitySearchApi.size();
        this.financeContractIndexSize = await financeContractSearchApi.size();
    },

    components: {
        DealerGlobalContextHint,
    },
});
