
import { CarryDispoLocation } from "@/api/carryDispoSettings";
import CountryPicker from "@/app/components/CountryPicker.vue";
import StatePicker from "@/app/components/StatePicker.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { CarryDispoAssistantState } from "@/app/pages/carrydispo/assistant/carryDispoAssistant";
import { maxLength } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        carryDispoAssistantState: {
            type: Object as () => CarryDispoAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        locations: {
            type: Array as () => CarryDispoLocation[],
            default: () => [],
        },
    },

    data() {
        return {
            maxLength,
            preferredLocationId: null as string | null,
        };
    },

    computed: {
        preferredLocations(): SelectOptions {
            const options: SelectOptions = [];

            if (this.locations.length) {
                options.push(
                    { header: this.$t("Bevorzugte Adressen") },
                    ...this.locations.map((l) => ({
                        text: l.name,
                        value: l.id,
                    }))
                );
            }

            options.push({ header: this.$t("Standorte") }, ...dealerOptions());

            return options;
        },
    },

    methods: {
        setLocation(locationId: string | null) {
            this.$nextTick(() => {
                this.preferredLocationId = null;
            });

            if (!locationId) {
                return;
            }

            const dealer = dealersStore.dealerById(locationId);

            if (dealer) {
                this.carryDispoAssistantState.deliveryPointName = dealer.name;
                this.carryDispoAssistantState.deliveryPointDescription = dealer.address1;
                this.carryDispoAssistantState.deliveryPointStreet = dealer.address2;
                this.carryDispoAssistantState.deliveryPointHouseNumber = null;
                this.carryDispoAssistantState.deliveryPointZip = dealer.zip;
                this.carryDispoAssistantState.deliveryPointCity = dealer.city;
                this.carryDispoAssistantState.deliveryPointState = dealer.state;
                this.carryDispoAssistantState.deliveryPointCountry = dealer.country;
                this.carryDispoAssistantState.deliveryPointAdditionalInfo = null;
                return;
            }

            const location = this.locations.find((l) => l.id === locationId);

            if (location) {
                this.carryDispoAssistantState.deliveryPointName = location.name;
                this.carryDispoAssistantState.deliveryPointDescription = location.description;
                this.carryDispoAssistantState.deliveryPointStreet = location.street;
                this.carryDispoAssistantState.deliveryPointHouseNumber = location.houseNumber;
                this.carryDispoAssistantState.deliveryPointZip = location.zip;
                this.carryDispoAssistantState.deliveryPointCity = location.city;
                this.carryDispoAssistantState.deliveryPointState = location.state;
                this.carryDispoAssistantState.deliveryPointCountry = location.country;
                this.carryDispoAssistantState.deliveryPointAdditionalInfo = null;
                return;
            }
        },
    },

    components: {
        CountryPicker,
        StatePicker,
    },
});
