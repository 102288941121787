
import CasesDataTableRow from "./CasesDataTableRow.vue";
import { caseSearchApi, CaseSearchRequest, CaseSearchResult } from "@/api/caseSearch";
import { Permission } from "@/api/userSession";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { userSession } from "@/store/userSession";
import { PickMutable } from "@/util/types";
import Vue from "vue";

interface Item {
    selected: boolean;
    readonly caseSearchResult: CaseSearchResult;
}

export default Vue.extend({
    props: {
        canSelect: {
            type: Boolean,
            default: false,
        },
        canSelectPageSize: {
            type: Boolean,
            default: false,
        },
        itemsPerPage: {
            type: Number,
            default: 100,
        },
        multiselect: {
            type: Boolean,
            default: false,
        },
        myCases: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            default: false,
        },
        searchRequest: {
            type: Object as () => CaseSearchRequest,
            required: true,
        },
        selectDisabledIds: {
            type: Array as () => string[],
            required: false,
            default: () => [],
        },
        ultraCompactMode: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            items: [] as Item[],
            loading: true,
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                pageSizes: [this.itemsPerPage, this.itemsPerPage * 2.5, this.itemsPerPage * 5, this.itemsPerPage * 10],
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
        };
    },

    computed: {
        canViewAllCases(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_CASES);
        },

        headers(): DataTableHeader[] {
            const headers: DataTableHeader[] = [{ width: "30%" }, { width: "35%" }];

            if (!this.ultraCompactMode) {
                headers.push({ width: "35%" });
            }

            if (this.canSelect && !this.multiselect) {
                headers.unshift({ width: "1%" });
            }

            return headers;
        },

        selectableItems(): Item[] {
            return this.items.filter((item) => !this.selectDisabledIds.includes(item.caseSearchResult.caseId));
        },

        selectedCaseSearchResults(): CaseSearchResult[] {
            return this.items.filter((i) => i.selected).map((i) => i.caseSearchResult);
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const caseSearchResults = await caseSearchApi.search(
                    (this.paging.page - 1) * this.paging.pageSize,
                    this.paging.pageSize,
                    this.searchRequest,
                    ++this.searchCounter,
                    this.myCases
                );

                if (caseSearchResults.searchId === this.searchCounter) {
                    this.items = caseSearchResults.results.map((caseSearchResult) => ({
                        selected: false,
                        caseSearchResult,
                    }));
                    this.paging.totalSize = caseSearchResults.totalSize;
                    this.$emit("loaded", caseSearchResults.totalSize);
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        select(item: Item) {
            if (this.selectDisabledIds.includes(item.caseSearchResult.caseId)) {
                return;
            }

            item.selected = true;

            this.$emit("select", item.caseSearchResult);
        },

        selectAll() {
            for (const item of this.selectableItems) {
                this.select(item);
            }
        },

        unselect(item: Item) {
            if (this.selectDisabledIds.includes(item.caseSearchResult.caseId)) {
                return;
            }

            item.selected = false;

            this.$emit("unselect", item.caseSearchResult);
        },

        unselectAll() {
            for (const item of this.selectableItems) {
                this.unselect(item);
            }
        },
    },

    watch: {
        async searchRequest() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        selectedCaseSearchResults() {
            this.$emit("select:multiple", this.selectedCaseSearchResults);
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        CasesDataTableRow,
        DataTable,
    },
});
