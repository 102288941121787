
import { NumberType, Partner, PartnerForm, PartnerNumber, partnersApi, PartnerType } from "@/api/partners";
import { Permission } from "@/api/userSession";
import CountryPicker from "@/app/components/CountryPicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import StatePicker from "@/app/components/StatePicker.vue";
import { showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { email, maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
        };
    },

    computed: {
        api() {
            return partnersApi;
        },
        PartnerType() {
            return PartnerType;
        },
        NumberType() {
            return NumberType;
        },
        maxLength() {
            return maxLength;
        },
        notEmpty() {
            return notEmpty;
        },
        emailRule() {
            return email();
        },
        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },
    },

    methods: {
        async deletePartner(item: Partner) {
            if (
                await showConfirm(
                    this.$t("Partner löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Partner löschen möchten?") as string
                )
            ) {
                await partnersApi.delete(item.dealerId, item.id);
                return true;
            }
            return false;
        },

        async updateOrder(items: Partner[]) {
            await partnersApi.updateOrder(
                items[0].dealerId,
                items.map((d) => d.id)
            );
        },

        toForm(item: Partner): PartnerForm {
            return {
                ...item,
                state: item.state,
                country: item.country,
                numbers: item.numbers.map((n) => ({ ...n })),
            };
        },

        emptyForm(): PartnerForm {
            return {
                type: (null as unknown) as PartnerType,
                name: "",
                address1: "",
                address2: "",
                zip: "",
                city: "",
                state: null,
                country: null,
                numbers: [],
                emailAddress: "",
                notes: "",
            };
        },

        addPhoneNumber(numbers: PartnerNumber[]) {
            numbers.push({
                type: (null as unknown) as NumberType,
                number: "",
                label: "",
            });
        },

        removePhoneNumber(numbers: PartnerNumber[], index: number) {
            numbers.splice(index, 1);
        },

        phoneNumberTypeIcon(numberType: NumberType) {
            if (numberType === NumberType.MOBILE) {
                return "mdi-cellphone";
            } else if (numberType === NumberType.LANDLINE) {
                return "mdi-deskphone";
            } else if (numberType === NumberType.FAX) {
                return "mdi-fax";
            }
            return "mdi-phone-classic";
        },
    },

    components: {
        CrudPage,
        CountryPicker,
        EnumField,
        PhoneNumberField,
        StatePicker,
        DealerContextGuard,
    },
});
