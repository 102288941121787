
import OpportunitiesDataTableRow from "./OpportunitiesDataTableRow.vue";
import { opportunitiesApi, OpportunityResult } from "@/api/opportunities";
import { Permission } from "@/api/userSession";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { userSession } from "@/store/userSession";
import { PickMutable } from "@/util/types";
import Vue from "vue";

interface Item {
    readonly opportunityId: string;
    readonly opportunityResult: OpportunityResult | null;
}

export default Vue.extend({
    props: {
        itemsPerPage: {
            type: Number,
            default: 25,
        },
        opportunityIds: {
            type: Array as () => string[],
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            items: [] as Item[],
            loading: true,
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                totalSize: this.opportunityIds.length,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchId: 1,
        };
    },

    computed: {
        canViewAllOpportunities(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES);
        },

        canViewOpportunities(): boolean {
            return this.canViewAllOpportunities || userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES);
        },

        headers(): DataTableHeader[] {
            return [{ width: "1%" }, { width: "30%" }, { width: "34%" }, { width: "35%" }];
        },

        pageOpportunityIds(): string[] {
            const start = (this.paging.page - 1) * this.itemsPerPage;

            return this.opportunityIds.slice(start, start + this.itemsPerPage);
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            const pageOpportunityIds = this.pageOpportunityIds;
            const searchId = ++this.searchId;

            try {
                const opportunityResults = this.canViewOpportunities
                    ? await opportunitiesApi.getByIds(pageOpportunityIds)
                    : [];

                const items: Item[] = pageOpportunityIds.map((opportunityId) => ({
                    opportunityId,
                    opportunityResult: opportunityResults.find((or) => or.opportunity.id === opportunityId) ?? null,
                }));

                if (searchId === this.searchId) {
                    this.items = items;
                    this.paging.totalSize = this.opportunityIds.length;
                    this.loading = false;
                }
            } catch (e) {
                if (searchId === this.searchId) {
                    this.loading = false;
                    this.paging.page = 1;
                }
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },
    },

    watch: {
        async opportunityIds() {
            this.paging.page = 1;
            this.paging.totalSize = this.opportunityIds.length;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DataTable,
        OpportunitiesDataTableRow,
    },
});
