
import {
    Contact,
    ContactForm,
    ContactSalutation,
    contactsApi,
    ContactSource,
    ContactStatus,
    ContactType,
    NumberType,
} from "@/api/contacts";
import BirthdayPicker from "@/app/components/BirthdayPicker.vue";
import CountryPicker from "@/app/components/CountryPicker.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import StatePicker from "@/app/components/StatePicker.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

const emptyContactForm: ContactForm = {
    source: ContactSource.UI,
    dealerId: null,
    status: null,
    type: null,
    salutation: null,
    knownSince: null,
    dateOfBirth: null,
    companyName: "",
    position: "",
    department: "",
    namePrefix: "",
    givenName: "",
    middleName: "",
    familyName: "",
    nameSuffix: "",
    address1: "",
    address2: "",
    zip: "",
    city: "",
    state: null,
    country: null,
    numbers: [],
    emailAddresses: [],
    website: "",
    notes: "",
    url: null,
    externalReferences: [],
};

export default Vue.extend({
    props: {
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
    },

    data() {
        return {
            isWorking: false,
            maxLength,
            contactForm: this.contact
                ? (cloneObject(this.contact.contactData) as ContactForm)
                : cloneObject({
                      ...emptyContactForm,
                      dealerId: userSession.dealerId,
                      country: configStore.configuration.defaultCountry,
                  }),
            ContactStatus,
            ContactType,
            ContactSalutation,
            emailRules: notEmpty().email(),
            numberRules: notEmpty().e164(),
        };
    },

    computed: {
        phoneNumberTypes(): SelectOptions {
            return Object.keys(NumberType).map((k) => ({ value: k, text: this.$t(`enum.NumberType.${k}`) }));
        },

        defaultCountry(): string {
            return this.contactForm.country ? this.contactForm.country : configStore.configuration.defaultCountry;
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        dealerOptions() {
            return dealerOptions();
        },
    },

    methods: {
        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            try {
                if (this.contact) {
                    await contactsApi.editContact(this.contact.id, this.contactForm);
                    this.$emit("success");
                } else {
                    const contactId = await contactsApi.addContact(this.contactForm);
                    this.$emit("success", contactId);
                }
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },

        addPhoneNumber() {
            this.contactForm.numbers.push({
                type: NumberType.LANDLINE,
                number: "",
                label: "",
            });
        },

        removePhoneNumber(index: number) {
            this.contactForm.numbers.splice(index, 1);
        },

        addEmailAddress() {
            this.contactForm.emailAddresses.push({
                address: "",
                label: "",
            });
        },

        removeEmailAddress(index: number) {
            this.contactForm.emailAddresses.splice(index, 1);
        },
    },

    components: {
        EnumField,
        DatePicker,
        BirthdayPicker,
        StatePicker,
        CountryPicker,
        PhoneNumberField,
    },
});
