import { Note } from "@/api/notes";
import { $t } from "@/app/i18n";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";

export function getCreator(note: Note): string {
    if (note.creatorCtUserId) {
        return $t("Externer Agent") as string;
    } else if (note.creatorUserId) {
        return (getFullName(usersStore.getUserById(note.creatorUserId)) || $t("Unbekannt")) as string;
    } else {
        return $t("System") as string;
    }
}
