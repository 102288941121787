
import { getContactDetailsLabel, getContactDetailsOptions } from "@/app/pages/contacts/preferredContact";
import { OpportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import { getSentimentIcon, getSentimentIconColor, getSentimentText, SENTIMENTS } from "@/app/sentimentUtils";
import { notEmpty } from "@/app/validation";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        opportunityAssistantState: {
            type: Object as () => OpportunityAssistantState,
            required: true,
        },
    },

    data() {
        return {
            notEmpty,
            sentimentRules: userSession.isCtUser() ? notEmpty() : [],
        };
    },

    computed: {
        contactDetailsLabel(): string | null {
            return getContactDetailsLabel(this.opportunityAssistantState.preferredContact.preferredContactMethod);
        },

        contactDetailsOptions(): SelectOption[] {
            return getContactDetailsOptions(
                this.opportunityAssistantState.preferredContact.preferredContactMethod,
                this.opportunityAssistantState.contactData?.emailAddresses ?? [],
                this.opportunityAssistantState.contactData?.numbers ?? []
            );
        },

        sentimentItems() {
            return [...SENTIMENTS, null].map((s) => ({
                value: s,
                icon: getSentimentIcon(s),
                iconColor: getSentimentIconColor(s),
                text: getSentimentText(s),
            }));
        },

        sourceOptions() {
            return opportunitySourcesStore.opportunitySources.map((s) => ({ value: s.id, text: s.name }));
        },
    },

    methods: {
        isSentimentSelected(sentiment: number | null) {
            return this.opportunityAssistantState.sentiment === sentiment;
        },

        selectSentiment(sentiment: number | null) {
            this.opportunityAssistantState.sentiment = sentiment;
        },
    },
});
