import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface CaseActionResult {
    readonly timeZone: string;
    readonly locale: string;
    readonly userFullName: string;
    readonly darkmode: boolean;
    readonly caseId: string | null;
    readonly hasCaseChanged: boolean | null;
    readonly closed: Date | null;
    readonly wasClosedBySelf: boolean | null;
    readonly closerFullName: string | null;
    readonly isCloserUser: boolean | null;
    readonly isCloserCtUser: boolean | null;
}

export interface OpportunityActionResult {
    readonly timeZone: string;
    readonly locale: string;
    readonly userFullName: string;
    readonly darkmode: boolean;
    readonly opportunityId: string | null;
    readonly hasOpportunityChanged: boolean | null;
    readonly closed: Date | null;
    readonly wasClosedBySelf: boolean | null;
    readonly closerFullName: string | null;
    readonly isCloserUser: boolean | null;
    readonly isCloserCtUser: boolean | null;
}

interface UserActionLinkApi {
    closeCase(ualt: string): Promise<CaseActionResult>;
    closeOpportunity(ualt: string): Promise<OpportunityActionResult>;
    changePassword(password: string, ualt: string): Promise<void>;
}

export const userActionLinkApi: UserActionLinkApi = {
    async closeCase(ualt) {
        const formData = new FormData();
        formData.append("ualt", ualt);
        return cloneObject((await axios.post("/api/user-action-link/_close-case", formData)).data);
    },

    async closeOpportunity(ualt) {
        const formData = new FormData();
        formData.append("ualt", ualt);
        return cloneObject((await axios.post("/api/user-action-link/_close-opportunity", formData)).data);
    },

    async changePassword(password, ualt) {
        const formData = new FormData();
        formData.append("password", password);
        formData.append("ualt", ualt);
        await axios.post("/api/user-action-link/_change-password", formData);
    },
};
