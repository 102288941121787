
import { emailDomainsApi } from "@/api/emailDomains";
import { NotFound } from "@/api/errors";
import {
    IncomingEmail,
    IncomingEmailAttachment,
    IncomingEmailReceiver,
    IncomingEmailReceiverType,
    incomingEmailsApi,
    IssueId,
} from "@/api/incomingEmails";
import { isIncomingEmailUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { canReportSpam, ReplyToBaseEmail, writeHtmlToIframe } from "@/app/emailUtils";
import { isIllegalReceiverEmailAddress } from "@/app/issueUtils";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

enum HtmlBodyError {
    LOADING_HTML_BODY_FAILED = "LOADING_HTML_BODY_FAILED",
    HTML_BODY_EMPTY = "HTML_BODY_EMPTY",
    WRITING_HTML_BODY_TO_IFRAME_FAILED = "WRITING_HTML_BODY_TO_IFRAME_FAILED",
}

export default Vue.extend({
    props: {
        canSendEmail: {
            type: Boolean,
            default: false,
        },
        canViewContent: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        incomingEmail: {
            type: Object as () => IncomingEmail,
            required: true,
        },
    },

    data() {
        return {
            htmlBody: null as string | null,
            htmlBodyError: null as HtmlBodyError | null,
            htmlBodyLoaded: false,
            image: "",
            incomingEmailProp: this.incomingEmail,
            loading: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            reportingSpam: false,
            writing: false,
        };
    },

    computed: {
        canReplyToEmail(): boolean {
            return (
                this.canSendEmail &&
                !isIllegalReceiverEmailAddress(
                    this.replyToAddress ? this.replyToAddress : this.incomingEmail.fromAddress
                )
            );
        },

        canReportSpam(): boolean {
            return canReportSpam(this.incomingEmail);
        },

        creationTimestamp(): string {
            return formatInstant(this.incomingEmailProp.created, userSession.timeZone, userSession.locale, "S");
        },

        bccReceivers(): IncomingEmailReceiver[] {
            return this.incomingEmailProp.receivers.filter((r) => r.type === IncomingEmailReceiverType.BCC);
        },

        ccReceivers(): IncomingEmailReceiver[] {
            return this.incomingEmailProp.receivers.filter((r) => r.type === IncomingEmailReceiverType.CC);
        },

        htmlBodyErrorColor(): string | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return "error";
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return "info";
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return "error";
            } else {
                return null;
            }
        },

        htmlBodyErrorRetry(): Function | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return () => this.loadHtmlBody();
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return () => this.loadHtmlBody();
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return () => this.writeHtmlBodyToIframe();
            } else {
                return null;
            }
        },

        htmlBodyErrorRetrying(): boolean {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return this.loading;
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return this.loading;
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return this.writing;
            } else {
                return false;
            }
        },

        htmlBodyErrorText(): string | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return this.$t("Es ist ein Fehler beim Laden der E-Mail aufgetreten.") as string;
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return this.$t("Der Inhalt der E-Mail wurde nicht gefunden.") as string;
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return this.$t("Es ist ein Fehler beim Darstellen der E-Mail aufgetreten.") as string;
            } else {
                return null;
            }
        },

        issueId(): IssueId | null {
            if (this.incomingEmailProp.caseId) {
                return { caseId: this.incomingEmailProp.caseId };
            } else if (this.incomingEmailProp.opportunityId) {
                return { opportunityId: this.incomingEmailProp.opportunityId };
            } else {
                return null;
            }
        },

        replyToAddress(): string | null {
            const processContentEmailAddresses = this.incomingEmail.processContentEmailAddresses.filter(
                (emailAddress) =>
                    configStore.configuration.incomingEmailPseudonymizedContactEmailAddressPatterns.every(
                        (pattern) => !emailAddress.match(new RegExp(pattern, "i"))
                    )
            );

            if (processContentEmailAddresses.length) {
                return processContentEmailAddresses[0];
            } else {
                return this.incomingEmail.replyToAddress;
            }
        },

        replyToName(): string | null {
            if (this.incomingEmail.replyToAddress === this.replyToAddress) {
                return this.incomingEmail.replyToName;
            } else {
                return null;
            }
        },

        spamReportedTimestamp(): string | null {
            if (!this.incomingEmailProp.spamReported) {
                return null;
            }

            return formatInstant(this.incomingEmailProp.spamReported, userSession.timeZone, userSession.locale, "S");
        },

        toReceivers(): IncomingEmailReceiver[] {
            return this.incomingEmailProp.receivers.filter((r) => r.type === IncomingEmailReceiverType.TO);
        },
    },

    methods: {
        downloadEmail() {
            window.open(
                incomingEmailsApi.generateEmailDownloadLink(
                    this.incomingEmailProp.id,
                    this.incomingEmailProp.rawEmailHash,
                    this.issueId!
                )
            );
        },

        downloadEmailForm() {
            window.open(incomingEmailsApi.generateEmailFormDownloadLink(this.incomingEmailProp.id, this.issueId!));
        },

        async loadHtmlBody() {
            if (this.htmlBody !== null) {
                return;
            }

            this.loading = true;

            try {
                this.htmlBody = await incomingEmailsApi.getHtmlBody(this.incomingEmailProp.id, this.issueId!);
                this.htmlBodyError = !this.htmlBody ? HtmlBodyError.HTML_BODY_EMPTY : null;
            } catch (e) {
                this.htmlBodyError = HtmlBodyError.LOADING_HTML_BODY_FAILED;

                if (!(e instanceof NotFound)) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            } finally {
                this.loading = false;
            }

            if (this.htmlBody !== null) {
                this.$nextTick(() => {
                    this.writeHtmlBodyToIframe();
                });
            }
        },

        async loadHtmlBodyOnIntersect() {
            if (this.htmlBodyError !== null) {
                return;
            }

            await this.loadHtmlBody();
        },

        async reportSpam() {
            this.reportingSpam = true;
            try {
                const spamReported = await incomingEmailsApi.reportSpam(this.incomingEmailProp.id);

                this.incomingEmailProp = { ...this.incomingEmailProp, spamReported };
            } finally {
                this.reportingSpam = false;
            }
        },

        async reply(replyToReceivers: boolean) {
            const transactionEmailHost = configStore.configuration.transactionEmailHost.toLowerCase();
            const receivers = this.incomingEmail.receivers.filter(
                (r) => !r.address.toLowerCase().endsWith(`@${transactionEmailHost}`)
            );

            const internalDomains: string[] = receivers.length
                ? (await emailDomainsApi.getAllIds()).map((internalDomain) => internalDomain.toLowerCase())
                : [];

            const getReceiversByType = (type: IncomingEmailReceiverType) =>
                receivers
                    .filter((r) => r.type === type)
                    .filter((r) => !internalDomains.includes(r.address.split("@")[1].toLowerCase()))
                    .map((r) => ({ name: r.name, address: r.address }));

            const email: ReplyToBaseEmail = {
                id: this.incomingEmail.id,
                fromName: this.incomingEmail.fromName,
                fromAddress: this.incomingEmail.fromAddress,
                replyToName: this.replyToName,
                replyToAddress: this.replyToAddress,
                toReceivers: replyToReceivers ? getReceiversByType(IncomingEmailReceiverType.TO) : [],
                ccReceivers: replyToReceivers ? getReceiversByType(IncomingEmailReceiverType.CC) : [],
                bccReceivers: replyToReceivers ? getReceiversByType(IncomingEmailReceiverType.BCC) : [],
                subject: this.incomingEmail.subject,
                htmlBodyForQuote: await incomingEmailsApi.getHtmlBody(this.incomingEmailProp.id, this.issueId!, true),
                headers: this.incomingEmail.headers,
            };

            this.$emit("send-reply", email);
        },

        showAttachment(attachment: IncomingEmailAttachment, download: boolean) {
            const fname = attachment.filename.toLowerCase();
            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.image = incomingEmailsApi.generateAttachmentLink(
                    this.incomingEmailProp.id,
                    attachment,
                    this.issueId!
                );
            } else {
                window.open(
                    incomingEmailsApi.generateAttachmentLink(
                        this.incomingEmailProp.id,
                        attachment,
                        this.issueId!,
                        download
                    )
                );
            }
        },

        writeHtmlBodyToIframe() {
            if (!this.htmlBody) {
                return;
            }

            this.writing = true;
            try {
                const success = writeHtmlToIframe(this.$refs.iframe as HTMLIFrameElement, this.htmlBody);

                this.htmlBodyError = !success ? HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED : null;
            } catch (e) {
                this.htmlBodyError = HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED;

                this.$nextTick(() => {
                    throw e;
                });
            } finally {
                this.writing = false;
            }
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isIncomingEmailUpdatedNotification(n) && n.id === this.incomingEmailProp.id) {
                this.incomingEmailProp = {
                    ...this.incomingEmailProp,
                    caseId: n.caseId,
                    opportunityId: n.opportunityId,
                    spamReported: n.spamReported,
                    processingState: n.processingState,
                    processedAt: n.processedAt,
                };
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        TimeSpan,
        ImageCarousel,
    },
});
