
import OpportunityTradeInFormCard from "./OpportunityTradeInFormCard.vue";
import { Opportunity } from "@/api/opportunities";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import { renderUnit } from "@/app/filters";
import { renderPower } from "@/app/inventoryUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },

        canEdit: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            renderPower,
            renderUnit,
            tradeInFormVisible: false,
            expanded: null as boolean | null,
        };
    },

    computed: {
        contactVehicleFirstRegistration(): string | null {
            if (!this.opportunityProp.contactVehicleFirstRegistrationYear) {
                return null;
            }

            let date = this.opportunityProp.contactVehicleFirstRegistrationYear.toString();

            if (this.opportunityProp.contactVehicleFirstRegistrationMonth !== null) {
                date += "-" + this.opportunityProp.contactVehicleFirstRegistrationMonth.toString().padStart(2, "0");

                if (this.opportunityProp.contactVehicleFirstRegistrationDay !== null) {
                    date += "-" + this.opportunityProp.contactVehicleFirstRegistrationDay.toString().padStart(2, "0");
                }
            }

            return date;
        },

        hasContent(): boolean {
            return this.opportunityProp.tradeInRequested !== null || this.hasContactVehicleData;
        },

        hasContactVehicleData(): boolean {
            return (
                !!this.opportunityProp.contactVehicleLink ||
                !!this.opportunityProp.contactVehicleClass ||
                !!this.opportunityProp.contactVehicleMake ||
                !!this.opportunityProp.contactVehicleModel ||
                !!this.opportunityProp.contactVehicleModelDescription ||
                !!this.opportunityProp.contactVehicleLicensePlate ||
                !!this.opportunityProp.contactVehicleVin ||
                !!this.opportunityProp.contactVehicleModelYear ||
                this.opportunityProp.contactVehicleMileage !== null ||
                !!this.contactVehicleFirstRegistration ||
                this.opportunityProp.contactVehiclePower !== null ||
                !!this.opportunityProp.contactVehicleColor ||
                !!this.opportunityProp.contactVehicleTransmission ||
                !!this.opportunityProp.contactVehicleFeatures ||
                this.opportunityProp.contactVehiclePreviousOwners !== null ||
                this.opportunityProp.contactVehicleLoanRedemption !== null ||
                this.opportunityProp.contactVehiclePriceSuggestion !== null
            );
        },
    },

    watch: {
        hasContent() {
            if (this.expanded === null && this.hasContent) {
                // expand only if the user has not changed the expansion state manually
                this.expanded = true;
            }
        },
    },

    methods: {
        showEditForm() {
            this.tradeInFormVisible = true;
        },

        closeEditForm() {
            this.tradeInFormVisible = false;
        },
    },

    created() {
        this.expanded = this.hasContent || null;
    },

    components: {
        CopyToClipboardIcon,
        DToolbarCard,
        OpportunityTradeInFormCard,
    },
});
