import axios from "axios";

export interface CaseTypeGroupEntry {
    readonly name: string;
    readonly caseTypes: string[];
}

interface CaseTypesApi {
    getCaseTypes(): Promise<CaseTypeGroupEntry[]>;
}

export const caseTypesApi: CaseTypesApi = {
    async getCaseTypes() {
        return (await axios.get(`/api/case-types`)).data;
    },
};
