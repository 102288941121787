
import { ContactSearchResult, ContactVehicleSearchResult } from "@/api/contactSearch";
import { MileageUnit } from "@/api/contacts";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { renderContactName, renderFullName, renderPhoneNumberTypeIcon, renderVehicleTitle } from "@/app/contactUtils";
import { renderUnit } from "@/app/filters";
import PhoneNumberLink from "@/app/pages/PhoneNumberLink.vue";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        contactSearchResult: {
            type: Object as () => ContactSearchResult,
            required: true,
        },
    },

    data() {
        return {
            renderPhoneNumberTypeIcon,
            renderVehicleTitle,
        };
    },

    computed: {
        contactDealerName(): string | null {
            if (!this.contactSearchResult.dealerId) {
                return null;
            }

            return dealersStore.dealerById(this.contactSearchResult.dealerId)?.name ?? null;
        },

        formattedContactName(): string {
            return renderContactName(this.contactSearchResult);
        },

        formattedFullName(): string | null {
            return renderFullName(this.contactSearchResult) || null;
        },
    },

    methods: {
        getLastRecordMileage(cv: ContactVehicleSearchResult): string | null {
            if (!cv.lastRecordMileage) {
                return null;
            }

            return renderUnit(
                cv.lastRecordMileage,
                cv.lastRecordMileageUnit === MileageUnit.KM
                    ? "kilometer"
                    : cv.lastRecordMileageUnit === MileageUnit.MI
                    ? "mile"
                    : ""
            );
        },
    },

    components: {
        CopyToClipboardIcon,
        PhoneNumberLink,
    },
});
