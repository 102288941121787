
import PlaySound from "@/app/components/PlaySound.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: Number,
    },

    data() {
        return {
            internalValue: this.value * 100,
        };
    },

    computed: {
        icon() {
            if (this.internalValue === 0) {
                return "mdi-volume-variant-off";
            }
            if (this.internalValue < 20) {
                return "mdi-volume-low";
            }
            if (this.internalValue < 80) {
                return "mdi-volume-medium";
            }
            return "mdi-volume-high";
        },
    },

    methods: {
        play() {
            (this.$refs.audio as any).play();
        },

        pause() {
            (this.$refs.audio as any).pause();
            this.$emit("input", this.internalValue / 100);
        },
    },

    components: {
        PlaySound,
    },
});
