import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface ServiceAccountForm {
    readonly name: string;
    readonly readOnly: boolean;
}

export interface ServiceAccount {
    readonly id: string;
    readonly created: Date;
    readonly name: string;
    readonly readOnly: boolean;
    readonly subscriptionIds: string[];
}

interface ServiceAccountsApi {
    serviceAccounts(): Promise<ServiceAccount[]>;
    addServiceAccount(serviceAccountForm: ServiceAccountForm): Promise<string>;
    deleteServiceAccount(id: string): Promise<void>;
    deleteServiceAccountSubscription(serviceAccountId: string, subscriptionId: string): Promise<void>;
}

export const serviceAccountsApi: ServiceAccountsApi = {
    async serviceAccounts() {
        return cloneObject((await axios.get("/api/service-accounts")).data);
    },

    async addServiceAccount(serviceAccountForm) {
        return (
            await axios.post("/api/service-accounts", serviceAccountForm, {
                headers: { Accept: "text/plain" },
            })
        ).data;
    },

    async deleteServiceAccount(id) {
        await axios.delete(`/api/service-accounts/${id}`);
    },

    async deleteServiceAccountSubscription(serviceAccountId, subscriptionId) {
        await axios.delete(`/api/service-accounts/${serviceAccountId}/${subscriptionId}`);
    },
};
