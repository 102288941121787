import { changelogsApi } from "@/api/changelogs";
import { isChangelogNotification, notificationEventSource } from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class ChangelogsStore {
    private refreshLimiter = new ActionLimiter(true);
    private unreadCount_ = 0;

    get unreadCount() {
        return this.unreadCount_;
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.unreadCount_ = await changelogsApi.getUnreadChangelogCount();
        });
    }
}

export const changelogsStore = new ChangelogsStore();

notificationEventSource.addDataHandler((n) => {
    if (isChangelogNotification(n)) {
        changelogsStore.refresh();
    }
});
