
import { incomingEmailsOverviewState } from "./incomingEmailsOverview";
import { Contact } from "@/api/contacts";
import {
    IncomingEmail,
    IncomingEmailAndContact,
    incomingEmailsApi,
    IncomingEmailSearchOrder,
    IssueType,
    ProcessingState,
} from "@/api/incomingEmails";
import { isIncomingEmailUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EmailAddressesField from "@/app/components/EmailAddressesField.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { renderContactCaption } from "@/app/contactUtils";
import { downloadUrl } from "@/app/fileUtils";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import { PickMutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const itemsPerPage = 100;

        return {
            incomingEmailsOverviewState: incomingEmailsOverviewState,
            IncomingEmailSearchOrder,
            IssueType,
            items: [] as IncomingEmailAndContact[],
            itemsPerPage,
            loading: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            ProcessingState,
            searchCounter: 1,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("E-Mail"),
                    width: "40%",
                },
                {
                    text: this.$t("Absender und Empfänger"),
                    width: "30%",
                },
                {
                    text: this.$t("Kontakt, Vorgang und Betreff"),
                    width: "30%",
                },
            ];
        },
    },

    methods: {
        getContactCaption(contact: Contact | null): string | null {
            if (!contact) {
                return null;
            }

            return renderContactCaption(contact.contactData);
        },

        getStatusCaption(incomingEmail: IncomingEmail) {
            return this.$t(`enum.ProcessingState.${incomingEmail.processingState}`);
        },

        getStatusColor(processingState: ProcessingState) {
            if (processingState === ProcessingState.UNPROCESSED) {
                return null;
            } else if (processingState === ProcessingState.UNPROCESSED_IGNORE_FILTER) {
                return null;
            } else if (processingState === ProcessingState.PROCESSED) {
                return "success";
            } else if (processingState === ProcessingState.PROCESS_MANUALLY) {
                return "warning";
            } else if (processingState === ProcessingState.FILTERED) {
                return "error";
            } else {
                return null;
            }
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        isContactDeleted(contact: Contact | null): boolean {
            return !!contact?.deleted;
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const incomingEmailSearchResults = await incomingEmailsApi.search(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    this.incomingEmailsOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (incomingEmailSearchResults.searchId === this.searchCounter) {
                    this.items = incomingEmailSearchResults.results;
                    this.paging.totalSize = incomingEmailSearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        async reactivate(incomingEmail: IncomingEmail) {
            await incomingEmailsApi.reactivate(incomingEmail.id);
        },

        refresh() {
            this.incomingEmailsOverviewState.refresh();
        },

        exportRawEmails() {
            downloadUrl(incomingEmailsApi.generateRawEmailsLink(this.incomingEmailsOverviewState.searchRequest));
        },
    },

    watch: {
        async "incomingEmailsOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        if (!this.incomingEmailsOverviewState.initialized) {
            this.incomingEmailsOverviewState.setTodayQuickfilter();
        }

        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isIncomingEmailUpdatedNotification(n)) {
                this.items = this.items.map((item) =>
                    item.incomingEmail.id === n.id
                        ? {
                              ...item,
                              incomingEmail: {
                                  ...item.incomingEmail,
                                  caseId: n.caseId,
                                  opportunityId: n.opportunityId,
                                  spamReported: n.spamReported,
                                  processingState: n.processingState,
                                  processedAt: n.processedAt,
                              },
                          }
                        : item
                );
            }
        });

        await this.loadItems();
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        DataTable,
        DatePicker,
        EmailAddressesField,
        EnumField,
        IconWithTooltip,
    },
});
