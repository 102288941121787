export interface Node {
    readonly type: "LINK" | "TEXT";
    readonly value: string;
}

function getUniqueLinks(content: string): string[] {
    const links = content.match(/(^|\s)(https?:\/\/|www\.)\S+/gim);

    if (!links || links.length === 0) {
        return [];
    }

    return links
        .map((link) => link.trim())
        .filter((link) => {
            try {
                return new URL(link.startsWith("www.") ? "http://" + link : link);
            } catch (error) {
                return false;
            }
        })
        .sort((a, b) => a.localeCompare(b))
        .filter((link, index, array) => !index || array[index - 1] !== link);
}

function convertLinkOccurrencesInNodeToLinkNodes(node: Node, link: string): Node[] {
    if (node.type === "LINK") {
        return [node];
    } else if (node.value === link) {
        return [{ type: "LINK", value: link }];
    }

    const parts = node.value.split(link).map((s) => s.trim());

    if (parts.length === 1) {
        // text node does not contain link
        return [node];
    }

    // insert link node between each consecutive text nodes
    const linkNode: Node = { type: "LINK", value: link };

    return parts
        .map((value) => ({ type: "TEXT", value } as Node))
        .map((textNode, index) => (index ? [linkNode, textNode] : [textNode]))
        .reduce((prev, cur) => prev.concat(cur), []);
}

function convertLinkOccurrencesInNodesToLinkNodes(nodes: Node[], link: string): Node[] {
    return nodes
        .map((node) => convertLinkOccurrencesInNodeToLinkNodes(node, link))
        .reduce((prev, cur) => prev.concat(cur), []);
}

export function getNodes(value: string): Node[] {
    return getUniqueLinks(value)
        .reduce((nodes, link) => convertLinkOccurrencesInNodesToLinkNodes(nodes, link), [
            { type: "TEXT", value },
        ] as Node[])
        .filter((n) => n.value.length);
}
