
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import { getSentimentIcon, getSentimentIconColor, getSentimentText, SENTIMENTS } from "@/app/sentimentUtils";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        sentimentItems() {
            return [...SENTIMENTS, null].map((s) => ({
                value: s,
                icon: getSentimentIcon(s),
                iconColor: getSentimentIconColor(s),
                text: getSentimentText(s),
            }));
        },

        sentimentRules() {
            return userSession.isCtUser() ? notEmpty() : [];
        },
    },
});
