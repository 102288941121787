
import { CallStatus, OutgoingCall, outgoingCallsApi } from "@/api/outgoingCalls";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { outgoingCallsStore } from "@/store/outgoingCalls";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        outgoingCall: {
            type: Object as () => OutgoingCall,
            required: true,
        },
    },

    data() {
        return {
            cancelling: false,
            releasing: false,
        };
    },

    computed: {
        statusText(): string {
            if (this.outgoingCall.callStatus === CallStatus.INIT) {
                return this.$t("Verbindung zu Ihnen wird aufgebaut unter {0}.", [
                    parseAndFormatNumber(this.outgoingCall.inboundNumber, "INTERNATIONAL"),
                ]) as string;
            }
            if (this.outgoingCall.callStatus === CallStatus.RINGING_INBOUND) {
                return this.$t("Es klingelt bei Ihnen unter {0}.", [
                    parseAndFormatNumber(this.outgoingCall.inboundNumber, "INTERNATIONAL"),
                ]) as string;
            }
            if (this.outgoingCall.callStatus === CallStatus.CONNECTING) {
                return this.$t("Sie werden verbunden mit {0}.", [
                    parseAndFormatNumber(this.outgoingCall.outboundNumber, "INTERNATIONAL"),
                ]) as string;
            }
            if (this.outgoingCall.callStatus === CallStatus.RINGING_OUTBOUND) {
                return this.$t("Es klingelt bei {0}.", [
                    parseAndFormatNumber(this.outgoingCall.outboundNumber, "INTERNATIONAL"),
                ]) as string;
            }
            if (this.outgoingCall.callStatus === CallStatus.IN_PROGRESS) {
                return this.$t("Gesprächsverbindung mit {0} ist aufgebaut.", [
                    parseAndFormatNumber(this.outgoingCall.outboundNumber, "INTERNATIONAL"),
                ]) as string;
            }

            return "";
        },

        connectionEstablished(): boolean {
            return this.outgoingCall.callStatus === CallStatus.IN_PROGRESS;
        },
    },

    methods: {
        async cancelCall(id: string) {
            this.cancelling = true;
            await outgoingCallsApi.cancelCall(id);
        },

        async releaseCall() {
            this.releasing = true;
            await outgoingCallsApi.releaseCall(this.outgoingCall.id);
            outgoingCallsStore.handleUpdate({ ...this.outgoingCall, released: new Date() });
        },
    },

    components: {
        TimeSpan,
    },
});
