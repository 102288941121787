
import { inventoryImportApi } from "@/api/inventoryImport";
import { inventoryVehicleDuplicatesApi } from "@/api/inventoryVehicleDuplicates";
import { Permission } from "@/api/userSession";
import { showConfirm } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loadingNonDeletedInventoryVehiclesWithoutSourceCount: true,
            markingAsDeleted: false,
            nonDeletedInventoryVehiclesWithoutSourceCount: null as number | null,
            removingDuplicatesOfAllInventoryVehicles: false,
            removingDuplicatesOfInventoryVehiclesWithoutSource: false,
        };
    },

    computed: {
        canManageInventoryImport(): boolean {
            return userSession.hasPermission(Permission.MANAGE_INVENTORY_IMPORT);
        },
    },

    methods: {
        async loadNonDeletedInventoryVehiclesWithoutSourceCount() {
            this.loadingNonDeletedInventoryVehiclesWithoutSourceCount = true;
            try {
                this.nonDeletedInventoryVehiclesWithoutSourceCount = await inventoryImportApi.getNonDeletedInventoryVehiclesWithoutSourceCount();
            } finally {
                this.loadingNonDeletedInventoryVehiclesWithoutSourceCount = false;
            }
        },

        async markInventoryVehiclesWithoutSourceAsDeleted() {
            if (
                !(await showConfirm(
                    this.$t("Fahrzeuge löschen") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie Fahrzeuge ohne Importquelle aus dem Bestand entfernen möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            this.markingAsDeleted = true;
            try {
                await inventoryImportApi.markInventoryVehiclesWithoutSourceAsDeleted();
                await this.loadNonDeletedInventoryVehiclesWithoutSourceCount();
            } finally {
                this.markingAsDeleted = false;
            }
        },

        async removeDuplicatesOfAllInventoryVehicles() {
            if (
                !(await showConfirm(
                    this.$t("Entferne Dubletten von allen Fahrzeugen") as string,
                    this.$t("Sind Sie sicher, dass Sie Fahrzeugdubletten entfernen möchten?") as string
                ))
            ) {
                return;
            }

            this.removingDuplicatesOfAllInventoryVehicles = true;
            try {
                await inventoryVehicleDuplicatesApi.removeDuplicatesOfAllInventoryVehicles();
            } finally {
                this.removingDuplicatesOfAllInventoryVehicles = false;
            }
        },

        async removeDuplicatesOfInventoryVehiclesWithoutSource() {
            if (
                !(await showConfirm(
                    this.$t("Entferne Dubletten von Fahrzeugen ohne Importquelle") as string,
                    this.$t("Sind Sie sicher, dass Sie Fahrzeugdubletten entfernen möchten?") as string
                ))
            ) {
                return;
            }

            this.removingDuplicatesOfInventoryVehiclesWithoutSource = true;
            try {
                await inventoryVehicleDuplicatesApi.removeDuplicatesOfInventoryVehiclesWithoutSource();
            } finally {
                this.removingDuplicatesOfInventoryVehiclesWithoutSource = false;
            }
        },
    },

    async mounted() {
        await this.loadNonDeletedInventoryVehiclesWithoutSourceCount();
    },

    components: {
        DealerGlobalContextHint,
    },
});
