
import CasesDataTableRow from "./CasesDataTableRow.vue";
import { CaseResult, casesApi, CasesSort, CaseUser, CaseVisibilityArea } from "@/api/cases";
import { Forbidden } from "@/api/errors";
import {
    isCaseUpdatedNotification,
    isCaseVisibilityNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { groupByTimeInterval, TimeIntervalGroupedItems } from "@/app/timeIntervalUtils";
import { now } from "@/store/now";
import { ActionLimiter } from "@/util/debounce";
import { PickMutable } from "@/util/types";
import Vue from "vue";

interface CaseRow {
    caseResult: CaseResult | null;
    readonly caseUser: CaseUser;
    loading: boolean;
}

export default Vue.extend({
    props: {
        casesSort: {
            type: String as () => CasesSort,
            required: true,
        },
        caseVisibilityArea: {
            type: String as () => CaseVisibilityArea,
            required: true,
        },
        itemsPerPage: {
            type: Number,
            default: 100,
        },
        grouped: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            caseLoadLimiterById: new Map<string, ActionLimiter>(),
            items: [] as CaseRow[],
            loading: true,
            loadItemsLimiter: new ActionLimiter(true),
            notificationHandler: null as ((n: Notification) => void) | null,
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
        };
    },

    computed: {
        groupedItems(): TimeIntervalGroupedItems<CaseRow>[] {
            if (!this.grouped) {
                return [
                    {
                        timeIntervalResult: null,
                        items: this.items,
                    },
                ];
            }

            return groupByTimeInterval(this.items, this.getCaseSortDate, now());
        },

        headers(): DataTableHeader[] {
            return [{ width: "30%" }, { width: "35%" }, { width: "35%" }];
        },
    },

    methods: {
        getCaseSortDate(row: CaseRow): Date | null {
            if (this.casesSort === CasesSort.POSTPONED_UNTIL_ASC) {
                return row.caseResult?.caseObj.postponedUntil || null;
            } else if (this.casesSort === CasesSort.POSTPONED_UNTIL_DESC) {
                return row.caseResult?.caseObj.postponedUntil || null;
            } else {
                return null;
            }
        },

        async loadItems(silent: boolean) {
            if (!silent) {
                this.paging.totalSize = 0;
                this.items = [];
                this.loading = true;
            }

            await this.loadItemsLimiter.execute(async () => {
                try {
                    const myCasesResult = await casesApi.getMyCases(
                        this.caseVisibilityArea,
                        this.casesSort,
                        (this.paging.page - 1) * this.paging.pageSize,
                        this.paging.pageSize,
                        ++this.searchCounter
                    );

                    const cases = await casesApi.getByIds(myCasesResult.caseUsers.map((u) => u.caseId));
                    const caseRows = myCasesResult.caseUsers.map((u) => ({
                        caseUser: u,
                        loading: false,
                        caseResult: cases.find((v) => v.caseObj.id === u.caseId) || null,
                        working: false,
                    }));

                    if (myCasesResult.searchId === this.searchCounter) {
                        this.items = caseRows;
                        this.paging.totalSize = myCasesResult.totalSize;
                        this.loading = false;
                    }
                } catch (e) {
                    this.loading = false;
                    this.paging.page = 1;
                    throw e;
                }
            });
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems(false);
        },
    },

    watch: {
        async casesSort() {
            this.paging.page = 1;
            try {
                await this.loadItems(false);
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        async caseVisibilityArea() {
            this.paging.page = 1;
            try {
                await this.loadItems(false);
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isCaseUpdatedNotification(n)) {
                const caseRow = this.items.find((c) => c.caseResult?.caseObj.id === n.caseId);

                const caseId = caseRow?.caseResult?.caseObj.id;

                if (!caseRow || !caseId) {
                    return;
                }

                if (!this.caseLoadLimiterById.has(caseId)) {
                    this.caseLoadLimiterById.set(caseId, new ActionLimiter(true));
                }

                await this.caseLoadLimiterById.get(caseId)!.execute(async () => {
                    caseRow.loading = true;
                    try {
                        caseRow.caseResult = await casesApi.getById(n.caseId);
                    } catch (e) {
                        if (!(e instanceof Forbidden)) {
                            throw e;
                        }

                        caseRow.caseResult = null;
                    } finally {
                        caseRow.loading = false;
                    }
                });
            } else if (isCaseVisibilityNotification(n)) {
                await this.loadItems(true);
            }
        });

        await this.loadItems(false);
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        CasesDataTableRow,
        DataTable,
    },
});
