
import { changelogsApi, ChangelogView } from "@/api/changelogs";
import { userSession } from "@/store/userSession";
import { formatLocalDate } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            initialCount: 50,
            incrementCount: 10,
            totalCount: 0,
            items: [] as ChangelogView[],
            loading: true,
        };
    },

    methods: {
        async loadMore() {
            this.loading = true;

            try {
                const changelogs = await changelogsApi.getChangelogs(
                    this.items.length,
                    Math.min(this.items.length + this.incrementCount, this.totalCount),
                    userSession.locale
                );

                this.items.push(...changelogs);
            } finally {
                this.loading = false;
            }
        },

        formatDate(date: string) {
            return formatLocalDate(date, userSession.locale, "L");
        },

        async loadInitial() {
            this.loading = true;
            try {
                this.totalCount = await changelogsApi.getTotalChangelogCount();
                this.items = await changelogsApi.getChangelogs(
                    0,
                    Math.min(this.totalCount, this.initialCount),
                    userSession.locale
                );
            } finally {
                this.loading = false;
            }
        },
    },

    async mounted() {
        await changelogsApi.resetUnreadChangelogCount();
        await this.loadInitial();
    },
});
