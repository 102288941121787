import { NameAndEmailAddress } from "./emailUtils";
import { Contact, ContactMethod } from "@/api/contacts";
import { IncomingCallResult } from "@/api/incomingCalls";
import { IncomingEmail } from "@/api/incomingEmails";
import { IncomingSms } from "@/api/incomingSms";
import { IncomingWhatsAppMessage } from "@/api/incomingWhatsApp";
import { OutgoingCall } from "@/api/outgoingCalls";
import { OutgoingCitnowVideo } from "@/api/outgoingCitnowVideos";
import { OutgoingEmail, OutgoingEmailEventType, TransactionEmailReceiverType } from "@/api/outgoingEmails";
import { OutgoingSms } from "@/api/outgoingSms";
import { OutgoingWhatsAppMessage } from "@/api/outgoingWhatsApp";
import { configStore } from "@/store/config";

export enum TimelineItemType {
    AUDIT_EVENT = "AUDIT_EVENT",
    INCOMING_CALL = "INCOMING_CALL",
    INCOMING_EMAIL = "INCOMING_EMAIL",
    INCOMING_SMS = "INCOMING_SMS",
    INCOMING_WHATSAPP = "INCOMING_WHATSAPP",
    INVENTORY_VEHICLE_INQUIRY_MATCH = "INVENTORY_VEHICLE_INQUIRY_MATCH",
    NOTE = "NOTE",
    OUTGOING_CALL = "OUTGOING_CALL",
    OUTGOING_CITNOW_VIDEO = "OUTGOING_CITNOW_VIDEO",
    OUTGOING_EMAIL = "OUTGOING_EMAIL",
    OUTGOING_SMS = "OUTGOING_SMS",
    OUTGOING_WHATSAPP = "OUTGOING_WHATSAPP",
}

export interface TimelineItem {
    readonly id: string;
    readonly timelineItemType: TimelineItemType;
    readonly created: Date;
}

export function isIncomingCallTimelineItem(
    timelineItem: TimelineItem
): timelineItem is IncomingCallResult & TimelineItem {
    return timelineItem.timelineItemType === TimelineItemType.INCOMING_CALL;
}

export function isIncomingEmailTimelineItem(timelineItem: TimelineItem): timelineItem is IncomingEmail & TimelineItem {
    return timelineItem.timelineItemType === TimelineItemType.INCOMING_EMAIL;
}

export function isIncomingSmsTimelineItem(timelineItem: TimelineItem): timelineItem is IncomingSms & TimelineItem {
    return timelineItem.timelineItemType === TimelineItemType.INCOMING_SMS;
}

export function isIncomingWhatsAppMessageTimelineItem(
    timelineItem: TimelineItem
): timelineItem is IncomingWhatsAppMessage & TimelineItem {
    return timelineItem.timelineItemType === TimelineItemType.INCOMING_WHATSAPP;
}

export function isOutgoingCallTimelineItem(timelineItem: TimelineItem): timelineItem is OutgoingCall & TimelineItem {
    return timelineItem.timelineItemType === TimelineItemType.OUTGOING_CALL;
}

export function isOutgoingCitnowVideoTimelineItem(
    timelineItem: TimelineItem
): timelineItem is OutgoingCitnowVideo & TimelineItem {
    return timelineItem.timelineItemType === TimelineItemType.OUTGOING_CITNOW_VIDEO;
}

export function isOutgoingEmailTimelineItem(timelineItem: TimelineItem): timelineItem is OutgoingEmail & TimelineItem {
    return timelineItem.timelineItemType === TimelineItemType.OUTGOING_EMAIL;
}

export function isMainOutgoingEmailTimelineItem(
    timelineItem: TimelineItem
): timelineItem is OutgoingEmail & TimelineItem {
    return (
        isOutgoingEmailTimelineItem(timelineItem) &&
        !((timelineItem as unknown) as OutgoingEmail).headers.some((h) => h.name === "X-Main-Email-Id")
    );
}

export function isOutgoingSmsTimelineItem(timelineItem: TimelineItem): timelineItem is OutgoingSms & TimelineItem {
    return timelineItem.timelineItemType === TimelineItemType.OUTGOING_SMS;
}

export function isOutgoingWhatsAppMessageTimelineItem(
    timelineItem: TimelineItem
): timelineItem is OutgoingWhatsAppMessage & TimelineItem {
    return timelineItem.timelineItemType === TimelineItemType.OUTGOING_WHATSAPP;
}

interface Issue {
    readonly preferredContactMethod: ContactMethod | null;
    readonly preferredContactDetails: string | null;
}

function getIncomingEmailReplyAddress(incomingEmail: IncomingEmail): NameAndEmailAddress {
    if (incomingEmail.replyToAddress) {
        return { name: incomingEmail.replyToName, address: incomingEmail.replyToAddress };
    } else {
        return { name: incomingEmail.fromName, address: incomingEmail.fromAddress };
    }
}

export function isIllegalReceiverEmailAddress(emailAddress: string): boolean {
    const normalized = emailAddress.toLowerCase();

    return (
        normalized.startsWith("noreply") ||
        normalized.startsWith("no-reply") ||
        normalized.startsWith("no_reply") ||
        normalized.startsWith("donotreply") ||
        normalized.startsWith("do-not-reply") ||
        normalized.startsWith("do_not_reply") ||
        normalized.startsWith("mailer-daemon") ||
        normalized.startsWith("mailer_daemon") ||
        new Set(configStore.configuration.incomingEmailExternalSystemEmailAddresses.map((r) => r.toLowerCase())).has(
            normalized
        )
    );
}

export function getMostRelevantEmailAddress(
    issue: Issue | null,
    contact: Contact | null,
    timelineItems: TimelineItem[]
): NameAndEmailAddress | null {
    return (
        [
            // use email of active conversation (i.e., consider outgoing and incoming user emails first)
            ...timelineItems
                .map((item) =>
                    isIncomingEmailTimelineItem(item) && item.user
                        ? [getIncomingEmailReplyAddress(item)]
                        : isIncomingEmailTimelineItem(item) && !item.user
                        ? item.processContentEmailAddresses
                              .filter((address) =>
                                  configStore.configuration.incomingEmailPseudonymizedContactEmailAddressPatterns.every(
                                      (pattern) => !address.match(new RegExp(pattern, "i"))
                                  )
                              )
                              .map((address) => ({ name: null, address }))
                        : isOutgoingEmailTimelineItem(item)
                        ? item.receivers
                              .filter((r) => r.type === TransactionEmailReceiverType.TO)
                              .filter((r) =>
                                  item.events
                                      .filter((e) => e.address === r.address)
                                      .every((e) => e.eventType !== OutgoingEmailEventType.BOUNCED_HARD)
                              )
                              .map((r) => ({ name: r.name, address: r.address }))
                        : []
                )
                .reduce((prev, cur) => prev.concat(cur), []),

            // fall back to preferred email address
            issue && issue.preferredContactMethod === ContactMethod.EMAIL && issue.preferredContactDetails
                ? { name: null, address: issue.preferredContactDetails }
                : null,

            // if there is none, use an email address of the contact
            ...(contact && !contact.deleted
                ? contact.contactData.emailAddresses.map((e) => ({ name: null, address: e.address }))
                : []),

            // if there is no other email address, use reply address of an incoming system email
            ...timelineItems.map((item) =>
                isIncomingEmailTimelineItem(item) && !item.user ? getIncomingEmailReplyAddress(item) : null
            ),
        ]
            .filter(
                (receiver): receiver is NameAndEmailAddress =>
                    !!receiver && !isIllegalReceiverEmailAddress(receiver.address)
            )
            .shift() ?? null
    );
}
