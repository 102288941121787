import { Department, departmentsApi } from "@/api/departments";
import {
    isDepartmentChangedNotification,
    isDepartmentDeletedNotification,
    isDepartmentsOrderChangedNotification,
    isUserDeletedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { removeMatchingItems, reorderItems, updateItem } from "@/util/storeUtils";

@reactive
class DepartmentsStore {
    private departments_: Department[] = [];
    private refreshLimiter = new ActionLimiter(true);

    get departments() {
        return this.departments_;
    }

    departmentsByDealer(dealerId: string) {
        return this.departments_.filter((d) => d.dealerId === dealerId);
    }

    departmentById(departmentId: string) {
        for (const department of this.departments_) {
            if (department.id === departmentId) {
                return department;
            }
        }
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.departments_ = await departmentsApi.getAll();
        });
    }

    handleNotification(n: Notification) {
        if (isDepartmentChangedNotification(n)) {
            updateItem(this.departments_, n.department, (d) => d.id);
        } else if (isDepartmentDeletedNotification(n)) {
            removeMatchingItems(this.departments_, (d) => d.id === n.departmentId);
        } else if (isDepartmentsOrderChangedNotification(n)) {
            reorderItems(this.departments_, n.orderedDepartmentIds, (d) => d.id);
        } else if (isUserDeletedNotification(n)) {
            for (const department of this.departments_) {
                removeMatchingItems(department.departmentUsers, (du) => du.userId === n.userId);
            }
        }
    }
}

export const departmentsStore = new DepartmentsStore();

notificationEventSource.addDataHandler((n) => departmentsStore.handleNotification(n));
