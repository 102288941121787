import axios from "axios";

export interface CitnowVideoLocationSettingsForm {
    lid: string;
    host: string;
    typeId: string;
}

export interface CitnowVideoLocationSettings {
    dealerId: string;
    lid: string;
    host: string;
    typeId: string;
}

interface CitnowVideoSettingsApi {
    getLocationSettings(dealerId: string): Promise<CitnowVideoLocationSettings[]>;
    addLocationSettings(dealerId: string, form: CitnowVideoLocationSettingsForm): Promise<void>;
    editLocationSettings(dealerId: string, form: CitnowVideoLocationSettingsForm): Promise<void>;
    deleteLocationSettings(dealerId: string, lid: string): Promise<void>;
}

export const citnowVideoSettingsApi: CitnowVideoSettingsApi = {
    async getLocationSettings(dealerId) {
        return (await axios.get(`/api/citnow-video-settings/${dealerId}`)).data;
    },

    async addLocationSettings(dealerId, form) {
        await axios.post(`/api/citnow-video-settings/${dealerId}`, form);
    },

    async editLocationSettings(dealerId, form) {
        await axios.patch(`/api/citnow-video-settings/${dealerId}`, form);
    },

    async deleteLocationSettings(dealerId, lid) {
        await axios.delete(`/api/citnow-video-settings/${dealerId}`, { params: { lid } });
    },
};
