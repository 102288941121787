
import { callerIdsApi } from "@/api/callerIds";
import {
    Dealer,
    DealerForm,
    DealerNumber,
    dealersApi,
    EmailAddress,
    ExternalBdcTarget,
    LegalInspectionType,
    NumberType,
} from "@/api/dealers";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import CountryPicker from "@/app/components/CountryPicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import StatePicker from "@/app/components/StatePicker.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { integer, maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";
import VImageInput from "vuetify-image-input";

const PNG_DATA_URI_PREFIX = "data:image/png;base64,";

export default Vue.extend({
    data() {
        return {
            Permission,
            isWorking: true,
            dealer: null as Dealer | null,

            // FORM FIELDS
            id: "",
            address1: "",
            address2: "",
            zip: "",
            city: "",
            state: null as string | null,
            country: "",
            timeZone: "",
            numbers: [] as DealerNumber[],
            emailAddresses: [] as EmailAddress[],
            mailboxInfo: null as string | null,
            legalInspectionType: LegalInspectionType.NOT_POSSIBLE,
            legalInspectionExecutionHours: "",
            incidentServiceRadius: null as number | null,
            breakdownInstructions: null as string | null,
            accidentInstructions: null as string | null,
            logoUri: null as string | null,

            // OPTIONS
            callerIds: [] as SelectOptions,
            LegalInspectionType,

            // RULES
            maxLength2000Rules: maxLength(2000),
            maxLength1000Rules: maxLength(1000),
            maxLength300Rules: maxLength(300),
            maxLength63Rules: maxLength(63),
            numberRules: notEmpty().e164(),
            emailRules: notEmpty().email(),
            positiveIntegerRules: integer(0),
            notEmpty,
        };
    },

    computed: {
        mailboxAvailable: {
            get(): boolean {
                return this.mailboxInfo !== null;
            },
            set(v: boolean) {
                if (v) {
                    if (this.mailboxInfo === null) {
                        this.mailboxInfo = "";
                    }
                } else {
                    this.mailboxInfo = null;
                }
            },
        },

        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },

        phoneNumberTypes(): SelectOptions {
            return Object.keys(NumberType).map((k) => ({ value: k, text: this.$t(`enum.NumberType.${k}`) }));
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        dealerName(): string | undefined {
            return this.dealer?.name;
        },

        externalBdcTargets(): ExternalBdcTarget[] {
            return this.dealer?.externalBdcTargets || [];
        },
    },

    watch: {
        dealerId(value: string | null) {
            if (value) {
                this.loadDealerForm(value);
            }
        },
    },

    methods: {
        addEmailAddress() {
            this.emailAddresses.push({
                address: "",
                label: "",
            });
        },

        removeEmailAddress(index: number) {
            this.emailAddresses.splice(index, 1);
        },

        addPhoneNumber() {
            this.numbers.push({
                type: NumberType.LANDLINE,
                number: "",
                label: "",
            });
        },

        removePhoneNumber(index: number) {
            this.numbers.splice(index, 1);
        },

        async loadDealerForm(dealerId: string) {
            this.isWorking = true;

            try {
                this.dealer = await dealersApi.getById(dealerId);
                this.id = this.dealer.id;
                this.address1 = this.dealer.address1 || "";
                this.address2 = this.dealer.address2 || "";
                this.zip = this.dealer.zip || "";
                this.city = this.dealer.city || "";
                this.state = this.dealer.state;
                this.country = this.dealer.country;
                this.timeZone = this.dealer.timeZone;
                this.numbers = this.dealer.numbers;
                this.emailAddresses = this.dealer.emailAddresses;
                this.mailboxInfo = this.dealer.mailboxInfo;
                this.legalInspectionType = this.dealer.legalInspectionType;
                this.legalInspectionExecutionHours = this.dealer.legalInspectionExecutionHours || "";
                this.incidentServiceRadius = this.dealer.incidentServiceRadius;
                this.breakdownInstructions = this.dealer.breakdownInstructions;
                this.accidentInstructions = this.dealer.accidentInstructions;

                if (this.dealer.logoHash) {
                    this.logoUri =
                        PNG_DATA_URI_PREFIX + (await dealersApi.getLogoByHashAsBase64(this.id, this.dealer.logoHash));
                } else {
                    this.logoUri = null;
                    this.$refs.imageInput.clear();
                }
            } finally {
                this.isWorking = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            const dealerForm: DealerForm = {
                id: this.id,
                address1: this.address1,
                address2: this.address2,
                zip: this.zip,
                city: this.city,
                state: this.state,
                country: this.country,
                timeZone: this.timeZone,
                numbers: this.numbers,
                emailAddresses: this.emailAddresses,
                mailboxInfo: this.mailboxInfo,
                legalInspectionType: this.legalInspectionType,
                legalInspectionExecutionHours: this.legalInspectionExecutionHours,
                incidentServiceRadius: this.incidentServiceRadius,
                breakdownInstructions: this.breakdownInstructions,
                accidentInstructions: this.accidentInstructions,
                logoHash: null,
            };

            try {
                let logo;
                if (this.logoUri) {
                    const blob = await (await fetch(this.logoUri)).blob();
                    logo = new File([blob], "logo.png", { type: blob.type });
                }

                await dealersApi.edit(dealerForm, logo);
                await this.loadDealerForm(this.id);
            } finally {
                this.isWorking = false;
            }
        },

        getPhoneNumberTypeIcon(numberType: NumberType) {
            if (numberType === NumberType.MOBILE) {
                return "mdi-cellphone";
            } else if (numberType === NumberType.LANDLINE) {
                return "mdi-deskphone";
            } else if (numberType === NumberType.FAX) {
                return "mdi-fax";
            }

            return "mdi-phone-classic";
        },
    },

    async mounted() {
        const callerIds = (await callerIdsApi.getCallerIds()).map((cid) => cid.phoneNumber);
        this.callerIds = callerIds.map((n) => ({ value: n, text: parseAndFormatNumber(n, "INTERNATIONAL")! }));
        if (this.dealerId) {
            await this.loadDealerForm(this.dealerId);
        }
    },

    components: {
        DealerContextGuard,
        PhoneNumberField,
        StatePicker,
        CountryPicker,
        TimeZonePicker,
        EnumField,
        NumberField,
        CopyToClipboardIcon,
        VImageInput,
    },
});
