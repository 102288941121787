
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { EmergencyIncidentAssistantState } from "@/app/pages/emergencyincidents/assistant/emergencyIncidentAssistant";
import { e164, maxLength } from "@/app/validation";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        emergencyIncidentAssistantState: {
            type: Object as () => EmergencyIncidentAssistantState,
            required: true,
        },
    },

    data() {
        return {
            e164,
            maxLength,
        };
    },

    computed: {
        contactNumberAutocompleteNumbers(): string[] {
            return (this.emergencyIncidentAssistantState.contactData?.numbers ?? []).map((n) => n.number);
        },

        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },
    },

    components: {
        PhoneNumberField,
    },
});
