
import { getCaseStatus } from "./caseUtils";
import { Case, CaseStatus, CaseUser } from "@/api/cases";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import UserLink from "@/app/pages/UserLink.vue";
import UserDirectoryEntrySpan from "@/app/pages/users/UserDirectoryEntrySpan.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseProp: {
            type: Object as () => Case,
            required: true,
        },
    },

    data() {
        return {
            expanded: null as boolean | null,
        };
    },

    computed: {
        filteredAndSortedCaseUsers(): CaseUser[] {
            return this.caseProp.users
                .filter((u) => !u.visibilityEnd)
                .sort((a, b) => a.visibilityBegin.getTime() - b.visibilityBegin.getTime());
        },

        caseStatus(): CaseStatus {
            return getCaseStatus(this.caseProp);
        },
    },

    watch: {
        filteredAndSortedCaseUsers() {
            if (this.expanded === null && this.filteredAndSortedCaseUsers.length) {
                // expand only if the user has not changed the expansion state manually
                this.expanded = true;
            }
        },
    },

    created() {
        this.expanded = !!this.filteredAndSortedCaseUsers.length || null;
    },

    components: {
        DToolbarCard,
        TimeSpan,
        UserDirectoryEntrySpan,
        UserLink,
    },
});
