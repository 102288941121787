import axios from "axios";

interface FileHostingApi {
    uploadFile(file: File, progressCallback: (progressEvent: ProgressEvent) => void): Promise<string>;
}

export const fileHostingApi: FileHostingApi = {
    async uploadFile(file, onUploadProgress) {
        const formData = new FormData();
        formData.append("file", file);
        return (await axios.post("/api/file-hosting", formData, { onUploadProgress })).headers.location;
    },
};
