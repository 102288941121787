
import OpportunitiesDataTable from "./OpportunitiesDataTable.vue";
import { OpportunitySearchRequest } from "@/api/opportunitySearch";
import Vue from "vue";

export default Vue.extend({
    props: {
        itemsPerPage: {
            type: Number,
            default: 25,
        },
        myOpportunities: {
            type: Boolean,
            default: false,
        },
        searchRequest: {
            type: Object as () => OpportunitySearchRequest,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
    },

    components: {
        OpportunitiesDataTable,
    },
});
