
import { OfficeHoursEntry } from "@/api/officeHours";
import TimePicker from "@/app/components/TimePicker.vue";
import { showInfo } from "@/app/messageUtil";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            valid: true,

            // FORM FIELDS
            fromTime: "",
            toTime: "",
            notEmptyRules: notEmpty(),
        };
    },

    methods: {
        close() {
            this.$emit("close");
        },

        submitSubform() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            if (this.toTime <= this.fromTime) {
                showInfo(this.$t("Das Ende der Arbeitszeit muss nach dem Anfang der Arbeitszeit liegen.") as string);
                return;
            }

            const officeHoursEntry: OfficeHoursEntry = {
                from: this.fromTime,
                to: this.toTime,
                dayOfWeek: Number.parseInt(this.dayOfWeek),
            };

            this.$emit("add", officeHoursEntry);
        },
    },

    props: {
        dayOfWeek: {
            type: String,
            required: true,
        },
    },

    components: {
        TimePicker,
    },
});
