/* eslint-disable @typescript-eslint/camelcase */
import de from "./de";
import de_CH from "./de_CH";
import en from "./en";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
    messages: {
        de,
        "de-CH": de_CH,
        en,
    },
    locale: navigator.language,
    fallbackLocale: "de",
    formatFallbackMessages: true,
    silentTranslationWarn: true,
});

export default i18n;
export const $t = i18n.t.bind(i18n);
