
import Vue from "vue";

export default Vue.extend({
    props: {
        roomName: {
            type: String,
            required: true,
        },
        userName: {
            type: String,
            required: false,
        },
        width: {
            type: String,
            default: "100%",
        },
        height: {
            type: String,
            default: "100%",
        },
        host: {
            type: String,
            default: "meet.jit.si",
        },
        appId: {
            type: String,
            required: false,
        },
        jwt: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            jitsi: null as any,
        };
    },

    methods: {
        async init(): Promise<void> {
            const JitsiMeetExternalAPI = (window as any).JitsiMeetExternalAPI;

            if (JitsiMeetExternalAPI) {
                if (!this.jitsi) {
                    this.jitsi = new JitsiMeetExternalAPI(this.host, {
                        roomName: (this.appId ? this.appId + "/" : "") + this.roomName,
                        width: this.width,
                        height: this.height,
                        parentNode: this.$refs.jitsi,
                        userInfo: {
                            displayName: this.userName,
                        },
                        jwt: this.jwt || undefined,
                        configOverwrite: {
                            defaultLanguage: this.$i18n.locale,
                            prejoinPageEnabled: !this.userName,
                            enableInsecureRoomNameWarning: false,
                            hideConferenceSubject: true,
                            disableDeepLinking: true,
                            toolbarButtons: [
                                "microphone",
                                "camera",
                                "desktop",
                                "fullscreen",
                                "toggle-camera",
                                "hangup",
                                "chat",
                                "shareaudio",
                                "sharedvideo",
                                "settings",
                                "raisehand",
                                "videoquality",
                                "stats",
                                "shortcuts",
                                "tileview",
                                "select-background",
                            ],
                        },
                        interfaceConfigOverwrite: {
                            MOBILE_APP_PROMO: false,
                            SHOW_CHROME_EXTENSION_BANNER: false,
                            DEFAULT_LOCAL_DISPLAY_NAME: this.$t("ich"),
                            SETTINGS_SECTIONS: ["devices", "language"],
                        },
                    });

                    this.jitsi.addListener("videoConferenceJoined", (event: any) => {
                        this.$emit("joined", event.displayName);
                    });

                    this.jitsi.addListener("videoConferenceLeft", () => {
                        this.$emit("left");
                        this.jitsi.dispose();
                        this.jitsi = null;
                    });

                    this.jitsi.addListener("participantJoined", (event: any) => {
                        this.$emit("participantJoined", event.displayName);
                    });
                }

                return Promise.resolve();
            }

            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.addEventListener("load", async () => {
                    await this.init();
                    resolve();
                });
                script.type = "text/javascript";
                script.src = `https://${this.host}/external_api.js`;
                document.head.appendChild(script);
            });
        },
    },

    async mounted() {
        await this.init();
    },

    beforeDestroy() {
        if (this.jitsi) {
            this.jitsi.dispose();
        }
    },
});
