
import { canAddNote, getStatusColor } from "./outgoingCallUtils";
import { Contact } from "@/api/contacts";
import { isOutgoingCallUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { OutgoingCall, OutgoingCallAndContact, outgoingCallsApi } from "@/api/outgoingCalls";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { renderContactCaption } from "@/app/contactUtils";
import { editModeState } from "@/app/editModeUtils";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        outgoingCallAndContact: {
            type: Object as () => OutgoingCallAndContact,
            required: true,
        },
    },

    data() {
        return {
            note: "",
            noteFormVisible: false,
            notEmpty,
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingCallAndContactProp: this.outgoingCallAndContact,
            parseAndFormatNumber,
            savingNote: false,
        };
    },

    computed: {
        canAddNote(): boolean {
            return canAddNote(this.outgoingCall);
        },

        contact(): Contact | null {
            return this.outgoingCallAndContactProp.contact;
        },

        contactCaption(): string | null {
            if (!this.contact) {
                return null;
            }

            return renderContactCaption(this.contact.contactData);
        },

        isContactDeleted(): boolean {
            return !!this.contact?.deleted;
        },

        outgoingCall(): OutgoingCall {
            return this.outgoingCallAndContactProp.outgoingCall;
        },

        statusClass(): string {
            const color = getStatusColor(this.outgoingCall.callStatus);

            return color ? `${color}--text` : "";
        },
    },

    methods: {
        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        hideNoteForm() {
            this.noteFormVisible = false;
            editModeState.leave();
        },

        async saveNote() {
            if (!(this.$refs.noteForm as any).validate()) {
                return;
            }

            this.savingNote = true;
            try {
                await outgoingCallsApi.setNote(this.outgoingCall.id, this.note);
            } finally {
                this.noteFormVisible = false;
                this.savingNote = false;
                editModeState.leave();
            }
        },

        showNoteForm() {
            if (this.noteFormVisible) {
                return;
            }

            this.note = "";
            this.noteFormVisible = true;
            editModeState.enter();
        },
    },

    watch: {
        outgoingCallAndContact() {
            this.outgoingCallAndContactProp = this.outgoingCallAndContact;
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isOutgoingCallUpdatedNotification(n)) {
                if (n.outgoingCall.id === this.outgoingCall.id) {
                    this.outgoingCallAndContactProp = {
                        ...this.outgoingCallAndContactProp,
                        outgoingCall: n.outgoingCall,
                    };
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        TimeSpan,
    },
});
