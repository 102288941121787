var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('crud-page',{staticClass:"pb-3",attrs:{"icon":"mdi-check-circle-outline","no-items-text":_vm.$t('Es wurden keine Resultate gefunden.'),"add-dialog-title":_vm.$t('Resultat hinzufügen'),"edit-dialog-title":_vm.$t('Resultat bearbeiten'),"page-title":_vm.$t('Erfolgreiche Resultate'),"global":"","callbacks":{
            load: () => _vm.getOpportunityOutcomeReasons(true),
            add: (_, form) => _vm.api.add(form),
            edit: (item, form) => _vm.api.edit(item.id, form),
            delete: (item) => _vm.deleteOpportunityOutcomeReason(item),
            updateOrder: (items) => _vm.updateOrder(items),
            key: (item) => item.id,
            toForm: (item) => _vm.toForm(item),
            emptyForm: () => _vm.emptyForm(true),
        }},scopedSlots:_vm._u([{key:"title",fn:function({ item }){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.name))]),(_vm.outcomeReasonsToIgnoreInAssignmentLock.includes(item.id))?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.$t("Für Berechnung der Annahmesperre ignorieren"))+" ")]):_vm._e(),(_vm.ignoreOpportunityOutcomeReasonIdsInReporting.includes(item.id))?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.$t("Für Berichte ignorieren"))+" ")]):_vm._e()]}},{key:"content",fn:function({ item }){return [(item.description)?_c('v-card-text',[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e()]}},{key:"dialog",fn:function({ form }){return [_c('v-text-field',{attrs:{"autocomplete":"no","autofocus":"","id":_vm.$id(),"label":_vm.$t('Name'),"rules":_vm.notEmpty().maxLength(300)},model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}}),_c('v-text-field',{attrs:{"autocomplete":"no","id":_vm.$id(),"label":_vm.$t('Beschreibung'),"rules":_vm.maxLength(300)},model:{value:(form.description),callback:function ($$v) {_vm.$set(form, "description", $$v)},expression:"form.description"}})]}}])}),_c('crud-page',{staticClass:"pt-0 pb-3",attrs:{"icon":"mdi-close-circle-outline","no-items-text":_vm.$t('Es wurden keine Resultate gefunden.'),"add-dialog-title":_vm.$t('Resultat hinzufügen'),"edit-dialog-title":_vm.$t('Resultat bearbeiten'),"page-title":_vm.$t('Erfolglose Resultate'),"global":"","hide-dealer-context-warning":"","callbacks":{
            load: () => _vm.getOpportunityOutcomeReasons(false),
            add: (_, form) => _vm.api.add(form),
            edit: (item, form) => _vm.api.edit(item.id, form),
            delete: (item) => _vm.deleteOpportunityOutcomeReason(item),
            updateOrder: (items) => _vm.updateOrder(items),
            key: (item) => item.id,
            toForm: (item) => _vm.toForm(item),
            emptyForm: () => _vm.emptyForm(false),
        }},scopedSlots:_vm._u([{key:"title",fn:function({ item }){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.name))]),(_vm.outcomeReasonsToIgnoreInAssignmentLock.includes(item.id))?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.$t("Für Berechnung der Annahmesperre ignorieren"))+" ")]):_vm._e(),(_vm.ignoreOpportunityOutcomeReasonIdsInReporting.includes(item.id))?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.$t("Für Berichte ignorieren"))+" ")]):_vm._e()]}},{key:"content",fn:function({ item }){return [(item.description)?_c('v-card-text',[_c('div',[_vm._v(_vm._s(item.description))])]):_vm._e()]}},{key:"dialog",fn:function({ form }){return [_c('v-text-field',{attrs:{"autocomplete":"no","autofocus":"","id":_vm.$id(),"label":_vm.$t('Name'),"rules":_vm.notEmpty().maxLength(300)},model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}}),_c('v-text-field',{attrs:{"autocomplete":"no","id":_vm.$id(),"label":_vm.$t('Beschreibung'),"rules":_vm.maxLength(300)},model:{value:(form.description),callback:function ($$v) {_vm.$set(form, "description", $$v)},expression:"form.description"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }