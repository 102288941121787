
import {
    ExternalAbsenceProvider,
    ExternalAbsenceProviderForm,
    externalAbsenceProvidersApi,
    ExternalAbsenceProviderType,
} from "@/api/externalAbsenceProviders";
import EnumField from "@/app/components/EnumField.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        externalAbsenceProvider: {
            type: Object as () => ExternalAbsenceProvider | null,
            required: false,
        },
    },

    data() {
        return {
            ExternalAbsenceProviderType,
            valid: true,
            isWorking: false,

            // FORM FIELDS
            externalAbsenceProviderForm: {
                dealerId: this.externalAbsenceProvider?.dealerId || "",
                resolveSubstitutesGlobally: this.externalAbsenceProvider?.resolveSubstitutesGlobally ?? false,
                type: this.externalAbsenceProvider?.type || ExternalAbsenceProviderType.TIMEBUTLER,
                credential1: this.externalAbsenceProvider?.credential1 || "",
                credential2: this.externalAbsenceProvider?.credential2 || "",
            } as ExternalAbsenceProviderForm,

            // RULES
            credentialRules: notEmpty().maxLength(2000),
            httpsUrlRules: [
                ...(notEmpty().maxLength(2000) as any),
                (u: string) => {
                    try {
                        if (new URL(u.trim()).protocol === "https:") {
                            return true;
                        }
                    } catch (e) {
                        // ignore
                    }
                    return this.$t("Bitte geben Sie eine gültige HTTPS-URL ein.");
                },
            ],
            ftpUrlRules: [
                ...(notEmpty().maxLength(2000) as any),
                (u: string) => {
                    try {
                        const url = new URL(u.trim());
                        if (url.protocol === "ftp:" && url.username && url.password) {
                            return true;
                        }
                    } catch (e) {
                        // ignore
                    }
                    return this.$t("Bitte geben Sie eine gültige FTP-URL mit Benutzernamen und Passwort ein.");
                },
            ],
        };
    },

    computed: {
        dealerOptions(): SelectOptions {
            return [{ value: "", text: this.$t("Gesamte Händlergruppe") }, ...dealerOptions()];
        },

        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name || this.$t("Gesamte Händlergruppe");
        },

        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            try {
                if (this.externalAbsenceProvider) {
                    await externalAbsenceProvidersApi.edit(
                        this.externalAbsenceProvider.id,
                        this.externalAbsenceProviderForm
                    );
                } else {
                    await externalAbsenceProvidersApi.add(this.externalAbsenceProviderForm);
                }
            } finally {
                this.isWorking = false;
            }
            this.$emit("success");
        },
    },

    components: {
        EnumField,
    },
});
