
import { autoCrmLeadMailAssistantState } from "./autoCrmLeadMailAssistantState";
import { ContactSalutation } from "@/api/contacts";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { e164, email, maxLength } from "@/app/validation";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            autoCrmLeadMailAssistantState,
            ContactSalutation,
            e164,
            email,
            maxLength,
        };
    },

    computed: {
        defaultCountry(): string {
            return configStore.configuration.defaultCountry;
        },
    },

    components: {
        EnumField,
        PhoneNumberField,
    },
});
