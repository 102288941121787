
import { CaseChannel } from "@/api/cases";
import { emailDomainsApi } from "@/api/emailDomains";
import { EmailTemplate, emailTemplatesApi } from "@/api/emailTemplates";
import { BadRequest } from "@/api/errors";
import { OpportunityChannel } from "@/api/opportunities";
import {
    QualityAssuranceLevel,
    SystemEmailAlias,
    systemEmailAliasesApi,
    SystemEmailAliasForm,
    TypeHint,
} from "@/api/systemEmailAliases";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import EnumField from "@/app/components/EnumField.vue";
import { dealerOptions, getSortedByDealer } from "@/app/dealerUtils";
import { showConfirm } from "@/app/messageUtil";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import CrudPage from "@/app/pages/CrudPage.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { getFullName } from "@/app/userUtils";
import { EMAIL_LOCAL_PART_REGEX, notEmpty, regEx, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { officeHoursStore } from "@/store/officeHours";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

interface SystemEmailAliasFormWithAutoresponderOption extends SystemEmailAliasForm {
    readonly autoresponderEnabled: boolean;
}

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();

        return {
            emailTemplates: [] as EmailTemplate[],
            emailDomainIds: [] as string[],
            localPartRules: regEx(EMAIL_LOCAL_PART_REGEX)
                .msg(() => this.$t("Gültiger Alias ist erforderlich"))
                .and(validationHelper, "localPart"),
            fromAddressLocalPartRules: regEx(EMAIL_LOCAL_PART_REGEX).msg(() =>
                this.$t("Gültiger Alias ist erforderlich")
            ),
            fromAddressEmailDomainRules: notEmpty(),
            validationHelper,
            Permission,
            CaseChannel,
            OpportunityChannel,
            QualityAssuranceLevel,
            TypeHint,
        };
    },

    computed: {
        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },

        dealerId() {
            return userSession.dealerId;
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        transactionEmailHost() {
            return configStore.configuration.transactionEmailHost;
        },

        escalationGroupReceiversOptions(): SelectOption[] {
            return getSortedByDealer(escalationGroupsStore.escalationGroups, (e) => e.dealerId).map((e) => ({
                text: this.appendDealerNameToOptionText(e.name, e.dealerId),
                value: e.id,
            }));
        },

        individualReceiversOptions(): SelectOption[] {
            return [...usersStore.users]
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        notEmpty() {
            return notEmpty;
        },

        opportunitySourceOptions(): SelectOption[] {
            return opportunitySourcesStore.opportunitySources.map((s) => ({
                text: s.name,
                value: s.id,
            }));
        },

        opportunityTeamReceiversOptions(): SelectOption[] {
            return getSortedByDealer(opportunityTeamsStore.opportunityTeams, (t) => t.dealerId).map((t) => ({
                text: this.appendDealerNameToOptionText(t.name, t.dealerId),
                value: t.id,
            }));
        },

        emailTemplateOptions(): SelectOption[] {
            return this.emailTemplates.map((t) => ({
                value: t.id,
                text: `${t.name} (${t.locale})`,
            }));
        },

        officeHoursOptions(): SelectOption[] {
            return officeHoursStore.officeHours.map((officeHours) => ({
                value: officeHours.id,
                text: officeHours.name,
            }));
        },
    },

    methods: {
        async addSystemEmailAlias(form: SystemEmailAliasFormWithAutoresponderOption, formRef: unknown) {
            try {
                await systemEmailAliasesApi.add({
                    ...form,
                    autoresponderOfficeHoursId: form.autoresponderEnabled ? form.autoresponderOfficeHoursId : null,
                    autoresponderInsideOfficeHoursEmailTemplateId: form.autoresponderEnabled
                        ? form.autoresponderInsideOfficeHoursEmailTemplateId
                        : null,
                    autoresponderOutsideOfficeHoursEmailTemplateId: form.autoresponderEnabled
                        ? form.autoresponderOutsideOfficeHoursEmailTemplateId
                        : null,
                });
                return true;
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, formRef);
                return false;
            }
        },

        appendDealerNameToOptionText(text: string, dealerId: string) {
            return `${text} (${this.getDealerNameById(dealerId)})`;
        },

        async deleteSystemEmailAlias(item: SystemEmailAlias) {
            if (
                await showConfirm(
                    this.$t("Eingehende E-Mail-Adresse löschen").toString(),
                    this.$t("Sind Sie sicher, dass Sie den Alias löschen möchten?").toString()
                )
            ) {
                await systemEmailAliasesApi.delete(item.id);
                return true;
            }
            return false;
        },

        async editSystemEmailAlias(
            systemEmailAliasid: string,
            form: SystemEmailAliasFormWithAutoresponderOption,
            formRef: unknown
        ) {
            try {
                await systemEmailAliasesApi.edit(systemEmailAliasid, {
                    ...form,
                    autoresponderOfficeHoursId: form.autoresponderEnabled ? form.autoresponderOfficeHoursId : null,
                    autoresponderInsideOfficeHoursEmailTemplateId: form.autoresponderEnabled
                        ? form.autoresponderInsideOfficeHoursEmailTemplateId
                        : null,
                    autoresponderOutsideOfficeHoursEmailTemplateId: form.autoresponderEnabled
                        ? form.autoresponderOutsideOfficeHoursEmailTemplateId
                        : null,
                });
                return true;
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, formRef);
                return false;
            }
        },

        emptyForm(): SystemEmailAliasFormWithAutoresponderOption {
            return {
                localPart: (null as unknown) as string,
                type: (null as unknown) as TypeHint,
                dealerId: this.dealerId!,
                overrideDealerIdByVehicleLocation: false,
                individualReceivers: [],
                qualityAssuranceLevel: QualityAssuranceLevel.NONE,
                autoresponderEnabled: false,
                autoresponderOfficeHoursId: null,
                autoresponderInsideOfficeHoursEmailTemplateId: null,
                autoresponderOutsideOfficeHoursEmailTemplateId: null,
                autoresponderFromAddressLocalPart: null,
                autoresponderFromAddressDomainId: this.emailDomainIds.length === 1 ? this.emailDomainIds[0] : null,
                ignoreParsedContent: false,
                caseChannel: null,
                caseType: null,
                escalationGroupReceivers: [],
                opportunityChannel: null,
                sourceId: null,
                opportunityTeamReceivers: [],
            };
        },

        getDealerNameById(dealerId: string) {
            return (dealersStore.dealerById(dealerId)?.name || this.$t("Unbekannt")).toString();
        },

        getEscalationGroupNameById(escalationGroupId: string) {
            return (
                escalationGroupsStore.escalationGroupById(escalationGroupId)?.name || this.$t("Unbekannt")
            ).toString();
        },

        getOpportunitySourceNameById(opportunitySourceId: string) {
            return (
                opportunitySourcesStore.getOpportunitySourceById(opportunitySourceId)?.name || this.$t("Unbekannt")
            ).toString();
        },

        getOpportunityTeamNameById(opportunityTeamId: string) {
            return (
                opportunityTeamsStore.getOpportunityTeamById(opportunityTeamId)?.name || this.$t("Unbekannt")
            ).toString();
        },

        async getSystemEmailAliases(dealerId: string) {
            const aliases: SystemEmailAlias[] = await systemEmailAliasesApi.getByDealer(dealerId);
            return aliases.sort((a, b) => a.localPart.localeCompare(b.localPart, userSession.locale));
        },

        getUserFullNameById(userId: string) {
            return (getFullName(usersStore.getUserById(userId)) || this.$t("Unbekannt")).toString();
        },

        toForm(item: SystemEmailAlias): SystemEmailAliasFormWithAutoresponderOption {
            return {
                ...cloneObject(item),
                autoresponderEnabled:
                    !!item.autoresponderInsideOfficeHoursEmailTemplateId ||
                    !!item.autoresponderOutsideOfficeHoursEmailTemplateId,
            };
        },

        getEmailTemplateLabel(emailTemplateId: string) {
            return this.emailTemplateOptions.find((t) => t.value === emailTemplateId)?.text;
        },

        getEmailTemplateHint(emailTemplateId: string) {
            if (!emailTemplateId) {
                return null;
            }
            const emailTemplate = this.emailTemplates.find((t) => t.id === emailTemplateId)!;
            return emailTemplate.content.match(/%USER_.*?%/) || emailTemplate.content.match(/%EMAIL_QUOTE%/)
                ? this.$t(
                      "Die ausgewählte Vorlage enthält Platzhalter, die nicht für automatische Antworten verwendet werden können."
                  )
                : null;
        },

        getOfficeHoursNameById(id: string) {
            return officeHoursStore.getOfficeHoursById(id)?.name;
        },

        emailTemplateRules(form: SystemEmailAliasFormWithAutoresponderOption) {
            return form.autoresponderOfficeHoursId &&
                (form.autoresponderInsideOfficeHoursEmailTemplateId ||
                    form.autoresponderOutsideOfficeHoursEmailTemplateId)
                ? []
                : notEmpty();
        },
    },

    async created() {
        const [emailTemplates, emailDomainIds] = await Promise.all([
            emailTemplatesApi.list(false),
            emailDomainsApi.getAllIds(),
        ]);
        this.emailTemplates = emailTemplates;
        this.emailDomainIds = emailDomainIds;
    },

    components: {
        CaseTypePicker,
        CrudPage,
        DAutocomplete,
        EnumField,
        CopyToClipboardIcon,
        DealerContextGuard,
    },
});
