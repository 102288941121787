
import { OrderType } from "@/api/carryDispo";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { CarryDispoAssistantState } from "@/app/pages/carrydispo/assistant/carryDispoAssistant";
import { e164, maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        carryDispoAssistantState: {
            type: Object as () => CarryDispoAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            e164,
            maxLength,
            notEmpty,
            OrderType,
        };
    },

    computed: {
        contractorCustomerPhoneAutocompleteNumbers(): string[] {
            return (this.carryDispoAssistantState.contactData?.numbers ?? []).map((n) => n.number);
        },

        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },
    },

    components: {
        EnumField,
        PhoneNumberField,
    },
});
