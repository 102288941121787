import {
    ContactNumber,
    ContactSalutation,
    ContactSource,
    ContactStatus,
    ContactType,
    ContactVehicleRecordType,
    EmailAddress,
    MileageUnit,
} from "./contacts";
import { VehicleClass } from "./makeModels";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface ContactSearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

export interface ContactSearchRequest {
    readonly dealerIds: string[];
    readonly contactSources: ContactSource[];
    readonly fullName: string | null;
    readonly companyName: string | null;
    readonly number: string | null;
    readonly emailAddress: string | null;
    readonly licensePlate: string | null;
    readonly vin: string | null;
    readonly address: string | null;
    readonly externalReference: string | null;
    readonly search: string | null;
    readonly updatedFrom: Date | null;
    readonly updatedTo: Date | null;
}

export interface ContactSearchResult {
    readonly id: string;
    readonly updated: Date | null;
    readonly source: ContactSource | null;
    readonly dealerId: string | null;
    readonly status: ContactStatus | null;
    readonly type: ContactType | null;
    readonly salutation: ContactSalutation | null;
    readonly knownSince: string | null;
    readonly dateOfBirth: string | null;
    readonly companyName: string | null;
    readonly position: string | null;
    readonly department: string | null;
    readonly namePrefix: string | null;
    readonly givenName: string | null;
    readonly middleName: string | null;
    readonly familyName: string | null;
    readonly nameSuffix: string | null;
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
    readonly numbers: ContactNumber[];
    readonly emailAddresses: EmailAddress[];
    readonly contactVehicleSearchResults: ContactVehicleSearchResult[];
    readonly additionalVehicleCount: number | null;
    readonly externalReferences: string[] | null;
    readonly url: string | null;
}

export interface ContactSearchResults {
    readonly searchId: number;
    readonly totalSize: number;
    readonly results: ContactSearchResult[];
}

export interface ContactVehicleSearchResult {
    readonly id: string;
    readonly vin: string | null;
    readonly licensePlate: string | null;
    readonly vehicleClass: VehicleClass | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly modelYear: string | null;
    readonly lastRecordDate: string | null;
    readonly lastRecordType: ContactVehicleRecordType | null;
    readonly lastRecordMileage: number | null;
    readonly lastRecordMileageUnit: MileageUnit | null;
    readonly url: string | null;
}

interface ContactSearchApi {
    rebuild(): Promise<void>;
    search(
        start: number,
        size: number,
        searchRequest: ContactSearchRequest,
        searchId: number,
        weakenTextSearchConditionIfResultIsEmpty: boolean
    ): Promise<ContactSearchResults>;
    size(): Promise<ContactSearchIndexSize>;
}

export const contactSearchApi: ContactSearchApi = {
    async rebuild() {
        await axios.post("/api/contact-search/_rebuild");
    },

    async search(start, size, contactSearchRequest, searchId, weakenTextSearchConditionIfResultIsEmpty) {
        return cloneObject(
            (
                await axios.post("/api/contact-search/_search", contactSearchRequest, {
                    params: {
                        start,
                        size,
                        searchId,
                        weakenTextSearchConditionIfResultIsEmpty,
                    },
                })
            ).data
        );
    },

    async size() {
        return (await axios.get("/api/contact-search/_size")).data;
    },
};
