
import { CallTrackingGroup, callTrackingGroupsApi, IncomingCallDistributionType } from "@/api/callTrackingGroups";
import { AutoAnswerType, incomingPhoneNumbersApi, InternalIncomingPhoneNumber } from "@/api/incomingPhoneNumbers";
import { OpportunitySource, OpportunitySourceForm, opportunitySourcesApi } from "@/api/opportunitySources";
import { QualityAssuranceLevel, SystemEmailAlias, systemEmailAliasesApi, TypeHint } from "@/api/systemEmailAliases";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import CrudPage from "@/app/pages/CrudPage.vue";
import { getFullName } from "@/app/userUtils";
import { maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { officeHoursStore } from "@/store/officeHours";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            AutoAnswerType,
            internalIncomingPhoneNumbers: [] as InternalIncomingPhoneNumber[],
            loading: true,
            callTrackingGroups: [] as CallTrackingGroup[],
            QualityAssuranceLevel,
            systemEmailAliases: [] as SystemEmailAlias[],
            TypeHint,
            mergeDialogVisible: false,
            mergeSourceId: null as string | null,
            mergeTargetId: null as string | null,
            merging: false,
        };
    },
    computed: {
        transactionEmailHost() {
            return configStore.configuration.transactionEmailHost;
        },

        maxLength() {
            return maxLength;
        },

        notEmpty() {
            return notEmpty;
        },

        mergeOptions(): SelectOptions {
            return opportunitySourcesStore.opportunitySources
                .filter((s) => s.id !== this.mergeSourceId)
                .map((s) => ({
                    value: s.id,
                    text: s.name,
                }));
        },
    },

    methods: {
        async addOpportunitySource(opportunitySourceForm: OpportunitySourceForm) {
            try {
                await opportunitySourcesApi.add(opportunitySourceForm);
                return true;
            } catch (e) {
                return false;
            }
        },

        async editOpportunitySource(opportunitySourceId: string, opportunitySourceForm: OpportunitySourceForm) {
            try {
                await opportunitySourcesApi.edit(opportunitySourceId, opportunitySourceForm);
                return true;
            } catch (e) {
                return false;
            }
        },

        emptyForm(): OpportunitySourceForm {
            return {
                name: "",
                notes: null,
            };
        },

        getCallTrackingGroupByInternalIncomingPhoneNumber(
            internalIncomingPhoneNumber: InternalIncomingPhoneNumber
        ): CallTrackingGroup | null {
            return (
                this.callTrackingGroups.find((g) => g.id === internalIncomingPhoneNumber.callTrackingGroupId) ?? null
            );
        },

        getCallTrackingGroupName(callTrackingGroupId: string): string | null {
            return this.callTrackingGroups.find((g) => g.id === callTrackingGroupId)?.name ?? null;
        },

        getDealerNameById(dealerId: string): string | null {
            return dealersStore.dealerById(dealerId)?.name ?? null;
        },

        getInternalIncomingPhoneNumbers(opportunitySourceId: string, dealerId: string | null) {
            return this.internalIncomingPhoneNumbers
                .filter((s) => s.opportunitySourceId === opportunitySourceId)
                .filter((s) => s.dealerId === dealerId);
        },

        getInternalIncomingPhoneNumberDistributeOnlyIfAvailable(
            internalIncomingPhoneNumber: InternalIncomingPhoneNumber
        ): boolean | null {
            const callTrackingGroup = this.getCallTrackingGroupByInternalIncomingPhoneNumber(
                internalIncomingPhoneNumber
            );

            return callTrackingGroup
                ? callTrackingGroup.distributeOnlyIfAvailable
                : internalIncomingPhoneNumber.distributeOnlyIfAvailable;
        },

        getInternalIncomingPhoneNumberDistributeOnlyIfInWorkingHours(
            internalIncomingPhoneNumber: InternalIncomingPhoneNumber
        ): boolean | null {
            const callTrackingGroup = this.getCallTrackingGroupByInternalIncomingPhoneNumber(
                internalIncomingPhoneNumber
            );

            return callTrackingGroup
                ? callTrackingGroup.distributeOnlyIfInWorkingHours
                : internalIncomingPhoneNumber.distributeOnlyIfInWorkingHours;
        },

        getInternalIncomingPhoneNumberDistributionOfficeHoursId(
            internalIncomingPhoneNumber: InternalIncomingPhoneNumber
        ): string | null {
            const callTrackingGroup = this.getCallTrackingGroupByInternalIncomingPhoneNumber(
                internalIncomingPhoneNumber
            );

            return callTrackingGroup
                ? callTrackingGroup.distributionOfficeHoursId
                : internalIncomingPhoneNumber.distributionOfficeHoursId;
        },

        getInternalIncomingPhoneNumberDistributionTimeoutSeconds(
            internalIncomingPhoneNumber: InternalIncomingPhoneNumber
        ): number | null {
            const callTrackingGroup = this.getCallTrackingGroupByInternalIncomingPhoneNumber(
                internalIncomingPhoneNumber
            );

            return callTrackingGroup
                ? callTrackingGroup.distributionTimeoutSeconds
                : internalIncomingPhoneNumber.distributionTimeoutSeconds;
        },

        getInternalIncomingPhoneNumberDistributionType(
            internalIncomingPhoneNumber: InternalIncomingPhoneNumber
        ): IncomingCallDistributionType | null {
            const callTrackingGroup = this.getCallTrackingGroupByInternalIncomingPhoneNumber(
                internalIncomingPhoneNumber
            );

            return callTrackingGroup
                ? callTrackingGroup.distributionType
                : internalIncomingPhoneNumber.distributionType;
        },

        getInternalIncomingPhoneNumberIsAutoAnswerTransferToCallTrackingNumber(
            internalIncomingPhoneNumber: InternalIncomingPhoneNumber
        ): boolean {
            return (
                internalIncomingPhoneNumber.autoAnswerType === AutoAnswerType.TRANSFER_TO_CUSTOM_NUMBER &&
                !!this.internalIncomingPhoneNumbers.find(
                    (n) => n.number === internalIncomingPhoneNumber.autoAnswerCustomNumber
                )
            );
        },

        getInternalIncomingPhoneNumberTargetUserIds(
            internalIncomingPhoneNumber: InternalIncomingPhoneNumber
        ): string[] {
            const callTrackingGroup = this.getCallTrackingGroupByInternalIncomingPhoneNumber(
                internalIncomingPhoneNumber
            );

            return [...internalIncomingPhoneNumber.targetUserIds, ...(callTrackingGroup?.targetUserIds ?? [])].filter(
                (targetUserId, index, array) => array.indexOf(targetUserId) === index
            );
        },

        getOfficeHoursNameById(officeHoursId: string): string | null {
            return officeHoursStore.getOfficeHoursById(officeHoursId)?.name ?? null;
        },

        getOpportunitySources() {
            return opportunitySourcesApi.getAll();
        },

        getOpportunityTeamNameById(opportunityTeamId: string) {
            return opportunityTeamsStore.getOpportunityTeamById(opportunityTeamId)?.name ?? null;
        },

        getSystemEmailAliases(opportunitySourceId: string, dealerId: string | null) {
            return this.systemEmailAliases
                .filter((s) => s.sourceId === opportunitySourceId)
                .filter((s) => s.dealerId === dealerId);
        },

        getUserFullNameById(userId: string): string | null {
            const user = usersStore.getUserById(userId);

            return user ? getFullName(user) : null;
        },

        async mergeOpportunitySource() {
            if (!(this.$refs.mergeForm as any).validate()) {
                return;
            }

            this.merging = true;
            try {
                await opportunitySourcesApi.merge(this.mergeSourceId!, this.mergeTargetId!);
                this.mergeDialogVisible = false;
            } finally {
                this.merging = false;
            }
            (this.$refs.crudPage as any).loadItems();
        },

        showMergeDialog(opportunitySource: OpportunitySource) {
            this.mergeSourceId = opportunitySource.id;
            this.mergeTargetId = null;
            this.mergeDialogVisible = true;
            return false;
        },

        toForm(item: OpportunitySource): OpportunitySourceForm {
            return cloneObject(item);
        },

        async updateOrder(items: OpportunitySource[]) {
            await opportunitySourcesApi.updateOrder(items.map((i) => i.id));
        },
    },

    async mounted() {
        try {
            const [internalIncomingPhoneNumbers, systemEmailAliases, callTrackingGroups] = await Promise.all([
                incomingPhoneNumbersApi.getAllInternal(),
                systemEmailAliasesApi.getAll(),
                callTrackingGroupsApi.getAll(),
            ]);
            this.internalIncomingPhoneNumbers = internalIncomingPhoneNumbers.sort((a, b) =>
                a.number.localeCompare(b.number, userSession.locale)
            );
            this.systemEmailAliases = systemEmailAliases.sort((a, b) =>
                a.localPart.localeCompare(b.localPart, userSession.locale)
            );
            this.callTrackingGroups = callTrackingGroups;
        } finally {
            this.loading = false;
        }
    },

    components: {
        CopyToClipboardIcon,
        CrudPage,
    },
});
