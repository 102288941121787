import { Contact } from "./contacts";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum ActorType {
    USER = "USER",
    CT_USER = "CT_USER",
    SYSTEM = "SYSTEM",
}

export enum FeedbackType {
    INFO = "INFO",
    ERROR_WRONG_LICENSE_PLATE = "ERROR_WRONG_LICENSE_PLATE",
    ERROR_WRONG_PHONE_NUMBER = "ERROR_WRONG_PHONE_NUMBER",
    ERROR_WRONG_RECEIVER = "ERROR_WRONG_RECEIVER",
    ERROR_OTHER = "ERROR_OTHER",
}

export enum IssueType {
    CASE = "CASE",
    EMERGENCY_INCIDENT = "EMERGENCY_INCIDENT",
    OPPORTUNITY = "OPPORTUNITY",
    UNKNOWN = "UNKNOWN",
}

export enum NoteSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
}

export interface Note {
    readonly id: string;
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly creatorCtUserId: string | null;
    readonly caseId: string | null;
    readonly emergencyIncidentId: string | null;
    readonly opportunityId: string | null;
    readonly content: string | null;
    readonly feedbackCreated: Date | null;
    readonly feedbackType: FeedbackType | null;
    readonly comment: string | null;
    readonly reporterUserId: string;
    readonly dealerId: string | null;
}

export interface NoteAndContact {
    readonly note: Note;
    readonly contact: Contact | null;
}

export interface NoteForm {
    content: string | null;
}

export interface NoteSearchRequest {
    readonly dealerIds: string[];
    readonly creatorUserIds: string[];
    readonly creatorActorTypes: ActorType[];
    readonly issueTypes: IssueType[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly feedbackTypes: (FeedbackType | null)[];
    readonly sortBy: NoteSearchOrder;
}

export interface NoteSearchResults {
    readonly results: NoteAndContact[];
    readonly searchId: number;
    readonly totalSize: number;
}

export interface ReportFeedbackForm {
    readonly resourceId: string;
    readonly feedbackType: FeedbackType;
    readonly comment: string | null;
    readonly reporterPhone: string | null;
}

interface NotesApi {
    getByCase(caseId: string): Promise<Note[]>;
    getByEmergencyIncident(emergencyIncidentId: string): Promise<Note[]>;
    getByOpportunity(opportunityId: string): Promise<Note[]>;
    getMyNotes(start: number, size: number, searchId: number): Promise<NoteSearchResults>;
    reportFeedback(reportFeedbackForm: ReportFeedbackForm): Promise<void>;
    search(start: number, size: number, searchRequest: NoteSearchRequest, searchId: number): Promise<NoteSearchResults>;
}

export const notesApi: NotesApi = {
    async getByCase(caseId) {
        return cloneObject((await axios.get(`/api/notes/cases/${caseId}`)).data);
    },

    async getByEmergencyIncident(emergencyIncidentId) {
        return cloneObject((await axios.get(`/api/notes/emergency-incidents/${emergencyIncidentId}`)).data);
    },

    async getByOpportunity(opportunityId) {
        return cloneObject((await axios.get(`/api/notes/opportunities/${opportunityId}`)).data);
    },

    async getMyNotes(start, size, searchId) {
        return cloneObject(
            (
                await axios.get("/api/notes/_my-notes", {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },

    async reportFeedback(reportFeedbackForm) {
        await axios.post("/api/notes/_report-feedback", reportFeedbackForm);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/notes/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },
};
