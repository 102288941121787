var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('crud-page',{ref:"crudPage",attrs:{"icon":"mdi-account-cog","no-items-text":_vm.$t('Es wurden keine Dienstkonten gefunden.'),"add-dialog-title":_vm.$t('Dienstkonto hinzufügen'),"page-title":_vm.$t('Dienstkonten'),"callbacks":{
        load: () => _vm.api.serviceAccounts(),
        add: (_, form) => _vm.addServiceAccount(form),
        delete: (item) => _vm.deleteServiceAccount(item),
        key: (item) => item.id,
        emptyForm: () => _vm.emptyForm(),
    },"global":true},scopedSlots:_vm._u([{key:"title",fn:function({ item }){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.name))]),(item.readOnly)?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","color":"warning"}},[_vm._v(_vm._s(_vm.$t("Nur Lesezugriff")))]):_vm._e()]}},{key:"content",fn:function({ item }){return [_c('v-card-text',[_c('div',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm._f("instant")(item.created,"L"))+" ")],1),_c('div',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-tag")]),_vm._v(" "+_vm._s(item.id)+" ")],1),_c('div',_vm._l((item.subscriptionIds),function(subscriptionId){return _c('v-tooltip',{key:subscriptionId,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","close":""},on:{"click:close":function($event){return _vm.deleteSubscription(item.id, subscriptionId)}}},on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-webhook")]),_vm._v(" "+_vm._s(subscriptionId)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("API Subscription")))])])}),1)])]}},{key:"dialog",fn:function({ form }){return [_c('v-text-field',{attrs:{"label":_vm.$t('Name des Dienstkontos'),"rules":_vm.notEmpty().maxLength(63),"id":_vm.$id(),"autocomplete":"no","autofocus":""},model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}}),_c('v-switch',{attrs:{"label":_vm.$t('Nur Lesezugriff')},model:{value:(form.readOnly),callback:function ($$v) {_vm.$set(form, "readOnly", $$v)},expression:"form.readOnly"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }