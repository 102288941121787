
import { emailPlaceholders } from "./emailPlaceholders";
import { EmailTemplate, EmailTemplateAttachment, EmailTemplateForm, emailTemplatesApi } from "@/api/emailTemplates";
import FileDropZone from "@/app/components/FileDropZone.vue";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { downloadOrOpenFile, fileDialog } from "@/app/fileUtils";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        emailTemplate: {
            type: Object as () => EmailTemplate,
            required: false,
        },
        fragment: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isWorking: false,
            name: this.emailTemplate?.name || "",
            nameRules: notEmpty().maxLength(300),
            content: this.emailTemplate?.content || "",
            contentRules: notEmpty().maxLength(4_194_303),
            locale: this.emailTemplate?.locale || configStore.configuration.defaultLocale,
            attachments: this.emailTemplate?.attachments || [],
            previewFile: null as File | string | null,
            files: [] as File[],
        };
    },

    computed: {
        placeholders(): string[] {
            return [...emailPlaceholders, "LINK_<key>"];
        },

        locales(): SelectOptions {
            return this.$i18n.availableLocales.map((k) => ({ value: k, text: this.$t(`locale.${k}`) }));
        },

        acceptedFileTypes(): string[] {
            return configStore.configuration.whitelistedOutgoingFileExtensions;
        },
    },

    methods: {
        appendToContent(placeholder: string) {
            this.content += placeholder;
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            const form: EmailTemplateForm = {
                name: this.name,
                content: this.content,
                locale: this.locale,
                attachments: this.attachments,
            };

            try {
                if (this.emailTemplate) {
                    await emailTemplatesApi.edit(this.emailTemplate.id, form, this.files);
                } else {
                    await emailTemplatesApi.add(form, this.fragment, this.files);
                }
                this.$emit("success");
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },

        notSupportedForAutoReply(placeholder: string) {
            return placeholder.startsWith("USER_") || placeholder === "EMAIL_QUOTE";
        },

        showFileDialog() {
            fileDialog(true, this.addFiles, this.acceptedFileTypes);
        },

        addFiles(files: File[]) {
            for (const file of files) {
                this.files.push(file);
            }
        },

        showAttachment(attachment: EmailTemplateAttachment, download: boolean) {
            const fname = attachment.filename.toLowerCase();
            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.previewFile = emailTemplatesApi.generateAttachmentLink(attachment);
            } else {
                window.open(emailTemplatesApi.generateAttachmentLink(attachment, download));
            }
        },

        removeAttachment(attachment: EmailTemplateAttachment) {
            this.attachments = this.attachments.filter((a) => a !== attachment);
        },

        showFile(file: File, download: boolean) {
            const fname = file.name.toLowerCase();
            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.previewFile = file;
            } else {
                downloadOrOpenFile(file, download);
            }
        },

        removeFile(file: File) {
            this.files = this.files.filter((f) => f !== file);
        },
    },

    components: {
        FileDropZone,
        ImageCarousel,
        WysiwygEditor: () => import("@/app/components/WysiwygEditor.vue"),
    },
});
