
import { ContactVehicle } from "@/api/contacts";
import { renderVehicleTitle } from "@/app/contactUtils";
import { userSession } from "@/store/userSession";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        canAdd: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        value: {
            type: String,
            required: false,
        },
        vehicles: {
            type: Array as () => ContactVehicle[],
            required: true,
        },
        working: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            selectedContactVehicleId: this.value,
        };
    },

    computed: {
        vehicleOptions(): SelectOptions {
            return [
                ...this.vehicles
                    .map((k) => ({
                        value: k.id,
                        text: renderVehicleTitle(k.contactVehicleData, true),
                    }))
                    .sort((a, b) => a.text.localeCompare(b.text, userSession.locale)),
            ];
        },
    },

    watch: {
        selectedContactVehicleId() {
            this.$emit("input", this.selectedContactVehicleId || null);
        },

        value() {
            this.selectedContactVehicleId = this.value;
        },
    },
});
