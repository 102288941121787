
import { userSessionApi } from "@/api/userSession";
import { AwayForm, AwayType } from "@/api/users";
import DatePicker from "@/app/components/DatePicker.vue";
import TimePicker from "@/app/components/TimePicker.vue";
import { maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { getDate, getTime, getWeek } from "@/util/dateTimeUtils";
import { SelectOptions } from "@/util/types";
import moment from "moment-timezone"; // TODO GET RID OF
import Vue from "vue";

enum Validity {
    NEVER = "NEVER",
    M30 = "M30",
    H1 = "H1",
    H4 = "H4",
    DAY = "DAY",
    WEEK = "WEEK",
    CUSTOM = "CUSTOM",
}

export default Vue.extend({
    data() {
        const away = usersStore.getAwayByUser(userSession.userId!);
        return {
            Validity,
            valid: true,
            isWorking: false,

            // FORM FIELDS
            type: away?.type || AwayType.OTHER,
            notes: away?.notes || "",
            validity: Validity.CUSTOM,
            untilDate: getDate(away?.until || now(), userSession.timeZone),
            untilTime: away?.until ? getTime(away.until!, userSession.timeZone) : "23:59",

            // RULES
            notesRules: maxLength(1000),
            notEmptyRules: notEmpty(),
        };
    },

    computed: {
        until(): Date | null {
            if (this.validity === Validity.NEVER) {
                return null;
            }
            return moment.tz(this.untilDate + "T" + this.untilTime + ":59.999", userSession.timeZone).toDate();
        },
        awayTypes(): SelectOptions {
            return [
                { value: "OTHER", text: this.$t("keine Angabe") },
                ...Object.keys(AwayType).map((k) => ({ value: k, text: this.$t(`enum.AwayType.${k}`) })),
            ];
        },
        validities(): SelectOptions {
            return [
                { value: Validity.NEVER, text: this.$t("Niemals") },
                { value: Validity.M30, text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 30) },
                { value: Validity.H1, text: this.$tc("0 Stunde | 1 Stunde | {count} Stunden", 1) },
                { value: Validity.H4, text: this.$tc("0 Stunde | 1 Stunde | {count} Stunden", 4) },
                { value: Validity.DAY, text: this.$t("Heute") },
                { value: Validity.WEEK, text: this.$t("Dieser Woche") },
                { value: Validity.CUSTOM, text: this.$t("Datum und Zeit wählen") },
            ];
        },
    },

    watch: {
        validity(value: Validity) {
            this.untilDate = this.calculateValidUntilDate(value);
            this.untilTime = this.calculateValidUntilTime(value);
        },
    },

    methods: {
        calculateValidUntilDate(validity: Validity) {
            if (validity === Validity.M30) {
                return getDate(now(), userSession.timeZone, 0, 0, 30);
            } else if (validity === Validity.H1) {
                return getDate(now(), userSession.timeZone, 0, 1);
            } else if (validity === Validity.H4) {
                return getDate(now(), userSession.timeZone, 0, 4);
            } else if (validity === Validity.WEEK) {
                const week = getWeek(now(), 0, userSession.timeZone, configStore.configuration.defaultLocale);
                return week.end;
            }

            // NEVER, DAY, CUSTOM
            return getDate(now(), userSession.timeZone);
        },

        calculateValidUntilTime(validity: Validity) {
            if (validity === Validity.M30) {
                return getTime(now(), userSession.timeZone, 0, 0, 30);
            } else if (validity === Validity.H1) {
                return getTime(now(), userSession.timeZone, 0, 1);
            } else if (validity === Validity.H4) {
                return getTime(now(), userSession.timeZone, 0, 4);
            }

            // NEVER, DAY, WEEK, CUSTOM
            return "23:59";
        },

        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            const awayForm: AwayForm = {
                type: this.type,
                notes: this.notes,
                until: this.until,
            };

            try {
                await userSessionApi.updateAway(awayForm);
            } finally {
                this.isWorking = false;
            }
            this.$emit("close");
        },

        close() {
            this.$emit("close");
        },
    },

    components: {
        DatePicker,
        TimePicker,
    },

    created() {
        const away = usersStore.getAwayByUser(userSession.userId!);

        if (!away) {
            this.validity = Validity.DAY;
        } else if (away?.until) {
            if (
                this.calculateValidUntilDate(Validity.DAY) === this.untilDate &&
                this.calculateValidUntilTime(Validity.DAY) === this.untilTime
            ) {
                this.validity = Validity.DAY;
            } else if (
                this.calculateValidUntilDate(Validity.WEEK) === this.untilDate &&
                this.calculateValidUntilTime(Validity.WEEK) === this.untilTime
            ) {
                this.validity = Validity.WEEK;
            }
        } else {
            this.validity = Validity.NEVER;
        }
    },
});
