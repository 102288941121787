
import IpAddressRangeDialog from "./IpAddressRangeDialog.vue";
import {
    TwoFactorAuthenticationSettings,
    twoFactorAuthenticationSettingsApi,
} from "@/api/twoFactorAuthenticationSettings";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import { showConfirm } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            ipAddressRangeDialogVisible: false,
            saving: false,
            settings: {
                enabled: false,
                exceptionalUserIds: [],
                exceptionalIpAddressRanges: [],
            } as TwoFactorAuthenticationSettings,
        };
    },

    computed: {
        userOptions(): SelectOptions {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },
    },

    methods: {
        async addAddressRange(range: string) {
            const settings = cloneObject(this.settings);
            settings.exceptionalIpAddressRanges.push(range);

            await twoFactorAuthenticationSettingsApi.editSettings(settings);

            this.settings = settings;
            this.ipAddressRangeDialogVisible = false;
        },

        async deleteAddressRange(range: string) {
            const index = this.settings.exceptionalIpAddressRanges.findIndex((item) => item === range);

            if (index === -1) {
                return;
            }

            if (
                !(await showConfirm(
                    this.$t("IP-Ausnahme löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die IP-Ausnahme löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.settings.exceptionalIpAddressRanges.splice(index, 1);
            await this.saveSettings();
        },

        async saveSettings() {
            this.saving = true;
            try {
                await twoFactorAuthenticationSettingsApi.editSettings(this.settings);
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        try {
            this.settings = await twoFactorAuthenticationSettingsApi.getSettings();
        } finally {
            this.loading = false;
        }
    },

    components: {
        DAutocomplete,
        DealerGlobalContextHint,
        IpAddressRangeDialog,
    },
});
