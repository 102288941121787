import { CaseOutcomeReason } from "./caseOutcomeReasons";
import { CaseSettings } from "./caseSettings";
import { Dealer } from "./dealers";
import { Department } from "./departments";
import { EscalationGroup } from "./escalationGroups";
import { ExternalInventoryManagementSettings } from "./externalInventoryManagementSettings";
import { IncomingCallResult } from "./incomingCalls";
import { ProcessingState as IncomingEmailProcessingState } from "./incomingEmails";
import { ProcessingState as IncomingSmsProcessingState } from "./incomingSms";
import { ProcessingState as IncomingWhatsAppMessageProcessingState } from "./incomingWhatsApp";
import { InventoryVehicleStatus } from "./inventory";
import { MakeModelSettings } from "./makeModelSettings";
import { OfficeHours } from "./officeHours";
import { OpportunityOutcomeReason } from "./opportunityOutcomeReasons";
import { OpportunitySettings, OpportunitySettingsExceptionalUser } from "./opportunitySettings";
import { OpportunitySource } from "./opportunitySources";
import { OpportunityTeam } from "./opportunityTeams";
import { OutgoingCall } from "./outgoingCalls";
import { OutgoingEmailEvent } from "./outgoingEmails";
import { SmsStatus } from "./outgoingSms";
import { OutgoingWhatsAppMessageStatus } from "./outgoingWhatsApp";
import { ReportingDealerSettings, ReportingSettings } from "./reportingSettings";
import { Status, UserDirectoryEntry } from "./users";
import { cloneObject } from "@/util/cloneUtils";
import { ReconnectingEventSource } from "@/util/eventSource";

export interface Notification {
    readonly timestamp: Date;
    readonly type: string;
}

export interface UserStatusChanged {
    readonly userId: string;
    readonly status: Status;
    readonly lastActive: Date | null;
}

export interface UsersStatusChangedNotification extends Notification {
    readonly statuses: UserStatusChanged[];
}

export function isUsersStatusChangedNotification(n: Notification): n is UsersStatusChangedNotification {
    return n.type === "USERS_STATUS_CHANGED";
}

export interface InventoryVehicleStatusNotification extends Notification {
    readonly inventoryVehicleId: string;
    readonly inventoryVehicleStatus: InventoryVehicleStatus;
}

export function isInventoryVehicleStatusNotification(n: Notification): n is InventoryVehicleStatusNotification {
    return n.type === "INVENTORY_VEHICLE_STATUS";
}

export interface UserChangedNotification extends Notification {
    readonly user: UserDirectoryEntry;
}

export function isUserChangedNotification(n: Notification): n is UserChangedNotification {
    return n.type === "USER_CHANGED";
}

export function isUserProfileChangedNotification(n: Notification) {
    return n.type === "USER_PROFILE_CHANGED";
}

export interface UserDeletedNotification extends Notification {
    readonly userId: string;
}

export function isUserDeletedNotification(n: Notification): n is UserDeletedNotification {
    return n.type === "USER_DELETED";
}

export function isAnnouncementNotification(n: Notification) {
    return n.type === "ANNOUNCEMENT";
}

export function isChangelogNotification(n: Notification) {
    return n.type === "CHANGELOG";
}

export interface DealerChangedNotification extends Notification {
    readonly dealer: Dealer;
}

export function isDealerChangedNotification(n: Notification): n is DealerChangedNotification {
    return n.type === "DEALER_CHANGED";
}

export function isInventoryImportNotification(n: Notification) {
    return n.type === "INVENTORY_IMPORT";
}

export interface DepartmentChangedNotification extends Notification {
    readonly department: Department;
}

export function isDepartmentChangedNotification(n: Notification): n is DepartmentChangedNotification {
    return n.type === "DEPARTMENT_CHANGED";
}

export interface DepartmentDeletedNotification extends Notification {
    readonly departmentId: string;
}

export function isDepartmentDeletedNotification(n: Notification): n is DepartmentDeletedNotification {
    return n.type === "DEPARTMENT_DELETED";
}

export interface DepartmentsOrderChangedNotification extends Notification {
    readonly orderedDepartmentIds: string[];
}

export function isDepartmentsOrderChangedNotification(n: Notification): n is DepartmentsOrderChangedNotification {
    return n.type === "DEPARTMENTS_ORDER_CHANGED";
}

export function isEmailDomainUpdatedNotification(n: Notification) {
    return n.type === "EMAIL_DOMAIN";
}

export function isCaseVisibilityNotification(n: Notification) {
    return n.type === "CASE_VISIBILITY";
}

export function isOpportunityVisibilityNotification(n: Notification) {
    return n.type === "OPPORTUNITY_VISIBILITY";
}

export interface CaseUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseUpdatedNotification(n: Notification): n is CaseUpdatedNotification {
    return n.type === "CASE_UPDATED";
}

export interface EmergencyIncidentUpdatedNotification extends Notification {
    readonly emergencyIncidentId: string;
}

export function isEmergencyIncidentUpdatedNotification(n: Notification): n is EmergencyIncidentUpdatedNotification {
    return n.type === "EMERGENCY_INCIDENT_UPDATED";
}

export function isEmergencyIncidentVisibilityNotification(n: Notification) {
    return n.type === "EMERGENCY_INCIDENT_VISIBILITY";
}

export interface OpportunityUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityUpdatedNotification(n: Notification): n is OpportunityUpdatedNotification {
    return n.type === "OPPORTUNITY_UPDATED";
}

export interface CaseReadReceiptsUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseReadReceiptsUpdatedNotification(n: Notification): n is CaseReadReceiptsUpdatedNotification {
    return n.type === "CASE_READ_RECEIPTS_UPDATED";
}

export interface OpportunityReadReceiptsUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityReadReceiptsUpdatedNotification(
    n: Notification
): n is OpportunityReadReceiptsUpdatedNotification {
    return n.type === "OPPORTUNITY_READ_RECEIPTS_UPDATED";
}

export interface CaseNotesUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseNotesUpdatedNotification(n: Notification): n is CaseNotesUpdatedNotification {
    return n.type === "CASE_NOTES_UPDATED";
}

export interface EmergencyIncidentNotesUpdatedNotification extends Notification {
    readonly emergencyIncidentId: string;
}

export function isEmergencyIncidentNotesUpdatedNotification(
    n: Notification
): n is EmergencyIncidentNotesUpdatedNotification {
    return n.type === "EMERGENCY_INCIDENT_NOTES_UPDATED";
}

export interface OpportunityNotesUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityNotesUpdatedNotification(n: Notification): n is OpportunityNotesUpdatedNotification {
    return n.type === "OPPORTUNITY_NOTES_UPDATED";
}

export interface CaseAuditEventsUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseAuditEventsUpdatedNotification(n: Notification): n is CaseAuditEventsUpdatedNotification {
    return n.type === "CASE_AUDIT_EVENTS_UPDATED";
}

export interface OpportunityAuditEventsUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityAuditEventsUpdatedNotification(
    n: Notification
): n is OpportunityAuditEventsUpdatedNotification {
    return n.type === "OPPORTUNITY_AUDIT_EVENTS_UPDATED";
}

export interface OutgoingCallUpdatedNotification extends Notification {
    readonly outgoingCall: OutgoingCall;
}

export function isOutgoingCallUpdatedNotification(n: Notification): n is OutgoingCallUpdatedNotification {
    return n.type === "OUTGOING_CALL_UPDATED";
}

export interface CaseOutgoingCallsUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseOutgoingCallsUpdatedNotification(n: Notification): n is CaseOutgoingCallsUpdatedNotification {
    return n.type === "CASE_OUTGOING_CALLS_UPDATED";
}

export interface OpportunityOutgoingCallsUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityOutgoingCallsUpdatedNotification(
    n: Notification
): n is OpportunityOutgoingCallsUpdatedNotification {
    return n.type === "OPPORTUNITY_OUTGOING_CALLS_UPDATED";
}

export interface IncomingCallUpdatedNotification extends Notification {
    readonly incomingCall: IncomingCallResult;
}

export function isIncomingCallUpdatedNotification(n: Notification): n is IncomingCallUpdatedNotification {
    return n.type === "INCOMING_CALL_UPDATED";
}

export interface CaseIncomingCallsUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseIncomingCallsUpdatedNotification(n: Notification): n is CaseIncomingCallsUpdatedNotification {
    return n.type === "CASE_INCOMING_CALLS_UPDATED";
}

export interface OpportunityIncomingCallsUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityIncomingCallsUpdatedNotification(
    n: Notification
): n is OpportunityIncomingCallsUpdatedNotification {
    return n.type === "OPPORTUNITY_INCOMING_CALLS_UPDATED";
}

export interface OutgoingSmsUpdatedNotification extends Notification {
    readonly id: string;
    readonly status: SmsStatus;
}

export function isOutgoingSmsUpdatedNotification(n: Notification): n is OutgoingSmsUpdatedNotification {
    return n.type === "OUTGOING_SMS_UPDATED";
}

export interface CaseOutgoingSmsUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseOutgoingSmsUpdatedNotification(n: Notification): n is CaseOutgoingSmsUpdatedNotification {
    return n.type === "CASE_OUTGOING_SMS_UPDATED";
}

export interface OpportunityOutgoingSmsUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityOutgoingSmsUpdatedNotification(
    n: Notification
): n is OpportunityOutgoingSmsUpdatedNotification {
    return n.type === "OPPORTUNITY_OUTGOING_SMS_UPDATED";
}

export interface OutgoingEmailEventsUpdatedNotification extends Notification {
    readonly id: string;
    readonly event: OutgoingEmailEvent;
}

export function isOutgoingEmailEventsUpdatedNotification(n: Notification): n is OutgoingEmailEventsUpdatedNotification {
    return n.type === "OUTGOING_EMAIL_EVENTS_UPDATED";
}

export interface CaseOutgoingEmailsUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseOutgoingEmailsUpdatedNotification(n: Notification): n is CaseOutgoingEmailsUpdatedNotification {
    return n.type === "CASE_OUTGOING_EMAILS_UPDATED";
}

export interface OpportunityOutgoingEmailsUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityOutgoingEmailsUpdatedNotification(
    n: Notification
): n is OpportunityOutgoingEmailsUpdatedNotification {
    return n.type === "OPPORTUNITY_OUTGOING_EMAILS_UPDATED";
}

export interface OutgoingWhatsAppUpdatedNotification extends Notification {
    readonly id: string;
    readonly status: OutgoingWhatsAppMessageStatus;
}

export function isOutgoingWhatsAppUpdatedNotification(n: Notification): n is OutgoingWhatsAppUpdatedNotification {
    return n.type === "OUTGOING_WHATSAPP_UPDATED";
}

export interface CaseOutgoingWhatsAppUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseOutgoingWhatsAppUpdatedNotification(
    n: Notification
): n is CaseOutgoingWhatsAppUpdatedNotification {
    return n.type === "CASE_OUTGOING_WHATSAPP_UPDATED";
}

export interface OpportunityOutgoingWhatsAppUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityOutgoingWhatsAppUpdatedNotification(
    n: Notification
): n is OpportunityOutgoingWhatsAppUpdatedNotification {
    return n.type === "OPPORTUNITY_OUTGOING_WHATSAPP_UPDATED";
}

export interface IncomingEmailUpdatedNotification extends Notification {
    readonly id: string;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly spamReported: Date | null;
    readonly processingState: IncomingEmailProcessingState;
    readonly processedAt: Date | null;
}

export function isIncomingEmailUpdatedNotification(n: Notification): n is IncomingEmailUpdatedNotification {
    return n.type === "INCOMING_EMAIL_UPDATED";
}

export interface OpportunityIncomingEmailsUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityIncomingEmailsUpdatedNotification(
    n: Notification
): n is OpportunityIncomingEmailsUpdatedNotification {
    return n.type === "OPPORTUNITY_INCOMING_EMAILS_UPDATED";
}

export interface CaseIncomingEmailsUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseIncomingEmailsUpdatedNotification(n: Notification): n is CaseIncomingEmailsUpdatedNotification {
    return n.type === "CASE_INCOMING_EMAILS_UPDATED";
}

export interface IncomingSmsUpdatedNotification extends Notification {
    readonly id: string;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly processingState: IncomingSmsProcessingState;
    readonly processedAt: Date | null;
}

export function isIncomingSmsUpdatedNotification(n: Notification): n is IncomingSmsUpdatedNotification {
    return n.type === "INCOMING_SMS_UPDATED";
}

export interface CaseIncomingSmsUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseIncomingSmsUpdatedNotification(n: Notification): n is CaseIncomingSmsUpdatedNotification {
    return n.type === "CASE_INCOMING_SMS_UPDATED";
}

export interface OpportunityIncomingSmsUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityIncomingSmsUpdatedNotification(
    n: Notification
): n is OpportunityIncomingSmsUpdatedNotification {
    return n.type === "OPPORTUNITY_INCOMING_SMS_UPDATED";
}

export interface CaseIncomingWhatsAppUpdatedNotification extends Notification {
    readonly caseId: string;
}

export function isCaseIncomingWhatsAppUpdatedNotification(
    n: Notification
): n is CaseIncomingWhatsAppUpdatedNotification {
    return n.type === "CASE_INCOMING_WHATSAPP_UPDATED";
}

export interface OpportunityIncomingWhatsAppUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityIncomingWhatsAppUpdatedNotification(
    n: Notification
): n is OpportunityIncomingWhatsAppUpdatedNotification {
    return n.type === "OPPORTUNITY_INCOMING_WHATSAPP_UPDATED";
}

export interface IncomingWhatsAppMessageUpdatedNotification extends Notification {
    readonly id: string;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly processingState: IncomingWhatsAppMessageProcessingState;
    readonly processedAt: Date | null;
}

export function isIncomingWhatsAppMessageUpdatedNotification(
    n: Notification
): n is IncomingWhatsAppMessageUpdatedNotification {
    return n.type === "INCOMING_WHATSAPP_MESSAGE_UPDATED";
}

export interface EscalationGroupChangedNotification extends Notification {
    readonly escalationGroup: EscalationGroup;
}

export function isEscalationGroupChangedNotification(n: Notification): n is EscalationGroupChangedNotification {
    return n.type === "ESCALATION_GROUP_CHANGED";
}

export interface EscalationGroupDeletedNotification extends Notification {
    readonly escalationGroupId: string;
}

export function isEscalationGroupDeletedNotification(n: Notification): n is EscalationGroupDeletedNotification {
    return n.type === "ESCALATION_GROUP_DELETED";
}

export interface EscalationGroupsOrderChangedNotification extends Notification {
    readonly orderedEscalationGroupIds: string[];
}

export function isEscalationGroupsOrderChangedNotification(
    n: Notification
): n is EscalationGroupsOrderChangedNotification {
    return n.type === "ESCALATION_GROUPS_ORDER_CHANGED";
}

export interface OfficeHoursChangedNotification extends Notification {
    readonly officeHours: OfficeHours;
}

export function isOfficeHoursChangedNotification(n: Notification): n is OfficeHoursChangedNotification {
    return n.type === "OFFICE_HOURS_CHANGED";
}

export interface OfficeHoursDeletedNotification extends Notification {
    readonly officeHoursId: string;
}

export function isOfficeHoursDeletedNotification(n: Notification): n is OfficeHoursDeletedNotification {
    return n.type === "OFFICE_HOURS_DELETED";
}

export interface OfficeHoursOrderChangedNotification extends Notification {
    readonly orderedOfficeHoursIds: string[];
}

export function isOfficeHoursOrderChangedNotification(n: Notification): n is OfficeHoursOrderChangedNotification {
    return n.type === "OFFICE_HOURS_ORDER_CHANGED";
}

export interface OpportunityOutcomeReasonChangedNotification extends Notification {
    readonly opportunityOutcomeReason: OpportunityOutcomeReason;
}

export function isOpportunityOutcomeReasonChangedNotification(
    n: Notification
): n is OpportunityOutcomeReasonChangedNotification {
    return n.type === "OPPORTUNITY_OUTCOME_REASON_CHANGED";
}

export interface OpportunityOutcomeReasonDeletedNotification extends Notification {
    readonly opportunityOutcomeReasonId: string;
}

export function isOpportunityOutcomeReasonDeletedNotification(
    n: Notification
): n is OpportunityOutcomeReasonDeletedNotification {
    return n.type === "OPPORTUNITY_OUTCOME_REASON_DELETED";
}

export interface OpportunityOutcomeReasonsOrderChangedNotification extends Notification {
    readonly orderedOpportunityOutcomeReasonIds: string[];
}

export function isOpportunityOutcomeReasonsOrderChangedNotification(
    n: Notification
): n is OpportunityOutcomeReasonsOrderChangedNotification {
    return n.type === "OPPORTUNITY_OUTCOME_REASONS_ORDER_CHANGED";
}

export interface OpportunitySourceChangedNotification extends Notification {
    readonly opportunitySource: OpportunitySource;
}

export function isOpportunitySourceChangedNotification(n: Notification): n is OpportunitySourceChangedNotification {
    return n.type === "OPPORTUNITY_SOURCE_CHANGED";
}

export interface OpportunitySourceDeletedNotification extends Notification {
    readonly opportunitySourceId: string;
}

export function isOpportunitySourceDeletedNotification(n: Notification): n is OpportunitySourceDeletedNotification {
    return n.type === "OPPORTUNITY_SOURCE_DELETED";
}

export interface OpportunitySourcesOrderChangedNotification extends Notification {
    readonly orderedOpportunitySourceIds: string[];
}

export function isOpportunitySourcesOrderChangedNotification(
    n: Notification
): n is OpportunitySourcesOrderChangedNotification {
    return n.type === "OPPORTUNITY_SOURCES_ORDER_CHANGED";
}

export interface OpportunityTeamChangedNotification extends Notification {
    readonly opportunityTeam: OpportunityTeam;
}

export function isOpportunityTeamChangedNotification(n: Notification): n is OpportunityTeamChangedNotification {
    return n.type === "OPPORTUNITY_TEAM_CHANGED";
}

export interface OpportunityTeamDeletedNotification extends Notification {
    readonly opportunityTeamId: string;
}

export function isOpportunityTeamDeletedNotification(n: Notification): n is OpportunityTeamDeletedNotification {
    return n.type === "OPPORTUNITY_TEAM_DELETED";
}

export interface OpportunityTeamsOrderChangedNotification extends Notification {
    readonly orderedOpportunityTeamIds: string[];
}

export function isOpportunityTeamsOrderChangedNotification(
    n: Notification
): n is OpportunityTeamsOrderChangedNotification {
    return n.type === "OPPORTUNITY_TEAMS_ORDER_CHANGED";
}

export interface OpportunitySettingsChangedNotification extends Notification {
    readonly opportunitySettings: OpportunitySettings;
}

export function isOpportunitySettingsChangedNotification(n: Notification): n is OpportunitySettingsChangedNotification {
    return n.type === "OPPORTUNITY_SETTINGS_CHANGED";
}

export interface OpportunitySettingsExceptionalOutcomeReasonsChangedNotification extends Notification {
    readonly outcomeReasonIds: string[];
}

export function isOpportunitySettingsExceptionalOutcomeReasonsChangedNotification(
    n: Notification
): n is OpportunitySettingsExceptionalOutcomeReasonsChangedNotification {
    return n.type === "OPPORTUNITY_SETTINGS_EXCEPTIONAL_OUTCOME_REASONS_CHANGED";
}

export interface OpportunitySettingsExceptionalOutcomeReasonDeletedNotification extends Notification {
    readonly outcomeReasonId: string;
}

export function isOpportunitySettingsExceptionalOutcomeReasonDeletedNotification(
    n: Notification
): n is OpportunitySettingsExceptionalOutcomeReasonDeletedNotification {
    return n.type === "OPPORTUNITY_SETTINGS_EXCEPTIONAL_OUTCOME_REASON_DELETED";
}

export interface OpportunitySettingsExceptionalUserDeletedNotification extends Notification {
    readonly userId: string;
}

export function isOpportunitySettingsExceptionalUserDeletedNotification(
    n: Notification
): n is OpportunitySettingsExceptionalUserDeletedNotification {
    return n.type === "OPPORTUNITY_SETTINGS_EXCEPTIONAL_USER_DELETED";
}

export interface OpportunitySettingsExceptionalUsersChangedNotification extends Notification {
    readonly exceptionalUsers: OpportunitySettingsExceptionalUser[];
}

export function isOpportunitySettingsExceptionalUsersChangedNotification(
    n: Notification
): n is OpportunitySettingsExceptionalUsersChangedNotification {
    return n.type === "OPPORTUNITY_SETTINGS_EXCEPTIONAL_USERS_CHANGED";
}

export interface CaseOutcomeReasonChangedNotification extends Notification {
    readonly caseOutcomeReason: CaseOutcomeReason;
}

export function isCaseOutcomeReasonChangedNotification(n: Notification): n is CaseOutcomeReasonChangedNotification {
    return n.type === "CASE_OUTCOME_REASON_CHANGED";
}

export interface CaseOutcomeReasonDeletedNotification extends Notification {
    readonly caseOutcomeReasonId: string;
}

export function isCaseOutcomeReasonDeletedNotification(n: Notification): n is CaseOutcomeReasonDeletedNotification {
    return n.type === "CASE_OUTCOME_REASON_DELETED";
}

export interface CaseOutcomeReasonsOrderChangedNotification extends Notification {
    readonly orderedCaseOutcomeReasonIds: string[];
}

export function isCaseOutcomeReasonsOrderChangedNotification(
    n: Notification
): n is CaseOutcomeReasonsOrderChangedNotification {
    return n.type === "CASE_OUTCOME_REASONS_ORDER_CHANGED";
}

export interface CaseSettingsChangedNotification extends Notification {
    readonly caseSettings: CaseSettings;
}

export function isCaseSettingsChangedNotification(n: Notification): n is CaseSettingsChangedNotification {
    return n.type === "CASE_SETTINGS_CHANGED";
}

export interface OpportunityUserAssignmentLockUpdatedNotification extends Notification {
    readonly lockedUntil: Date;
}

export function isOpportunityUserAssignmentLockUpdatedNotification(
    n: Notification
): n is OpportunityUserAssignmentLockUpdatedNotification {
    return n.type === "OPPORTUNITY_USER_ASSIGNMENT_LOCK_UPDATED";
}

export interface ExternalInventoryManagementSettingsUpdatedNotification extends Notification {
    readonly settings: ExternalInventoryManagementSettings;
}

export function isExternalInventoryManagementSettingsUpdatedNotification(
    n: Notification
): n is ExternalInventoryManagementSettingsUpdatedNotification {
    return n.type === "EXTERNAL_INVENTORY_MANAGEMENT_SETTINGS_UPDATED";
}

export interface OpportunityInventoryVehicleInquiryMatchesUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityInventoryVehicleInquiryMatchesUpdatedNotification(
    n: Notification
): n is OpportunityInventoryVehicleInquiryMatchesUpdatedNotification {
    return n.type === "OPPORTUNITY_INVENTORY_VEHICLE_INQUIRY_MATCHES_UPDATED";
}

export interface InventoryVehicleInquiryStatusUpdatedNotification extends Notification {
    readonly inventoryVehicleInquiryId: string;
    readonly disabled: Date | null;
}

export function isInventoryVehicleInquiryStatusUpdatedNotification(
    n: Notification
): n is InventoryVehicleInquiryStatusUpdatedNotification {
    return n.type === "INVENTORY_VEHICLE_INQUIRY_STATUS_UPDATED";
}

export interface VideochatRingingNotification extends Notification {
    readonly id: string;
    readonly invitorUserId: string | null;
    readonly videochatGroupName: string | null;
}

export function isVideochatRingingNotification(n: Notification): n is VideochatRingingNotification {
    return n.type === "VIDEOCHAT_RINGING";
}

export interface VideochatStopRingingNotification extends Notification {
    readonly id: string;
}

export function isVideochatStopRingingNotification(n: Notification): n is VideochatStopRingingNotification {
    return n.type === "VIDEOCHAT_STOP_RINGING";
}

export function isVideochatsUpdatedNotification(n: Notification) {
    return n.type === "VIDEOCHATS_UPDATED";
}

export function isTimestampNotification(n: Notification) {
    return n.type === "TIMESTAMP";
}

export interface MakeModelSettingsChangedNotification extends Notification {
    readonly makeModelSettings: MakeModelSettings;
}

export function isMakeModelSettingsChangedNotification(n: Notification): n is MakeModelSettingsChangedNotification {
    return n.type === "MAKE_MODEL_SETTINGS_CHANGED";
}

export const notificationEventSource = new ReconnectingEventSource("/api/notifications/_stream", (data) =>
    cloneObject(data)
);

export interface ReportingSettingsChangedNotification extends Notification {
    readonly reportingSettings: ReportingSettings;
}

export function isReportingSettingsChangedNotification(n: Notification): n is ReportingSettingsChangedNotification {
    return n.type === "REPORTING_SETTINGS_CHANGED";
}

export interface ReportingDealerSettingsChangedNotification extends Notification {
    readonly reportingDealerSettings: ReportingDealerSettings;
}

export function isReportingDealerSettingsChangedNotification(
    n: Notification
): n is ReportingDealerSettingsChangedNotification {
    return n.type === "REPORTING_DEALER_SETTINGS_CHANGED";
}

export interface ExternOrderCarryDispoNoteUpdatedNotification extends Notification {
    readonly externOrderId: number;
}

export function isExternOrderCarryDispoNoteUpdatedNotification(
    n: Notification
): n is ExternOrderCarryDispoNoteUpdatedNotification {
    return n.type === "EXTERN_ORDER_CARRY_DISPO_NOTE_UPDATED";
}

export interface OpportunityOutgoingCitnowVideosUpdatedNotification extends Notification {
    readonly opportunityId: string;
}

export function isOpportunityOutgoingCitnowVideosUpdatedNotification(
    n: Notification
): n is OpportunityOutgoingCitnowVideosUpdatedNotification {
    return n.type === "OPPORTUNITY_OUTGOING_CITNOW_VIDEOS_UPDATED";
}

export interface OutgoingCitnowVideoUpdatedNotification extends Notification {
    readonly id: string;
    readonly videoEmbed: string;
    readonly videoPage: string;
    readonly videoThumb: string;
}

export function isOutgoingCitnowVideoUpdatedNotification(n: Notification): n is OutgoingCitnowVideoUpdatedNotification {
    return n.type === "OUTGOING_CITNOW_VIDEO_UPDATED";
}
