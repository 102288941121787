
import CasesDataTable from "./CasesDataTable.vue";
import MyCasesDataTable from "./MyCasesDataTable.vue";
import { EMPTY_SEARCH_REQUEST } from "./caseSearchUtils";
import { CaseSearchOrder, CaseSearchRequest } from "@/api/caseSearch";
import { CasesSort, CaseVisibilityArea } from "@/api/cases";
import EnumField from "@/app/components/EnumField.vue";
import { caseStatusStore } from "@/store/caseStatus";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            activeTab: 0,
            CasesSort,
            caseSearch: null as string | null,
            CaseSearchOrder,
            caseStatusStore,
            emptyCaseSearchRequest: EMPTY_SEARCH_REQUEST,
            preferences: userSession.preferences!.myCasesPreferences,
        };
    },

    computed: {
        caseSearchRequest(): CaseSearchRequest {
            return {
                ...this.emptyCaseSearchRequest,
                search: this.caseSearch || "",
                sortBy: this.preferences.searchCasesSortBy!,
            };
        },

        casesSort(): CasesSort {
            if (this.activeTab === 0) {
                return this.preferences.activeCasesSortBy!;
            } else if (this.activeTab === 1) {
                return CasesSort.POSTPONED_UNTIL_ASC;
            } else if (this.activeTab === 2) {
                return this.preferences.createdCasesSortBy!;
            } else if (this.activeTab === 3) {
                return this.preferences.closedCasesSortBy!;
            } else {
                return this.preferences.activeCasesSortBy!;
            }
        },

        caseVisibilityArea() {
            if (this.activeTab === 0) {
                return CaseVisibilityArea.ACTIVE;
            } else if (this.activeTab === 1) {
                return CaseVisibilityArea.POSTPONED;
            } else if (this.activeTab === 2) {
                return CaseVisibilityArea.CREATED;
            } else if (this.activeTab === 3) {
                return CaseVisibilityArea.CLOSED;
            } else {
                return CaseVisibilityArea.ACTIVE;
            }
        },
    },

    methods: {
        async refreshSearch() {
            this.emptyCaseSearchRequest = { ...EMPTY_SEARCH_REQUEST };
        },
    },

    components: {
        CasesDataTable,
        EnumField,
        MyCasesDataTable,
    },
});
