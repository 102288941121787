
import CaseOutcomeDialog from "./CaseOutcomeDialog.vue";
import CasesDataTable from "./CasesDataTable.vue";
import CasesDataTableRow from "./CasesDataTableRow.vue";
import CasesOverviewFilterDialog from "./CasesOverviewFilterDialog.vue";
import {
    CaseSearchFilterOptions,
    filterIsValid,
    filterMatchesCasesOverviewState,
    mapToCasesOverviewFilters,
    mapToSearchFilterForm,
} from "./caseSearchFilterUtils";
import { casesOverviewState } from "./casesOverview";
import { ActorType, caseSearchApi, CaseSearchOrder, CaseSearchResult, SentimentType } from "@/api/caseSearch";
import { CaseChannel, CaseOutcomeForm, CasePriority, CaseResult, casesApi, CaseStatus, CaseUrgency } from "@/api/cases";
import {
    UserSearchFilter,
    userSearchFilterApi,
    UserSearchFilterForm,
    UserSearchFilterType,
} from "@/api/userSearchFilters";
import { Permission } from "@/api/userSession";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { downloadOrOpenFile } from "@/app/fileUtils";
import { showConfirm, showInfo } from "@/app/messageUtil";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import ProcessingProgressDialog from "@/app/pages/ProcessingProgressDialog.vue";
import { ProcessingProgressDialogProps } from "@/app/pages/processingProgressDialog";
import UserPickerDialog from "@/app/pages/users/UserPickerDialog.vue";
import { getFullName } from "@/app/userUtils";
import { caseOutcomeReasonsStore } from "@/store/caseOutcomeReasons";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

interface CaseSearchFilterSummary {
    readonly id: string;
    readonly name: string;
    readonly filterJson: { [key: string]: any };
    readonly isValid: boolean;
}

export default Vue.extend({
    props: {
        canSeeBatchUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            ActorType,
            CaseChannel,
            CasePriority,
            casesOverviewState,
            CaseSearchOrder,
            CaseStatus,
            CaseUrgency,
            SentimentType,
            assigneePickerVisible: false,
            caseOutcomeDialogVisible: false,
            downloadingCsv: false,
            filterDialogVisible: false,
            processing: false,
            processingProgressDialogProps: null as ProcessingProgressDialogProps<CaseResult> | null,
            searchFilters: [] as CaseSearchFilterSummary[],
            selectedItems: [] as CaseSearchResult[],
        };
    },

    computed: {
        assigneeOptions(): SelectOptions {
            return [
                {
                    value: null,
                    text: this.$t("Nicht zugeteilt"),
                },
                ...this.userOptions,
            ];
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        escalationGroupOptions(): SelectOptions {
            return [
                {
                    value: null,
                    text: this.$t("Ohne Eskalationsgruppe"),
                },
                ...escalationGroupsStore.escalationGroups.map((g) => ({
                    value: g.id,
                    text: `${g.name} (${dealersStore.dealerById(g.dealerId)!.name})`,
                })),
            ];
        },

        individualReceiverOptions(): SelectOptions {
            return [
                {
                    value: null,
                    text: this.$t("Ohne Einzelempfänger"),
                },
                ...this.userOptions,
            ];
        },

        outcomeReasonOptions(): SelectOptions {
            return [
                {
                    value: null,
                    text: this.$t("Kein Grund angegeben"),
                },
                ...caseOutcomeReasonsStore.caseOutcomeReasons.map((r) => ({
                    value: r.id,
                    text: r.name,
                })),
            ];
        },

        userOptions(): SelectOptions {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },

        isFiltersVisible(): boolean {
            return !userSession.isCtUser();
        },

        canBatchUpdate(): boolean {
            return (
                this.canSeeBatchUpdate &&
                userSession.isCtUser() &&
                userSession.hasPermission(Permission.MANAGE_ALL_CASES)
            );
        },

        canDeleteCases(): boolean {
            return userSession.hasPermission(Permission.DELETE_CASE);
        },

        canDownloadCsv(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_CASES);
        },
    },

    methods: {
        refresh() {
            this.casesOverviewState.refresh();
        },

        filterMatchesOverviewState(filter: UserSearchFilter): boolean {
            return filterMatchesCasesOverviewState(filter, this.casesOverviewState);
        },

        applyFilter(filter: UserSearchFilter | UserSearchFilterForm) {
            this.casesOverviewState.restoreFilters(mapToCasesOverviewFilters(filter, this.casesOverviewState));
        },

        showAddFilter() {
            this.filterDialogVisible = true;
        },

        hideAddFilter() {
            this.filterDialogVisible = false;
        },

        async addFilter(options: CaseSearchFilterOptions) {
            this.hideAddFilter();
            const filterForm = mapToSearchFilterForm(options, this.casesOverviewState);
            await userSearchFilterApi.addFilter(filterForm);
            await this.loadSearchFilters();
            this.applyFilter(filterForm);
        },

        async deleteFilter(filterId: string) {
            if (
                await showConfirm(
                    this.$t("Schnellauswahl löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Schnellauswahl löschen wollen?") as string
                )
            ) {
                await userSearchFilterApi.deleteFilter(filterId);
                await this.loadSearchFilters();
            }
        },

        async loadSearchFilters() {
            this.searchFilters = (await userSearchFilterApi.getAllFilters(UserSearchFilterType.CASE)).map((f) => ({
                ...f,
                isValid: filterIsValid(f),
            }));
        },

        updateAssignees(assigneeIds: string[]) {
            this.assigneePickerVisible = false;

            const updatable = this.getUnclosedSelectedCaseResults();

            if (!updatable.length) {
                return;
            }

            this.processingProgressDialogProps = {
                items: updatable,
                process: (caseResult: CaseResult) => casesApi.updateAssignees(caseResult.caseObj.id, assigneeIds, true),
                title: this.$t("Ändere Zuteilung") as string,
            };
        },

        closeCases(caseOutcomeForm: CaseOutcomeForm) {
            this.caseOutcomeDialogVisible = false;

            const updatable = this.getUnclosedSelectedCaseResults();

            if (!updatable.length) {
                return;
            }

            this.processingProgressDialogProps = {
                items: updatable,
                process: (caseResult: CaseResult) => casesApi.closeCase(caseResult.caseObj.id, caseOutcomeForm, true),
                title: this.$t("Schließe Fälle") as string,
            };
        },

        async deleteCases() {
            const updatable = this.selectedItems.filter((item) => !!item.caseResult).map((item) => item.caseResult!);

            if (this.selectedItems.length && updatable.length < this.selectedItems.length) {
                showInfo(
                    this.$t("Es wurden {0} von {1} Fällen ignoriert.", [
                        this.selectedItems.length - updatable.length,
                        this.selectedItems.length,
                    ]) as string
                );
            }

            if (!updatable.length) {
                return;
            }

            if (
                !(await showConfirm(
                    this.$t("Lösche Fälle") as string,
                    this.$t("Sind Sie sicher, dass Sie die ausgewählten Fälle löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.processingProgressDialogProps = {
                items: updatable,
                process: (caseResult: CaseResult) => casesApi.deleteCase(caseResult.caseObj.id),
                title: this.$t("Lösche Fälle") as string,
            };
        },

        getUnclosedSelectedCaseResults(): CaseResult[] {
            const unclosed = this.selectedItems
                .filter((item) => !!item.caseResult && !item.caseResult.caseObj.closed)
                .map((item) => item.caseResult!);

            if (this.selectedItems.length && unclosed.length < this.selectedItems.length) {
                showInfo(
                    this.$t("Es wurden {0} von {1} Fällen ignoriert, da sie bereits geschlossen sind.", [
                        this.selectedItems.length - unclosed.length,
                        this.selectedItems.length,
                    ]) as string
                );
            }

            return unclosed;
        },

        closeProcessingProgressDialog() {
            this.processingProgressDialogProps = null;
            this.refresh();
        },

        async downloadCsv() {
            this.downloadingCsv = true;
            try {
                downloadOrOpenFile(await caseSearchApi.downloadCsv(casesOverviewState.searchRequest), true);
            } finally {
                this.downloadingCsv = false;
            }
        },
    },

    watch: {
        dealerId() {
            this.casesOverviewState.dealerIds = this.dealerId ? [this.dealerId] : [];
        },
    },

    async mounted() {
        if (!this.casesOverviewState.initialized) {
            this.casesOverviewState.setAllQuickfilter();
        }
        if (this.isFiltersVisible) {
            await this.loadSearchFilters();
        }
    },

    components: {
        CasesOverviewFilterDialog,
        CasesDataTable,
        CasesDataTableRow,
        CaseTypePicker,
        DAutocomplete,
        EnumField,
        DatePicker,
        ProcessingProgressDialog,
        UserPickerDialog,
        CaseOutcomeDialog,
    },
});
