import axios from "axios";

export interface OfficeHoursEntry {
    readonly dayOfWeek: number;
    readonly from: string;
    readonly to: string;
}

export interface ExceptionalDayEntry {
    readonly from: string;
    readonly to: string;
}

export interface ExceptionalDay {
    readonly date: string;
    readonly reason: string;
    readonly entries: ExceptionalDayEntry[];
}

export interface OfficeHours {
    readonly id: string;
    readonly dealerId: string;
    readonly name: string;
    readonly openingHours: boolean;
    readonly entries: OfficeHoursEntry[];
    readonly exceptionalDays: ExceptionalDay[];
    readonly dismissedSuggestions: string[];
}

export interface OfficeHoursForm {
    readonly name: string;
    readonly openingHours: boolean;
    readonly entries: OfficeHoursEntry[];
}

interface OfficeHoursApi {
    getAll(): Promise<OfficeHours[]>;
    getByDealer(dealerId: string): Promise<OfficeHours[]>;
    getById(id: string): Promise<OfficeHours>;
    updateOrder(dealerId: string, ids: string[]): Promise<void>;
    add(dealerId: string, form: OfficeHoursForm): Promise<void>;
    edit(id: string, form: OfficeHoursForm): Promise<void>;
    delete(id: string): Promise<void>;
    dismissSuggestion(id: string, date: string): Promise<void>;
    deleteExceptionalDay(id: string, date: string): Promise<void>;
    addExceptionalDay(id: string, exceptionalDay: ExceptionalDay): Promise<void>;
}

export const officeHoursApi: OfficeHoursApi = {
    async getAll() {
        return (await axios.get("/api/office-hours")).data;
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/office-hours/${dealerId}`)).data;
    },

    async getById(id) {
        return (await axios.get(`/api/office-hours/_/${id}`)).data;
    },

    async updateOrder(dealerId, ids) {
        await axios.post(`/api/office-hours/${dealerId}/_update-order`, ids);
    },

    async add(dealerId, form) {
        await axios.post(`/api/office-hours/${dealerId}`, form);
    },

    async edit(id, form) {
        await axios.put(`/api/office-hours/_/${id}`, form);
    },

    async delete(id) {
        await axios.delete(`/api/office-hours/_/${id}`);
    },

    async dismissSuggestion(id, date) {
        await axios.post(`/api/office-hours/_/${id}/_dismiss-suggestion`, null, {
            params: {
                date,
            },
        });
    },

    async deleteExceptionalDay(id, date) {
        await axios.delete(`/api/office-hours/_/${id}/_delete-exceptional-day`, {
            params: {
                date,
            },
        });
    },

    async addExceptionalDay(id, exceptionalDay) {
        await axios.post(`/api/office-hours/_/${id}/_add-exceptional-day`, exceptionalDay);
    },
};
