import {
    OutgoingEmailEvent,
    OutgoingEmailEventType,
    TransactionEmailReceiver,
    TransactionEmailReceiverType,
} from "@/api/outgoingEmails";

export interface OutgoingEmailReceiverView {
    readonly name: string | null;
    readonly address: string;
    readonly lastStatusEvent: OutgoingEmailEvent | null;
    readonly openCount: number;
    readonly lastOpen: Date | null;
    readonly clickCount: number;
    readonly lastClick: Date | null;
}

function getSortedEventsByAddress(address: string, allEvents: OutgoingEmailEvent[]): OutgoingEmailEvent[] {
    const result = allEvents.filter((e) => e.address === address);
    result.sort((a, b) => b.occuredAt.getTime() - a.occuredAt.getTime());
    return result;
}

function getLastClick(events: OutgoingEmailEvent[]): Date | null {
    const event = events.find((e) => e.eventType === OutgoingEmailEventType.CLICKED);
    if (!event) {
        return null;
    }

    return event.occuredAt;
}

function getClickCount(events: OutgoingEmailEvent[]): number {
    return events.filter((e) => e.eventType === OutgoingEmailEventType.CLICKED).length;
}

function getLastOpen(events: OutgoingEmailEvent[]): Date | null {
    const event = events.find((e) => e.eventType === OutgoingEmailEventType.OPENED);
    if (!event) {
        return null;
    }

    return event.occuredAt;
}

function getOpenCount(events: OutgoingEmailEvent[]): number {
    return events.filter((e) => e.eventType === OutgoingEmailEventType.OPENED).length;
}

function getLastStatusEvent(events: OutgoingEmailEvent[]): OutgoingEmailEvent | null {
    return (
        events.find(
            (e) => e.eventType !== OutgoingEmailEventType.OPENED && e.eventType !== OutgoingEmailEventType.CLICKED
        ) || null
    );
}

export function getReceiverViews(
    transactionEmailReceivers: TransactionEmailReceiver[],
    type: TransactionEmailReceiverType,
    allEvents: OutgoingEmailEvent[]
): OutgoingEmailReceiverView[] {
    const receivers = transactionEmailReceivers.filter((r) => r.type === type);

    const result: OutgoingEmailReceiverView[] = [];
    for (const receiver of receivers) {
        const events = getSortedEventsByAddress(receiver.address, allEvents);

        result.push({
            name: receiver.name,
            address: receiver.address,
            lastStatusEvent: getLastStatusEvent(events),
            openCount: getOpenCount(events),
            lastOpen: getLastOpen(events),
            clickCount: getClickCount(events),
            lastClick: getLastClick(events),
        });
    }

    return result;
}
