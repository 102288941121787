
import { usersApi } from "@/api/users";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            unsubscribing: true,
            success: false,
        };
    },

    async mounted() {
        const userId = this.$route.params.userid;
        try {
            await usersApi.unsubscribe(userId);
            this.success = true;
        } finally {
            this.unsubscribing = false;
        }
    },
});
