var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dealer-context-guard',{attrs:{"permission":_vm.Permission.MANAGE_VIDEOCHAT_GROUP_SETTINGS,"container":""}},[_c('crud-page',{attrs:{"hide-dealer-context-warning":"","global":"","icon":"mdi-video","no-items-text":_vm.$t('Es wurden keine Videochat-Gruppen gefunden.'),"add-dialog-title":_vm.$t('Videochat-Gruppe hinzufügen'),"edit-dialog-title":_vm.$t('Videochat-Gruppe bearbeiten'),"page-title":_vm.$t('Videochat-Gruppen'),"callbacks":{
            load: (dealerId) => _vm.getByDealer(dealerId),
            add: (dealerId, form) => _vm.add(dealerId, form),
            edit: (item, form) => _vm.api.edit(item.id, form),
            delete: (item) => _vm.deleteGroup(item),
            key: (item) => item.id,
            toForm: (item) => _vm.toForm(item),
            emptyForm: () => _vm.emptyForm(),
        }},scopedSlots:_vm._u([{key:"title",fn:function({ item }){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.name))]),_c('v-chip',{staticClass:"ml-2 font-weight-regular",attrs:{"x-small":""}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.id))]),_c('copy-to-clipboard-icon',{staticClass:"ml-1",attrs:{"x-small":"","content":item.id}})],1),(item.preferForIntenvoryVehicles)?_c('v-chip',{staticClass:"ml-2 font-weight-regular",attrs:{"x-small":"","color":"info"}},[_vm._v(" "+_vm._s(_vm.$t("Für Fahrzeuganfragen bevorzugen"))+" ")]):_vm._e()]}},{key:"content",fn:function({ item }){return [_c('v-card-text',[_c('div',[(!item.userIds.length)?_c('span',[_vm._v(_vm._s(_vm.$t("Es sind keine Mitglieder vorhanden.")))]):_vm._e(),_vm._l((item.userIds),function(userId){return _c('v-chip',{key:userId,staticClass:"mr-1 pl-2",attrs:{"x-small":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.getUserFullNameById(userId))+" ")],1)})],2)])]}},{key:"dialog",fn:function({ form }){return [_c('v-text-field',{attrs:{"label":_vm.$t('Name'),"rules":_vm.nameRules,"id":_vm.$id(),"autocomplete":"no"},model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}}),_c('d-autocomplete',{attrs:{"autocomplete":"no","deletable-chips":"","id":_vm.$id(),"items":_vm.userOptions,"label":_vm.$t('Mitglieder'),"multiple":"","small-chips":""},model:{value:(form.userIds),callback:function ($$v) {_vm.$set(form, "userIds", $$v)},expression:"form.userIds"}}),_c('v-switch',{attrs:{"label":_vm.$t('Für Fahrzeuganfragen bevorzugen'),"hint":_vm.$t(
                        'Falls diese Einstellung gesetzt ist, wird diese Videochat-Gruppe bevorzugt für Fahrzeuganfragen dieses Standorts angezeigt.'
                    ),"persistent-hint":""},model:{value:(form.preferForIntenvoryVehicles),callback:function ($$v) {_vm.$set(form, "preferForIntenvoryVehicles", $$v)},expression:"form.preferForIntenvoryVehicles"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }