
import { BadRequest } from "@/api/errors";
import { passwordPolicyApi } from "@/api/passwordPolicy";
import { PasswordForm } from "@/api/userSession";
import { showInfo } from "@/app/messageUtil";
import { notEmpty, ValidationHelper } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { ActionLimiter } from "@/util/debounce";
import Vue from "vue";

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            validationHelper,
            isWorking: false,
            valid: true,
            oldPassword: "",
            newPassword: "",
            confirmedPassword: "",
            oldPasswordRules: notEmpty().and(validationHelper, "oldPassword"),
            newPasswordRules: notEmpty().and(validationHelper, "password"),
            validationActionLimiter: new ActionLimiter(),
        };
    },

    computed: {
        confirmedPasswordRules() {
            return [
                (v: string) => v === this.newPassword || this.$t("Die eingegebenen Passwörter stimmen nicht überein."),
            ];
        },
    },

    watch: {
        async newPassword() {
            if (this.newPassword.trim()) {
                try {
                    await this.validationActionLimiter.execute(() => passwordPolicyApi.validate(this.newPassword));
                } catch (e) {
                    if (e instanceof BadRequest) {
                        this.validationHelper.update(e, this.$refs.form);
                    }
                }
            }
        },
    },

    methods: {
        async changePassword() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            const passwordForm: PasswordForm = {
                newPassword: this.newPassword,
                oldPassword: this.oldPassword,
            };

            try {
                await userSession.changePassword(passwordForm);
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, this.$refs.form);
                return;
            } finally {
                this.isWorking = false;
            }

            this.oldPassword = "";
            this.newPassword = "";
            this.confirmedPassword = "";
            (this.$refs.form as any).resetValidation();
            showInfo(this.$t("Passwort erfolgreich geändert") as string);
        },
    },
});
