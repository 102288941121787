
import OpportunityGeneralInfoFormCard from "./OpportunityGeneralInfoFormCard.vue";
import { Opportunity } from "@/api/opportunities";
import UserLink from "@/app/pages/UserLink.vue";
import { getSentimentText } from "@/app/sentimentUtils";
import { dealersStore } from "@/store/dealers";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },

        canEdit: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            generalInfoFormVisible: false,
        };
    },

    computed: {
        creator(): string {
            if (this.opportunityProp.creatorCtUserId) {
                return this.$t("Externer Agent") as string;
            }
            return this.$t("System") as string;
        },

        sentimentText(): string | null {
            return getSentimentText(this.opportunityProp.sentiment);
        },

        sourceName(): string | null {
            return opportunitySourcesStore.getOpportunitySourceById(this.opportunityProp.sourceId)?.name || null;
        },
    },

    methods: {
        showEditForm() {
            this.generalInfoFormVisible = true;
        },

        closeEditForm() {
            this.generalInfoFormVisible = false;
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },
    },

    components: {
        OpportunityGeneralInfoFormCard,
        UserLink,
    },
});
