import { CaseSearchOrder } from "@/api/caseSearch";
import { CasesSort } from "@/api/cases";
import { OpportunitiesSort } from "@/api/opportunities";
import { OpportunitySearchOrder } from "@/api/opportunitySearch";
import {
    MyCasesPreferences,
    MyOpportunitiesPreferences,
    UserPreferences,
    userPreferencesApi,
} from "@/api/userPreferences";
import { reactive } from "@/util/reactive";

const DEFAULT_MY_CASES_PREFERENCES: MyCasesPreferences = {
    activeCasesSortBy: CasesSort.PRIORITY_DESC,
    createdCasesSortBy: CasesSort.CREATED_DESC,
    closedCasesSortBy: CasesSort.CLOSED_DESC,
    searchCasesSortBy: CaseSearchOrder.CREATED_DESC,
};

const DEFAULT_MY_OPPORTUNITIES_PREFERENCES: MyOpportunitiesPreferences = {
    activeOpportunitiesSortBy: OpportunitiesSort.PRIORITY_DESC,
    createdOpportunitiesSortBy: OpportunitiesSort.CREATED_DESC,
    closedOpportunitiesSortBy: OpportunitiesSort.CLOSED_DESC,
    searchOpportunitiesSortBy: OpportunitySearchOrder.CREATED_DESC,
};

const DEFAULT_USER_PREFERENCES: UserPreferences = {
    myCasesPreferences: DEFAULT_MY_CASES_PREFERENCES,
    myOpportunitiesPreferences: DEFAULT_MY_OPPORTUNITIES_PREFERENCES,
};

@reactive
class MyCasesPreferencesState {
    private preferences: MyCasesPreferences = DEFAULT_MY_CASES_PREFERENCES;

    constructor(myCasesPreferences: MyCasesPreferences | null) {
        if (myCasesPreferences) {
            this.preferences = myCasesPreferences;
        }
    }

    get activeCasesSortBy() {
        return this.preferences.activeCasesSortBy || DEFAULT_MY_CASES_PREFERENCES.activeCasesSortBy;
    }

    set activeCasesSortBy(casesSort: CasesSort | null) {
        this.updatePreferences({ ...this.preferences, activeCasesSortBy: casesSort });
    }

    get createdCasesSortBy() {
        return this.preferences.createdCasesSortBy || DEFAULT_MY_CASES_PREFERENCES.createdCasesSortBy;
    }

    set createdCasesSortBy(casesSort: CasesSort | null) {
        this.updatePreferences({ ...this.preferences, createdCasesSortBy: casesSort });
    }

    get closedCasesSortBy() {
        return this.preferences.closedCasesSortBy || DEFAULT_MY_CASES_PREFERENCES.closedCasesSortBy;
    }

    set closedCasesSortBy(casesSort: CasesSort | null) {
        this.updatePreferences({ ...this.preferences, closedCasesSortBy: casesSort });
    }

    get searchCasesSortBy() {
        return this.preferences.searchCasesSortBy || DEFAULT_MY_CASES_PREFERENCES.searchCasesSortBy;
    }

    set searchCasesSortBy(caseSearchOrder: CaseSearchOrder | null) {
        this.updatePreferences({ ...this.preferences, searchCasesSortBy: caseSearchOrder });
    }

    refreshPreferences(myCasesPreferences: MyCasesPreferences) {
        this.preferences = myCasesPreferences;
    }

    updatePreferences(myCasesPreferences: MyCasesPreferences) {
        const hasChanged =
            this.preferences.activeCasesSortBy !== myCasesPreferences.activeCasesSortBy ||
            this.preferences.createdCasesSortBy !== myCasesPreferences.createdCasesSortBy ||
            this.preferences.closedCasesSortBy !== myCasesPreferences.closedCasesSortBy ||
            this.preferences.searchCasesSortBy !== myCasesPreferences.searchCasesSortBy;

        if (!hasChanged) {
            return;
        }

        this.preferences = myCasesPreferences;
        userPreferencesApi.editMyCasesPreferences(this.preferences);
    }
}

@reactive
class MyOpportunitiesPreferencesState {
    private preferences: MyOpportunitiesPreferences = DEFAULT_MY_OPPORTUNITIES_PREFERENCES;

    constructor(myOpportunitiesPreferences: MyOpportunitiesPreferences | null) {
        if (myOpportunitiesPreferences) {
            this.preferences = myOpportunitiesPreferences;
        }
    }

    get activeOpportunitiesSortBy() {
        return (
            this.preferences.activeOpportunitiesSortBy || DEFAULT_MY_OPPORTUNITIES_PREFERENCES.activeOpportunitiesSortBy
        );
    }

    set activeOpportunitiesSortBy(opportunitiesSort: OpportunitiesSort | null) {
        this.updatePreferences({ ...this.preferences, activeOpportunitiesSortBy: opportunitiesSort });
    }

    get createdOpportunitiesSortBy() {
        return (
            this.preferences.createdOpportunitiesSortBy ||
            DEFAULT_MY_OPPORTUNITIES_PREFERENCES.createdOpportunitiesSortBy
        );
    }

    set createdOpportunitiesSortBy(opportunitiesSort: OpportunitiesSort | null) {
        this.updatePreferences({ ...this.preferences, createdOpportunitiesSortBy: opportunitiesSort });
    }

    get closedOpportunitiesSortBy() {
        return (
            this.preferences.closedOpportunitiesSortBy || DEFAULT_MY_OPPORTUNITIES_PREFERENCES.closedOpportunitiesSortBy
        );
    }

    set closedOpportunitiesSortBy(opportunitiesSort: OpportunitiesSort | null) {
        this.updatePreferences({ ...this.preferences, closedOpportunitiesSortBy: opportunitiesSort });
    }

    get searchOpportunitiesSortBy() {
        return (
            this.preferences.searchOpportunitiesSortBy || DEFAULT_MY_OPPORTUNITIES_PREFERENCES.searchOpportunitiesSortBy
        );
    }

    set searchOpportunitiesSortBy(opportunitySearchOrder: OpportunitySearchOrder | null) {
        this.updatePreferences({ ...this.preferences, searchOpportunitiesSortBy: opportunitySearchOrder });
    }

    refreshPreferences(myOpportunitiesPreferences: MyOpportunitiesPreferences) {
        this.preferences = myOpportunitiesPreferences;
    }

    updatePreferences(myOpportunitiesPreferences: MyOpportunitiesPreferences) {
        const hasChanged =
            this.preferences.activeOpportunitiesSortBy !== myOpportunitiesPreferences.activeOpportunitiesSortBy ||
            this.preferences.createdOpportunitiesSortBy !== myOpportunitiesPreferences.createdOpportunitiesSortBy ||
            this.preferences.closedOpportunitiesSortBy !== myOpportunitiesPreferences.closedOpportunitiesSortBy ||
            this.preferences.searchOpportunitiesSortBy !== myOpportunitiesPreferences.searchOpportunitiesSortBy;

        if (!hasChanged) {
            return;
        }

        this.preferences = myOpportunitiesPreferences;
        userPreferencesApi.editMyOpportunitiesPreferences(this.preferences);
    }
}

@reactive
export class UserPreferencesState {
    private myCasesPreferencesState: MyCasesPreferencesState | null = null;
    private myOpportunitiesPreferencesState: MyOpportunitiesPreferencesState | null = null;

    constructor(userPreferences: UserPreferences | null) {
        this.myCasesPreferencesState = new MyCasesPreferencesState(
            (userPreferences || DEFAULT_USER_PREFERENCES).myCasesPreferences
        );

        this.myOpportunitiesPreferencesState = new MyOpportunitiesPreferencesState(
            (userPreferences || DEFAULT_USER_PREFERENCES).myOpportunitiesPreferences
        );
    }

    get myCasesPreferences() {
        return this.myCasesPreferencesState!;
    }

    get myOpportunitiesPreferences() {
        return this.myOpportunitiesPreferencesState!;
    }

    refreshPreferences(userPreferences: UserPreferences) {
        this.myCasesPreferencesState!.refreshPreferences(userPreferences.myCasesPreferences);
        this.myOpportunitiesPreferencesState!.refreshPreferences(userPreferences.myOpportunitiesPreferences);
    }
}
