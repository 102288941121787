import { EmergencyIncidentSearchOrder, EmergencyIncidentSearchRequest } from "@/api/emergencyIncidentSearch";

export const EMPTY_SEARCH_REQUEST: EmergencyIncidentSearchRequest = {
    emergencyIncidentIds: [],
    excludedEmergencyIncidentIds: [],
    dealerIds: [],
    types: [],
    creatorActorType: [],
    createdBy: [],
    closerActorType: [],
    closedBy: [],
    open: null,
    contactId: null,
    createdFrom: null,
    createdTo: null,
    search: null,
    sortBy: EmergencyIncidentSearchOrder.CREATED_DESC,
};
