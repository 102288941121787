
import { autoCrmLeadMailAssistantState } from "./autoCrmLeadMailAssistantState";
import { ExteriorColor } from "@/api/inventory";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import PartialDatePicker from "@/app/components/PartialDatePicker.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import ModelPicker from "@/app/pages/ModelPicker.vue";
import { decimal, defaultCurrency, integer, maxLength } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            autoCrmLeadMailAssistantState,
            currencyRules: decimal(defaultCurrency()),
            ExteriorColor,
            maxLength,
            now: now(),
            positiveIntegerRules: integer(0),
        };
    },

    computed: {
        currentDate(): string {
            return getDate(this.now, userSession.timeZone);
        },

        defaultCurrency(): string {
            return configStore.configuration.defaultCurrency;
        },
    },

    components: {
        EnumField,
        MakePicker,
        ModelPicker,
        NumberField,
        PartialDatePicker,
    },
});
