import {
    Climatisation,
    Drive,
    ExteriorColor,
    Feature,
    Fuel,
    Gearbox,
    HeadlightType,
    InteriorType,
    InventoryVehicle,
    ParkingAssistant,
    SpeedControl,
    TrailerCouplingType,
    UsageType,
} from "@/api/inventory";
import { InventoryVehicleInquiry, InventoryVehicleInquiryData } from "@/api/inventoryVehicleInquiries";
import { OpportunityChannel } from "@/api/opportunities";
import { ContactAndVehicleId } from "@/app/contactUtils";
import { getInventoryVehicleLatestPrice } from "@/app/inventoryUtils";
import { EMPTY_SEARCH_REQUEST } from "@/app/pages/contacts/contactSearchUtils";
import { UserPickerData } from "@/app/pages/users/userPickerCardTypes";
import { configStore } from "@/store/config";
import { makeModelsStore } from "@/store/makeModels";
import { now } from "@/store/now";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, getTime, toDateObject } from "@/util/dateTimeUtils";
import { getYearMonthDay, toYearMonthDayObject } from "@/util/partialDateUtils";
import { reactive } from "@/util/reactive";
import { YearMonthDay } from "@/util/types";

function getRangeValue(baseValue: number, factor: number, isRangeTo: boolean, roundToNextDivisor: number): number {
    const value = isRangeTo ? Math.ceil(baseValue * factor) : Math.floor(baseValue * factor);

    const candidates = [
        value - (value % roundToNextDivisor),
        value - (value % roundToNextDivisor) + roundToNextDivisor,
    ].filter((c) => c === value || isRangeTo === c > value);

    return isRangeTo ? Math.min(...candidates) : Math.max(...candidates);
}

@reactive
class InventoryVehicleInquiryAssistantState {
    public contactAndVehicleId: ContactAndVehicleId | null = null;
    public contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
    public id = null as string | null;
    public validUntilTimeZone: string | null = null;
    private openEndValidity_ = true;
    public validUntilDate = "";
    public validUntilTime = "";
    public disableOnMatch = true;
    public comment = "";
    public dealerIds: string[] = [];
    public vehicleClasses: string[] = [];
    public categories: string[] = [];
    private makes_: string[] = [];
    public models: string[] = [];
    public usageTypes: UsageType[] = [];
    private firstRegistrationFrom_: YearMonthDay = { year: null, month: null, day: null };
    private firstRegistrationTo_: YearMonthDay = { year: null, month: null, day: null };
    public mileageFrom: number | null = null;
    public mileageTo: number | null = null;
    public cubicCapacityFrom: number | null = null;
    public cubicCapacityTo: number | null = null;
    public powerFrom: number | null = null;
    public powerTo: number | null = null;
    public gearboxes: Gearbox[] = [];
    public drives: Drive[] = [];
    public fuels: Fuel[] = [];
    public exteriorColors: ExteriorColor[] = [];
    public interiorTypes: InteriorType[] = [];
    public climatisations: Climatisation[] = [];
    public speedControls: SpeedControl[] = [];
    public headlightTypes: HeadlightType[] = [];
    public parkingAssistants: ParkingAssistant[] = [];
    public trailerCouplingTypes: TrailerCouplingType[] = [];
    public priceFrom: number | null = null;
    public priceTo: number | null = null;
    public features: Feature[] = [];
    private dealerId_: string | null = null;
    public channel: OpportunityChannel = OpportunityChannel.PHONE_INTERNAL;
    public sourceId: string | null = null;
    public individualReceivers = new UserPickerData();
    public opportunityTeamReceivers: string[] = [];

    get makes() {
        return this.makes_;
    }

    set makes(makes: string[]) {
        if (makes.length) {
            this.models = this.models.filter((model) =>
                makes.some((make) => makeModelsStore.getModelsByMake(make).includes(model))
            );
        }

        this.makes_ = makes;
    }

    reset(inventoryVehicleInquiry: InventoryVehicleInquiry | null) {
        this.contactAndVehicleId = inventoryVehicleInquiry
            ? { contactId: inventoryVehicleInquiry.data.contactId, contactVehicleId: null }
            : null;
        this.contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
        this.id = inventoryVehicleInquiry?.id || null;

        this.openEndValidity_ = inventoryVehicleInquiry ? !inventoryVehicleInquiry.data.validUntil : true;
        this.validUntilDate =
            inventoryVehicleInquiry && inventoryVehicleInquiry.data.validUntil
                ? getDate(inventoryVehicleInquiry?.data.validUntil, configStore.configuration.defaultTimeZone)
                : "";
        this.validUntilTime =
            inventoryVehicleInquiry && inventoryVehicleInquiry.data.validUntil
                ? getTime(inventoryVehicleInquiry?.data.validUntil, configStore.configuration.defaultTimeZone)
                : "";

        const data = inventoryVehicleInquiry?.data;
        this.disableOnMatch = data?.disableOnMatch ?? true;
        this.comment = data?.comment || "";
        this.dealerIds = data?.dealerIds || (this.dealerId_ ? [this.dealerId_] : []);
        this.vehicleClasses = data?.vehicleClasses || [];
        this.categories = data?.categories || [];
        this.makes = data?.makes || [];
        this.models = data?.models || [];
        this.usageTypes = data?.usageTypes || [];
        this.firstRegistrationFrom = data?.firstRegistrationFrom || null;
        this.firstRegistrationTo = data?.firstRegistrationTo || null;
        this.mileageFrom = data?.mileageFrom || null;
        this.mileageTo = data?.mileageTo || null;
        this.cubicCapacityFrom = data?.cubicCapacityFrom || null;
        this.cubicCapacityTo = data?.cubicCapacityTo || null;
        this.powerFrom = data?.powerFrom || null;
        this.powerTo = data?.powerTo || null;
        this.gearboxes = data?.gearboxes || [];
        this.drives = data?.drives || [];
        this.fuels = data?.fuels || [];
        this.exteriorColors = data?.exteriorColors || [];
        this.interiorTypes = data?.interiorTypes || [];
        this.climatisations = data?.climatisations || [];
        this.speedControls = data?.speedControls || [];
        this.headlightTypes = data?.headlightTypes || [];
        this.parkingAssistants = data?.parkingAssistants || [];
        this.trailerCouplingTypes = data?.trailerCouplingTypes || [];
        this.priceFrom = data?.priceFrom || null;
        this.priceTo = data?.priceTo || null;
        this.features = data?.features || [];
        this.dealerId_ = data?.dealerId || this.dealerId_;
        this.channel = data?.channel || OpportunityChannel.PHONE_INTERNAL;
        this.sourceId = data?.sourceId || null;
        this.opportunityTeamReceivers = data?.opportunityTeamReceivers || [];

        this.individualReceivers = new UserPickerData();
        if (data) {
            this.individualReceivers.userIds = data.individualReceivers;
        }
    }

    setSearchCriteriaByInventoryVehicle(inventoryVehicle: InventoryVehicle) {
        this.dealerId = inventoryVehicle.dealerId;
        this.dealerIds = [inventoryVehicle.dealerId];
        this.vehicleClasses = inventoryVehicle.data.vehicleClass ? [inventoryVehicle.data.vehicleClass] : [];
        this.categories = inventoryVehicle.data.category ? [inventoryVehicle.data.category] : [];
        this.makes = inventoryVehicle.data.make ? [inventoryVehicle.data.make] : [];
        this.models = inventoryVehicle.data.model ? [inventoryVehicle.data.model] : [];
        this.usageTypes = inventoryVehicle.data.usageType ? [inventoryVehicle.data.usageType] : [];

        if (inventoryVehicle.data.firstRegistrationYear !== null) {
            this.firstRegistrationFrom_ = {
                year: inventoryVehicle.data.firstRegistrationYear - 1,
                month: inventoryVehicle.data.firstRegistrationMonth ?? 1,
                day: null,
            };
        }

        if (inventoryVehicle.data.mileage === 0 || inventoryVehicle.data.mileage) {
            this.mileageTo = getRangeValue(inventoryVehicle.data.mileage, 1.05, true, 1000);
        }

        if (inventoryVehicle.data.cubicCapacity) {
            this.cubicCapacityFrom = getRangeValue(inventoryVehicle.data.cubicCapacity, 0.9, false, 50);
            this.cubicCapacityTo = getRangeValue(inventoryVehicle.data.cubicCapacity, 1.1, true, 50);
        }

        if (inventoryVehicle.data.power) {
            this.powerFrom = getRangeValue(inventoryVehicle.data.power, 0.9, false, 5);
            this.powerTo = getRangeValue(inventoryVehicle.data.power, 1.1, true, 5);
        }

        this.gearboxes = inventoryVehicle.data.gearbox ? [inventoryVehicle.data.gearbox] : [];
        this.drives = inventoryVehicle.data.drive ? [inventoryVehicle.data.drive] : [];
        this.fuels = inventoryVehicle.data.fuel ? [inventoryVehicle.data.fuel] : [];
        this.exteriorColors = inventoryVehicle.data.exteriorColor ? [inventoryVehicle.data.exteriorColor] : [];
        this.interiorTypes = inventoryVehicle.data.interiorType ? [inventoryVehicle.data.interiorType] : [];
        this.climatisations = inventoryVehicle.data.climatisation ? [inventoryVehicle.data.climatisation] : [];
        this.speedControls = inventoryVehicle.data.speedControl ? [inventoryVehicle.data.speedControl] : [];
        this.headlightTypes = inventoryVehicle.data.headlightType ? [inventoryVehicle.data.headlightType] : [];
        this.parkingAssistants = inventoryVehicle.data.parkingAssistants;
        this.trailerCouplingTypes = inventoryVehicle.data.trailerCouplingType
            ? [inventoryVehicle.data.trailerCouplingType]
            : [];

        const latestConsumerPrice = getInventoryVehicleLatestPrice(inventoryVehicle)?.consumerPriceGross;
        if (latestConsumerPrice) {
            this.priceTo = getRangeValue(latestConsumerPrice, 1.15, true, 100);
        }

        this.features = (Object.keys(inventoryVehicle.data.features) as Feature[]).filter(
            (feature) => inventoryVehicle.data.features[feature]
        );
    }

    toData(): InventoryVehicleInquiryData {
        return {
            validUntil: this.validUntil(),
            disableOnMatch: this.disableOnMatch,
            comment: this.comment,
            contactId: this.contactAndVehicleId!.contactId,
            dealerIds: this.dealerIds,
            vehicleClasses: this.vehicleClasses,
            categories: this.categories,
            makes: this.makes,
            models: this.models,
            usageTypes: this.usageTypes,
            firstRegistrationFrom: this.firstRegistrationFrom,
            firstRegistrationTo: this.firstRegistrationTo,
            mileageFrom: this.mileageFrom,
            mileageTo: this.mileageTo,
            cubicCapacityFrom: this.cubicCapacityFrom,
            cubicCapacityTo: this.cubicCapacityTo,
            powerFrom: this.powerFrom,
            powerTo: this.powerTo,
            gearboxes: this.gearboxes,
            drives: this.drives,
            fuels: this.fuels,
            exteriorColors: this.exteriorColors,
            interiorTypes: this.interiorTypes,
            climatisations: this.climatisations,
            speedControls: this.speedControls,
            headlightTypes: this.headlightTypes,
            parkingAssistants: this.parkingAssistants,
            trailerCouplingTypes: this.trailerCouplingTypes,
            priceFrom: this.priceFrom,
            priceTo: this.priceTo,
            features: this.features,
            dealerId: this.dealerId!,
            channel: this.channel,
            sourceId: this.sourceId!,
            individualReceivers: this.individualReceivers.userIds,
            opportunityTeamReceivers: this.opportunityTeamReceivers,
        };
    }

    set dealerId(dealerId) {
        if (dealerId) {
            this.dealerId_ = dealerId;
        }
    }

    get dealerId() {
        return this.dealerId_;
    }

    private validUntil() {
        return this.openEndValidity_
            ? null
            : toDateObject(this.validUntilTimeZone!, this.validUntilDate, 0, this.validUntilTime);
    }

    get openEndValidity() {
        return this.openEndValidity_;
    }

    set openEndValidity(newValue) {
        if (!newValue) {
            this.validUntilDate = getDate(now(), this.validUntilTimeZone!);
            this.validUntilTime = getTime(now(), this.validUntilTimeZone!);
        }

        this.openEndValidity_ = newValue;
    }

    get firstRegistrationFrom() {
        return getYearMonthDay(this.firstRegistrationFrom_);
    }

    set firstRegistrationFrom(firstRegistrationFrom) {
        this.firstRegistrationFrom_ = toYearMonthDayObject(firstRegistrationFrom);
    }

    get firstRegistrationTo() {
        return getYearMonthDay(this.firstRegistrationTo_);
    }

    set firstRegistrationTo(firstRegistrationTo) {
        this.firstRegistrationTo_ = toYearMonthDayObject(firstRegistrationTo);
    }
}

export const inventoryVehicleInquiryAssistantState = new InventoryVehicleInquiryAssistantState();
