
import DatePicker from "@/app/components/DatePicker.vue";
import TimePicker from "@/app/components/TimePicker.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import { CarryDispoAssistantState } from "@/app/pages/carrydispo/assistant/carryDispoAssistant";
import { notEmpty } from "@/app/validation";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        carryDispoAssistantState: {
            type: Object as () => CarryDispoAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            notEmpty,
            now: now(),
        };
    },

    computed: {
        currentDate(): string {
            return getDate(this.now, userSession.timeZone);
        },
    },

    components: {
        DatePicker,
        TimePicker,
        TimeZonePicker,
    },
});
