
import {
    RenaultRaceLeadReceiverMappingForm,
    renaultRaceLeadReceiverMappingsApi,
} from "@/api/renaultRaceLeadReceiverMapping";
import { Permission } from "@/api/userSession";
import { showConfirm } from "@/app/messageUtil";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { getFullName } from "@/app/userUtils";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { Mutable, PickNullable, SelectOption } from "@/util/types";
import Vue from "vue";

type MutableRenaultRaceLeadReceiverMappingForm = PickNullable<
    Mutable<RenaultRaceLeadReceiverMappingForm>,
    "renaultRaceLeadReceiver" | "userId"
>;

const EMPTY_MUTABLE_RENAULT_RACE_LEAD_RECEIVER_MAPPING_FORM: MutableRenaultRaceLeadReceiverMappingForm = {
    renaultRaceLeadReceiver: null,
    userId: null,
};

export default Vue.extend({
    data() {
        return {
            notEmpty,

            Permission,

            items: [] as MutableRenaultRaceLeadReceiverMappingForm[],
            loading: true,
            saving: false,
        };
    },

    computed: {
        dealerId(): string | null {
            return userSession.dealerId;
        },

        leadReceiverRules(): Function[] {
            return [
                ...((notEmpty().maxLength(20) as unknown) as Function[]),
                (v: string | null) =>
                    (!!v && this.items.filter((m) => m.renaultRaceLeadReceiver?.trim() === v.trim()).length <= 1) ||
                    this.$t("Der Mitarbeiter ist bereits zugeordnet."),
            ];
        },

        userOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        addEmptyItem() {
            this.items.push({ renaultRaceLeadReceiver: null, userId: null });
        },

        isEmptyForm(form: MutableRenaultRaceLeadReceiverMappingForm): boolean {
            return JSON.stringify(EMPTY_MUTABLE_RENAULT_RACE_LEAD_RECEIVER_MAPPING_FORM) === JSON.stringify(form);
        },

        async loadItems() {
            this.items = [];

            if (!this.dealerId) {
                this.loading = false;
                return;
            }

            this.loading = true;

            try {
                this.items = await renaultRaceLeadReceiverMappingsApi.getByDealerId(this.dealerId);
            } finally {
                this.loading = false;
            }
        },

        async removeItem(index: number) {
            if (
                !this.isEmptyForm(this.items[index]) &&
                !(await showConfirm(
                    this.$t("Benutzerzuordnung löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Benutzerzuordnung löschen möchten?") as string
                ))
            ) {
                return false;
            }

            this.items.splice(index, 1);
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await renaultRaceLeadReceiverMappingsApi.edit(
                    this.dealerId!,
                    this.items as RenaultRaceLeadReceiverMappingForm[]
                );
            } finally {
                this.saving = false;
            }
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DealerContextGuard,
    },
});
