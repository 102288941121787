import { $t } from "./i18n";
import { Dealer } from "@/api/dealers";
import { dealersStore } from "@/store/dealers";
import { SelectOptions } from "@/util/types";

export function getSortedByDealer<T>(items: T[], getDealerId: (item: T) => string | null): T[] {
    const sorted = dealersStore.dealers
        .map((d) => items.filter((i) => getDealerId(i) === d.id))
        .reduce((prev, cur) => prev.concat(cur), []);

    sorted.unshift(...items.filter((i) => getDealerId(i) === null));

    return sorted;
}

export function dealerOptions(
    visiblePredicate: (d: Dealer) => boolean = () => true,
    disabledPredicate: (d: Dealer) => boolean = () => false
): SelectOptions {
    const dealers = dealersStore.dealers.filter(visiblePredicate);
    const inactiveDealers = dealers.filter((d) => !d.active);

    return [
        ...dealers.filter((d) => d.active).map((d) => ({ value: d.id, text: d.name, disabled: disabledPredicate(d) })),
        ...(inactiveDealers.length
            ? [
                  { header: $t("Inaktive Standorte") },
                  ...inactiveDealers.map((d) => ({ value: d.id, text: d.name, disabled: disabledPredicate(d) })),
              ]
            : []),
    ];
}
