
import CarryDispoDataCheckCard from "./CarryDispoDataCheckCard.vue";
import { carryDispoAssistantState } from "./carryDispoAssistant";
import CarryDispoDeliveryPointCard from "./singlepage/CarryDispoDeliveryPointCard.vue";
import CarryDispoEndDateCard from "./singlepage/CarryDispoEndDateCard.vue";
import CarryDispoOrderCard from "./singlepage/CarryDispoOrderCard.vue";
import CarryDispoServicePointCard from "./singlepage/CarryDispoServicePointCard.vue";
import CarryDispoTargetDateCard from "./singlepage/CarryDispoTargetDateCard.vue";
import CarryDispoVehicleCard from "./singlepage/CarryDispoVehicleCard.vue";
import { carryDispoApi } from "@/api/carryDispo";
import { CarryDispoLocation, carryDispoSettingsApi } from "@/api/carryDispoSettings";
import { DealerInstructionCategory, IssueType } from "@/api/dealerInstructions";
import { processQueryParameters } from "@/app/issueAssistantUtils";
import { showConfirm, showError } from "@/app/messageUtil";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import ContactAndVehiclePicker from "@/app/pages/contacts/ContactAndVehiclePicker.vue";
import DealerInstructions from "@/app/pages/dealerinstructions/DealerInstructions.vue";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            IssueType,
            adding: false,
            carryDispoAssistantState,
            checkingConfiguration: true,
            isConfigured: false,
            locations: [] as CarryDispoLocation[],
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        dealerInstructionCategories(): DealerInstructionCategory[] {
            if (this.carryDispoAssistantState.contactAndVehicleId) {
                return [DealerInstructionCategory.DATA_ACQUISITION, DealerInstructionCategory.RECEIVER_SELECTION];
            }

            return [
                DealerInstructionCategory.CONTACT_SEARCH,
                DealerInstructionCategory.DATA_ACQUISITION,
                DealerInstructionCategory.RECEIVER_SELECTION,
            ];
        },

        isAgent(): boolean {
            return userSession.isAgent();
        },

        phoneticSpellingLocale(): string {
            return configStore.configuration.defaultLocale;
        },

        working(): boolean {
            return this.adding;
        },
    },

    methods: {
        async addExternOrder() {
            if (!(this.$refs.form1 as any).validate() || !(this.$refs.form2 as any).validate()) {
                showError(this.$t("Bitte korrigieren Sie Ihre fehlerhaften Eingaben.") as string);
                return;
            }

            this.adding = true;
            try {
                await carryDispoApi.addExternOrder(this.dealerId!, {
                    ...this.carryDispoAssistantState.externOrderForm,
                    branch: dealersStore.dealers.find((d) => d.id === this.dealerId)?.name || null,
                });

                this.carryDispoAssistantState.reset();
                await this.$router.push(`/carry-dispo-orders`);
            } finally {
                this.adding = false;
            }
        },

        async loadLocations() {
            this.locations = [];

            if (this.dealerId) {
                this.locations = (
                    await carryDispoSettingsApi.getCarryDispoLocationsByDealer(this.dealerId)
                ).sort((a, b) => a.name.localeCompare(b.name, userSession.locale));
            }
        },

        async loadSettings() {
            this.isConfigured = false;
            this.checkingConfiguration = true;

            try {
                if (this.dealerId) {
                    this.isConfigured = await carryDispoSettingsApi.isEndpointConfigured(this.dealerId);
                } else {
                    this.isConfigured = false;
                }
            } finally {
                this.checkingConfiguration = false;
            }
        },

        async reset() {
            if (
                await showConfirm(
                    this.$t("Eingaben zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?") as string
                )
            ) {
                this.carryDispoAssistantState.reset();
                this.setTimeZonesIfNull();
            }
        },

        setTimeZonesIfNull() {
            if (!this.dealerId) {
                return;
            }

            const timeZone = dealersStore.dealerById(this.dealerId)?.timeZone;

            if (timeZone && !this.carryDispoAssistantState.targetTimeZone) {
                this.carryDispoAssistantState.targetTimeZone = timeZone;
            }

            if (timeZone && !this.carryDispoAssistantState.endTimeZone) {
                this.carryDispoAssistantState.endTimeZone = timeZone;
            }
        },
    },

    watch: {
        async dealerId() {
            this.setTimeZonesIfNull();
            await this.loadSettings();
            await this.loadLocations();
        },
    },

    async mounted() {
        this.setTimeZonesIfNull();
        await this.loadSettings();
        await this.loadLocations();
    },

    beforeRouteEnter(to, _, next) {
        processQueryParameters(to, next);
    },

    components: {
        CarryDispoDataCheckCard,
        CarryDispoDeliveryPointCard,
        CarryDispoEndDateCard,
        CarryDispoOrderCard,
        CarryDispoServicePointCard,
        CarryDispoTargetDateCard,
        CarryDispoVehicleCard,
        ContactAndVehiclePicker,
        DealerContextWarning,
        DealerInstructions,
    },
});
