
import ExceptionalDayDialog from "./ExceptionalDayDialog.vue";
import OfficeHoursCard from "./OfficeHoursCard.vue";
import OfficeHoursFormDialog from "./OfficeHoursFormDialog.vue";
import { Dealer } from "@/api/dealers";
import { Holiday, holidaysApi } from "@/api/holidays";
import { OfficeHours, officeHoursApi } from "@/api/officeHours";
import { Permission } from "@/api/userSession";
import { showConfirm } from "@/app/messageUtil";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { formatLocalDate, getDate, getWeek } from "@/util/dateTimeUtils";
import Sortable from "sortablejs";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            items: [] as OfficeHours[],
            loading: true,
            dialogVisible: false,
            dialogItem: null as OfficeHours | null,
            exceptionalDayDialogIsVisible: false,
            exceptionalDayDialogOfficeHoursId: null as string | null,
            exceptionalDayDialogReason: null as string | null,
            exceptionalDayDialogDate: null as string | null,
            holidays: [] as Holiday[],
            currentWeek: getWeek(now(), 0, "UTC", configStore.configuration.defaultLocale),
            suggestionsDays: 365,
            sortableInitialized: false,
        };
    },

    computed: {
        dealer(): Dealer | null {
            return this.dealerId ? dealersStore.dealerById(this.dealerId) : null;
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        timeZone(): string | undefined {
            if (!this.dealer) {
                return;
            }

            return this.dealer.timeZone;
        },
    },

    methods: {
        async dismissSuggestion(officeHours: OfficeHours, date: string) {
            if (
                await showConfirm(
                    this.$t("Vorschlag ignorieren") as string,
                    this.$t("Sind Sie sicher, dass Sie den Vorschlag für das Datum {0} ignorieren möchten?", [
                        formatLocalDate(date, userSession.locale, "S"),
                    ]) as string
                )
            ) {
                await officeHoursApi.dismissSuggestion(officeHours.id, date);
                await this.loadItems();
            }
        },

        showExceptionalDayDialog(officeHours: OfficeHours, date: string | null, reason: string | null) {
            this.exceptionalDayDialogOfficeHoursId = officeHours.id;
            this.exceptionalDayDialogReason = reason;
            this.exceptionalDayDialogDate = date;
            this.exceptionalDayDialogIsVisible = true;
        },

        closeExceptionalDayDialog() {
            this.exceptionalDayDialogIsVisible = false;
        },

        async deleteExceptionalDay(officeHours: OfficeHours, date: string) {
            await officeHoursApi.deleteExceptionalDay(officeHours.id, date);
            await this.loadItems();
        },

        getExceptionalDaySuggestions(officeHours: OfficeHours): Holiday[] {
            const result = [] as Holiday[];

            for (const holiday of this.holidays) {
                if (officeHours.dismissedSuggestions.find((e) => e === holiday.date)) {
                    continue;
                }

                if (officeHours.exceptionalDays.find((e) => e.date === holiday.date)) {
                    continue;
                }

                result.push(holiday);
            }

            return result;
        },

        async loadItems() {
            this.loading = true;

            try {
                if (this.dealerId) {
                    this.items = await officeHoursApi.getByDealer(this.dealerId);
                } else {
                    this.items = [];
                }
            } finally {
                this.loading = false;
            }
        },

        showCreateDialog() {
            this.dialogItem = null;
            this.dialogVisible = true;
        },

        showEditDialog(officeHours: OfficeHours) {
            this.dialogItem = officeHours;
            this.dialogVisible = true;
        },

        async deleteOfficeHours(officeHours: OfficeHours) {
            if (
                await showConfirm(
                    this.$t("Geschäftszeiten löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Geschäftszeiten {0} löschen möchten?", [
                        officeHours.name,
                    ]) as string
                )
            ) {
                await officeHoursApi.delete(officeHours.id);
                await this.loadItems();
            }
        },

        async saved() {
            this.dialogVisible = false;
            await this.loadItems();
        },

        async savedExceptionalDay() {
            await this.loadItems();
            this.exceptionalDayDialogIsVisible = false;
        },

        async refreshHolidaysAndWeek() {
            if (!this.dealer) {
                return;
            }

            this.currentWeek = getWeek(now(), 0, this.timeZone!, configStore.configuration.defaultLocale);
            this.holidays = await holidaysApi.holidays(
                this.currentWeek.begin,
                getDate(now(), this.timeZone!, this.suggestionsDays),
                this.dealer.country,
                this.dealer.state
            );
        },
    },

    watch: {
        async dealerId() {
            this.sortableInitialized = false;
            try {
                await this.refreshHolidaysAndWeek();
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        items() {
            const itemscol = this.$refs.itemscol as HTMLElement;
            if (!this.sortableInitialized && this.items.length && itemscol) {
                Sortable.create(itemscol, {
                    animation: 150,
                    handle: ".drag-handle",
                    ghostClass: "accent",
                    onEnd: async ({ oldIndex, newIndex }) => {
                        if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                            const item = this.items.splice(oldIndex, 1)[0];
                            this.items.splice(newIndex, 0, item);
                            await officeHoursApi.updateOrder(
                                this.items[0].dealerId,
                                this.items.map((d) => d.id)
                            );
                        }
                    },
                });
                this.sortableInitialized = true;
            }
        },
    },

    async mounted() {
        await this.refreshHolidaysAndWeek();
        await this.loadItems();
    },

    components: {
        DealerContextGuard,
        OfficeHoursFormDialog,
        OfficeHoursCard,
        ExceptionalDayDialog,
    },
});
