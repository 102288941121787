import axios from "axios";

export enum IncomingCallDistributionType {
    PARALLEL = "PARALLEL",
    ROUND_ROBIN = "ROUND_ROBIN",
}

export interface CallTrackingGroup {
    readonly id: string;
    readonly name: string;
    readonly targetUserIds: string[];
    readonly distributionType: IncomingCallDistributionType;
    readonly distributionTimeoutSeconds: number | null;
    readonly distributionOfficeHoursId: string | null;
    readonly distributeOnlyIfAvailable: boolean;
    readonly distributeOnlyIfInWorkingHours: boolean;
}

export interface CallTrackingGroupForm {
    readonly name: string;
    readonly targetUserIds: string[];
    readonly distributionType: IncomingCallDistributionType;
    readonly distributionTimeoutSeconds: number | null;
    readonly distributionOfficeHoursId: string | null;
    readonly distributeOnlyIfAvailable: boolean;
    readonly distributeOnlyIfInWorkingHours: boolean;
}

export interface CallTrackingGroupsApi {
    getAll(): Promise<CallTrackingGroup[]>;
    add(form: CallTrackingGroupForm): Promise<void>;
    edit(id: string, form: CallTrackingGroupForm): Promise<void>;
    delete(id: string): Promise<void>;
    updateOrder(ids: string[]): Promise<void>;
}

export const callTrackingGroupsApi: CallTrackingGroupsApi = {
    async getAll() {
        return (await axios.get("/api/call-tracking-groups")).data;
    },

    async add(form) {
        await axios.post("/api/call-tracking-groups", form);
    },

    async edit(id, form) {
        await axios.put(`/api/call-tracking-groups/${id}`, form);
    },

    async delete(id) {
        await axios.delete(`/api/call-tracking-groups/${id}`);
    },

    async updateOrder(ids) {
        await axios.post("/api/call-tracking-groups/_update-order", ids);
    },
};
