import { EMPTY_SEARCH_REQUEST } from "./financeContractSearchUtils";
import { FinanceContractSearchOrder, FinanceContractSearchRequest } from "@/api/financeContractSearch";
import { ProcessingState } from "@/api/financeContracts";
import { reactive } from "@/util/reactive";

@reactive
class FinanceContractOverviewState {
    private searchRequest_: FinanceContractSearchRequest = EMPTY_SEARCH_REQUEST;

    get searchRequest() {
        return this.searchRequest_;
    }

    set dealerIds(dealerIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest_, dealerIds };
    }

    get dealerIds() {
        return this.searchRequest_.dealerIds;
    }

    set uploadSourceIds(uploadSourceIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest_, uploadSourceIds };
    }

    get uploadSourceIds() {
        return this.searchRequest_.uploadSourceIds;
    }

    set processingStates(processingStates: ProcessingState[]) {
        this.searchRequest_ = { ...this.searchRequest_, processingStates };
    }

    get processingStates() {
        return this.searchRequest_.processingStates;
    }

    set finalInstallmentPaymentDateFrom(finalInstallmentPaymentDateFrom: string | null) {
        this.searchRequest_ = { ...this.searchRequest_, finalInstallmentPaymentDateFrom };
    }

    get finalInstallmentPaymentDateFrom() {
        return this.searchRequest_.finalInstallmentPaymentDateFrom;
    }

    set finalInstallmentPaymentDateTo(finalInstallmentPaymentDateTo: string | null) {
        this.searchRequest_ = { ...this.searchRequest_, finalInstallmentPaymentDateTo };
    }

    get finalInstallmentPaymentDateTo() {
        return this.searchRequest_.finalInstallmentPaymentDateTo;
    }

    set processFrom(processFrom: string | null) {
        this.searchRequest_ = { ...this.searchRequest_, processFrom };
    }

    get processFrom() {
        return this.searchRequest_.processFrom;
    }

    set processTo(processTo: string | null) {
        this.searchRequest_ = { ...this.searchRequest_, processTo };
    }

    get processTo() {
        return this.searchRequest_.processTo;
    }

    set hasFinalInstallmentPaymentDate(hasFinalInstallmentPaymentDate: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest_, hasFinalInstallmentPaymentDate };
    }

    get hasFinalInstallmentPaymentDate() {
        return this.searchRequest_.hasFinalInstallmentPaymentDate;
    }

    set search(search: string | null) {
        this.searchRequest_ = { ...this.searchRequest_, search };
    }

    get search() {
        return this.searchRequest_.search;
    }

    set sortBy(sortBy: FinanceContractSearchOrder) {
        this.searchRequest_ = { ...this.searchRequest_, sortBy };
    }

    get sortBy() {
        return this.searchRequest_.sortBy;
    }
}

export const financeContractOverviewState = new FinanceContractOverviewState();
