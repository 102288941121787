import axios from "axios";

export interface CustomWeek {
    readonly mondayOfWeek: string;
    readonly workingHoursTemplateId: string;
}

export interface WorkingHoursTemplateEntry {
    readonly dayOfWeek: number;
    readonly dealerId: string | null;
    readonly from: string;
    readonly to: string;
}

export interface WorkingHoursTemplate {
    readonly id: string;
    readonly name: string;
    readonly defaultTemplate: boolean;
    readonly workingHoursTemplateEntries: WorkingHoursTemplateEntry[];
}

export interface WorkingHoursTemplateForm {
    name: string;
    workingHoursTemplateEntries: WorkingHoursTemplateEntry[];
}

export interface WorkingHours {
    readonly userId: string;
    readonly workingHoursTemplates: WorkingHoursTemplate[];
    readonly confirmedWeeks: string[];
    readonly customWeeks: CustomWeek[];
}

interface WorkingHoursApi {
    getByMainDealer(dealerId: string): Promise<WorkingHours[]>;
    getByUser(userId: string): Promise<WorkingHours>;
    addTemplate(userId: string, workingHoursTemplateForm: WorkingHoursTemplateForm): Promise<void>;
    editTemplate(userId: string, id: string, workingHoursTemplateForm: WorkingHoursTemplateForm): Promise<void>;
    deleteTemplate(userId: string, id: string): Promise<void>;
    updateTemplateSetDefault(userId: string, id: string): Promise<void>;
    addConfirmedWeek(userId: string, mondayOfWeek: string, id: string): Promise<void>;
    upsertCustomWeek(userId: string, mondayOfWeek: string, id: string): Promise<void>;
    deleteCustomWeek(userId: string, mondayOfWeek: string): Promise<void>;
}

export const workingHoursApi: WorkingHoursApi = {
    async getByMainDealer(dealerId) {
        return (await axios.get(`/api/working-hours?dealerId=${dealerId}`)).data;
    },

    async getByUser(userId) {
        return (await axios.get(`/api/working-hours?userId=${userId}`)).data[0];
    },

    async addTemplate(userId, workingHoursTemplateForm) {
        return (await axios.post(`/api/working-hours?userId=${userId}`, workingHoursTemplateForm)).data;
    },

    async editTemplate(userId, id, workingHoursTemplateForm) {
        return (await axios.put(`/api/working-hours/${id}?userId=${userId}`, workingHoursTemplateForm)).data;
    },

    async deleteTemplate(userId, id) {
        return (await axios.delete(`/api/working-hours/${id}?userId=${userId}`)).data;
    },

    async updateTemplateSetDefault(userId, id) {
        await axios.post(`/api/working-hours/${id}/_set-default?userId=${userId}`);
    },

    async addConfirmedWeek(userId, mondayOfWeek, id) {
        await axios.post(`/api/working-hours/${id}/_confirm-week?userId=${userId}&mondayOfWeek=${mondayOfWeek}`);
    },

    async upsertCustomWeek(userId, mondayOfWeek, id) {
        await axios.post(`/api/working-hours/${id}/_upsert-custom-week?userId=${userId}&mondayOfWeek=${mondayOfWeek}`);
    },

    async deleteCustomWeek(userId, mondayOfWeek) {
        await axios.post(`/api/working-hours/_delete-custom-week?userId=${userId}&mondayOfWeek=${mondayOfWeek}`);
    },
};
