
import EnumField from "@/app/components/EnumField.vue";
import { FilterTimeRange, timeRangeUserSearchFilters } from "@/app/userSearchFilterUtils";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            FilterTimeRange,
            name: "",
            nameRules: notEmpty(),
            created: FilterTimeRange.NONE,
        };
    },

    methods: {
        submit() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }
            const createdFilter = timeRangeUserSearchFilters[this.created];
            this.$emit("submit", {
                name: this.name,
                createdFrom: createdFilter.from,
                createdTo: createdFilter.to,
                createdUnit: createdFilter.unit,
            });
        },

        close() {
            this.$emit("close");
        },
    },

    components: { EnumField },
});
