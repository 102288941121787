
import { smsSignatureTemplatePlaceholders } from "./smsSignatureTemplatePlaceholders";
import { smsSignatureTemplateApi } from "@/api/smsSignatureTemplate";
import { Permission } from "@/api/userSession";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { maxLength } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            content: "",
            contentRules: maxLength(8000),
            isWorking: false,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        placeholders(): string[] {
            return smsSignatureTemplatePlaceholders;
        },
    },

    methods: {
        appendToContent(placeholder: string) {
            this.content += placeholder;
        },

        async loadSmsSignatureTemplate() {
            if (!this.dealerId) {
                this.content = "";
                return;
            }

            try {
                this.isWorking = true;
                const smsSignatureTemplate = await smsSignatureTemplateApi.getByDealer(this.dealerId);

                this.content = smsSignatureTemplate.content || "";
            } finally {
                this.isWorking = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;
            try {
                this.content = trimAndReturnNullIfEmpty(this.content) || "";

                await smsSignatureTemplateApi.editSmsSignatureTemplate({
                    dealerId: this.dealerId!,
                    content: this.content,
                });
            } finally {
                this.isWorking = false;
            }
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadSmsSignatureTemplate();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadSmsSignatureTemplate();
    },

    components: {
        DealerContextGuard,
    },
});
