
import { Contact, contactsApi } from "@/api/contacts";
import { IncomingCallResult, incomingCallsApi, IncomingCallStatus } from "@/api/incomingCalls";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { renderContactCaption } from "@/app/contactUtils";
import { dealersStore } from "@/store/dealers";
import { incomingCallsStore } from "@/store/incomingCalls";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        incomingCall: {
            type: Object as () => IncomingCallResult,
            required: true,
        },
    },

    data() {
        return {
            contact: null as Contact | null,
            loadingContact: false,
            releasing: false,
            renderContactCaption,
        };
    },

    computed: {
        connectionEstablished(): boolean {
            return this.incomingCall.incomingCallStatus === IncomingCallStatus.IN_PROGRESS;
        },

        dealerName(): string | null {
            return dealersStore.dealerById(this.incomingCall.dealerId)?.name ?? null;
        },

        statusText(): string {
            const formattedFromNumber = this.incomingCall.fromNumber
                ? parseAndFormatNumber(this.incomingCall.fromNumber, "INTERNATIONAL")
                : this.$t("unbekannt");

            if (this.connectionEstablished) {
                return this.$t("Gesprächsverbindung mit {0} ist aufgebaut.", [formattedFromNumber]) as string;
            } else {
                return this.$t("Eingehender Anruf von {0}.", [formattedFromNumber]) as string;
            }
        },

        relevantCaseId(): string | null {
            if (this.incomingCall.caseId) {
                return this.incomingCall.caseId;
            }

            if (this.incomingCall.resolvedCaseId) {
                return this.incomingCall.resolvedCaseId;
            }

            return null;
        },

        relevantOpportunityId(): string | null {
            if (this.incomingCall.opportunityId) {
                return this.incomingCall.opportunityId;
            }

            if (this.incomingCall.resolvedOpportunityId) {
                return this.incomingCall.resolvedOpportunityId;
            }

            return null;
        },
    },

    methods: {
        async releaseCall() {
            this.releasing = true;
            await incomingCallsApi.releaseCall(this.incomingCall.id);
            incomingCallsStore.handleUpdate({ ...this.incomingCall, released: new Date() });
        },
    },

    async mounted() {
        if (this.incomingCall.resolvedContactId) {
            this.loadingContact = true;
            try {
                this.contact = await contactsApi.getContactById(this.incomingCall.resolvedContactId);
            } finally {
                this.loadingContact = false;
            }
        }
    },

    components: {
        TimeSpan,
    },
});
