
import { Partner, partnersApi } from "@/api/partners";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { renderPhoneNumberTypeIcon } from "@/app/contactUtils";
import { getCountryCaption, getStateCaption, getStateLabel } from "@/app/countryStateUtils";
import { getGoogleMapsLink, GoogleMapsAddress } from "@/app/googleServicesUtils";
import PhoneNumberLink from "@/app/pages/PhoneNumberLink.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            renderPhoneNumberTypeIcon,
            getStateLabel,
            getStateCaption,
            getCountryCaption,
            items: null as Partner[] | null,
        };
    },

    watch: {
        async dealerId() {
            try {
                await this.load();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    methods: {
        getPartnerGoogleMapsLink(partner: Partner): string | null {
            return getGoogleMapsLink(partner as GoogleMapsAddress, partner.name);
        },

        async load() {
            this.items = null;
            this.items = await partnersApi.getByDealer(this.dealerId);
        },
    },

    async mounted() {
        await this.load();
    },

    components: {
        CopyToClipboardIcon,
        PhoneNumberLink,
    },
});
