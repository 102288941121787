
import { CaseActionResult, userActionLinkApi } from "@/api/userActionLinkApi";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            authorized: false,
            caseActionResult: null as CaseActionResult | null,
            connecting: true,
            dialog: true,
        };
    },

    computed: {
        closedText(): string | null {
            if (!this.caseActionResult) {
                return null;
            }

            if (this.caseActionResult.hasCaseChanged) {
                return this.$t("Fall {0} wurde geschlossen.", [this.caseActionResult.caseId]) as string;
            } else if (this.caseActionResult.wasClosedBySelf) {
                return this.$t("Fall {0} wurde bereits am {1} von Ihnen geschlossen.", [
                    this.caseActionResult.caseId,
                    this.renderInstant(this.caseActionResult.closed!),
                ]) as string;
            } else if (this.caseActionResult.isCloserUser) {
                if (this.caseActionResult.closerFullName) {
                    return this.$t("Fall {0} wurde bereits am {1} von {2} geschlossen.", [
                        this.caseActionResult.caseId,
                        this.renderInstant(this.caseActionResult.closed!),
                        this.caseActionResult.closerFullName,
                    ]) as string;
                } else {
                    return this.$t("Fall {0} wurde bereits am {1} von einem gelöschten Benutzer geschlossen.", [
                        this.caseActionResult.caseId,
                        this.renderInstant(this.caseActionResult.closed!),
                    ]) as string;
                }
            } else if (this.caseActionResult.isCloserCtUser) {
                return this.$t("Fall {0} wurde bereits am {1} von einem externen Agenten geschlossen.", [
                    this.caseActionResult.caseId,
                    this.renderInstant(this.caseActionResult.closed!),
                ]) as string;
            } else {
                return this.$t("Fall {0} wurde bereits am {1} vom System geschlossen.", [
                    this.caseActionResult.caseId,
                    this.renderInstant(this.caseActionResult.closed!),
                ]) as string;
            }
        },

        closedTitle(): string | null {
            if (!this.caseActionResult) {
                return null;
            }

            if (this.caseActionResult.wasClosedBySelf) {
                if (this.caseActionResult.hasCaseChanged) {
                    return this.$t("Der Fall wurde Ihnen zugeteilt.") as string;
                } else {
                    return this.$t("Der Fall ist Ihnen bereits zugeteilt.") as string;
                }
            } else {
                return this.$t("Der Fall konnte Ihnen nicht zugeteilt werden.") as string;
            }
        },

        errorText(): string | null {
            if (this.connecting) {
                return null;
            }

            if (!this.authorized) {
                return this.$t("Dieser Link ist abgelaufen.") as string;
            } else if (!this.caseActionResult || !this.caseActionResult.caseId) {
                return this.$t("Der Fall wurde nicht gefunden.") as string;
            } else if (!this.caseActionResult.closed) {
                return this.$t("Es ist ein Fehler beim Schließen des Falles aufgetreten.") as string;
            } else {
                return null;
            }
        },

        hasError(): boolean {
            if (this.connecting) {
                return false;
            }

            return (
                !this.authorized ||
                !this.caseActionResult ||
                !this.caseActionResult.caseId ||
                !this.caseActionResult.closed
            );
        },

        ualt(): string {
            return (this.$route.query.ualt || "") as string;
        },
    },

    methods: {
        renderInstant(instant: Date): string | null {
            if (!this.caseActionResult) {
                return null;
            }

            return formatInstant(instant, this.caseActionResult.timeZone, this.caseActionResult.locale, "S");
        },
    },

    async created() {
        try {
            this.connecting = true;
            this.caseActionResult = await userActionLinkApi.closeCase(this.ualt);

            this.$i18n.locale = this.$vuetify.lang.current = this.caseActionResult.locale;
            this.$vuetify.theme.dark = this.caseActionResult.darkmode;
        } catch (e) {
            // noop
        } finally {
            this.authorized = !!this.caseActionResult;
            this.connecting = false;
        }
    },
});
