import { OutgoingWhatsAppMessage, OutgoingWhatsAppMessageStatus } from "@/api/outgoingWhatsApp";

export function getStatus(outgoingWhatsAppMessage: OutgoingWhatsAppMessage): OutgoingWhatsAppMessageStatus {
    if (outgoingWhatsAppMessage.failed) {
        return OutgoingWhatsAppMessageStatus.FAILED;
    } else if (outgoingWhatsAppMessage.delivered && outgoingWhatsAppMessage.read) {
        return OutgoingWhatsAppMessageStatus.READ;
    } else if (outgoingWhatsAppMessage.delivered) {
        return OutgoingWhatsAppMessageStatus.DELIVERED;
    } else {
        return OutgoingWhatsAppMessageStatus.SENT;
    }
}

export function getStatusColor(outgoingWhatsAppStatus: OutgoingWhatsAppMessageStatus | null): string | null {
    if (outgoingWhatsAppStatus === OutgoingWhatsAppMessageStatus.SENT) {
        return null;
    } else if (outgoingWhatsAppStatus === OutgoingWhatsAppMessageStatus.FAILED) {
        return "error";
    } else if (outgoingWhatsAppStatus === OutgoingWhatsAppMessageStatus.DELIVERED) {
        return "success";
    } else if (outgoingWhatsAppStatus === OutgoingWhatsAppMessageStatus.READ) {
        return "success";
    } else {
        return null;
    }
}

export function getStatusDate(outgoingWhatsAppMessage: OutgoingWhatsAppMessage): Date | null {
    const status = getStatus(outgoingWhatsAppMessage);

    if (status === OutgoingWhatsAppMessageStatus.SENT) {
        return outgoingWhatsAppMessage.created;
    } else if (status === OutgoingWhatsAppMessageStatus.FAILED) {
        return outgoingWhatsAppMessage.failed;
    } else if (status === OutgoingWhatsAppMessageStatus.DELIVERED) {
        return outgoingWhatsAppMessage.delivered;
    } else if (status === OutgoingWhatsAppMessageStatus.READ) {
        return outgoingWhatsAppMessage.read;
    } else {
        return null;
    }
}
