
import { ContactNumber, EmailAddress } from "@/api/contacts";
import ExpansionPanel from "@/app/components/ExpansionPanel.vue";
import PreferredContactFormContent from "@/app/pages/contacts/PreferredContactFormContent.vue";
import { OpportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        opportunityAssistantState: {
            type: Object as () => OpportunityAssistantState,
            required: true,
        },
    },

    computed: {
        emailAddresses(): EmailAddress[] {
            return this.opportunityAssistantState.contactData?.emailAddresses ?? [];
        },

        hasPreferredContactTime(): boolean {
            return (
                !!this.opportunityAssistantState.preferredContact.earliestContactTime ||
                !!this.opportunityAssistantState.preferredContact.latestContactTime
            );
        },

        numbers(): ContactNumber[] {
            return this.opportunityAssistantState.contactData?.numbers ?? [];
        },
    },

    components: {
        ExpansionPanel,
        PreferredContactFormContent,
    },
});
