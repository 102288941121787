
import { Contact } from "@/api/contacts";
import { NoteTemplate, noteTemplatesApi } from "@/api/noteTemplates";
import { NoteForm } from "@/api/notes";
import { ContactNameData, EMPTY_CONTACT_NAME_DATA } from "@/app/contactUtils";
import { EditModeMixin } from "@/app/editModeUtils";
import { renderNotePlaceholders } from "@/app/pages/notetemplates/notePlaceholders";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        working: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            noteForm: {
                content: "",
            } as NoteForm,
            noteTemplates: [] as NoteTemplate[],
            loadingTemplates: true,
        };
    },

    methods: {
        applyNoteTemplate(template: NoteTemplate) {
            this.noteForm.content = this.renderNoteTemplate(template);
        },

        close() {
            this.$emit("close");
        },

        renderNoteTemplate(template: NoteTemplate): string {
            const contactNameData: ContactNameData = this.contact?.deleted
                ? EMPTY_CONTACT_NAME_DATA
                : {
                      salutation: this.contact?.contactData.salutation || null,
                      companyName: this.contact?.contactData.companyName || null,
                      namePrefix: this.contact?.contactData.namePrefix || null,
                      givenName: this.contact?.contactData.givenName || null,
                      middleName: this.contact?.contactData.middleName || null,
                      familyName: this.contact?.contactData.familyName || null,
                      nameSuffix: this.contact?.contactData.nameSuffix || null,
                  };

            return renderNotePlaceholders(template.content, contactNameData, userSession.profile!);
        },

        submit() {
            this.$emit("submit", this.noteForm);
        },
    },

    async mounted() {
        this.loadingTemplates = true;
        try {
            this.noteTemplates = await noteTemplatesApi.list();
        } finally {
            this.loadingTemplates = false;
        }
    },

    mixins: [EditModeMixin],
});
