
import { getStatus, getStatusColor, getStatusDate } from "./outgoingWhatsAppUtils";
import { Contact } from "@/api/contacts";
import { isOutgoingWhatsAppUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import {
    OutgoingWhatsAppAndContact,
    outgoingWhatsAppApi,
    OutgoingWhatsAppMessage,
    OutgoingWhatsAppMessageStatus,
} from "@/api/outgoingWhatsApp";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { renderContactCaption } from "@/app/contactUtils";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        outgoingWhatsAppAndContact: {
            type: Object as () => OutgoingWhatsAppAndContact,
            required: true,
        },
    },

    data() {
        return {
            image: "",
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingWhatsAppAndContactProp: this.outgoingWhatsAppAndContact,
        };
    },

    computed: {
        contact(): Contact | null {
            return this.outgoingWhatsAppAndContactProp.contact;
        },

        contactCaption(): string | null {
            if (!this.contact) {
                return null;
            }

            return renderContactCaption(this.contact.contactData);
        },

        isContactDeleted(): boolean {
            return !!this.contact?.deleted;
        },

        outgoingWhatsAppMessage(): OutgoingWhatsAppMessage {
            return this.outgoingWhatsAppAndContactProp.outgoingWhatsAppMessage;
        },

        status(): OutgoingWhatsAppMessageStatus {
            return getStatus(this.outgoingWhatsAppMessage);
        },

        statusDate(): Date | null {
            return getStatusDate(this.outgoingWhatsAppMessage);
        },

        statusClass(): string | null {
            const color = getStatusColor(this.status);

            return color ? `${color}--text` : null;
        },
    },

    methods: {
        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        showMedia(download: boolean) {
            const mediaType = this.outgoingWhatsAppMessage.mediaType!;
            if (!download && mediaType.startsWith("image/")) {
                this.image = outgoingWhatsAppApi.generateMediaLink(this.outgoingWhatsAppMessage, {});
            } else {
                window.open(outgoingWhatsAppApi.generateMediaLink(this.outgoingWhatsAppMessage, {}, download));
            }
        },
    },

    watch: {
        outgoingWhatsAppAndContact() {
            this.outgoingWhatsAppAndContactProp = this.outgoingWhatsAppAndContact;
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isOutgoingWhatsAppUpdatedNotification(n) && this.outgoingWhatsAppMessage.id === n.id) {
                if (n.status === OutgoingWhatsAppMessageStatus.FAILED) {
                    this.outgoingWhatsAppAndContactProp = {
                        ...this.outgoingWhatsAppAndContactProp,
                        outgoingWhatsAppMessage: {
                            ...this.outgoingWhatsAppAndContactProp.outgoingWhatsAppMessage,
                            failed: n.timestamp,
                        },
                    };
                } else if (n.status === OutgoingWhatsAppMessageStatus.READ) {
                    this.outgoingWhatsAppAndContactProp = {
                        ...this.outgoingWhatsAppAndContactProp,
                        outgoingWhatsAppMessage: {
                            ...this.outgoingWhatsAppAndContactProp.outgoingWhatsAppMessage,
                            read: n.timestamp,
                            delivered:
                                this.outgoingWhatsAppAndContactProp.outgoingWhatsAppMessage.delivered || n.timestamp,
                        },
                    };
                } else if (n.status === OutgoingWhatsAppMessageStatus.DELIVERED) {
                    this.outgoingWhatsAppAndContactProp = {
                        ...this.outgoingWhatsAppAndContactProp,
                        outgoingWhatsAppMessage: {
                            ...this.outgoingWhatsAppAndContactProp.outgoingWhatsAppMessage,
                            delivered: n.timestamp,
                        },
                    };
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        ImageCarousel,
    },
});
