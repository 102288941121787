import {
    InventoryVehicleInquirySearchOrder,
    InventoryVehicleInquirySearchRequest,
} from "@/api/inventoryVehicleInquiries";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { reactive } from "@/util/reactive";

const EMPTY_INVENTORY_VEHICLE_INQUIRY_SEARCH_REQUEST: InventoryVehicleInquirySearchRequest = {
    enabledAndValid: true,
    dealerIds: [],
    sortBy: InventoryVehicleInquirySearchOrder.CREATED_DESC,
};

@reactive
export class InventoryVehicleInquiryOverviewState {
    private searchRequest_: InventoryVehicleInquirySearchRequest = EMPTY_INVENTORY_VEHICLE_INQUIRY_SEARCH_REQUEST;
    private initialized_ = false;

    get searchRequest() {
        return this.searchRequest_;
    }

    get initialized() {
        return this.initialized_;
    }

    set enabledAndValid(enabledAndValid) {
        this.searchRequest_ = { ...this.searchRequest_, enabledAndValid };
    }

    get enabledAndValid() {
        return this.searchRequest_.enabledAndValid;
    }

    set dealerIds(dealerIds) {
        this.searchRequest_ = { ...this.searchRequest_, dealerIds };
    }

    get dealerIds() {
        return this.searchRequest_.dealerIds;
    }

    set sortBy(sortBy) {
        this.searchRequest_ = { ...this.searchRequest_, sortBy };
    }

    get sortBy() {
        return this.searchRequest_.sortBy;
    }

    refresh() {
        this.searchRequest_ = cloneObject(this.searchRequest_);
    }

    reset() {
        this.initialized_ = true;
        this.searchRequest_ = {
            ...EMPTY_INVENTORY_VEHICLE_INQUIRY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
        };
    }
}

export const inventoryVehicleInquiryOverviewState = new InventoryVehicleInquiryOverviewState();
