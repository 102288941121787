
import PreferredContactFormCard from "./PreferredContactFormCard.vue";
import { getOpportunityStatus } from "./opportunityUtils";
import { Contact, ContactMethod } from "@/api/contacts";
import { Opportunity, OpportunityStatus } from "@/api/opportunities";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import CountDown from "@/app/components/CountDown.vue";
import PhoneNumberLink from "@/app/pages/PhoneNumberLink.vue";
import { configStore } from "@/store/config";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canEdit: {
            type: Boolean,
            required: true,
        },
        canSendEmail: {
            type: Boolean,
            default: false,
        },
        canSendSms: {
            type: Boolean,
            default: false,
        },
        canStartCall: {
            type: Boolean,
            default: false,
        },
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
    },

    data() {
        return {
            ContactMethod,
            parseAndFormatNumber,
            preferredContactFormVisible: false,
        };
    },

    computed: {
        canUseTelLink(): boolean {
            return configStore.configuration.canUseTelLink;
        },

        isOpportunityActive(): boolean {
            return (
                this.opportunityStatus !== OpportunityStatus.CLOSED &&
                this.opportunityStatus !== OpportunityStatus.POSTPONED
            );
        },

        opportunityStatus(): OpportunityStatus {
            return getOpportunityStatus(this.opportunityProp);
        },
    },

    methods: {
        closeEditForm() {
            this.preferredContactFormVisible = false;
        },

        sendEmail() {
            this.$emit("send-email", this.opportunityProp.preferredContactDetails);
        },

        sendSms() {
            this.$emit("send-sms", this.opportunityProp.preferredContactDetails);
        },

        showEditForm() {
            this.preferredContactFormVisible = true;
        },

        startCall() {
            this.$emit("start-call", this.opportunityProp.preferredContactDetails);
        },
    },

    components: {
        CopyToClipboardIcon,
        CountDown,
        PhoneNumberLink,
        PreferredContactFormCard,
    },
});
