
import { Dealer, LegalInspectionType } from "@/api/dealers";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            LegalInspectionType,
        };
    },

    computed: {
        dealer(): Dealer | null {
            return dealersStore.dealerById(this.dealerId);
        },

        legalInspectionNoticeColor(): string {
            if (!this.dealer) {
                return "error";
            }

            if (this.dealer.legalInspectionType === LegalInspectionType.NOT_POSSIBLE) {
                return "error";
            } else {
                return "success";
            }
        },
    },
});
