
import IncomingWhatsAppsOverviewDataTableRow from "./IncomingWhatsAppsOverviewDataTableRow.vue";
import { incomingWhatsAppsOverviewState } from "./incomingWhatsAppsOverview";
import {
    IncomingWhatsAppAndContact,
    incomingWhatsAppApi,
    IncomingWhatsAppSearchOrder,
    IssueType,
    ProcessingState,
} from "@/api/incomingWhatsApp";
import {
    isIncomingWhatsAppMessageUpdatedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { PickMutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const itemsPerPage = 100;

        return {
            incomingWhatsAppsOverviewState: incomingWhatsAppsOverviewState,
            IncomingWhatsAppSearchOrder,
            IssueType,
            items: [] as IncomingWhatsAppAndContact[],
            itemsPerPage,
            loading: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            ProcessingState,
            searchCounter: 1,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("WhatsApp"),
                    width: "30%",
                },
                {
                    text: this.$t("Nachricht"),
                    width: "35%",
                },
                {
                    text: this.$t("Kontakt und Vorgang"),
                    width: "35%",
                },
            ];
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const incomingWhatsAppSearchResults = await incomingWhatsAppApi.search(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    this.incomingWhatsAppsOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (incomingWhatsAppSearchResults.searchId === this.searchCounter) {
                    this.items = incomingWhatsAppSearchResults.results;
                    this.paging.totalSize = incomingWhatsAppSearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        refresh() {
            this.incomingWhatsAppsOverviewState.refresh();
        },
    },

    watch: {
        async "incomingWhatsAppsOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        if (!this.incomingWhatsAppsOverviewState.initialized) {
            this.incomingWhatsAppsOverviewState.setTodayQuickfilter();
        }

        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isIncomingWhatsAppMessageUpdatedNotification(n)) {
                this.items = this.items.map((item) =>
                    item.incomingWhatsAppMessage.id === n.id
                        ? {
                              ...item,
                              incomingWhatsAppMessage: {
                                  ...item.incomingWhatsAppMessage,
                                  caseId: n.caseId,
                                  opportunityId: n.opportunityId,
                                  processingState: n.processingState,
                                  processedAt: n.processedAt,
                              },
                          }
                        : item
                );
            }
        });

        await this.loadItems();
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        DataTable,
        DatePicker,
        EnumField,
        IncomingWhatsAppsOverviewDataTableRow,
    },
});
