import axios from "axios";

export interface TwoFactorAuthenticationSettings {
    readonly enabled: boolean;
    readonly exceptionalUserIds: string[];
    readonly exceptionalIpAddressRanges: string[];
}

interface TwoFactorAuthenticationSettingsApi {
    getSettings(): Promise<TwoFactorAuthenticationSettings>;
    editSettings(setting: TwoFactorAuthenticationSettings): Promise<void>;
}

export const twoFactorAuthenticationSettingsApi: TwoFactorAuthenticationSettingsApi = {
    async getSettings() {
        return (await axios.get("/api/two-factor-authentication-settings")).data;
    },

    async editSettings(settings) {
        await axios.post("/api/two-factor-authentication-settings", settings);
    },
};
