
import { DealerMapping, financeContractSettingsApi, SalespersonMapping } from "@/api/financeContractSettings";
import { dealerOptions } from "@/app/dealerUtils";
import { showConfirm } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { getFullName } from "@/app/userUtils";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            dealerMappings: [] as DealerMapping[],
            disableSalespersonResolving: false,
            loading: true,
            salespersonMappings: [] as SalespersonMapping[],
            userRules: notEmpty(),
            saving: false,
        };
    },

    computed: {
        userOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        salespersonRules(): Function[] {
            return [
                ...((notEmpty() as unknown) as Function[]),
                (v: string) =>
                    this.salespersonMappings.filter((m) => m.salesperson.trim() === v.trim()).length <= 1 ||
                    this.$t("Der Verkäufer ist bereits zugeordnet."),
            ];
        },

        dealerRules(): Function[] {
            return [
                ...((notEmpty() as unknown) as Function[]),
                (v: string) =>
                    this.dealerMappings.filter((m) => m.dealer.trim() === v.trim()).length <= 1 ||
                    this.$t("Der Standort ist bereits zugeordnet."),
            ];
        },
    },

    methods: {
        addSalespersonMapping() {
            this.salespersonMappings.push({ salesperson: "", userId: "" });
        },

        async removeSalespersonMapping(index: number) {
            if (
                !(await showConfirm(
                    this.$t("Verkäuferzuordnung löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Verkäuferzuordnung löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.salespersonMappings.splice(index, 1);
            await this.save();
        },

        addDealerMapping() {
            this.dealerMappings.push({ dealer: "", dealerId: "" });
        },

        async removeDealerMapping(index: number) {
            if (
                !(await showConfirm(
                    this.$t("Standortzuordnung löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Standortzuordnung löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.dealerMappings.splice(index, 1);
            await this.save();
        },

        async save() {
            if (!(this.$refs.mappingForm as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await financeContractSettingsApi.editSettings({
                    salespersonMappings: this.salespersonMappings,
                    disableSalespersonResolving: this.disableSalespersonResolving,
                    dealerMappings: this.dealerMappings,
                });
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        try {
            const settings = await financeContractSettingsApi.getSettings();
            this.salespersonMappings = settings.salespersonMappings;
            this.disableSalespersonResolving = settings.disableSalespersonResolving;
            this.dealerMappings = settings.dealerMappings;
        } finally {
            this.loading = false;
        }
    },

    components: {
        DealerGlobalContextHint,
    },
});
