import { ExternOrder, OrderType, Status } from "./carryDispo";
import { CarryDispoNote } from "./carryDispoNotes";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface ExternOrderResult {
    readonly externOrder: ExternOrder;
    readonly externOrderNotes: CarryDispoNote[];
}

export interface ExternOrderSearchRequest {
    readonly dealerId: string;
    readonly orderTypes: OrderType[];
    readonly status: Status | null;
    readonly createdFrom: string | null;
    readonly createdTo: string | null;
}

export interface ExternOrderSearchResults {
    readonly searchId: number;
    readonly totalSize: number;
    readonly results: ExternOrderResult[];
}

interface CarryDispoSearchApi {
    searchExternOrders(
        searchRequest: ExternOrderSearchRequest,
        start: number,
        size: number,
        searchId: number
    ): Promise<ExternOrderSearchResults>;
}

export const carryDispoSearchApi: CarryDispoSearchApi = {
    async searchExternOrders(searchRequest, start, size, searchId): Promise<ExternOrderSearchResults> {
        return cloneObject(
            (
                await axios.post("/api/carry-dispo-search", searchRequest, {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },
};
