import { EMPTY_SEARCH_REQUEST } from "./contactSearchUtils";
import { ContactSearchRequest } from "@/api/contactSearch";
import { ContactSource } from "@/api/contacts";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";

export interface ContactSearchOverviewState {
    readonly searchRequest: ContactSearchRequest;
    readonly initialized: boolean;
    dealerIds: string[];
    contactSources: ContactSource[];
    updatedFrom: string | null;
    updatedTo: string | null;
    search: string | null;

    tz(): string;
    refresh(): void;
}

@reactive
class ContactSearchOverviewStateImpl implements ContactSearchOverviewState {
    private searchRequest_: ContactSearchRequest = EMPTY_SEARCH_REQUEST;
    private initialized_ = false;

    get searchRequest() {
        return this.searchRequest_;
    }

    get initialized() {
        return this.initialized_;
    }

    get dealerIds() {
        return this.searchRequest.dealerIds;
    }

    set dealerIds(dealerIds: string[]) {
        this.initialized_ = true;

        this.searchRequest_ = {
            ...this.searchRequest,
            dealerIds,
        };
    }

    get contactSources() {
        return this.searchRequest.contactSources;
    }

    set contactSources(contactSources: ContactSource[]) {
        this.initialized_ = true;

        this.searchRequest_ = {
            ...this.searchRequest,
            contactSources,
        };
    }

    set updatedFrom(updatedFrom: string | null) {
        this.initialized_ = true;

        this.searchRequest_ = {
            ...this.searchRequest,
            updatedFrom: updatedFrom ? toDateObject(this.tz(), updatedFrom) : null,
        };
    }

    get updatedFrom() {
        return this.searchRequest.updatedFrom ? getDate(this.searchRequest.updatedFrom, this.tz()) : null;
    }

    set updatedTo(updatedTo: string | null) {
        this.initialized_ = true;

        this.searchRequest_ = {
            ...this.searchRequest,
            updatedTo: updatedTo ? toDateObject(this.tz(), updatedTo, 1) : null,
        };
    }

    get updatedTo() {
        return this.searchRequest.updatedTo ? getDate(this.searchRequest.updatedTo, this.tz(), -1) : null;
    }

    set search(search: string | null) {
        this.initialized_ = true;

        this.searchRequest_ = { ...this.searchRequest, search };
    }

    get search() {
        return this.searchRequest.search;
    }

    tz() {
        if (!userSession.mainDealerId) {
            return configStore.configuration.defaultTimeZone;
        } else {
            return (
                dealersStore.dealerById(userSession.mainDealerId)?.timeZone || configStore.configuration.defaultTimeZone
            );
        }
    }

    refresh() {
        this.searchRequest_ = { ...this.searchRequest };
    }
}

export const contactSearchOverviewState: ContactSearchOverviewState = new ContactSearchOverviewStateImpl();
