
import AnnouncementsInfoCard from "./AnnouncementsInfoCard.vue";
import DropboxInfoCard from "./DropboxInfoCard.vue";
import EmergencyIncidentInfoCard from "./EmergencyIncidentInfoCard.vue";
import ExternalBdcInfoCard from "./ExternalBdcInfoCard.vue";
import LegalInspectionInfoCard from "./LegalInspectionInfoCard.vue";
import LocationInfoCard from "./LocationInfoCard.vue";
import OpeningHoursInfoCard from "./OpeningHoursInfoCard.vue";
import PartnersInfoCard from "./PartnersInfoCard.vue";
import ServicesOfferedInfoCard from "./ServicesOfferedInfoCard.vue";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import { carryDispoAssistantState } from "@/app/pages/carrydispo/assistant/carryDispoAssistant";
import { caseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import { emergencyIncidentAssistantState } from "@/app/pages/emergencyincidents/assistant/emergencyIncidentAssistant";
import { opportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    computed: {
        dealer() {
            return userSession.dealerId ? dealersStore.dealerById(userSession.dealerId) : null;
        },
        dealerId() {
            return userSession.dealerId;
        },
        isCtUser() {
            return userSession.isCtUser();
        },
    },

    methods: {
        setContactSearchNumberOfAssistants(number: string) {
            carryDispoAssistantState.contactSearchRequest = {
                ...carryDispoAssistantState.contactSearchRequest,
                number,
            };
            caseAssistantState.contactSearchRequest = {
                ...caseAssistantState.contactSearchRequest,
                number,
            };
            emergencyIncidentAssistantState.contactSearchRequest = {
                ...emergencyIncidentAssistantState.contactSearchRequest,
                number,
            };
            opportunityAssistantState.contactSearchRequest = {
                ...opportunityAssistantState.contactSearchRequest,
                number,
            };
        },
    },

    mounted() {
        // take over caller id
        const number = this.$route.query.number;
        if (number && typeof number === "string" && this.dealer?.takeOverCallerId) {
            this.setContactSearchNumberOfAssistants(number);
        }
    },

    components: {
        DealerContextWarning,
        ExternalBdcInfoCard,
        LocationInfoCard,
        AnnouncementsInfoCard,
        OpeningHoursInfoCard,
        ServicesOfferedInfoCard,
        LegalInspectionInfoCard,
        DropboxInfoCard,
        PartnersInfoCard,
        EmergencyIncidentInfoCard,
    },
});
