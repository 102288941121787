import { EmailAlias, Gender, PhoneNumber } from "@/api/users";
import { ContactNameData } from "@/app/contactUtils";
import {
    contactPlaceholders,
    contactSalutationPlaceholders,
    renderContactPlaceholders,
    renderContactSalutationPlaceholders,
    renderUserPlaceholders,
    userPlaceholders,
} from "@/app/placeholderUtils";

export const notePlaceholders = [
    ...contactSalutationPlaceholders,
    ...contactPlaceholders,
    ...userPlaceholders,
    "USER_SIGNATURE",
];

interface UserNotePlaceholderInfo {
    readonly username: string | null;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly languages: string[];
    readonly phoneNumbers: PhoneNumber[];
    readonly emailAliases: EmailAlias[];
    readonly smsSignature: string | null;
}

export function renderNotePlaceholders(
    content: string,
    contactNameData: ContactNameData,
    user: UserNotePlaceholderInfo
) {
    return [
        (content: string) => renderContactSalutationPlaceholders(content, contactNameData),
        (content: string) => renderContactPlaceholders(content, contactNameData),
        (content: string) => renderUserPlaceholders(content, user),
        (content: string) => content.replace(/%USER_SIGNATURE%/g, user.smsSignature || ""),
    ].reduce((content, fn) => fn(content), content);
}
