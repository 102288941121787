import { Contact } from "./contacts";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum IncomingSmsSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
}

export enum IssueType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
    UNKNOWN = "UNKNOWN",
}

export enum ProcessingState {
    UNPROCESSED = "UNPROCESSED",
    UNPROCESSED_IGNORE_FILTER = "UNPROCESSED_IGNORE_FILTER",
    PROCESSED = "PROCESSED",
    PROCESS_MANUALLY = "PROCESS_MANUALLY",
    FILTERED = "FILTERED",
}

export interface IncomingSms {
    readonly id: string;
    readonly created: Date;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly providerReference: string;
    readonly fromNumber: string;
    readonly toNumber: string;
    readonly body: string;
    readonly processingState: ProcessingState;
    readonly processedAt: Date | null;
}

export interface IncomingSmsAndContact {
    readonly incomingSms: IncomingSms;
    readonly contact: Contact | null;
}

export interface IncomingSmsSearchRequest {
    readonly issueTypes: IssueType[];
    readonly processingStates: ProcessingState[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly sortBy: IncomingSmsSearchOrder;
}

export interface IncomingSmsSearchResults {
    readonly results: IncomingSmsAndContact[];
    readonly searchId: number;
    readonly totalSize: number;
}

interface IncomingSmsApi {
    getIncomingSmsByCase(caseId: string): Promise<IncomingSms[]>;
    getIncomingSmsByOpportunity(opportunityId: string): Promise<IncomingSms[]>;
    reactivate(id: string): Promise<void>;
    search(
        start: number,
        size: number,
        searchRequest: IncomingSmsSearchRequest,
        searchId: number
    ): Promise<IncomingSmsSearchResults>;
}

export const incomingSmsApi: IncomingSmsApi = {
    async getIncomingSmsByCase(caseId) {
        return cloneObject((await axios.get(`/api/incoming-sms/cases/${caseId}`)).data);
    },

    async getIncomingSmsByOpportunity(opportunityId) {
        return cloneObject((await axios.get(`/api/incoming-sms/opportunities/${opportunityId}`)).data);
    },

    async reactivate(id) {
        await axios.post(`/api/incoming-sms/${id}/_reactivate`);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/incoming-sms/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },
};
