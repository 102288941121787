import { EMPTY_SEARCH_REQUEST } from "./caseSearchUtils";
import { ActorType, CaseSearchOrder, CaseSearchRequest, SentimentType } from "@/api/caseSearch";
import { CaseChannel, CasePriority, CaseStatus, CaseUrgency } from "@/api/cases";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";

export interface CasesOverviewFilters {
    readonly dealerIds?: string[];
    readonly caseTypes?: string[];
    readonly status?: CaseStatus[];
    readonly channels?: CaseChannel[];
    readonly sentimentTypes?: SentimentType[];
    readonly urgencies?: CaseUrgency[];
    readonly priority?: CasePriority[];
    readonly creatorActorType?: ActorType[];
    readonly createdBy?: string[];
    readonly closerActorType?: ActorType[];
    readonly closedBy?: string[];
    readonly outcomeReasonIds?: (string | null)[];
    readonly assignees?: (string | null)[];
    readonly contactId?: string | null;
    readonly createdFrom?: string | null;
    readonly createdTo?: string | null;
    readonly lastActivityFrom?: string | null;
    readonly lastActivityTo?: string | null;
    readonly individualReceivers?: (string | null)[];
    readonly escalationGroupReceivers?: (string | null)[];
    readonly hasReceivers?: boolean | null;
    readonly search?: string | null;
}

export interface CasesOverviewState {
    readonly searchRequest: CaseSearchRequest;
    readonly initialized: boolean;
    dealerIds: string[];
    caseTypes: string[];
    status: CaseStatus[];
    channels: CaseChannel[];
    sentimentTypes: SentimentType[];
    urgencies: CaseUrgency[];
    priority: CasePriority[];
    creatorActorType: ActorType[];
    createdBy: string[];
    closerActorType: ActorType[];
    closedBy: string[];
    outcomeReasonIds: (string | null)[];
    assignees: (string | null)[];
    contactId: string | null;
    createdFrom: string | null;
    createdTo: string | null;
    lastActivityFrom: string | null;
    lastActivityTo: string | null;
    individualReceivers: (string | null)[];
    escalationGroupReceivers: (string | null)[];
    hasReceivers: boolean | null;
    search: string | null;
    sortBy: CaseSearchOrder;
    readonly todayQuickfilter: CaseSearchRequest;
    readonly isTodayQuickfilter: boolean;
    readonly unclosedQuickfilter: CaseSearchRequest;
    readonly isUnclosedQuickfilter: boolean;
    readonly openQuickfilter: CaseSearchRequest;
    readonly isOpenQuickfilter: boolean;
    readonly allQuickfilter: CaseSearchRequest;
    readonly isAllQuickfilter: boolean;
    readonly highPriorityQuickfilter: CaseSearchRequest;
    readonly isHighPriorityQuickfilter: boolean;
    readonly openNoReceiversQuickfilter: CaseSearchRequest;
    readonly isOpenNoReceiversQuickfilter: boolean;

    tz(): string;
    refresh(): void;
    setTodayQuickfilter(): void;
    setUnclosedQuickfilter(): void;
    setOpenQuickfilter(): void;
    setAllQuickfilter(): void;
    setHighPriorityQuickfilter(): void;
    setOpenNoReceiversQuickfilter(): void;
    restoreFilters(settings: CasesOverviewFilters): void;
}

@reactive
class CasesOverviewStateImpl implements CasesOverviewState {
    private searchRequest_: CaseSearchRequest = EMPTY_SEARCH_REQUEST;
    private initialized_ = false;

    get searchRequest() {
        return this.searchRequest_;
    }

    get initialized() {
        return this.initialized_;
    }

    set dealerIds(dealerIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest, dealerIds };
    }

    get dealerIds() {
        return this.searchRequest.dealerIds;
    }

    set caseTypes(caseTypes: string[]) {
        this.searchRequest_ = { ...this.searchRequest, caseTypes };
    }

    get caseTypes() {
        return this.searchRequest.caseTypes;
    }

    set status(status: CaseStatus[]) {
        this.searchRequest_ = { ...this.searchRequest, status };
    }

    get status() {
        return this.searchRequest.status;
    }

    set channels(channels: CaseChannel[]) {
        this.searchRequest_ = { ...this.searchRequest, channels };
    }

    get channels() {
        return this.searchRequest.channels;
    }

    set sentimentTypes(sentimentTypes: SentimentType[]) {
        this.searchRequest_ = { ...this.searchRequest, sentimentTypes };
    }

    get sentimentTypes() {
        return this.searchRequest.sentimentTypes;
    }

    set urgencies(urgencies: CaseUrgency[]) {
        this.searchRequest_ = { ...this.searchRequest, urgencies };
    }

    get urgencies() {
        return this.searchRequest.urgencies;
    }

    set priority(priority: CasePriority[]) {
        this.searchRequest_ = { ...this.searchRequest, priority };
    }

    get priority() {
        return this.searchRequest.priority;
    }

    set creatorActorType(creatorActorType: ActorType[]) {
        this.searchRequest_ = { ...this.searchRequest, creatorActorType };
    }

    get creatorActorType() {
        return this.searchRequest.creatorActorType;
    }

    set createdBy(createdBy: string[]) {
        this.searchRequest_ = { ...this.searchRequest, createdBy };
    }

    get createdBy() {
        return this.searchRequest.createdBy;
    }

    set closerActorType(closerActorType: ActorType[]) {
        this.searchRequest_ = { ...this.searchRequest, closerActorType };
    }

    get closerActorType() {
        return this.searchRequest.closerActorType;
    }

    set closedBy(closedBy: string[]) {
        this.searchRequest_ = { ...this.searchRequest, closedBy };
    }

    get closedBy() {
        return this.searchRequest.closedBy;
    }

    set outcomeReasonIds(outcomeReasonIds: (string | null)[]) {
        this.searchRequest_ = { ...this.searchRequest_, outcomeReasonIds };
    }

    get outcomeReasonIds() {
        return this.searchRequest_.outcomeReasonIds;
    }

    set assignees(assignees: (string | null)[]) {
        this.searchRequest_ = { ...this.searchRequest, assignees };
    }

    get assignees() {
        return this.searchRequest.assignees;
    }

    set contactId(contactId: string | null) {
        this.searchRequest_ = { ...this.searchRequest, contactIds: contactId ? [contactId] : [] };
    }

    get contactId() {
        return this.searchRequest.contactIds.length ? this.searchRequest.contactIds[0] : null;
    }

    set createdFrom(createdFrom: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdFrom: createdFrom ? toDateObject(this.tz(), createdFrom) : null,
        };
    }

    get createdFrom() {
        return this.searchRequest.createdFrom ? getDate(this.searchRequest.createdFrom, this.tz()) : null;
    }

    set createdTo(createdTo: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdTo: createdTo ? toDateObject(this.tz(), createdTo, 1) : null,
        };
    }

    get createdTo() {
        return this.searchRequest.createdTo ? getDate(this.searchRequest.createdTo, this.tz(), -1) : null;
    }

    set lastActivityFrom(lastActivityFrom: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            lastActivityFrom: lastActivityFrom ? toDateObject(this.tz(), lastActivityFrom) : null,
        };
    }

    get lastActivityFrom() {
        return this.searchRequest.lastActivityFrom ? getDate(this.searchRequest.lastActivityFrom, this.tz()) : null;
    }

    set lastActivityTo(lastActivityTo: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            lastActivityTo: lastActivityTo ? toDateObject(this.tz(), lastActivityTo, 1) : null,
        };
    }

    get lastActivityTo() {
        return this.searchRequest.lastActivityTo ? getDate(this.searchRequest.lastActivityTo, this.tz(), -1) : null;
    }

    set individualReceivers(individualReceivers: (string | null)[]) {
        this.searchRequest_ = { ...this.searchRequest, individualReceivers };
    }

    get individualReceivers() {
        return this.searchRequest.individualReceivers;
    }

    set escalationGroupReceivers(escalationGroupReceivers: (string | null)[]) {
        this.searchRequest_ = { ...this.searchRequest, escalationGroupReceivers };
    }

    get escalationGroupReceivers() {
        return this.searchRequest.escalationGroupReceivers;
    }

    set hasReceivers(hasReceivers: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest, hasReceivers };
    }

    get hasReceivers() {
        return this.searchRequest.hasReceivers;
    }

    set search(search: string | null) {
        this.searchRequest_ = { ...this.searchRequest, search };
    }

    get search() {
        return this.searchRequest.search;
    }

    set sortBy(sortBy: CaseSearchOrder) {
        this.searchRequest_ = { ...this.searchRequest, sortBy };
    }

    get sortBy() {
        return this.searchRequest.sortBy;
    }

    tz() {
        if (!userSession.mainDealerId) {
            return configStore.configuration.defaultTimeZone;
        } else {
            return (
                dealersStore.dealerById(userSession.mainDealerId)?.timeZone || configStore.configuration.defaultTimeZone
            );
        }
    }

    refresh() {
        this.searchRequest_ = cloneObject(this.searchRequest);
    }

    get todayQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            createdFrom: toDateObject(this.tz(), getDate(now(), this.tz())),
            createdTo: toDateObject(this.tz(), getDate(now(), this.tz(), 1)),
        };
    }

    setTodayQuickfilter() {
        this.searchRequest_ = this.todayQuickfilter;
        this.initialized_ = true;
    }

    get isTodayQuickfilter() {
        return JSON.stringify(this.todayQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get unclosedQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            status: [CaseStatus.OPEN, CaseStatus.ASSIGNED, CaseStatus.POSTPONED],
        };
    }

    setUnclosedQuickfilter() {
        this.searchRequest_ = this.unclosedQuickfilter;
        this.initialized_ = true;
    }

    get isUnclosedQuickfilter() {
        return JSON.stringify(this.unclosedQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get openQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            status: [CaseStatus.OPEN],
        };
    }

    setOpenQuickfilter() {
        this.searchRequest_ = this.openQuickfilter;
        this.initialized_ = true;
    }

    get isOpenQuickfilter() {
        return JSON.stringify(this.openQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get allQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
        };
    }

    setAllQuickfilter() {
        this.searchRequest_ = this.allQuickfilter;
        this.initialized_ = true;
    }

    get isAllQuickfilter() {
        return JSON.stringify(this.allQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get highPriorityQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            priority: [CasePriority.HIGH],
        };
    }

    setHighPriorityQuickfilter() {
        this.searchRequest_ = this.highPriorityQuickfilter;
        this.initialized_ = true;
    }

    get isHighPriorityQuickfilter() {
        return JSON.stringify(this.highPriorityQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get openNoReceiversQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            hasReceivers: false,
            status: [CaseStatus.OPEN],
        };
    }

    setOpenNoReceiversQuickfilter() {
        this.searchRequest_ = this.openNoReceiversQuickfilter;
        this.initialized_ = true;
    }

    get isOpenNoReceiversQuickfilter() {
        return JSON.stringify(this.openNoReceiversQuickfilter) === JSON.stringify(this.searchRequest);
    }

    restoreFilters(filters: CasesOverviewFilters) {
        this.searchRequest_ = EMPTY_SEARCH_REQUEST;

        this.dealerIds = filters.dealerIds ?? this.dealerIds;
        this.caseTypes = filters.caseTypes ?? this.caseTypes;
        this.status = filters.status ?? this.status;
        this.channels = filters.channels ?? this.channels;
        this.sentimentTypes = filters.sentimentTypes ?? this.sentimentTypes;
        this.urgencies = filters.urgencies ?? this.urgencies;
        this.priority = filters.priority ?? this.priority;
        this.creatorActorType = filters.creatorActorType ?? this.creatorActorType;
        this.createdBy = filters.createdBy ?? this.createdBy;
        this.closerActorType = filters.closerActorType ?? this.closerActorType;
        this.closedBy = filters.closedBy ?? this.closedBy;
        this.outcomeReasonIds = filters.outcomeReasonIds ?? this.outcomeReasonIds;
        this.assignees = filters.assignees ?? this.assignees;
        this.contactId = filters.contactId ?? this.contactId;
        this.createdFrom = filters.createdFrom ?? this.createdFrom;
        this.createdTo = filters.createdTo ?? this.createdTo;
        this.lastActivityFrom = filters.lastActivityFrom ?? this.lastActivityFrom;
        this.lastActivityTo = filters.lastActivityTo ?? this.lastActivityTo;
        this.individualReceivers = filters.individualReceivers ?? this.individualReceivers;
        this.escalationGroupReceivers = filters.escalationGroupReceivers ?? this.escalationGroupReceivers;
        this.hasReceivers = filters.hasReceivers ?? this.hasReceivers;
        this.search = filters.search ?? this.search;

        this.initialized_ = true;
    }
}

export const casesOverviewState: CasesOverviewState = new CasesOverviewStateImpl();
