import { EMPTY_SEARCH_REQUEST } from "./incomingCallSearchUtils";
import { IncomingCallSearchOrder, IncomingCallSearchRequest, IssueType } from "@/api/incomingCalls";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";

export interface IncomingCallsOverviewState {
    readonly searchRequest: IncomingCallSearchRequest;
    readonly initialized: boolean;
    dealerIds: string[];
    issueTypes: IssueType[];
    acceptorUserIds: string[];
    internalIncomingPhoneNumberIds: string[];
    fromNumbers: string[];
    createdFrom: string | null;
    createdTo: string | null;
    sortBy: IncomingCallSearchOrder;
    readonly todayQuickfilter: IncomingCallSearchRequest;
    readonly isTodayQuickfilter: boolean;
    readonly allQuickfilter: IncomingCallSearchRequest;
    readonly isAllQuickfilter: boolean;

    tz(): string;
    refresh(): void;
    setTodayQuickfilter(): void;
    setAllQuickfilter(): void;
}

@reactive
class IncomingCallsOverviewStateImpl implements IncomingCallsOverviewState {
    private searchRequest_: IncomingCallSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
    private initialized_ = false;

    get searchRequest() {
        return this.searchRequest_;
    }

    get initialized() {
        return this.initialized_;
    }

    get dealerIds() {
        return this.searchRequest.dealerIds;
    }

    set dealerIds(dealerIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest, dealerIds };
    }

    get issueTypes() {
        return this.searchRequest.issueTypes;
    }

    set issueTypes(issueTypes: IssueType[]) {
        this.searchRequest_ = { ...this.searchRequest, issueTypes };
    }

    get acceptorUserIds() {
        return this.searchRequest.acceptorUserIds;
    }

    set acceptorUserIds(acceptorUserIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest, acceptorUserIds };
    }

    get internalIncomingPhoneNumberIds() {
        return this.searchRequest.internalIncomingPhoneNumberIds;
    }

    set internalIncomingPhoneNumberIds(internalIncomingPhoneNumberIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest, internalIncomingPhoneNumberIds };
    }

    get fromNumbers() {
        return this.searchRequest.fromNumbers;
    }

    set fromNumbers(fromNumbers: string[]) {
        this.searchRequest_ = { ...this.searchRequest, fromNumbers };
    }

    get fromNumberBlocked() {
        return this.searchRequest.fromNumberBlocked;
    }

    set fromNumberBlocked(fromNumberBlocked: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest, fromNumberBlocked };
    }

    set createdFrom(createdFrom: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdFrom: createdFrom ? toDateObject(this.tz(), createdFrom) : null,
        };
    }

    get createdFrom() {
        return this.searchRequest.createdFrom ? getDate(this.searchRequest.createdFrom, this.tz()) : null;
    }

    set createdTo(createdTo: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdTo: createdTo ? toDateObject(this.tz(), createdTo, 1) : null,
        };
    }

    get createdTo() {
        return this.searchRequest.createdTo ? getDate(this.searchRequest.createdTo, this.tz(), -1) : null;
    }

    set sortBy(sortBy: IncomingCallSearchOrder) {
        this.searchRequest_ = { ...this.searchRequest, sortBy };
    }

    get sortBy() {
        return this.searchRequest.sortBy;
    }

    tz() {
        if (!userSession.mainDealerId) {
            return configStore.configuration.defaultTimeZone;
        } else {
            return (
                dealersStore.dealerById(userSession.mainDealerId)?.timeZone || configStore.configuration.defaultTimeZone
            );
        }
    }

    refresh() {
        this.searchRequest_ = cloneObject(this.searchRequest);
    }

    get todayQuickfilter() {
        return {
            ...cloneObject(EMPTY_SEARCH_REQUEST),
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            createdFrom: toDateObject(this.tz(), getDate(now(), this.tz())),
            createdTo: toDateObject(this.tz(), getDate(now(), this.tz(), 1)),
        };
    }

    setTodayQuickfilter() {
        this.searchRequest_ = this.todayQuickfilter;
        this.initialized_ = true;
    }

    get isTodayQuickfilter() {
        return JSON.stringify(this.todayQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get allQuickfilter() {
        return {
            ...cloneObject(EMPTY_SEARCH_REQUEST),
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
        };
    }

    setAllQuickfilter() {
        this.searchRequest_ = this.allQuickfilter;
    }

    get isAllQuickfilter() {
        return JSON.stringify(this.allQuickfilter) === JSON.stringify(this.searchRequest);
    }
}

export const incomingCallsOverviewState: IncomingCallsOverviewState = new IncomingCallsOverviewStateImpl();
