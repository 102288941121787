import { Contact } from "./contacts";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum CallStatus {
    INIT = "INIT",
    RINGING_INBOUND = "RINGING_INBOUND",
    CANCELED = "CANCELED",
    FAILED_INBOUND = "FAILED_INBOUND",
    CONNECTING = "CONNECTING",
    RINGING_OUTBOUND = "RINGING_OUTBOUND",
    IN_PROGRESS = "IN_PROGRESS",
    BUSY = "BUSY",
    FAILED_OUTBOUND = "FAILED_OUTBOUND",
    NO_ANSWER = "NO_ANSWER",
    COMPLETED = "COMPLETED",
    UNKNOWN = "UNKNOWN",
}

export enum InternalCallStatus {
    INIT = "INIT",
    RINGING = "RINGING",
    IN_PROGRESS = "IN_PROGRESS",
    BUSY = "BUSY",
    FAILED = "FAILED",
    NO_ANSWER = "NO_ANSWER",
    CANCELED = "CANCELED",
    COMPLETED = "COMPLETED",
}

export enum IssueType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
    UNKNOWN = "UNKNOWN",
}

export enum OutgoingCallSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
}

export interface OutgoingCall {
    readonly id: string;
    readonly created: Date;
    readonly initiatorUserId: string | null;
    readonly initiatorCtUserId: string | null;
    readonly contactId: string | null;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly dealerId: string;
    readonly inboundNumber: string;
    readonly callerId: string;
    readonly outboundNumber: string;
    readonly inboundCallStatus: InternalCallStatus;
    readonly outboundCallStatus: InternalCallStatus;
    readonly outboundCallAccepted: Date | null;
    readonly ended: Date | null;
    readonly released: Date | null;
    readonly note: string | null;
    readonly billed: boolean;
    readonly callStatus: CallStatus;
}

export interface OutgoingCallAndContact {
    readonly outgoingCall: OutgoingCall;
    readonly contact: Contact | null;
}

export interface OutgoingCallForm {
    inboundNumber: string;
    callerId: string;
    outboundNumber: string;
}

export interface OutgoingCallSearchRequest {
    readonly initiatorUserIds: string[];
    readonly issueTypes: IssueType[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly sortBy: OutgoingCallSearchOrder;
}

export interface OutgoingCallSearchResults {
    readonly results: OutgoingCallAndContact[];
    readonly searchId: number;
    readonly totalSize: number;
}

interface OutgoingCallsApi {
    cancelCall(id: string): Promise<void>;
    getAllActiveCalls(): Promise<OutgoingCall[]>;
    getMyCalls(start: number, size: number, searchId: number): Promise<OutgoingCallSearchResults>;
    getOutgoingCallsByCase(caseId: string): Promise<OutgoingCall[]>;
    getOutgoingCallsByOpportunity(opportunityId: string): Promise<OutgoingCall[]>;
    search(
        start: number,
        size: number,
        searchRequest: OutgoingCallSearchRequest,
        searchId: number
    ): Promise<OutgoingCallSearchResults>;
    setNote(id: string, note: string): Promise<void>;
    startCall(contactId: string | null, dealerId: string, outgoingCallForm: OutgoingCallForm): Promise<void>;
    releaseCall(id: string): Promise<void>;
}

export const outgoingCallsApi: OutgoingCallsApi = {
    async cancelCall(id) {
        await axios.post(`/api/outgoing-calls/${id}/_cancel-call`);
    },

    async getAllActiveCalls() {
        return cloneObject((await axios.get("/api/outgoing-calls/_active-calls")).data);
    },

    async getMyCalls(start, size, searchId) {
        return cloneObject(
            (
                await axios.get("/api/outgoing-calls/_my-calls", {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },

    async getOutgoingCallsByCase(caseId) {
        return cloneObject((await axios.get(`/api/outgoing-calls/cases/${caseId}`)).data);
    },

    async getOutgoingCallsByOpportunity(opportunityId) {
        return cloneObject((await axios.get(`/api/outgoing-calls/opportunities/${opportunityId}`)).data);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/outgoing-calls/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },

    async setNote(id, note) {
        await axios.patch(`/api/outgoing-calls/${id}`, null, { params: { note } });
    },

    async startCall(contactId, dealerId, outgoingCallForm) {
        await axios.post("/api/outgoing-calls/_start-call", outgoingCallForm, {
            params: {
                contactId,
                dealerId,
            },
        });
    },

    async releaseCall(id) {
        await axios.post(`/api/outgoing-calls/${id}/_release`);
    },
};
