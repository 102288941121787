
import { OpportunityChannel, OpportunityUrgency } from "@/api/opportunities";
import DateTimePicker from "@/app/components/DateTimePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import ExpansionPanel from "@/app/components/ExpansionPanel.vue";
import { OpportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        opportunityAssistantState: {
            type: Object as () => OpportunityAssistantState,
            required: true,
        },
    },

    data() {
        return {
            OpportunityChannel,
            OpportunityUrgency,
        };
    },

    computed: {
        timeZone(): string {
            return dealersStore.dealerById(this.opportunityAssistantState.dealerId!)!.timeZone;
        },
    },

    components: {
        DateTimePicker,
        EnumField,
        ExpansionPanel,
    },
});
