
import { InventoryVehicle } from "@/api/inventory";
import { renderInventoryVehicleTitle } from "@/app/inventoryUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        buttonClass: {
            type: String,
            default: "",
        },

        inventoryVehicles: {
            type: Array as () => InventoryVehicle[],
            required: true,
        },

        selectedItems: {
            type: Array as () => string[],
            required: true,
        },
    },

    methods: {
        isInventoryVehicleSelected(vehicle: InventoryVehicle) {
            return this.selectedItems.includes(vehicle.id);
        },

        renderInventoryVehicleTitle(vehicle: InventoryVehicle) {
            return renderInventoryVehicleTitle(vehicle);
        },

        selectInventoryVehicle(vehicle: InventoryVehicle) {
            this.$emit("select", vehicle);
        },
    },
});
