import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface RenaultRaceLeadReceiverMapping {
    readonly renaultRaceLeadReceiver: string;
    readonly userId: string;
}

export interface RenaultRaceLeadReceiverMappingForm {
    readonly renaultRaceLeadReceiver: string;
    readonly userId: string;
}

interface RenaultRaceLeadReceiverMappingsApi {
    edit(dealerId: string, renaultRaceLeadReceiverMappingForms: RenaultRaceLeadReceiverMappingForm[]): Promise<void>;
    getByDealerId(dealerId: string): Promise<RenaultRaceLeadReceiverMapping[]>;
}

export const renaultRaceLeadReceiverMappingsApi: RenaultRaceLeadReceiverMappingsApi = {
    async edit(dealerId, renaultRaceLeadReceiverMappingForms) {
        await axios.put(`/api/renault-race-lead-receiver-mappings/${dealerId}`, renaultRaceLeadReceiverMappingForms);
    },

    async getByDealerId(dealerId) {
        return cloneObject((await axios.get(`/api/renault-race-lead-receiver-mappings/${dealerId}`)).data);
    },
};
