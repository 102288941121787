import { IncomingCallResult, IncomingCallStatus } from "@/api/incomingCalls";
import { userSession } from "@/store/userSession";

export function canAddNote(incomingCallResult: IncomingCallResult): boolean {
    // only the acceptor can add a note
    if (incomingCallResult.acceptorUserId) {
        return incomingCallResult.acceptorUserId === userSession.userId;
    } else {
        return false;
    }
}

export function getStatusColor(incomingCallStatus: IncomingCallStatus | null): string | null {
    if (incomingCallStatus === IncomingCallStatus.RINGING) {
        return "warning";
    } else if (incomingCallStatus === IncomingCallStatus.IN_PROGRESS) {
        return "success";
    } else if (incomingCallStatus === IncomingCallStatus.NO_ANSWER) {
        return "error";
    } else if (incomingCallStatus === IncomingCallStatus.COMPLETED) {
        return "success";
    } else {
        return null;
    }
}
