import { cloneObject } from "./cloneUtils";

interface Group<K, T> {
    readonly key: K;
    readonly items: T[];
}

function keyToString<K>(key: K): string {
    return JSON.stringify(key);
}

export function partitionBy<K, T>(items: readonly T[], keyMapper: (item: T) => K): T[][] {
    const itemsByKey = items.reduce((map, item) => {
        const key = keyToString(keyMapper(item));
        const group = map.get(key) ?? [];

        group.push(item);
        map.set(key, group);

        return map;
    }, new Map<string, T[]>());

    return [...itemsByKey.values()];
}

export function groupBy<K, T>(items: readonly T[], keyMapper: (r: T) => K): Group<K, T>[] {
    return partitionBy(items, keyMapper).map((partition) => ({
        key: Object.freeze(cloneObject(keyMapper(partition[0]))),
        items: partition,
    }));
}
