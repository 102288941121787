
import OutgoingCallFormCard from "./OutgoingCallFormCard.vue";
import { OutgoingCallFormCardState } from "./outgoingCallFormCard";
import Vue from "vue";

export default Vue.extend({
    props: {
        onSubmit: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            outgoingCallFormCardState: new OutgoingCallFormCardState(),
        };
    },

    methods: {
        async submit() {
            await (this.$refs.outgoingCallFormCard as any).submit();
        },
    },

    components: {
        OutgoingCallFormCard,
    },
});
