
import { usersApi } from "@/api/users";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            isReset: false,
            username: "",
            usernameRules: notEmpty()
                .email()
                .msg(() => this.$t("E-Mail-Adresse ist erforderlich")),
            resetting: false,
        };
    },

    computed: {
        config() {
            return configStore.configuration;
        },
    },

    methods: {
        async resetPassword() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.resetting = true;
            try {
                await usersApi.resetForgottenPassword(this.username);
                this.isReset = true;
            } finally {
                this.resetting = false;
            }
        },
    },

    beforeRouteEnter(to, _, next) {
        if (userSession.permissions) {
            next((to.query.d as string) || "/");
        } else {
            next();
        }
    },
});
