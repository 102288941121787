import axios from "axios";

export interface SmsTemplate {
    id: string;
    name: string;
    content: string;
    locale: string;
}

export interface SmsTemplateForm {
    name: string;
    content: string;
    locale: string;
}

interface SmsTemplatesApi {
    list(fragment: boolean): Promise<SmsTemplate[]>;
    add(smsTemplateForm: SmsTemplateForm, fragment: boolean): Promise<void>;
    edit(id: string, smsTemplateForm: SmsTemplateForm): Promise<void>;
    editOrder(ids: string[]): Promise<void>;
    delete(id: string): Promise<void>;
}

export const smsTemplatesApi: SmsTemplatesApi = {
    async list(fragment) {
        return (await axios.get("/api/sms-templates", { params: { fragment } })).data;
    },

    async add(smsTemplateForm, fragment) {
        await axios.post("/api/sms-templates", smsTemplateForm, { params: { fragment } });
    },

    async edit(id, smsTemplateForm) {
        await axios.put(`/api/sms-templates/${id}`, smsTemplateForm);
    },

    async editOrder(ids) {
        await axios.post("/api/sms-templates/_edit-order", ids);
    },

    async delete(id) {
        await axios.delete(`/api/sms-templates/${id}`);
    },
};
