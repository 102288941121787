
import { CaseUrgency } from "@/api/cases";
import { ContactMethod, ContactNumber, EmailAddress } from "@/api/contacts";
import DateTimePicker from "@/app/components/DateTimePicker.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import { getContactDetailsLabel, getContactDetailsOptions } from "@/app/pages/contacts/preferredContact";
import { notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { getDate } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            CaseUrgency,
            notEmpty,
            now: now(),
        };
    },

    computed: {
        caseUrgencyOptions(): SelectOption[] {
            return [
                {
                    text: this.$t("Normal"),
                    value: CaseUrgency.NORMAL,
                },
                {
                    text: this.$t("Hoch"),
                    value: CaseUrgency.HIGH,
                },
                {
                    text: this.$t("Kunde vor Ort"),
                    value: CaseUrgency.PERSONAL_APPEARANCE,
                },
            ];
        },

        contactDetailsLabel(): string | null {
            return getContactDetailsLabel(this.caseAssistantState.preferredContact.preferredContactMethod);
        },

        contactDetailsOptions(): SelectOption[] {
            return getContactDetailsOptions(
                this.caseAssistantState.preferredContact.preferredContactMethod,
                this.contactEmailAddresses,
                this.contactNumbers
            );
        },

        contactEmailAddresses(): EmailAddress[] {
            return this.caseAssistantState.contactData?.emailAddresses ?? [];
        },

        contactMethodOptions(): SelectOption[] {
            return Object.keys(ContactMethod).map((value) => ({
                text: this.$t(`enum.ContactMethod.${value}`),
                value,
            }));
        },

        contactNumbers(): ContactNumber[] {
            return this.caseAssistantState.contactData?.numbers ?? [];
        },

        currentDateForPreferredContact(): string | null {
            return this.caseAssistantState.preferredContactTimeZone
                ? getDate(this.now, this.caseAssistantState.preferredContactTimeZone)
                : null;
        },

        dealerTimeZone(): string {
            return dealersStore.dealerById(this.caseAssistantState.dealerId!)!.timeZone;
        },

        earliestContactDate(): string | null {
            return this.caseAssistantState.preferredContact.earliestContactTime &&
                this.caseAssistantState.preferredContactTimeZone
                ? getDate(
                      this.caseAssistantState.preferredContact.earliestContactTime,
                      this.caseAssistantState.preferredContactTimeZone
                  )
                : null;
        },

        latestContactDate(): string | null {
            return this.caseAssistantState.preferredContact.latestContactTime &&
                this.caseAssistantState.preferredContactTimeZone
                ? getDate(
                      this.caseAssistantState.preferredContact.latestContactTime,
                      this.caseAssistantState.preferredContactTimeZone
                  )
                : null;
        },
    },

    methods: {
        setPreferredContactMethod(contactMethod: ContactMethod) {
            this.caseAssistantState.preferredContact.preferredContactMethod = contactMethod;

            const contactDetailsOptions = getContactDetailsOptions(
                contactMethod,
                this.contactEmailAddresses,
                this.contactNumbers
            );

            if (
                !contactDetailsOptions.some(
                    (o) => o.value === this.caseAssistantState.preferredContact.preferredContactDetails
                )
            ) {
                this.caseAssistantState.preferredContact.preferredContactDetails = null;
            }

            if (
                this.caseAssistantState.preferredContact.preferredContactDetails === null &&
                contactDetailsOptions.length === 1
            ) {
                this.caseAssistantState.preferredContact.preferredContactDetails = contactDetailsOptions[0].value;
            }
        },
    },

    components: {
        DateTimePicker,
        TimeZonePicker,
    },
});
