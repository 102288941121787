import { EMPTY_SEARCH_REQUEST } from "./emergencyIncidentSearchUtils";
import { ActorType, EmergencyIncidentSearchOrder, EmergencyIncidentSearchRequest } from "@/api/emergencyIncidentSearch";
import { EmergencyIncidentType } from "@/api/emergencyIncidents";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";

export interface EmergencyIncidentsOverviewState {
    readonly searchRequest: EmergencyIncidentSearchRequest;
    readonly initialized: boolean;
    dealerIds: string[];
    types: EmergencyIncidentType[];
    creatorActorType: ActorType[];
    createdBy: string[];
    closerActorType: ActorType[];
    closedBy: string[];
    open: boolean | null;
    contactId: string | null;
    createdFrom: string | null;
    createdTo: string | null;
    search: string | null;
    sortBy: EmergencyIncidentSearchOrder;
    readonly todayQuickfilter: EmergencyIncidentSearchRequest;
    readonly isTodayQuickfilter: boolean;
    readonly openQuickfilter: EmergencyIncidentSearchRequest;
    readonly isOpenQuickfilter: boolean;
    readonly allQuickfilter: EmergencyIncidentSearchRequest;
    readonly isAllQuickfilter: boolean;

    tz(): string;
    refresh(): void;
    setTodayQuickfilter(): void;
    setOpenQuickfilter(): void;
    setAllQuickfilter(): void;
}

@reactive
class EmergencyIncidentsOverviewStateImpl implements EmergencyIncidentsOverviewState {
    private searchRequest_: EmergencyIncidentSearchRequest = EMPTY_SEARCH_REQUEST;
    private initialized_ = false;

    get searchRequest() {
        return this.searchRequest_;
    }

    get initialized() {
        return this.initialized_;
    }

    set dealerIds(dealerIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest, dealerIds };
    }

    get dealerIds() {
        return this.searchRequest.dealerIds;
    }

    set types(types: EmergencyIncidentType[]) {
        this.searchRequest_ = { ...this.searchRequest, types };
    }

    get types() {
        return this.searchRequest.types;
    }

    set open(open: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest, open };
    }

    get open() {
        return this.searchRequest.open;
    }

    set creatorActorType(creatorActorType: ActorType[]) {
        this.searchRequest_ = { ...this.searchRequest, creatorActorType };
    }

    get creatorActorType() {
        return this.searchRequest.creatorActorType;
    }

    set createdBy(createdBy: string[]) {
        this.searchRequest_ = { ...this.searchRequest, createdBy };
    }

    get createdBy() {
        return this.searchRequest.createdBy;
    }

    set closerActorType(closerActorType: ActorType[]) {
        this.searchRequest_ = { ...this.searchRequest, closerActorType };
    }

    get closerActorType() {
        return this.searchRequest.closerActorType;
    }

    set closedBy(closedBy: string[]) {
        this.searchRequest_ = { ...this.searchRequest, closedBy };
    }

    get closedBy() {
        return this.searchRequest.closedBy;
    }

    set contactId(contactId: string | null) {
        this.searchRequest_ = { ...this.searchRequest, contactId };
    }

    get contactId() {
        return this.searchRequest.contactId;
    }

    set createdFrom(createdFrom: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdFrom: createdFrom ? toDateObject(this.tz(), createdFrom) : null,
        };
    }

    get createdFrom() {
        return this.searchRequest.createdFrom ? getDate(this.searchRequest.createdFrom, this.tz()) : null;
    }

    set createdTo(createdTo: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdTo: createdTo ? toDateObject(this.tz(), createdTo, 1) : null,
        };
    }

    get createdTo() {
        return this.searchRequest.createdTo ? getDate(this.searchRequest.createdTo, this.tz(), -1) : null;
    }

    set search(search: string | null) {
        this.searchRequest_ = { ...this.searchRequest, search };
    }

    get search() {
        return this.searchRequest.search;
    }

    set sortBy(sortBy: EmergencyIncidentSearchOrder) {
        this.searchRequest_ = { ...this.searchRequest, sortBy };
    }

    get sortBy() {
        return this.searchRequest.sortBy;
    }

    tz() {
        if (!userSession.mainDealerId) {
            return configStore.configuration.defaultTimeZone;
        } else {
            return (
                dealersStore.dealerById(userSession.mainDealerId)?.timeZone || configStore.configuration.defaultTimeZone
            );
        }
    }

    refresh() {
        this.searchRequest_ = cloneObject(this.searchRequest);
    }

    get todayQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            createdFrom: toDateObject(this.tz(), getDate(now(), this.tz())),
            createdTo: toDateObject(this.tz(), getDate(now(), this.tz(), 1)),
        };
    }

    setTodayQuickfilter() {
        this.searchRequest_ = this.todayQuickfilter;
    }

    get isTodayQuickfilter() {
        return JSON.stringify(this.todayQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get openQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            open: true,
        };
    }

    setOpenQuickfilter() {
        this.searchRequest_ = this.openQuickfilter;
        this.initialized_ = true;
    }

    get isOpenQuickfilter() {
        return JSON.stringify(this.openQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get allQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
        };
    }

    setAllQuickfilter() {
        this.searchRequest_ = this.allQuickfilter;
    }

    get isAllQuickfilter() {
        return JSON.stringify(this.allQuickfilter) === JSON.stringify(this.searchRequest);
    }
}

export const emergencyIncidentsOverviewState: EmergencyIncidentsOverviewState = new EmergencyIncidentsOverviewStateImpl();
