
import OpportunitiesDataTable from "./OpportunitiesDataTable.vue";
import { EMPTY_SEARCH_REQUEST } from "./opportunitySearchUtils";
import { Contact } from "@/api/contacts";
import { OpportunitySearchOrder, OpportunitySearchRequest, OpportunitySearchResult } from "@/api/opportunitySearch";
import { Permission } from "@/api/userSession";
import EnumField from "@/app/components/EnumField.vue";
import { showConfirm } from "@/app/messageUtil";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityId: {
            type: String,
            required: true,
        },
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
    },

    data() {
        return {
            emptySearchRequest: EMPTY_SEARCH_REQUEST,
            OpportunitySearchOrder,
            search: null as string | null,
            searchSortBy: OpportunitySearchOrder.CREATED_DESC,
            showOnlyWithContact: false,
        };
    },

    computed: {
        contactId(): string | null {
            return this.contact && !this.contact.deleted ? this.contact.id : null;
        },

        searchRequest(): OpportunitySearchRequest {
            return {
                ...this.emptySearchRequest,
                contactIds: this.contactId && this.showOnlyWithContact ? [this.contactId] : [],
                search: this.search,
                sortBy: this.searchSortBy,
            };
        },

        showOnlyMyOpportunities(): boolean {
            return (
                !userSession.hasPermission(Permission.MANAGE_ALL_OPPORTUNITIES) ||
                !userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES)
            );
        },
    },

    methods: {
        async selectOpportunity(opportunitySearchResult: OpportunitySearchResult) {
            if (
                await showConfirm(
                    this.$t("Verkaufschancen zusammenführen") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie die soeben ausgewählte Verkaufschance löschen und deren Inhalte in die aktuelle Verkaufschance übernehmen möchten?"
                    ) as string
                )
            ) {
                this.$emit("merge", opportunitySearchResult.opportunityId);
            }
        },

        close() {
            this.$emit("close");
        },

        refreshSearch() {
            this.emptySearchRequest = { ...EMPTY_SEARCH_REQUEST };
        },
    },

    components: {
        EnumField,
        OpportunitiesDataTable,
    },
});
