
import AbsenceManagement from "./AbsenceManagement.vue";
import { Permission } from "@/api/userSession";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },
    },

    components: {
        DealerContextGuard,
        AbsenceManagement,
    },
});
