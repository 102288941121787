
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import { getContactDetailsLabel, getContactDetailsOptions } from "@/app/pages/contacts/preferredContact";
import { getSentimentIcon, getSentimentIconColor, getSentimentText, SENTIMENTS } from "@/app/sentimentUtils";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            sentimentRules: userSession.isCtUser() ? notEmpty() : [],
        };
    },

    computed: {
        contactDetailsLabel(): string | null {
            return getContactDetailsLabel(this.caseAssistantState.preferredContact.preferredContactMethod);
        },

        contactDetailsOptions(): SelectOption[] {
            return getContactDetailsOptions(
                this.caseAssistantState.preferredContact.preferredContactMethod,
                this.caseAssistantState.contactData?.emailAddresses ?? [],
                this.caseAssistantState.contactData?.numbers ?? []
            );
        },

        sentimentItems() {
            return [...SENTIMENTS, null].map((s) => ({
                value: s,
                icon: getSentimentIcon(s),
                iconColor: getSentimentIconColor(s),
                text: getSentimentText(s),
            }));
        },
    },

    methods: {
        isSentimentSelected(sentiment: number | null) {
            return this.caseAssistantState.sentiment === sentiment;
        },

        selectSentiment(sentiment: number | null) {
            this.caseAssistantState.sentiment = sentiment;
        },
    },

    components: {
        CaseTypePicker,
    },
});
