var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dealer-context-guard',{attrs:{"permission":_vm.Permission.MANAGE_EMERGENCY_INCIDENT_NOTIFICATION_SETTINGS,"container":""}},[_c('v-container',{staticClass:"pb-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("Benachrichtigungseinstellungen"))+" ")]),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-cog")])],1),(_vm.loading)?_c('v-card-text',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(!_vm.loading)?_c('v-card-text',[_c('v-autocomplete',{attrs:{"autocomplete":"no","hide-details":"","items":_vm.emergencyIncidentNotificationEmailFormatOptions,"label":_vm.$t('E-Mail-Format'),"prepend-icon":"mdi-email","rules":_vm.notEmpty()},on:{"change":_vm.submitForm},model:{value:(_vm.emergencyIncidentNotificationEmailFormat),callback:function ($$v) {_vm.emergencyIncidentNotificationEmailFormat=$$v},expression:"emergencyIncidentNotificationEmailFormat"}})],1):_vm._e()],1)],1)],1),_c('crud-table-page',{attrs:{"no-items-text":_vm.$t('Es wurden keine Benachrichtigungsempfänger gefunden.'),"add-dialog-title":_vm.$t('Benachrichtigungsempfänger hinzufügen'),"page-title":_vm.$t('Benachrichtigungsempfänger'),"headers":_vm.headers,"callbacks":{
            load: () => _vm.getEmergencyIncidentNotificationReceivers(_vm.dealerId),
            add: (_, form, formRef) => _vm.addEmergencyIncidentNotificationReceiver(form, formRef),
            delete: (item) => _vm.deleteEmergencyIncidentNotificationReceiver(item),
            key: (item) => JSON.stringify(item),
            toForm: (item) => _vm.toEmergencyIncidentNotificationReceiverForm(item),
            emptyForm: () => _vm.emptyEmergencyIncidentNotificationReceiverForm(),
        }},scopedSlots:_vm._u([{key:"item.user",fn:function({item}){return [_vm._v(" "+_vm._s(_vm.getFullNameById(item.userId))+" ")]}},{key:"dialog",fn:function({ form }){return [_c('v-autocomplete',{attrs:{"autocomplete":"no","dense":"","hide-details":"auto","id":_vm.$id(),"items":_vm.userOptions,"label":_vm.$t('Benutzer'),"outlined":"","rules":_vm.notEmpty()},model:{value:(form.userId),callback:function ($$v) {_vm.$set(form, "userId", $$v)},expression:"form.userId"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }