
import OpportunitiesDataTableRow from "./OpportunitiesDataTableRow.vue";
import { Forbidden } from "@/api/errors";
import {
    isOpportunityUpdatedNotification,
    isOpportunityVisibilityNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import {
    opportunitiesApi,
    OpportunitiesSort,
    OpportunityResult,
    OpportunityUser,
    OpportunityVisibilityArea,
} from "@/api/opportunities";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { groupByTimeInterval, TimeIntervalGroupedItems } from "@/app/timeIntervalUtils";
import { now } from "@/store/now";
import { ActionLimiter } from "@/util/debounce";
import { PickMutable } from "@/util/types";
import Vue from "vue";

interface OpportunityRow {
    opportunityResult: OpportunityResult | null;
    readonly opportunityUser: OpportunityUser;
    loading: boolean;
}

export default Vue.extend({
    props: {
        itemsPerPage: {
            type: Number,
            default: 100,
        },
        grouped: {
            type: Boolean,
            default: false,
        },
        opportunitiesSort: {
            type: String as () => OpportunitiesSort,
            required: true,
        },
        opportunityVisibilityArea: {
            type: String as () => OpportunityVisibilityArea,
            required: true,
        },
    },

    data() {
        return {
            caseLoadLimiterById: new Map<string, ActionLimiter>(),
            items: [] as OpportunityRow[],
            loading: true,
            loadItemsLimiter: new ActionLimiter(true),
            notificationHandler: null as ((n: Notification) => void) | null,
            opportunityLoadLimiterById: new Map<string, ActionLimiter>(),
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
        };
    },

    computed: {
        groupedItems(): TimeIntervalGroupedItems<OpportunityRow>[] {
            if (!this.grouped) {
                return [
                    {
                        timeIntervalResult: null,
                        items: this.items,
                    },
                ];
            }

            return groupByTimeInterval(this.items, this.getOpportunitySortDate, now());
        },

        headers(): DataTableHeader[] {
            return [{ width: "1%" }, { width: "30%" }, { width: "34%" }, { width: "35%" }];
        },
    },

    methods: {
        getOpportunitySortDate(row: OpportunityRow): Date | null {
            if (this.opportunitiesSort === OpportunitiesSort.POSTPONED_UNTIL_ASC) {
                return row.opportunityResult?.opportunity.postponedUntil || null;
            } else if (this.opportunitiesSort === OpportunitiesSort.POSTPONED_UNTIL_DESC) {
                return row.opportunityResult?.opportunity.postponedUntil || null;
            } else {
                return null;
            }
        },

        async loadItems(silent: boolean) {
            if (!silent) {
                this.paging.totalSize = 0;
                this.items = [];
                this.loading = true;
            }

            await this.loadItemsLimiter.execute(async () => {
                try {
                    const myOpportunitiesResult = await opportunitiesApi.getMyOpportunities(
                        this.opportunityVisibilityArea,
                        this.opportunitiesSort,
                        (this.paging.page - 1) * this.paging.pageSize,
                        this.paging.pageSize,
                        ++this.searchCounter
                    );

                    const opportunities = await opportunitiesApi.getByIds(
                        myOpportunitiesResult.opportunityUsers.map((u) => u.opportunityId)
                    );
                    const opportunityRows = myOpportunitiesResult.opportunityUsers.map((u) => ({
                        opportunityUser: u,
                        loading: false,
                        opportunityResult: opportunities.find((v) => v.opportunity.id === u.opportunityId) || null,
                    }));

                    if (myOpportunitiesResult.searchId === this.searchCounter) {
                        this.items = opportunityRows;
                        this.paging.totalSize = myOpportunitiesResult.totalSize;
                        this.loading = false;
                    }
                } catch (e) {
                    this.loading = false;
                    this.paging.page = 1;
                    throw e;
                }
            });
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems(false);
        },
    },

    watch: {
        async opportunitiesSort() {
            this.paging.page = 1;
            try {
                await this.loadItems(false);
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        async opportunityVisibilityArea() {
            this.paging.page = 1;
            try {
                await this.loadItems(false);
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isOpportunityUpdatedNotification(n)) {
                const opportunityRow = this.items.find((c) => c.opportunityResult?.opportunity.id === n.opportunityId);
                const opportunityId = opportunityRow?.opportunityResult?.opportunity.id;

                if (!opportunityRow || !opportunityId) {
                    return;
                }

                if (!this.opportunityLoadLimiterById.has(opportunityId)) {
                    this.opportunityLoadLimiterById.set(opportunityId, new ActionLimiter(true));
                }

                await this.opportunityLoadLimiterById.get(opportunityId)!.execute(async () => {
                    opportunityRow.loading = true;
                    try {
                        opportunityRow.opportunityResult = await opportunitiesApi.getById(n.opportunityId);
                    } catch (e) {
                        if (!(e instanceof Forbidden)) {
                            throw e;
                        }

                        opportunityRow.opportunityResult = null;
                    } finally {
                        opportunityRow.loading = false;
                    }
                });
            } else if (isOpportunityVisibilityNotification(n)) {
                await this.loadItems(true);
            }
        });

        await this.loadItems(false);
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        DataTable,
        OpportunitiesDataTableRow,
    },
});
