
import { Node, getNodes } from "./enhancedText";
import Vue from "vue";

export default Vue.extend({
    props: {
        content: {
            type: String,
            required: true,
        },
    },

    computed: {
        nodes(): Node[] {
            return getNodes(this.content);
        },
    },
});
