
import { BadRequest } from "@/api/errors";
import { ipRestrictionsApi } from "@/api/ipRestrictions";
import { notEmpty, ValidationHelper } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            valid: true,
            isWorking: false,
            validationHelper,
            range: "",
            rangeRules: notEmpty()
                .maxLength(19)
                .and(validationHelper, "range"),
        };
    },

    methods: {
        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }
            this.isWorking = true;
            try {
                await ipRestrictionsApi.addAddressRange(this.range);
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, this.$refs.form);
                return;
            } finally {
                this.isWorking = false;
            }
            this.$emit("success");
        },

        close() {
            this.$emit("close");
        },
    },
});
