import { OpportunitySearchOrder, OpportunitySearchRequest, SentimentType } from "@/api/opportunitySearch";

export const EMPTY_SEARCH_REQUEST: OpportunitySearchRequest = {
    opportunityIds: [],
    excludedOpportunityIds: [],
    dealerIds: [],
    sourceIds: [],
    status: [],
    channels: [],
    sentimentTypes: [],
    urgencies: [],
    priority: [],
    creatorActorType: [],
    createdBy: [],
    closerActorType: [],
    closedBy: [],
    outcome: null,
    outcomeReasonIds: [],
    assignees: [],
    contactIds: [],
    createdFrom: null,
    createdTo: null,
    closedFrom: null,
    closedTo: null,
    lastActivityFrom: null,
    lastActivityTo: null,
    individualReceivers: [],
    opportunityTeamReceivers: [],
    hasReceivers: null,
    inventoryVehicleIds: [],
    makes: [],
    models: [],
    usageTypes: [],
    externalReference: null,
    search: null,
    sortBy: OpportunitySearchOrder.CREATED_DESC,
};

export function getSentimentType(sentiment: number | null): SentimentType {
    if (sentiment === null) {
        return SentimentType.UNKNOWN;
    } else if (sentiment > 0) {
        return SentimentType.POSITIVE;
    } else if (sentiment === 0) {
        return SentimentType.NEUTRAL;
    } else {
        return SentimentType.NEGATIVE;
    }
}
