
import CaseDataCheckCard from "./CaseDataCheckCard.vue";
import { caseAssistantState } from "./caseAssistant";
import CaseAdditionalDataCard from "./singlepage/CaseAdditionalDataCard.vue";
import CaseContactCard from "./singlepage/CaseContactCard.vue";
import CaseDataCard from "./singlepage/CaseDataCard.vue";
import CaseEscalationCard from "./singlepage/CaseEscalationCard.vue";
import CaseLocationCard from "./singlepage/CaseLocationCard.vue";
import CasePreferredContactPicker from "./singlepage/CasePreferredContactPicker.vue";
import { CaseChannel, CaseCreationForm, casesApi } from "@/api/cases";
import { ContactType } from "@/api/contacts";
import { DealerInstructionCategory, IssueType } from "@/api/dealerInstructions";
import { VehicleClass } from "@/api/makeModels";
import { processQueryParameters } from "@/app/issueAssistantUtils";
import { showConfirm, showError } from "@/app/messageUtil";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import FilePickerCard from "@/app/pages/FilePickerCard.vue";
import EscalationGroupPickerCard from "@/app/pages/cases/EscalationGroupPickerCard.vue";
import DealerInstructions from "@/app/pages/dealerinstructions/DealerInstructions.vue";
import UserPickerCard from "@/app/pages/users/UserPickerCard.vue";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            IssueType,
            adding: false,
            caseAssistantState,
            progress: 0,
        };
    },

    computed: {
        contactType(): ContactType | null {
            return this.caseAssistantState.contactData?.type ?? null;
        },

        contactVehicleClass(): VehicleClass | null {
            return this.caseAssistantState.contactVehicleData?.vehicleClass ?? null;
        },

        contactVehicleMake(): string | null {
            return this.caseAssistantState.contactVehicleData?.make ?? null;
        },

        dealerId() {
            return userSession.dealerId;
        },

        dealerInstructionCategories(): DealerInstructionCategory[] {
            if (this.caseAssistantState.contactAndVehicleId) {
                return [DealerInstructionCategory.DATA_ACQUISITION, DealerInstructionCategory.RECEIVER_SELECTION];
            }

            return [
                DealerInstructionCategory.CONTACT_SEARCH,
                DealerInstructionCategory.DATA_ACQUISITION,
                DealerInstructionCategory.RECEIVER_SELECTION,
            ];
        },

        isAgent(): boolean {
            return userSession.isAgent();
        },

        phoneticSpellingLocale(): string {
            return configStore.configuration.defaultLocale;
        },
    },

    methods: {
        async reset() {
            if (
                await showConfirm(
                    this.$t("Eingaben zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?") as string
                )
            ) {
                caseAssistantState.reset(null, null);
            }
        },

        async addCase() {
            if (!(this.$refs.form as any).validate()) {
                showError(this.$t("Bitte korrigieren Sie Ihre fehlerhaften Eingaben.") as string);
                return;
            }

            if (!this.caseAssistantState.contactAndVehicleId?.contactId && userSession.isCtUser()) {
                showError(this.$t("Es ist kein Kontakt ausgewählt!") as string);
                return;
            }

            if (
                !caseAssistantState.escalationGroupReceivers.length &&
                !caseAssistantState.individualReceivers.userIds.length
            ) {
                showError(this.$t("Es ist kein Empfänger ausgewählt!") as string);
                return;
            }

            if (
                !caseAssistantState.contactAndVehicleId?.contactId &&
                !(await showConfirm(
                    this.$t("Es ist kein Kontakt ausgewählt!") as string,
                    this.$t("Sind Sie sicher, dass Sie ohne Kontakt fortfahren möchten?") as string
                ))
            ) {
                return;
            }

            if (
                caseAssistantState.contactAndVehicleId?.contactId &&
                !caseAssistantState.contactAndVehicleId?.contactVehicleId &&
                !(await showConfirm(
                    this.$t("Es ist kein Fahrzeug ausgewählt!") as string,
                    this.$t("Sind Sie sicher, dass Sie ohne Fahrzeug fortfahren möchten?") as string
                ))
            ) {
                return;
            }

            const assignToSelf =
                !caseAssistantState.escalationGroupReceivers.length &&
                caseAssistantState.individualReceivers.userIds.length === 1 &&
                caseAssistantState.individualReceivers.userIds[0] === userSession.userId &&
                (await showConfirm(
                    this.$t("Sie sind der einzige Empfänger!") as string,
                    this.$t("Möchten Sie sich den Fall direkt zuteilen?") as string,
                    this.$t("Ja") as string,
                    this.$t("Nein") as string
                ));

            const caseCreationForm: CaseCreationForm = {
                dealerId: caseAssistantState.dealerId!,
                channel: caseAssistantState.channel!,
                caseType: caseAssistantState.caseType!,
                sentiment: caseAssistantState.sentiment,
                urgency: caseAssistantState.urgency,
                appointmentTime: caseAssistantState.appointmentTime,
                subject: caseAssistantState.subject,
                individualReceivers: caseAssistantState.individualReceivers.userIds,
                escalationGroupReceivers: caseAssistantState.escalationGroupReceivers,
                contactId: caseAssistantState.contactAndVehicleId?.contactId ?? null,
                contactVehicleId: caseAssistantState.contactAndVehicleId?.contactVehicleId ?? null,
                escalationBegin: caseAssistantState.escalationBegin,
                preferredContactMethod: caseAssistantState.preferredContact.preferredContactMethod,
                preferredContactDetails: caseAssistantState.preferredContact.preferredContactDetails,
                earliestContactTime: caseAssistantState.preferredContact.earliestContactTime,
                latestContactTime: caseAssistantState.preferredContact.latestContactTime,
                externalReferences: [],
            };

            this.adding = true;
            try {
                const caseId = await casesApi.addCase(
                    caseCreationForm,
                    caseAssistantState.initialNoteContent!,
                    assignToSelf ? [userSession.userId!] : [],
                    caseAssistantState.files,
                    ({ total, loaded }) => (this.progress = (100 * loaded) / total)
                );

                caseAssistantState.reset(null, null);
                await this.$router.push(`/case/${caseId}`);
            } finally {
                this.adding = false;
            }
        },

        setPreferredContactTimeZoneIfNull() {
            if (!caseAssistantState.preferredContactTimeZone && this.dealerId) {
                caseAssistantState.preferredContactTimeZone = dealersStore.dealerById(this.dealerId)?.timeZone ?? null;
            }
        },

        setEscalationBeginTimeZoneIfNull() {
            if (!caseAssistantState.escalationBeginTimeZone && this.dealerId) {
                caseAssistantState.escalationBeginTimeZone = dealersStore.dealerById(this.dealerId)?.timeZone ?? null;
            }
        },
    },

    watch: {
        dealerId(newDealerId: string) {
            this.caseAssistantState.dealerId = newDealerId;
            this.setPreferredContactTimeZoneIfNull();
            this.setEscalationBeginTimeZoneIfNull();
        },
    },

    created() {
        if (!caseAssistantState.dealerId && this.dealerId) {
            caseAssistantState.dealerId = this.dealerId;
        }
        if (caseAssistantState.initialNoteContent === null) {
            caseAssistantState.resetActivityContent();
        }
        if (caseAssistantState.channel === null) {
            caseAssistantState.channel = userSession.isCtUser() ? CaseChannel.PHONE_BDC : CaseChannel.PHONE_INTERNAL;
        }

        this.setPreferredContactTimeZoneIfNull();
        this.setEscalationBeginTimeZoneIfNull();
    },

    beforeRouteEnter(to, _, next) {
        processQueryParameters(to, next);
    },

    components: {
        CaseAdditionalDataCard,
        CaseContactCard,
        CaseDataCard,
        CaseDataCheckCard,
        CaseEscalationCard,
        CaseLocationCard,
        CasePreferredContactPicker,
        DealerContextWarning,
        DealerInstructions,
        EscalationGroupPickerCard,
        FilePickerCard,
        UserPickerCard,
    },
});
