import {
    isOfficeHoursChangedNotification,
    isOfficeHoursDeletedNotification,
    isOfficeHoursOrderChangedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { OfficeHours, officeHoursApi } from "@/api/officeHours";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { removeMatchingItems, reorderItems, updateItem } from "@/util/storeUtils";

@reactive
class OfficeHoursStore {
    private officeHours_: OfficeHours[] = [];
    private refreshLimiter = new ActionLimiter(true);

    getOfficeHoursByDealerId(dealerId: string): OfficeHours[] {
        return this.officeHours_.filter((o) => o.dealerId === dealerId);
    }

    getOfficeHoursById(officeHoursId: string): OfficeHours | null {
        return this.officeHours_.find((o) => o.id === officeHoursId) ?? null;
    }

    get officeHours(): OfficeHours[] {
        return [...this.officeHours_];
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.officeHours_ = await officeHoursApi.getAll();
        });
    }

    handleNotification(n: Notification) {
        if (isOfficeHoursChangedNotification(n)) {
            updateItem(this.officeHours_, n.officeHours, (o) => o.id);
        } else if (isOfficeHoursDeletedNotification(n)) {
            removeMatchingItems(this.officeHours_, (o) => o.id === n.officeHoursId);
        } else if (isOfficeHoursOrderChangedNotification(n)) {
            reorderItems(this.officeHours_, n.orderedOfficeHoursIds, (o) => o.id);
        }
    }
}

export const officeHoursStore = new OfficeHoursStore();

notificationEventSource.addDataHandler((n) => officeHoursStore.handleNotification(n));
