
import Vue from "vue";

function openPopupWindow(url: string, width: number, height: number) {
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;
    return window.open(url, undefined, `popup=yes, width=${width}, height=${height}, top=${top}, left=${left}`)!;
}

export default Vue.extend({
    data() {
        return {
            popup: null as Window | null,
            closeTimer: null as number | null,
        };
    },

    methods: {
        onMessage(event: MessageEvent) {
            this.$emit(event.data.type, event.data.value);
        },
    },

    mounted() {
        this.popup = openPopupWindow("/api/whatsapp-accounts/_onboarding", 600, 900);

        this.closeTimer = setInterval(() => {
            if (this.popup!.closed) {
                clearInterval(this.closeTimer!);
                this.$emit("close");
            }
        }, 1000);

        addEventListener("message", this.onMessage);
    },

    beforeDestroy() {
        if (this.closeTimer !== null) {
            clearInterval(this.closeTimer);
        }

        if (this.popup) {
            this.popup.close();
        }

        removeEventListener("message", this.onMessage);
    },
});
