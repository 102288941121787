
import { BadRequest } from "@/api/errors";
import { NumberBlacklistEntry, NumberBlacklistEntryCreationForm, numbersBlacklistApi } from "@/api/numbersBlacklist";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { maxLength, notEmpty, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { Mutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        numberBlacklistEntry: {
            type: Object as () => NumberBlacklistEntry | null,
            default: null,
        },
    },

    data() {
        const validationHelper = new ValidationHelper();

        return {
            form: {
                number: this.numberBlacklistEntry?.number ?? "",
                blockCalls: this.numberBlacklistEntry?.blockCalls ?? false,
                blockTextMessages: this.numberBlacklistEntry?.blockTextMessages ?? false,
                comment: this.numberBlacklistEntry?.comment ?? null,
            } as Mutable<NumberBlacklistEntryCreationForm>,
            maxLength,
            saving: false,
            numberRules: notEmpty()
                .e164()
                .and(validationHelper, "number"),
            validationHelper,
        };
    },

    computed: {
        defaultCountry(): string {
            return configStore.configuration.defaultCountry;
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        async submit() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;

            try {
                if (this.numberBlacklistEntry) {
                    await numbersBlacklistApi.edit(this.numberBlacklistEntry.number, this.form);
                } else {
                    await numbersBlacklistApi.add(this.form);
                }

                this.$emit("success");
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }

                this.validationHelper.update(e, this.$refs.form);
            } finally {
                this.saving = false;
            }
        },
    },

    components: {
        PhoneNumberField,
    },
});
