
import AnnouncementFormDialog from "./AnnouncementFormDialog.vue";
import { Announcement, AnnouncementUser } from "@/api/announcements";
import IntersectWrapper from "@/app/components/IntersectWrapper.vue";
import EscalationGroupLink from "@/app/pages/EscalationGroupLink.vue";
import OpportunityTeamLink from "@/app/pages/OpportunityTeamLink.vue";
import UserLink from "@/app/pages/UserLink.vue";
import { getSortedByUserFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        announcement: {
            type: Object as () => Announcement,
            required: true,
        },
    },

    data() {
        return {
            formDialogVisible: false,
            maxAnnouncementUsersVisibleCount: 10,
            showAllAnnouncementUsers: false,
        };
    },

    computed: {
        announcementUsers(): AnnouncementUser[] {
            return getSortedByUserFullName(this.announcement.announcementUsers, (au) => au.userId).sort(
                (a, b) => (!!a.read ? 1 : 0) - (!!b.read ? 1 : 0) || (!!a.confirmed ? 1 : 0) - (!!b.confirmed ? 1 : 0)
            );
        },
    },

    methods: {
        formatDate(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "L");
        },

        getDealerNameById(dealerId: string): string | null {
            return dealersStore.dealerById(dealerId)?.name ?? null;
        },

        async saved() {
            this.formDialogVisible = false;
            this.$emit("saved");
        },
    },

    components: {
        AnnouncementFormDialog,
        EscalationGroupLink,
        IntersectWrapper,
        OpportunityTeamLink,
        UserLink,
    },
});
