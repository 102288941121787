import {
    ContactNumber,
    ContactSalutation,
    ContactStatus,
    ContactType,
    ContactVehicleRecordForm,
    EmailAddress,
    MileageUnit,
} from "./contacts";
import { VehicleClass } from "./makeModels";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum ContactParserCharset {
    UTF_8 = "UTF_8",
    ISO_8859_1 = "ISO_8859_1",
}

export enum ContactParserFormat {
    GENERIC = "GENERIC",
    ALPHA_PLUS = "ALPHA_PLUS",
    AMIS = "AMIS",
    APAS = "APAS",
    AUDARIS = "AUDARIS",
    AUTOSYS = "AUTOSYS",
    AUTOSYS2 = "AUTOSYS2",
    AUTRADO = "AUTRADO",
    BUERO_PLUS = "BUERO_PLUS",
    BUNGERTP2 = "BUNGERTP2",
    CARACAMP = "CARACAMP",
    CARDIS = "CARDIS",
    CARE = "CARE",
    CARIT = "CARIT",
    CAVIO = "CAVIO",
    CDK = "CDK",
    CDK_2 = "CDK_2",
    CIDCAR = "CIDCAR",
    CROSS = "CROSS",
    CSB = "CSB",
    CUSTOMER_ONE = "CUSTOMER_ONE",
    DRACAR = "DRACAR",
    ECAROS = "ECAROS",
    EVA = "EVA",
    FILAKSPLUS = "FILAKSPLUS",
    FORMEL1 = "FORMEL1",
    HUBSPOT = "HUBSPOT",
    INCADEA = "INCADEA",
    INGOSOFT = "INGOSOFT",
    KAR2 = "KAR2",
    KSRVIS = "KSRVIS",
    LOCOSOFT = "LOCOSOFT",
    MACS = "MACS",
    PROF4NET_CATCH = "PROF4NET_CATCH",
    SALES_ASSIST = "SALES_ASSIST",
    SALES_ASSIST_2 = "SALES_ASSIST_2",
    SAMSURION = "SAMSURION",
    SAP_PRO = "SAP_PRO",
    SYSCARA = "SYSCARA",
    TELREP = "TELREP",
    VAUDIS_CLASSIC = "VAUDIS_CLASSIC",
    VAUDIS_PRO = "VAUDIS_PRO",
    VAUDIS_X = "VAUDIS_X",
    VECTORCDP = "VECTORCDP",
    VERENDUS = "VERENDUS",
    WEBCARE = "WEBCARE",
    WEBMOBIL24 = "WEBMOBIL24",
    WERWISO = "WERWISO",
    ZAENCAR = "ZAENCAR",
    UNKNOWN = "UNKNOWN",
}

export enum ContactParserSeparator {
    COMMA = "COMMA",
    PIPE = "PIPE",
    SEMICOLON = "SEMICOLON",
    TAB = "TAB",
}

export enum ContactUploadType {
    EXTERNAL_API = "EXTERNAL_API",
    FTP = "FTP",
    UI = "UI",
}

export interface ContactUpload {
    readonly id: string;
    readonly created: Date;
    readonly contactUploadSourceId: string;
    readonly type: ContactUploadType;
    readonly filename: string;
    readonly fileModified: Date | null;
    readonly dataHash: string;
    readonly size: number;
    readonly applied: Date | null;
    readonly total: number | null;
    readonly inserts: number | null;
    readonly updates: number | null;
    readonly deletes: number | null;
}

export interface ContactUploadParseResult {
    readonly total: number;
    readonly inserts: number;
    readonly updates: number;
    readonly deletes: number;
    readonly contacts: ExternalContactParsed[];
}

export interface ContactUploadSource {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly name: string;
    readonly dealerId: string | null;
    readonly deleteContactIfExternalContactIsMissing: boolean;
    readonly defaultCountry: string;
    readonly format: ContactParserFormat;
    readonly charset: ContactParserCharset | null;
    readonly separator: ContactParserSeparator | null;
    readonly importFromFtp: boolean;
    readonly ftpHost: string | null;
    readonly ftpUsername: string | null;
    readonly ftpPassword: string | null;
    readonly ftpFileLocation: string | null;
    readonly ftpFileBasename: string | null;
    readonly ftpLastImportTry: Date | null;
    readonly latestContactUpload: ContactUpload | null;
}

export interface ContactUploadSourceForm {
    readonly name: string;
    readonly dealerId: string | null;
    readonly deleteContactIfExternalContactIsMissing: boolean;
    readonly defaultCountry: string;
    readonly format: ContactParserFormat;
    readonly charset: ContactParserCharset | null;
    readonly separator: ContactParserSeparator | null;
    readonly importFromFtp: boolean;
    readonly ftpHost: string | null;
    readonly ftpUsername: string | null;
    readonly ftpPassword: string | null;
    readonly ftpFileLocation: string | null;
    readonly ftpFileBasename: string | null;
}

export interface ExternalContactPayload {
    readonly status: ContactStatus | null;
    readonly type: ContactType | null;
    readonly salutation: ContactSalutation | null;
    readonly knownSince: string | null;
    readonly dateOfBirth: string | null;
    readonly companyName: string | null;
    readonly position: string | null;
    readonly department: string | null;
    readonly namePrefix: string | null;
    readonly givenName: string | null;
    readonly middleName: string | null;
    readonly familyName: string | null;
    readonly nameSuffix: string | null;
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
    readonly numbers: ContactNumber[];
    readonly emailAddresses: EmailAddress[];
    readonly website: string | null;
    readonly notes: string | null;
    readonly url: string | null;
    readonly vehicles: ExternalContactVehiclePayload[];
    readonly externalReferences: string[];
}

export interface ExternalContactParsed {
    readonly externalContactId: string;
    readonly payload: ExternalContactPayload;
}

export interface ExternalContactVehiclePayload {
    readonly vin: string | null;
    readonly licensePlate: string | null;
    readonly vehicleClass: VehicleClass | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly modelYear: string | null;
    readonly firstRegistration: string | null;
    readonly nextGeneralInspection: string | null;
    readonly nextInspection: string | null;
    readonly nextInspectionMileage: number | null;
    readonly nextInspectionMileageUnit: MileageUnit | null;
    readonly warrantyUntil: string | null;
    readonly warrantyUntilMileage: number | null;
    readonly warrantyUntilMileageUnit: MileageUnit | null;
    readonly warrantyPaintingUntil: string | null;
    readonly warrantyRustUntil: string | null;
    readonly records: ContactVehicleRecordForm[];
    readonly notes: string | null;
    readonly url: string | null;
    readonly externalReference: string | null;
}

interface ContactUploadsApi {
    add(contactUploadSourceForm: ContactUploadSourceForm): Promise<void>;
    apply(contactUploadId: string): Promise<void>;
    delete(id: string): Promise<void>;
    edit(id: string, contactUploadSourceForm: ContactUploadSourceForm): Promise<void>;
    generateDownloadLink(contactUploadId: string): string;
    getAll(): Promise<ContactUploadSource[]>;
    importFromFtp(id: string): Promise<string | null>;
    parse(contactUploadId: string, limit: number): Promise<ContactUploadParseResult>;
    upload(id: string, file: File, progressCallback: (progressEvent: ProgressEvent) => void): Promise<void>;
}

export const contactUploadsApi: ContactUploadsApi = {
    async add(contactUploadSourceForm) {
        await axios.post("/api/contact-uploads", contactUploadSourceForm);
    },

    async apply(contactUploadId) {
        await axios.post("/api/contact-uploads/_apply", undefined, {
            params: {
                contactUploadId,
            },
        });
    },

    async delete(id) {
        await axios.delete(`/api/contact-uploads/${id}`);
    },

    async edit(id, contactUploadSourceForm) {
        await axios.put(`/api/contact-uploads/${id}`, contactUploadSourceForm);
    },

    generateDownloadLink(contactUploadId) {
        return `/api/contact-uploads/_download?contactUploadId=${contactUploadId}`;
    },

    async getAll() {
        return cloneObject((await axios.get("/api/contact-uploads")).data);
    },

    async importFromFtp(id) {
        return (await axios.post(`/api/contact-uploads/${id}/_import-from-ftp`)).data;
    },

    async parse(contactUploadId, limit) {
        return cloneObject(
            (
                await axios.post("/api/contact-uploads/_parse", undefined, {
                    params: {
                        contactUploadId,
                        limit,
                    },
                })
            ).data
        );
    },

    async upload(id, file, onUploadProgress) {
        const formData = new FormData();
        formData.append("file", file);
        await axios.post(`/api/contact-uploads/${id}/_upload`, formData, { onUploadProgress });
    },
};
