
import { OutgoingSmsFormCardState } from "./OutgoingSmsFormCard";
import OutgoingSmsFormCard from "./OutgoingSmsFormCard.vue";
import { Contact, ContactData } from "@/api/contacts";
import { EditModeMixin } from "@/app/editModeUtils";
import { UrlWithKey } from "@/app/placeholderUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        defaultCountry: {
            type: String,
            required: true,
        },
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        issueId: {
            type: String as () => string | null,
            default: null,
        },
        working: {
            type: Boolean,
            required: true,
        },
        receiver: {
            type: String,
            required: false,
        },
        toNumbers: {
            type: Array as () => string[],
            default: () => [],
        },
        urls: {
            type: Array as () => UrlWithKey[],
            default: () => [],
        },
        onSubmit: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            outgoingSmsFormCardState: new OutgoingSmsFormCardState(),
        };
    },

    computed: {
        contactData(): ContactData | null {
            if (this.contact?.deleted) {
                return null;
            }

            return this.contact?.contactData || null;
        },
    },

    methods: {
        submit() {
            (this.$refs.outgoingSmsFormCard as any).submit();
        },
    },

    mixins: [EditModeMixin],

    components: {
        OutgoingSmsFormCard,
    },
});
