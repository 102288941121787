
import EmailTemplateFormPage from "./EmailTemplateFormPage.vue";
import { EmailTemplate, EmailTemplateAttachment, emailTemplatesApi } from "@/api/emailTemplates";
import DataTable from "@/app/components/DataTable.vue";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { showConfirm } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import Sortable from "sortablejs";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
    data() {
        return {
            loadingTemplates: true,
            emailTemplates: [] as EmailTemplate[],
            loadingFragments: true,
            emailFragments: [] as EmailTemplate[],
            formItem: null as EmailTemplate | null,
            formVisible: false,
            formFragment: false,
            templatesSortableInitialized: false,
            fragmentsSortableInitialized: false,
            previewFile: null as string | null,
        };
    },

    computed: {
        templateHeaders() {
            return [
                {
                    text: "",
                    sortable: false,
                    width: "1%",
                },
                {
                    text: this.$t("Name"),
                    sortable: false,
                    width: "18%",
                },
                {
                    text: this.$t("Sprache/Region"),
                    sortable: false,
                    width: "20%",
                },
                {
                    text: this.$t("Inhalt"),
                    sortable: false,
                    width: "40%",
                },
                {
                    text: this.$t("Anhänge"),
                    sortable: false,
                    width: "20%",
                },
                {
                    text: this.$t("Aktionen"),
                    sortable: false,
                    width: "1%",
                },
            ] as DataTableHeader[];
        },

        fragmentHeaders() {
            return [
                {
                    text: "",
                    sortable: false,
                    width: "1%",
                },
                {
                    text: this.$t("Name"),
                    sortable: false,
                    width: "18%",
                },
                {
                    text: this.$t("Sprache/Region"),
                    sortable: false,
                    width: "20%",
                },
                {
                    text: this.$t("Inhalt"),
                    sortable: false,
                    width: "60%",
                },
                {
                    text: this.$t("Aktionen"),
                    sortable: false,
                    width: "1%",
                },
            ] as DataTableHeader[];
        },
    },

    watch: {
        emailTemplates() {
            const templateitems = document.getElementById("templateitems")?.firstChild?.firstChild?.childNodes?.item(3);
            if (!this.templatesSortableInitialized && this.emailTemplates.length && templateitems) {
                Sortable.create(templateitems as HTMLElement, {
                    animation: 150,
                    handle: ".drag-handle",
                    ghostClass: "accent",
                    onEnd: async ({ oldIndex, newIndex }) => {
                        if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                            this.loadingTemplates = true;
                            try {
                                const item = this.emailTemplates.splice(oldIndex, 1)[0];
                                this.emailTemplates.splice(newIndex, 0, item);
                                await emailTemplatesApi.editOrder(this.emailTemplates.map((d) => d.id));
                            } finally {
                                this.loadingTemplates = false;
                            }
                        }
                    },
                });
                this.templatesSortableInitialized = true;
            }
        },

        emailFragments() {
            const fragmentitems = document.getElementById("fragmentitems")?.firstChild?.firstChild?.childNodes?.item(3);
            if (!this.fragmentsSortableInitialized && this.emailFragments.length && fragmentitems) {
                Sortable.create(fragmentitems as HTMLElement, {
                    animation: 150,
                    handle: ".drag-handle",
                    ghostClass: "accent",
                    onEnd: async ({ oldIndex, newIndex }) => {
                        if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                            this.loadingFragments = true;
                            try {
                                const item = this.emailFragments.splice(oldIndex, 1)[0];
                                this.emailFragments.splice(newIndex, 0, item);
                                await emailTemplatesApi.editOrder(this.emailFragments.map((d) => d.id));
                            } finally {
                                this.loadingFragments = false;
                            }
                        }
                    },
                });
                this.fragmentsSortableInitialized = true;
            }
        },
    },

    methods: {
        async deleteItem(emailTemplate: EmailTemplate, fragment: boolean) {
            if (
                await showConfirm(
                    this.$t("Bestätigung") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie die E-Mail-Vorlage bzw. den E-Mail-Textbaustein löschen möchten?"
                    ) as string
                )
            ) {
                await emailTemplatesApi.delete(emailTemplate.id);
                if (fragment) {
                    await this.loadFragments();
                } else {
                    await this.loadTemplates();
                }
            }
        },

        async savedItem() {
            this.formVisible = false;
            if (this.formFragment) {
                await this.loadFragments();
            } else {
                await this.loadTemplates();
            }
        },

        async loadTemplates() {
            this.loadingTemplates = true;
            try {
                this.emailTemplates = await emailTemplatesApi.list(false);
            } finally {
                this.loadingTemplates = false;
            }
        },

        async loadFragments() {
            this.loadingFragments = true;
            try {
                this.emailFragments = await emailTemplatesApi.list(true);
            } finally {
                this.loadingFragments = false;
            }
        },

        showForm(formItem: EmailTemplate | null, fragment: boolean) {
            this.formItem = formItem;
            this.formVisible = true;
            this.formFragment = fragment;
        },

        showAttachment(attachment: EmailTemplateAttachment, download: boolean) {
            const fname = attachment.filename.toLowerCase();
            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.previewFile = emailTemplatesApi.generateAttachmentLink(attachment);
            } else {
                window.open(emailTemplatesApi.generateAttachmentLink(attachment, download));
            }
        },
    },

    async mounted() {
        await this.loadTemplates();
        await this.loadFragments();
    },

    components: {
        DealerGlobalContextHint,
        EmailTemplateFormPage,
        DataTable,
        ImageCarousel,
    },
});
