
import DateTimePicker from "@/app/components/DateTimePicker.vue";
import ExpansionPanel from "@/app/components/ExpansionPanel.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import { now } from "@/store/now";
import { getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            now: now(),
        };
    },

    computed: {
        currentDate(): string | null {
            return this.caseAssistantState.escalationBeginTimeZone
                ? getDate(this.now, this.caseAssistantState.escalationBeginTimeZone)
                : null;
        },
    },

    components: {
        DateTimePicker,
        ExpansionPanel,
        TimeZonePicker,
    },
});
