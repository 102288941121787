
import EscalationGroupBottomSheet from "./EscalationGroupBottomSheet.vue";
import { EscalationGroupSummary } from "@/api/escalationGroups";
import { escalationGroupsStore } from "@/store/escalationGroups";
import Vue from "vue";

export default Vue.extend({
    props: {
        escalationGroupId: {
            type: String,
            required: true,
        },
        inheritColor: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            bottomSheetVisible: false,
        };
    },

    computed: {
        escalationGroup(): EscalationGroupSummary | null {
            return escalationGroupsStore.escalationGroupById(this.escalationGroupId);
        },
    },

    methods: {
        showBottomSheet() {
            this.bottomSheetVisible = true;
        },
    },

    components: {
        EscalationGroupBottomSheet,
    },
});
