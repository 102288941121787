var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('enum-field',{attrs:{"dense":"","disabled":_vm.disabled,"hide-details":"auto","label":_vm.$t('Kontaktmethode'),"text":(k) => _vm.$t(`enum.ContactMethod.${k}`),"values":_vm.ContactMethod},model:{value:(_vm.preferredContact.preferredContactMethod),callback:function ($$v) {_vm.$set(_vm.preferredContact, "preferredContactMethod", $$v)},expression:"preferredContact.preferredContactMethod"}})],1),(_vm.preferredContact.preferredContactMethod)?_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","clearable":"","dense":"","disabled":_vm.disabled,"hide-details":"auto","id":_vm.$id(),"items":_vm.contactDetailsOptions,"label":_vm.contactDetailsLabel},model:{value:(_vm.preferredContact.preferredContactDetails),callback:function ($$v) {_vm.$set(_vm.preferredContact, "preferredContactDetails", $$v)},expression:"preferredContact.preferredContactDetails"}})],1):_vm._e(),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","dense":"","disabled":_vm.disabled,"hide-details":"auto","items":[
                { value: true, text: _vm.$t('Flexibel') },
                { value: false, text: _vm.$t('Zeitpunkt festlegen') },
            ],"label":_vm.$t('Frühester Kontaktzeitpunkt'),"value":_vm.earliestFlexible},on:{"input":_vm.setEarliestFlexibleAndContactTime}})],1),(!_vm.earliestFlexible)?_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('time-zone-picker',{attrs:{"dense":"","disabled":_vm.disabled,"hide-details":"auto","prepend-icon":"mdi-map-clock"},model:{value:(_vm.timeZoneProp),callback:function ($$v) {_vm.timeZoneProp=$$v},expression:"timeZoneProp"}})],1):_vm._e(),(!_vm.earliestFlexible)?_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('date-time-picker',{attrs:{"dense":"","disabled":_vm.disabled,"hide-details":"auto","label":_vm.$t('Frühestens'),"tz":_vm.timeZoneProp},model:{value:(_vm.preferredContact.earliestContactTime),callback:function ($$v) {_vm.$set(_vm.preferredContact, "earliestContactTime", $$v)},expression:"preferredContact.earliestContactTime"}})],1):_vm._e(),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","dense":"","hide-details":"auto","disabled":_vm.disabled,"items":[
                { value: true, text: _vm.$t('Flexibel') },
                { value: false, text: _vm.$t('Zeitpunkt festlegen') },
            ],"label":_vm.$t('Spätester Kontaktzeitpunkt'),"value":_vm.latestFlexible},on:{"input":_vm.setLatestFlexibleAndContactTime}})],1),(!_vm.latestFlexible)?_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('time-zone-picker',{attrs:{"dense":"","disabled":_vm.disabled,"hide-details":"auto","prepend-icon":"mdi-map-clock"},model:{value:(_vm.timeZoneProp),callback:function ($$v) {_vm.timeZoneProp=$$v},expression:"timeZoneProp"}})],1):_vm._e(),(!_vm.latestFlexible)?_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('date-time-picker',{attrs:{"dense":"","disabled":_vm.disabled,"hide-details":"auto","label":_vm.$t('Spätestens'),"tz":_vm.timeZoneProp},model:{value:(_vm.preferredContact.latestContactTime),callback:function ($$v) {_vm.$set(_vm.preferredContact, "latestContactTime", $$v)},expression:"preferredContact.latestContactTime"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }