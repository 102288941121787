import axios from "axios";

export enum EmergencyIncidentNotificationEmailFormat {
    REGULAR = "REGULAR",
    REGULAR_WITHOUT_CONTACT_DATA = "REGULAR_WITHOUT_CONTACT_DATA",
    REGULAR_WITHOUT_LINK = "REGULAR_WITHOUT_LINK",
}

export interface EmergencyIncidentNotificationSettings {
    readonly dealerId: string;
    readonly emergencyIncidentNotificationEmailFormat: EmergencyIncidentNotificationEmailFormat;
}

interface EmergencyIncidentNotificationSettingsApi {
    editEmergencyIncidentNotificationSettings(
        emergencyIncidentNotificationSettings: EmergencyIncidentNotificationSettings
    ): Promise<void>;
    getByDealer(dealerId: string): Promise<EmergencyIncidentNotificationSettings>;
}

export const emergencyIncidentNotificationSettingsApi: EmergencyIncidentNotificationSettingsApi = {
    async editEmergencyIncidentNotificationSettings(emergencyIncidentNotificationSettings) {
        await axios.put("/api/emergency-incident-notification-settings", emergencyIncidentNotificationSettings);
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/emergency-incident-notification-settings/${dealerId}`)).data;
    },
};
