
import { IncomingEmail, IncomingEmailAttachment } from "@/api/incomingEmails";
import { IncomingWhatsAppMessage } from "@/api/incomingWhatsApp";
import { opportunitiesApi, Opportunity, OpportunityAttachment } from "@/api/opportunities";
import { OutgoingEmail, OutgoingEmailAttachment } from "@/api/outgoingEmails";
import { OutgoingWhatsAppMessage } from "@/api/outgoingWhatsApp";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import FileDropZone from "@/app/components/FileDropZone.vue";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { fileDialog } from "@/app/fileUtils";
import { showConfirm } from "@/app/messageUtil";
import IncomingEmailAttachmentPanelRow from "@/app/pages/incomingemails/IncomingEmailAttachmentPanelRow.vue";
import IncomingWhatsAppMediaPanelRow from "@/app/pages/incomingwhatsapp/IncomingWhatsAppMediaPanelRow.vue";
import OutgoingEmailAttachmentPanelRow from "@/app/pages/outgoingemails/OutgoingEmailAttachmentPanelRow.vue";
import OutgoingWhatsAppMediaPanelRow from "@/app/pages/outgoingwhatsapp/OutgoingWhatsAppMediaPanelRow.vue";
import { configStore } from "@/store/config";
import Vue from "vue";

enum ActivityAttachmentType {
    EMAIL,
    WHATSAPP,
}

interface IncomingAttachment {
    readonly type: ActivityAttachmentType;
    readonly attachment?: IncomingEmailAttachment;
    readonly activity: IncomingEmail | IncomingWhatsAppMessage;
    readonly hash: string;
}

interface OutgoingAttachment {
    readonly type: ActivityAttachmentType;
    readonly attachment?: OutgoingEmailAttachment;
    readonly activity: OutgoingEmail | OutgoingWhatsAppMessage;
    readonly hash: string;
}

export default Vue.extend({
    props: {
        canDownload: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        incomingEmails: {
            type: Array as () => IncomingEmail[],
            required: true,
        },
        incomingWhatsAppMessages: {
            type: Array as () => IncomingWhatsAppMessage[],
            required: true,
        },
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
        outgoingEmails: {
            type: Array as () => OutgoingEmail[],
            required: true,
        },
        outgoingWhatsAppMessages: {
            type: Array as () => OutgoingWhatsAppMessage[],
            required: true,
        },
    },

    data() {
        return {
            ActivityAttachmentType,
            deleting: false,
            uploading: 0,
            successfullyDeleted: [] as number[],
            progress: 0,
            image: "",
        };
    },

    computed: {
        attachments(): OpportunityAttachment[] {
            return this.opportunityProp.attachments.filter((a) => this.successfullyDeleted.indexOf(a.id) < 0);
        },

        incomingAttachments(): IncomingAttachment[] {
            return [
                ...this.incomingEmails
                    .map((email) =>
                        email.attachments
                            .filter(
                                (attachment) =>
                                    !attachment.contentId ||
                                    !this.hiddenOutgoingEmbeddedContentHashes.includes(attachment.contentHash)
                            )
                            .map((attachment) => ({
                                type: ActivityAttachmentType.EMAIL,
                                attachment,
                                activity: email,
                                hash: attachment.contentHash,
                            }))
                    )
                    .reduce((prev, cur) => prev.concat(cur), []),
                ...this.incomingWhatsAppMessages
                    .filter((w) => w.mediaHash)
                    .map((whatsApp) => ({
                        type: ActivityAttachmentType.WHATSAPP,
                        activity: whatsApp,
                        hash: whatsApp.mediaHash,
                    })),
            ]
                .map((a) => a as IncomingAttachment)
                .filter((a, index, array) => array.findIndex((b) => a.hash === b.hash) === index)
                .sort((a, b) => b.activity.created.getTime() - a.activity.created.getTime());
        },

        outgoingAttachments(): OutgoingAttachment[] {
            return [
                ...this.outgoingEmails
                    .map((email) =>
                        email.attachments
                            .filter((attachment) => !attachment.contentId)
                            .map((attachment) => ({
                                type: ActivityAttachmentType.EMAIL,
                                attachment,
                                activity: email,
                                hash: attachment.contentHash,
                            }))
                    )
                    .reduce((prev, cur) => prev.concat(cur), []),
                ...this.outgoingWhatsAppMessages
                    .filter((w) => w.mediaHash)
                    .map((whatsApp) => ({
                        type: ActivityAttachmentType.WHATSAPP,
                        activity: whatsApp,
                        hash: whatsApp.mediaHash,
                    })),
            ]
                .map((a) => a as OutgoingAttachment)
                .filter((a, index, array) => array.findIndex((b) => a.hash === b.hash) === index)
                .sort((a, b) => b.activity.created.getTime() - a.activity.created.getTime());
        },

        hiddenOutgoingEmbeddedContentHashes(): string[] {
            return this.outgoingEmails
                .map((email) =>
                    email.attachments
                        .filter((attachment) => !!attachment.contentId)
                        .map((attachment) => attachment.contentHash)
                )
                .reduce((prev, cur) => prev.concat(cur), [])
                .filter((a, index, array) => array.findIndex((b) => a === b) === index);
        },

        acceptedFileTypes(): string[] {
            return configStore.configuration.whitelistedOutgoingFileExtensions;
        },
    },

    methods: {
        showFileDialog() {
            fileDialog(true, this.addFiles, this.acceptedFileTypes);
        },

        async addFiles(files: File[]) {
            this.progress = 0;
            this.uploading++;
            try {
                await opportunitiesApi.addAttachments(
                    this.opportunityProp.id,
                    files,
                    ({ total, loaded }) => (this.progress = (100 * loaded) / total)
                );
            } finally {
                this.uploading--;
            }
        },

        async deleteAttachment(attachmentId: number) {
            if (
                await showConfirm(
                    this.$t("Datei löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Datei löschen möchten?") as string
                )
            ) {
                this.deleting = true;
                try {
                    await opportunitiesApi.deleteAttachment(this.opportunityProp.id, attachmentId);
                } finally {
                    this.successfullyDeleted.push(attachmentId);
                    this.deleting = false;
                }
            }
        },

        showAttachment(attachment: OpportunityAttachment, download: boolean) {
            const fname = attachment.filename.toLowerCase();
            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.image = opportunitiesApi.generateAttachmentLink(this.opportunityProp.id, attachment);
            } else {
                window.open(opportunitiesApi.generateAttachmentLink(this.opportunityProp.id, attachment, download));
            }
        },

        dragAttachment(event: DragEvent, attachment: OpportunityAttachment) {
            event.dataTransfer!.setData(
                "text/uri-list",
                new URL(opportunitiesApi.generateAttachmentLink(this.opportunityProp.id, attachment), document.baseURI)
                    .href
            );
        },
    },

    components: {
        DToolbarCard,
        FileDropZone,
        ImageCarousel,
        IncomingEmailAttachmentPanelRow,
        IncomingWhatsAppMediaPanelRow,
        OutgoingEmailAttachmentPanelRow,
        OutgoingWhatsAppMediaPanelRow,
    },
});
