
import { ExternalContactParsed, ExternalContactPayload } from "@/api/contactUploads";
import { ContactVehicleRecord, MileageUnit } from "@/api/contacts";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { renderContactName, renderFullName, renderPhoneNumberTypeIcon, renderVehicleTitle } from "@/app/contactUtils";
import { renderUnit } from "@/app/filters";
import Vue from "vue";

type ExternalContactPayloadWithId = ExternalContactPayload & { readonly id: string };

export default Vue.extend({
    props: {
        items: Array as () => ExternalContactParsed[],
        totalSize: Number,
        pageSize: Number,
    },

    data() {
        return {
            paging: {
                page: 1,
                pageSize: this.pageSize,
                totalSize: this.totalSize,
                maxTotalSize: this.totalSize + 1,
                maxPage: Math.ceil(this.items.length / this.pageSize),
            } as DataTablePaging,
            renderContactName,
            renderFullName,
            renderVehicleTitle,
            renderPhoneNumberTypeIcon,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("ID"),
                    width: "5%",
                },
                {
                    text: this.$t("Kontakt"),
                    width: "15%",
                },
                {
                    text: this.$t("Name und Anschrift"),
                    width: "20%",
                },
                {
                    text: this.$t("Kontaktdaten"),
                    width: "20%",
                },
                {
                    text: this.$t("Fahrzeuge"),
                    width: "40%",
                },
            ];
        },

        pagedItems(): ExternalContactPayloadWithId[] {
            const i = this.paging.page - 1;
            return this.items
                .slice(i * this.paging.pageSize, (i + 1) * this.paging.pageSize)
                .map((c) => ({ id: c.externalContactId, ...c.payload }));
        },
    },

    methods: {
        getMileage(record: ContactVehicleRecord) {
            if (!record.mileage) {
                return null;
            }

            let unit = "";
            if (record.mileageUnit === MileageUnit.KM) {
                unit = "kilometer";
            } else if (record.mileageUnit === MileageUnit.MI) {
                unit = "mile";
            }

            return renderUnit(record.mileage, unit);
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };
        },
    },

    components: {
        CopyToClipboardIcon,
        DataTable,
    },
});
