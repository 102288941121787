import { CaseOutcomeReason, caseOutcomeReasonsApi } from "@/api/caseOutcomeReasons";
import {
    isCaseOutcomeReasonChangedNotification,
    isCaseOutcomeReasonDeletedNotification,
    isCaseOutcomeReasonsOrderChangedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { removeMatchingItems, reorderItems, updateItem } from "@/util/storeUtils";

@reactive
class CaseOutcomeReasonsStore {
    private caseOutcomeReasons_: CaseOutcomeReason[] = [];
    private refreshLimiter = new ActionLimiter(true);

    getCaseOutcomeReasonById(id: string): CaseOutcomeReason | null {
        return this.caseOutcomeReasons_.find((r) => r.id === id) ?? null;
    }

    get caseOutcomeReasons(): CaseOutcomeReason[] {
        return [...this.caseOutcomeReasons_];
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.caseOutcomeReasons_ = await caseOutcomeReasonsApi.getAll();
        });
    }

    handleNotification(n: Notification) {
        if (isCaseOutcomeReasonChangedNotification(n)) {
            updateItem(this.caseOutcomeReasons_, n.caseOutcomeReason, (r) => r.id);
        } else if (isCaseOutcomeReasonDeletedNotification(n)) {
            removeMatchingItems(this.caseOutcomeReasons_, (r) => r.id === n.caseOutcomeReasonId);
        } else if (isCaseOutcomeReasonsOrderChangedNotification(n)) {
            reorderItems(this.caseOutcomeReasons_, n.orderedCaseOutcomeReasonIds, (r) => r.id);
        }
    }
}

export const caseOutcomeReasonsStore = new CaseOutcomeReasonsStore();

notificationEventSource.addDataHandler((n) => caseOutcomeReasonsStore.handleNotification(n));
