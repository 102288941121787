import { VehicleClass } from "./makeModels";
import axios from "axios";

export interface ServiceOffered {
    readonly id: string;
    readonly dealerId: string;
    readonly make: string | null;
    readonly vehicleClass: VehicleClass | null;
    readonly newVehicles: boolean | null;
    readonly usedVehicles: boolean | null;
    readonly service: boolean | null;
    readonly parts: boolean | null;
    readonly description: string | null;
}

export interface ServiceOfferedForm {
    readonly make: string | null;
    readonly vehicleClass: VehicleClass | null;
    readonly newVehicles: boolean | null;
    readonly usedVehicles: boolean | null;
    readonly service: boolean | null;
    readonly parts: boolean | null;
    readonly description: string | null;
}

interface ServicesOfferedApi {
    getByDealer(dealerId: string): Promise<ServiceOffered[]>;
    updateOrder(dealerId: string, ids: string[]): Promise<void>;
    add(dealerId: string, form: ServiceOfferedForm): Promise<void>;
    edit(dealerId: string, id: string, form: ServiceOfferedForm): Promise<void>;
    delete(dealerId: string, id: string): Promise<void>;
}

export const servicesOfferedApi: ServicesOfferedApi = {
    async getByDealer(dealerId) {
        return (await axios.get(`/api/services-offered/${dealerId}`)).data;
    },

    async updateOrder(dealerId, ids) {
        await axios.post(`/api/services-offered/${dealerId}/_update-order`, ids);
    },

    async add(dealerId, form) {
        await axios.post(`/api/services-offered/${dealerId}`, form);
    },

    async edit(dealerId, id, form) {
        await axios.put(`/api/services-offered/${dealerId}/${id}`, form);
    },

    async delete(dealerId, id) {
        await axios.delete(`/api/services-offered/${dealerId}/${id}`);
    },
};
