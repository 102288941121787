import axios from "axios";

export interface Department {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly dealerId: string;
    readonly name: string;
    readonly departmentUsers: DepartmentUser[];
}

export interface DepartmentUser {
    readonly userId: string;
    readonly position: string;
}

export interface DepartmentForm {
    readonly name: string;
}

interface DepartmentsApi {
    getAll(): Promise<Department[]>;
    getByDealer(dealerId: string): Promise<Department[]>;
    updateOrder(dealerId: string, departmentIds: string[]): Promise<void>;
    add(dealerId: string, departmentForm: DepartmentForm): Promise<void>;
    edit(dealerId: string, id: string, departmentForm: DepartmentForm): Promise<void>;
    delete(dealerId: string, id: string): Promise<void>;
    updateUserOrder(dealerId: string, id: string, userIds: string[]): Promise<void>;
}

export const departmentsApi: DepartmentsApi = {
    async updateUserOrder(dealerId, id, userIds) {
        return await axios.post(`/api/departments/${dealerId}/${id}/_update-user-order`, userIds);
    },

    async getAll() {
        const result: Department[] = (await axios.get(`/api/departments`)).data;

        return result.map((v) => ({
            ...v,
            created: new Date(v.created),
            updated: new Date(v.updated),
        }));
    },

    async getByDealer(dealerId) {
        const result: Department[] = (await axios.get(`/api/departments/${dealerId}`)).data;

        return result.map((v) => ({
            ...v,
            created: new Date(v.created),
            updated: new Date(v.updated),
        }));
    },

    async updateOrder(dealerId, departmentIds) {
        return await axios.post(`/api/departments/${dealerId}/_update-order`, departmentIds);
    },

    async add(dealerId, departmentForm) {
        await axios.post(`/api/departments/${dealerId}`, departmentForm);
    },

    async edit(dealerId, id, departmentForm) {
        await axios.patch(`/api/departments/${dealerId}/${id}`, departmentForm);
    },

    async delete(dealerId, id) {
        await axios.delete(`/api/departments/${dealerId}/${id}`);
    },
};
