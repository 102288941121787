import { renderUnit } from "./filters";
import { $t } from "./i18n";
import { getVehicleClassIcon } from "./makeModelsUtils";
import { ContactSalutation, MileageUnit, NumberType } from "@/api/contacts";
import { VehicleClass } from "@/api/makeModels";

export interface ContactAndVehicleId {
    readonly contactId: string;
    readonly contactVehicleId: string | null;
}

interface VehicleMakeModelData {
    readonly licensePlate: string | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly modelYear: string | null;
}

export interface ContactNameData {
    readonly salutation: ContactSalutation | null;
    readonly companyName: string | null;
    readonly namePrefix: string | null;
    readonly givenName: string | null;
    readonly middleName: string | null;
    readonly familyName: string | null;
    readonly nameSuffix: string | null;
}

export const EMPTY_CONTACT_NAME_DATA: ContactNameData = Object.freeze({
    salutation: null,
    companyName: null,
    namePrefix: null,
    givenName: null,
    middleName: null,
    familyName: null,
    nameSuffix: null,
});

function toName(parts: (string | null)[]): string {
    return parts.map((v) => (v ? v.trim() : "")).reduce((l, r) => `${l} ${r}`.trim(), "");
}

export function renderFullSalutation(c: ContactNameData, locale: string): string {
    let salutation = "";

    if (c.salutation && c.familyName) {
        salutation = $t(`salutation.${c.salutation}`, locale, [c.familyName]) as string;
    }

    if (!salutation || salutation.indexOf("salutation.") === 0) {
        const isSingular =
            !c.salutation ||
            c.salutation === ContactSalutation.MR ||
            c.salutation === ContactSalutation.MS ||
            c.salutation === ContactSalutation.MRS;

        if (isSingular) {
            salutation = $t("Sehr geehrte Damen und Herren", locale) as string;
        } else {
            salutation = $t("Sehr geehrte Damen und Herren", locale) as string;
        }
    }

    if (!salutation.length) {
        return $t("Sehr geehrte Damen und Herren", locale) as string;
    }

    return salutation.trim();
}

export function renderFullName(c: ContactNameData): string {
    return toName([c.namePrefix, c.givenName, c.middleName, c.familyName, c.nameSuffix]);
}

export function renderContactName(c: ContactNameData): string {
    const fullName = renderFullName(c);

    if (!fullName) {
        if (!c.companyName) {
            return $t("Unbenannter Kontakt") as string;
        }
        return c.companyName;
    }

    if (!c.salutation) {
        return fullName;
    }

    const contactSalutation = $t(`enum.ContactSalutation.${c.salutation}`);

    return `${contactSalutation} ${fullName}`;
}

export function renderContactCaption(c: ContactNameData): string {
    const fullName = renderContactName(c);

    if (!c.companyName || c.companyName === fullName) {
        return fullName;
    }

    return `${fullName}, ${c.companyName}`;
}

export function renderVehicleTitle(cv: VehicleMakeModelData, prependLicensePlate: boolean): string {
    let result = toName([cv.make, cv.model, cv.modelDescription]);

    if (!result.length) {
        result = $t("Unbekanntes Fahrzeugmodell") as string;
    }

    if (cv.modelYear) {
        result += ` (${cv.modelYear})`;
    }

    if (!cv.licensePlate || !prependLicensePlate) {
        return result;
    }

    return `${cv.licensePlate}, ${result}`;
}

export function renderPhoneNumberTypeIcon(numberType: NumberType): string {
    if (numberType === NumberType.MOBILE) {
        return "mdi-cellphone";
    } else if (numberType === NumberType.LANDLINE) {
        return "mdi-deskphone";
    } else if (numberType === NumberType.FAX) {
        return "mdi-fax";
    }

    return "mdi-phone-classic";
}

export function getContactVehicleIcon(vehicleClass: VehicleClass | null): string {
    return getVehicleClassIcon(vehicleClass ?? VehicleClass.CAR);
}

export function renderMileage(mileage: number | null, mileageUnit: MileageUnit | null): string | null {
    const unit = mileageUnit === MileageUnit.KM ? "kilometer" : mileageUnit === MileageUnit.MI ? "mile" : null;

    if ((!mileage && mileage !== 0) || !unit) {
        return null;
    }

    return renderUnit(mileage, unit);
}
