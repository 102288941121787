
import { OutgoingEmailAssistantState, outgoingEmailAssistantState } from "./outgoingEmailAssistantState";
import { ContactData } from "@/api/contacts";
import { OutgoingEmailForm, outgoingEmailsApi } from "@/api/outgoingEmails";
import { showConfirm } from "@/app/messageUtil";
import ContactAndVehiclePicker from "@/app/pages/contacts/ContactAndVehiclePicker.vue";
import OutgoingEmailFormCard from "@/app/pages/outgoingemails/OutgoingEmailFormCard.vue";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        persistent: {
            type: Boolean,
            default: true,
        },
        defaultText: {
            type: String,
            required: false,
        },
        jumpToMyActivities: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            contactData: null as ContactData | null,
            defaultReceiver: null as string | null,
            emailAttachmentsUploadProgress: 0,
            outgoingEmailAssistantState: this.persistent
                ? outgoingEmailAssistantState
                : new OutgoingEmailAssistantState(),
            sending: false,
        };
    },

    computed: {
        dealerId(): string | null {
            return userSession.dealerId;
        },

        working(): boolean {
            return this.sending;
        },
    },

    methods: {
        async redirectToMyActivities() {
            await this.$router.push("/my-activities?t=3");
        },

        async reset() {
            if (
                await showConfirm(
                    this.$t("Eingaben zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?") as string
                )
            ) {
                this.outgoingEmailAssistantState.reset();
                this.defaultReceiver = null;
            }
        },

        async sendEmail(outgoingEmailForm: OutgoingEmailForm, files: File[]) {
            this.sending = true;

            try {
                await outgoingEmailsApi.sendEmail(
                    this.outgoingEmailAssistantState.contactAndVehicleId?.contactId ?? null,
                    outgoingEmailForm,
                    files,
                    ({ total, loaded }) => (this.emailAttachmentsUploadProgress = (100 * loaded) / total)
                );
                this.outgoingEmailAssistantState.reset();
                this.$emit("success");
                if (this.jumpToMyActivities) {
                    await this.redirectToMyActivities();
                }
            } catch (e) {
                Vue.nextTick(() => {
                    throw e;
                });
            } finally {
                this.sending = false;
            }
        },

        async submit() {
            await (this.$refs.outgoingEmailFormCard as any).submit();
        },
    },

    components: {
        ContactAndVehiclePicker,
        OutgoingEmailFormCard,
    },
});
