
import { OutgoingEmailReceiverView } from "./outgoingEmailReceiver";
import { OutgoingEmailEventType } from "@/api/outgoingEmails";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        receiverView: {
            type: Object as () => OutgoingEmailReceiverView,
            required: true,
        },
    },

    computed: {
        tooltip(): string {
            let result = "";

            if (!this.receiverView.lastStatusEvent) {
                result = this.$t("E-Mail befindet sich in Zustellung.") as string;
            }
            if (this.receiverView.lastStatusEvent?.eventType === OutgoingEmailEventType.DELIVERED) {
                result = this.$t("E-Mail wurde erfolgreich zugestellt. ({0})", [
                    formatInstant(
                        this.receiverView.lastStatusEvent.occuredAt,
                        userSession.timeZone,
                        userSession.locale,
                        "S"
                    ),
                ]) as string;
            }
            if (this.receiverView.lastStatusEvent?.eventType === OutgoingEmailEventType.BOUNCED_HARD) {
                result = this.$t("Zustellung der E-Mail ist fehlgeschlagen. ({0})", [
                    formatInstant(
                        this.receiverView.lastStatusEvent.occuredAt,
                        userSession.timeZone,
                        userSession.locale,
                        "S"
                    ),
                ]) as string;
            }
            if (this.receiverView.lastStatusEvent?.eventType === OutgoingEmailEventType.BOUNCED_TRANSIENT) {
                result = this.$t("Zustellung der E-Mail ist temporär fehlgeschlagen. ({0})", [
                    formatInstant(
                        this.receiverView.lastStatusEvent.occuredAt,
                        userSession.timeZone,
                        userSession.locale,
                        "S"
                    ),
                ]) as string;
            }
            if (this.receiverView.lastStatusEvent?.eventType === OutgoingEmailEventType.BOUNCED_AUTO_RESPONDER) {
                result = this.$t("Der Empfänger hat einen Auto-Responder aktiviert. ({0})", [
                    formatInstant(
                        this.receiverView.lastStatusEvent.occuredAt,
                        userSession.timeZone,
                        userSession.locale,
                        "S"
                    ),
                ]) as string;
            }
            if (this.receiverView.lastStatusEvent?.eventType === OutgoingEmailEventType.BOUNCED_SPAM_NOTIFICATION) {
                result = this.$t(
                    "Die E-Mail wurde vom Empfänger oder vom System des Empfängers als Spam markiert. ({0})",
                    [
                        formatInstant(
                            this.receiverView.lastStatusEvent.occuredAt,
                            userSession.timeZone,
                            userSession.locale,
                            "S"
                        ),
                    ]
                ) as string;
            }
            if (this.receiverView.lastStatusEvent?.eventType === OutgoingEmailEventType.BOUNCED_SOFT) {
                result = this.$t("Zustellung der E-Mail konnte temporär nicht ausgeführt werden. ({0})", [
                    formatInstant(
                        this.receiverView.lastStatusEvent.occuredAt,
                        userSession.timeZone,
                        userSession.locale,
                        "S"
                    ),
                ]) as string;
            }
            if (this.receiverView.lastStatusEvent?.eventType === OutgoingEmailEventType.BOUNCED_VIRUS_NOTIFICATION) {
                result = this.$t("Die Zustellung ist aufgrund einer Virus-Warnung fehlgeschlagen. ({0})", [
                    formatInstant(
                        this.receiverView.lastStatusEvent.occuredAt,
                        userSession.timeZone,
                        userSession.locale,
                        "S"
                    ),
                ]) as string;
            }
            if (this.receiverView.lastStatusEvent?.eventType === OutgoingEmailEventType.BOUNCED_SPAM_COMPLAINT) {
                result = this.$t("Die E-Mail wurde vom Empfänger als Spam markiert. ({0})", [
                    formatInstant(
                        this.receiverView.lastStatusEvent.occuredAt,
                        userSession.timeZone,
                        userSession.locale,
                        "S"
                    ),
                ]) as string;
            }
            if (this.receiverView.lastStatusEvent?.eventType === OutgoingEmailEventType.BOUNCED_BLOCKED) {
                result = this.$t("Zustellung der E-Mail wurde durch den Provider blockiert. ({0})", [
                    formatInstant(
                        this.receiverView.lastStatusEvent.occuredAt,
                        userSession.timeZone,
                        userSession.locale,
                        "S"
                    ),
                ]) as string;
            }
            if (this.receiverView.lastStatusEvent?.eventType === OutgoingEmailEventType.BOUNCED_OTHER) {
                result = this.$t("Zustellung der E-Mail ist aus sonstigem Grund fehlgeschlagen. ({0})", [
                    formatInstant(
                        this.receiverView.lastStatusEvent.occuredAt,
                        userSession.timeZone,
                        userSession.locale,
                        "S"
                    ),
                ]) as string;
            }

            if (this.receiverView.openCount) {
                const lastOpenCaption = formatInstant(
                    this.receiverView.lastOpen!,
                    userSession.timeZone,
                    userSession.locale,
                    "S"
                );

                result += "\n";
                result += this.$tc(
                    "E-Mail wurde 0 Mal geöffnet. | E-Mail wurde 1 Mal geöffnet. | E-Mail wurde {count} Mal geöffnet.",
                    this.receiverView.openCount
                ) as string;
                result += ` (${this.$t("zuletzt geöffnet am {0}", [lastOpenCaption])})`;
            }

            if (this.receiverView.clickCount) {
                const lastClickCaption = formatInstant(
                    this.receiverView.lastClick!,
                    userSession.timeZone,
                    userSession.locale,
                    "S"
                );

                result += "\n";
                result += this.$tc(
                    "Link aus der E-Mail wurde 0 Mal geklickt. | Link aus der E-Mail wurde 1 Mal geklickt. | Link aus der E-Mail wurde {count} Mal geklickt.",
                    this.receiverView.clickCount
                ) as string;
                result += ` (${this.$t("zuletzt geklickt am {0}", [lastClickCaption])})`;
            }

            return result;
        },

        prefixIcon(): string {
            if (!this.receiverView.lastStatusEvent) {
                return "mdi-clock-outline";
            }

            if (this.receiverView.lastStatusEvent.eventType === OutgoingEmailEventType.DELIVERED) {
                return "mdi-check";
            }

            return "mdi-close";
        },

        chipColor(): string | undefined {
            if (!this.receiverView.lastStatusEvent) {
                return undefined;
            }

            if (this.receiverView.lastStatusEvent.eventType === OutgoingEmailEventType.DELIVERED) {
                return "success";
            }

            return "error";
        },
    },
});
