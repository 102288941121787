
import {
    getIncomingCallEventStatusColor,
    IncomingCallEventWithDuration,
    InternalIncomingPhoneNumberEvents,
    toInternalIncomingPhoneNumberEvents,
} from "./incomingCallEventUtils";
import { IncomingCallResult } from "@/api/incomingCalls";
import { InternalIncomingPhoneNumber } from "@/api/incomingPhoneNumbers";
import UserLink from "@/app/pages/UserLink.vue";
import { userSession } from "@/store/userSession";
import { formatDuration, formatTime } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        incomingCall: {
            type: Object as () => IncomingCallResult,
            required: true,
        },
        internalIncomingPhoneNumbers: {
            type: Array as () => InternalIncomingPhoneNumber[],
            required: true,
        },
    },

    data() {
        return {
            expand: false,
        };
    },

    computed: {
        eventsByInternalIncomingPhoneNumbers(): InternalIncomingPhoneNumberEvents[] {
            return toInternalIncomingPhoneNumberEvents(this.incomingCall);
        },
    },

    methods: {
        eventColor(event: IncomingCallEventWithDuration): string | null {
            return getIncomingCallEventStatusColor(event.status, event.duration);
        },

        formatDuration(duration: number): string {
            return formatDuration(duration, true, "S");
        },

        formatTime(date: Date): string {
            return formatTime(date, userSession.timeZone, userSession.locale);
        },

        getInternalIncomingPhoneNumberById(id: string): InternalIncomingPhoneNumber | null {
            return this.internalIncomingPhoneNumbers.find((n) => n.id === id) ?? null;
        },
    },

    components: {
        UserLink,
    },
});
