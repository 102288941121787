import axios from "axios";

export interface EmergencyIncidentNotificationReceiver {
    readonly dealerId: string;
    readonly userId: string;
}

interface EmergencyIncidentNotificationReceiversApi {
    add(emergencyIncidentNotificationReceiver: EmergencyIncidentNotificationReceiver): Promise<void>;
    delete(dealerId: string, userId: string): Promise<void>;
    getByDealer(dealerId: string): Promise<EmergencyIncidentNotificationReceiver[]>;
}

export const emergencyIncidentNotificationReceiversApi: EmergencyIncidentNotificationReceiversApi = {
    async add(emergencyIncidentNotificationReceiver) {
        await axios.post("/api/emergency-incident-notification-receivers", emergencyIncidentNotificationReceiver);
    },

    async delete(dealerId, userId) {
        await axios.delete(`/api/emergency-incident-notification-receivers/${dealerId}/${userId}`);
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/emergency-incident-notification-receivers/${dealerId}`)).data;
    },
};
