
import { getCreator, getStatusColor } from "./outgoingCallUtils";
import { outgoingCallsOverviewState } from "./outgoingCallsOverview";
import { Contact } from "@/api/contacts";
import { isOutgoingCallUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import {
    IssueType,
    OutgoingCall,
    OutgoingCallAndContact,
    outgoingCallsApi,
    OutgoingCallSearchOrder,
} from "@/api/outgoingCalls";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { renderContactCaption } from "@/app/contactUtils";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { PickMutable, SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const itemsPerPage = 100;

        return {
            getCreator,
            IssueType,
            items: [] as OutgoingCallAndContact[],
            itemsPerPage,
            loading: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingCallsOverviewState: outgoingCallsOverviewState,
            OutgoingCallSearchOrder,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            parseAndFormatNumber,
            searchCounter: 1,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Anruf"),
                    width: "30%",
                },
                {
                    text: this.$t("Gesprächsnotiz"),
                    width: "35%",
                },
                {
                    text: this.$t("Kontakt und Vorgang"),
                    width: "35%",
                },
            ];
        },

        initiatorUserOptions(): SelectOption[] {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },
    },

    methods: {
        getContactCaption(contact: Contact | null): string | null {
            if (!contact) {
                return null;
            }

            return renderContactCaption(contact.contactData);
        },

        getStatusClass(outgoingCall: OutgoingCall): string {
            const color = getStatusColor(outgoingCall.callStatus);

            return color ? `${color}--text` : "error--text";
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        isContactDeleted(contact: Contact | null): boolean {
            return !!contact?.deleted;
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const outgoingCallSearchResults = await outgoingCallsApi.search(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    this.outgoingCallsOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (outgoingCallSearchResults.searchId === this.searchCounter) {
                    this.items = outgoingCallSearchResults.results;
                    this.paging.totalSize = outgoingCallSearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        refresh() {
            this.outgoingCallsOverviewState.refresh();
        },
    },

    watch: {
        async "outgoingCallsOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        if (!this.outgoingCallsOverviewState.initialized) {
            this.outgoingCallsOverviewState.setTodayQuickfilter();
        }

        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isOutgoingCallUpdatedNotification(n)) {
                this.items = this.items.map((item) =>
                    item.outgoingCall.id === n.outgoingCall.id
                        ? {
                              ...item,
                              outgoingCall: n.outgoingCall,
                          }
                        : item
                );
            }
        });

        await this.loadItems();
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        DAutocomplete,
        DataTable,
        DatePicker,
        EnumField,
        TimeSpan,
        UserLink,
    },
});
