
import AbsenceFormDialog from "./AbsenceFormDialog.vue";
import { Absence, absencesApi } from "@/api/absences";
import { ExternalAbsenceProvider, externalAbsenceProvidersApi } from "@/api/externalAbsenceProviders";
import { usersApi, UserSummary } from "@/api/users";
import { isFullDays, renderAbsenceTime } from "@/app/absenceUtils";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import { showConfirm } from "@/app/messageUtil";
import { searchFilter } from "@/app/searchFilter";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import Vue from "vue";

interface UserSummaryWithAbsences extends UserSummary {
    absences: Absence[];
}

type MutableAbsence = { -readonly [P in keyof Absence]: Absence[P] };

export default Vue.extend({
    props: {
        dealerId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            formTimeZone: "",
            formVisble: false,
            formUserId: "",
            formItem: null as Absence | null,
            formFullDays: true,
            users: [] as UserSummaryWithAbsences[],
            externalAbsenceProviders: [] as ExternalAbsenceProvider[],
            loading: true,
            search: null as string | null,
            removingDefaultSubstitute: false,
            getFullName,
            renderAbsenceTime,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Benutzer"),
                    width: "20%",
                },
                {
                    text: this.$t("Abwesenheiten"),
                    width: "70%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "10%",
                },
            ];
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    methods: {
        closeDialog() {
            this.formVisble = false;
        },

        addAbsence(user: UserSummaryWithAbsences) {
            this.formItem = null;
            this.formUserId = user.id;
            this.formTimeZone = user.timeZone;
            this.formFullDays = true;
            this.formVisble = true;
        },

        async deleteAbsence(id: string) {
            if (
                await showConfirm(
                    this.$t("Abwesenheit löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Abwesenheit löschen möchten?") as string
                )
            ) {
                await absencesApi.delete(id);
                await this.loadItems();
            }
        },

        editAbsence(absence: Absence, timeZone: string) {
            this.formItem = absence;
            this.formUserId = absence.userId;
            this.formTimeZone = timeZone;
            this.formFullDays = isFullDays(timeZone, absence);
            this.formVisble = true;
        },

        isAbsenceActive(absence: Absence) {
            const now = Date.now();

            if (!absence.to) {
                return now >= absence.from.getTime();
            }

            return now >= absence.from.getTime() && now <= absence.to.getTime();
        },

        async savedItem() {
            this.formVisble = false;
            await this.loadItems();
        },

        getExternalAbsenceProviderById(externalAbsenceProviderId: string) {
            for (const externalAbsenceProvider of this.externalAbsenceProviders) {
                if (externalAbsenceProvider.id === externalAbsenceProviderId) {
                    return externalAbsenceProvider;
                }
            }
        },

        getUserFullNameById(userId: string) {
            return getFullName(usersStore.getUserById(userId));
        },

        async loadItems() {
            this.loading = true;

            try {
                if (this.dealerId) {
                    this.externalAbsenceProviders = await externalAbsenceProvidersApi.list();

                    const users = await usersApi.getByMainDealer(this.dealerId);
                    const absences = await absencesApi.getByMainDealer(this.dealerId);

                    this.users = users.map((user) => {
                        return {
                            ...user,
                            absences: this.getAbsencesByUserId(absences, user.id),
                        };
                    });
                } else {
                    this.users = [];
                }
            } finally {
                this.loading = false;
            }
        },

        getAbsencesByUserId(absences: Absence[], userId: string) {
            return absences.filter((absence) => absence.userId === userId);
        },

        filter(search: string, item: UserSummaryWithAbsences): boolean {
            return searchFilter(search, getFullName(item), item.username, item.employeeNumber);
        },

        async removeDefaultSubstitute(absence: MutableAbsence) {
            this.removingDefaultSubstitute = true;
            try {
                await absencesApi.removeDefaultSubstitute(absence.id);
            } finally {
                this.removingDefaultSubstitute = false;
            }
            absence.substituteUserIds = [];
            absence.defaultSubstituteRemoved = true;
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        AbsenceFormDialog,
        DataTable,
    },
});
