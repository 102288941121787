
import AbsenceFormDialog from "./AbsenceFormDialog.vue";
import { Absence, absencesApi } from "@/api/absences";
import { ExternalAbsenceProvider, externalAbsenceProvidersApi } from "@/api/externalAbsenceProviders";
import { Permission } from "@/api/userSession";
import { usersApi, UserSummary } from "@/api/users";
import { isFullDays, renderAbsenceTime } from "@/app/absenceUtils";
import { showConfirm } from "@/app/messageUtil";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import Vue from "vue";

interface UserSummaryWithAbsences extends UserSummary {
    absences: Absence[];
}

type MutableAbsence = { -readonly [P in keyof Absence]: Absence[P] };

export default Vue.extend({
    data() {
        return {
            formTimeZone: "",
            formVisble: false,
            formUserId: "",
            formItem: null as Absence | null,
            formFullDays: true,
            user: null as UserSummaryWithAbsences | null,
            externalAbsenceProviders: [] as ExternalAbsenceProvider[],
            loading: true,
            removingDefaultSubstitute: false,
        };
    },

    computed: {
        canChangeOwnAbsences() {
            return userSession.hasPermission(Permission.CHANGE_OWN_ABSENCES);
        },
    },

    methods: {
        closeDialog() {
            this.formVisble = false;
        },

        addAbsence() {
            this.formItem = null;
            this.formUserId = this.user!.id;
            this.formTimeZone = this.user!.timeZone;
            this.formFullDays = true;
            this.formVisble = true;
        },

        async deleteAbsence(id: string) {
            if (
                await showConfirm(
                    this.$t("Abwesenheit löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Abwesenheit löschen möchten?") as string
                )
            ) {
                await absencesApi.delete(id);
                await this.loadItems();
            }
        },

        editAbsence(absence: Absence) {
            this.formItem = absence;
            this.formUserId = absence.userId;
            this.formTimeZone = this.user!.timeZone;
            this.formFullDays = isFullDays(this.user!.timeZone, absence);
            this.formVisble = true;
        },

        isAbsenceActive(absence: Absence) {
            const now = Date.now();

            if (!absence.to) {
                return now >= absence.from.getTime();
            }

            return now >= absence.from.getTime() && now <= absence.to.getTime();
        },

        renderAbsenceTime(absence: Absence) {
            return renderAbsenceTime(this.user!.timeZone, absence);
        },

        async savedItem() {
            this.formVisble = false;
            await this.loadItems();
        },

        getExternalAbsenceProviderById(externalAbsenceProviderId: string) {
            for (const externalAbsenceProvider of this.externalAbsenceProviders) {
                if (externalAbsenceProvider.id === externalAbsenceProviderId) {
                    return externalAbsenceProvider;
                }
            }
        },

        getUserFullNameById(userId: string) {
            return getFullName(usersStore.getUserById(userId));
        },

        async loadItems() {
            this.loading = true;

            try {
                this.externalAbsenceProviders = await externalAbsenceProvidersApi.list();

                const user = await usersApi.getById(userSession.userId!);
                const absences = await absencesApi.getByUser(userSession.userId!);

                this.user = {
                    ...user,
                    absences: absences,
                };
            } finally {
                this.loading = false;
            }
        },

        async removeDefaultSubstitute(absence: MutableAbsence) {
            this.removingDefaultSubstitute = true;
            try {
                await absencesApi.removeDefaultSubstitute(absence.id);
            } finally {
                this.removingDefaultSubstitute = false;
            }
            absence.substituteUserIds = [];
            absence.defaultSubstituteRemoved = true;
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        AbsenceFormDialog,
    },
});
