
import DealerInstructionOverviewFormDialogContent from "./DealerInstructionOverviewFormDialogContent.vue";
import {
    DealerInstruction,
    DealerInstructionForm,
    DealerInstructionPriority,
    dealerInstructionsApi,
} from "@/api/dealerInstructions";
import { showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import DealerLink from "@/app/pages/DealerLink.vue";
import Vue from "vue";

export default Vue.extend({
    methods: {
        async getAllInstructions() {
            return await dealerInstructionsApi.getAll();
        },

        async addInstruction(form: DealerInstructionForm) {
            await dealerInstructionsApi.add(form);
        },

        async editInstruction(id: string, form: DealerInstructionForm) {
            await dealerInstructionsApi.edit(id, form);
        },

        async deleteInstruction(item: DealerInstruction) {
            if (
                await showConfirm(
                    this.$t("Anweisung löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Anweisung löschen möchten?") as string
                )
            ) {
                await dealerInstructionsApi.delete(item.id);
                return true;
            }
            return false;
        },

        async updateOrder(items: DealerInstruction[]) {
            await dealerInstructionsApi.updateOrder(items.map((d) => d.id));
        },

        toForm(item: DealerInstruction): DealerInstructionForm {
            return JSON.parse(JSON.stringify(item));
        },

        emptyForm(): DealerInstructionForm {
            return {
                dealerIds: [],
                priority: DealerInstructionPriority.MEDIUM,
                categories: [],
                issueTypes: [],
                caseTypes: [],
                visibleFromDate: null,
                visibleFromTime: null,
                visibleToDate: null,
                visibleToTime: null,
                headline: "",
                text: "",
            };
        },

        headlineColorClass(item: DealerInstruction) {
            if (item.priority === DealerInstructionPriority.HIGH) {
                return "error--text";
            }
            if (item.priority === DealerInstructionPriority.MEDIUM) {
                return "warning--text";
            }
            return "";
        },
    },

    components: {
        CrudPage,
        DealerInstructionOverviewFormDialogContent,
        DealerLink,
    },
});
