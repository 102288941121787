
import { ContactVehicle, ContactVehicleRecord } from "@/api/contacts";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { getContactVehicleIcon, renderMileage, renderVehicleTitle } from "@/app/contactUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canEdit: {
            type: Boolean,
            default: false,
        },
        canUnselect: {
            type: Boolean,
            default: false,
        },
        contactId: {
            type: String as () => string | null,
            required: false,
        },
        contactVehicle: {
            type: Object as () => ContactVehicle | null,
            required: false,
        },
        contactDeleted: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        elevatedButtons: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            getContactVehicleIcon,
            renderMileage,
            renderVehicleTitle,
        };
    },

    computed: {
        lastRecord(): ContactVehicleRecord | null {
            if (!this.contactVehicle?.contactVehicleData.records.length) {
                return null;
            }

            return this.contactVehicle.contactVehicleData.records[
                this.contactVehicle.contactVehicleData.records.length - 1
            ];
        },

        lastRecordMileage(): string | null {
            return renderMileage(this.lastRecord?.mileage ?? null, this.lastRecord?.mileageUnit ?? null);
        },

        vehicleTooltip(): string {
            if (this.contactVehicle?.contactVehicleData.vehicleClass) {
                return this.$t(`enum.VehicleClass.${this.contactVehicle.contactVehicleData.vehicleClass}`) as string;
            } else {
                return this.$t("Fahrzeug") as string;
            }
        },
    },

    components: {
        CopyToClipboardIcon,
    },
});
