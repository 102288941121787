
import {
    Announcement,
    AnnouncementCreationForm,
    AnnouncementModificationForm,
    announcementsApi,
} from "@/api/announcements";
import { Permission } from "@/api/userSession";
import DateTimePicker from "@/app/components/DateTimePicker.vue";
import { dealerOptions, getSortedByDealer } from "@/app/dealerUtils";
import { notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        announcement: {
            type: Object as () => Announcement | null,
            default: null,
        },
    },

    data() {
        return {
            valid: true,
            isWorking: false,

            // FORM FIELDS
            title: this.announcement?.title || "",
            message: this.announcement?.message || "",
            expiry: this.announcement?.expiry ?? null,
            confirmable: false,
            global: !userSession.dealerId,
            dealerIds: userSession.dealerId ? [userSession.dealerId] : [],
            escalationGroupIds: [...(this.announcement?.escalationGroupIds ?? [])],
            opportunityTeamIds: [...(this.announcement?.opportunityTeamIds ?? [])],

            // RULES
            titleRules: notEmpty().maxLength(300),
            messageRules: notEmpty().maxLength(16383),
        };
    },

    computed: {
        dealerIdsRules() {
            return [
                () =>
                    !!this.announcement ||
                    this.global ||
                    this.dealerIds.length > 0 ||
                    this.$t("Es muss mindestens ein Standort ausgewählt werden."),
            ];
        },

        dealerOptions(): SelectOptions {
            return dealerOptions((d) => this.globalAllowed || !!userSession.profile?.dealerIds.includes(d.id));
        },

        escalationGroupOptions(): SelectOption[] {
            return getSortedByDealer(escalationGroupsStore.escalationGroups, (e) => e.dealerId).map((e) => ({
                text: `${e.name} (${this.getDealerNameById(e.dealerId)})`,
                value: e.id,
            }));
        },

        globalAllowed(): boolean {
            return userSession.hasPermission(Permission.MANAGE_ANNOUNCEMENTS);
        },

        opportunityTeamOptions(): SelectOption[] {
            return getSortedByDealer(opportunityTeamsStore.opportunityTeams, (t) => t.dealerId).map((g) => ({
                value: g.id,
                text: `${g.name} (${this.getDealerNameById(g.dealerId)})`,
            }));
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name || this.$t("Unbekannter Standort");
        },

        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            try {
                if (this.announcement) {
                    const announcementModificationForm: AnnouncementModificationForm = {
                        expiry: this.expiry,
                        title: this.title,
                        message: this.message,
                    };

                    await announcementsApi.edit(this.announcement.id, announcementModificationForm);
                } else {
                    const announcementCreationForm: AnnouncementCreationForm = {
                        expiry: this.expiry,
                        title: this.title,
                        message: this.message,
                        confirmable: this.confirmable,
                        dealerIds: this.global ? [] : this.dealerIds,
                        escalationGroupIds: this.escalationGroupIds,
                        opportunityTeamIds: this.opportunityTeamIds,
                    };

                    await announcementsApi.add(announcementCreationForm);
                }

                this.$emit("success");
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },
    },

    components: {
        DateTimePicker,
    },
});
