
import { ReportingDealerSettings, reportingSettingsApi } from "@/api/reportingSettings";
import { Permission } from "@/api/userSession";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { officeHoursStore } from "@/store/officeHours";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            Permission,
            reportingDealerSettings: null as ReportingDealerSettings | null,
            saving: false,
        };
    },

    computed: {
        dealerId(): string | null {
            return userSession.dealerId;
        },

        officeHoursOptions(): SelectOption[] {
            if (!this.dealerId) {
                return [];
            }

            return officeHoursStore.getOfficeHoursByDealerId(this.dealerId).map((officeHours) => ({
                value: officeHours.id,
                text: officeHours.name,
            }));
        },

        working(): boolean {
            return this.loading || this.saving;
        },
    },

    methods: {
        async loadItems() {
            this.reportingDealerSettings = null;

            if (!this.dealerId) {
                return;
            }

            this.loading = true;
            try {
                this.reportingDealerSettings = await reportingSettingsApi.getReportingDealerSettingsByDealer(
                    this.dealerId
                );
            } finally {
                this.loading = false;
            }
        },

        async submit() {
            if (!this.reportingDealerSettings || !(this.$refs.reportingsettingsform as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await reportingSettingsApi.editReportingDealerSettings(this.reportingDealerSettings);
            } finally {
                this.saving = false;
            }
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DealerContextGuard,
    },
});
