
import { Forbidden } from "@/api/errors";
import { Permission } from "@/api/userSession";
import { PhoneNumberType, usersApi, UserSummary } from "@/api/users";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { showAlert, showConfirm, showError } from "@/app/messageUtil";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { departmentsStore } from "@/store/departments";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        user: {
            type: Object as () => UserSummary,
            required: true,
        },
    },

    data() {
        return {
            getFullName,
        };
    },

    computed: {
        isLoginAsUserAllowed() {
            return !!userSession.permissions && userSession.permissions.includes(Permission.LOGIN_AS_USER);
        },

        formattedLanguages(): string[] {
            return this.user.languages
                .map((k) => this.$t(`language.${k}`) as string)
                .sort((a, b) => a.localeCompare(b, userSession.locale));
        },

        profileImageUrl(): string {
            if (!this.user.profileImageHash) {
                return "/img/user_placeholder.png";
            }

            return usersApi.generateProfileImageLink(this.user.id, this.user.profileImageHash);
        },
    },

    methods: {
        async deleteUser() {
            if (
                !(await showConfirm(
                    this.$t("Benutzer löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Benutzer {0} löschen möchten?", [
                        getFullName(this.user),
                    ]) as string
                ))
            ) {
                return;
            }

            try {
                await usersApi.delete(this.user.id);
                this.$emit("refresh");
            } catch (e) {
                if (!(e instanceof Forbidden)) {
                    throw e;
                }

                showError(this.$t("Sie haben nicht die Berechtigung, den Benutzer zu löschen.") as string);
            }
        },

        getDealerNameById(dealerId: string): string {
            return dealersStore.dealerById(dealerId)?.name || (this.$t("Unbekannter Standort") as string);
        },

        getDepartmentNameById(departmentId: string): string {
            return departmentsStore.departmentById(departmentId)?.name || (this.$t("Unbekannte Abteilung") as string);
        },

        getPhoneNumberTypeIcon(phoneNumberType: PhoneNumberType) {
            if (phoneNumberType === PhoneNumberType.MOBILE) {
                return "mdi-cellphone";
            } else if (phoneNumberType === PhoneNumberType.LANDLINE) {
                return "mdi-deskphone";
            } else if (phoneNumberType === PhoneNumberType.FAX) {
                return "mdi-fax";
            }

            return "mdi-phone-classic";
        },

        async loginAsUser() {
            await usersApi.loginAsUser(this.user.id);

            window.location.href = `/dashboard?dealerId=${this.user.mainDealerId}`;
        },

        async redirectToUserUserFormPage() {
            await this.$router.push(`/user/${this.user.id}`);
        },

        async redirectToUserNotificationSettingsPage() {
            await this.$router.push(`/user-notification-settings?userId=${this.user.id}`);
        },

        async resetPassword() {
            if (
                !(await showConfirm(
                    this.$t("Passwort zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie das Passwort für den Benutzer {0} zurücksetzen möchten?", [
                        getFullName(this.user),
                    ]) as string
                ))
            ) {
                return;
            }

            await usersApi.resetPassword(this.user.id);

            await showAlert(
                this.$t("E-Mail wurde versandt") as string,
                this.$t("Dem Benutzer wurde eine E-Mail zum Neusetzen des Passworts geschickt.") as string
            );
        },
    },

    components: {
        CopyToClipboardIcon,
    },
});
