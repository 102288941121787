
import Vue from "vue";

export default Vue.extend({
    props: {
        volume: {
            type: Number,
            default: 1,
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        audio(): HTMLAudioElement {
            return this.$refs.audio as HTMLAudioElement;
        },
    },

    watch: {
        volume() {
            this.audio.volume = this.volume;
        },
    },

    methods: {
        play() {
            this.audio.play();
        },

        pause() {
            this.audio.pause();
        },
    },

    mounted() {
        this.audio.volume = this.volume;
        if (this.autoplay) {
            this.audio.play();
        }
    },
});
