
import EscalationGroupPickerCard from "./EscalationGroupPickerCard.vue";
import { Case, CaseReceiversForm } from "@/api/cases";
import { dealerOptions } from "@/app/dealerUtils";
import { showError } from "@/app/messageUtil";
import UserPickerCard from "@/app/pages/users/UserPickerCard.vue";
import { UserPickerData } from "@/app/pages/users/userPickerCardTypes";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseProp: {
            type: Object as () => Case,
            required: true,
        },
    },

    data() {
        return {
            releasing: false,
            dealerId: this.caseProp.dealerId,
            escalationGroupReceivers: this.caseProp.escalationGroupReceivers.map((i) => i.escalationGroupId),
            individualReceivers: new UserPickerData(this.caseProp.individualReceivers.map((i) => i.userId)),
        };
    },

    computed: {
        dealerOptions(): SelectOptions {
            return dealerOptions();
        },
    },

    methods: {
        submit() {
            if (!(this.$refs.releaseForm as any).validate()) {
                return;
            }

            if (!this.escalationGroupReceivers.length && !this.individualReceivers.userIds.length) {
                showError(this.$t("Es ist kein Empfänger ausgewählt!") as string);
                return;
            }

            this.releasing = true;
            this.$emit(
                "submit",
                {
                    escalationGroupReceivers: this.escalationGroupReceivers,
                    individualReceivers: this.individualReceivers.userIds,
                } as CaseReceiversForm,
                this.dealerId
            );
        },
    },

    components: {
        EscalationGroupPickerCard,
        UserPickerCard,
    },
});
