
import { OpportunityAssistantState } from "./opportunityAssistant";
import { OpportunityUrgency } from "@/api/opportunities";
import { OpportunitySource } from "@/api/opportunitySources";
import { OpportunityTeam } from "@/api/opportunityTeams";
import { isEmptyVehicleSummary, renderVehicleSummaryTitle, VehicleSummary } from "@/app/inventoryUtils";
import IssueDataCheckCard from "@/app/pages/IssueDataCheckCard.vue";
import IssueDataCheckCardDataTableRow from "@/app/pages/IssueDataCheckCardDataTableRow.vue";
import OpportunityTeamLink from "@/app/pages/OpportunityTeamLink.vue";
import { opportunityTeamFilterMatches } from "@/app/pages/opportunities/opportunityTeamFilter";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityAssistantState: {
            type: Object as () => OpportunityAssistantState,
            required: true,
        },
        phoneticSpellingLocale: {
            type: String,
            required: true,
        },
        singlePageAssistant: {
            type: Boolean,
            default: false,
        },
        vehicleSummaries: {
            type: Array as () => VehicleSummary[],
            default: () => [],
        },
    },

    data() {
        return {
            OpportunityUrgency,
            renderVehicleSummaryTitle,
            now: now(),
        };
    },

    computed: {
        appointmentTime(): Date | null {
            return this.opportunityAssistantState.appointmentTime;
        },

        checkOpportunityDataAcquisitionFields(): boolean {
            return (
                this.checkReceiverSelectionData || this.opportunityAssistantState.urgency !== OpportunityUrgency.NORMAL
            );
        },

        checkReceiverSelectionData(): boolean {
            return (
                this.singlePageAssistant ||
                !!this.opportunityAssistantState.opportunityTeamReceivers.length ||
                !!this.opportunityAssistantState.opportunityIndividualReceivers.userIds.length
            );
        },

        dealerId(): string | null {
            return this.opportunityAssistantState.dealerId;
        },

        dealerTimeZone(): string {
            return dealersStore.dealerById(this.dealerId!)!.timeZone;
        },

        nonEmptyVehicleSummaries(): VehicleSummary[] {
            return this.vehicleSummaries.filter((v) => !isEmptyVehicleSummary(v));
        },

        opportunityTeamReceivers(): (OpportunityTeam | null)[] {
            return this.opportunityAssistantState.opportunityTeamReceivers.map((opportunityTeamId) =>
                opportunityTeamsStore.getOpportunityTeamById(opportunityTeamId)
            );
        },

        source(): OpportunitySource | null {
            if (!this.opportunityAssistantState.sourceId) {
                return null;
            }

            return opportunitySourcesStore.getOpportunitySourceById(this.opportunityAssistantState.sourceId);
        },
    },

    methods: {
        formatInstant(date: Date | null, timeZone: string | null): string | null {
            if (!date || !timeZone) {
                return null;
            }

            return formatInstant(date, timeZone, userSession.locale, "S");
        },

        isBefore(date1: Date, date2: Date): boolean {
            return date1.getTime() < date2.getTime();
        },

        isInFuture(date: Date | null): boolean {
            return !!date && this.isBefore(this.now, date);
        },

        isOpportunityTeamHighlighted(opportunityTeam: OpportunityTeam): boolean {
            if (!this.vehicleSummaries.length || !opportunityTeam.filters.length) {
                return false;
            }

            for (const vehicleSummary of this.vehicleSummaries) {
                for (const filter of opportunityTeam.filters) {
                    if (
                        opportunityTeamFilterMatches(
                            filter,
                            this.opportunityAssistantState.sourceId,
                            this.opportunityAssistantState.contactData?.type ?? null,
                            vehicleSummary,
                            this.opportunityAssistantState.acquisitionType,
                            this.opportunityAssistantState.tradeInRequested
                        )
                    ) {
                        return true;
                    }
                }
            }

            return false;
        },

        isOpportunityTeamOfDealer(opportunityTeam: OpportunityTeam): boolean {
            return opportunityTeam.dealerId === this.dealerId;
        },
    },

    components: {
        IssueDataCheckCard,
        IssueDataCheckCardDataTableRow,
        OpportunityTeamLink,
    },
});
