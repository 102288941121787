
import { IncomingEmail, IncomingEmailAttachment, incomingEmailsApi, IssueId } from "@/api/incomingEmails";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        canDownload: {
            type: Boolean,
            required: true,
        },
        attachment: {
            type: Object as () => IncomingEmailAttachment,
            required: true,
        },
        email: {
            type: Object as () => IncomingEmail,
            required: true,
        },
    },

    data() {
        return {
            image: null as string | null,
        };
    },

    computed: {
        issueId(): IssueId | null {
            return this.email.caseId
                ? { caseId: this.email.caseId }
                : this.email.opportunityId
                ? { opportunityId: this.email.opportunityId }
                : null;
        },
    },

    methods: {
        showAttachment(download: boolean) {
            const fname = this.attachment.filename.toLowerCase();

            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.image = incomingEmailsApi.generateAttachmentLink(this.email.id, this.attachment, this.issueId);
            } else {
                window.open(
                    incomingEmailsApi.generateAttachmentLink(this.email.id, this.attachment, this.issueId, download)
                );
            }
        },

        dragAttachment(event: DragEvent) {
            event.dataTransfer!.setData(
                "text/uri-list",
                new URL(
                    incomingEmailsApi.generateAttachmentLink(this.email.id, this.attachment, this.issueId),
                    document.baseURI
                ).href
            );
        },
    },

    components: {
        ImageCarousel,
    },
});
