
import { CaseNotificationEmailFormat, caseNotificationSettingsApi } from "@/api/caseNotificationSettings";
import { Permission } from "@/api/userSession";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { SelectOptions } from "@/util/types/SelectOptions";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            caseNotificationEmailFormat: null as CaseNotificationEmailFormat | null,
            loading: true,
            notEmpty,
            saving: false,
        };
    },

    computed: {
        caseNotificationEmailFormatOptions(): SelectOptions {
            return [
                ...Object.keys(CaseNotificationEmailFormat).map((k) => ({
                    value: k,
                    text: this.$t(`enum.CaseNotificationEmailFormat.${k}`),
                })),
            ];
        },

        dealerId() {
            return userSession.dealerId;
        },
    },

    methods: {
        async loadCaseNotificationSettings() {
            if (!this.dealerId) {
                return;
            }

            this.loading = true;

            try {
                const caseNotificationSettings = await caseNotificationSettingsApi.getByDealer(this.dealerId);

                this.caseNotificationEmailFormat = caseNotificationSettings.caseNotificationEmailFormat;
            } finally {
                this.loading = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;

            try {
                await caseNotificationSettingsApi.editCaseNotificationSettings({
                    dealerId: this.dealerId!,
                    caseNotificationEmailFormat: this.caseNotificationEmailFormat!,
                });
            } finally {
                this.saving = false;
            }
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadCaseNotificationSettings();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadCaseNotificationSettings();
    },

    components: {
        DealerContextGuard,
    },
});
