
import OpportunityInventoryVehiclePickerCardRow from "./OpportunityInventoryVehiclePickerCardRow.vue";
import { inventoryApi, InventoryVehicle } from "@/api/inventory";
import { isInventoryVehicleStatusNotification, Notification, notificationEventSource } from "@/api/notifications";
import { renderUnit } from "@/app/filters";
import {
    getLastInventoryVehicleStatus,
    renderFirstRegistration,
    renderInventoryVehicleLatestConsumerPrice,
    renderInventoryVehicleTitle,
    renderMileage,
    renderPower,
} from "@/app/inventoryUtils";
import InventoryVehiclePickerDialog from "@/app/pages/inventory/InventoryVehiclePickerDialog.vue";
import { OpportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        opportunityAssistantState: {
            type: Object as () => OpportunityAssistantState,
            required: true,
        },
    },

    data() {
        return {
            getLastInventoryVehicleStatus,
            renderFirstRegistration,
            renderInventoryVehicleLatestConsumerPrice,
            renderInventoryVehicleTitle,
            renderMileage,
            renderPower,
            renderUnit,
            inventoryVehicles: [] as InventoryVehicle[],
            inventoryVehiclePickerDialogVisible: false,
            loading: false,
            notificationHandler: null as ((n: Notification) => void) | null,
        };
    },

    computed: {
        selectedInventoryVehicles(): InventoryVehicle[] {
            return this.inventoryVehicles.filter((iv) => this.selectedVehicleIds.includes(iv.id));
        },

        selectedVehicleIds(): string[] {
            return this.opportunityAssistantState.inventoryVehicles.map((i) => i.inventoryVehicleId);
        },
    },

    methods: {
        getInventoryVehicleById(inventoryVehicleId: string): InventoryVehicle | null {
            return this.inventoryVehicles.find((iv) => iv.id === inventoryVehicleId) ?? null;
        },

        async loadInventoryVehicles() {
            this.loading = true;

            try {
                this.inventoryVehicles = await inventoryApi.getByIds(
                    this.opportunityAssistantState.inventoryVehicles.map((i) => i.inventoryVehicleId)
                );
            } finally {
                this.loading = false;
            }
        },

        removeInventoryVehicle(inventoryVehicleId: string) {
            this.opportunityAssistantState.inventoryVehicles = this.opportunityAssistantState.inventoryVehicles.filter(
                (i) => i.inventoryVehicleId !== inventoryVehicleId
            );
        },

        selectInventoryVehicle(inventoryVehicle: InventoryVehicle) {
            if (this.selectedVehicleIds.includes(inventoryVehicle.id)) {
                return;
            }

            this.opportunityAssistantState.inventoryVehicles.push({
                inventoryVehicleId: inventoryVehicle.id,
                link: null,
            });

            if (!this.inventoryVehicles.some((iv) => iv.id === inventoryVehicle.id)) {
                this.inventoryVehicles.push(inventoryVehicle);
            }
        },
    },

    watch: {
        selectedInventoryVehicles() {
            this.$emit("inventoryVehicles", this.selectedInventoryVehicles);
        },
    },

    async mounted() {
        await this.loadInventoryVehicles();

        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isInventoryVehicleStatusNotification(n)) {
                for (const inventoryVehicle of this.inventoryVehicles) {
                    if (inventoryVehicle.id === n.inventoryVehicleId) {
                        inventoryVehicle.statuses.push(n.inventoryVehicleStatus);
                    }
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        InventoryVehiclePickerDialog,
        OpportunityInventoryVehiclePickerCardRow,
    },
});
