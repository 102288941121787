import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum ActorType {
    USER = "USER",
    CT_USER = "CT_USER",
    SYSTEM = "SYSTEM",
}

export enum NumberBlacklistSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    NUMBER_DESC = "NUMBER_DESC",
    NUMBER_ASC = "NUMBER_ASC",
}

export interface NumberBlacklistEntry {
    readonly number: string;
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly creatorCtUserId: string | null;
    readonly updated: Date;
    readonly blockCalls: boolean;
    readonly blockTextMessages: boolean;
    readonly comment: string | null;
}

export interface NumberBlacklistEntryCreationForm {
    readonly number: string;
    readonly blockCalls: boolean;
    readonly blockTextMessages: boolean;
    readonly comment: string | null;
}

export interface NumberBlacklistEntryForm {
    readonly blockCalls: boolean;
    readonly blockTextMessages: boolean;
    readonly comment: string | null;
}

export interface NumberBlacklistSearchRequest {
    readonly number: string | null;
    readonly creatorActorTypes: ActorType[];
    readonly blockCalls: boolean | null;
    readonly blockTextMessages: boolean | null;
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly sortBy: NumberBlacklistSearchOrder;
}

export interface NumberBlacklistSearchResults {
    readonly results: NumberBlacklistEntry[];
    readonly searchId: number;
    readonly totalSize: number;
}

interface NumbersBlacklistApi {
    add(form: NumberBlacklistEntryCreationForm): Promise<void>;
    delete(number: string): Promise<void>;
    edit(number: string, form: NumberBlacklistEntryForm): Promise<void>;
    getAll(): Promise<NumberBlacklistEntry[]>;
    search(
        start: number,
        size: number,
        searchRequest: NumberBlacklistSearchRequest,
        searchId: number
    ): Promise<NumberBlacklistSearchResults>;
}

export const numbersBlacklistApi: NumbersBlacklistApi = {
    async add(form) {
        await axios.post("/api/numbers-blacklist", form);
    },

    async delete(number) {
        await axios.delete(`/api/numbers-blacklist/${encodeURIComponent(number)}`);
    },

    async edit(number, form) {
        await axios.put(`/api/numbers-blacklist/${encodeURIComponent(number)}`, form);
    },

    async getAll() {
        return cloneObject((await axios.get("/api/numbers-blacklist")).data);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/numbers-blacklist/_search", searchRequest, {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },
};
