import { $t } from "./i18n";
import { userSession } from "@/store/userSession";
import moment, { Moment, unitOfTime } from "moment-timezone";
import { TranslateResult } from "vue-i18n";

interface TimeInterval {
    readonly getText: () => TranslateResult;
    readonly unitOfTime: unitOfTime.StartOf;
    readonly advanceToTimeInterval: (m: Moment) => Moment;
}

export interface TimeIntervalResult {
    readonly start: Date;
    readonly end: Date;
    readonly text: TranslateResult;
}

export interface TimeIntervalGroupedItems<T> {
    readonly timeIntervalResult: TimeIntervalResult | null;
    readonly items: T[];
}

function startOf(m: Moment, startOf: unitOfTime.StartOf) {
    return moment(m).startOf(startOf);
}

function endOf(m: Moment, endOf: unitOfTime.StartOf) {
    return moment(m).endOf(endOf);
}

const timeIntervals: TimeInterval[] = [
    {
        getText: () => $t("Gestern"),
        unitOfTime: "day",
        advanceToTimeInterval: (m: Moment) => m.add(1, "day"),
    },
    {
        getText: () => $t("Heute"),
        unitOfTime: "day",
        advanceToTimeInterval: (m: Moment) => m,
    },
    {
        getText: () => $t("Morgen"),
        unitOfTime: "day",
        advanceToTimeInterval: (m: Moment) => m.subtract(1, "day"),
    },
    {
        getText: () => $t("Letzte Woche"),
        unitOfTime: "week",
        advanceToTimeInterval: (m: Moment) => m.add(1, "week"),
    },
    {
        getText: () => $t("Aktuelle Woche"),
        unitOfTime: "week",
        advanceToTimeInterval: (m: Moment) => m,
    },
    {
        getText: () => $t("Nächste Woche"),
        unitOfTime: "week",
        advanceToTimeInterval: (m: Moment) => m.subtract(1, "week"),
    },
    {
        getText: () => $t("Aktueller Monat"),
        unitOfTime: "month",
        advanceToTimeInterval: (m: Moment) => m,
    },
];

function getTimeIntervalResult<T>(item: T, getDate: (item: T) => Date | null, ts: Date): TimeIntervalResult | null {
    const date = getDate(item);

    if (!date) {
        return null;
    }

    const dateInTimezone = moment(date)
        .tz(userSession.timeZone)
        .locale(userSession.locale);

    const tsInTimezone = moment(ts)
        .tz(userSession.timeZone)
        .locale(userSession.locale);

    for (const timeInterval of timeIntervals) {
        const dateAtStartOfTimeInterval = timeInterval.advanceToTimeInterval(
            startOf(dateInTimezone, timeInterval.unitOfTime)
        );
        const startOfTimeInterval = startOf(tsInTimezone, timeInterval.unitOfTime);

        if (dateAtStartOfTimeInterval.isSame(startOfTimeInterval)) {
            return {
                text: timeInterval.getText(),
                start: startOf(dateInTimezone, timeInterval.unitOfTime).toDate(),
                end: endOf(dateInTimezone, timeInterval.unitOfTime).toDate(),
            };
        }
    }

    return {
        text: dateInTimezone.format($t("MMMM YYYY") as string),
        start: startOf(dateInTimezone, "month").toDate(),
        end: endOf(dateInTimezone, "month").toDate(),
    };
}

function isSortedByDate<T>(items: T[], getDate: (item: T) => Date | null): boolean {
    const dates = items.map(getDate);
    const indexFirstNullDate = dates.indexOf(null);

    for (let index = 1; index < dates.length; ++index) {
        const prevDate = dates[index - 1];
        const curDate = dates[index];

        if (curDate === null && prevDate !== null && index !== indexFirstNullDate) {
            return false;
        } else if (prevDate !== null && curDate !== null) {
            if (prevDate.getTime() > curDate.getTime()) {
                return false;
            }
        }
    }

    return true;
}

export function groupByTimeInterval<T>(
    items: T[],
    getDate: (item: T) => Date | null,
    ts: Date
): TimeIntervalGroupedItems<T>[] {
    if (!items.length) {
        return [];
    }

    if (!isSortedByDate(items, getDate) && !isSortedByDate(items.reverse(), getDate)) {
        return [{ timeIntervalResult: null, items }];
    }

    return items
        .map((item) => ({
            timeIntervalResult: getTimeIntervalResult(item, getDate, ts),
            items: [item],
        }))
        .reduce((prev, cur) => {
            if (!prev.length) {
                return [cur];
            }

            const last = prev[prev.length - 1];

            if (
                last.timeIntervalResult?.start.getTime() === cur.timeIntervalResult?.start.getTime() &&
                last.timeIntervalResult?.end.getTime() === cur.timeIntervalResult?.end.getTime()
            ) {
                last.items.push(...cur.items);
            } else {
                prev.push(cur);
            }

            return prev;
        }, [] as TimeIntervalGroupedItems<T>[]);
}
