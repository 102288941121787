
import { ContactType } from "@/api/contacts";
import { Fuel, UsageType } from "@/api/inventory";
import {
    AcquisitionType,
    OpportunityTeam,
    OpportunityTeamDistributionMethod,
    OpportunityTeamFilter,
    OpportunityTeamForm,
    opportunityTeamsApi,
    OpportunityTeamVisibility,
} from "@/api/opportunityTeams";
import { Permission } from "@/api/userSession";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { renderUnit } from "@/app/filters";
import { getModelOptions } from "@/app/makeModelsUtils";
import { showConfirm, showError } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import { getFullName } from "@/app/userUtils";
import { integer, maxLength, notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { makeModelsStore } from "@/store/makeModels";
import { officeHoursStore } from "@/store/officeHours";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { Mutable, PickMutable, SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

const EMPTY_OPPORTUNITY_TEAM_FILTER: Mutable<OpportunityTeamFilter> = {
    opportunitySourceIds: [],
    contactTypes: [],
    internalIdPrefix: null,
    internalIdSuffix: null,
    vehicleClasses: [],
    makes: [],
    models: [],
    usageTypes: [],
    mileageFrom: null,
    mileageTo: null,
    fuels: [],
    preOffer: null,
    acquisitionTypes: [],
    tradeInRequested: null,
};

type OpportunityTeamFormWithNullableVisibility = Omit<OpportunityTeamForm, "visibility"> & {
    readonly nullableVisibility: OpportunityTeamVisibility | null;
};

export default Vue.extend({
    data() {
        return {
            AcquisitionType,
            ContactType,
            Fuel,
            OpportunityTeamDistributionMethod,
            OpportunityTeamVisibility,
            Permission,
            UsageType,

            getModelOptions,
            maxLength,
            notEmpty,
            renderUnit,

            distributionTimeoutMinutesRules: integer(0),
            escalationTimeMinutesRules: notEmpty().integer(0),
            mileageRules: integer(0),
            vehicleClasses: [] as string[],
        };
    },

    computed: {
        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        filterNullTrueFalseOptions(): SelectOption[] {
            return [
                { value: null, text: this.$t("Beliebig") },
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },

        opportunitySourceOptions(): SelectOption[] {
            return opportunitySourcesStore.opportunitySources.map((s) => ({ value: s.id, text: s.name }));
        },

        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },

        userOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        vehicleClassOptions(): SelectOption[] {
            return this.vehicleClasses
                .map((vc) => ({
                    value: vc,
                    text: this.$t(`vehicle.class.${vc}`).toString(),
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        visibilityOptions(): SelectOptions {
            return [
                { value: null, text: this.$t("Unsichtbar") },
                ...Object.keys(OpportunityTeamVisibility).map((k) => ({
                    value: k,
                    text: this.$t(`enum.OpportunityTeamVisibility.${k}`),
                })),
            ];
        },
    },

    methods: {
        addEscalationMember(form: OpportunityTeamFormWithNullableVisibility) {
            form.escalationMembers.push({
                userId: (null as unknown) as string,
                escalationTimeMinutes: (null as unknown) as number,
            });
        },

        addFilter(form: OpportunityTeamFormWithNullableVisibility) {
            form.filters.push(cloneObject(EMPTY_OPPORTUNITY_TEAM_FILTER));
        },

        async addOpportunityTeam(dealerId: string, form: OpportunityTeamFormWithNullableVisibility) {
            if (!this.validateForm(form)) {
                return false;
            }

            try {
                await opportunityTeamsApi.add(dealerId, {
                    ...form,
                    visibility: form.nullableVisibility || OpportunityTeamVisibility.SELECTED_DEALERS,
                    visibleForDealerIds: form.nullableVisibility ? form.visibleForDealerIds : [],
                });
                return true;
            } catch (e) {
                return false;
            }
        },

        async deleteOpportunityTeam(item: OpportunityTeam) {
            if (
                await showConfirm(
                    this.$t("Verkäuferteam löschen").toString(),
                    this.$t("Sind Sie sicher, dass Sie das Verkäuferteam löschen möchten?").toString()
                )
            ) {
                await opportunityTeamsApi.delete(item.id);
                return true;
            }
            return false;
        },

        async editOpportunityTeam(opportunityTeamid: string, form: OpportunityTeamFormWithNullableVisibility) {
            if (!this.validateForm(form)) {
                return false;
            }

            try {
                await opportunityTeamsApi.edit(opportunityTeamid, {
                    ...form,
                    visibility: form.nullableVisibility || OpportunityTeamVisibility.SELECTED_DEALERS,
                    visibleForDealerIds: form.nullableVisibility ? form.visibleForDealerIds : [],
                });
                return true;
            } catch (e) {
                return false;
            }
        },

        emptyForm(): OpportunityTeamFormWithNullableVisibility {
            return {
                officeHoursId: null,
                name: "",
                notes: null,
                memberUserIds: [],
                escalationMembers: [],
                nullableVisibility: OpportunityTeamVisibility.DEALER,
                visibleForDealerIds: [],
                visibleForAgents: true,
                distributionMethod: OpportunityTeamDistributionMethod.PARALLEL,
                distributionTimeoutMinutes: null,
                onlyDistributeToOnlineUsers: false,
                onlyDistributeToPresentUsers: false,
                filters: [],
                fallbackFilter: false,
                prioritized: false,
            };
        },

        getItemsTranslationText(items: string[], translationSupplier: (key: string) => TranslateResult | string) {
            return items.map((i) => translationSupplier(i)).join(", ");
        },

        getOfficeHoursNameById(officeHoursId: string): string | undefined {
            return officeHoursStore.getOfficeHoursById(officeHoursId)?.name;
        },

        getOfficeHoursOptions(dealerId: string): SelectOptions {
            return officeHoursStore.getOfficeHoursByDealerId(dealerId).map((officeHours) => ({
                value: officeHours.id,
                text: officeHours.name,
            }));
        },

        getOpportunitySourceNameById(opportunitySourceId: string): string | null {
            return opportunitySourcesStore.getOpportunitySourceById(opportunitySourceId)?.name ?? null;
        },

        getOpportunityTeams(dealerId: string) {
            return opportunityTeamsApi.getByDealer(dealerId);
        },

        getUserFullNameById(userId: string) {
            return (getFullName(usersStore.getUserById(userId)) || this.$t("Unbekannt")).toString();
        },

        removeEscalationMember(form: OpportunityTeamFormWithNullableVisibility, index: number) {
            form.escalationMembers.splice(index, 1);
        },

        removeFilter(form: OpportunityTeamFormWithNullableVisibility, index: number) {
            form.filters.splice(index, 1);
        },

        toForm(item: OpportunityTeam): OpportunityTeamFormWithNullableVisibility {
            return cloneObject({
                ...item,
                nullableVisibility:
                    item.visibility === OpportunityTeamVisibility.SELECTED_DEALERS && !item.visibleForDealerIds.length
                        ? null
                        : item.visibility,
            });
        },

        async updateOrder(items: OpportunityTeam[]) {
            await opportunityTeamsApi.updateOrder(
                items[0].dealerId,
                items.map((ot) => ot.id)
            );
        },

        validateForm(form: OpportunityTeamFormWithNullableVisibility) {
            if (form.escalationMembers.length !== new Set(form.escalationMembers.map((m) => m.userId)).size) {
                showError(this.$t("Ein Benutzer darf nicht mehrfach Eskalationsmitglied sein.").toString());
                return false;
            }

            if (
                form.filters.filter((f) => f.mileageFrom && f.mileageTo !== null && f.mileageTo < f.mileageFrom).length
            ) {
                showError(
                    this.$t("Die untere Grenze der Laufleistung übersteigt die gewählte obere Grenze.").toString()
                );
                return false;
            }

            return true;
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)!.name;
        },

        updateModels(filter: PickMutable<OpportunityTeamFilter, "models">) {
            const validModels = makeModelsStore.getModelsByMakes(filter.makes);
            filter.models = filter.models.filter((m) => validModels.includes(m));
        },
    },

    async mounted() {
        this.vehicleClasses = makeModelsStore.vehicleClasses;
    },

    components: {
        CrudPage,
        DAutocomplete,
        DealerContextGuard,
        EnumField,
        MakePicker,
        NumberField,
    },
});
