import axios from "axios";

export interface OutgoingEmailSettings {
    trackEmailOpened: boolean;
    trackLinkClicked: boolean;
}

interface OutgoingEmailSettingsApi {
    getOutgoingEmailSettings(): Promise<OutgoingEmailSettings>;
    editOutgoingEmailSettings(outgoingEmailSettings: OutgoingEmailSettings): Promise<void>;
}

export const outgoingEmailSettingsApi: OutgoingEmailSettingsApi = {
    async getOutgoingEmailSettings() {
        return (await axios.get("/api/outgoing-email-settings")).data;
    },

    async editOutgoingEmailSettings(outgoingEmailSettings) {
        await axios.put("/api/outgoing-email-settings", outgoingEmailSettings);
    },
};
