
import OpportunitySearchBottomSheet from "./OpportunitySearchBottomSheet.vue";
import { EMPTY_SEARCH_REQUEST as EMPTY_OPPORTUNITY_SEARCH_REQUEST } from "./opportunitySearchUtils";
import { getOpportunitiesCountColor, OpportunityInventoryVehicleWithInventoryVehicle } from "./opportunityUtils";
import { InventoryVehicle, InventoryVehicleStatusType } from "@/api/inventory";
import { opportunitiesApi, OpportunityInventoryVehicle, OpportunityStatus } from "@/api/opportunities";
import { OpportunityCountType, opportunitySearchApi, OpportunitySearchRequest } from "@/api/opportunitySearch";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { renderUnit } from "@/app/filters";
import {
    getLastInventoryVehicleStatus,
    getStatusColor,
    renderFirstRegistration,
    renderInventoryVehicleLatestConsumerPrice,
    renderInventoryVehicleTitle,
    renderMileage,
    renderPower,
} from "@/app/inventoryUtils";
import { showConfirm } from "@/app/messageUtil";
import { renderExternalInventoryManagementVehicleLink } from "@/app/pages/externalinventorymanagementsettings/externalInventoryManagementPlaceholders";
import InventoryVehicleImageAndCarousel, {
    InventoryVehicleImage,
} from "@/app/pages/inventory/InventoryVehicleImageAndCarousel.vue";
import InventoryVehicleStatusFormDialog from "@/app/pages/inventory/InventoryVehicleStatusFormDialog.vue";
import { dealersStore } from "@/store/dealers";
import { externalInventoryManagementSettingsStore } from "@/store/externalInventoryManagementSettings";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canEdit: {
            type: Boolean,
            required: true,
        },
        contactId: {
            type: String,
            required: false,
        },
        opportunityId: {
            type: String,
            required: true,
        },
        opportunityInventoryVehicleWithInventoryVehicleProp: {
            type: Object as () => OpportunityInventoryVehicleWithInventoryVehicle,
            required: true,
        },
    },

    data() {
        return {
            getLastInventoryVehicleStatus,
            renderFirstRegistration,
            renderInventoryVehicleLatestConsumerPrice,
            renderInventoryVehicleTitle,
            renderMileage,
            renderPower,
            renderUnit,
            edit: false,
            link: null as string | null,
            opportunityInventoryVehicleWithInventoryVehicle: this.opportunityInventoryVehicleWithInventoryVehicleProp,
            saving: false,
            statusDialogVisible: false,
            wasIntersected: false,
            loadingOpportunityStatusCounts: false,
            otherOpportunitiesCount: 0,
            otherOpportunitiesCountColor: null as string | null,
            opportunitySearchBottomSheetVisible: false,
        };
    },

    computed: {
        canViewOpportunities(): boolean {
            return (
                userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES) ||
                userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES)
            );
        },

        dealerName(): string | null {
            if (!this.inventoryVehicle) {
                return null;
            }

            return dealersStore.dealerById(this.inventoryVehicle.dealerId)?.name ?? null;
        },

        externalInventoryManagementVehicleLink(): string | null {
            if (!this.inventoryVehicle) {
                return null;
            }

            return renderExternalInventoryManagementVehicleLink(
                externalInventoryManagementSettingsStore.settings.vehicleLinkPattern,
                this.inventoryVehicle.data
            );
        },

        externalInventoryManagementVehicleTooltip(): string | null {
            return externalInventoryManagementSettingsStore.settings.vehicleLinkLabel;
        },

        inventoryVehicle(): InventoryVehicle | null {
            return this.opportunityInventoryVehicleWithInventoryVehicle.inventoryVehicle;
        },

        inventoryVehicleImages(): InventoryVehicleImage[] {
            if (!this.inventoryVehicle) {
                return [];
            }

            return this.inventoryVehicle.data.images.map((image) => ({
                inventoryVehicleId: this.inventoryVehicle!.id,
                image,
            }));
        },

        inventoryVehicleStatus(): InventoryVehicleStatusType | null {
            if (!this.inventoryVehicle) {
                return null;
            }

            return getLastInventoryVehicleStatus(this.inventoryVehicle);
        },

        inventoryVehicleStatusColor(): string | null {
            if (!this.inventoryVehicleStatus) {
                return null;
            }

            return getStatusColor(this.inventoryVehicleStatus);
        },

        opportunityInventoryVehicle(): OpportunityInventoryVehicle {
            return this.opportunityInventoryVehicleWithInventoryVehicle.opportunityInventoryVehicle;
        },

        opportunitySearchRequest(): OpportunitySearchRequest {
            return {
                ...cloneObject(EMPTY_OPPORTUNITY_SEARCH_REQUEST),
                inventoryVehicleIds: [this.inventoryVehicle!.id],
            };
        },
    },

    methods: {
        async createInventoryInquiry() {
            if (!this.inventoryVehicle) {
                return;
            }

            if (
                await showConfirm(
                    this.$t("Suchauftrag erstellen") as string,
                    this.$t(
                        "Alle Daten, die aktuell im Suchauftrag-Assistenten gespeichert sind, gehen verloren. Möchten Sie fortfahren?"
                    ) as string
                )
            ) {
                const params = {
                    iv: this.inventoryVehicle.id,
                    c: this.contactId,
                };

                const query = Object.entries(params)
                    .filter((e) => !!e[1])
                    .map((e) => `${e[0]}=${e[1]}`)
                    .join("&");

                await this.$router.push("/inventory-vehicle-inquiry-assistant?" + query);
            }
        },

        async loadOpportunityStatusCounts(_: unknown, __: unknown, isIntersected: boolean) {
            if (this.wasIntersected || !isIntersected || !this.inventoryVehicle || !this.canViewOpportunities) {
                return;
            }

            this.wasIntersected = true;
            this.loadingOpportunityStatusCounts = true;

            try {
                const otherOpportunitiesCounts = await opportunitySearchApi.getCounts(
                    {
                        ...this.opportunitySearchRequest,
                        excludedOpportunityIds: [this.opportunityId],
                    },
                    [OpportunityCountType.STATUS],
                    false
                );

                this.otherOpportunitiesCount = otherOpportunitiesCounts.reduce((sum, cur) => sum + cur.count, 0);
                this.otherOpportunitiesCountColor = getOpportunitiesCountColor(
                    otherOpportunitiesCounts.map((oc) => oc.value as OpportunityStatus)
                );
            } finally {
                this.loadingOpportunityStatusCounts = false;
            }
        },

        async save() {
            this.saving = true;
            try {
                await opportunitiesApi.updateInventoryVehicle(
                    this.opportunityId,
                    this.opportunityInventoryVehicle.inventoryVehicleId,
                    this.link
                );

                // update locally before remote update arrives
                this.opportunityInventoryVehicleWithInventoryVehicle = {
                    opportunityInventoryVehicle: {
                        ...this.opportunityInventoryVehicle,
                        link: this.link,
                    },
                    inventoryVehicle: this.inventoryVehicle,
                };

                this.edit = false;
            } finally {
                this.saving = false;
            }
        },

        showInExternalInventoryManagement() {
            if (!this.externalInventoryManagementVehicleLink) {
                return;
            }

            window.open(this.externalInventoryManagementVehicleLink);
        },

        switchToEditMode() {
            this.edit = true;
            this.link = this.opportunityInventoryVehicle.link;
        },
    },

    watch: {
        opportunityInventoryVehicleWithInventoryVehicleProp() {
            this.opportunityInventoryVehicleWithInventoryVehicle = this.opportunityInventoryVehicleWithInventoryVehicleProp;
        },
    },

    components: {
        CopyToClipboardIcon,
        InventoryVehicleImageAndCarousel,
        InventoryVehicleStatusFormDialog,
        OpportunitySearchBottomSheet,
    },
});
