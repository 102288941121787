import { UsageType } from "./inventory";
import {
    OpportunityChannel,
    OpportunityPriority,
    OpportunityResult,
    OpportunityStatus,
    OpportunityUrgency,
} from "./opportunities";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum ActorType {
    USER = "USER",
    CT_USER = "CT_USER",
    SYSTEM = "SYSTEM",
}

export enum OpportunityCountType {
    DEALER_ID = "DEALER_ID",
    SOURCE_ID = "SOURCE_ID",
    STATUS = "STATUS",
    CHANNEL = "CHANNEL",
    SENTIMENT_TYPE = "SENTIMENT_TYPE",
    URGENCY = "URGENCY",
    PRIORITY = "PRIORITY",
    CREATOR_ACTOR_TYPE = "CREATOR_ACTOR_TYPE",
    CREATED_BY = "CREATED_BY",
    CLOSER_ACTOR_TYPE = "CLOSER_ACTOR_TYPE",
    CLOSED_BY = "CLOSED_BY",
    OUTCOME_REASON_ID = "OUTCOME_REASON_ID",
    ASSIGNEE = "ASSIGNEE",
    INDIVIDUAL_RECEIVER = "INDIVIDUAL_RECEIVER",
    OPPORTUNITY_TEAM_RECEIVER = "OPPORTUNITY_TEAM_RECEIVER",
    MAKE = "MAKE",
    MODEL = "MODEL",
    USAGE_TYPE = "USAGE_TYPE",
}

export enum OpportunitySearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    LAST_ACTIVITY_DESC = "LAST_ACTIVITY_DESC",
    LAST_ACTIVITY_ASC = "LAST_ACTIVITY_ASC",
    POSTPONED_UNTIL_DESC = "POSTPONED_UNTIL_DESC",
    POSTPONED_UNTIL_ASC = "POSTPONED_UNTIL_ASC",
    CLOSED_DESC = "CLOSED_DESC",
    CLOSED_ASC = "CLOSED_ASC",
    PRIORITY_DESC = "PRIORITY_DESC",
}

export enum SentimentType {
    UNKNOWN = "UNKNOWN",
    POSITIVE = "POSITIVE",
    NEUTRAL = "NEUTRAL",
    NEGATIVE = "NEGATIVE",
}

export interface OpportunityCount {
    readonly type: OpportunityCountType;
    readonly value: string | null;
    readonly count: number;
}

export interface OpportunitySearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

export interface OpportunitySearchRequest {
    readonly opportunityIds: string[];
    readonly excludedOpportunityIds: string[];
    readonly dealerIds: string[];
    readonly sourceIds: string[];
    readonly status: OpportunityStatus[];
    readonly channels: OpportunityChannel[];
    readonly sentimentTypes: SentimentType[];
    readonly urgencies: OpportunityUrgency[];
    readonly priority: OpportunityPriority[];
    readonly creatorActorType: ActorType[];
    readonly createdBy: string[];
    readonly closerActorType: ActorType[];
    readonly closedBy: string[];
    readonly outcome: boolean | null;
    readonly outcomeReasonIds: (string | null)[];
    readonly assignees: (string | null)[];
    readonly contactIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly lastActivityFrom: Date | null;
    readonly lastActivityTo: Date | null;
    readonly closedFrom: Date | null;
    readonly closedTo: Date | null;
    readonly individualReceivers: (string | null)[];
    readonly opportunityTeamReceivers: (string | null)[];
    readonly hasReceivers: boolean | null;
    readonly inventoryVehicleIds: (string | null)[];
    readonly makes: string[];
    readonly models: string[];
    readonly usageTypes: UsageType[];
    readonly externalReference: string | null;
    readonly search: string | null;
    readonly sortBy: OpportunitySearchOrder;
}

export interface OpportunitySearchResult {
    readonly opportunityId: string;
    readonly opportunityResult: OpportunityResult | null;
}

export interface OpportunitySearchResults {
    readonly searchId: number;
    readonly totalSize: number;
    readonly results: OpportunitySearchResult[];
}

interface OpportunitySearchApi {
    downloadCsv(searchRequest: OpportunitySearchRequest): Promise<File>;
    getCounts(
        searchRequest: OpportunitySearchRequest,
        opportunityCountTypes: OpportunityCountType[],
        myOpportunities: boolean
    ): Promise<OpportunityCount[]>;
    rebuild(): Promise<void>;
    search(
        start: number,
        size: number,
        searchRequest: OpportunitySearchRequest,
        searchId: number,
        myOpportunities: boolean
    ): Promise<OpportunitySearchResults>;
    size(): Promise<OpportunitySearchIndexSize>;
}

export const opportunitySearchApi: OpportunitySearchApi = {
    async downloadCsv(searchRequest) {
        const response = await axios.post("/api/opportunity-search/_csv", searchRequest, { responseType: "blob" });

        return new File(
            [response.data],
            response.headers["content-disposition"].replace("attachment; filename=", "").trim(),
            {
                type: response.headers["content-type"],
            }
        );
    },

    async getCounts(searchRequest, opportunityCountTypes, myOpportunities) {
        return (
            await axios.post("/api/opportunity-search/_counts", searchRequest, {
                params: {
                    opportunityCountType: opportunityCountTypes.join(","),
                    myOpportunities,
                },
            })
        ).data;
    },

    async rebuild() {
        await axios.post(`/api/opportunity-search/_rebuild`);
    },

    async search(start, size, searchRequest, searchId, myOpportunities) {
        return cloneObject(
            (
                await axios.post("/api/opportunity-search", searchRequest, {
                    params: {
                        start,
                        size,
                        searchId,
                        myOpportunities,
                    },
                })
            ).data
        );
    },

    async size() {
        return (await axios.get(`/api/opportunity-search/_size`)).data;
    },
};
