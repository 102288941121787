
import { outgoingCallAssistantState } from "./outgoingCallAssistantState";
import { ContactData } from "@/api/contacts";
import { OutgoingCallForm, outgoingCallsApi } from "@/api/outgoingCalls";
import { showConfirm } from "@/app/messageUtil";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import ContactAndVehiclePicker from "@/app/pages/contacts/ContactAndVehiclePicker.vue";
import OutgoingCallFormCard from "@/app/pages/outgoingcalls/OutgoingCallFormCard.vue";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            calling: false,
            contactData: null as ContactData | null,
            defaultReceiver: null as string | null,
            outgoingCallAssistantState,
        };
    },

    computed: {
        defaultCountry(): string {
            return configStore.configuration.defaultCountry;
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        outboundNumbers(): string[] {
            return (this.contactData?.numbers ?? []).map((n) => n.number);
        },

        working(): boolean {
            return this.calling;
        },
    },

    methods: {
        async redirectToMyActivities() {
            await this.$router.push("/my-activities?t=1");
        },

        async reset() {
            if (
                await showConfirm(
                    this.$t("Eingaben zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?") as string
                )
            ) {
                outgoingCallAssistantState.reset();
                this.defaultReceiver = null;
            }
        },

        async startCall(outgoingCallForm: OutgoingCallForm) {
            this.calling = true;

            try {
                await outgoingCallsApi.startCall(
                    outgoingCallAssistantState.contactAndVehicleId?.contactId ?? null,
                    this.dealerId!,
                    outgoingCallForm
                );
                outgoingCallAssistantState.reset();
                await this.$router.push("/my-activities?t=1");
            } finally {
                this.calling = false;
            }
        },

        async submit() {
            await (this.$refs.outgoingCallFormCard as any).submit();
        },
    },

    components: {
        ContactAndVehiclePicker,
        DealerContextWarning,
        OutgoingCallFormCard,
    },
});
