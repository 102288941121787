
import Vue from "vue";

export default Vue.extend({
    props: {
        images: Array as () => (string | Blob)[],
    },

    data() {
        return {
            image: 0,
            keydownListener: null as ((e: KeyboardEvent) => void) | null,
            urls: [] as string[],
            urlsToRevoke: [] as string[],
        };
    },

    beforeMount() {
        this.keydownListener = (e) => {
            if (e.key === "Escape") {
                this.$emit("close");
            } else if (e.key === "ArrowLeft") {
                this.image--;
            } else if (e.key === "ArrowRight") {
                this.image++;
            }
        };
        document.addEventListener("keydown", this.keydownListener);

        for (const image of this.images) {
            if (typeof image === "string") {
                this.urls.push(image);
            } else {
                const url = URL.createObjectURL(image);
                this.urls.push(url);
                this.urlsToRevoke.push(url);
            }
        }
    },

    beforeDestroy() {
        this.urlsToRevoke.forEach((url) => URL.revokeObjectURL(url));
        document.removeEventListener("keydown", this.keydownListener!);
    },
});
