import axios from "axios";

export interface AutoCrmLeadMailerSettings {
    readonly autoCrmEmailAddress: string | null;
    readonly source: string | null;
}

export interface AutoCrmLeadMailerUserSettings {
    readonly userId: string;
    readonly emailAddress: string | null;
    readonly categories: string[];
}

interface AutoCrmLeadMailerSettingsApi {
    editSettings(dealerId: string, settings: AutoCrmLeadMailerSettings): Promise<void>;
    editUserSettings(userSettings: AutoCrmLeadMailerUserSettings): Promise<void>;
    getSettingsByDealer(dealerId: string): Promise<AutoCrmLeadMailerSettings>;
    getUserSettings(): Promise<AutoCrmLeadMailerUserSettings[]>;
    isConfigured(dealerId: string): Promise<boolean>;
}

export const autoCrmLeadMailerSettingsApi: AutoCrmLeadMailerSettingsApi = {
    async editSettings(dealerId, settings): Promise<void> {
        await axios.put(`/api/auto-crm-lead-mailer-settings/${dealerId}`, settings);
    },

    async editUserSettings(userSettings): Promise<void> {
        await axios.put("/api/auto-crm-lead-mailer-settings/_/users", userSettings);
    },

    async getSettingsByDealer(dealerId): Promise<AutoCrmLeadMailerSettings> {
        return (await axios.get(`/api/auto-crm-lead-mailer-settings/${dealerId}`)).data;
    },

    async getUserSettings(): Promise<AutoCrmLeadMailerUserSettings[]> {
        return (await axios.get("/api/auto-crm-lead-mailer-settings/_/users")).data;
    },

    async isConfigured(dealerId): Promise<boolean> {
        return (await axios.get(`/api/auto-crm-lead-mailer-settings/${dealerId}/configured`)).data;
    },
};
