import { inventoryApi, InventoryVehicle } from "@/api/inventory";
import { getFile, readFileAsText } from "@/app/fileUtils";
import { renderUnit } from "@/app/filters";
import { $t } from "@/app/i18n";
import {
    getInventoryVehicleLatestPrice,
    renderFirstRegistration,
    renderInventoryVehicleLatestConsumerPrice,
    renderInventoryVehicleTitle,
    renderMileage,
    renderPower,
} from "@/app/inventoryUtils";

function renderEmailExposeItemTemplate(
    imageB64: string | null,
    title: string | null,
    shortDescription: string | null,
    renderedLatestPrice: string | null,
    vatIncluded: boolean,
    internalId: string | null,
    locale: string
) {
    return `
<div style="margin: 5px 0; padding: 5px; border: 1px solid #aaaaaa; font-size: 14px; max-width: 800px;">
<table cellpadding="0" cellspacing="0" style="border: 0; width: 100%; ">
    <tbody>
        <tr>
            <td rowspan="2" style="vertical-align: top; line-height: 0; width: 120px;">
                ${imageB64 ? `<img style="width: 100%" src="data:image/jpeg;base64,${imageB64}" />` : ""}
            </td>
            <td rowspan="2" style="vertical-align: top;">
                <div style="margin-left: 10px">
                    <div><strong>${title}</strong></div>
                    ${shortDescription ? `<div style="margin-top: 10px;">${shortDescription}</div>` : ""}
                </div>
            </td>
            <td style="text-align: right; vertical-align: top; width: 180px;">
                <div style="margin-left: 5px; margin-bottom: 5px;">
                    <div><strong>${$t("Preis", locale)}:</strong> ${renderedLatestPrice}</div>
                    ${
                        vatIncluded
                            ? `<div style="margin-top: 2px; font-size: 12px">(${$t("inkl. MwSt.", locale)})</div>`
                            : ""
                    }
                </div>
            </td>
        </tr>
        <tr>
            <td style="text-align: right; vertical-align: bottom;">
                <div style="margin-left: 5px; margin-top: 3px;">
                    ${
                        internalId
                            ? `<div style="margin-top: 2px; font-size: 12px">${$t(
                                  "Angebotsnummer",
                                  locale
                              )}: ${internalId}</div>`
                            : ""
                    }
                </div>
                <div style="margin-top: 2px; font-size: 12px; text-align: right;">${$t(
                    "Exposé im Anhang",
                    locale
                )}</div>
            </td>
        </tr>
    </tbody>
</table>
</div>`.trim();
}

export async function renderEmailExposeItem(inventoryVehicle: InventoryVehicle, locale: string) {
    let imageB64 = null as string | null;

    if (inventoryVehicle.data.images.length) {
        const imageLink = inventoryApi.generateVehicleImageAsBase64Link(
            inventoryVehicle.id,
            inventoryVehicle.data.images[0],
            120
        );
        const imageFile = await getFile(imageLink);

        if (imageFile) {
            try {
                imageB64 = await readFileAsText(imageFile);
            } catch (e) {
                // ignore
            }
        }
    }

    const shortDescriptionParts = [];

    if (inventoryVehicle.data.category) {
        shortDescriptionParts.push(
            $t("Kategorie", locale) + ": " + $t(`vehicle.category.${inventoryVehicle.data.category}`, locale)
        );
    }

    if (inventoryVehicle.data.usageType) {
        shortDescriptionParts.push(
            $t("Zustand", locale) + ": " + $t(`enum.UsageType.${inventoryVehicle.data.usageType}`, locale)
        );
    }

    if (inventoryVehicle.data.mileage) {
        shortDescriptionParts.push($t("Laufleistung", locale) + ": " + renderMileage(inventoryVehicle));
    }

    const firstRegistration = renderFirstRegistration(inventoryVehicle);
    if (firstRegistration) {
        shortDescriptionParts.push($t("Erstzulassung", locale) + ": " + firstRegistration);
    }

    if (inventoryVehicle.data.fuel) {
        shortDescriptionParts.push(
            $t("Kraftstoffart", locale) + ": " + $t(`enum.Fuel.${inventoryVehicle.data.fuel}`, locale)
        );
    }

    if (inventoryVehicle.data.power !== null) {
        shortDescriptionParts.push($t("Leistung", locale) + ": " + renderPower(inventoryVehicle.data.power));
    }

    if (inventoryVehicle.data.cubicCapacity) {
        shortDescriptionParts.push(
            $t("Hubraum", locale) + ": " + renderUnit(inventoryVehicle.data.cubicCapacity, "cm³")
        );
    }

    if (inventoryVehicle.data.exteriorColor) {
        shortDescriptionParts.push(
            $t("Farbe", locale) + ": " + $t(`enum.ExteriorColor.${inventoryVehicle.data.exteriorColor}`, locale)
        );
    }

    if (inventoryVehicle.data.generalInspection) {
        shortDescriptionParts.push(
            $t("Hauptuntersuchung", locale) + ": " + inventoryVehicle.data.generalInspection.trim()
        );
    }

    return renderEmailExposeItemTemplate(
        imageB64,
        renderInventoryVehicleTitle(inventoryVehicle),
        shortDescriptionParts.join(", "),
        renderInventoryVehicleLatestConsumerPrice(inventoryVehicle, "?"),
        !!getInventoryVehicleLatestPrice(inventoryVehicle)?.vatRate,
        inventoryVehicle.data.internalId,
        locale
    );
}
