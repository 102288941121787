/* eslint-disable prettier/prettier */
export default {
    "validation": {
        "contact-upload-locked": "Es läuft bereits ein Vorgang, bitte versuchen Sie es später erneut.",
        "contact-duplicate-removal-locked": "Die Kontaktdublettenbereinigung läuft bereits.",
        "inventory-import-locked": "Es läuft bereits ein Vorgang, bitte versuchen Sie es später erneut.",
        "dealer-department-assignment-constraint-violation": "Jeder Standort und jede Abteilung darf dem Benutzer jeweils nur ein mal zugeordnet werden und der Hauptstandort muss zugeordnet werden.",
        "duplicate-username": "E-Mail-Adresse ist bereits vergeben",
        "duplicate-alias": "E-Mail-Alias ist bereits vergeben",
        "incoming-phone-number-in-use": "Rufnummer befindet sich noch in Verwendung",
        "duplicate-exceptional-day-date": "Ausnahme für dieses Datum existiert bereits",
        "email-signature-length-exceeded": "Die E-Mail-Signatur ist zu groß",
        "invalid-ip-range": "IP-Bereich ist ungültig",
        "password-wrong": "Das eingegebene Passwort ist nicht korrekt",
        "domain-already-in-use": "Die Domain wird bereits verwendet",
        "attachments-too-large": "Die Anhänge sind zu groß.",
        "forbidden-receiver": "Die folgenden Empfänger dürfen nicht kontaktiert werden: {0}.",
        "illegal-attachment-type": "Unerlaubter Dateityp im Anhang.",
        "illegal-receiver": "Die folgenden Empfänger sing ungültig und können nicht kontaktiert werden: {0}.",
        "message-body-too-large": "Die Nachricht ist zu lang.",
        "pending-sender-domain": "Die Sender-Domain wurde noch nicht bestätigt.",
        "too-many-login-attempts": "Die Captcha-Validierung ist fehlgeschlagen. Bitte versuchen Sie es erneut.",
        "duplicate-system-alias-local-part": "E-Mail-Alias ist bereits vergeben",
        "cannot-assign-to-self": "Sie können die Verkaufschance derzeit nicht übernehmen.",
        "password-policy": {
            "TOO_SHORT": "Das Passwort muss {minimumLength} oder mehr Zeichen lang sein.",
            "INSUFFICIENT_UPPERCASE": "Das Passwort muss {minimumRequired} oder mehr Großbuchstaben enthalten.",
            "INSUFFICIENT_LOWERCASE": "Das Passwort muss {minimumRequired} oder mehr Kleinbuchstaben enthalten.",
            "INSUFFICIENT_DIGIT": "Das Passwort muss {minimumRequired} oder mehr Ziffern enthalten.",
            "INSUFFICIENT_SPECIAL": "Das Passwort muss {minimumRequired} oder mehr Sonderzeichen enthalten.",
            "ILLEGAL_WHITESPACE": "Das Passwort enthält ein Leerzeichen.",
            "ILLEGAL_WORD": "Das Passwort enthält das verbotene Wort \"{matchingWord}\"."
        },
        "duplicate-whatsapp-account": "Das WhatsApp-Konto wurde bereits hinzugefügt.",
        "invalid-whatsapp-api-key": "Der API-Schlüssel ist ungültig.",
        "invalid-sms-destination-number": "An diese Nummer kann keine SMS geschickt werden.",
        "xml-schema-validation-failed": "XML-Validierung fehlgeschlagen: \"{0}\".",
        "finance-contract-upload-locked": "Es läuft bereits ein Vorgang, bitte versuchen Sie es später erneut.",
        "ftp-connection-failed": "Die Verbindung zum FTP-Server konnte nicht aufgebaut werden.",
        "invalid-whatsapp-contact": "An diese Nummer kann keine WhatsApp-Nachricht geschickt werden.",
        "whatsapp-unavailable": "WhatsApp for Business ist derzeit nicht verfügbar. Bitte versuchen Sie es später erneut.",
        "too-many-check-attempts": "Sie haben zu oft einen ungültigen Token eingegeben.",
        "no-channel-selected": "Sie müssen genau eine neue Nummer auswählen.",
        "duplicate-blacklisted-number": "Es existiert bereits eine Rufnummernsperre für diese Rufnummer.",
        "number-is-blocked-for-calls": "Die Rufnummer {0} ist für eingehende und ausgehende Anrufe gesperrt.",
        "number-is-blocked-for-text-messages": "Die Rufnummer {0} ist für den Versand und Empfang von Textnachrichten gesperrt.",
        "duplicate-external-bdc-target-number": "Die Zielrufnummer wird bereits von einem anderen Händler verwendet.",
        "duplicate-citnow-video-lid": "Die LID ist bereits für diesen Standort konfiguriert.",
        "renault-race-api-error": "Der Zugriff auf die Renault-RACE-API ist fehlgeschlagen.",
        "invalid-case-status": "Die Aktion kann nicht ausgeführt werden, da sich der Fall in einem ungültigen Status befindet.",
        "case-outcome-reason-required": "Es muss ein Grund angegeben werden.",
        "invalid-opportunity-status": "Die Aktion kann nicht ausgeführt werden, da sich die Verkaufschance in einem ungültigen Status befindet.",
        "opportunity-outcome-reason-required": "Es muss ein Grund angegeben werden.",
        "invalid-emergency-incident-status": "Die Aktion kann nicht ausgeführt werden, da sich der Notdienstvorgang in einem ungültigen Status befindet.",
        "whatsapp-media-too-large": "Die Datei überschreitet die Größenbeschränkung von WhatsApp für diesen Dateityp.",
        "unsupported-whatsapp-media-type": "Der Dateityp wird von WhatsApp nicht unterstützt."
    },
    "locale": {
        "de": "Deutsch (Deutschland)",
        "de-CH": "Deutsch (Schweiz)",
        "en": "English (US)"
    },
    "localeFlag": {
        "de": "de",
        "de-CH": "ch",
        "en": "us"
    },
    "language": {
        "aa": "Afar",
        "ab": "Abchasisch",
        "ae": "Avestisch",
        "af": "Afrikaans",
        "ak": "Akan",
        "am": "Amharisch",
        "an": "Aragonesisch",
        "ar": "Arabisch",
        "as": "Assamesisch",
        "av": "Avarisch",
        "ay": "Aymara",
        "az": "Aserbaidschanisch",
        "ba": "Baschkirisch",
        "be": "Weißrussisch",
        "bg": "Bulgarisch",
        "bh": "Bihari",
        "bi": "Bislama",
        "bm": "Bambara",
        "bn": "Bengalisch",
        "bo": "Tibetisch",
        "br": "Bretonisch",
        "bs": "Bosnisch",
        "ca": "Katalanisch",
        "ce": "Tschetschenisch",
        "ch": "Chamorro",
        "co": "Korsisch",
        "cr": "Cree",
        "cs": "Tschechisch",
        "cu": "Kirchenslawisch",
        "cv": "Tschuwaschisch",
        "cy": "Walisisch",
        "da": "Dänisch",
        "de": "Deutsch",
        "dv": "Dhivehi",
        "dz": "Dzongkha",
        "ee": "Ewe",
        "el": "Griechisch",
        "en": "Englisch",
        "eo": "Esperanto",
        "es": "Spanisch",
        "et": "Estnisch",
        "eu": "Baskisch",
        "fa": "Persisch",
        "ff": "Fulfulde",
        "fi": "Finnisch",
        "fj": "Fidschi",
        "fo": "Färöisch",
        "fr": "Französisch",
        "fy": "Westfriesisch",
        "ga": "Irisch",
        "gd": "Schottisch-gälisch",
        "gl": "Galicisch",
        "gn": "Guaraní",
        "gu": "Gujarati",
        "gv": "Manx",
        "ha": "Hausa",
        "he": "Hebräisch",
        "hi": "Hindi",
        "ho": "Hiri Motu",
        "hr": "Kroatisch",
        "ht": "Haitianisch",
        "hu": "Ungarisch",
        "hy": "Armenisch",
        "hz": "Otjiherero",
        "ia": "Interlingua",
        "id": "Indonesisch",
        "ie": "Interlingue",
        "ig": "Igbo",
        "ii": "Yi",
        "ik": "Inupiaq",
        "io": "Ido",
        "is": "Isländisch",
        "it": "Italienisch",
        "iu": "Inuktitut",
        "ja": "Japanisch",
        "jv": "Javanisch",
        "ka": "Georgisch",
        "kg": "Kikongo",
        "ki": "Kikuyu",
        "kj": "oshiKwanyama",
        "kk": "Kasachisch",
        "kl": "Grönländisch",
        "km": "Khmer",
        "kn": "Kannada",
        "ko": "Koreanisch",
        "kr": "Kanuri",
        "ks": "Kashmiri",
        "ku": "Kurdisch",
        "kv": "Komi",
        "kw": "Kornisch",
        "ky": "Kirgisisch",
        "la": "Latein",
        "lb": "Luxemburgisch",
        "lg": "Luganda",
        "li": "Limburgisch",
        "ln": "Lingála",
        "lo": "Laotisch",
        "lt": "Litauisch",
        "lu": "Kiluba",
        "lv": "Lettisch",
        "mg": "Malagasy",
        "mh": "Marshallesisch",
        "mi": "Maori",
        "mk": "Mazedonisch",
        "ml": "Malayalam",
        "mn": "Mongolisch",
        "mr": "Marathi",
        "ms": "Malaiisch",
        "mt": "Maltesisch",
        "my": "Birmanisch",
        "na": "Nauruisch",
        "nb": "Bokmål",
        "nd": "Nord-Ndebele",
        "ne": "Nepali",
        "ng": "Ndonga",
        "nl": "Niederländisch",
        "nn": "Nynorsk",
        "no": "Norwegisch",
        "nr": "Süd-Ndebele",
        "nv": "Navajo",
        "ny": "Chichewa",
        "oc": "Okzitanisch",
        "oj": "Ojibwe",
        "om": "Oromo",
        "or": "Oriya",
        "os": "Ossetisch",
        "pa": "Panjabi",
        "pi": "Pali",
        "pl": "Polnisch",
        "ps": "Paschtunisch",
        "pt": "Portugiesisch",
        "qu": "Quechua",
        "rm": "Bündnerromanisch",
        "rn": "Kirundi",
        "ro": "Rumänisch",
        "ru": "Russisch",
        "rw": "Kinyarwanda",
        "sa": "Sanskrit",
        "sc": "Sardisch",
        "sd": "Sindhi",
        "se": "Nordsamisch",
        "sg": "Sango",
        "si": "Singhalesisch",
        "sk": "Slowakisch",
        "sl": "Slowenisch",
        "sm": "Samoanisch",
        "sn": "Shona",
        "so": "Somali",
        "sq": "Albanisch",
        "sr": "Serbisch",
        "ss": "Siswati",
        "st": "Sesotho",
        "su": "Sundanesisch",
        "sv": "Schwedisch",
        "sw": "Swahili",
        "ta": "Tamil",
        "te": "Telugu",
        "tg": "Tadschikisch",
        "th": "Thai",
        "ti": "Tigrinya",
        "tk": "Turkmenisch",
        "tl": "Tagalog",
        "tn": "Setswana",
        "to": "Tongaisch",
        "tr": "Türkisch",
        "ts": "Xitsonga",
        "tt": "Tatarisch",
        "tw": "Twi",
        "ty": "Tahitianisch",
        "ug": "Uigurisch",
        "uk": "Ukrainisch",
        "ur": "Urdu",
        "uz": "Usbekisch",
        "ve": "Tshivenda",
        "vi": "Vietnamesisch",
        "vo": "Volapük",
        "wa": "Wallonisch",
        "wo": "Wolof",
        "xh": "isiXhosa",
        "yi": "Jiddisch",
        "yo": "Yoruba",
        "za": "Zhuang",
        "zh": "Chinesisch",
        "zu": "isiZulu"
    },
    "caseTypeInitialNoteTemplate": {
        "WORKSHOP_APPOINTMENT": "Der Kontakt wünscht einen Werkstatttermin für das genannte Fahrzeug.",
        "INSPECTION_APPOINTMENT": "Der Kontakt wünscht einen Inspektionstermin für das genannte Fahrzeug.",
        "TYRE_CHANGE_APPOINTMENT": "Der Kontakt wünscht einen Reifenwechseltermin für das genannte Fahrzeug.",
        "LEGAL_INSPECTION_APPOINTMENT": "Der Kontakt wünscht einen HU-Termin für das genannte Fahrzeug.",
        "APPOINTMENT_POSTPONEMENT": "Der Kontakt hat einen Termin mit Ihnen vereinbart und würde diesen gerne verschieben. Termin: ",
        "APPOINTMENT_CANCELLATION": "Der Kontakt muss den vereinbarten Termin absagen. Termin: ",
        "SERVICE_STATUS": "Der Kontakt wünscht eine Auskunft zum aktuellen Reparaturstand für das genannte Fahrzeug.",
        "TECHNICAL_QUESTION": "Der Kontakt hat eine technische Frage: ",
        "SERVICE_AUTHORIZATION": "Der Kontakt hat eine Rückfrage: ",
        "SERVICE_PRICE_INFORMATION": "Der Kontakt wünscht eine Preisauskunft zu folgendem Service: ",
        "WARRANTY_PROCESSING": "Der Kontakt hat eine Rückfrage: ",
        "OTHER_SERVICE_REQUEST": "Der Kontakt hat eine Rückfrage: ",
        "NEW_CARS": "Der Kontakt äußerte Interesse an folgendem Neuwagen: ",
        "USED_CARS": "Der Kontakt äußerte Interesse an folgendem Gebrauchtwagen: ",
        "OTHER_SALES_REQUEST": "Der Kontakt hat eine Rückfrage zu: ",
        "PARTS_ORDER": "Der Kontakt möchte folgendes Ersatzteil bestellen: ",
        "PARTS_STATUS": "Der Kontakt hat eine Rückfrage zu seiner Ersatzteilbestellung.",
        "PARTS_PRICE_INFORMATION": "Der Kontakt wünscht eine Preisauskunft zu folgendem Ersatzteil: ",
        "ONLINE_SHOP": "Der Kontakt hat eine Rückfrage: ",
        "OTHER_PARTS_REQUEST": "Der Kontakt hat eine Rückfrage: ",
        "COMPLAINT": "Der Kontakt beschwert sich über folgenden Sachverhalt: ",
        "ACCOUNTING": "Der Kontakt hat eine Rückfrage zu folgender Rechnung: ",
        "CAR_RENTAL": "Der Kontakt benötigt ein Mietfahrzeug.",
        "EMERGENCY": "Der Kontakt hat folgendes Problem und benötigt Hilfe: ",
        "PERSONAL_CONCERN": "Der Kontakt würde gerne das Anliegen mit Ihnen persönlich besprechen. Hinweis: ",
        "REAL_ESTATE": "Der Kontakt hat eine Rückfrage zu folgender Immobilie: ",
        "NOTES": "",
        "JOB_APPLICATION": "Der Kontakt hat eine Rückfrage zu seiner Bewerbung: ",
        "OTHER_REQUEST": "Der Kontakt hat eine Rückfrage zu folgendem Sachverhalt: ",
    },
    "enum": {
        "Role": {
            "ACCOUNTANT": "Buchhalter",
            "CASE_VIEWER": "Fall-Betrachter",
            "CASE_ACTIVITY_VIEWER": "Fall-Betrachter (mit Aktivitätsinhalten)",
            "CASE_ADMIN": "Fall-Administrator",
            "EMERGENCY_INCIDENT_ADMIN": "Notdienstvorgangs-Administrator",
            "OPPORTUNITY_VIEWER": "Verkaufchancen-Betrachter",
            "OPPORTUNITY_ACTIVITY_VIEWER": "Verkaufchancen-Betrachter (mit Aktivitätsinhalten)",
            "OPPORTUNITY_ADMIN": "Verkaufchancen-Administrator",
            "LOCAL_CONTROLLER": "Lokaler Controller",
            "GLOBAL_CONTROLLER": "Globaler Controller",
            "LOCAL_ADMIN": "Lokaler Administrator",
            "SUPER_ADMIN": "Super-Administrator"
        },
        "PaymentMethod": {
            "BANK_TRANSFER": "Überweisung",
            "SEPA_B2B_DIRECT_DEBIT": "SEPA-Firmenlastschrift"
        },
        "AbsenceType": {
            "TRAINING_SCHOOL": "Berufsschule",
            "BUSINESS_TRIP": "Dienstreise",
            "PARENTAL_LEAVE": "Elternzeit",
            "HOME_OFFICE": "Home-Office",
            "VACATION": "Urlaub",
            "SICKNESS": "Krankheit",
            "FIELD_WORK": "Außendienst",
            "FURTHER_TRAINING": "Weiterbildung",
            "COMPENSATORY_TIME_OFF": "Zeitausgleich",
            "EXHIBITION": "Messe"
        },
        "AwayType": {
            "BREAK": "Pause",
            "MEETING": "Meeting",
            "CUSTOMER_TALK": "Kundengespräch",
            "OTHER": "Sonstiges"
        },
        "MileageUnit": {
            "KM": "km",
            "MI": "mi"
        },
        "InventoryImportSourceType": {
            "FTP": "FTP-Upload (CSV)",
            "FTP_STOCK2NET": "FTP-Upload (Stock2Net)",
            "MOBILE_DE": "Mobile.de Inseratseinbindung",
        },
        "InventoryImportErrorType": {
            "GENERAL": "Allgemeiner Fehler",
            "WRONG_CREDENTIALS": "Falsche Zugangsdaten",
            "FILE_NOT_FOUND": "Datei nicht gefunden",
            "CORRUPT_FILE": "Datei ist beschädigt"
        },
        "CaseNotificationEmailFormat": {
            "REGULAR": "Standardformat",
            "REGULAR_WITHOUT_CONTACT_DATA": "Standardformat mit ausgeblendeten Kontaktdaten",
            "REGULAR_WITH_CLOSE_LINK": "Standardformat mit Link zum sofortigen Schließen"
        },
        "OpportunityNotificationEmailFormat": {
            "REGULAR": "Standardformat",
            "REGULAR_WITHOUT_CONTACT_DATA": "Standardformat mit ausgeblendeten Kontaktdaten",
            "REGULAR_WITH_CLOSE_LINK": "Standardformat mit Link zum sofortigen Schließen"
        },
        "EscalationGroupVisibility": {
            "DEALER": "Nur dieser Standort",
            "SELECTED_DEALERS": "Nur ausgewählte Standorte",
            "DEALERGROUP": "Gesamte Händlergruppe"
        },
        "PartnerType": {
            "EMERGENCY_MECHANIC": "Notdienstmonteur",
            "ASSISTANCE": "Assistance",
            "TOWING_SERVICE": "Abschleppunternehmen",
            "CAR_RENTAL": "Autovermietung",
            "MOTOR_VEHICLE_ASSESSOR": "KFZ-Sachverständiger",
            "EMERGENCY_CONTACT": "Notfallkontakt",
            "OTHERS": "Andere"
        },
        "CasesSort": {
            "LAST_ACTIVITY_DESC": "Letzte Aktivität (absteigend)",
            "LAST_ACTIVITY_ASC": "Letzte Aktivität (aufsteigend)",
            "POSTPONED_UNTIL_DESC": "Zurückgestellt bis (absteigend)",
            "POSTPONED_UNTIL_ASC": "Zurückgestellt bis (aufsteigend)",
            "CLOSED_DESC": "Schließungsdatum (absteigend)",
            "CLOSED_ASC": "Schließungsdatum (aufsteigend)",
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "VISIBILITY_BEGIN_DESC": "Sichtbarkeitsbeginn (absteigend)",
            "VISIBILITY_BEGIN_ASC": "Sichtbarkeitsbeginn (aufsteigend)",
            "VISIBILITY_END_DESC": "Sichtbarkeitsende (absteigend)",
            "VISIBILITY_END_ASC": "Sichtbarkeitsende (aufsteigend)",
            "PRIORITY_DESC": "Priorität (absteigend)",
        },
        "OpportunitiesSort": {
            "LAST_ACTIVITY_DESC": "Letzte Aktivität (absteigend)",
            "LAST_ACTIVITY_ASC": "Letzte Aktivität (aufsteigend)",
            "POSTPONED_UNTIL_DESC": "Zurückgestellt bis (absteigend)",
            "POSTPONED_UNTIL_ASC": "Zurückgestellt bis (aufsteigend)",
            "CLOSED_DESC": "Schließungsdatum (absteigend)",
            "CLOSED_ASC": "Schließungsdatum (aufsteigend)",
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "VISIBILITY_BEGIN_DESC": "Sichtbarkeitsbeginn (absteigend)",
            "VISIBILITY_BEGIN_ASC": "Sichtbarkeitsbeginn (aufsteigend)",
            "VISIBILITY_END_DESC": "Sichtbarkeitsende (absteigend)",
            "VISIBILITY_END_ASC": "Sichtbarkeitsende (aufsteigend)",
            "PRIORITY_DESC": "Priorität (absteigend)",
        },
        "LegalInspectionType": {
            "NOT_POSSIBLE": "Nicht möglich",
            "POSSIBLE_WITH_APPOINTMENT_ONLY": "Nur mit Termin möglich",
            "POSSIBLE_WITHOUT_APPOINTMENT": "Ohne Termin möglich",
        },
        "FeedbackType": {
            "INFO": "Allgemeines Feedback (kein Fehler)",
            "ERROR_WRONG_LICENSE_PLATE": "Falsches Kennzeichen",
            "ERROR_WRONG_PHONE_NUMBER": "Falsche Rufnummer",
            "ERROR_WRONG_RECEIVER": "Falscher Empfänger",
            "ERROR_OTHER": "Sonstiger Fehler",
        },
        "IncomingPhoneNumberStatus": {
            "AVAILABLE": "Verfügbar",
            "INSTALLED": "Installiert",
            "INSTALLED_INVALID": "Installiert (ungültig)",
            "INSTALLED_NOT_FOUND": "Installiert (nicht gefunden)",
            "INSTALLED_DEALERDESK_LEGACY": "Vergeben (Legacy)",
            "INSTALLED_OTHER": "Vergeben"
        },
        "ActorType": {
            "USER": "Interner Benutzer",
            "CT_USER": "Externer Agent",
            "SYSTEM": "System"
        },
        "CaseSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "LAST_ACTIVITY_DESC": "Letzte Aktivität (absteigend)",
            "LAST_ACTIVITY_ASC": "Letzte Aktivität (aufsteigend)",
            "POSTPONED_UNTIL_DESC": "Zurückgestellt bis (absteigend)",
            "POSTPONED_UNTIL_ASC": "Zurückgestellt bis (aufsteigend)",
            "CLOSED_DESC": "Schließungsdatum (absteigend)",
            "CLOSED_ASC": "Schließungsdatum (aufsteigend)",
            "PRIORITY_DESC": "Priorität (absteigend)",
        },
        "InventorySearchOrder": {
            "INTERNAL_ID_DESC": "Fahrzeugnummer (absteigend)",
            "INTERNAL_ID_ASC": "Fahrzeugnummer (aufsteigend)",
            "FIRST_REGISTRATION_DESC": "Erstzulassung (absteigend)",
            "FIRST_REGISTRATION_ASC": "Erstzulassung (aufsteigend)",
            "MILEAGE_DESC": "Laufleistung (absteigend)",
            "MILEAGE_ASC": "Laufleistung (aufsteigend)",
            "PRICE_DESC": "Preis (absteigend)",
            "PRICE_ASC": "Preis (aufsteigend)",
            "TOTAL_OPPORTUNITIES_DESC": "Anzahl Verkaufschancen (absteigend)",
            "TOTAL_OPPORTUNITIES_ASC": "Anzahl Verkaufschancen (aufsteigend)"
        },
        "InventoryVehicleStatusType": {
            "AVAILABLE": "verfügbar",
            "RESERVED": "reserviert",
            "SOLD": "verkauft",
        },
        "OpportunityTeamDistributionMethod": {
            "PARALLEL": "Parallel",
            "ROUND_ROBIN": "Round Robin",
            "RANDOM": "Zufällig",
        },
        "OpportunityTeamVisibility": {
            "DEALER": "Nur dieser Standort",
            "SELECTED_DEALERS": "Nur ausgewählte Standorte",
            "DEALERGROUP": "Gesamte Händlergruppe"
        },
        "OpportunitySearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "LAST_ACTIVITY_DESC": "Letzte Aktivität (absteigend)",
            "LAST_ACTIVITY_ASC": "Letzte Aktivität (aufsteigend)",
            "POSTPONED_UNTIL_DESC": "Zurückgestellt bis (absteigend)",
            "POSTPONED_UNTIL_ASC": "Zurückgestellt bis (aufsteigend)",
            "CLOSED_DESC": "Schließungsdatum (absteigend)",
            "CLOSED_ASC": "Schließungsdatum (aufsteigend)",
            "PRIORITY_DESC": "Priorität (absteigend)",
        },
        "TypeHint": {
            "CASE": "Fall",
            "OPPORTUNITY": "Verkaufschance",
            "AUTO_DETECT": "Automatische Erkennung",
        },
        "QualityAssuranceLevel": {
            "NONE": "Keine",
            "DATA_MAINTENANCE": "Datenpflege",
            "DATA_MAINTENANCE_WITH_PRE_QUALIFICATION": "Datenpflege mit Vorqualifizierung",
        },
        "AutoAnswerType": {
            "NONE": "Keine",
            "PLAY_ANNOUNCEMENT": "Ansage abspielen",
            "TRANSFER_TO_CUSTOM_NUMBER": "Weiterleiten an eine benutzerdefinierte Rufnummer",
            "TRANSFER_TO_EXTERNAL_BDC": "Weiterleiten an das externe Call-Center",
            "TRANSFER_TO_INCOMING_PHONE_NUMBER": "Weiterleiten an eine Call-Tracking-Nummer",
            "TRANSFER_VIA_DTMF_MENU": "Weiterleiten über Sprachmenü",
        },
        "IncomingCallDistributionType": {
            "PARALLEL": "Parallel",
            "ROUND_ROBIN": "Round Robin",
        },
        "Gender": {
            "MALE": "männlich",
            "FEMALE": "weiblich",
            "DIVERSE": "divers",
        },
        "SentimentType": {
            "UNKNOWN": "Unbekannte Stimmung",
            "POSITIVE": "Positive Stimmung",
            "NEUTRAL": "Neutrale Stimmung",
            "NEGATIVE": "Negative Stimmung"
        },
        "IssueType": {
            "UNKNOWN": "Unbekannt",
            "CARRY_DISPO": "CARRY Dispo",
            "CASE": "Fall",
            "EMERGENCY_INCIDENT": "Notdienstvorgang",
            "OPPORTUNITY": "Verkaufschance"
        },
        "EmergencyIncidentSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "LAST_ACTIVITY_DESC": "Letzte Aktivität (absteigend)",
            "LAST_ACTIVITY_ASC": "Letzte Aktivität (aufsteigend)",
            "CLOSED_DESC": "Schließungsdatum (absteigend)",
            "CLOSED_ASC": "Schließungsdatum (aufsteigend)",
        },
        "ContactParserFormat": {
            "GENERIC": "Generisches Format",
            "ALPHA_PLUS": "Alpha Plus",
            "AMIS": "AMIS",
            "APAS": "APAS",
            "AUDARIS": "Audaris",
            "AUTOSYS" : "Autosys",
            "AUTOSYS2": "Autosys 2",
            "AUTRADO": "Autrado",
            "BUERO_PLUS": "büro+",
            "BUNGERTP2": "Bungert P2",
            "CARACAMP": "CaraCamp",
            "CARDIS": "CARDIS",
            "CARE": "CARE / VEACT",
            "CARIT": "Carit",
            "CAVIO" : "caVio",
            "CDK": "CDK",
            "CDK_2": "CDK 2",
            "CIDCAR": "cidcar",
            "CROSS": "Cross",
            "CSB": "CSB",
            "CUSTOMER_ONE": "CustomerOne",
            "DRACAR": "Dracar",
            "ECAROS": "ecaros",
            "EVA": "EVA",
            "FILAKSPLUS": "FILAKS.PLUS",
            "FORMEL1": "Formel 1",
            "HUBSPOT": "HubSpot",
            "INCADEA": "incadea.dms",
            "INGOSOFT": "Ingo Software",
            "KAR2": "KAR/2",
            "KSRVIS": "KSR VIS",
            "LOCOSOFT": "Loco-Soft",
            "MACS": "MACS",
            "PROF4NET_CATCH": "CATCH (Prof4Net)",
            "SALES_ASSIST": "SalesAssist",
            "SALES_ASSIST_2": "SalesAssist 2",
            "SAMSURION": "SAMsurion",
            "SAP_PRO": "SAP Professional",
            "SYSCARA": "SYSCARA",
            "TELREP": "TELREP",
            "VAUDIS_CLASSIC": "VaudisClassic",
            "VAUDIS_PRO": "VaudisPro",
            "VAUDIS_X": "VaudisX",
            "VECTORCDP": "VECTOR CDP",
            "VERENDUS": "Verendus",
            "WEBCARE": "web.care",
            "WEBMOBIL24": "WebMobil24",
            "WERWISO": "werwiso",
            "ZAENCAR": "zänCAR",
            "UNKNOWN": "Unbekannt"
        },
        "ContactParserCharset": {
            "UTF_8": "UTF-8",
            "ISO_8859_1": "ISO 8859-1",
        },
        "ContactParserSeparator": {
            "COMMA": "Komma",
            "PIPE": "Pipe",
            "SEMICOLON": "Semikolon",
            "TAB": "Tab"
        },
        "EmergencyIncidentNotificationEmailFormat": {
            "REGULAR": "Standardformat",
            "REGULAR_WITHOUT_CONTACT_DATA": "Standardformat mit ausgeblendeten Kontaktdaten",
            "REGULAR_WITHOUT_LINK": "Standardformat ohne Link zum Notdienstvorgang"
        },
        "ProcessingState": {
            "UNPROCESSED": "Unverarbeitet",
            "UNPROCESSED_IGNORE_FILTER": "Unverarbeitet (forciert)",
            "PROCESSED": "Verarbeitet",
            "PROCESS_MANUALLY": "Manuelle Verarbeitung",
            "FILTERED": "Gefiltert"
        },
        "IncomingEmailSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "FROM_NAME_DESC": "Absendername (absteigend)",
            "FROM_NAME_ASC": "Absendername (aufsteigend)",
            "FROM_ADDRESS_DESC": "Absenderadresse (absteigend)",
            "FROM_ADDRESS_ASC": "Absenderadresse (aufsteigend)",
            "SUBJECT_DESC": "Betreff (absteigend)",
            "SUBJECT_ASC": "Betreff (aufsteigend)"
        },
        "OutgoingSmsSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)"
        },
        "IncomingSmsSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)"
        },
        "OutgoingEmailSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "FROM_NAME_DESC": "Absendername (absteigend)",
            "FROM_NAME_ASC": "Absendername (aufsteigend)",
            "FROM_ADDRESS_DESC": "Absenderadresse (absteigend)",
            "FROM_ADDRESS_ASC": "Absenderadresse (aufsteigend)",
            "SUBJECT_DESC": "Betreff (absteigend)",
            "SUBJECT_ASC": "Betreff (aufsteigend)"
        },
        "NoteSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)"
        },
        "OutgoingCallSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)"
        },
        "IncomingCallSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)"
        },
        "InventoryVehicleInquirySearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)"
        },
        "CarryDispo": {
            "OrderType": {
                "UNFALL": "Unfall",
                "PANNE": "Panne",
                "VERBRINGUNGSFAHRT": "Verbringungsfahrt",
                "HOL_UND_BRINGDIENST": "Hol- und Bringdienst",
                "LACKFAHRT": "Lackfahrt",
                "FALSCHPARKER": "Falschparker",
                "UEBERFUEHRUNG": "Überführung",
            },
            "PropulsionType": {
                "DIESEL": "Diesel",
                "DIESEL_HYBRID": "Diesel-Hybrid",
                "ELECTRIC": "Elektrisch",
                "GASOLINE": "Benzin",
                "GASOLINE_HYBRID": "Benzin-Hybrid",
                "OTHER": "Sonstiges",
                "UNKNOWN": "Unbekannt",
            },
            "Status": {
                "NO_CLEARANCE": "Nicht freigegeben",
                "CLEARANCE": "Freigegeben",
                "CANCELLED": "Auftrag abgebrochen",
                "EMPTY_TRIP": "Fehlfahrt",
                "SENT": "Auftragsversand",
                "RECEIVED": "Auftrag empfangen",
                "READ": "Auftrag gelesen",
                "ACCEPTED": "Auftrag angenommen",
                "START": "Auftrag begonnen",
                "TOWING_START": "Abschleppen begonnen",
                "RECOVER_START": "Bergen begonnen",
                "ARRIVE_AT_SERVICE_POINT": "Ankunft am Einsatzort",
                "WORK_START": "Arbeit am Einsatzort begonnen",
                "WORK_END": "Arbeit am Einsatzort beendet",
                "DRIVE_TO_DELIVERY_POINT": "Weiterfahrt zum Zielort",
                "END": "Auftrag erledigt",
                "PAUSED": "Auftrag unterbrochen",
                "DRIVE_TO_SERVICE_POINT": "Anfahrt zum Einsatzort",
                "ARRIVE_AT_DELIVERY_POINT": "Ankunft am Zielort",
                "UNLOAD_START": "Arbeit am Zielort begonnen",
                "UNLOAD_END": "Arbeit am Zielort beendet",
                "NOT_INTERRUPTED": "Auftrag begonnen/fortgesetzt",
                "CLIENT_INFORMED": "Havarist informiert",
                "WORK_END_DRIVABLE": "Weiterfahrt möglich",
                "WORK_END_NO_ACTION": "Keine weiteren Aktionen erforderlich",
                "BREAKDOWN_END_DRIVABLE": "Weiterfahrt nach Panne möglich",
                "BREAKDOWN_END_DRIVABLE_LIMITED": "Weiterfahrt nach Panne eingeschränkt möglich",
                "BREAKDOWN_END_TOWING_NEEDED": "Abschleppen nach Panne erforderlich",
                "VEHICLE_STORED": "Fahrzeug eingestellt",
                "CREATED": "Fahrer beauftragt",
                "TOWING_END": "Abschleppen abgeschlossen",
                "START_COMPLICATED_LOADING": "Start erschwertes Verladen",
                "END_COMPLICATED_LOADING": "Abschluss erschwertes Verladen",
                "RECOVER_END_DRIVABLE": "Weiterfahrt nach Bergung möglich",
                "RECOVER_END_DRIVABLE_LIMITED": "Weiterfahrt nach Bergung eingeschränkt möglich",
                "RECOVER_END_NO_ACTION": "Keine weiteren Aktionen nach Bergung erforderlich",
                "RECOVER_END_TOWING_NEEDED": "Bergen abgeschlosssen, Abschleppen erforderlich",
                "SECOND_TOWING_START": "Start Zweitschleppen",
                "SECOND_TOWING_END": "Ende Zweitschleppen",
                "FETCHED": "abgeholt",
                "TRANSPORT_END": "Verbund abgeschlosssen",
                "LOAD_END": "Aufgeladen",
                "PICKUP_END": "Pickup abgeschlosssen",
                "WORK_END_TOWING_NEEDED": "PH nicht erfolgreich, Abschleppen erfoderlich",
                "OPERATOR_START_ORDER": "Fahrer hat Auftrag begonnen",
                "START_WORK_BREAKDOWN": "Fahrerauswahl Pannenhilfe",
                "START_WORK_TOWING": "Fahrerauswahl Abschleppen",
                "START_WORK_RECOVER": "Fahrerauswahl Bergen",
                "ROLLING_NO": "Nicht rollfähig",
                "ROLLING_YES": "Rollfähig",
                "LOCKED_NO": "Unverschlosssen",
                "LOCKED_YES": "Verschlossen",
                "SECOND_TOWING_NEEDED": "Zweitschleppen erforderlich",
                "CLIENT_INFORMED_BY_PHONE": "Havarist telefonisch informiert",
                "CLIENT_INFORMED_BY_SMS": "Havarist per SMS informiert",
                "LOAD_START": "Aufladen begonnen",
                "CONTRACTOR_NOT_DEFINED": "Auftraggeber nicht definiert",
                "REJECTED": "Auftrag abgelehnt",
                "RECOVER_YES": "Bergung erforderlich",
                "RECOVER_NO": "Keine Bergung erforderlich",
                "DRIVE_TO_LOADING_POINT": "Anfahrt zum Ladeort",
                "LOAD_VEHICLE": "Fahrzeug aufladen",
                "UNLOAD_VEHICLE": "Fahrzeug abladen",
                "CONFIRM_SIMPLE_SERVICE_POINT": "Einzelbestätigung einfach am Ladeort",
                "CONFIRM_SIGNATURE_SERVICE_POINT": "Einzelbestätigung mit Unterschrift am Ladeort",
                "CONFIRM_SIMPLE_DELIVERY_POINT": "Einzelbestätigung einfach am Abladeort",
                "CONFIRM_SIGNATURE_DELIVERY_POINT": "Einzelbestätigung mit Unterschrift am Abladeort",
                "CONFIRM_ALL_SIMPLE_SERVICE_POINT": "Gesamtbestätigung einfach am Ladeort",
                "CONFIRM_ALL_SIGNATURE_SERVICE_POINT": "Gesamtbestätigung mit Unterschrift am Ladeort",
                "CONFIRM_ALL_SIMPLE_DELIVERY_POINT": "Gesamtbestätigung einfach am Abladeort",
                "CONFIRM_ALL_SIGNATURE_DELIVERY_POINT": "Gesamtbestätigung mit Unterschrift am Abladeort",
                "RETURN": "Rückfahrt",
                "START_WORK_TOUR": "Beginn Arbeit",
                "END_WORK_TOUR": "Ende Arbeit",
                "START_CLEANING": "Beginn Reinigung",
                "END_CLEANING": "Ende Reinigung",
                "PREPARE_CLEANING": "Vorbereiten Reinigung",
                "APPLICATION_BINDER": "Aufbringen Ölbindemittel",
                "NO_BINDER": "Ohne Ölbindemittel",
                "PRE_CLEANING": "Vorreinigung(Kehren)",
                "NO_PRE_CLEANING": "Keine Vorreingung",
                "VACCUM_SCRUB_CLEANING": "Saug-Schrubb-Verfahren",
                "HIGH_PRSSURE_CLEANING": "Hochdruckreinigung",
                "CLEANING_DEVICES": "Gerätereinigung",
                "DISPOSAL_MUD": "Entsorgung Reststoffe",
                "RESTORE_OPERATION_READINESS": "Einsatzbereitschaft wiederherstellen",
                "TIMEOUT": "Zeitüberschreitung",
                "ARCHIVED": "Archiviert",
            },
            "TargetType": {
                "SERVICE_POINT": "Einsatzort",
                "DELIVERY_POINT": "Zielort",
            },
            "TransmissionType": {
                "AUTOMATIC": "Automatik",
                "AUTOMATIC_DUAL_CLUTCH": "Doppelkupplungsgetriebe",
                "MANUAL": "Manuell",
                "MANUMATIC": "Manumatik",
                "OTHER": "Sonstiges",
                "UNKNOWN": "Unbekannt",
            },
            "VehicleType": {
                "PKW": "PKW",
                "VAN": "VAN",
                "MOTORRAD": "Motorrad",
                "MOTORRAD_MIT_BEIWAGEN": "Motorrad mit Beiwagen",
                "SUV": "SUV",
                "WOHNMOBIL": "Wohnmobil",
                "WOHNWAGEN": "Wohnwagen",
                "ANHAENGER": "Anhänger",
                "LKW": "LKW",
                "BUS": "Bus",
                "FLUGZEUG": "Flugzeug",
                "BOOTSANHAENGER": "Bootsanhänger",
                "ANHAENGER_MIT_BOOT": "Anhänger mit Boot",
                "BOOT": "Boot",
                "CONTAINER": "Container",
            }
        },
        "IncomingWhatsAppSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)"
        },
        "OutgoingWhatsAppSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)"
        },
        "OutgoingWhatsAppMessageStatus": {
             "SENT": "Versandt",
             "DELIVERED": "Zugestellt",
             "READ": "Gelesen",
             "FAILED": "Fehlgeschlagen"
        },
        "ContactSource": {
            "UNKNOWN": "Unbekannt",
            "UPLOAD": "Import",
            "UI": "Manuell angelegt",
            "SYSTEM": "System",
            "API": "API",
            "DELETED_UPLOAD": "Import (gelöschte Quelle)"
        },
        "FinanceContractParserFormat": {
            "GENERIC": "Generisches Format",
            "AKF": "AKF",
            "ALD_AUTOMOTIVE": "ALD Automotive",
            "BANK11": "Bank11",
            "BMW_FINANCING": "BMW Finanzierung",
            "BMW_LEASING": "BMW Leasing",
            "CATCH": "Catch",
            "CREDIT_PLUS": "CreditPlus Bank",
            "FCA": "FCA Bank",
            "HIS": "HIS",
            "HYUNDAI": "Hyundai Bank",
            "MERCEDES": "Mercedes",
            "OPEL": "Opel",
            "PEUGEOT_FINANCING": "Peugeot Finanzierung",
            "PEUGEOT_LEASING": "Peugeot Leasing",
            "PSA_BANK": "Banque PSA Finance",
            "RCI": "RCI",
            "SANTANDER_FINANCING": "Santander Finanzierung",
            "SANTANDER_LEASING": "Santander Leasing"
        },
        "FinanceContractParserCharset": {
            "UTF_8": "UTF-8",
            "ISO_8859_1": "ISO 8859-1",
        },
        "FinanceContractParserSeparator": {
            "COMMA": "Komma",
            "PIPE": "Pipe",
            "SEMICOLON": "Semikolon",
            "TAB": "Tab"
        },
        "FinanceContractType": {
            "LEASING": "Leasing",
            "FINANCING": "Finanzierung",
        },
        "FinanceContractCustomerType": {
            "PRIVATE": "Privat",
            "BUSINESS": "Geschäftlich",
        },
        "FinanceContractVehicleUsageType": {
            "NEW": "Neuwagen",
            "USED": "Gebrauchtwagen",
        },
        "ContactUploadType": {
            "EXTERNAL_API": "API-Upload",
            "FTP": "FTP-Upload",
            "UI": "Manueller Upload",
        },
        "TimelineItemType": {
            "AUDIT_EVENT": "Statusänderung",
            "INCOMING_CALL": "Eingehender Anruf",
            "INCOMING_EMAIL": "Eingehende E-Mail",
            "INCOMING_SMS": "Eingehende SMS",
            "INCOMING_WHATSAPP": "Eingehende WhatsApp-Nachricht",
            "INVENTORY_VEHICLE_INQUIRY_MATCH": "Treffer für Suchauftrag",
            "NOTE": "Notiz",
            "OUTGOING_CALL": "Ausgehender Anruf",
            "OUTGOING_EMAIL": "Ausgehende E-Mail",
            "OUTGOING_SMS": "Ausgehende SMS",
            "OUTGOING_WHATSAPP": "Ausgehende WhatsApp-Nachricht",
        },
        "DealerInstructionPriority": {
            "LOW": "niedrig",
            "MEDIUM": "mittel",
            "HIGH": "hoch",
        },
        "DealerInstructionCategory": {
            "CONTACT_SEARCH": "Kontaktsuche",
            "DATA_ACQUISITION": "Datenaufnahme",
            "RECEIVER_SELECTION": "Empfängersauswahl",
        },
        "FilterTimeRange": {
            "NONE": "Keine Einschränkung",
            "TODAY": "Heute",
            "YESTERDAY": "Gestern",
            "THIS_WEEK": "Aktuelle Woche",
            "LAST_WEEK": "Letzte Woche",
            "THIS_MONTH": "Aktueller Monat",
            "LAST_MONTH": "Letzten Monat",
            "THIS_YEAR": "Aktuelles Jahr",
            "LAST_YEAR": "Letztes Jahr",
        },
        "FinanceContractSearchOrder": {
            "FINAL_INSTALLMENT_PAYMENT_DATE_ASC": "Zahlungsdatum der Schlussrate (aufsteigend)",
            "FINAL_INSTALLMENT_PAYMENT_DATE_DESC": "Zahlungsdatum der Schlussrate (absteigend)",
        },
        "ExternalAbsenceProviderType": {
            "ATOSS": "ATOSS",
            "ATOSS_DEFAULT" : "ATOSS (Standard)",
            "AUTOSYS": "Autosys",
            "KDV_ZDE": "KDV-ZDE",
            "PERSONIO": "Personio",
            "SAGE_HR": "Sage HR",
            "SOFT_CLOCK": "soft-clock",
            "STARTIME": "StarTIME2",
            "TIME_IX": "Time iX",
            "TIMEBUTLER": "Timebutler",
            "TIMEMETER_PLUS": "TimeMeter Plus",
            "WEBCARE": "web.care"
        },
        "NumberBlacklistSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "NUMBER_DESC": "Rufnummer (absteigend)",
            "NUMBER_ASC": "Rufnummer (aufsteigend)"
        },
        "RenaultRaceApiErrorType": {
            "FORBIDDEN": "Die Renault-RACE-API meldet, dass der Zugriff auf den aufgerufenen Endpunkt nicht gestattet ist.",
            "GENERAL": "Allgemeiner Fehler",
            "HTTP_BASIC_AUTH_CREDENTIALS_INCORRECT": "Die Zugangsdaten sind inkorrekt.",
            "INTERNAL_SERVER_ERROR": "Die Renault-RACE-API meldet einen internen Serverfehler.",
            "LEAD_NOT_FOUND": "Die Renault-RACE-API meldet, dass der Lead nicht gefunden werden konnte.",
            "LEAD_PROCESSING_FAILED": "Der abgerufene Lead konnte nicht verarbeitet werden.",
            "TLS_CLIENT_CERTIFICATE_DECODING_FAILED": "Das TLS-Client-Zertifikat konnte nicht entschlüsselt werden.",
            "TLS_CLIENT_CERTIFICATE_INCORRECT": "Das TLS-Client-Zertifikat ist ungültig.",
            "TLS_CLIENT_CERTIFICATE_INCORRECT_PASSWORD": "Das Passwort zum TLS-Client-Zertifikat ist inkorrekt."
        },
        "WhatsAppApiType": {
            "CLOUD_API": "Cloud API",
            "ON_PREMISES_API": "On-Premises API"
        }
    },
    "messagePlaceholder": {
        "SALUTATION": "Anrede mit Begrüßungsformel",
        "CONTACT_SALUTATION": "Anrede",
        "CONTACT_GIVEN_NAME": "Vorname",
        "CONTACT_FAMILY_NAME": "Nachname",
        "CONTACT_FULL_NAME": "Name",
        "DEALER_NAME": "Name des Händlers",
        "DEALER_ADDRESS_1": "Adresszusatz",
        "DEALER_ADDRESS_2": "Straße und Hausnummer",
        "DEALER_ZIP": "Postleitzahl",
        "DEALER_CITY": "Ort",
        "DEALER_STATE": "Region",
        "DEALER_COUNTRY": "Land",
        "USER_SALUTATION": "Anrede",
        "USER_GIVEN_NAME": "Vorname",
        "USER_FAMILY_NAME": "Nachname",
        "USER_FULL_NAME": "Name",
        "USER_EMAIL_ADDRESS": "E-Mail-Adresse",
        "USER_PHONE": "Persönliche Telefonnummer",
        "USER_LANDLINE": "Persönliche Festnetznummer",
        "USER_MOBILE": "Persönliche Handynummer",
        "USER_FAX": "Persönliche Faxnummer",
        "USER_LANGUAGES": "Gesprochene Sprachen",
        "USER_PROFILE_IMAGE_100": "Profilbild (klein)",
        "USER_PROFILE_IMAGE_150": "Profilbild (mittel)",
        "USER_PROFILE_IMAGE_200": "Profilbild (groß)",
        "USER_DEALER_LANDLINE": "Festnetznummer am Hauptstandort",
        "USER_DEALER_MOBILE": "Handynummer am Hauptstandort",
        "USER_DEALER_FAX": "Faxnummer am Hauptstandort",
        "USER_DEPARTMENT_NAME": "Abteilung am Hauptstandort",
        "USER_DEPARTMENT_POSITION": "Position in der Abteilung",
        "USER_SIGNATURE": "Signatur",
        "REQUESTED_VEHICLE_CAPTION": "Fahrzeugbezeichnung",
        "REQUESTED_VEHICLE_MAKE": "Marke",
        "REQUESTED_VEHICLE_MODEL": "Modell",
        "REQUESTED_VEHICLE_MODEL_DESCRIPTION": "Modellbeschreibung",
        "REQUESTED_VEHICLE_INTERNAL_ID": "Fahrzeugnummer",
        "EMAIL_QUOTE": "E-Mail-Zitat",
        "ISSUE_ID": "Vorgangsnummer",
        "LINK_<key>": "Link",
    },
    "messagePlaceholderDescription": {
        "SALUTATION": "Formale Anrede des verknüpften Kontakts, z.B. Sehr geehrter Herr Dr. Schmidt",
        "CONTACT_SALUTATION": "Anrede des verknüpften Kontakts, z.B. Herr",
        "CONTACT_GIVEN_NAME": "Vorname des verknüpften Kontakts, z.B. Thomas",
        "CONTACT_FAMILY_NAME": "Nachname des verknüpften Kontakts, z.B. Schmidt",
        "CONTACT_FULL_NAME": "Name des des verknüpften Kontakts, z.B. Dr. Thomas Schmidt",
        "DEALER_NAME": "Name des Händlers, z.B. Autohaus Müller",
        "DEALER_ADDRESS_1": "Adresszusatz des Händlers",
        "DEALER_ADDRESS_2": "Straße und Hausnummer des Händlers, z.B. Motorenstraße 11",
        "DEALER_ZIP": "Postleitzahl des Händlers",
        "DEALER_CITY": "Stadt des Händlers",
        "DEALER_STATE": "Region des Händlers, z.B. Bayern",
        "DEALER_COUNTRY": "Land des Händlers, z.B. Deutschland",
        "USER_SALUTATION": "Anrede des Benutzers, z.B. Herr",
        "USER_GIVEN_NAME": "Vorname des Benutzers, z.B. Max",
        "USER_FAMILY_NAME": "Nachname des Benutzers, z.B. Brecht",
        "USER_FULL_NAME": "Name des Benutzers, z.B. Dr. Max Brecht",
        "USER_EMAIL_ADDRESS": "E-Mail-Adresse des Benutzers, z.b. max.brecht@autohaus.de",
        "USER_PHONE": "Persönliche Telefonnummer des Benutzers (Festnetz oder Handy)",
        "USER_LANDLINE": "Persönliche Festnetznummer des Benutzers, z.B. +49 800 6630000",
        "USER_MOBILE": "Persönliche Handynummer des Benutzers",
        "USER_FAX": "Persönliche Faxnummer des Benutzers",
        "USER_LANGUAGES": "Gesprochene Sprachen des Benutzers, z.B. Deutsch, Englisch",
        "USER_PROFILE_IMAGE_100": "Profilbild des Benutzers (100px breit)",
        "USER_PROFILE_IMAGE_150": "Profilbild des Benutzers (150px breit)",
        "USER_PROFILE_IMAGE_200": "Profilbild des Benutzers (200px breit)",
        "USER_DEALER_LANDLINE": "Festnetznummer des Benutzers am Hauptstandort",
        "USER_DEALER_MOBILE": "Handynummer des Benutzers am Hauptstandort",
        "USER_DEALER_FAX": "Faxnummer des Benutzers am Hauptstandort",
        "USER_DEPARTMENT_NAME": "Abteilung des Benutzers am Hauptstandort, z.B. Kundenservice",
        "USER_DEPARTMENT_POSITION": "Position des Benutzers in der Abteilung am Hauptstandort, z.B. Serviceberater",
        "USER_SIGNATURE": "Signatur des Benutzers",
        "REQUESTED_VEHICLE_CAPTION": "Name des angefragten Fahrzeuges",
        "REQUESTED_VEHICLE_MAKE": "Marke des angefragten Fahrzeuges",
        "REQUESTED_VEHICLE_MODEL": "Modell des angefragten Fahrzeuges",
        "REQUESTED_VEHICLE_MODEL_DESCRIPTION": "Modellbeschreibung des angefragten Fahrzeuges",
        "REQUESTED_VEHICLE_INTERNAL_ID": "Fahrzeugnummer des angefragten Fahrzeuges",
        "EMAIL_QUOTE": "Zitat der letzten E-Mail",
        "ISSUE_ID": "Eindeutige Kennung des Vorgangs",
        "LINK_<key>": "Link auf URL mit entsprechendem Schlüssel",
    },
    "welcomeEmailPlaceholders": {
        "SALUTATION": "Anrede mit Begrüßungsformel",
        "USERNAME": "Benutzername",
        "PASSWORD": "Passwort",
        "APP_NAME": "Name des Webportals",
        "APP_LINK": "Link zum Webportal",
    },
    "welcomeEmailPlaceholdersDescription": {
        "SALUTATION": "Formale Anrede des Benutzers, z.B. Sehr geehrter Herr Schmidt",
        "USERNAME": "Benutzername für die Anmeldung im Webportal",
        "PASSWORD": "Passwort des Benutzers",
        "APP_NAME": "",
        "APP_LINK": "",
    },
    "externalInventoryManagementUserLinkPlaceholder": {
        "USER_ID": "Benutzer-ID"
    },
    "externalInventoryManagementUserLinkPlaceholderDescription": {
        "USER_ID": "ID des Benutzers"
    },
    "externalInventoryManagementVehicleLinkPlaceholder": {
        "USER_ID": "Benutzer-ID",
        "INTERNAL_ID": "Fahrzeugnummer",
        "VIN": "FIN"
    },
    "externalInventoryManagementVehicleLinkPlaceholderDescription": {
        "USER_ID": "ID des Benutzers",
        "INTERNAL_ID": "Nummer des Fahrzeugs im externen Fahrzeugverwaltungssystem",
        "VIN": "Fahrzeug-Identifizierungsnummer"
    },
    "email": {
        "welcomeEmail": {
            "subject": "Willkommen bei %APP_NAME% 10! (Zugangsdaten)",
            "content": `<p>%SALUTATION%,</p>
hiermit erhalten Sie Ihre Zugangsdaten für das %APP_NAME%-System.</p>
<p>
<strong>Webportal: </strong><a href="%APP_LINK%">%APP_LINK%</a><br />
<strong>Benutzername: </strong>%USERNAME%<br />
<strong>Passwort: </strong>%PASSWORD%<br />
</p>
<p><strong>Erklärvideo zum Dashboard (19:38 Minuten): </strong><a href="https://vimeo.com/502273195/73b61af440">https://vimeo.com/502273195/73b61af440</a></p>
<p>
Folgende Browser werden unterstützt:<br />
- Mozilla Firefox<br />
- Google Chrome<br />
- Safari<br />
- Microsoft Edge<br />
</p>
<p>Bitte beachten Sie, dass wir nur aktuelle Versionen unterstützen.</p>
<p><strong>WICHTIG:</strong> Aus Sicherheitsgründen empfehlen wir Ihnen, Ihr Passwort zu ändern. Ihr Passwort können Sie über den Menüpunkt "Passwort ändern" oben links ändern.</p>
<p>Darüber hinaus ist es wichtig, dass Sie Ihr Benutzerkonto (Profil, Anwesenheitszeiten, Benachrichtigungseinstellungen) aktualisieren.</p>
<p>
Wenn Sie weitere Fragen haben, stehen wir Ihnen selbstverständlich gerne zur Verfügung.<br />
<strong>E-Mail: </strong>support@dealerdesk.de<br />
<strong>Technischer Support: </strong>+49 40 33480668<br />
<strong>Call-Center Support: </strong>+49 40 33452744<br />
</p>
<p>
Mit freundlichen Grüßen<br />
Ihr %APP_NAME%-Team
</p>`,
        },
    },
};
