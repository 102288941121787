import { DealerAssignment, EmailAlias, Gender, PhoneNumber } from "@/api/users";
import {
    renderUserDealerPlaceholders,
    renderUserPlaceholders,
    renderUserProfileImagePlaceholders,
    userDealerPlaceholders,
    userPlaceholders,
    userProfileImagePlaceholders,
} from "@/app/placeholderUtils";

export const emailSignatureTemplatePlaceholders = [
    ...userPlaceholders,
    ...userProfileImagePlaceholders,
    ...userDealerPlaceholders,
];

interface UserEmailSignatureTemplatePlaceholderInfo {
    readonly id: string | null;
    readonly username: string | null;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly languages: string[];
    readonly profileImageHash: string | null;
    readonly mainDealerId: string | null;
    readonly phoneNumbers: PhoneNumber[];
    readonly dealerAssignments: DealerAssignment[];
    readonly emailAliases: EmailAlias[];
}

export async function renderEmailSignatureTemplatePlaceholders(
    content: string,
    user: UserEmailSignatureTemplatePlaceholderInfo
) {
    const replaced = await renderUserProfileImagePlaceholders(content, user);

    return [
        (content: string) => renderUserPlaceholders(content, user),
        (content: string) => renderUserDealerPlaceholders(content, user),
    ].reduce((content, fn) => fn(content), replaced);
}
