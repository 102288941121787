
import { readReceiptSettingsApi } from "@/api/readReceiptSettings";
import CrudPage from "@/app/pages/CrudPage.vue";
import { getFullName } from "@/app/userUtils";
import { notEmpty } from "@/app/validation";
import { usersStore } from "@/store/users";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

interface ReadReceiptHiddenUserForm {
    userId: string | null;
}

export default Vue.extend({
    data() {
        return {
            getFullName,
        };
    },

    computed: {
        api() {
            return readReceiptSettingsApi;
        },
        notEmpty() {
            return notEmpty;
        },
        allUsersOptions(): SelectOptions {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },
    },

    methods: {
        emptyForm(): ReadReceiptHiddenUserForm {
            return { userId: null };
        },

        getUserById(userId: string) {
            return usersStore.getUserById(userId);
        },
    },

    components: {
        CrudPage,
    },
});
