
import { ContactType } from "@/api/contacts";
import {
    EscalationGroup,
    EscalationGroupForm,
    escalationGroupsApi,
    EscalationGroupVisibility,
} from "@/api/escalationGroups";
import { OfficeHours } from "@/api/officeHours";
import { Permission } from "@/api/userSession";
import { UserDirectoryEntry } from "@/api/users";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { showConfirm } from "@/app/messageUtil";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import CrudPage from "@/app/pages/CrudPage.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import { getFullName, getSortedByUserFullName } from "@/app/userUtils";
import { notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { makeModelsStore } from "@/store/makeModels";
import { officeHoursStore } from "@/store/officeHours";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

type EscalationGroupFormWithNullableVisibility = Omit<EscalationGroupForm, "visibility"> & {
    readonly nullableVisibility: EscalationGroupVisibility | null;
};

export default Vue.extend({
    data() {
        return {
            Permission,
            ContactType,
            EscalationGroupVisibility,
            escalationTimeMinutesRules: notEmpty().integer(0),
            getFullName,
            loading: true,
            nameRules: notEmpty().maxLength(300),
            dealerRules: notEmpty(),
            officeHours: [] as OfficeHours[],
        };
    },

    computed: {
        allUsersOptions(): SelectOptions {
            return getSortedByUserFullName(
                usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) })),
                (o) => o.value
            );
        },

        visibilityOptions(): SelectOptions {
            return [
                { value: null, text: this.$t("Unsichtbar") },
                ...Object.keys(EscalationGroupVisibility).map((k) => ({
                    value: k,
                    text: this.$t(`enum.EscalationGroupVisibility.${k}`),
                })),
            ];
        },

        officeHoursOptions(): SelectOption[] {
            return [
                { value: null, text: this.$t("keine Angabe") },
                ...this.officeHours.map((officeHours) => ({
                    value: officeHours.id,
                    text: officeHours.name,
                })),
            ];
        },

        vehicleClassOptions(): SelectOption[] {
            return makeModelsStore.vehicleClasses
                .map((vc) => ({
                    value: vc,
                    text: this.$t(`vehicle.class.${vc}`).toString(),
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },
    },

    methods: {
        async addEscalationGroup(dealerId: string, form: EscalationGroupFormWithNullableVisibility) {
            try {
                await escalationGroupsApi.add(dealerId, {
                    ...form,
                    visibility: form.nullableVisibility || EscalationGroupVisibility.SELECTED_DEALERS,
                    visibleForDealerIds: form.nullableVisibility ? form.visibleForDealerIds : [],
                });
                return true;
            } catch (e) {
                return false;
            }
        },

        addEscalationLevel(form: EscalationGroupFormWithNullableVisibility) {
            form.escalationGroupLevels.push({
                escalationTimeMinutes: 60,
                members: [],
            });
        },

        async deleteEscalationGroup(escalationGroup: EscalationGroup) {
            const usageLabels: TranslateResult[] = [];
            const usage = await escalationGroupsApi.getUsage(escalationGroup.id);
            if (usage.catchAllSms) {
                usageLabels.push(this.$t("Allgemeine SMS-Einstellungen"));
            }
            if (usage.catchAllUserEmails) {
                usageLabels.push(this.$t("Allgemeine E-Mail-Einstellungen"));
            }
            if (usage.incomingPhoneNumberIds.length) {
                usageLabels.push(this.$t("Call-Tracking-Nummern"));
            }
            if (usage.individualEscalationUserIds.length) {
                usageLabels.push(this.$t("Individuelle Eskalationen"));
            }
            if (usage.systemEmailAliasIds.length) {
                usageLabels.push(this.$t("Eingehende E-Mail-Adressen"));
            }
            if (usage.whatsAppAccountIds.length) {
                usageLabels.push(this.$t("WhatsApp-Konten"));
            }

            if (usageLabels.length) {
                if (
                    await showConfirm(
                        this.$t("Eskalationsgruppe in Verwendung") as string,
                        this.$t(
                            "Die Eskalationsgruppe wird noch in folgenden Einstellungen verwendet:\n{0}\n\nSind Sie sicher, dass Sie die Eskalationsgruppe {1} löschen möchten?",
                            [usageLabels.join("\n"), escalationGroup.name]
                        ) as string
                    )
                ) {
                    await escalationGroupsApi.delete(escalationGroup.id);
                    return true;
                }

                return false;
            }

            if (
                await showConfirm(
                    this.$t("Eskalationsgruppe löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eskalationsgruppe {0} löschen möchten?", [
                        escalationGroup.name,
                    ]) as string
                )
            ) {
                await escalationGroupsApi.delete(escalationGroup.id);
                return true;
            }

            return false;
        },

        async editEscalationGroup(escalationGroupId: string, form: EscalationGroupFormWithNullableVisibility) {
            try {
                await escalationGroupsApi.edit(escalationGroupId, {
                    ...form,
                    visibility: form.nullableVisibility || EscalationGroupVisibility.SELECTED_DEALERS,
                    visibleForDealerIds: form.nullableVisibility ? form.visibleForDealerIds : [],
                });
                return true;
            } catch (e) {
                return false;
            }
        },

        emptyForm(): EscalationGroupFormWithNullableVisibility {
            return {
                officeHoursId: null,
                nullableVisibility: EscalationGroupVisibility.DEALER,
                visibleForDealerIds: [],
                visibleForAgents: true,
                name: "",
                caseTypeSuggestions: [],
                escalationGroupLevels: [],
                contactTypes: [],
                makes: [],
                vehicleClasses: [],
            };
        },

        async getEscalationGroups(dealerId: string) {
            this.officeHours = officeHoursStore.getOfficeHoursByDealerId(dealerId);

            return await escalationGroupsApi.getByDealer(dealerId);
        },

        getOfficeHoursNameById(itemId: string): string {
            return this.officeHours.find((i) => i.id === itemId)?.name || "";
        },

        getUserById(userId: string): UserDirectoryEntry {
            return usersStore.getUserById(userId)!;
        },

        getItemsTranslationText(items: string[], translationSupplier: (key: string) => TranslateResult | string) {
            return items.map((i) => translationSupplier(i)).join(", ");
        },

        removeEscalationLevel(form: EscalationGroupFormWithNullableVisibility, index: number) {
            form.escalationGroupLevels.splice(index, 1);
        },

        toForm(item: EscalationGroup): EscalationGroupFormWithNullableVisibility {
            return cloneObject({
                ...item,
                nullableVisibility:
                    item.visibility === EscalationGroupVisibility.SELECTED_DEALERS && !item.visibleForDealerIds.length
                        ? null
                        : item.visibility,
            });
        },

        async updateOrder(items: EscalationGroup[]) {
            await escalationGroupsApi.updateOrder(
                items[0].dealerId,
                items.map((ot) => ot.id)
            );
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)!.name;
        },
    },

    components: {
        CaseTypePicker,
        CrudPage,
        DAutocomplete,
        DealerContextGuard,
        EnumField,
        MakePicker,
        NumberField,
    },
});
