import { userSession } from "./userSession";
import { emergencyIncidentsApi, UserEmergencyIncidentStatus } from "@/api/emergencyIncidents";
import { isEmergencyIncidentVisibilityNotification, notificationEventSource } from "@/api/notifications";
import { Permission } from "@/api/userSession";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class EmergencyIncidentStatusStore {
    private refreshLimiter = new ActionLimiter(true);
    private userEmergencyIncidentStatus_: UserEmergencyIncidentStatus = {
        openEmergencyIncidents: 0,
        openEmergencyIncidentsMainDealer: 0,
    };

    get activeEmergencyIncidentsCount() {
        return this.userEmergencyIncidentStatus_.openEmergencyIncidents;
    }

    get activeEmergencyIncidentsForMainDealerCount() {
        return this.userEmergencyIncidentStatus_.openEmergencyIncidentsMainDealer;
    }

    async refresh() {
        if (!userSession.hasPermission(Permission.MANAGE_EMERGENCY_INCIDENTS)) {
            this.userEmergencyIncidentStatus_ = {
                openEmergencyIncidents: 0,
                openEmergencyIncidentsMainDealer: 0,
            };
            return;
        }

        await this.refreshLimiter.execute(async () => {
            this.userEmergencyIncidentStatus_ = await emergencyIncidentsApi.getMyEmergencyIncidentsStatus();
        });
    }
}

export const emergencyIncidentStatusStore = new EmergencyIncidentStatusStore();

notificationEventSource.addDataHandler((n) => {
    if (isEmergencyIncidentVisibilityNotification(n)) {
        emergencyIncidentStatusStore.refresh();
    }
});
