
import Vue from "vue";

export default Vue.extend({
    methods: {
        print() {
            const win = (this.$refs.iframe as HTMLIFrameElement).contentWindow!;
            const body = win.document.body;
            body.innerHTML = "";
            const container = document.createElement("div");
            body.appendChild(container);
            new Vue({
                i18n: this.$i18n,
                render: (h) => h("div", {}, this.$slots["default"]),
            }).$mount(container);
            win.print();
        },
    },
});
