
import { CaseOutcomeReason } from "@/api/caseOutcomeReasons";
import { CaseAuditEvent, CaseAuditEventType } from "@/api/cases";
import TimeSpan from "@/app/components/TimeSpan.vue";
import EscalationGroupLink from "@/app/pages/EscalationGroupLink.vue";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { caseOutcomeReasonsStore } from "@/store/caseOutcomeReasons";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        auditEvent: {
            type: Object as () => CaseAuditEvent,
            required: true,
        },
    },

    data() {
        return {
            CaseAuditEventType,
        };
    },

    computed: {
        addedAssigneeIds(): string[] {
            return this.auditEvent.newAssignedUserIds.filter(
                (assigneeId) => !this.auditEvent.oldAssignedUserIds.includes(assigneeId)
            );
        },

        addedReceiverGroupIds(): string[] {
            return this.getSortedByEscalationGroupName(
                this.auditEvent.newEscalationGroupReceiverIds.filter(
                    (groupId) => !this.auditEvent.oldEscalationGroupReceiverIds.includes(groupId)
                )
            );
        },

        addedReceiverUserIds(): string[] {
            return this.getSortedByFullName(
                this.auditEvent.newIndividualReceiverUserIds.filter(
                    (userId) => !this.auditEvent.oldIndividualReceiverUserIds.includes(userId)
                )
            );
        },

        creator(): string {
            if (this.auditEvent.creatorCtUserId) {
                return this.$t("Externer Agent") as string;
            }
            if (this.auditEvent.creatorUserId) {
                return this.getFullNameById(this.auditEvent.creatorUserId);
            }
            return this.$t("System") as string;
        },

        creationTimestamp() {
            return formatInstant(this.auditEvent.created, userSession.timeZone, userSession.locale, "S");
        },

        hasAddedReceivers(): boolean {
            return !!this.addedReceiverUserIds.length || !!this.addedReceiverGroupIds.length;
        },

        hasRemovedReceivers(): boolean {
            return !!this.removedReceiverUserIds.length || !!this.removedReceiverGroupIds.length;
        },

        outcomeReason(): CaseOutcomeReason | null {
            if (!this.auditEvent.outcomeReasonId) {
                return null;
            }

            return caseOutcomeReasonsStore.getCaseOutcomeReasonById(this.auditEvent.outcomeReasonId);
        },

        postponedUntilTimestamp(): string | null {
            if (!this.auditEvent.postponedUntil) {
                return null;
            }

            return formatInstant(this.auditEvent.postponedUntil, userSession.timeZone, userSession.locale, "S");
        },

        removedAssigneeIds(): string[] {
            return this.auditEvent.oldAssignedUserIds.filter(
                (assigneeId) => !this.auditEvent.newAssignedUserIds.includes(assigneeId)
            );
        },

        removedReceiverGroupIds(): string[] {
            return this.getSortedByEscalationGroupName(
                this.auditEvent.oldEscalationGroupReceiverIds.filter(
                    (groupId) => !this.auditEvent.newEscalationGroupReceiverIds.includes(groupId)
                )
            );
        },

        removedReceiverUserIds(): string[] {
            return this.getSortedByFullName(
                this.auditEvent.oldIndividualReceiverUserIds.filter(
                    (userId) => !this.auditEvent.newIndividualReceiverUserIds.includes(userId)
                )
            );
        },
    },

    methods: {
        getFullNameById(id: string) {
            return (getFullName(usersStore.getUserById(id)) || this.$t("Unbekannt")) as string;
        },

        getSortedByFullName(userIds: string[]): string[] {
            return [...userIds].sort((a, b) =>
                this.getFullNameById(a).localeCompare(this.getFullNameById(b), userSession.locale)
            );
        },

        getEscalationGroupNameById(id: string) {
            return (escalationGroupsStore.escalationGroupById(id)?.name ||
                this.$t("Gelöschte Eskalationsgruppe")) as string;
        },

        getSortedByEscalationGroupName(groupIds: string[]) {
            return [...groupIds].sort((a, b) =>
                this.getEscalationGroupNameById(a).localeCompare(this.getEscalationGroupNameById(b), userSession.locale)
            );
        },
    },

    components: {
        TimeSpan,
        UserLink,
        EscalationGroupLink,
    },
});
