
import UserDirectoryEntrySpan from "./UserDirectoryEntrySpan.vue";
import { UserPickerData } from "./userPickerCardTypes";
import { Department, DepartmentUser } from "@/api/departments";
import { UserDirectoryEntry } from "@/api/users";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import IntersectWrapper from "@/app/components/IntersectWrapper.vue";
import DealerLink from "@/app/pages/DealerLink.vue";
import { searchFilter } from "@/app/searchFilter";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { departmentsStore } from "@/store/departments";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

interface Entries {
    readonly departmentEntries: DepartmentEntry[];
    readonly usersWithoutDepartment: UserDirectoryEntry[];
}

interface DepartmentEntry {
    readonly id: string;
    readonly dealerId: string;
    readonly name: string;
    readonly departmentUserEntries: DepartmentUserEntry[];
}

interface DepartmentUserEntry {
    readonly departmentUser: DepartmentUser;
    readonly userDirectoryEntry: UserDirectoryEntry;
}

export default Vue.extend({
    props: {
        contextTitle: {
            type: String,
            required: true,
        },
        dealerId: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object as () => UserPickerData,
            required: true,
        },
    },

    data() {
        return {
            valueProp: cloneObject(this.value),
            users: [] as UserDirectoryEntry[],
        };
    },

    computed: {
        departments(): Department[] {
            // sort order: dealer, department
            return (!this.valueProp.global ? [this.dealerId] : dealersStore.dealers.map((d) => d.id))
                .map((dealerId) => departmentsStore.departmentsByDealer(dealerId))
                .reduce((prev, cur) => prev.concat(cur), []);
        },

        entries(): Entries {
            const departmentEntries: DepartmentEntry[] = [];

            for (const department of this.departments) {
                const departmentUserEntries = department.departmentUsers
                    .map((departmentUser) => {
                        return {
                            departmentUser,
                            userDirectoryEntry: this.unselectedUsers.find((u) => u.id === departmentUser.userId),
                        } as DepartmentUserEntry;
                    })
                    .filter((e) => !!e.userDirectoryEntry)
                    .filter((e) =>
                        this.hasSearchMatch(
                            this.valueProp.search,
                            e.userDirectoryEntry,
                            department.name,
                            e.departmentUser.position
                        )
                    );

                if (departmentUserEntries.length) {
                    departmentEntries.push({ ...department, departmentUserEntries });
                }
            }

            return {
                departmentEntries,
                usersWithoutDepartment: this.filteredUnselectedUsers.filter(
                    (u) => this.usersWithoutDepartment.indexOf(u) >= 0
                ),
            };
        },

        filteredUnselectedUsers(): UserDirectoryEntry[] {
            return this.unselectedUsers.filter((u) => this.hasSearchMatch(this.valueProp.search, u));
        },

        unselectedUsers(): UserDirectoryEntry[] {
            return this.users.filter((u) => this.valueProp.userIds.indexOf(u.id) < 0);
        },

        usersWithoutDepartment(): UserDirectoryEntry[] {
            return this.users.filter(
                (u) => !this.departments.find((d) => !!d.departmentUsers.find((du) => du.userId === u.id))
            );
        },
    },

    methods: {
        addUser(userId: string) {
            this.valueProp.userIds.push(userId);
            this.$emit("input", this.valueProp);
        },

        deleteUser(userId: string) {
            for (let i = 0; i < this.valueProp.userIds.length; i++) {
                if (this.valueProp.userIds[i] === userId) {
                    this.valueProp.userIds.splice(i, 1);
                    this.$emit("input", this.valueProp);
                    return;
                }
            }
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },

        hasSearchMatch(search: string | null, user: UserDirectoryEntry, ...values: (string | null | undefined)[]) {
            const userPhoneNumbers = [] as string[];

            user.phoneNumbers
                .map((n) => n.number)
                .filter((n) => !!n)
                .forEach((n) => {
                    userPhoneNumbers.push(n);

                    const formatted = parseAndFormatNumber(n, "NATIONAL");
                    const normalized = !!formatted ? formatted.replace(/[^\d]/g, "") : null;

                    if (!normalized) {
                        return;
                    }

                    userPhoneNumbers.push(normalized);
                });

            return searchFilter(search, user.username, getFullName(user), ...values, ...userPhoneNumbers);
        },

        refreshUsers() {
            let users: UserDirectoryEntry[];

            if (this.valueProp.global) {
                users = usersStore.getUsersByDealer(null);
            } else {
                users = usersStore.getUsersByDealer(this.dealerId);
            }

            this.users = [...users].sort((a, b) => getFullName(a).localeCompare(getFullName(b), userSession.locale));
        },
    },

    watch: {
        value: function() {
            this.valueProp = cloneObject(this.value);
            this.refreshUsers();
        },

        dealerId() {
            this.refreshUsers();
        },
    },

    mounted() {
        this.refreshUsers();
    },

    components: {
        DealerLink,
        DToolbarCard,
        IntersectWrapper,
        UserDirectoryEntrySpan,
    },
});
