
import OpportunityUrlDialog from "./OpportunityUrlDialog.vue";
import { opportunitiesApi, Opportunity, OpportunityUrl } from "@/api/opportunities";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import { showConfirm } from "@/app/messageUtil";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            updating: false,
            urlDialogVisible: false,
            urlToEdit: null as OpportunityUrl | null,
            expanded: !!this.opportunityProp.urls.length || null,
        };
    },

    computed: {
        urls(): OpportunityUrl[] {
            return this.opportunityProp.urls;
        },
    },

    watch: {
        urls() {
            if (this.expanded === null && this.urls.length) {
                // expand only if the user has not changed the expansion state manually
                this.expanded = true;
            }
        },
    },

    methods: {
        showAddUrlDialog() {
            this.urlToEdit = null;
            this.urlDialogVisible = true;
        },

        showEditUrlDialog(url: OpportunityUrl) {
            this.urlToEdit = url;
            this.urlDialogVisible = true;
        },

        async upsertUrl(url: OpportunityUrl) {
            this.urlDialogVisible = false;
            this.updating = true;
            const urls = [
                ...this.urls.filter((item) =>
                    url.key ? item.key !== url.key : !!item.key || item.url !== (this.urlToEdit?.url ?? url.url)
                ),
                url,
            ];
            try {
                await opportunitiesApi.updateUrls(this.opportunityProp.id, urls);
            } finally {
                this.updating = false;
            }
        },

        async deleteUrl(url: OpportunityUrl) {
            if (
                await showConfirm(
                    this.$t("Link löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Link löschen möchten?") as string
                )
            ) {
                this.updating = true;
                try {
                    const urls = [
                        ...this.urls.filter((item) =>
                            url.key ? item.key !== url.key : !!item.key || item.url !== url.url
                        ),
                    ];
                    await opportunitiesApi.updateUrls(this.opportunityProp.id, urls);
                } finally {
                    this.updating = false;
                }
            }
        },
    },

    components: {
        DToolbarCard,
        OpportunityUrlDialog,
    },
});
