
import EmergencyIncidentContactVehicleFormCard from "./EmergencyIncidentContactVehicleFormCard.vue";
import { Contact, ContactVehicle } from "@/api/contacts";
import ContactCard from "@/app/pages/contacts/ContactCard.vue";
import ContactVehicleCard from "@/app/pages/contacts/ContactVehicleCard.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        emergencyIncidentId: {
            type: String,
            required: true,
        },
        vehicle: {
            type: Object as () => ContactVehicle | null,
            required: false,
        },
    },

    data() {
        return {
            editMode: false,
        };
    },

    computed: {
        contactId(): string | null {
            if (!this.contact || this.contact.deleted) {
                return null;
            }

            return this.contact.id;
        },

        contactVehicleId(): string | null {
            if (!this.contactId || !this.vehicle || this.vehicle.deleted) {
                return null;
            }

            return this.vehicle.id;
        },
    },

    components: {
        ContactCard,
        ContactVehicleCard,
        EmergencyIncidentContactVehicleFormCard,
    },
});
