import { render, staticRenderFns } from "./OutgoingWhatsAppMedia.vue?vue&type=template&id=33fa4876&scoped=true&"
import script from "./OutgoingWhatsAppMedia.vue?vue&type=script&lang=ts&"
export * from "./OutgoingWhatsAppMedia.vue?vue&type=script&lang=ts&"
import style0 from "./OutgoingWhatsAppMedia.vue?vue&type=style&index=0&id=33fa4876&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33fa4876",
  null
  
)

export default component.exports