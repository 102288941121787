
import Vue from "vue";

export default Vue.extend({
    props: {
        tag: {
            type: String,
            default: "div",
        },
    },

    data() {
        return {
            intersecting: false,
        };
    },

    methods: {
        onIntersect(_: IntersectionObserverEntry[], __: IntersectionObserver, isIntersecting: boolean) {
            this.intersecting = isIntersecting;
        },
    },
});
