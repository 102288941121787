
import ExternalAbsenceProviderFormDialog from "./ExternalAbsenceProviderFormDialog.vue";
import { ExternalAbsenceProvider, externalAbsenceProvidersApi } from "@/api/externalAbsenceProviders";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import { showConfirm } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            formVisible: false,
            formItem: null as ExternalAbsenceProvider | null,
            items: [] as ExternalAbsenceProvider[],
            loading: true,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Anbieter"),
                    width: "45%",
                },
                {
                    text: this.$t("Händler"),
                    width: "45%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "10%",
                },
            ];
        },
    },

    methods: {
        async deleteItem(id: string) {
            if (
                await showConfirm(
                    this.$t("Externe Abwesenheitsquelle löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie diese Quelle löschen möchten?") as string
                )
            ) {
                await externalAbsenceProvidersApi.delete(id);
                await this.loadItems();
            }
        },

        async loadItems() {
            this.loading = true;

            try {
                this.items = await externalAbsenceProvidersApi.list();
            } finally {
                this.loading = false;
            }
        },

        showCreateForm() {
            this.formItem = null;
            this.formVisible = true;
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name || this.$t("Gesamte Händlergruppe");
        },

        async showEditForm(externalAbsenceProvider: ExternalAbsenceProvider) {
            this.formItem = externalAbsenceProvider;
            this.formVisible = true;
        },

        async savedItem() {
            this.formVisible = false;
            await this.loadItems();
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        ExternalAbsenceProviderFormDialog,
        DealerGlobalContextHint,
        DataTable,
    },
});
