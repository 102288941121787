import { Contact } from "./contacts";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum IncomingCallSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
}

export enum IncomingCallStatus {
    RINGING = "RINGING",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    NO_ANSWER = "NO_ANSWER",
}

export enum IssueType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
    UNKNOWN = "UNKNOWN",
}

export enum IncomingCallEventStatus {
    INIT = "INIT",
    RINGING = "RINGING",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    BUSY = "BUSY",
    FAILED = "FAILED",
    NO_ANSWER = "NO_ANSWER",
    CANCELED = "CANCELED",
}

export interface IncomingCallEvent {
    readonly callId: string;
    readonly inbound: boolean;
    readonly fromNumber: string | null;
    readonly toNumber: string;
    readonly toUserId: string | null;
    readonly status: IncomingCallEventStatus;
    readonly timestamp: Date;
    readonly internalIncomingPhoneNumberId: string | null;
}

export interface IncomingCallResult {
    readonly id: string;
    readonly created: Date;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly dealerId: string;
    readonly internalIncomingPhoneNumberId: string | null;
    readonly internalIncomingPhoneNumberName: string | null;
    readonly fromNumber: string | null;
    readonly fromNumberBlocked: boolean;
    readonly toNumber: string | null;
    readonly accepted: Date | null;
    readonly acceptorUserId: string | null;
    readonly ended: Date | null;
    readonly released: Date | null;
    readonly note: string | null;
    readonly resolvedCaseId: string | null;
    readonly resolvedOpportunityId: string | null;
    readonly resolvedContactId: string | null;
    readonly incomingCallStatus: IncomingCallStatus;
    readonly ringingUserIds: string[];
    readonly events: IncomingCallEvent[];
    readonly forwardedInternalIncomingPhoneNumberIds: string[];
}

export interface IncomingCallResultAndContact {
    readonly incomingCallResult: IncomingCallResult;
    readonly contact: Contact | null;
}

export interface IncomingCallSearchRequest {
    readonly dealerIds: string[];
    readonly issueTypes: IssueType[];
    readonly acceptorUserIds: string[];
    readonly internalIncomingPhoneNumberIds: string[];
    readonly fromNumbers: string[];
    readonly fromNumberBlocked: boolean | null;
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly sortBy: IncomingCallSearchOrder;
}

export interface IncomingCallSearchResults {
    readonly results: IncomingCallResultAndContact[];
    readonly searchId: number;
    readonly totalSize: number;
}

interface IncomingCallsApi {
    getAllActiveIncomingCalls(): Promise<IncomingCallResult[]>;
    getIncomingCallsByCase(caseId: string): Promise<IncomingCallResult[]>;
    getIncomingCallsByOpportunity(opportunityId: string): Promise<IncomingCallResult[]>;
    getMyCalls(start: number, size: number, searchId: number): Promise<IncomingCallSearchResults>;
    search(
        start: number,
        size: number,
        searchRequest: IncomingCallSearchRequest,
        searchId: number
    ): Promise<IncomingCallSearchResults>;
    setNote(id: string, note: string): Promise<void>;
    releaseCall(id: string): Promise<void>;
}

export const incomingCallsApi: IncomingCallsApi = {
    async getAllActiveIncomingCalls() {
        return cloneObject((await axios.get("/api/incoming-calls/_active-calls")).data);
    },

    async getIncomingCallsByCase(caseId) {
        return cloneObject((await axios.get(`/api/incoming-calls/cases/${caseId}`)).data);
    },

    async getIncomingCallsByOpportunity(opportunityId) {
        return cloneObject((await axios.get(`/api/incoming-calls/opportunities/${opportunityId}`)).data);
    },

    async getMyCalls(start, size, searchId) {
        return cloneObject(
            (
                await axios.get("/api/incoming-calls/_my-calls", {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/incoming-calls/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },

    async setNote(id, note) {
        await axios.patch(`/api/incoming-calls/${id}`, null, { params: { note } });
    },

    async releaseCall(id) {
        await axios.post(`/api/incoming-calls/${id}/_release`);
    },
};
