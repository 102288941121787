
import {
    NotificationReason,
    NotificationType,
    userNotificationSettingsApi,
    UserNotificationSettingsItem,
} from "@/api/userNotificationSettings";
import { userSession } from "@/store/userSession";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            activeWorkers: 0,
            loading: true,
            userNotificationSettings: [] as UserNotificationSettingsItem[],
            NotificationType,
        };
    },

    computed: {
        userId(): string | null {
            return trimAndReturnNullIfEmpty(this.$route.query.userId as string | null) ?? userSession.userId ?? null;
        },
    },

    methods: {
        getUserNotificationSettingsItemByReason(notificationReason: string) {
            return this.userNotificationSettings.find((n) => n.notificationReason === notificationReason);
        },

        getToggleIcon(type: NotificationType) {
            const selected = this.getNumberOfSelectedSettings(type);
            if (selected === 0) {
                return "mdi-toggle-switch-off-outline";
            }
            if (selected === this.userNotificationSettings.length) {
                return "mdi-toggle-switch";
            }
            return "mdi-toggle-switch-outline";
        },

        getToggleTooltip(type: NotificationType) {
            return this.getNumberOfSelectedSettings(type) === this.userNotificationSettings.length
                ? this.$t("alles deaktivieren")
                : this.$t("alles aktivieren");
        },

        getNumberOfSelectedSettings(type: NotificationType) {
            if (type === NotificationType.DESKTOP_NOTIFICATION) {
                return this.userNotificationSettings.filter((s) => s.desktopNotification).length;
            }
            if (type === NotificationType.EMAIL_WITHIN_PRESENCE) {
                return this.userNotificationSettings.filter((s) => s.emailWithinPresence).length;
            }
            if (type === NotificationType.EMAIL_OUTSIDE_PRESENCE) {
                return this.userNotificationSettings.filter((s) => s.emailOutsidePresence).length;
            }
            return 0;
        },

        async loadItems() {
            this.userNotificationSettings = [];

            if (!this.userId) {
                return;
            }

            this.loading = true;
            try {
                const userNotificationSettings = await userNotificationSettingsApi.getByUser(this.userId);

                this.userNotificationSettings = Object.keys(NotificationReason)
                    .map((reason) => userNotificationSettings.find((s) => s.notificationReason === reason))
                    .filter((s) => s)
                    .map((s) => s as UserNotificationSettingsItem);
            } finally {
                this.loading = false;
            }
        },

        async saveNotificationSettingsItem(notificationReason: string) {
            this.activeWorkers++;
            try {
                await userNotificationSettingsApi.edit(
                    this.userId!,
                    this.getUserNotificationSettingsItemByReason(notificationReason)!
                );
            } finally {
                this.activeWorkers--;
            }
        },

        async toggleSettings(type: NotificationType) {
            const enabled = this.getNumberOfSelectedSettings(type) < this.userNotificationSettings.length;
            for (const setting of this.userNotificationSettings) {
                if (type === NotificationType.DESKTOP_NOTIFICATION) {
                    setting.desktopNotification = enabled;
                }
                if (type === NotificationType.EMAIL_WITHIN_PRESENCE) {
                    setting.emailWithinPresence = enabled;
                }
                if (type === NotificationType.EMAIL_OUTSIDE_PRESENCE) {
                    setting.emailOutsidePresence = enabled;
                }
            }

            this.activeWorkers++;
            try {
                await userNotificationSettingsApi.editByType(this.userId!, type, enabled);
            } finally {
                this.activeWorkers--;
            }
        },
    },

    watch: {
        async userId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },
});
