
import { carryDispoApi, StatusResult } from "@/api/carryDispo";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        externOrderId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            loading: true,
            items: [] as StatusResult[],
        };
    },

    methods: {
        async loadItems() {
            this.items = [];
            this.loading = true;

            try {
                if (userSession.dealerId) {
                    this.items = await carryDispoApi.getExternOrderStatusResults(
                        userSession.dealerId,
                        this.externOrderId
                    );
                }
            } finally {
                this.loading = false;
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },
});
