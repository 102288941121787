import { render, staticRenderFns } from "./UserDetailsBottomSheet.vue?vue&type=template&id=396c8b51&scoped=true&"
import script from "./UserDetailsBottomSheet.vue?vue&type=script&lang=ts&"
export * from "./UserDetailsBottomSheet.vue?vue&type=script&lang=ts&"
import style0 from "./UserDetailsBottomSheet.vue?vue&type=style&index=0&id=396c8b51&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396c8b51",
  null
  
)

export default component.exports