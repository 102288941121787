
import MyOpportunitiesDataTable from "./MyOpportunitiesDataTable.vue";
import OpportunitiesDataTable from "./OpportunitiesDataTable.vue";
import { EMPTY_SEARCH_REQUEST } from "./opportunitySearchUtils";
import { OpportunitiesSort, OpportunityVisibilityArea } from "@/api/opportunities";
import { OpportunitySearchOrder, OpportunitySearchRequest } from "@/api/opportunitySearch";
import EnumField from "@/app/components/EnumField.vue";
import { opportunityStatusStore } from "@/store/opportunityStatus";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            activeTab: 0,
            emptyOpportunitySearchRequest: EMPTY_SEARCH_REQUEST,
            OpportunitiesSort,
            opportunitySearch: null as string | null,
            OpportunitySearchOrder,
            opportunityStatusStore,
            preferences: userSession.preferences!.myOpportunitiesPreferences,
        };
    },

    computed: {
        opportunitiesSort(): OpportunitiesSort {
            if (this.activeTab === 0) {
                return this.preferences.activeOpportunitiesSortBy!;
            } else if (this.activeTab === 1) {
                return OpportunitiesSort.POSTPONED_UNTIL_ASC;
            } else if (this.activeTab === 2) {
                return this.preferences.createdOpportunitiesSortBy!;
            } else if (this.activeTab === 3) {
                return this.preferences.closedOpportunitiesSortBy!;
            } else {
                return this.preferences.activeOpportunitiesSortBy!;
            }
        },

        opportunitySearchRequest(): OpportunitySearchRequest {
            return {
                ...this.emptyOpportunitySearchRequest,
                search: this.opportunitySearch || "",
                sortBy: this.preferences.searchOpportunitiesSortBy!,
            };
        },

        opportunityVisibilityArea() {
            if (this.activeTab === 0) {
                return OpportunityVisibilityArea.ACTIVE;
            } else if (this.activeTab === 1) {
                return OpportunityVisibilityArea.POSTPONED;
            } else if (this.activeTab === 2) {
                return OpportunityVisibilityArea.CREATED;
            } else if (this.activeTab === 3) {
                return OpportunityVisibilityArea.CLOSED;
            } else {
                return OpportunityVisibilityArea.ACTIVE;
            }
        },
    },

    methods: {
        async refreshSearch() {
            this.emptyOpportunitySearchRequest = { ...EMPTY_SEARCH_REQUEST };
        },
    },

    components: {
        EnumField,
        OpportunitiesDataTable,
        MyOpportunitiesDataTable,
    },
});
