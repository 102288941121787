
import { ReportingSettings, reportingSettingsApi } from "@/api/reportingSettings";
import { Permission } from "@/api/userSession";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { caseOutcomeReasonsStore } from "@/store/caseOutcomeReasons";
import { opportunityOutcomeReasonsStore } from "@/store/opportunityOutcomeReasons";
import { userSession } from "@/store/userSession";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            Permission,
            reportingSettings: null as ReportingSettings | null,
            saving: false,
        };
    },

    computed: {
        dealerId(): string | null {
            return userSession.dealerId;
        },

        caseOutcomeReasonOptions(): SelectOption[] {
            return caseOutcomeReasonsStore.caseOutcomeReasons.map((r) => ({
                value: r.id,
                text: r.name,
            }));
        },

        opportunityOutcomeReasonOptions(): SelectOptions {
            const won = opportunityOutcomeReasonsStore.opportunityOutcomeReasons.filter((r) => r.won);
            const notWon = opportunityOutcomeReasonsStore.opportunityOutcomeReasons.filter((r) => !r.won);

            const options: SelectOptions = [];

            if (won.length) {
                options.push(
                    { header: this.$t("Erfolgreich") },
                    ...won.map((r) => ({
                        value: r.id,
                        text: `${r.name}`,
                    }))
                );
            }

            if (notWon.length) {
                options.push(
                    { header: this.$t("Erfolglos") },
                    ...notWon.map((r) => ({
                        value: r.id,
                        text: `${r.name}`,
                    }))
                );
            }

            return options;
        },

        working(): boolean {
            return this.loading || this.saving;
        },
    },

    methods: {
        async loadSettings() {
            this.loading = true;
            try {
                this.reportingSettings = await reportingSettingsApi.getReportingSettings();
            } finally {
                this.loading = false;
            }
        },

        async submit() {
            if (!this.reportingSettings || !(this.$refs.reportingsettingsform as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await reportingSettingsApi.editReportingSettings(this.reportingSettings);
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        await this.loadSettings();
    },

    components: {
        DealerGlobalContextHint,
    },
});
