import { AutoCrmLead } from "@/api/autoCrmLeadMailers";
import { ContactSalutation } from "@/api/contacts";
import { ExteriorColor } from "@/api/inventory";
import { reactive } from "@/util/reactive";
import { YearMonthDay } from "@/util/types";

export interface ReceiverIdAndCategory {
    readonly receiverId: string | null;
    readonly category: string | null;
}

@reactive
class AutoCrmLeadMailAssistantState {
    // contact
    public contactSalutation: ContactSalutation | null = null;
    public contactCompanyName: string | null = null;
    public contactGivenName: string | null = null;
    public contactFamilyName: string | null = null;
    public contactAddress2: string | null = null;
    public contactZip: string | null = null;
    public contactCity: string | null = null;
    public contactEmailAddress: string | null = null;
    public contactPhoneNumber: string | null = null;

    // vehicle
    public vehicleInternalId: string | null = null;
    public vehicleMake: string | null = null;
    public vehicleModel: string | null = null;
    public vehicleModelDescription: string | null = null;
    public vehiclePower: number | null = null;
    public vehicleFirstRegistration: YearMonthDay = { year: null, month: null, day: null };
    public vehicleMileage: number | null = null;
    public vehiclePrice: number | null = null;
    public vehicleExteriorColor: ExteriorColor | null = null;

    // misc
    public receiverIdAndCategory: ReceiverIdAndCategory | null = null;
    public message: string | null = null;

    // category and receiver picker
    public categoryAndReceiverPickerGlobal = false;
    public categoryAndReceiverPickerSearch: string | null = null;

    get autoCrmLead(): AutoCrmLead {
        return {
            contact: {
                salutation: this.contactSalutation,
                companyName: this.contactCompanyName,
                givenName: this.contactGivenName,
                familyName: this.contactFamilyName,
                address2: this.contactAddress2,
                zip: this.contactZip,
                city: this.contactCity,
                emailAddress: this.contactEmailAddress,
                phoneNumber: this.contactPhoneNumber,
            },
            vehicle: {
                internalId: this.vehicleInternalId,
                make: this.vehicleMake,
                model: this.vehicleModel,
                modelDescription: this.vehicleModelDescription,
                power: this.vehiclePower,
                firstRegistrationDay: this.vehicleFirstRegistration.day,
                firstRegistrationMonth: this.vehicleFirstRegistration.month,
                firstRegistrationYear: this.vehicleFirstRegistration.year,
                mileage: this.vehicleMileage,
                price: this.vehiclePrice,
                exteriorColor: this.vehicleExteriorColor,
            },
            receiverId: this.receiverIdAndCategory?.receiverId ?? null,
            category: this.receiverIdAndCategory?.category ?? null,
            message: this.message,
        };
    }

    reset() {
        this.contactSalutation = null;
        this.contactCompanyName = null;
        this.contactGivenName = null;
        this.contactFamilyName = null;
        this.contactAddress2 = null;
        this.contactZip = null;
        this.contactCity = null;
        this.contactEmailAddress = null;
        this.contactPhoneNumber = null;

        this.vehicleInternalId = null;
        this.vehicleMake = null;
        this.vehicleModel = null;
        this.vehicleModelDescription = null;
        this.vehiclePower = null;
        this.vehicleFirstRegistration = { year: null, month: null, day: null };
        this.vehicleMileage = null;
        this.vehiclePrice = null;
        this.vehicleExteriorColor = null;

        this.receiverIdAndCategory = null;
        this.message = null;

        this.categoryAndReceiverPickerGlobal = false;
        this.categoryAndReceiverPickerSearch = null;
    }
}

export const autoCrmLeadMailAssistantState = new AutoCrmLeadMailAssistantState();
