
import { ExternalFinanceContractParsed } from "@/api/financeContractUploads";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { renderVehicleTitle } from "@/app/contactUtils";
import { userSession } from "@/store/userSession";
import { formatLocalDate, getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        items: Array as () => ExternalFinanceContractParsed[],
        totalSize: Number,
        pageSize: Number,
    },

    data() {
        return {
            paging: {
                page: 1,
                pageSize: this.pageSize,
                totalSize: this.totalSize,
                maxTotalSize: this.totalSize + 1,
                maxPage: Math.ceil(this.items.length / this.pageSize),
            } as DataTablePaging,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("ID"),
                    width: "5%",
                },
                {
                    text: this.$t("Vertrag"),
                    width: "20%",
                },
                {
                    text: this.$t("Kontakt"),
                    width: "20%",
                },
                {
                    text: this.$t("Fahrzeug"),
                    width: "25%",
                },
                {
                    text: this.$t("Notizen"),
                    width: "30%",
                },
            ];
        },

        pagedItems(): ExternalFinanceContractParsed[] {
            const i = this.paging.page - 1;
            return this.items.slice(i * this.paging.pageSize, (i + 1) * this.paging.pageSize);
        },
    },

    methods: {
        formatLocalDate(date: Date): string {
            return formatLocalDate(getDate(date, userSession.timeZone), userSession.locale, "L");
        },

        page(paging: DataTablePaging) {
            this.paging = { ...paging };
        },

        renderVehicleTitle(item: ExternalFinanceContractParsed): string {
            return renderVehicleTitle(
                {
                    licensePlate: item.licensePlate,
                    make: item.make,
                    model: item.model,
                    modelDescription: item.modelDescription,
                    modelYear: null,
                },
                false
            );
        },
    },

    components: {
        CopyToClipboardIcon,
        DataTable,
    },
});
