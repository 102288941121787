
import EmergencyIncidentDataCheckCard from "./EmergencyIncidentDataCheckCard.vue";
import { emergencyIncidentAssistantState } from "./emergencyIncidentAssistant";
import EmergencyIncidentAdditionalDataCard from "./singlepage/EmergencyIncidentAdditionalDataCard.vue";
import EmergencyIncidentContactCard from "./singlepage/EmergencyIncidentContactCard.vue";
import EmergencyIncidentDataCard from "./singlepage/EmergencyIncidentDataCard.vue";
import EmergencyIncidentLocationCard from "./singlepage/EmergencyIncidentLocationCard.vue";
import { DealerInstructionCategory, IssueType } from "@/api/dealerInstructions";
import { EmergencyIncidentCreationForm, emergencyIncidentsApi } from "@/api/emergencyIncidents";
import { processQueryParameters } from "@/app/issueAssistantUtils";
import { showConfirm, showError } from "@/app/messageUtil";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import DealerInstructions from "@/app/pages/dealerinstructions/DealerInstructions.vue";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            IssueType,
            adding: false,
            emergencyIncidentAssistantState,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        dealerInstructionCategories(): DealerInstructionCategory[] {
            if (this.emergencyIncidentAssistantState.contactAndVehicleId) {
                return [DealerInstructionCategory.DATA_ACQUISITION, DealerInstructionCategory.RECEIVER_SELECTION];
            }

            return [
                DealerInstructionCategory.CONTACT_SEARCH,
                DealerInstructionCategory.DATA_ACQUISITION,
                DealerInstructionCategory.RECEIVER_SELECTION,
            ];
        },

        isAgent(): boolean {
            return userSession.isAgent();
        },

        phoneticSpellingLocale(): string {
            return configStore.configuration.defaultLocale;
        },
    },

    methods: {
        async addEmergencyIncident() {
            if (!(this.$refs.form1 as any).validate() || !(this.$refs.form2 as any).validate()) {
                showError(this.$t("Bitte korrigieren Sie Ihre fehlerhaften Eingaben.") as string);
                return;
            }

            if (!emergencyIncidentAssistantState.contactAndVehicleId?.contactId) {
                await showError(this.$t("Es ist kein Kontakt ausgewählt!") as string);
                return;
            }

            if (!emergencyIncidentAssistantState.contactAndVehicleId?.contactVehicleId) {
                await showError(this.$t("Es ist kein Fahrzeug ausgewählt!") as string);
                return;
            }

            const emergencyIncidentCreationForm: EmergencyIncidentCreationForm = {
                dealerId: emergencyIncidentAssistantState.dealerId!,
                type: emergencyIncidentAssistantState.type,
                contactId: emergencyIncidentAssistantState.contactAndVehicleId!.contactId,
                contactVehicleId: emergencyIncidentAssistantState.contactAndVehicleId!.contactVehicleId!,
                contactNumber: emergencyIncidentAssistantState.contactNumber || null,
                costIndicationGiven: emergencyIncidentAssistantState.costIndicationGiven,
                caller: emergencyIncidentAssistantState.caller,
                externalIncidentNumber: emergencyIncidentAssistantState.externalIncidentNumber,
                damage: emergencyIncidentAssistantState.damage,
                location: emergencyIncidentAssistantState.location,
                serviceProvider: emergencyIncidentAssistantState.serviceProvider,
                towingTarget: emergencyIncidentAssistantState.towingTarget,
            };

            this.adding = true;
            try {
                const emergencyIncidentId = await emergencyIncidentsApi.addEmergencyIncident(
                    emergencyIncidentCreationForm,
                    emergencyIncidentAssistantState.initialNoteContent!
                );

                emergencyIncidentAssistantState.reset(null, null);
                await this.$router.push(`/emergency-incident/${emergencyIncidentId}`);
            } finally {
                this.adding = false;
            }
        },

        async reset() {
            if (
                await showConfirm(
                    this.$t("Eingaben zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?") as string
                )
            ) {
                emergencyIncidentAssistantState.reset(null, null);
            }
        },
    },

    watch: {
        dealerId() {
            this.emergencyIncidentAssistantState.dealerId = this.dealerId;
        },
    },

    created() {
        if (!emergencyIncidentAssistantState.dealerId && this.dealerId) {
            emergencyIncidentAssistantState.dealerId = this.dealerId;
        }
    },

    beforeRouteEnter(to, _, next) {
        processQueryParameters(to, next);
    },

    components: {
        DealerContextWarning,
        DealerInstructions,
        EmergencyIncidentAdditionalDataCard,
        EmergencyIncidentContactCard,
        EmergencyIncidentDataCard,
        EmergencyIncidentDataCheckCard,
        EmergencyIncidentLocationCard,
    },
});
