
import IssueDataCheckCardDataTableRow from "./IssueDataCheckCardDataTableRow.vue";
import UserLink from "./UserLink.vue";
import { getContactDetailsLabel, PreferredContact } from "./contacts/preferredContact";
import {
    ContactData,
    ContactMethod,
    ContactSalutation,
    ContactType,
    ContactVehicleData,
    ContactVehicleRecord,
} from "@/api/contacts";
import { Dealer } from "@/api/dealers";
import { ServiceOffered, servicesOfferedApi } from "@/api/servicesOffered";
import { UserDirectoryEntry } from "@/api/users";
import { renderMileage } from "@/app/contactUtils";
import { dealersStore } from "@/store/dealers";
import { makeModelsStore } from "@/store/makeModels";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant, getDate } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        checkContactVehicleData: {
            type: Boolean,
            default: false,
        },
        contactData: {
            type: Object as () => ContactData | null,
            default: null,
        },
        contactVehicleData: {
            type: Object as () => ContactVehicleData | null,
            default: null,
        },
        dealerId: {
            type: String as () => string | null,
            default: null,
        },
        individualReceiverUserIds: {
            type: Array as () => string[],
            default: () => [],
        },
        phoneticSpellingLocale: {
            type: String,
            required: true,
        },
        preferredContact: {
            type: Object as () => PreferredContact | null,
            default: null,
        },
        preferredContactTimeZone: {
            type: String as () => string | null,
            default: null,
        },
    },

    data() {
        return {
            ContactMethod,
            ContactType,
            loadingServicesOffered: false,
            now: now(),
            renderMileage,
            servicesOffered: [] as ServiceOffered[],
        };
    },

    computed: {
        contactCompanyName(): string | null {
            return trimAndReturnNullIfEmpty(this.contactData?.companyName);
        },

        contactDetails(): string | null {
            if (
                !this.contactDetailsLabel ||
                !trimAndReturnNullIfEmpty(this.preferredContact?.preferredContactDetails)
            ) {
                return null;
            }

            if (this.contactDetailsMethod === ContactMethod.PHONE || this.contactDetailsMethod === ContactMethod.SMS) {
                return trimAndReturnNullIfEmpty(
                    parseAndFormatNumber(this.preferredContact!.preferredContactDetails!, "INTERNATIONAL")
                );
            }

            if (this.contactDetailsMethod === ContactMethod.EMAIL) {
                return trimAndReturnNullIfEmpty(this.preferredContact!.preferredContactDetails);
            }

            return null;
        },

        contactDetailsLabel(): string | null {
            if (!this.contactDetailsMethod) {
                return null;
            }

            return getContactDetailsLabel(this.contactDetailsMethod);
        },

        contactDetailsMethod(): ContactMethod | null {
            return this.preferredContact?.preferredContactMethod ?? null;
        },

        contactFamilyName(): string | null {
            return trimAndReturnNullIfEmpty(this.contactData?.familyName);
        },

        contactGivenName(): string | null {
            return trimAndReturnNullIfEmpty(this.contactData?.givenName);
        },

        contactSalutation(): ContactSalutation | null {
            return this.contactData?.salutation ?? null;
        },

        contactType(): ContactType | null {
            return this.contactData?.type ?? null;
        },

        contactVehicleLicensePlate(): string | null {
            return trimAndReturnNullIfEmpty(this.contactVehicleData?.licensePlate);
        },

        contactVehicleMake(): string | null {
            return trimAndReturnNullIfEmpty(this.contactVehicleData?.make);
        },

        contactVehicleMakeHasModels(): boolean {
            if (!this.contactVehicleMake) {
                return false;
            }

            return !!makeModelsStore.getModelsByMake(this.contactVehicleMake).length;
        },

        contactVehicleModel(): string | null {
            return trimAndReturnNullIfEmpty(this.contactVehicleData?.model);
        },

        contactVehicleTodaysRecord(): ContactVehicleRecord | null {
            return this.contactVehicleData?.records.find((r) => r.date === this.currentDate) ?? null;
        },

        currentDate(): string {
            return getDate(this.now, userSession.timeZone);
        },

        dealer(): Dealer | null {
            if (!this.dealerId) {
                return null;
            }

            return dealersStore.dealerById(this.dealerId);
        },

        dealerTimeZone(): string {
            return dealersStore.dealerById(this.dealerId!)!.timeZone;
        },

        individualReceivers(): (UserDirectoryEntry | null)[] {
            return this.individualReceiverUserIds.map((userId) => usersStore.getUserById(userId));
        },

        isPhoneticSpellingTooltipVisible(): boolean {
            return userSession.isCtUser();
        },

        isServiceOfferedForContactVehicle(): boolean | null {
            const vehicleServicesOffered = this.servicesOffered.filter((s) => s.make);

            if (!this.contactVehicleMake || !vehicleServicesOffered.length) {
                return null;
            }

            return vehicleServicesOffered.some((s) => s.make === this.contactVehicleMake);
        },

        preferredEarliestContactTime(): Date | null {
            return this.preferredContact?.earliestContactTime ?? null;
        },

        preferredLatestContactTime(): Date | null {
            return this.preferredContact?.latestContactTime ?? null;
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadServicesOffered();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    methods: {
        formatInstant(date: Date | null, timeZone: string | null): string | null {
            if (!date || !timeZone) {
                return null;
            }

            return formatInstant(date, timeZone, userSession.locale, "S");
        },

        isBefore(date1: Date, date2: Date): boolean {
            return date1.getTime() < date2.getTime();
        },

        isInFuture(date: Date | null): boolean {
            return !!date && this.isBefore(this.now, date);
        },

        isUserOfDealer(user: UserDirectoryEntry): boolean {
            return (
                user.mainDealerId === this.dealerId || user.dealerAssignments.some((a) => a.dealerId === this.dealerId)
            );
        },

        async loadServicesOffered() {
            this.servicesOffered = [];

            if (!this.dealerId || !this.checkContactVehicleData) {
                return;
            }

            this.loadingServicesOffered = true;
            try {
                this.servicesOffered = await servicesOfferedApi.getByDealer(this.dealerId);
            } finally {
                this.loadingServicesOffered = false;
            }
        },
    },

    async mounted() {
        await this.loadServicesOffered();
    },

    components: {
        IssueDataCheckCardDataTableRow,
        UserLink,
    },
});
