
import { inventoryApi, InventoryVehicleStatusForm, InventoryVehicleStatusType } from "@/api/inventory";
import EnumField from "@/app/components/EnumField.vue";
import { maxLength } from "@/app/validation";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        inventoryVehicleId: {
            type: String,
            required: true,
        },
        currentStatusType: {
            type: String as () => InventoryVehicleStatusType,
            required: true,
        },
    },

    data() {
        return {
            isWorking: false,
            type: cloneObject(this.currentStatusType) as InventoryVehicleStatusType,
            InventoryVehicleStatusType,
            comment: "",
            commentRules: maxLength(8000),
        };
    },

    methods: {
        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            const form: InventoryVehicleStatusForm = {
                type: this.type,
                comment: this.comment,
            };

            try {
                await inventoryApi.addStatus(this.inventoryVehicleId, form);
                this.$emit("success", this.inventoryVehicleId, this.type);
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },
    },

    components: {
        EnumField,
    },
});
