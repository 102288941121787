
import DealerStubFormDialog from "./DealerStubFormDialog.vue";
import { BillingAccount, billingAccountsApi } from "@/api/billingAccounts";
import { Dealer, dealersApi } from "@/api/dealers";
import { Permission } from "@/api/userSession";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import { showInfo } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import InitializeDealerSettingsDialog from "@/app/pages/initialization/InitializeDealerSettingsDialog.vue";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            dealerStubFormDialogVisible: false,
            dealerStubFormDialogDealer: null as Dealer | null,
            billingAccounts: [] as BillingAccount[],
            billingAccountsMap: new Map<number, BillingAccount>(),
            dealers: [] as Dealer[],
            initializeDealerIds: [] as string[],
            initializeDealerSettingsDialogVisible: false,
            loading: true,
        };
    },

    computed: {
        canInitializeDealer(): boolean {
            return userSession.hasPermission(Permission.INITIALIZE_PARTITION);
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Standort"),
                    width: "30%",
                },
                {
                    text: this.$t("Externe BDC-Ziele"),
                    width: "60%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "10%",
                },
            ];
        },
    },

    async mounted() {
        await this.loadItems();
    },

    methods: {
        closeInitializeDealerSettingsDialog() {
            this.initializeDealerSettingsDialogVisible = false;
            this.initializeDealerIds = [];
        },

        async loadItems() {
            this.loading = true;
            try {
                this.billingAccounts = await billingAccountsApi.list();
                this.billingAccountsMap.clear();
                for (const billingAccount of this.billingAccounts) {
                    this.billingAccountsMap.set(billingAccount.id, billingAccount);
                }
                this.dealers = await dealersApi.list();
            } finally {
                this.loading = false;
            }
        },

        showCreateForm() {
            if (!this.billingAccounts.length) {
                showInfo(
                    this.$t(
                        "Es wurde kein Abrechnungskonto gefunden. Bitte fügen Sie zunächst ein Abrechnungskonto hinzu und fahren Sie dann fort."
                    ) as string
                );
            } else {
                this.dealerStubFormDialogDealer = null;
                this.dealerStubFormDialogVisible = true;
            }
        },

        showEditForm(dealer: Dealer) {
            this.dealerStubFormDialogDealer = dealer;
            this.dealerStubFormDialogVisible = true;
        },

        showInitializeDealerSettingsDialog(dealerId: string) {
            this.initializeDealerIds = [dealerId];
            this.initializeDealerSettingsDialogVisible = true;
        },

        async savedDealer() {
            this.dealerStubFormDialogVisible = false;
            await this.loadItems();
        },
    },

    components: {
        DealerGlobalContextHint,
        DealerStubFormDialog,
        DataTable,
        InitializeDealerSettingsDialog,
    },
});
