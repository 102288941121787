
import OutgoingEmailReceiverViewChip from "./OutgoingEmailReceiverViewChip.vue";
import { getAttachments, getBccReceivers, getCcReceivers, getCreator, getToReceivers } from "./outgoingEmailUtils";
import { outgoingEmailsOverviewState } from "./outgoingEmailsOverview";
import { Contact } from "@/api/contacts";
import { isOutgoingEmailEventsUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { IssueType, OutgoingEmailAndContact, outgoingEmailsApi, OutgoingEmailSearchOrder } from "@/api/outgoingEmails";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { renderContactCaption } from "@/app/contactUtils";
import { downloadUrl } from "@/app/fileUtils";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import { PickMutable, SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const itemsPerPage = 100;

        return {
            getAttachments,
            getBccReceivers,
            getCcReceivers,
            getCreator,
            getToReceivers,
            IssueType,
            items: [] as OutgoingEmailAndContact[],
            itemsPerPage,
            loading: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingEmailsOverviewState: outgoingEmailsOverviewState,
            OutgoingEmailSearchOrder,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("E-Mail"),
                    width: "40%",
                },
                {
                    text: this.$t("Absender und Empfänger"),
                    width: "30%",
                },
                {
                    text: this.$t("Kontakt, Vorgang und Betreff"),
                    width: "30%",
                },
            ];
        },

        initiatorUserOptions(): SelectOption[] {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },
    },

    methods: {
        getContactCaption(contact: Contact | null): string | null {
            if (!contact) {
                return null;
            }

            return renderContactCaption(contact.contactData);
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        isContactDeleted(contact: Contact | null): boolean {
            return !!contact?.deleted;
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const outgoingEmailSearchResults = await outgoingEmailsApi.search(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    this.outgoingEmailsOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (outgoingEmailSearchResults.searchId === this.searchCounter) {
                    this.items = outgoingEmailSearchResults.results;
                    this.paging.totalSize = outgoingEmailSearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        refresh() {
            this.outgoingEmailsOverviewState.refresh();
        },

        exportRawEmails() {
            downloadUrl(outgoingEmailsApi.generateRawEmailsLink(this.outgoingEmailsOverviewState.searchRequest));
        },
    },

    watch: {
        async "outgoingEmailsOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        if (!this.outgoingEmailsOverviewState.initialized) {
            this.outgoingEmailsOverviewState.setTodayQuickfilter();
        }

        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isOutgoingEmailEventsUpdatedNotification(n)) {
                const item = this.items.find((i) => i.outgoingEmail.id === n.id);

                if (item) {
                    item.outgoingEmail.events.push(n.event);
                }
            }
        });

        await this.loadItems();
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        DAutocomplete,
        DataTable,
        DatePicker,
        EnumField,
        OutgoingEmailReceiverViewChip,
        UserLink,
    },
});
