import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import moment, { unitOfTime } from "moment-timezone";

export enum UserSearchFilterUnitOfTime {
    DAYS = "DAYS",
    WEEKS = "WEEKS",
    MONTHS = "MONTHS",
    YEARS = "YEARS",
}

export enum FilterTimeRange {
    NONE = "NONE",
    TODAY = "TODAY",
    YESTERDAY = "YESTERDAY",
    THIS_WEEK = "THIS_WEEK",
    LAST_WEEK = "LAST_WEEK",
    THIS_MONTH = "THIS_MONTH",
    LAST_MONTH = "LAST_MONTH",
    THIS_YEAR = "THIS_YEAR",
    LAST_YEAR = "LAST_YEAR",
}

interface TimeRangeFilter {
    readonly from: number | null;
    readonly to: number | null;
    readonly unit: UserSearchFilterUnitOfTime;
}

export const timeRangeUserSearchFilters: Record<FilterTimeRange, TimeRangeFilter> = {
    [FilterTimeRange.NONE]: { from: null, to: null, unit: UserSearchFilterUnitOfTime.DAYS },
    [FilterTimeRange.TODAY]: { from: 0, to: 0, unit: UserSearchFilterUnitOfTime.DAYS },
    [FilterTimeRange.YESTERDAY]: { from: 1, to: 1, unit: UserSearchFilterUnitOfTime.DAYS },
    [FilterTimeRange.THIS_WEEK]: { from: 0, to: 0, unit: UserSearchFilterUnitOfTime.WEEKS },
    [FilterTimeRange.LAST_WEEK]: { from: 1, to: 1, unit: UserSearchFilterUnitOfTime.WEEKS },
    [FilterTimeRange.THIS_MONTH]: { from: 0, to: 0, unit: UserSearchFilterUnitOfTime.MONTHS },
    [FilterTimeRange.LAST_MONTH]: { from: 1, to: 1, unit: UserSearchFilterUnitOfTime.MONTHS },
    [FilterTimeRange.THIS_YEAR]: { from: 0, to: 0, unit: UserSearchFilterUnitOfTime.YEARS },
    [FilterTimeRange.LAST_YEAR]: { from: 1, to: 1, unit: UserSearchFilterUnitOfTime.YEARS },
};

const unitsOfTime: Record<UserSearchFilterUnitOfTime, unitOfTime.Base> = {
    [UserSearchFilterUnitOfTime.DAYS]: "d",
    [UserSearchFilterUnitOfTime.WEEKS]: "w",
    [UserSearchFilterUnitOfTime.MONTHS]: "M",
    [UserSearchFilterUnitOfTime.YEARS]: "y",
};

export function createFilterDate(
    value: number | null,
    unit: UserSearchFilterUnitOfTime,
    startOf: boolean
): string | null {
    if (value === null) {
        return null;
    }
    return moment(now())
        .tz(userSession.timeZone)
        .locale(userSession.locale)
        .add(-value, unitsOfTime[unit])
        [startOf ? "startOf" : "endOf"](unitsOfTime[unit])
        .format()
        .substring(0, 10);
}

export function sortFilterValues(filterValues?: (string | null)[]): (string | null)[] | null {
    if (!filterValues) {
        return null;
    }
    return [...filterValues].sort();
}
