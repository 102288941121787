
import { outgoingSmsOverviewState } from "./outgoingSmsOverview";
import { getCreator, getSenderId, getStatusColor } from "./outgoingSmsUtils";
import { Contact } from "@/api/contacts";
import { isOutgoingSmsUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import {
    IssueType,
    OutgoingSms,
    OutgoingSmsAndContact,
    outgoingSmsApi,
    OutgoingSmsSearchOrder,
} from "@/api/outgoingSms";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { renderContactCaption } from "@/app/contactUtils";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import { PickMutable, SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const itemsPerPage = 100;

        return {
            getCreator,
            getSenderId,
            IssueType,
            items: [] as OutgoingSmsAndContact[],
            itemsPerPage,
            loading: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingSmsOverviewState: outgoingSmsOverviewState,
            OutgoingSmsSearchOrder,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("SMS"),
                    width: "30%",
                },
                {
                    text: this.$t("Nachricht"),
                    width: "35%",
                },
                {
                    text: this.$t("Kontakt und Vorgang"),
                    width: "35%",
                },
            ];
        },

        initiatorUserOptions(): SelectOption[] {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },
    },

    methods: {
        getContactCaption(contact: Contact | null): string | null {
            if (!contact) {
                return null;
            }

            return renderContactCaption(contact.contactData);
        },

        getCreationTimestamp(outgoingSms: OutgoingSms): string {
            return formatInstant(outgoingSms.created, userSession.timeZone, userSession.locale, "S");
        },

        getStatusClass(outgoingSms: OutgoingSms): string {
            const color = getStatusColor(outgoingSms.smsStatus);

            return color ? `${color}--text` : "error--text";
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        isContactDeleted(contact: Contact | null): boolean {
            return !!contact?.deleted;
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const outgoingSmsSearchResults = await outgoingSmsApi.search(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    this.outgoingSmsOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (outgoingSmsSearchResults.searchId === this.searchCounter) {
                    this.items = outgoingSmsSearchResults.results;
                    this.paging.totalSize = outgoingSmsSearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        refresh() {
            this.outgoingSmsOverviewState.refresh();
        },
    },

    watch: {
        async "outgoingSmsOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        if (!this.outgoingSmsOverviewState.initialized) {
            this.outgoingSmsOverviewState.setTodayQuickfilter();
        }

        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isOutgoingSmsUpdatedNotification(n)) {
                this.items = this.items.map((item) =>
                    item.outgoingSms.id === n.id
                        ? {
                              ...item,
                              outgoingSms: {
                                  ...item.outgoingSms,
                                  smsStatus: n.status,
                                  updated: n.timestamp,
                              },
                          }
                        : item
                );
            }
        });

        await this.loadItems();
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        DAutocomplete,
        DataTable,
        DatePicker,
        EnumField,
        UserLink,
    },
});
