
import ContactFormDialog from "./ContactFormDialog.vue";
import ContactVehicleFormDialog from "./ContactVehicleFormDialog.vue";
import { CaseSearchOrder, CaseSearchRequest } from "@/api/caseSearch";
import { Contact, ContactResult, contactsApi, ContactVehicle, MileageUnit } from "@/api/contacts";
import { EmergencyIncidentSearchOrder, EmergencyIncidentSearchRequest } from "@/api/emergencyIncidentSearch";
import { OpportunitySearchOrder, OpportunitySearchRequest } from "@/api/opportunitySearch";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import EnhancedText from "@/app/components/EnhancedText.vue";
import EnumField from "@/app/components/EnumField.vue";
import { renderContactCaption, renderPhoneNumberTypeIcon, renderVehicleTitle } from "@/app/contactUtils";
import { getCountryCaption, getStateCaption, getStateLabel } from "@/app/countryStateUtils";
import { renderUnit } from "@/app/filters";
import { showConfirm } from "@/app/messageUtil";
import PhoneNumberLink from "@/app/pages/PhoneNumberLink.vue";
import CasesDataTable from "@/app/pages/cases/CasesDataTable.vue";
import { caseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import { EMPTY_SEARCH_REQUEST as EMPTY_CASE_SEARCH_REQUEST } from "@/app/pages/cases/caseSearchUtils";
import EmergencyIncidentsDataTable from "@/app/pages/emergencyincidents/EmergencyIncidentsDataTable.vue";
import { emergencyIncidentAssistantState } from "@/app/pages/emergencyincidents/assistant/emergencyIncidentAssistant";
import { EMPTY_SEARCH_REQUEST as EMPTY_EMERGENCY_INCIDENT_SEARCH_REQUEST } from "@/app/pages/emergencyincidents/emergencyIncidentSearchUtils";
import OpportunitiesDataTable from "@/app/pages/opportunities/OpportunitiesDataTable.vue";
import { opportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import { EMPTY_SEARCH_REQUEST as EMPTY_OPPORTUNITY_SEARCH_REQUEST } from "@/app/pages/opportunities/opportunitySearchUtils";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { formatLocalDate, fromNow, getAge } from "@/util/dateTimeUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            contactId: this.$route.params.contactid,
            contactResult: null as ContactResult | null,
            loading: false,
            renderContactCaption,
            getStateLabel,
            getStateCaption,
            getCountryCaption,
            renderPhoneNumberTypeIcon,
            renderVehicleTitle,
            formDialogVisible: false,
            vehicleFormDialogVisible: false,
            vehicleFormDialogItem: null as ContactVehicle | null,
            CaseSearchOrder,
            emptyCaseSearchRequest: EMPTY_CASE_SEARCH_REQUEST,
            caseSearch: "",
            caseSortBy: CaseSearchOrder.CREATED_DESC,
            totalCasesSize: 0,
            EmergencyIncidentSearchOrder,
            emptyEmergencyIncidentSearchRequest: EMPTY_EMERGENCY_INCIDENT_SEARCH_REQUEST,
            emergencyIncidentSearch: "",
            emergencyIncidentSortBy: EmergencyIncidentSearchOrder.CREATED_DESC,
            totalEmergencyIncidentsSize: 0,
            OpportunitySearchOrder,
            emptyOpportunitySearchRequest: EMPTY_OPPORTUNITY_SEARCH_REQUEST,
            opportunitySearch: "",
            opportunitySortBy: OpportunitySearchOrder.CREATED_DESC,
            totalOpportunitiesSize: 0,
        };
    },

    computed: {
        activeTab: {
            get(): number {
                if (this.$route.params.contactvehicleid) {
                    return 1;
                }
                return parseInt(this.$route.params.tab);
            },
            async set(v: number) {
                try {
                    if (v === 1 && this.$route.params.contactvehicleid) {
                        await this.$router.push(
                            `/contact/${this.contactResult!.contact.id}/vehicle/${this.selectedVehicleId}`
                        );
                    } else {
                        await this.$router.push(`/contact/${this.contactResult!.contact.id}/${v}`);
                    }
                } catch (e) {
                    // handle async exception in vue event loop
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },

        contact(): Contact | null {
            return this.contactResult?.contact ?? null;
        },

        selectedVehicleId(): string | null {
            return this.$route.params.contactvehicleid || null;
        },

        vehicles(): null | ContactVehicle[] {
            if (!this.contactResult) {
                return null;
            }

            return this.contactResult.contactVehicles.filter((v) => !v.deleted);
        },

        selectedVehicle(): null | ContactVehicle {
            if (!this.contactResult) {
                return null;
            }

            return (
                this.contactResult.contactVehicles.find((v) => v.id === this.selectedVehicleId && !v.deleted) || null
            );
        },

        vehicleOptions(): SelectOptions {
            return [
                ...this.vehicles!.map((k) => ({
                    value: k.id,
                    text: renderVehicleTitle(k.contactVehicleData, true),
                })).sort((a, b) => a.text.localeCompare(b.text, userSession.locale)),
            ];
        },

        caseSearchRequest(): CaseSearchRequest {
            return {
                ...this.emptyCaseSearchRequest,
                contactIds: this.contactId ? [this.contactId] : [],
                search: this.caseSearch,
                sortBy: this.caseSortBy,
            };
        },

        emergencyIncidentSearchRequest(): EmergencyIncidentSearchRequest {
            return {
                ...this.emptyEmergencyIncidentSearchRequest,
                contactId: this.contactId,
                search: this.emergencyIncidentSearch,
                sortBy: this.emergencyIncidentSortBy,
            };
        },

        opportunitySearchRequest(): OpportunitySearchRequest {
            return {
                ...this.emptyOpportunitySearchRequest,
                contactIds: this.contactId ? [this.contactId] : [],
                search: this.opportunitySearch,
                sortBy: this.opportunitySortBy,
            };
        },

        canViewAllCases(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_CASES);
        },

        canManageEmergencyIncidents(): boolean {
            return userSession.hasPermission(Permission.MANAGE_EMERGENCY_INCIDENTS);
        },

        canViewAllOpportunities(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES);
        },
    },

    methods: {
        async changeVehicle(vehicleId: string) {
            if (vehicleId) {
                await this.$router.push(`/contact/${this.contact!.id}/vehicle/${vehicleId}`);
            } else {
                await this.$router.push(`/contact/${this.contact!.id}/1`);
            }
        },

        async load() {
            this.loading = true;
            try {
                this.contactResult = await contactsApi.getContactResultById(this.contactId);
            } finally {
                this.loading = false;
            }
        },

        renderMileage(mileage: number, mileageUnit: MileageUnit) {
            let unit = "";
            if (mileageUnit === MileageUnit.KM) {
                unit = "kilometer";
            }
            if (mileageUnit === MileageUnit.MI) {
                unit = "mile";
            }

            return renderUnit(mileage, unit);
        },

        renderLocalDate(localDate: string) {
            return formatLocalDate(localDate, userSession.locale, "S");
        },

        calculcateAge(localDate: string) {
            return getAge(now(), localDate, userSession.timeZone);
        },

        renderFromNow(localDate: string) {
            const fromNowCaption = fromNow(now(), localDate, userSession.timeZone, userSession.locale);

            if (fromNowCaption) {
                return fromNowCaption;
            }

            return this.$t("heute");
        },

        async savedContact() {
            this.formDialogVisible = false;
            await this.load();
        },

        createVehicle() {
            this.vehicleFormDialogItem = null;
            this.vehicleFormDialogVisible = true;
        },

        editVehicle() {
            this.vehicleFormDialogItem = this.selectedVehicle;
            this.vehicleFormDialogVisible = true;
        },

        async markContactVehicleAsDeleted() {
            if (
                !(await showConfirm(
                    this.$t("Fahrzeug löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie das Fahrzeug löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.loading = true;
            try {
                await contactsApi.markContactVehicleAsDeleted(this.contact!.id, this.selectedVehicle!.id);
                await this.load();
                await this.$router.push(`/contact/${this.contact!.id}/1`);
            } finally {
                this.loading = false;
            }
        },

        async savedVehicle(contactVehicleId: string | null) {
            this.vehicleFormDialogVisible = false;
            await this.load();

            if (contactVehicleId) {
                await this.$router.push(`/contact/${this.contact!.id}/vehicle/${contactVehicleId}`);
            }
        },

        async markContactAsDeleted() {
            if (
                !(await showConfirm(
                    this.$t("Kontakt löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Kontakt löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.loading = true;
            try {
                await contactsApi.markContactAsDeleted(this.contact!.id);
                await this.load();
            } finally {
                this.loading = false;
            }
        },

        async createCase() {
            if (
                await showConfirm(
                    this.$t("Fall erstellen") as string,
                    this.$t(
                        "Alle Daten, die aktuell im Fall-Assistenten gespeichert sind, gehen verloren. Möchten Sie fortfahren?"
                    ) as string
                )
            ) {
                caseAssistantState.reset(this.contactId!, this.selectedVehicleId);
                await this.$router.push("/case-assistant");
            }
        },

        async createEmergencyIncident() {
            if (
                await showConfirm(
                    this.$t("Notdienstvorgang erstellen") as string,
                    this.$t(
                        "Alle Daten, die aktuell im Notdienstvorgangs-Assistenten gespeichert sind, gehen verloren. Möchten Sie fortfahren?"
                    ) as string
                )
            ) {
                emergencyIncidentAssistantState.reset(this.contactId!, this.selectedVehicleId);
                await this.$router.push("/emergency-incident-assistant");
            }
        },

        async createOpportunity() {
            if (
                await showConfirm(
                    this.$t("Verkaufschance erstellen") as string,
                    this.$t(
                        "Alle Daten, die aktuell im Verkaufschancen-Assistenten gespeichert sind, gehen verloren. Möchten Sie fortfahren?"
                    ) as string
                )
            ) {
                opportunityAssistantState.reset(this.contactId!);
                await this.$router.push("/opportunity-assistant");
            }
        },

        async createInventoryInquiry() {
            if (
                await showConfirm(
                    this.$t("Suchauftrag erstellen") as string,
                    this.$t(
                        "Alle Daten, die aktuell im Suchauftrag-Assistenten gespeichert sind, gehen verloren. Möchten Sie fortfahren?"
                    ) as string
                )
            ) {
                await this.$router.push("/inventory-vehicle-inquiry-assistant?c=" + this.contactId);
            }
        },

        casesLoaded(totalCasesSize: number) {
            this.totalCasesSize = totalCasesSize;
        },

        emergencyIncidentsLoaded(totalEmergencyIncidentsSize: number) {
            this.totalEmergencyIncidentsSize = totalEmergencyIncidentsSize;
        },

        opportunitiesLoaded(totalOpportunitiesSize: number) {
            this.totalOpportunitiesSize = totalOpportunitiesSize;
        },

        async refreshCasesSearch() {
            this.emptyCaseSearchRequest = { ...EMPTY_CASE_SEARCH_REQUEST };
        },

        async refreshEmergencyIncidentsSearch() {
            this.emptyEmergencyIncidentSearchRequest = { ...EMPTY_EMERGENCY_INCIDENT_SEARCH_REQUEST };
        },

        async refreshOpportunitiesSearch() {
            this.emptyOpportunitySearchRequest = { ...EMPTY_OPPORTUNITY_SEARCH_REQUEST };
        },

        getDealerName(dealerId: string): string {
            return dealersStore.dealerById(dealerId)!.name;
        },
    },

    async mounted() {
        await this.load();
    },

    components: {
        EnhancedText,
        CasesDataTable,
        ContactFormDialog,
        ContactVehicleFormDialog,
        CopyToClipboardIcon,
        EmergencyIncidentsDataTable,
        EnumField,
        OpportunitiesDataTable,
        PhoneNumberLink,
    },
});
