
import { makeModelSettingsStore } from "@/store/makeModelSettingsStore";
import { makeModelsStore } from "@/store/makeModels";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        value: [String as () => string | null, Array as () => string[]],
    },

    computed: {
        items(): SelectOptions {
            const items: SelectOptions = makeModelsStore.makes.map((make) => ({
                text: make,
                value: make,
            }));

            const preferredMakeOptions = makeModelsStore.makes
                .filter((make) => makeModelSettingsStore.preferredMakes.includes(make))
                .map((make) => ({
                    text: make,
                    value: make,
                }));

            if (!preferredMakeOptions.length) {
                return items;
            }

            return [
                { header: this.$t("Bevorzugte Marken") },
                ...preferredMakeOptions,
                { header: this.$t("Weitere Marken") },
                ...items,
            ];
        },
    },
});
