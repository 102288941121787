
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerId: {
            type: String as () => string | null,
            default: null,
        },
    },

    computed: {
        dealerOptions() {
            return dealersStore.dealers.filter((d) => d.active).map((d) => ({ value: d.id, text: d.name }));
        },

        isDealerActive(): boolean {
            if (!this.dealerId) {
                return false;
            }

            return dealersStore.dealerById(this.dealerId)!.active;
        },
    },

    methods: {
        async selectDealer(dealerId: string) {
            await this.$router.replace({ query: { ...this.$route.query, dealerId } });
        },
    },
});
