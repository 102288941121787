import { OfficeHours } from "@/api/officeHours";
import { dealersStore } from "@/store/dealers";
import { getDate, getWeekDayOfDate, toDateObject } from "@/util/dateTimeUtils";

export interface OfficeHoursEntryTimeSpan {
    readonly from: Date;
    readonly to: Date;
}

export function getOfficeHoursEntries(officeHours: OfficeHours, ts: Date): OfficeHoursEntryTimeSpan[] {
    const timeZone = dealersStore.dealerById(officeHours.dealerId)!.timeZone;
    const date = getDate(ts, timeZone);

    const exceptionalDay = officeHours.exceptionalDays.find((e) => e.date === date);
    const dayOfWeek = getWeekDayOfDate(date);

    const entries = exceptionalDay
        ? exceptionalDay.entries
        : officeHours.entries.filter((e) => e.dayOfWeek === dayOfWeek);

    return entries.map((entry) => ({
        from: toDateObject(timeZone, date, 0, entry.from),
        to: toDateObject(timeZone, date, 0, entry.to),
    }));
}

export function isExceptionalDay(officeHours: OfficeHours, ts: Date): boolean {
    const timeZone = dealersStore.dealerById(officeHours.dealerId)!.timeZone;
    const date = getDate(ts, timeZone);

    return officeHours.exceptionalDays.some((e) => e.date === date);
}

export function withinOfficeHours(officeHours: OfficeHours, ts: Date): boolean {
    const time = ts.getTime();

    return getOfficeHoursEntries(officeHours, ts).some(
        (entry) => entry.from.getTime() <= time && time <= entry.to.getTime()
    );
}
