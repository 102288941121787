
import { videochatGroupApi } from "@/api/videochatGroups";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { email, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: Boolean,
        contactName: String,
        videochatGroupId: String,
    },

    data() {
        return {
            isWorking: false,
            name: this.contactName,
            nameRules: notEmpty().maxLength(300),
            number: "",
            numberRules: notEmpty().e164(),
            emailAddress: "",
            emailAddressRules: email(),
            note: this.$t("Mein Videoanruf wurde nicht beantwortet. Ich bitte um Rückruf.") as string,
            noteRules: notEmpty().maxLength(8000),
        };
    },

    computed: {
        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },
    },

    methods: {
        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;
            try {
                await videochatGroupApi.createCase(this.videochatGroupId, {
                    name: this.name,
                    number: this.number,
                    emailAddress: this.emailAddress,
                    note: this.note,
                });
            } finally {
                this.isWorking = false;
            }

            this.$emit("success");
        },
    },

    components: {
        PhoneNumberField,
    },
});
