import { Gender } from "@/api/users";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";

interface UserFullNameParts {
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
}

export function getFullName(fullNameParts: UserFullNameParts | null): string {
    if (!fullNameParts) {
        return "";
    }
    const fullName = `${fullNameParts.givenName} ${fullNameParts.familyName}`;
    if (fullNameParts.namePrefix) {
        return `${fullNameParts.namePrefix} ${fullName}`;
    }
    return fullName;
}

export function getSortedByUserFullName<T>(items: T[], getUserId: (t: T) => string): T[] {
    return [...items]
        .map((item) => ({
            item,
            userId: getUserId(item),
            user: usersStore.getUserById(getUserId(item)),
        }))
        .map((v) => ({
            ...v,
            fullName: getFullName(v.user),
        }))
        .sort((a, b) => {
            if (a.userId === b.userId) {
                // same user
                return 0;
            } else if (a.user === b.user) {
                // same user or both users not found
                return 0;
            } else if (!a.user) {
                return 1;
            } else if (!b.user) {
                return -1;
            }

            return a.fullName.localeCompare(b.fullName, userSession.locale);
        })
        .map((v) => v.item);
}
