import { foldAscii } from "./foldAsciiUtils";

export interface PhoneticGraphemeSpelling {
    readonly grapheme: string;
    readonly spelling: string;
}

const PHONETIC_SPELLINGS_DE: PhoneticGraphemeSpelling[] = [
    { grapheme: "A", spelling: "Anton" },
    { grapheme: "Ä", spelling: "Ärger" },
    { grapheme: "B", spelling: "Berta" },
    { grapheme: "C", spelling: "Cäsar" },
    { grapheme: "Ch", spelling: "Charlotte" },
    { grapheme: "D", spelling: "Dora" },
    { grapheme: "E", spelling: "Emil" },
    { grapheme: "F", spelling: "Friedrich" },
    { grapheme: "G", spelling: "Gustav" },
    { grapheme: "H", spelling: "Heinrich" },
    { grapheme: "I", spelling: "Ida" },
    { grapheme: "J", spelling: "Julius" },
    { grapheme: "K", spelling: "Kaufmann" },
    { grapheme: "L", spelling: "Ludwig" },
    { grapheme: "M", spelling: "Martha" },
    { grapheme: "N", spelling: "Nordpol" },
    { grapheme: "O", spelling: "Otto" },
    { grapheme: "Ö", spelling: "Ökonom" },
    { grapheme: "P", spelling: "Paula" },
    { grapheme: "Q", spelling: "Quelle" },
    { grapheme: "R", spelling: "Richard" },
    { grapheme: "S", spelling: "Siegfried" },
    { grapheme: "Sch", spelling: "Schule" },
    { grapheme: "ẞ", spelling: "Eszett" },
    { grapheme: "T", spelling: "Theodor" },
    { grapheme: "U", spelling: "Ulrich" },
    { grapheme: "Ü", spelling: "Übermut" },
    { grapheme: "V", spelling: "Viktor" },
    { grapheme: "W", spelling: "Wilhelm" },
    { grapheme: "X", spelling: "Xanthippe" },
    { grapheme: "Y", spelling: "Ypsilon" },
    { grapheme: "Z", spelling: "Zeppelin" },
];

const PHONETIC_SPELLINGS_AT: PhoneticGraphemeSpelling[] = [
    { grapheme: "A", spelling: "Anton" },
    { grapheme: "Ä", spelling: "Ärger" },
    { grapheme: "B", spelling: "Berta" },
    { grapheme: "C", spelling: "Cäsar" },
    { grapheme: "Ch", spelling: "Charlotte" },
    { grapheme: "D", spelling: "Dora" },
    { grapheme: "E", spelling: "Emil" },
    { grapheme: "F", spelling: "Friedrich" },
    { grapheme: "G", spelling: "Gustav" },
    { grapheme: "H", spelling: "Heinrich" },
    { grapheme: "I", spelling: "Ida" },
    { grapheme: "J", spelling: "Julius" },
    { grapheme: "K", spelling: "Konrad" },
    { grapheme: "L", spelling: "Ludwig" },
    { grapheme: "M", spelling: "Martha" },
    { grapheme: "N", spelling: "Nordpol" },
    { grapheme: "O", spelling: "Otto" },
    { grapheme: "Ö", spelling: "Österreich" },
    { grapheme: "P", spelling: "Paula" },
    { grapheme: "Q", spelling: "ku" },
    { grapheme: "Qu", spelling: "Quelle" },
    { grapheme: "R", spelling: "Richard" },
    { grapheme: "S", spelling: "Siegfried" },
    { grapheme: "Sch", spelling: "Schule" },
    { grapheme: "ẞ", spelling: "scharfes S" },
    { grapheme: "T", spelling: "Theodor" },
    { grapheme: "U", spelling: "Ulrich" },
    { grapheme: "Ü", spelling: "Übel" },
    { grapheme: "V", spelling: "Viktor" },
    { grapheme: "W", spelling: "Wilhelm" },
    { grapheme: "X", spelling: "Xaver" },
    { grapheme: "Y", spelling: "Ypsilon" },
    { grapheme: "Z", spelling: "Zürich" },
];

const PHONETIC_SPELLINGS_CH: PhoneticGraphemeSpelling[] = [
    { grapheme: "A", spelling: "Anna" },
    { grapheme: "Ä", spelling: "Äsch" },
    { grapheme: "B", spelling: "Berta" },
    { grapheme: "C", spelling: "Cäsar" },
    { grapheme: "Ch", spelling: "Chiasso" },
    { grapheme: "D", spelling: "Daniel" },
    { grapheme: "E", spelling: "Emil" },
    { grapheme: "F", spelling: "Friedrich" },
    { grapheme: "G", spelling: "Gustav" },
    { grapheme: "H", spelling: "Heinrich" },
    { grapheme: "I", spelling: "Ida" },
    { grapheme: "J", spelling: "Jakob" },
    { grapheme: "K", spelling: "Kaiser" },
    { grapheme: "L", spelling: "Leopold" },
    { grapheme: "M", spelling: "Marie" },
    { grapheme: "N", spelling: "Niklaus" },
    { grapheme: "O", spelling: "Otto" },
    { grapheme: "Ö", spelling: "Örlikon" },
    { grapheme: "P", spelling: "Peter" },
    { grapheme: "Q", spelling: "Quasi" },
    { grapheme: "R", spelling: "Rosa" },
    { grapheme: "S", spelling: "Sophie" },
    { grapheme: "T", spelling: "Theodor" },
    { grapheme: "U", spelling: "Ulrich" },
    { grapheme: "Ü", spelling: "Übermut" },
    { grapheme: "V", spelling: "Viktor" },
    { grapheme: "W", spelling: "Wilhelm" },
    { grapheme: "X", spelling: "Xaver" },
    { grapheme: "Y", spelling: "Yverdon" },
    { grapheme: "Z", spelling: "Zürich" },
];

const PHONETIC_SPELLINGS_EN: PhoneticGraphemeSpelling[] = [
    { grapheme: "A", spelling: "Alfa" },
    { grapheme: "B", spelling: "Bravo" },
    { grapheme: "C", spelling: "Charlie" },
    { grapheme: "D", spelling: "Delta" },
    { grapheme: "E", spelling: "Echo" },
    { grapheme: "F", spelling: "Foxtrot" },
    { grapheme: "G", spelling: "Golf" },
    { grapheme: "H", spelling: "Hotel" },
    { grapheme: "I", spelling: "India" },
    { grapheme: "J", spelling: "Juliett" },
    { grapheme: "K", spelling: "Kilo" },
    { grapheme: "L", spelling: "Lima" },
    { grapheme: "M", spelling: "Mike" },
    { grapheme: "N", spelling: "November" },
    { grapheme: "O", spelling: "Oscar" },
    { grapheme: "P", spelling: "Papa" },
    { grapheme: "Q", spelling: "Quebec" },
    { grapheme: "R", spelling: "Romeo" },
    { grapheme: "S", spelling: "Sierra" },
    { grapheme: "T", spelling: "Tango" },
    { grapheme: "U", spelling: "Uniform" },
    { grapheme: "V", spelling: "Victor" },
    { grapheme: "W", spelling: "Whiskey" },
    { grapheme: "X", spelling: "X-Ray" },
    { grapheme: "Y", spelling: "Yankee" },
    { grapheme: "Z", spelling: "Zulu" },
];

function getLongestMatch(text: string, graphemes: string[]): string | null {
    const uppercaseText = text.toUpperCase();

    let token: string | null = null;

    for (const grapheme of graphemes) {
        if (!uppercaseText.startsWith(grapheme.toUpperCase()) || grapheme.length <= (token?.length ?? 0)) {
            continue;
        }

        token = grapheme;
    }

    return token;
}

interface Token {
    readonly token: string;
    readonly matched: string;
}

function tokenize(text: string, graphemes: string[]): Token[] {
    let oldRemaining: string | null = null;
    let newRemaining: string = text;

    const tokens: Token[] = [];

    while (oldRemaining !== newRemaining && newRemaining) {
        oldRemaining = newRemaining;

        const unicodeMatch = getLongestMatch(newRemaining, graphemes);
        const unicodeToken: Token | null = unicodeMatch
            ? {
                  token: unicodeMatch,
                  matched: newRemaining.substr(0, unicodeMatch.length),
              }
            : null;

        if (unicodeToken) {
            tokens.push(unicodeToken);
            newRemaining = newRemaining.substr(unicodeToken.matched.length);
            continue;
        }

        const asciiText = foldAscii(newRemaining[0]);
        const asciiTokens = !newRemaining.startsWith(asciiText) ? tokenize(asciiText, graphemes) : [];

        if (asciiTokens.length) {
            tokens.push(...asciiTokens);
            newRemaining = newRemaining.substr(1);
            continue;
        }

        tokens.push({
            token: newRemaining[0],
            matched: newRemaining[0],
        });
        newRemaining = newRemaining.substr(1);
    }

    return tokens;
}

export function getPhoneticSpelling(text: string, locale: string): PhoneticGraphemeSpelling[] {
    const spellings = locale.startsWith("de")
        ? locale === "de-AT"
            ? PHONETIC_SPELLINGS_AT
            : locale === "de-CH"
            ? PHONETIC_SPELLINGS_CH
            : PHONETIC_SPELLINGS_DE
        : locale.startsWith("en")
        ? PHONETIC_SPELLINGS_EN
        : null;

    if (!spellings) {
        return [];
    }

    const alphabet = spellings.map((spelling) => spelling.grapheme);

    return tokenize(text, alphabet).map((token) => ({
        grapheme: token.matched,
        spelling: spellings.find((spelling) => spelling.grapheme === token.token)?.spelling ?? token.matched,
    }));
}
