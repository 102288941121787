
import AgentInfoDialog from "./AgentInfoDialog.vue";
import AwayFormDialog from "./AwayFormDialog.vue";
import EditModeDialog from "./EditModeDialog.vue";
import IncomingCallSystemBar from "./IncomingCallSystemBar.vue";
import Navigation from "./Navigation.vue";
import OutgoingCallSystemBar from "./OutgoingCallSystemBar.vue";
import { renderExternalInventoryManagementUserLink } from "./externalinventorymanagementsettings/externalInventoryManagementPlaceholders";
import StatusDashboardSwitcherDialog from "./statusdashboard/StatusDashboardSwitcherDialog.vue";
import { IncomingCallResult } from "@/api/incomingCalls";
import { OutgoingCall } from "@/api/outgoingCalls";
import { Permission, userSessionApi } from "@/api/userSession";
import { Away, AwayType, usersApi } from "@/api/users";
import { videochatApi } from "@/api/videochats";
import PlaySound from "@/app/components/PlaySound.vue";
import navigation, { NavigationFolder, NavigationItem } from "@/app/navigation";
import { getFullName } from "@/app/userUtils";
import { announcementsStore } from "@/store/announcements";
import { changelogsStore } from "@/store/changelogs";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { externalInventoryManagementSettingsStore } from "@/store/externalInventoryManagementSettings";
import { incomingCallsStore } from "@/store/incomingCalls";
import { outgoingCallsStore } from "@/store/outgoingCalls";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { videochatsStore } from "@/store/videochats";
import { SelectOption } from "@/util/types";
import Vue from "vue";
import { Location, Route } from "vue-router";

function handleNavigation(
    { path, query, hash, params }: Route,
    next: (to?: Location) => void,
    defaultDealerId?: string
) {
    const currentDealerId = userSession.dealerId || "";
    const queryDealerId = query.dealerId as string | undefined;
    if (queryDealerId === undefined) {
        next({ path, query: { ...query, dealerId: currentDealerId || defaultDealerId || "" }, hash, params });
        return;
    }
    if (queryDealerId && !dealersStore.dealerById(queryDealerId)) {
        next({ path, query: { ...query, dealerId: "" }, hash, params });
        return;
    }
    if (queryDealerId !== currentDealerId) {
        userSession.dealerId = queryDealerId || null;
    }

    next();
}

export default Vue.extend({
    data() {
        return {
            agentInfoDialogVisible: false,
            agentInfoDialogWasClosedByUser: false,
            awayFormDialogVisible: false,
            statusDashboardSwitcherDialogVisible: false,
            drawer: null as boolean | null,
            togglingAvailableForVideochat: false,
            togglingAway: false,
            videochatsStore,
            acceptingVideochat: false,
            loggingOut: false,
        };
    },

    computed: {
        canViewDealerDirectory(): boolean {
            return userSession.hasPermission(Permission.CT_VIEW_DEALER_DIRECTORY);
        },

        externalInventoryManagementUserLink(): string | null {
            return renderExternalInventoryManagementUserLink(
                externalInventoryManagementSettingsStore.settings.userLinkPattern
            );
        },

        externalInventoryManagementUserLabel(): string | null {
            return externalInventoryManagementSettingsStore.settings.userLinkLabel;
        },

        myActiveOutgoingCall(): OutgoingCall | null | undefined {
            if (!userSession.userId) {
                return null;
            }
            return outgoingCallsStore.outgoingCallByInitiator(userSession.userId);
        },

        myActiveIncomingCall(): IncomingCallResult | null {
            if (!userSession.userId) {
                return null;
            }
            return incomingCallsStore.incomingCallByRingingUserOrAcceptor(userSession.userId);
        },

        openAnnouncements(): number {
            return Math.max(announcementsStore.toConfirm, announcementsStore.toRead);
        },

        unreadChangelogs(): number {
            return changelogsStore.unreadCount;
        },

        fullName(): string {
            return userSession.fullName || "";
        },

        isConnected(): boolean {
            return userSession.connected;
        },

        title(): string | undefined {
            return this.$route.matched.map((r) => r.meta.title as string | undefined).reduceRight((p, c) => p || c);
        },

        navigation(): (NavigationFolder | NavigationItem)[] {
            return navigation;
        },

        isAgent(): boolean {
            return userSession.isAgent();
        },

        isCtUser(): boolean {
            return userSession.isCtUser();
        },

        canChangeOwnUserProfile(): boolean {
            return userSession.hasPermission(Permission.CHANGE_OWN_USER_PROFILE);
        },

        canChangeOwnNotifications(): boolean {
            return userSession.hasPermission(Permission.CHANGE_OWN_NOTIFICATIONS);
        },

        canChangeOwnPassword(): boolean {
            return userSession.hasPermission(Permission.CHANGE_OWN_PASSWORD);
        },

        canChangeOwnStatus(): boolean {
            return userSession.hasPermission(Permission.CHANGE_OWN_STATUS);
        },

        userId(): string | undefined {
            return userSession.userId;
        },

        ctUrl(): string {
            return configStore.configuration.ctUrl;
        },

        currentDealerId(): string {
            return userSession?.dealerId || "";
        },

        absencesIconVisible(): boolean {
            return usersStore.getUserById(userSession.userId!)?.status.absent || false;
        },

        workingHoursBadgeVisible(): boolean {
            if (!usersStore.getUserById(userSession.userId!)) {
                return false;
            }

            if (usersStore.getUserById(userSession.userId!)?.status.withinWorkingHours === null) {
                // not set
                return true;
            } else if (
                !usersStore.getUserById(userSession.userId!)?.status.workingHoursConfirmedCurrentWeek ||
                !usersStore.getUserById(userSession.userId!)?.status.workingHoursConfirmedNextWeek
            ) {
                // not confirmed
                return true;
            }

            // return true if not there
            return !usersStore.getUserById(userSession.userId!)?.status.withinWorkingHours;
        },

        workingHoursBadgeColor(): string {
            if (usersStore.getUserById(userSession.userId!)?.status.withinWorkingHours === null) {
                // not set
                return "warning";
            } else if (
                !usersStore.getUserById(userSession.userId!)?.status.workingHoursConfirmedCurrentWeek ||
                !usersStore.getUserById(userSession.userId!)?.status.workingHoursConfirmedNextWeek
            ) {
                // not confirmed
                return "warning";
            }

            // not there
            return "info";
        },

        workingHoursBadgeIcon(): string {
            if (usersStore.getUserById(userSession.userId!)?.status.withinWorkingHours === null) {
                // not set
                return "mdi-alert";
            } else if (
                !usersStore.getUserById(userSession.userId!)?.status.workingHoursConfirmedCurrentWeek ||
                !usersStore.getUserById(userSession.userId!)?.status.workingHoursConfirmedNextWeek
            ) {
                // not confirmed
                return "mdi-help";
            }

            // not there
            return "mdi-briefcase";
        },

        profileImageUrl(): string {
            if (!userSession.profileImageHash) {
                return "/img/user_placeholder.png";
            }

            const url = usersApi.generateProfileImageLink(userSession.userId!, userSession.profileImageHash);

            if (userSession.isCtUser()) {
                return configStore.configuration.ctUrl + url;
            }

            return url;
        },

        currentDealerName(): string {
            return userSession.dealerId
                ? dealersStore.dealerById(userSession.dealerId)?.name || ""
                : (this.$t("Händlergruppe") as string);
        },

        activeDealerOptions(): SelectOption[] {
            return [
                { value: "", text: this.$t("Händlergruppe") },
                ...dealersStore.dealers.filter((d) => d.active).map((d) => ({ value: d.id, text: d.name })),
            ];
        },

        inactiveDealerOptions(): SelectOption[] {
            return dealersStore.dealers.filter((d) => !d.active).map((d) => ({ value: d.id, text: d.name }));
        },

        away(): Away | null {
            return usersStore.getAwayByUser(userSession.userId!);
        },

        availableForVideochat(): boolean | null {
            return usersStore.getAvailableForVideochatByUser(userSession.userId!);
        },

        realUsername(): string | undefined {
            return userSession.realUsername;
        },

        volume(): number {
            return userSession.profile!.volume;
        },

        routerViewKey(): string {
            // router view component shall be re-mounted when the first two path components have changed
            return this.$route.path
                .split("/")
                .slice(0, 3)
                .join("/");
        },

        isInactiveDealerSelected(): boolean {
            return dealersStore.dealers
                .filter((d) => !d.active)
                .map((d) => d.id)
                .includes(this.currentDealerId);
        },

        canShowVideochatStatus(): boolean {
            return configStore.configuration.videochatConfigured;
        },
    },

    methods: {
        getFullNameById(id: string) {
            return (getFullName(usersStore.getUserById(id)) || this.$t("Unbekannt")) as string;
        },

        async acceptVideochat(id: string) {
            if (!this.acceptingVideochat) {
                this.acceptingVideochat = true;
                try {
                    await videochatApi.acceptInvitation(id);
                    await this.$router.push(`/videochat/${id}`);
                } finally {
                    this.acceptingVideochat = false;
                }
            }
        },

        async dismissVideochat(id: string) {
            await videochatApi.dismissInvitation(id);
        },

        async toggleAvailableForVideochat() {
            if (this.togglingAvailableForVideochat || !this.isConnected) {
                return;
            }

            this.togglingAvailableForVideochat = true;
            try {
                await userSessionApi.updateAvailableForVideochat(!this.availableForVideochat);
            } finally {
                this.togglingAvailableForVideochat = false;
            }
        },

        async logout() {
            const redirectUrl = this.realUsername ? `/users?dealerId=${userSession.mainDealerId || ""}` : "/";
            this.loggingOut = true;
            await userSession.logout();
            window.location.href = redirectUrl;
        },

        getStatusText() {
            if (!this.isConnected) {
                return this.$t("baue Verbindung auf");
            } else if (this.away) {
                if (this.away.type === AwayType.OTHER) {
                    return this.$t("abwesend");
                } else {
                    return this.$t(`enum.AwayType.${this.away.type}`);
                }
            }

            return this.$t("online");
        },

        getStatusColor() {
            if (!this.isConnected) {
                return "error";
            } else if (this.away) {
                return "warning";
            }

            return "success";
        },

        removeStatus() {
            userSessionApi.updateAway(null);
        },

        setStatus() {
            this.awayFormDialogVisible = true;
        },

        toggleDrawer() {
            this.drawer = !this.drawer;
        },

        async selectDealer(dealerId: string) {
            await this.$router.replace({ query: { ...this.$route.query, dealerId } });
        },

        toggleAgentInfoDialog() {
            this.agentInfoDialogVisible = !this.agentInfoDialogVisible;

            if (!this.agentInfoDialogVisible) {
                this.agentInfoDialogWasClosedByUser = true;
            }
        },

        async toggleAway() {
            if (this.togglingAway || !this.isConnected) {
                return;
            }

            this.togglingAway = true;
            try {
                await userSessionApi.updateAway(this.away ? null : { type: AwayType.OTHER, notes: null, until: null });
            } finally {
                this.togglingAway = false;
            }
        },
    },

    beforeRouteEnter(to, _, next) {
        handleNavigation(to, next, userSession.mainDealerId);
    },

    beforeRouteUpdate(to, _, next) {
        handleNavigation(to, next);

        if (to.path !== "/location-details" && userSession.isAgent() && !this.agentInfoDialogWasClosedByUser) {
            this.agentInfoDialogVisible = true;
        }
    },

    components: {
        AgentInfoDialog,
        AwayFormDialog,
        EditModeDialog,
        Navigation,
        OutgoingCallSystemBar,
        IncomingCallSystemBar,
        StatusDashboardSwitcherDialog,
        PlaySound,
    },
});
