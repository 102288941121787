import axios from "axios";

export enum FinanceContractCustomerType {
    LEASING = "LEASING",
    FINANCING = "FINANCING",
}

export enum FinanceContractType {
    PRIVATE = "PRIVATE",
    BUSINESS = "BUSINESS",
}

export enum FinanceContractVehicleUsageType {
    NEW = "NEW",
    USED = "USED",
}

export enum ProcessingState {
    UNPROCESSED = "UNPROCESSED",
    PROCESSED = "PROCESSED",
    PROCESS_MANUALLY = "PROCESS_MANUALLY",
}

export interface FinanceContract {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly processingState: ProcessingState;
    readonly processedAt: Date | null;
    readonly opportunityId: string | null;
    readonly contactId: string | null;
    readonly financeContractUploadSourceId: string;
    readonly financeContractId: string;
    readonly dealer: string | null;
    readonly type: FinanceContractType | null;
    readonly salesperson: string | null;
    readonly customerType: FinanceContractCustomerType | null;
    readonly name: string | null;
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
    readonly number: string | null;
    readonly emailAddress: string | null;
    readonly firstInstallment: number | null;
    readonly monthlyInstallment: number | null;
    readonly finalInstallment: number | null;
    readonly finalInstallmentPaymentDate: string | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly vin: string | null;
    readonly licensePlate: string | null;
    readonly vehicleUsageType: FinanceContractVehicleUsageType | null;
    readonly notes: string | null;
}

interface FinanceContractsApi {
    delete(id: string): Promise<void>;
    markAsProcessManually(id: string): Promise<void>;
    markAsUnprocessed(id: string): Promise<void>;
    process(id: string, disableNotifications?: boolean): Promise<void>;
}

export const financeContractsApi: FinanceContractsApi = {
    async delete(id) {
        await axios.delete(`/api/finance-contracts/${id}`);
    },

    async markAsProcessManually(id) {
        await axios.post(`/api/finance-contracts/${id}/_mark-as-process-manually`);
    },

    async markAsUnprocessed(id) {
        await axios.post(`/api/finance-contracts/${id}/_mark-as-unprocessed`);
    },

    async process(id, disableNotifications) {
        await axios.post(`/api/finance-contracts/${id}/_process`, undefined, { params: { disableNotifications } });
    },
};
