
import {
    OpportunityNotificationEmailFormat,
    opportunityNotificationSettingsApi,
} from "@/api/opportunityNotificationSettings";
import { Permission } from "@/api/userSession";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { SelectOptions } from "@/util/types/SelectOptions";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            opportunityNotificationEmailFormat: null as OpportunityNotificationEmailFormat | null,
            loading: true,
            notEmpty,
            saving: false,
        };
    },

    computed: {
        opportunityNotificationEmailFormatOptions(): SelectOptions {
            return [
                ...Object.keys(OpportunityNotificationEmailFormat).map((k) => ({
                    value: k,
                    text: this.$t(`enum.OpportunityNotificationEmailFormat.${k}`),
                })),
            ];
        },

        dealerId() {
            return userSession.dealerId;
        },
    },

    methods: {
        async loadOpportunityNotificationSettings() {
            if (!this.dealerId) {
                return;
            }

            this.loading = true;

            try {
                const opportunityNotificationSettings = await opportunityNotificationSettingsApi.getByDealer(
                    this.dealerId
                );

                this.opportunityNotificationEmailFormat =
                    opportunityNotificationSettings.opportunityNotificationEmailFormat;
            } finally {
                this.loading = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;

            try {
                await opportunityNotificationSettingsApi.editOpportunityNotificationSettings({
                    dealerId: this.dealerId!,
                    opportunityNotificationEmailFormat: this.opportunityNotificationEmailFormat!,
                });
            } finally {
                this.saving = false;
            }
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadOpportunityNotificationSettings();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadOpportunityNotificationSettings();
    },

    components: {
        DealerContextGuard,
    },
});
