
import { Absence, AbsenceForm, absencesApi, AbsenceType } from "@/api/absences";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import TimePicker from "@/app/components/TimePicker.vue";
import { getFullName } from "@/app/userUtils";
import { maxLength, notEmpty } from "@/app/validation";
import { now } from "@/store/now";
import { usersStore } from "@/store/users";
import { getDate, getTime, toDateObject } from "@/util/dateTimeUtils";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            valid: true,
            isWorking: false,
            fullDaysSwitch: this.fullDays,

            // FORM FIELDS
            fromDate: getDate(this.absence?.from || now(), this.timeZone),
            fromTime: this.absence?.from ? getTime(this.absence?.from, this.timeZone) : "00:00",
            toDate: this.absence?.to ? getDate(this.absence.to, this.timeZone, this.fullDays ? -1 : 0) : null,
            toTime: this.absence?.to ? getTime(this.absence.to, this.timeZone) : null,
            type: this.absence?.type || "",
            substituteUserIds: this.absence
                ? [...this.absence.substituteUserIds]
                : [usersStore.getUserById(this.userId)?.defaultSubstituteUserId as string].filter((i) => !!i),
            notes: this.absence?.notes || "",

            // RULES
            notesRules: maxLength(300),
            notEmptyRules: notEmpty(),
        };
    },

    watch: {
        async fullDaysSwitch() {
            this.fromTime = "00:00";
            if (this.toTime) {
                this.toTime = "00:00";
            }
            await this.$nextTick();
            (this.$refs.form as any).validate();
        },

        toDate(value: string) {
            if (value) {
                if (!this.toTime) {
                    this.toTime = "00:00";
                }
            } else {
                if (this.toTime) {
                    this.toTime = null;
                }
            }
        },

        async toTime(value: string) {
            if (value) {
                if (!this.toDate) {
                    this.toDate = this.fromDate;
                }
            } else {
                if (this.toDate) {
                    this.toDate = null;
                }
            }
            await this.$nextTick();
            (this.$refs.form as any).validate();
        },

        from() {
            (this.$refs.form as any).validate();
        },
    },

    computed: {
        absenceTypes(): SelectOptions {
            return [
                { value: "", text: this.$t("keine Angabe") },
                ...Object.keys(AbsenceType).map((k) => ({ value: k, text: this.$t(`enum.AbsenceType.${k}`) })),
            ];
        },

        allUsersOptions(): SelectOption[] {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },

        from(): Date {
            return toDateObject(this.timeZone, this.fromDate, 0, this.fromTime);
        },

        to(): Date | null {
            if (this.toDate && this.toTime) {
                return toDateObject(this.timeZone, this.toDate, this.fullDaysSwitch ? 1 : 0, this.toTime);
            }
            return null;
        },

        toRules() {
            return [
                () =>
                    !this.to ||
                    this.to.getTime() > this.from.getTime() ||
                    this.$t("Das Ende der Abwesenheit darf nicht vor dem Beginn liegen."),
            ];
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            const absenceForm: AbsenceForm = {
                from: this.from,
                to: this.to,
                type: (this.type as AbsenceType) || null,
                substituteUserIds: this.substituteUserIds,
                notes: this.notes,
                defaultSubstituteRemoved: null,
            };

            try {
                if (this.absence) {
                    await absencesApi.edit(this.absence.id, absenceForm);
                } else {
                    await absencesApi.add(this.userId, absenceForm);
                }
            } finally {
                this.isWorking = false;
            }
            this.$emit("success");
        },
    },

    props: {
        absence: {
            type: Object as () => Absence | null,
            required: false,
        },
        userId: {
            type: String,
            required: true,
        },
        timeZone: {
            type: String,
            required: true,
        },
        fullDays: {
            type: Boolean,
            required: true,
        },
    },

    components: {
        DAutocomplete,
        DatePicker,
        TimePicker,
    },
});
