
import { CaseLastActivityType, casePrioritySettingsApi, CasePrioritySettingsEntry } from "@/api/casePrioritySettings";
import { Permission } from "@/api/userSession";
import NumberField from "@/app/components/NumberField.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { integer } from "@/app/validation";
import { officeHoursStore } from "@/store/officeHours";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            integer,
            loading: true,
            escalationHoursId: null as string | null,
            saving: false,
            settingEntries: [] as CasePrioritySettingsEntry[],
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        officeHoursOptions(): SelectOption[] {
            if (!this.dealerId) {
                return [];
            }

            return officeHoursStore.getOfficeHoursByDealerId(this.dealerId).map((officeHours) => ({
                value: officeHours.id,
                text: officeHours.name,
            }));
        },
    },

    methods: {
        async loadSettings() {
            if (!this.dealerId) {
                return;
            }

            this.loading = true;
            try {
                const settings = await casePrioritySettingsApi.getSettings(this.dealerId);
                this.escalationHoursId = settings.escalationHoursId;
                this.settingEntries = [...settings.settingEntries].sort((a, b) =>
                    this.typeLabel(a.lastActivityType).localeCompare(
                        this.typeLabel(b.lastActivityType),
                        userSession.locale
                    )
                );
            } finally {
                this.loading = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await casePrioritySettingsApi.editSettings(this.dealerId!, {
                    escalationHoursId: this.escalationHoursId,
                    settingEntries: this.settingEntries,
                });
            } finally {
                this.saving = false;
            }
        },

        typeLabel(type: CaseLastActivityType) {
            return this.$t(`enum.CaseLastActivityType.${type}`) as string;
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadSettings();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadSettings();
    },

    components: {
        DealerContextGuard,
        NumberField,
    },
});
