import axios from "axios";

export enum OpportunityLastActivityType {
    INCOMING_EMAIL = "INCOMING_EMAIL",
    OUTGOING_EMAIL = "OUTGOING_EMAIL",
    INCOMING_CALL_ACCEPTED = "INCOMING_CALL_ACCEPTED",
    INCOMING_CALL_UNANSWERED = "INCOMING_CALL_UNANSWERED",
    OUTGOING_CALL_ACCEPTED = "OUTGOING_CALL_ACCEPTED",
    OUTGOING_CALL_UNANSWERED = "OUTGOING_CALL_UNANSWERED",
    NOTE_BY_CT_USER = "NOTE_BY_CT_USER",
    NOTE_BY_PARTITION_USER = "NOTE_BY_PARTITION_USER",
    INCOMING_SMS = "INCOMING_SMS",
    OUTGOING_SMS = "OUTGOING_SMS",
    INCOMING_WHATSAPP = "INCOMING_WHATSAPP",
    OUTGOING_WHATSAPP = "OUTGOING_WHATSAPP",
}

export interface OpportunityPrioritySettingsEntry {
    readonly lastActivityType: OpportunityLastActivityType;
    readonly mediumEscalation: number | null;
    readonly highEscalation: number | null;
}

export interface OpportunityPrioritySettings {
    readonly escalationHoursId: string | null;
    readonly settingEntries: OpportunityPrioritySettingsEntry[];
}

interface OpportunityPrioritySettingsApi {
    getSettings(dealerId: string): Promise<OpportunityPrioritySettings>;
    editSettings(dealerId: string, settings: OpportunityPrioritySettings): Promise<void>;
}

export const opportunityPrioritySettingsApi: OpportunityPrioritySettingsApi = {
    async getSettings(dealerId) {
        return (await axios.get(`/api/opportunity-priority-settings/${dealerId}`)).data;
    },

    async editSettings(dealerId, settings) {
        await axios.put(`/api/opportunity-priority-settings/${dealerId}`, settings);
    },
};
