import AbsencesOverview from "./pages/absences/AbsencesOverview.vue";
import ExternalAbsenceProviderManagement from "./pages/absences/ExternalAbsenceProviderManagement.vue";
import AnnouncementsOverview from "./pages/announcements/AnnouncementsOverview.vue";
import AutoCrmLeadMailAssistant from "./pages/autocrmleadmailer/assistant/AutoCrmLeadMailAssistant.vue";
import AutoCrmLeadMailerSettings from "./pages/autocrmleadmailersettings/AutoCrmLeadMailerSettings.vue";
import AutoCrmLeadMailerUserSettings from "./pages/autocrmleadmailersettings/AutoCrmLeadMailerUserSettings.vue";
import CallerIds from "./pages/callerids/CallerIds.vue";
import CallSettings from "./pages/callsettings/CallSettings.vue";
import CallTrackingGroups from "./pages/calltrackinggroups/CallTrackingGroups.vue";
import CarryDispoExternOrdersOverview from "./pages/carrydispo/CarryDispoExternOrdersOverview.vue";
import CarryDispoSettings from "./pages/carrydisposettings/CarryDispoSettings.vue";
import CaseNotificationSettings from "./pages/casenotificationsettings/CaseNotificationSettings.vue";
import CasesOverview from "./pages/cases/CasesOverview.vue";
import MyCases from "./pages/cases/MyCases.vue";
import SinglePageCaseAssistant from "./pages/cases/assistant/SinglePageCaseAssistant.vue";
import WizardCaseAssistant from "./pages/cases/assistant/WizardCaseAssistant.vue";
import CaseOutcomeReasonSettings from "./pages/casesettings/CaseOutcomeReasonSettings.vue";
import CasePrioritySettings from "./pages/casesettings/CasePrioritySettings.vue";
import CaseSettings from "./pages/casesettings/CaseSettings.vue";
import CitnowVideoSettings from "./pages/citnowvideosettings/CitnowVideoSettings.vue";
import ContactSearchOverview from "./pages/contacts/ContactSearchOverview.vue";
import ContactsOverview from "./pages/contacts/ContactsOverview.vue";
import ContactUploads from "./pages/contactuploads/ContactUploads.vue";
import DealerInstructionOverview from "./pages/dealerinstructions/DealerInstructionOverview.vue";
import DealerForm from "./pages/dealers/DealerForm.vue";
import DealerStubs from "./pages/dealers/DealerStubs.vue";
import DepartmentsOverview from "./pages/departments/DepartmentsOverview.vue";
import EmailDomains from "./pages/emaildomains/EmailDomains.vue";
import EmailSettings from "./pages/emailsettings/EmailSettings.vue";
import EmailSignatureTemplateFormPage from "./pages/emailsignaturetemplate/EmailSignatureTemplateFormPage.vue";
import EmailTemplatesOverview from "./pages/emailtemplates/EmailTemplatesOverview.vue";
import EmergencyIncidentNotificationSettings from "./pages/emergencyincidentnotificationsettings/EmergencyIncidentNotificationSettings.vue";
import EmergencyIncidentsOverview from "./pages/emergencyincidents/EmergencyIncidentsOverview.vue";
import EscalationGroupsOverview from "./pages/escalationgroups/EscalationGroupsOverview.vue";
import ExternalInventoryManagementSettings from "./pages/externalinventorymanagementsettings/ExternalInventoryManagementSettings.vue";
import FileSettings from "./pages/filesettings/FileSettings.vue";
import FinanceContractOverview from "./pages/financecontracts/FinanceContractOverview.vue";
import FinanceContractSettings from "./pages/financecontractsettings/FinanceContractSettings.vue";
import FinanceContractUploads from "./pages/financecontractuploads/FinanceContractUploads.vue";
import FrontendSettings from "./pages/frontendsettings/FrontendSettings.vue";
import HouseKeepStorage from "./pages/housekeepstorage/HouseKeepStorage.vue";
import IncomingCallsOverview from "./pages/incomingcalls/IncomingCallsOverview.vue";
import IncomingEmailsOverview from "./pages/incomingemails/IncomingEmailsOverview.vue";
import SystemEmailAliases from "./pages/incomingemailsettings/SystemEmailAliases.vue";
import IncomingPhoneNumbersManagement from "./pages/incomingphonenumbers/IncomingPhoneNumbersManagement.vue";
import InternalIncomingPhoneNumberConfiguration from "./pages/incomingphonenumbers/InternalIncomingPhoneNumberConfiguration.vue";
import IncomingSmsOverview from "./pages/incomingsms/IncomingSmsOverview.vue";
import IncomingWhatsAppsOverview from "./pages/incomingwhatsapp/IncomingWhatsAppsOverview.vue";
import IndividualEscalationsOverview from "./pages/individualescalations/IndividualEscalationsOverview.vue";
import InitializePartition from "./pages/initialization/InitializePartition.vue";
import HousekeepInventory from "./pages/inventory/HousekeepInventory.vue";
import InventoryOverview from "./pages/inventory/InventoryOverview.vue";
import InventoryImportManagement from "./pages/inventoryimport/InventoryImportManagement.vue";
import InventoryVehicleInquiryOverview from "./pages/inventoryvehicleinquiry/InventoryVehicleInquiryOverview.vue";
import InvoicesOverview from "./pages/invoices/InvoicesOverview.vue";
import IpRestrictions from "./pages/iprestrictions/IpRestrictions.vue";
import LocationDetails from "./pages/locationdetails/LocationDetails.vue";
import MakeModelSettings from "./pages/makemodelsettings/MakeModelSettings.vue";
import MyActivities from "./pages/myactivities/MyActivities.vue";
import NotesOverview from "./pages/notes/NotesOverview.vue";
import NoteTemplatesOverview from "./pages/notetemplates/NoteTemplatesOverview.vue";
import NumberBlacklistOverview from "./pages/numberblacklist/NumberBlacklistOverview.vue";
import OfficeHoursOverview from "./pages/officehours/OfficeHoursOverview.vue";
import MyOpportunities from "./pages/opportunities/MyOpportunities.vue";
import OpportunitiesOverview from "./pages/opportunities/OpportunitiesOverview.vue";
import SinglePageOpportunityAssistant from "./pages/opportunities/assistant/SinglePageOpportunityAssistant.vue";
import OpportunityIndividualEscalationsOverview from "./pages/opportunityindividualescalations/OpportunityIndividualEscalationsOverview.vue";
import OpportunityNotificationSettings from "./pages/opportunitynotificationsettings/OpportunityNotificationSettings.vue";
import OpportunityExceptionalSettings from "./pages/opportunitysettings/OpportunityExceptionalSettings.vue";
import OpportunityOutcomeReasonSettings from "./pages/opportunitysettings/OpportunityOutcomeReasonSettings.vue";
import OpportunityPrioritySettings from "./pages/opportunitysettings/OpportunityPrioritySettings.vue";
import OpportunitySettings from "./pages/opportunitysettings/OpportunitySettings.vue";
import OpportunitySourceSettings from "./pages/opportunitysettings/OpportunitySourceSettings.vue";
import OpportunityTeamSettings from "./pages/opportunitysettings/OpportunityTeamSettings.vue";
import OutgoingCallsOverview from "./pages/outgoingcalls/OutgoingCallsOverview.vue";
import OutgoingEmailsOverview from "./pages/outgoingemails/OutgoingEmailsOverview.vue";
import OutgoingSmsOverview from "./pages/outgoingsms/OutgoingSmsOverview.vue";
import OutgoingWhatsAppsOverview from "./pages/outgoingwhatsapp/OutgoingWhatsAppsOverview.vue";
import Partners from "./pages/partners/Partners.vue";
import PasswordPolicy from "./pages/passwordpolicy/PasswordPolicy.vue";
import ReadReceiptSettings from "./pages/readreceipt/ReadReceiptSettings.vue";
import RebuildIndexes from "./pages/rebuildindexes/RebuildIndexes.vue";
import RenaultRaceAccounts from "./pages/renaultraceaccounts/RenaultRaceAccounts.vue";
import RenaultRaceLeadReceiverMappingOverview from "./pages/renaultraceleadreceivermappings/RenaultRaceLeadReceiverMappingOverview.vue";
import RenaultRaceLeadSourceMappingOverview from "./pages/renaultraceleadsourcemappings/RenaultRaceLeadSourceMappingOverview.vue";
import ReportingDealerSettings from "./pages/reportingsettings/ReportingDealerSettings.vue";
import ReportingSettings from "./pages/reportingsettings/ReportingSettings.vue";
import ServiceAccounts from "./pages/serviceaccounts/ServiceAccounts.vue";
import ServicesOffered from "./pages/servicesoffered/ServicesOffered.vue";
import SmsSettings from "./pages/smssettings/SmsSettings.vue";
import SmsSignatureTemplateFormPage from "./pages/smssignaturetemplate/SmsSignatureTemplateFormPage.vue";
import SmsTemplatesOverview from "./pages/smstemplates/SmsTemplatesOverview.vue";
import SupportOverview from "./pages/support/SupportOverview.vue";
import TwoFactorAuthenticationSettings from "./pages/twofactorauthenticationsettings/TwoFactorAuthenticationSettings.vue";
import InternalUserAdministration from "./pages/users/InternalUserAdministration.vue";
import UserDirectory from "./pages/users/UserDirectory.vue";
import UserOverview from "./pages/users/UserOverview.vue";
import VideochatGroups from "./pages/videochatgroups/VideochatGroups.vue";
import MyVideochats from "./pages/videochats/MyVideochats.vue";
import VideochatSettings from "./pages/videochatsettings/VideochatSettings.vue";
import WelcomeEmail from "./pages/welcomeemail/WelcomeEmail.vue";
import WhatsAppAccounts from "./pages/whatsappaccounts/WhatsAppAccounts.vue";
import WorkingHoursOverview from "./pages/workinghours/WorkingHoursOverview.vue";
import { Permission } from "@/api/userSession";
import { caseStatusStore } from "@/store/caseStatus";
import { configStore } from "@/store/config";
import { emergencyIncidentStatusStore } from "@/store/emergencyIncidentStatus";
import { opportunityStatusStore } from "@/store/opportunityStatus";
import { userSession } from "@/store/userSession";
import Vue, { AsyncComponent, ComponentOptions } from "vue";

type Component = ComponentOptions<Vue> | typeof Vue | AsyncComponent;
type Props = Record<string, any>;

interface NavigationBadge {
    readonly value: number;
    readonly color: string;
    readonly tooltip?: string;
}

export abstract class NavigationNode {
    abstract readonly isVisible: boolean;
    abstract readonly badges: NavigationBadge[];

    protected constructor(public title: string, public icon?: string) {}
}

export class NavigationItem extends NavigationNode {
    readonly path: string;
    readonly component: Component;
    readonly props?: Props;
    readonly permissions?: Permission[];
    private readonly badges_?: () => NavigationBadge[];
    private readonly guard_?: () => boolean;

    constructor(item: {
        title: string;
        icon?: string;
        path: string;
        component: Component;
        props?: Props;
        permission?: Permission;
        permissions?: Permission[];
        badges?: () => NavigationBadge[];
        guard?: () => boolean;
    }) {
        super(item.title, item.icon);
        this.path = item.path;
        this.component = item.component;
        this.props = item.props;
        this.permissions = item.permission ? [item.permission] : item.permissions;
        this.badges_ = item.badges;
        this.guard_ = item.guard;
    }

    get isVisible() {
        const hasPermission =
            !this.permissions || (userSession.permissions || []).some((p) => this.permissions!.includes(p));

        return hasPermission && (!this.guard_ || this.guard_());
    }

    get badges() {
        return this.badges_ ? this.badges_().filter((b) => b.value > 0) : [];
    }
}

export class NavigationFolder extends NavigationNode {
    items: NavigationNode[];

    constructor(folder: { title: string; icon?: string; items: NavigationNode[] }) {
        super(folder.title, folder.icon);
        this.items = folder.items;
    }

    get isVisible() {
        return this.items.some((i) => i.isVisible);
    }

    get badges() {
        const badges = new Map<string, NavigationBadge>();
        for (const item of this.items) {
            for (const badge of item.badges) {
                const b = badges.get(badge.color);
                badges.set(badge.color, { value: (b?.value || 0) + badge.value, color: badge.color });
            }
        }
        return [...badges.values()];
    }
}

const $t = (s: string) => s;

export default [
    new NavigationItem({
        title: $t("Meine Fälle"),
        icon: "mdi-folder-star",
        path: "dashboard",
        component: MyCases,
        permission: Permission.MANAGE_OWN_CASES,
        badges: () => [
            {
                value: caseStatusStore.activeUnreadCasesCount,
                color: "error",
                tooltip: $t("Anzahl aktiver Fälle mit ungelesenen Aktivitäten"),
            },
        ],
    }),
    new NavigationItem({
        title: $t("Meine Verkaufschancen"),
        icon: "mdi-trophy-award",
        path: "my-opportunities",
        component: MyOpportunities,
        permission: Permission.MANAGE_OWN_OPPORTUNITIES,
        badges: () => [
            {
                value: opportunityStatusStore.activeUnreadOpportunitiesCount,
                color: "error",
                tooltip: $t("Anzahl aktiver Verkaufschancen mit ungelesenen Aktivitäten"),
            },
        ],
    }),
    new NavigationItem({
        title: $t("Meine Videochats"),
        icon: "mdi-video-account",
        path: "my-videochats",
        component: MyVideochats,
        permission: Permission.MANAGE_OWN_VIDEOCHATS,
        guard: () => configStore.configuration.videochatConfigured,
    }),
    new NavigationItem({
        title: $t("Meine Aktivitäten"),
        icon: "mdi-playlist-star",
        path: "my-activities",
        component: MyActivities,
        permission: Permission.MANAGE_OWN_ACTIVITIES,
    }),
    new NavigationItem({
        title: $t("Fall-Assistent"),
        icon: "mdi-folder-plus",
        path: "case-assistant",
        component: SinglePageCaseAssistant,
        permission: Permission.CREATE_CASE,
        guard: () => {
            if (!userSession.isAgent()) {
                // partition users use old case assistant
                return true;
            }

            const isFeatureTester =
                userSession.hasPermission(Permission.TEST_FEATURE_A) ||
                userSession.hasPermission(Permission.TEST_FEATURE_B);

            if (!isFeatureTester) {
                // CT users who are not feature testers also use old assistant
                return true;
            }

            return userSession.hasPermission(Permission.TEST_FEATURE_A);
        },
    }),
    new NavigationItem({
        title: $t("Fall-Assistent"),
        icon: "mdi-folder-plus",
        path: "case-assistant-wizard",
        component: WizardCaseAssistant,
        permission: Permission.CREATE_CASE,
        guard: () => userSession.isAgent() && userSession.hasPermission(Permission.TEST_FEATURE_B),
    }),
    new NavigationItem({
        title: $t("Verkaufschancen-Assistent"),
        icon: "mdi-trophy",
        path: "opportunity-assistant",
        component: SinglePageOpportunityAssistant,
        permission: Permission.CREATE_OPPORTUNITY,
        guard: () => !configStore.configuration.autoCrmLeadMailerConfigured,
    }),
    new NavigationItem({
        title: $t("AutoCRM Verkaufschancen-Assistent"),
        icon: "mdi-trophy",
        path: "submit-auto-crm-lead-mail-assistant",
        component: AutoCrmLeadMailAssistant,
        permission: Permission.SUBMIT_AUTO_CRM_LEAD_MAIL,
        guard: () => configStore.configuration.autoCrmLeadMailerConfigured,
    }),
    new NavigationItem({
        title: $t("Standortdetails"),
        icon: "mdi-map-marker",
        path: "location-details",
        component: LocationDetails,
        permission: Permission.VIEW_LOCATION_DETAILS,
    }),
    new NavigationItem({
        title: $t("Benutzerverzeichnis"),
        icon: "mdi-account-multiple",
        path: "user-directory",
        component: UserDirectory,
        permission: Permission.VIEW_USER_DIRECTORY,
    }),
    new NavigationItem({
        title: $t("Fahrzeugbestand"),
        icon: "mdi-car-multiple",
        path: "inventory",
        component: InventoryOverview,
        permission: Permission.VIEW_VEHICLE_INVENTORY,
    }),
    new NavigationItem({
        title: $t("Suchaufträge"),
        icon: "mdi-clipboard-text-search",
        path: "inventory-vehicle-inquiries",
        component: InventoryVehicleInquiryOverview,
        permissions: [
            Permission.MANAGE_OWN_INVENTORY_VEHICLE_INQUIRIES,
            Permission.VIEW_ALL_INVENTORY_VEHICLE_INQUIRIES,
        ],
    }),
    new NavigationItem({
        title: $t("Kontakte"),
        icon: "mdi-card-account-details",
        path: "contacts",
        component: ContactsOverview,
        permission: Permission.VIEW_CONTACTS,
    }),
    new NavigationItem({
        title: $t("Notdienstvorgänge"),
        icon: "mdi-car-emergency",
        path: "emergency-incidents",
        component: EmergencyIncidentsOverview,
        permission: Permission.MANAGE_EMERGENCY_INCIDENTS,
        badges: () => [
            {
                value: emergencyIncidentStatusStore.activeEmergencyIncidentsForMainDealerCount,
                color: "error",
                tooltip: $t("Anzahl aktiver Notdienstvorgänge am Hauptstandort"),
            },
            {
                value: emergencyIncidentStatusStore.activeEmergencyIncidentsCount,
                color: "warning",
                tooltip: $t("Anzahl aktiver Notdienstvorgänge an allen Standorten"),
            },
        ],
    }),
    new NavigationItem({
        title: $t("Fälle"),
        icon: "mdi-folder-multiple",
        path: "cases",
        component: CasesOverview,
        permission: Permission.VIEW_ALL_CASES,
    }),
    new NavigationItem({
        title: $t("Verkaufschancen"),
        icon: "mdi-trophy-outline",
        path: "opportunities",
        component: OpportunitiesOverview,
        permission: Permission.VIEW_ALL_OPPORTUNITIES,
    }),
    new NavigationItem({
        title: $t("CARRY Dispo Aufträge"),
        icon: "mdi-tow-truck",
        path: "carry-dispo-orders",
        component: CarryDispoExternOrdersOverview,
        permission: Permission.MANAGE_CARRY_DISPO_ORDERS,
        guard: () => configStore.configuration.carryDispoEndpointConfigured,
    }),
    new NavigationFolder({
        title: $t("Administration"),
        icon: "mdi-tune",
        items: [
            new NavigationFolder({
                title: $t("Standort"),
                items: [
                    new NavigationItem({
                        title: $t("Allgemeine Standorteinstellungen"),
                        path: "location",
                        component: DealerForm,
                        permissions: [Permission.DEALER_MANAGEMENT, Permission.MANAGE_OWN_DEALER],
                    }),
                    new NavigationItem({
                        title: $t("Dienstleistungen"),
                        path: "services-offered",
                        component: ServicesOffered,
                        permissions: [Permission.MANAGE_SERVICES_OFFERED, Permission.MANAGE_DEALER_SERVICES_OFFERED],
                    }),
                    new NavigationItem({
                        title: $t("Geschäftszeiten"),
                        path: "office-hours",
                        component: OfficeHoursOverview,
                        permissions: [Permission.MANAGE_OFFICE_HOURS, Permission.MANAGE_DEALER_OFFICE_HOURS],
                    }),
                    new NavigationItem({
                        title: $t("Partner"),
                        path: "partners",
                        component: Partners,
                        permissions: [Permission.MANAGE_PARTNERS, Permission.MANAGE_DEALER_PARTNERS],
                    }),
                    new NavigationItem({
                        title: $t("Abteilungen"),
                        path: "departments",
                        component: DepartmentsOverview,
                        permissions: [Permission.USER_MANAGEMENT, Permission.MANAGE_DEALER_USERS],
                    }),
                ],
            }),
            new NavigationItem({
                title: $t("Ankündigungen"),
                path: "announcements",
                component: AnnouncementsOverview,
                permissions: [Permission.MANAGE_ANNOUNCEMENTS, Permission.MANAGE_DEALER_ANNOUNCEMENTS],
            }),
            new NavigationFolder({
                title: $t("Benutzer"),
                items: [
                    new NavigationItem({
                        title: $t("Benutzerverwaltung"),
                        path: "users",
                        component: UserOverview,
                        permissions: [Permission.USER_MANAGEMENT, Permission.MANAGE_DEALER_USERS],
                    }),
                    new NavigationItem({
                        title: $t("Abwesenheitsimport"),
                        path: "external-absence-providers",
                        component: ExternalAbsenceProviderManagement,
                        permission: Permission.ABSENCE_MANAGEMENT,
                    }),
                    new NavigationItem({
                        title: $t("Abwesenheiten"),
                        path: "absences",
                        component: AbsencesOverview,
                        permissions: [Permission.ABSENCE_MANAGEMENT, Permission.MANAGE_DEALER_ABSENCES],
                    }),
                    new NavigationItem({
                        title: $t("Arbeitszeiten"),
                        path: "working-hours",
                        component: WorkingHoursOverview,
                        permissions: [Permission.WORKING_HOURS_MANAGEMENT, Permission.MANAGE_DEALER_WORKING_HOURS],
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("E-Mails"),
                items: [
                    new NavigationItem({
                        title: $t("Allgemeine E-Mail-Einstellungen"),
                        path: "email-settings",
                        component: EmailSettings,
                        permissions: [
                            Permission.MANAGE_INCOMING_EMAIL_SETTINGS,
                            Permission.MANAGE_OUTGOING_EMAIL_SETTINGS,
                        ],
                    }),
                    new NavigationItem({
                        title: $t("Eingehende E-Mail-Adressen"),
                        path: "system-email-aliases",
                        component: SystemEmailAliases,
                        permissions: [
                            Permission.MANAGE_SYSTEM_EMAIL_ALIASES,
                            Permission.MANAGE_DEALER_SYSTEM_EMAIL_ALIASES,
                        ],
                    }),
                    new NavigationItem({
                        title: $t("Benutzer-E-Mail-Domains"),
                        path: "email-domains",
                        component: EmailDomains,
                        permission: Permission.MANAGE_EMAIL_DOMAINS,
                    }),
                    new NavigationItem({
                        title: $t("E-Mail-Signatur-Vorlage"),
                        path: "email-signature-template",
                        component: EmailSignatureTemplateFormPage,
                        permissions: [
                            Permission.MANAGE_EMAIL_SIGNATURE_TEMPLATE,
                            Permission.MANAGE_DEALER_EMAIL_SIGNATURE_TEMPLATE,
                        ],
                    }),
                    new NavigationItem({
                        title: $t("E-Mail-Vorlagen"),
                        path: "email-templates",
                        component: EmailTemplatesOverview,
                        permission: Permission.MANAGE_EMAIL_TEMPLATES,
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Anrufe"),
                items: [
                    new NavigationItem({
                        title: $t("Allgemeine Anrufeinstellungen"),
                        path: "call-settings",
                        component: CallSettings,
                        permission: Permission.MANAGE_CALL_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Call-Tracking-Nummern"),
                        path: "internal-incoming-phone-numbers",
                        component: InternalIncomingPhoneNumberConfiguration,
                        permissions: [
                            Permission.CONFIGURE_INCOMING_PHONE_NUMBERS,
                            Permission.CONFIGURE_DEALER_INCOMING_PHONE_NUMBERS,
                        ],
                    }),
                    new NavigationItem({
                        title: $t("Call-Tracking-Gruppen"),
                        path: "call-tracking-groups",
                        component: CallTrackingGroups,
                        permission: Permission.CONFIGURE_INCOMING_PHONE_NUMBERS,
                    }),
                    new NavigationItem({
                        title: $t("Anruferkennungen"),
                        path: "caller-ids",
                        component: CallerIds,
                        permission: Permission.MANAGE_CALLER_IDS,
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Textnachrichten"),
                items: [
                    new NavigationItem({
                        title: $t("SMS"),
                        path: "sms-settings",
                        component: SmsSettings,
                        permission: Permission.MANAGE_SMS_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("WhatsApp"),
                        path: "whatsapp-accounts",
                        component: WhatsAppAccounts,
                        permissions: [Permission.MANAGE_WHATSAPP_SETTINGS, Permission.MANAGE_DEALER_WHATSAPP_SETTINGS],
                    }),
                    new NavigationItem({
                        title: $t("Vorlagen"),
                        path: "sms-templates",
                        component: SmsTemplatesOverview,
                        permission: Permission.MANAGE_SMS_TEMPLATES,
                    }),
                    new NavigationItem({
                        title: $t("SMS-Signatur-Vorlage"),
                        path: "sms-signature-template",
                        component: SmsSignatureTemplateFormPage,
                        permissions: [
                            Permission.MANAGE_SMS_SIGNATURE_TEMPLATE,
                            Permission.MANAGE_DEALER_SMS_SIGNATURE_TEMPLATE,
                        ],
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Videochat"),
                items: [
                    new NavigationItem({
                        title: $t("Einstellungen"),
                        path: "videochat-settings",
                        component: VideochatSettings,
                        permission: Permission.MANAGE_VIDEOCHAT_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Videochat-Gruppen"),
                        path: "videochat-groups",
                        component: VideochatGroups,
                        permissions: [
                            Permission.MANAGE_VIDEOCHAT_GROUP_SETTINGS,
                            Permission.MANAGE_DEALER_VIDEOCHAT_GROUP_SETTINGS,
                        ],
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Notizen"),
                items: [
                    new NavigationItem({
                        title: $t("Notiz-Vorlagen"),
                        path: "note-templates",
                        component: NoteTemplatesOverview,
                        permission: Permission.MANAGE_NOTE_TEMPLATES,
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Notdienstvorgänge"),
                items: [
                    new NavigationItem({
                        title: $t("Benachrichtigungseinstellungen"),
                        path: "emergency-incident-notification-settings",
                        component: EmergencyIncidentNotificationSettings,
                        permissions: [
                            Permission.MANAGE_EMERGENCY_INCIDENT_NOTIFICATION_SETTINGS,
                            Permission.MANAGE_DEALER_EMERGENCY_INCIDENT_NOTIFICATION_SETTINGS,
                        ],
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Fälle"),
                items: [
                    new NavigationItem({
                        title: $t("Allgemeine Fall-Einstellungen"),
                        path: "case-settings",
                        component: CaseSettings,
                        permissions: [Permission.MANAGE_CASE_SETTINGS, Permission.MANAGE_DEALER_CASE_SETTINGS],
                    }),
                    new NavigationItem({
                        title: $t("Benachrichtigungseinstellungen"),
                        path: "case-notification-settings",
                        component: CaseNotificationSettings,
                        permissions: [
                            Permission.MANAGE_CASE_NOTIFICATION_SETTINGS,
                            Permission.MANAGE_DEALER_CASE_NOTIFICATION_SETTINGS,
                        ],
                    }),
                    new NavigationItem({
                        title: $t("Eskalationsgruppen"),
                        path: "escalation-groups",
                        component: EscalationGroupsOverview,
                        permissions: [Permission.MANAGE_ESCALATION, Permission.MANAGE_DEALER_ESCALATION],
                    }),
                    new NavigationItem({
                        title: $t("Individuelle Eskalationen"),
                        path: "individual-escalations",
                        component: IndividualEscalationsOverview,
                        permissions: [Permission.MANAGE_ESCALATION, Permission.MANAGE_DEALER_ESCALATION],
                    }),
                    new NavigationItem({
                        title: $t("Gründe"),
                        path: "case-outcome-reason-settings",
                        component: CaseOutcomeReasonSettings,
                        permission: Permission.MANAGE_CASE_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Prioritätseinstellungen"),
                        path: "case-priority-settings",
                        component: CasePrioritySettings,
                        permissions: [
                            Permission.MANAGE_CASE_PRIORITY_SETTINGS,
                            Permission.MANAGE_DEALER_CASE_PRIORITY_SETTINGS,
                        ],
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Verkaufschancen"),
                items: [
                    new NavigationItem({
                        title: $t("Allgemeine Verkaufschancen-Einstellungen"),
                        path: "opportunity-settings",
                        component: OpportunitySettings,
                        permissions: [
                            Permission.MANAGE_OPPORTUNITY_SETTINGS,
                            Permission.MANAGE_DEALER_OPPORTUNITY_SETTINGS,
                        ],
                    }),
                    new NavigationItem({
                        title: $t("Ausnahmeeinstellungen"),
                        path: "opportunity-exceptional-settings",
                        component: OpportunityExceptionalSettings,
                        permission: Permission.MANAGE_OPPORTUNITY_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Benachrichtigungseinstellungen"),
                        path: "opportunity-notification-settings",
                        component: OpportunityNotificationSettings,
                        permissions: [
                            Permission.MANAGE_OPPORTUNITY_NOTIFICATION_SETTINGS,
                            Permission.MANAGE_DEALER_OPPORTUNITY_NOTIFICATION_SETTINGS,
                        ],
                    }),
                    new NavigationItem({
                        title: $t("Quellen"),
                        path: "opportunity-source-settings",
                        component: OpportunitySourceSettings,
                        permission: Permission.MANAGE_OPPORTUNITY_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Verkäuferteams"),
                        path: "opportunity-team-settings",
                        component: OpportunityTeamSettings,
                        permissions: [
                            Permission.MANAGE_OPPORTUNITY_TEAM_SETTINGS,
                            Permission.MANAGE_DEALER_OPPORTUNITY_TEAM_SETTINGS,
                        ],
                    }),
                    new NavigationItem({
                        title: $t("Individuelle Eskalationen"),
                        path: "opportunity-individual-escalations",
                        component: OpportunityIndividualEscalationsOverview,
                        permissions: [Permission.MANAGE_ESCALATION, Permission.MANAGE_DEALER_OPPORTUNITY_ESCALATION],
                    }),
                    new NavigationItem({
                        title: $t("Resultate"),
                        path: "opportunity-outcome-reason-settings",
                        component: OpportunityOutcomeReasonSettings,
                        permission: Permission.MANAGE_OPPORTUNITY_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Prioritätseinstellungen"),
                        path: "opportunity-priority-settings",
                        component: OpportunityPrioritySettings,
                        permissions: [
                            Permission.MANAGE_OPPORTUNITY_PRIORITY_SETTINGS,
                            Permission.MANAGE_DEALER_OPPORTUNITY_PRIORITY_SETTINGS,
                        ],
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Berichte"),
                items: [
                    new NavigationItem({
                        title: $t("Allgemeine Berichtseinstellungen"),
                        path: "reporting-settings",
                        component: ReportingSettings,
                        permission: Permission.MANAGE_REPORTING_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Standortberichtseinstellungen"),
                        path: "reporting-dealer-settings",
                        component: ReportingDealerSettings,
                        permissions: [
                            Permission.MANAGE_REPORTING_SETTINGS,
                            Permission.MANAGE_DEALER_REPORTING_SETTINGS,
                        ],
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Kontakte"),
                items: [
                    new NavigationItem({
                        title: $t("Kontaktimport"),
                        path: "contact-uploads",
                        component: ContactUploads,
                        permission: Permission.MANAGE_CONTACT_UPLOADS,
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Fahrzeugbestand"),
                items: [
                    new NavigationItem({
                        title: $t("Fahrzeugimport"),
                        path: "inventory-import",
                        component: InventoryImportManagement,
                        permissions: [Permission.MANAGE_INVENTORY_IMPORT, Permission.MANAGE_DEALER_INVENTORY_IMPORT],
                    }),
                    new NavigationItem({
                        title: $t("Externe Fahrzeugverwaltung"),
                        path: "external-inventory-management",
                        component: ExternalInventoryManagementSettings,
                        permission: Permission.MANAGE_EXTERNAL_INVENTORY_MANAGEMENT_SETTINGS,
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Sicherheit"),
                items: [
                    new NavigationItem({
                        title: $t("IP-Einschränkungen"),
                        path: "ip-restrictions",
                        component: IpRestrictions,
                        permission: Permission.MANAGE_IP_RESTRICTIONS,
                    }),
                    new NavigationItem({
                        title: $t("Lesebestätigungen"),
                        path: "read-receipt-settings",
                        component: ReadReceiptSettings,
                        permission: Permission.MANAGE_READ_RECEIPT_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Passwortrichtlinie"),
                        path: "password-policy",
                        component: PasswordPolicy,
                        permission: Permission.MANAGE_PASSWORD_POLICY,
                    }),
                    new NavigationItem({
                        title: $t("Zwei-Faktor-Authentifizierung"),
                        path: "two-factor-authentication-settings",
                        component: TwoFactorAuthenticationSettings,
                        permission: Permission.MANAGE_TWO_FACTOR_AUTHENTICATION_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Dateieinstellungen"),
                        path: "file-settings",
                        component: FileSettings,
                        permission: Permission.MANAGE_FILE_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Rufnummernsperre"),
                        path: "number-blacklist-overview",
                        component: NumberBlacklistOverview,
                        permission: Permission.MANAGE_NUMBERS_BLACKLIST,
                    }),
                ],
            }),
            new NavigationItem({
                title: $t("Dienstkonten"),
                path: "service-accounts",
                component: ServiceAccounts,
                permission: Permission.MANAGE_SERVICE_ACCOUNTS,
            }),
            new NavigationFolder({
                title: $t("Leasing und Finanzierung"),
                items: [
                    new NavigationItem({
                        title: $t("Bankdatenimport"),
                        path: "finance-contract-uploads",
                        component: FinanceContractUploads,
                        permission: Permission.MANAGE_FINANCE_CONTRACT_UPLOADS,
                    }),
                    new NavigationItem({
                        title: $t("Bankdaten"),
                        path: "finance-contracts",
                        component: FinanceContractOverview,
                        permissions: [Permission.MANAGE_FINANCE_CONTRACTS, Permission.MANAGE_DEALER_FINANCE_CONTRACTS],
                    }),
                    new NavigationItem({
                        title: $t("Einstellungen"),
                        path: "finance-contract-settings",
                        component: FinanceContractSettings,
                        permission: Permission.MANAGE_FINANCE_CONTRACT_SETTINGS,
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("OEM"),
                items: [
                    new NavigationFolder({
                        title: $t("Renault"),
                        items: [
                            new NavigationItem({
                                title: $t("RACE-Konten"),
                                path: "renault-race-accounts",
                                component: RenaultRaceAccounts,
                                permission: Permission.MANAGE_RENAULT_RACE_ACCOUNTS,
                            }),
                            new NavigationItem({
                                title: $t("Benutzerzuordnungen"),
                                path: "renault-race-lead-receiver-mappings",
                                component: RenaultRaceLeadReceiverMappingOverview,
                                permission: Permission.MANAGE_RENAULT_RACE_LEAD_RECEIVER_MAPPINGS,
                            }),
                            new NavigationItem({
                                title: $t("Quellenzuordnungen"),
                                path: "renault-race-lead-source-mappings",
                                component: RenaultRaceLeadSourceMappingOverview,
                                permission: Permission.MANAGE_RENAULT_RACE_LEAD_SOURCE_MAPPINGS,
                            }),
                        ],
                    }),
                ],
            }),
        ],
    }),
    new NavigationFolder({
        title: $t("Aktivitätsverlauf"),
        icon: "mdi-swap-horizontal-circle",
        items: [
            new NavigationItem({
                title: $t("Ausgehende SMS"),
                path: "outgoing-sms",
                component: OutgoingSmsOverview,
                permission: Permission.MANAGE_OUTGOING_SMS,
            }),
            new NavigationItem({
                title: $t("Eingehende SMS"),
                path: "incoming-sms",
                component: IncomingSmsOverview,
                permission: Permission.MANAGE_INCOMING_SMS,
            }),
            new NavigationItem({
                title: $t("Ausgehende Anrufe"),
                path: "outgoing-calls",
                component: OutgoingCallsOverview,
                permission: Permission.MANAGE_OUTGOING_CALLS,
            }),
            new NavigationItem({
                title: $t("Eingehende Anrufe"),
                path: "incoming-calls",
                component: IncomingCallsOverview,
                permission: Permission.MANAGE_INCOMING_CALLS,
            }),
            new NavigationItem({
                title: $t("Ausgehende E-Mails"),
                path: "outgoing-emails",
                component: OutgoingEmailsOverview,
                permission: Permission.MANAGE_OUTGOING_EMAILS,
            }),
            new NavigationItem({
                title: $t("Eingehende E-Mails"),
                path: "incoming-emails",
                component: IncomingEmailsOverview,
                permission: Permission.MANAGE_INCOMING_EMAILS,
            }),
            new NavigationItem({
                title: $t("Ausgehende WhatsApp-Nachrichten"),
                path: "outgoing-whatsapp-messages",
                component: OutgoingWhatsAppsOverview,
                permission: Permission.MANAGE_OUTGOING_WHATSAPP,
            }),
            new NavigationItem({
                title: $t("Eingehende WhatsApp-Nachrichten"),
                path: "incoming-whatsapp-messages",
                component: IncomingWhatsAppsOverview,
                permission: Permission.MANAGE_INCOMING_WHATSAPP,
            }),
            new NavigationItem({
                title: $t("Notizen"),
                path: "notes",
                component: NotesOverview,
                permission: Permission.MANAGE_NOTES,
            }),
        ],
    }),
    new NavigationFolder({
        title: $t("Berichte"),
        icon: "mdi-chart-bar",
        items: [
            new NavigationFolder({
                title: $t("Fälle"),
                items: [
                    new NavigationItem({
                        title: $t("Dashboard"),
                        path: "case-reporting-dashboard",
                        component: () => import("./pages/reporting").then((m) => m.CaseDashboard),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Standortvergleich"),
                        path: "case-location-reporting-dashboard",
                        component: () => import("./pages/reporting").then((m) => m.CaseLocationDashboard),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Reaktionszeit der Erstreaktion"),
                        path: "case-waiting-time-until-first-reaction-report",
                        component: () =>
                            import("./pages/reporting").then((m) => m.CaseWaitingTimeUntilFirstReactionReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Stoßzeiten"),
                        path: "case-busy-time-report",
                        component: () => import("./pages/reporting").then((m) => m.CaseBusyTimeReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Bearbeitungsdauer"),
                        path: "case-processing-time-report",
                        component: () => import("./pages/reporting").then((m) => m.CaseProcessingTimeReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Bearbeitungsdauerentwicklung"),
                        path: "case-processing-time-time-series",
                        component: () => import("./pages/reporting").then((m) => m.CaseProcessingTimeTimeSeries),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Stimmung"),
                        path: "case-sentiment-type-report",
                        component: () => import("./pages/reporting").then((m) => m.CaseSentimentTypeReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Stimmungsentwicklung"),
                        path: "case-sentiment-type-time-series",
                        component: () => import("./pages/reporting").then((m) => m.CaseSentimentTypeTimeSeries),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Verkaufschancen"),
                items: [
                    new NavigationItem({
                        title: $t("Dashboard"),
                        path: "opportunity-reporting-dashboard",
                        component: () => import("./pages/reporting").then((m) => m.OpportunityDashboard),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Standortvergleich"),
                        path: "opportunity-location-reporting-dashboard",
                        component: () => import("./pages/reporting").then((m) => m.OpportunityLocationDashboard),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Ergebnis"),
                        path: "opportunity-outcome-report",
                        component: () => import("./pages/reporting").then((m) => m.OpportunityOutcomeReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Ergebnisentwicklung"),
                        path: "opportunity-outcome-time-series",
                        component: () => import("./pages/reporting").then((m) => m.OpportunityOutcomeTimeSeries),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Quellen"),
                        path: "source-time-series",
                        component: () => import("./pages/reporting").then((m) => m.OpportunitySourceTimeSeries),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Conversion Rate"),
                        path: "conversion-rate-time-series",
                        component: () => import("./pages/reporting").then((m) => m.OpportunityConversionRateTimeSeries),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Reaktionszeit der Erstreaktion"),
                        path: "opportunity-waiting-time-until-first-reaction-report",
                        component: () =>
                            import("./pages/reporting").then((m) => m.OpportunityWaitingTimeUntilFirstReactionReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Stoßzeiten"),
                        path: "opportunity-busy-time-report",
                        component: () => import("./pages/reporting").then((m) => m.OpportunityBusyTimeReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Bearbeitungsdauer"),
                        path: "opportunity-processing-time-report",
                        component: () => import("./pages/reporting").then((m) => m.OpportunityProcessingTimeReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Bearbeitungsdauerentwicklung"),
                        path: "opportunity-processing-time-time-series",
                        component: () => import("./pages/reporting").then((m) => m.OpportunityProcessingTimeTimeSeries),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Stimmung"),
                        path: "opportunity-sentiment-type-report",
                        component: () => import("./pages/reporting").then((m) => m.OpportunitySentimentTypeReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Stimmungsentwicklung"),
                        path: "opportunity-sentiment-type-time-series",
                        component: () => import("./pages/reporting").then((m) => m.OpportunitySentimentTypeTimeSeries),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Call-Tracking"),
                items: [
                    new NavigationItem({
                        title: $t("Anrufe"),
                        path: "incoming-call-report",
                        component: () => import("./pages/reporting").then((m) => m.IncomingCallReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Stoßzeiten"),
                        path: "incoming-call-busy-time-report",
                        component: () => import("./pages/reporting").then((m) => m.IncomingCallBusyTimeReport),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                    new NavigationItem({
                        title: $t("Anrufentwicklung"),
                        path: "incoming-call-time-series",
                        component: () => import("./pages/reporting").then((m) => m.IncomingCallTimeSeries),
                        permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
                    }),
                ],
            }),
            new NavigationItem({
                title: $t("Ausgehende Anrufe"),
                path: "outgoing-call-time-series",
                component: () => import("./pages/reporting").then((m) => m.OutgoingCallTimeSeries),
                permissions: [Permission.VIEW_DEALER_REPORTS, Permission.VIEW_ALL_REPORTS],
            }),
            new NavigationFolder({
                title: $t("Externes BDC"),
                items: [
                    new NavigationItem({
                        title: $t("Tagesbericht"),
                        path: "external-bdc-daily-report",
                        component: () => import("./pages/reporting").then((m) => m.ExternalBdcExtendedDailyReport),
                        permissions: [
                            Permission.VIEW_DEALER_EXTERNAL_BDC_REPORTS,
                            Permission.VIEW_DEALER_REPORTS,
                            Permission.VIEW_ALL_EXTERNAL_BDC_REPORTS,
                            Permission.VIEW_ALL_REPORTS,
                        ],
                        guard: () => configStore.configuration.extendedExternalBdcReporting,
                    }),
                    new NavigationItem({
                        title: $t("Eingehende Anrufe"),
                        path: "external-bdc-incoming-call-report",
                        component: () => import("./pages/reporting").then((m) => m.ExternalBdcIncomingCallReport),
                        permissions: [
                            Permission.VIEW_DEALER_EXTERNAL_BDC_REPORTS,
                            Permission.VIEW_DEALER_REPORTS,
                            Permission.VIEW_ALL_EXTERNAL_BDC_REPORTS,
                            Permission.VIEW_ALL_REPORTS,
                        ],
                    }),
                    new NavigationItem({
                        title: $t("Eingehende Anrufentwicklung"),
                        path: "external-bdc-incoming-call-time-series",
                        component: () => import("./pages/reporting").then((m) => m.ExternalBdcIncomingCallTimeSeries),
                        permissions: [
                            Permission.VIEW_DEALER_EXTERNAL_BDC_REPORTS,
                            Permission.VIEW_DEALER_REPORTS,
                            Permission.VIEW_ALL_EXTERNAL_BDC_REPORTS,
                            Permission.VIEW_ALL_REPORTS,
                        ],
                    }),
                ],
            }),
        ],
    }),
    new NavigationFolder({
        title: $t("Abrechnung"),
        icon: "mdi-bank-transfer",
        items: [
            new NavigationItem({
                title: $t("Rechnungen"),
                path: "invoices",
                component: InvoicesOverview,
                permission: Permission.MANAGE_BILLING,
            }),
        ],
    }),
    new NavigationFolder({
        title: $t("Interne Administration"),
        icon: "mdi-cog",
        items: [
            new NavigationItem({
                title: $t("Standorte"),
                path: "dealerstubs",
                component: DealerStubs,
                permission: Permission.DEALER_STUB_MANAGEMENT,
            }),
            new NavigationItem({
                title: $t("Kontakte"),
                path: "contacts-internal-administration",
                component: ContactSearchOverview,
                props: { canSeeBatchUpdate: true },
                permission: Permission.VIEW_CONTACTS,
                guard: () => userSession.isCtUser() && userSession.hasPermission(Permission.DEALER_STUB_MANAGEMENT),
            }),
            new NavigationItem({
                title: $t("Fälle"),
                path: "cases-internal-administration",
                component: CasesOverview,
                props: { canSeeBatchUpdate: true },
                permission: Permission.MANAGE_ALL_CASES,
                guard: () => userSession.isCtUser() && userSession.hasPermission(Permission.DEALER_STUB_MANAGEMENT),
            }),
            new NavigationItem({
                title: $t("Verkaufschancen"),
                path: "opportunities-internal-administration",
                component: OpportunitiesOverview,
                props: { canSeeBatchUpdate: true },
                permission: Permission.MANAGE_ALL_OPPORTUNITIES,
                guard: () => userSession.isCtUser() && userSession.hasPermission(Permission.DEALER_STUB_MANAGEMENT),
            }),
            new NavigationFolder({
                title: $t("Einstellungen"),
                items: [
                    new NavigationItem({
                        title: $t("Frontendeinstellungen"),
                        path: "frontend-settings",
                        component: FrontendSettings,
                        permission: Permission.MANAGE_FRONTEND_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Marken- und Modelleinstellungen"),
                        path: "make-model-settings",
                        component: MakeModelSettings,
                        permission: Permission.MANAGE_MAKE_MODEL_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("Eingehende Rufnummern"),
                        path: "incoming-phone-numbers",
                        component: IncomingPhoneNumbersManagement,
                        permission: Permission.MANAGE_INCOMING_PHONE_NUMBERS,
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Externes BDC"),
                items: [
                    new NavigationItem({
                        title: $t("Anweisungen"),
                        path: "dealer-instructions",
                        component: DealerInstructionOverview,
                        permission: Permission.MANAGE_DEALER_INSTRUCTIONS,
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Drittanbieter"),
                items: [
                    new NavigationItem({
                        title: $t("AutoCRM Lead-Mailer-Einstellungen"),
                        path: "auto-crm-lead-mailer-settings",
                        component: AutoCrmLeadMailerSettings,
                        permission: Permission.MANAGE_AUTO_CRM_LEAD_MAILER_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("AutoCRM Benutzereinstellungen"),
                        path: "auto-crm-lead-mailer-user-settings",
                        component: AutoCrmLeadMailerUserSettings,
                        permission: Permission.MANAGE_AUTO_CRM_LEAD_MAILER_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("CARRY Dispo Einstellungen"),
                        path: "carry-dispo-settings",
                        component: CarryDispoSettings,
                        permission: Permission.MANAGE_CARRY_DISPO_SETTINGS,
                    }),
                    new NavigationItem({
                        title: $t("CitNOW Einstellungen"),
                        path: "citnow-video-settings",
                        component: CitnowVideoSettings,
                        permission: Permission.MANAGE_CITNOW_VIDEO_SETTINGS,
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Einrichtung"),
                items: [
                    new NavigationItem({
                        title: $t("Einstellungsimport"),
                        path: "initialize-partition",
                        component: InitializePartition,
                        permission: Permission.INITIALIZE_PARTITION,
                    }),
                    new NavigationItem({
                        title: $t("Benutzer"),
                        path: "internal-user-administration",
                        component: InternalUserAdministration,
                        permission: Permission.USER_MANAGEMENT,
                        guard: () => userSession.isCtUser(),
                    }),
                    new NavigationItem({
                        title: $t("Willkommens-E-Mail"),
                        path: "welcome-email",
                        component: WelcomeEmail,
                        permission: Permission.SEND_WELCOME_EMAIL,
                    }),
                ],
            }),
            new NavigationFolder({
                title: $t("Wartung"),
                items: [
                    new NavigationItem({
                        title: $t("Suchindex"),
                        path: "rebuild-indexes",
                        component: RebuildIndexes,
                        permission: Permission.REBUILD_INDEXES,
                    }),
                    new NavigationItem({
                        title: $t("Speicher"),
                        path: "storage-housekeeping",
                        component: HouseKeepStorage,
                        permission: Permission.HOUSEKEEP_STORAGE,
                    }),
                    new NavigationItem({
                        title: $t("Fahrzeugbestand"),
                        path: "inventory-housekeeping",
                        component: HousekeepInventory,
                        permission: Permission.REMOVE_INVENTORY_VEHICLE_DUPLICATES,
                    }),
                ],
            }),
        ],
    }),
    new NavigationItem({
        title: $t("Support"),
        icon: "mdi-lifebuoy",
        path: "support",
        component: SupportOverview,
        permission: Permission.VIEW_SUPPORT_PAGE,
    }),
];
