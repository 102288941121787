
import CaseDataCheckCard from "./CaseDataCheckCard.vue";
import { caseAssistantState, CaseAssistantStep } from "./caseAssistant";
import CaseEscalationFormCard from "./wizard/CaseEscalationFormCard.vue";
import CaseEscalationGroupFormCard from "./wizard/CaseEscalationGroupFormCard.vue";
import CaseIndividualReceiverFormCard from "./wizard/CaseIndividualReceiverFormCard.vue";
import CasePreferredContactCard from "./wizard/CasePreferredContactCard.vue";
import CasePreferredContactFormCard from "./wizard/CasePreferredContactFormCard.vue";
import CaseSentimentFormCard from "./wizard/CaseSentimentFormCard.vue";
import CaseSubjectFormCard from "./wizard/CaseSubjectFormCard.vue";
import ContactIssuesCard from "./wizard/ContactIssuesCard.vue";
import { CaseChannel, casesApi } from "@/api/cases";
import { ContactMethod, contactsApi, ContactSource, ContactType, NumberType } from "@/api/contacts";
import { DealerInstructionCategory, IssueType } from "@/api/dealerInstructions";
import { VehicleClass } from "@/api/makeModels";
import { Permission } from "@/api/userSession";
import { ContactAndVehicleId } from "@/app/contactUtils";
import { processQueryParameters } from "@/app/issueAssistantUtils";
import { showConfirm, showError } from "@/app/messageUtil";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import ContactAndVehicleFormCard from "@/app/pages/contacts/ContactAndVehicleFormCard.vue";
import { SearchResult } from "@/app/pages/contacts/contactSearchUtils";
import ContactSearchFilterCard from "@/app/pages/contacts/contactandvehiclesearchcard/ContactSearchFilterCard.vue";
import ContactSearchResultsCard from "@/app/pages/contacts/contactandvehiclesearchcard/ContactSearchResultsCard.vue";
import DealerInstructions from "@/app/pages/dealerinstructions/DealerInstructions.vue";
import { email, validate } from "@/app/validation";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { isMobile, parseAndFormatNumber, PhoneNumberCountryCode } from "@/util/phoneNumberUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            CaseAssistantStep,
            ContactMethod,
            IssueType,
            Permission,
            caseAssistantState,
            contactSearchStep: {
                creatingContact: false,
                searching: true,
                searchResult: null as SearchResult | null,
            },
            receiverSelectionStep: {
                casePreferredContactFormCardVisible: false,
                creatingCase: false,
            },
        };
    },

    computed: {
        caseAssistantStep(): CaseAssistantStep {
            return this.caseAssistantState.step;
        },

        contactType(): ContactType | null {
            return this.caseAssistantState.contactData?.type ?? null;
        },

        contactVehicleClass(): VehicleClass | null {
            return this.caseAssistantState.contactVehicleData?.vehicleClass ?? null;
        },

        contactVehicleMake(): string | null {
            return this.caseAssistantState.contactVehicleData?.make ?? null;
        },

        dealerId() {
            return userSession.dealerId;
        },

        dealerInstructionCategories(): DealerInstructionCategory[] {
            if (this.caseAssistantStep === CaseAssistantStep.CONTACT_SEARCH) {
                return [DealerInstructionCategory.CONTACT_SEARCH];
            } else if (this.caseAssistantStep === CaseAssistantStep.CONTACT_DATA_ACQUISITION) {
                return [DealerInstructionCategory.CONTACT_SEARCH];
            } else if (this.caseAssistantStep === CaseAssistantStep.CASE_DATA_ACQUISITION) {
                return [DealerInstructionCategory.DATA_ACQUISITION];
            } else {
                return [DealerInstructionCategory.RECEIVER_SELECTION];
            }
        },

        isCtUser(): boolean {
            return userSession.isCtUser();
        },

        isEmptyContactSearchRequest(): boolean {
            return this.contactSearchStep.searchResult?.isSearchRequestEmpty ?? true;
        },

        phoneticSpellingLocale(): string {
            return configStore.configuration.defaultLocale;
        },

        working(): boolean {
            return this.contactSearchStep.creatingContact || this.receiverSelectionStep.creatingCase;
        },
    },

    methods: {
        async createCase() {
            if (this.caseAssistantStep !== CaseAssistantStep.RECEIVER_SELECTION) {
                return;
            }

            const caseCreationForm = this.caseAssistantState.caseCreationForm;

            if (!(this.$refs.form as any).validate() || !caseCreationForm) {
                showError(this.$t("Bitte korrigieren Sie Ihre fehlerhaften Eingaben.") as string);
                return;
            }

            if (!this.caseAssistantState.contactAndVehicleId?.contactId && userSession.isCtUser()) {
                showError(this.$t("Es ist kein Kontakt ausgewählt!") as string);
                return;
            }

            if (
                !this.caseAssistantState.escalationGroupReceivers.length &&
                !this.caseAssistantState.individualReceivers.userIds.length
            ) {
                showError(this.$t("Es ist kein Empfänger ausgewählt!") as string);
                return;
            }

            if (
                !this.caseAssistantState.contactAndVehicleId?.contactId &&
                !(await showConfirm(
                    this.$t("Es ist kein Kontakt ausgewählt!") as string,
                    this.$t("Sind Sie sicher, dass Sie ohne Kontakt fortfahren möchten?") as string
                ))
            ) {
                return;
            }

            if (
                this.caseAssistantState.contactAndVehicleId?.contactId &&
                !this.caseAssistantState.contactAndVehicleId?.contactVehicleId &&
                !(await showConfirm(
                    this.$t("Es ist kein Fahrzeug ausgewählt!") as string,
                    this.$t("Sind Sie sicher, dass Sie ohne Fahrzeug fortfahren möchten?") as string
                ))
            ) {
                return;
            }

            const assignToSelf =
                !this.caseAssistantState.escalationGroupReceivers.length &&
                this.caseAssistantState.individualReceivers.userIds.length === 1 &&
                this.caseAssistantState.individualReceivers.userIds[0] === userSession.userId &&
                (await showConfirm(
                    this.$t("Sie sind der einzige Empfänger!") as string,
                    this.$t("Möchten Sie sich den Fall direkt zuteilen?") as string,
                    this.$t("Ja") as string,
                    this.$t("Nein") as string
                ));

            this.receiverSelectionStep.creatingCase = true;
            try {
                const caseId = await casesApi.addCase(
                    caseCreationForm,
                    this.caseAssistantState.initialNoteContent!,
                    assignToSelf ? [userSession.userId!] : [],
                    [],
                    () => {
                        return;
                    }
                );

                this.caseAssistantState.reset(null, null);
                await this.$router.push(`/case/${caseId}`);
            } finally {
                this.receiverSelectionStep.creatingCase = false;
            }
        },

        async createContactAndVehicle() {
            if (this.caseAssistantStep !== CaseAssistantStep.CONTACT_SEARCH) {
                return;
            }

            this.contactSearchStep.creatingContact = true;
            try {
                const contactId = await contactsApi.addContact({
                    source: ContactSource.UI,
                    dealerId: userSession.dealerId,
                    status: null,
                    type: trimAndReturnNullIfEmpty(this.caseAssistantState.contactSearchRequest.companyName)
                        ? ContactType.BUSINESS
                        : null,
                    salutation: null,
                    knownSince: null,
                    dateOfBirth: null,
                    companyName: this.caseAssistantState.contactSearchRequest.companyName,
                    position: null,
                    department: null,
                    namePrefix: null,
                    givenName: null,
                    middleName: null,
                    familyName: this.caseAssistantState.contactSearchRequest.fullName,
                    nameSuffix: null,
                    address1: null,
                    address2: this.caseAssistantState.contactSearchRequest.address,
                    zip: null,
                    city: null,
                    state: null,
                    country: null,
                    numbers: [this.caseAssistantState.contactSearchRequest.number]
                        .map((number) =>
                            parseAndFormatNumber(
                                number ?? "",
                                "E.164",
                                configStore.configuration.defaultCountry as PhoneNumberCountryCode
                            )
                        )
                        .filter((number) => !!number)
                        .map((number) => number!)
                        .map((number) => ({
                            type: isMobile(number) ? NumberType.MOBILE : NumberType.LANDLINE,
                            number,
                            label: null,
                        })),
                    emailAddresses: [this.caseAssistantState.contactSearchRequest.emailAddress]
                        .map((address) => trimAndReturnNullIfEmpty(address))
                        .filter((address) => !!address && validate(email(), address))
                        .map((address) => address!)
                        .map((address) => ({
                            address,
                            label: null,
                        })),
                    website: null,
                    notes: null,
                    url: null,
                    externalReferences: [],
                });

                this.caseAssistantState.contactAndVehicleId = {
                    contactId,
                    contactVehicleId: await contactsApi.addContactVehicle(contactId, {
                        vin: this.caseAssistantState.contactSearchRequest.vin,
                        licensePlate: this.caseAssistantState.contactSearchRequest.licensePlate,
                        vehicleClass: null,
                        make: null,
                        model: null,
                        modelDescription: null,
                        modelYear: null,
                        firstRegistration: null,
                        nextGeneralInspection: null,
                        nextInspection: null,
                        nextInspectionMileage: null,
                        nextInspectionMileageUnit: null,
                        warrantyUntil: null,
                        warrantyUntilMileage: null,
                        warrantyUntilMileageUnit: null,
                        warrantyPaintingUntil: null,
                        warrantyRustUntil: null,
                        records: [],
                        notes: null,
                        url: null,
                        externalReference: null,
                    }),
                };
                this.caseAssistantState.step = CaseAssistantStep.CONTACT_DATA_ACQUISITION;
            } finally {
                this.contactSearchStep.creatingContact = false;
            }
        },

        async reset() {
            if (
                await showConfirm(
                    this.$t("Eingaben zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?") as string
                )
            ) {
                this.caseAssistantState.reset(null, null);
            }
        },

        selectContactAndVehicle(contactAndVehicleId: ContactAndVehicleId | null) {
            const oldContactAndVehicleId = this.caseAssistantState.contactAndVehicleId;

            this.caseAssistantState.contactAndVehicleId = contactAndVehicleId;

            if (this.caseAssistantStep === CaseAssistantStep.CONTACT_SEARCH) {
                this.stepForward();
            } else if (oldContactAndVehicleId && !contactAndVehicleId) {
                this.caseAssistantState.step = CaseAssistantStep.CONTACT_SEARCH;
            }
        },

        setEscalationBeginTimeZoneIfNull() {
            if (!caseAssistantState.escalationBeginTimeZone && this.dealerId) {
                caseAssistantState.escalationBeginTimeZone = dealersStore.dealerById(this.dealerId)?.timeZone ?? null;
            }
        },

        setPreferredContactMethod(contactMethod: ContactMethod, preferredContactDetails: string) {
            this.caseAssistantState.preferredContact.preferredContactMethod = contactMethod;
            this.caseAssistantState.preferredContact.preferredContactDetails = preferredContactDetails;
        },

        setPreferredContactTimeZoneIfNull() {
            if (!this.caseAssistantState.preferredContactTimeZone && this.dealerId) {
                this.caseAssistantState.preferredContactTimeZone =
                    dealersStore.dealerById(this.dealerId)?.timeZone ?? null;
            }
        },

        async stepBackward() {
            if (
                this.caseAssistantStep === CaseAssistantStep.CONTACT_SEARCH ||
                (this.caseAssistantStep === CaseAssistantStep.CONTACT_DATA_ACQUISITION &&
                    !!this.caseAssistantState.contactAndVehicleId)
            ) {
                return;
            }

            this.$vuetify.goTo(0, { duration: 0 });

            if (this.caseAssistantStep === CaseAssistantStep.RECEIVER_SELECTION) {
                this.$refs.contactAndVehicleFormCard?.setEditMode(false);
                this.caseAssistantState.step = CaseAssistantStep.CASE_DATA_ACQUISITION;
            } else if (this.caseAssistantStep === CaseAssistantStep.CASE_DATA_ACQUISITION) {
                if (this.caseAssistantState.contactAndVehicleId) {
                    this.caseAssistantState.step = CaseAssistantStep.CONTACT_DATA_ACQUISITION;
                } else {
                    this.caseAssistantState.step = CaseAssistantStep.CONTACT_SEARCH;
                }
            }
        },

        async stepForward() {
            if (this.caseAssistantStep === CaseAssistantStep.RECEIVER_SELECTION) {
                return;
            }

            this.$vuetify.goTo(0, { duration: 0 });

            if (!(this.$refs.form as any).validate()) {
                showError(this.$t("Bitte korrigieren Sie Ihre fehlerhaften Eingaben.") as string);
                return;
            }

            if (this.caseAssistantStep === CaseAssistantStep.CONTACT_SEARCH) {
                if (this.caseAssistantState.contactAndVehicleId) {
                    this.$refs.contactAndVehicleFormCard?.setEditMode(true);
                    this.caseAssistantState.step = CaseAssistantStep.CONTACT_DATA_ACQUISITION;
                } else {
                    this.$refs.contactAndVehicleFormCard?.setEditMode(false);
                    this.caseAssistantState.step = CaseAssistantStep.CASE_DATA_ACQUISITION;
                }
            } else if (this.caseAssistantStep === CaseAssistantStep.CONTACT_DATA_ACQUISITION) {
                this.$refs.contactAndVehicleFormCard?.setEditMode(false);
                this.caseAssistantState.step = CaseAssistantStep.CASE_DATA_ACQUISITION;
            } else if (this.caseAssistantStep === CaseAssistantStep.CASE_DATA_ACQUISITION) {
                this.$refs.contactAndVehicleFormCard?.setEditMode(false);
                this.receiverSelectionStep = {
                    casePreferredContactFormCardVisible: false,
                    creatingCase: false,
                };
                this.caseAssistantState.step = CaseAssistantStep.RECEIVER_SELECTION;
            }
        },

        handleKeyDown(e: KeyboardEvent) {
            const htmlElementTagName = ((e.target as HTMLElement)?.tagName ?? "").toLowerCase();

            if (htmlElementTagName === "input" || htmlElementTagName === "textarea") {
                return;
            }

            if (e.code === "ArrowLeft") {
                this.stepBackward();
            } else if (e.code === "ArrowRight") {
                this.stepForward();
            }
        },
    },

    watch: {
        dealerId(newDealerId: string) {
            this.caseAssistantState.dealerId = newDealerId;
            this.setPreferredContactTimeZoneIfNull();
            this.setEscalationBeginTimeZoneIfNull();
        },
    },

    created() {
        if (!this.caseAssistantState.dealerId && this.dealerId) {
            this.caseAssistantState.dealerId = this.dealerId;
        }
        if (this.caseAssistantState.initialNoteContent === null) {
            this.caseAssistantState.resetActivityContent();
        }
        if (this.caseAssistantState.channel === null) {
            this.caseAssistantState.channel = userSession.isCtUser()
                ? CaseChannel.PHONE_BDC
                : CaseChannel.PHONE_INTERNAL;
        }
        if (this.caseAssistantState.contactAndVehicleId) {
            if (this.caseAssistantStep === CaseAssistantStep.CONTACT_SEARCH) {
                this.caseAssistantState.step = CaseAssistantStep.CONTACT_DATA_ACQUISITION;
            }
        } else {
            if (this.caseAssistantStep === CaseAssistantStep.CONTACT_DATA_ACQUISITION) {
                this.caseAssistantState.step = CaseAssistantStep.CONTACT_SEARCH;
            }
        }

        this.setPreferredContactTimeZoneIfNull();
        this.setEscalationBeginTimeZoneIfNull();

        window.addEventListener("keydown", this.handleKeyDown);
    },

    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeyDown);
    },

    beforeRouteEnter(to, _, next) {
        processQueryParameters(to, next);
    },

    components: {
        CaseDataCheckCard,
        CaseEscalationFormCard,
        CaseEscalationGroupFormCard,
        CaseIndividualReceiverFormCard,
        CasePreferredContactCard,
        CasePreferredContactFormCard,
        CaseSentimentFormCard,
        CaseSubjectFormCard,
        ContactAndVehicleFormCard,
        ContactIssuesCard,
        ContactSearchFilterCard,
        ContactSearchResultsCard,
        DealerContextGuard,
        DealerInstructions,
    },
});
