import { ContactType } from "@/api/contacts";
import { AcquisitionType, OpportunityTeamFilter } from "@/api/opportunityTeams";
import { VehicleSummary } from "@/app/inventoryUtils";

function compareOrFilterNull<T>(f: T | null, v: T | null, compare: (f: T, v: T) => boolean) {
    return f === null || (v !== null && compare(f, v));
}

function containsOrEmpty<T>(l: T[], e: T | null) {
    return !l.length || (e !== null && l.indexOf(e) >= 0);
}

export function opportunityTeamFilterMatches(
    filter: OpportunityTeamFilter,
    opportunitySourceId: string | null,
    contactType: ContactType | null,
    v: VehicleSummary,
    acquisitionType: AcquisitionType | null,
    tradeInRequested: boolean | null
) {
    return (
        containsOrEmpty(filter.opportunitySourceIds, opportunitySourceId) &&
        compareOrFilterNull(filter.preOffer, v.preOffer, (f, v) => f === v) &&
        compareOrFilterNull(filter.internalIdPrefix, v.internalId, (prefix, internalId) =>
            internalId.toLowerCase().startsWith(prefix.toLowerCase())
        ) &&
        compareOrFilterNull(filter.internalIdSuffix, v.internalId, (suffix, internalId) =>
            internalId.toLowerCase().endsWith(suffix.toLowerCase())
        ) &&
        compareOrFilterNull(filter.mileageFrom, v.mileage, (from, m) => from <= m) &&
        compareOrFilterNull(filter.mileageTo, v.mileage, (to, m) => m <= to) &&
        containsOrEmpty(filter.contactTypes, contactType) &&
        containsOrEmpty(filter.vehicleClasses, v.vehicleClass) &&
        containsOrEmpty(filter.makes, v.make) &&
        containsOrEmpty(filter.models, v.model) &&
        containsOrEmpty(filter.usageTypes, v.usageType) &&
        containsOrEmpty(filter.fuels, v.fuel) &&
        containsOrEmpty(filter.acquisitionTypes, acquisitionType) &&
        compareOrFilterNull(filter.tradeInRequested, tradeInRequested, (f, v) => f === v)
    );
}
