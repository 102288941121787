import { FinanceContractSearchOrder, FinanceContractSearchRequest } from "@/api/financeContractSearch";

export const EMPTY_SEARCH_REQUEST: FinanceContractSearchRequest = {
    dealerIds: [],
    uploadSourceIds: [],
    processingStates: [],
    finalInstallmentPaymentDateFrom: null,
    finalInstallmentPaymentDateTo: null,
    processFrom: null,
    processTo: null,
    hasFinalInstallmentPaymentDate: null,
    search: null,
    sortBy: FinanceContractSearchOrder.FINAL_INSTALLMENT_PAYMENT_DATE_ASC,
};
