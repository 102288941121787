
import EmergencyIncidentsDataTableRow from "./EmergencyIncidentsDataTableRow.vue";
import { emergencyIncidentSearchApi, EmergencyIncidentSearchRequest } from "@/api/emergencyIncidentSearch";
import { EmergencyIncidentResult } from "@/api/emergencyIncidents";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { PickMutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        canSelect: {
            type: Boolean,
            default: false,
        },
        itemsPerPage: {
            type: Number,
            default: 100,
        },
        openInNewTab: {
            type: Boolean,
            default: false,
        },
        searchRequest: {
            type: Object as () => EmergencyIncidentSearchRequest,
            required: true,
        },
        selectDisabledIds: {
            type: Array as () => string[],
            required: false,
            default: () => [],
        },
        ultraCompactMode: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            items: [] as EmergencyIncidentResult[],
            loading: true,
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            const headers: DataTableHeader[] = [{ width: "30%" }, { width: "35%" }];

            if (!this.ultraCompactMode) {
                headers.push({ width: "35%" });
            }

            if (this.canSelect) {
                headers.unshift({ width: "1%" });
            }

            return headers;
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const emergencyIncidentSearchResults = await emergencyIncidentSearchApi.search(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    this.searchRequest,
                    ++this.searchCounter
                );

                if (emergencyIncidentSearchResults.searchId === this.searchCounter) {
                    this.items = emergencyIncidentSearchResults.results;
                    this.paging.totalSize = emergencyIncidentSearchResults.totalSize;
                    this.$emit("loaded", emergencyIncidentSearchResults.totalSize);
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        select(emergencyIncidentResult: EmergencyIncidentResult) {
            if (this.selectDisabledIds.includes(emergencyIncidentResult.emergencyIncident.id)) {
                return;
            }

            this.$emit("select", emergencyIncidentResult);
        },
    },

    watch: {
        async searchRequest() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DataTable,
        EmergencyIncidentsDataTableRow,
    },
});
