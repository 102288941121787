import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum Gender {
    MALE = "MALE",
    FEMALE = "FEMALE",
    DIVERSE = "DIVERSE",
}

export enum AwayType {
    BREAK = "BREAK",
    MEETING = "MEETING",
    CUSTOMER_TALK = "CUSTOMER_TALK",
    OTHER = "OTHER",
}

export interface EmailAlias {
    emailDomainId: string;
    localPart: string;
    defaultSender: boolean;
    defaultReplyTo: boolean;
}

export interface Away {
    readonly type: AwayType;
    readonly notes: string | null;
    readonly since: Date;
    readonly until: Date | null;
}

export interface AwayForm {
    readonly type: AwayType;
    readonly notes: string | null;
    readonly until: Date | null;
}

export enum PhoneNumberType {
    LANDLINE = "LANDLINE",
    MOBILE = "MOBILE",
    FAX = "FAX",
}

export interface PhoneNumber {
    type: PhoneNumberType;
    publishable: boolean;
    number: string;
    label: string | null;
}

export interface DepartmentAssignment {
    departmentId: string;
    position: string | null;
}

export interface DealerAssignment {
    dealerId: string;
    phoneNumbers: PhoneNumber[];
    departmentAssignments: DepartmentAssignment[];
}

export enum Role {
    ACCOUNTANT = "ACCOUNTANT",
    CASE_VIEWER = "CASE_VIEWER",
    CASE_ACTIVITY_VIEWER = "CASE_ACTIVITY_VIEWER",
    CASE_ADMIN = "CASE_ADMIN",
    EMERGENCY_INCIDENT_ADMIN = "EMERGENCY_INCIDENT_ADMIN",
    OPPORTUNITY_VIEWER = "OPPORTUNITY_VIEWER",
    OPPORTUNITY_ACTIVITY_VIEWER = "OPPORTUNITY_ACTIVITY_VIEWER",
    OPPORTUNITY_ADMIN = "OPPORTUNITY_ADMIN",
    LOCAL_CONTROLLER = "LOCAL_CONTROLLER",
    GLOBAL_CONTROLLER = "GLOBAL_CONTROLLER",
    LOCAL_ADMIN = "LOCAL_ADMIN",
    SUPER_ADMIN = "SUPER_ADMIN",
}

export interface Status {
    readonly online: boolean;
    readonly absent: boolean;
    readonly withinWorkingHours: boolean | null;
    readonly workingHoursConfirmedCurrentWeek: boolean;
    readonly workingHoursConfirmedNextWeek: boolean;
}

export interface UserDirectoryEntry {
    readonly id: string;
    username: string;
    gender: Gender | null;
    namePrefix: string | null;
    givenName: string;
    familyName: string;
    mainDealerId: string;
    phoneNumbers: PhoneNumber[];
    dealerAssignments: DealerAssignment[];
    away: Away | null;
    availableForVideochat: boolean;
    status: Status;
    lastActive: Date | null;
    defaultSubstituteUserId: string | null;
}

export interface UserCreationResult {
    readonly id: string;
}

export interface User {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly username: string;
    readonly roles: Role[];
    readonly timeZone: string;
    readonly locale: string;
    readonly darkmode: boolean;
    readonly compactMode: boolean;
    readonly broadcastEmailsEnabled: boolean;
    readonly volume: number;
    readonly dateOfBirth: string | null;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly employeeNumber: string | null;
    readonly notes: string | null;
    readonly emailSignatureHash: string | null;
    readonly smsSignature: string | null;
    readonly languages: string[];
    readonly profileImageHash: string | null;
    readonly emailPublishable: boolean;
    readonly forwardEmails: boolean;
    readonly mainDealerId: string;
    readonly phoneNumbers: PhoneNumber[];
    readonly dealerAssignments: DealerAssignment[];
    readonly lastActive: Date | null;
    readonly away: Away | null;
    readonly availableForVideochat: boolean;
    readonly status: Status;
    readonly defaultCallerId: string | null;
    readonly terminalPhoneNumber: string | null;
    readonly emailAliases: EmailAlias[];
    readonly defaultSubstituteUserId: string | null;
}

export interface UserSummary {
    readonly id: string;
    readonly username: string;
    readonly roles: Role[];
    readonly timeZone: string;
    readonly locale: string;
    readonly dateOfBirth: string | null;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly employeeNumber: string | null;
    readonly languages: string[];
    readonly profileImageHash: string | null;
    readonly emailPublishable: boolean;
    readonly mainDealerId: string;
    readonly phoneNumbers: PhoneNumber[];
    readonly dealerAssignments: DealerAssignment[];
    readonly lastActive: Date | null;
    readonly away: Away | null;
    readonly availableForVideochat: boolean;
    readonly emailAliases: EmailAlias[];
    readonly defaultSubstituteUserId: string | null;
}

export interface UserForm {
    username: string;
    roles: Role[];
    timeZone: string;
    locale: string;
    dateOfBirth: string | null;
    gender: Gender | null;
    namePrefix: string | null;
    givenName: string;
    familyName: string;
    employeeNumber: string | null;
    notes: string | null;
    emailSignature: string | null;
    smsSignature: string | null;
    languages: string[];
    emailPublishable: boolean;
    forwardEmails: boolean;
    mainDealerId: string;
    phoneNumbers: PhoneNumber[];
    dealerAssignments: DealerAssignment[];
    defaultCallerId: string | null;
    terminalPhoneNumber: string | null;
    emailAliases: EmailAlias[];
    defaultSubstituteUserId: string | null;
}

interface UsersApi {
    getAll(): Promise<UserSummary[]>;
    getByMainDealer(mainDealerId: string): Promise<UserSummary[]>;
    getById(id: string): Promise<User>;
    getUserSummaryById(id: string): Promise<UserSummary>;
    add(
        userForm: UserForm,
        profileImage: File | null,
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<UserCreationResult>;
    edit(
        id: string,
        userForm: UserForm,
        profileImage: File | null,
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
    editEmailSignature(id: string, emailSignature: string | null): Promise<void>;
    editSmsSignature(id: string, smsSignature: string | null): Promise<void>;
    delete(id: string): Promise<void>;
    resetPassword(id: string): Promise<void>;
    resetForgottenPassword(username: string): Promise<void>;
    loginAsUser(id: string): Promise<void>;
    generateProfileImageLink(id: string, hash: string, width?: number, height?: number): string;
    getProfileImageByHash(id: string, hash: string, width?: number, height?: number): Promise<string>;
    getAllUserDirectoryEntries(): Promise<UserDirectoryEntry[]>;
    getEmailSignatureByHash(id: string, hash: string): Promise<string>;
    unsubscribe(userId: string): Promise<void>;
}

export const usersApi: UsersApi = {
    generateProfileImageLink(id, hash, width?: number, height?: number) {
        let params = "";
        let paramsSeperator = "?";

        if (width) {
            params += `${paramsSeperator}width=${width}`;
            paramsSeperator = "&";
        }

        if (height) {
            params += `${paramsSeperator}height=${height}`;
            paramsSeperator = "&";
        }

        return `/api/users/${id}/profile-image/${hash}.jpg${params}`;
    },

    async getProfileImageByHash(id, hash, width?: number, height?: number) {
        return (
            await axios.get(`/api/users/${id}/profile-image/${hash}.b64`, {
                params: {
                    width,
                    height,
                },
                responseType: "text",
            })
        ).data;
    },

    async getById(id) {
        return cloneObject((await axios.get(`/api/users/${id}`)).data);
    },

    async getAll() {
        return cloneObject((await axios.get("/api/users")).data);
    },

    async getByMainDealer(mainDealerId) {
        return cloneObject(
            (
                await axios.get("/api/users", {
                    params: {
                        mainDealerId,
                    },
                })
            ).data
        );
    },

    async getUserSummaryById(id) {
        return cloneObject((await axios.get(`/api/users/${id}/_summary`)).data);
    },

    async edit(id, userForm, profileImageFile, onUploadProgress) {
        const formData = new FormData();

        if (profileImageFile) {
            formData.append("profileImageFile", profileImageFile);
        }

        formData.append("userForm", new Blob([JSON.stringify(userForm)], { type: "application/json" }));

        await axios.put(`/api/users/${id}`, formData, { onUploadProgress });
    },

    async editEmailSignature(id, emailSignature) {
        const formData = new FormData();

        if (emailSignature) {
            formData.append("emailSignature", emailSignature);
        }

        await axios.post(`/api/users/${id}/_edit-email-signature`, formData);
    },

    async editSmsSignature(id, smsSignature) {
        const formData = new FormData();

        if (smsSignature) {
            formData.append("smsSignature", smsSignature);
        }

        await axios.post(`/api/users/${id}/_edit-sms-signature`, formData);
    },

    async add(userForm, profileImageFile, onUploadProgress) {
        const formData = new FormData();

        if (profileImageFile) {
            formData.append("profileImageFile", profileImageFile);
        }

        formData.append("userForm", new Blob([JSON.stringify(userForm)], { type: "application/json" }));

        return (await axios.post("/api/users", formData, { onUploadProgress })).data;
    },

    async resetPassword(id) {
        await axios.post(`/api/users/${id}/_reset-password`);
    },

    async resetForgottenPassword(username) {
        await axios.post("/api/users/_/_reset-forgotten-password", null, {
            params: {
                username,
            },
        });
    },

    async delete(id) {
        await axios.delete(`/api/users/${id}`);
    },

    async loginAsUser(id) {
        await axios.post(`/api/users/${id}/_login`);
    },

    async getAllUserDirectoryEntries() {
        return cloneObject((await axios.get("/api/users/_directory")).data);
    },

    async getEmailSignatureByHash(id, hash) {
        return (await axios.get(`/api/users/${id}/email-signature/${hash}.html`, { responseType: "text" })).data;
    },

    async unsubscribe(userId) {
        await axios.post(`/api/users/${userId}/_unsubscribe`);
    },
};
