
import { emergencyIncidentsApi } from "@/api/emergencyIncidents";
import { ContactAndVehicleId } from "@/app/contactUtils";
import { showError } from "@/app/messageUtil";
import ActionCard from "@/app/pages/ActionCard.vue";
import ContactAndVehiclePicker from "@/app/pages/contacts/ContactAndVehiclePicker.vue";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        emergencyIncidentId: {
            type: String,
            required: true,
        },
        contactId: {
            type: String,
            required: false,
        },
        vehicleId: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            working: false,
            contactAndVehicleId: (this.contactId
                ? {
                      contactId: this.contactId,
                      contactVehicleId: this.vehicleId ?? null,
                  }
                : null) as ContactAndVehicleId | null,
        };
    },

    computed: {
        isCtUser(): boolean {
            return userSession.isCtUser();
        },

        phoneticSpellingLocale(): string {
            return configStore.configuration.defaultLocale;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async save() {
            if (!this.contactAndVehicleId?.contactId) {
                await showError(this.$t("Es ist kein Kontakt ausgewählt!") as string);
                return;
            }

            if (!this.contactAndVehicleId?.contactVehicleId) {
                await showError(this.$t("Es ist kein Fahrzeug ausgewählt!") as string);
                return;
            }

            this.working = true;
            try {
                await emergencyIncidentsApi.editContactAndVehicle(this.emergencyIncidentId, {
                    contactId: this.contactAndVehicleId!.contactId,
                    contactVehicleId: this.contactAndVehicleId!.contactVehicleId!,
                });
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        ContactAndVehiclePicker,
    },
});
