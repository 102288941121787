
import UserOverviewDataTableRow from "./UserOverviewDataTableRow.vue";
import { Permission } from "@/api/userSession";
import { User, usersApi, UserSummary } from "@/api/users";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { searchFilter } from "@/app/searchFilter";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { departmentsStore } from "@/store/departments";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            loading: true,
            search: null as string | null,
            users: [] as UserSummary[],
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Benutzer"),
                    width: "20%",
                },
                {
                    text: this.$t("Standorte und Rufnummern"),
                    width: "35%",
                },
                {
                    text: this.$t("Details"),
                    width: "35%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "10%",
                },
            ];
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    methods: {
        filter(search: string | null, item: User): boolean {
            return searchFilter(
                search,
                getFullName(item),
                item.username,
                item.employeeNumber,
                ...item.dealerAssignments
                    .map((d) => [
                        dealersStore.dealerById(d.dealerId)?.name,
                        ...d.departmentAssignments
                            .map((da) => [da.position, departmentsStore.departmentById(da.departmentId)?.name])
                            .reduce((prev, cur) => prev.concat(cur), []),
                    ])
                    .reduce((prev, cur) => prev.concat(cur), [])
            );
        },

        async loadItems() {
            this.loading = true;

            try {
                if (this.dealerId) {
                    this.users = await usersApi.getByMainDealer(this.dealerId);
                } else {
                    this.users = [];
                }
            } finally {
                this.loading = false;
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DataTable,
        DealerContextGuard,
        UserOverviewDataTableRow,
    },
});
