
import { opportunitySettingsApi, OpportunitySettingsExceptionalUser } from "@/api/opportunitySettings";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import UserPickerDialog from "@/app/pages/users/UserPickerDialog.vue";
import { getFullName } from "@/app/userUtils";
import { opportunityOutcomeReasonsStore } from "@/store/opportunityOutcomeReasons";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            exceptionalUsers: [] as OpportunitySettingsExceptionalUser[],
            exceptionalUserPickerVisible: false,
            loading: true,
            outcomeReasonsToIgnoreInAssignmentLock: [] as string[],
            saving: false,
        };
    },

    computed: {
        exceptionalUserIds(): string[] {
            return this.exceptionalUsers.map((u) => u.userId);
        },

        outcomeReasonOptions(): SelectOptions {
            const won = opportunityOutcomeReasonsStore.opportunityOutcomeReasons.filter((r) => r.won);
            const notWon = opportunityOutcomeReasonsStore.opportunityOutcomeReasons.filter((r) => !r.won);

            const options: SelectOptions = [];

            if (won.length) {
                options.push(
                    { header: this.$t("Erfolgreich") },
                    ...won.map((r) => ({
                        value: r.id,
                        text: `${r.name}`,
                    }))
                );
            }

            if (notWon.length) {
                options.push(
                    { header: this.$t("Erfolglos") },
                    ...notWon.map((r) => ({
                        value: r.id,
                        text: `${r.name}`,
                    }))
                );
            }

            return options;
        },

        opportunitySettingsExceptionalUsers(): OpportunitySettingsExceptionalUser[] {
            return [...this.exceptionalUsers].sort((a, b) =>
                this.getUserFullNameById(a.userId).localeCompare(this.getUserFullNameById(b.userId), userSession.locale)
            );
        },
    },

    methods: {
        getUserFullNameById(userId: string) {
            return (getFullName(usersStore.getUserById(userId)) || this.$t("Unbekannt")).toString();
        },

        async removeExceptionalUser(index: number) {
            this.exceptionalUsers.splice(index, 1);
        },

        async submitForm() {
            this.saving = true;
            try {
                await opportunitySettingsApi.editExceptionalUsers(this.exceptionalUsers);
                await opportunitySettingsApi.editOutcomeReasonsToIgnoreInAssignmentLock(
                    this.outcomeReasonsToIgnoreInAssignmentLock
                );
            } finally {
                this.saving = false;
            }
        },

        updateExceptionalUsers(updatedUserIds: string[]) {
            this.exceptionalUserPickerVisible = false;

            const userIdsToDelete = this.exceptionalUserIds.filter((id) => !updatedUserIds.includes(id));
            const userIdsToAdd = updatedUserIds.filter((id) => !this.exceptionalUserIds.includes(id));

            const exceptionalUsers = this.exceptionalUsers;

            for (let i = exceptionalUsers.length - 1; i >= 0; --i) {
                if (userIdsToDelete.find((u) => u === exceptionalUsers[i].userId)) {
                    exceptionalUsers.splice(i, 1);
                }
            }

            for (const userId of userIdsToAdd) {
                exceptionalUsers.push({
                    userId,
                    canAssignAllHours: true,
                    canAssignWithoutTimeout: true,
                    canSeeHiddenOpportunityData: true,
                });
            }
        },
    },

    async mounted() {
        try {
            this.exceptionalUsers = await opportunitySettingsApi.getExceptionalUsers();
            this.outcomeReasonsToIgnoreInAssignmentLock = await opportunitySettingsApi.getAllOutcomeReasonsToIgnoreInAssignmentLock();
        } finally {
            this.loading = false;
        }
    },

    components: {
        UserPickerDialog,
        DealerGlobalContextHint,
    },
});
