export function removeMatchingItems<T>(items: T[], isToRemovePredicate: (item: T) => boolean) {
    for (let index = 0; index < items.length; ) {
        if (!isToRemovePredicate(items[index])) {
            index++;
            continue;
        }

        items.splice(index, 1);
    }
}

export function reorderItems<K, T>(items: T[], orderedIds: K[], getId: (item: T) => K) {
    const reordered: T[] = [...new Set(orderedIds)].reduce(
        (prev, id) => prev.concat(items.filter((i) => getId(i) === id)),
        [] as T[]
    );

    const missing: T[] = items.filter((i) => !orderedIds.includes(getId(i)));

    items.splice(0);

    items.push(...reordered);
    items.push(...missing);
}

export function updateItem<K, T>(items: T[], updatedItem: T, getId: (item: T) => K) {
    const updatedId: K = getId(updatedItem);

    let updated = false;

    for (let index = 0; index < items.length; index++) {
        if (getId(items[index]) !== updatedId) {
            continue;
        }

        items.splice(index, 1, updatedItem);
        updated = true;
    }

    if (!updated) {
        items.push(updatedItem);
    }
}
