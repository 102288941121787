import axios from "axios";

export interface VideochatSettingsForm {
    readonly appId: string | null;
    readonly keyId: string | null;
    readonly privateKey: string | null;
    readonly overwritePrivateKey: boolean;
}

export interface VideochatSettingsSummary {
    readonly appId: string | null;
    readonly keyId: string | null;
    readonly hasPrivateKey: boolean;
}

interface VideochatSettingsApi {
    editVideochatSettings(form: VideochatSettingsForm): Promise<void>;
    getVideochatSettings(): Promise<VideochatSettingsSummary>;
}

export const videochatSettingsApi: VideochatSettingsApi = {
    async editVideochatSettings(form) {
        await axios.put("/api/videochat-settings", form);
    },

    async getVideochatSettings() {
        return (await axios.get("/api/videochat-settings")).data;
    },
};
