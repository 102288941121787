
import { videochatSettingsApi, VideochatSettingsForm, VideochatSettingsSummary } from "@/api/videochatSettings";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { maxLength } from "@/app/validation";
import { configStore } from "@/store/config";
import { Mutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            maxLength,
            loading: true,
            saving: false,
            videochatSettingsSummary: null as VideochatSettingsSummary | null,
            videochatSettingsForm: {
                appId: null,
                keyId: null,
                privateKey: null,
                overwritePrivateKey: true,
            } as Mutable<VideochatSettingsForm>,
        };
    },

    methods: {
        async loadSettings() {
            this.videochatSettingsSummary = null;
            this.loading = true;
            try {
                this.videochatSettingsSummary = await videochatSettingsApi.getVideochatSettings();

                this.videochatSettingsForm.appId = this.videochatSettingsSummary.appId;
                this.videochatSettingsForm.keyId = this.videochatSettingsSummary.keyId;
                this.videochatSettingsForm.overwritePrivateKey = !this.videochatSettingsSummary.hasPrivateKey;
            } finally {
                this.loading = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await videochatSettingsApi.editVideochatSettings(this.videochatSettingsForm);
                await configStore.initialize();
                await this.loadSettings();
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        await this.loadSettings();
    },

    components: {
        DealerGlobalContextHint,
    },
});
