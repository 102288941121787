import axios from "axios";

export enum NotificationReason {
    CASE_CREATED = "CASE_CREATED",
    CASE_REOPENED = "CASE_REOPENED",
    CASE_UNPOSTPONED = "CASE_UNPOSTPONED",
    CASE_CLOSED = "CASE_CLOSED",
    CASE_NEW_ACTIVITY = "CASE_NEW_ACTIVITY",
    CASE_ESCALATED = "CASE_ESCALATED",
    EMERGENCY_INCIDENT_CREATED = "EMERGENCY_INCIDENT_CREATED",
    EMERGENCY_INCIDENT_CLOSED = "EMERGENCY_INCIDENT_CLOSED",
    EMERGENCY_INCIDENT_NEW_ACTIVITY = "EMERGENCY_INCIDENT_NEW_ACTIVITY",
    OPPORTUNITY_CREATED = "OPPORTUNITY_CREATED",
    OPPORTUNITY_REOPENED = "OPPORTUNITY_REOPENED",
    OPPORTUNITY_UNPOSTPONED = "OPPORTUNITY_UNPOSTPONED",
    OPPORTUNITY_CLOSED = "OPPORTUNITY_CLOSED",
    OPPORTUNITY_NEW_ACTIVITY = "OPPORTUNITY_NEW_ACTIVITY",
}

export enum NotificationType {
    DESKTOP_NOTIFICATION = "DESKTOP_NOTIFICATION",
    EMAIL_WITHIN_PRESENCE = "EMAIL_WITHIN_PRESENCE",
    EMAIL_OUTSIDE_PRESENCE = "EMAIL_OUTSIDE_PRESENCE",
}

export interface UserNotificationSettingsItem {
    readonly notificationReason: NotificationReason;
    desktopNotification: boolean;
    emailWithinPresence: boolean;
    emailOutsidePresence: boolean;
}

export interface DesktopNotificationSubscription {
    readonly endpoint: string;
    readonly p256dh: string;
    readonly auth: string;
}

interface UserNotificationSettingsApi {
    getByUser(userId: string): Promise<UserNotificationSettingsItem[]>;
    edit(userId: string, userNotificationSettingsItem: UserNotificationSettingsItem): Promise<void>;
    editByType(userId: string, type: NotificationType, enabled: boolean): Promise<void>;
    subscribeDesktopNotifications(subscription: DesktopNotificationSubscription): Promise<void>;
    unsubscribeDesktopNotifications(endpoint: string): Promise<void>;
}

export const userNotificationSettingsApi: UserNotificationSettingsApi = {
    async getByUser(userId) {
        return (await axios.get("/api/user-notification-settings", { params: { userId } })).data;
    },

    async edit(userId, userNotificationSettingsItem) {
        await axios.post("/api/user-notification-settings", userNotificationSettingsItem, {
            params: {
                userId,
            },
        });
    },

    async editByType(userId, type, enabled) {
        await axios.post("/api/user-notification-settings/_edit-by-type", undefined, {
            params: {
                userId,
                type,
                enabled,
            },
        });
    },

    async subscribeDesktopNotifications(subscription) {
        await axios.post("/api/user-notification-settings/subscriptions", subscription);
    },

    async unsubscribeDesktopNotifications(endpoint: string) {
        await axios.delete(`/api/user-notification-settings/subscriptions`, {
            params: {
                endpoint,
            },
        });
    },
};
