import axios from "axios";

interface ContactDuplicatesApi {
    removeDuplicates(): Promise<number>;
}

export const contactDuplicatesApi: ContactDuplicatesApi = {
    async removeDuplicates() {
        return (await axios.post("/api/contact-duplicates/_remove-duplicates")).data;
    },
};
