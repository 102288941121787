import axios from "axios";

export enum AbsenceType {
    TRAINING_SCHOOL = "TRAINING_SCHOOL",
    BUSINESS_TRIP = "BUSINESS_TRIP",
    PARENTAL_LEAVE = "PARENTAL_LEAVE",
    HOME_OFFICE = "HOME_OFFICE",
    VACATION = "VACATION",
    SICKNESS = "SICKNESS",
    FIELD_WORK = "FIELD_WORK",
    FURTHER_TRAINING = "FURTHER_TRAINING",
    COMPENSATORY_TIME_OFF = "COMPENSATORY_TIME_OFF",
    EXHIBITION = "EXHIBITION",
}

export interface Absence {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly userId: string;
    readonly externalAbsenceProviderId: string | null;
    readonly externalAbsenceProviderReference: string | null;
    readonly from: Date;
    readonly to: Date | null;
    readonly type: AbsenceType | null;
    readonly substituteUserIds: string[];
    readonly notes: string | null;
    readonly defaultSubstituteRemoved: boolean | null;
}

export interface AbsenceForm {
    readonly from: Date;
    readonly to: Date | null;
    readonly type: AbsenceType | null;
    readonly substituteUserIds: string[];
    readonly notes: string | null;
    readonly defaultSubstituteRemoved: boolean | null;
}

interface AbsencesApi {
    getByMainDealer(dealerId: string): Promise<Absence[]>;
    getByUser(userId: string): Promise<Absence[]>;
    add(userId: string, absenceForm: AbsenceForm): Promise<void>;
    edit(id: string, absenceForm: AbsenceForm): Promise<void>;
    delete(id: string): Promise<void>;
    removeDefaultSubstitute(id: string): Promise<void>;
}

export const absencesApi: AbsencesApi = {
    async getByMainDealer(dealerId) {
        const result: Absence[] = (await axios.get(`/api/absences?dealerId=${dealerId}`)).data;

        return result.map((v) => ({
            ...v,
            created: new Date(v.created),
            updated: new Date(v.updated),
            from: new Date(v.from),
            to: v.to ? new Date(v.to) : null,
        }));
    },

    async getByUser(userId) {
        const result: Absence[] = (await axios.get(`/api/absences?userId=${userId}`)).data;

        return result.map((v) => ({
            ...v,
            created: new Date(v.created),
            updated: new Date(v.updated),
            from: new Date(v.from),
            to: v.to ? new Date(v.to) : null,
        }));
    },

    async edit(id, absenceForm) {
        await axios.put(`/api/absences/${id}`, absenceForm);
    },

    async add(userId, absenceForm) {
        return (await axios.post(`/api/absences?userId=${userId}`, absenceForm)).data;
    },

    async delete(id) {
        await axios.delete(`/api/absences/${id}`);
    },

    async removeDefaultSubstitute(id) {
        await axios.post(`/api/absences/${id}/_remove-default-substitute`);
    },
};
