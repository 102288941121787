
import { CaseChannel } from "@/api/cases";
import { BadRequest } from "@/api/errors";
import { OpportunityChannel } from "@/api/opportunities";
import {
    RenaultRaceAccount,
    RenaultRaceAccountDealerForm,
    RenaultRaceAccountForm,
    renaultRaceAccountsApi,
    RenaultRaceApiErrorType,
} from "@/api/renaultRaceAccounts";
import { Permission } from "@/api/userSession";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import EnumField from "@/app/components/EnumField.vue";
import { dealerOptions, getSortedByDealer } from "@/app/dealerUtils";
import { showConfirm, showError, showInfo } from "@/app/messageUtil";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import CrudPage from "@/app/pages/CrudPage.vue";
import { getFullName } from "@/app/userUtils";
import { maxLength, notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { formatInstant } from "@/util/dateTimeUtils";
import { Mutable, PickNullable, SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

type MutableRenaultRaceAccountDealerForm = PickNullable<
    Mutable<RenaultRaceAccountDealerForm>,
    "renaultRaceDealerNumber" | "dealerId" | "opportunitySourceId"
>;

interface MutableRenaultRaceAccountForm {
    name: string | null;
    dealerForms: MutableRenaultRaceAccountDealerForm[];
    httpBasicAuthUsername: string | null;
    httpBasicAuthPassword: string | null;
}

const EMPTY_MUTABLE_RENAULT_RACE_ACCOUNT_DEALER_FORM: MutableRenaultRaceAccountDealerForm = {
    renaultRaceDealerNumber: null,
    dealerId: null,
    fallback: false,
    caseChannel: null,
    caseType: null,
    caseIndividualReceivers: [],
    escalationGroupReceivers: [],
    opportunityChannel: null,
    opportunitySourceId: null,
    opportunityIndividualReceivers: [],
    opportunityTeamReceivers: [],
};

export default Vue.extend({
    data() {
        return {
            CaseChannel,
            OpportunityChannel,
            Permission,
            maxLength,
            notEmpty,
            renaultRaceAccounts: [] as RenaultRaceAccount[],
        };
    },

    computed: {
        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        escalationGroupReceiversOptions(): SelectOption[] {
            return getSortedByDealer(escalationGroupsStore.escalationGroups, (e) => e.dealerId).map((e) => ({
                text: `${e.name} (${this.getDealerNameById(e.dealerId) || this.$t("Unbekannter Standort")})`,
                value: e.id,
            }));
        },

        individualReceiversOptions(): SelectOption[] {
            return [...usersStore.users]
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        opportunitySourceOptions(): SelectOption[] {
            return opportunitySourcesStore.opportunitySources.map((s) => ({
                text: s.name,
                value: s.id,
            }));
        },

        opportunityTeamReceiversOptions(): SelectOption[] {
            return getSortedByDealer(opportunityTeamsStore.opportunityTeams, (t) => t.dealerId).map((t) => ({
                text: `${t.name} (${this.getDealerNameById(t.dealerId) || this.$t("Unbekannter Standort")})`,
                value: t.id,
            }));
        },

        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },
    },

    methods: {
        addDealer(form: MutableRenaultRaceAccountForm) {
            form.dealerForms.push({
                ...cloneObject(EMPTY_MUTABLE_RENAULT_RACE_ACCOUNT_DEALER_FORM),
                fallback: form.dealerForms.length === 0,
            });
        },

        async addRenaultRaceAccount(form: MutableRenaultRaceAccountForm): Promise<boolean> {
            try {
                await renaultRaceAccountsApi.add(form as RenaultRaceAccountForm);

                return true;
            } catch (e) {
                return false;
            }
        },

        async deleteRenaultRaceAccount(renaultRaceAccountId: string): Promise<boolean> {
            if (
                !(await showConfirm(
                    this.$t("Renault-RACE-Account löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Account löschen möchten?") as string
                ))
            ) {
                return false;
            }

            try {
                await renaultRaceAccountsApi.delete(renaultRaceAccountId);

                return true;
            } catch (e) {
                return false;
            }
        },

        async editRenaultRaceAccount(
            renaultRaceAccountId: string,
            form: MutableRenaultRaceAccountForm
        ): Promise<boolean> {
            try {
                await renaultRaceAccountsApi.edit(renaultRaceAccountId, form as RenaultRaceAccountForm);

                return true;
            } catch (e) {
                return false;
            }
        },

        emptyForm(): MutableRenaultRaceAccountForm {
            return {
                name: null,
                dealerForms: [],
                httpBasicAuthUsername: null,
                httpBasicAuthPassword: null,
            };
        },

        formatInstant(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        getDealerNameById(dealerId: string): string | null {
            return dealersStore.dealerById(dealerId)?.name ?? null;
        },

        getEscalationGroupNameById(escalationGroupId: string): string | null {
            return escalationGroupsStore.escalationGroupById(escalationGroupId)?.name ?? null;
        },

        getFallbackRenaultRaceDealerNumber(form: MutableRenaultRaceAccountForm): string | null {
            return (
                form.dealerForms
                    .filter((dealer) => dealer.fallback)
                    .map((dealer) => dealer.renaultRaceDealerNumber)
                    .pop() ?? null
            );
        },

        getOpportunitySourceNameById(opportunitySourceId: string): string | null {
            return opportunitySourcesStore.getOpportunitySourceById(opportunitySourceId)?.name ?? null;
        },

        getOpportunityTeamNameById(opportunityTeamId: string): string | null {
            return opportunityTeamsStore.getOpportunityTeamById(opportunityTeamId)?.name ?? null;
        },

        getRenaultRaceAccounts(): Promise<RenaultRaceAccount[]> {
            return renaultRaceAccountsApi.getAll();
        },

        getRenaultRaceDealerNumbers(form: MutableRenaultRaceAccountForm): SelectOption[] {
            return form.dealerForms
                .map((dealerForm) => dealerForm.renaultRaceDealerNumber)
                .filter((renaultRaceDealerNumber): renaultRaceDealerNumber is string => !!renaultRaceDealerNumber)
                .map((renaultRaceDealerNumber) => ({
                    text: renaultRaceDealerNumber,
                    value: renaultRaceDealerNumber,
                }));
        },

        getUserFullNameById(userId: string): string | null {
            return getFullName(usersStore.getUserById(userId)) ?? null;
        },

        async pingRenaultRaceApi(renaultRaceAccountId: string): Promise<boolean> {
            try {
                await renaultRaceAccountsApi.pingRenaultRaceApi(renaultRaceAccountId);

                await showInfo(this.$t("Die Verbindung konnte erfolgreich hergestellt werden.") as string);

                return false;
            } catch (e) {
                if (e instanceof BadRequest) {
                    const errorType = e.details
                        .filter((d) => d.path === "error-type")
                        .map((d) => d.rejectedValue)
                        .pop();

                    if (typeof errorType === "string" && Object.keys(RenaultRaceApiErrorType).includes(errorType)) {
                        showError(this.$t(`enum.RenaultRaceApiErrorType.${errorType}`) as string);
                        return false;
                    }
                }

                showError(this.$t("Es ist ein Fehler aufgetreten.") as string);
                return false;
            }
        },

        async removeDealer(form: MutableRenaultRaceAccountForm, index: number) {
            if (
                JSON.stringify(EMPTY_MUTABLE_RENAULT_RACE_ACCOUNT_DEALER_FORM) !==
                    JSON.stringify(form.dealerForms[index]) &&
                !(await showConfirm(
                    this.$t("Händler löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Händler löschen möchten?") as string
                ))
            ) {
                return false;
            }

            form.dealerForms.splice(index, 1);
        },

        setFallbackRenaultRaceDealerNumber(
            form: MutableRenaultRaceAccountForm,
            renaultRaceDealerNumber: string | null | undefined
        ) {
            for (const dealerForm of form.dealerForms) {
                dealerForm.fallback = dealerForm.renaultRaceDealerNumber === renaultRaceDealerNumber;
            }
        },

        toForm(item: RenaultRaceAccount): MutableRenaultRaceAccountForm {
            return {
                name: item.name,
                dealerForms: cloneObject(item.dealers),
                httpBasicAuthUsername: item.httpBasicAuthUsername,
                httpBasicAuthPassword: item.httpBasicAuthPassword,
            };
        },
    },

    components: {
        CaseTypePicker,
        CrudPage,
        DAutocomplete,
        EnumField,
    },
});
