
import IncomingWhatsAppMedia from "./IncomingWhatsAppMedia.vue";
import { incomingWhatsAppApi, IncomingWhatsAppMessage, OptionalIssueId } from "@/api/incomingWhatsApp";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canViewContent: {
            type: Boolean,
            required: true,
        },
        incomingMessage: {
            type: Object as () => IncomingWhatsAppMessage,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            image: "",
        };
    },

    computed: {
        creationTimestamp(): string {
            return formatInstant(this.incomingMessage.created, userSession.timeZone, userSession.locale, "S");
        },

        issueId(): OptionalIssueId {
            if (this.incomingMessage.caseId) {
                return { caseId: this.incomingMessage.caseId };
            }
            if (this.incomingMessage.opportunityId) {
                return { opportunityId: this.incomingMessage.opportunityId };
            }
            return {};
        },
    },

    methods: {
        showMedia(download: boolean) {
            const mediaType = this.incomingMessage.mediaType!;
            if (!download && mediaType.startsWith("image/")) {
                this.image = incomingWhatsAppApi.generateMediaLink(this.incomingMessage, this.issueId);
            } else {
                window.open(incomingWhatsAppApi.generateMediaLink(this.incomingMessage, this.issueId, download));
            }
        },
    },

    components: {
        IncomingWhatsAppMedia,
        TimeSpan,
        ImageCarousel,
    },
});
