
import NoteTemplateFormDialog from "./NoteTemplateFormDialog.vue";
import { NoteTemplate, noteTemplatesApi } from "@/api/noteTemplates";
import DataTable from "@/app/components/DataTable.vue";
import { showConfirm } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import Sortable from "sortablejs";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
    data() {
        return {
            loadingTemplates: true,
            noteTemplates: [] as NoteTemplate[],
            formItem: null as NoteTemplate | null,
            formVisible: false,
            templatesSortableInitialized: false,
            fragmentsSortableInitialized: false,
        };
    },

    components: {
        DealerGlobalContextHint,
        NoteTemplateFormDialog,
        DataTable,
    },

    computed: {
        headers() {
            return [
                {
                    text: "",
                    sortable: false,
                    width: "1%",
                },
                {
                    text: this.$t("Name"),
                    sortable: false,
                    width: "18%",
                },
                {
                    text: this.$t("Sprache/Region"),
                    sortable: false,
                    width: "20%",
                },
                {
                    text: this.$t("Inhalt"),
                    sortable: false,
                    width: "60%",
                },
                {
                    text: this.$t("Aktionen"),
                    sortable: false,
                    width: "1%",
                },
            ] as DataTableHeader[];
        },
    },

    watch: {
        noteTemplates() {
            const templateitems = document.getElementById("templateitems")?.firstChild?.firstChild?.childNodes?.item(3);
            if (!this.templatesSortableInitialized && this.noteTemplates.length && templateitems) {
                Sortable.create(templateitems as HTMLElement, {
                    animation: 150,
                    handle: ".drag-handle",
                    ghostClass: "accent",
                    onEnd: async ({ oldIndex, newIndex }) => {
                        if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                            this.loadingTemplates = true;
                            try {
                                const item = this.noteTemplates.splice(oldIndex, 1)[0];
                                this.noteTemplates.splice(newIndex, 0, item);
                                await noteTemplatesApi.editOrder(this.noteTemplates.map((d) => d.id));
                            } finally {
                                this.loadingTemplates = false;
                            }
                        }
                    },
                });
                this.templatesSortableInitialized = true;
            }
        },
    },

    methods: {
        async deleteItem(noteTemplate: NoteTemplate) {
            if (
                await showConfirm(
                    this.$t("Bestätigung") as string,
                    this.$t("Sind Sie sicher, dass Sie die Notiz-Vorlage löschen möchten?") as string
                )
            ) {
                await noteTemplatesApi.delete(noteTemplate.id);
                await this.loadTemplates();
            }
        },

        async savedItem() {
            this.formVisible = false;
            await this.loadTemplates();
        },

        async loadTemplates() {
            this.loadingTemplates = true;
            try {
                this.noteTemplates = await noteTemplatesApi.list();
            } finally {
                this.loadingTemplates = false;
            }
        },

        showForm(formItem: NoteTemplate | null) {
            this.formItem = formItem;
            this.formVisible = true;
        },
    },

    async mounted() {
        await this.loadTemplates();
    },
});
