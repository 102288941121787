import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum InventoryImportSourceType {
    FTP = "FTP",
    FTP_STOCK2NET = "FTP_STOCK2NET",
    MOBILE_DE = "MOBILE_DE",
}

export interface InventoryImportSourceForm {
    readonly type: InventoryImportSourceType;
    readonly credentials1: string;
    readonly credentials2: string;
    readonly dealerCode: string | null;
}

export interface FtpAccountView {
    readonly id: string;
    readonly label: string;
}

export interface InventoryImportSource {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly dealerId: string;
    readonly type: InventoryImportSourceType;
    readonly credentials1: string;
    readonly credentials2: string;
    readonly dealerCode: string | null;
    readonly intervalMinutesAfterSuccess: number;
    readonly intervalMinutesAfterException: number;
    readonly nextTryBegin: Date | null;
    readonly currentBegin: Date | null;
    readonly lastSuccessfulEnd: Date | null;
    readonly lastSuccessfulChecksum: string | null;
    readonly lastError: string | null;
    readonly lastErrorTimestamp: Date | null;
    readonly lastErrorType: string | null;
    readonly lastSuccessfulVehicleCount: number | null;
}

interface InventoryImportApi {
    add(dealerId: string, inventoryImportSourceForm: InventoryImportSourceForm): Promise<void>;
    edit(id: string, inventoryImportSourceForm: InventoryImportSourceForm): Promise<void>;
    delete(id: string): Promise<void>;
    getByDealer(dealerId: string): Promise<InventoryImportSource[]>;
    getNonDeletedInventoryVehiclesWithoutSourceCount(): Promise<number>;
    listFtpAccounts(): Promise<FtpAccountView[]>;
    markInventoryVehiclesWithoutSourceAsDeleted(): Promise<void>;
    sync(id: string): Promise<void>;
}

export const inventoryImportApi: InventoryImportApi = {
    async add(dealerId, inventoryImportSourceForm) {
        return (await axios.post(`/api/inventory-import/${dealerId}`, inventoryImportSourceForm)).data;
    },

    async edit(id, inventoryImportSourceForm) {
        await axios.put(`/api/inventory-import/_/${id}`, inventoryImportSourceForm);
    },

    async delete(id) {
        await axios.delete(`/api/inventory-import/_/${id}`);
    },

    async getByDealer(dealerId) {
        return cloneObject((await axios.get(`/api/inventory-import/${dealerId}`)).data);
    },

    async getNonDeletedInventoryVehiclesWithoutSourceCount() {
        return cloneObject(
            (await axios.get("/api/inventory-import/non-deleted-inventory-vehicles-without-source-count")).data
        );
    },

    async listFtpAccounts() {
        return (await axios.get(`/api/inventory-import/_ftp-accounts`)).data;
    },

    async markInventoryVehiclesWithoutSourceAsDeleted() {
        await axios.post("/api/inventory-import/_mark-inventory-vehicles-without-source-as-deleted");
    },

    async sync(id) {
        try {
            await axios.post(`/api/inventory-import/_/${id}/_sync`, undefined, { timeout: 5000 });
        } catch (e) {
            // ignore canceled long-running request
            if (e.code !== "ECONNABORTED") {
                throw e;
            }
        }
    },
};
