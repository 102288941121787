
import {
    OpportunityIndividualEscalation,
    opportunityIndividualEscalationsApi,
} from "@/api/opportunityIndividualEscalations";
import { OpportunityTeam, opportunityTeamsApi } from "@/api/opportunityTeams";
import { Permission } from "@/api/userSession";
import { UserDirectoryEntry } from "@/api/users";
import NumberField from "@/app/components/NumberField.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { getFullName, getSortedByUserFullName } from "@/app/userUtils";
import { notEmpty, validate } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            loading: true,
            opportunityTeams: [] as OpportunityTeam[],
            individualEscalations: [] as OpportunityIndividualEscalation[],
            escalationTimeMinutesRules: notEmpty().integer(0),
            getFullName,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        opportunityTeamOptions(): SelectOption[] {
            return this.opportunityTeams.map((g) => ({
                value: g.id,
                text: `${g.name} (${dealersStore.dealerById(g.dealerId)!.name})`,
            }));
        },

        individualEscalationsSortedByUserFullName(): OpportunityIndividualEscalation[] {
            return getSortedByUserFullName(this.individualEscalations, (i) => i.userId);
        },
    },

    methods: {
        getUserById(userId: string): UserDirectoryEntry {
            return usersStore.getUserById(userId)!;
        },

        async loadItems() {
            this.loading = true;

            try {
                if (this.dealerId) {
                    this.opportunityTeams = await opportunityTeamsApi.getAll();
                    this.individualEscalations = await opportunityIndividualEscalationsApi.getByDealer(this.dealerId);
                } else {
                    this.opportunityTeams = [];
                    this.individualEscalations = [];
                }
            } finally {
                this.loading = false;
            }
        },

        async setEscalationTime(individualEscalation: OpportunityIndividualEscalation) {
            if (
                validate(
                    this.escalationTimeMinutesRules,
                    individualEscalation.individualEscalationData!.escalationTimeMinutes
                )
            ) {
                await opportunityIndividualEscalationsApi.editIndividualEscalation(individualEscalation.userId, {
                    opportunityTeamId: individualEscalation.individualEscalationData!.opportunityTeamId,
                    escalationTimeMinutes: individualEscalation.individualEscalationData!.escalationTimeMinutes,
                });
            }
        },

        async setOpportunityTeam(
            individualEscalation: OpportunityIndividualEscalation,
            opportunityTeamId: string | null
        ) {
            this.loading = true;
            try {
                if (opportunityTeamId) {
                    await opportunityIndividualEscalationsApi.editIndividualEscalation(individualEscalation.userId, {
                        opportunityTeamId,
                        escalationTimeMinutes:
                            individualEscalation.individualEscalationData?.escalationTimeMinutes ?? 60,
                    });
                } else {
                    await opportunityIndividualEscalationsApi.deleteByUser(individualEscalation.userId);
                }
                this.individualEscalations = await opportunityIndividualEscalationsApi.getByDealer(this.dealerId!);
            } finally {
                this.loading = false;
            }
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DealerContextGuard,
        NumberField,
    },
});
