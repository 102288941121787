
import GeneralInfoFormCard from "./GeneralInfoFormCard.vue";
import { Contact } from "@/api/contacts";
import { EmergencyIncident } from "@/api/emergencyIncidents";
import UserLink from "@/app/pages/UserLink.vue";
import { dealersStore } from "@/store/dealers";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canEdit: {
            type: Boolean,
            required: true,
        },
        contact: {
            type: Object as () => Contact,
            required: false,
        },
        emergencyIncidentProp: {
            type: Object as () => EmergencyIncident,
            required: true,
        },
    },

    data() {
        return {
            generalInfoFormVisible: false,
            parseAndFormatNumber,
        };
    },

    computed: {
        creator(): string {
            if (this.emergencyIncidentProp.creatorCtUserId) {
                return this.$t("Externer Agent") as string;
            }
            return this.$t("System") as string;
        },
    },

    methods: {
        showEditForm() {
            this.generalInfoFormVisible = true;
        },

        closeEditForm() {
            this.generalInfoFormVisible = false;
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },
    },

    components: {
        UserLink,
        GeneralInfoFormCard,
    },
});
