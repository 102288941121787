
import CreateDownloadLink from "./CreateDownloadLink.vue";
import OutgoingEmailTemplatePickerButton from "./OutgoingEmailTemplatePickerButton.vue";
import {
    OutgoingEmailFormCardAttachment,
    OutgoingEmailFormCardAttachmentFileStatus,
    OutgoingEmailFormCardAttachmentScope,
    OutgoingEmailFormCardState,
} from "./outgoingEmailFormCard";
import { renderEmailExposeItem } from "./outgoingEmailFormExpose";
import { ContactData } from "@/api/contacts";
import { EmailTemplate, emailTemplatesApi } from "@/api/emailTemplates";
import { BadRequest } from "@/api/errors";
import { inventoryApi, InventoryVehicle } from "@/api/inventory";
import { outgoingEmailsApi, TransactionEmailReceiver, TransactionEmailReceiverType } from "@/api/outgoingEmails";
import { usersApi } from "@/api/users";
import EmailAddressesField from "@/app/components/EmailAddressesField.vue";
import FileDropZone from "@/app/components/FileDropZone.vue";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { asQuote } from "@/app/emailUtils";
import { downloadOrOpenFile, fileDialog, getFile } from "@/app/fileUtils";
import { renderInventoryVehicleTitle, VehicleSummary } from "@/app/inventoryUtils";
import { showConfirm, showInfo } from "@/app/messageUtil";
import InventoryVehicleExposePickerButton from "@/app/pages/inventory/InventoryVehicleExposePickerButton.vue";
import InventoryVehiclePickerDialog from "@/app/pages/inventory/InventoryVehiclePickerDialog.vue";
import { UrlWithKey } from "@/app/placeholderUtils";
import { maxLength, notEmpty, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

interface EmailDraft {
    readonly attachments: OutgoingEmailFormCardAttachment[];
    readonly htmlBody: string;
}

let lastDraft = null as EmailDraft | null;

const MAX_EMAIL_SIZE = 9_500_000;
const MAX_FILE_SIZE = 50_000_000;

export default Vue.extend({
    props: {
        automaticQuote: {
            type: Boolean,
            default: false,
        },
        contactData: {
            type: Object as () => ContactData | null,
            required: false,
        },
        contentForQuote: {
            type: String,
            default: "",
        },
        dealerId: {
            type: String as () => string | null,
            default: null,
        },
        defaultSubject: {
            type: String,
            required: false,
        },
        defaultText: {
            type: String,
            required: false,
        },
        emailAttachmentsUploadProgress: {
            type: Number,
            required: true,
        },
        issueId: {
            type: String as () => string | null,
            default: null,
        },
        onSubmit: {
            type: Function,
            required: true,
        },
        outgoingEmailFormCardState: {
            type: Object as () => OutgoingEmailFormCardState,
            required: true,
        },
        receivers: {
            type: Array as () => TransactionEmailReceiver[],
            required: false,
            default: () => [],
        },
        urls: {
            type: Array as () => UrlWithKey[],
            default: () => [],
        },
        vehicleSummaries: {
            type: Array as () => VehicleSummary[],
            default: () => [],
        },
        working: {
            type: Boolean,
            required: true,
        },
        inventoryVehicles: {
            type: Array as () => InventoryVehicle[],
            default: () => [],
        },
    },

    data() {
        const validationHelper = new ValidationHelper();

        return {
            attachmentPreviewImageFile: null as File | null,
            contentRules: notEmpty()
                .maxLength(MAX_EMAIL_SIZE)
                .and(validationHelper, "htmlBody"),
            emailSignature: "",
            failedAddresses: [] as string[],
            failedAddressesMessage: "",
            fromAddressRules: notEmpty().and(validationHelper, "fromAddress"),
            inventoryVehiclePickerDialogVisible: false,
            loadingEmailSignature: true,
            OutgoingEmailFormCardAttachmentScope,
            OutgoingEmailFormCardAttachmentFileStatus,
            quote: asQuote(this.contentForQuote),
            subjectRules: maxLength(2000),
            internalWorking: false,
            internalEmailAttachmentsUploadProgress: null as number | null,
            TransactionEmailReceiverType,
            validationHelper,
            hasLastDraft: !!lastDraft,
            initialHtmlBody: "",
        };
    },

    computed: {
        activeAttachments(): OutgoingEmailFormCardAttachment[] {
            return this.outgoingEmailFormCardState.attachments.filter((a) => a.active);
        },

        attachmentsCardStyle(): object {
            return {
                borderColor: this.hasTooLargeAttachments ? this.$vuetify.theme.currentTheme.error : undefined,
                borderStyle: this.activeAttachments.length ? undefined : "dashed",
            };
        },

        attachmentsRules(): Function[] {
            const files = this.activeAttachments.filter((a) => !!a.file).map((a) => a.file);
            const filenames = JSON.stringify(files);
            return [
                () => this.validationHelper.validate("files", filenames),
                () => this.validationHelper.validate("filename", filenames),
            ];
        },

        bccReceiverAddresses(): string[] {
            return this.outgoingEmailFormCardState.bccReceivers.map((r) => r.address);
        },

        bccReceiversRules(): Function[] {
            return [
                () =>
                    !this.bccReceiverAddresses.filter((a) => this.failedAddresses.includes(a)).length ||
                    this.failedAddressesMessage,
            ];
        },

        ccReceiverAddresses(): string[] {
            return this.outgoingEmailFormCardState.ccReceivers.map((r) => r.address);
        },

        ccReceiversRules(): Function[] {
            return [
                () =>
                    !this.ccReceiverAddresses.filter((a) => this.failedAddresses.includes(a)).length ||
                    this.failedAddressesMessage,
            ];
        },

        emailAliasses(): SelectOptions {
            if (!userSession.profile) {
                return [];
            }

            return userSession.profile.emailAliases.map((a) => ({
                value: `${a.localPart}@${a.emailDomainId}`,
                text: `${this.userFullName} (${a.localPart}@${a.emailDomainId})`,
            }));
        },

        hasReceiverWithPseudonymizedContactEmailAddress(): boolean {
            if (!this.toReceiverAddresses.length) {
                return false;
            }

            return configStore.configuration.incomingEmailPseudonymizedContactEmailAddressPatterns.some((pattern) =>
                this.toReceiverAddresses.some((receiver) => receiver.match(new RegExp(pattern, "i")))
            );
        },

        hasUnavailableActiveAttachments(): boolean {
            return this.activeAttachments.some(
                (a) => a.fileStatus !== OutgoingEmailFormCardAttachmentFileStatus.AVAILABLE
            );
        },

        hasTooLargeAttachments(): boolean {
            return this.activeAttachments
                .filter((a) => a.fileStatus === OutgoingEmailFormCardAttachmentFileStatus.AVAILABLE)
                .map((a) => a.file as File)
                .some((f) => (f.size * 8.0) / 6 > MAX_EMAIL_SIZE);
        },

        selectedInventoryVehicleIds(): string[] {
            return this.activeAttachments
                .filter((a) => a.scope === OutgoingEmailFormCardAttachmentScope.INVENTORY_VEHICLE_EXPOSE)
                .map((a) => a.id);
        },

        toReceiverAddresses(): string[] {
            return this.outgoingEmailFormCardState.toReceivers.map((r) => r.address);
        },

        toReceiverHint(): string | null {
            if (!this.hasReceiverWithPseudonymizedContactEmailAddress) {
                return null;
            }

            return this.$t("Das Feld enthält eine pseudonymisierte E-Mail-Adresse.") as string;
        },

        toReceiversRules(): Function[] {
            return [
                () => !!this.toReceiverAddresses.length || this.$t("Dieses Feld ist erforderlich."),
                () =>
                    !this.toReceiverAddresses.filter((a) => this.failedAddresses.includes(a)).length ||
                    this.failedAddressesMessage,
            ];
        },

        userFullName(): string {
            return userSession.fullName!;
        },

        downloadPossible(): boolean {
            return (
                !this.hasUnavailableActiveAttachments &&
                !!this.outgoingEmailFormCardState.htmlBody &&
                !!this.outgoingEmailFormCardState.htmlBody.trim() &&
                !!this.toReceiverAddresses.length
            );
        },

        computedWorking(): boolean {
            return this.working || this.internalWorking;
        },

        computedEmailAttachmentsUploadProgress(): number {
            return this.internalEmailAttachmentsUploadProgress ?? this.emailAttachmentsUploadProgress;
        },

        acceptedFileTypes(): string[] {
            return configStore.configuration.whitelistedOutgoingFileExtensions;
        },
    },

    methods: {
        async addExposeAttachment(inventoryVehicle: InventoryVehicle) {
            const existingAttachment = this.outgoingEmailFormCardState.attachments.find(
                (a) =>
                    a.id === inventoryVehicle.id &&
                    a.scope === OutgoingEmailFormCardAttachmentScope.INVENTORY_VEHICLE_EXPOSE
            );

            if (existingAttachment) {
                existingAttachment.active = true;
                return;
            }

            const title = renderInventoryVehicleTitle(inventoryVehicle) || "";
            const filename = `expose-${title}-${inventoryVehicle.id.substr(0, 5)}.pdf`
                .trim()
                .toLowerCase()
                .replace(/[^a-z0-9_\-.]/g, "_")
                .replace(/_*-+_*/g, "-");

            const attachment: OutgoingEmailFormCardAttachment = {
                active: true,
                file: null,
                fileStatus: OutgoingEmailFormCardAttachmentFileStatus.DOWNLOADING,
                id: inventoryVehicle.id,
                name: inventoryVehicle.data.internalId ? `expose-${inventoryVehicle.data.internalId}.pdf` : filename,
                scope: OutgoingEmailFormCardAttachmentScope.INVENTORY_VEHICLE_EXPOSE,
                inventoryVehicle,
            };

            this.outgoingEmailFormCardState.attachments.push(attachment);
            await this.loadExposeAttachment(attachment);
        },

        addTemplateAttachments(emailTemplate: EmailTemplate) {
            this.outgoingEmailFormCardState.attachments = this.outgoingEmailFormCardState.attachments.filter(
                (a) => a.scope !== OutgoingEmailFormCardAttachmentScope.EMAIL_TEMPLATE_ATTACHMENT
            );

            for (const templateAttachment of emailTemplate.attachments) {
                const emailAttachment: OutgoingEmailFormCardAttachment = {
                    active: true,
                    file: null,
                    fileStatus: OutgoingEmailFormCardAttachmentFileStatus.DOWNLOADING,
                    id: `${emailTemplate.id}-${templateAttachment.id}`,
                    name: templateAttachment.filename,
                    scope: OutgoingEmailFormCardAttachmentScope.EMAIL_TEMPLATE_ATTACHMENT,
                    inventoryVehicle: null,
                };
                this.outgoingEmailFormCardState.attachments.push(emailAttachment);

                getFile(emailTemplatesApi.generateAttachmentLink(templateAttachment), templateAttachment.filename).then(
                    (file) => {
                        if (file) {
                            emailAttachment.file = file;
                            emailAttachment.fileStatus = OutgoingEmailFormCardAttachmentFileStatus.AVAILABLE;
                        } else {
                            emailAttachment.fileStatus = OutgoingEmailFormCardAttachmentFileStatus.DOWNLOAD_FAILED;
                        }
                    }
                );
            }
        },

        showFileDialog() {
            fileDialog(true, this.addFiles, this.acceptedFileTypes);
        },

        addFiles(files: File[]) {
            for (const file of files) {
                const id = (this as any).$id();
                this.outgoingEmailFormCardState.attachments.push({
                    active: true,
                    file,
                    fileStatus: OutgoingEmailFormCardAttachmentFileStatus.AVAILABLE,
                    name: null,
                    id: `${OutgoingEmailFormCardAttachmentScope.ADDED_BY_USER}-${id}`,
                    scope: OutgoingEmailFormCardAttachmentScope.ADDED_BY_USER,
                    inventoryVehicle: null,
                });
            }
        },

        canCreateDownloadLink(attachment: OutgoingEmailFormCardAttachment) {
            return this.isTooLarge(attachment) && attachment.file!.size <= MAX_FILE_SIZE;
        },

        deleteAttachment(attachmentId: string) {
            const index = this.outgoingEmailFormCardState.attachments.findIndex((a) => a.id === attachmentId);

            if (index < 0) {
                return;
            }

            this.outgoingEmailFormCardState.attachments.splice(index, 1);
        },

        insertDownloadLinkIntoEditorContent(attachmentId: string, content: string) {
            (this.$refs.editor as any).insertContent(content, true);
            this.deleteAttachment(attachmentId);
        },

        insertEmailFragmentIntoEditorContent({
            renderedContent,
        }: {
            readonly template: EmailTemplate;
            readonly renderedContent: string;
        }) {
            (this.$refs.editor as any).insertContent(renderedContent, true);
        },

        async insertInventoryVehicleIntoEditorContent(inventoryVehicle: InventoryVehicle) {
            const emailExposeItem = await renderEmailExposeItem(
                inventoryVehicle,
                configStore.configuration.defaultLocale
            );

            if (!this.$refs.editor) {
                return;
            }

            (this.$refs.editor as any).insertContent(emailExposeItem, false, inventoryVehicle.id);

            await this.addExposeAttachment(inventoryVehicle);
        },

        isTooLarge(attachment: OutgoingEmailFormCardAttachment) {
            return (
                attachment.active &&
                attachment.fileStatus === OutgoingEmailFormCardAttachmentFileStatus.AVAILABLE &&
                (attachment.file!.size * 8.0) / 6 > MAX_EMAIL_SIZE
            );
        },

        async loadExposeAttachment(attachment: OutgoingEmailFormCardAttachment) {
            attachment.fileStatus = OutgoingEmailFormCardAttachmentFileStatus.DOWNLOADING;

            const exposeLink = inventoryApi.generateVehicleExposeLink(attachment.id);
            const file = await getFile(exposeLink, attachment.name!);

            if (file) {
                attachment.file = file;
                attachment.fileStatus = OutgoingEmailFormCardAttachmentFileStatus.AVAILABLE;
            } else {
                attachment.fileStatus = OutgoingEmailFormCardAttachmentFileStatus.DOWNLOAD_FAILED;
            }
        },

        async selectInventoryVehicle(inventoryVehicle: InventoryVehicle) {
            await this.insertInventoryVehicleIntoEditorContent(inventoryVehicle);
        },

        setEditorContentToEmailTemplate({
            template,
            renderedContent,
        }: {
            readonly template: EmailTemplate;
            readonly renderedContent: string;
        }) {
            this.outgoingEmailFormCardState.htmlBody = renderedContent;
            this.addTemplateAttachments(template);
        },

        setReceiversForType(validAddresses: string[], invalidAddresses: string[], type: TransactionEmailReceiverType) {
            if (invalidAddresses.length) {
                showInfo(this.$t("{0} ist keine gültige E-Mail Adresse.", [invalidAddresses[0]]) as string);
                this.outgoingEmailFormCardState.refreshReceivers();
                return;
            }

            const receivers: TransactionEmailReceiver[] = validAddresses.map((address) => ({
                name: null,
                address,
                type,
            }));

            if (type === TransactionEmailReceiverType.BCC) {
                this.outgoingEmailFormCardState.bccReceivers = receivers;
            } else if (type === TransactionEmailReceiverType.CC) {
                this.outgoingEmailFormCardState.ccReceivers = receivers;
            } else if (type === TransactionEmailReceiverType.TO) {
                this.outgoingEmailFormCardState.toReceivers = receivers;
            }
        },

        showAttachment(attachment: OutgoingEmailFormCardAttachment, download: boolean) {
            if (!attachment.file) {
                return;
            }

            const fname = attachment.file.name.toLowerCase();

            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.attachmentPreviewImageFile = attachment.file;
            } else {
                downloadOrOpenFile(attachment.file, download);
            }
        },

        showInventoryVehiclePickerDialog() {
            this.inventoryVehiclePickerDialogVisible = true;
        },

        async submit() {
            if (!(this.$refs.emailform as any).validate() || this.hasTooLargeAttachments) {
                return;
            }

            if (
                this.hasUnavailableActiveAttachments &&
                !(await showConfirm(
                    this.$t("Exposé fehlt im Anhang") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie die E-Mail trotz fehlendem Anhang verschicken möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            if (
                this.hasReceiverWithPseudonymizedContactEmailAddress &&
                !(await showConfirm(
                    this.$t("Versand über ein Fremdsystem") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie die E-Mail über ein Fremdsystem (z.B. eine Fahrzeugbörse) verschicken möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            const availableAttachments = this.activeAttachments
                .filter((a) => a.fileStatus === OutgoingEmailFormCardAttachmentFileStatus.AVAILABLE)
                .map((a) => a.file as File);

            const totalEmailSize = availableAttachments
                .map((f) => (f.size * 8.0) / 6)
                .reduce((p, c) => p + c, this.outgoingEmailFormCardState.outgoingEmailForm.htmlBody.length);

            if (
                totalEmailSize > MAX_EMAIL_SIZE &&
                availableAttachments.length &&
                !(await showConfirm(
                    this.$t("E-Mail zu groß") as string,
                    this.$t(
                        "Die Gesamtgröße der E-Mail inkl. der Anhänge ist zu groß. Soll die E-Mail in mehreren Teilen verschickt werden?"
                    ) as string
                ))
            ) {
                return;
            }

            const exposeVehicles: InventoryVehicle[] = this.activeAttachments
                .filter((a) => a.scope === OutgoingEmailFormCardAttachmentScope.INVENTORY_VEHICLE_EXPOSE)
                .map((a) => a.inventoryVehicle!);

            this.failedAddresses = [];
            try {
                await this.onSubmit(
                    this.outgoingEmailFormCardState.outgoingEmailForm,
                    availableAttachments,
                    exposeVehicles
                );
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    this.$nextTick(() => {
                        throw e;
                    });
                } else {
                    if (e.details.length && e.details[0].path === "addresses") {
                        this.failedAddresses = e.details[0].rejectedValue as string[];
                        this.failedAddressesMessage = this.$t(e.details[0].messageKey) as string;
                        (this.$refs.emailform as any).validate();
                    } else {
                        this.validationHelper.update(e, this.$refs.emailform);
                    }
                }
            }
        },

        async downloadEmail() {
            this.internalWorking = true;
            this.internalEmailAttachmentsUploadProgress = 0;
            try {
                const file = await outgoingEmailsApi.prepareEmail(
                    {
                        ...this.outgoingEmailFormCardState.outgoingEmailForm,
                        fromAddress:
                            this.outgoingEmailFormCardState.fromAddress ||
                            "draft@" + configStore.configuration.emailDomain,
                    },
                    this.activeAttachments.filter((a) => !!a.file).map((a) => a.file as File),
                    ({ total, loaded }) => (this.internalEmailAttachmentsUploadProgress = (100 * loaded) / total)
                );
                downloadOrOpenFile(file, false);
            } finally {
                this.internalWorking = false;
                this.internalEmailAttachmentsUploadProgress = null;
            }
        },

        restoreLastDraft() {
            if (lastDraft) {
                this.outgoingEmailFormCardState.attachments = [...lastDraft.attachments];
                this.outgoingEmailFormCardState.htmlBody = lastDraft.htmlBody;
            }
        },
    },

    watch: {
        "outgoingEmailFormCardState.initialized"() {
            if (!this.outgoingEmailFormCardState.initialized) {
                this.outgoingEmailFormCardState.initialize(this.receivers, this.defaultSubject, this.emailSignature);
            }
        },

        receivers() {
            const hasChanged =
                this.receivers.length !== this.outgoingEmailFormCardState.receivers.length ||
                this.receivers.some(
                    (r) =>
                        !this.outgoingEmailFormCardState.receivers.some(
                            (s) => r.type === s.type && r.address === s.address
                        )
                );

            if (!hasChanged) {
                return;
            }

            this.outgoingEmailFormCardState.toReceivers = this.receivers.filter(
                (r) => r.type === TransactionEmailReceiverType.TO
            );
            this.outgoingEmailFormCardState.ccReceivers = this.receivers.filter(
                (r) => r.type === TransactionEmailReceiverType.CC
            );
            this.outgoingEmailFormCardState.bccReceivers = this.receivers.filter(
                (r) => r.type === TransactionEmailReceiverType.BCC
            );
        },
    },

    async mounted() {
        // if user has no aliases, show info
        if (!userSession.profile || userSession.profile.emailAliases.length === 0) {
            showInfo(
                this.$t(
                    "Sie haben keine E-Mail-Aliasse in Ihrem Profil hinterlegt. Dies ist eine Voraussetzung für den E-Mail-Versand."
                ) as string
            );
            (this.$refs.emailform as any).validate();
        }

        this.loadingEmailSignature = true;
        try {
            if (userSession.profile!.emailSignatureHash) {
                this.emailSignature = await usersApi.getEmailSignatureByHash(
                    userSession.profile!.id,
                    userSession.profile!.emailSignatureHash
                );
            }
        } finally {
            this.loadingEmailSignature = false;
        }

        if (!this.outgoingEmailFormCardState.initialized) {
            this.outgoingEmailFormCardState.initialize(this.receivers, this.defaultSubject, this.emailSignature);
        }

        if (this.defaultText) {
            this.outgoingEmailFormCardState.htmlBody = this.defaultText;
        } else if (this.automaticQuote) {
            this.outgoingEmailFormCardState.htmlBody += this.quote;
        }

        this.initialHtmlBody = this.outgoingEmailFormCardState.htmlBody;
    },

    beforeDestroy() {
        if (
            this.outgoingEmailFormCardState.attachments.length ||
            (this.outgoingEmailFormCardState.htmlBody &&
                this.outgoingEmailFormCardState.htmlBody !== this.initialHtmlBody)
        ) {
            lastDraft = {
                attachments: [...this.outgoingEmailFormCardState.attachments],
                htmlBody: this.outgoingEmailFormCardState.htmlBody,
            };
        }
    },

    components: {
        CreateDownloadLink,
        EmailAddressesField,
        FileDropZone,
        ImageCarousel,
        InventoryVehicleExposePickerButton,
        InventoryVehiclePickerDialog: InventoryVehiclePickerDialog,
        OutgoingEmailTemplatePickerButton,
        WysiwygEditor: () => import("@/app/components/WysiwygEditor.vue"),
    },
});
