
import WhatsAppOnboardingDialog from "./WhatsAppOnboardingDialog.vue";
import { BadRequest } from "@/api/errors";
import { OfficeHours } from "@/api/officeHours";
import { Permission } from "@/api/userSession";
import {
    TypeHint,
    WhatsAppAccount,
    whatsAppAccountApi,
    WhatsAppAccountCreationForm,
    WhatsAppAccountUpdateForm,
    WhatsAppApiType,
} from "@/api/whatsAppAccounts";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import EnumField from "@/app/components/EnumField.vue";
import { getSortedByDealer } from "@/app/dealerUtils";
import { showConfirm, showError } from "@/app/messageUtil";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import CrudPage from "@/app/pages/CrudPage.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { getFullName } from "@/app/userUtils";
import { maxLength, notEmpty, ValidationHelper } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { officeHoursStore } from "@/store/officeHours";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            Permission,
            TypeHint,
            WhatsAppApiType,
            notEmpty,
            validationHelper,
            apiKeyRules: notEmpty()
                .maxLength(63)
                .and(validationHelper, "apiKey"),
            labelRules: maxLength(300),
            officeHours: [] as OfficeHours[],
            showOnboardingDialog: false,
            channelId: null as string | null,
            apiKey: "",
        };
    },

    computed: {
        api() {
            return whatsAppAccountApi;
        },

        escalationGroupReceiversOptions(): SelectOption[] {
            return getSortedByDealer(escalationGroupsStore.escalationGroups, (e) => e.dealerId).map((e) => ({
                text: this.appendDealerNameToOptionText(e.name, e.dealerId),
                value: e.id,
            }));
        },

        individualReceiversOptions(): SelectOption[] {
            return [...usersStore.users]
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        isCtUser(): boolean {
            return userSession.isCtUser();
        },

        officeHoursOptions(): SelectOption[] {
            return this.officeHours.map((officeHours) => ({
                value: officeHours.id,
                text: officeHours.name,
            }));
        },

        opportunitySourceOptions(): SelectOption[] {
            return opportunitySourcesStore.opportunitySources.map((s) => ({
                text: s.name,
                value: s.id,
            }));
        },

        opportunityTeamReceiversOptions(): SelectOption[] {
            return getSortedByDealer(opportunityTeamsStore.opportunityTeams, (t) => t.dealerId).map((t) => ({
                text: this.appendDealerNameToOptionText(t.name, t.dealerId),
                value: t.id,
            }));
        },

        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },
    },

    methods: {
        getTypeHintDescription(typeHint: TypeHint) {
            if (typeHint === TypeHint.CASE) {
                return this.$t("Bei eingehenden Chats, wird ein Fall erstellt.");
            }
            if (typeHint === TypeHint.OPPORTUNITY) {
                return this.$t("Bei eingehenden Chats, wird eine Verkaufschance erstellt.");
            }
            if (typeHint === TypeHint.AUTO_DETECT) {
                return this.$t(
                    "Falls in der ersten Nachricht ein Fahrzeug erkannt wird, wird eine Verkaufschance erstellt, andernfalls wird ein Fall erstellt."
                );
            }

            return this.$t("Legt fest welche Art von Vorgang bei eingehenden Chats erstellt wird.");
        },

        async getAccounts(dealerId: string) {
            this.officeHours = officeHoursStore.getOfficeHoursByDealerId(dealerId);
            return whatsAppAccountApi.getAccounts(dealerId);
        },

        async addAccount(dealerId: string, form: WhatsAppAccountCreationForm, formRef: unknown) {
            try {
                await whatsAppAccountApi.addAccount(dealerId, form);
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, formRef);
                return false;
            }
        },

        async deleteAccount(item: WhatsAppAccount) {
            if (
                await showConfirm(
                    this.$t("WhatsApp-Konto löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie das WhatsApp-Konto löschen möchten?") as string
                )
            ) {
                await whatsAppAccountApi.deleteAccount(item.id);
                return true;
            }
            return false;
        },

        toForm(item: WhatsAppAccount): WhatsAppAccountUpdateForm {
            return cloneObject(item);
        },

        emptyForm(): WhatsAppAccountCreationForm {
            return {
                name: "",
                channelId: this.channelId,
                apiType: WhatsAppApiType.CLOUD_API,
                apiKey: this.apiKey,
                label: "",
                type: (null as unknown) as TypeHint,
                overrideDealerIdByVehicleLocation: false,
                individualReceivers: [],
                officeHoursId: null,
                insideOfficeHoursMessage: "",
                outsideOfficeHoursMessage: "",
                caseType: null,
                escalationGroupReceivers: [],
                sourceId: null,
                opportunityTeamReceivers: [],
            };
        },

        appendDealerNameToOptionText(text: string, dealerId: string) {
            return `${text} (${this.getDealerNameById(dealerId)})`;
        },

        getDealerNameById(dealerId: string) {
            return (dealersStore.dealerById(dealerId)?.name || this.$t("Unbekannt")).toString();
        },

        getEscalationGroupNameById(escalationGroupId: string) {
            return (
                escalationGroupsStore.escalationGroupById(escalationGroupId)?.name || this.$t("Unbekannt")
            ).toString();
        },

        getOpportunitySourceNameById(opportunitySourceId: string) {
            return (
                opportunitySourcesStore.getOpportunitySourceById(opportunitySourceId)?.name || this.$t("Unbekannt")
            ).toString();
        },

        getOpportunityTeamNameById(opportunityTeamId: string) {
            return (
                opportunityTeamsStore.getOpportunityTeamById(opportunityTeamId)?.name || this.$t("Unbekannt")
            ).toString();
        },

        getUserFullNameById(userId: string) {
            return (getFullName(usersStore.getUserById(userId)) || this.$t("Unbekannt")).toString();
        },

        getOfficeHoursNameById(itemId: string) {
            return this.officeHours.find((i) => i.id === itemId)?.name;
        },

        onOnboardingSuccess(data: { channelId: string; apiKey: string }) {
            this.showOnboardingDialog = false;
            this.channelId = data.channelId;
            this.apiKey = data.apiKey;
            try {
                (this.$refs.crudPage as any).showCreateDialog();
            } finally {
                this.channelId = null;
                this.apiKey = "";
            }
        },

        onOnboardingError(messageKey: string) {
            this.showOnboardingDialog = false;
            showError(this.$t(messageKey) as string);
        },
    },

    components: {
        WhatsAppOnboardingDialog,
        CrudPage,
        DAutocomplete,
        DealerContextGuard,
        CaseTypePicker,
        EnumField,
    },
});
