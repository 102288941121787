
import { autoCrmLeadMailerSettingsApi, AutoCrmLeadMailerUserSettings } from "@/api/autoCrmLeadMailerSettings";
import { Permission } from "@/api/userSession";
import CrudTablePage from "@/app/pages/CrudTablePage.vue";
import { TableHeader } from "@/app/pages/crudPage";
import { getFullName, getSortedByUserFullName } from "@/app/userUtils";
import { email, notEmpty } from "@/app/validation";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            email,
            notEmpty,
            Permission,
        };
    },

    computed: {
        headers(): TableHeader[] {
            return [
                {
                    id: "user",
                    text: this.$t("Benutzer"),
                    width: "20%",
                },
                {
                    id: "email",
                    text: this.$t("E-Mail"),
                    width: "20%",
                },
                {
                    id: "categories",
                    text: this.$t("Kategorien"),
                    width: "60%",
                },
            ];
        },
    },

    methods: {
        addCategory(form: AutoCrmLeadMailerUserSettings): void {
            form.categories.push("");
        },

        async editUserSettings(item: AutoCrmLeadMailerUserSettings): Promise<boolean> {
            try {
                await autoCrmLeadMailerSettingsApi.editUserSettings(item);
                return true;
            } catch (e) {
                return false;
            }
        },

        emptyUserSettingsForm(): AutoCrmLeadMailerUserSettings {
            return {
                userId: "",
                emailAddress: null,
                categories: [],
            };
        },

        getUserFullNameById(userId: string) {
            return getFullName(usersStore.getUserById(userId)) || this.$t("Unbekannt");
        },

        async getUserSettings(): Promise<AutoCrmLeadMailerUserSettings[]> {
            return getSortedByUserFullName(await autoCrmLeadMailerSettingsApi.getUserSettings(), (u) => u.userId);
        },

        removeCategory(form: AutoCrmLeadMailerUserSettings, index: number) {
            form.categories.splice(index, 1);
        },

        toUserSettingsForm(userSettings: AutoCrmLeadMailerUserSettings): AutoCrmLeadMailerUserSettings {
            return cloneObject(userSettings);
        },
    },

    components: {
        CrudTablePage,
    },
});
