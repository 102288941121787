import { FinanceContractCustomerType, FinanceContractType, FinanceContractVehicleUsageType } from "./financeContracts";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum FinanceContractParserCharset {
    UTF_8 = "UTF_8",
    ISO_8859_1 = "ISO_8859_1",
}

export enum FinanceContractParserFormat {
    GENERIC = "GENERIC",
    AKF = "AKF",
    ALD_AUTOMOTIVE = "ALD_AUTOMOTIVE",
    BANK11 = "BANK11",
    BMW_FINANCING = "BMW_FINANCING",
    BMW_LEASING = "BMW_LEASING",
    CATCH = "CATCH",
    CREDIT_PLUS = "CREDIT_PLUS",
    FCA = "FCA",
    HIS = "HIS",
    HYUNDAI = "HYUNDAI",
    MERCEDES = "MERCEDES",
    OPEL = "OPEL",
    PEUGEOT_FINANCING = "PEUGEOT_FINANCING",
    PEUGEOT_LEASING = "PEUGEOT_LEASING",
    PSA_BANK = "PSA_BANK",
    RCI = "RCI",
    SANTANDER_FINANCING = "SANTANDER_FINANCING",
    SANTANDER_LEASING = "SANTANDER_LEASING",
}

export enum FinanceContractParserSeparator {
    COMMA = "COMMA",
    PIPE = "PIPE",
    SEMICOLON = "SEMICOLON",
    TAB = "TAB",
}

export interface ExternalFinanceContractParsed {
    readonly financeContractId: string;
    readonly dealer: string | null;
    readonly type: FinanceContractType | null;
    readonly salesperson: string | null;
    readonly customerType: FinanceContractCustomerType | null;
    readonly name: string | null;
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
    readonly number: string | null;
    readonly emailAddress: string | null;
    readonly firstInstallment: number | null;
    readonly monthlyInstallment: number | null;
    readonly finalInstallment: number | null;
    readonly finalInstallmentPaymentDate: Date | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly vin: string | null;
    readonly licensePlate: string | null;
    readonly vehicleUsageType: FinanceContractVehicleUsageType | null;
    readonly notes: string;
}

export interface FinanceContractUpload {
    readonly id: string;
    readonly created: Date;
    readonly financeContractUploadSourceId: string;
    readonly filename: string;
    readonly dataHash: string;
    readonly size: number;
    readonly applied: Date | null;
    readonly total: number | null;
    readonly inserts: number | null;
    readonly updates: number | null;
}

export interface FinanceContractUploadSource {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly name: string;
    readonly processInAdvanceMonth: number;
    readonly dealerId: string;
    readonly opportunitySourceId: string;
    readonly individualReceivers: string[];
    readonly opportunityTeamReceivers: string[];
    readonly defaultCountry: string;
    readonly format: FinanceContractParserFormat;
    readonly charset: FinanceContractParserCharset | null;
    readonly separator: FinanceContractParserSeparator | null;
    readonly latestFinanceContractUpload: FinanceContractUpload | null;
}

export interface FinanceContractUploadSourceForm {
    readonly name: string;
    readonly processInAdvanceMonth: number;
    readonly dealerId: string;
    readonly opportunitySourceId: string;
    readonly individualReceivers: string[];
    readonly opportunityTeamReceivers: string[];
    readonly defaultCountry: string;
    readonly format: FinanceContractParserFormat;
    readonly charset: FinanceContractParserCharset | null;
    readonly separator: FinanceContractParserSeparator | null;
}

export interface FinanceContractUploadParseResult {
    readonly total: number;
    readonly inserts: number;
    readonly updates: number;
    readonly financeContracts: ExternalFinanceContractParsed[];
}

interface FinanceContractUploadsApi {
    addFinanceContractUploadSource(financeContractUploadSourceForm: FinanceContractUploadSourceForm): Promise<void>;
    applyFinanceContracts(financeContractUploadId: string): Promise<void>;
    deleteFinanceContractUploadSource(id: string): Promise<void>;
    editFinanceContractUploadSource(
        id: string,
        financeContractUploadSourceForm: FinanceContractUploadSourceForm
    ): Promise<void>;
    generateFinanceContractUploadDownloadLink(financeContractUploadId: string): string;
    getAllFinanceContractUploadSources(): Promise<FinanceContractUploadSource[]>;
    parseFinanceContracts(financeContractUploadId: string, limit: number): Promise<FinanceContractUploadParseResult>;
    uploadFinanceContracts(
        id: string,
        file: File,
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
}

export const financeContractUploadsApi: FinanceContractUploadsApi = {
    async addFinanceContractUploadSource(financeContractUploadSourceForm) {
        await axios.post("/api/finance-contract-uploads", financeContractUploadSourceForm);
    },

    async applyFinanceContracts(financeContractUploadId) {
        await axios.post("/api/finance-contract-uploads/_apply-finance-contracts", undefined, {
            params: {
                financeContractUploadId,
            },
        });
    },

    async deleteFinanceContractUploadSource(id) {
        await axios.delete(`/api/finance-contract-uploads/${id}`);
    },

    async editFinanceContractUploadSource(id, financeContractUploadSourceForm) {
        await axios.put(`/api/finance-contract-uploads/${id}`, financeContractUploadSourceForm);
    },

    generateFinanceContractUploadDownloadLink(financeContractUploadId: string) {
        return `/api/finance-contract-uploads/_download?financeContractUploadId=${financeContractUploadId}`;
    },

    async getAllFinanceContractUploadSources() {
        return cloneObject((await axios.get("/api/finance-contract-uploads")).data);
    },

    async parseFinanceContracts(financeContractUploadId, limit) {
        return cloneObject(
            (
                await axios.post("/api/finance-contract-uploads/_parse-finance-contracts", undefined, {
                    params: {
                        financeContractUploadId,
                        limit,
                    },
                })
            ).data
        );
    },

    async uploadFinanceContracts(id, file, onUploadProgress) {
        const formData = new FormData();
        formData.append("file", file);
        await axios.post(`/api/finance-contract-uploads/${id}/_upload-finance-contracts`, formData, {
            onUploadProgress,
        });
    },
};
