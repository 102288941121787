
import { formatLocalTime, is24hrFormat, parseTime } from "@/util/dateTimeUtils";
import Vue from "vue";

function formatTime(time: string | null, locale: string) {
    return time ? formatLocalTime(time, locale, "S") : "";
}

export default Vue.extend({
    props: {
        value: String,
        rules: {
            type: Array as () => Function[] | undefined,
            required: false,
        },
    },

    data() {
        return {
            internalValue: this.value as string | null,
            formattedValue: formatTime(this.value, this.$i18n.locale),
            menu: false,
        };
    },

    computed: {
        timeFormat() {
            return is24hrFormat(this.$i18n.locale) ? "24hr" : "ampm";
        },

        rulez(): Function[] {
            const rs = this.rules || [];
            return rs.map((r) => () => r(this.internalValue));
        },
    },

    watch: {
        value(v: string) {
            if (this.internalValue !== v) {
                this.internalValue = v;
                this.formattedValue = formatTime(v, this.$i18n.locale);
            }
        },
    },

    methods: {
        handleTimePickerInput(v: string) {
            this.$emit("input", v);
        },

        handleFieldInput(v: string) {
            this.internalValue = parseTime(v);
            this.$emit("input", this.internalValue);
        },

        handleFieldChange(v: string) {
            this.internalValue = parseTime(v);
            this.formattedValue = (this.$refs.field as any).lazyValue = formatTime(
                this.internalValue,
                this.$i18n.locale
            );
            this.$emit("change", this.internalValue);
        },

        handleFieldFocus() {
            (this.$refs.field as any).$refs.input.select();
        },
    },
});
