import { CaseResult } from "./cases";
import { Notification } from "./notifications";
import { OpportunityResult } from "./opportunities";
import { cloneObject } from "@/util/cloneUtils";
import { ReconnectingEventSource } from "@/util/eventSource";
import axios from "axios";

export interface StatusDashboardConfiguration {
    readonly dealerId: string;
    readonly dealerName: string;
    readonly userId: string;
    readonly timeZone: string;
    readonly locale: string;
    readonly userFullName: string;
    readonly darkmode: boolean;
    readonly serverTime: Date;
}

export interface StatusDashboardResult {
    readonly assignedCaseResults: CaseResult[];
    readonly openCaseResults: CaseResult[];
    readonly postponedCaseResults: CaseResult[];
    readonly assignedOpportunityResults: OpportunityResult[];
    readonly openOpportunityResults: OpportunityResult[];
    readonly postponedOpportunityResults: OpportunityResult[];
}

interface StatusDashboardApi {
    generateLink(): Promise<string>;
    generateQrCodeImageLink(): string;
    getConfiguration(sdt: string): Promise<StatusDashboardConfiguration>;
    getNewStream(sdt: string): ReconnectingEventSource<Notification>;
    getResult(sdt: string): Promise<StatusDashboardResult>;
}

export const statusDashboardApi: StatusDashboardApi = {
    async generateLink() {
        return (await axios.get("/api/status-dashboard/management/_generate-link")).data;
    },

    generateQrCodeImageLink() {
        return "/api/status-dashboard/management/qr-code.png";
    },

    async getConfiguration(sdt: string) {
        const result: StatusDashboardConfiguration = (
            await axios.get(`/api/status-dashboard/_configuration?sdt=${sdt}`)
        ).data;

        return {
            ...result,
            serverTime: new Date(result.serverTime),
        };
    },

    getNewStream(sdt: string) {
        return new ReconnectingEventSource(`/api/status-dashboard/_stream?sdt=${sdt}`, (data) => cloneObject(data));
    },

    async getResult(sdt: string) {
        return cloneObject((await axios.get(`/api/status-dashboard/_result?sdt=${sdt}`)).data);
    },
};
