
import { inventoryApi, InventoryVehicle, InventoryVehicleStatusType } from "@/api/inventory";
import { OpportunityAuditEvent, OpportunityAuditEventType } from "@/api/opportunities";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { getStatusColor as getInventoryVehicleStatusColor, renderInventoryVehicleTitle } from "@/app/inventoryUtils";
import OpportunityTeamLink from "@/app/pages/OpportunityTeamLink.vue";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { opportunityOutcomeReasonsStore } from "@/store/opportunityOutcomeReasons";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        auditEvent: {
            type: Object as () => OpportunityAuditEvent,
            required: true,
        },
        inventoryVehicles: {
            type: Array as () => InventoryVehicle[],
            required: true,
        },
    },

    data() {
        return {
            getInventoryVehicleStatusColor,
            inventoryVehicle: null as InventoryVehicle | null,
            InventoryVehicleStatusType,
            loadingInventoryVehicle: true,
            OpportunityAuditEventType,
            renderInventoryVehicleTitle,
        };
    },

    computed: {
        addedAssigneeIds(): string[] {
            return this.auditEvent.newAssignedUserIds.filter(
                (assigneeId) => !this.auditEvent.oldAssignedUserIds.includes(assigneeId)
            );
        },

        addedReceiverTeamIds(): string[] {
            return this.getSortedByTeamName(
                this.auditEvent.newOpportunityTeamReceiverIds.filter(
                    (teamId) => !this.auditEvent.oldOpportunityTeamReceiverIds.includes(teamId)
                )
            );
        },

        addedReceiverUserIds(): string[] {
            return this.getSortedByFullName(
                this.auditEvent.newIndividualReceiverUserIds.filter(
                    (userId) => !this.auditEvent.oldIndividualReceiverUserIds.includes(userId)
                )
            );
        },

        creator(): string {
            if (this.auditEvent.creatorCtUserId) {
                return this.$t("Externer Agent") as string;
            }
            if (this.auditEvent.creatorUserId) {
                return this.getFullNameById(this.auditEvent.creatorUserId);
            }
            return this.$t("System") as string;
        },

        creationTimestamp() {
            return formatInstant(this.auditEvent.created, userSession.timeZone, userSession.locale, "S");
        },

        hasAddedReceivers(): boolean {
            return !!this.addedReceiverUserIds.length || !!this.addedReceiverTeamIds.length;
        },

        hasRemovedReceivers(): boolean {
            return !!this.removedReceiverUserIds.length || !!this.removedReceiverTeamIds.length;
        },

        outcomeReasonName(): string | null {
            if (!this.auditEvent.outcomeReasonId) {
                return null;
            }

            return (opportunityOutcomeReasonsStore.getOpportunityOutcomeReasonById(this.auditEvent.outcomeReasonId)
                ?.name || this.$t("Unbekannt")) as string;
        },

        postponedUntilTimestamp(): string | null {
            if (!this.auditEvent.postponedUntil) {
                return null;
            }

            return formatInstant(this.auditEvent.postponedUntil, userSession.timeZone, userSession.locale, "S");
        },

        removedAssigneeIds(): string[] {
            return this.auditEvent.oldAssignedUserIds.filter(
                (assigneeId) => !this.auditEvent.newAssignedUserIds.includes(assigneeId)
            );
        },

        removedReceiverTeamIds(): string[] {
            return this.getSortedByTeamName(
                this.auditEvent.oldOpportunityTeamReceiverIds.filter(
                    (teamId) => !this.auditEvent.newOpportunityTeamReceiverIds.includes(teamId)
                )
            );
        },

        removedReceiverUserIds(): string[] {
            return this.getSortedByFullName(
                this.auditEvent.oldIndividualReceiverUserIds.filter(
                    (userId) => !this.auditEvent.newIndividualReceiverUserIds.includes(userId)
                )
            );
        },
    },

    methods: {
        getFullNameById(id: string) {
            return (getFullName(usersStore.getUserById(id)) || this.$t("Unbekannt")) as string;
        },

        getSortedByFullName(userIds: string[]): string[] {
            return [...userIds].sort((a, b) =>
                this.getFullNameById(a).localeCompare(this.getFullNameById(b), userSession.locale)
            );
        },

        getTeamNameById(id: string) {
            return (opportunityTeamsStore.getOpportunityTeamById(id)?.name ||
                this.$t("Gelöschtes Verkäuferteam")) as string;
        },

        getSortedByTeamName(teamIds: string[]) {
            return [...teamIds].sort((a, b) =>
                this.getTeamNameById(a).localeCompare(this.getTeamNameById(b), userSession.locale)
            );
        },
    },

    async mounted() {
        try {
            if (this.auditEvent.inventoryVehicleId) {
                this.inventoryVehicle =
                    this.inventoryVehicles.find((v) => v.id === this.auditEvent.inventoryVehicleId) || null;

                if (!this.inventoryVehicle) {
                    this.inventoryVehicle = await inventoryApi.getById(this.auditEvent.inventoryVehicleId);
                }
            }
        } finally {
            this.loadingInventoryVehicle = false;
        }
    },

    components: {
        CopyToClipboardIcon,
        UserLink,
        TimeSpan,
        OpportunityTeamLink,
    },
});
