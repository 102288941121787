
import { Contact } from "@/api/contacts";
import {
    InventoryVehicleInquiry,
    InventoryVehicleInquiryAndContact,
    inventoryVehicleInquiryApi,
} from "@/api/inventoryVehicleInquiries";
import {
    isInventoryVehicleInquiryStatusUpdatedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { Permission } from "@/api/userSession";
import { renderContactCaption } from "@/app/contactUtils";
import { renderDefaultCurrency, renderUnit } from "@/app/filters";
import { renderPower } from "@/app/inventoryUtils";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import OpportunityTeamLink from "@/app/pages/OpportunityTeamLink.vue";
import UserLink from "@/app/pages/UserLink.vue";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        inventoryVehicleInquiryAndContact: {
            type: Object as () => InventoryVehicleInquiryAndContact,
            required: true,
        },
    },

    data() {
        return {
            enablingOrDisabling: false,
            inventoryVehicleInquiryAndContactProp: cloneObject(this.inventoryVehicleInquiryAndContact),
            notificationHandler: null as ((n: Notification) => void) | null,
        };
    },

    computed: {
        contact(): Contact | null {
            return this.inventoryVehicleInquiryAndContactProp.contact;
        },

        contactCaption(): string | null {
            if (!this.contact) {
                return null;
            }

            return renderContactCaption(this.contact.contactData);
        },

        cubicCapacityRangeText(): string | null {
            const renderCubicCapacityFn = (cubicCapacity: number) => renderUnit(cubicCapacity, "ccm");

            return this.getRangeText(
                this.safeRender(this.inventoryVehicleInquiry.data.cubicCapacityFrom, renderCubicCapacityFn),
                this.safeRender(this.inventoryVehicleInquiry.data.cubicCapacityTo, renderCubicCapacityFn)
            );
        },

        dealerName(): string {
            return this.getDealerNameById(this.inventoryVehicleInquiry.data.dealerId);
        },

        features(): string[] {
            const tags: string[] = [];

            for (const feature of this.inventoryVehicleInquiry.data.features) {
                tags.push(this.$t(`enum.Feature.${feature}`) as string);
            }

            return tags
                .filter((feature, index, array) => array.indexOf(feature) === index)
                .sort((a, b) => a.localeCompare(b, userSession.locale));
        },

        firstRegistrationRangeText(): string | null {
            return this.getRangeText(
                this.inventoryVehicleInquiry.data.firstRegistrationFrom,
                this.inventoryVehicleInquiry.data.firstRegistrationTo
            );
        },

        inventoryVehicleInquiry(): InventoryVehicleInquiry {
            return this.inventoryVehicleInquiryAndContactProp.inventoryVehicleInquiry;
        },

        isContactDeleted(): boolean {
            return !!this.contact?.deleted;
        },

        mileageRangeText(): string | null {
            const renderMileageFn = (mileage: number) => renderUnit(mileage, "km");

            return this.getRangeText(
                this.safeRender(this.inventoryVehicleInquiry.data.mileageFrom, renderMileageFn),
                this.safeRender(this.inventoryVehicleInquiry.data.mileageTo, renderMileageFn)
            );
        },

        powerRangeText(): string | null {
            return this.getRangeText(
                this.safeRender(this.inventoryVehicleInquiry.data.powerFrom, renderPower),
                this.safeRender(this.inventoryVehicleInquiry.data.powerTo, renderPower)
            );
        },

        priceRangeText(): string | null {
            const renderPriceFn = (price: number) => renderDefaultCurrency(price);

            return this.getRangeText(
                this.safeRender(this.inventoryVehicleInquiry.data.priceFrom, renderPriceFn),
                this.safeRender(this.inventoryVehicleInquiry.data.priceTo, renderPriceFn)
            );
        },

        canManage(): boolean {
            return (
                userSession.hasPermission(Permission.MANAGE_ALL_INVENTORY_VEHICLE_INQUIRIES) ||
                (!!this.inventoryVehicleInquiry.creatorUserId &&
                    this.inventoryVehicleInquiry.creatorUserId === userSession.userId)
            );
        },
    },

    methods: {
        getDealerNameById(dealerId: string): string {
            return dealersStore.dealerById(dealerId)?.name || (this.$t("Unbekannter Standort") as string);
        },

        getRangeText(from: string | null, to: string | null): string | null {
            if (from === to) {
                return from;
            } else if (from !== null && to !== null) {
                return this.$t("{from} bis {to}", { from, to }) as string;
            } else if (from !== null) {
                return this.$t("ab {from}", { from }) as string;
            } else if (to !== null) {
                return this.$t("bis {to}", { to }) as string;
            } else {
                return null;
            }
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        safeRender<T>(value: T | null, renderFn: (value: T) => string | null): string | null {
            return value !== null ? renderFn(value) : null;
        },

        async toggleDisabled() {
            this.enablingOrDisabling = true;
            try {
                if (this.inventoryVehicleInquiry.disabled) {
                    await inventoryVehicleInquiryApi.enable(this.inventoryVehicleInquiry.id);
                } else {
                    await inventoryVehicleInquiryApi.disable(this.inventoryVehicleInquiry.id);
                }
            } finally {
                this.enablingOrDisabling = false;
            }
        },
    },

    watch: {
        inventoryVehicleInquiryAndContact() {
            this.inventoryVehicleInquiryAndContactProp = cloneObject(this.inventoryVehicleInquiryAndContact);
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isInventoryVehicleInquiryStatusUpdatedNotification(n)) {
                if (n.inventoryVehicleInquiryId === this.inventoryVehicleInquiry.id) {
                    this.inventoryVehicleInquiryAndContactProp = {
                        ...this.inventoryVehicleInquiryAndContactProp,
                        inventoryVehicleInquiry: {
                            ...this.inventoryVehicleInquiryAndContactProp.inventoryVehicleInquiry,
                            disabled: n.disabled,
                        },
                    };
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        IconWithTooltip,
        OpportunityTeamLink,
        UserLink,
    },
});
