import { IncomingCallEvent, IncomingCallEventStatus, IncomingCallResult } from "@/api/incomingCalls";
import { partitionBy } from "@/util/arrayUtils";

export interface IncomingCallEventWithDuration extends IncomingCallEvent {
    readonly duration: number | null;
}

export interface ConnectingToNumberCall {
    readonly callId: string;
    readonly initiated: Date | null;
    readonly fromNumber: string | null;
    readonly toUserId: string | null;
    readonly toNumber: string;
    readonly events: IncomingCallEventWithDuration[];
    readonly internalIncomingPhoneNumberId: string | null;
}

export interface OutboundCall {
    readonly callId: string;
    readonly internalIncomingPhoneNumberId: string | null;
    readonly connectingToNumberCalls: ConnectingToNumberCall[];
}

export interface InternalIncomingPhoneNumberEvents {
    readonly internalIncomingPhoneNumberId: string | null;
    readonly outboundCalls: OutboundCall[];
}

export function getIncomingCallEventStatusColor(
    status: IncomingCallEventStatus,
    duration: number | null
): string | null {
    if (status === IncomingCallEventStatus.RINGING && duration === null) {
        return "warning";
    } else if (status === IncomingCallEventStatus.IN_PROGRESS && duration === null) {
        return "warning";
    } else if (status === IncomingCallEventStatus.COMPLETED) {
        return "success";
    } else if (status === IncomingCallEventStatus.BUSY) {
        return "error";
    } else if (status === IncomingCallEventStatus.FAILED) {
        return "error";
    } else if (status === IncomingCallEventStatus.NO_ANSWER) {
        return "error";
    } else if (status === IncomingCallEventStatus.CANCELED) {
        return "error";
    } else {
        return null;
    }
}

function toConnectingToNumberCalls(events: IncomingCallEvent[]): ConnectingToNumberCall[] {
    return partitionBy(
        events.filter((e) => !e.inbound),
        (e) => `${e.callId}-${e.toNumber}`
    ).map((connectingCallEvents) => ({
        callId: connectingCallEvents[0].callId,
        initiated: connectingCallEvents.find((e) => e.status === IncomingCallEventStatus.INIT)?.timestamp ?? null,
        fromNumber: connectingCallEvents[0].fromNumber,
        toUserId: connectingCallEvents[0].toUserId,
        toNumber: connectingCallEvents[0].toNumber,
        events: connectingCallEvents.map((e, index, array) => ({
            ...e,
            duration:
                index < array.length - 1
                    ? Math.trunc((array[index + 1].timestamp.getTime() - e.timestamp.getTime()) / 1000)
                    : null,
        })),
        internalIncomingPhoneNumberId: connectingCallEvents[0].internalIncomingPhoneNumberId,
    }));
}

function toOutboundCalls(events: IncomingCallEvent[]): OutboundCall[] {
    return partitionBy(events, (e) => e.callId)
        .map((outboundCallEvents) => ({
            callId: outboundCallEvents[0].callId,
            internalIncomingPhoneNumberId: outboundCallEvents[0].internalIncomingPhoneNumberId,
            connectingToNumberCalls: toConnectingToNumberCalls(outboundCallEvents),
        }))
        .filter((call) => call.connectingToNumberCalls.length);
}

export function toInternalIncomingPhoneNumberEvents(
    incomingCall: IncomingCallResult
): InternalIncomingPhoneNumberEvents[] {
    const events = partitionBy(toOutboundCalls(incomingCall.events), (c) => c.internalIncomingPhoneNumberId).map(
        (outboundCalls) => ({
            internalIncomingPhoneNumberId: outboundCalls[0].internalIncomingPhoneNumberId,
            outboundCalls,
        })
    );

    if (!incomingCall.forwardedInternalIncomingPhoneNumberIds.length) {
        return events;
    }

    const intermediateInternalNumberIds = [
        ...incomingCall.forwardedInternalIncomingPhoneNumberIds,
        incomingCall.internalIncomingPhoneNumberId,
    ].filter((id): id is string => !!id);

    const result: InternalIncomingPhoneNumberEvents[] = [];

    for (const internalIncomingPhoneNumberId of intermediateInternalNumberIds) {
        if (events.length && events[0].internalIncomingPhoneNumberId === internalIncomingPhoneNumberId) {
            result.push(events.shift()!);
        } else {
            result.push({ internalIncomingPhoneNumberId, outboundCalls: [] });
        }
    }

    return result;
}
