
import { incomingPhoneNumbersApi, InternalIncomingPhoneNumber } from "@/api/incomingPhoneNumbers";
import { SmsSettings, smsSettingsApi } from "@/api/smsSettings";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import { getSortedByDealer } from "@/app/dealerUtils";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { userSession } from "@/store/userSession";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            saving: false,
            parseAndFormatNumber,
            smsCapableInternalIncomingPhoneNumbers: [] as InternalIncomingPhoneNumber[],
            smsSettings: {
                defaultSender: null,
                catchAllUserSmsEscalationGroupId: null,
                manualUserSmsDispatching: null,
            } as SmsSettings,
        };
    },

    computed: {
        escalationGroupOptions(): SelectOption[] {
            return getSortedByDealer(escalationGroupsStore.escalationGroups, (e) => e.dealerId).map((e) => ({
                text: `${e.name} (${this.getDealerNameById(e.dealerId)})`,
                value: e.id,
            }));
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Rufnummer"),
                    width: "30%",
                },
                {
                    text: this.$t("Status"),
                    width: "70%",
                },
            ];
        },

        manualUserSmsDispatchingOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },

        notEmpty() {
            return notEmpty;
        },

        receiveCapability(): boolean {
            return this.smsCapableInternalIncomingPhoneNumbers.some((i) => i.number === this.smsSettings.defaultSender);
        },

        receiveCapabilityColor(): string | null {
            if (this.loading) {
                return null;
            }

            if (!(this.$refs.defaultsenderfield as any).validate()) {
                return "error";
            }
            if (this.receiveCapability) {
                return "success";
            }
            return "warning";
        },

        receiveCapabilityHint(): string | null {
            if (this.loading || !this.smsSettings.defaultSender) {
                return null;
            }

            if (this.receiveCapability) {
                return this.$t("Antworten an diesen Absender sind möglich.") as string;
            } else {
                return this.$t("Antworten an diesen Absender sind nicht möglich.") as string;
            }
        },

        defaultSenderRules(): Function[] {
            return [
                (v: string) =>
                    !v ||
                    this.smsCapableInternalIncomingPhoneNumbers.some((i) => i.number === v) ||
                    !!v.match(/^(?=.*[a-zA-Z])[0-9a-zA-Z]{1,11}$/) ||
                    this.$t("Diese Absenderkennung ist nicht gültig."),
            ];
        },
    },

    methods: {
        getDealerNameById(dealerId: string) {
            return (dealersStore.dealerById(dealerId)?.name || this.$t("Unbekannt")).toString();
        },

        async updateSmsSettings() {
            if (!(this.$refs.smssettingsform as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await smsSettingsApi.editSmsSettings(this.smsSettings);
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        try {
            this.smsSettings = await smsSettingsApi.getSmsSettings();
            this.smsCapableInternalIncomingPhoneNumbers = (await incomingPhoneNumbersApi.getAllInternal())
                .filter((i) => i.smsCapability)
                .sort((a, b) => a.number.localeCompare(b.number, userSession.locale));
        } finally {
            this.loading = false;
        }
    },

    components: {
        DataTable,
        DealerGlobalContextHint,
    },
});
