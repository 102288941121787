
import { CaseCountType, caseSearchApi, CaseSearchRequest } from "@/api/caseSearch";
import { CaseStatus } from "@/api/cases";
import { Contact } from "@/api/contacts";
import {
    EmergencyIncidentCountType,
    emergencyIncidentSearchApi,
    EmergencyIncidentSearchRequest,
} from "@/api/emergencyIncidentSearch";
import { OpportunityStatus } from "@/api/opportunities";
import { OpportunityCountType, opportunitySearchApi, OpportunitySearchRequest } from "@/api/opportunitySearch";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { renderContactCaption, renderPhoneNumberTypeIcon } from "@/app/contactUtils";
import { getCountryCaption, getStateCaption, getStateLabel } from "@/app/countryStateUtils";
import PhoneNumberLink from "@/app/pages/PhoneNumberLink.vue";
import CaseSearchBottomSheet from "@/app/pages/cases/CaseSearchBottomSheet.vue";
import { EMPTY_SEARCH_REQUEST as EMPTY_CASE_SEARCH_REQUEST } from "@/app/pages/cases/caseSearchUtils";
import { getCaseStatusColor } from "@/app/pages/cases/caseUtils";
import EmergencyIncidentSearchBottomSheet from "@/app/pages/emergencyincidents/EmergencyIncidentSearchBottomSheet.vue";
import { EMPTY_SEARCH_REQUEST as EMPTY_EMERGENCY_INCIDENT_SEARCH_REQUEST } from "@/app/pages/emergencyincidents/emergencyIncidentSearchUtils";
import { getEmergencyIncidentStatusColor } from "@/app/pages/emergencyincidents/emergencyIncidentUtils";
import OpportunitySearchBottomSheet from "@/app/pages/opportunities/OpportunitySearchBottomSheet.vue";
import { EMPTY_SEARCH_REQUEST as EMPTY_OPPORTUNITY_SEARCH_REQUEST } from "@/app/pages/opportunities/opportunitySearchUtils";
import { getOpportunitiesCountColor } from "@/app/pages/opportunities/opportunityUtils";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { fromNow, getAge } from "@/util/dateTimeUtils";
import Vue from "vue";

function getCasesCountColor(statuses: CaseStatus[]): string | null {
    const earliest = [
        CaseStatus.OPEN,
        CaseStatus.ASSIGNED,
        CaseStatus.POSTPONED,
        CaseStatus.CLOSED,
        ...(Object.keys(CaseStatus) as CaseStatus[]),
    ].find((status) => statuses.includes(status));

    return getCaseStatusColor(earliest ?? CaseStatus.OPEN);
}

function getEmergencyIncidentsCountColor(isOpenStatuses: boolean[]): string | null {
    const earliest = [true, false].find((isOpen) => isOpenStatuses.includes(isOpen));

    return getEmergencyIncidentStatusColor(!earliest);
}

export default Vue.extend({
    props: {
        canEdit: {
            type: Boolean,
            default: false,
        },
        canSelectEmailAddress: {
            type: Boolean,
            default: false,
        },
        canSelectPhoneNumber: {
            type: Boolean,
            default: false,
        },
        canSendEmail: {
            type: Boolean,
            default: false,
        },
        canSendSms: {
            type: Boolean,
            default: false,
        },
        canStartCall: {
            type: Boolean,
            default: false,
        },
        canTriggerTelLink: {
            type: Boolean,
            default: false,
        },
        canUnselect: {
            type: Boolean,
            default: false,
        },
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        contextIssueId: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        elevatedButtons: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        showIssues: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            caseSearchBottomSheetVisible: false,
            emergencyIncidentSearchBottomSheetVisible: false,
            getCountryCaption,
            getStateLabel,
            getStateCaption,
            loadingIssueStatusCounts: false,
            opportunitySearchBottomSheetVisible: false,
            otherCasesCount: null as number | null,
            otherCasesCountColor: null as string | null,
            otherEmergencyIncidentsCount: null as number | null,
            otherEmergencyIncidentsCountColor: null as string | null,
            otherOpportunitiesCount: null as number | null,
            otherOpportunitiesCountColor: null as string | null,
            renderContactCaption,
            renderPhoneNumberTypeIcon,
            searchId: 1,
        };
    },

    computed: {
        age(): number | null {
            if (!this.contact?.contactData.dateOfBirth) {
                return null;
            }

            return getAge(now(), this.contact.contactData.dateOfBirth, userSession.timeZone);
        },

        canUseTelLink(): boolean {
            return configStore.configuration.canUseTelLink;
        },

        canViewCases(): boolean {
            return (
                userSession.hasPermission(Permission.MANAGE_OWN_CASES) ||
                userSession.hasPermission(Permission.VIEW_ALL_CASES)
            );
        },

        canViewEmergencyIncidents(): boolean {
            return userSession.hasPermission(Permission.MANAGE_EMERGENCY_INCIDENTS);
        },

        canViewOpportunities(): boolean {
            return (
                userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES) ||
                userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES)
            );
        },

        caseSearchRequest(): CaseSearchRequest | null {
            return this.contactId
                ? {
                      ...EMPTY_CASE_SEARCH_REQUEST,
                      contactIds: [this.contactId],
                  }
                : null;
        },

        contactId(): string | null {
            if (!this.contact || this.contact.deleted) {
                return null;
            }

            return this.contact.id;
        },

        emergencyIncidentSearchRequest(): EmergencyIncidentSearchRequest | null {
            return this.contactId
                ? {
                      ...EMPTY_EMERGENCY_INCIDENT_SEARCH_REQUEST,
                      contactId: this.contactId,
                  }
                : null;
        },

        hasAddressParts(): boolean {
            return (
                !!this.contact?.contactData.address1 ||
                !!this.contact?.contactData.address2 ||
                !!this.contact?.contactData.zip ||
                !!this.contact?.contactData.city ||
                !!this.contact?.contactData.state ||
                !!this.contact?.contactData.country
            );
        },

        hasNumbersOrEmailAddresses(): boolean {
            return !!this.contact?.contactData.emailAddresses.length || !!this.contact?.contactData.numbers.length;
        },

        isContextIssueCase(): boolean {
            return !!this.contextIssueId?.startsWith("C-");
        },

        isContextIssueEmergencyIncident(): boolean {
            return !!this.contextIssueId?.startsWith("E-");
        },

        isContextIssueOpportunity(): boolean {
            return !!this.contextIssueId?.startsWith("O-");
        },

        opportunitySearchRequest(): OpportunitySearchRequest | null {
            return this.contactId
                ? {
                      ...EMPTY_OPPORTUNITY_SEARCH_REQUEST,
                      contactIds: [this.contactId],
                  }
                : null;
        },
    },

    methods: {
        async loadIssueStatusCounts() {
            this.otherCasesCount = null;
            this.otherCasesCountColor = null;
            this.otherEmergencyIncidentsCount = null;
            this.otherEmergencyIncidentsCountColor = null;
            this.otherOpportunitiesCount = null;
            this.otherOpportunitiesCountColor = null;

            if (!this.showIssues) {
                return;
            }

            this.loadingIssueStatusCounts = true;
            const searchId = ++this.searchId;

            try {
                const [otherCasesCounts, otherEmergencyIncidentsCounts, otherOpportunitiesCounts] = await Promise.all([
                    this.canViewCases && this.caseSearchRequest
                        ? caseSearchApi.getCounts(
                              {
                                  ...this.caseSearchRequest,
                                  excludedCaseIds: this.contextIssueId ? [this.contextIssueId] : [],
                              },
                              [CaseCountType.STATUS],
                              false
                          )
                        : Promise.resolve([]),
                    this.canViewEmergencyIncidents && this.emergencyIncidentSearchRequest
                        ? emergencyIncidentSearchApi.getCounts(
                              {
                                  ...this.emergencyIncidentSearchRequest,
                                  excludedEmergencyIncidentIds: this.contextIssueId ? [this.contextIssueId] : [],
                              },
                              [EmergencyIncidentCountType.OPEN]
                          )
                        : Promise.resolve([]),
                    this.canViewOpportunities && this.opportunitySearchRequest
                        ? opportunitySearchApi.getCounts(
                              {
                                  ...this.opportunitySearchRequest,
                                  excludedOpportunityIds: this.contextIssueId ? [this.contextIssueId] : [],
                              },
                              [OpportunityCountType.STATUS],
                              false
                          )
                        : Promise.resolve([]),
                ]);

                if (searchId === this.searchId) {
                    this.otherCasesCount = otherCasesCounts.reduce((sum, cc) => sum + cc.count, 0);
                    this.otherCasesCountColor = getCasesCountColor(
                        otherCasesCounts.map((cc) => cc.value as CaseStatus)
                    );

                    this.otherEmergencyIncidentsCount = otherEmergencyIncidentsCounts.reduce(
                        (sum, cur) => sum + cur.count,
                        0
                    );
                    this.otherEmergencyIncidentsCountColor = getEmergencyIncidentsCountColor(
                        otherEmergencyIncidentsCounts.map((eic) => eic.value === "true")
                    );

                    this.otherOpportunitiesCount = otherOpportunitiesCounts.reduce((sum, cur) => sum + cur.count, 0);
                    this.otherOpportunitiesCountColor = getOpportunitiesCountColor(
                        otherOpportunitiesCounts.map((oc) => oc.value as OpportunityStatus)
                    );
                }
            } finally {
                if (searchId === this.searchId) {
                    this.loadingIssueStatusCounts = false;
                }
            }
        },

        renderFromNow(localDate: string) {
            const fromNowCaption = fromNow(now(), localDate, userSession.timeZone, userSession.locale);

            if (fromNowCaption) {
                return fromNowCaption;
            }

            return this.$t("heute");
        },
    },

    watch: {
        async contactId() {
            try {
                await this.loadIssueStatusCounts();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        async showIssues() {
            try {
                await this.loadIssueStatusCounts();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadIssueStatusCounts();
    },

    components: {
        CaseSearchBottomSheet,
        CopyToClipboardIcon,
        EmergencyIncidentSearchBottomSheet,
        OpportunitySearchBottomSheet,
        PhoneNumberLink,
    },
});
