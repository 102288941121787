
import { isVideochatsUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { Videochat, videochatApi } from "@/api/videochats";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import UserLink from "@/app/pages/UserLink.vue";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import { PickMutable } from "@/util/types";
import Vue from "vue";

const ITEMS_PER_PAGE = 100;

export default Vue.extend({
    data() {
        return {
            items: [] as Videochat[],
            loading: true,
            paging: {
                page: 1,
                pageSize: ITEMS_PER_PAGE,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / ITEMS_PER_PAGE,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
            startingVideochat: false,
            notificationHandler: null as ((n: Notification) => void) | null,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Videochat"),
                    width: "30%",
                },
                {
                    text: this.$t("Standort"),
                    width: "20%",
                },
                {
                    text: this.$t("Teilnehmer"),
                    width: "50%",
                },
            ];
        },
    },

    methods: {
        getParticipants(item: Videochat) {
            const names = item.joinEvents.map((e) => e.name || (this.$t("Anonymer Teilnehmer") as string));
            const uniqueNames: string[] = [];
            for (const name of names) {
                if (uniqueNames.indexOf(name) === -1) uniqueNames.push(name);
            }

            return uniqueNames.join(", ");
        },

        async loadItems(silent: boolean) {
            if (!silent) {
                this.paging.totalSize = 0;
                this.items = [];
                this.loading = true;
            }

            try {
                const videochatResults = await videochatApi.getMyVideochats(
                    (this.paging.page - 1) * ITEMS_PER_PAGE,
                    ITEMS_PER_PAGE,
                    ++this.searchCounter
                );

                if (videochatResults.searchId === this.searchCounter) {
                    this.items = videochatResults.results;
                    this.paging.totalSize = videochatResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems(false);
        },

        async startVideochat() {
            this.startingVideochat = true;
            try {
                const id = await videochatApi.start();
                await this.$router.push(`/videochat/${id}`);
            } finally {
                this.startingVideochat = false;
            }
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },
    },

    async mounted() {
        await this.loadItems(false);

        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isVideochatsUpdatedNotification(n)) {
                await this.loadItems(true);
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        DataTable,
        UserLink,
    },
});
