
import { AutoCrmLeadMailerSettings, autoCrmLeadMailerSettingsApi } from "@/api/autoCrmLeadMailerSettings";
import { Permission } from "@/api/userSession";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { email, maxLength } from "@/app/validation";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            autoCrmLeadMailerSettings: {
                autoCrmEmailAddress: null,
                source: null,
            } as AutoCrmLeadMailerSettings,
            loading: true,
            email,
            maxLength,
            Permission,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },
    },

    methods: {
        async loadSettings() {
            if (!this.dealerId) {
                return;
            }

            this.loading = true;
            try {
                this.autoCrmLeadMailerSettings = await autoCrmLeadMailerSettingsApi.getSettingsByDealer(this.dealerId);
            } finally {
                this.loading = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.loading = true;
            try {
                await autoCrmLeadMailerSettingsApi.editSettings(this.dealerId!, this.autoCrmLeadMailerSettings);
            } finally {
                this.loading = false;
            }
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadSettings();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadSettings();
    },

    components: {
        DealerContextGuard,
    },
});
