
import { statusDashboardApi } from "@/api/statusDashboard";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            statusDashboardLink: null as null | string,
            qrCodeImageLink: null as null | string,
        };
    },

    async created() {
        this.statusDashboardLink = await statusDashboardApi.generateLink();
        this.qrCodeImageLink = statusDashboardApi.generateQrCodeImageLink();
    },

    methods: {
        close() {
            this.$emit("close");
        },
    },
});
