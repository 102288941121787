
import InventoryDataTableRow from "./InventoryDataTableRow.vue";
import { inventoryOverviewState } from "./inventoryOverview";
import {
    InventoryCount,
    InventoryCountType,
    inventorySearchApi,
    InventorySearchOrder,
    InventoryVehicleResult,
} from "@/api/inventorySearch";
import DataTable from "@/app/components/DataTable.vue";
import EnumField from "@/app/components/EnumField.vue";
import MonthPicker from "@/app/components/MonthPicker.vue";
import NumberField from "@/app/components/NumberField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { integer, validate } from "@/app/validation";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { PickMutable, SelectOptions } from "@/util/types";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

const ITEMS_PER_PAGE = 25;

export default Vue.extend({
    data() {
        return {
            inventoryOverviewState,
            InventorySearchOrder,
            positiveIntegerRules: integer(0),
            items: [] as InventoryVehicleResult[],
            counts: [] as InventoryCount[],
            loading: true,
            paging: {
                page: 1,
                pageSize: ITEMS_PER_PAGE,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / ITEMS_PER_PAGE,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchCounter: 1,
            mileageFrom: "",
            mileageTo: "",
            cubicCapacityFrom: "",
            cubicCapacityTo: "",
            powerFrom: "",
            powerTo: "",
            priceFrom: "",
            priceTo: "",
        };
    },

    computed: {
        defaultCurrency(): string {
            return configStore.configuration.defaultCurrency;
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Bilder"),
                    width: "5%",
                },
                {
                    text: this.$t("Fahrzeug"),
                    width: "90%",
                },
                {
                    text: this.$t("Verkaufschancen"),
                    width: "5%",
                },
            ];
        },

        dealerOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.DEALER,
                (key) => dealersStore.dealerById(key)!.name,
                this.inventoryOverviewState.dealerIds,
                true
            );
        },

        vehicleClassOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.VEHICLE_CLASS,
                (key) => this.$t(`vehicle.class.${key}`),
                this.inventoryOverviewState.searchRequest.vehicleClasses,
                true
            );
        },

        categoryOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.CATEGORY,
                (key) => this.$t(`vehicle.category.${key}`),
                this.inventoryOverviewState.searchRequest.categories,
                true
            );
        },

        makeOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.MAKE,
                (key) => key,
                this.inventoryOverviewState.searchRequest.makes,
                true
            );
        },

        modelOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.MODEL,
                (key) => key,
                this.inventoryOverviewState.searchRequest.models,
                true
            );
        },

        usageTypeOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.USAGE_TYPE,
                (key) => this.$t(`enum.UsageType.${key}`),
                this.inventoryOverviewState.searchRequest.usageTypes,
                true
            );
        },

        deletionStatusOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.DELETION_STATUS,
                (key) => this.$t(`enum.InventoryVehicleDeletionStatus.${key}`),
                this.inventoryOverviewState.searchRequest.deletionStatuses,
                true
            );
        },

        gearboxOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.GEARBOX,
                (key) => this.$t(`enum.Gearbox.${key}`),
                this.inventoryOverviewState.searchRequest.gearboxes,
                true
            );
        },

        driveOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.DRIVE,
                (key) => this.$t(`enum.Drive.${key}`),
                this.inventoryOverviewState.searchRequest.drives,
                true
            );
        },

        fuelOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.FUEL,
                (key) => this.$t(`enum.Fuel.${key}`),
                this.inventoryOverviewState.searchRequest.fuels,
                true
            );
        },

        exteriorColorOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.EXTERIOR_COLOR,
                (key) => this.$t(`enum.ExteriorColor.${key}`),
                this.inventoryOverviewState.searchRequest.exteriorColors,
                true
            );
        },

        interiorTypeOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.INTERIOR_TYPE,
                (key) => this.$t(`enum.InteriorType.${key}`),
                this.inventoryOverviewState.searchRequest.interiorTypes,
                true
            );
        },

        climatisationOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.CLIMATISATION,
                (key) => this.$t(`enum.Climatisation.${key}`),
                this.inventoryOverviewState.searchRequest.climatisations,
                true
            );
        },

        speedControlOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.SPEED_CONTROL,
                (key) => this.$t(`enum.SpeedControl.${key}`),
                this.inventoryOverviewState.searchRequest.speedControls,
                true
            );
        },

        headlightTypeOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.HEADLIGHT_TYPE,
                (key) => this.$t(`enum.HeadlightType.${key}`),
                this.inventoryOverviewState.searchRequest.headlightTypes,
                true
            );
        },

        parkingAssistantOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.PARKING_ASSISTANTS,
                (key) => this.$t(`enum.ParkingAssistant.${key}`),
                this.inventoryOverviewState.searchRequest.parkingAssistants,
                false
            );
        },

        trailerCouplingTypeOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.TRAILER_COUPLING_TYPE,
                (key) => this.$t(`enum.TrailerCouplingType.${key}`),
                this.inventoryOverviewState.searchRequest.trailerCouplingTypes,
                true
            );
        },

        featureOptions(): SelectOptions {
            return this.getOptions(
                InventoryCountType.FEATURE,
                (key) => this.$t(`enum.Feature.${key}`),
                this.inventoryOverviewState.searchRequest.features,
                false
            );
        },
    },

    methods: {
        getOptions(
            type: InventoryCountType,
            translationSupplier: (key: string) => TranslateResult,
            selectedValues: (string | null)[],
            showCounts: boolean
        ) {
            // only retain counts of correct types
            const counts = this.counts.filter((c) => c.type === type);

            // add selected but not found counts with 0 count
            for (const selectedValue of selectedValues) {
                if (!counts.find((c) => c.value === selectedValue)) {
                    counts.push({
                        type,
                        value: selectedValue,
                        count: 0,
                    });
                }
            }

            // map counts to select options and return
            return counts.map((c) => {
                const selectedValue = selectedValues.indexOf(c.value) >= 0;

                if (c.value === null) {
                    return {
                        value: c.value,
                        text: `${this.$t("Unbekannt")}${showCounts || !selectedValue ? ` (${this.$n(c.count)})` : ""}`,
                    };
                }

                return {
                    value: c.value,
                    text: `${translationSupplier(c.value)}${
                        showCounts || !selectedValue ? ` (${this.$n(c.count)})` : ""
                    }`,
                };
            });
        },

        setMileageFrom(val: string | null) {
            this.inventoryOverviewState.mileageFrom =
                !validate(this.positiveIntegerRules, val) || !val ? null : parseInt(val);
        },

        setMileageTo(val: string | null) {
            this.inventoryOverviewState.mileageTo =
                !validate(this.positiveIntegerRules, val) || !val ? null : parseInt(val);
        },

        setCubicCapacityFrom(val: string | null) {
            this.inventoryOverviewState.cubicCapacityFrom =
                !validate(this.positiveIntegerRules, val) || !val ? null : parseInt(val);
        },

        setCubicCapacityTo(val: string | null) {
            this.inventoryOverviewState.cubicCapacityTo =
                !validate(this.positiveIntegerRules, val) || !val ? null : parseInt(val);
        },

        setPowerFrom(val: string | null) {
            this.inventoryOverviewState.powerFrom =
                !validate(this.positiveIntegerRules, val) || !val ? null : parseInt(val);
        },

        setPowerTo(val: string | null) {
            this.inventoryOverviewState.powerTo =
                !validate(this.positiveIntegerRules, val) || !val ? null : parseInt(val);
        },

        setPriceFrom(val: string | null) {
            this.inventoryOverviewState.priceFrom =
                !validate(this.positiveIntegerRules, val) || !val ? null : parseInt(val);
        },

        setPriceTo(val: string | null) {
            this.inventoryOverviewState.priceTo =
                !validate(this.positiveIntegerRules, val) || !val ? null : parseInt(val);
        },

        refresh() {
            this.inventoryOverviewState.refresh();
            this.mileageFrom = this.inventoryOverviewState.mileageFrom?.toString() || "";
            this.mileageTo = this.inventoryOverviewState.mileageTo?.toString() || "";
            this.cubicCapacityFrom = this.inventoryOverviewState.cubicCapacityFrom?.toString() || "";
            this.cubicCapacityTo = this.inventoryOverviewState.cubicCapacityTo?.toString() || "";
            this.powerFrom = this.inventoryOverviewState.powerFrom?.toString() || "";
            this.powerTo = this.inventoryOverviewState.powerTo?.toString() || "";
            this.priceFrom = this.inventoryOverviewState.priceFrom?.toString() || "";
            this.priceTo = this.inventoryOverviewState.priceTo?.toString() || "";
        },

        reset() {
            this.inventoryOverviewState.reset();
            this.mileageFrom = "";
            this.mileageTo = "";
            this.cubicCapacityFrom = "";
            this.cubicCapacityTo = "";
            this.powerFrom = "";
            this.powerTo = "";
            this.priceFrom = "";
            this.priceTo = "";
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const inventorySearchResults = await inventorySearchApi.search(
                    (this.paging.page - 1) * ITEMS_PER_PAGE,
                    ITEMS_PER_PAGE,
                    this.inventoryOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (inventorySearchResults.searchId === this.searchCounter) {
                    this.items = inventorySearchResults.inventoryVehicleResults;
                    this.counts = inventorySearchResults.inventoryCounts;
                    this.paging.totalSize = inventorySearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },
    },

    watch: {
        dealerId() {
            this.inventoryOverviewState.dealerIds = this.dealerId ? [this.dealerId] : [];
        },

        async "inventoryOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    mounted() {
        if (!this.inventoryOverviewState.initialized) {
            this.reset();
        } else {
            this.refresh();
        }
    },

    components: {
        EnumField,
        DataTable,
        InventoryDataTableRow,
        MonthPicker,
        NumberField,
    },
});
