import axios from "axios";

interface UserBulkProcessingApi {
    upsertUsersByCsv(
        mainDealerId: string,
        csvFile: File,
        progressCallback?: (progressEvent: ProgressEvent) => void
    ): Promise<string[]>;
}

export const userBulkProcessingApi: UserBulkProcessingApi = {
    async upsertUsersByCsv(mainDealerId, csvFile, onUploadProgress) {
        const formData = new FormData();
        formData.append("csvFile", csvFile);

        return (
            await axios.post(`/api/user-bulk-processing/${mainDealerId}`, formData, {
                onUploadProgress,
            })
        ).data;
    },
};
