
import { CallTrackingGroup, IncomingCallDistributionType } from "@/api/callTrackingGroups";
import { AutoAnswerType, InternalIncomingPhoneNumber, IssueType } from "@/api/incomingPhoneNumbers";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { getFullName } from "@/app/userUtils";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { officeHoursStore } from "@/store/officeHours";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { usersStore } from "@/store/users";
import Vue from "vue";

export default Vue.extend({
    props: {
        callTrackingGroups: {
            type: Array as () => CallTrackingGroup[],
            required: true,
        },
        internalIncomingPhoneNumber: {
            type: Object as () => InternalIncomingPhoneNumber,
            required: true,
        },
        internalIncomingPhoneNumbers: {
            type: Array as () => InternalIncomingPhoneNumber[],
            required: true,
        },
    },

    data() {
        return {
            AutoAnswerType,
            IssueType,
        };
    },

    computed: {
        distributeOnlyIfAvailable(): boolean | null {
            return this.selectedCallTrackingGroup
                ? this.selectedCallTrackingGroup.distributeOnlyIfAvailable
                : this.internalIncomingPhoneNumber.distributeOnlyIfAvailable;
        },

        distributeOnlyIfInWorkingHours(): boolean | null {
            return this.selectedCallTrackingGroup
                ? this.selectedCallTrackingGroup.distributeOnlyIfInWorkingHours
                : this.internalIncomingPhoneNumber.distributeOnlyIfInWorkingHours;
        },

        distributionOfficeHoursId(): string | null {
            return this.selectedCallTrackingGroup
                ? this.selectedCallTrackingGroup.distributionOfficeHoursId
                : this.internalIncomingPhoneNumber.distributionOfficeHoursId;
        },

        distributionTimeoutSeconds(): number | null {
            return this.selectedCallTrackingGroup
                ? this.selectedCallTrackingGroup.distributionTimeoutSeconds
                : this.internalIncomingPhoneNumber.distributionTimeoutSeconds;
        },

        distributionType(): IncomingCallDistributionType | null {
            return this.selectedCallTrackingGroup
                ? this.selectedCallTrackingGroup.distributionType
                : this.internalIncomingPhoneNumber.distributionType;
        },

        isAutoAnswerTransferToCallTrackingNumber(): boolean {
            return (
                this.internalIncomingPhoneNumber.autoAnswerType === AutoAnswerType.TRANSFER_TO_CUSTOM_NUMBER &&
                !!this.internalIncomingPhoneNumbers.find(
                    (n) => n.number === this.internalIncomingPhoneNumber.autoAnswerCustomNumber
                )
            );
        },

        selectedCallTrackingGroup(): CallTrackingGroup | null {
            if (!this.internalIncomingPhoneNumber.callTrackingGroupId) {
                return null;
            }

            return (
                this.callTrackingGroups.find((g) => g.id === this.internalIncomingPhoneNumber.callTrackingGroupId) ??
                null
            );
        },

        targetUserIds(): string[] {
            return [
                ...this.internalIncomingPhoneNumber.targetUserIds,
                ...(this.selectedCallTrackingGroup?.targetUserIds ?? []),
            ].filter((targetUserId, index, array) => array.indexOf(targetUserId) === index);
        },
    },

    methods: {
        getCallTrackingGroupName(callTrackingGroupId: string): string | null {
            return this.callTrackingGroups.find((g) => g.id === callTrackingGroupId)?.name ?? null;
        },

        getEscalationGroupNameById(escalationGroupId: string): string | null {
            return escalationGroupsStore.escalationGroupById(escalationGroupId)?.name ?? null;
        },

        getOfficeHoursNameById(officeHoursId: string): string | null {
            return officeHoursStore.getOfficeHoursById(officeHoursId)?.name ?? null;
        },

        getOpportunitySourceNameById(opportunitySourceId: string): string | null {
            return opportunitySourcesStore.getOpportunitySourceById(opportunitySourceId)?.name ?? null;
        },

        getOpportunityTeamNameById(opportunityTeamId: string): string | null {
            return opportunityTeamsStore.getOpportunityTeamById(opportunityTeamId)?.name ?? null;
        },

        getUserFullNameById(userId: string): string | null {
            return getFullName(usersStore.getUserById(userId)) || null;
        },
    },

    components: {
        CopyToClipboardIcon,
    },
});
