import { Contact } from "./contacts";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum IssueType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
    UNKNOWN = "UNKNOWN",
}

export enum OutgoingWhatsAppMessageStatus {
    SENT = "SENT",
    DELIVERED = "DELIVERED",
    READ = "READ",
    FAILED = "FAILED",
}

export enum OutgoingWhatsAppSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
}

export type OptionalIssueId = { readonly caseId: string } | { readonly opportunityId: string } | {};

export interface OutgoingWhatsAppMessageForm {
    readonly toNumber: string;
    readonly text: string | null;
    readonly templateNamespace: string | null;
    readonly templateName: string | null;
    readonly templateLanguage: string | null;
}

export interface OutgoingWhatsAppMessage {
    readonly id: string;
    readonly created: Date;
    readonly delivered: Date | null;
    readonly read: Date | null;
    readonly failed: Date | null;
    readonly initiatorUserId: string | null;
    readonly initiatorCtUserId: string | null;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly fromNumber: string;
    readonly toNumber: string;
    readonly text: string | null;
    readonly mediaHash: string | null;
    readonly mediaType: string | null;
    readonly mediaSize: number | null;
    readonly filename: string | null;
}

export interface OutgoingWhatsAppAndContact {
    readonly outgoingWhatsAppMessage: OutgoingWhatsAppMessage;
    readonly contact: Contact | null;
}

export interface OutgoingWhatsAppSearchRequest {
    readonly initiatorUserIds: string[];
    readonly issueTypes: IssueType[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly sortBy: OutgoingWhatsAppSearchOrder;
}

export interface OutgoingWhatsAppSearchResults {
    readonly results: OutgoingWhatsAppAndContact[];
    readonly searchId: number;
    readonly totalSize: number;
}

interface OutgoingWhatsAppApi {
    getMessagesByCase(caseId: string): Promise<OutgoingWhatsAppMessage[]>;
    getMessagesByOpportunity(opportunityId: string): Promise<OutgoingWhatsAppMessage[]>;
    getMyMessages(start: number, size: number, searchId: number): Promise<OutgoingWhatsAppSearchResults>;
    generateMediaLink(msg: OutgoingWhatsAppMessage, issueId: OptionalIssueId, download?: boolean): string;
    search(
        start: number,
        size: number,
        searchRequest: OutgoingWhatsAppSearchRequest,
        searchId: number
    ): Promise<OutgoingWhatsAppSearchResults>;
}

export const outgoingWhatsAppApi: OutgoingWhatsAppApi = {
    async getMessagesByCase(caseId) {
        return cloneObject((await axios.get(`/api/outgoing-whatsapp/cases/${caseId}`)).data);
    },

    async getMessagesByOpportunity(opportunityId) {
        return cloneObject((await axios.get(`/api/outgoing-whatsapp/opportunities/${opportunityId}`)).data);
    },

    async getMyMessages(start, size, searchId) {
        return cloneObject(
            (
                await axios.get("/api/outgoing-whatsapp/_my-messages", {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },

    generateMediaLink(msg, issueId, download) {
        return `/api/outgoing-whatsapp/${msg.id}/${msg.mediaHash}${
            msg.filename ? "/" + encodeURIComponent(msg.filename.replace(/\//g, "-")) : ""
        }?${Object.entries(issueId)
            .map((e) => `${e[0]}=${e[1]}`)
            .join("&")}${download ? "&dl=true" : ""}`;
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/outgoing-whatsapp/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },
};
