
import { ContactVehicle } from "@/api/contacts";
import { EmergencyIncident, EmergencyIncidentResult } from "@/api/emergencyIncidents";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DLink from "@/app/components/DLink.vue";
import {
    getStatusCaption,
    getStatusColor,
    getVehicleCaption,
    getVehicleDeleted,
    getVehicleIcon,
    getVehicleTooltip,
} from "@/app/pages/emergencyincidents/emergencyIncidentUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        compact: {
            type: Boolean,
            required: true,
        },
        emergencyIncidentResult: {
            type: Object as () => EmergencyIncidentResult,
            required: true,
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        contactVehicle(): ContactVehicle | null {
            return this.emergencyIncidentResult.contactVehicle;
        },

        emergencyIncident(): EmergencyIncident {
            return this.emergencyIncidentResult.emergencyIncident;
        },

        emergencyIncidentStatusCaption(): string {
            return getStatusCaption(this.emergencyIncidentResult.emergencyIncident);
        },

        emergencyIncidentStatusColor(): string | null {
            return getStatusColor(this.emergencyIncidentResult.emergencyIncident);
        },

        vehicleCaption(): string | null {
            return getVehicleCaption(this.emergencyIncidentResult, false);
        },

        vehicleDeleted(): boolean {
            return getVehicleDeleted(this.emergencyIncidentResult);
        },

        vehicleIcon(): string {
            return getVehicleIcon(this.emergencyIncidentResult);
        },

        vehicleTooltip(): string {
            return getVehicleTooltip(this.emergencyIncidentResult);
        },
    },

    components: {
        CopyToClipboardIcon,
        DLink,
    },
});
