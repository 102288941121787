
import SelfAssignButton from "./SelfAssignButton.vue";
import ContactInfo from "./datatable/ContactInfo.vue";
import OpportunityGeneralInfo from "./datatable/OpportunityGeneralInfo.vue";
import OpportunityMainInfo from "./datatable/OpportunityMainInfo.vue";
import { getOpportunityStatus, isVisibleFor } from "./opportunityUtils";
import {
    opportunitiesApi,
    Opportunity,
    OpportunityPriority,
    OpportunityResult,
    OpportunityStatus,
    OpportunityUrgency,
    OpportunityUser,
} from "@/api/opportunities";
import { Permission } from "@/api/userSession";
import InventoryVehicleImageAndCarousel, {
    InventoryVehicleImage,
} from "@/app/pages/inventory/InventoryVehicleImageAndCarousel.vue";
import { dealersStore } from "@/store/dealers";
import { opportunitySettingsStore } from "@/store/opportunitySettings";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        canSelect: {
            type: Boolean,
            default: false,
        },
        canSelfAssign: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            default: false,
        },
        opportunityResult: {
            type: Object as () => OpportunityResult,
            required: false,
        },
        opportunityUser: {
            type: Object as () => OpportunityUser,
            required: false,
        },
        selectDisabled: {
            type: Boolean,
            default: false,
        },
        ultraCompactMode: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            assigning: false,
        };
    },

    computed: {
        canAssign(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            return (
                this.canSelfAssign &&
                userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES) &&
                isVisibleFor(this.opportunityObj, userSession.userId!)
            );
        },

        colCount(): number {
            return 3 + (this.ultraCompactMode ? 0 : 1);
        },

        compact(): boolean {
            return userSession.compactMode || this.ultraCompactMode;
        },

        dealerName(): string | null {
            if (!this.opportunityObj?.created) {
                return null;
            }

            return dealersStore.dealerById(this.opportunityObj.dealerId)?.name || null;
        },

        horizontalPadding(): number {
            return this.ultraCompactMode || this.$vuetify.breakpoint.xsOnly ? 2 : 3;
        },

        inventoryVehiclesImages(): InventoryVehicleImage[] {
            return (this.opportunityResult?.vehicleSummaries ?? [])
                .map((v) => v.images.map((image) => ({ inventoryVehicleId: v.id, image })))
                .reduce((prev, cur) => prev.concat(cur), []);
        },

        inventoryVehicleClass(): string | null {
            const vehicleClasses = (this.opportunityResult?.vehicleSummaries ?? [])
                .map((v) => v.vehicleClass)
                .filter((vehicleClass, index, array) => array.indexOf(vehicleClass) === index);

            if (vehicleClasses.length === 1) {
                return vehicleClasses[0];
            }

            return null;
        },

        isOpportunityInfoHidden(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            return (
                opportunitySettingsStore.hideOpportunityInfo &&
                getOpportunityStatus(this.opportunityObj) === OpportunityStatus.OPEN
            );
        },

        opportunityObj(): Opportunity | null {
            return this.opportunityResult?.opportunity ?? null;
        },

        rowClass(): string {
            if (!this.opportunityObj) {
                return "";
            }

            const darkModeClass = this.$vuetify.theme.dark ? "dark-mode" : "";

            if (this.opportunityObj.priority === OpportunityPriority.HIGH) {
                if (this.opportunityObj.urgency === OpportunityUrgency.PERSONAL_APPEARANCE) {
                    return `personal-appearance-urgency ${darkModeClass}`;
                } else {
                    return `high-priority ${darkModeClass}`;
                }
            } else if (this.opportunityObj.priority === OpportunityPriority.MEDIUM) {
                return `medium-priority ${darkModeClass}`;
            } else if (this.opportunityObj.priority === OpportunityPriority.LOW && !!this.unreadActivities) {
                return `low-priority-with-unread-activities ${darkModeClass}`;
            } else {
                return "";
            }
        },

        tableViewCellClass(): string {
            return `py-${this.verticalPadding} pl-${this.horizontalPadding} pr-0`;
        },

        tableViewLastCellClass(): string {
            return `${this.tableViewCellClass} pr-${this.horizontalPadding}`;
        },

        unreadActivities(): number | null {
            if (!this.opportunityObj || !this.opportunityUser) {
                return null;
            }

            return this.opportunityObj.activitiesCount - this.opportunityUser.activitiesRead;
        },

        verticalPadding(): number {
            return this.$vuetify.breakpoint.xsOnly ? 2 : this.compact ? 1 : 2;
        },

        isSingleReceiver(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            const visibleFor = this.opportunityObj.opportunityUsers
                .filter((u) => !u.visibilityEnd)
                .map((u) => u.userId);
            return visibleFor.length === 1 && visibleFor[0] === userSession.userId;
        },
    },

    methods: {
        async assignToCurrentUser() {
            if (!this.opportunityObj) {
                return;
            }

            this.assigning = true;
            try {
                const id = this.opportunityObj.id;
                await opportunitiesApi.assignToSelf(id);
                await this.$router.push(`/opportunity/${id}`);
            } finally {
                this.assigning = false;
            }
        },
    },

    components: {
        ContactInfo,
        InventoryVehicleImageAndCarousel,
        OpportunityGeneralInfo,
        OpportunityMainInfo,
        SelfAssignButton,
    },
});
