
import { Permission } from "@/api/userSession";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { SelectOptions } from "@/util/types";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
    props: {
        permission: {
            type: String as () => Permission,
            required: true,
        },
        container: {
            type: Boolean,
            default: false,
        },
        allowGlobal: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        alertType(): string {
            if (!this.dealerId && !this.allowGlobal) {
                return "warning";
            }
            return "error";
        },

        alertMessage(): TranslateResult {
            if (!this.dealerId && !this.allowGlobal) {
                return this.$t("Diese Seite steht nur mit einem ausgewählten Standort zur Verfügung.");
            }
            return this.$t("Sie haben nicht die Berechtigung, diese Seite mit dem ausgewählten Standort aufzurufen.");
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        dealerOptions(): SelectOptions {
            return dealersStore.dealers
                .filter((d) => d.active)
                .filter((d) => this.isDealerAllowed(d.id))
                .map((d) => ({ value: d.id, text: d.name }));
        },

        showAlert(): boolean {
            if (!this.dealerId && this.allowGlobal && userSession.hasPermission(this.permission)) {
                return false;
            }
            return !this.dealerId || !this.isDealerAllowed(this.dealerId);
        },

        showInactiveAlert(): boolean {
            return !this.showAlert && !!this.dealerId && !dealersStore.dealerById(this.dealerId!)!.active;
        },
    },

    methods: {
        isDealerAllowed(dealerId: string) {
            return userSession.hasPermission(this.permission) || !!userSession.profile?.dealerIds.includes(dealerId);
        },

        async selectDealer(dealerId: string) {
            await this.$router.replace({ query: { ...this.$route.query, dealerId } });
        },
    },
});
