
import VideochatContactEmailInvitationDialog from "./VideochatContactEmailInvitationDialog.vue";
import VideochatContactSmsInvitationDialog from "./VideochatContactSmsInvitationDialog.vue";
import VideochatQrCodeDialog from "./VideochatQrCodeDialog.vue";
import { Videochat, VideochatAccessToken, videochatApi } from "@/api/videochats";
import JitsiClient from "@/app/components/JitsiClient.vue";
import UserPickerDialog from "@/app/pages/users/UserPickerDialog.vue";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loadingVideochat: true,
            videochatId: "",
            videochat: null as Videochat | null,
            videochatAccessToken: null as VideochatAccessToken | null,
            qrCodeDialogVisible: false,
            inviteDialogVisible: false,
            contactSmsInvitationDialogVisible: false,
            contactEmailInvitationDialogVisible: false,
        };
    },

    computed: {
        videochatLink(): string {
            return `https://${configStore.configuration.appHost}/v/${this.videochatId}`;
        },

        userSessionFullName(): string {
            return userSession.fullName!;
        },
    },

    methods: {
        showQrCodeDialog() {
            this.qrCodeDialogVisible = true;
        },

        showInviteDialog() {
            this.inviteDialogVisible = true;
        },

        showContactSmsInvitationDialog() {
            this.contactSmsInvitationDialogVisible = true;
        },

        showContactEmailInvitationDialog() {
            this.contactEmailInvitationDialogVisible = true;
        },

        async inviteUser(userId: string | null) {
            this.inviteDialogVisible = false;
            if (userId) {
                await videochatApi.invite(this.videochatId, userId);
            }
        },

        async joined(displayName: string) {
            await videochatApi.addJoinEvent(this.videochatId, displayName);
        },

        async left() {
            await this.$router.push("/my-videochats");
        },
    },

    async mounted() {
        try {
            this.videochatId = this.$route.params.videochatid;
            this.videochat = await videochatApi.getById(this.videochatId);
            this.videochatAccessToken = await videochatApi.generateAccessToken(this.videochatId);
        } finally {
            this.loadingVideochat = false;
        }
    },

    components: {
        VideochatQrCodeDialog,
        VideochatContactSmsInvitationDialog,
        VideochatContactEmailInvitationDialog,
        JitsiClient,
        UserPickerDialog,
    },
});
