
import { OutgoingSmsFormCardState } from "./OutgoingSmsFormCard";
import { ContactData } from "@/api/contacts";
import { BadRequest } from "@/api/errors";
import { incomingPhoneNumbersApi } from "@/api/incomingPhoneNumbers";
import { smsSettingsApi } from "@/api/smsSettings";
import { SmsTemplate } from "@/api/smsTemplates";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { showInfo } from "@/app/messageUtil";
import OutgoingTextTemplatePickerButton from "@/app/pages/outgoingtexttemplates/OutgoingTextTemplatePickerButton.vue";
import { UrlWithKey } from "@/app/placeholderUtils";
import { notEmpty, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { getNumberOfMessages } from "@/util/smsUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        contactData: {
            type: Object as () => ContactData | null,
            required: false,
        },
        issueId: {
            type: String as () => string | null,
            default: null,
        },
        outgoingSmsFormCardState: {
            type: Object as () => OutgoingSmsFormCardState,
            required: true,
        },
        receiver: {
            type: String,
            required: false,
        },
        toNumbers: {
            type: Array as () => string[],
            default: () => [],
        },
        urls: {
            type: Array as () => UrlWithKey[],
            default: () => [],
        },
        working: {
            type: Boolean,
            required: true,
        },
        defaultText: {
            type: String,
            required: false,
        },
        defaultCountryProp: {
            type: String,
            required: false,
        },
        onSubmit: {
            type: Function,
            required: true,
        },
    },

    data() {
        const validationHelper = new ValidationHelper();
        return {
            defaultSender: null as string | null,
            isSelectionHandlerRegistered: false,
            loadingDefaultSender: true,
            maxMessageCount: 10,
            selectionStart: 0,
            selectionEnd: 0,
            smsCapableIncomingNumbers: [] as string[],
            toNumberRules: notEmpty()
                .e164()
                .and(validationHelper, "toNumber"),
            validationHelper,
        };
    },

    computed: {
        defaultCountry(): string {
            return this.defaultCountryProp || configStore.configuration.defaultCountry;
        },

        bodyMessages(): string[] | null {
            const messageCountMessage = this.$t("{count}/{max} Einzelnachrichten", {
                count: this.messageCount,
                max: this.maxMessageCount,
            });

            const component = this.$refs.messagebodyfield as any;

            if (!component || component.errorBucket.length) {
                return null;
            }

            return [messageCountMessage] as string[];
        },

        bodyRules(): Function[] {
            return [
                (v: string) => !!v.trim() || this.$t("Dieses Feld ist erforderlich."),
                () => this.messageCount <= this.maxMessageCount || this.$t("Die Nachricht ist zu lang."),
            ];
        },

        messageCount(): number {
            return getNumberOfMessages(this.outgoingSmsFormCardState.messageBody);
        },

        receiveCapability(): boolean {
            return !!this.smsCapableIncomingNumbers.find((n) => n === this.outgoingSmsFormCardState.from);
        },

        receiveCapabilityColor(): string | null {
            if (this.loadingDefaultSender) {
                return null;
            }

            if (!(this.$refs.defaultsenderfield as any).validate()) {
                return "error";
            }
            if (this.receiveCapability) {
                return "success";
            }
            return "warning";
        },

        receiveCapabilityHint(): string | null {
            if (this.loadingDefaultSender) {
                return null;
            }

            if (this.receiveCapability) {
                return this.$t("Antworten an diesen Absender sind möglich.") as string;
            } else {
                return this.$t("Antworten an diesen Absender sind nicht möglich.") as string;
            }
        },

        defaultSenderRules(): Function[] {
            return [
                (v: string) =>
                    !v ||
                    this.smsCapableIncomingNumbers.some((n) => n === v) ||
                    !!v.match(/^(?=.*[a-zA-Z])[0-9a-zA-Z]{1,11}$/) ||
                    this.$t("Diese Absenderkennung ist nicht gültig."),
            ];
        },
    },

    methods: {
        handleSelection(e: Event) {
            if (!e.target) {
                return;
            }

            const textarea = e.target as HTMLTextAreaElement;

            this.selectionStart = textarea.selectionStart;
            this.selectionEnd = textarea.selectionEnd;
        },

        insertTextFragment({ renderedContent }: { readonly template: SmsTemplate; readonly renderedContent: string }) {
            this.outgoingSmsFormCardState.messageBody =
                this.outgoingSmsFormCardState.messageBody.substr(0, this.selectionStart) +
                renderedContent +
                this.outgoingSmsFormCardState.messageBody.substr(this.selectionEnd);

            this.selectionStart = this.selectionStart + renderedContent.length;
            this.selectionEnd = this.selectionStart;
        },

        registerSelectionHandler() {
            const component = this.$refs.messagebodyfield as any;

            if (this.isSelectionHandlerRegistered || !component) {
                return;
            }

            const textarea = component.$refs.input as HTMLTextAreaElement;

            textarea.addEventListener("click", this.handleSelection);
            textarea.addEventListener("keyup", this.handleSelection);
            textarea.addEventListener("select", this.handleSelection);

            this.isSelectionHandlerRegistered = true;
        },

        setBodyToSmsTemplate({
            renderedContent,
        }: {
            readonly template: SmsTemplate;
            readonly renderedContent: string;
        }) {
            this.outgoingSmsFormCardState.messageBody = renderedContent;
            this.selectionStart = this.outgoingSmsFormCardState.messageBody.length;
            this.selectionEnd = this.selectionStart;
        },

        async submit() {
            if (!(this.$refs.smsform as any).validate()) {
                return;
            }

            try {
                await this.onSubmit(this.outgoingSmsFormCardState.outgoingSmsForm);
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    this.$nextTick(() => {
                        throw e;
                    });
                } else {
                    this.validationHelper.update(e, this.$refs.smsform);
                }
            }
        },
    },

    watch: {
        "outgoingSmsFormCardState.initialized"() {
            if (!this.outgoingSmsFormCardState.initialized) {
                this.outgoingSmsFormCardState.initialize(
                    userSession.profile?.smsSignature || null,
                    this.defaultSender,
                    this.receiver
                );
            }
        },

        receiver(val) {
            if (val) {
                this.outgoingSmsFormCardState.toNumber = val;
            }
        },
    },

    async mounted() {
        this.loadingDefaultSender = true;
        try {
            this.defaultSender = (await smsSettingsApi.getSmsSettings()).defaultSender;
            this.smsCapableIncomingNumbers = (await incomingPhoneNumbersApi.getAllInternal())
                .filter((i) => i.smsCapability)
                .map((i) => i.number);
        } finally {
            this.loadingDefaultSender = false;
        }

        if (!this.defaultSender) {
            showInfo(
                this.$t(
                    "Es ist kein Standard Absender für SMS festgelegt. Bitte wenden Sie sich an Ihren System-Administrator."
                ) as string
            );
            this.$emit("close");
        }

        if (!this.outgoingSmsFormCardState.initialized) {
            this.outgoingSmsFormCardState.initialize(
                userSession.profile?.smsSignature || null,
                this.defaultSender,
                this.receiver
            );
        }

        if (this.defaultText) {
            this.outgoingSmsFormCardState.messageBody = this.defaultText;
        }
    },

    components: {
        OutgoingTextTemplatePickerButton,
        PhoneNumberField,
    },
});
