import { ContactSearchRequest } from "@/api/contactSearch";
import { ContactData, ContactVehicleData } from "@/api/contacts";
import { EmergencyIncidentType } from "@/api/emergencyIncidents";
import { ContactAndVehicleId } from "@/app/contactUtils";
import { EMPTY_SEARCH_REQUEST } from "@/app/pages/contacts/contactSearchUtils";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { reactive } from "@/util/reactive";

const DEFAULT_EMERGENCY_INCIDENT_TYPE = EmergencyIncidentType.BREAKDOWN;

export interface EmergencyIncidentAssistantState {
    dealerId: string | null;
    type: EmergencyIncidentType;
    contactNumber: string | null;
    costIndicationGiven: boolean | null;
    caller: string | null;
    externalIncidentNumber: string | null;
    damage: string | null;
    location: string | null;
    serviceProvider: string | null;
    towingTarget: string | null;
    initialNoteContent: string;
    contactAndVehicleId: ContactAndVehicleId | null;
    contactSearchRequest: ContactSearchRequest;
    contactData: ContactData | null;
    contactVehicleData: ContactVehicleData | null;

    reset(contactId: string | null, contactVehicleId: string | null): void;
}

@reactive
class EmergencyIncidentAssistantStateImpl implements EmergencyIncidentAssistantState {
    dealerId: string | null = null;
    type: EmergencyIncidentType = DEFAULT_EMERGENCY_INCIDENT_TYPE;
    contactNumber: string | null = null;
    costIndicationGiven: boolean | null = null;
    caller: string | null = null;
    externalIncidentNumber: string | null = null;
    damage: string | null = null;
    location: string | null = null;
    serviceProvider: string | null = null;
    towingTarget: string | null = null;
    initialNoteContent = "";
    contactAndVehicleId: ContactAndVehicleId | null = null;
    contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
    contactData: ContactData | null = null;
    contactVehicleData: ContactVehicleData | null = null;

    reset(contactId: string | null, contactVehicleId: string | null) {
        this.contactData = contactId !== this.contactAndVehicleId?.contactId ? null : this.contactData;
        this.contactVehicleData =
            contactVehicleId !== this.contactAndVehicleId?.contactVehicleId ? null : this.contactVehicleData;
        this.contactAndVehicleId = contactId ? { contactId, contactVehicleId } : null;
        this.contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);
        this.dealerId = userSession.dealerId;
        this.type = DEFAULT_EMERGENCY_INCIDENT_TYPE;
        this.contactNumber = null;
        this.costIndicationGiven = null;
        this.caller = null;
        this.externalIncidentNumber = null;
        this.damage = null;
        this.location = null;
        this.serviceProvider = null;
        this.towingTarget = null;
        this.initialNoteContent = "";
    }
}

export const emergencyIncidentAssistantState = new EmergencyIncidentAssistantStateImpl();
