
import FinanceContractDataTable from "./FinanceContractDataTable.vue";
import FinanceContractUploadSourceFormDialog from "./FinanceContractUploadSourceFormDialog.vue";
import {
    ExternalFinanceContractParsed,
    FinanceContractUploadParseResult,
    financeContractUploadsApi,
    FinanceContractUploadSource,
} from "@/api/financeContractUploads";
import { fileDialog } from "@/app/fileUtils";
import { showConfirm } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

interface FinanceContractUploadSourceWithFile extends FinanceContractUploadSource {
    applyInProgress: boolean;
    analyzeInProgress: boolean;
    uploadInProgress: boolean;
    progress: number;
    analyzed: boolean;
    financeContractUploadParseResult: FinanceContractUploadParseResult | null;
}

export default Vue.extend({
    data() {
        return {
            financeContractUploadSourceFormDialogVisible: false,
            financeContractUploadSourceFormDialogItem: null as FinanceContractUploadSource | null,
            items: [] as FinanceContractUploadSourceWithFile[],
            loading: true,
            previewDialogVisible: false,
            previewData: [] as ExternalFinanceContractParsed[],
            previewTotalSize: 0,
        };
    },

    methods: {
        formatDate(date: Date) {
            return formatInstant(date, userSession.timeZone, userSession.locale, "L");
        },

        async loadItems() {
            this.loading = true;

            try {
                this.items = (await financeContractUploadsApi.getAllFinanceContractUploadSources()).map((v) => ({
                    ...v,
                    applyInProgress: false,
                    analyzeInProgress: false,
                    uploadInProgress: false,
                    progress: 0,
                    analyzed: false,
                    financeContractUploadParseResult: null,
                }));
            } finally {
                this.loading = false;
            }
        },

        async analyzeFile(itemId: string) {
            const item = this.items.find((i) => i.id === itemId);
            if (!item) {
                return;
            }

            item.analyzed = false;
            item.analyzeInProgress = true;

            try {
                item.financeContractUploadParseResult = Object.freeze(
                    await financeContractUploadsApi.parseFinanceContracts(item.latestFinanceContractUpload?.id!, 1000)
                );
                item.analyzed = true;
            } finally {
                item.analyzeInProgress = false;
            }
        },

        showPreviewDialog(item: FinanceContractUploadSourceWithFile) {
            this.previewData = item.financeContractUploadParseResult!.financeContracts;
            this.previewTotalSize = item.financeContractUploadParseResult!.total;
            this.previewDialogVisible = true;
        },

        async applyFile(item: FinanceContractUploadSourceWithFile) {
            item.applyInProgress = true;
            try {
                await financeContractUploadsApi.applyFinanceContracts(item.latestFinanceContractUpload?.id!);
                await this.loadItems();
            } finally {
                item.applyInProgress = false;
            }
        },

        uploadFile(item: FinanceContractUploadSourceWithFile) {
            fileDialog(
                false,
                async (files: File[]) => {
                    await this.fileSelected(files[0], item);
                    await this.analyzeFile(item.id);
                },
                [".csv", ".zip"]
            );
        },

        async fileSelected(file: File, item: FinanceContractUploadSourceWithFile) {
            item.uploadInProgress = true;

            try {
                await financeContractUploadsApi.uploadFinanceContracts(
                    item.id,
                    file,
                    ({ total, loaded }) => (item.progress = (100 * loaded) / total)
                );

                await this.loadItems();
            } finally {
                item.uploadInProgress = false;
            }
        },

        showCreateForm() {
            this.financeContractUploadSourceFormDialogItem = null;
            this.financeContractUploadSourceFormDialogVisible = true;
        },

        showEditForm(item: FinanceContractUploadSourceWithFile) {
            this.financeContractUploadSourceFormDialogItem = item;
            this.financeContractUploadSourceFormDialogVisible = true;
        },

        async deleteItem(item: FinanceContractUploadSourceWithFile) {
            if (
                await showConfirm(
                    this.$t("Bankdatenquelle löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Bankdatenquelle {0} löschen möchten?", [item.name]) as string
                )
            ) {
                await financeContractUploadsApi.deleteFinanceContractUploadSource(item.id);
                await this.loadItems();
            }
        },

        downloadFinanceContractUpload(financeContractUploadId: string) {
            window.open(financeContractUploadsApi.generateFinanceContractUploadDownloadLink(financeContractUploadId));
        },

        async savedItem() {
            this.financeContractUploadSourceFormDialogVisible = false;
            await this.loadItems();
        },

        getDealerName(dealerId: string): string {
            for (const dealer of dealersStore.dealers) {
                if (dealer.id === dealerId) {
                    return dealer.name;
                }
            }

            return "";
        },

        getOpportunitySourceNameById(opportunitySourceId: string) {
            return opportunitySourcesStore.getOpportunitySourceById(opportunitySourceId)!.name;
        },

        getUserFullNameById(userId: string) {
            return getFullName(usersStore.getUserById(userId));
        },

        getOpportunityTeamNameById(opportunityTeamId: string) {
            const team = opportunityTeamsStore.getOpportunityTeamById(opportunityTeamId)!;
            return `${team.name} (${dealersStore.dealerById(team.dealerId)!.name})`;
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        FinanceContractUploadSourceFormDialog,
        FinanceContractDataTable,
        DealerGlobalContextHint,
    },
});
