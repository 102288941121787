import axios from "axios";

export interface FileSettings {
    onlyWhitelistedIncomingFileExtensions: boolean;
    onlyWhitelistedOutgoingFileExtensions: boolean;
}

interface FileSettingsApi {
    getFileSettings(): Promise<FileSettings>;
    editFileSettings(fileSettings: FileSettings): Promise<void>;
}

export const fileSettingsApi: FileSettingsApi = {
    async getFileSettings() {
        return (await axios.get("/api/file-settings")).data;
    },

    async editFileSettings(fileSettings) {
        await axios.put("/api/file-settings", fileSettings);
    },
};
