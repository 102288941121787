
import GeneralInfoFormCard from "./GeneralInfoFormCard.vue";
import { Case } from "@/api/cases";
import UserLink from "@/app/pages/UserLink.vue";
import { getSentimentText } from "@/app/sentimentUtils";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseProp: {
            type: Object as () => Case,
            required: true,
        },

        canEdit: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            generalInfoFormVisible: false,
        };
    },

    computed: {
        creator(): string {
            if (this.caseProp.creatorCtUserId) {
                return this.$t("Externer Agent") as string;
            }
            return this.$t("System") as string;
        },

        sentimentText(): string | null {
            return getSentimentText(this.caseProp.sentiment);
        },
    },

    methods: {
        showEditForm() {
            this.generalInfoFormVisible = true;
        },

        closeEditForm() {
            this.generalInfoFormVisible = false;
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },
    },

    components: {
        UserLink,
        GeneralInfoFormCard,
    },
});
