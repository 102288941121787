import { officeHoursStore } from "./officeHours";
import { userSession } from "./userSession";
import {
    isOpportunitySettingsChangedNotification,
    isOpportunitySettingsExceptionalOutcomeReasonDeletedNotification,
    isOpportunitySettingsExceptionalOutcomeReasonsChangedNotification,
    isOpportunitySettingsExceptionalUserDeletedNotification,
    isOpportunitySettingsExceptionalUsersChangedNotification,
    isOpportunityUserAssignmentLockUpdatedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { OfficeHours } from "@/api/officeHours";
import { opportunitiesApi } from "@/api/opportunities";
import {
    OpportunitySettings,
    opportunitySettingsApi,
    OpportunitySettingsExceptionalUser,
} from "@/api/opportunitySettings";
import { Permission } from "@/api/userSession";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { removeMatchingItems, updateItem } from "@/util/storeUtils";

@reactive
class OpportunitySettingsStore {
    private exceptionalUsers: OpportunitySettingsExceptionalUser[] = [];
    private lockedUntil_: Date | null = null;
    private opportunitySettings: OpportunitySettings[] = [];
    private outcomeReasonsToIgnoreInAssignmentLock_: string[] = [];
    private refreshLimiter = new ActionLimiter(true);

    get hideOpportunityInfo(): boolean {
        return (
            !!this.opportunitySettings.find((s) => s.dealerId === userSession.mainDealerId)?.hideOpportunityInfo &&
            !userSession.isCtUser() &&
            !userSession.hasPermission(Permission.MANAGE_ALL_OPPORTUNITIES) &&
            !this.exceptionalUsers.some((u) => u.userId === userSession.userId && u.canSeeHiddenOpportunityData)
        );
    }

    get lockedUntil(): Date | null {
        const canAssignWithoutTimeout = this.exceptionalUsers.some(
            (e) => e.userId === userSession.userId && e.canAssignWithoutTimeout
        );

        return !canAssignWithoutTimeout ? this.lockedUntil_ : null;
    }

    get assignmentHours(): OfficeHours | null {
        const canAssignAllHours = this.exceptionalUsers.some(
            (e) => e.userId === userSession.userId && e.canAssignAllHours
        );

        const opportunitySettings = this.opportunitySettings.find((s) => s.dealerId === userSession.mainDealerId);

        if (canAssignAllHours || !opportunitySettings?.assignmentHoursId) {
            return null;
        }

        return officeHoursStore.getOfficeHoursById(opportunitySettings.assignmentHoursId);
    }

    get outcomeReasonsToIgnoreInAssignmentLock(): string[] {
        return this.outcomeReasonsToIgnoreInAssignmentLock_;
    }

    outcomeReasonMandatory(dealerId: string): boolean {
        return this.opportunitySettings.find((s) => s.dealerId === dealerId)?.outcomeReasonMandatory || false;
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.lockedUntil_ = await opportunitiesApi.getLockedUntil();
            this.outcomeReasonsToIgnoreInAssignmentLock_ = await opportunitySettingsApi.getAllOutcomeReasonsToIgnoreInAssignmentLock();
            this.exceptionalUsers = await opportunitySettingsApi.getExceptionalUsers();
            this.opportunitySettings = await opportunitySettingsApi.getAllOpportunitySettings();
        });
    }

    handleNotification(n: Notification) {
        if (isOpportunitySettingsChangedNotification(n)) {
            updateItem(
                this.opportunitySettings,
                n.opportunitySettings,
                (o) => o.dealerId === n.opportunitySettings.dealerId
            );
        } else if (isOpportunitySettingsExceptionalOutcomeReasonsChangedNotification(n)) {
            this.outcomeReasonsToIgnoreInAssignmentLock_ = n.outcomeReasonIds;
        } else if (isOpportunitySettingsExceptionalOutcomeReasonDeletedNotification(n)) {
            removeMatchingItems(
                this.outcomeReasonsToIgnoreInAssignmentLock_,
                (outcomeReasonId) => outcomeReasonId === n.outcomeReasonId
            );
        } else if (isOpportunitySettingsExceptionalUserDeletedNotification(n)) {
            removeMatchingItems(this.exceptionalUsers, (e) => e.userId === n.userId);
        } else if (isOpportunitySettingsExceptionalUsersChangedNotification(n)) {
            this.exceptionalUsers = n.exceptionalUsers;
        } else if (isOpportunityUserAssignmentLockUpdatedNotification(n)) {
            this.lockedUntil_ = n.lockedUntil;
        }
    }
}

export const opportunitySettingsStore = new OpportunitySettingsStore();

notificationEventSource.addDataHandler((n) => opportunitySettingsStore.handleNotification(n));
