import axios from "axios";

export interface OpportunitySettingsExceptionalUser {
    readonly userId: string;
    canAssignAllHours: boolean;
    canAssignWithoutTimeout: boolean;
    canSeeHiddenOpportunityData: boolean;
}

export interface OpportunitySettings {
    readonly dealerId: string;
    readonly assignmentHoursId: string | null;
    readonly hideOpportunityInfo: boolean;
    readonly lockTimeoutMinutes: number | null;
    readonly outcomeReasonMandatory: boolean;
}

export interface OpportunitySettingsForm {
    readonly assignmentHoursId: string | null;
    readonly hideOpportunityInfo: boolean;
    readonly lockTimeoutMinutes: number | null;
    readonly outcomeReasonMandatory: boolean;
}

interface OpportunitySettingsApi {
    getAllOpportunitySettings(): Promise<OpportunitySettings[]>;
    getOpportunitySettings(dealerId: string): Promise<OpportunitySettings>;
    editOpportunitySettings(dealerId: string, opportunitySettingsForm: OpportunitySettingsForm): Promise<void>;
    getExceptionalUsers(): Promise<OpportunitySettingsExceptionalUser[]>;
    editExceptionalUsers(users: OpportunitySettingsExceptionalUser[]): Promise<void>;
    editOutcomeReasonsToIgnoreInAssignmentLock(outcomeReasonIds: string[]): Promise<void>;
    getAllOutcomeReasonsToIgnoreInAssignmentLock(): Promise<string[]>;
}

export const opportunitySettingsApi: OpportunitySettingsApi = {
    async getAllOpportunitySettings() {
        return (await axios.get("/api/opportunity-settings")).data;
    },

    async getOpportunitySettings(dealerId) {
        return (await axios.get(`/api/opportunity-settings/${dealerId}`)).data;
    },

    async editOpportunitySettings(dealerId, opportunitySettingsForm: OpportunitySettingsForm) {
        await axios.put(`/api/opportunity-settings/${dealerId}`, opportunitySettingsForm);
    },

    async getExceptionalUsers() {
        return (await axios.get("/api/opportunity-settings/_/exceptional-users")).data;
    },

    async editExceptionalUsers(users) {
        await axios.put("/api/opportunity-settings/_/exceptional-users", users);
    },

    async editOutcomeReasonsToIgnoreInAssignmentLock(outcomeReasonIds) {
        await axios.put("/api/opportunity-settings/_/outcome-reasons-to-ignore-in-assignment-lock", outcomeReasonIds);
    },

    async getAllOutcomeReasonsToIgnoreInAssignmentLock() {
        return (await axios.get("/api/opportunity-settings/_/outcome-reasons-to-ignore-in-assignment-lock")).data;
    },
};
