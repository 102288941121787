
import { canAddNote } from "./incomingCallUtils";
import { Contact } from "@/api/contacts";
import { IncomingCallResult, IncomingCallResultAndContact, incomingCallsApi } from "@/api/incomingCalls";
import { isIncomingCallUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { renderContactCaption } from "@/app/contactUtils";
import { editModeState } from "@/app/editModeUtils";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        incomingCallAndContact: {
            type: Object as () => IncomingCallResultAndContact,
            required: true,
        },
    },

    data() {
        return {
            note: "",
            noteFormVisible: false,
            notEmpty,
            notificationHandler: null as ((n: Notification) => void) | null,
            incomingCallAndContactProp: this.incomingCallAndContact,
            parseAndFormatNumber,
            savingNote: false,
        };
    },

    computed: {
        canAddNote(): boolean {
            return canAddNote(this.incomingCall);
        },

        contact(): Contact | null {
            return this.incomingCallAndContactProp.contact;
        },

        contactCaption(): string | null {
            if (!this.contact) {
                return null;
            }

            return renderContactCaption(this.contact.contactData);
        },

        isContactDeleted(): boolean {
            return !!this.contact?.deleted;
        },

        incomingCall(): IncomingCallResult {
            return this.incomingCallAndContactProp.incomingCallResult;
        },
    },

    methods: {
        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        hideNoteForm() {
            this.noteFormVisible = false;
            editModeState.leave();
        },

        async saveNote() {
            if (!(this.$refs.noteForm as any).validate()) {
                return;
            }

            this.savingNote = true;
            try {
                await incomingCallsApi.setNote(this.incomingCall.id, this.note);
            } finally {
                this.noteFormVisible = false;
                this.savingNote = false;
                editModeState.leave();
            }
        },

        showNoteForm() {
            if (this.noteFormVisible) {
                return;
            }

            this.note = "";
            this.noteFormVisible = true;
            editModeState.enter();
        },
    },

    watch: {
        incomingCallAndContact() {
            this.incomingCallAndContactProp = this.incomingCallAndContact;
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isIncomingCallUpdatedNotification(n)) {
                if (n.incomingCall.id === this.incomingCall.id) {
                    this.incomingCallAndContactProp = {
                        ...this.incomingCallAndContactProp,
                        incomingCallResult: n.incomingCall,
                    };
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        TimeSpan,
    },
});
