
import MyIncomingCallsDataTable from "@/app/pages/incomingcalls/MyIncomingCallsDataTable.vue";
import MyNotesDataTable from "@/app/pages/notes/MyNotesDataTable.vue";
import MyOutgoingCallsDataTable from "@/app/pages/outgoingcalls/MyOutgoingCallsDataTable.vue";
import MyOutgoingEmailsDataTable from "@/app/pages/outgoingemails/MyOutgoingEmailsDataTable.vue";
import MyOutgoingSmsDataTable from "@/app/pages/outgoingsms/MyOutgoingSmsDataTable.vue";
import MyOutgoingWhatsAppsDataTable from "@/app/pages/outgoingwhatsapp/MyOutgoingWhatsAppsDataTable.vue";
import Vue from "vue";

export default Vue.extend({
    computed: {
        activeTab: {
            get(): number {
                return parseInt((this.$route.query.t as string) || "0");
            },
            async set(tab: number) {
                try {
                    await this.$router.push({ query: { ...this.$route.query, t: tab.toString() } });
                } catch (e) {
                    // handle async exception in vue event loop
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    components: {
        MyIncomingCallsDataTable,
        MyNotesDataTable,
        MyOutgoingCallsDataTable,
        MyOutgoingEmailsDataTable,
        MyOutgoingSmsDataTable,
        MyOutgoingWhatsAppsDataTable,
    },
});
