
import { CallerId, callerIdsApi } from "@/api/callerIds";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    computed: {
        api() {
            return callerIdsApi;
        },
        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },
        notEmpty() {
            return notEmpty;
        },
    },

    methods: {
        async deleteCallerId(phoneNumber: string) {
            if (
                await showConfirm(
                    this.$t("Anruferkennung löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Anruferkennung löschen möchten?") as string
                )
            ) {
                await callerIdsApi.deleteCallerId(phoneNumber);
                return true;
            }
            return false;
        },

        async addCallerId(callerId: CallerId) {
            for (;;) {
                const validationCode = await callerIdsApi.addCallerId(callerId);
                if (!validationCode) {
                    return true;
                }
                if (
                    !(await showConfirm(
                        `${this.$t("Validierungscode")}: ${validationCode.code}`,
                        this.$t("Drücken Sie OK nachdem die Anruferkennung validiert wurde.") as string
                    ))
                ) {
                    return false;
                }
            }
        },

        emptyForm(): CallerId {
            return { phoneNumber: "", internal: false };
        },
    },

    components: {
        CrudPage,
        PhoneNumberField,
    },
});
