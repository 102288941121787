
import EmergencyIncidentsDataTable from "./EmergencyIncidentsDataTable.vue";
import { EmergencyIncidentSearchRequest } from "@/api/emergencyIncidentSearch";
import Vue from "vue";

export default Vue.extend({
    props: {
        itemsPerPage: {
            type: Number,
            default: 25,
        },
        searchRequest: {
            type: Object as () => EmergencyIncidentSearchRequest,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
    },

    components: {
        EmergencyIncidentsDataTable,
    },
});
