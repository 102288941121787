
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import { fileDialog } from "@/app/fileUtils";
import { showInfo } from "@/app/messageUtil";
import { notEmpty } from "@/app/validation";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

enum AnnouncementType {
    AUDIO_FILE,
    TEXT_TO_SPEECH,
}

export interface Announcement {
    readonly audioHash: string | null;
    readonly audioFile: File | null;
    readonly text: string | null;
}

export type GenerateAnnouncementLink = (audioHash: string) => string | null;

export default Vue.extend({
    props: {
        announcement: {
            type: Object as () => Announcement,
            required: true,
        },
        generateAnnouncementLink: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            notEmpty,

            AnnouncementType,

            announcementAudioFileUrl: null as string | null,
            announcementType: (this.announcement.audioHash || !this.announcement.text
                ? AnnouncementType.AUDIO_FILE
                : AnnouncementType.TEXT_TO_SPEECH) as AnnouncementType,
            objectUrls: [] as string[],
        };
    },

    computed: {
        announcementLink(): string | null {
            if (!this.announcement.audioHash) {
                return null;
            }

            return (this.generateAnnouncementLink as GenerateAnnouncementLink)(this.announcement.audioHash);
        },

        announcementAudioRules(): Function[] {
            return [() => this.hasAudioAnnouncement || this.$t("Dieses Feld ist erforderlich.")];
        },

        hasAudioAnnouncement(): boolean {
            return !!this.announcementLink || !!this.announcement.audioFile;
        },

        hasTextAnnouncement(): boolean {
            return !!trimAndReturnNullIfEmpty(this.announcement.text);
        },
    },

    methods: {
        selectAnnouncementFile() {
            const acceptedAudioFileTypes = [
                "audio/mpeg",
                "audio/wav",
                "audio/wave",
                "audio/x-wav",
                "audio/aiff",
                "audio/x-aifc",
                "audio/x-aiff",
                "audio/x-gsm",
            ];

            fileDialog(
                false,
                (files) => {
                    if (!acceptedAudioFileTypes.includes(files[0].type)) {
                        showInfo(this.$t("Das Format der ausgewählten Datei wird nicht unterstützt.") as string);
                        return;
                    }

                    const announcement: Announcement = {
                        audioHash: null,
                        audioFile: files[0],
                        text: null,
                    };

                    this.$emit("input", announcement);
                },
                ["audio/*"]
            );
        },

        handleAnnouncementTypeInput(announcementType: AnnouncementType) {
            this.announcementType = announcementType;

            if (announcementType === AnnouncementType.AUDIO_FILE) {
                this.$emit("input", {
                    ...this.announcement,
                    text: null,
                });
            } else {
                this.$emit("input", {
                    ...this.announcement,
                    audioHash: null,
                    audioFile: null,
                });
            }
        },

        handleTextInput(text: string | null | undefined) {
            const announcement: Announcement = {
                audioHash: null,
                audioFile: null,
                text: text ?? null,
            };

            this.$emit("input", announcement);
        },

        unselectAnnouncement() {
            const announcement: Announcement = {
                audioHash: null,
                audioFile: null,
                text: null,
            };

            this.$emit("input", announcement);
        },
    },

    watch: {
        "announcement.audioFile": {
            immediate: true,
            handler(audioFile: File | null, oldAudioFile: File | null) {
                if (audioFile === oldAudioFile) {
                    return;
                }

                if (!audioFile) {
                    this.announcementAudioFileUrl = null;
                    return;
                }

                this.announcementAudioFileUrl = URL.createObjectURL(audioFile);
            },
        },
    },

    destroyed() {
        for (const objectUrl of this.objectUrls) {
            URL.revokeObjectURL(objectUrl);
        }
    },

    components: {
        DAutocomplete,
    },
});
