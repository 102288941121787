import axios from "axios";

export interface CarryDispoLocation {
    readonly id: string;
    readonly name: string;
    readonly description: string | null;
    readonly street: string | null;
    readonly houseNumber: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
}

export interface CarryDispoLocationForm {
    readonly name: string;
    readonly description: string | null;
    readonly street: string | null;
    readonly houseNumber: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
}

export interface CarryDispoSettings {
    readonly baseUrl: string | null;
    readonly username: string | null;
    readonly password: string | null;
}

interface CarryDispoSettingsApi {
    addCarryDispoLocation(dealerId: string, carryDispoLocationForm: CarryDispoLocationForm): Promise<void>;
    deleteCarryDispoLocation(id: string): Promise<void>;
    editCarryDispoLocation(id: string, carryDispoLocationForm: CarryDispoLocationForm): Promise<void>;
    editCarryDispoSettings(dealerId: string, carryDispoSettings: CarryDispoSettings): Promise<void>;
    getBaseUrl(dealerId: string): Promise<string>;
    getCarryDispoLocationsByDealer(dealerId: string): Promise<CarryDispoLocation[]>;
    getCarryDispoSettingsByDealer(dealerId: string): Promise<CarryDispoSettings>;
    isEndpointConfigured(dealerId: string): Promise<boolean>;
}

export const carryDispoSettingsApi: CarryDispoSettingsApi = {
    async addCarryDispoLocation(dealerId, carryDispoLocationForm): Promise<void> {
        await axios.post(`/api/carry-dispo-settings/${dealerId}/locations`, carryDispoLocationForm);
    },

    async deleteCarryDispoLocation(id): Promise<void> {
        await axios.delete(`/api/carry-dispo-settings/_/locations/${id}`);
    },

    async editCarryDispoLocation(id, carryDispoLocationForm): Promise<void> {
        await axios.put(`/api/carry-dispo-settings/_/locations/${id}`, carryDispoLocationForm);
    },

    async editCarryDispoSettings(dealerId, carryDispoSettings): Promise<void> {
        await axios.put(`/api/carry-dispo-settings/${dealerId}`, carryDispoSettings);
    },

    async getBaseUrl(dealerId): Promise<string> {
        return (await axios.get(`/api/carry-dispo-settings/${dealerId}/base-url`)).data;
    },

    async getCarryDispoLocationsByDealer(dealerId): Promise<CarryDispoLocation[]> {
        return (await axios.get(`/api/carry-dispo-settings/${dealerId}/locations`)).data;
    },

    async getCarryDispoSettingsByDealer(dealerId): Promise<CarryDispoSettings> {
        return (await axios.get(`/api/carry-dispo-settings/${dealerId}`)).data;
    },

    async isEndpointConfigured(dealerId): Promise<boolean> {
        return (await axios.get(`/api/carry-dispo-settings/${dealerId}/configured`)).data;
    },
};
