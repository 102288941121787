
import { FileSettings, fileSettingsApi } from "@/api/fileSettings";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            saving: false,
            fileSettings: {} as FileSettings,
        };
    },

    methods: {
        async updateFileSettings() {
            this.saving = true;
            try {
                await fileSettingsApi.editFileSettings(this.fileSettings);
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        try {
            this.fileSettings = await fileSettingsApi.getFileSettings();
        } finally {
            this.loading = false;
        }
    },

    components: {
        DealerGlobalContextHint,
    },
});
