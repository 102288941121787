import { Dealer } from "@/api/dealers";
import { EmailAlias, Gender, PhoneNumber } from "@/api/users";
import { ContactNameData } from "@/app/contactUtils";
import {
    contactPlaceholders,
    contactSalutationPlaceholders,
    dealerPlaceholders,
    renderContactPlaceholders,
    renderContactSalutationPlaceholders,
    renderDealerPlaceholders,
    renderLinkPlaceholders,
    renderRequestedVehiclePlaceholders,
    renderUserPlaceholders,
    RequestedVehiclePlaceholderInfo,
    requestedVehiclePlaceholders,
    UrlWithKey,
    userPlaceholders,
} from "@/app/placeholderUtils";

export const emailPlaceholders = [
    ...contactSalutationPlaceholders,
    ...contactPlaceholders,
    ...dealerPlaceholders,
    ...userPlaceholders,
    "USER_SIGNATURE",
    ...requestedVehiclePlaceholders,
    "EMAIL_QUOTE",
    "ISSUE_ID",
];

interface UserEmailPlaceholderInfo {
    readonly username: string | null;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly languages: string[];
    readonly phoneNumbers: PhoneNumber[];
    readonly emailAliases: EmailAlias[];
    readonly emailSignature: string | null;
}

export function renderEmailPlaceholders(
    content: string,
    dealer: Dealer | null,
    locale: string,
    contactNameData: ContactNameData,
    user: UserEmailPlaceholderInfo,
    requestedVehicle: RequestedVehiclePlaceholderInfo,
    urls: UrlWithKey[],
    quote: string,
    issueId: string | null
) {
    return [
        (content: string) => renderContactSalutationPlaceholders(content, contactNameData, locale),
        (content: string) => renderContactPlaceholders(content, contactNameData, locale),
        (content: string) => renderDealerPlaceholders(content, dealer),
        (content: string) => renderUserPlaceholders(content, user, locale),
        (content: string) => content.replace(/%USER_SIGNATURE%/g, user.emailSignature || ""),
        (content: string) => renderRequestedVehiclePlaceholders(content, requestedVehicle),
        (content: string) => renderLinkPlaceholders(content, urls, true),
        (content: string) => content.replace(/%EMAIL_QUOTE%/g, quote),
        (content: string) => content.replace(/%ISSUE_ID%/g, issueId || ""),
    ].reduce((content, fn) => fn(content), content);
}
