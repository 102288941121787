import { Contact, ContactVehicle } from "./contacts";
import { NoteForm } from "./notes";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum EmergencyIncidentType {
    BREAKDOWN = "BREAKDOWN",
    ACCIDENT = "ACCIDENT",
}

export interface EmergencyIncident {
    readonly id: string;
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly creatorCtUserId: string | null;
    readonly updated: Date;
    readonly closed: Date | null;
    readonly closerUserId: string | null;
    readonly closerCtUserId: string | null;
    readonly dealerId: string;
    readonly type: EmergencyIncidentType;
    readonly contactId: string;
    readonly contactVehicleId: string;
    readonly lastActivity: Date | null;
    readonly activitiesCount: number;
    readonly contactNumber: string | null;
    readonly costIndicationGiven: boolean | null;
    readonly caller: string | null;
    readonly externalIncidentNumber: string | null;
    readonly damage: string | null;
    readonly location: string | null;
    readonly serviceProvider: string | null;
    readonly towingTarget: string | null;
}

export interface EmergencyIncidentCreationForm {
    readonly dealerId: string;
    readonly type: EmergencyIncidentType;
    readonly contactId: string;
    readonly contactVehicleId: string;
    readonly contactNumber: string | null;
    readonly costIndicationGiven: boolean | null;
    readonly caller: string | null;
    readonly externalIncidentNumber: string | null;
    readonly damage: string | null;
    readonly location: string | null;
    readonly serviceProvider: string | null;
    readonly towingTarget: string | null;
}

export interface EmergencyIncidentContactVehicleForm {
    readonly contactId: string;
    readonly contactVehicleId: string;
}

export interface EmergencyIncidentResult {
    readonly emergencyIncident: EmergencyIncident;
    readonly contact: Contact | null;
    readonly contactVehicle: ContactVehicle | null;
}

export interface GeneralInfoForm {
    readonly dealerId: string;
    readonly type: EmergencyIncidentType;
    readonly contactNumber: string | null;
    readonly costIndicationGiven: boolean | null;
    readonly caller: string | null;
    readonly externalIncidentNumber: string | null;
    readonly damage: string | null;
    readonly location: string | null;
    readonly serviceProvider: string | null;
    readonly towingTarget: string | null;
}

export interface UserEmergencyIncidentStatus {
    readonly openEmergencyIncidents: number;
    readonly openEmergencyIncidentsMainDealer: number;
}

interface EmergencyIncidentsApi {
    addEmergencyIncident(
        emergencyIncidentCreationForm: EmergencyIncidentCreationForm,
        initialNoteContent: string
    ): Promise<string>;
    addNote(id: string, noteForm: NoteForm): Promise<void>;
    closeEmergencyIncident(id: string): Promise<void>;
    editContactAndVehicle(
        id: string,
        emergencyIncidentContactVehicleForm: EmergencyIncidentContactVehicleForm
    ): Promise<void>;
    editGeneralInfo(id: string, generalInfoForm: GeneralInfoForm): Promise<void>;
    getById(id: string): Promise<EmergencyIncidentResult | null>;
    getByIds(ids: string[]): Promise<EmergencyIncidentResult[]>;
    getMyEmergencyIncidentsStatus(): Promise<UserEmergencyIncidentStatus>;
}

export const emergencyIncidentsApi: EmergencyIncidentsApi = {
    async addEmergencyIncident(emergencyIncidentCreationForm, initialNoteContent) {
        const formData = new FormData();

        formData.append(
            "form",
            new Blob([JSON.stringify(emergencyIncidentCreationForm)], { type: "application/json" })
        );
        formData.append("initialNoteContent", initialNoteContent);

        return (await axios.post("/api/emergency-incidents", formData)).data;
    },

    async addNote(id, noteForm) {
        await axios.post(`/api/emergency-incidents/${id}/_add-note`, noteForm);
    },

    async closeEmergencyIncident(id) {
        await axios.post(`/api/emergency-incidents/${id}/_close`);
    },

    async editContactAndVehicle(id, emergencyIncidentContactVehicleForm) {
        await axios.post(
            `/api/emergency-incidents/${id}/_edit-contact-and-vehicle`,
            emergencyIncidentContactVehicleForm
        );
    },

    async editGeneralInfo(id, generalInfoForm) {
        await axios.post(`/api/emergency-incidents/${id}/_edit-general-info`, generalInfoForm);
    },

    async getById(id) {
        const result: EmergencyIncidentResult = (await axios.get(`/api/emergency-incidents/${id}`)).data;

        if (!result) {
            return null;
        }

        return cloneObject(result);
    },

    async getByIds(ids) {
        return cloneObject((await axios.get("/api/emergency-incidents", { params: { id: ids.join(",") } })).data);
    },

    async getMyEmergencyIncidentsStatus() {
        return (await axios.get("/api/emergency-incidents/_my-status")).data;
    },
};
