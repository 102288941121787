
import { notePlaceholders } from "./notePlaceholders";
import { NoteTemplate, NoteTemplateForm, noteTemplatesApi } from "@/api/noteTemplates";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        noteTemplate: {
            type: Object as () => NoteTemplate,
            required: false,
        },
    },

    data() {
        return {
            isWorking: false,
            name: this.noteTemplate?.name || "",
            nameRules: notEmpty().maxLength(300),
            content: this.noteTemplate?.content || "",
            contentRules: notEmpty().maxLength(8000),
            locale: this.noteTemplate?.locale || configStore.configuration.defaultLocale,
        };
    },

    computed: {
        placeholders(): string[] {
            return notePlaceholders;
        },

        locales(): SelectOptions {
            return this.$i18n.availableLocales.map((k) => ({ value: k, text: this.$t(`locale.${k}`) }));
        },
    },

    methods: {
        appendToContent(placeholder: string) {
            this.content += placeholder;
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            const form: NoteTemplateForm = {
                name: this.name,
                content: this.content,
                locale: this.locale,
            };

            try {
                if (this.noteTemplate) {
                    await noteTemplatesApi.edit(this.noteTemplate.id, form);
                } else {
                    await noteTemplatesApi.add(form);
                }
                this.$emit("success");
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },
    },
});
