
import ContactCard from "./ContactCard.vue";
import ContactVehicleCard from "./ContactVehicleCard.vue";
import ContactFormCard from "./contactandvehiclepicker/ContactFormCard.vue";
import ContactVehicleFormCard from "./contactandvehiclepicker/ContactVehicleFormCard.vue";
import ContactVehicleSelectorCard from "./contactandvehiclepicker/ContactVehicleSelectorCard.vue";
import { ContactSearchRequest } from "@/api/contactSearch";
import {
    Contact,
    ContactForm,
    contactsApi,
    ContactVehicle,
    ContactVehicleData,
    ContactVehicleForm,
    MileageUnit,
} from "@/api/contacts";
import { ContactAndVehicleId } from "@/app/contactUtils";
import { showConfirm, showInfo } from "@/app/messageUtil";
import { email, integer, validate } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { cloneObject } from "@/util/cloneUtils";
import { ActionLimiter } from "@/util/debounce";
import { parseAndFormatNumber, PhoneNumberCountryCode } from "@/util/phoneNumberUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canExitEditMode: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
        canSelectContactVehicle: {
            type: Boolean,
            default: false,
        },
        canSelectEmailAddress: {
            type: Boolean,
            default: false,
        },
        canSelectPhoneNumber: {
            type: Boolean,
            default: false,
        },
        canSendEmail: {
            type: Boolean,
            default: false,
        },
        canSendSms: {
            type: Boolean,
            default: false,
        },
        canStartCall: {
            type: Boolean,
            default: false,
        },
        canUnselect: {
            type: Boolean,
            default: false,
        },
        contactAndVehicleId: {
            type: Object as () => ContactAndVehicleId | null,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editMode: {
            type: Boolean,
            default: true,
        },
        elevatedButtons: {
            type: Boolean,
            default: false,
        },
        searchRequest: {
            type: Object as () => ContactSearchRequest | null,
            required: false,
        },
    },

    data() {
        return {
            addingVehicle: false,
            contact: null as Contact | null,
            deletingVehicle: false,
            editModeProp: false,
            loading: false,
            positiveIntegerRules: integer(0),
            saveContactActionLimiter: new ActionLimiter(),
            saveVehicleActionLimiter: new ActionLimiter(),
            selectedVehicle: null as ContactVehicle | null,
            vehicles: [] as ContactVehicle[],
        };
    },

    computed: {
        contactForm(): ContactForm | null {
            return this.contact?.contactData ?? null;
        },

        defaultMileageUnit(): string {
            return configStore.configuration.defaultMileageUnit;
        },
    },

    methods: {
        async createVehicle() {
            if (!this.contact) {
                return;
            }

            const contactVehicleData: ContactVehicleData = {
                vin: this.searchRequest?.vin ?? null,
                licensePlate: this.searchRequest?.licensePlate ?? null,
                vehicleClass: null,
                make: null,
                model: null,
                modelDescription: null,
                modelYear: null,
                firstRegistration: null,
                nextGeneralInspection: null,
                nextInspection: null,
                nextInspectionMileage: null,
                nextInspectionMileageUnit: null,
                warrantyUntil: null,
                warrantyUntilMileage: null,
                warrantyUntilMileageUnit: null,
                warrantyPaintingUntil: null,
                warrantyRustUntil: null,
                records: [],
                notes: null,
                url: null,
                externalReference: null,
            };

            this.addingVehicle = true;

            try {
                const contactVehicleId = await contactsApi.addContactVehicle(this.contact!.id, contactVehicleData);

                const newVehicle: ContactVehicle = {
                    id: contactVehicleId,
                    created: now(),
                    updated: now(),
                    deleted: null,
                    contactVehicleData: contactVehicleData,
                };

                this.vehicles.push(newVehicle);

                this.selectedVehicle = newVehicle;

                this.emitUpdateContactAndVehicleId();

                this.$emit("change:contact-vehicle-data", cloneObject(newVehicle.contactVehicleData));
            } finally {
                this.addingVehicle = false;
            }
        },

        async deleteSelectedVehicle() {
            if (!this.contact || !this.selectedVehicle) {
                return;
            }

            if (
                await showConfirm(
                    this.$t("Fahrzeug löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie das Fahrzeug löschen möchten?") as string
                )
            ) {
                this.deletingVehicle = true;

                try {
                    await contactsApi.markContactVehicleAsDeleted(this.contact.id, this.selectedVehicle.id);

                    this.vehicles = this.vehicles.filter((v) => v.id !== this.selectedVehicle!.id);
                    this.selectedVehicle = null;

                    this.emitUpdateContactAndVehicleId();

                    this.$emit("change:contact-vehicle-data", null);
                } finally {
                    this.deletingVehicle = false;
                }
            }
        },

        emitUpdateContactAndVehicleId() {
            const contactAndVehicleId: ContactAndVehicleId | null = this.contact
                ? {
                      contactId: this.contact.id,
                      contactVehicleId: this.selectedVehicle?.id ?? null,
                  }
                : null;

            this.$emit("update:contact-and-vehicle-id", contactAndVehicleId);
        },

        async loadContactAndVehicle(contactId: string, contactVehicleId: string | null) {
            this.loading = true;
            try {
                const contactResult = await contactsApi.getContactResultById(contactId);

                const contact = contactResult?.contact ?? null;
                const contactVehicles = (contactResult?.contactVehicles ?? []).filter((v) => !v.deleted);
                const contactVehicle = contactVehicles.find((v) => v.id === contactVehicleId) ?? null;

                if (!contact || (contactVehicleId && !contactVehicle)) {
                    this.unselectContactAndVehicle();
                    showInfo(this.$t("Der Kontakt konnte nicht geladen werden.") as string);
                    return;
                }

                this.contact = contact;
                this.selectedVehicle = contactVehicle;
                this.vehicles = contactVehicles;

                this.emitUpdateContactAndVehicleId();

                this.$emit("change:contact-data", cloneObject(contact.contactData));
                this.$emit("change:contact-vehicle-data", cloneObject(contactVehicle?.contactVehicleData ?? null));
            } finally {
                this.loading = false;
            }
        },

        async loadContactAndVehicleByPropsIfNecessary() {
            const currentContactId = this.contact?.id ?? null;
            const currentContactVehicleId = this.selectedVehicle?.id ?? null;

            const trimmedContactId = trimAndReturnNullIfEmpty(this.contactAndVehicleId?.contactId);
            const trimmedContactVehicleId = trimAndReturnNullIfEmpty(this.contactAndVehicleId?.contactVehicleId);

            if (!trimmedContactId) {
                this.unselectContactAndVehicle();
                return;
            }

            if (currentContactId === trimmedContactId && currentContactVehicleId === trimmedContactVehicleId) {
                return;
            }

            await this.loadContactAndVehicle(trimmedContactId, trimmedContactVehicleId);
        },

        async saveContact(contactForm: ContactForm) {
            if (!this.contact) {
                return;
            }

            this.contact = {
                ...this.contact,
                contactData: {
                    ...this.contact.contactData,
                    ...cloneObject(contactForm),
                },
            };

            // remove invalid numbers and  invalid email addresses
            const validContactForm: ContactForm = {
                ...cloneObject(contactForm),
                numbers: contactForm.numbers.filter((n) =>
                    parseAndFormatNumber(
                        n.number,
                        "E.164",
                        (contactForm.country || configStore.configuration.defaultCountry) as PhoneNumberCountryCode
                    )
                ),
                emailAddresses: contactForm.emailAddresses.filter(
                    (e) => e.address.trim() && validate(email(), e.address.trim())
                ),
            };

            // emit potentially dirty data to avoid that depending components feel unresponding
            this.$emit(
                "change:contact-data",
                cloneObject({
                    ...this.contact.contactData,
                    ...validContactForm,
                })
            );

            // execute the edit
            await this.saveContactActionLimiter.execute(() =>
                contactsApi.editContact(this.contact!.id, validContactForm)
            );
        },

        async saveVehicle(contactVehicleForm: ContactVehicleForm) {
            if (!this.contact || !this.selectedVehicle) {
                return;
            }

            this.selectedVehicle = {
                ...this.selectedVehicle,
                contactVehicleData: cloneObject(contactVehicleForm),
            };

            this.vehicles = this.vehicles.map((v) =>
                v.id === this.selectedVehicle?.id ? { ...v, contactVehicleData: cloneObject(contactVehicleForm) } : v
            );

            const validContactVehicleForm: ContactVehicleForm = {
                ...cloneObject(contactVehicleForm),
                // set mileage to null if invalid for next inspection
                nextInspectionMileage: validate(this.positiveIntegerRules, contactVehicleForm.nextInspectionMileage)
                    ? contactVehicleForm.nextInspectionMileage
                    : null,
                // set default mileage unit if mileage unit is not set for next inspection
                nextInspectionMileageUnit: contactVehicleForm.nextInspectionMileageUnit
                    ? contactVehicleForm.nextInspectionMileageUnit
                    : (this.defaultMileageUnit as MileageUnit),
                // set mileage to null if invalid for warranty until
                warrantyUntilMileage: validate(this.positiveIntegerRules, contactVehicleForm.warrantyUntilMileage)
                    ? contactVehicleForm.warrantyUntilMileage
                    : null,
                // set default mileage unit if mileage unit is not set for warranty until
                warrantyUntilMileageUnit: contactVehicleForm.warrantyUntilMileageUnit
                    ? contactVehicleForm.warrantyUntilMileageUnit
                    : (this.defaultMileageUnit as MileageUnit),
            };

            // emit potentially dirty data to avoid that depending components feel unresponding
            this.$emit("change:contact-vehicle-data", cloneObject(validContactVehicleForm));

            // execute the edit
            await this.saveVehicleActionLimiter.execute(() =>
                contactsApi.editContactVehicle(this.contact!.id!, this.selectedVehicle!.id, validContactVehicleForm)
            );
        },

        selectVehicle(vehicleId: string) {
            const contactVehicle = this.vehicles.find((v) => v.id === vehicleId);

            if (!contactVehicle) {
                this.unselectContactAndVehicle();
                return;
            }

            this.selectedVehicle = contactVehicle;

            this.emitUpdateContactAndVehicleId();

            this.$emit("change:contact-vehicle-data", cloneObject(contactVehicle.contactVehicleData));
        },

        setEditMode(editMode: boolean) {
            this.editModeProp = this.canEdit && !!this.contact && editMode;
            this.$emit("edit-mode", this.editModeProp);
        },

        unselectContactAndVehicle() {
            this.contact = null;
            this.selectedVehicle = null;
            this.vehicles = [];

            this.setEditMode(false);
            this.emitUpdateContactAndVehicleId();

            this.$emit("change:contact-data", null);
            this.$emit("change:contact-vehicle-data", null);
        },

        unselectVehicle() {
            this.selectedVehicle = null;

            this.emitUpdateContactAndVehicleId();

            this.$emit("change:contact-vehicle-data", null);
        },
    },

    watch: {
        canEdit() {
            this.setEditMode(this.editModeProp);
        },

        contactAndVehicleId: {
            deep: true,
            async handler() {
                try {
                    await this.loadContactAndVehicleByPropsIfNecessary();
                } catch (e) {
                    Vue.nextTick(() => {
                        throw e;
                    });
                }
            },
        },

        editMode() {
            this.setEditMode(this.editMode);
        },
    },

    async mounted() {
        await this.loadContactAndVehicleByPropsIfNecessary();
        this.setEditMode(this.canEdit && this.editMode);
    },

    components: {
        ContactCard,
        ContactFormCard,
        ContactVehicleCard,
        ContactVehicleFormCard,
        ContactVehicleSelectorCard,
    },
});
