import { IncomingCallResult, incomingCallsApi } from "@/api/incomingCalls";
import { isIncomingCallUpdatedNotification, notificationEventSource } from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class IncomingCallsStore {
    private initLimiter = new ActionLimiter(true);
    private incomingCalls_: IncomingCallResult[] = [];

    async init() {
        await this.initLimiter.execute(async () => {
            this.incomingCalls_ = await incomingCallsApi.getAllActiveIncomingCalls();
        });
    }

    handleUpdate(newIncomingCall: IncomingCallResult) {
        const currentIncomingCall = this.incomingCalls_.find((c) => c.id === newIncomingCall.id);

        // if call has ended and this call id was in store, remove it
        if (newIncomingCall.ended) {
            if (currentIncomingCall) {
                this.incomingCalls_ = this.incomingCalls_.filter((c) => c !== currentIncomingCall);
            }
            return;
        }

        // if call has not ended, replace or add the new call
        if (currentIncomingCall) {
            this.incomingCalls_ = this.incomingCalls_.filter((c) => c !== currentIncomingCall);
        }
        this.incomingCalls_.push(newIncomingCall);
    }

    incomingCallByRingingUserOrAcceptor(userId: string) {
        for (const incomingCall of this.incomingCalls_) {
            if (incomingCall.acceptorUserId === userId && !incomingCall.released) {
                return incomingCall;
            }
            if (!!incomingCall.ringingUserIds.find((u) => u === userId)) {
                return incomingCall;
            }
        }
        return null;
    }
}

export const incomingCallsStore = new IncomingCallsStore();

notificationEventSource.addDataHandler((n) => {
    if (isIncomingCallUpdatedNotification(n)) {
        incomingCallsStore.handleUpdate(n.incomingCall);
    }
});
