
import { videochatApi } from "@/api/videochats";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        videochatId: {
            type: String,
            required: true,
        },
    },

    computed: {
        qrCodeImageLink(): string {
            return videochatApi.generateQrCodeImageLink(this.videochatId, userSession.fullName!);
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },
    },
});
