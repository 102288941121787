
import { Case, casesApi } from "@/api/cases";
import { Contact, ContactNumber, EmailAddress } from "@/api/contacts";
import ActionCard from "@/app/pages/ActionCard.vue";
import PreferredContactFormContent from "@/app/pages/contacts/PreferredContactFormContent.vue";
import { PreferredContact } from "@/app/pages/contacts/preferredContact";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseProp: {
            type: Object as () => Case,
            required: true,
        },
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
    },

    data() {
        return {
            preferredContact: {
                preferredContactMethod: this.caseProp.preferredContactMethod,
                preferredContactDetails: this.caseProp.preferredContactDetails,
                earliestContactTime: this.caseProp.earliestContactTime,
                latestContactTime: this.caseProp.latestContactTime,
            } as PreferredContact,
            timeZone: dealersStore.dealerById(this.caseProp.dealerId)!.timeZone,
            working: false,
        };
    },

    computed: {
        emailAddresses(): EmailAddress[] {
            if (this.contact?.deleted) {
                return [];
            }

            return this.contact?.contactData.emailAddresses ?? [];
        },

        numbers(): ContactNumber[] {
            if (this.contact?.deleted) {
                return [];
            }

            return this.contact?.contactData.numbers ?? [];
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async save() {
            this.working = true;
            try {
                await casesApi.updatePreferredContact(this.caseProp!.id, this.preferredContact);
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        PreferredContactFormContent,
    },
});
