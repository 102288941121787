
import ContactDataTable from "./ContactDataTable.vue";
import ContactUploadSourceFormDialog from "./ContactUploadSourceFormDialog.vue";
import {
    ContactUploadParseResult,
    contactUploadsApi,
    ContactUploadSource,
    ExternalContactParsed,
} from "@/api/contactUploads";
import { Permission } from "@/api/userSession";
import { fileDialog } from "@/app/fileUtils";
import { showConfirm, showInfo } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

interface ContactUploadSourceWithFile extends ContactUploadSource {
    applyInProgress: boolean;
    analyzeInProgress: boolean;
    uploadInProgress: boolean;
    progress: number;
    analyzed: boolean;
    contactUploadParseResult: ContactUploadParseResult | null;
}

export default Vue.extend({
    data() {
        return {
            contactUploadSourceFormDialogVisible: false,
            contactUploadSourceFormDialogItem: null as ContactUploadSource | null,
            items: [] as ContactUploadSourceWithFile[],
            loading: true,
            previewDialogVisible: false,
            previewData: [] as ExternalContactParsed[],
            previewTotalSize: 0,
        };
    },

    computed: {
        canDelete(): boolean {
            return userSession.hasPermission(Permission.DELETE_CONTACT_UPLOADS);
        },
    },

    methods: {
        formatDate(date: Date) {
            return formatInstant(date, userSession.timeZone, userSession.locale, "L");
        },

        async loadItems() {
            this.loading = true;
            this.items = [];

            try {
                this.items = (await contactUploadsApi.getAll()).map((v) => ({
                    ...v,
                    applyInProgress: false,
                    analyzeInProgress: false,
                    uploadInProgress: false,
                    progress: 0,
                    analyzed: false,
                    contactUploadParseResult: null,
                }));
            } finally {
                this.loading = false;
            }
        },

        async analyzeFile(itemId: string) {
            const item = this.items.find((i) => i.id === itemId);
            if (!item) {
                return;
            }

            item.analyzed = false;
            item.analyzeInProgress = true;

            try {
                item.contactUploadParseResult = Object.freeze(
                    await contactUploadsApi.parse(item.latestContactUpload?.id!, 1000)
                );
                item.analyzed = true;
            } finally {
                item.analyzeInProgress = false;
            }
        },

        showPreviewDialog(item: ContactUploadSourceWithFile) {
            this.previewData = item.contactUploadParseResult!.contacts;
            this.previewTotalSize = item.contactUploadParseResult!.total;
            this.previewDialogVisible = true;
        },

        async applyFile(item: ContactUploadSourceWithFile) {
            const result = item.contactUploadParseResult!;
            const oldTotal = result.total - result.inserts + result.deletes;
            if (
                oldTotal > 0 &&
                result.deletes / oldTotal > 0.1 &&
                !(await showConfirm(
                    this.$t("Datenprobleme erkannt") as string,
                    this.$t(
                        "In den hochgeladenen Daten fehlen mehr als 10% der derzeitigen Kontakte. Sind Sie sicher, dass Sie die Kontakte aktualisieren möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            item.applyInProgress = true;
            try {
                await contactUploadsApi.apply(item.latestContactUpload?.id!);
                await this.loadItems();
            } finally {
                item.applyInProgress = false;
            }
        },

        async importFromFtp(item: ContactUploadSourceWithFile) {
            item.applyInProgress = true;
            try {
                const uploadId = await contactUploadsApi.importFromFtp(item.id);

                if (uploadId) {
                    await this.loadItems();
                } else {
                    showInfo(this.$t("Es wurde keine aktuelle Importdatei gefunden.") as string);
                }
            } finally {
                item.applyInProgress = false;
            }
        },

        uploadFile(item: ContactUploadSourceWithFile) {
            fileDialog(
                false,
                async (files: File[]) => {
                    await this.fileSelected(files[0], item);
                    await this.analyzeFile(item.id);
                },
                [".csv", ".zip"]
            );
        },

        async fileSelected(file: File, item: ContactUploadSourceWithFile) {
            item.uploadInProgress = true;

            try {
                await contactUploadsApi.upload(
                    item.id,
                    file,
                    ({ total, loaded }) => (item.progress = (100 * loaded) / total)
                );

                await this.loadItems();
            } finally {
                item.uploadInProgress = false;
            }
        },

        showCreateForm() {
            this.contactUploadSourceFormDialogItem = null;
            this.contactUploadSourceFormDialogVisible = true;
        },

        showEditForm(item: ContactUploadSourceWithFile) {
            this.contactUploadSourceFormDialogItem = item;
            this.contactUploadSourceFormDialogVisible = true;
        },

        async deleteItem(item: ContactUploadSourceWithFile) {
            if (
                await showConfirm(
                    this.$t("Kontaktquelle löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Kontaktquelle {0} löschen möchten?", [item.name]) as string
                )
            ) {
                await contactUploadsApi.delete(item.id);
                await this.loadItems();
            }
        },

        async savedItem() {
            this.contactUploadSourceFormDialogVisible = false;
            await this.loadItems();
        },

        getDealerName(dealerId: string): string {
            return dealersStore.dealerById(dealerId)!.name;
        },

        downloadContactUpload(contactUploadId: string) {
            window.open(contactUploadsApi.generateDownloadLink(contactUploadId));
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        ContactUploadSourceFormDialog,
        DealerGlobalContextHint,
        ContactDataTable,
    },
});
