
import WorkingHoursWeek from "./WorkingHoursWeek.vue";
import { Permission } from "@/api/userSession";
import { usersApi, UserSummary } from "@/api/users";
import { WorkingHours, workingHoursApi } from "@/api/workingHours";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { searchFilter } from "@/app/searchFilter";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            dealersStore,
            users: [] as UserSummary[],
            workingHours: [] as WorkingHours[],
            loading: true,
            search: null as string | null,
            getFullName,
        };
    },

    computed: {
        dealerId() {
            if (
                !userSession.dealerId ||
                userSession.hasPermission(Permission.WORKING_HOURS_MANAGEMENT) ||
                userSession.profile?.dealerIds.includes(userSession.dealerId)
            ) {
                return userSession.dealerId;
            }
            return null;
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Benutzer"),
                    width: "20%",
                },
                {
                    text: this.$t("Arbeitszeiten"),
                    width: "79%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "1%",
                },
            ];
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    methods: {
        async loadItems() {
            this.loading = true;

            try {
                if (this.dealerId) {
                    this.workingHours = await workingHoursApi.getByMainDealer(this.dealerId);
                    this.users = await usersApi.getByMainDealer(this.dealerId);
                } else {
                    this.users = [];
                    this.workingHours = [];
                }
            } finally {
                this.loading = false;
            }
        },

        getWorkingHoursByUser(userId: string): WorkingHours | undefined {
            for (const workingHours of this.workingHours) {
                if (workingHours.userId === userId) {
                    return workingHours;
                }
            }

            return {
                userId,
                workingHoursTemplates: [],
                confirmedWeeks: [],
                customWeeks: [],
            };
        },

        filter(search: string, item: UserSummary): boolean {
            return searchFilter(search, getFullName(item), item.username, item.employeeNumber);
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        WorkingHoursWeek,
        DealerContextGuard,
        DataTable,
    },
});
