
import CaseReceiversFormCard from "./CaseReceiversFormCard.vue";
import { CaseStatus, EscalationGroupReceiver, IndividualReceiver } from "@/api/cases";
import EscalationGroupLink from "@/app/pages/EscalationGroupLink.vue";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import UserDirectoryEntrySpan from "@/app/pages/users/UserDirectoryEntrySpan.vue";
import { getSortedByUserFullName } from "@/app/userUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseId: {
            type: String,
            required: true,
        },
        individualReceivers: {
            type: Array as () => IndividualReceiver[],
            required: true,
        },
        escalationGroupReceivers: {
            type: Array as () => EscalationGroupReceiver[],
            required: true,
        },
        dealerId: {
            type: String,
            required: true,
        },
        caseType: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        caseStatus: {
            type: String as () => CaseStatus,
            required: true,
        },
    },

    data() {
        return {
            editMode: false,
        };
    },

    computed: {
        individualReceiverIds(): string[] {
            return this.individualReceiversSortedByUserFullname.map((r) => r.userId);
        },

        individualReceiversSortedByUserFullname(): IndividualReceiver[] {
            return getSortedByUserFullName(this.individualReceivers, (r) => r.userId);
        },

        escalationGroupReceiverIds(): string[] {
            return this.escalationGroupReceivers.map((r) => r.escalationGroupId);
        },
    },

    components: { CaseReceiversFormCard, EscalationGroupLink, UserDirectoryEntrySpan, IconWithTooltip },
});
