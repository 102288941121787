
import {
    OpportunityOutcomeReason,
    OpportunityOutcomeReasonForm,
    opportunityOutcomeReasonsApi,
} from "@/api/opportunityOutcomeReasons";
import { showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import { maxLength, notEmpty } from "@/app/validation";
import { opportunitySettingsStore } from "@/store/opportunitySettings";
import { reportingSettingsStore } from "@/store/reportingSettingsStore";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            maxLength,
            notEmpty,
        };
    },

    computed: {
        api() {
            return opportunityOutcomeReasonsApi;
        },

        ignoreOpportunityOutcomeReasonIdsInReporting(): string[] {
            return reportingSettingsStore.reportingSettings.ignoreOpportunityOutcomeReasonIds;
        },

        outcomeReasonsToIgnoreInAssignmentLock(): string[] {
            return opportunitySettingsStore.outcomeReasonsToIgnoreInAssignmentLock;
        },
    },

    methods: {
        async deleteOpportunityOutcomeReason(item: OpportunityOutcomeReason) {
            if (
                await showConfirm(
                    this.$t("Resultat löschen").toString(),
                    this.$t("Sind Sie sicher, dass Sie das Resultat löschen möchten?").toString()
                )
            ) {
                await this.api.delete(item.id);
                return true;
            }
            return false;
        },

        emptyForm(won: boolean): OpportunityOutcomeReasonForm {
            return {
                name: "",
                description: null,
                won,
            };
        },

        async getOpportunityOutcomeReasons(won: boolean) {
            return (await this.api.getAll()).filter((i) => i.won === won);
        },

        toForm(item: OpportunityOutcomeReason): OpportunityOutcomeReasonForm {
            return cloneObject(item);
        },

        async updateOrder(items: OpportunityOutcomeReason[]) {
            await this.api.updateOrder(items.map((ot) => ot.id));
        },
    },

    components: {
        CrudPage,
    },
});
