import {
    ExternalInventoryManagementSettings,
    externalInventoryManagementSettingsApi,
} from "@/api/externalInventoryManagementSettings";
import { isExternalInventoryManagementSettingsUpdatedNotification, notificationEventSource } from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class ExternalInventoryManagementSettingsStore {
    private refreshLimiter = new ActionLimiter(true);
    public settings: ExternalInventoryManagementSettings = {
        userLinkPattern: null,
        userLinkLabel: null,
        vehicleLinkPattern: null,
        vehicleLinkLabel: null,
    };

    async initialize() {
        await this.refreshLimiter.execute(async () => {
            this.settings = await externalInventoryManagementSettingsApi.getSettings();
        });
    }
}

export const externalInventoryManagementSettingsStore = new ExternalInventoryManagementSettingsStore();

notificationEventSource.addDataHandler((n) => {
    if (isExternalInventoryManagementSettingsUpdatedNotification(n)) {
        externalInventoryManagementSettingsStore.settings = n.settings;
    }
});
