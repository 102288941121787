
import { Permission } from "@/api/userSession";
import { WorkingHours, workingHoursApi, WorkingHoursTemplate, WorkingHoursTemplateEntry } from "@/api/workingHours";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDaysOfWeek, getWeek, Week } from "@/util/dateTimeUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        offset: {
            type: Number,
            required: true,
        },
        tz: {
            type: String,
            required: true,
        },
        confirmable: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String,
            required: true,
        },
        workingHours: {
            type: Object as () => WorkingHours,
            required: true,
        },
    },

    data() {
        return {
            isConfirming: false,
            currentDate: now(),
            selectedTemplate: "",
            isSelecting: false,
        };
    },

    computed: {
        canEditWorkingHours() {
            return (
                userSession.hasPermission(Permission.WORKING_HOURS_MANAGEMENT) ||
                userSession.hasPermission(Permission.CHANGE_OWN_WORKING_HOURS)
            );
        },

        week(): Week {
            return getWeek(this.currentDate, this.offset, this.tz, configStore.configuration.defaultLocale);
        },

        daysOfWeek(): SelectOptions {
            return getDaysOfWeek(configStore.configuration.defaultLocale, userSession.locale);
        },

        customTemplateId(): string | null {
            for (const customWeek of this.workingHours.customWeeks) {
                if (customWeek.mondayOfWeek === this.week.monday) {
                    return customWeek.workingHoursTemplateId;
                }
            }

            return null;
        },

        workingHoursTemplate(): WorkingHoursTemplate | null {
            const workingHoursTemplateId = this.customTemplateId;

            for (const workingHoursTemplate of this.workingHours.workingHoursTemplates) {
                if (workingHoursTemplateId && workingHoursTemplate.id === workingHoursTemplateId) {
                    return workingHoursTemplate;
                }

                if (!workingHoursTemplateId) {
                    if (workingHoursTemplate.defaultTemplate) {
                        return workingHoursTemplate;
                    }
                }
            }

            return null;
        },

        templates(): SelectOptions {
            const result = [];

            for (const workingHoursTemplate of this.workingHours.workingHoursTemplates) {
                if (workingHoursTemplate.defaultTemplate) {
                    result.push({
                        value: "",
                        text: this.$t("Standardvorlage ({0})", [workingHoursTemplate.name]),
                    });
                    break;
                }
            }

            for (const workingHoursTemplate of this.workingHours.workingHoursTemplates) {
                result.push({
                    value: workingHoursTemplate.id,
                    text: workingHoursTemplate.name,
                });
            }

            return result;
        },

        isConfirmed(): boolean {
            for (const confirmedWeek of this.workingHours.confirmedWeeks) {
                if (confirmedWeek === this.week.monday) {
                    return true;
                }
            }

            return false;
        },
    },

    methods: {
        getEntriesForDayOfWeek(
            workingHoursTemplate: WorkingHoursTemplate,
            dayOfWeek: number
        ): WorkingHoursTemplateEntry[] {
            const result = [] as WorkingHoursTemplateEntry[];

            for (const workingHoursTemplateEntry of workingHoursTemplate.workingHoursTemplateEntries) {
                if (workingHoursTemplateEntry.dayOfWeek === dayOfWeek) {
                    result.push(workingHoursTemplateEntry);
                }
            }

            return result.sort((a, b) => a.from.localeCompare(b.from, userSession.locale));
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },

        async confirm() {
            this.isConfirming = true;
            try {
                await workingHoursApi.addConfirmedWeek(this.userId, this.week.monday, this.workingHoursTemplate!.id);
                this.$emit("change");
            } catch (e) {
                throw e;
            } finally {
                this.isConfirming = false;
            }
        },

        refreshSelectedTemplateSelect() {
            this.selectedTemplate = this.customTemplateId || "";
        },

        async setTemplate() {
            this.isSelecting = true;
            try {
                if (this.selectedTemplate === "") {
                    await workingHoursApi.deleteCustomWeek(this.userId, this.week.monday);
                } else {
                    await workingHoursApi.upsertCustomWeek(this.userId, this.week.monday, this.selectedTemplate);
                }
                this.$emit("change");
            } catch (e) {
                throw e;
            } finally {
                this.isSelecting = false;
            }
        },
    },

    watch: {
        workingHours() {
            this.refreshSelectedTemplateSelect();
        },
    },

    mounted() {
        this.refreshSelectedTemplateSelect();
    },
});
