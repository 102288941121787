
import { SearchResult } from "./contactSearchUtils";
import ContactSearchFilterCard from "./contactandvehiclesearchcard/ContactSearchFilterCard.vue";
import ContactSearchResultsCard from "./contactandvehiclesearchcard/ContactSearchResultsCard.vue";
import { ContactSearchRequest } from "@/api/contactSearch";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canSelectContactVehicle: {
            type: Boolean,
            default: false,
        },
        canSelectEmailAddress: {
            type: Boolean,
            default: false,
        },
        canSelectPhoneNumber: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        phoneticSpellingLocale: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
        showIssues: {
            type: Boolean,
            default: false,
        },
        showPhoneticSpelling: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object as () => ContactSearchRequest,
            required: true,
        },
    },

    data() {
        return {
            searching: true,
            searchRequestProp: cloneObject(this.value),
            searchResult: null as SearchResult | null,
        };
    },

    computed: {
        isEmptySearchRequest(): boolean {
            return this.searchResult?.isSearchRequestEmpty ?? true;
        },
    },

    methods: {
        async handleSearchInput(searchRequest: ContactSearchRequest) {
            this.searchRequestProp = cloneObject(searchRequest);
            this.$emit("input", cloneObject(this.searchRequestProp));
        },
    },

    watch: {
        searchRequest: {
            deep: true,
            handler() {
                if (JSON.stringify(this.searchRequestProp) !== JSON.stringify(this.value)) {
                    this.searchRequestProp = cloneObject(this.value);
                }
            },
        },
    },

    components: {
        ContactSearchFilterCard,
        ContactSearchResultsCard,
    },
});
