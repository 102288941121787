import { InventoryVehicleData } from "@/api/inventory";
import { userSession } from "@/store/userSession";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";

type ExternalInventoryManagementVehicleLinkData = Pick<InventoryVehicleData, "internalId" | "vin">;

export const externalInventoryManagementUserLinkPlaceholders = ["USER_ID"];

export const externalInventoryManagementVehicleLinkPlaceholders = ["USER_ID", "INTERNAL_ID", "VIN"];

export function renderExternalInventoryManagementUserLink(userLinkPattern: string | null) {
    userLinkPattern = trimAndReturnNullIfEmpty(userLinkPattern);

    if (!userLinkPattern || userSession.isCtUser()) {
        return null;
    }

    return [(userLink: string) => userLink.replace(/%USER_ID%/g, userSession.userId!)].reduce(
        (userLink, fn) => fn(userLink),
        userLinkPattern
    );
}

export function renderExternalInventoryManagementVehicleLink(
    vehicleLinkPattern: string | null,
    { internalId, vin }: ExternalInventoryManagementVehicleLinkData
) {
    vehicleLinkPattern = trimAndReturnNullIfEmpty(vehicleLinkPattern);

    if (!vehicleLinkPattern || (!internalId && !vin) || userSession.isCtUser()) {
        return null;
    }

    return [
        (vehicleLink: string) => vehicleLink.replace(/%USER_ID%/g, userSession.userId!),
        (vehicleLink: string) => vehicleLink.replace(/%INTERNAL_ID%/g, encodeURIComponent(internalId || "")),
        (vehicleLink: string) => vehicleLink.replace(/%VIN%/g, encodeURIComponent(vin || "")),
    ].reduce((vehicleLink, fn) => fn(vehicleLink), vehicleLinkPattern);
}
