
import { Contact } from "@/api/contacts";
import { Note, NoteAndContact } from "@/api/notes";
import { renderContactCaption } from "@/app/contactUtils";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        noteAndContact: {
            type: Object as () => NoteAndContact,
            required: true,
        },
    },

    computed: {
        note(): Note {
            return this.noteAndContact.note;
        },

        contact(): Contact | null {
            return this.noteAndContact.contact;
        },

        contactCaption(): string | null {
            if (!this.contact) {
                return null;
            }

            return renderContactCaption(this.contact.contactData);
        },

        creationTimestamp(): string {
            return formatInstant(this.note.created, userSession.timeZone, userSession.locale, "S");
        },

        isContactDeleted(): boolean {
            return !!this.contact?.deleted;
        },
    },
});
