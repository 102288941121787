import { CaseSearchOrder } from "./caseSearch";
import { CasesSort } from "./cases";
import { OpportunitiesSort } from "./opportunities";
import { OpportunitySearchOrder } from "./opportunitySearch";
import axios from "axios";

export interface MyCasesPreferences {
    readonly activeCasesSortBy: CasesSort | null;
    readonly createdCasesSortBy: CasesSort | null;
    readonly closedCasesSortBy: CasesSort | null;
    readonly searchCasesSortBy: CaseSearchOrder | null;
}

export interface MyOpportunitiesPreferences {
    readonly activeOpportunitiesSortBy: OpportunitiesSort | null;
    readonly createdOpportunitiesSortBy: OpportunitiesSort | null;
    readonly closedOpportunitiesSortBy: OpportunitiesSort | null;
    readonly searchOpportunitiesSortBy: OpportunitySearchOrder | null;
}

export interface UserPreferences {
    readonly myCasesPreferences: MyCasesPreferences;
    readonly myOpportunitiesPreferences: MyOpportunitiesPreferences;
}

interface UserPreferencesApi {
    currentUserPreferences(): Promise<UserPreferences>;

    editMyCasesPreferences(myCasesPreferences: MyCasesPreferences): Promise<void>;

    editMyOpportunitiesPreferences(myOpportunitiesPreferences: MyOpportunitiesPreferences): Promise<void>;
}

export const userPreferencesApi: UserPreferencesApi = {
    async currentUserPreferences() {
        return (await axios.get("/api/user-preferences")).data;
    },

    async editMyCasesPreferences(myCasesPreferences) {
        await axios.post("/api/user-preferences/my-cases", myCasesPreferences);
    },

    async editMyOpportunitiesPreferences(myOpportunitiesPreferences) {
        await axios.post("/api/user-preferences/my-opportunities", myOpportunitiesPreferences);
    },
};
