
import { Case, CaseChannel, casesApi, CaseUrgency, GeneralInfoForm } from "@/api/cases";
import DateTimePicker from "@/app/components/DateTimePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import { dealerOptions } from "@/app/dealerUtils";
import ActionCard from "@/app/pages/ActionCard.vue";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import { getSentimentText, SENTIMENTS } from "@/app/sentimentUtils";
import { dealersStore } from "@/store/dealers";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseProp: {
            type: Object as () => Case,
            required: true,
        },
    },

    data() {
        return {
            CaseChannel,
            CaseUrgency,
            escalationBegin: this.caseProp.escalationBegin,
            escalationBeginTimeZone: dealersStore.dealerById(this.caseProp.dealerId)!.timeZone,
            generalInfoFormData: {
                dealerId: this.caseProp.dealerId,
                channel: this.caseProp.channel,
                caseType: this.caseProp.caseType,
                sentiment: this.caseProp.sentiment,
                urgency: this.caseProp.urgency,
                appointmentTime: this.caseProp.appointmentTime,
                subject: this.caseProp.subject,
                escalationBegin: this.caseProp.escalationBegin,
            } as GeneralInfoForm,
            instantEscalation: !this.caseProp.escalationBegin,
            working: false,
        };
    },

    computed: {
        dealerOptions() {
            return dealerOptions();
        },

        sentimentOptions(): SelectOptions {
            return SENTIMENTS.map((s) => ({
                value: s,
                text: getSentimentText(s),
            }));
        },

        timeZone(): string {
            return dealersStore.dealerById(this.caseProp.dealerId)!.timeZone;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async save() {
            const form: GeneralInfoForm = {
                ...this.generalInfoFormData!,
                escalationBegin: this.instantEscalation ? null : this.escalationBegin,
            };

            this.working = true;
            try {
                await casesApi.updateGeneralInfo(this.caseProp.id, form);
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },

        setInstantEscalationAndEscalationBegin(instantEscalation: boolean) {
            this.instantEscalation = instantEscalation;

            if (!instantEscalation && !this.escalationBegin) {
                this.escalationBegin = new Date(this.caseProp.escalationBegin ?? Date.now());
            }
        },
    },

    components: {
        ActionCard,
        CaseTypePicker,
        DateTimePicker,
        EnumField,
        TimeZonePicker,
    },
});
