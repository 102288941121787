import { ContactType } from "./contacts";
import { Fuel, UsageType } from "./inventory";
import axios from "axios";

export enum AcquisitionType {
    CASH = "CASH",
    LEASE = "LEASE",
    FINANCE = "FINANCE",
    SUBSCRIPTION = "SUBSCRIPTION",
}

export enum OpportunityTeamDistributionMethod {
    PARALLEL = "PARALLEL",
    ROUND_ROBIN = "ROUND_ROBIN",
    RANDOM = "RANDOM",
}

export interface OpportunityTeamEscalationMember {
    readonly userId: string;
    readonly escalationTimeMinutes: number;
}

export interface OpportunityTeamFilter {
    readonly opportunitySourceIds: string[];
    readonly contactTypes: ContactType[];
    readonly internalIdPrefix: string | null;
    readonly internalIdSuffix: string | null;
    readonly vehicleClasses: string[];
    readonly makes: string[];
    readonly models: string[];
    readonly usageTypes: UsageType[];
    readonly mileageFrom: number | null;
    readonly mileageTo: number | null;
    readonly fuels: Fuel[];
    readonly preOffer: boolean | null;
    readonly acquisitionTypes: AcquisitionType[];
    readonly tradeInRequested: boolean | null;
}

export enum OpportunityTeamVisibility {
    DEALER = "DEALER",
    SELECTED_DEALERS = "SELECTED_DEALERS",
    DEALERGROUP = "DEALERGROUP",
}

export interface OpportunityTeam {
    readonly id: string;
    readonly dealerId: string;
    readonly officeHoursId: string | null;
    readonly name: string;
    readonly notes: string | null;
    readonly memberUserIds: string[];
    readonly escalationMembers: OpportunityTeamEscalationMember[];
    readonly visibility: OpportunityTeamVisibility;
    readonly visibleForDealerIds: string[];
    readonly visibleForAgents: boolean;
    readonly distributionMethod: OpportunityTeamDistributionMethod;
    readonly distributionTimeoutMinutes: number | null;
    readonly onlyDistributeToOnlineUsers: boolean;
    readonly onlyDistributeToPresentUsers: boolean;
    readonly filters: OpportunityTeamFilter[];
    readonly fallbackFilter: boolean;
    readonly prioritized: boolean;
}

export interface OpportunityTeamForm {
    readonly officeHoursId: string | null;
    readonly name: string;
    readonly notes: string | null;
    readonly memberUserIds: string[];
    readonly escalationMembers: OpportunityTeamEscalationMember[];
    readonly visibility: OpportunityTeamVisibility;
    readonly visibleForDealerIds: string[];
    readonly visibleForAgents: boolean;
    readonly distributionMethod: OpportunityTeamDistributionMethod;
    readonly distributionTimeoutMinutes: number | null;
    readonly onlyDistributeToOnlineUsers: boolean;
    readonly onlyDistributeToPresentUsers: boolean;
    readonly filters: OpportunityTeamFilter[];
    readonly fallbackFilter: boolean;
    readonly prioritized: boolean;
}

interface OpportunityTeamsApi {
    getAll(): Promise<OpportunityTeam[]>;
    getByDealer(dealerId: string): Promise<OpportunityTeam[]>;
    getById(id: string): Promise<OpportunityTeam>;
    updateOrder(dealerId: string, ids: string[]): Promise<void>;
    add(dealerId: string, opportunityTeamForm: OpportunityTeamForm): Promise<void>;
    edit(id: string, opportunityTeamForm: OpportunityTeamForm): Promise<void>;
    delete(id: string): Promise<void>;
}

export const opportunityTeamsApi: OpportunityTeamsApi = {
    async getAll() {
        return (await axios.get(`/api/opportunity-teams`)).data;
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/opportunity-teams/${dealerId}`)).data;
    },

    async getById(id) {
        return (await axios.get(`/api/opportunity-teams/_/${id}`)).data;
    },

    async updateOrder(dealerId, ids) {
        await axios.post(`/api/opportunity-teams/${dealerId}/_update-order`, ids);
    },

    async add(dealerId, opportunityTeamForm) {
        await axios.post(`/api/opportunity-teams/${dealerId}`, opportunityTeamForm);
    },

    async edit(id, opportunityTeamForm) {
        await axios.put(`/api/opportunity-teams/_/${id}`, opportunityTeamForm);
    },

    async delete(id) {
        await axios.delete(`/api/opportunity-teams/_/${id}`);
    },
};
