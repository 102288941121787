
import Vue from "vue";

export default Vue.extend({
    props: {
        expanded: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            expandedProp: this.expanded,
        };
    },
});
