
import { OutgoingSmsAssistantState, outgoingSmsAssistantState } from "./outgoingSmsAssistantState";
import { ContactData } from "@/api/contacts";
import { outgoingSmsApi, OutgoingSmsForm } from "@/api/outgoingSms";
import { showConfirm } from "@/app/messageUtil";
import ContactAndVehiclePicker from "@/app/pages/contacts/ContactAndVehiclePicker.vue";
import OutgoingSmsFormCard from "@/app/pages/outgoingsms/OutgoingSmsFormCard.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        persistent: {
            type: Boolean,
            default: true,
        },
        defaultText: {
            type: String,
            required: false,
        },
        jumpToMyActivities: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            contactData: null as ContactData | null,
            defaultReceiver: null as string | null,
            outgoingSmsAssistantState: this.persistent ? outgoingSmsAssistantState : new OutgoingSmsAssistantState(),
            sending: false,
        };
    },

    computed: {
        toNumbers(): string[] {
            return (this.contactData?.numbers ?? []).map((n) => n.number);
        },

        working(): boolean {
            return this.sending;
        },
    },

    methods: {
        async redirectToMyActivities() {
            await this.$router.push("/my-activities");
        },

        async reset() {
            if (
                await showConfirm(
                    this.$t("Eingaben zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?") as string
                )
            ) {
                this.outgoingSmsAssistantState.reset();
                this.defaultReceiver = null;
            }
        },

        async sendSms(outgoingSmsForm: OutgoingSmsForm) {
            this.sending = true;

            try {
                await outgoingSmsApi.sendSms(
                    this.outgoingSmsAssistantState.contactAndVehicleId?.contactId ?? null,
                    outgoingSmsForm
                );
                this.outgoingSmsAssistantState.reset();
                this.$emit("success");
                if (this.jumpToMyActivities) {
                    await this.redirectToMyActivities();
                }
            } finally {
                this.sending = false;
            }
        },

        async submit() {
            await (this.$refs.outgoingSmsFormCard as any).submit();
        },
    },

    components: {
        ContactAndVehiclePicker,
        OutgoingSmsFormCard,
    },
});
