import axios from "axios";

export interface DealerSettingsParameters {
    readonly dealerIds: string[];
    readonly systemEmailAliasParameters: DealerSystemEmailAliasParameters[];
}

export interface DealerSystemEmailAliasParameters {
    readonly dealerId: string;
    readonly importSystemEmailAliases: boolean;
    readonly systemEmailAliasesSuffix: string | null;
}

interface InitializationApi {
    initializeDealers(
        dealerSettingsParameters: DealerSettingsParameters,
        dealerSettingsFile: File,
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;

    initializeGlobalSettings(
        globalSettingsFile: File,
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;

    initializeWorkingHoursTemplates(
        userIds: string[],
        workingHoursTemplatesInitializationFile: File,
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
}

export const initializationApi: InitializationApi = {
    async initializeDealers(dealerSettingsParameters, dealerSettingsFile, onUploadProgress) {
        const formData = new FormData();

        formData.append("dealerSettingsFile", dealerSettingsFile);

        formData.append(
            `dealerSettingsParameters`,
            new Blob([JSON.stringify(dealerSettingsParameters)], { type: "application/json" })
        );

        await axios.post("/api/initialization/_/_initialize-dealers", formData, {
            onUploadProgress,
        });
    },

    async initializeGlobalSettings(globalSettingsFile, onUploadProgress) {
        const formData = new FormData();
        formData.append("globalSettingsFile", globalSettingsFile);

        await axios.post("/api/initialization/_/_initialize-global-settings", formData, {
            onUploadProgress,
        });
    },

    async initializeWorkingHoursTemplates(userIds, workingHoursTemplatesInitializationFile, onUploadProgress) {
        const formData = new FormData();

        formData.append("workingHoursTemplatesInitializationFile", workingHoursTemplatesInitializationFile);
        formData.append("userIds", new Blob([JSON.stringify(userIds)], { type: "application/json" }));

        await axios.post("/api/initialization/_/_initialize-working-hours-templates", formData, {
            onUploadProgress,
        });
    },
};
