import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface RenaultRaceLeadSourceMapping {
    readonly renaultRaceLeadSource: string;
    readonly opportunitySourceId: string;
}

export interface RenaultRaceLeadSourceMappingForm {
    readonly renaultRaceLeadSource: string;
    readonly opportunitySourceId: string;
}

interface RenaultRaceLeadSourceMappingsApi {
    edit(renaultRaceLeadSourceMappingForms: RenaultRaceLeadSourceMappingForm[]): Promise<void>;
    getAll(): Promise<RenaultRaceLeadSourceMapping[]>;
}

export const renaultRaceLeadSourceMappingsApi: RenaultRaceLeadSourceMappingsApi = {
    async edit(renaultRaceLeadSourceMappingForms) {
        await axios.put("/api/renault-race-lead-source-mappings", renaultRaceLeadSourceMappingForms);
    },

    async getAll() {
        return cloneObject((await axios.get("/api/renault-race-lead-source-mappings")).data);
    },
};
