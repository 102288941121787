
import { getPhoneticSpelling, PhoneticGraphemeSpelling } from "@/util/phoneticSpellingUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

interface WordSpelling {
    readonly text: string;
    readonly graphemes: PhoneticGraphemeSpelling[];
}

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        locale: {
            type: String,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
        xSmall: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        words(): WordSpelling[] {
            const normalizedText = (this.text ?? "").replace(/\s+/g, " ");
            const trimmed = trimAndReturnNullIfEmpty(normalizedText);

            if (!trimmed) {
                return [];
            }

            const splitChars = "[-+*/&\.@]";

            return trimmed
                .split(new RegExp(`\\s|(?=${splitChars})|(?<=${splitChars})`, "g"))
                .map((word) => ({
                    text: word,
                    graphemes: getPhoneticSpelling(word, this.locale),
                }))
                .map((spelling) => {
                    const isSingletonGraphemeWithoutDistinctSpelling =
                        spelling.graphemes.length === 1 && spelling.graphemes[0].spelling === spelling.text;
                    const isDigitSequence = !!spelling.text.match(/^\d+$/);

                    return {
                        text: spelling.text,
                        graphemes:
                            isSingletonGraphemeWithoutDistinctSpelling || isDigitSequence ? [] : spelling.graphemes,
                    };
                });
        },
    },
});
