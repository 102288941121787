
import { Contact, ContactNumber, EmailAddress } from "@/api/contacts";
import { opportunitiesApi, Opportunity } from "@/api/opportunities";
import ActionCard from "@/app/pages/ActionCard.vue";
import PreferredContactFormContent from "@/app/pages/contacts/PreferredContactFormContent.vue";
import { PreferredContact } from "@/app/pages/contacts/preferredContact";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
    },

    data() {
        return {
            preferredContact: {
                preferredContactMethod: this.opportunityProp.preferredContactMethod,
                preferredContactDetails: this.opportunityProp.preferredContactDetails,
                earliestContactTime: this.opportunityProp.earliestContactTime,
                latestContactTime: this.opportunityProp.latestContactTime,
            } as PreferredContact,
            timeZone: dealersStore.dealerById(this.opportunityProp.dealerId)!.timeZone,
            working: false,
        };
    },

    computed: {
        emailAddresses(): EmailAddress[] {
            if (this.contact?.deleted) {
                return [];
            }

            return this.contact?.contactData.emailAddresses ?? [];
        },

        numbers(): ContactNumber[] {
            if (this.contact?.deleted) {
                return [];
            }

            return this.contact?.contactData.numbers ?? [];
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async save() {
            this.working = true;
            try {
                await opportunitiesApi.updatePreferredContact(this.opportunityProp.id, this.preferredContact);
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        PreferredContactFormContent,
    },
});
