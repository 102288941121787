
import { Department, departmentsApi } from "@/api/departments";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            valid: true,
            isWorking: false,

            // FORM FIELDS
            name: this.department?.name || "",

            // RULES
            nameRules: notEmpty().maxLength(300),
        };
    },

    props: {
        dealerId: {
            type: String,
            required: true,
        },
        department: {
            type: Object as () => Department | null,
            required: false,
        },
    },

    methods: {
        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            try {
                if (this.department) {
                    await departmentsApi.edit(this.dealerId, this.department.id, { name: this.name });
                } else {
                    await departmentsApi.add(this.dealerId, { name: this.name });
                }
            } finally {
                this.isWorking = false;
            }
            this.$emit("success");
        },

        close() {
            this.$emit("close");
        },
    },
});
