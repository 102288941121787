import axios from "axios";

export interface EmailTemplate {
    readonly id: string;
    readonly name: string;
    readonly content: string;
    readonly locale: string;
    readonly attachments: EmailTemplateAttachment[];
}

export interface EmailTemplateAttachment {
    readonly id: number;
    readonly filename: string;
    readonly contentHash: string;
    readonly contentType: string;
    readonly size: number;
}

export interface EmailTemplateForm {
    readonly name: string;
    readonly content: string;
    readonly locale: string;
    readonly attachments: EmailTemplateAttachmentForm[];
}

export interface EmailTemplateAttachmentForm {
    readonly filename: string;
    readonly contentHash: string;
    readonly contentType: string;
}

interface EmailTemplatesApi {
    list(fragment: boolean): Promise<EmailTemplate[]>;
    add(
        emailTemplateForm: EmailTemplateForm,
        fragment: boolean,
        files: File[],
        progressCallback?: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
    edit(
        id: string,
        emailTemplateForm: EmailTemplateForm,
        files: File[],
        progressCallback?: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
    editOrder(ids: string[]): Promise<void>;
    delete(id: string): Promise<void>;
    generateAttachmentLink(attachment: EmailTemplateAttachment, download?: boolean): string;
}

export const emailTemplatesApi: EmailTemplatesApi = {
    async list(fragment) {
        return (await axios.get("/api/email-templates", { params: { fragment } })).data;
    },

    async add(emailTemplateForm, fragment, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }
        formData.append("form", new Blob([JSON.stringify(emailTemplateForm)], { type: "application/json" }));
        await axios.post("/api/email-templates", formData, { params: { fragment }, onUploadProgress });
    },

    async edit(id, emailTemplateForm, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }
        formData.append("form", new Blob([JSON.stringify(emailTemplateForm)], { type: "application/json" }));
        await axios.put(`/api/email-templates/${id}`, formData, { onUploadProgress });
    },

    async editOrder(ids) {
        await axios.post("/api/email-templates/_edit-order", ids);
    },

    async delete(id) {
        await axios.delete(`/api/email-templates/${id}`);
    },

    generateAttachmentLink(attachment, download) {
        return `/api/email-templates/attachments/${attachment.id}/${attachment.contentHash}/${encodeURIComponent(
            attachment.filename.replace(/\//g, "-")
        )}${download ? "?dl=true" : ""}`;
    },
};
