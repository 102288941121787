
import AuditEventTimelineItem from "./AuditEventTimelineItem.vue";
import { canAddNote, getOpportunityStatus, OpportunityInventoryVehicleWithInventoryVehicle } from "./opportunityUtils";
import { Contact, ContactMethod } from "@/api/contacts";
import { Forbidden } from "@/api/errors";
import { IncomingCallResult, incomingCallsApi } from "@/api/incomingCalls";
import { IncomingEmail, incomingEmailsApi } from "@/api/incomingEmails";
import { IncomingSms, incomingSmsApi } from "@/api/incomingSms";
import { incomingWhatsAppApi, IncomingWhatsAppMessage } from "@/api/incomingWhatsApp";
import { InventoryVehicle } from "@/api/inventory";
import { inventoryVehicleInquiryApi, InventoryVehicleInquiryMatch } from "@/api/inventoryVehicleInquiries";
import { Note, NoteForm, notesApi } from "@/api/notes";
import {
    isOpportunityAuditEventsUpdatedNotification,
    isOpportunityIncomingCallsUpdatedNotification,
    isOpportunityIncomingEmailsUpdatedNotification,
    isOpportunityIncomingSmsUpdatedNotification,
    isOpportunityIncomingWhatsAppUpdatedNotification,
    isOpportunityInventoryVehicleInquiryMatchesUpdatedNotification,
    isOpportunityNotesUpdatedNotification,
    isOpportunityOutgoingCallsUpdatedNotification,
    isOpportunityOutgoingCitnowVideosUpdatedNotification,
    isOpportunityOutgoingEmailsUpdatedNotification,
    isOpportunityOutgoingSmsUpdatedNotification,
    isOpportunityOutgoingWhatsAppUpdatedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { opportunitiesApi, Opportunity, OpportunityAuditEvent, OpportunityStatus } from "@/api/opportunities";
import { OutgoingCall, OutgoingCallForm, outgoingCallsApi } from "@/api/outgoingCalls";
import {
    OutgoingCitnowVideo,
    OutgoingCitnowVideoCreationForm,
    outgoingCitnowVideosApi,
} from "@/api/outgoingCitnowVideos";
import {
    OutgoingEmail,
    OutgoingEmailForm,
    outgoingEmailsApi,
    TransactionEmailHeader,
    TransactionEmailReceiver,
    TransactionEmailReceiverType,
} from "@/api/outgoingEmails";
import { OutgoingSms, outgoingSmsApi, OutgoingSmsForm } from "@/api/outgoingSms";
import { outgoingWhatsAppApi, OutgoingWhatsAppMessage } from "@/api/outgoingWhatsApp";
import { WhatsAppTemplate } from "@/api/whatsAppAccounts";
import { getReplyHeaders, getReplySubject, ReplyToBaseEmail } from "@/app/emailUtils";
import { renderVehicleSummaryTitle, VehicleSummary } from "@/app/inventoryUtils";
import {
    getMostRelevantEmailAddress,
    isIncomingCallTimelineItem,
    isIncomingEmailTimelineItem,
    isIncomingSmsTimelineItem,
    isIncomingWhatsAppMessageTimelineItem,
    isMainOutgoingEmailTimelineItem,
    isOutgoingCallTimelineItem,
    isOutgoingSmsTimelineItem,
    isOutgoingWhatsAppMessageTimelineItem,
    TimelineItem,
    TimelineItemType,
} from "@/app/issueUtils";
import { showConfirm, showInfo } from "@/app/messageUtil";
import IncomingCallTimelineItem from "@/app/pages/incomingcalls/IncomingCallTimelineItem.vue";
import IncomingEmailTimelineItem from "@/app/pages/incomingemails/IncomingEmailTimelineItem.vue";
import IncomingSmsTimelineItem from "@/app/pages/incomingsms/IncomingSmsTimelineItem.vue";
import IncomingWhatsAppTimelineItem from "@/app/pages/incomingwhatsapp/IncomingWhatsAppTimelineItem.vue";
import InventoryVehicleInquiryMatchTimelineItem from "@/app/pages/inventoryvehicleinquiry/InventoryVehicleInquiryMatchTimelineItem.vue";
import NoteFormTimelineItem from "@/app/pages/notes/NoteFormTimelineItem.vue";
import NoteTimelineItem from "@/app/pages/notes/NoteTimelineItem.vue";
import OutgoingCallFormTimelineItem from "@/app/pages/outgoingcalls/OutgoingCallFormTimelineItem.vue";
import OutgoingCallTimelineItem from "@/app/pages/outgoingcalls/OutgoingCallTimelineItem.vue";
import OutgoingCitnowVideoFormTimelineItem from "@/app/pages/outgoingcitnowvideos/OutgoingCitnowVideoFormTimelineItem.vue";
import OutgoingCitnowVideoTimelineItem from "@/app/pages/outgoingcitnowvideos/OutgoingCitnowVideoTimelineItem.vue";
import OutgoingEmailFormTimelineItem from "@/app/pages/outgoingemails/OutgoingEmailFormTimelineItem.vue";
import OutgoingEmailTimelineItem from "@/app/pages/outgoingemails/OutgoingEmailTimelineItem.vue";
import OutgoingSmsFormTimelineItem from "@/app/pages/outgoingsms/OutgoingSmsFormTimelineItem.vue";
import OutgoingSmsTimelineItem from "@/app/pages/outgoingsms/OutgoingSmsTimelineItem.vue";
import OutgoingWhatsAppFormTimelineItem from "@/app/pages/outgoingwhatsapp/OutgoingWhatsAppFormTimelineItem.vue";
import OutgoingWhatsAppTemplateFormTimelineItem from "@/app/pages/outgoingwhatsapp/OutgoingWhatsAppTemplateFormTimelineItem.vue";
import OutgoingWhatsAppTimelineItem from "@/app/pages/outgoingwhatsapp/OutgoingWhatsAppTimelineItem.vue";
import { UrlWithKey } from "@/app/placeholderUtils";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { ActionLimiter } from "@/util/debounce";
import Vue from "vue";

export default Vue.extend({
    props: {
        canManageOpportunity: {
            type: Boolean,
            required: true,
        },
        canRequestCitnowVideo: {
            type: Boolean,
            required: true,
        },
        canSendEmail: {
            type: Boolean,
            required: true,
        },
        canSendSms: {
            type: Boolean,
            required: true,
        },
        canSendWhatsapp: {
            type: Boolean,
            required: true,
        },
        canStartCall: {
            type: Boolean,
            required: true,
        },
        canViewContent: {
            type: Boolean,
            required: true,
        },
        contact: {
            type: Object as () => Contact,
            required: false,
        },
        opportunityInventoryVehiclesWithInventoryVehicle: {
            type: Array as () => OpportunityInventoryVehicleWithInventoryVehicle[],
            required: true,
        },
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
        vehicleSummaries: {
            type: Array as () => VehicleSummary[],
            required: true,
        },
    },

    data() {
        return {
            // action limiters
            loadAuditEventsLimiter: new ActionLimiter(true),
            loadIncomingCallsLimiter: new ActionLimiter(true),
            loadIncomingEmailsLimiter: new ActionLimiter(true),
            loadIncomingSmsLimiter: new ActionLimiter(true),
            loadIncomingWhatsAppMessagesLimiter: new ActionLimiter(true),
            loadInventoryVehicleInquiryMatchesLimiter: new ActionLimiter(true),
            loadNotesLimiter: new ActionLimiter(true),
            loadOutgoingCallsLimiter: new ActionLimiter(true),
            loadOutgoingCitnowVideoLimiter: new ActionLimiter(true),
            loadOutgoingEmailsLimiter: new ActionLimiter(true),
            loadOutgoingSmsLimiter: new ActionLimiter(true),
            loadOutgoingWhatsAppMessagesLimiter: new ActionLimiter(true),
            showOutgoingEmailFormLimiter: new ActionLimiter(true),
            updateReadLimiter: new ActionLimiter(true),

            // activities
            auditEvents: [] as OpportunityAuditEvent[],
            incomingCalls: [] as IncomingCallResult[],
            incomingEmails: [] as IncomingEmail[],
            incomingSms: [] as IncomingSms[],
            incomingWhatsApps: [] as IncomingWhatsAppMessage[],
            inventoryVehicleInquiryMatches: [] as InventoryVehicleInquiryMatch[],
            notes: [] as Note[],
            outgoingCalls: [] as OutgoingCall[],
            outgoingCitnowVideos: [] as OutgoingCitnowVideo[],
            outgoingEmails: [] as OutgoingEmail[],
            outgoingSms: [] as OutgoingSms[],
            outgoingWhatsApps: [] as OutgoingWhatsAppMessage[],

            // work indicators
            activeWorkers: 0,
            addingNote: false,
            requestingCitnowVideo: false,
            sendingEmail: false,
            sendingSms: false,
            sendingWhatsApp: false,
            startingCall: false,

            // misc
            emailAttachmentsUploadProgress: 0,
            outgoingEmailReceivers: [] as TransactionEmailReceiver[],
            outgoingEmailHeaders: [] as TransactionEmailHeader[],
            outgoingEmailDefaultSubject: "",
            outgoingEmailContentForQuote: "",
            outgoingEmailAutomaticQuote: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            outboundNumber: null as string | null,
            smsReceiver: null as string | null,
            timelineForm: null as "note" | "call" | "sms" | "email" | "whatsapp" | "citnow-video" | null,
            TimelineItemType,
        };
    },

    computed: {
        canAddNote(): boolean {
            return canAddNote(this.opportunityProp);
        },

        defaultCountry(): string {
            if (this.contact && !this.contact.deleted && this.contact.contactData.country) {
                return this.contact.contactData.country;
            }

            return (
                dealersStore.dealerById(this.opportunityProp.dealerId)?.country ||
                configStore.configuration.defaultCountry
            );
        },

        defaultEmailSubject(): string {
            const vehicleCaption = this.vehicleSummaries.length
                ? renderVehicleSummaryTitle({ ...this.vehicleSummaries[0], internalId: null })
                : null;

            return `${this.$t("Ihre Anfrage")}${vehicleCaption ? ` (${vehicleCaption})` : ""} [${
                this.opportunityProp.id
            }]`;
        },

        inventoryVehicles(): InventoryVehicle[] {
            return this.opportunityInventoryVehiclesWithInventoryVehicle
                .filter((i) => i.inventoryVehicle)
                .map((i) => i.inventoryVehicle!);
        },

        latestIncomingWhatsAppMessage(): IncomingWhatsAppMessage | null {
            const msgs = [...this.incomingWhatsApps].sort((a, b) => b.created.getTime() - a.created.getTime());

            if (!msgs.length) {
                return null;
            }

            return msgs[0];
        },

        mostRelevantEmailAddress(): string | null {
            return getMostRelevantEmailAddress(this.opportunityProp, this.contact, this.timelineItems)?.address ?? null;
        },

        opportunityStatus(): OpportunityStatus {
            return getOpportunityStatus(this.opportunityProp);
        },

        opportunityUrlsWithKey(): UrlWithKey[] {
            return this.opportunityProp.urls.filter((url) => !!url.key).map((url) => ({ url: url.url, key: url.key! }));
        },

        timelineItems(): TimelineItem[] {
            return [
                ...this.toTimelineItems(
                    this.auditEvents.map((a) => ({ id: JSON.stringify(a), ...a })),
                    TimelineItemType.AUDIT_EVENT
                ),
                ...this.toTimelineItems(this.incomingCalls, TimelineItemType.INCOMING_CALL),
                ...this.toTimelineItems(this.incomingEmails, TimelineItemType.INCOMING_EMAIL),
                ...this.toTimelineItems(this.incomingSms, TimelineItemType.INCOMING_SMS),
                ...this.toTimelineItems(this.incomingWhatsApps, TimelineItemType.INCOMING_WHATSAPP),
                ...this.toTimelineItems(
                    this.inventoryVehicleInquiryMatches,
                    TimelineItemType.INVENTORY_VEHICLE_INQUIRY_MATCH
                ),
                ...this.toTimelineItems(this.notes, TimelineItemType.NOTE),
                ...this.toTimelineItems(this.outgoingCalls, TimelineItemType.OUTGOING_CALL),
                ...this.toTimelineItems(this.outgoingCitnowVideos, TimelineItemType.OUTGOING_CITNOW_VIDEO),
                ...this.toTimelineItems(this.outgoingEmails, TimelineItemType.OUTGOING_EMAIL),
                ...this.toTimelineItems(this.outgoingSms, TimelineItemType.OUTGOING_SMS),
                ...this.toTimelineItems(this.outgoingWhatsApps, TimelineItemType.OUTGOING_WHATSAPP),
            ].sort((a, b) => b.created.getTime() - a.created.getTime());
        },

        whatsAppAccountId(): string | null {
            if (!this.latestIncomingWhatsAppMessage) {
                return null;
            }

            const diff = now().getTime() - this.latestIncomingWhatsAppMessage.created.getTime();

            if (diff > 23.5 * 60 * 60 * 1000) {
                return null;
            }

            return this.latestIncomingWhatsAppMessage.accountId;
        },

        whatsAppReceiver(): string | null {
            return this.latestIncomingWhatsAppMessage?.fromNumber || null;
        },

        whatsAppSender(): string | null {
            return this.latestIncomingWhatsAppMessage?.toNumber || null;
        },
    },

    methods: {
        async addNote(noteForm: NoteForm) {
            this.addingNote = true;
            try {
                await opportunitiesApi.addNote(this.opportunityProp.id, noteForm);
                this.timelineForm = null;
            } finally {
                this.addingNote = false;
            }
        },

        async assignYourselfOrIsCtUserForOutgoingCommunication(text: string) {
            if (userSession.isCtUser() || this.opportunityProp.assigneeIds.includes(userSession.userId!)) {
                return true;
            }

            if (!this.canManageOpportunity) {
                return false;
            }

            if (!(await showConfirm(this.$t("Die Verkaufschance muss Ihnen zugeteilt sein") as string, text))) {
                return false;
            }

            this.$emit("worker:start");
            try {
                if (this.opportunityStatus === OpportunityStatus.CLOSED) {
                    await opportunitiesApi.reopenOpportunity(this.opportunityProp.id);
                }

                if (this.opportunityProp.assigneeIds.length) {
                    await opportunitiesApi.updateAssignees(this.opportunityProp.id, [
                        ...this.opportunityProp.assigneeIds,
                        userSession.userId!,
                    ]);
                } else {
                    await opportunitiesApi.assignToSelf(this.opportunityProp.id);
                }
            } finally {
                this.$emit("worker:end");
            }

            return true;
        },

        getMostRelevantPhone(): string | null {
            for (const timelineItem of this.timelineItems) {
                if (isIncomingWhatsAppMessageTimelineItem(timelineItem)) {
                    return timelineItem.fromNumber;
                } else if (isOutgoingWhatsAppMessageTimelineItem(timelineItem)) {
                    return timelineItem.toNumber;
                } else if (isIncomingCallTimelineItem(timelineItem)) {
                    return timelineItem.fromNumber;
                } else if (isOutgoingCallTimelineItem(timelineItem)) {
                    return timelineItem.outboundNumber;
                } else if (isIncomingSmsTimelineItem(timelineItem)) {
                    return timelineItem.fromNumber;
                } else if (isOutgoingSmsTimelineItem(timelineItem)) {
                    return timelineItem.toNumber;
                }
            }

            if (
                (this.opportunityProp.preferredContactMethod === ContactMethod.PHONE ||
                    this.opportunityProp.preferredContactMethod === ContactMethod.SMS) &&
                this.opportunityProp.preferredContactDetails
            ) {
                return this.opportunityProp.preferredContactDetails;
            }

            if (this.contact && !this.contact.deleted && this.contact.contactData.numbers.length) {
                return this.contact.contactData.numbers[0].number;
            }

            return null;
        },

        hideTimelineForm() {
            this.timelineForm = null;
        },

        async requestCitnowVideo(dealerId: string, lid: string, form: OutgoingCitnowVideoCreationForm) {
            this.requestingCitnowVideo = true;
            try {
                await opportunitiesApi.requestCitnowVideo(this.opportunityProp.id, dealerId, lid, form);
                this.timelineForm = null;
            } finally {
                this.requestingCitnowVideo = false;
            }
        },

        async sendEmail(outgoingEmailForm: OutgoingEmailForm, files: File[], offerVehicles: InventoryVehicle[]) {
            if (
                !(await this.assignYourselfOrIsCtUserForOutgoingCommunication(
                    this.$t(
                        "Wenn Sie eine E-Mail senden, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?"
                    ) as string
                ))
            ) {
                return;
            }

            this.sendingEmail = true;
            try {
                await opportunitiesApi.sendEmail(
                    this.opportunityProp.id,
                    {
                        ...outgoingEmailForm,
                        htmlBody:
                            outgoingEmailForm.htmlBody + `\n<p><small>[OPP#${this.opportunityProp.id}]</small></p>`,
                    },
                    files,
                    ({ total, loaded }) => (this.emailAttachmentsUploadProgress = (100 * loaded) / total)
                );
                this.$emit("send:offerVehicles", offerVehicles);
                this.timelineForm = null;
            } catch (e) {
                Vue.nextTick(() => {
                    throw e;
                });
            } finally {
                this.sendingEmail = false;
            }
        },

        async sendSms(form: OutgoingSmsForm) {
            if (
                !(await this.assignYourselfOrIsCtUserForOutgoingCommunication(
                    this.$t(
                        "Wenn Sie eine SMS senden, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?"
                    ) as string
                ))
            ) {
                return;
            }

            this.sendingSms = true;
            try {
                await opportunitiesApi.sendSms(this.opportunityProp.id, form);
                this.timelineForm = null;
            } finally {
                this.sendingSms = false;
            }
        },

        async sendWhatsApp({
            text,
            files,
            toNumber,
            accountId,
            template,
        }: {
            text?: string;
            files?: File[];
            toNumber?: string;
            accountId?: string;
            template?: WhatsAppTemplate;
        }) {
            if (
                !(await this.assignYourselfOrIsCtUserForOutgoingCommunication(
                    this.$t(
                        "Wenn Sie eine WhatsApp-Nachricht senden, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?"
                    ) as string
                ))
            ) {
                return;
            }

            this.sendingWhatsApp = true;
            try {
                await opportunitiesApi.sendWhatsAppMessage(
                    this.opportunityProp.id,
                    accountId || this.whatsAppAccountId!,
                    {
                        toNumber: toNumber || this.whatsAppReceiver!,
                        text: template?.text || text || null,
                        templateNamespace: template?.namespace || null,
                        templateName: template?.name || null,
                        templateLanguage: template?.language || null,
                    },
                    files || [],
                    () => undefined
                );
                this.timelineForm = null;
            } finally {
                this.sendingWhatsApp = false;
            }
        },

        showNoteForm() {
            this.timelineForm = "note";
        },

        showOutgoingCallForm(phone: string | null) {
            if (!this.canStartCall) {
                showInfo(
                    this.$t("Sie haben nicht die Berechtigung, für diesen Vorgang einen Anruf zu starten.") as string
                );
                return;
            }

            this.outboundNumber = phone || this.getMostRelevantPhone();
            this.timelineForm = "call";
        },

        showOutgoingCitnowVideoForm() {
            this.timelineForm = "citnow-video";
        },

        async showOutgoingEmailFormForReply(reply: ReplyToBaseEmail) {
            await this.showOutgoingEmailFormLimiter.execute(async () => {
                if (!this.canSendEmail) {
                    showInfo(
                        this.$t("Sie haben nicht die Berechtigung, für diesen Vorgang eine E-Mail zu senden.") as string
                    );
                    return;
                }

                this.timelineForm = null;

                await this.$nextTick();

                const receivers: TransactionEmailReceiver[] = [
                    ...reply.toReceivers.map((r) => ({
                        ...r,
                        type: TransactionEmailReceiverType.TO,
                    })),
                    ...reply.ccReceivers.map((r) => ({
                        ...r,
                        type: TransactionEmailReceiverType.CC,
                    })),
                    ...reply.bccReceivers.map((r) => ({
                        ...r,
                        type: TransactionEmailReceiverType.BCC,
                    })),
                ];

                const receiver = reply.replyToAddress
                    ? { name: reply.replyToName, address: reply.replyToAddress }
                    : { name: reply.fromName, address: reply.fromAddress };

                if (!receivers.some((r) => r.address === receiver.address)) {
                    receivers.unshift({ ...receiver, type: TransactionEmailReceiverType.TO });
                }

                this.outgoingEmailReceivers = receivers;

                this.outgoingEmailHeaders = getReplyHeaders([reply]);

                this.outgoingEmailDefaultSubject = reply.subject
                    ? getReplySubject(reply.subject)
                    : this.defaultEmailSubject;
                this.outgoingEmailContentForQuote = reply.htmlBodyForQuote;
                this.outgoingEmailAutomaticQuote = true;

                this.timelineForm = "email";

                await this.$nextTick();
                await this.$vuetify.goTo(this.$refs.emailForm as Vue);
            });
        },

        async showOutgoingEmailForm(email: string | null) {
            await this.showOutgoingEmailFormLimiter.execute(async () => {
                if (!this.canSendEmail) {
                    showInfo(
                        this.$t("Sie haben nicht die Berechtigung, für diesen Vorgang eine E-Mail zu senden.") as string
                    );
                    return;
                }

                this.outgoingEmailReceivers = [];

                if (email) {
                    this.outgoingEmailReceivers = [
                        {
                            name: null,
                            address: email,
                            type: TransactionEmailReceiverType.TO,
                        },
                    ];
                } else {
                    const mostRelevantReceiver = getMostRelevantEmailAddress(
                        this.opportunityProp,
                        this.contact,
                        this.timelineItems
                    );

                    if (mostRelevantReceiver) {
                        this.outgoingEmailReceivers = [
                            {
                                ...mostRelevantReceiver,
                                type: TransactionEmailReceiverType.TO,
                            },
                        ];
                    }
                }

                this.outgoingEmailHeaders = getReplyHeaders(
                    (this.timelineItems.filter(
                        (i) => isMainOutgoingEmailTimelineItem(i) || isIncomingEmailTimelineItem(i)
                    ) as unknown[]) as (IncomingEmail | OutgoingEmail)[]
                );

                this.outgoingEmailDefaultSubject =
                    [
                        ...this.timelineItems.filter(isMainOutgoingEmailTimelineItem),
                        ...this.timelineItems.filter(isIncomingEmailTimelineItem).filter((i) => i.user),
                    ]
                        .sort((a, b) => b.created.getTime() - a.created.getTime())
                        .map((i) => i.subject)
                        .filter((subject) => !!subject)
                        .map((subject) => getReplySubject(subject!))
                        .pop() ?? this.defaultEmailSubject;

                const lastEmail = this.timelineItems.find(
                    (i) => isIncomingEmailTimelineItem(i) || isMainOutgoingEmailTimelineItem(i)
                );

                if (lastEmail && isMainOutgoingEmailTimelineItem(lastEmail)) {
                    this.outgoingEmailContentForQuote = await outgoingEmailsApi.getHtmlBody(
                        lastEmail.id,
                        {
                            opportunityId: this.opportunityProp.id,
                        },
                        true
                    );
                } else if (lastEmail && isIncomingEmailTimelineItem(lastEmail)) {
                    this.outgoingEmailContentForQuote = await incomingEmailsApi.getHtmlBody(
                        lastEmail.id,
                        {
                            opportunityId: this.opportunityProp.id,
                        },
                        true
                    );
                } else {
                    this.outgoingEmailContentForQuote = "";
                }
                this.outgoingEmailAutomaticQuote = false;

                this.timelineForm = "email";
            });
        },

        showOutgoingSmsForm(phone: string | null) {
            if (!this.canSendSms) {
                showInfo(this.$t("Sie haben nicht die Berechtigung, für diesen Vorgang eine SMS zu senden.") as string);
                return;
            }

            this.smsReceiver = phone || this.getMostRelevantPhone();
            this.timelineForm = "sms";
        },

        showOutgoingWhatsAppForm() {
            if (!this.canSendWhatsapp) {
                showInfo(
                    this.$t(
                        "Sie haben nicht die Berechtigung, für diesen Vorgang eine WhatsApp-Nachricht zu senden."
                    ) as string
                );
                return;
            }

            this.timelineForm = "whatsapp";
        },

        async startCall(outgoingCallForm: OutgoingCallForm) {
            if (
                !(await this.assignYourselfOrIsCtUserForOutgoingCommunication(
                    this.$t(
                        "Wenn Sie einen Anruf starten, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?"
                    ) as string
                ))
            ) {
                return;
            }

            this.startingCall = true;
            try {
                await opportunitiesApi.startCall(this.opportunityProp.id, outgoingCallForm);
                this.timelineForm = null;
            } finally {
                this.startingCall = false;
            }
        },

        toTimelineItems(items: { id: string; created: Date }[], type: TimelineItemType): TimelineItem[] {
            return items.map((i) => ({ ...i, timelineItemType: type }));
        },
    },

    async mounted() {
        opportunitiesApi.updateRead(this.opportunityProp.id);

        this.activeWorkers++;
        try {
            // type error when >10 arguments for Promise.all
            const [
                [
                    auditEvents,
                    incomingCalls,
                    incomingEmails,
                    incomingSms,
                    incomingWhatApps,
                    inventoryVehicleInquiryMatches,
                    notes,
                ],
                outgoingCalls,
                outgoingCitnowVideos,
                outgoingEmails,
                outgoingSms,
                outgoingWhatsApps,
            ] = await Promise.all([
                Promise.all([
                    opportunitiesApi.getAuditEvents(this.opportunityProp.id),
                    incomingCallsApi.getIncomingCallsByOpportunity(this.opportunityProp.id),
                    incomingEmailsApi.getIncomingEmails({ opportunityId: this.opportunityProp.id }),
                    incomingSmsApi.getIncomingSmsByOpportunity(this.opportunityProp.id),
                    incomingWhatsAppApi.getMessagesByOpportunity(this.opportunityProp.id),
                    inventoryVehicleInquiryApi.getMatchesByOpportunity(this.opportunityProp.id),
                    notesApi.getByOpportunity(this.opportunityProp.id),
                ]),
                outgoingCallsApi.getOutgoingCallsByOpportunity(this.opportunityProp.id),
                outgoingCitnowVideosApi.getOutgoingCitnowVideosByOpportunity(this.opportunityProp.id),
                outgoingEmailsApi.getOutgoingEmails({ opportunityId: this.opportunityProp.id }),
                outgoingSmsApi.getOutgoingSmsByOpportunity(this.opportunityProp.id),
                outgoingWhatsAppApi.getMessagesByOpportunity(this.opportunityProp.id),
            ]);

            this.notes = notes!;
            this.auditEvents = auditEvents!;
            this.incomingCalls = incomingCalls!;
            this.incomingEmails = incomingEmails!;
            this.incomingSms = incomingSms!;
            this.incomingWhatsApps = incomingWhatApps!;
            this.inventoryVehicleInquiryMatches = inventoryVehicleInquiryMatches;
            this.outgoingCalls = outgoingCalls!;
            this.outgoingCitnowVideos = outgoingCitnowVideos!;
            this.outgoingEmails = outgoingEmails!;
            this.outgoingSms = outgoingSms!;
            this.outgoingWhatsApps = outgoingWhatsApps!;
        } catch (e) {
            if (!(e instanceof Forbidden)) {
                throw e;
            }

            this.$emit("issue:forbidden");
        } finally {
            this.$emit("incoming-emails", this.incomingEmails);
            this.$emit("incoming-whats-app-messages", this.incomingWhatsApps);
            this.$emit("outgoing-emails", this.outgoingEmails);
            this.$emit("outgoing-whats-app-messages", this.outgoingWhatsApps);

            this.activeWorkers--;
        }

        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            const isActivityUpdateNotification =
                isOpportunityAuditEventsUpdatedNotification(n) ||
                isOpportunityIncomingCallsUpdatedNotification(n) ||
                isOpportunityIncomingEmailsUpdatedNotification(n) ||
                isOpportunityIncomingSmsUpdatedNotification(n) ||
                isOpportunityIncomingWhatsAppUpdatedNotification(n) ||
                isOpportunityInventoryVehicleInquiryMatchesUpdatedNotification(n) ||
                isOpportunityNotesUpdatedNotification(n) ||
                isOpportunityOutgoingCallsUpdatedNotification(n) ||
                isOpportunityOutgoingCitnowVideosUpdatedNotification(n) ||
                isOpportunityOutgoingEmailsUpdatedNotification(n) ||
                isOpportunityOutgoingSmsUpdatedNotification(n) ||
                isOpportunityOutgoingWhatsAppUpdatedNotification(n);

            if (isActivityUpdateNotification) {
                if (this.opportunityProp.id === n.opportunityId) {
                    this.updateReadLimiter.execute(async () => opportunitiesApi.updateRead(this.opportunityProp.id));

                    this.activeWorkers++;
                    try {
                        if (isOpportunityAuditEventsUpdatedNotification(n)) {
                            await this.loadAuditEventsLimiter.execute(async () => {
                                this.auditEvents = await opportunitiesApi.getAuditEvents(n.opportunityId);
                            });
                        } else if (isOpportunityIncomingCallsUpdatedNotification(n)) {
                            await this.loadIncomingCallsLimiter.execute(async () => {
                                this.incomingCalls = await incomingCallsApi.getIncomingCallsByOpportunity(
                                    n.opportunityId
                                );
                            });
                        } else if (isOpportunityIncomingEmailsUpdatedNotification(n)) {
                            await this.loadIncomingEmailsLimiter.execute(async () => {
                                this.incomingEmails = await incomingEmailsApi.getIncomingEmails({
                                    opportunityId: n.opportunityId,
                                });
                                this.$emit("incoming-emails", this.incomingEmails);
                            });
                        } else if (isOpportunityIncomingSmsUpdatedNotification(n)) {
                            await this.loadIncomingSmsLimiter.execute(async () => {
                                this.incomingSms = await incomingSmsApi.getIncomingSmsByOpportunity(n.opportunityId);
                            });
                        } else if (isOpportunityIncomingWhatsAppUpdatedNotification(n)) {
                            await this.loadIncomingWhatsAppMessagesLimiter.execute(async () => {
                                this.incomingWhatsApps = await incomingWhatsAppApi.getMessagesByOpportunity(
                                    n.opportunityId
                                );
                                this.$emit("incoming-whats-app-messages", this.incomingWhatsApps);
                            });
                        } else if (isOpportunityInventoryVehicleInquiryMatchesUpdatedNotification(n)) {
                            await this.loadInventoryVehicleInquiryMatchesLimiter.execute(async () => {
                                this.inventoryVehicleInquiryMatches = await inventoryVehicleInquiryApi.getMatchesByOpportunity(
                                    n.opportunityId
                                );
                            });
                        } else if (isOpportunityNotesUpdatedNotification(n)) {
                            await this.loadNotesLimiter.execute(async () => {
                                this.notes = await notesApi.getByOpportunity(n.opportunityId);
                            });
                        } else if (isOpportunityOutgoingCallsUpdatedNotification(n)) {
                            await this.loadOutgoingCallsLimiter.execute(async () => {
                                this.outgoingCalls = await outgoingCallsApi.getOutgoingCallsByOpportunity(
                                    n.opportunityId
                                );
                            });
                        } else if (isOpportunityOutgoingCitnowVideosUpdatedNotification(n)) {
                            await this.loadOutgoingCitnowVideoLimiter.execute(async () => {
                                this.outgoingCitnowVideos = await outgoingCitnowVideosApi.getOutgoingCitnowVideosByOpportunity(
                                    n.opportunityId
                                );
                            });
                        } else if (isOpportunityOutgoingEmailsUpdatedNotification(n)) {
                            await this.loadOutgoingEmailsLimiter.execute(async () => {
                                this.outgoingEmails = await outgoingEmailsApi.getOutgoingEmails({
                                    opportunityId: n.opportunityId,
                                });
                                this.$emit("outgoing-emails", this.outgoingEmails);
                            });
                        } else if (isOpportunityOutgoingSmsUpdatedNotification(n)) {
                            await this.loadOutgoingSmsLimiter.execute(async () => {
                                this.outgoingSms = await outgoingSmsApi.getOutgoingSmsByOpportunity(n.opportunityId);
                            });
                        } else if (isOpportunityOutgoingWhatsAppUpdatedNotification(n)) {
                            await this.loadOutgoingWhatsAppMessagesLimiter.execute(async () => {
                                this.outgoingWhatsApps = await outgoingWhatsAppApi.getMessagesByOpportunity(
                                    n.opportunityId
                                );
                                this.$emit("outgoing-whats-app-messages", this.outgoingWhatsApps);
                            });
                        }

                        this.$emit("issue:allowed");
                    } catch (e) {
                        if (!(e instanceof Forbidden)) {
                            throw e;
                        }

                        this.$emit("issue:forbidden");
                    } finally {
                        this.activeWorkers--;
                    }
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        AuditEventTimelineItem,
        IncomingCallTimelineItem,
        IncomingEmailTimelineItem,
        IncomingSmsTimelineItem,
        IncomingWhatsAppTimelineItem,
        InventoryVehicleInquiryMatchTimelineItem,
        NoteFormTimelineItem,
        NoteTimelineItem,
        OutgoingCallFormTimelineItem,
        OutgoingCallTimelineItem,
        OutgoingCitnowVideoFormTimelineItem,
        OutgoingCitnowVideoTimelineItem,
        OutgoingEmailFormTimelineItem,
        OutgoingEmailTimelineItem,
        OutgoingSmsFormTimelineItem,
        OutgoingSmsTimelineItem,
        OutgoingWhatsAppTimelineItem,
        OutgoingWhatsAppFormTimelineItem,
        OutgoingWhatsAppTemplateFormTimelineItem,
    },
});
