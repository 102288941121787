
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        title: {
            type: String,
            required: true,
        },
        working: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        save() {
            this.$emit("save");
        },
    },

    components: {
        DToolbarCard,
    },
});
