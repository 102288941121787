
import { Department, departmentsApi } from "@/api/departments";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import Sortable from "sortablejs";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            departmentsUsers: cloneObject(this.department.departmentUsers),
        };
    },

    props: {
        department: {
            type: Object as () => Department,
            required: true,
        },
    },

    methods: {
        fullName(userId: string) {
            return getFullName(usersStore.getUserById(userId));
        },
    },

    mounted() {
        const items = this.$refs.items as HTMLElement;
        Sortable.create(items, {
            animation: 150,
            handle: ".drag-handle",
            ghostClass: "accent",
            onEnd: async ({ oldIndex, newIndex }) => {
                if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                    const item = this.departmentsUsers.splice(oldIndex, 1)[0];
                    this.departmentsUsers.splice(newIndex, 0, item);
                    await departmentsApi.updateUserOrder(
                        this.department.dealerId,
                        this.department.id,
                        this.departmentsUsers.map((d) => d.userId)
                    );
                }
            },
        });
    },
});
