
import OpportunityAcquisitionDetailsCard from "./OpportunityAcquisitionDetailsCard.vue";
import OpportunityAttachmentsCard from "./OpportunityAttachmentsCard.vue";
import OpportunityContactCard from "./OpportunityContactCard.vue";
import OpportunityConvertDialog from "./OpportunityConvertDialog.vue";
import OpportunityGeneralInfoCard from "./OpportunityGeneralInfoCard.vue";
import OpportunityInventoryVehiclesCard from "./OpportunityInventoryVehiclesCard.vue";
import OpportunityMergeDialog from "./OpportunityMergeDialog.vue";
import OpportunityOutcomeDialog from "./OpportunityOutcomeDialog.vue";
import OpportunityReceiversCard from "./OpportunityReceiversCard.vue";
import OpportunityReleaseDialog from "./OpportunityReleaseDialog.vue";
import OpportunityRequestedVehicleCard from "./OpportunityRequestedVehicleCard.vue";
import OpportunityTimelineCard from "./OpportunityTimelineCard.vue";
import OpportunityTradeInCard from "./OpportunityTradeInCard.vue";
import OpportunityUrlsCard from "./OpportunityUrlsCard.vue";
import OpportunityUsersCard from "./OpportunityUsersCard.vue";
import PreferredContactCard from "./PreferredContactCard.vue";
import SelfAssignButton from "./SelfAssignButton.vue";
import {
    canManageOpportunity,
    canRequestCitnowVideo,
    canSendEmail,
    canSendSms,
    canSendWhatsapp,
    canStartCall,
    getAssigneeIdsSortedByUserFullName,
    getCloser,
    getCreator,
    getOpportunityChannelColor,
    getOpportunityChannelIcon,
    getOpportunityStatus,
    getStatusCaption,
    getStatusColor,
    getUrgencyColor,
    getUrgencyTextColor,
    isInvalidOpportunityStatusExceptionWithOpportunityStatus,
    OpportunityInventoryVehicleWithInventoryVehicle,
} from "./opportunityUtils";
import { Contact } from "@/api/contacts";
import { BadRequest, Forbidden } from "@/api/errors";
import { IncomingEmail } from "@/api/incomingEmails";
import { IncomingWhatsAppMessage } from "@/api/incomingWhatsApp";
import { inventoryApi, InventoryVehicle } from "@/api/inventory";
import {
    isInventoryVehicleStatusNotification,
    isOpportunityUpdatedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import {
    opportunitiesApi,
    Opportunity,
    OpportunityCloseForm,
    OpportunityConversionForm,
    OpportunityInventoryVehicle,
    OpportunityPriority,
    OpportunityReceiversForm,
    OpportunityResult,
    OpportunityStatus,
    OpportunityUrgency,
} from "@/api/opportunities";
import { OutgoingEmail } from "@/api/outgoingEmails";
import { OutgoingWhatsAppMessage } from "@/api/outgoingWhatsApp";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import PrintFrame from "@/app/components/PrintFrame.vue";
import { VehicleSummary } from "@/app/inventoryUtils";
import { showConfirm, showError, showInfo } from "@/app/messageUtil";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import PostponementDialog from "@/app/pages/PostponementDialog.vue";
import UserLink from "@/app/pages/UserLink.vue";
import ReadReceiptsCard from "@/app/pages/readreceipt/ReadReceiptsCard.vue";
import UserPickerDialog from "@/app/pages/users/UserPickerDialog.vue";
import { getSentimentIcon, getSentimentIconColor, getSentimentText } from "@/app/sentimentUtils";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { opportunityOutcomeReasonsStore } from "@/store/opportunityOutcomeReasons";
import { opportunitySettingsStore } from "@/store/opportunitySettings";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { titleStore } from "@/store/title";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            OpportunityPriority,
            OpportunityStatus,
            OpportunityUrgency,
            activeWorkers: 0,
            assigneePickerVisible: false,
            canAssign: false,
            forbidden: false,
            hint: "",
            hintEditMode: false,
            incomingEmails: [] as IncomingEmail[],
            incomingWhatsAppMessages: [] as IncomingWhatsAppMessage[],
            inventoryVehicles: [] as InventoryVehicle[],
            loading: true,
            loadLimiter: new ActionLimiter(true),
            notificationHandler: null as ((n: Notification) => void) | null,
            opportunityConvertDialogVisible: false,
            opportunityMergeDialogVisible: false,
            opportunityOutcomeDialogVisible: false,
            opportunityReleaseDialogVisible: false,
            opportunityResult: null as OpportunityResult | null,
            outgoingEmails: [] as OutgoingEmail[],
            outgoingWhatsAppMessages: [] as OutgoingWhatsAppMessage[],
            postponementDialogVisible: false,
            printView: "",
            savingHint: false,
            subject: "",
            updateId: 0,
        };
    },

    computed: {
        assigneeIdsSortedByUserFullName(): string[] {
            return getAssigneeIdsSortedByUserFullName(this.opportunityObj);
        },

        canAssignYourself(): boolean {
            if (
                this.opportunityStatus !== OpportunityStatus.OPEN &&
                this.opportunityStatus !== OpportunityStatus.ASSIGNED
            ) {
                return false;
            }

            if (userSession.isCtUser() || this.opportunityObj!.assigneeIds.includes(userSession.userId!)) {
                return false;
            }
            return this.canManageOpportunity;
        },

        canClose(): boolean {
            if (
                this.opportunityStatus !== OpportunityStatus.OPEN &&
                this.opportunityStatus !== OpportunityStatus.ASSIGNED
            ) {
                return false;
            }

            return this.canManageOpportunity;
        },

        canConvert(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            const isOwnIssue =
                this.opportunityObj.creatorUserId === userSession.userId ||
                this.opportunityObj.assigneeIds.includes(userSession.userId!);

            if (
                !userSession.hasPermission(Permission.MANAGE_ALL_OPPORTUNITIES) &&
                !(userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES) && isOwnIssue)
            ) {
                return false;
            }

            const isVisibleAfterConversion =
                userSession.hasPermission(Permission.VIEW_ALL_CASES) ||
                (userSession.hasPermission(Permission.MANAGE_OWN_CASES) && isOwnIssue);

            return this.opportunityStatus !== OpportunityStatus.CLOSED && isVisibleAfterConversion;
        },

        canDelete(): boolean {
            return userSession.hasPermission(Permission.DELETE_OPPORTUNITY);
        },

        canEditAcquisitionDetails(): boolean {
            return this.canManageOpportunity;
        },

        canEditAttachments(): boolean {
            return this.canManageOpportunity;
        },

        canEditContact(): boolean {
            return this.canManageOpportunity;
        },

        canEditGeneralInfo(): boolean {
            return this.canManageOpportunity;
        },

        canEditInventoryVehicles(): boolean {
            return this.canManageOpportunity;
        },

        canEditPreferredContact(): boolean {
            return this.canManageOpportunity;
        },

        canEditReceivers(): boolean {
            return this.canManageOpportunity;
        },

        canEditRequestedVehicle(): boolean {
            return this.canManageOpportunity;
        },

        canEditTradeIn(): boolean {
            return this.canManageOpportunity;
        },

        canEditUrls(): boolean {
            return this.canManageOpportunity;
        },

        canManageOpportunity(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            return canManageOpportunity(this.opportunityObj);
        },

        canMerge(): boolean {
            return this.canManageOpportunity && this.opportunityStatus !== OpportunityStatus.CLOSED;
        },

        canPostpone(): boolean {
            if (this.opportunityStatus !== OpportunityStatus.ASSIGNED) {
                return false;
            }

            if (this.canPostponeOnlyWhenAssignedToSelf && !this.isAssignedToSelf) {
                return this.canUpdateAssignees && this.canManageOpportunity;
            }

            return this.canManageOpportunity;
        },

        canPostponeOnlyWhenAssignedToSelf(): boolean {
            return !userSession.hasPermission(Permission.MANAGE_ALL_OPPORTUNITIES);
        },

        canRemoveSelfAssignment(): boolean {
            if (
                this.opportunityStatus !== OpportunityStatus.ASSIGNED ||
                !this.opportunityObj!.assigneeIds.includes(userSession.userId!)
            ) {
                return false;
            }

            return this.canManageOpportunity;
        },

        canReopen(): boolean {
            if (this.opportunityStatus !== OpportunityStatus.CLOSED) {
                return false;
            }

            return this.canManageOpportunity;
        },

        canRequestCitnowVideo(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            return canRequestCitnowVideo(this.opportunityObj);
        },

        canSendEmail(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            return canSendEmail(this.opportunityObj);
        },

        canSendSms(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            return canSendSms(this.opportunityObj);
        },

        canSendWhatsapp(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            return canSendWhatsapp(this.opportunityObj);
        },

        canStartCall(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            return canStartCall(this.opportunityObj);
        },

        canUnpostpone(): boolean {
            if (this.opportunityStatus !== OpportunityStatus.POSTPONED) {
                return false;
            }

            return this.canManageOpportunity;
        },

        canUpdateAssignees(): boolean {
            if (
                this.opportunityStatus === OpportunityStatus.CLOSED ||
                (this.opportunityStatus === OpportunityStatus.OPEN &&
                    !userSession.hasPermission(Permission.MANAGE_ALL_OPPORTUNITIES))
            ) {
                return false;
            }

            return this.canManageOpportunity;
        },

        canUpdateOutcome(): boolean {
            if (this.opportunityStatus !== OpportunityStatus.CLOSED) {
                return false;
            }

            return this.canManageOpportunity;
        },

        canViewActivityContent(): boolean {
            return (
                (this.canManageOpportunity && !userSession.isCtUser()) ||
                userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES_ACTIVITY_CONTENT)
            );
        },

        channelColor(): string | null {
            if (!this.opportunityObj) {
                return null;
            }

            return getOpportunityChannelColor(this.opportunityObj.channel);
        },

        channelIcon(): string | null {
            if (!this.opportunityObj) {
                return null;
            }

            return getOpportunityChannelIcon(this.opportunityObj.channel);
        },

        closedTimestamp(): string | null {
            if (!this.opportunityObj?.closed) {
                return null;
            }

            return formatInstant(this.opportunityObj.closed, userSession.timeZone, userSession.locale, "S");
        },

        closer(): string {
            return getCloser(this.opportunityObj);
        },

        contact(): Contact | null {
            return this.opportunityResult?.contact ?? null;
        },

        creationTimestamp(): string | null {
            if (!this.opportunityObj) {
                return null;
            }

            return formatInstant(this.opportunityObj.created, userSession.timeZone, userSession.locale, "S");
        },

        creator(): string {
            return getCreator(this.opportunityObj);
        },

        dealerName(): string | null {
            if (!this.opportunityObj) {
                return null;
            }

            return dealersStore.dealerById(this.opportunityObj.dealerId)?.name || null;
        },

        ignoreSelfAssignmentRestrictions(): boolean {
            return (
                this.opportunityStatus === OpportunityStatus.ASSIGNED ||
                userSession.hasPermission(Permission.MANAGE_ALL_OPPORTUNITIES)
            );
        },

        isAssignedToSelf(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            return this.opportunityObj.assigneeIds.includes(userSession.userId!);
        },

        opportunityInventoryVehiclesWithInventoryVehicle(): OpportunityInventoryVehicleWithInventoryVehicle[] {
            if (!this.opportunityObj) {
                return [];
            }

            return this.opportunityObj.inventoryVehicles.map((opportunityInventoryVehicle) => ({
                opportunityInventoryVehicle,
                inventoryVehicle:
                    this.inventoryVehicles.find((iv) => iv.id === opportunityInventoryVehicle.inventoryVehicleId) ??
                    null,
            }));
        },

        opportunityObj(): Opportunity | null {
            return this.opportunityResult?.opportunity ?? null;
        },

        opportunityOutcomeName(): string | null {
            if (!this.opportunityObj?.outcomeReasonId) {
                return null;
            }

            return (
                opportunityOutcomeReasonsStore.getOpportunityOutcomeReasonById(this.opportunityObj.outcomeReasonId)
                    ?.name || (this.$t("Unbekannt") as string)
            );
        },

        opportunityStatus(): OpportunityStatus {
            return getOpportunityStatus(this.opportunityObj!);
        },

        opportunityStatusCaption(): string {
            return getStatusCaption(this.opportunityObj);
        },

        opportunityStatusColor(): string | null {
            return getStatusColor(this.opportunityObj);
        },

        opportunityTargetEmailAddress(): string {
            return `${this.opportunityObj!.id}@${configStore.configuration.transactionEmailHost}`;
        },

        postponedUntilTimestamp(): string | null {
            if (!this.opportunityObj?.postponedUntil) {
                return null;
            }

            return formatInstant(this.opportunityObj.postponedUntil, userSession.timeZone, userSession.locale, "S");
        },

        sentimentIcon(): string {
            return getSentimentIcon(this.opportunityObj?.sentiment ?? null);
        },

        sentimentIconColor(): string | null {
            return getSentimentIconColor(this.opportunityObj?.sentiment ?? null);
        },

        sentimentText(): string | null {
            return getSentimentText(this.opportunityObj?.sentiment ?? null);
        },

        sourceName(): string | null {
            if (!this.opportunityObj) {
                return null;
            }

            return opportunitySourcesStore.getOpportunitySourceById(this.opportunityObj.sourceId)?.name || null;
        },

        urgencyColor(): string | null {
            return getUrgencyColor(this.opportunityObj);
        },

        urgencyTextColor(): string | null {
            return getUrgencyTextColor(this.opportunityObj);
        },

        vehicleSummaries(): VehicleSummary[] {
            if (!this.opportunityObj) {
                return [];
            }

            const vehicleSummaries: VehicleSummary[] = this.opportunityInventoryVehiclesWithInventoryVehicle
                .filter((oiv) => oiv.inventoryVehicle)
                .map((oiv) => oiv.inventoryVehicle!)
                .map((iv) => ({
                    internalId: iv.data.internalId,
                    vehicleClass: iv.data.vehicleClass,
                    make: iv.data.make,
                    model: iv.data.model,
                    modelDescription: iv.data.modelDescription,
                    usageType: iv.data.usageType,
                    mileage: iv.data.mileage,
                    fuel: iv.data.fuel,
                    preOffer: iv.data.preOffer,
                }));

            vehicleSummaries.push({
                internalId: this.opportunityObj.internalId,
                vehicleClass: this.opportunityObj.vehicleClass,
                make: this.opportunityObj.make,
                model: this.opportunityObj.model,
                modelDescription: this.opportunityObj.modelDescription,
                usageType: this.opportunityObj.usageType,
                mileage: this.opportunityObj.mileage,
                fuel: this.opportunityObj.fuel,
                preOffer: this.opportunityObj.preOffer,
            });

            return vehicleSummaries;
        },

        mandatoryAssignees(): string[] {
            return this.opportunityObj?.assigneeIds.includes(userSession.userId!) ? [userSession.userId!] : [];
        },

        isSingleReceiver(): boolean {
            if (!this.opportunityObj) {
                return false;
            }

            const visibleFor = this.opportunityObj.opportunityUsers
                .filter((u) => !u.visibilityEnd)
                .map((u) => u.userId);
            return visibleFor.length === 1 && visibleFor[0] === userSession.userId;
        },
    },

    methods: {
        async assignToSelf() {
            this.activeWorkers++;
            try {
                await opportunitiesApi.assignToSelf(this.opportunityObj!.id);
                window.location.reload();
            } finally {
                this.activeWorkers--;
            }
        },

        async assignYourself() {
            this.activeWorkers++;
            try {
                if (this.ignoreSelfAssignmentRestrictions) {
                    await opportunitiesApi.updateAssignees(this.opportunityObj!.id, [
                        ...this.opportunityObj!.assigneeIds,
                        userSession.userId!,
                    ]);
                } else {
                    await opportunitiesApi.assignToSelf(this.opportunityObj!.id);
                }
            } finally {
                this.activeWorkers--;
            }
        },

        async closeOpportunity(opportunityCloseForm: OpportunityCloseForm) {
            this.opportunityOutcomeDialogVisible = false;
            this.activeWorkers++;
            try {
                if (this.opportunityStatus === OpportunityStatus.CLOSED) {
                    await opportunitiesApi.updateOutcome(this.opportunityObj!.id, opportunityCloseForm.outcomeForm);
                } else {
                    await opportunitiesApi.closeOpportunity(this.opportunityObj!.id, opportunityCloseForm);
                }
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }

                if (!isInvalidOpportunityStatusExceptionWithOpportunityStatus(e, OpportunityStatus.CLOSED)) {
                    throw e;
                }

                showError(this.$t("Die Verkaufschance wurde bereits geschlossen.") as string);
            } finally {
                this.activeWorkers--;
            }
        },

        async convertOpportunity(opportunityConversionForm: OpportunityConversionForm) {
            if (
                await showConfirm(
                    this.$t("In Fall umwandeln") as string,
                    this.$t("Sind Sie sicher, dass Sie diese Verkaufschance in einen Fall umwandeln möchten?") as string
                )
            ) {
                const caseId = await opportunitiesApi.convertOpportunity(
                    this.opportunityObj!.id,
                    opportunityConversionForm
                );
                try {
                    await this.$router.replace(`/case/${caseId}`);
                } catch (e) {
                    // ignore
                }
            } else {
                this.opportunityConvertDialogVisible = false;
            }
        },

        async deleteOpportunity() {
            if (
                await showConfirm(
                    this.$t("Verkaufschance löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Verkaufschance unwiderruflich löschen möchten?") as string
                )
            ) {
                this.activeWorkers++;
                try {
                    await opportunitiesApi.deleteOpportunity(this.opportunityObj!.id);
                } finally {
                    this.activeWorkers--;
                }
            }
        },

        async mergeOpportunity(sourceOpportunityId: string) {
            this.opportunityMergeDialogVisible = false;
            this.activeWorkers++;
            try {
                await opportunitiesApi.mergeOpportunity(this.opportunityObj!.id, sourceOpportunityId);
            } finally {
                this.activeWorkers--;
            }
        },

        async postponeOpportunity(postponedUntil: Date) {
            this.postponementDialogVisible = false;

            this.activeWorkers++;
            try {
                if (this.canPostponeOnlyWhenAssignedToSelf && !this.isAssignedToSelf) {
                    if (
                        !(await showConfirm(
                            this.$t("Zuteilung erforderlich") as string,
                            this.$t(
                                "Um diese Verkaufschance zurückzustellen, müssen Sie sich erst zuteilen. Jetzt zuteilen?"
                            ) as string
                        ))
                    ) {
                        return;
                    }

                    await this.updateAssignees([...this.opportunityObj!.assigneeIds, userSession.userId!]);
                }

                await opportunitiesApi.postponeOpportunity(this.opportunityObj!.id, postponedUntil);
            } finally {
                this.activeWorkers--;
            }
        },

        async removeSelfAssignment(receiversForm?: OpportunityReceiversForm, dealerId?: string) {
            const newAssignees = this.opportunityObj!.assigneeIds.filter((a) => a !== userSession.userId!);
            if (!newAssignees.length && !receiversForm) {
                this.opportunityReleaseDialogVisible = true;
                return;
            }

            this.opportunityReleaseDialogVisible = false;

            this.activeWorkers++;
            try {
                if (dealerId && this.opportunityObj!.dealerId !== dealerId) {
                    await opportunitiesApi.updateGeneralInfo(this.opportunityObj!.id, {
                        dealerId,
                        channel: this.opportunityObj!.channel,
                        sourceId: this.opportunityObj!.sourceId,
                        sentiment: this.opportunityObj!.sentiment,
                        urgency: this.opportunityObj!.urgency,
                        appointmentTime: this.opportunityObj!.appointmentTime,
                        subject: this.opportunityObj!.subject,
                        nextStep: this.opportunityObj!.nextStep,
                        projectedCloseDate: this.opportunityObj!.projectedCloseDate,
                    });
                }
                if (receiversForm) {
                    await opportunitiesApi.editReceivers(this.opportunityObj!.id, receiversForm);
                }
                await opportunitiesApi.updateAssignees(this.opportunityObj!.id, newAssignees);
            } finally {
                this.activeWorkers--;
            }
        },

        async reopenOpportunity() {
            this.activeWorkers++;
            try {
                await opportunitiesApi.reopenOpportunity(this.opportunityObj!.id);
            } finally {
                this.activeWorkers--;
            }
        },

        async saveHint() {
            this.savingHint = true;
            try {
                await opportunitiesApi.updateGeneralInfo(this.opportunityObj!.id, {
                    ...this.opportunityObj!,
                    subject: this.hint,
                });
                this.hintEditMode = false;
            } finally {
                this.savingHint = false;
            }
        },

        showOutgoingCallForm(phone: string | null) {
            if (!this.$refs.timeline) {
                return;
            }

            (this.$refs.timeline as any).showOutgoingCallForm(phone);
        },

        showOutgoingEmailForm(email: string | null) {
            if (!this.$refs.timeline) {
                return;
            }

            (this.$refs.timeline as any).showOutgoingEmailForm(email);
        },

        showOutgoingSmsForm(phone: string | null) {
            if (!this.$refs.timeline) {
                return;
            }

            (this.$refs.timeline as any).showOutgoingSmsForm(phone);
        },

        switchToHintEditMode() {
            this.hintEditMode = true;
            this.hint = this.opportunityObj?.subject || "";
        },

        async unpostponeOpportunity() {
            this.activeWorkers++;
            try {
                await opportunitiesApi.unpostponeOpportunity(this.opportunityObj!.id);
            } finally {
                this.activeWorkers--;
            }
        },

        async updateAssignees(assignees: string[]) {
            this.assigneePickerVisible = false;
            this.activeWorkers++;
            try {
                await opportunitiesApi.updateAssignees(this.opportunityObj!.id, assignees);
            } finally {
                this.activeWorkers--;
            }
        },

        async print() {
            this.activeWorkers++;
            try {
                this.printView = await opportunitiesApi.getPrintView(this.opportunityObj!.id);
            } finally {
                this.activeWorkers--;
            }
            await this.$nextTick();
            (this.$refs.printFrame as any).print();
        },

        async addOfferVehicles(vehicles: InventoryVehicle[]) {
            if (!this.opportunityObj) {
                return;
            }
            const vehicleIds = this.opportunityObj.inventoryVehicles.map((v) => v.inventoryVehicleId);
            const newVehicles = vehicles
                .filter((v) => !vehicleIds.includes(v.id))
                .map((v) => ({
                    inventoryVehicleId: v.id,
                    link: null,
                }));

            if (newVehicles.length) {
                await this.addInventoryVehicles(newVehicles);
                showInfo(
                    this.$t(
                        "Die Fahrzeuge der versandten Exposés wurden zur Liste der angefragten Bestandsfahrzeuge hinzugefügt."
                    ) as string
                );
            }
        },

        async addInventoryVehicles(vehicles: OpportunityInventoryVehicle[]) {
            if (!this.opportunityObj) {
                return;
            }
            this.activeWorkers++;

            try {
                for (const vehicle of vehicles) {
                    await opportunitiesApi.addInventoryVehicle(this.opportunityObj.id, vehicle);
                }
            } finally {
                this.activeWorkers--;
            }
        },
    },

    async mounted() {
        let opportunityId: string | null;

        try {
            const routeOpportunityId = this.$route.params.opportunityid;

            opportunityId = await opportunitiesApi.resolveAlias(routeOpportunityId);

            if (!opportunityId) {
                this.loading = false;
                return;
            }

            if (opportunityId !== routeOpportunityId) {
                try {
                    await this.$router.replace({ params: { opportunityid: opportunityId } });
                } catch (e) {
                    // ignore
                }
                return;
            }
        } catch (e) {
            this.loading = false;
            if (!(e instanceof Forbidden)) {
                throw e;
            }
            this.forbidden = true;
            return;
        }

        titleStore.title = this.$t("Verkaufschance {0}", [opportunityId]) as string;

        try {
            const opportunityResult = await opportunitiesApi.getById(opportunityId);

            if (!opportunityResult) {
                return;
            }

            if (
                opportunitySettingsStore.hideOpportunityInfo &&
                getOpportunityStatus(opportunityResult.opportunity) === OpportunityStatus.OPEN
            ) {
                this.canAssign = true;
                this.forbidden = true;
                this.opportunityResult = opportunityResult;
                return;
            }

            const inventoryVehicles = (opportunityResult?.opportunity.inventoryVehicles ?? []).length
                ? await inventoryApi.getByIds(
                      opportunityResult!.opportunity.inventoryVehicles.map((i) => i.inventoryVehicleId)
                  )
                : [];

            this.opportunityResult = opportunityResult;
            this.inventoryVehicles = inventoryVehicles;

            opportunitiesApi.updateRead(opportunityResult.opportunity.id);
        } catch (e) {
            if (!(e instanceof Forbidden)) {
                throw e;
            }
            this.forbidden = true;
            return;
        } finally {
            this.loading = false;
        }

        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isOpportunityUpdatedNotification(n)) {
                if (this.opportunityObj?.id !== n.opportunityId) {
                    return;
                }

                await this.loadLimiter.execute(async () => {
                    try {
                        const updateId = ++this.updateId;
                        const opportunityResult = await opportunitiesApi.getById(this.opportunityObj!.id);
                        const inventoryVehicles = (opportunityResult?.opportunity.inventoryVehicles ?? []).length
                            ? await inventoryApi.getByIds(
                                  opportunityResult!.opportunity.inventoryVehicles.map((i) => i.inventoryVehicleId)
                              )
                            : [];

                        if (updateId === this.updateId) {
                            this.opportunityResult = opportunityResult;
                            this.inventoryVehicles = inventoryVehicles;

                            this.forbidden = false;
                        }
                    } catch (e) {
                        if (!(e instanceof Forbidden)) {
                            throw e;
                        }
                        this.forbidden = true;
                    }
                });
            } else if (isInventoryVehicleStatusNotification(n)) {
                for (const inventoryVehicle of this.inventoryVehicles) {
                    if (inventoryVehicle.id === n.inventoryVehicleId) {
                        inventoryVehicle.statuses.push(n.inventoryVehicleStatus);
                    }
                }
            }
        });

        if (this.$route.query.action) {
            await this.$nextTick();

            const action = this.$route.query.action as string;
            const emailAddress = decodeURIComponent((this.$route.query.emailAddress as string) ?? "") || null;
            const phoneNumber = decodeURIComponent((this.$route.query.phoneNumber as string) ?? "") || null;

            if (action === "SEND_EMAIL") {
                this.showOutgoingEmailForm(emailAddress);
            } else if (action === "SEND_SMS") {
                this.showOutgoingSmsForm(phoneNumber);
            } else if (action === "START_CALL") {
                this.showOutgoingCallForm(phoneNumber);
            }

            await this.$router.replace({
                query: { ...this.$route.query, action: undefined, emailAddress: undefined, phoneNumber: undefined },
            });
        }
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        CopyToClipboardIcon,
        IconWithTooltip,
        OpportunityAcquisitionDetailsCard,
        OpportunityAttachmentsCard,
        OpportunityContactCard,
        OpportunityConvertDialog,
        OpportunityGeneralInfoCard,
        OpportunityInventoryVehiclesCard,
        OpportunityMergeDialog,
        OpportunityOutcomeDialog,
        OpportunityReceiversCard,
        OpportunityReleaseDialog,
        OpportunityRequestedVehicleCard,
        OpportunityTimelineCard,
        OpportunityTradeInCard,
        OpportunityUrlsCard,
        OpportunityUsersCard,
        PostponementDialog,
        PrintFrame,
        PreferredContactCard,
        ReadReceiptsCard,
        SelfAssignButton,
        UserLink,
        UserPickerDialog,
    },
});
