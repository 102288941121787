
import {
    EmergencyIncidentNotificationReceiver,
    emergencyIncidentNotificationReceiversApi,
} from "@/api/emergencyIncidentNotificationReceivers";
import {
    EmergencyIncidentNotificationEmailFormat,
    emergencyIncidentNotificationSettingsApi,
} from "@/api/emergencyIncidentNotificationSettings";
import { Permission } from "@/api/userSession";
import { showConfirm } from "@/app/messageUtil";
import CrudTablePage from "@/app/pages/CrudTablePage.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { TableHeader } from "@/app/pages/crudPage";
import { getFullName, getSortedByUserFullName } from "@/app/userUtils";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { SelectOption, SelectOptions } from "@/util/types/SelectOptions";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            emergencyIncidentNotificationEmailFormat: null as EmergencyIncidentNotificationEmailFormat | null,
            loading: true,
            notEmpty,
        };
    },

    computed: {
        emergencyIncidentNotificationEmailFormatOptions(): SelectOptions {
            return [
                ...Object.keys(EmergencyIncidentNotificationEmailFormat).map((k) => ({
                    value: k,
                    text: this.$t(`enum.EmergencyIncidentNotificationEmailFormat.${k}`),
                })),
            ];
        },

        dealerId() {
            return userSession.dealerId;
        },

        headers(): TableHeader[] {
            return [
                {
                    id: "user",
                    text: this.$t("Benutzer"),
                    width: "100%",
                },
            ];
        },

        userOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        async addEmergencyIncidentNotificationReceiver(item: EmergencyIncidentNotificationReceiver) {
            try {
                await emergencyIncidentNotificationReceiversApi.add(item);
                return true;
            } catch (e) {
                return false;
            }
        },

        async deleteEmergencyIncidentNotificationReceiver(item: EmergencyIncidentNotificationReceiver) {
            if (
                await showConfirm(
                    this.$t("Benachrichtigungsempfänger löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Benachrichtigungsempfänger löschen möchten?") as string
                )
            ) {
                await emergencyIncidentNotificationReceiversApi.delete(item.dealerId, item.userId);
                return true;
            }
            return false;
        },

        emptyEmergencyIncidentNotificationReceiverForm() {
            return {
                dealerId: this.dealerId,
                userId: null,
            };
        },

        async getEmergencyIncidentNotificationReceivers(dealerId: string) {
            return getSortedByUserFullName(
                await emergencyIncidentNotificationReceiversApi.getByDealer(dealerId),
                (r) => r.userId
            );
        },

        getFullNameById(userId: string) {
            return getFullName(usersStore.getUserById(userId));
        },

        async loadEmergencyIncidentNotificationSettings() {
            if (!this.dealerId) {
                return;
            }

            this.loading = true;

            try {
                const emergencyIncidentNotificationSettings = await emergencyIncidentNotificationSettingsApi.getByDealer(
                    this.dealerId
                );

                this.emergencyIncidentNotificationEmailFormat =
                    emergencyIncidentNotificationSettings.emergencyIncidentNotificationEmailFormat;
            } finally {
                this.loading = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.loading = true;

            try {
                await emergencyIncidentNotificationSettingsApi.editEmergencyIncidentNotificationSettings({
                    dealerId: this.dealerId!,
                    emergencyIncidentNotificationEmailFormat: this.emergencyIncidentNotificationEmailFormat!,
                });
            } finally {
                this.loading = false;
            }
        },

        toEmergencyIncidentNotificationReceiverForm(item: EmergencyIncidentNotificationReceiver) {
            return item;
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadEmergencyIncidentNotificationSettings();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadEmergencyIncidentNotificationSettings();
    },

    components: {
        CrudTablePage,
        DealerContextGuard,
    },
});
