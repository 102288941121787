import { CasesOverviewFilters, CasesOverviewState } from "./casesOverview";
import { UserSearchFilter, UserSearchFilterForm, UserSearchFilterType } from "@/api/userSearchFilters";
import { createFilterDate, sortFilterValues, UserSearchFilterUnitOfTime } from "@/app/userSearchFilterUtils";
import { caseOutcomeReasonsStore } from "@/store/caseOutcomeReasons";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { usersStore } from "@/store/users";

export interface CaseSearchFilterOptions {
    readonly name: string;
    readonly createdFrom: number | null;
    readonly createdTo: number | null;
    readonly createdUnit: UserSearchFilterUnitOfTime | null;
}

export function mapToSearchFilterForm(
    options: CaseSearchFilterOptions,
    casesOverviewState: CasesOverviewState
): UserSearchFilterForm {
    return {
        name: options.name,
        filterType: UserSearchFilterType.CASE,
        filterJson: {
            createdFrom: options.createdFrom,
            createdTo: options.createdTo,
            createdUnit: options.createdUnit,
            caseTypes: casesOverviewState.caseTypes,
            status: casesOverviewState.status,
            channels: casesOverviewState.channels,
            sentimentTypes: casesOverviewState.sentimentTypes,
            urgencies: casesOverviewState.urgencies,
            priority: casesOverviewState.priority,
            creatorActorType: casesOverviewState.creatorActorType,
            createdBy: casesOverviewState.createdBy,
            closerActorType: casesOverviewState.closerActorType,
            closedBy: casesOverviewState.closedBy,
            outcomeReasonIds: casesOverviewState.outcomeReasonIds,
            assignees: casesOverviewState.assignees,
            individualReceivers: casesOverviewState.individualReceivers,
            escalationGroupReceivers: casesOverviewState.escalationGroupReceivers,
            hasReceivers: casesOverviewState.hasReceivers,
            search: casesOverviewState.search,
        },
    };
}

export function mapToCasesOverviewFilters(
    { filterJson }: UserSearchFilter | UserSearchFilterForm,
    casesOverviewState: CasesOverviewState
): CasesOverviewFilters {
    return {
        dealerIds: casesOverviewState.dealerIds,
        createdFrom: createFilterDate(filterJson.createdFrom, filterJson.createdUnit, true),
        createdTo: createFilterDate(filterJson.createdTo, filterJson.createdUnit, false),
        caseTypes: filterJson.caseTypes,
        status: filterJson.status,
        channels: filterJson.channels,
        sentimentTypes: filterJson.sentimentTypes,
        urgencies: filterJson.urgencies,
        priority: filterJson.priority,
        creatorActorType: filterJson.creatorActorType,
        createdBy: filterJson.createdBy,
        closerActorType: filterJson.closerActorType,
        closedBy: filterJson.closedBy,
        outcomeReasonIds: filterJson.outcomeReasonIds,
        assignees: filterJson.assignees,
        individualReceivers: filterJson.individualReceivers,
        escalationGroupReceivers: filterJson.escalationGroupReceivers,
        hasReceivers: filterJson.hasReceivers,
        search: filterJson.search,
    };
}

function stringifyFilter(filter: CasesOverviewFilters): string {
    return JSON.stringify({
        caseTypes: sortFilterValues(filter.caseTypes),
        status: filter.status,
        channels: sortFilterValues(filter.channels),
        sentimentTypes: sortFilterValues(filter.sentimentTypes),
        urgencies: sortFilterValues(filter.urgencies),
        priority: filter.priority,
        creatorActorType: filter.creatorActorType,
        createdBy: filter.createdBy,
        closerActorType: filter.closerActorType,
        closedBy: filter.closedBy,
        outcomeReasonIds: sortFilterValues(filter.outcomeReasonIds),
        assignees: sortFilterValues(filter.assignees),
        individualReceivers: sortFilterValues(filter.individualReceivers),
        escalationGroupReceivers: sortFilterValues(filter.escalationGroupReceivers),
        hasReceivers: filter.hasReceivers,
        search: filter.search,
    });
}

export function filterMatchesCasesOverviewState(
    filter: UserSearchFilter,
    casesOverviewState: CasesOverviewState
): boolean {
    const createdFrom = createFilterDate(filter.filterJson.createdFrom, filter.filterJson.createdUnit, true);
    const createdTo = createFilterDate(filter.filterJson.createdTo, filter.filterJson.createdUnit, false);
    const datesMatch = createdFrom === casesOverviewState.createdFrom && createdTo === casesOverviewState.createdTo;

    return datesMatch && stringifyFilter(filter.filterJson) === stringifyFilter(casesOverviewState);
}

export function filterIsValid(filter: UserSearchFilter): boolean {
    if (filter.filterJson.createdBy?.length) {
        const users = usersStore.users.map((u) => u.id);
        if (filter.filterJson.createdBy.some((e: string) => !users.includes(e))) {
            return false;
        }
    }
    if (filter.filterJson.closedBy?.length) {
        const users = usersStore.users.map((u) => u.id);
        if (filter.filterJson.closedBy.some((e: string) => !users.includes(e))) {
            return false;
        }
    }
    if (filter.filterJson.asignees?.length) {
        const users = usersStore.users.map((u) => u.id);
        if (
            filter.filterJson.asignees.filter((e: string | null) => e !== null).some((e: string) => !users.includes(e))
        ) {
            return false;
        }
    }
    if (filter.filterJson.individualReceivers?.length) {
        const users = usersStore.users.map((u) => u.id);
        if (
            filter.filterJson.individualReceivers
                .filter((e: string | null) => e !== null)
                .some((e: string) => !users.includes(e))
        ) {
            return false;
        }
    }
    if (filter.filterJson.escalationGroupReceivers?.length) {
        const escalationGroups = escalationGroupsStore.escalationGroups.map((e) => e.id);
        if (
            filter.filterJson.escalationGroupReceivers
                .filter((e: string | null) => e !== null)
                .some((e: string) => !escalationGroups.includes(e))
        ) {
            return false;
        }
    }
    if (filter.filterJson.outcomeReasonIds?.length) {
        const outcomeReasons = caseOutcomeReasonsStore.caseOutcomeReasons.map((r) => r.id);
        if (
            filter.filterJson.outcomeReasonIds
                .filter((e: string | null) => e !== null)
                .some((e: string) => !outcomeReasons.includes(e))
        ) {
            return false;
        }
    }
    return true;
}
