import { ContactNameData, renderFullName, renderFullSalutation } from "./contactUtils";
import i18n, { $t } from "./i18n";
import { getFullName } from "./userUtils";
import { Dealer } from "@/api/dealers";
import { DealerAssignment, EmailAlias, Gender, PhoneNumber, PhoneNumberType, usersApi } from "@/api/users";
import { getCountryCaption, getStateCaption } from "@/app/countryStateUtils";
import { departmentsStore } from "@/store/departments";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";

interface UserInfo {
    readonly username: string | null;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly languages: string[];
    readonly phoneNumbers: PhoneNumber[];
    readonly emailAliases: EmailAlias[];
}

interface UserDealerInfo {
    readonly mainDealerId: string | null;
    readonly dealerAssignments: DealerAssignment[];
}

interface UserProfileImageInfo {
    readonly id: string | null;
    readonly profileImageHash: string | null;
}

export interface UrlWithKey {
    url: string;
    key: string;
}

async function getProfileImageHtml(user: UserProfileImageInfo, width: number) {
    if (!user.id || !user.profileImageHash) {
        return null;
    }

    const image = await usersApi.getProfileImageByHash(user.id, user.profileImageHash, width);

    if (!image) {
        return null;
    }

    return `<img width="${width}" src="data:image/jpeg;base64,${image}" alt="" />`;
}

function normalize(name: string | null): string {
    return (name || "").replace(/\s+/g, " ").trim();
}

const XML_REPLACEMENTS: Record<string, string> = {
    "<": "&lt;",
    ">": "&gt;",
    "&": "&amp;",
    "'": "&apos;",
    '"': "&quot;",
};

function escapeXml(unsafe: string) {
    return unsafe.replace(/[<>&'"]/g, (c) => XML_REPLACEMENTS[c]);
}

function renderPhoneNumber(phoneNumber: PhoneNumber | undefined) {
    if (!phoneNumber) {
        return null;
    }

    return parseAndFormatNumber(phoneNumber.number, "INTERNATIONAL");
}

export const contactSalutationPlaceholders = ["SALUTATION"];
export function renderContactSalutationPlaceholders(
    content: string,
    contactNameData: ContactNameData,
    locale = i18n.locale
) {
    return content
        .replace(
            /%SALUTATION%/g,
            renderFullSalutation(contactNameData, locale) || $t("Sehr geehrte Damen und Herren", locale).toString()
        )
        .trim();
}

export const contactPlaceholders = [
    "CONTACT_SALUTATION",
    "CONTACT_GIVEN_NAME",
    "CONTACT_FAMILY_NAME",
    "CONTACT_FULL_NAME",
];

export function renderContactPlaceholders(
    content: string,
    contactNameData: ContactNameData,
    locale = i18n.locale
): string {
    return content
        .replace(
            /%CONTACT_SALUTATION%/g,
            contactNameData.salutation
                ? ($t(`enum.ContactSalutation.${contactNameData.salutation}`, locale) as string)
                : ""
        )
        .replace(/%CONTACT_GIVEN_NAME%/g, contactNameData.givenName || "")
        .replace(/%CONTACT_FAMILY_NAME%/g, contactNameData.familyName || "")
        .replace(/%CONTACT_FULL_NAME%/g, renderFullName(contactNameData))
        .trim();
}

export const dealerPlaceholders = [
    "DEALER_NAME",
    "DEALER_ADDRESS_1",
    "DEALER_ADDRESS_2",
    "DEALER_ZIP",
    "DEALER_CITY",
    "DEALER_STATE",
    "DEALER_COUNTRY",
];

export function renderDealerPlaceholders(content: string, dealer: Dealer | null) {
    return content
        .replace(/%DEALER_NAME%/g, dealer?.name || "")
        .replace(/%DEALER_ADDRESS_1%/g, dealer?.address1 || "")
        .replace(/%DEALER_ADDRESS_2%/g, dealer?.address2 || "")
        .replace(/%DEALER_ZIP%/g, dealer?.zip || "")
        .replace(/%DEALER_CITY%/g, dealer?.city || "")
        .replace(
            /%DEALER_STATE%/g,
            (!!dealer?.country && !!dealer?.state ? getStateCaption(dealer.country, dealer.state) : null) || ""
        )
        .replace(/%DEALER_COUNTRY%/g, (!!dealer?.country ? getCountryCaption(dealer.country) : null) || "")
        .trim();
}
export const userDealerPlaceholders = [
    "USER_DEALER_LANDLINE",
    "USER_DEALER_MOBILE",
    "USER_DEALER_FAX",
    "USER_DEPARTMENT_NAME",
    "USER_DEPARTMENT_POSITION",
];
export function renderUserDealerPlaceholders(content: string, user: UserDealerInfo) {
    let userDealerLandline = undefined;
    let userDealerMobile = undefined;
    let userDealerFax = undefined;

    let userDepartmentName = "";
    let userDepartmentPosition = null;

    const assignments = user.dealerAssignments.find((a) => a.dealerId === user.mainDealerId);

    if (assignments) {
        userDealerLandline = assignments.phoneNumbers.find((n) => n.type === PhoneNumberType.LANDLINE && n.publishable);
        userDealerMobile = assignments.phoneNumbers.find((n) => n.type === PhoneNumberType.MOBILE && n.publishable);
        userDealerFax = assignments.phoneNumbers.find((n) => n.type === PhoneNumberType.FAX && n.publishable);

        if (assignments.departmentAssignments.length) {
            const assignment = assignments.departmentAssignments[0];

            userDepartmentName = departmentsStore.departmentById(assignment.departmentId)?.name || "";
            userDepartmentPosition = assignment.position || "";
        }
    }

    return content
        .replace(/%USER_DEALER_LANDLINE%/g, renderPhoneNumber(userDealerLandline) || "")
        .replace(/%USER_DEALER_MOBILE%/g, renderPhoneNumber(userDealerMobile) || "")
        .replace(/%USER_DEALER_FAX%/g, renderPhoneNumber(userDealerFax) || "")
        .replace(/%USER_DEPARTMENT_NAME%/g, normalize(userDepartmentName))
        .replace(/%USER_DEPARTMENT_POSITION%/g, normalize(userDepartmentPosition))
        .trim();
}

export const userPlaceholders = [
    "USER_SALUTATION",
    "USER_GIVEN_NAME",
    "USER_FAMILY_NAME",
    "USER_FULL_NAME",
    "USER_EMAIL_ADDRESS",
    "USER_PHONE",
    "USER_LANDLINE",
    "USER_MOBILE",
    "USER_FAX",
    "USER_LANGUAGES",
];
export function renderUserPlaceholders(content: string, user: UserInfo, locale = i18n.locale) {
    const landline = user.phoneNumbers.find((n) => n.type === PhoneNumberType.LANDLINE && n.publishable);
    const mobile = user.phoneNumbers.find((n) => n.type === PhoneNumberType.MOBILE && n.publishable);
    const phone = user.phoneNumbers.find(
        (n) => (n.type === PhoneNumberType.LANDLINE || n.type === PhoneNumberType.MOBILE) && n.publishable
    );
    const fax = user.phoneNumbers.find((n) => n.type === PhoneNumberType.FAX && n.publishable);
    const languages = user.languages
        .map((l) => $t(`language.${l}`, locale).toString())
        .sort((a, b) => a.localeCompare(b, locale))
        .join(", ");

    const defaultReplyToEmailAlias = user.emailAliases.find((a) => a.defaultReplyTo);
    const emailAddress = user.username
        ? user.username
        : defaultReplyToEmailAlias
        ? `${defaultReplyToEmailAlias.localPart}@${defaultReplyToEmailAlias.emailDomainId}`
        : "";

    return content
        .replace(/%USER_FULL_NAME%/g, normalize(getFullName(user)))
        .replace(
            /%USER_SALUTATION%/g,
            (user.gender === Gender.MALE
                ? $t("Herr", locale)
                : user.gender === Gender.FEMALE
                ? $t("Frau", locale)
                : "") as string
        )
        .replace(/%USER_GIVEN_NAME%/g, normalize(user.givenName))
        .replace(/%USER_FAMILY_NAME%/g, normalize(user.familyName))
        .replace(/%USER_EMAIL_ADDRESS%/g, emailAddress)
        .replace(/%USER_PHONE%/g, renderPhoneNumber(phone) || "")
        .replace(/%USER_LANDLINE%/g, renderPhoneNumber(landline) || "")
        .replace(/%USER_MOBILE%/g, renderPhoneNumber(mobile) || "")
        .replace(/%USER_FAX%/g, renderPhoneNumber(fax) || "")
        .replace(/%USER_LANGUAGES%/g, languages)
        .trim();
}

export const userProfileImagePlaceholders = [
    "USER_PROFILE_IMAGE_100",
    "USER_PROFILE_IMAGE_150",
    "USER_PROFILE_IMAGE_200",
];
export async function renderUserProfileImagePlaceholders(content: string, user: UserProfileImageInfo) {
    const profileImagePlaceholderMatches = content.match(/%USER_PROFILE_IMAGE_[1-9]\d*%/g) || [];

    for (const profileImagePlaceholderMatch of profileImagePlaceholderMatches) {
        const widthMatch = profileImagePlaceholderMatch.match(/(\d+)/);
        const width = widthMatch![0];

        if (user.id && user.profileImageHash) {
            content = content.replace(
                new RegExp(profileImagePlaceholderMatch, "g"),
                (await getProfileImageHtml(user, Number.parseInt(width))) || ""
            );
        } else {
            content = content.replace(new RegExp(profileImagePlaceholderMatch, "g"), "");
        }
    }

    return content.trim();
}

export interface RequestedVehiclePlaceholderInfo {
    readonly caption: string | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly internalId: string | null;
}

export const requestedVehiclePlaceholders = [
    "REQUESTED_VEHICLE_CAPTION",
    "REQUESTED_VEHICLE_MAKE",
    "REQUESTED_VEHICLE_MODEL",
    "REQUESTED_VEHICLE_MODEL_DESCRIPTION",
    "REQUESTED_VEHICLE_INTERNAL_ID",
];

export function renderRequestedVehiclePlaceholders(content: string, vehicle: RequestedVehiclePlaceholderInfo): string {
    return content
        .replace(/%REQUESTED_VEHICLE_CAPTION%/g, normalize(vehicle.caption))
        .replace(/%REQUESTED_VEHICLE_MAKE%/g, normalize(vehicle.make))
        .replace(/%REQUESTED_VEHICLE_MODEL%/g, normalize(vehicle.model))
        .replace(/%REQUESTED_VEHICLE_MODEL_DESCRIPTION%/g, normalize(vehicle.modelDescription))
        .replace(/%REQUESTED_VEHICLE_INTERNAL_ID%/g, normalize(vehicle.internalId))
        .trim();
}

export function renderLinkPlaceholders(content: string, urls: UrlWithKey[], html: boolean) {
    for (const url of urls) {
        if (html) {
            const escapedUrl = escapeXml(url.url);
            content = content.replace(`%LINK_${url.key}%`, `<a href="${escapedUrl}">${escapedUrl}</a>`);
        } else {
            content = content.replace(`%LINK_${url.key}%`, url.url);
        }
    }
    return content.replace(/%LINK_[^%]*%/g, "").trim();
}
