
import { opportunitiesApi } from "@/api/opportunities";
import { ContactAndVehicleId } from "@/app/contactUtils";
import ActionCard from "@/app/pages/ActionCard.vue";
import ContactAndVehiclePicker from "@/app/pages/contacts/ContactAndVehiclePicker.vue";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityId: {
            type: String,
            required: true,
        },
        contactId: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            contactAndVehicleId: (this.contactId
                ? { contactId: this.contactId, contactVehicleId: null }
                : null) as ContactAndVehicleId | null,
            working: false,
        };
    },

    computed: {
        isCtUser(): boolean {
            return userSession.isCtUser();
        },

        phoneticSpellingLocale(): string {
            return configStore.configuration.defaultLocale;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async save() {
            this.working = true;
            try {
                await opportunitiesApi.editContact(this.opportunityId, this.contactAndVehicleId?.contactId ?? null);
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        ContactAndVehiclePicker,
    },
});
