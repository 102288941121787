var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-0",staticStyle:{"overflow-x":"auto"}},[(_vm.dealer && !_vm.dealer.active)?_c('v-alert',{staticClass:"body-2 mb-0 text-center",attrs:{"dense":"","icon":false,"prominent":"","tile":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t("Der Standort ist nicht aktiv."))+" ")]):_vm._e(),_c('table',[_c('tbody',[_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Name des Kunden'),"phonetic-spelling":_vm.isPhoneticSpellingTooltipVisible,"phonetic-spelling-locale":_vm.phoneticSpellingLocale,"text":_vm.carryDispoAssistantState.contractorCustomerName,"text-class":"text-no-wrap","valid":!!_vm.carryDispoAssistantState.contractorCustomerName}}),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Fahrer'),"phonetic-spelling":_vm.isPhoneticSpellingTooltipVisible,"phonetic-spelling-locale":_vm.phoneticSpellingLocale,"text":_vm.carryDispoAssistantState.vehicleDriver,"text-class":"text-no-wrap","valid":!!_vm.carryDispoAssistantState.vehicleDriver}}),_c('issue-data-check-card-data-table-row',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.carryDispoAssistantState.vehicleBrand)?_c('v-icon',{attrs:{"color":"error","x-small":""}},[_vm._v(" mdi-cancel ")]):(!_vm.carryDispoAssistantState.vehicleModel && _vm.vehicleMakeHasModels)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"warning","x-small":""}},on),[_vm._v(" mdi-alert ")])]}}])},[_c('div',[_vm._v(_vm._s(_vm.$t("Es ist kein Modell ausgewählt.")))])]):_c('v-icon',{attrs:{"color":"success","x-small":""}},[_vm._v("mdi-check")])]},proxy:true},{key:"header",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("Marke"))+", ")]),_c('span',{class:!_vm.carryDispoAssistantState.vehicleModel && _vm.vehicleMakeHasModels
                                    ? 'warning--text'
                                    : ''},[_vm._v(_vm._s(_vm.$t("Modell")))]),_c('span',[_vm._v(":")])]},proxy:true},{key:"data",fn:function(){return [(_vm.carryDispoAssistantState.vehicleBrand)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.carryDispoAssistantState.vehicleBrand))]):_vm._e(),(_vm.carryDispoAssistantState.vehicleBrand && _vm.carryDispoAssistantState.vehicleModel)?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.carryDispoAssistantState.vehicleBrand && _vm.carryDispoAssistantState.vehicleModel)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.carryDispoAssistantState.vehicleModel))]):_vm._e(),(!_vm.carryDispoAssistantState.vehicleBrand)?_c('span',[_vm._v("-")]):_vm._e()]},proxy:true}])}),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Kennzeichen'),"phonetic-spelling":_vm.isPhoneticSpellingTooltipVisible,"phonetic-spelling-locale":_vm.phoneticSpellingLocale,"text":_vm.carryDispoAssistantState.vehicleLicensePlate,"text-class":"text-no-wrap","valid":!!_vm.carryDispoAssistantState.vehicleLicensePlate}}),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Laufleistung'),"text":_vm.carryDispoAssistantState.vehicleOdometerKilometers
                            ? _vm.renderMileage(_vm.carryDispoAssistantState.vehicleOdometerKilometers, _vm.MileageUnit.KM)
                            : null,"text-class":"text-no-wrap","valid":_vm.carryDispoAssistantState.vehicleOdometerKilometers !== null}}),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Rückrufnummer'),"text":_vm.carryDispoAssistantState.contractorCustomerPhone
                            ? _vm.parseAndFormatNumber(
                                  _vm.carryDispoAssistantState.contractorCustomerPhone,
                                  'INTERNATIONAL'
                              )
                            : null,"text-class":"text-no-wrap","valid":!!_vm.carryDispoAssistantState.contractorCustomerPhone}}),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Auftragstyp'),"text":_vm.carryDispoAssistantState.orderType
                            ? _vm.$t(`enum.CarryDispo.OrderType.${_vm.carryDispoAssistantState.orderType}`)
                            : null,"text-class":"text-no-wrap","valid":!!_vm.carryDispoAssistantState.orderType}}),_c('issue-data-check-card-data-table-row',{attrs:{"header":_vm.$t('Einsatzort'),"text":_vm.servicePoint,"text-class":"text-no-wrap","valid":!!_vm.servicePoint}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }