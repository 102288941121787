
import { getFile } from "@/app/fileUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        acceptedFileTypes: {
            type: Array as () => string[],
            default: () => [],
        },
        canDropUrl: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        filenameFilter: {
            type: RegExp,
            default: () => /^[^.]+$|.\.(?=[^.]+$)(?!(exe|com|dll|vbs|bat|cmd)$)/i,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isDownloading: false,
            showOverlay: false,
        };
    },

    methods: {
        async handleDropEvent(event: DragEvent) {
            this.showOverlay = false;

            if (this.disabled || this.isDownloading || !event.dataTransfer) {
                return;
            }

            if (event.dataTransfer.files.length > 0 && (this.multiple || event.dataTransfer.files.length === 1)) {
                const files = Array.from(event.dataTransfer.files).filter((file) => this.isFileAccepted(file));
                if (files.length) {
                    this.$emit("drop", files);
                }
            } else if (this.canDropUrl) {
                const url = event.dataTransfer.getData("URL");
                if (url) {
                    this.isDownloading = true;
                    try {
                        const file = await getFile(url);
                        if (file && this.isFileAccepted(file)) {
                            this.$emit("drop", [file]);
                        }
                    } finally {
                        this.isDownloading = false;
                    }
                }
            }
        },

        isFileAccepted(file: File): boolean {
            return (
                !!file.name.match(this.filenameFilter) &&
                (!this.acceptedFileTypes.length ||
                    this.acceptedFileTypes.some((t) => file.name.toLowerCase().endsWith(t)))
            );
        },
    },
});
