
import { PropulsionType, TransmissionType, VehicleType } from "@/api/carryDispo";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import ModelPicker from "@/app/pages/ModelPicker.vue";
import { CarryDispoAssistantState } from "@/app/pages/carrydispo/assistant/carryDispoAssistant";
import { integer, maxLength } from "@/app/validation";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        carryDispoAssistantState: {
            type: Object as () => CarryDispoAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            maxLength,
            now: now(),
            positiveIntegerRules: integer(0),
            PropulsionType,
            TransmissionType,
            VehicleType,
        };
    },

    computed: {
        currentDate(): string {
            return getDate(this.now, userSession.timeZone);
        },
    },

    components: {
        DatePicker,
        EnumField,
        MakePicker,
        ModelPicker,
        NumberField,
    },
});
