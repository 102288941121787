
import { emailSignatureTemplatePlaceholders } from "./emailSignatureTemplatePlaceholders";
import { emailSignatureTemplateApi } from "@/api/emailSignatureTemplate";
import { Permission } from "@/api/userSession";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { maxLength } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            content: "",
            contentRules: maxLength(4_194_303),
            isWorking: false,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        placeholders(): string[] {
            return emailSignatureTemplatePlaceholders;
        },
    },

    methods: {
        appendToContent(placeholder: string) {
            this.content += placeholder;
        },

        async loadEmailSignatureTemplate() {
            if (!this.dealerId) {
                this.content = "";
                return;
            }

            try {
                this.isWorking = true;
                const emailSignatureTemplate = await emailSignatureTemplateApi.getByDealer(this.dealerId);

                this.content = emailSignatureTemplate.content || "";
            } finally {
                this.isWorking = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            try {
                this.content = trimAndReturnNullIfEmpty(this.content) || "";

                await emailSignatureTemplateApi.editEmailSignatureTemplate({
                    dealerId: this.dealerId!,
                    content: this.content,
                });
            } finally {
                this.isWorking = false;
            }
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadEmailSignatureTemplate();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadEmailSignatureTemplate();
    },

    components: {
        DealerContextGuard,
        WysiwygEditor: () => import("@/app/components/WysiwygEditor.vue"),
    },
});
