
import PreferredContactFormCard from "./PreferredContactFormCard.vue";
import { getCaseStatus } from "./caseUtils";
import { Case, CaseStatus } from "@/api/cases";
import { Contact, ContactMethod } from "@/api/contacts";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import CountDown from "@/app/components/CountDown.vue";
import PhoneNumberLink from "@/app/pages/PhoneNumberLink.vue";
import { configStore } from "@/store/config";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canEdit: {
            type: Boolean,
            required: true,
        },
        canSendEmail: {
            type: Boolean,
            default: false,
        },
        canSendSms: {
            type: Boolean,
            default: false,
        },
        canStartCall: {
            type: Boolean,
            default: false,
        },
        caseProp: {
            type: Object as () => Case,
            required: true,
        },
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
    },

    data() {
        return {
            ContactMethod,
            parseAndFormatNumber,
            preferredContactFormVisible: false,
        };
    },

    computed: {
        canUseTelLink(): boolean {
            return configStore.configuration.canUseTelLink;
        },

        caseStatus(): CaseStatus {
            return getCaseStatus(this.caseProp);
        },

        isCaseActive(): boolean {
            return this.caseStatus !== CaseStatus.CLOSED && this.caseStatus !== CaseStatus.POSTPONED;
        },
    },

    methods: {
        closeEditForm() {
            this.preferredContactFormVisible = false;
        },

        sendEmail() {
            this.$emit("send-email", this.caseProp.preferredContactDetails);
        },

        sendSms() {
            this.$emit("send-sms", this.caseProp.preferredContactDetails);
        },

        showEditForm() {
            this.preferredContactFormVisible = true;
        },

        startCall() {
            this.$emit("start-call", this.caseProp.preferredContactDetails);
        },
    },

    components: {
        CopyToClipboardIcon,
        CountDown,
        PhoneNumberLink,
        PreferredContactFormCard,
    },
});
