
import AutoCrmLeadMailContactCard from "./AutoCrmLeadMailContactCard.vue";
import AutoCrmLeadMailDataCard from "./AutoCrmLeadMailDataCard.vue";
import AutoCrmLeadMailReceiverCard from "./AutoCrmLeadMailReceiverCard.vue";
import AutoCrmLeadMailVehicleCard from "./AutoCrmLeadMailVehicleCard.vue";
import { autoCrmLeadMailAssistantState } from "./autoCrmLeadMailAssistantState";
import { autoCrmLeadMailerSettingsApi, AutoCrmLeadMailerUserSettings } from "@/api/autoCrmLeadMailerSettings";
import { autoCrmLeadMailerApi } from "@/api/autoCrmLeadMailers";
import { showConfirm, showError } from "@/app/messageUtil";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            autoCrmLeadMailAssistantState,
            checkingConfiguration: true,
            isConfigured: false,
            submitted: false,
            submitting: false,
            userSettings: [] as AutoCrmLeadMailerUserSettings[],
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        working(): boolean {
            return this.submitting;
        },
    },

    methods: {
        async submitLead() {
            if (
                !(this.$refs.form1 as any).validate() ||
                !(this.$refs.form2 as any).validate() ||
                !(this.$refs.form3 as any).validate()
            ) {
                showError(this.$t("Bitte korrigieren Sie Ihre fehlerhaften Eingaben.") as string);
                return;
            }

            if (
                !this.autoCrmLeadMailAssistantState.receiverIdAndCategory &&
                !(await showConfirm(
                    this.$t("Es ist kein Empfänger ausgewählt!") as string,
                    this.$t("Sind Sie sicher, dass Sie ohne Empfänger fortfahren möchten?") as string
                ))
            ) {
                return;
            }

            this.submitting = true;
            try {
                await autoCrmLeadMailerApi.submitLead(this.dealerId!, this.autoCrmLeadMailAssistantState.autoCrmLead);
                this.submitted = true;
                this.autoCrmLeadMailAssistantState.reset();
            } finally {
                this.submitting = false;
            }
        },

        async loadSettings() {
            this.isConfigured = false;
            this.checkingConfiguration = true;

            try {
                if (this.dealerId) {
                    this.isConfigured = await autoCrmLeadMailerSettingsApi.isConfigured(this.dealerId);
                } else {
                    this.isConfigured = false;
                }
                if (this.isConfigured) {
                    this.userSettings = await autoCrmLeadMailerSettingsApi.getUserSettings();
                }
            } finally {
                this.checkingConfiguration = false;
            }
        },

        async reset() {
            if (
                await showConfirm(
                    this.$t("Eingaben zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?") as string
                )
            ) {
                this.autoCrmLeadMailAssistantState.reset();
                this.submitted = false;
            }
        },
    },

    watch: {
        async dealerId() {
            await this.loadSettings();
        },
    },

    async mounted() {
        await this.loadSettings();
    },

    components: {
        AutoCrmLeadMailContactCard,
        AutoCrmLeadMailDataCard,
        AutoCrmLeadMailReceiverCard,
        AutoCrmLeadMailVehicleCard,
        DealerContextWarning,
    },
});
