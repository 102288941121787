import {
    isReportingDealerSettingsChangedNotification,
    isReportingSettingsChangedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { ReportingDealerSettings, ReportingSettings, reportingSettingsApi } from "@/api/reportingSettings";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { updateItem } from "@/util/storeUtils";

@reactive
class ReportingSettingsStore {
    private refreshLimiter = new ActionLimiter(true);
    private reportingDealerSettings_: ReportingDealerSettings[] = [];
    private reportingSettings_: ReportingSettings | null = null;

    getReportingDealerSettingsByDealer(dealerId: string): ReportingDealerSettings {
        const settings = this.reportingDealerSettings_.find((s) => s.dealerId === dealerId);

        if (!settings) {
            return {
                dealerId,
                caseReactionTimeOfficeHoursId: null,
                caseReactionTimeAsPerceivedExternallyOfficeHoursId: null,
                opportunityReactionTimeOfficeHoursId: null,
                opportunityReactionTimeAsPerceivedExternallyOfficeHoursId: null,
            };
        }

        return settings;
    }

    get reportingDealerSettings(): ReportingDealerSettings[] {
        return [...this.reportingDealerSettings_];
    }

    get reportingSettings(): ReportingSettings {
        return this.reportingSettings_!;
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.reportingDealerSettings_ = await reportingSettingsApi.getAllReportingDealerSettings();
            this.reportingSettings_ = await reportingSettingsApi.getReportingSettings();
        });
    }

    handleNotification(n: Notification) {
        if (isReportingDealerSettingsChangedNotification(n)) {
            updateItem(this.reportingDealerSettings_, n.reportingDealerSettings, (r) => r.dealerId);
        } else if (isReportingSettingsChangedNotification(n)) {
            this.reportingSettings_ = n.reportingSettings;
        }
    }
}

export const reportingSettingsStore = new ReportingSettingsStore();

notificationEventSource.addDataHandler((n) => reportingSettingsStore.handleNotification(n));
