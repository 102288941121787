
import IpRestrictionDialog from "./IpRestrictionDialog.vue";
import { ipRestrictionsApi } from "@/api/ipRestrictions";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import { showConfirm } from "@/app/messageUtil";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: false,
            saving: false,
            showDialog: false,
            addressRanges: [] as string[],
            userIds: [] as string[],
        };
    },

    computed: {
        userOptions(): SelectOptions {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },
    },

    methods: {
        async loadAddressRanges() {
            this.loading = true;
            try {
                this.addressRanges = await ipRestrictionsApi.listAddressRanges();
            } finally {
                this.loading = false;
            }
        },

        async deleteAddressRange(range: string) {
            if (
                !(await showConfirm(
                    this.$t("IP-Einschränkung löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die IP-Einschränkung löschen möchten?") as string
                ))
            ) {
                return;
            }

            await ipRestrictionsApi.deleteAddressRange(range);
            await this.loadAddressRanges();
        },

        async addressRangeAdded() {
            this.showDialog = false;
            await this.loadAddressRanges();
        },

        async updateExceptions() {
            this.saving = true;
            try {
                await ipRestrictionsApi.editExceptions(this.userIds);
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        await this.loadAddressRanges();
        this.userIds = await ipRestrictionsApi.getExceptions();
    },

    components: {
        DAutocomplete,
        DealerGlobalContextHint,
        IpRestrictionDialog,
    },
});
