
import DropboxInfoCard from "./locationdetails/DropboxInfoCard.vue";
import EmergencyIncidentInfoCard from "./locationdetails/EmergencyIncidentInfoCard.vue";
import LegalInspectionInfoCard from "./locationdetails/LegalInspectionInfoCard.vue";
import LocationInfoCard from "./locationdetails/LocationInfoCard.vue";
import OpeningHoursInfoCard from "./locationdetails/OpeningHoursInfoCard.vue";
import PartnersInfoCard from "./locationdetails/PartnersInfoCard.vue";
import ServicesOfferedInfoCard from "./locationdetails/ServicesOfferedInfoCard.vue";
import { Dealer, ExternalBdcTarget } from "@/api/dealers";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            alignRight: true,
        };
    },

    computed: {
        dealer(): Dealer | null {
            if (!this.dealerId) {
                return null;
            }

            return dealersStore.dealerById(this.dealerId);
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        dealerGroupName(): string {
            return configStore.configuration.dealergroupName;
        },

        externalBdcTargetsWithInstructions(): ExternalBdcTarget[] {
            if (!this.dealer) {
                return [];
            }

            return this.dealer.externalBdcTargets
                .filter((e) => e.instructions)
                .sort((a, b) => a.number.localeCompare(b.number, userSession.locale));
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },
    },

    components: {
        DropboxInfoCard,
        EmergencyIncidentInfoCard,
        LegalInspectionInfoCard,
        LocationInfoCard,
        OpeningHoursInfoCard,
        PartnersInfoCard,
        ServicesOfferedInfoCard,
    },
});
