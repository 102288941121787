
import DealerLink from "./DealerLink.vue";
import OfficeHoursCard from "./officehours/OfficeHoursCard.vue";
import { Dealer } from "@/api/dealers";
import { EscalationGroup, escalationGroupsApi, EscalationGroupVisibility } from "@/api/escalationGroups";
import { OfficeHours } from "@/api/officeHours";
import { UserDirectoryEntry } from "@/api/users";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { officeHoursStore } from "@/store/officeHours";
import { usersStore } from "@/store/users";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
    props: {
        escalationGroupId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            currentDate: now(),
            escalationGroup: null as EscalationGroup | null,
            loading: true,
            getFullName,
            EscalationGroupVisibility,
        };
    },

    computed: {
        dealer(): Dealer | null {
            return this.escalationGroup ? dealersStore.dealerById(this.escalationGroup.dealerId) : null;
        },

        officeHours(): OfficeHours | null {
            return this.escalationGroup?.officeHoursId
                ? officeHoursStore.getOfficeHoursById(this.escalationGroup.officeHoursId)
                : null;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        getUserById(userId: string): UserDirectoryEntry | null {
            return usersStore.getUserById(userId);
        },

        getItemsTranslationText(items: string[], translationSupplier: (key: string) => TranslateResult | string) {
            return items.map((i) => translationSupplier(i)).join(", ");
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)!.name;
        },
    },

    async mounted() {
        try {
            this.escalationGroup = await escalationGroupsApi.getById(this.escalationGroupId);
        } finally {
            this.loading = false;
        }
    },

    components: {
        DealerLink,
        OfficeHoursCard,
    },
});
