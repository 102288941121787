
import {
    FinanceContractParserCharset,
    FinanceContractParserFormat,
    FinanceContractParserSeparator,
    financeContractUploadsApi,
    FinanceContractUploadSource,
    FinanceContractUploadSourceForm,
} from "@/api/financeContractUploads";
import CountryPicker from "@/app/components/CountryPicker.vue";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import EnumField from "@/app/components/EnumField.vue";
import { dealerOptions, getSortedByDealer } from "@/app/dealerUtils";
import { getFullName } from "@/app/userUtils";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        financeContractUploadSource: {
            type: Object as () => FinanceContractUploadSource | null,
            required: false,
        },
    },

    data() {
        return {
            FinanceContractParserFormat,
            FinanceContractParserCharset,
            FinanceContractParserSeparator,

            valid: true,
            isWorking: false,

            // FORM FIELDS
            name: this.financeContractUploadSource?.name || "",
            processInAdvanceMonth: this.financeContractUploadSource?.processInAdvanceMonth || 6,
            dealerId: this.financeContractUploadSource?.dealerId || null,
            opportunitySourceId: this.financeContractUploadSource?.opportunitySourceId || null,
            individualReceivers: this.financeContractUploadSource?.individualReceivers || [],
            opportunityTeamReceivers: this.financeContractUploadSource?.opportunityTeamReceivers || [],
            defaultCountry:
                this.financeContractUploadSource?.defaultCountry || configStore.configuration.defaultCountry,
            format: (this.financeContractUploadSource?.format || null) as FinanceContractParserFormat | null,
            charset: (this.financeContractUploadSource?.charset || null) as FinanceContractParserCharset | null,
            separator: (this.financeContractUploadSource?.separator || null) as FinanceContractParserSeparator | null,

            // RULES
            nameRules: notEmpty().maxLength(63),
            notEmpty,
        };
    },

    computed: {
        processInAdvanceOptions(): SelectOption[] {
            return [1, 3, 6, 9, 12, 18].map((v) => ({
                value: v,
                text: this.$tc("0 Monate | 1 Monat | {count} Monate", v),
            }));
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        opportunitySourceOptions(): SelectOption[] {
            return opportunitySourcesStore.opportunitySources.map((s) => ({ value: s.id, text: s.name }));
        },

        individualReceiversOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        opportunityTeamReceiversOptions(): SelectOption[] {
            return getSortedByDealer(opportunityTeamsStore.opportunityTeams, (t) => t.dealerId).map((t) => ({
                text: `${t.name} (${dealersStore.dealerById(t.dealerId)!.name})`,
                value: t.id,
            }));
        },
    },

    methods: {
        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            const financeContractUploadSourceForm: FinanceContractUploadSourceForm = {
                name: this.name,
                processInAdvanceMonth: this.processInAdvanceMonth,
                dealerId: this.dealerId!,
                opportunitySourceId: this.opportunitySourceId!,
                individualReceivers: this.individualReceivers,
                opportunityTeamReceivers: this.opportunityTeamReceivers,
                defaultCountry: this.defaultCountry,
                format: this.format!,
                charset: this.charset,
                separator: this.separator,
            };

            try {
                if (this.financeContractUploadSource) {
                    await financeContractUploadsApi.editFinanceContractUploadSource(
                        this.financeContractUploadSource.id,
                        financeContractUploadSourceForm
                    );
                } else {
                    await financeContractUploadsApi.addFinanceContractUploadSource(financeContractUploadSourceForm);
                }
            } finally {
                this.isWorking = false;
            }
            this.$emit("success");
        },

        close() {
            this.$emit("close");
        },
    },

    components: {
        CountryPicker,
        DAutocomplete,
        EnumField,
    },
});
