
import { EmergencyIncident, EmergencyIncidentResult } from "@/api/emergencyIncidents";
import DLink from "@/app/components/DLink.vue";
import { getContactCaption, getContactDeleted } from "@/app/pages/emergencyincidents/emergencyIncidentUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        compact: {
            type: Boolean,
            required: true,
        },
        emergencyIncidentResult: {
            type: Object as () => EmergencyIncidentResult,
            required: true,
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        contactCaption(): string | null {
            return getContactCaption(this.emergencyIncidentResult);
        },

        contactDeleted(): boolean {
            return getContactDeleted(this.emergencyIncidentResult);
        },

        emergencyIncident(): EmergencyIncident {
            return this.emergencyIncidentResult.emergencyIncident;
        },
    },

    components: {
        DLink,
    },
});
