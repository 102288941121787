
import { BillingAccount } from "@/api/billingAccounts";
import { Dealer, dealersApi, DealerStubForm } from "@/api/dealers";
import { BadRequest } from "@/api/errors";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { maxLength, notEmpty, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { cloneObject } from "@/util/cloneUtils";
import { PickNullable, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealer: {
            type: Object as () => Dealer | null,
            default: null,
        },
        billingAccounts: {
            type: Array as () => BillingAccount[],
            required: true,
        },
    },

    data() {
        const validationHelper = new ValidationHelper();

        const dealerStubForm: PickNullable<DealerStubForm, "name" | "billingAccountId" | "citnowAccountNumber"> = this
            .dealer
            ? cloneObject(this.dealer)
            : {
                  name: null,
                  billingAccountId: this.billingAccounts.length ? this.billingAccounts[0].id : null,
                  citnowAccountNumber: null,
                  takeOverCallerId: true,
                  externalBdcTargets: [],
                  active: true,
              };

        return {
            notEmpty,
            maxLength,
            valid: true,
            isWorking: false,
            validationHelper,

            // FORM FIELDS
            dealerStubForm,

            // RULES
            externalBdcTargetNumberRules: notEmpty()
                .e164()
                .and(validationHelper, "number"),
        };
    },

    computed: {
        billingAccountsOptions(): SelectOptions {
            return this.billingAccounts.map((b) => ({ value: b.id, text: b.companyName }));
        },

        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },
    },

    methods: {
        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            try {
                if (this.dealer) {
                    await dealersApi.editStub(this.dealer.id, this.dealerStubForm as DealerStubForm);
                } else {
                    await dealersApi.addStub(this.dealerStubForm as DealerStubForm);
                }

                this.$emit("success");
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, this.$refs.form);
                return;
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },

        addExternalBdcTarget() {
            this.dealerStubForm.externalBdcTargets.push({
                number: "",
                greeting: this.$t(
                    "Herzlich Willkommen in der externen Telefonzentrale von {0}. Mein Name ist XXX. Was darf ich für Sie tun?",
                    [this.dealerStubForm.name]
                ) as string,
                instructions: "",
            });
        },

        removeExternalBdcTarget(index: number) {
            this.dealerStubForm.externalBdcTargets.splice(index, 1);
        },
    },

    components: {
        PhoneNumberField,
    },
});
