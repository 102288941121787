import axios from "axios";

export interface OpportunityIndividualEscalationData {
    readonly opportunityTeamId: string;
    readonly escalationTimeMinutes: number;
}

export interface OpportunityIndividualEscalation {
    readonly userId: string;
    readonly individualEscalationData: OpportunityIndividualEscalationData | null;
}

interface OpportunityIndividualEscalationsApi {
    deleteByUser(userId: string): Promise<void>;
    editIndividualEscalation(
        userId: string,
        individualEscalationData: OpportunityIndividualEscalationData
    ): Promise<void>;
    getByDealer(dealerId: string): Promise<OpportunityIndividualEscalation[]>;
}

export const opportunityIndividualEscalationsApi: OpportunityIndividualEscalationsApi = {
    async deleteByUser(userId) {
        await axios.delete(`/api/opportunity-individual-escalations/_/${userId}`);
    },

    async editIndividualEscalation(userId, individualEscalationData) {
        await axios.put(`/api/opportunity-individual-escalations/_/${userId}`, individualEscalationData);
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/opportunity-individual-escalations/${dealerId}`)).data;
    },
};
