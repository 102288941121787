
import CaseGeneralInfo from "./datatable/CaseGeneralInfo.vue";
import CaseMainInfo from "./datatable/CaseMainInfo.vue";
import ContactInfo from "./datatable/ContactInfo.vue";
import { Case, CasePriority, CaseResult, CaseUrgency, CaseUser } from "@/api/cases";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        canSelect: {
            type: Boolean,
            default: false,
        },
        caseResult: {
            type: Object as () => CaseResult,
            required: false,
        },
        caseUser: {
            type: Object as () => CaseUser,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            default: false,
        },
        selectDisabled: {
            type: Boolean,
            default: false,
        },
        ultraCompactMode: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        caseObj(): Case | null {
            return this.caseResult?.caseObj ?? null;
        },

        colCount(): number {
            return 2 + (this.ultraCompactMode ? 0 : 1);
        },

        compact(): boolean {
            return userSession.compactMode || this.ultraCompactMode;
        },

        horizontalPadding(): number {
            return this.ultraCompactMode || this.$vuetify.breakpoint.xsOnly ? 2 : 3;
        },

        rowClass(): string {
            if (!this.caseObj) {
                return "";
            }

            const darkModeClass = this.$vuetify.theme.dark ? "dark-mode" : "";

            if (this.caseObj.priority === CasePriority.HIGH) {
                if (this.caseObj.urgency === CaseUrgency.PERSONAL_APPEARANCE) {
                    return `personal-appearance-urgency ${darkModeClass}`;
                } else {
                    return `high-priority ${darkModeClass}`;
                }
            } else if (this.caseObj.priority === CasePriority.MEDIUM) {
                return `medium-priority ${darkModeClass}`;
            } else if (this.caseObj.priority === CasePriority.LOW && !!this.unreadActivities) {
                return `low-priority-with-unread-activities ${darkModeClass}`;
            } else {
                return "";
            }
        },

        tableViewCellClass(): string {
            return `py-${this.verticalPadding} pl-${this.horizontalPadding} pr-0`;
        },

        tableViewLastCellClass(): string {
            return `${this.tableViewCellClass} pr-${this.horizontalPadding}`;
        },

        unreadActivities(): number | null {
            if (!this.caseObj || !this.caseUser) {
                return null;
            }

            return this.caseObj.activitiesCount - this.caseUser.activitiesRead;
        },

        verticalPadding(): number {
            return this.$vuetify.breakpoint.xsOnly ? 2 : this.compact ? 1 : 2;
        },
    },

    components: {
        CaseGeneralInfo,
        CaseMainInfo,
        ContactInfo,
    },
});
