
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerId: {
            type: String,
            required: true,
        },
    },

    computed: {
        name(): string {
            const dealer = dealersStore.dealerById(this.dealerId);
            if (!dealer) {
                return this.$t("Gelöschter Händler") as string;
            }
            return dealer.name;
        },
    },
});
