import { isOutgoingCallUpdatedNotification, notificationEventSource } from "@/api/notifications";
import { OutgoingCall, outgoingCallsApi } from "@/api/outgoingCalls";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class OutgoingCallsStore {
    private initLimiter = new ActionLimiter(true);
    private outgoingCalls_: OutgoingCall[] = [];

    async init() {
        await this.initLimiter.execute(async () => {
            this.outgoingCalls_ = await outgoingCallsApi.getAllActiveCalls();
        });
    }

    get allActiveCalls() {
        return this.outgoingCalls_;
    }

    handleUpdate(newOutgoingCall: OutgoingCall) {
        const currentOutgoingCall = this.outgoingCallById(newOutgoingCall.id);

        // if call has ended and this call id was in store, remove it
        if (newOutgoingCall.ended) {
            if (currentOutgoingCall) {
                this.outgoingCalls_ = this.outgoingCalls_.filter((c) => c !== currentOutgoingCall);
            }
            return;
        }

        // if call has not ended, replace or add the new call
        if (currentOutgoingCall) {
            this.outgoingCalls_ = this.outgoingCalls_.filter((c) => c !== currentOutgoingCall);
        }
        this.outgoingCalls_.push(newOutgoingCall);
    }

    outgoingCallById(id: string) {
        return this.outgoingCalls_.find((c) => c.id === id);
    }

    outgoingCallByInitiator(userId: string) {
        for (const outgoingCall of this.outgoingCalls_) {
            if (
                (outgoingCall.initiatorUserId === userId || outgoingCall.initiatorCtUserId === userId) &&
                !outgoingCall.released
            ) {
                return outgoingCall;
            }
        }
    }
}

export const outgoingCallsStore = new OutgoingCallsStore();

notificationEventSource.addDataHandler((n) => {
    if (isOutgoingCallUpdatedNotification(n)) {
        outgoingCallsStore.handleUpdate(n.outgoingCall);
    }
});
