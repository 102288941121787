
import { Contact, ContactData } from "@/api/contacts";
import { inventoryApi, InventoryVehicle } from "@/api/inventory";
import { SmsTemplate } from "@/api/smsTemplates";
import FileDropZone from "@/app/components/FileDropZone.vue";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { EditModeMixin } from "@/app/editModeUtils";
import { downloadOrOpenFile, fileDialog, getFile } from "@/app/fileUtils";
import { renderInventoryVehicleTitle, VehicleSummary } from "@/app/inventoryUtils";
import { showConfirm } from "@/app/messageUtil";
import InventoryVehicleExposePickerButton from "@/app/pages/inventory/InventoryVehicleExposePickerButton.vue";
import InventoryVehiclePickerDialog from "@/app/pages/inventory/InventoryVehiclePickerDialog.vue";
import OutgoingTextTemplatePickerButton from "@/app/pages/outgoingtexttemplates/OutgoingTextTemplatePickerButton.vue";
import { UrlWithKey } from "@/app/placeholderUtils";
import { configStore } from "@/store/config";
import Vue from "vue";

export enum OutgoingWhatsAppFormAttachmentScope {
    ADDED_BY_USER = "ADDED_BY_USER",
    INVENTORY_VEHICLE_EXPOSE = "INVENTORY_VEHICLE_EXPOSE",
}

export enum OutgoingWhatsAppFormAttachmentStatus {
    AVAILABLE,
    DOWNLOAD_FAILED,
    DOWNLOADING,
}

export interface OutgoingWhatsAppFormAttachment {
    readonly id: string;
    readonly name: string | null;
    readonly scope: OutgoingWhatsAppFormAttachmentScope;
    readonly inventoryVehicle: InventoryVehicle | null;

    active: boolean;
    file: File | null;
    fileStatus: OutgoingWhatsAppFormAttachmentStatus;
}

export default Vue.extend({
    props: {
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        fromNumber: {
            type: String,
            default: () => "",
        },
        issueId: {
            type: String as () => string | null,
            default: null,
        },
        toNumber: {
            type: String,
            default: () => "",
        },
        urls: {
            type: Array as () => UrlWithKey[],
            default: () => [],
        },
        vehicleSummaries: {
            type: Array as () => VehicleSummary[],
            default: () => [],
        },
        working: {
            type: Boolean,
            required: true,
        },
        inventoryVehicles: {
            type: Array as () => InventoryVehicle[],
            default: () => [],
        },
    },

    data() {
        return {
            attachments: [] as OutgoingWhatsAppFormAttachment[],
            inventoryVehiclePickerDialogVisible: false,
            isSelectionHandlerRegistered: false,
            OutgoingWhatsAppFormAttachmentScope,
            OutgoingWhatsAppFormAttachmentStatus,
            previewFile: null as File | null,
            selectionStart: 0,
            selectionEnd: 0,
            text: "",
        };
    },

    computed: {
        activeAttachments(): OutgoingWhatsAppFormAttachment[] {
            return this.attachments.filter((a) => a.active);
        },

        contactData(): ContactData | null {
            if (this.contact?.deleted) {
                return null;
            }

            return this.contact?.contactData || null;
        },

        hasUnavailableActiveAttachments(): boolean {
            return this.activeAttachments.some((a) => a.fileStatus !== OutgoingWhatsAppFormAttachmentStatus.AVAILABLE);
        },

        selectedInventoryVehicleIds(): string[] {
            return this.activeAttachments
                .filter((a) => a.scope === OutgoingWhatsAppFormAttachmentScope.INVENTORY_VEHICLE_EXPOSE)
                .map((a) => a.id);
        },

        textRules(): Function[] {
            return [
                (v: string) =>
                    !!this.activeAttachments.length || !!v.trim() || this.$t("Dieses Feld ist erforderlich."),
            ];
        },

        acceptedFileTypes(): string[] {
            return configStore.configuration.whitelistedOutgoingFileExtensions;
        },
    },

    watch: {
        activeAttachments() {
            (this.$refs.form as any).validate();
        },
    },

    methods: {
        showFileDialog() {
            fileDialog(true, this.addFiles, this.acceptedFileTypes);
        },

        addFiles(files: File[]) {
            for (const file of files) {
                const id = (this as any).$id();

                this.attachments.push({
                    active: true,
                    file,
                    fileStatus: OutgoingWhatsAppFormAttachmentStatus.AVAILABLE,
                    name: null,
                    id: `${OutgoingWhatsAppFormAttachmentScope.ADDED_BY_USER}-${id}`,
                    scope: OutgoingWhatsAppFormAttachmentScope.ADDED_BY_USER,
                    inventoryVehicle: null,
                });
            }
        },

        deleteAttachment(id: string) {
            const index = this.attachments.findIndex((f) => f.id === id);

            if (index < 0) {
                return;
            }

            if (this.attachments[index].scope === OutgoingWhatsAppFormAttachmentScope.INVENTORY_VEHICLE_EXPOSE) {
                this.attachments[index].active = false;
                return;
            }

            this.attachments.splice(index, 1);
        },

        handleSelection(e: Event) {
            if (!e.target) {
                return;
            }

            const textarea = e.target as HTMLTextAreaElement;

            this.selectionStart = textarea.selectionStart;
            this.selectionEnd = textarea.selectionEnd;
        },

        insertTextFragment({ renderedContent }: { readonly template: SmsTemplate; readonly renderedContent: string }) {
            this.text =
                this.text.substr(0, this.selectionStart) + renderedContent + this.text.substr(this.selectionEnd);

            this.selectionStart = this.selectionStart + renderedContent.length;
            this.selectionEnd = this.selectionStart;
        },

        async loadExposeAttachment(attachment: OutgoingWhatsAppFormAttachment) {
            attachment.fileStatus = OutgoingWhatsAppFormAttachmentStatus.DOWNLOADING;

            const exposeLink = inventoryApi.generateVehicleExposeLink(attachment.inventoryVehicle!.id);
            const file = await getFile(exposeLink, attachment.name!);

            if (file) {
                attachment.file = file;
                attachment.fileStatus = OutgoingWhatsAppFormAttachmentStatus.AVAILABLE;
            } else {
                attachment.fileStatus = OutgoingWhatsAppFormAttachmentStatus.DOWNLOAD_FAILED;
            }
        },

        registerSelectionHandler() {
            const component = this.$refs.textarea as any;

            if (this.isSelectionHandlerRegistered || !component) {
                return;
            }

            const textarea = component.$refs.input as HTMLTextAreaElement;

            textarea.addEventListener("click", this.handleSelection);
            textarea.addEventListener("keyup", this.handleSelection);
            textarea.addEventListener("select", this.handleSelection);

            this.isSelectionHandlerRegistered = true;
        },

        setBodyToTextTemplate({
            renderedContent,
        }: {
            readonly template: SmsTemplate;
            readonly renderedContent: string;
        }) {
            this.text = renderedContent;
            this.selectionStart = this.text.length;
            this.selectionEnd = this.selectionStart;
        },

        async submit() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            if (
                this.hasUnavailableActiveAttachments &&
                !(await showConfirm(
                    this.$t("Exposé fehlt im Anhang") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie die WhatsApp-Nachricht trotz fehlendem Anhang verschicken möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            this.$emit("submit", {
                text: this.text,
                files: this.activeAttachments.filter((a) => a.file).map((a) => a.file!),
            });
        },

        showAttachment(attachment: OutgoingWhatsAppFormAttachment, download: boolean) {
            if (!attachment.file) {
                return;
            }

            const fname = attachment.file.name.toLowerCase();

            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.previewFile = attachment.file;
            } else {
                downloadOrOpenFile(attachment.file, download);
            }
        },

        async selectInventoryVehicle(inventoryVehicle: InventoryVehicle) {
            const existingAttachment = this.attachments.find(
                (f) =>
                    f.id === inventoryVehicle.id &&
                    f.scope === OutgoingWhatsAppFormAttachmentScope.INVENTORY_VEHICLE_EXPOSE
            );

            if (existingAttachment) {
                existingAttachment.active = true;
                return;
            }

            const title = renderInventoryVehicleTitle(inventoryVehicle) || "";
            const filename = `expose-${title}-${inventoryVehicle.id.substr(0, 5)}.pdf`
                .trim()
                .toLowerCase()
                .replace(/[^a-z0-9_\-.]/g, "_")
                .replace(/_*-+_*/g, "-");

            const attachment: OutgoingWhatsAppFormAttachment = {
                active: true,
                file: null,
                fileStatus: OutgoingWhatsAppFormAttachmentStatus.DOWNLOADING,
                id: inventoryVehicle.id,
                name: inventoryVehicle.data.internalId ? `expose-${inventoryVehicle.data.internalId}.pdf` : filename,
                scope: OutgoingWhatsAppFormAttachmentScope.INVENTORY_VEHICLE_EXPOSE,
                inventoryVehicle,
            };

            this.attachments.push(attachment);
            await this.loadExposeAttachment(attachment);
        },
    },

    mixins: [EditModeMixin],

    components: {
        FileDropZone,
        ImageCarousel,
        InventoryVehiclePickerDialog,
        InventoryVehicleExposePickerButton,
        OutgoingTextTemplatePickerButton,
        PhoneNumberField,
    },
});
