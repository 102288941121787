
import InternalIncomingPhoneNumberConfigurationCard from "./internalIncomingphonenumberconfiguration/InternalIncomingPhoneNumberConfigurationCard.vue";
import InternalIncomingPhoneNumberConfigurationDialog from "./internalIncomingphonenumberconfiguration/InternalIncomingPhoneNumberConfigurationDialog.vue";
import { CallTrackingGroup, callTrackingGroupsApi } from "@/api/callTrackingGroups";
import { incomingPhoneNumbersApi, InternalIncomingPhoneNumber } from "@/api/incomingPhoneNumbers";
import { Permission } from "@/api/userSession";
import { showConfirm, showInfo } from "@/app/messageUtil";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            callTrackingGroups: [] as CallTrackingGroup[],
            deleting: false,
            internalIncomingPhoneNumbers: [] as InternalIncomingPhoneNumber[],
            loading: true,
            internalIncomingPhoneNumberConfigurationDialogNumber: null as InternalIncomingPhoneNumber | null,
            internalIncomingPhoneNumberConfigurationDialogVisible: false,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        items(): InternalIncomingPhoneNumber[] {
            return this.internalIncomingPhoneNumbers
                .filter((i) => i.dealerId === this.dealerId)
                .filter((i) => !!i.name);
        },

        nonConfiguredNumbersLeft(): boolean {
            return this.internalIncomingPhoneNumbers.some((n) => !n.name);
        },
    },

    methods: {
        async deleteInternalIncomingPhoneNumberConfiguration(item: InternalIncomingPhoneNumber) {
            if (
                this.internalIncomingPhoneNumbers.some(
                    (n) =>
                        n.autoAnswerCustomNumber === item.number ||
                        n.autoAnswerDtmfMenuOptions.some((o) => o.phoneNumber === item.number)
                )
            ) {
                showInfo(
                    this.$t(
                        "Diese Rufnummer kann nicht gelöscht werden, da auf diese noch von anderen Rufnummern weitergeleitet wird."
                    ) as string
                );
                return;
            }

            if (
                !(await showConfirm(
                    this.$t("Eingehende Rufnummer löschen").toString(),
                    this.$t("Sind Sie sicher, dass Sie die eingehende Rufnummer löschen möchten?").toString()
                ))
            ) {
                return;
            }

            this.deleting = true;
            try {
                await incomingPhoneNumbersApi.deleteIncomingPhoneNumberConfiguration(item.id);

                await this.loadItems();
            } finally {
                this.deleting = false;
            }
        },

        async hideConfigurationDialog(refresh: boolean) {
            this.internalIncomingPhoneNumberConfigurationDialogNumber = null;
            this.internalIncomingPhoneNumberConfigurationDialogVisible = false;

            if (refresh) {
                await this.loadItems();
            }
        },

        async loadItems() {
            this.callTrackingGroups = [];
            this.internalIncomingPhoneNumbers = [];
            this.loading = true;

            try {
                const [internalIncomingPhoneNumbers, callTrackingGroups] = await Promise.all([
                    incomingPhoneNumbersApi.getAllInternal(),
                    callTrackingGroupsApi.getAll(),
                ]);

                this.callTrackingGroups = callTrackingGroups;
                this.internalIncomingPhoneNumbers = internalIncomingPhoneNumbers
                    .filter((i) => i.voiceCapability)
                    .sort((a, b) => a.number.localeCompare(b.number, userSession.locale));
            } finally {
                this.loading = false;
            }
        },

        showConfigurationAddDialog() {
            if (!this.nonConfiguredNumbersLeft) {
                showInfo(this.$t("Es sind keine freien Rufnummern mehr verfügbar.").toString());
                return;
            }

            this.internalIncomingPhoneNumberConfigurationDialogNumber = null;
            this.internalIncomingPhoneNumberConfigurationDialogVisible = true;
        },

        showConfigurationEditDialog(internalIncomingPhoneNumber: InternalIncomingPhoneNumber) {
            this.internalIncomingPhoneNumberConfigurationDialogNumber = internalIncomingPhoneNumber;
            this.internalIncomingPhoneNumberConfigurationDialogVisible = true;
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DealerContextGuard,
        InternalIncomingPhoneNumberConfigurationCard,
        InternalIncomingPhoneNumberConfigurationDialog,
    },
});
