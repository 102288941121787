import axios from "axios";

export enum CaseNotificationEmailFormat {
    REGULAR = "REGULAR",
    REGULAR_WITHOUT_CONTACT_DATA = "REGULAR_WITHOUT_CONTACT_DATA",
    REGULAR_WITH_CLOSE_LINK = "REGULAR_WITH_CLOSE_LINK",
}

export interface CaseNotificationSettings {
    readonly dealerId: string;
    readonly caseNotificationEmailFormat: CaseNotificationEmailFormat;
}

interface CaseNotificationSettingsApi {
    editCaseNotificationSettings(caseNotificationSettings: CaseNotificationSettings): Promise<void>;
    getByDealer(dealerId: string): Promise<CaseNotificationSettings>;
}

export const caseNotificationSettingsApi: CaseNotificationSettingsApi = {
    async editCaseNotificationSettings(caseNotificationSettings) {
        await axios.put("/api/case-notification-settings", caseNotificationSettings);
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/case-notification-settings/${dealerId}`)).data;
    },
};
