
import { Contact } from "@/api/contacts";
import { renderContactCaption } from "@/app/contactUtils";
import moment from "moment-timezone";
import Vue from "vue";

export interface StatusDashboardTableItemSentiment {
    readonly icon: string | null;
    readonly iconColor: string | null;
}
export interface StatusDashboardTableItem {
    readonly id: string;
    readonly contact: Contact | null;
    readonly deltaTimeDate: Date | null;
    readonly sentiment: StatusDashboardTableItemSentiment | null;
    readonly prioritized: boolean;
}

export default Vue.extend({
    props: {
        entityHeaderText: {
            type: String,
            required: true,
        },
        itemIcon: String,
        items: {
            type: Array as () => StatusDashboardTableItem[],
            required: true,
        },
        locale: {
            type: String,
            required: true,
        },
        noItemsText: {
            type: String,
            required: true,
        },
        now: {
            type: Date,
            required: true,
        },
        timeDeltaHeaderText: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            renderContactCaption,
        };
    },

    methods: {
        renderLocalDifference(baseDate: Date, itemDate: Date): string | null {
            return moment(itemDate)
                .locale(this.locale)
                .from(moment(baseDate));
        },
    },
});
