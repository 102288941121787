
import { ExteriorColor, Fuel, UsageType } from "@/api/inventory";
import { VehicleClass } from "@/api/makeModels";
import { opportunitiesApi, Opportunity, OpportunityRequestedVehicleForm } from "@/api/opportunities";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import PartialDatePicker from "@/app/components/PartialDatePicker.vue";
import ActionCard from "@/app/pages/ActionCard.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import ModelPicker from "@/app/pages/ModelPicker.vue";
import { decimal, defaultCurrency, integer } from "@/app/validation";
import { configStore } from "@/store/config";
import { makeModelsStore } from "@/store/makeModels";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate } from "@/util/dateTimeUtils";
import { SelectOption, YearMonthDay } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
    },

    data() {
        return {
            ExteriorColor,
            Fuel,
            currencyRules: decimal(defaultCurrency()),
            firstRegistration: {
                year: this.opportunityProp.firstRegistrationYear,
                month: this.opportunityProp.firstRegistrationMonth,
                day: this.opportunityProp.firstRegistrationDay,
            } as YearMonthDay,
            now: now(),
            positiveIntegerRules: integer(0),
            requestedVehicleFormData: {
                internalId: this.opportunityProp.internalId,
                link: this.opportunityProp.link,
                vehicleClass: this.opportunityProp.vehicleClass,
                make: this.opportunityProp.make,
                model: this.opportunityProp.model,
                modelDescription: this.opportunityProp.modelDescription,
                usageType: this.opportunityProp.usageType,
                mileage: this.opportunityProp.mileage,
                price: this.opportunityProp.price,
                firstRegistrationDay: null,
                firstRegistrationMonth: null,
                firstRegistrationYear: null,
                fuel: this.opportunityProp.fuel,
                power: this.opportunityProp.power,
                exteriorColor: this.opportunityProp.exteriorColor,
                cubicCapacity: this.opportunityProp.cubicCapacity,
                preOffer: this.opportunityProp.preOffer,
            } as OpportunityRequestedVehicleForm,
            UsageType,
            VehicleClass,
            working: false,
        };
    },

    computed: {
        currentDate(): string {
            return getDate(this.now, userSession.timeZone);
        },

        vehicleClassOptions(): SelectOption[] {
            return makeModelsStore.vehicleClasses
                .map((vc) => ({
                    value: vc,
                    text: this.$t(`vehicle.class.${vc}`).toString(),
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        defaultCurrency(): string {
            return configStore.configuration.defaultCurrency;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async save() {
            this.working = true;
            try {
                await opportunitiesApi.updateRequestedVehicle(this.opportunityProp.id, {
                    ...this.requestedVehicleFormData,
                    firstRegistrationDay: this.firstRegistration.day,
                    firstRegistrationMonth: this.firstRegistration.month,
                    firstRegistrationYear: this.firstRegistration.year,
                });
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        EnumField,
        MakePicker,
        ModelPicker,
        NumberField,
        PartialDatePicker,
    },
});
