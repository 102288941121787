
import { BadRequest } from "@/api/errors";
import { notEmpty, ValidationHelper } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        onSubmit: {
            type: Function,
            required: true,
        },
    },

    data() {
        const validationHelper = new ValidationHelper();
        return {
            isWorking: false,
            validationHelper,
            range: "",
            rangeRules: notEmpty()
                .maxLength(19)
                .and(validationHelper, "range"),
        };
    },

    methods: {
        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            try {
                await this.onSubmit(this.range);
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, this.$refs.form);
                return;
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },
    },
});
