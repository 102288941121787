
import { FrontendSettings, frontendSettingsApi } from "@/api/frontendSettings";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            notEmpty,
            frontendSettings: {
                canUseTelLink: false,
            } as FrontendSettings,
            loading: true,
            saving: false,
        };
    },

    methods: {
        async loadFrontendSettings() {
            this.loading = true;
            try {
                this.frontendSettings = await frontendSettingsApi.getFrontendSettings();
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (!(this.$refs.settingsForm as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await frontendSettingsApi.editFrontendSettings(this.frontendSettings);
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        await this.loadFrontendSettings();
    },

    components: {
        DealerGlobalContextHint,
    },
});
