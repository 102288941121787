
import { MakeModelSettings, makeModelSettingsApi } from "@/api/makeModelSettings";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { makeModelsStore } from "@/store/makeModels";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            saving: false,
            makeModelSettings: {
                preferredMakes: [],
            } as MakeModelSettings,
        };
    },

    computed: {
        makes() {
            return makeModelsStore.makes;
        },

        working(): boolean {
            return this.loading || this.saving;
        },
    },

    methods: {
        async loadMakeModelSettings() {
            this.loading = true;
            try {
                this.makeModelSettings = await makeModelSettingsApi.getMakeModelSettings();
            } finally {
                this.loading = false;
            }
        },

        async submit() {
            if (!(this.$refs.makemodelsettingsform as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await makeModelSettingsApi.editMakeModelSettings(this.makeModelSettings);
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        await this.loadMakeModelSettings();
    },

    components: {
        DealerGlobalContextHint,
    },
});
