import { Dealer, dealersApi } from "@/api/dealers";
import { isDealerChangedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { updateItem } from "@/util/storeUtils";

@reactive
class DealersStore {
    private dealers_: Dealer[] = [];
    private refreshLimiter = new ActionLimiter(true);

    dealerById(dealerId: string): Dealer | null {
        return this.dealers_.find((d) => d.id === dealerId) ?? null;
    }

    get dealers(): Dealer[] {
        return [...this.dealers_];
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.dealers_ = await dealersApi.list();
        });
    }

    handleNotification(n: Notification) {
        if (isDealerChangedNotification(n)) {
            updateItem(this.dealers_, n.dealer, (d) => d.id);
        }
    }
}

export const dealersStore = new DealersStore();

notificationEventSource.addDataHandler((n) => dealersStore.handleNotification(n));
