import axios from "axios";

export interface NoteTemplate {
    readonly id: string;
    readonly name: string;
    readonly content: string;
    readonly locale: string;
}

export interface NoteTemplateForm {
    readonly name: string;
    readonly content: string;
    readonly locale: string;
}

interface NoteTemplatesApi {
    list(): Promise<NoteTemplate[]>;
    add(noteTemplateForm: NoteTemplateForm): Promise<void>;
    edit(id: string, noteTemplateForm: NoteTemplateForm): Promise<void>;
    editOrder(ids: string[]): Promise<void>;
    delete(id: string): Promise<void>;
}

export const noteTemplatesApi: NoteTemplatesApi = {
    async list() {
        return (await axios.get("/api/note-templates")).data;
    },

    async add(noteTemplateForm) {
        await axios.post("/api/note-templates", noteTemplateForm);
    },

    async edit(id, noteTemplateForm) {
        await axios.put(`/api/note-templates/${id}`, noteTemplateForm);
    },

    async editOrder(ids) {
        await axios.post("/api/note-templates/_edit-order", ids);
    },

    async delete(id) {
        await axios.delete(`/api/note-templates/${id}`);
    },
};
