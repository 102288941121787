
import { ContactVehicleData, ContactVehicleForm, ContactVehicleRecordType, MileageUnit } from "@/api/contacts";
import { VehicleClass } from "@/api/makeModels";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import ExpansionPanel from "@/app/components/ExpansionPanel.vue";
import NumberField from "@/app/components/NumberField.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import ModelPicker from "@/app/pages/ModelPicker.vue";
import { integer, validate } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getCurrentUtcYear, getDate } from "@/util/dateTimeUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

const MIN_MODEL_YEAR = 1900;

function trimAndAddUndefinedFields(contactVehicleForm: ContactVehicleForm): ContactVehicleForm {
    // clears might result in undefined fields
    return {
        vin: trimAndReturnNullIfEmpty(contactVehicleForm.vin),
        licensePlate: trimAndReturnNullIfEmpty(contactVehicleForm.licensePlate),
        vehicleClass: contactVehicleForm.vehicleClass || null,
        make: trimAndReturnNullIfEmpty(contactVehicleForm.make),
        model: trimAndReturnNullIfEmpty(contactVehicleForm.model),
        modelDescription: trimAndReturnNullIfEmpty(contactVehicleForm.modelDescription),
        modelYear: trimAndReturnNullIfEmpty(contactVehicleForm.modelYear),
        firstRegistration: trimAndReturnNullIfEmpty(contactVehicleForm.firstRegistration),
        nextGeneralInspection: trimAndReturnNullIfEmpty(contactVehicleForm.nextGeneralInspection),
        nextInspection: trimAndReturnNullIfEmpty(contactVehicleForm.nextInspection),
        nextInspectionMileage: contactVehicleForm.nextInspectionMileage ?? null,
        nextInspectionMileageUnit: contactVehicleForm.nextInspectionMileageUnit || null,
        warrantyUntil: trimAndReturnNullIfEmpty(contactVehicleForm.warrantyUntil),
        warrantyUntilMileage: contactVehicleForm.warrantyUntilMileage ?? null,
        warrantyUntilMileageUnit: contactVehicleForm.warrantyUntilMileageUnit || null,
        warrantyPaintingUntil: trimAndReturnNullIfEmpty(contactVehicleForm.warrantyPaintingUntil),
        warrantyRustUntil: trimAndReturnNullIfEmpty(contactVehicleForm.warrantyRustUntil),
        records: contactVehicleForm.records ?? [],
        notes: trimAndReturnNullIfEmpty(contactVehicleForm.notes),
        url: trimAndReturnNullIfEmpty(contactVehicleForm.url),
        externalReference: trimAndReturnNullIfEmpty(contactVehicleForm.externalReference),
    };
}

export default Vue.extend({
    props: {
        canExitEditMode: {
            type: Boolean,
            default: false,
        },
        canUnselect: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        value: {
            type: Object as () => ContactVehicleData | null,
            required: false,
        },
        working: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            contactVehicleForm: this.value ? trimAndAddUndefinedFields(cloneObject(this.value)) : null,
            ContactVehicleRecordType,
            currentDate: getDate(now(), userSession.timeZone),
            now: now(),
            positiveIntegerRules: integer(0),
            recordType: ContactVehicleRecordType.CALL_CENTER,
            recordMileage: null as number | null,
            recordMileageUnit: configStore.configuration.defaultMileageUnit as MileageUnit,
            VehicleClass,
        };
    },

    computed: {
        defaultMileageUnit(): MileageUnit {
            return configStore.configuration.defaultMileageUnit as MileageUnit;
        },

        modelYearOptions(): string[] {
            const currentYear = getCurrentUtcYear(this.now);
            const result = [];

            for (let i = currentYear + 5; i >= MIN_MODEL_YEAR; i--) {
                result.push(i.toString());
            }

            return result;
        },
    },

    methods: {
        changeMake() {
            this.contactVehicleForm = {
                ...this.contactVehicleForm!,
                model: null,
            };

            this.handleUpdate();
        },

        handleUpdate() {
            if (!this.contactVehicleForm) {
                return;
            }

            let contactVehicleForm: ContactVehicleForm = trimAndAddUndefinedFields(
                cloneObject(this.contactVehicleForm)
            );

            // validate current record data and append or replace if valid
            if (validate(this.positiveIntegerRules, this.recordMileage) && this.recordMileage) {
                // if there was a record for todays date already, replace it
                contactVehicleForm = {
                    ...contactVehicleForm,
                    records: [
                        ...contactVehicleForm.records.filter((r) => r.date !== this.currentDate),
                        {
                            date: this.currentDate,
                            type: this.recordType,
                            mileage: this.recordMileage,
                            mileageUnit: this.recordMileageUnit,
                        },
                    ],
                };
            }

            this.$emit("input", contactVehicleForm);
        },

        initializeRecord() {
            if (!this.contactVehicleForm) {
                return;
            }

            const record = this.contactVehicleForm.records.find((r) => r.date === this.currentDate);

            this.recordType = record?.type ?? ContactVehicleRecordType.CALL_CENTER;
            this.recordMileage = record?.mileage ?? null;
            this.recordMileageUnit = record?.mileageUnit ?? this.defaultMileageUnit;
        },
    },

    watch: {
        contactVehicleForm() {
            this.initializeRecord();
        },

        value() {
            this.contactVehicleForm = this.value ? trimAndAddUndefinedFields(cloneObject(this.value)) : null;
            this.initializeRecord();
        },
    },

    mounted() {
        this.initializeRecord();
    },

    components: {
        DatePicker,
        EnumField,
        ExpansionPanel,
        MakePicker,
        ModelPicker,
        NumberField,
    },
});
