
import ContactFormDialog from "./ContactFormDialog.vue";
import ContactSearchDataTable from "./ContactSearchDataTable.vue";
import { contactSearchOverviewState } from "./contactSearchOverview";
import { contactDuplicatesApi } from "@/api/contactDuplicates";
import { contactsApi, ContactSource } from "@/api/contacts";
import { Permission } from "@/api/userSession";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { showAlert, showConfirm, showInfo } from "@/app/messageUtil";
import { userSession } from "@/store/userSession";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            ContactSource,
            contactSearchOverviewState,
            formDialogVisible: false,
            deletingUnusedContacts: false,
            duplicateRemovalInfoDialogTimeout: null as number | null,
            removingDuplicates: false,
        };
    },

    computed: {
        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        isAllowedToRemoveDuplicates(): boolean {
            return userSession.hasPermission(Permission.MANAGE_CONTACT_UPLOADS);
        },

        isAllowedToDeleteUnusedContacts(): boolean {
            return userSession.hasPermission(Permission.DELETE_CONTACT_UPLOADS);
        },
    },

    methods: {
        async createdContact(contactId: string) {
            this.formDialogVisible = false;
            await this.$router.push(`/contact/${contactId}/0`);
        },

        async removeDuplicates() {
            if (
                !(await showConfirm(
                    this.$t("Dublettenbereinigung") as string,
                    this.$t("Sind Sie sicher, dass Sie Kontaktdubletten entfernen möchten?") as string
                ))
            ) {
                return;
            }

            this.removingDuplicates = true;
            try {
                this.duplicateRemovalInfoDialogTimeout = setTimeout(() => {
                    showInfo(
                        this.$t(
                            "Die Dublettenbereinigung wurde gestartet und läuft im Hintergrund solange, bis keine weiteren Dubletten mehr gefunden werden können. Dies kann mehrere Stunden in Anspruch nehmen."
                        ) as string,
                        true
                    );
                }, 1000);

                const result = await contactDuplicatesApi.removeDuplicates();

                await showAlert(
                    this.$t("Dublettenbereinigung") as string,
                    this.$tc(
                        "Es wurden keine Kontaktdubletten erkannt. | Es wurde eine Kontaktdublette erkannt und entfernt. | Es wurden {count} Kontaktdubletten erkannt und entfernt.",
                        result
                    ) as string
                );

                this.contactSearchOverviewState.refresh();
            } finally {
                if (this.duplicateRemovalInfoDialogTimeout !== null) {
                    clearTimeout(this.duplicateRemovalInfoDialogTimeout);
                }

                this.removingDuplicates = false;
            }
        },

        async deleteUnusedContacts(contactSource: ContactSource) {
            const sourceName = this.$t(`enum.ContactSource.${contactSource}`);
            if (
                !(await showConfirm(
                    this.$t("Unbenutzte Kontakte löschen") as string,
                    this.$t('Sind Sie sicher, dass Sie unbenutzte Kontakte aus Quelle "{0}" löschen möchten?', [
                        sourceName,
                    ]) as string
                ))
            ) {
                return;
            }

            this.deletingUnusedContacts = true;
            try {
                await contactsApi.deleteUnusedContactsByContactSource(contactSource);
            } finally {
                this.deletingUnusedContacts = false;
            }

            this.contactSearchOverviewState.refresh();
        },
    },

    async mounted() {
        if (!this.contactSearchOverviewState.initialized) {
            this.contactSearchOverviewState.search = this.$route.query.searchTerm
                ? (this.$route.query.searchTerm as string)
                : null;
        }
    },

    beforeDestroy() {
        if (this.duplicateRemovalInfoDialogTimeout !== null) {
            clearTimeout(this.duplicateRemovalInfoDialogTimeout);
        }
    },

    components: {
        ContactFormDialog,
        ContactSearchDataTable,
        DatePicker,
        EnumField,
    },
});
