import axios from "axios";

interface PasswordPolicy {
    readonly minimumLength: number;
    readonly minimumRequiredLowercases: number;
    readonly minimumRequiredUppercases: number;
    readonly minimumRequiredDigits: number;
    readonly minimumRequiredSpecials: number;
    readonly forbiddenWords: string[];
}

interface PasswordPolicyApi {
    validate(password: string): Promise<void>;
    passwordPolicy(): Promise<PasswordPolicy>;
    editPasswordPolicy(passwordPolicy: PasswordPolicy): Promise<void>;
}

export const passwordPolicyApi: PasswordPolicyApi = {
    async validate(password) {
        const formData = new FormData();
        formData.append("password", password);
        await axios.post("/api/password-policy/_validate", formData);
    },

    async passwordPolicy() {
        return (await axios.get("/api/password-policy")).data;
    },

    async editPasswordPolicy(passwordPolicy) {
        await axios.put("/api/password-policy", passwordPolicy);
    },
};
