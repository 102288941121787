import { CaseSettings, caseSettingsApi } from "@/api/caseSettings";
import { isCaseSettingsChangedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { updateItem } from "@/util/storeUtils";

@reactive
class CaseSettingsStore {
    private caseSettings: CaseSettings[] = [];
    private refreshLimiter = new ActionLimiter(true);

    outcomeReasonMandatory(dealerId: string): boolean {
        return this.caseSettings.find((s) => s.dealerId === dealerId)?.outcomeReasonMandatory || false;
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.caseSettings = await caseSettingsApi.getAllCaseSettings();
        });
    }

    handleNotification(n: Notification) {
        if (isCaseSettingsChangedNotification(n)) {
            updateItem(this.caseSettings, n.caseSettings, (s) => s.dealerId === n.caseSettings.dealerId);
        }
    }
}

export const caseSettingsStore = new CaseSettingsStore();

notificationEventSource.addDataHandler((n) => caseSettingsStore.handleNotification(n));
