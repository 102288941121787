
import DtmfMenuOptions from "./DtmfMenuOptions.vue";
import InternalIncomingPhoneNumberConfigurationDialogAnnouncement, {
    Announcement,
} from "./InternalIncomingPhoneNumberConfigurationDialogAnnouncement.vue";
import { CallTrackingGroup, IncomingCallDistributionType } from "@/api/callTrackingGroups";
import {
    AutoAnswerType,
    incomingPhoneNumbersApi,
    InternalIncomingPhoneNumber,
    InternalIncomingPhoneNumberConfigurationForm,
    IssueType,
} from "@/api/incomingPhoneNumbers";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { dealerOptions, getSortedByDealer } from "@/app/dealerUtils";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import { getFullName } from "@/app/userUtils";
import { integer, maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { officeHoursStore } from "@/store/officeHours";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { Mutable, SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

enum CreateIssueOnConditionType {
    CALL_WAS_ANSWERED,
    CALL_WAS_NOT_ANSWERED,
}

interface ExtendedInternalIncomingPhoneNumberConfigurationForm extends InternalIncomingPhoneNumberConfigurationForm {
    id: string | null;
    isAutoAnswerTypeTransferToIncomingPhoneNumber: boolean;
    autoAnswerAnnouncementFile: File | null;
}

const AUTO_ANSWER_TYPE_TRANSFER_TO_INCOMING_PHONE_NUMBER = "TRANSFER_TO_INCOMING_PHONE_NUMBER";

export default Vue.extend({
    props: {
        callTrackingGroups: {
            type: Array as () => CallTrackingGroup[],
            required: true,
        },
        internalIncomingPhoneNumber: {
            type: Object as () => InternalIncomingPhoneNumber | null,
            default: null,
        },
        internalIncomingPhoneNumbers: {
            type: Array as () => InternalIncomingPhoneNumber[],
            required: true,
        },
    },

    data() {
        const editMode = !!this.internalIncomingPhoneNumber?.name;

        const isCallTrackingNumber = this.internalIncomingPhoneNumber
            ? this.internalIncomingPhoneNumber.autoAnswerType === AutoAnswerType.TRANSFER_TO_CUSTOM_NUMBER &&
              !!this.internalIncomingPhoneNumber!.autoAnswerCustomNumber &&
              this.internalIncomingPhoneNumbers
                  .map((n) => n.number)
                  .includes(this.internalIncomingPhoneNumber!.autoAnswerCustomNumber)
            : false;

        return {
            notEmpty,
            maxLength,
            integer,

            AutoAnswerType,
            CreateIssueOnConditionType,
            IncomingCallDistributionType,
            IssueType,

            editMode,
            form: (editMode
                ? {
                      // custom fields
                      autoAnswerAnnouncementFile: null,
                      isAutoAnswerTypeTransferToIncomingPhoneNumber: isCallTrackingNumber,

                      // form fields (including id)
                      ...cloneObject(this.internalIncomingPhoneNumber),
                  }
                : {
                      // custom fields
                      id: null,
                      autoAnswerAnnouncementFile: null,
                      isAutoAnswerTypeTransferToIncomingPhoneNumber: isCallTrackingNumber,

                      // form fields
                      name: null,
                      dealerId: userSession.dealerId,
                      callTrackingGroupId: null,
                      distributeToAssignees: true,
                      targetUserIds: [],
                      distributionOfficeHoursId: null,
                      distributeOnlyIfAvailable: true,
                      distributeOnlyIfInWorkingHours: false,
                      displayCallersId: false,
                      callAttemptTimeoutSeconds: null,
                      distributionType: IncomingCallDistributionType.PARALLEL,
                      distributionTimeoutSeconds: null,
                      autoAnswerType: AutoAnswerType.TRANSFER_TO_EXTERNAL_BDC,
                      autoAnswerAnnouncementHash: null,
                      autoAnswerAnnouncementContentType: null,
                      autoAnswerAnnouncementText: null,
                      autoAnswerCustomNumber: null,
                      autoAnswerDtmfMenuOptions: [],
                      autoAnswerInParallelDuringDistribution: false,
                      assignIssueToAcceptor: true,
                      createIssueIfAnswered: true,
                      createIssueIfUnanswered: true,
                      issueType: null,
                      individualReceivers: [],
                      caseType: null,
                      escalationGroupReceivers: [],
                      opportunitySourceId: null,
                      opportunityTeamReceivers: [],
                  }) as Mutable<ExtendedInternalIncomingPhoneNumberConfigurationForm>,
            saving: false,
        };
    },

    computed: {
        autoAnswerCustomNumberRules(): Function[] {
            return [
                ...((notEmpty().e164() as unknown) as Function[]),
                (phoneNumber: string | null | undefined) =>
                    !phoneNumber ||
                    phoneNumber !== this.selectedInternalIncomingPhoneNumber?.number ||
                    this.$t("Es kann nicht an die eingehende Rufnummer weitergeleitet werden."),
            ];
        },

        callTrackingGroupOptions(): SelectOption[] {
            return this.callTrackingGroups
                .map((g) => ({ value: g.id, text: g.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        callTrackingNumberOptions(): SelectOption[] {
            return this.internalIncomingPhoneNumbers
                .filter((n) => (!!n.name && this.form.id !== n.id) || this.form.autoAnswerCustomNumber === n.number)
                .map((n) => ({
                    text: n.name ? `${n.number} (${n.name})` : n.number,
                    value: n.number,
                }));
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        defaultCountry() {
            return configStore.configuration.defaultCountry;
        },

        displayCallersIdOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Rufnummer des Anrufers") },
                { value: false, text: this.$t("Rufnummer dieser Gruppe") },
            ];
        },

        dtmfMenuPhoneNumbers(): string[] {
            return this.internalIncomingPhoneNumbers.filter((n) => n.id !== this.form.id).map((n) => n.number);
        },

        escalationGroupReceiversOptions(): SelectOption[] {
            return getSortedByDealer(escalationGroupsStore.escalationGroups, (e) => e.dealerId).map((e) => ({
                text: this.appendDealerNameToOptionText(e.name, e.dealerId),
                value: e.id,
            }));
        },

        extendedAutoAnswerType(): AutoAnswerType | string {
            if (this.form.isAutoAnswerTypeTransferToIncomingPhoneNumber) {
                return AUTO_ANSWER_TYPE_TRANSFER_TO_INCOMING_PHONE_NUMBER;
            } else {
                return this.form.autoAnswerType;
            }
        },

        extendedAutoAnswerTypeOptions(): SelectOption[] {
            return [
                {
                    text: this.$t(`enum.AutoAnswerType.${AutoAnswerType.NONE}`),
                    value: AutoAnswerType.NONE,
                },
                {
                    text: this.$t(`enum.AutoAnswerType.${AutoAnswerType.PLAY_ANNOUNCEMENT}`),
                    value: AutoAnswerType.PLAY_ANNOUNCEMENT,
                },
                {
                    text: this.$t(`enum.AutoAnswerType.${AutoAnswerType.TRANSFER_TO_CUSTOM_NUMBER}`),
                    value: AutoAnswerType.TRANSFER_TO_CUSTOM_NUMBER,
                },
                {
                    text: this.$t(`enum.AutoAnswerType.${AutoAnswerType.TRANSFER_TO_EXTERNAL_BDC}`),
                    value: AutoAnswerType.TRANSFER_TO_EXTERNAL_BDC,
                },
                {
                    text: this.$t(`enum.AutoAnswerType.${AUTO_ANSWER_TYPE_TRANSFER_TO_INCOMING_PHONE_NUMBER}`),
                    value: AUTO_ANSWER_TYPE_TRANSFER_TO_INCOMING_PHONE_NUMBER,
                },
                {
                    text: this.$t(`enum.AutoAnswerType.${AutoAnswerType.TRANSFER_VIA_DTMF_MENU}`),
                    value: AutoAnswerType.TRANSFER_VIA_DTMF_MENU,
                },
            ];
        },

        formCreateIssueOnConditionTypeValue: {
            get(): CreateIssueOnConditionType[] {
                const form = this.form as ExtendedInternalIncomingPhoneNumberConfigurationForm;
                const value: CreateIssueOnConditionType[] = [];

                if (form.createIssueIfAnswered) {
                    value.push(CreateIssueOnConditionType.CALL_WAS_ANSWERED);
                }

                if (form.createIssueIfUnanswered) {
                    value.push(CreateIssueOnConditionType.CALL_WAS_NOT_ANSWERED);
                }

                return value;
            },
            set(value: CreateIssueOnConditionType[]) {
                const form = this.form as Mutable<ExtendedInternalIncomingPhoneNumberConfigurationForm>;

                const createIssueIfAnswered = value.includes(CreateIssueOnConditionType.CALL_WAS_ANSWERED);
                if (form.createIssueIfAnswered !== createIssueIfAnswered) {
                    form.createIssueIfAnswered = createIssueIfAnswered;
                }

                const createIssueIfUnanswered = value.includes(CreateIssueOnConditionType.CALL_WAS_NOT_ANSWERED);
                if (form.createIssueIfUnanswered !== createIssueIfUnanswered) {
                    form.createIssueIfUnanswered = createIssueIfUnanswered;
                }
            },
        },

        individualReceiversOptions(): SelectOption[] {
            return [...this.userOptions];
        },

        internalIncomingPhoneNumberOptions(): SelectOption[] {
            return this.internalIncomingPhoneNumbers
                .filter(
                    (i) =>
                        !i.name || (!!this.internalIncomingPhoneNumber && i.id === this.internalIncomingPhoneNumber.id)
                )
                .map((i) => ({
                    text: i.number,
                    value: i.id,
                }));
        },

        officeHoursOptions(): SelectOption[] {
            return getSortedByDealer(officeHoursStore.officeHours, (officeHours) => officeHours.dealerId).map(
                (officeHours) => ({
                    text: this.appendDealerNameToOptionText(officeHours.name, officeHours.dealerId),
                    value: officeHours.id,
                })
            );
        },

        opportunitySourceOptions(): SelectOption[] {
            return opportunitySourcesStore.opportunitySources.map((s) => ({
                text: s.name,
                value: s.id,
            }));
        },

        opportunityTeamReceiversOptions(): SelectOption[] {
            return getSortedByDealer(opportunityTeamsStore.opportunityTeams, (t) => t.dealerId).map((t) => ({
                text: this.appendDealerNameToOptionText(t.name, t.dealerId),
                value: t.id,
            }));
        },

        selectedInternalIncomingPhoneNumber(): InternalIncomingPhoneNumber | null {
            if (!this.form.id) {
                return null;
            }

            return this.internalIncomingPhoneNumbers.find((n) => n.id === this.form.id) ?? null;
        },

        selectedCallTrackingGroup(): CallTrackingGroup | null {
            if (!this.form.callTrackingGroupId) {
                return null;
            }

            return this.callTrackingGroups.find((g) => g.id === this.form.callTrackingGroupId) ?? null;
        },

        targetUserOptions(): SelectOption[] {
            return this.userOptions;
        },

        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },

        userOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        appendDealerNameToOptionText(text: string, dealerId: string): string {
            return `${text} (${this.getDealerNameById(dealerId) || this.$t("Unbekannt")})`;
        },

        generateAutoAnswerAnnouncementLink(announcementHash: string): string | null {
            if (!this.form.id) {
                return null;
            }

            return incomingPhoneNumbersApi.generateAutoAnswerAnnouncementLink(this.form.id, announcementHash);
        },

        handleAutoAnswerAnnouncementInput(announcement: Announcement) {
            this.form = {
                ...this.form,
                autoAnswerAnnouncementHash: announcement.audioHash,
                autoAnswerAnnouncementFile: announcement.audioFile,
                autoAnswerAnnouncementText: announcement.text,
            };
        },

        handleExtendedAutoAnswerTypeInput(autoAnswerType: string) {
            if (autoAnswerType === AUTO_ANSWER_TYPE_TRANSFER_TO_INCOMING_PHONE_NUMBER) {
                this.form.autoAnswerType = AutoAnswerType.TRANSFER_TO_CUSTOM_NUMBER;
                this.form.isAutoAnswerTypeTransferToIncomingPhoneNumber = true;
            } else {
                this.form.autoAnswerType = autoAnswerType as AutoAnswerType;
                this.form.isAutoAnswerTypeTransferToIncomingPhoneNumber = false;
            }
        },

        getDealerNameById(dealerId: string): string | null {
            return dealersStore.dealerById(dealerId)?.name ?? null;
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await incomingPhoneNumbersApi.editInternalIncomingPhoneNumberConfiguration(
                    this.form.id!,
                    {
                        ...this.form,
                        autoAnswerInParallelDuringDistribution:
                            this.form.autoAnswerType === AutoAnswerType.TRANSFER_TO_CUSTOM_NUMBER &&
                            !!this.callTrackingNumberOptions.find((o) => o.value === this.form.autoAnswerCustomNumber)
                                ? false
                                : this.form.autoAnswerInParallelDuringDistribution,
                    },
                    this.form.autoAnswerType === AutoAnswerType.PLAY_ANNOUNCEMENT ||
                        this.form.autoAnswerType === AutoAnswerType.TRANSFER_VIA_DTMF_MENU
                        ? (this.form.autoAnswerAnnouncementFile as File | null)
                        : null
                );
                this.$emit("success");
            } finally {
                this.saving = false;
            }
        },
    },

    components: {
        CaseTypePicker,
        DAutocomplete,
        DtmfMenuOptions,
        EnumField,
        InternalIncomingPhoneNumberConfigurationDialogAnnouncement,
        NumberField,
        PhoneNumberField,
    },
});
