
import CallerIdDialog from "./CallerIdDialog.vue";
import VolumeSettings from "./VolumeSettings.vue";
import { callerIdsApi } from "@/api/callerIds";
import { EmailSignatureTemplate, emailSignatureTemplateApi } from "@/api/emailSignatureTemplate";
import { BadRequest } from "@/api/errors";
import { SmsSignatureTemplate, smsSignatureTemplateApi } from "@/api/smsSignatureTemplate";
import { UserProfileForm, userSessionApi } from "@/api/userSession";
import { Gender, PhoneNumberType, usersApi } from "@/api/users";
import BirthdayPicker from "@/app/components/BirthdayPicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import { readTextAsFile } from "@/app/fileUtils";
import { showInfo } from "@/app/messageUtil";
import { renderEmailSignatureTemplatePlaceholders } from "@/app/pages/emailsignaturetemplate/emailSignatureTemplatePlaceholders";
import { renderSmsSignatureTemplatePlaceholders } from "@/app/pages/smssignaturetemplate/smsSignatureTemplatePlaceholders";
import { getFullName } from "@/app/userUtils";
import { e164, maxLength, notEmpty, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";
import VImageInput from "vuetify-image-input";

const JPEG_DATA_URI_PREFIX = "data:image/jpeg;base64,";

export default Vue.extend({
    data() {
        return {
            validationHelper: new ValidationHelper(),
            sendingTestEmail: false,
            isWorking: true,
            emailSignatureTemplate: null as EmailSignatureTemplate | null,
            smsSignatureTemplate: null as SmsSignatureTemplate | null,
            renderingEmailSignatureTemplate: false,
            showCallerIdDialog: false,

            // FORM FIELDS
            userProfileForm: {
                timeZone: userSession.profile?.timeZone,
                locale: userSession.profile?.locale,
                darkmode: userSession.profile?.darkmode,
                compactMode: userSession.profile?.compactMode,
                broadcastEmailsEnabled: userSession.profile?.broadcastEmailsEnabled,
                volume: userSession.profile?.volume,
                dateOfBirth: userSession.profile?.dateOfBirth,
                gender: userSession?.profile?.gender || null,
                namePrefix: userSession?.profile?.namePrefix || null,
                givenName: userSession?.profile?.givenName || "",
                familyName: userSession?.profile?.familyName || "",
                emailSignature: "",
                smsSignature: userSession.profile?.smsSignature,
                languages: cloneObject(userSession.profile?.languages),
                phoneNumbers: cloneObject(userSession.profile?.phoneNumbers || []),
                defaultCallerId: userSession.profile?.defaultCallerId || null,
                terminalPhoneNumber: userSession.profile?.terminalPhoneNumber || null,
                defaultSubstituteUserId: userSession.profile?.defaultSubstituteUserId || "",
            } as UserProfileForm,
            profileImageFileFormat: "jpeg",
            profileImageUri: null as string | null,

            // OPTIONS
            callerIds: [] as SelectOptions,

            // RULES
            numberRules: notEmpty().e164(),
            labelRules: maxLength(300),
            textRules: maxLength(16383),
            terminalNumberRules: e164(),
            Gender,
            namePrefixRules: maxLength(300),
            namePartRules: notEmpty().maxLength(300),
        };
    },

    computed: {
        defaultCountry(): string {
            return configStore.configuration.defaultCountry;
        },

        languageOptions(): SelectOption[] {
            return configStore.configuration.languages
                .map((k) => ({
                    value: k.code,
                    text: this.$t(`language.${k.code}`),
                }))
                .sort((a, b) => (a.text as string).localeCompare(b.text as string, userSession.locale));
        },

        localeOptions(): SelectOption[] {
            return this.$i18n.availableLocales.map((k) => ({
                value: k,
                text: this.$t(`locale.${k}`),
            }));
        },

        phoneNumbers(): string[] {
            const user = userSession.userId ? usersStore.getUserById(userSession.userId) : null;

            return [
                ...this.userProfileForm.phoneNumbers.map((n) => n.number),
                ...(user?.dealerAssignments ?? []).reduce(
                    (prev, cur) => [...prev, ...cur.phoneNumbers.map((n) => n.number)],
                    [] as string[]
                ),
            ]
                .sort()
                .filter((n, index, array) => !!n && (!index || array[index - 1] !== n));
        },

        phoneNumberTypeOptions(): SelectOption[] {
            return Object.keys(PhoneNumberType).map((k) => ({
                value: k,
                text: this.$t(`enum.NumberType.${k}`),
            }));
        },

        substituteUsersOptions(): SelectOption[] {
            return [
                { value: "", text: this.$t("keine Vertretung") },
                ...usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) })),
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        userSession() {
            return userSession;
        },
    },

    methods: {
        addPhoneNumber() {
            this.userProfileForm.phoneNumbers.push({
                type: PhoneNumberType.LANDLINE,
                publishable: true,
                number: "",
                label: "",
            });
        },

        async callerIdAdded(callerId: string) {
            this.showCallerIdDialog = false;
            this.isWorking = true;
            try {
                await this.loadCallerIds();
            } finally {
                this.isWorking = false;
            }
            this.userProfileForm.defaultCallerId = callerId;
        },

        getPhoneNumberTypeIcon(phoneNumberType: PhoneNumberType) {
            if (phoneNumberType === PhoneNumberType.MOBILE) {
                return "mdi-cellphone";
            } else if (phoneNumberType === PhoneNumberType.LANDLINE) {
                return "mdi-deskphone";
            } else if (phoneNumberType === PhoneNumberType.FAX) {
                return "mdi-fax";
            }

            return "mdi-phone-classic";
        },

        goToFirstError() {
            this.$nextTick(() => {
                try {
                    this.$vuetify.goTo(".error--text");
                } catch (e) {
                    // ignore
                }
            });
        },

        async loadCallerIds() {
            this.callerIds = (await callerIdsApi.getCallerIds())
                .filter((cid) => !cid.internal)
                .map((cid) => ({
                    value: cid.phoneNumber,
                    text: parseAndFormatNumber(cid.phoneNumber, "INTERNATIONAL")!,
                }));
        },

        removePhoneNumber(index: number) {
            this.userProfileForm.phoneNumbers.splice(index, 1);
        },

        async sendTestMail() {
            this.sendingTestEmail = true;
            try {
                await userSessionApi.sendTestMail({
                    subject: this.$t("Signatur Vorschau") as string,
                    signature: this.userProfileForm.emailSignature || "",
                });
                showInfo(this.$t("Signatur-Vorschau wurde erfolgreich versandt") as string);
            } finally {
                this.sendingTestEmail = false;
            }
        },

        async setEmailSignatureToEmailSignatureTemplate() {
            if (!this.emailSignatureTemplate || !this.emailSignatureTemplate.content) {
                return;
            }

            const user = userSession.userId ? usersStore.getUserById(userSession.userId) : null;

            try {
                this.renderingEmailSignatureTemplate = true;

                this.userProfileForm.emailSignature = await renderEmailSignatureTemplatePlaceholders(
                    this.emailSignatureTemplate.content,
                    {
                        id: userSession.userId || null,
                        username: user?.username || null,
                        profileImageHash: userSession.profile?.profileImageHash || null,
                        mainDealerId: user?.mainDealerId || null,
                        dealerAssignments: user?.dealerAssignments || [],
                        emailAliases: userSession.profile?.emailAliases || [],
                        ...this.userProfileForm,
                    }
                );
            } finally {
                this.renderingEmailSignatureTemplate = false;
            }
        },

        setSmsSignatureToSmsSignatureTemplate() {
            if (!this.smsSignatureTemplate || !this.smsSignatureTemplate.content) {
                return;
            }

            const user = userSession.userId ? usersStore.getUserById(userSession.userId) : null;

            this.userProfileForm.smsSignature = renderSmsSignatureTemplatePlaceholders(
                this.smsSignatureTemplate.content,
                {
                    username: user?.username || null,
                    mainDealerId: user?.mainDealerId || null,
                    dealerAssignments: user?.dealerAssignments || [],
                    emailAliases: userSession.profile?.emailAliases || [],
                    ...this.userProfileForm,
                }
            );
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                this.goToFirstError();
                return;
            }

            this.isWorking = true;

            try {
                await userSessionApi.edit(
                    this.userProfileForm,
                    this.profileImageUri
                        ? await readTextAsFile(this.profileImageUri, "profile-image." + this.profileImageFileFormat)
                        : null,
                    () => null
                );
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, this.$refs.form);
                this.goToFirstError();
                return;
            } finally {
                this.isWorking = false;
            }

            showInfo(this.$t("Das Benutzerprofil wurde gespeichert.") as string);
        },
    },

    async mounted() {
        await this.loadCallerIds();

        if (userSession.profile) {
            this.userProfileForm.emailSignature = userSession.profile.emailSignatureHash
                ? await usersApi.getEmailSignatureByHash(
                      userSession.profile.id!,
                      userSession.profile.emailSignatureHash
                  )
                : "";

            if (userSession.profile?.profileImageHash) {
                this.profileImageUri =
                    JPEG_DATA_URI_PREFIX +
                    (await usersApi.getProfileImageByHash(
                        userSession.profile.id!,
                        userSession.profile.profileImageHash
                    ));
            }
        }

        this.emailSignatureTemplate = await emailSignatureTemplateApi.getByDealer(userSession.mainDealerId!);
        this.smsSignatureTemplate = await smsSignatureTemplateApi.getByDealer(userSession.mainDealerId!);

        this.isWorking = false;
    },

    components: {
        BirthdayPicker,
        CallerIdDialog,
        EnumField,
        PhoneNumberField,
        TimeZonePicker,
        VImageInput,
        VolumeSettings,
        WysiwygEditor: () => import("@/app/components/WysiwygEditor.vue"),
    },
});
