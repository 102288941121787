
import { Opportunity, OpportunityConversionForm } from "@/api/opportunities";
import { showError } from "@/app/messageUtil";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import EscalationGroupPickerCard from "@/app/pages/cases/EscalationGroupPickerCard.vue";
import UserPickerCard from "@/app/pages/users/UserPickerCard.vue";
import { UserPickerData } from "@/app/pages/users/userPickerCardTypes";
import Vue from "vue";

const DEFAULT_CASE_TYPE = "OTHER_REQUEST";

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
    },

    data() {
        return {
            caseType: DEFAULT_CASE_TYPE as string,
            converting: false,
            escalationGroupReceivers: [] as string[],
            individualReceivers: new UserPickerData(
                this.opportunityProp.opportunityIndividualReceivers.map((i) => i.userId)
            ),
        };
    },

    methods: {
        submit() {
            if (!(this.$refs.convertForm as any).validate()) {
                return;
            }

            if (!this.escalationGroupReceivers.length && !this.individualReceivers.userIds.length) {
                showError(this.$t("Es ist kein Empfänger ausgewählt!") as string);
                return;
            }

            this.converting = true;
            this.$emit("submit", {
                caseType: this.caseType,
                individualReceivers: this.individualReceivers.userIds,
                escalationGroupReceivers: this.escalationGroupReceivers,
            } as OpportunityConversionForm);
        },
    },

    components: {
        CaseTypePicker,
        EscalationGroupPickerCard,
        UserPickerCard,
    },
});
