
import { CaseOutcomeForm } from "@/api/cases";
import { Contact } from "@/api/contacts";
import { NoteTemplate, noteTemplatesApi } from "@/api/noteTemplates";
import { ContactNameData, EMPTY_CONTACT_NAME_DATA } from "@/app/contactUtils";
import { renderNotePlaceholders } from "@/app/pages/notetemplates/notePlaceholders";
import { maxLength, notEmpty } from "@/app/validation";
import { caseOutcomeReasonsStore } from "@/store/caseOutcomeReasons";
import { caseSettingsStore } from "@/store/caseSettings";
import { userSession } from "@/store/userSession";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        dealerId: {
            type: String,
            required: false,
        },
        outcomeReasonIdProp: {
            type: String,
            required: false,
        },
        outcomeDetailsProp: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            loadingTemplates: true,
            noteTemplates: [] as NoteTemplate[],
            outcomeReasonId: this.outcomeReasonIdProp as string | null,
            outcomeDetails: this.outcomeDetailsProp as string | null,
            maxLength,
        };
    },

    computed: {
        isOutcomeReasonMandatory(): boolean {
            return this.dealerId
                ? caseSettingsStore.outcomeReasonMandatory(this.dealerId)
                : !!this.outcomeReasonOptions.length;
        },

        outcomeReasonOptions(): SelectOptions {
            return caseOutcomeReasonsStore.caseOutcomeReasons.map((r) => ({
                value: r.id,
                text: r.name,
            }));
        },

        outcomeReasonRules(): any {
            return this.isOutcomeReasonMandatory ? notEmpty() : [];
        },
    },

    methods: {
        applyNoteTemplate(template: NoteTemplate) {
            this.outcomeDetails = this.renderNoteTemplate(template);
        },

        applyNoteTemplateAndClose(template: NoteTemplate) {
            this.applyNoteTemplate(template);
            this.submitForm();
        },

        close() {
            this.$emit("close");
        },

        renderNoteTemplate(template: NoteTemplate): string {
            const contactNameData: ContactNameData = this.contact?.deleted
                ? EMPTY_CONTACT_NAME_DATA
                : {
                      salutation: this.contact?.contactData.salutation || null,
                      companyName: this.contact?.contactData.companyName || null,
                      namePrefix: this.contact?.contactData.namePrefix || null,
                      givenName: this.contact?.contactData.givenName || null,
                      middleName: this.contact?.contactData.middleName || null,
                      familyName: this.contact?.contactData.familyName || null,
                      nameSuffix: this.contact?.contactData.nameSuffix || null,
                  };

            return renderNotePlaceholders(template.content, contactNameData, userSession.profile!);
        },

        submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            const caseOutcomeForm: CaseOutcomeForm = {
                outcomeReasonId: this.outcomeReasonId,
                outcomeDetails: this.outcomeDetails,
            };

            this.$emit("close-case", caseOutcomeForm);
        },
    },

    async mounted() {
        this.loadingTemplates = true;

        try {
            this.noteTemplates = await noteTemplatesApi.list();
        } finally {
            this.loadingTemplates = false;
        }
    },
});
