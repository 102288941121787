
import PhoneticSpellingTooltip from "./PhoneticSpellingTooltip.vue";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
    props: {
        header: {
            type: String as () => TranslateResult | string | null,
            default: null,
        },
        headerColor: {
            type: String as () => string | null,
            default: null,
        },
        phoneticSpelling: {
            type: Boolean,
            default: false,
        },
        phoneticSpellingLocale: {
            type: String as () => string | null,
            default: null,
        },
        text: {
            type: String as () => TranslateResult | string | null,
            default: null,
        },
        textClass: {
            type: String as () => string | null,
            default: null,
        },
        valid: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        PhoneticSpellingTooltip,
    },
});
