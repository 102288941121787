import axios from "axios";

export enum PaymentMethod {
    BANK_TRANSFER = "BANK_TRANSFER",
    SEPA_B2B_DIRECT_DEBIT = "SEPA_B2B_DIRECT_DEBIT",
}

export interface SepaB2bDirectDebitPaymentInformation {
    readonly iban: string;
    readonly bic: string;
    readonly mandateReference: string;
    readonly signatureDate: string;
}

export interface BillingAccount {
    readonly id: number;
    readonly created: Date;
    readonly updated: Date;
    readonly companyName: string;
    readonly address1: string | null;
    readonly address2: string;
    readonly zip: string;
    readonly city: string;
    readonly state: string | null;
    readonly country: string;
    readonly vatNumber: string | null;
    readonly companyRegistrationNumber: string | null;
    readonly receivers: string[];
    readonly billedMonths: string[];
    readonly paymentMethod: PaymentMethod;
    readonly sepaB2bDirectDebitPaymentInformation: SepaB2bDirectDebitPaymentInformation | null;
}

interface BillingAccountsApi {
    list(): Promise<BillingAccount[]>;
}

export const billingAccountsApi: BillingAccountsApi = {
    async list() {
        const result: BillingAccount[] = (await axios.get("/api/billing-accounts")).data;

        return result.map((v) => ({
            ...v,
            created: new Date(v.created),
            updated: new Date(v.updated),
        }));
    },
};
