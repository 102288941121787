import i18n from "./i18n";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { DateTimeOutputFormat, formatInstant, formatLocalDate, formatLocalTime } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export function renderUnit(n: number, unit: string, locale?: string) {
    try {
        // cast to any needed because of experimental API
        return new Intl.NumberFormat(locale || i18n.locale, { style: "unit", unit } as any).format(n);
    } catch (e) {
        // fallback for browsers not supporting units
        return `${i18n.n(n, undefined, locale || i18n.locale)} ${i18n.t(unit, locale || i18n.locale)}`;
    }
}

export function renderCurrency(n: number, currency: string) {
    return new Intl.NumberFormat(i18n.locale, { style: "currency", currency }).format(n);
}

export function renderDefaultCurrency(n: number) {
    return renderCurrency(n, configStore.configuration.defaultCurrency);
}

export function renderFilesize(n: number, maxFractionDigits: number, locale?: string) {
    if (n < 1000) {
        return renderUnit(n, "byte", locale);
    }
    if (n < 1000000) {
        return renderUnit(Number.parseFloat((n / 1000).toFixed(maxFractionDigits)), "kilobyte", locale);
    }

    return renderUnit(Number.parseFloat((n / 1000000).toFixed(maxFractionDigits)), "megabyte", locale);
}

export function renderInternalLink(link: string) {
    const url = new URL(link, window.location.href);

    if (url.searchParams.has("dealerId")) {
        return link;
    }

    return `${link}${url.search ? "&" : "?"}dealerId=${userSession.dealerId || ""}`;
}

Vue.mixin({
    filters: {
        filesize(n: number, maxFractionDigits: number) {
            return renderFilesize(n, maxFractionDigits);
        },

        phoneNumber(v: string) {
            return v ? parseAndFormatNumber(v, "INTERNATIONAL") || v : "";
        },

        currency(n: number, currency: string) {
            return renderCurrency(n, currency);
        },

        defaultCurrency(n: number) {
            return renderDefaultCurrency(n);
        },

        unit(n: number, unit: string) {
            return renderUnit(n, unit);
        },

        localDate(localDate: string | null, format: DateTimeOutputFormat = "S") {
            return localDate ? formatLocalDate(localDate, userSession.locale, format) : "";
        },

        localTime(localTime: string | null, format: DateTimeOutputFormat = "S") {
            return localTime ? formatLocalTime(localTime, userSession.locale, format) : "";
        },

        instant(d: Date | string | null, format: DateTimeOutputFormat = "S", tz?: string) {
            return d ? formatInstant(d, tz || userSession.timeZone, userSession.locale, format) : "";
        },

        internalLink(link: string) {
            return renderInternalLink(link);
        },

        externalLink(link: string) {
            if (link.match(/^(https?|ftp):\/\//)) {
                return link;
            }

            return "http://" + link;
        },
    },
});
