import { userNotificationSettingsApi } from "./api/userNotificationSettings";
import { $t } from "./app/i18n";
import { showInfo } from "./app/messageUtil";
import { configStore } from "./store/config";
import { userSession } from "./store/userSession";
import { register, unregister } from "register-service-worker";

export interface ServiceWorker {
    reg?: ServiceWorkerRegistration;
    subscribe(): Promise<void>;
    unsubscribe(): Promise<void>;
}

const serviceWorker: ServiceWorker = {
    async subscribe() {
        if (!this.reg || !this.reg.pushManager || !window.Notification || !userSession.userId) {
            return;
        }

        if (Notification.permission === "default") {
            const result = await Notification.requestPermission();
            if (result !== "granted") {
                return;
            }
        }

        let subscription = await this.reg.pushManager.getSubscription();
        try {
            if (!subscription) {
                subscription = await this.reg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: configStore.configuration.notificationSubscriptionKey,
                });
            }
            const s = subscription.toJSON();
            await userNotificationSettingsApi.subscribeDesktopNotifications({
                endpoint: s.endpoint!,
                auth: s.keys!.auth,
                p256dh: s.keys!.p256dh,
            });
        } catch (e) {
            if (Notification.permission === "denied") {
                return;
            }
            // eslint-disable-next-line no-console
            console.warn(e);
            showInfo($t("Abonnieren von Desktop-Benachrichtigungen ist fehlgeschlagen") as string);
        }
    },

    async unsubscribe() {
        if (!this.reg || !this.reg.pushManager) {
            return;
        }

        const subscription = await this.reg.pushManager.getSubscription();
        if (!subscription) {
            return;
        }

        try {
            const success = await subscription.unsubscribe();
            if (success) {
                await userNotificationSettingsApi.unsubscribeDesktopNotifications(subscription.endpoint);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
            showInfo($t("Abbestellen von Desktop-Benachrichtigungen ist fehlgeschlagen") as string);
        }
    },
};

export default serviceWorker;

export function registerServiceWorker() {
    if (process.env.NODE_ENV !== "production") {
        return;
    }

    if (userSession.isCtUser()) {
        unregister();
        return;
    }

    register(`${process.env.BASE_URL}service-worker.js`, {
        async ready(reg) {
            serviceWorker.reg = reg;
            await serviceWorker.subscribe();
        },

        updated(reg) {
            if (reg.waiting) {
                reg.waiting.postMessage({ type: "SKIP_WAITING" });
                configStore.outdated = true;
            }
        },

        error(error) {
            // eslint-disable-next-line no-console
            console.warn(error);
            showInfo($t("Desktop-Benachrichtigungen werden nicht unterstützt") as string);
        },
    });
}
