
import DateTimePicker from "@/app/components/DateTimePicker.vue";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

enum TimeScale {
    MINUTE,
    HOUR,
    DAY,
    WEEK,
    MONTH,
}

interface TimeOffset {
    readonly offset: number;
    readonly scale: TimeScale;
}

export default Vue.extend({
    data() {
        return {
            individualPostponementDate: null as Date | null,
            postponeToMinDate: getDate(now(), userSession.timeZone),
            timeOffsets: [
                { scale: TimeScale.HOUR, offset: 1 },
                { scale: TimeScale.HOUR, offset: 2 },
                { scale: TimeScale.HOUR, offset: 3 },
                { scale: TimeScale.HOUR, offset: 4 },
                { scale: TimeScale.DAY, offset: 1 },
                { scale: TimeScale.DAY, offset: 2 },
                { scale: TimeScale.DAY, offset: 3 },
                { scale: TimeScale.DAY, offset: 4 },
                { scale: TimeScale.WEEK, offset: 1 },
                { scale: TimeScale.WEEK, offset: 2 },
                { scale: TimeScale.WEEK, offset: 3 },
                { scale: TimeScale.WEEK, offset: 4 },
                { scale: TimeScale.MONTH, offset: 2 },
                { scale: TimeScale.MONTH, offset: 3 },
                { scale: TimeScale.MONTH, offset: 6 },
                { scale: TimeScale.MONTH, offset: 12 },
            ],
        };
    },

    computed: {
        individualPostponementDateRules(): Function[] {
            return [
                (v: Date | null | undefined) => !!v || this.$t("Dieses Feld ist erforderlich."),
                (v: Date | null | undefined) =>
                    !v ||
                    now().getTime() < v.getTime() ||
                    this.$t("Der Zurückstellungszeitpunkt muss in der Zukunft liegen."),
            ];
        },

        timeOffsetOptions(): SelectOption[] {
            return this.timeOffsets.map((timeOffset: TimeOffset) => ({
                text:
                    timeOffset.scale === TimeScale.MINUTE
                        ? this.$tc("0 Minuten | 1 Minute | {count} Minuten", timeOffset.offset)
                        : timeOffset.scale === TimeScale.HOUR
                        ? this.$tc("0 Stunde | 1 Stunde | {count} Stunden", timeOffset.offset)
                        : timeOffset.scale === TimeScale.DAY
                        ? this.$tc("0 Tage | 1 Tag | {count} Tage", timeOffset.offset)
                        : timeOffset.scale === TimeScale.WEEK
                        ? this.$tc("Keine | 1 Woche | {count} Wochen", timeOffset.offset)
                        : timeOffset.scale === TimeScale.MONTH
                        ? this.$tc("Keine | 1 Monat | {count} Monate", timeOffset.offset)
                        : this.$t("Unbekannt"),
                value: timeOffset,
            }));
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        getPostponedDate(timeOffset: TimeOffset): Date | null {
            let timeOffsetInMillis = timeOffset.offset * 1000;

            // noinspection FallThroughInSwitchStatementJS
            switch (timeOffset.scale) {
                case TimeScale.MONTH:
                    const ts = now();
                    const lastDayMonth = new Date(
                        ts.getFullYear(),
                        ts.getMonth() + timeOffset.offset + 1, // + 1 for month after target month
                        0, // 0 for last day of target month
                        ts.getHours(),
                        ts.getMinutes(),
                        ts.getSeconds()
                    );
                    return new Date(new Date(lastDayMonth).setDate(Math.min(ts.getDate(), lastDayMonth.getDate())));

                case TimeScale.WEEK:
                    timeOffsetInMillis *= 7;
                case TimeScale.DAY:
                    timeOffsetInMillis *= 24;
                case TimeScale.HOUR:
                    timeOffsetInMillis *= 60;
                case TimeScale.MINUTE:
                    timeOffsetInMillis *= 60;
                    return new Date(now().getTime() + timeOffsetInMillis);

                default:
                    return null;
            }
        },

        postpone(timeOffset: TimeOffset) {
            this.$emit("postpone", this.getPostponedDate(timeOffset)!);
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.$emit("postpone", this.individualPostponementDate);
        },
    },

    components: {
        DateTimePicker,
    },
});
