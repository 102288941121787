
import { isOutgoingCitnowVideoUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { OutgoingCitnowVideo } from "@/api/outgoingCitnowVideos";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        outgoingCitnowVideo: {
            type: Object as () => OutgoingCitnowVideo,
            required: true,
        },
    },

    data() {
        return {
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingCitnowVideoObj: this.outgoingCitnowVideo,
        };
    },

    computed: {
        creationTimestamp(): string {
            return formatInstant(this.outgoingCitnowVideoObj.created, userSession.timeZone, userSession.locale, "S");
        },

        creator(): string {
            if (this.outgoingCitnowVideoObj.initiatorCtUserId) {
                return this.$t("Externer Agent") as string;
            }
            if (this.outgoingCitnowVideoObj.initiatorUserId) {
                return (getFullName(usersStore.getUserById(this.outgoingCitnowVideoObj.initiatorUserId)) ||
                    this.$t("Unbekannt")) as string;
            }
            return this.$t("System") as string;
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isOutgoingCitnowVideoUpdatedNotification(n)) {
                if (n.id === this.outgoingCitnowVideoObj.id) {
                    this.outgoingCitnowVideoObj = {
                        ...this.outgoingCitnowVideoObj,
                        updated: n.timestamp,
                        videoEmbed: n.videoEmbed,
                        videoPage: n.videoPage,
                        videoThumb: n.videoThumb,
                    };
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        CopyToClipboardIcon,
        TimeSpan,
        UserLink,
    },
});
