import {
    isDepartmentDeletedNotification,
    isUserChangedNotification,
    isUserDeletedNotification,
    isUsersStatusChangedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { Status, UserDirectoryEntry, usersApi } from "@/api/users";
import { reactive } from "@/util/reactive";
import { removeMatchingItems } from "@/util/storeUtils";

@reactive
class UsersStore {
    private users_ = [] as UserDirectoryEntry[];

    get users() {
        return this.users_;
    }

    getUsersByDealer(dealerId: string | null) {
        if (!dealerId) {
            return this.users_;
        }

        return this.users_.filter((user) => {
            for (const dealerAssignment of user.dealerAssignments) {
                if (dealerAssignment.dealerId === dealerId) {
                    return true;
                }
            }

            return false;
        });
    }

    getUserById(userId: string): UserDirectoryEntry | null {
        for (const user of this.users_) {
            if (user.id === userId) {
                return user;
            }
        }

        return null;
    }

    getAwayByUser(userId: string) {
        const user = this.getUserById(userId);
        if (user) {
            return user.away;
        }
        return null;
    }

    getAvailableForVideochatByUser(userId: string) {
        const user = this.getUserById(userId);
        if (user) {
            return user.availableForVideochat;
        }
        return null;
    }

    async refresh(currentUserId: string) {
        this.users_ = await usersApi.getAllUserDirectoryEntries();

        // current user is always online
        const currentUser = this.getUserById(currentUserId);
        if (currentUser) {
            currentUser.status = { ...currentUser.status, online: true };
        }
    }

    private refreshUser(userDirectoryEntry: UserDirectoryEntry) {
        for (const user of this.users_) {
            if (user.id === userDirectoryEntry.id) {
                // TODO REPLACE (TO NOT DEPEND ON THE INTERNAL STRUCTURE AND MAKE FIELDS READONLY)
                user.username = userDirectoryEntry.username;
                user.gender = userDirectoryEntry.gender;
                user.namePrefix = userDirectoryEntry.namePrefix;
                user.givenName = userDirectoryEntry.givenName;
                user.familyName = userDirectoryEntry.familyName;
                user.mainDealerId = userDirectoryEntry.mainDealerId;
                user.phoneNumbers = userDirectoryEntry.phoneNumbers;
                user.dealerAssignments = userDirectoryEntry.dealerAssignments;
                user.away = userDirectoryEntry.away;
                user.availableForVideochat = userDirectoryEntry.availableForVideochat;
                user.lastActive = userDirectoryEntry.lastActive;
                user.defaultSubstituteUserId = userDirectoryEntry.defaultSubstituteUserId;
                return;
            }
        }

        this.users_.push(userDirectoryEntry);
    }

    private updateStatus(userId: string, status: Status, lastActive: Date | null) {
        for (const user of this.users_) {
            if (user.id === userId) {
                user.status = status;
                user.lastActive = lastActive;
                return;
            }
        }
    }

    handleNotification(n: Notification) {
        if (isDepartmentDeletedNotification(n)) {
            for (const user of this.users_) {
                for (const dealerAssignment of user.dealerAssignments) {
                    removeMatchingItems(
                        dealerAssignment.departmentAssignments,
                        (a) => a.departmentId === n.departmentId
                    );
                }
            }
        } else if (isUsersStatusChangedNotification(n)) {
            for (const change of n.statuses) {
                this.updateStatus(change.userId, change.status, change.lastActive);
            }
        } else if (isUserChangedNotification(n)) {
            this.refreshUser(n.user);
        } else if (isUserDeletedNotification(n)) {
            removeMatchingItems(this.users_, (u) => u.id === n.userId);
        }
    }
}

export const usersStore = new UsersStore();

notificationEventSource.addDataHandler((n) => usersStore.handleNotification(n));
