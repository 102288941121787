import { reactive } from "@/util/reactive";

@reactive
class EditModeState {
    private cnt_ = 0;

    get dirty() {
        return this.cnt_ > 0;
    }

    enter() {
        this.cnt_ += 1;
    }

    leave() {
        if (this.cnt_ > 0) {
            this.cnt_ -= 1;
        }
    }

    reset() {
        this.cnt_ = 0;
    }
}

export const editModeState = new EditModeState();

export const EditModeMixin = {
    mounted() {
        editModeState.enter();
    },

    beforeDestroy() {
        editModeState.leave();
    },
};
