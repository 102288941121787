
import OpportunityTeamBottomSheet from "./OpportunityTeamBottomSheet.vue";
import { OpportunityTeam } from "@/api/opportunityTeams";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityTeamId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetVisible: false,
        };
    },

    computed: {
        opportunityTeam(): OpportunityTeam | null {
            return opportunityTeamsStore.getOpportunityTeamById(this.opportunityTeamId);
        },
    },

    methods: {
        showBottomSheet() {
            this.bottomSheetVisible = true;
        },
    },

    components: {
        OpportunityTeamBottomSheet,
    },
});
