import { ProcessingState } from "@/api/financeContracts";

export function getStatusColor(processingState: ProcessingState): string | null {
    if (processingState === ProcessingState.UNPROCESSED) {
        return null;
    } else if (processingState === ProcessingState.PROCESSED) {
        return "success";
    } else if (processingState === ProcessingState.PROCESS_MANUALLY) {
        return "warning";
    } else {
        return null;
    }
}
