
import { BadRequest } from "@/api/errors";
import { opportunitySettingsApi, OpportunitySettingsForm } from "@/api/opportunitySettings";
import { Permission } from "@/api/userSession";
import NumberField from "@/app/components/NumberField.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { integer, notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { officeHoursStore } from "@/store/officeHours";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            exceptionalUserPickerVisible: false,
            isWorking: true,
            lockTimeoutMinutesRules: integer(0),
            settingsForm: null as OpportunitySettingsForm | null,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        notEmpty() {
            return notEmpty;
        },

        hideOpportunityInfoOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ausblenden") },
                { value: false, text: this.$t("Nicht ausblenden") },
            ];
        },

        officeHoursOptions(): SelectOption[] {
            return officeHoursStore.officeHours.map((officeHours) => ({
                value: officeHours.id,
                text: `${officeHours.name} (${this.getDealerNameById(officeHours.dealerId)})`,
            }));
        },

        outcomeReasonMandatoryOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Verpflichtend") },
                { value: false, text: this.$t("Optional") },
            ];
        },
    },

    methods: {
        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },

        async refresh() {
            if (!this.dealerId) {
                return;
            }

            this.isWorking = true;

            this.settingsForm = null;

            this.settingsForm = await opportunitySettingsApi.getOpportunitySettings(this.dealerId);

            this.isWorking = false;
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate() || !this.settingsForm) {
                return;
            }

            this.isWorking = true;

            try {
                await opportunitySettingsApi.editOpportunitySettings(this.dealerId!, this.settingsForm);
            } catch (e) {
                this.isWorking = false;
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                return;
            }

            this.isWorking = false;
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.refresh();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.refresh();
    },

    components: {
        NumberField,
        DealerContextGuard,
    },
});
