
import { caseTypesStore } from "@/store/caseTypes";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: [String as () => string | null, Array as () => string[]],
        multiple: {
            type: Boolean,
            default: false,
        },
        optional: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            items: [] as SelectOptions,
        };
    },

    computed: {
        caseTypeRules() {
            return [
                (v: string) =>
                    (!v && this.optional) ||
                    this.items.some((t) => "value" in t && t.value === v) ||
                    this.$t("Gültiger Fall-Typ ist erforderlich"),
            ];
        },
    },

    created() {
        const caseTypeGroups = caseTypesStore.caseTypeGroups;
        for (const caseTypeGroup of caseTypeGroups) {
            this.items.push({ header: this.$t(`enum.CaseTypeGroup.${caseTypeGroup.name}`) });
            for (const caseType of caseTypeGroup.caseTypes) {
                this.items.push({ value: caseType, text: this.$t(`enum.CaseType.${caseType}`) });
            }
        }
    },
});
