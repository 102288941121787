
import { inventoryVehicleInquiryAssistantState } from "./inventoryVehicleInquiryAssistantState";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: Boolean,
    },

    data() {
        return {
            inventoryVehicleInquiryAssistantState,
        };
    },
});
