export function toQueryString(o: object) {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(JSON.parse(JSON.stringify(o)))) {
        if (Array.isArray(value)) {
            for (const v of value) {
                if (v !== null) {
                    params.append(key, v.toString());
                }
            }
        } else if (value !== null) {
            params.append(key, (value as any).toString());
        }
    }
    return params.toString();
}
