
import IncomingCallEvents from "./IncomingCallEvents.vue";
import { getStatusColor } from "./incomingCallUtils";
import { incomingCallsOverviewState } from "./incomingCallsOverview";
import { Contact } from "@/api/contacts";
import {
    IncomingCallResult,
    IncomingCallResultAndContact,
    incomingCallsApi,
    IncomingCallSearchOrder,
    IncomingCallStatus,
    IssueType,
} from "@/api/incomingCalls";
import { incomingPhoneNumbersApi, InternalIncomingPhoneNumber } from "@/api/incomingPhoneNumbers";
import { isIncomingCallUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { renderContactCaption } from "@/app/contactUtils";
import { dealerOptions } from "@/app/dealerUtils";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { PickMutable, SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const itemsPerPage = 100;

        return {
            IncomingCallSearchOrder,
            incomingCallsOverviewState,
            internalIncomingPhoneNumbers: [] as InternalIncomingPhoneNumber[],
            IssueType,
            items: [] as IncomingCallResultAndContact[],
            itemsPerPage,
            loading: true,
            notificationHandler: null as ((n: Notification) => void) | null,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            parseAndFormatNumber,
            searchCounter: 1,
        };
    },

    computed: {
        acceptorUserOptions(): SelectOption[] {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Anruf") as string,
                    width: "30%",
                },
                {
                    text: this.$t("Gesprächsnotiz") as string,
                    width: "35%",
                },
                {
                    text: this.$t("Kontakt und Vorgang") as string,
                    width: "35%",
                },
            ];
        },

        internalIncomingPhoneNumberOptions(): SelectOption[] {
            return this.internalIncomingPhoneNumbers
                .map((internalIncomingPhoneNumber) => ({
                    value: internalIncomingPhoneNumber.id,
                    text:
                        parseAndFormatNumber(internalIncomingPhoneNumber.number, "INTERNATIONAL") ||
                        internalIncomingPhoneNumber.number,
                }))
                .sort((a, b) => a.text?.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        getContactCaption(contact: Contact | null): string | null {
            if (!contact) {
                return null;
            }

            return renderContactCaption(contact.contactData);
        },

        getStatusClass(incomingCallStatus: IncomingCallStatus): string | null {
            const color = getStatusColor(incomingCallStatus);

            return color ? `${color}--text` : null;
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        getViaNumbers(call: IncomingCallResult): string[] {
            const ids = call.forwardedInternalIncomingPhoneNumberIds.length
                ? call.forwardedInternalIncomingPhoneNumberIds
                : call.events
                      .map((e) => e.internalIncomingPhoneNumberId!)
                      .filter((i) => !!i && i !== call.internalIncomingPhoneNumberId);

            return [...new Set(ids)]
                .map((i) => this.internalIncomingPhoneNumbers.find((n) => n.id === i)?.number as string)
                .filter((n) => !!n);
        },

        isContactDeleted(contact: Contact | null): boolean {
            return !!contact?.deleted;
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const incomingCallSearchResults = await incomingCallsApi.search(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    this.incomingCallsOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (incomingCallSearchResults.searchId === this.searchCounter) {
                    this.items = incomingCallSearchResults.results;
                    this.paging.totalSize = incomingCallSearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        refresh() {
            this.incomingCallsOverviewState.refresh();
        },
    },

    watch: {
        dealerId() {
            this.incomingCallsOverviewState.dealerIds = this.dealerId ? [this.dealerId] : [];
        },

        async "incomingCallsOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        if (!this.incomingCallsOverviewState.initialized) {
            this.incomingCallsOverviewState.setTodayQuickfilter();
        }

        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isIncomingCallUpdatedNotification(n)) {
                this.items = this.items.map((item) =>
                    item.incomingCallResult.id === n.incomingCall.id
                        ? {
                              ...item,
                              incomingCallResult: n.incomingCall,
                          }
                        : item
                );
            }
        });

        this.internalIncomingPhoneNumbers = await incomingPhoneNumbersApi.getAllInternal();

        await this.loadItems();
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        DAutocomplete,
        DataTable,
        DatePicker,
        EnumField,
        TimeSpan,
        UserLink,
        IncomingCallEvents,
    },
});
