
import EmergencyIncidentsDataTable from "./EmergencyIncidentsDataTable.vue";
import { emergencyIncidentsOverviewState } from "./emergencyIncidentsOverview";
import { ActorType, EmergencyIncidentSearchOrder } from "@/api/emergencyIncidentSearch";
import { EmergencyIncidentType } from "@/api/emergencyIncidents";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            ActorType,
            emergencyIncidentsOverviewState,
            EmergencyIncidentSearchOrder,
            EmergencyIncidentType,
        };
    },

    computed: {
        dealerId(): string | null {
            return userSession.dealerId;
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        userOptions(): SelectOptions {
            return usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) }));
        },
    },

    methods: {
        refresh() {
            this.emergencyIncidentsOverviewState.refresh();
        },
    },

    watch: {
        dealerId() {
            this.emergencyIncidentsOverviewState.dealerIds = this.dealerId ? [this.dealerId] : [];
        },
    },

    mounted() {
        if (!this.emergencyIncidentsOverviewState.initialized) {
            this.emergencyIncidentsOverviewState.setOpenQuickfilter();
        }
    },

    components: {
        DAutocomplete,
        DatePicker,
        EmergencyIncidentsDataTable,
        EnumField,
    },
});
