import {
    isVideochatRingingNotification,
    isVideochatStopRingingNotification,
    notificationEventSource,
    VideochatRingingNotification,
} from "@/api/notifications";
import { reactive } from "@/util/reactive";

@reactive
class VideochatsStore {
    private ringingVideochats_: VideochatRingingNotification[] = [];

    get ringingVideochats() {
        return this.ringingVideochats_;
    }

    addRingingVideochat(newItem: VideochatRingingNotification) {
        if (this.ringingVideochats_.find((item) => item.id === newItem.id)) {
            return;
        }
        this.ringingVideochats_.push(newItem);
    }

    removeRingingVideochat(id: string) {
        this.ringingVideochats_ = this.ringingVideochats_.filter((item) => item.id !== id);
    }
}

export const videochatsStore = new VideochatsStore();

notificationEventSource.addDataHandler((n) => {
    if (isVideochatRingingNotification(n)) {
        videochatsStore.addRingingVideochat(n);
    }

    if (isVideochatStopRingingNotification(n)) {
        videochatsStore.removeRingingVideochat(n.id);
    }
});
