
import {
    contactsApi,
    ContactVehicle,
    ContactVehicleForm,
    ContactVehicleRecord,
    ContactVehicleRecordType,
    MileageUnit,
} from "@/api/contacts";
import { VehicleClass } from "@/api/makeModels";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import ModelPicker from "@/app/pages/ModelPicker.vue";
import { integer, maxLength } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getCurrentUtcYear, getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

const MIN_MODEL_YEAR = 1900;

const emptyContactVehicleForm: ContactVehicleForm = {
    vin: "",
    licensePlate: "",
    vehicleClass: null,
    make: null,
    model: null,
    modelDescription: "",
    modelYear: null,
    firstRegistration: null,
    nextGeneralInspection: null,
    nextInspection: null,
    nextInspectionMileage: null,
    nextInspectionMileageUnit: null,
    warrantyUntil: null,
    warrantyUntilMileage: null,
    warrantyUntilMileageUnit: null,
    warrantyPaintingUntil: null,
    warrantyRustUntil: null,
    records: [],
    notes: "",
    url: null,
    externalReference: null,
};

export default Vue.extend({
    props: {
        vehicle: {
            type: Object as () => ContactVehicle | null,
            required: false,
        },
        contactId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isWorking: false,
            contactVehicleForm: this.vehicle
                ? (cloneObject({
                      ...this.vehicle.contactVehicleData,
                      nextInspectionMileageUnit:
                          this.vehicle.contactVehicleData.nextInspectionMileageUnit ||
                          (configStore.configuration.defaultMileageUnit as MileageUnit),
                      warrantyUntilMileageUnit:
                          this.vehicle.contactVehicleData.warrantyUntilMileageUnit ||
                          (configStore.configuration.defaultMileageUnit as MileageUnit),
                      records: this.vehicle.contactVehicleData.records.map((r: ContactVehicleRecord) => ({
                          ...r,
                          mileageUnit: r.mileageUnit || (configStore.configuration.defaultMileageUnit as MileageUnit),
                      })),
                  }) as ContactVehicleForm)
                : cloneObject({
                      ...emptyContactVehicleForm,
                      nextInspectionMileageUnit: configStore.configuration.defaultMileageUnit as MileageUnit,
                      warrantyUntilMileageUnit: configStore.configuration.defaultMileageUnit as MileageUnit,
                  }),
            maxLength,
            VehicleClass,
            ContactVehicleRecordType,
            positiveIntegerRules: integer(0),
            now: now(),
        };
    },

    computed: {
        modelYearOptions() {
            const currentYear = getCurrentUtcYear(this.now);
            const result = [];

            for (let i = currentYear + 5; i >= MIN_MODEL_YEAR; i--) {
                result.push(i.toString());
            }

            return result;
        },
    },

    methods: {
        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;
            try {
                if (this.vehicle) {
                    await contactsApi.editContactVehicle(this.contactId, this.vehicle.id, this.contactVehicleForm);
                    this.$emit("success");
                } else {
                    const contactVehicleId = await contactsApi.addContactVehicle(
                        this.contactId,
                        this.contactVehicleForm
                    );
                    this.$emit("success", contactVehicleId);
                }
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },

        addRecord() {
            this.contactVehicleForm.records.push({
                date: getDate(now(), userSession.timeZone),
                type: ContactVehicleRecordType.WORKSHOP_APPOINTMENT,
                mileage: null,
                mileageUnit: configStore.configuration.defaultMileageUnit as MileageUnit,
            });
        },

        removeRecord(index: number) {
            this.contactVehicleForm.records.splice(index, 1);
        },
    },

    components: {
        EnumField,
        DatePicker,
        MakePicker,
        ModelPicker,
        NumberField,
    },
});
