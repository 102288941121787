
import { dealerOptions } from "@/app/dealerUtils";
import { OpportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        opportunityAssistantState: {
            type: Object as () => OpportunityAssistantState,
            required: true,
        },
    },

    computed: {
        dealerOptions() {
            return dealerOptions();
        },
    },
});
