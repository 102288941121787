
import { CaseOutcomeReason, CaseOutcomeReasonForm, caseOutcomeReasonsApi } from "@/api/caseOutcomeReasons";
import { showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import { maxLength, notEmpty } from "@/app/validation";
import { reportingSettingsStore } from "@/store/reportingSettingsStore";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            maxLength,
            notEmpty,
        };
    },

    computed: {
        api() {
            return caseOutcomeReasonsApi;
        },

        ignoreCaseOutcomeReasonIdsInReporting(): string[] {
            return reportingSettingsStore.reportingSettings.ignoreCaseOutcomeReasonIds;
        },
    },

    methods: {
        async deleteCaseOutcomeReason(item: CaseOutcomeReason) {
            if (
                await showConfirm(
                    this.$t("Resultat löschen").toString(),
                    this.$t("Sind Sie sicher, dass Sie das Resultat löschen möchten?").toString()
                )
            ) {
                await this.api.delete(item.id);
                return true;
            }
            return false;
        },

        emptyForm(): CaseOutcomeReasonForm {
            return {
                name: "",
                description: null,
            };
        },

        async getCaseOutcomeReasons() {
            return await this.api.getAll();
        },

        toForm(item: CaseOutcomeReason): CaseOutcomeReasonForm {
            return cloneObject(item);
        },

        async updateOrder(items: CaseOutcomeReason[]) {
            await this.api.updateOrder(items.map((ot) => ot.id));
        },
    },

    components: {
        CrudPage,
    },
});
