
import OpportunityTeamPickerCard from "./OpportunityTeamPickerCard.vue";
import { Contact, ContactType } from "@/api/contacts";
import { Opportunity, OpportunityReceiversForm } from "@/api/opportunities";
import { dealerOptions } from "@/app/dealerUtils";
import { VehicleSummary } from "@/app/inventoryUtils";
import { showError } from "@/app/messageUtil";
import UserPickerCard from "@/app/pages/users/UserPickerCard.vue";
import { UserPickerData } from "@/app/pages/users/userPickerCardTypes";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
        vehicleSummaries: {
            type: Array as () => VehicleSummary[],
            default: () => [],
        },
    },

    data() {
        return {
            releasing: false,
            dealerId: this.opportunityProp.dealerId,
            opportunityTeamReceivers: this.opportunityProp.opportunityTeamReceivers.map((i) => i.opportunityTeamId),
            opportunityIndividualReceivers: new UserPickerData(
                this.opportunityProp.opportunityIndividualReceivers.map((i) => i.userId)
            ),
        };
    },

    computed: {
        contactType(): ContactType | null {
            if (this.contact?.deleted) {
                return null;
            }

            return this.contact?.contactData?.type ?? null;
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },
    },

    methods: {
        submit() {
            if (!(this.$refs.releaseForm as any).validate()) {
                return;
            }

            if (!this.opportunityTeamReceivers.length && !this.opportunityIndividualReceivers.userIds.length) {
                showError(this.$t("Es ist kein Empfänger ausgewählt!") as string);
                return;
            }

            this.releasing = true;
            this.$emit(
                "submit",
                {
                    opportunityTeamReceivers: this.opportunityTeamReceivers,
                    individualReceivers: this.opportunityIndividualReceivers.userIds,
                } as OpportunityReceiversForm,
                this.dealerId
            );
        },
    },

    components: {
        OpportunityTeamPickerCard,
        UserPickerCard,
    },
});
