import { IncomingCallSearchOrder, IncomingCallSearchRequest } from "@/api/incomingCalls";

export const EMPTY_SEARCH_REQUEST: IncomingCallSearchRequest = Object.freeze({
    dealerIds: [],
    issueTypes: [],
    acceptorUserIds: [],
    internalIncomingPhoneNumberIds: [],
    fromNumbers: [],
    fromNumberBlocked: null,
    createdFrom: null,
    createdTo: null,
    sortBy: IncomingCallSearchOrder.CREATED_DESC,
});
