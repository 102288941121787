import { NumberBlacklistSearchOrder, NumberBlacklistSearchRequest } from "@/api/numbersBlacklist";

export const EMPTY_SEARCH_REQUEST: NumberBlacklistSearchRequest = Object.freeze({
    number: null,
    creatorActorTypes: [],
    blockCalls: null,
    blockTextMessages: null,
    createdFrom: null,
    createdTo: null,
    sortBy: NumberBlacklistSearchOrder.CREATED_DESC,
});
