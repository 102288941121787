
import EmergencyIncidentContactVehicleCard from "./EmergencyIncidentContactVehicleCard.vue";
import GeneralInfoCard from "./GeneralInfoCard.vue";
import {
    getCloser,
    getCreator,
    getStatusCaption,
    getStatusColor,
    isInvalidEmergencyIncidentStatusException,
} from "./emergencyIncidentUtils";
import { Contact, ContactVehicle } from "@/api/contacts";
import { EmergencyIncident, EmergencyIncidentResult, emergencyIncidentsApi } from "@/api/emergencyIncidents";
import { BadRequest, Forbidden } from "@/api/errors";
import { Note, NoteForm, notesApi } from "@/api/notes";
import {
    isEmergencyIncidentNotesUpdatedNotification,
    isEmergencyIncidentUpdatedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { Permission } from "@/api/userSession";
import { showError } from "@/app/messageUtil";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import UserLink from "@/app/pages/UserLink.vue";
import NoteFormTimelineItem from "@/app/pages/notes/NoteFormTimelineItem.vue";
import NoteTimelineItem from "@/app/pages/notes/NoteTimelineItem.vue";
import { titleStore } from "@/store/title";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

enum TimelineItemType {
    NOTE,
}

interface TimelineItem {
    readonly timelineItemType: TimelineItemType;
    readonly created: Date;
}

export default Vue.extend({
    data() {
        return {
            emergencyIncidentId: "",
            loading: true,
            forbidden: false,
            emergencyIncidentResult: null as EmergencyIncidentResult | null,
            notificationHandler: null as ((n: Notification) => void) | null,
            activeWorkers: 0,
            activeTimelineWorkers: 0,
            notes: [] as Note[],
            addingNote: false,
            timelineForm: null as "note" | null,
            TimelineItemType,
        };
    },

    computed: {
        canAddNote(): boolean {
            return this.canManageEmergencyIncident;
        },

        canClose(): boolean {
            return this.canManageEmergencyIncident && !this.emergencyIncident?.closed;
        },

        canEditContactAndVehicle(): boolean {
            return this.canManageEmergencyIncident;
        },

        canEditGeneralInfo(): boolean {
            return this.canManageEmergencyIncident;
        },

        canManageEmergencyIncident(): boolean {
            if (!this.emergencyIncident) {
                return false;
            }

            return userSession.hasPermission(Permission.MANAGE_EMERGENCY_INCIDENTS);
        },

        contact(): Contact | null {
            return this.emergencyIncidentResult?.contact ?? null;
        },

        contactVehicle(): ContactVehicle | null {
            return this.emergencyIncidentResult?.contactVehicle ?? null;
        },

        closedTimestamp(): string | null {
            if (!this.emergencyIncident || !this.emergencyIncident.closed) {
                return null;
            }

            return formatInstant(this.emergencyIncident.closed, userSession.timeZone, userSession.locale, "S");
        },

        closer(): string {
            return getCloser(this.emergencyIncident);
        },

        creationTimestamp(): string | null {
            if (!this.emergencyIncident || !this.emergencyIncident.created) {
                return null;
            }

            return formatInstant(this.emergencyIncident.created, userSession.timeZone, userSession.locale, "S");
        },

        creator(): string {
            return getCreator(this.emergencyIncident);
        },

        emergencyIncident(): EmergencyIncident | null {
            return this.emergencyIncidentResult?.emergencyIncident ?? null;
        },

        emergencyIncidentStatusCaption(): string {
            return getStatusCaption(this.emergencyIncident);
        },

        emergencyIncidentStatusColor(): string | null {
            return getStatusColor(this.emergencyIncident);
        },

        timelineItems(): TimelineItem[] {
            return [...this.toTimelineItems(this.notes, TimelineItemType.NOTE)].sort(
                (a, b) => b.created.getTime() - a.created.getTime()
            );
        },
    },

    methods: {
        async closeEmergencyIncident() {
            this.activeWorkers++;
            try {
                await emergencyIncidentsApi.closeEmergencyIncident(this.emergencyIncident!.id);
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }

                if (!isInvalidEmergencyIncidentStatusException(e)) {
                    throw e;
                }

                showError(this.$t("Der Notdienstvorgang wurde bereits geschlossen.") as string);
            } finally {
                this.activeWorkers--;
            }
        },

        async addNote(noteForm: NoteForm, close: boolean) {
            this.addingNote = true;
            try {
                await emergencyIncidentsApi.addNote(this.emergencyIncidentId, noteForm);
                this.timelineForm = null;
            } finally {
                this.addingNote = false;
            }

            if (close) {
                await this.closeEmergencyIncident();
            }
        },

        hideTimelineForm() {
            this.timelineForm = null;
        },

        showNoteForm() {
            this.timelineForm = "note";
        },

        toTimelineItems(items: { created: Date }[], type: TimelineItemType): TimelineItem[] {
            return items.map((i) => ({ ...i, timelineItemType: type }));
        },
    },

    async mounted() {
        this.emergencyIncidentId = this.$route.params.emergencyincidentid;

        if (!this.emergencyIncidentId) {
            this.loading = false;
            return;
        }

        titleStore.title = this.$t("Notdienstvorgang {0}", [this.emergencyIncidentId]) as string;

        try {
            const emergencyIncidentResult = await emergencyIncidentsApi.getById(this.emergencyIncidentId);

            if (!emergencyIncidentResult) {
                return;
            }

            const notes = await notesApi.getByEmergencyIncident(emergencyIncidentResult.emergencyIncident.id);

            this.emergencyIncidentResult = emergencyIncidentResult;
            this.notes = notes;
        } catch (e) {
            if (!(e instanceof Forbidden)) {
                throw e;
            }
            this.forbidden = true;
            return;
        } finally {
            this.loading = false;
        }

        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isEmergencyIncidentUpdatedNotification(n)) {
                if (this.emergencyIncident!.id === n.emergencyIncidentId) {
                    try {
                        this.emergencyIncidentResult = await emergencyIncidentsApi.getById(this.emergencyIncidentId);

                        this.forbidden = false;
                    } catch (e) {
                        if (!(e instanceof Forbidden)) {
                            throw e;
                        }
                        this.forbidden = true;
                    }
                }
            } else if (isEmergencyIncidentNotesUpdatedNotification(n)) {
                if (this.emergencyIncident!.id === n.emergencyIncidentId) {
                    this.activeTimelineWorkers++;
                    try {
                        this.notes = await notesApi.getByEmergencyIncident(n.emergencyIncidentId);
                        this.forbidden = false;
                    } catch (e) {
                        if (!(e instanceof Forbidden)) {
                            throw e;
                        }
                        this.forbidden = true;
                    } finally {
                        this.activeTimelineWorkers--;
                    }
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        EmergencyIncidentContactVehicleCard,
        GeneralInfoCard,
        IconWithTooltip,
        NoteFormTimelineItem,
        NoteTimelineItem,
        UserLink,
    },
});
