
import { now } from "@/store/now";
import { formatDifference } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        from: {
            type: (Date as unknown) as () => Date | null,
            required: false,
        },
        to: {
            type: (Date as unknown) as () => Date | null,
            required: false,
        },
        beforeColor: {
            type: String,
            required: true,
        },
        afterColor: {
            type: String,
            required: true,
        },
        betweenColor: {
            type: String as () => string | null,
            required: false,
        },
    },

    computed: {
        difference(): string {
            const n = now();
            if (this.from && n.getTime() < this.from.getTime()) {
                return formatDifference(n, this.from, false);
            }
            return formatDifference(n, this.to || this.from || n, false);
        },

        color(): string {
            const n = now().getTime();
            const from = this.from?.getTime() || 0;
            if (n < from) {
                return this.beforeColor;
            }
            const to = this.to?.getTime() || Number.MAX_SAFE_INTEGER;
            if (n >= from && n < to) {
                return this.betweenColor || this.beforeColor;
            }
            return this.afterColor;
        },
    },
});
