
import DatePicker from "./DatePicker.vue";
import TimePicker from "./TimePicker.vue";
import { getDate, getTime, toDateObject } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: {
            type: (Date as unknown) as () => Date | null,
            default: null,
        },
        tz: {
            type: String,
            required: true,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "",
        },
        mindate: {
            type: String,
            required: false,
        },
        maxdate: {
            type: String,
            required: false,
        },
    },

    computed: {
        date(): string | null {
            return this.value ? getDate(this.value, this.tz) : null;
        },

        time(): string | null {
            return this.value ? getTime(this.value, this.tz) : null;
        },
    },

    methods: {
        async emitInput(date: Date | null) {
            // wait to avoid that the time picker still displays time when the date picker is already cleared
            await this.$nextTick();

            this.$emit("input", date);
        },

        async setDate(date: string | null) {
            const time = this.time || getTime(new Date(), this.tz);

            await this.emitInput(date ? toDateObject(this.tz, date, 0, time) : null);
        },

        async setTime(time: string | null) {
            await this.emitInput(time ? toDateObject(this.tz, this.date!, 0, time || undefined) : null);
        },
    },

    components: {
        DatePicker,
        TimePicker,
    },
});
