
import { autoCrmLeadMailAssistantState, ReceiverIdAndCategory } from "./autoCrmLeadMailAssistantState";
import { AutoCrmLeadMailerUserSettings } from "@/api/autoCrmLeadMailerSettings";
import UserDirectoryEntrySpan from "@/app/pages/users/UserDirectoryEntrySpan.vue";
import { searchFilter } from "@/app/searchFilter";
import { getFullName, getSortedByUserFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import Vue from "vue";

interface CategoryReceiversEntry {
    readonly name: string;
    readonly receivers: ReceiverIdAndCategory[];
}

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        userSettings: {
            type: Array as () => AutoCrmLeadMailerUserSettings[],
            required: true,
        },
    },

    data() {
        return {
            autoCrmLeadMailAssistantState,
        };
    },

    computed: {
        categories(): string[] {
            return this.dealerUserSettings
                .map((u) => u.categories)
                .reduce((prev, cur) => prev.concat(cur), [])
                .sort((a, b) => a.localeCompare(b, userSession.locale))
                .filter((category, index, array) => array.indexOf(category) === index);
        },

        categoryReceiversEntries(): CategoryReceiversEntry[] {
            const userSettings = getSortedByUserFullName(this.dealerUserSettings, (u) => u.userId);

            return this.categories.map((category) => ({
                name: category,
                receivers: userSettings
                    .filter((u) => !!u.emailAddress && u.categories.includes(category))
                    .map((u) => ({
                        receiverId: u.userId,
                        category,
                    })),
            }));
        },

        categoryEntries(): ReceiverIdAndCategory[] {
            return this.categories.map((category) => ({
                receiverId: null,
                category,
            }));
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        dealerUserSettings(): AutoCrmLeadMailerUserSettings[] {
            if (this.autoCrmLeadMailAssistantState.categoryAndReceiverPickerGlobal) {
                return this.userSettings;
            }

            return this.userSettings.filter((u) => usersStore.getUserById(u.userId)?.mainDealerId === this.dealerId);
        },

        filteredUnselectedCategoryEntries(): ReceiverIdAndCategory[] {
            return this.categoryEntries
                .filter((v) => v !== this.autoCrmLeadMailAssistantState.receiverIdAndCategory)
                .filter((v) =>
                    searchFilter(this.autoCrmLeadMailAssistantState.categoryAndReceiverPickerSearch, v.category)
                );
        },

        filteredUnselectedCategoryReceiversEntries(): CategoryReceiversEntry[] {
            return this.categoryReceiversEntries
                .map((cre) => ({
                    ...cre,
                    receivers: cre.receivers
                        .filter((v) => v !== this.autoCrmLeadMailAssistantState.receiverIdAndCategory)
                        .filter((v) =>
                            searchFilter(
                                this.autoCrmLeadMailAssistantState.categoryAndReceiverPickerSearch,
                                cre.name,
                                this.getUserFullNameById(v.receiverId!)
                            )
                        ),
                }))
                .filter((cre) => cre.receivers.length);
        },

        filteredUnselectedReceiverWithoutCategoryEntries(): ReceiverIdAndCategory[] {
            return this.receiverWithoutCategoryEntries
                .filter((v) => v !== this.autoCrmLeadMailAssistantState.receiverIdAndCategory)
                .filter((v) =>
                    searchFilter(
                        this.autoCrmLeadMailAssistantState.categoryAndReceiverPickerSearch,
                        this.getUserFullNameById(v.receiverId!)
                    )
                );
        },

        receiverWithoutCategoryEntries(): ReceiverIdAndCategory[] {
            return getSortedByUserFullName(this.dealerUserSettings, (u) => u.userId)
                .filter((u) => !!u.emailAddress && !u.categories.length)
                .map((u) => ({
                    receiverId: u.userId,
                    category: null,
                }));
        },
    },

    methods: {
        getUserFullNameById(userId: string): string | null {
            return getFullName(usersStore.getUserById(userId)) || null;
        },

        selectReceiverAndCategory(receiverIdAndCategory: ReceiverIdAndCategory) {
            this.autoCrmLeadMailAssistantState.receiverIdAndCategory = receiverIdAndCategory;
        },
    },

    components: {
        UserDirectoryEntrySpan,
    },
});
