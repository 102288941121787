import axios from "axios";

export interface FrontendSettings {
    readonly canUseTelLink: boolean;
}

interface FrontendSettingsApi {
    getFrontendSettings(): Promise<FrontendSettings>;
    editFrontendSettings(settings: FrontendSettings): Promise<void>;
}

export const frontendSettingsApi: FrontendSettingsApi = {
    async getFrontendSettings() {
        return (await axios.get("/api/frontend-settings")).data;
    },

    async editFrontendSettings(settings) {
        await axios.post("/api/frontend-settings", settings);
    },
};
