
import { CaseAssistantState, CaseAssistantStep } from "./caseAssistant";
import { CaseUrgency } from "@/api/cases";
import { EscalationGroupSummary } from "@/api/escalationGroups";
import EscalationGroupLink from "@/app/pages/EscalationGroupLink.vue";
import IssueDataCheckCard from "@/app/pages/IssueDataCheckCard.vue";
import IssueDataCheckCardDataTableRow from "@/app/pages/IssueDataCheckCardDataTableRow.vue";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

function containsOrEmptyOrFalsy<T>(l: T[], e: T | null) {
    return !l.length || !e || l.indexOf(e) >= 0;
}

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        phoneticSpellingLocale: {
            type: String,
            required: true,
        },
        singlePageAssistant: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            CaseUrgency,
            now: now(),
        };
    },

    computed: {
        appointmentTime(): Date | null {
            return this.caseAssistantState.appointmentTime;
        },

        checkCaseDataAcquisitionFields(): boolean {
            return (
                this.checkReceiverSelectionData ||
                this.caseAssistantState.step === CaseAssistantStep.CASE_DATA_ACQUISITION ||
                this.caseAssistantState.urgency !== CaseUrgency.NORMAL
            );
        },

        checkReceiverSelectionData(): boolean {
            return (
                this.singlePageAssistant ||
                this.caseAssistantState.step === CaseAssistantStep.RECEIVER_SELECTION ||
                !!this.caseAssistantState.escalationGroupReceivers.length ||
                !!this.caseAssistantState.escalationBegin ||
                !!this.caseAssistantState.individualReceivers.userIds.length
            );
        },

        dealerId(): string | null {
            return this.caseAssistantState.dealerId;
        },

        dealerTimeZone(): string {
            return dealersStore.dealerById(this.dealerId!)!.timeZone;
        },

        escalationBegin(): Date | null {
            return this.caseAssistantState.escalationBegin;
        },

        escalationGroupReceivers(): (EscalationGroupSummary | null)[] {
            return this.caseAssistantState.escalationGroupReceivers.map((escalationGroupId) =>
                escalationGroupsStore.escalationGroupById(escalationGroupId)
            );
        },
    },

    methods: {
        formatInstant(date: Date | null, timeZone: string | null): string | null {
            if (!date || !timeZone) {
                return null;
            }

            return formatInstant(date, timeZone, userSession.locale, "S");
        },

        isBefore(date1: Date, date2: Date): boolean {
            return date1.getTime() < date2.getTime();
        },

        isEscalationGroupHighlighted(escalationGroup: EscalationGroupSummary): boolean {
            return (
                !!this.caseAssistantState.caseType &&
                escalationGroup.caseTypeSuggestions.indexOf(this.caseAssistantState.caseType) >= 0 &&
                containsOrEmptyOrFalsy(escalationGroup.contactTypes, this.caseAssistantState.contactData?.type) &&
                containsOrEmptyOrFalsy(escalationGroup.makes, this.caseAssistantState.contactVehicleData?.make) &&
                containsOrEmptyOrFalsy(
                    escalationGroup.vehicleClasses,
                    this.caseAssistantState.contactVehicleData?.vehicleClass
                )
            );
        },

        isEscalationGroupOfDealer(escalationGroup: EscalationGroupSummary): boolean {
            return escalationGroup.dealerId === this.caseAssistantState.dealerId;
        },

        isInFuture(date: Date | null): boolean {
            return !!date && this.isBefore(this.now, date);
        },
    },

    components: {
        EscalationGroupLink,
        IssueDataCheckCard,
        IssueDataCheckCardDataTableRow,
    },
});
