
import DealerLink from "./DealerLink.vue";
import OfficeHoursCard from "./officehours/OfficeHoursCard.vue";
import { ContactType } from "@/api/contacts";
import { Dealer } from "@/api/dealers";
import { UsageType } from "@/api/inventory";
import { OfficeHours } from "@/api/officeHours";
import { OpportunityTeam, OpportunityTeamDistributionMethod, OpportunityTeamVisibility } from "@/api/opportunityTeams";
import { UserDirectoryEntry } from "@/api/users";
import { renderUnit } from "@/app/filters";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { officeHoursStore } from "@/store/officeHours";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { usersStore } from "@/store/users";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
    props: {
        opportunityTeamId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            ContactType,
            currentDate: now(),
            OpportunityTeamDistributionMethod,
            OpportunityTeamVisibility,
            renderUnit,
            UsageType,
            getFullName,
        };
    },

    computed: {
        dealer(): Dealer | null {
            return this.opportunityTeam ? dealersStore.dealerById(this.opportunityTeam.dealerId) : null;
        },

        officeHours(): OfficeHours | null {
            return this.opportunityTeam?.officeHoursId
                ? officeHoursStore.getOfficeHoursById(this.opportunityTeam.officeHoursId)
                : null;
        },

        opportunityTeam(): OpportunityTeam | null {
            return opportunityTeamsStore.getOpportunityTeamById(this.opportunityTeamId);
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        getDealerNameById(dealerId: string): string | null {
            return dealersStore.dealerById(dealerId)!.name ?? null;
        },

        getItemsTranslationText(items: string[], translationSupplier: (key: string) => TranslateResult | string) {
            return items.map((i) => translationSupplier(i)).join(", ");
        },

        getOpportunitySourceNameById(opportunitySourceId: string): string | null {
            return opportunitySourcesStore.getOpportunitySourceById(opportunitySourceId)?.name ?? null;
        },

        getUserById(userId: string): UserDirectoryEntry | null {
            return usersStore.getUserById(userId);
        },
    },

    components: {
        DealerLink,
        OfficeHoursCard,
    },
});
