import axios from "axios";

export interface SalespersonMapping {
    readonly salesperson: string;
    readonly userId: string;
}

export interface DealerMapping {
    readonly dealer: string;
    readonly dealerId: string;
}

export interface FinanceContractSettings {
    readonly salespersonMappings: SalespersonMapping[];
    readonly disableSalespersonResolving: boolean;
    readonly dealerMappings: DealerMapping[];
}

interface FinanceContractSettingsApi {
    getSettings(): Promise<FinanceContractSettings>;
    editSettings(settings: FinanceContractSettings): Promise<void>;
}

export const financeContractSettingsApi: FinanceContractSettingsApi = {
    async getSettings() {
        return (await axios.get("/api/finance-contract-settings")).data;
    },

    async editSettings(settings) {
        await axios.post("/api/finance-contract-settings", settings);
    },
};
