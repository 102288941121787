import axios from "axios";

export enum OpportunityNotificationEmailFormat {
    REGULAR = "REGULAR",
    REGULAR_WITHOUT_CONTACT_DATA = "REGULAR_WITHOUT_CONTACT_DATA",
    REGULAR_WITH_CLOSE_LINK = "REGULAR_WITH_CLOSE_LINK",
}

export interface OpportunityNotificationSettings {
    readonly dealerId: string;
    readonly opportunityNotificationEmailFormat: OpportunityNotificationEmailFormat;
}

interface OpportunityNotificationSettingsApi {
    editOpportunityNotificationSettings(
        opportunityNotificationSettings: OpportunityNotificationSettings
    ): Promise<void>;
    getByDealer(dealerId: string): Promise<OpportunityNotificationSettings>;
}

export const opportunityNotificationSettingsApi: OpportunityNotificationSettingsApi = {
    async editOpportunityNotificationSettings(opportunityNotificationSettings) {
        await axios.put("/api/opportunity-notification-settings", opportunityNotificationSettings);
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/opportunity-notification-settings/${dealerId}`)).data;
    },
};
