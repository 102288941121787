import { userSession } from "./userSession";
import { makeModelsApi, MakeWithModels, ModelgroupWithModels, VehicleFullClass } from "@/api/makeModels";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";

@reactive
class MakeModelsStore {
    private fullClasses_: VehicleFullClass[] = [];
    private initLimiter = new ActionLimiter(true);

    async initialize() {
        await this.initLimiter.execute(async () => {
            this.fullClasses_ = await makeModelsApi.getFullClasses();
        });
    }

    get categories(): string[] {
        return this.fullClasses_
            .reduce((prev, fullClass) => prev.concat(fullClass.categoriesAndMakes.categories), [] as string[])
            .sort((a, b) => a.localeCompare(b, userSession.locale))
            .filter((value, index, array) => !index || array[index - 1] !== value);
    }

    get makes(): string[] {
        return this.makesWithModels
            .map((makeWithModels) => makeWithModels.name)
            .sort((a, b) => a.localeCompare(b, userSession.locale))
            .filter((value, index, array) => !index || array[index - 1] !== value);
    }

    get modelgroups(): ModelgroupWithModels[] {
        return this.makesWithModels
            .reduce((prev, cur) => prev.concat(cur.modelgroups), [] as ModelgroupWithModels[])
            .map((group) => ({
                ...group,
                models: [...group.models]
                    .sort((a, b) => a.localeCompare(b, userSession.locale))
                    .filter((value, index, array) => !index || array[index - 1] !== value),
            }))
            .sort((a, b) => a.name.localeCompare(b.name, userSession.locale));
    }

    get models(): string[] {
        return this.makesWithModels
            .reduce((prev, cur) => prev.concat(cur.models), [] as string[])
            .sort((a, b) => a.localeCompare(b, userSession.locale))
            .filter((value, index, array) => !index || array[index - 1] !== value);
    }

    get vehicleClasses(): string[] {
        return this.fullClasses_
            .map((c) => c.classKey)
            .filter((value, index, array) => !index || array[index - 1] !== value);
    }

    getModelsByMake(make: string): string[] {
        return this.getModelsByMakes([make]);
    }

    getModelsByMakes(makes: string[]): string[] {
        return this.makesWithModels
            .filter((makeWithModels) => makes.includes(makeWithModels.name))
            .reduce((prev, cur) => prev.concat(cur.models), [] as string[])
            .sort((a, b) => a.localeCompare(b, userSession.locale))
            .filter((value, index, array) => !index || array[index - 1] !== value);
    }

    getModelgroupsByMake(make: string): ModelgroupWithModels[] {
        return this.getModelgroupsByMakes([make]);
    }

    getModelgroupsByMakes(makes: string[]): ModelgroupWithModels[] {
        return this.makesWithModels
            .filter((makeWithModels) => makes.includes(makeWithModels.name) && makeWithModels.models.length)
            .reduce((prev, cur) => prev.concat(cur.modelgroups), [] as ModelgroupWithModels[])
            .map((group) => ({
                ...group,
                models: [...group.models]
                    .sort((a, b) => a.localeCompare(b, userSession.locale))
                    .filter((value, index, array) => !index || array[index - 1] !== value),
            }))
            .sort((a, b) => a.name.localeCompare(b.name, userSession.locale));
    }

    private get makesWithModels(): MakeWithModels[] {
        return this.fullClasses_
            .reduce((prev, fullClass) => prev.concat(fullClass.categoriesAndMakes.makes), [] as MakeWithModels[])
            .sort((a, b) => a.name.localeCompare(b.name, userSession.locale));
    }
}

export const makeModelsStore = new MakeModelsStore();
