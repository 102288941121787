
import { escalationGroupsApi, EscalationGroupSummary } from "@/api/escalationGroups";
import { IndividualEscalationData, IndividualEscalations, individualEscalationsApi } from "@/api/individualEscalations";
import { Permission } from "@/api/userSession";
import { UserDirectoryEntry } from "@/api/users";
import NumberField from "@/app/components/NumberField.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { getFullName, getSortedByUserFullName } from "@/app/userUtils";
import { notEmpty, validate } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            loading: true,
            escalationGroupSummaries: [] as EscalationGroupSummary[],
            individualEscalations: [] as IndividualEscalations[],
            escalationTimeMinutesRules: notEmpty().integer(0),
            getFullName,
            sortableInitialized: false,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        escalationGroupSummariesOptions(): SelectOption[] {
            return this.escalationGroupSummaries.map((g) => ({
                value: g.id,
                text: `${g.name} (${dealersStore.dealerById(g.dealerId)!.name})`,
            }));
        },

        individualEscalationsSortedByUserFullName(): IndividualEscalations[] {
            return getSortedByUserFullName(this.individualEscalations, (i) => i.userId);
        },
    },

    methods: {
        getUserById(userId: string): UserDirectoryEntry {
            return usersStore.getUserById(userId)!;
        },

        async loadItems() {
            this.loading = true;

            try {
                if (this.dealerId) {
                    this.escalationGroupSummaries = await escalationGroupsApi.getAll();
                    this.individualEscalations = await individualEscalationsApi.getByDealer(this.dealerId);
                } else {
                    this.escalationGroupSummaries = [];
                    this.individualEscalations = [];
                }
            } finally {
                this.loading = false;
            }
        },

        async setEscalationTime(individualEscalation: IndividualEscalations) {
            if (
                validate(
                    this.escalationTimeMinutesRules,
                    individualEscalation.individualEscalationData!.escalationTimeMinutes
                )
            ) {
                await individualEscalationsApi.editIndividualEscalation(individualEscalation.userId, {
                    escalationGroupId: individualEscalation.individualEscalationData!.escalationGroupId,
                    escalationTimeMinutes: individualEscalation.individualEscalationData!.escalationTimeMinutes,
                    instantEscalationIfOffline: individualEscalation.individualEscalationData!
                        .instantEscalationIfOffline,
                });
            }
        },

        async setInstantEscalation(individualEscalation: IndividualEscalations, newInstantEscalation: boolean) {
            await individualEscalationsApi.editIndividualEscalation(individualEscalation.userId, {
                escalationGroupId: individualEscalation.individualEscalationData!.escalationGroupId,
                escalationTimeMinutes: individualEscalation.individualEscalationData!.escalationTimeMinutes,
                instantEscalationIfOffline: newInstantEscalation,
            });
        },

        async setEscalationGroup(individualEscalation: IndividualEscalations, newEscalationGroup: string) {
            this.loading = true;

            try {
                if (!newEscalationGroup) {
                    await individualEscalationsApi.deleteByUser(individualEscalation.userId);
                } else {
                    const individualEscalationData: IndividualEscalationData = {
                        escalationGroupId: newEscalationGroup,
                        escalationTimeMinutes:
                            individualEscalation.individualEscalationData?.escalationTimeMinutes || 60,
                        instantEscalationIfOffline: individualEscalation.individualEscalationData
                            ? individualEscalation.individualEscalationData.instantEscalationIfOffline
                            : false,
                    };

                    await individualEscalationsApi.editIndividualEscalation(
                        individualEscalation.userId,
                        individualEscalationData
                    );
                }

                this.individualEscalations = await individualEscalationsApi.getByDealer(this.dealerId!);
            } finally {
                this.loading = false;
            }
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DealerContextGuard,
        NumberField,
    },
});
