
import {
    ContactParserCharset,
    ContactParserFormat,
    ContactParserSeparator,
    contactUploadsApi,
    ContactUploadSource,
    ContactUploadSourceForm,
} from "@/api/contactUploads";
import CountryPicker from "@/app/components/CountryPicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import { generateRandomUUID } from "@/util/uuidUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        contactUploadSource: {
            type: Object as () => ContactUploadSource | null,
            required: false,
        },
    },

    data() {
        return {
            ContactParserFormat,
            ContactParserCharset,
            ContactParserSeparator,

            valid: true,
            isWorking: false,

            // FORM FIELDS
            name: this.contactUploadSource?.name || "",
            dealerId: this.contactUploadSource?.dealerId || null,
            deleteContactIfExternalContactIsMissing:
                this.contactUploadSource?.deleteContactIfExternalContactIsMissing ?? false,
            defaultCountry: this.contactUploadSource?.defaultCountry || configStore.configuration.defaultCountry,
            format: this.contactUploadSource?.format || ContactParserFormat.UNKNOWN,
            charset: (this.contactUploadSource?.charset || null) as ContactParserCharset | null,
            separator: (this.contactUploadSource?.separator || null) as ContactParserSeparator | null,
            importFromFtp: this.contactUploadSource?.importFromFtp ?? false,
            ftpHost: (this.contactUploadSource?.ftpHost || null) as string | null,
            ftpUsername: (this.contactUploadSource?.ftpUsername || null) as string | null,
            ftpPassword: (this.contactUploadSource?.ftpPassword || null) as string | null,
            ftpFileLocation: (this.contactUploadSource?.ftpFileLocation || null) as string | null,
            ftpFileBasename: (this.contactUploadSource?.ftpFileBasename || null) as string | null,

            // RULES
            nameRules: notEmpty().maxLength(63),
            maxLength,
            notEmpty,
        };
    },

    computed: {
        dealerOptions() {
            return dealerOptions();
        },
    },

    methods: {
        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            const contactUploadSourceForm: ContactUploadSourceForm = {
                name: this.name,
                dealerId: this.dealerId,
                deleteContactIfExternalContactIsMissing: this.deleteContactIfExternalContactIsMissing,
                defaultCountry: this.defaultCountry,
                format: this.format,
                charset: this.charset,
                separator: this.separator,
                importFromFtp: this.importFromFtp,
                ftpHost: this.ftpHost,
                ftpUsername: this.ftpUsername,
                ftpPassword: this.ftpPassword,
                ftpFileLocation: this.ftpFileLocation,
                ftpFileBasename: this.ftpFileBasename,
            };

            try {
                if (this.contactUploadSource) {
                    await contactUploadsApi.edit(this.contactUploadSource.id, contactUploadSourceForm);
                } else {
                    await contactUploadsApi.add(contactUploadSourceForm);
                }
            } finally {
                this.isWorking = false;
            }
            this.$emit("success");
        },

        close() {
            this.$emit("close");
        },

        onPasteFtpHost(event: ClipboardEvent) {
            const ftpHost = trimAndReturnNullIfEmpty(event.clipboardData?.getData("text/plain"));

            if (!ftpHost?.startsWith("ftp://")) {
                // execute native behavior
                return;
            }

            try {
                const url = new URL(ftpHost);

                event.preventDefault();

                this.ftpUsername = url.username;
                this.ftpPassword = url.password;
                this.ftpHost = url.host;
                this.ftpFileLocation = url.pathname;
            } catch (e) {
                // execute native behavior
            }
        },
    },

    watch: {
        importFromFtp() {
            if (this.importFromFtp) {
                this.ftpFileBasename = this.ftpFileBasename ?? generateRandomUUID();
            } else {
                this.ftpHost = null;
                this.ftpUsername = null;
                this.ftpPassword = null;
                this.ftpFileLocation = null;
                this.ftpFileBasename = null;
            }
        },
    },

    components: {
        CountryPicker,
        EnumField,
    },
});
