import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum OrderType {
    UNFALL = "UNFALL",
    PANNE = "PANNE",
    VERBRINGUNGSFAHRT = "VERBRINGUNGSFAHRT",
    HOL_UND_BRINGDIENST = "HOL_UND_BRINGDIENST",
    LACKFAHRT = "LACKFAHRT",
    FALSCHPARKER = "FALSCHPARKER",
    UEBERFUEHRUNG = "UEBERFUEHRUNG",
}

export enum PropulsionType {
    DIESEL = "DIESEL",
    DIESEL_HYBRID = "DIESEL_HYBRID",
    ELECTRIC = "ELECTRIC",
    GASOLINE = "GASOLINE",
    GASOLINE_HYBRID = "GASOLINE_HYBRID",
    OTHER = "OTHER",
    UNKNOWN = "UNKNOWN",
}

export enum RemarkType {
    DIAGNOSIS = "DIAGNOSIS",
    SHORTREPORT = "SHORTREPORT",
}

export enum Status {
    NO_CLEARANCE = "NO_CLEARANCE",
    CLEARANCE = "CLEARANCE",
    CANCELLED = "CANCELLED",
    EMPTY_TRIP = "EMPTY_TRIP",
    TIMEOUT = "TIMEOUT",
    ARCHIVED = "ARCHIVED",
    SENT = "SENT",
    RECEIVED = "RECEIVED",
    READ = "READ",
    ACCEPTED = "ACCEPTED",
    START = "START",
    TOWING_START = "TOWING_START",
    RECOVER_START = "RECOVER_START",
    ARRIVE_AT_SERVICE_POINT = "ARRIVE_AT_SERVICE_POINT",
    WORK_START = "WORK_START",
    WORK_END = "WORK_END",
    DRIVE_TO_DELIVERY_POINT = "DRIVE_TO_DELIVERY_POINT",
    END = "END",
    PAUSED = "PAUSED",
    DRIVE_TO_SERVICE_POINT = "DRIVE_TO_SERVICE_POINT",
    ARRIVE_AT_DELIVERY_POINT = "ARRIVE_AT_DELIVERY_POINT",
    UNLOAD_START = "UNLOAD_START",
    UNLOAD_END = "UNLOAD_END",
    NOT_INTERRUPTED = "NOT_INTERRUPTED",
    CLIENT_INFORMED = "CLIENT_INFORMED",
    WORK_END_DRIVABLE = "WORK_END_DRIVABLE",
    WORK_END_NO_ACTION = "WORK_END_NO_ACTION",
    BREAKDOWN_END_DRIVABLE = "BREAKDOWN_END_DRIVABLE",
    BREAKDOWN_END_DRIVABLE_LIMITED = "BREAKDOWN_END_DRIVABLE_LIMITED",
    BREAKDOWN_END_TOWING_NEEDED = "BREAKDOWN_END_TOWING_NEEDED",
    VEHICLE_STORED = "VEHICLE_STORED",
    CREATED = "CREATED",
    TOWING_END = "TOWING_END",
    START_COMPLICATED_LOADING = "START_COMPLICATED_LOADING",
    END_COMPLICATED_LOADING = "END_COMPLICATED_LOADING",
    RECOVER_END_DRIVABLE = "RECOVER_END_DRIVABLE",
    RECOVER_END_DRIVABLE_LIMITED = "RECOVER_END_DRIVABLE_LIMITED",
    RECOVER_END_NO_ACTION = "RECOVER_END_NO_ACTION",
    RECOVER_END_TOWING_NEEDED = "RECOVER_END_TOWING_NEEDED",
    SECOND_TOWING_START = "SECOND_TOWING_START",
    SECOND_TOWING_END = "SECOND_TOWING_END",
    FETCHED = "FETCHED",
    TRANSPORT_END = "TRANSPORT_END",
    LOAD_END = "LOAD_END",
    PICKUP_END = "PICKUP_END",
    WORK_END_TOWING_NEEDED = "WORK_END_TOWING_NEEDED",
    OPERATOR_START_ORDER = "OPERATOR_START_ORDER",
    START_WORK_BREAKDOWN = "START_WORK_BREAKDOWN",
    START_WORK_TOWING = "START_WORK_TOWING",
    START_WORK_RECOVER = "START_WORK_RECOVER",
    ROLLING_NO = "ROLLING_NO",
    ROLLING_YES = "ROLLING_YES",
    LOCKED_NO = "LOCKED_NO",
    LOCKED_YES = "LOCKED_YES",
    SECOND_TOWING_NEEDED = "SECOND_TOWING_NEEDED",
    CLIENT_INFORMED_BY_PHONE = "CLIENT_INFORMED_BY_PHONE",
    CLIENT_INFORMED_BY_SMS = "CLIENT_INFORMED_BY_SMS",
    LOAD_START = "LOAD_START",
    CONTRACTOR_NOT_DEFINED = "CONTRACTOR_NOT_DEFINED",
    REJECTED = "REJECTED",
    RECOVER_YES = "RECOVER_YES",
    RECOVER_NO = "RECOVER_NO",
    DRIVE_TO_LOADING_POINT = "DRIVE_TO_LOADING_POINT",
    LOAD_VEHICLE = "LOAD_VEHICLE",
    UNLOAD_VEHICLE = "UNLOAD_VEHICLE",
    CONFIRM_SIMPLE_SERVICE_POINT = "CONFIRM_SIMPLE_SERVICE_POINT",
    CONFIRM_SIGNATURE_SERVICE_POINT = "CONFIRM_SIGNATURE_SERVICE_POINT",
    CONFIRM_SIMPLE_DELIVERY_POINT = "CONFIRM_SIMPLE_DELIVERY_POINT",
    CONFIRM_SIGNATURE_DELIVERY_POINT = "CONFIRM_SIGNATURE_DELIVERY_POINT",
    CONFIRM_ALL_SIMPLE_SERVICE_POINT = "CONFIRM_ALL_SIMPLE_SERVICE_POINT",
    CONFIRM_ALL_SIGNATURE_SERVICE_POINT = "CONFIRM_ALL_SIGNATURE_SERVICE_POINT",
    CONFIRM_ALL_SIMPLE_DELIVERY_POINT = "CONFIRM_ALL_SIMPLE_DELIVERY_POINT",
    CONFIRM_ALL_SIGNATURE_DELIVERY_POINT = "CONFIRM_ALL_SIGNATURE_DELIVERY_POINT",
    RETURN = "RETURN",
    START_WORK_TOUR = "START_WORK_TOUR",
    END_WORK_TOUR = "END_WORK_TOUR",
    START_CLEANING = "START_CLEANING",
    END_CLEANING = "END_CLEANING",
    PREPARE_CLEANING = "PREPARE_CLEANING",
    APPLICATION_BINDER = "APPLICATION_BINDER",
    NO_BINDER = "NO_BINDER",
    PRE_CLEANING = "PRE_CLEANING",
    NO_PRE_CLEANING = "NO_PRE_CLEANING",
    VACCUM_SCRUB_CLEANING = "VACCUM_SCRUB_CLEANING",
    HIGH_PRSSURE_CLEANING = "HIGH_PRSSURE_CLEANING",
    CLEANING_DEVICES = "CLEANING_DEVICES",
    DISPOSAL_MUD = "DISPOSAL_MUD",
    RESTORE_OPERATION_READINESS = "RESTORE_OPERATION_READINESS",
}

export enum TargetType {
    SERVICE_POINT = "SERVICE_POINT",
    DELIVERY_POINT = "DELIVERY_POINT",
}

export enum TransmissionType {
    AUTOMATIC = "AUTOMATIC",
    AUTOMATIC_DUAL_CLUTCH = "AUTOMATIC_DUAL_CLUTCH",
    MANUAL = "MANUAL",
    MANUMATIC = "MANUMATIC",
    OTHER = "OTHER",
    UNKNOWN = "UNKNOWN",
}

export enum VehicleType {
    PKW = "PKW",
    VAN = "VAN",
    MOTORRAD = "MOTORRAD",
    MOTORRAD_MIT_BEIWAGEN = "MOTORRAD_MIT_BEIWAGEN",
    SUV = "SUV",
    WOHNMOBIL = "WOHNMOBIL",
    WOHNWAGEN = "WOHNWAGEN",
    ANHAENGER = "ANHAENGER",
    LKW = "LKW",
    BUS = "BUS",
    FLUGZEUG = "FLUGZEUG",
    BOOTSANHAENGER = "BOOTSANHAENGER",
    ANHAENGER_MIT_BOOT = "ANHAENGER_MIT_BOOT",
    BOOT = "BOOT",
    CONTAINER = "CONTAINER",
}

export interface ExternOrder {
    readonly id: number;
    readonly lastmodified: Date | null;
    readonly contractorCustomerName: string | null;
    readonly contractorCustomerNumber: string | null;
    readonly contractorCustomerPhone: string | null;
    readonly branch: string | null;
    readonly orderType: OrderType | null;
    readonly targetDate: Date | null;
    readonly endDate: Date | null;
    readonly createdDate: Date;
    readonly status: Status | null;
    readonly statusDetails: string | null;
    readonly vehicle: Vehicle;
    readonly targets: Target[];
    readonly remarks: Remark[];
    readonly clearance: boolean;
    readonly source: string | null;
    readonly accessToken: string | null;
    readonly eta: Date | null;
    readonly etaDestination: string | null;
}

export interface ExternOrderForm {
    readonly contractorCustomerName: string;
    readonly contractorCustomerNumber: string | null;
    readonly contractorCustomerPhone: string;
    readonly branch: string | null;
    readonly orderType: OrderType;
    readonly targetDate: Date | null;
    readonly endDate: Date | null;
    readonly vehicle: VehicleForm;
    readonly targets: TargetForm[];
    readonly remarks: RemarkForm[];
    readonly clearance: boolean;
    readonly source: string | null;
}

export interface Remark {
    readonly id: number;
    readonly lastmodified: Date | null;
    readonly type: RemarkType | null;
    readonly text: string | null;
}

export interface RemarkForm {
    readonly type: RemarkType;
    readonly text: string;
}

export interface StatusResult {
    readonly id: number;
    readonly timestamp: Date;
    readonly content: string | null;
}

export interface Target {
    readonly id: number;
    readonly lastmodified: Date | null;
    readonly type: TargetType | null;
    readonly name: string | null;
    readonly description: string | null;
    readonly street: string | null;
    readonly houseNumber: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly region: string | null;
    readonly country: string | null;
    readonly additionalInfo: string | null;
}

export interface TargetForm {
    readonly type: TargetType;
    readonly name: string | null;
    readonly description: string | null;
    readonly street: string | null;
    readonly houseNumber: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly region: string | null;
    readonly country: string | null;
    readonly additionalInfo: string | null;
}

export interface Vehicle {
    readonly id: number;
    readonly lastmodified: Date | null;
    readonly brand: string | null;
    readonly chassisNumber: string | null;
    readonly color: string | null;
    readonly comment: string | null;
    readonly description: string | null;
    readonly driver: string | null;
    readonly licensePlate: string | null;
    readonly model: string | null;
    readonly odometerKilometers: number | null;
    readonly propulsionType: PropulsionType | null;
    readonly registrationDate: Date | null;
    readonly transmissionType: TransmissionType | null;
    readonly passengers: number | null;
    readonly vehicleType: VehicleType | null;
}

export interface VehicleForm {
    readonly brand: string | null;
    readonly chassisNumber: string | null;
    readonly color: string | null;
    readonly comment: string | null;
    readonly description: string | null;
    readonly driver: string | null;
    readonly licensePlate: string | null;
    readonly model: string | null;
    readonly odometerKilometers: number | null;
    readonly propulsionType: PropulsionType;
    readonly registrationDate: string | null;
    readonly transmissionType: TransmissionType;
    readonly passengers: number | null;
    readonly vehicleType: VehicleType;
}

interface CarryDispoApi {
    addExternOrder(dealerId: string, externOrderForm: ExternOrderForm): Promise<void>;
    cancelExternOrder(dealerId: string, id: number, reason: string): Promise<void>;
    generateAccessToken(dealerId: string, id: number): Promise<string | null>;
    getExternOrderStatusResults(dealerId: string, externOrderId: number): Promise<StatusResult[]>;
}

export const carryDispoApi: CarryDispoApi = {
    async addExternOrder(dealerId, externOrderForm): Promise<void> {
        await axios.post(`/api/carry-dispo/${dealerId}`, externOrderForm);
    },

    async cancelExternOrder(dealerId, id, reason): Promise<void> {
        await axios.post(`/api/carry-dispo/${dealerId}/${id}/_cancel`, null, {
            params: {
                reason,
            },
        });
    },

    async generateAccessToken(dealerId, externOrderId): Promise<string | null> {
        return (await axios.get(`/api/carry-dispo/${dealerId}/${externOrderId}/_generate-access-token`)).data;
    },

    async getExternOrderStatusResults(dealerId, externOrderId): Promise<StatusResult[]> {
        return cloneObject((await axios.get(`/api/carry-dispo/${dealerId}/${externOrderId}/status-results`)).data);
    },
};
