import {
    isOpportunitySourceChangedNotification,
    isOpportunitySourceDeletedNotification,
    isOpportunitySourcesOrderChangedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { OpportunitySource, opportunitySourcesApi } from "@/api/opportunitySources";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { removeMatchingItems, reorderItems, updateItem } from "@/util/storeUtils";

@reactive
class OpportunitySourcesStore {
    private refreshLimiter = new ActionLimiter(true);
    private opportunitySources_: OpportunitySource[] = [];

    getOpportunitySourceById(id: string): OpportunitySource | null {
        return this.opportunitySources_.find((o) => o.id === id) ?? null;
    }

    get opportunitySources() {
        return [...this.opportunitySources_];
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.opportunitySources_ = await opportunitySourcesApi.getAll();
        });
    }

    handleNotification(n: Notification) {
        if (isOpportunitySourceChangedNotification(n)) {
            updateItem(this.opportunitySources_, n.opportunitySource, (o) => o.id);
        } else if (isOpportunitySourceDeletedNotification(n)) {
            removeMatchingItems(this.opportunitySources_, (o) => o.id === n.opportunitySourceId);
        } else if (isOpportunitySourcesOrderChangedNotification(n)) {
            reorderItems(this.opportunitySources_, n.orderedOpportunitySourceIds, (o) => o.id);
        }
    }
}

export const opportunitySourcesStore = new OpportunitySourcesStore();

notificationEventSource.addDataHandler((n) => opportunitySourcesStore.handleNotification(n));
