
import { BadRequest } from "@/api/errors";
import { WhatsAppAccount, whatsAppAccountApi, WhatsAppTemplate } from "@/api/whatsAppAccounts";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { showInfo } from "@/app/messageUtil";
import { notEmpty, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        toNumber: String,
        working: Boolean,
        onSubmit: Function,
    },

    data() {
        const validationHelper = new ValidationHelper();
        return {
            loading: true,
            accountId: null as string | null,
            accounts: [] as WhatsAppAccount[],
            accountRules: notEmpty(),
            toNumberField: this.toNumber,
            toNumberRules: notEmpty()
                .e164()
                .and(validationHelper, "toNumber"),
            templateValue: null as string | null,
            templates: [] as WhatsAppTemplate[],
            templateRules: notEmpty(),
            validationHelper,
        };
    },

    computed: {
        accountOptions(): SelectOptions {
            return this.accounts.map((a) => ({
                value: a.id,
                text: `${parseAndFormatNumber(a.number, "INTERNATIONAL")!} (${a.name})`,
            }));
        },

        templateOptions(): SelectOptions {
            return this.templates.map((t) => ({
                value: JSON.stringify(t),
                text: `${t.name} (${t.language})`,
            }));
        },

        defaultCountry(): string {
            return configStore.configuration.defaultCountry;
        },

        text(): string {
            return this.templateValue ? JSON.parse(this.templateValue).text : "";
        },
    },

    watch: {
        async accountId() {
            this.templateValue = null;
            this.loading = true;
            try {
                this.templates = await whatsAppAccountApi.getTemplates(this.accountId!);
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            } finally {
                this.loading = false;
            }

            if (this.templates.length === 1) {
                this.templateValue = JSON.stringify(this.templates[0]);
            }
        },
    },

    methods: {
        async submit() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            try {
                await this.onSubmit({
                    toNumber: this.toNumberField,
                    accountId: this.accountId,
                    template: JSON.parse(this.templateValue!),
                });
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    this.$nextTick(() => {
                        throw e;
                    });
                } else {
                    this.validationHelper.update(e, this.$refs.form);
                }
            }
        },
    },

    async mounted() {
        try {
            this.accounts = await whatsAppAccountApi.getAllAccounts();
        } finally {
            this.loading = false;
        }

        if (!this.accounts.length) {
            showInfo(
                this.$t(
                    "Es sind keine WhatsApp-Konten konfiguriert. Bitte wenden Sie sich an Ihren System-Administrator."
                ) as string
            );
            this.$emit("close");
            return;
        }

        const mainDealerAccounts = this.accounts.filter((a) => a.dealerId === userSession.mainDealerId);
        this.accountId = mainDealerAccounts.length ? mainDealerAccounts[0].id : this.accounts[0].id;
    },

    components: {
        PhoneNumberField,
    },
});
