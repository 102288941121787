import { ContactMethod, ContactNumber, EmailAddress, NumberType } from "@/api/contacts";
import { $t } from "@/app/i18n";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { SelectOption } from "@/util/types";

export interface PreferredContact {
    preferredContactMethod: ContactMethod | null;
    preferredContactDetails: string | null;
    earliestContactTime: Date | null;
    latestContactTime: Date | null;
}

export const EMPTY_PREFERRED_CONTACT: PreferredContact = {
    preferredContactMethod: null,
    preferredContactDetails: null,
    earliestContactTime: null,
    latestContactTime: null,
};

export function getContactDetailsLabel(contactMethod: ContactMethod | null): string | null {
    if (contactMethod === ContactMethod.EMAIL) {
        return $t("E-Mail-Adresse") as string;
    } else if (contactMethod === ContactMethod.PHONE) {
        return $t("Rückrufnummer") as string;
    } else if (contactMethod === ContactMethod.SMS) {
        return $t("Kontaktrufnummer") as string;
    } else {
        return null;
    }
}

export function getContactDetailsOptions(
    contactMethod: ContactMethod | null,
    emailAddresses: EmailAddress[],
    numbers: ContactNumber[]
): SelectOption[] {
    if (contactMethod === ContactMethod.EMAIL) {
        return emailAddresses.map((e) => ({
            text: e.label ? `${e.address} (${e.label})` : e.address,
            value: e.address,
        }));
    } else if (contactMethod === ContactMethod.PHONE || contactMethod === ContactMethod.SMS) {
        return numbers
            .filter((n) => n.type === NumberType.LANDLINE || n.type === NumberType.MOBILE)
            .map((n) => {
                const e164 = parseAndFormatNumber(n.number, "INTERNATIONAL");

                return e164
                    ? {
                          text: n.label ? `${e164} (${n.label})` : e164,
                          value: n.number,
                      }
                    : null;
            })
            .filter((o) => o) as SelectOption[];
    } else {
        return [];
    }
}
