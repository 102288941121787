
import ContactInfo from "./datatable/ContactInfo.vue";
import EmergencyIncidentGeneralInfo from "./datatable/EmergencyIncidentGeneralInfo.vue";
import EmergencyIncidentMainInfo from "./datatable/EmergencyIncidentMainInfo.vue";
import { EmergencyIncidentResult } from "@/api/emergencyIncidents";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        canSelect: {
            type: Boolean,
            required: true,
        },
        emergencyIncidentResult: {
            type: Object as () => EmergencyIncidentResult,
            required: true,
        },
        openInNewTab: {
            type: Boolean,
            required: true,
        },
        selectDisabled: {
            type: Boolean,
            default: false,
        },
        ultraCompactMode: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        colCount(): number {
            return 2 + (this.ultraCompactMode ? 0 : 1);
        },

        compact(): boolean {
            return userSession.compactMode || this.ultraCompactMode;
        },

        horizontalPadding(): number {
            return this.ultraCompactMode || this.$vuetify.breakpoint.xsOnly ? 2 : 3;
        },

        tableViewCellClass(): string {
            return `py-${this.verticalPadding} pl-${this.horizontalPadding} pr-0`;
        },

        tableViewLastCellClass(): string {
            return `${this.tableViewCellClass} pr-${this.horizontalPadding}`;
        },

        verticalPadding(): number {
            return this.$vuetify.breakpoint.xsOnly ? 2 : this.compact ? 1 : 2;
        },
    },

    components: {
        ContactInfo,
        EmergencyIncidentGeneralInfo,
        EmergencyIncidentMainInfo,
    },
});
