import axios from "axios";

export interface ChangelogView {
    readonly created: string;
    readonly version: string;
    readonly items: string[];
}

interface ChangelogsApi {
    getTotalChangelogCount(): Promise<number>;
    getChangelogs(fromIndex: number, toIndex: number, locale: string): Promise<ChangelogView[]>;
    getUnreadChangelogCount(): Promise<number>;
    resetUnreadChangelogCount(): Promise<void>;
}

export const changelogsApi: ChangelogsApi = {
    async getTotalChangelogCount() {
        return (await axios.get(`/api/changelogs/total-count`)).data;
    },

    async getChangelogs(fromIndex, toIndex, locale) {
        return (await axios.get(`/api/changelogs?fromIndex=${fromIndex}&toIndex=${toIndex}&locale=${locale}`)).data;
    },

    async getUnreadChangelogCount() {
        return (await axios.get(`/api/changelogs/unread-count`)).data;
    },

    async resetUnreadChangelogCount() {
        await axios.post(`/api/changelogs/_reset`);
    },
};
