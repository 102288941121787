
import NumberBlacklistDataTable from "./NumberBlacklistDataTable.vue";
import NumberBlacklistFormDialog from "./NumberBlacklistFormDialog.vue";
import { numberBlacklistOverviewState } from "./numberBlacklistOverview";
import { ActorType, NumberBlacklistSearchOrder } from "@/api/numbersBlacklist";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            ActorType,
            numberBlacklistFormDialogVisible: false,
            NumberBlacklistSearchOrder,
            numberBlacklistOverviewState,
        };
    },

    computed: {
        defaultCountry(): string {
            return configStore.configuration.defaultCountry;
        },
    },

    methods: {
        hideNumberBlacklistFormDialogAndRefresh() {
            this.numberBlacklistFormDialogVisible = false;
            this.numberBlacklistOverviewState.refresh();
        },
    },

    components: {
        DatePicker,
        DealerGlobalContextHint,
        EnumField,
        NumberBlacklistDataTable,
        NumberBlacklistFormDialog,
        PhoneNumberField,
    },
});
