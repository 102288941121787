import { DealerAssignment, EmailAlias, Gender, PhoneNumber } from "@/api/users";
import {
    renderUserDealerPlaceholders,
    renderUserPlaceholders,
    userDealerPlaceholders,
    userPlaceholders,
} from "@/app/placeholderUtils";

export const smsSignatureTemplatePlaceholders = [...userPlaceholders, ...userDealerPlaceholders];

interface UserSmsSignatureTemplatePlaceholderInfo {
    readonly username: string | null;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly languages: string[];
    readonly mainDealerId: string | null;
    readonly phoneNumbers: PhoneNumber[];
    readonly dealerAssignments: DealerAssignment[];
    readonly emailAliases: EmailAlias[];
}

export function renderSmsSignatureTemplatePlaceholders(content: string, user: UserSmsSignatureTemplatePlaceholderInfo) {
    return [
        (content: string) => renderUserDealerPlaceholders(content, user),
        (content: string) => renderUserPlaceholders(content, user),
    ].reduce((content, fn) => fn(content), content);
}
