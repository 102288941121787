
import { CallerId, callerIdsApi } from "@/api/callerIds";
import { emailDomainsApi } from "@/api/emailDomains";
import { EmailSignatureTemplate, emailSignatureTemplateApi } from "@/api/emailSignatureTemplate";
import { BadRequest, Forbidden } from "@/api/errors";
import { SmsSignatureTemplate, smsSignatureTemplateApi } from "@/api/smsSignatureTemplate";
import { Permission } from "@/api/userSession";
import { DealerAssignment, Gender, PhoneNumberType, Role, User, UserForm, usersApi } from "@/api/users";
import BirthdayPicker from "@/app/components/BirthdayPicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { readTextAsFile } from "@/app/fileUtils";
import { showAlert, showConfirm, showError } from "@/app/messageUtil";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { renderEmailSignatureTemplatePlaceholders } from "@/app/pages/emailsignaturetemplate/emailSignatureTemplatePlaceholders";
import { renderSmsSignatureTemplatePlaceholders } from "@/app/pages/smssignaturetemplate/smsSignatureTemplatePlaceholders";
import { getFullName } from "@/app/userUtils";
import { e164, EMAIL_LOCAL_PART_REGEX, maxLength, notEmpty, regEx, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { departmentsStore } from "@/store/departments";
import { titleStore } from "@/store/title";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import { Mutable, SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";
import VImageInput from "vuetify-image-input";

interface DealerAssignmentWithDepartmentOptions extends DealerAssignment {
    departments?: SelectOptions;
}

const JPEG_DATA_URI_PREFIX = "data:image/jpeg;base64,";

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            Permission,
            validationHelper,
            emailDomainIds: [] as string[],
            saving: false,
            loading: true,
            emailSignatureTemplate: null as EmailSignatureTemplate | null,
            smsSignatureTemplate: null as SmsSignatureTemplate | null,
            renderingEmailSignatureTemplate: false,
            user: null as User | null,
            userId: null as string | null,

            // FORM FIELDS
            userForm: {
                username: "",
                roles: [],
                timeZone:
                    dealersStore.dealerById(userSession.dealerId || "")?.timeZone ||
                    configStore.configuration.defaultTimeZone,
                locale: configStore.configuration.defaultLocale,
                dateOfBirth: null,
                gender: null as Gender | null,
                namePrefix: null,
                givenName: "",
                familyName: "",
                employeeNumber: "",
                notes: "",
                emailSignature: "",
                smsSignature: "",
                languages: [configStore.configuration.defaultLocale.substring(0, 2)],
                emailPublishable: true,
                forwardEmails: false,
                mainDealerId: userSession.dealerId || "",
                phoneNumbers: [],
                dealerAssignments: [],
                defaultCallerId: null,
                terminalPhoneNumber: null,
                emailAliases: [],
                defaultSubstituteUserId: "",
            } as Mutable<UserForm>,
            profileImageFileFormat: "jpeg",
            profileImageUri: null as string | null,

            // OPTIONS
            callerIds: [] as CallerId[],

            // RULES
            usernameRules: notEmpty()
                .email()
                .and(validationHelper, "username"),
            employeeNumberRules: maxLength(63),
            mainDealerIdRules: notEmpty(),
            dealerIdRules: notEmpty(),
            positionRules: maxLength(300),
            departmentIdRules: notEmpty(),
            numberRules: notEmpty().e164(),
            labelRules: maxLength(300),
            textRules: maxLength(16383),
            Gender,
            namePrefixRules: maxLength(300),
            namePartRules: notEmpty().maxLength(300),
            terminalNumberRules: e164(),
            emailDomainIdRules: notEmpty(),
        };
    },

    computed: {
        callerIdOptions(): SelectOption[] {
            return this.callerIds.map((cid) => ({
                value: cid.phoneNumber,
                text: parseAndFormatNumber(cid.phoneNumber, "INTERNATIONAL")!,
            }));
        },

        defaultCountry(): string {
            return configStore.configuration.defaultCountry;
        },

        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        emailDomainOptions(): SelectOption[] {
            return this.emailDomainIds.map((k) => ({ value: k, text: `@${k}` }));
        },

        isDefaultSenderSelected(): boolean {
            return !!this.userForm.emailAliases.find((a) => a.defaultSender);
        },

        languageOptions(): SelectOption[] {
            return configStore.configuration.languages
                .map((k) => ({
                    value: k.code,
                    text: this.$t(`language.${k.code}`),
                }))
                .sort((a, b) => (a.text as string).localeCompare(b.text as string, userSession.locale));
        },

        localeOptions(): SelectOption[] {
            return this.$i18n.availableLocales.map((k) => ({
                value: k,
                text: this.$t(`locale.${k}`),
            }));
        },

        mainDealerOptions(): SelectOptions {
            return dealerOptions(
                (d) =>
                    userSession.hasPermission(Permission.USER_MANAGEMENT) ||
                    !!userSession.profile?.dealerIds.includes(d.id)
            );
        },

        phoneNumbers(): string[] {
            return [
                ...this.userForm.phoneNumbers.map((n) => n.number),
                ...this.userForm.dealerAssignments.reduce(
                    (prev, cur) => [...prev, ...cur.phoneNumbers.map((n) => n.number)],
                    [] as string[]
                ),
            ]
                .sort()
                .filter((n, index, array) => !!n && (!index || array[index - 1] !== n));
        },

        phoneNumberTypeOptions(): SelectOption[] {
            return Object.keys(PhoneNumberType).map((k) => ({
                value: k,
                text: this.$t(`enum.NumberType.${k}`),
            }));
        },

        roleOptions(): SelectOption[] {
            return Object.keys(Role).map((k) => ({
                value: k,
                text: this.$t(`enum.Role.${k}`),
            }));
        },

        substituteUsersOptions(): SelectOption[] {
            return [
                { value: "", text: this.$t("keine Vertretung") },
                ...usersStore.users.map((u) => ({ value: u.id, text: getFullName(u) })),
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        addDealerAssignment() {
            this.userForm.dealerAssignments.push({
                dealerId: "",
                phoneNumbers: [],
                departmentAssignments: [],
            });
        },

        addDealerAssignmentDepartmentAssignment(item: DealerAssignment) {
            item.departmentAssignments.push({
                departmentId: "",
                position: "",
            });
        },

        addDealerAssignmentPhoneNumber(item: DealerAssignment) {
            item.phoneNumbers.push({
                type: PhoneNumberType.LANDLINE,
                publishable: true,
                number: "",
                label: "",
            });
        },

        addEmailAlias() {
            this.userForm.emailAliases.push({
                localPart: "",
                emailDomainId: "",
                defaultSender: !this.isDefaultSenderSelected,
                defaultReplyTo: false,
            });
        },

        addPhoneNumber() {
            this.userForm.phoneNumbers.push({
                type: PhoneNumberType.LANDLINE,
                publishable: true,
                number: "",
                label: "",
            });
        },

        getPhoneNumberTypeIcon(phoneNumberType: PhoneNumberType) {
            if (phoneNumberType === PhoneNumberType.MOBILE) {
                return "mdi-cellphone";
            } else if (phoneNumberType === PhoneNumberType.LANDLINE) {
                return "mdi-deskphone";
            } else if (phoneNumberType === PhoneNumberType.FAX) {
                return "mdi-fax";
            }

            return "mdi-phone-classic";
        },

        goToFirstError() {
            this.$nextTick(() => {
                try {
                    this.$vuetify.goTo(".error--text");
                } catch (e) {
                    // ignore
                }
            });
        },

        localPartRules(index: number) {
            return regEx(EMAIL_LOCAL_PART_REGEX)
                .msg(() => this.$t("Gültige E-Mail-Adresse ist erforderlich"))
                .and(this.validationHelper, `aliases[${index}]`);
        },

        async redirectToUsersOverview() {
            await this.$router.push("/users");
        },

        removeDealerAssignment(index: number) {
            this.userForm.dealerAssignments.splice(index, 1);
        },

        removeDealerAssignmentDepartmentAssignment(item: DealerAssignment, index: number) {
            item.departmentAssignments.splice(index, 1);
        },

        removeDealerAssignmentPhoneNumber(item: DealerAssignment, index: number) {
            item.phoneNumbers.splice(index, 1);
        },

        removeEmailAlias(index: number) {
            this.userForm.emailAliases.splice(index, 1);

            if (!this.isDefaultSenderSelected && this.userForm.emailAliases.length) {
                this.userForm.emailAliases[0].defaultSender = true;
            }
        },

        removePhoneNumber(index: number) {
            this.userForm.phoneNumbers.splice(index, 1);
        },

        setDefaultReplyTo(index: number) {
            const current = this.userForm.emailAliases[index].defaultReplyTo;
            for (const alias of this.userForm.emailAliases) {
                alias.defaultReplyTo = false;
            }
            this.userForm.emailAliases[index].defaultReplyTo = !current;
        },

        setDefaultSender(index: number) {
            for (const alias of this.userForm.emailAliases) {
                alias.defaultSender = false;
            }
            this.userForm.emailAliases[index].defaultSender = true;
        },

        async setEmailSignatureToEmailSignatureTemplate() {
            if (!this.emailSignatureTemplate || !this.emailSignatureTemplate.content) {
                return;
            }

            try {
                this.renderingEmailSignatureTemplate = true;
                this.userForm.emailSignature = await renderEmailSignatureTemplatePlaceholders(
                    this.emailSignatureTemplate.content,
                    {
                        id: this.user?.id ?? null,
                        profileImageHash: this.user?.profileImageHash || null,
                        ...this.userForm,
                    }
                );
            } finally {
                this.renderingEmailSignatureTemplate = false;
            }
        },

        setSmsSignatureToSmsSignatureTemplate() {
            if (!this.smsSignatureTemplate || !this.smsSignatureTemplate.content) {
                return;
            }

            this.userForm.smsSignature = renderSmsSignatureTemplatePlaceholders(
                this.smsSignatureTemplate.content,
                this.userForm
            );
        },

        async submitForm() {
            try {
                if (this.user) {
                    await this.submitFormEditUser();
                } else {
                    await this.submitFormCreateUser();
                }
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }

                this.validationHelper.update(e, this.$refs.form);
                this.goToFirstError();
            }
        },

        async submitFormCreateUser() {
            if (!!this.user || !(this.$refs.form as any).validate()) {
                this.goToFirstError();
                return;
            }

            this.saving = true;
            try {
                const userCreationResult = await usersApi.add(
                    this.userForm,
                    this.profileImageUri
                        ? await readTextAsFile(this.profileImageUri, "profile-image." + this.profileImageFileFormat)
                        : null,
                    () => null
                );

                if (
                    await showConfirm(
                        this.$t("Benutzer erfolgreich erstellt!") as string,
                        this.$t(
                            "Möchten Sie dem Benutzer eine E-Mail mit dem Link zum Setzen seines Passworts senden?"
                        ) as string
                    )
                ) {
                    await usersApi.resetPassword(userCreationResult.id);

                    await showAlert(
                        this.$t("E-Mail wurde versandt") as string,
                        this.$t("Dem Benutzer wurde eine E-Mail zum Neusetzen des Passworts geschickt.") as string
                    );
                }

                await this.$router.push("/users");
            } catch (e) {
                if (!(e instanceof Forbidden)) {
                    throw e;
                }
                showError(
                    this.$t("Sie haben nicht die Berechtigung, den Benutzer mit diesen Rollen anzulegen.") as string
                );

                return;
            } finally {
                this.saving = false;
            }
        },

        async submitFormEditUser() {
            if (!this.user || !(this.$refs.form as any).validate()) {
                this.goToFirstError();
                return;
            }

            this.saving = true;
            try {
                await usersApi.edit(
                    this.user.id,
                    this.userForm,
                    this.profileImageUri
                        ? await readTextAsFile(this.profileImageUri, "profile-image." + this.profileImageFileFormat)
                        : null,
                    () => null
                );

                await this.$router.push("/users");
            } catch (e) {
                if (!(e instanceof Forbidden)) {
                    throw e;
                }
                showError(
                    this.$t("Sie haben nicht die Berechtigung, die Benutzereinstellungen zu verändern.") as string
                );

                return;
            } finally {
                this.saving = false;
            }
        },

        async switchDealer(item: DealerAssignmentWithDepartmentOptions) {
            item.departmentAssignments = [];
            item.departments = departmentsStore.departmentsByDealer(item.dealerId).map((d) => ({
                value: d.id,
                text: d.name,
            }));
        },
    },

    watch: {
        async "userForm.mainDealerId"() {
            this.emailSignatureTemplate = null;
            this.smsSignatureTemplate = null;

            if (this.userForm.mainDealerId) {
                this.emailSignatureTemplate = await emailSignatureTemplateApi.getByDealer(this.userForm.mainDealerId);
                this.smsSignatureTemplate = await smsSignatureTemplateApi.getByDealer(this.userForm.mainDealerId);
            }
        },
    },

    async mounted() {
        try {
            this.callerIds = (await callerIdsApi.getCallerIds()).filter((cid) => !cid.internal);
            this.emailDomainIds = await emailDomainsApi.getAllIds();

            this.userId = trimAndReturnNullIfEmpty(this.$route.params.userid);

            if (this.userId) {
                this.user = await usersApi.getById(this.userId);
            }

            if (this.user) {
                titleStore.title = this.$t("Benutzer bearbeiten") as string;

                this.userForm = {
                    username: this.user.username,
                    roles: cloneObject(this.user.roles),
                    timeZone: this.user.timeZone,
                    locale: this.user.locale,
                    dateOfBirth: this.user.dateOfBirth,
                    gender: this.user.gender,
                    namePrefix: this.user.namePrefix,
                    givenName: this.user.givenName,
                    familyName: this.user.familyName,
                    employeeNumber: this.user.employeeNumber,
                    notes: this.user.notes,
                    emailSignature: this.user.emailSignatureHash
                        ? await usersApi.getEmailSignatureByHash(this.user.id, this.user.emailSignatureHash)
                        : null,
                    smsSignature: this.user.smsSignature,
                    languages: cloneObject(this.user.languages),
                    emailPublishable: this.user.emailPublishable,
                    forwardEmails: this.user.forwardEmails,
                    mainDealerId: this.user.mainDealerId,
                    phoneNumbers: cloneObject(this.user.phoneNumbers),
                    dealerAssignments:
                        cloneObject(this.user.dealerAssignments).map((item) => ({
                            ...item,
                            departments: departmentsStore.departmentsByDealer(item.dealerId).map((d) => ({
                                value: d.id,
                                text: d.name,
                            })),
                        })) || ([] as DealerAssignmentWithDepartmentOptions[]),
                    defaultCallerId: this.user.defaultCallerId,
                    terminalPhoneNumber: this.user.terminalPhoneNumber,
                    emailAliases: cloneObject(this.user.emailAliases),
                    defaultSubstituteUserId: this.user.defaultSubstituteUserId,
                };

                if (this.user.profileImageHash) {
                    this.profileImageUri =
                        JPEG_DATA_URI_PREFIX +
                        (await usersApi.getProfileImageByHash(this.user.id, this.user.profileImageHash));
                }
            } else {
                titleStore.title = this.$t("Benutzer hinzufügen") as string;

                this.addDealerAssignment();
                this.userForm.dealerAssignments[0].dealerId = userSession.dealerId || "";
                await this.switchDealer(this.userForm.dealerAssignments[0]);
            }

            if (this.userForm.mainDealerId) {
                this.emailSignatureTemplate = await emailSignatureTemplateApi.getByDealer(this.userForm.mainDealerId);
                this.smsSignatureTemplate = await smsSignatureTemplateApi.getByDealer(this.userForm.mainDealerId);
            }
        } finally {
            this.loading = false;
        }
    },

    components: {
        BirthdayPicker,
        DealerContextGuard,
        EnumField,
        PhoneNumberField,
        TimeZonePicker,
        VImageInput,
        WysiwygEditor: () => import("@/app/components/WysiwygEditor.vue"),
    },
});
