import axios from "axios";

export interface IncomingEmailSettings {
    catchAllSystemEmailAliasId: string | null;
    catchAllUserEmailEscalationGroupId: string | null;
    forwardingAddress: string | null;
    excludedFileExtensions: string[];
    manualUserEmailDispatching: boolean | null;
}

interface IncomingEmailSettingsApi {
    getIncomingEmailSettings(): Promise<IncomingEmailSettings>;
    editIncomingEmailSettings(incomingEmailSettings: IncomingEmailSettings): Promise<void>;
}

export const incomingEmailSettingsApi: IncomingEmailSettingsApi = {
    async getIncomingEmailSettings() {
        return (await axios.get("/api/incoming-email-settings")).data;
    },

    async editIncomingEmailSettings(incomingEmailSettings) {
        await axios.put("/api/incoming-email-settings", incomingEmailSettings);
    },
};
