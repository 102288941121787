import axios from "axios";

interface InventoryVehicleDuplicatesApi {
    removeDuplicatesOfAllInventoryVehicles(): Promise<void>;
    removeDuplicatesOfInventoryVehiclesWithoutSource(): Promise<void>;
}

export const inventoryVehicleDuplicatesApi: InventoryVehicleDuplicatesApi = {
    async removeDuplicatesOfAllInventoryVehicles() {
        await axios.post("/api/inventory-vehicle-duplicates/_remove-duplicates-of-all-inventory-vehicles");
    },

    async removeDuplicatesOfInventoryVehiclesWithoutSource() {
        await axios.post("/api/inventory-vehicle-duplicates/_remove-duplicates-of-inventory-vehicles-without-source");
    },
};
