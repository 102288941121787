import { getReceiverViews, OutgoingEmailReceiverView } from "./outgoingEmailReceiver";
import { IssueId, OutgoingEmail, TransactionEmailReceiverType } from "@/api/outgoingEmails";
import { $t } from "@/app/i18n";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";

export function getAttachments(outgoingEmail: OutgoingEmail) {
    return outgoingEmail.attachments.filter((a) => !a.contentId);
}

export function getBccReceivers(outgoingEmail: OutgoingEmail): OutgoingEmailReceiverView[] {
    return getReceiverViews(outgoingEmail.receivers, TransactionEmailReceiverType.BCC, outgoingEmail.events);
}

export function getCcReceivers(outgoingEmail: OutgoingEmail): OutgoingEmailReceiverView[] {
    return getReceiverViews(outgoingEmail.receivers, TransactionEmailReceiverType.CC, outgoingEmail.events);
}

export function getCreator(outgoingEmail: OutgoingEmail): string {
    if (outgoingEmail.initiatorCtUserId) {
        return $t("Externer Agent") as string;
    } else if (outgoingEmail.initiatorUserId) {
        return (getFullName(usersStore.getUserById(outgoingEmail.initiatorUserId)) || $t("Unbekannt")) as string;
    } else {
        return $t("System") as string;
    }
}

export function getIssueId(outgoingEmail: OutgoingEmail): IssueId | null {
    if (outgoingEmail.caseId) {
        return { caseId: outgoingEmail.caseId };
    } else if (outgoingEmail.opportunityId) {
        return { opportunityId: outgoingEmail.opportunityId };
    } else {
        return null;
    }
}

export function getToReceivers(outgoingEmail: OutgoingEmail): OutgoingEmailReceiverView[] {
    return getReceiverViews(outgoingEmail.receivers, TransactionEmailReceiverType.TO, outgoingEmail.events);
}
