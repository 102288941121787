import navigation, { NavigationFolder, NavigationItem, NavigationNode } from "./navigation";
import Login from "./pages/Login.vue";
import MainFrame from "./pages/MainFrame.vue";
import NotFound from "./pages/NotFound.vue";
import ResetPassword from "./pages/ResetPassword.vue";
import UnsubscribeBroadcastEmails from "./pages/UnsubscribeBroadcastEmails.vue";
import UserNotificationSettingsForm from "./pages/UserNotificationSettingsForm.vue";
import MyAbsences from "./pages/absences/MyAbsences.vue";
import MyAnnouncements from "./pages/announcements/MyAnnouncements.vue";
import SinglePageCarryDispoAssistant from "./pages/carrydispo/assistant/SinglePageCarryDispoAssistant.vue";
import CaseDetailPage from "./pages/cases/CaseDetailPage.vue";
import SoftwareUpdates from "./pages/changelogs/SoftwareUpdates.vue";
import ContactDetailPage from "./pages/contacts/ContactDetailPage.vue";
import EmergencyIncidentDetailPage from "./pages/emergencyincidents/EmergencyIncidentDetailPage.vue";
import SinglePageEmergencyIncidentAssistant from "./pages/emergencyincidents/assistant/SinglePageEmergencyIncidentAssistant.vue";
import IncomingEmailDetailPage from "./pages/incomingemails/IncomingEmailDetailPage.vue";
import InventoryVehicleInquiryAssistant from "./pages/inventoryvehicleinquiry/assistant/InventoryVehicleInquiryAssistant.vue";
import OpportunityDetailPage from "./pages/opportunities/OpportunityDetailPage.vue";
import OutgoingCallAssistant from "./pages/outgoingcalls/assistant/OutgoingCallAssistant.vue";
import OutgoingEmailDetailPage from "./pages/outgoingemails/OutgoingEmailDetailPage.vue";
import OutgoingEmailAssistant from "./pages/outgoingemails/assistant/OutgoingEmailAssistant.vue";
import OutgoingSmsAssistant from "./pages/outgoingsms/assistant/OutgoingSmsAssistant.vue";
import StatusDashboard from "./pages/statusdashboard/StatusDashboard.vue";
import CaseAction from "./pages/useractionlink/CaseAction.vue";
import OpportunityAction from "./pages/useractionlink/OpportunityAction.vue";
import PasswordAction from "./pages/useractionlink/PasswordAction.vue";
import PasswordForm from "./pages/users/PasswordForm.vue";
import UserFormPage from "./pages/users/UserFormPage.vue";
import UserProfileForm from "./pages/users/UserProfileForm.vue";
import VideochatDetailPage from "./pages/videochats/VideochatDetailPage.vue";
import VideochatLandingPage from "./pages/videochats/VideochatLandingPage.vue";
import WorkingHoursManagement from "./pages/workinghours/WorkingHoursManagement.vue";
import { Permission } from "@/api/userSession";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";
import VueRouter, { NavigationGuard, RouteConfig } from "vue-router";

Vue.use(VueRouter);

function checkPermissionsNavigationGuard(permissions: Permission[]) {
    return ((_, __, next) => {
        if ((userSession.permissions || []).some((p) => permissions.includes(p))) {
            next();
        } else {
            next("/404");
        }
    }) as NavigationGuard;
}

function toRouterConfig(nodes: NavigationNode[]): RouteConfig[] {
    return [
        ...nodes
            .filter((n): n is NavigationItem => n instanceof NavigationItem)
            .map((i) => {
                return {
                    path: i.path,
                    component: i.component,
                    props: i.props,
                    meta: { title: i.title },
                    beforeEnter: ((_, __, next) => {
                        if (i.isVisible) {
                            next();
                        } else {
                            next("/404");
                        }
                    }) as NavigationGuard,
                };
            }),
        ...nodes
            .filter((n): n is NavigationFolder => n instanceof NavigationFolder)
            .map((f) => toRouterConfig(f.items))
            .reduce((p, c) => p.concat(c), []),
    ];
}

const $t = (s: string) => s;

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        { path: "/widgets", component: () => import("@/widgets/Playground.vue") },
        {
            path: "/v/:videochatid",
            component: VideochatLandingPage,
            meta: { title: $t("Videochat") },
            beforeEnter: (_, __, next) => (configStore.configuration.videochatConfigured ? next() : next(false)),
        },
        { path: "/unsubscribe/:userid", component: UnsubscribeBroadcastEmails, meta: { title: $t("Abmelden") } },
        { path: "/login", component: Login, meta: { title: $t("Login") } },
        { path: "/reset-password", component: ResetPassword, meta: { title: $t("Passwort zurücksetzen") } },
        {
            path: "/status-dashboard",
            component: StatusDashboard,
            meta: { title: $t("Status Dashboard") },
        },
        {
            path: "/case-action",
            component: CaseAction,
            meta: { title: $t("Fall") },
        },
        {
            path: "/opportunity-action",
            component: OpportunityAction,
            meta: { title: $t("Verkaufschance") },
        },
        {
            path: "/password-action",
            component: PasswordAction,
            meta: { title: $t("Passwort ändern") },
        },
        {
            path: "/",
            component: MainFrame,
            meta: { title: $t("Partition") },
            children: [
                ...toRouterConfig(navigation),
                {
                    path: "user/:userid?",
                    component: UserFormPage,
                    beforeEnter: checkPermissionsNavigationGuard([
                        Permission.USER_MANAGEMENT,
                        Permission.MANAGE_DEALER_USERS,
                    ]),
                    meta: { title: $t("Benutzer") },
                },
                {
                    path: "contact/:contactid/vehicle/:contactvehicleid",
                    component: ContactDetailPage,
                    meta: { title: $t("Kontakt") },
                },
                { path: "contact/:contactid/:tab", component: ContactDetailPage, meta: { title: $t("Kontakt") } },
                { path: "case/:caseid", component: CaseDetailPage, meta: { title: $t("Fall") } },
                {
                    path: "videochat/:videochatid",
                    component: VideochatDetailPage,
                    meta: { title: $t("Videochat") },
                    beforeEnter: (_, __, next) =>
                        configStore.configuration.videochatConfigured ? next() : next(false),
                },
                {
                    path: "carry-dispo-assistant",
                    component: SinglePageCarryDispoAssistant,
                    meta: { title: $t("CARRY Dispo Auftrags-Assistent") },
                    beforeEnter: checkPermissionsNavigationGuard([Permission.MANAGE_CARRY_DISPO_ORDERS]),
                },
                {
                    path: "emergency-incident-assistant",
                    component: SinglePageEmergencyIncidentAssistant,
                    beforeEnter: checkPermissionsNavigationGuard([Permission.MANAGE_EMERGENCY_INCIDENTS]),
                    meta: { title: $t("Notdienstvorgangs-Assistent") },
                },
                {
                    path: "outgoing-call-assistant",
                    component: OutgoingCallAssistant,
                    beforeEnter: checkPermissionsNavigationGuard([Permission.MANAGE_OWN_ACTIVITIES]),
                    meta: { title: $t("Neuer Anruf") },
                },
                {
                    path: "outgoing-email-assistant",
                    component: OutgoingEmailAssistant,
                    beforeEnter: checkPermissionsNavigationGuard([Permission.MANAGE_OWN_ACTIVITIES]),
                    meta: { title: $t("Neue E-Mail") },
                },
                {
                    path: "outgoing-sms-assistant",
                    component: OutgoingSmsAssistant,
                    beforeEnter: checkPermissionsNavigationGuard([Permission.MANAGE_OWN_ACTIVITIES]),
                    meta: { title: $t("Neue SMS") },
                },
                {
                    path: "inventory-vehicle-inquiry-assistant",
                    component: InventoryVehicleInquiryAssistant,
                    meta: { title: $t("Suchauftrags-Assistent") },
                },
                {
                    path: "inventory-vehicle-inquiry/:inventoryvehicleinquiryid",
                    component: InventoryVehicleInquiryAssistant,
                    beforeEnter: checkPermissionsNavigationGuard([
                        Permission.MANAGE_OWN_INVENTORY_VEHICLE_INQUIRIES,
                        Permission.MANAGE_ALL_INVENTORY_VEHICLE_INQUIRIES,
                    ]),
                    meta: { title: $t("Suchauftrag") },
                },
                {
                    path: "emergency-incident/:emergencyincidentid",
                    component: EmergencyIncidentDetailPage,
                    beforeEnter: checkPermissionsNavigationGuard([Permission.MANAGE_EMERGENCY_INCIDENTS]),
                    meta: { title: $t("Notdienstvorgang") },
                },
                {
                    path: "incoming-email/:incomingemailid",
                    component: IncomingEmailDetailPage,
                    beforeEnter: checkPermissionsNavigationGuard([Permission.MANAGE_INCOMING_EMAILS]),
                    meta: { title: $t("Eingehende E-Mail") },
                },
                {
                    path: "outgoing-email/:outgoingemailid",
                    component: OutgoingEmailDetailPage,
                    beforeEnter: checkPermissionsNavigationGuard([
                        Permission.MANAGE_OUTGOING_EMAILS,
                        Permission.MANAGE_OWN_ACTIVITIES,
                    ]),
                    meta: { title: $t("Ausgehende E-Mail") },
                },
                {
                    path: "opportunity/:opportunityid",
                    component: OpportunityDetailPage,
                    meta: { title: $t("Verkaufschance") },
                },
                { path: "my-absences", component: MyAbsences, meta: { title: $t("Abwesenheiten") } },
                { path: "software-updates", component: SoftwareUpdates, meta: { title: $t("Software-Updates") } },
                { path: "password-form", component: PasswordForm, meta: { title: $t("Passwort ändern") } },
                { path: "user-profile-form", component: UserProfileForm, meta: { title: $t("Profil bearbeiten") } },
                {
                    path: "user-notification-settings",
                    component: UserNotificationSettingsForm,
                    meta: { title: $t("Benachrichtigungen") },
                },
                {
                    path: "working-hours-management",
                    component: WorkingHoursManagement,
                    meta: { title: $t("Arbeitszeiten") },
                },
                { path: "my-announcements", component: MyAnnouncements, meta: { title: $t("Ankündigungen") } },
                { path: "404", component: NotFound },
                { path: "*", component: NotFound },
            ],
            beforeEnter(to, _, next) {
                if (!userSession.permissions) {
                    next(`/login?d=${encodeURIComponent(to.fullPath)}`);
                } else if (to.path === "/") {
                    if (userSession.isCtUser()) {
                        next("/location-details");
                    } else {
                        next("/dashboard");
                    }
                } else {
                    next();
                }
            },
        },
    ],
});

router.beforeEach((to, _, next) => {
    if (configStore.outdated) {
        window.location.assign(to.fullPath);
        next(false);
    } else {
        next();
    }
});

export default router;
