
import { ContactSearchResult } from "@/api/contactSearch";
import {
    ContactAndVehicleId,
    renderContactCaption,
    renderPhoneNumberTypeIcon,
    renderVehicleTitle,
} from "@/app/contactUtils";
import PhoneNumberLink from "@/app/pages/PhoneNumberLink.vue";
import { ContactSearchResultIssueSummary, ContactSearchResultIssueType } from "@/app/pages/contacts/contactSearchUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canSelectContactVehicle: {
            type: Boolean,
            default: false,
        },
        canSelectEmailAddress: {
            type: Boolean,
            default: false,
        },
        canSelectPhoneNumber: {
            type: Boolean,
            default: false,
        },
        contactSearchResult: {
            type: Object as () => ContactSearchResult,
            required: true,
        },
        issueSummaries: {
            type: Array as () => ContactSearchResultIssueSummary[],
            default: () => [],
        },
    },

    data() {
        return {
            renderContactCaption,
            renderPhoneNumberTypeIcon,
            renderVehicleTitle,
        };
    },

    computed: {
        issueSummariesSortedByLatestActivityDesc(): ContactSearchResultIssueSummary[] {
            return [...this.issueSummaries].sort(
                (a, b) => (b.lastActivity?.getTime() ?? 0) - (a.lastActivity?.getTime() ?? 0)
            );
        },

        latestIssueRoute(): string | null {
            return this.getIssueRoute(
                this.issueSummariesSortedByLatestActivityDesc.find(
                    (i) => !(this.canSelectContactVehicle && i.contactVehicleId)
                )
            );
        },
    },

    methods: {
        getIssueRoute(issueSummary?: ContactSearchResultIssueSummary): string | null {
            if (!issueSummary) {
                return null;
            }

            if (issueSummary.issueType === ContactSearchResultIssueType.CASE) {
                return `/case/${issueSummary.id}`;
            } else if (issueSummary.issueType === ContactSearchResultIssueType.OPPORTUNITY) {
                return `/opportunity/${issueSummary.id}`;
            } else {
                return null;
            }
        },

        getLatestIssueRouteByContactVehicle(contactVehicleId: string): string | null {
            return this.getIssueRoute(
                this.issueSummariesSortedByLatestActivityDesc.find((i) => i.contactVehicleId === contactVehicleId)
            );
        },

        select({
            contactVehicleId,
            emailAddress,
            number,
        }: {
            contactVehicleId?: string | null;
            emailAddress?: string | null;
            number?: string | null;
        }) {
            const contactAndVehicleId: ContactAndVehicleId = {
                contactId: this.contactSearchResult.id,
                contactVehicleId: contactVehicleId
                    ? contactVehicleId
                    : this.canSelectContactVehicle && this.contactSearchResult.contactVehicleSearchResults.length === 1
                    ? this.contactSearchResult.contactVehicleSearchResults[0].id
                    : null,
            };

            this.$emit("select:contact-and-vehicle", contactAndVehicleId);

            if (emailAddress) {
                this.$emit("select:email-address", emailAddress);
            }

            if (number) {
                this.$emit("select:phone-number", number);
            }
        },
    },

    components: {
        PhoneNumberLink,
    },
});
