
import { IncomingCallStatus } from "@/api/incomingCalls";
import { CallStatus } from "@/api/outgoingCalls";
import { Permission } from "@/api/userSession";
import { AwayType, UserDirectoryEntry } from "@/api/users";
import UserLink from "@/app/pages/UserLink.vue";
import { configStore } from "@/store/config";
import { incomingCallsStore } from "@/store/incomingCalls";
import { outgoingCallsStore } from "@/store/outgoingCalls";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        userId: {
            type: String,
            required: true,
        },
        position: {
            type: String,
            required: false,
        },
    },

    computed: {
        isRinging(): boolean {
            // cant be ringing if is in call
            if (this.isInCall) {
                return false;
            }

            // check if outgoing call is ringing
            const outgoingCall = outgoingCallsStore.outgoingCallByInitiator(this.userId);
            if (!!outgoingCall && outgoingCall.callStatus === CallStatus.RINGING_INBOUND) {
                return true;
            }

            // check if incoming call is ringing
            const incomingCall = incomingCallsStore.incomingCallByRingingUserOrAcceptor(this.userId);
            if (!!incomingCall && incomingCall.incomingCallStatus === IncomingCallStatus.RINGING) {
                return true;
            }

            return false;
        },

        isInCall(): boolean {
            // check if outgoing call is in progress
            const outgoingCall = outgoingCallsStore.outgoingCallByInitiator(this.userId);
            if (
                !!outgoingCall &&
                (outgoingCall.callStatus === CallStatus.RINGING_INBOUND ||
                    outgoingCall.callStatus === CallStatus.CONNECTING ||
                    outgoingCall.callStatus === CallStatus.IN_PROGRESS)
            ) {
                return true;
            }

            // check if incoming call is in progress
            const incomingCall = incomingCallsStore.incomingCallByRingingUserOrAcceptor(this.userId);
            if (!!incomingCall && incomingCall.incomingCallStatus === IncomingCallStatus.IN_PROGRESS) {
                return true;
            }

            return false;
        },

        user(): UserDirectoryEntry | null {
            return usersStore.getUserById(this.userId);
        },

        presenceIcon(): string | null {
            if (!this.user) {
                return null;
            }

            if (this.user.status.absent) {
                return "mdi-calendar-remove";
            } else if (this.user.status.withinWorkingHours === null) {
                return "mdi-alert";
            } else if (this.user.status.workingHoursConfirmedCurrentWeek) {
                return "mdi-briefcase-check";
            } else {
                return "mdi-briefcase";
            }
        },

        presenceIconColor(): string | null {
            if (!this.user) {
                return null;
            }

            if (this.user.status.absent) {
                return "error";
            } else if (this.user.status.withinWorkingHours === null) {
                return "warning";
            } else if (this.user.status.withinWorkingHours) {
                return "success";
            } else {
                return "error";
            }
        },

        presenceTooltip(): string | null {
            if (!this.user) {
                return null;
            }

            if (this.user.status.absent) {
                return this.$t("abwesend") as string;
            } else if (this.user.status.withinWorkingHours === null) {
                return this.$t("keine Arbeitszeiten hinterlegt") as string;
            } else if (this.user.status.withinWorkingHours) {
                if (this.user.status.workingHoursConfirmedCurrentWeek) {
                    return this.$t("innerhalb der Arbeitszeit (bestätigt)") as string;
                } else {
                    return this.$t("innerhalb der Arbeitszeit (unbestätigt)") as string;
                }
            } else {
                if (this.user.status.workingHoursConfirmedCurrentWeek) {
                    return this.$t("außerhalb der Arbeitszeit (bestätigt)") as string;
                } else {
                    return this.$t("außerhalb der Arbeitszeit (unbestätigt)") as string;
                }
            }
        },

        statusIcon(): string | null {
            if (!this.user) {
                return null;
            }

            if (!this.user.status.online) {
                return "mdi-circle-outline";
            } else if (this.user.away) {
                return "mdi-circle";
            }

            return "mdi-circle";
        },

        statusIconColor(): string | null {
            if (!this.user) {
                return null;
            }

            if (!this.user.status.online) {
                return "";
            } else if (this.user.away) {
                return "warning";
            }

            return "success";
        },

        statusIconTooltip(): string | null {
            if (!this.user) {
                return null;
            }

            if (!this.user.status.online) {
                if (this.user.lastActive && this.isLastActiveVisible) {
                    const ts = formatInstant(this.user.lastActive, userSession.timeZone, userSession.locale, "S");
                    return `${this.$t("offline")} (${this.$t("zuletzt online am {0}", [ts])})`;
                }
                return this.$t("offline") as string;
            } else if (this.user.away) {
                let awayLabel: string;

                if (this.user.away.type === AwayType.OTHER) {
                    if (this.user.away.notes) {
                        awayLabel = this.user.away.notes;
                    } else {
                        awayLabel = this.$t("abwesend") as string;
                    }
                } else {
                    if (this.user.away.notes) {
                        awayLabel = `${this.$t(`enum.AwayType.${this.user.away.type}`)} / ${this.user.away.notes}`;
                    } else {
                        awayLabel = this.$t(`enum.AwayType.${this.user.away.type}`) as string;
                    }
                }

                if (this.user.away.until) {
                    const ts = formatInstant(this.user.away.until, userSession.timeZone, userSession.locale, "S");
                    awayLabel += ` (${this.$t("bis {0}", [ts])})`;
                }

                return awayLabel;
            }

            return this.$t("online") as string;
        },

        isLastActiveVisible(): boolean {
            return (
                userSession.hasPermission(Permission.USER_MANAGEMENT) ||
                (userSession.hasPermission(Permission.MANAGE_DEALER_USERS) &&
                    userSession.mainDealerId === this.user?.mainDealerId)
            );
        },

        canShowVideochatStatus(): boolean {
            return configStore.configuration.videochatConfigured;
        },
    },

    components: {
        UserLink,
    },
});
