
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import UserPickerCard from "@/app/pages/users/UserPickerCard.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            expanded: !!this.caseAssistantState.individualReceivers.userIds.length,
        };
    },

    components: {
        UserPickerCard,
    },
});
