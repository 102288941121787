const GSM_SPECIAL_CHARS = "|^€{}[]~\\";
const GSM_CHARS =
    " \n\r!\"#$%'()*+,-./:;<=>?@_¡£¥§¿&¤0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzÄÅÆÇÉÑØøÜßÖàäåæèéìñòöùüΔΦΓΛΩΠΨΣΘΞ" +
    GSM_SPECIAL_CHARS;

export function getNumberOfMessages(msg: string) {
    msg = msg.trim();
    if (!msg) {
        return 0;
    }
    let unicode = false;
    for (const c of msg) {
        if (!GSM_CHARS.includes(c)) {
            unicode = true;
            break;
        }
    }
    let len = msg.length;
    if (unicode) {
        if (len <= 70) {
            return 1;
        }
        return Math.ceil(len / (70 - 7));
    }
    for (const c of msg) {
        if (GSM_SPECIAL_CHARS.includes(c)) {
            len += 1;
        }
    }
    if (len <= 160) {
        return 1;
    }
    return Math.ceil(len / (160 - 7));
}
