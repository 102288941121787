import axios from "axios";

export interface SmsSignatureTemplate {
    dealerId: string;
    content: string | null;
}

interface SmsSignatureTemplateApi {
    editSmsSignatureTemplate(smsSignatureTemplate: SmsSignatureTemplate): Promise<void>;
    getByDealer(dealerId: string): Promise<SmsSignatureTemplate>;
}

export const smsSignatureTemplateApi: SmsSignatureTemplateApi = {
    async editSmsSignatureTemplate(smsSignatureTemplate) {
        await axios.post("/api/sms-signature-template", smsSignatureTemplate);
    },

    async getByDealer(dealerId: string) {
        return (await axios.get(`/api/sms-signature-template/${dealerId}`)).data;
    },
};
