
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import FileDropZone from "@/app/components/FileDropZone.vue";
import { fileDialog } from "@/app/fileUtils";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: {
            type: Array as () => File[],
            required: true,
        },
    },

    computed: {
        acceptedFileTypes(): string[] {
            return configStore.configuration.whitelistedOutgoingFileExtensions;
        },
    },

    methods: {
        showFileDialog() {
            fileDialog(true, this.addFiles, this.acceptedFileTypes);
        },

        addFiles(files: File[]) {
            for (const file of files) {
                this.value.push(file);
            }

            this.$emit("input", this.value);
        },

        deleteFile(index: number) {
            this.value.splice(index, 1);
            this.$emit("input", this.value);
        },
    },

    components: {
        DToolbarCard,
        FileDropZone,
    },
});
