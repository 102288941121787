
import { autoCrmLeadMailAssistantState } from "./autoCrmLeadMailAssistantState";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            autoCrmLeadMailAssistantState,
            notEmpty,
        };
    },
});
