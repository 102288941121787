import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface ReadReceipt {
    readonly userId: string;
    readonly firstSeen: Date;
    readonly lastSeen: Date;
}

interface ReadReceiptsApi {
    getReadReceiptsByCase(caseId: string): Promise<ReadReceipt[]>;
    getReadReceiptsByOpportunity(opportunityId: string): Promise<ReadReceipt[]>;
}

export const readReceiptsApi: ReadReceiptsApi = {
    async getReadReceiptsByCase(caseId) {
        return cloneObject((await axios.get(`/api/read-receipts/cases/${caseId}`)).data);
    },

    async getReadReceiptsByOpportunity(opportunityId) {
        return cloneObject((await axios.get(`/api/read-receipts/opportunities/${opportunityId}`)).data);
    },
};
