
import { OfficeHours, OfficeHoursEntry } from "@/api/officeHours";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate, getDateByDayOfWeek, getDaysOfWeek, getWeek, toDateObject, Week } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

interface OfficeHoursEntriesWithException {
    readonly entries: OfficeHoursEntry[];
    readonly exceptionReason: string | null;
}

export default Vue.extend({
    props: {
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideHeaderChip: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideIcon: {
            type: Boolean,
            required: false,
            default: false,
        },
        officeHours: {
            type: Object as () => OfficeHours | null,
            required: true,
        },
        showHeader: {
            type: String,
            required: false,
        },
        showWeekSwitcher: {
            type: Boolean,
            required: false,
            default: false,
        },
        timeZone: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            weekOffset: 0,
        };
    },

    computed: {
        daysOfWeek(): SelectOption[] {
            return getDaysOfWeek(configStore.configuration.defaultLocale, userSession.locale);
        },

        hasCurrentlyOpen(): boolean {
            if (!this.todaysDayOfWeek) {
                return false;
            }

            const officeHoursEntries = this.getEntriesForDayOfWeek(Number.parseInt(this.todaysDayOfWeek.value!));

            if (!officeHoursEntries.entries.length) {
                return false;
            }

            const today = getDate(now(), this.timeZone);
            const time = now().getTime();

            return officeHoursEntries.entries.reduce((hasOpen: boolean, entry: OfficeHoursEntry) => {
                if (hasOpen) {
                    return true;
                }

                const from = toDateObject(this.timeZone, today, 0, entry.from);
                const to = toDateObject(this.timeZone, today, 0, entry.to);

                return from.getTime() <= time && time <= to.getTime();
            }, false);
        },

        isOpeningHoursChipShown() {
            return !this.hideHeaderChip && this.officeHours && this.officeHours.openingHours;
        },

        todaysDayOfWeek(): SelectOption | undefined {
            return this.daysOfWeek.find((d) => {
                return this.isToday(this.week, Number.parseInt(d.value!));
            });
        },

        week(): Week {
            return this.getWeek(this.weekOffset);
        },
    },

    methods: {
        getEntriesForDayOfWeek(dayOfWeek: number): OfficeHoursEntriesWithException {
            const result = [] as OfficeHoursEntry[];
            const date = getDateByDayOfWeek(this.week, dayOfWeek);
            const exceptionalDay = this.officeHours!.exceptionalDays.find((e) => e.date === date);

            if (exceptionalDay) {
                for (const exceptionalDayEntry of exceptionalDay.entries) {
                    result.push({
                        dayOfWeek: 0,
                        from: exceptionalDayEntry.from,
                        to: exceptionalDayEntry.to,
                    });
                }
            } else {
                for (const officeHoursEntry of this.officeHours!.entries) {
                    if (officeHoursEntry.dayOfWeek === dayOfWeek) {
                        result.push(officeHoursEntry);
                    }
                }
            }

            return {
                entries: result.sort((a, b) => a.from.localeCompare(b.from, userSession.locale)),
                exceptionReason: exceptionalDay ? exceptionalDay.reason : null,
            };
        },

        isToday(week: Week, dayOfWeek: number) {
            return getDate(now(), this.timeZone) === getDateByDayOfWeek(week, dayOfWeek);
        },

        getWeek(offset: number): Week {
            return getWeek(now(), offset, this.timeZone, configStore.configuration.defaultLocale);
        },
    },
});
