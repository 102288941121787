
import { ServiceOffered, servicesOfferedApi } from "@/api/servicesOffered";
import Vue from "vue";

export default Vue.extend({
    props: {
        compact: {
            type: Boolean,
            default: false,
        },
        dealerId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            items: null as ServiceOffered[] | null,
        };
    },

    methods: {
        getServiceColor(isOffered: boolean): string {
            return isOffered ? "success" : "error";
        },

        getServiceIcon(isOffered: boolean): string {
            return isOffered ? "mdi-check" : "mdi-close";
        },

        getServiceTitle(serviceOffered: ServiceOffered): string {
            let title = "";

            if (serviceOffered.make) {
                title = serviceOffered.make;

                if (serviceOffered.vehicleClass) {
                    title += " (" + this.$t(`enum.VehicleClass.${serviceOffered.vehicleClass}`) + ")";
                }
            }

            if (serviceOffered.description) {
                title += serviceOffered.make ? ", " : "";
                title += serviceOffered.description;
            }

            return title;
        },

        async load() {
            this.items = null;
            this.items = await servicesOfferedApi.getByDealer(this.dealerId);
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.load();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.load();
    },
});
