
import { VehicleClass } from "@/api/makeModels";
import { ServiceOffered, ServiceOfferedForm, servicesOfferedApi } from "@/api/servicesOffered";
import { Permission } from "@/api/userSession";
import EnumField from "@/app/components/EnumField.vue";
import { showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import { maxLength, notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
        };
    },

    computed: {
        api() {
            return servicesOfferedApi;
        },

        VehicleClass() {
            return VehicleClass;
        },

        maxLength() {
            return maxLength;
        },

        notEmpty() {
            return notEmpty;
        },
    },

    methods: {
        async deleteServiceOffer(item: ServiceOffered) {
            if (
                await showConfirm(
                    this.$t("Dienstleistung löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Dienstleistung löschen möchten?") as string
                )
            ) {
                await servicesOfferedApi.delete(item.dealerId, item.id);
                return true;
            }
            return false;
        },

        async updateOrder(items: ServiceOffered[]) {
            await servicesOfferedApi.updateOrder(
                items[0].dealerId,
                items.map((d) => d.id)
            );
        },

        toForm(item: ServiceOffered): ServiceOfferedForm {
            return {
                make: item.make,
                vehicleClass: item.vehicleClass || VehicleClass.CAR,
                newVehicles: item.newVehicles || false,
                usedVehicles: item.usedVehicles || false,
                service: item.service || false,
                parts: item.parts || false,
                description: item.description,
            };
        },

        emptyForm(): ServiceOfferedForm {
            return {
                make: "",
                vehicleClass: VehicleClass.CAR,
                newVehicles: false,
                usedVehicles: false,
                service: false,
                parts: false,
                description: "",
            };
        },
    },

    components: {
        CrudPage,
        DealerContextGuard,
        EnumField,
        MakePicker,
    },
});
