
import { CallTrackingGroup, callTrackingGroupsApi, IncomingCallDistributionType } from "@/api/callTrackingGroups";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import { getSortedByDealer } from "@/app/dealerUtils";
import { showConfirm } from "@/app/messageUtil";
import CrudPage from "@/app/pages/CrudPage.vue";
import { getFullName } from "@/app/userUtils";
import { integer, notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { officeHoursStore } from "@/store/officeHours";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            IncomingCallDistributionType,
            callTrackingGroups: [] as CallTrackingGroup[],
            integer,
            notEmpty,
        };
    },

    computed: {
        officeHoursOptions(): SelectOption[] {
            return getSortedByDealer(officeHoursStore.officeHours, (officeHours) => officeHours.dealerId).map(
                (officeHours) => ({
                    text: `${officeHours.name} (${this.getDealerNameById(officeHours.dealerId)})`,
                    value: officeHours.id,
                })
            );
        },

        targetUserOptions(): SelectOption[] {
            return [...usersStore.users]
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },
    },

    methods: {
        async getCallTrackingGroups() {
            return await callTrackingGroupsApi.getAll();
        },

        async addCallTrackingGroup(form: CallTrackingGroup) {
            await callTrackingGroupsApi.add(form);
        },

        async editCallTrackingGroup(form: CallTrackingGroup) {
            await callTrackingGroupsApi.edit(form.id, form);
        },

        async deleteCallTrackingGroup(item: CallTrackingGroup) {
            if (
                await showConfirm(
                    this.$t("Call-Tracking-Gruppe löschen").toString(),
                    this.$t("Sind Sie sicher, dass Sie die Call-Tracking-Gruppe löschen möchten?").toString()
                )
            ) {
                await callTrackingGroupsApi.delete(item.id);
                return true;
            }
            return false;
        },

        async updateOrder(items: CallTrackingGroup[]) {
            await callTrackingGroupsApi.updateOrder(items.map((i) => i.id));
        },

        emptyForm(): CallTrackingGroup {
            return {
                id: "",
                name: "",
                targetUserIds: [],
                distributionType: IncomingCallDistributionType.PARALLEL,
                distributionTimeoutSeconds: null,
                distributionOfficeHoursId: null,
                distributeOnlyIfAvailable: true,
                distributeOnlyIfInWorkingHours: false,
            };
        },

        getDealerNameById(dealerId: string) {
            return (dealersStore.dealerById(dealerId)?.name || this.$t("Unbekannt")).toString();
        },

        getOfficeHoursNameById(officeHoursId: string) {
            return officeHoursStore.getOfficeHoursById(officeHoursId)?.name || (this.$t("Unbekannt") as string);
        },

        getUserFullNameById(userId: string) {
            return (getFullName(usersStore.getUserById(userId)) || this.$t("Unbekannt")).toString();
        },

        toForm(item: CallTrackingGroup) {
            return cloneObject(item);
        },
    },

    components: {
        CrudPage,
        DAutocomplete,
        EnumField,
        NumberField,
    },
});
