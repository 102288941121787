
import UserDirectoryEntrySpan from "./UserDirectoryEntrySpan.vue";
import { Department } from "@/api/departments";
import { UserDirectoryEntry } from "@/api/users";
import IntersectWrapper from "@/app/components/IntersectWrapper.vue";
import DealerLink from "@/app/pages/DealerLink.vue";
import { searchFilter } from "@/app/searchFilter";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { departmentsStore } from "@/store/departments";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

interface UserDirectoryEntryWithDepartmentPosition extends UserDirectoryEntry {
    readonly position: string;
}

interface UserDirectoryTableItem {
    readonly department: Department | null;
    readonly user: UserDirectoryEntryWithDepartmentPosition | null;
}

enum UserDirectoryTableGroupBy {
    USER = "user.id",
    DEPARTMENT = "department.id",
}

export default Vue.extend({
    data() {
        return {
            groupBy: UserDirectoryTableGroupBy.DEPARTMENT as UserDirectoryTableGroupBy,
            search: null as string | null,
            UserDirectoryTableGroupBy,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        departments(): Department[] {
            // sort order: dealer, department
            return (this.dealerId ? [this.dealerId] : dealersStore.dealers.map((d) => d.id))
                .map((dealerId) => departmentsStore.departmentsByDealer(dealerId))
                .reduce((prev, cur) => prev.concat(cur), []);
        },

        groupByOptions(): SelectOption[] {
            return [
                { value: UserDirectoryTableGroupBy.DEPARTMENT, text: this.$t("Abteilung") },
                { value: UserDirectoryTableGroupBy.USER, text: this.$t("Benutzer") },
            ];
        },

        items(): UserDirectoryTableItem[] {
            const items: UserDirectoryTableItem[] = [];

            for (const department of this.departments) {
                const departmentItems = department.departmentUsers
                    .map((du) => {
                        const user = this.users.find((u) => u.id === du.userId);

                        return user ? { department, user: { ...user, position: du.position } } : null;
                    })
                    .filter((i) => !!i) as UserDirectoryTableItem[];

                if (!departmentItems.length && this.groupBy === UserDirectoryTableGroupBy.DEPARTMENT) {
                    // show department even in case of no users
                    departmentItems.push({ department, user: null });
                }

                items.push(...departmentItems);
            }

            this.usersWithoutDepartment.forEach((user) => {
                items.push({
                    department: null,
                    user: { ...user, position: "" },
                });
            });

            if (this.groupBy === UserDirectoryTableGroupBy.USER) {
                return this.sortItemsByUsers(items);
            }

            return items;
        },

        users(): UserDirectoryEntry[] {
            let users;
            if (this.dealerId) {
                users = usersStore.getUsersByDealer(this.dealerId);
            } else {
                users = usersStore.users;
            }

            return [...users].sort((a, b) => getFullName(a).localeCompare(getFullName(b), userSession.locale));
        },

        usersWithoutDepartment(): UserDirectoryEntry[] {
            return this.users.filter(
                (u) => !this.departments.find((d) => !!d.departmentUsers.find((du) => du.userId === u.id))
            );
        },

        headers(): unknown[] {
            const isGroupByUser = this.groupBy === UserDirectoryTableGroupBy.USER;

            return [
                {
                    text: (isGroupByUser ? this.$t("Benutzer") : this.$t("Abteilung")) as string,
                    sortable: false,
                    width: "30%",
                },
                {
                    text: (isGroupByUser ? this.$t("Abteilung") : this.$t("Benutzer")) as string,
                    sortable: false,
                    width: "70%",
                },
            ];
        },
    },

    methods: {
        filter(_: unknown, search: string | null, item: UserDirectoryTableItem): boolean {
            const departmentValues = [];
            const userValues = [];
            const userPhoneNumbers = [] as string[];

            if (item.department) {
                departmentValues.push(item.department.name);
            }

            if (item.user) {
                userValues.push(item.user.username);
                userValues.push(getFullName(item.user));
                userValues.push(item.user.position);

                item.user.phoneNumbers
                    .map((n) => n.number)
                    .filter((n) => !!n)
                    .forEach((n) => {
                        userPhoneNumbers.push(n);

                        const formatted = parseAndFormatNumber(n, "NATIONAL");
                        const normalized = !!formatted ? formatted.replace(/[^\d]/g, "") : null;

                        if (!normalized) {
                            return;
                        }

                        userPhoneNumbers.push(normalized);
                    });
            }

            return searchFilter(search, ...userValues, ...departmentValues, ...userPhoneNumbers);
        },

        sortItemsByUsers(items: UserDirectoryTableItem[]): UserDirectoryTableItem[] {
            // enforce users sort while keeping sub-sort
            const result = this.users
                .map((u) => items.filter((i) => i.user && i.user.id === u.id))
                .filter((i) => !!i)
                .reduce((prev, cur) => prev.concat(cur), []);

            result.push(...items.filter((i) => i.user === null));

            return result;
        },
    },

    components: {
        DealerLink,
        UserDirectoryEntrySpan,
        IntersectWrapper,
    },
});
