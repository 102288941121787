
import { getOpportunityStatus } from "./opportunityUtils";
import { Opportunity, OpportunityStatus, OpportunityUser } from "@/api/opportunities";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import UserLink from "@/app/pages/UserLink.vue";
import UserDirectoryEntrySpan from "@/app/pages/users/UserDirectoryEntrySpan.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
    },

    data() {
        return {
            expanded: null as boolean | null,
        };
    },

    computed: {
        filteredAndSortedOpportunityUsers(): OpportunityUser[] {
            return this.opportunityProp.opportunityUsers
                .filter((u) => !u.visibilityEnd)
                .sort((a, b) => a.visibilityBegin.getTime() - b.visibilityBegin.getTime());
        },

        opportunityStatus(): OpportunityStatus {
            return getOpportunityStatus(this.opportunityProp);
        },
    },

    watch: {
        filteredAndSortedOpportunityUsers() {
            if (this.expanded === null && this.filteredAndSortedOpportunityUsers.length) {
                // expand only if the user has not changed the expansion state manually
                this.expanded = true;
            }
        },
    },

    created() {
        this.expanded = !!this.filteredAndSortedOpportunityUsers.length || null;
    },

    components: {
        DToolbarCard,
        TimeSpan,
        UserDirectoryEntrySpan,
        UserLink,
    },
});
