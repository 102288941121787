
import {
    DealerInstructionCategory,
    DealerInstructionForm,
    DealerInstructionPriority,
    IssueType,
} from "@/api/dealerInstructions";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import TimePicker from "@/app/components/TimePicker.vue";
import { dealerOptions } from "@/app/dealerUtils";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import { maxLength, notEmpty } from "@/app/validation";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        form: {
            type: Object as () => DealerInstructionForm,
            required: true,
        },
    },

    data() {
        return {
            DealerInstructionCategory,
            DealerInstructionPriority,
            IssueType,
            maxLength,
            notEmpty,
        };
    },

    computed: {
        dealerOptions(): SelectOptions {
            return dealerOptions();
        },
    },

    watch: {
        "form.visibleFromDate"(v: string | null) {
            if (!v) {
                this.$nextTick(() => {
                    this.form.visibleFromTime = null;
                });
            }
        },

        "form.visibleToDate"(v: string | null) {
            if (!v) {
                this.$nextTick(() => {
                    this.form.visibleToTime = null;
                });
            }
        },
    },

    components: {
        CaseTypePicker,
        DatePicker,
        EnumField,
        TimePicker,
    },
});
