import { Contact } from "./contacts";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum IncomingWhatsAppSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
}

export enum IssueType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
    UNKNOWN = "UNKNOWN",
}

export enum ProcessingState {
    UNPROCESSED = "UNPROCESSED",
    UNPROCESSED_IGNORE_FILTER = "UNPROCESSED_IGNORE_FILTER",
    PROCESSED = "PROCESSED",
    FILTERED = "FILTERED",
}

export type OptionalIssueId = { readonly caseId: string } | { readonly opportunityId: string } | {};

export interface IncomingWhatsAppMessage {
    readonly id: string;
    readonly created: Date;
    readonly read: Date | null;
    readonly deleted: Date | null;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly accountId: string | null;
    readonly fromNumber: string;
    readonly toNumber: string;
    readonly processingState: ProcessingState;
    readonly processedAt: Date | null;
    readonly text: string | null;
    readonly mediaId: string | null;
    readonly mediaHash: string | null;
    readonly mediaType: string | null;
    readonly mediaSize: number | null;
    readonly caption: string | null;
    readonly filename: string | null;
    readonly mediaDownloadAttempt: number | null;
    readonly mediaDownloadLastErrorTimestamp: Date | null;
    readonly latitude: string | null;
    readonly longitude: string | null;
    readonly locationName: string | null;
    readonly locationAddress: string | null;
    readonly locationUrl: string | null;
}

export interface IncomingWhatsAppAndContact {
    readonly incomingWhatsAppMessage: IncomingWhatsAppMessage;
    readonly contact: Contact | null;
}

export interface IncomingWhatsAppSearchRequest {
    readonly issueTypes: IssueType[];
    readonly processingStates: ProcessingState[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly sortBy: IncomingWhatsAppSearchOrder;
}

export interface IncomingWhatsAppSearchResults {
    readonly results: IncomingWhatsAppAndContact[];
    readonly searchId: number;
    readonly totalSize: number;
}

interface IncomingWhatsAppApi {
    getMessagesByCase(caseId: string): Promise<IncomingWhatsAppMessage[]>;
    getMessagesByOpportunity(opportunityId: string): Promise<IncomingWhatsAppMessage[]>;
    generateMediaLink(msg: IncomingWhatsAppMessage, issueId: OptionalIssueId, download?: boolean): string;
    reactivate(id: string): Promise<void>;
    search(
        start: number,
        size: number,
        searchRequest: IncomingWhatsAppSearchRequest,
        searchId: number
    ): Promise<IncomingWhatsAppSearchResults>;
}

export const incomingWhatsAppApi: IncomingWhatsAppApi = {
    async getMessagesByCase(caseId) {
        return cloneObject((await axios.get(`/api/incoming-whatsapp/cases/${caseId}`)).data);
    },

    async getMessagesByOpportunity(opportunityId) {
        return cloneObject((await axios.get(`/api/incoming-whatsapp/opportunities/${opportunityId}`)).data);
    },

    generateMediaLink(msg, issueId, download) {
        return `/api/incoming-whatsapp/${msg.id}/${msg.mediaHash}${
            msg.filename ? "/" + encodeURIComponent(msg.filename.replace(/\//g, "-")) : ""
        }?${Object.entries(issueId)
            .map((e) => `${e[0]}=${e[1]}`)
            .join("&")}${download ? "&dl=true" : ""}`;
    },

    async reactivate(id) {
        await axios.post(`/api/incoming-whatsapp/${id}/_reactivate`);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/incoming-whatsapp/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },
};
