import {
    isOpportunityTeamChangedNotification,
    isOpportunityTeamDeletedNotification,
    isOpportunityTeamsOrderChangedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { OpportunityTeam, opportunityTeamsApi } from "@/api/opportunityTeams";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { removeMatchingItems, reorderItems, updateItem } from "@/util/storeUtils";

@reactive
class OpportunityTeamsStore {
    private refreshLimiter = new ActionLimiter(true);
    private opportunityTeams_: OpportunityTeam[] = [];

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.opportunityTeams_ = await opportunityTeamsApi.getAll();
        });
    }

    get opportunityTeams() {
        return this.opportunityTeams_;
    }

    getOpportunityTeamById(id: string) {
        for (const opportunityTeam of this.opportunityTeams_) {
            if (opportunityTeam.id === id) {
                return opportunityTeam;
            }
        }

        return null;
    }

    handleNotification(n: Notification) {
        if (isOpportunityTeamChangedNotification(n)) {
            updateItem(this.opportunityTeams_, n.opportunityTeam, (t) => t.id);
        } else if (isOpportunityTeamDeletedNotification(n)) {
            removeMatchingItems(this.opportunityTeams_, (t) => t.id === n.opportunityTeamId);
        } else if (isOpportunityTeamsOrderChangedNotification(n)) {
            reorderItems(this.opportunityTeams_, n.orderedOpportunityTeamIds, (t) => t.id);
        }
    }
}

export const opportunityTeamsStore = new OpportunityTeamsStore();

notificationEventSource.addDataHandler((n) => opportunityTeamsStore.handleNotification(n));
