
import { carryDispoNoteApi, CarryDispoNoteForm } from "@/api/carryDispoNotes";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        externOrderId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            carryDispoNoteForm: {
                content: "",
            } as CarryDispoNoteForm,
            notEmpty,
            saving: false,
        };
    },

    methods: {
        async submit() {
            if (!(this.$refs.noteForm as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                await carryDispoNoteApi.add(this.externOrderId, this.carryDispoNoteForm);

                this.$emit("close");
            } finally {
                this.saving = false;
            }
        },
    },
});
