
import InventoryImportFormDialog from "./InventoryImportFormDialog.vue";
import {
    FtpAccountView,
    inventoryImportApi,
    InventoryImportSource,
    InventoryImportSourceType,
} from "@/api/inventoryImport";
import { isInventoryImportNotification, Notification, notificationEventSource } from "@/api/notifications";
import { Permission } from "@/api/userSession";
import { showConfirm } from "@/app/messageUtil";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Permission,
            InventoryImportSourceType,
            formDialogVisible: false,
            formDialogItem: null as InventoryImportSource | null,
            items: [] as InventoryImportSource[],
            ftpAccounts: [] as FtpAccountView[],
            loading: true,
            notificationHandler: null as ((n: Notification) => void) | null,
        };
    },

    computed: {
        dealerId() {
            if (
                !userSession.dealerId ||
                userSession.hasPermission(Permission.MANAGE_INVENTORY_IMPORT) ||
                userSession.profile?.dealerIds.includes(userSession.dealerId)
            ) {
                return userSession.dealerId;
            }
            return null;
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    methods: {
        formatDate(date: Date) {
            return formatInstant(date, userSession.timeZone, userSession.locale, "L");
        },

        async loadItems() {
            this.loading = true;
            this.items = [];

            try {
                if (this.dealerId) {
                    this.items = await inventoryImportApi.getByDealer(this.dealerId);
                }
            } finally {
                this.loading = false;
            }
        },

        getFtpAccountLabel(id: string) {
            return this.ftpAccounts.find((e) => e.id === id)?.label;
        },

        showCreateForm() {
            this.formDialogItem = null;
            this.formDialogVisible = true;
        },

        async refresh(item: InventoryImportSource) {
            await inventoryImportApi.sync(item.id);
        },

        showEditForm(item: InventoryImportSource) {
            this.formDialogItem = item;
            this.formDialogVisible = true;
        },

        async deleteItem(id: string) {
            if (
                await showConfirm(
                    this.$t("Fahrzeugimport-Quelle löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Fahrzeugimport-Quelle löschen möchten?") as string
                )
            ) {
                await inventoryImportApi.delete(id);
            }
        },

        savedItem() {
            this.formDialogVisible = false;
        },
    },

    async mounted() {
        this.ftpAccounts = await inventoryImportApi.listFtpAccounts();
        await this.loadItems();
        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isInventoryImportNotification(n)) {
                this.loadItems();
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        DealerContextGuard,
        InventoryImportFormDialog,
    },
});
