
import OutgoingEmailReceiverViewChip from "./OutgoingEmailReceiverViewChip.vue";
import { OutgoingEmailReceiverView } from "./outgoingEmailReceiver";
import {
    getAttachments,
    getBccReceivers,
    getCcReceivers,
    getCreator,
    getIssueId,
    getToReceivers,
} from "./outgoingEmailUtils";
import { emailDomainsApi } from "@/api/emailDomains";
import { NotFound } from "@/api/errors";
import { isOutgoingEmailEventsUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import {
    IssueId,
    OutgoingEmail,
    OutgoingEmailAttachment,
    outgoingEmailsApi,
    TransactionEmailReceiverType,
} from "@/api/outgoingEmails";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { ReplyToBaseEmail, writeHtmlToIframe } from "@/app/emailUtils";
import { isIllegalReceiverEmailAddress } from "@/app/issueUtils";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

enum HtmlBodyError {
    LOADING_HTML_BODY_FAILED = "LOADING_HTML_BODY_FAILED",
    HTML_BODY_EMPTY = "HTML_BODY_EMPTY",
    WRITING_HTML_BODY_TO_IFRAME_FAILED = "WRITING_HTML_BODY_TO_IFRAME_FAILED",
}

export default Vue.extend({
    props: {
        canSendEmail: {
            type: Boolean,
            default: false,
        },
        canViewContent: {
            type: Boolean,
            required: true,
        },
        outgoingEmail: {
            type: Object as () => OutgoingEmail,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            htmlBody: null as string | null,
            htmlBodyError: null as HtmlBodyError | null,
            htmlBodyLoaded: false,
            image: "",
            loading: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingEmailObj: this.outgoingEmail,
            writing: false,
        };
    },

    computed: {
        attachments(): OutgoingEmailAttachment[] {
            return getAttachments(this.outgoingEmailObj);
        },

        bccReceivers(): OutgoingEmailReceiverView[] {
            return getBccReceivers(this.outgoingEmailObj);
        },

        canReplyToEmail(): boolean {
            return (
                this.canSendEmail &&
                !isIllegalReceiverEmailAddress(
                    this.outgoingEmailObj.replyToAddress
                        ? this.outgoingEmailObj.replyToAddress
                        : this.outgoingEmailObj.fromAddress
                )
            );
        },

        ccReceivers(): OutgoingEmailReceiverView[] {
            return getCcReceivers(this.outgoingEmailObj);
        },

        creationTimestamp(): string {
            return formatInstant(this.outgoingEmailObj.created, userSession.timeZone, userSession.locale, "S");
        },

        creator(): string {
            return getCreator(this.outgoingEmailObj);
        },

        htmlBodyErrorColor(): string | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return "error";
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return "info";
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return "error";
            } else {
                return null;
            }
        },

        htmlBodyErrorRetry(): Function | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return () => this.loadHtmlBody();
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return () => this.loadHtmlBody();
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return () => this.writeHtmlBodyToIframe();
            } else {
                return null;
            }
        },

        htmlBodyErrorRetrying(): boolean {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return this.loading;
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return this.loading;
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return this.writing;
            } else {
                return false;
            }
        },

        htmlBodyErrorText(): string | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return this.$t("Es ist ein Fehler beim Laden der E-Mail aufgetreten.") as string;
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return this.$t("Der Inhalt der E-Mail wurde nicht gefunden.") as string;
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return this.$t("Es ist ein Fehler beim Darstellen der E-Mail aufgetreten.") as string;
            } else {
                return null;
            }
        },

        issueId(): IssueId | null {
            return getIssueId(this.outgoingEmailObj);
        },

        toReceivers(): OutgoingEmailReceiverView[] {
            return getToReceivers(this.outgoingEmailObj);
        },
    },

    methods: {
        getFullNameById(id: string) {
            return (getFullName(usersStore.getUserById(id)) || this.$t("Unbekannt")) as string;
        },

        async loadHtmlBody() {
            if (this.htmlBody !== null) {
                return;
            }

            this.loading = true;

            try {
                this.htmlBody = await outgoingEmailsApi.getHtmlBody(this.outgoingEmailObj.id, this.issueId!);
                this.htmlBodyError = !this.htmlBody ? HtmlBodyError.HTML_BODY_EMPTY : null;
            } catch (e) {
                this.htmlBodyError = HtmlBodyError.LOADING_HTML_BODY_FAILED;

                if (!(e instanceof NotFound)) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            } finally {
                this.loading = false;
            }

            if (this.htmlBody !== null) {
                this.$nextTick(() => {
                    this.writeHtmlBodyToIframe();
                });
            }
        },

        async loadHtmlBodyOnIntersect() {
            if (this.htmlBodyError !== null) {
                return;
            }

            await this.loadHtmlBody();
        },

        async reply(replyToReceivers: boolean) {
            const transactionEmailHost = configStore.configuration.transactionEmailHost.toLowerCase();
            const receivers = this.outgoingEmailObj.receivers.filter(
                (r) => !r.address.toLowerCase().endsWith(`@${transactionEmailHost}`)
            );

            const internalDomains: string[] = receivers.length
                ? (await emailDomainsApi.getAllIds()).map((internalDomain) => internalDomain.toLowerCase())
                : [];

            const getReceiversByType = (type: TransactionEmailReceiverType) =>
                receivers
                    .filter((r) => r.type === type)
                    .filter((r) => !internalDomains.includes(r.address.split("@")[1].toLowerCase()))
                    .map((r) => ({ name: r.name, address: r.address }));

            const email: ReplyToBaseEmail = {
                id: this.outgoingEmailObj.id,
                fromName: this.outgoingEmailObj.fromName,
                fromAddress: this.outgoingEmailObj.fromAddress,
                replyToName: this.outgoingEmailObj.replyToName,
                replyToAddress: this.outgoingEmailObj.replyToAddress,
                toReceivers: replyToReceivers ? getReceiversByType(TransactionEmailReceiverType.TO) : [],
                ccReceivers: replyToReceivers ? getReceiversByType(TransactionEmailReceiverType.CC) : [],
                bccReceivers: replyToReceivers ? getReceiversByType(TransactionEmailReceiverType.BCC) : [],
                subject: this.outgoingEmailObj.subject,
                htmlBodyForQuote: await outgoingEmailsApi.getHtmlBody(this.outgoingEmailObj.id, this.issueId!, true),
                headers: this.outgoingEmailObj.headers,
            };

            this.$emit("send-reply", email);
        },

        showAttachment(attachment: OutgoingEmailAttachment, download: boolean) {
            const fname = attachment.filename.toLowerCase();
            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.image = outgoingEmailsApi.generateAttachmentLink(
                    this.outgoingEmailObj.id,
                    attachment,
                    this.issueId
                );
            } else {
                window.open(
                    outgoingEmailsApi.generateAttachmentLink(
                        this.outgoingEmailObj.id,
                        attachment,
                        this.issueId,
                        download
                    )
                );
            }
        },

        writeHtmlBodyToIframe() {
            if (!this.htmlBody) {
                return;
            }

            this.writing = true;
            try {
                const success = writeHtmlToIframe(this.$refs.iframe as HTMLIFrameElement, this.htmlBody);

                this.htmlBodyError = !success ? HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED : null;
            } catch (e) {
                this.htmlBodyError = HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED;

                this.$nextTick(() => {
                    throw e;
                });
            } finally {
                this.writing = false;
            }
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isOutgoingEmailEventsUpdatedNotification(n)) {
                if (n.id === this.outgoingEmailObj.id) {
                    this.outgoingEmailObj.events.push(n.event);
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        ImageCarousel,
        OutgoingEmailReceiverViewChip,
        TimeSpan,
        UserLink,
    },
});
