
import { IncomingEmailSettings, incomingEmailSettingsApi } from "@/api/incomingEmailSettings";
import { OutgoingEmailSettings, outgoingEmailSettingsApi } from "@/api/outgoingEmailSettings";
import { SystemEmailAlias, systemEmailAliasesApi } from "@/api/systemEmailAliases";
import { Permission } from "@/api/userSession";
import { getSortedByDealer } from "@/app/dealerUtils";
import DealerGlobalContextHint from "@/app/pages/DealerGlobalContextHint.vue";
import { email } from "@/app/validation";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { userSession } from "@/store/userSession";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            emailRules: email(),
            loading: true,
            incomingEmailSettings: null as IncomingEmailSettings | null,
            outgoingEmailSettings: null as OutgoingEmailSettings | null,
            savingIncomingEmailSettings: false,
            savingOutgoingEmailSettings: false,
            systemEmailAliases: [] as SystemEmailAlias[],
        };
    },

    computed: {
        canManageIncomingEmailSettings(): boolean {
            return userSession.hasPermission(Permission.MANAGE_INCOMING_EMAIL_SETTINGS);
        },

        canManageOutgoingEmailSettings(): boolean {
            return userSession.hasPermission(Permission.MANAGE_OUTGOING_EMAIL_SETTINGS);
        },

        escalationGroupOptions(): SelectOption[] {
            return getSortedByDealer(escalationGroupsStore.escalationGroups, (e) => e.dealerId).map((e) => ({
                text: `${e.name} (${this.getDealerNameById(e.dealerId) ?? this.$t("Unbekannt")})`,
                value: e.id,
            }));
        },

        fileExtensionsRules(): Function[] {
            return [(es: string[]) => es.every((e) => !!e.trim()) || this.$t("Gültige Dateiendung ist erforderlich")];
        },

        systemEmailAliasOptions(): SelectOptions {
            return this.systemEmailAliases
                .map((s) => ({
                    text: `${s.localPart}@${this.transactionEmailHost}`,
                    value: s.id,
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        transactionEmailHost() {
            return configStore.configuration.transactionEmailHost;
        },

        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },
    },

    methods: {
        getDealerNameById(dealerId: string): string | null {
            return dealersStore.dealerById(dealerId)?.name ?? null;
        },

        async updateIncomingEmailSettings() {
            if (!this.$refs.incomingemailsettingsform || !(this.$refs.incomingemailsettingsform as any).validate()) {
                return;
            }

            this.savingIncomingEmailSettings = true;
            try {
                await incomingEmailSettingsApi.editIncomingEmailSettings(this.incomingEmailSettings!);
            } finally {
                this.savingIncomingEmailSettings = false;
            }
        },

        async updateOutgoingEmailSettings() {
            if (!this.$refs.outgoingemailsettingsform || !(this.$refs.outgoingemailsettingsform as any).validate()) {
                return;
            }

            this.savingOutgoingEmailSettings = true;
            try {
                await outgoingEmailSettingsApi.editOutgoingEmailSettings(this.outgoingEmailSettings!);
            } finally {
                this.savingOutgoingEmailSettings = false;
            }
        },
    },

    async mounted() {
        try {
            this.incomingEmailSettings = await incomingEmailSettingsApi.getIncomingEmailSettings();
            this.outgoingEmailSettings = await outgoingEmailSettingsApi.getOutgoingEmailSettings();
            this.systemEmailAliases = await systemEmailAliasesApi.getAll();
        } finally {
            this.loading = false;
        }
    },

    components: {
        DealerGlobalContextHint,
    },
});
