
import WorkingHoursTemplateFormPresenceCard from "./WorkingHoursTemplateFormPresenceCard.vue";
import { User } from "@/api/users";
import {
    workingHoursApi,
    WorkingHoursTemplate,
    WorkingHoursTemplateEntry,
    WorkingHoursTemplateForm,
} from "@/api/workingHours";
import { showInfo } from "@/app/messageUtil";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDaysOfWeek } from "@/util/dateTimeUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        workingHoursTemplate: {
            type: Object as () => WorkingHoursTemplate | null,
            required: false,
        },
        user: {
            type: Object as () => User,
            required: true,
        },
    },

    data() {
        return {
            valid: true,
            isWorking: false,
            visiblePresenceCards: [] as string[],

            // FORM FIELDS
            workingHoursTemplateForm: {
                name: this.workingHoursTemplate?.name || "",
                workingHoursTemplateEntries: cloneObject(this.workingHoursTemplate?.workingHoursTemplateEntries || []),
            } as WorkingHoursTemplateForm,

            // RULES
            nameRules: notEmpty().maxLength(63),
        };
    },

    computed: {
        daysOfWeek(): SelectOptions {
            return getDaysOfWeek(configStore.configuration.defaultLocale, userSession.locale);
        },
    },

    methods: {
        addEntry(workingHoursTemplateEntry: WorkingHoursTemplateEntry) {
            this.workingHoursTemplateForm.workingHoursTemplateEntries.push(workingHoursTemplateEntry);
            this.hidePresenceCard();
        },

        hidePresenceCard() {
            this.visiblePresenceCards = [];
        },

        showPresenceCard(dayOfWeek: string) {
            this.visiblePresenceCards = [];
            this.visiblePresenceCards.push(dayOfWeek);
        },

        deleteEntry(workingHoursTemplateEntry: WorkingHoursTemplateEntry) {
            for (let i = 0; i < this.workingHoursTemplateForm.workingHoursTemplateEntries.length; i++) {
                if (
                    JSON.stringify(workingHoursTemplateEntry) ===
                    JSON.stringify(this.workingHoursTemplateForm.workingHoursTemplateEntries[i])
                ) {
                    this.workingHoursTemplateForm.workingHoursTemplateEntries.splice(i, 1);
                    return;
                }
            }
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },

        getEntriesForDayOfWeek(
            workingHoursTemplate: WorkingHoursTemplate,
            dayOfWeek: number
        ): WorkingHoursTemplateEntry[] {
            const result = [] as WorkingHoursTemplateEntry[];

            for (const workingHoursTemplateEntry of this.workingHoursTemplateForm.workingHoursTemplateEntries) {
                if (workingHoursTemplateEntry.dayOfWeek === dayOfWeek) {
                    result.push(workingHoursTemplateEntry);
                }
            }

            return result.sort((a, b) => a.from.localeCompare(b.from, userSession.locale));
        },

        close() {
            this.$emit("close");
        },

        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            if (this.visiblePresenceCards.length > 0) {
                showInfo(this.$t("Bitte schließen oder speichern Sie Ihre Änderung bevor Sie fortfahren.") as string);
                return;
            }

            this.isWorking = true;

            try {
                if (this.workingHoursTemplate) {
                    await workingHoursApi.editTemplate(
                        this.user.id,
                        this.workingHoursTemplate.id,
                        this.workingHoursTemplateForm
                    );
                } else {
                    await workingHoursApi.addTemplate(this.user.id, this.workingHoursTemplateForm);
                }
            } finally {
                this.isWorking = false;
            }

            this.$emit("success");
        },
    },

    components: {
        WorkingHoursTemplateFormPresenceCard,
    },
});
