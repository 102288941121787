var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('dealer-global-context-hint'),_c('v-card',{staticClass:"mb-3",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("Eingehende Dateien"))+" ")]),_c('v-spacer'),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.saving),expression:"saving"}],staticClass:"mr-2",attrs:{"width":2,"indeterminate":"","size":18}}),_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-card-text',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.loading)?_c('v-form',{attrs:{"autocomplete":"off"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","hide-details":"auto","items":[
                        { value: false, text: _vm.$t('Nein') },
                        { value: true, text: _vm.$t('Ja') },
                    ],"label":_vm.$t('Nur Bild-, Ton- und PDF-Dateien anzeigen')},on:{"change":_vm.updateFileSettings},model:{value:(_vm.fileSettings.onlyWhitelistedIncomingFileExtensions),callback:function ($$v) {_vm.$set(_vm.fileSettings, "onlyWhitelistedIncomingFileExtensions", $$v)},expression:"fileSettings.onlyWhitelistedIncomingFileExtensions"}})],1):_vm._e()],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("Ausgehende und hochgeladene Dateien"))+" ")]),_c('v-spacer'),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.saving),expression:"saving"}],staticClass:"mr-2",attrs:{"width":2,"indeterminate":"","size":18}}),_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-card-text',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.loading)?_c('v-form',{attrs:{"autocomplete":"off"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","hide-details":"auto","items":[
                        { value: false, text: _vm.$t('Nein') },
                        { value: true, text: _vm.$t('Ja') },
                    ],"label":_vm.$t('Nur Bild-, Ton- und PDF-Dateien erlauben')},on:{"change":_vm.updateFileSettings},model:{value:(_vm.fileSettings.onlyWhitelistedOutgoingFileExtensions),callback:function ($$v) {_vm.$set(_vm.fileSettings, "onlyWhitelistedOutgoingFileExtensions", $$v)},expression:"fileSettings.onlyWhitelistedOutgoingFileExtensions"}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }