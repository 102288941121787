
import InventoryVehicleInquiryDataCard from "./InventoryVehicleInquiryDataCard.vue";
import InventoryVehicleInquiryVehicleSearchCriteriaCard from "./InventoryVehicleInquiryVehicleSearchCriteriaCard.vue";
import { inventoryVehicleInquiryAssistantState } from "./inventoryVehicleInquiryAssistantState";
import { contactsApi } from "@/api/contacts";
import { inventoryApi } from "@/api/inventory";
import { inventoryVehicleInquiryApi } from "@/api/inventoryVehicleInquiries";
import { OpportunityChannel } from "@/api/opportunities";
import { Permission } from "@/api/userSession";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import TimePicker from "@/app/components/TimePicker.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import { dealerOptions } from "@/app/dealerUtils";
import { showConfirm, showError, showInfo } from "@/app/messageUtil";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import ContactAndVehiclePicker from "@/app/pages/contacts/ContactAndVehiclePicker.vue";
import OpportunityTeamPickerCard from "@/app/pages/opportunities/OpportunityTeamPickerCard.vue";
import UserPickerCard from "@/app/pages/users/UserPickerCard.vue";
import { notEmpty } from "@/app/validation";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            inventoryVehicleInquiryAssistantState,
            initializing: true,
            serverErrorOnLoad: false,
            submitting: false,

            OpportunityChannel,
            notEmpty,
        };
    },

    computed: {
        dealerId(): string | null {
            return userSession.dealerId;
        },

        isEditMode(): boolean {
            return !!this.$route.params.inventoryvehicleinquiryid;
        },

        working(): boolean {
            return this.initializing || this.submitting;
        },

        dealerOptions() {
            return dealerOptions();
        },

        sourceOptions() {
            return opportunitySourcesStore.opportunitySources.map((s) => ({ value: s.id, text: s.name }));
        },
    },

    methods: {
        async reset() {
            if (
                await showConfirm(
                    this.$t("Eingaben zurücksetzen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?") as string
                )
            ) {
                inventoryVehicleInquiryAssistantState.reset(null);
            }
        },

        async submit() {
            if (!(this.$refs.form as any).validate()) {
                showError(this.$t("Bitte korrigieren Sie Ihre fehlerhaften Eingaben.") as string);
                return;
            }

            if (!inventoryVehicleInquiryAssistantState.contactAndVehicleId?.contactId) {
                showError(this.$t("Es ist kein Kontakt ausgewählt!") as string);
                return;
            }

            if (
                !inventoryVehicleInquiryAssistantState.opportunityTeamReceivers.length &&
                !inventoryVehicleInquiryAssistantState.individualReceivers.userIds.length &&
                !(await showConfirm(
                    this.$t("Es ist kein Empfänger ausgewählt!") as string,
                    this.$t("Sind Sie sicher, dass Sie ohne Empfänger fortfahren möchten?") as string
                ))
            ) {
                return;
            }

            this.submitting = true;
            try {
                if (inventoryVehicleInquiryAssistantState.id) {
                    await inventoryVehicleInquiryApi.edit(
                        inventoryVehicleInquiryAssistantState.id,
                        inventoryVehicleInquiryAssistantState.toData()
                    );
                    showInfo(this.$t("Suchauftrag wurde erfolgreich gespeichert.").toString());
                } else {
                    await inventoryVehicleInquiryApi.add(inventoryVehicleInquiryAssistantState.toData());
                    showInfo(this.$t("Suchauftrag wurde erfolgreich erstellt.").toString());
                }

                inventoryVehicleInquiryAssistantState.reset(null);

                if (userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES)) {
                    await this.$router.push(`/my-opportunities`);
                } else {
                    await this.$router.push(`/inventory-vehicle-inquiries`);
                }
            } finally {
                this.submitting = false;
            }
        },

        handleDealerChange(newDealerId: string | null) {
            inventoryVehicleInquiryAssistantState.dealerId = newDealerId;
            if (this.dealerId) {
                inventoryVehicleInquiryAssistantState.dealerIds = [this.dealerId];
            } else {
                inventoryVehicleInquiryAssistantState.dealerIds = [];
            }
        },
    },

    watch: {
        dealerId(newDealerId: string | null) {
            if (!this.isEditMode) {
                this.handleDealerChange(newDealerId);
            }
        },
    },

    async mounted() {
        inventoryVehicleInquiryAssistantState.reset(null);

        if (!inventoryVehicleInquiryAssistantState.dealerId) {
            this.handleDealerChange(this.dealerId);
        }

        if (!inventoryVehicleInquiryAssistantState.validUntilTimeZone) {
            inventoryVehicleInquiryAssistantState.validUntilTimeZone = userSession.timeZone;
        }

        try {
            if (!this.isEditMode) {
                inventoryVehicleInquiryAssistantState.individualReceivers.userIds = userSession.isCtUser()
                    ? []
                    : [userSession.userId!];

                if (this.$route.query.c) {
                    const contact = await contactsApi.getContactById(this.$route.query.c);

                    if (contact) {
                        inventoryVehicleInquiryAssistantState.contactAndVehicleId = {
                            contactId: contact.id,
                            contactVehicleId: null,
                        };
                    }
                }

                if (this.$route.query.iv) {
                    const inventoryVehicle = await inventoryApi.getById(this.$route.query.iv);

                    if (inventoryVehicle) {
                        inventoryVehicleInquiryAssistantState.setSearchCriteriaByInventoryVehicle(inventoryVehicle);
                    }
                }
            } else {
                const id = this.$route.params.inventoryvehicleinquiryid;

                if (id) {
                    inventoryVehicleInquiryAssistantState.reset(await inventoryVehicleInquiryApi.getById(id));
                }
            }
        } catch (e) {
            this.serverErrorOnLoad = true;
            throw e;
        } finally {
            this.initializing = false;
        }
    },

    async beforeCreate() {
        if (!userSession.dealerId) {
            await this.$router.replace({ query: { ...this.$route.query, dealerId: userSession.mainDealerId || null } });
        }
    },

    components: {
        ContactAndVehiclePicker,
        EnumField,
        DatePicker,
        DealerContextWarning,
        TimePicker,
        TimeZonePicker,
        OpportunityTeamPickerCard,
        UserPickerCard,
        InventoryVehicleInquiryDataCard,
        InventoryVehicleInquiryVehicleSearchCriteriaCard,
    },
});
