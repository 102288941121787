
import ContactFormDialog from "./ContactFormDialog.vue";
import ContactSearchDataTable from "./ContactSearchDataTable.vue";
import { EMPTY_SEARCH_REQUEST } from "./contactSearchUtils";
import { contactDuplicatesApi } from "@/api/contactDuplicates";
import { ContactSearchRequest } from "@/api/contactSearch";
import { ContactSource } from "@/api/contacts";
import { Permission } from "@/api/userSession";
import { showAlert, showConfirm, showInfo } from "@/app/messageUtil";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            ContactSource,
            formDialogVisible: false,
            duplicateRemovalInfoDialogTimeout: null as number | null,
            removingDuplicates: false,
            searchRequest: {
                ...cloneObject(EMPTY_SEARCH_REQUEST),
                search: this.$route.query.searchTerm || null,
            } as ContactSearchRequest,
        };
    },

    computed: {
        isAllowedToRemoveDuplicates(): boolean {
            return userSession.hasPermission(Permission.MANAGE_CONTACT_UPLOADS);
        },
    },

    methods: {
        async createdContact(contactId: string) {
            this.formDialogVisible = false;
            await this.$router.push(`/contact/${contactId}/0`);
        },

        async removeDuplicates() {
            if (
                !(await showConfirm(
                    this.$t("Dublettenbereinigung") as string,
                    this.$t("Sind Sie sicher, dass Sie Kontaktdubletten entfernen möchten?") as string
                ))
            ) {
                return;
            }

            this.removingDuplicates = true;
            try {
                this.duplicateRemovalInfoDialogTimeout = setTimeout(() => {
                    showInfo(
                        this.$t(
                            "Die Dublettenbereinigung wurde gestartet und läuft im Hintergrund solange, bis keine weiteren Dubletten mehr gefunden werden können. Dies kann mehrere Stunden in Anspruch nehmen."
                        ) as string,
                        true
                    );
                }, 1000);

                const result = await contactDuplicatesApi.removeDuplicates();

                await showAlert(
                    this.$t("Dublettenbereinigung") as string,
                    this.$tc(
                        "Es wurden keine Kontaktdubletten erkannt. | Es wurde eine Kontaktdublette erkannt und entfernt. | Es wurden {count} Kontaktdubletten erkannt und entfernt.",
                        result
                    ) as string
                );

                this.refresh();
            } finally {
                if (this.duplicateRemovalInfoDialogTimeout !== null) {
                    clearTimeout(this.duplicateRemovalInfoDialogTimeout);
                }

                this.removingDuplicates = false;
            }
        },

        refresh() {
            this.searchRequest = { ...this.searchRequest };
        },
    },

    beforeDestroy() {
        if (this.duplicateRemovalInfoDialogTimeout !== null) {
            clearTimeout(this.duplicateRemovalInfoDialogTimeout);
        }
    },

    components: {
        ContactFormDialog,
        ContactSearchDataTable,
    },
});
