import {
    isOpportunityOutcomeReasonChangedNotification,
    isOpportunityOutcomeReasonDeletedNotification,
    isOpportunityOutcomeReasonsOrderChangedNotification,
    Notification,
    notificationEventSource,
} from "@/api/notifications";
import { OpportunityOutcomeReason, opportunityOutcomeReasonsApi } from "@/api/opportunityOutcomeReasons";
import { ActionLimiter } from "@/util/debounce";
import { reactive } from "@/util/reactive";
import { removeMatchingItems, reorderItems, updateItem } from "@/util/storeUtils";

@reactive
class OpportunityOutcomeReasonsStore {
    private refreshLimiter = new ActionLimiter(true);
    private opportunityOutcomeReasons_: OpportunityOutcomeReason[] = [];

    get opportunityOutcomeReasons(): OpportunityOutcomeReason[] {
        return [...this.opportunityOutcomeReasons_];
    }

    getOpportunityOutcomeReasonById(id: string): OpportunityOutcomeReason | null {
        return this.opportunityOutcomeReasons_.find((r) => r.id === id) ?? null;
    }

    async refresh() {
        await this.refreshLimiter.execute(async () => {
            this.opportunityOutcomeReasons_ = await opportunityOutcomeReasonsApi.getAll();
        });
    }

    handleNotification(n: Notification) {
        if (isOpportunityOutcomeReasonChangedNotification(n)) {
            updateItem(this.opportunityOutcomeReasons_, n.opportunityOutcomeReason, (r) => r.id);
        } else if (isOpportunityOutcomeReasonDeletedNotification(n)) {
            removeMatchingItems(this.opportunityOutcomeReasons_, (r) => r.id === n.opportunityOutcomeReasonId);
        } else if (isOpportunityOutcomeReasonsOrderChangedNotification(n)) {
            reorderItems(this.opportunityOutcomeReasons_, n.orderedOpportunityOutcomeReasonIds, (r) => r.id);
        }
    }
}

export const opportunityOutcomeReasonsStore = new OpportunityOutcomeReasonsStore();

notificationEventSource.addDataHandler((n) => opportunityOutcomeReasonsStore.handleNotification(n));
