
import { OpportunityUrl } from "@/api/opportunities";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        urlProp: {
            type: Object as () => OpportunityUrl | null,
        },
    },

    data() {
        return {
            url: {
                url: this.urlProp?.url ?? "",
                key: this.urlProp?.key ?? null,
                description: this.urlProp?.description ?? "",
            } as OpportunityUrl,
            urlRules: notEmpty(),
        };
    },

    methods: {
        submit() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }
            this.$emit("submit", this.url);
        },
    },
});
