
import NumberBlacklistDataTableRow from "./NumberBlacklistDataTableRow.vue";
import { NumberBlacklistEntry, NumberBlacklistSearchRequest, numbersBlacklistApi } from "@/api/numbersBlacklist";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { PickMutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        itemsPerPage: {
            type: Number,
            default: 50,
        },
        searchRequest: {
            type: Object as () => NumberBlacklistSearchRequest,
            required: true,
        },
    },

    data() {
        return {
            items: [] as NumberBlacklistEntry[],
            loading: false,
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchId: 1,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                { text: this.$t("Rufnummer"), width: "40%" },
                { text: this.$t("Anmerkungen"), width: "60%" },
                { width: "1%" },
            ];
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            const searchId = ++this.searchId;

            try {
                const searchResults = await numbersBlacklistApi.search(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    this.searchRequest,
                    searchId
                );

                if (searchId === this.searchId) {
                    this.items = searchResults.results;
                    this.paging.totalSize = searchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                if (searchId === this.searchId) {
                    this.loading = false;
                    this.paging.page = 1;
                }
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        async refresh() {
            await this.loadItems();
        },
    },

    watch: {
        searchRequest: {
            deep: true,
            async handler() {
                this.paging.page = 1;
                try {
                    await this.loadItems();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DataTable,
        NumberBlacklistDataTableRow,
    },
});
