
import { Bank, opportunitiesApi, Opportunity, OpportunityAcquisitionDetailsForm } from "@/api/opportunities";
import { AcquisitionType } from "@/api/opportunityTeams";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import ActionCard from "@/app/pages/ActionCard.vue";
import { decimal, defaultCurrency, integer } from "@/app/validation";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },
    },

    data() {
        return {
            AcquisitionType,
            acquisitionFormData: {
                registrationRequested: this.opportunityProp.registrationRequested,
                insuranceRequested: this.opportunityProp.insuranceRequested,
                deliveryRequested: this.opportunityProp.deliveryRequested,
                testdriveRequested: this.opportunityProp.testdriveRequested,
                preferredTestdriveDateTime: this.opportunityProp.preferredTestdriveDateTime,
                counterOffer: this.opportunityProp.counterOffer,
                dealName: this.opportunityProp.dealName,
                acquisitionType: this.opportunityProp.acquisitionType,
                bank: this.opportunityProp.bank,
                paybackPeriodMonths: this.opportunityProp.paybackPeriodMonths,
                totalMileage: this.opportunityProp.totalMileage,
                firstInstallment: this.opportunityProp.firstInstallment,
                monthlyInstallment: this.opportunityProp.monthlyInstallment,
                finalInstallment: this.opportunityProp.finalInstallment,
            } as OpportunityAcquisitionDetailsForm,
            Bank,
            currencyRules: decimal(defaultCurrency()),
            positiveIntegerRules: integer(0),
            working: false,
        };
    },

    computed: {
        defaultCurrency(): string {
            return configStore.configuration.defaultCurrency;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        resetBankIfIncorrectAcquisitionType() {
            if (
                this.acquisitionFormData.acquisitionType !== AcquisitionType.FINANCE &&
                this.acquisitionFormData.acquisitionType !== AcquisitionType.LEASE
            ) {
                this.acquisitionFormData = {
                    ...this.acquisitionFormData,
                    bank: null,
                };
            }
        },

        async save() {
            this.working = true;
            try {
                await opportunitiesApi.updateAcquisitionDetails(this.opportunityProp.id, this.acquisitionFormData);
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        EnumField,
        NumberField,
    },
});
