var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('crud-page',{attrs:{"icon":"mdi-phone-outgoing","no-items-text":_vm.$t('Es wurden keine Anruferkennungen gefunden.'),"add-dialog-title":_vm.$t('Anruferkennung hinzufügen'),"edit-dialog-title":_vm.$t('Anruferkennung bearbeiten'),"page-title":_vm.$t('Anruferkennungen'),"global":true,"callbacks":{
        load: (_) => _vm.api.getCallerIds(),
        add: (_, form) => _vm.addCallerId(form),
        edit: (_, form) => _vm.api.editCallerId(form),
        delete: (item) => _vm.deleteCallerId(item.phoneNumber),
        toForm: (item) => ({ ...item, edit: true }),
        key: (item) => item.phoneNumber,
        emptyForm: () => _vm.emptyForm(),
    }},scopedSlots:_vm._u([{key:"title",fn:function({ item }){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm._f("phoneNumber")(item.phoneNumber)))]),(item.internal)?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","color":"warning"}},[_vm._v(_vm._s(_vm.$t("Interne Nummer")))]):_vm._e()]}},{key:"dialog",fn:function({ form }){return [_c('phone-number-field',{attrs:{"default-country-code":_vm.defaultCountry,"autofocus":"","label":_vm.$t('Rufnummer'),"rules":_vm.notEmpty().e164(),"disabled":form.edit},model:{value:(form.phoneNumber),callback:function ($$v) {_vm.$set(form, "phoneNumber", $$v)},expression:"form.phoneNumber"}}),_c('v-switch',{attrs:{"label":_vm.$t('Interne Nummer')},model:{value:(form.internal),callback:function ($$v) {_vm.$set(form, "internal", $$v)},expression:"form.internal"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }