
import OpportunityRequestedVehicleFormCard from "./OpportunityRequestedVehicleFormCard.vue";
import { ExteriorColor, Fuel, UsageType } from "@/api/inventory";
import { Opportunity } from "@/api/opportunities";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import { renderUnit } from "@/app/filters";
import { renderPower } from "@/app/inventoryUtils";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        opportunityProp: {
            type: Object as () => Opportunity,
            required: true,
        },

        canEdit: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            ExteriorColor,
            Fuel,
            renderPower,
            renderUnit,
            requestedVehicleFormVisible: false,
            expanded: null as boolean | null,
            UsageType,
        };
    },

    computed: {
        firstRegistration(): string | null {
            if (!this.opportunityProp.firstRegistrationYear) {
                return null;
            }

            let date = this.opportunityProp.firstRegistrationYear.toString();

            if (this.opportunityProp.firstRegistrationMonth !== null) {
                date += "-" + this.opportunityProp.firstRegistrationMonth.toString().padStart(2, "0");

                if (this.opportunityProp.firstRegistrationDay !== null) {
                    date += "-" + this.opportunityProp.firstRegistrationDay.toString().padStart(2, "0");
                }
            }

            return date;
        },

        hasContent(): boolean {
            return (
                !!this.opportunityProp.internalId ||
                !!this.opportunityProp.link ||
                !!this.opportunityProp.vehicleClass ||
                !!this.opportunityProp.make ||
                !!this.opportunityProp.modelDescription ||
                !!this.opportunityProp.usageType ||
                this.opportunityProp.mileage !== null ||
                this.opportunityProp.price !== null ||
                !!this.firstRegistration ||
                !!this.opportunityProp.fuel ||
                this.opportunityProp.power !== null ||
                !!this.opportunityProp.exteriorColor ||
                this.opportunityProp.cubicCapacity !== null ||
                this.opportunityProp.preOffer !== null
            );
        },
    },

    watch: {
        hasContent() {
            if (this.expanded === null && this.hasContent) {
                // expand only if the user has not changed the expansion state manually
                this.expanded = true;
            }
        },
    },

    methods: {
        showEditForm() {
            this.requestedVehicleFormVisible = true;
        },

        closeEditForm() {
            this.requestedVehicleFormVisible = false;
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },
    },

    created() {
        this.expanded = this.hasContent || null;
    },

    components: {
        CopyToClipboardIcon,
        DToolbarCard,
        OpportunityRequestedVehicleFormCard,
    },
});
