import { CaseLastActivityType } from "./casePrioritySettings";
import { Contact, ContactMethod, ContactVehicle } from "./contacts";
import { NoteForm } from "./notes";
import { OutgoingCallForm } from "./outgoingCalls";
import { OutgoingEmailForm } from "./outgoingEmails";
import { OutgoingSmsForm } from "./outgoingSms";
import { OutgoingWhatsAppMessageForm } from "./outgoingWhatsApp";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum CaseChannel {
    FAX = "FAX",
    EMAIL = "EMAIL",
    WEBSITE = "WEBSITE",
    PHONE_INTERNAL = "PHONE_INTERNAL",
    PHONE_BDC = "PHONE_BDC",
    SMS = "SMS",
    LIVE_CHAT = "LIVE_CHAT",
    PERSONAL_APPEARANCE = "PERSONAL_APPEARANCE",
    WHATSAPP = "WHATSAPP",
}

export enum CasePriority {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

export enum CaseUrgency {
    NORMAL = "NORMAL",
    HIGH = "HIGH",
    PERSONAL_APPEARANCE = "PERSONAL_APPEARANCE",
}

export interface CaseUser {
    readonly caseId: string;
    readonly userId: string;
    readonly originalUserId: string | null;
    readonly visibilityBegin: Date;
    readonly visibilityEnd: Date | null;
    readonly activitiesRead: number;
    readonly creator: boolean;
    readonly assignee: boolean;
    readonly closer: boolean;
    readonly caseActivitiesCount: number;
    readonly caseCreated: Date;
    readonly casePostponedUntil: Date | null;
    readonly caseClosed: Date | null;
    readonly caseLastActivity: Date | null;
    readonly caseLastActivityWithType: Date | null;
    readonly caseUrgencySortKey: number;
    readonly caseAppointmentTime: Date | null;
    readonly casePrioritySortKey: number;
    readonly caseAssigned: boolean;
}

export interface IndividualReceiver {
    readonly userId: string;
    readonly added: Date;
    readonly wasOnlineWhenAdded: boolean;
    readonly escalationGroupId: string | null;
    readonly currentLevel: number | null;
}

export interface EscalationGroupReceiver {
    readonly escalationGroupId: string;
    readonly added: Date;
    readonly currentLevel: number | null;
}

export interface CaseAttachment {
    readonly id: number;
    readonly filename: string;
    readonly size: number;
    readonly dataHash: string;
}

export interface CaseResult {
    readonly caseObj: Case;
    readonly contact: Contact | null;
    readonly contactVehicle: ContactVehicle | null;
}

export interface Case {
    readonly id: string;
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly creatorCtUserId: string | null;
    readonly updated: Date;
    readonly closed: Date | null;
    readonly closerUserId: string | null;
    readonly closerCtUserId: string | null;
    readonly outcomeReasonId: string | null;
    readonly outcomeDetails: string | null;
    readonly postponedUntil: Date | null;
    readonly users: CaseUser[];
    readonly assigneeIds: string[];
    readonly dealerId: string;
    readonly channel: CaseChannel;
    readonly caseType: string;
    readonly sentiment: number | null;
    readonly urgency: CaseUrgency;
    readonly appointmentTime: Date | null;
    readonly priority: CasePriority;
    readonly subject: string | null;
    readonly individualReceivers: IndividualReceiver[];
    readonly escalationGroupReceivers: EscalationGroupReceiver[];
    readonly contactId: string | null;
    readonly contactVehicleId: string | null;
    readonly attachments: CaseAttachment[];
    readonly escalationBegin: Date | null;
    readonly preferredContactMethod: ContactMethod | null;
    readonly preferredContactDetails: string | null;
    readonly earliestContactTime: Date | null;
    readonly latestContactTime: Date | null;
    readonly lastActivity: Date | null;
    readonly lastActivityWithType: Date | null;
    readonly lastActivityType: CaseLastActivityType | null;
    readonly activitiesCount: number;
    readonly externalReferences: string[];
}

export interface CaseConversionForm {
    readonly sourceId: string;
    readonly opportunityIndividualReceivers: string[];
    readonly opportunityTeamReceivers: string[];
}

export interface CaseCreationForm {
    readonly dealerId: string;
    readonly channel: CaseChannel;
    readonly caseType: string;
    readonly sentiment: number | null;
    readonly urgency: CaseUrgency;
    readonly appointmentTime: Date | null;
    readonly subject: string | null;
    readonly individualReceivers: string[];
    readonly escalationGroupReceivers: string[];
    readonly contactId: string | null;
    readonly contactVehicleId: string | null;
    readonly escalationBegin: Date | null;
    readonly preferredContactMethod: ContactMethod | null;
    readonly preferredContactDetails: string | null;
    readonly earliestContactTime: Date | null;
    readonly latestContactTime: Date | null;
    readonly externalReferences: string[];
}

export interface UserCaseStatus {
    readonly openCases: number;
    readonly openCasesWithUnreadActivities: number;
    readonly assignedCases: number;
    readonly assignedCasesWithUnreadActivities: number;
    readonly postponedCases: number;
    readonly postponedCasesWithUnreadActivities: number;
}

export enum CasesSort {
    LAST_ACTIVITY_DESC = "LAST_ACTIVITY_DESC",
    LAST_ACTIVITY_ASC = "LAST_ACTIVITY_ASC",
    POSTPONED_UNTIL_DESC = "POSTPONED_UNTIL_DESC",
    POSTPONED_UNTIL_ASC = "POSTPONED_UNTIL_ASC",
    CLOSED_DESC = "CLOSED_DESC",
    CLOSED_ASC = "CLOSED_ASC",
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    VISIBILITY_BEGIN_DESC = "VISIBILITY_BEGIN_DESC",
    VISIBILITY_BEGIN_ASC = "VISIBILITY_BEGIN_ASC",
    VISIBILITY_END_DESC = "VISIBILITY_END_DESC",
    VISIBILITY_END_ASC = "VISIBILITY_END_ASC",
    PRIORITY_DESC = "PRIORITY_DESC",
}

export enum CaseVisibilityArea {
    ACTIVE = "ACTIVE",
    POSTPONED = "POSTPONED",
    CREATED = "CREATED",
    CLOSED = "CLOSED",
}

export interface MyCasesResult {
    readonly totalSize: number;
    readonly caseUsers: CaseUser[];
    readonly searchId: number;
}

export interface PreferredContactForm {
    readonly preferredContactMethod: ContactMethod | null;
    readonly preferredContactDetails: string | null;
    readonly earliestContactTime: Date | null;
    readonly latestContactTime: Date | null;
}

export interface GeneralInfoForm {
    dealerId: string;
    channel: CaseChannel;
    caseType: string;
    sentiment: number | null;
    urgency: CaseUrgency;
    appointmentTime: Date | null;
    subject: string | null;
    escalationBegin: Date | null;
}

export interface CaseOutcomeForm {
    readonly outcomeReasonId: string | null;
    readonly outcomeDetails: string | null;
}

export interface CaseReceiversForm {
    individualReceivers: string[];
    escalationGroupReceivers: string[];
}

export interface CaseContactVehicleForm {
    contactId: string | null;
    contactVehicleId: string | null;
}

export enum CaseAuditEventType {
    CLOSED = "CLOSED",
    REOPENED = "REOPENED",
    POSTPONED = "POSTPONED",
    UNPOSTPONED = "UNPOSTPONED",
    UPDATED_ASSIGNEES = "UPDATED_ASSIGNEES",
    MERGED = "MERGED",
    UPDATED_OUTCOME_REASON = "UPDATED_OUTCOME_REASON",
    CONVERTED = "CONVERTED",
    UPDATED_RECEIVERS = "UPDATED_RECEIVERS",
}

export interface CaseAuditEvent {
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly creatorCtUserId: string | null;
    readonly caseId: string;
    readonly originalIssueId: string | null;
    readonly type: CaseAuditEventType;
    readonly postponedUntil: Date | null;
    readonly mergedIssueId: string | null;
    readonly oldAssignedUserIds: string[];
    readonly newAssignedUserIds: string[];
    readonly outcomeReasonId: string | null;
    readonly outcomeDetails: string | null;
    readonly convertedIssueId: string | null;
    readonly oldIndividualReceiverUserIds: string[];
    readonly newIndividualReceiverUserIds: string[];
    readonly oldEscalationGroupReceiverIds: string[];
    readonly newEscalationGroupReceiverIds: string[];
}

export enum CaseStatus {
    OPEN = "OPEN",
    ASSIGNED = "ASSIGNED",
    POSTPONED = "POSTPONED",
    CLOSED = "CLOSED",
}

interface CasesApi {
    addCase(
        caseCreationForm: CaseCreationForm,
        initialNoteContent: string,
        assignees: string[],
        files: File[],
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<string>;
    getMyCaseStatus(): Promise<UserCaseStatus>;
    getMyCases(
        caseVisibilityArea: CaseVisibilityArea,
        casesSort: CasesSort,
        start: number,
        size: number,
        searchId: number
    ): Promise<MyCasesResult>;
    getByIds(ids: string[]): Promise<CaseResult[]>;
    getById(id: string): Promise<CaseResult | null>;
    getPrintView(id: string): Promise<string>;
    updateRead(id: string): Promise<void>;
    closeCase(id: string, caseOutcomeForm: CaseOutcomeForm, disableNotifications?: boolean): Promise<void>;
    reopenCase(id: string): Promise<void>;
    postponeCase(id: string, postponedUntil: Date): Promise<void>;
    unpostponeCase(id: string): Promise<void>;
    updateAssignees(id: string, assignees: string[], disableNotifications?: boolean): Promise<void>;
    deleteCase(id: string): Promise<void>;
    mergeCase(targetCaseId: string, sourceCaseId: string): Promise<void>;
    updatePreferredContact(id: string, preferredContactForm: PreferredContactForm): Promise<void>;
    updateGeneralInfo(id: string, generalInfoForm: GeneralInfoForm): Promise<void>;
    updateOutcome(id: string, caseOutcomeForm: CaseOutcomeForm): Promise<void>;
    addAttachments(id: string, files: File[], progressCallback: (progressEvent: ProgressEvent) => void): Promise<void>;
    deleteAttachment(id: string, attachmentId: number): Promise<void>;
    generateAttachmentLink(id: string, attachment: CaseAttachment, download?: boolean): string;
    editReceivers(id: string, caseReceiversForm: CaseReceiversForm): Promise<void>;
    editContactAndVehicle(id: string, caseContactVehicleForm: CaseContactVehicleForm): Promise<void>;
    addNote(id: string, noteForm: NoteForm): Promise<void>;
    startCall(id: string, outgoingCallForm: OutgoingCallForm): Promise<void>;
    sendSms(id: string, outgoingSmsForm: OutgoingSmsForm): Promise<void>;
    sendEmail(
        id: string,
        outgoingEmailForm: OutgoingEmailForm,
        files: File[],
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
    sendWhatsAppMessage(
        id: string,
        accountId: string,
        form: OutgoingWhatsAppMessageForm,
        files: File[],
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
    getAuditEvents(id: string): Promise<CaseAuditEvent[]>;
    resolveAlias(alias: string): Promise<string | null>;
    convertCase(id: string, caseConversionForm: CaseConversionForm): Promise<string>;
}

export const casesApi: CasesApi = {
    async addCase(caseCreationForm, initialNoteContent, assignees, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }
        formData.append("form", new Blob([JSON.stringify(caseCreationForm)], { type: "application/json" }));
        formData.append("initialNoteContent", initialNoteContent);
        formData.append("assignees", new Blob([JSON.stringify(assignees)], { type: "application/json" }));
        return (await axios.post(`/api/cases`, formData, { onUploadProgress })).data;
    },

    async getMyCaseStatus() {
        return (await axios.get(`/api/cases/_my-case-status`)).data;
    },

    async getMyCases(caseVisibilityArea, casesSort, start, size, searchId) {
        return cloneObject(
            (
                await axios.get(`/api/cases/_my-cases`, {
                    params: { caseVisibilityArea, casesSort, start, size, searchId },
                })
            ).data
        );
    },

    async getByIds(ids) {
        return cloneObject((await axios.get(`/api/cases`, { params: { id: ids.join(",") } })).data);
    },

    async getById(id) {
        return cloneObject((await axios.get(`/api/cases/${id}`)).data);
    },

    async getPrintView(id) {
        const result: string = (await axios.get(`/api/cases/${id}/_print`)).data;
        return result || "";
    },

    async updateRead(id) {
        await axios.post(`/api/cases/${id}/_update-read`);
    },

    async closeCase(id, caseOutcomeForm, disableNotifications) {
        await axios.post(`/api/cases/${id}/_close`, caseOutcomeForm, { params: { disableNotifications } });
    },

    async reopenCase(id) {
        await axios.post(`/api/cases/${id}/_reopen`);
    },

    async postponeCase(id, postponedUntil) {
        await axios.post(`/api/cases/${id}/_postpone`, null, { params: { postponedUntil } });
    },

    async unpostponeCase(id) {
        await axios.post(`/api/cases/${id}/_unpostpone`);
    },

    async updateAssignees(id, assignees, disableNotifications) {
        await axios.post(`/api/cases/${id}/_update-assignees`, assignees, { params: { disableNotifications } });
    },

    async deleteCase(id) {
        await axios.delete(`/api/cases/${id}`);
    },

    async mergeCase(targetCaseId, sourceCaseId) {
        await axios.post(`/api/cases/${targetCaseId}/_merge`, null, {
            params: {
                sourceCaseId,
            },
        });
    },

    async updatePreferredContact(id, preferredContactForm) {
        await axios.post(`/api/cases/${id}/_update-preferred-contact`, preferredContactForm);
    },

    async updateGeneralInfo(id, generalInfoForm) {
        await axios.post(`/api/cases/${id}/_update-general-info`, generalInfoForm);
    },

    async updateOutcome(id, caseOutcomeForm) {
        await axios.post(`/api/cases/${id}/_update-outcome`, caseOutcomeForm);
    },

    async addAttachments(id, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }
        await axios.post(`/api/cases/${id}/attachments`, formData, { onUploadProgress });
    },

    async deleteAttachment(id, attachmentId) {
        await axios.delete(`/api/cases/${id}/attachments/${attachmentId}`);
    },

    generateAttachmentLink(id, attachment, download) {
        return `/api/cases/${id}/attachments/${attachment.id}/${attachment.dataHash}/${encodeURIComponent(
            attachment.filename.replace(/\//g, "-")
        )}${download ? "?dl=true" : ""}`;
    },

    async editReceivers(id, caseReceiversForm) {
        await axios.post(`/api/cases/${id}/_edit-receivers`, caseReceiversForm);
    },

    async editContactAndVehicle(id, caseContactVehicleForm) {
        await axios.post(`/api/cases/${id}/_edit-contact-and-vehicle`, caseContactVehicleForm);
    },

    async addNote(id, noteForm) {
        await axios.post(`/api/cases/${id}/_add-note`, noteForm);
    },

    async startCall(id, outgoingCallForm) {
        await axios.post(`/api/cases/${id}/_start-call`, outgoingCallForm);
    },

    async sendSms(id, outgoingSmsForm) {
        await axios.post(`/api/cases/${id}/_send-sms`, outgoingSmsForm);
    },

    async sendEmail(id, outgoingEmailForm, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }
        formData.append("form", new Blob([JSON.stringify(outgoingEmailForm)], { type: "application/json" }));
        await axios.post(`/api/cases/${id}/_send-email`, formData, { onUploadProgress });
    },

    async sendWhatsAppMessage(id, accountId, form, files, onUploadProgress) {
        const formData = new FormData();
        for (const file of files) {
            formData.append("file", file);
        }
        formData.append("accountId", accountId);
        formData.append("form", new Blob([JSON.stringify(form)], { type: "application/json" }));
        await axios.post(`/api/cases/${id}/_send-whatsapp`, formData, { onUploadProgress });
    },

    async getAuditEvents(id) {
        return cloneObject((await axios.get(`/api/cases/${id}/_audit-events`)).data);
    },

    async resolveAlias(alias) {
        return (await axios.get(`/api/cases/${alias}/_alias`)).data;
    },

    async convertCase(id, caseConversionForm) {
        return (await axios.post(`/api/cases/${id}/_convert`, caseConversionForm)).data;
    },
};
