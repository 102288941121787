/* eslint-disable prettier/prettier */
export default {
    "\"{0}\" hinzufügen": "Add \"{0}\"",
    "+ 0 weitere Benutzer | + 1 weiterer Benutzer | + {count} weitere Benutzer": "+ 0 additional users | + 1 additional user | + {count} additional users",
    "+ 0 weitere Fahrzeuge | + 1 weiteres Fahrzeug | + {count} weitere Fahrzeuge": "+ 0 additional vehicles | + 1 additional vehicle | + {count} additional vehicles",
    "+ 0 weitere Kontaktmethoden | + 1 weitere Kontaktmethode | + {count} weitere Kontaktmethoden": "+ 0 additional contact methods | + 1 additional contact method | + {count} additional contact methods",
    "+ 0 weitere Notizen | + 1 weitere Notiz | + {count} weitere Notizen": "+ 0 additional notes | + 1 additional note | + {count} additional notes",
    "0 Aktivitäten | 1 Aktivität | {count} Aktivitäten": "0 activities | 1 activity | {count} activities",
    "0 Fahrzeuge | 1 Fahrzeug | {count} Fahrzeuge": "0 vehicles | 1 vehicle | {count} vehicles",
    "0 Jahre | 1 Jahr | {count} Jahre": "0 years | 1 year | {count} years",
    "0 Minuten | 1 Minute | {count} Minuten": "0 minutes | 1 minute | {count} minutes",
    "0 Monate | 1 Monat | {count} Monate": "0 months | 1 month | {count} months",
    "0 Sekunden | 1 Sekunde | {count} Sekunden": "0 seconds | 1 second | {count} seconds",
    "0 Stunde | 1 Stunde | {count} Stunden": "0 hours | 1 hour | {count} hours",
    "0 Tage | 1 Tag | {count} Tage": "0 day | 1 day | {count} days",
    "1 - 2 Stunden": "1 - 2 hours",
    "1 - 2 Wochen": "1 - 2 weeks",
    "15 - 30 Minuten": "15 - 30 minutes",
    "2 - 4 Stunden": "2 - 4 hours",
    "2 - 4 Wochen": "2 - 4 weeks",
    "24 - 72 Stunden": "24 - 72 hours",
    "3 - 7 Tage": "3 - 7 days",
    "30 - 60 Minuten": "30 - 60 minutes",
    "4 - 24 Stunden": "4 - 24 hours",
    "5 - 15 Minuten": "5 - 15 minutes",
    "8x8 API Key": "8x8 API key",
    "< 5 Minuten": "< 5 minutes",
    ">= 4 Wochen": ">= 4 weeks",
    "API Schlüssel": "API key",
    "API Subscription": "API subscription",
    "API Subscription löschen": "Delete API subscription",
    "API-Typ": "API type",
    "AU {0}": "Exhaust inspection {0}",
    "Abbestellen von Desktop-Benachrichtigungen ist fehlgeschlagen": "Unsubscribing from desktop notifications has failed",
    "Abbrechen": "Cancel",
    "Abgerechnete Gesprächsminuten": "Billed call minutes",
    "Abgerechnete Minuten": "Billed minutes",
    "Abmelden": "Unsubscribe",
    "Abonnieren von Desktop-Benachrichtigungen ist fehlgeschlagen": "Subscribing to desktop notifications has failed",
    "Abrechnung": "Billing",
    "Abrechnungskonto": "Billing account",
    "Abschleppradius": "Towing radius",
    "Abschleppziel": "Towing target",
    "Absenden": "Send",
    "Absender": "Sender",
    "Absender Alias": "Sender alias",
    "Absender E-Mail-Domain": "Sender email domain",
    "Absender und Empfänger": "Sender and receiver",
    "Absolut": "Absolute",
    "Abteilung": "Department",
    "Abteilung bearbeiten": "Edit department",
    "Abteilung hinzufügen": "Add department",
    "Abteilung löschen": "Remove department",
    "Abteilung {0}": "Department {0}",
    "Abteilungen": "Departments",
    "Abwesenheit": "Absence",
    "Abwesenheit bearbeiten": "Edit absence",
    "Abwesenheit hinzufügen": "Add absence",
    "Abwesenheit löschen": "Delete absence",
    "Abwesenheiten": "Absences",
    "Abwesenheitsimport": "Absence import",
    "Administration": "Administration",
    "Adresse bearbeiten": "Edit address",
    "Adresse hinzufügen": "Add address",
    "Adresse löschen": "Delete address",
    "Adresszusatz": "Address",
    "Agenten-Feedback": "Agent feedback",
    "Aktionen": "Actions",
    "Aktiv": "Active",
    "Aktivitäten": "Activities",
    "Aktivitätstyp": "Activity type",
    "Aktivitätsverlauf": "Activity history",
    "Aktualisieren": "Refresh",
    "Aktualisierung läuft": "Update in progress",
    "Aktuelle Eskalationsstufe": "Current escalation level",
    "Aktuelle Laufleistung": "Current mileage",
    "Aktuelle Woche": "Current week",
    "Aktueller Monat": "Current month",
    "Aktuelles Jahr": "Current year",
    "Aktuelles Quartal": "Current quarter",
    "Alias": "Alias",
    "Alias Rufnummer": "Alias phone number",
    "Alle Anrufe": "All calls",
    "Alle Daten, die aktuell im Fall-Assistenten gespeichert sind, gehen verloren. Möchten Sie fortfahren?": "All saved data in the case assistant will be reset. Do you want to continue?",
    "Alle Daten, die aktuell im Notdienstvorgangs-Assistenten gespeichert sind, gehen verloren. Möchten Sie fortfahren?": "All saved data in the emergency incident assistant will be reset. Do you want to continue?",
    "Alle Daten, die aktuell im Suchauftrag-Assistenten gespeichert sind, gehen verloren. Möchten Sie fortfahren?": "All saved data in the search inquiry assistant will be reset. Do you want to continue?",
    "Alle Daten, die aktuell im Verkaufschancen-Assistenten gespeichert sind, gehen verloren. Möchten Sie fortfahren?": "All saved data in the opportunity assistant will be reset. Do you want to continue?",
    "Alle E-Mails": "All emails",
    "Alle Fälle": "All cases",
    "Alle Notdienstvorgänge": "All emergency incidents",
    "Alle Notizen": "All notes",
    "Alle Rufnummern reparieren": "Fix all phone numbers",
    "Alle SMS": "All text messages",
    "Alle Verkaufschancen": "All opportunities",
    "Alle Verkaufschancen, bei denen kein Fahrzeug erkannt wurde oder kein Filter zutrifft, werden diesem Team zugeordnet.": "All opportunities which do not match any filter or do not contain any vehicle will be assigned to this team.",
    "Alle WhatsApp-Nachrichten": "All WhatsApp messages",
    "Alle eingehenden E-Mails, die nicht an eine persönliche Adresse weitergeleitet wurden, werden an diese Adresse weitergeleitet.": "All incoming emails that have not been forwarded to a personal address will be forwarded to this address.",
    "Allen antworten": "Reply all",
    "Allgemeine Angaben": "General information",
    "Allgemeine Anrufeinstellungen": "General call settings",
    "Allgemeine Berichtseinstellungen": "General reporting settings",
    "Allgemeine E-Mail-Einstellungen": "General email settings",
    "Allgemeine Fall-Einstellungen": "General case settings",
    "Allgemeine Informationen": "General information",
    "Allgemeine Operationen": "General operations",
    "Allgemeine SMS-Einstellungen": "General text message settings",
    "Allgemeine Standorteinstellungen": "General location settings",
    "Allgemeine Verkaufschancen-Einstellungen": "General opportunity settings",
    "Als CSV herunterladen": "Download as CSV",
    "Als Datei herunterladen": "Download as file",
    "Als PDF anzeigen": "Show as PDF",
    "Als PNG herunterladen": "Download as PNG",
    "Als SVG herunterladen": "Download as SVG",
    "Als Standard festlegen": "Set as default",
    "Altes Passwort": "Old password",
    "An den linken Fensterrand verschieben": "Move to the left window border",
    "An den rechten Fensterrand verschieben": "Move to the right window border",
    "An zugeteilte Benutzer verteilen": "Distribute to assignees",
    "Anbieter": "Vendor",
    "Angabe des Grundes": "Specification of the reason",
    "Angebotsnummer": "Offer number",
    "Angefragte Bestandsfahrzeuge": "Requested inventory vehicles",
    "Angefragte Fahrzeuge": "Requested vehicles",
    "Angefragtes Bestandsfahrzeug löschen": "Remove requested inventory vehicle",
    "Angefragtes Fahrzeug": "Requested vehicle",
    "Angemeldet über {0}": "Logged in via {0}",
    "Angenommen": "Answered",
    "Angerufen": "Called",
    "Angezeigte Anruferkennung": "Displayed caller ID",
    "Anhänge": "Attachments",
    "Anhänge hinzufügen": "Add attachment",
    "Anhängerkupplung": "Trailer coupling type",
    "Ankündigung": "Announcement",
    "Ankündigung bearbeiten": "Edit announcement",
    "Ankündigung erstellen": "Create announcement",
    "Ankündigung löschen": "Delete announcement",
    "Ankündigungen": "Announcements",
    "Anliegen": "Issue",
    "Anmerkung": "Remark",
    "Anmerkungen": "Remarks",
    "Anmerkungen zum Status": "Status notes",
    "Annahmeeinstellungen": "Acceptance settings",
    "Annahmesperre ignorieren": "Ignore acceptance lock",
    "Annahmesperre in Minuten": "Acceptance lock in minutes",
    "Annahmezeiten": "Acceptance hours",
    "Annahmezeiten ignorieren": "Ignore acceptance hours",
    "Annehmen": "Accept",
    "Annehmer": "Acceptor",
    "Anonymer Teilnehmer": "Anonymous participant",
    "Anrede": "Salutation",
    "Anruf": "Call",
    "Anruf beenden": "End call",
    "Anruf starten": "Start call",
    "Anruf wird beendet": "Ending call",
    "Anruf wurde angenommen": "Call was answered",
    "Anruf wurde nicht angenommen": "Call was not answered",
    "Anruf während der Rufverteilung parallel mit der angegebenen Rufnummer verbinden": "Connect call to the given number in parallel during call distribution",
    "Anrufe": "Calls",
    "Anrufe blockieren": "Block calls",
    "Anrufe erlauben": "Allow calls",
    "Anrufe heute": "Calls today",
    "Anrufe nach Servicezeit": "Calls by service time",
    "Anrufeinstellungen": "Call settings",
    "Anrufentwicklung": "Call performance",
    "Anrufer": "Caller",
    "Anruferkennung": "Caller ID",
    "Anruferkennung bearbeiten": "Edit caller ID",
    "Anruferkennung hinzufügen": "Add caller ID",
    "Anruferkennung löschen": "Delete caller ID",
    "Anruferkennung übernehmen": "Take over caller ID",
    "Anruferkennungen": "Caller IDs",
    "Anrufverteilung": "Call distribution",
    "Anrufvolumen": "Call volume",
    "Ansage": "Announcement",
    "Ansage entfernen": "Remove announcement",
    "Ansagetext": "Announcement text",
    "Anschlussnummer": "Terminal number",
    "Anschrift": "Address",
    "Ansicht": "View",
    "Anteil": "Share",
    "Antriebsart": "Drive",
    "Antwortadresse": "Reply-To address",
    "Antworten": "Reply",
    "Antworten an diesen Absender sind möglich.": "Responding to this sender are possible.",
    "Antworten an diesen Absender sind nicht möglich.": "Responding to this sender is not possible.",
    "Antworten des Empfängers gehen auf diese E-Mail-Adresse ein.": "Replies of the receiver will be delivered to this email address.",
    "Antwortnachricht bei technischer Störung": "Response message at technical error",
    "Anweisung bearbeiten": "Edit instruction",
    "Anweisung hinzufügen": "Add instruction",
    "Anweisung löschen": "Delete instruction",
    "Anweisungen": "Instructions",
    "Anweisungen bei Pannen": "Breakdown instructions",
    "Anweisungen bei Unfällen": "Accident instructions",
    "Anweisungen für externe Agenten": "Instructions for external agents",
    "Anwesenheit": "Presence",
    "Anzahl Empfänger": "Receiver count",
    "Anzahl Passagiere": "Passengers count",
    "Anzahl aktiver Fälle mit ungelesenen Aktivitäten": "Active cases count with unread activities",
    "Anzahl aktiver Notdienstvorgänge am Hauptstandort": "Active emergency incidents at the main location",
    "Anzahl aktiver Notdienstvorgänge an allen Standorten": "Active emergency incidents at all locations",
    "Anzahl aktiver Verkaufschancen mit ungelesenen Aktivitäten": "Active opportunities count with unread activities",
    "Anzahl der Vorbesitzer": "Number of previous owners",
    "Anzulegender Vorgang": "Issue to be created",
    "Anzulegender Vorgang bei eingehenden Chats": "Created issue on incoming chats",
    "App ID": "App ID",
    "Arbeitszeiten": "Working hours",
    "Arbeitszeiten hinterlegen": "Add working hours",
    "Arbeitszeiten von {0}": "Working hours of {0}",
    "Art der Ansage": "Announcement type",
    "Art des Erstellers": "Creator type",
    "Art des Feedbacks": "Type of feedback",
    "Art des Schließers": "Closer type",
    "Art des Vorgangs": "Issue type",
    "Audiodatei": "Audio file",
    "Auf E-Mail-Signatur-Vorlage setzen": "Set to email signature template",
    "Auf SMS-Signatur-Vorlage setzen": "Set to text message signature template",
    "Auf dieser Rufnummer erhalten Sie einen Anruf, nach dessen Annahme Sie mit dem Angerufenen verbunden werden.": "You will get a call on this number. After you pick up the call you will be connected to the callee.",
    "Auf unverarbeitet setzen": "Set to unprocessed",
    "Auftrag": "Order",
    "Auftrag erstellen": "Create order",
    "Auftrag stornieren": "Cancel order",
    "Auftrag wird erstellt": "Order is being created",
    "Auftrag {0}": "Order {0}",
    "Auftrags-Assistent": "Order assistant",
    "Auftragstyp": "Order type",
    "Aus Sicht des Kontakts": "From the contact's point of view",
    "Ausblenden": "Hide",
    "Ausgehende Anrufe": "Outgoing calls",
    "Ausgehende Anruferkennung": "Outgoing caller id",
    "Ausgehende E-Mail": "Outgoing email",
    "Ausgehende E-Mails": "Outgoing emails",
    "Ausgehende SMS": "Outgoing text message",
    "Ausgehende WhatsApp-Nachricht": "Outgoing WhatsApp message",
    "Ausgehende WhatsApp-Nachrichten": "Outgoing WhatsApp messages",
    "Ausgehende und hochgeladene Dateien": "Outgoing and uploaded files",
    "Ausgehender Anruf": "Outgoing call",
    "Ausgewählte Benutzer": "Selected users",
    "Ausgewählte Empfänger": "Selected receivers",
    "Ausgewählte Eskalationsgruppen": "Selected escalation groups",
    "Ausgewählte Verkäuferteams": "Selected sales teams",
    "Ausgewählter Benutzer und/oder Kategorie": "Selected user and/or category",
    "Ausgewählter Kontakt": "Selected contact",
    "Ausgewähltes Fahrzeug": "Selected vehicle",
    "Ausnahme entfernen": "Remove exception",
    "Ausnahme hinzufügen": "Add exception",
    "Ausnahmeeinstellungen": "Exception settings",
    "Ausnahmen": "Exceptions",
    "Ausnahmen für Lesebestätigungen": "Exceptions for read receipts",
    "Ausstattung": "Features",
    "Auswahl": "Selection",
    "Auswertung": "Evaluation",
    "Auto-Responder": "Auto responder",
    "AutoCRM Benutzereinstellungen": "AutoCRM user settings",
    "AutoCRM Benutzereinstellungen bearbeiten": "Edit AutoCRM user settings",
    "AutoCRM E-Mail-Adresse": "AutoCRM email address",
    "AutoCRM Lead-Mailer-Einstellungen": "AutoCRM lead mailer settings",
    "AutoCRM Verkaufschancen-Assistent": "AutoCRM Opportunity assistant",
    "Automatische Anrufbeantwortung": "Automatic call answering",
    "Automatische Antwort": "Automatic respond",
    "Automatische Antwort außerhalb der Geschäftszeiten": "Automatic response outside office hours",
    "Automatische Antwort innerhalb der Geschäftszeiten": "Automatic response inside office hours",
    "Automatische Antwort versenden": "Send auto reply",
    "Automatische Deaktivierung bei Treffer": "Automatic disabling on match",
    "Automatische Löschung": "Automatic deletion",
    "Automatische Löschung am {0}": "Automatic deletion at {0}",
    "Automatische Verarbeitung verhindern": "Prevent automatic processing",
    "Automatische Verkäuferzuweisung unterbinden": "Prevent automatic salesperson assignment",
    "Außenfarbe": "Exterior color",
    "Außerhalb der Geschäftszeiten kann eine abweichende Nachricht gesendet werden.": "Outside office hours, a different message can be sent.",
    "BCC-Empfänger": "BCC receivers",
    "Bank": "Bank",
    "Bankdaten": "Bank data",
    "Bankdaten löschen": "Delete bank data",
    "Bankdaten verarbeiten": "Process bank data",
    "Bankdaten-Suchindex neu aufbauen": "Recreate bank data search index",
    "Bankdatenimport": "Bank data import",
    "Bankdatenquelle bearbeiten": "Edit bank data",
    "Bankdatenquelle hinzufügen": "Add bank data",
    "Bankdatenquelle löschen": "Delete bank data",
    "Beantworter hinzufügen": "add answering user",
    "Bearbeiten": "Edit",
    "Bearbeiter": "Processor",
    "Bearbeitet": "Processed",
    "Bearbeitungsdauer": "Processing time",
    "Bearbeitungsdauer (Median)": "Processing time (median)",
    "Bearbeitungsdauer geschlossener Fälle": "Processing time of closed cases",
    "Bearbeitungsdauer geschlossener Verkaufschancen": "Processing time of closed opportunities",
    "Bearbeitungsdauerentwicklung": "Processing time performance",
    "Bearbeitungsmodus beenden": "Exit edit mode",
    "Beginn der Abwesenheit": "Begin of absence",
    "Beginn der Arbeitszeit": "Work time begin",
    "Beginn der Geschäftszeit": "Begin of office hours",
    "Begrüßungsformel": "Salutation",
    "Bei ausgewählten Geschäftszeiten können Sie getrennte Auto-Responder-Nachrichten festlegen.": "If office hours are selected you can set separate auto responder messages.",
    "Bei eingehenden Anrufen SIP-Status 180 (Ringing) / 183 (Session Progress) statt 200 schicken": "Send SIP status 180 (Ringing) / 183 (Session Progress) instead of 200 for incoming calls",
    "Bei eingehenden Chats, wird ein Fall erstellt.": "Case will be created on incoming chat.",
    "Bei eingehenden Chats, wird eine Verkaufschance erstellt.": "Opportunity will be created on incoming chat.",
    "Beim Wechseln der Seite werden nicht gespeicherte Änderungen verloren gehen.": "Unsaved changes will be lost when you change the page.",
    "Beliebig": "Any",
    "Benachrichtigungen": "Notifications",
    "Benachrichtigungseinstellungen": "Notification settings",
    "Benachrichtigungsempfänger": "Notification receiver",
    "Benachrichtigungsempfänger hinzufügen": "Add notification receiver",
    "Benachrichtigungsempfänger löschen": "Delete notification receiver",
    "Benutzer": "User",
    "Benutzer abwählen": "Deselect user",
    "Benutzer auswählen": "Select user",
    "Benutzer bearbeiten": "Edit user",
    "Benutzer erfolgreich erstellt!": "User created successfully!",
    "Benutzer hinzufügen": "Add user",
    "Benutzer löschen": "Delete user",
    "Benutzer ohne Abteilung": "Users without department",
    "Benutzer ohne Kategorie": "Users without category",
    "Benutzer und/oder Kategorie abwählen": "Deselect user and/or category",
    "Benutzer-Ausnahmen": "User exceptions",
    "Benutzer-E-Mail": "User email",
    "Benutzer-E-Mail-Domain hinzufügen": "Add user email domain",
    "Benutzer-E-Mail-Domain löschen": "Delete user email domain",
    "Benutzer-E-Mail-Domains": "User email domains",
    "Benutzerauswahl": "User selection",
    "Benutzerdefinierte Domain": "Custom domain",
    "Benutzerdefinierte Rufnummer": "Custom phone number",
    "Benutzername": "Username",
    "Benutzerverwaltung": "User management",
    "Benutzerverzeichnis": "User directory",
    "Benutzerzuordnung löschen": "Delete user mapping",
    "Benutzerzuordnungen": "User mappings",
    "Berechtigungsschlüssel für Dienstkonto \"{0}\"": "Access key for service account \"{0}\"",
    "Berichte": "Reports",
    "Beschreibung": "Description",
    "Beschreibung der Dienstleistung": "Service description",
    "Beschädigt": "Damaged",
    "Bestandsfahrzeug": "Inventory vehicle",
    "Bestandsfahrzeuge auswählen": "Add inventory vehicles",
    "Bestätigen": "Confirm",
    "Bestätigung": "Confirmation",
    "Bestätigung erforderlich": "Confirmable",
    "Bestätigungspflicht": "Confirmable",
    "Betreff": "Subject",
    "Bevorstehende Feiertage": "Upcoming holidays",
    "Bevorzugte Adressen": "Preferred addresses",
    "Bevorzugte Eskalationsgruppe": "Preferred escalation group",
    "Bevorzugte Marken": "Preferred makes",
    "Bevorzugter Abholzeitpunkt": "Preferred pick up time",
    "Bevorzugter Lieferzeitpunkt": "Preferred delivery time",
    "Bilder": "Images",
    "Bitte geben Sie den Token ein, der per SMS an {0} geschickt wurde.": "Please enter the token that was sent via text message to {0}.",
    "Bitte geben Sie eine gültige FTP-URL mit Benutzernamen und Passwort ein.": "Please enter a valid FTP URL with username and password.",
    "Bitte geben Sie eine gültige HTTPS-URL ein.": "Please enter a valid HTTPS URL.",
    "Bitte geben Sie einen gültigen Wert ein.": "Please enter a valid value.",
    "Bitte klicken Sie auf den folgenden Link:": "Please click on the following link:",
    "Bitte klicken Sie auf folgenden Link, um dem Videochat beizutreten:": "Please click the following link to join the videochat:",
    "Bitte korrigieren Sie Ihre fehlerhaften Eingaben.": "Please check your input.",
    "Bitte loggen Sie sich erneut ein.": "Please log in again.",
    "Bitte loggen Sie sich in das Backend ein und erzeugen einen neuen Status-Dashboard-Link bzw. QR-Code.": "Please login to the backend and create a new status dashboard link or QR code.",
    "Bitte scannen Sie den folgenden QR-Code:": "Please scan the following QR code:",
    "Bitte scannen Sie nachfolgenden QR-Code, um dem Videochat mit Ihrem Smartphone beizutreten.": "Please scan the QR code below to join this videochat with your smartphone.",
    "Bitte schließen oder speichern Sie Ihre Änderung bevor Sie fortfahren.": "Please close or save your changes before you can proceed.",
    "Bitte warten. In Kürze wird Ihnen ein Berater zur Verfügung stehen.": "Please wait. A consultant will be available shortly.",
    "Blockieren": "Block",
    "Blockiert": "Blocked",
    "Blockierte Dateiendungen": "Blocked file extensions",
    "Bruttobetrag": "Gross amount",
    "CARRY Dispo Adressen": "CARRY Dispo addresses",
    "CARRY Dispo Auftrags-Assistent": "CARRY Dispo order assistant",
    "CARRY Dispo Aufträge": "CARRY Dispo orders",
    "CARRY Dispo Einstellungen": "CARRY Dispo settings",
    "CC-Empfänger": "CC receivers",
    "Call-Center-Team": "Call Center Team",
    "Call-Tracking": "Call tracking",
    "Call-Tracking-Gruppe": "Call tracking group",
    "Call-Tracking-Gruppe bearbeiten": "Edit call tracking group",
    "Call-Tracking-Gruppe hinzufügen": "Add call tracking group",
    "Call-Tracking-Gruppe löschen": "Delete call tracking group",
    "Call-Tracking-Gruppen": "Call tracking groups",
    "Call-Tracking-Nummer": "Call tracking number",
    "Call-Tracking-Nummern": "Call tracking numbers",
    "Catch-All Filter": "Catch-all filter",
    "CitNOW Einstellungen": "CitNOW settings",
    "CitNOW LID": "CitNOW LID",
    "CitNOW Standorteinstellungen": "CitNOW location settings",
    "CitNOW Video": "CitNOW video",
    "CitNOW ist für den aktuellen Standort nicht konfiguriert. Bitte wenden Sie sich an unseren Support.": "CitNOW is not configured for the current location. Please contact our support.",
    "CitNOW-Account-Nummer": "CitNOW account number",
    "Client ID": "Client ID",
    "Client Secret": "Client Secret",
    "Contact rate": "Contact rate",
    "Conversion Rate": "Conversion Rate",
    "DD.MM": "MM/DD",
    "DNS-Daten": "DNS data",
    "Das Benutzerprofil wurde gespeichert.": "The user profile was saved.",
    "Das Bestandsfahrzeug konnte nicht geladen werden.": "The inventory vehicle could not be loaded.",
    "Das Ende der Abwesenheit darf nicht vor dem Beginn liegen.": "The end of the absence must not be before the begin of the absence.",
    "Das Ende der Arbeitszeit muss nach dem Anfang der Arbeitszeit liegen.": "The end of the work time must be after the begin of the work time.",
    "Das Exposé konnte nicht heruntergeladen werden.": "The exposé could not be downloaded.",
    "Das Fahrzeug konnte nicht geladen werden.": "The vehicle could not be loaded.",
    "Das Fahrzeug wird geladen.": "Loading vehicle",
    "Das Fahrzeug wurde gelöscht.": "The vehicle was deleted.",
    "Das Feld enthält eine pseudonymisierte E-Mail-Adresse.": "The field contains a pseudonymized email address.",
    "Das Format der ausgewählten Datei wird nicht unterstützt.": "The format of the selected file is not supported.",
    "Das Format wurde nicht erkannt. Wir implementieren dies gerne für Sie, bitte wenden Sie sich an unseren Kundendienst: ": "The format was not recognized. We would like to implement it for you. Please contact us: ",
    "Das Format wurde nicht erkannt. Wir implementieren dies gerne für Sie, bitte wenden Sie sich an unseren Support.": "The format was not recognized. We would like to implement it for you. Please contact our support.",
    "Das Telefon dieses Benutzers klingelt.": "The phone of this user is ringing.",
    "Das Verkäuferteam gehört nicht zum ausgewählten Standort.": "The sales team does not belong to the selected location.",
    "Das Verkäuferteam konnte nicht geladen werden.": "The sales team could not be loaded.",
    "Das Ändern des Passwortes ist nicht mehr möglich. Lassen Sie das Passwort ggf. erneut zurücksetzen.": "You cannot change the password anymore. You may want to request another password reset.",
    "Dashboard": "Dashboard",
    "Datei ({0})": "File ({0})",
    "Datei analysieren": "Analyze file",
    "Datei hochladen": "Upload file",
    "Datei löschen": "Delete file",
    "Datei mit Händlereinstellungen": "Dealer settings file",
    "Datei wird analysiert": "File is being analyzed",
    "Datei wird heruntergeladen": "File is being downloaded",
    "Datei wird hochgeladen": "File is being uploaded",
    "Dateieinstellungen": "File settings",
    "Dateien": "Files",
    "Dateien hier ablegen": "Drop files here",
    "Dateien hinzufügen": "Add file",
    "Dateien hochladen": "Upload files",
    "Dateien werden hochgeladen": "Files are being uploaded",
    "Dateiname": "Filename",
    "Daten aktualisieren": "Apply data",
    "Daten der E-Mail": "Email data",
    "Daten der SMS": "Text message data",
    "Daten der Verkaufschance": "Opportunity details",
    "Daten des Anrufes": "Call data",
    "Daten des Auftrags": "Order data",
    "Daten des Falls": "Case details",
    "Daten des Notdienstvorgangs": "Emergency incident details",
    "Daten des Suchauftrags": "Search inquiry data",
    "Daten werden aktualisiert": "Data is being updated",
    "Daten zum Anrufer": "Caller details",
    "Daten zum Einsatzort": "Location data",
    "Daten zum Fahrzeug": "Vehicle data",
    "Daten zum Zielort": "Destination data",
    "Datenimport": "Data import",
    "Datenprobleme erkannt": "Data problems detected",
    "Datum": "Date",
    "Datum der Aufnahme": "Record date",
    "Datum und Zeit wählen": "Choose date and time",
    "Datum vorhanden": "Date available",
    "Dauer von Erstellung bis zur Zuteilung (Durchschnitt)": "Duration from creation to assignment (average)",
    "Dauer von Erstellung bis zur ersten Kontaktaufnahme (Durchschnitt)": "Duration from creation to first contact (average)",
    "Dauer von Zuteilung bis zur ersten Kontaktaufnahme": "Duration from assignment to first contact",
    "Dauerhaft eingeloggt bleiben": "Stay logged in permanently",
    "Deinstallieren": "Uninstall",
    "Dem Benutzer wurde eine E-Mail zum Neusetzen des Passworts geschickt.": "An email has been sent to the user to reset the password.",
    "Dem Verkäuferteam sind keine Geschäftszeiten zugeordnet.": "No office hours are assigned to the sales team.",
    "Der Anhang ist zu groß.": "Attachment is too large.",
    "Der Anruf endete hier und wurde durch einen Benutzer angenommen.": "The call ended here and was answered by a user.",
    "Der Anruf endete hier und wurde nicht angenommen.": "The call ended here and was not answered.",
    "Der Anruf kam von außerhalb des Systems (z.B. per Weiterleitung durch die Telefonanlage im Standort oder per Direktanruf).": "The call came from outside the system (e.g. by forwarding through the PBX at the location or by direct call).",
    "Der Anruf wurde nicht angenommen und an eine andere Call-Tracking-Nummer, an das externe BDC oder an eine benutzerdefinierte Rufnummer weitergeleitet.": "The call was not answered and forwarded to another call tracking number, to the external BDC, or to a custom phone number.",
    "Der Anruf wurde von einer anderen Call-Tracking-Nummer weitergeleitet.": "The call was forwarded from another call tracking number.",
    "Der Benutzer wurde nicht gefunden.": "The user was not found.",
    "Der CARRY Dispo Zugang für diesen Standort wird geprüft.": "The CARRY Dispo account for this location is being checked.",
    "Der CNAME-Eintrag enthält einen nicht erwarteten Wert: {0}": "The CNAME entry contains an unexpected value: {0}",
    "Der CNAME-Eintrag ist nicht gesetzt.": "The CNAME entry is not set.",
    "Der Einzelempfänger ist am ausgewählten Standort nicht verfügbar.": "The individual receiver is not available at the selected location.",
    "Der Empfänger hat einen Auto-Responder aktiviert. ({0})": "The receiver has an active auto responder. ({0})",
    "Der Endpunkt kann nicht partiell konfiguriert werden.": "The endpoint cannot be partially defined.",
    "Der Eskalationsgruppe sind keine Geschäftszeiten zugeordnet.": "No office hours are assigned to the escalation group.",
    "Der FTP-Server muss FTPS-fähig sein.": "The FTP server must be FTPS capable.",
    "Der Fall ist Ihnen bereits zugeteilt.": "The case is already assigned to you.",
    "Der Fall konnte Ihnen nicht zugeteilt werden.": "The case could not be assigned to you.",
    "Der Fall muss Ihnen zugeteilt sein": "The case has to be assigned to you",
    "Der Fall wurde Ihnen zugeteilt.": "The case has been assigned to you.",
    "Der Fall wurde bereits geschlossen.": "The case has already been closed.",
    "Der Fall wurde nicht gefunden.": "The case was not found.",
    "Der Fall-Typ, der erstellen Fällen zugeordnet werden soll.": "Case type to be assigned to created cases.",
    "Der Inhalt der E-Mail wurde nicht gefunden.": "The content of the email was not found.",
    "Der Kontakt konnte nicht geladen werden.": "The contact could not be loaded.",
    "Der Kontakt wurde gelöscht.": "The contact was deleted.",
    "Der Kontakt wurde nicht gefunden.": "The contact was not found.",
    "Der Mitarbeiter ist bereits zugeordnet.": "The user is already mapped.",
    "Der Notdienstvorgang wurde bereits geschlossen.": "The emergency incident has already been closed.",
    "Der Notdienstvorgang wurde nicht gefunden.": "The emergency incident was not found.",
    "Der Standort bietet für diese Marke keine Dienstleistung an.": "The location does not offer a service for this make.",
    "Der Standort ist an diesem Tag geschlossen": "Location is closed at this date",
    "Der Standort ist bereits zugeordnet.": "The location is already mapped.",
    "Der Standort ist nicht aktiv.": "The location is not active.",
    "Der Standort wurde nicht gefunden.": "The location was not found.",
    "Der Status eines Bestandsfahrzeuges hat sich verändert.": "Inventory vehicle status has changed.",
    "Der Suchauftrag wurde nicht gefunden.": "The search inquiry was not found.",
    "Der TXT-Eintrag enthält einen nicht erwarteten Wert: {0}": "The TXT entry contains an unexpected value: {0}",
    "Der TXT-Eintrag ist nicht gesetzt.": "The TXT entry is not set.",
    "Der Verkäufer ist bereits zugeordnet.": "The salesperson is already mapped.",
    "Der Wert wurde erfolgreich in die Zwischenablage kopiert.": "The value was copied to clipboard successfully.",
    "Der Zeitpunkt liegt in der Vergangenheit.": "The date and time is in the past.",
    "Der Zurückstellungszeitpunkt muss in der Zukunft liegen.": "The postponement time must be in the future.",
    "Der angeklickte Link führt auf eine externe Seite. Möchten Sie fortfahren?": "The link you click leads to an external page. Do you want to continue?",
    "Der ausgewählte Standort ist inaktiv.": "The selected location is inactive.",
    "Der ausgewählte Zeitraum ist zu lang.": "The selected time span is too long.",
    "Der eingegebene Token ist nicht korrekt.": "The token entered is incorrect.",
    "Der früheste gewünschte Kontaktzeitpunkt muss vor dem spätesten gewünschten Kontaktzeitpunkt liegen.": "The earliest preferred contact time must be before the latest preferred contact time.",
    "Design": "Theme",
    "Desktop-Benachrichtigung": "Desktop notification",
    "Desktop-Benachrichtigungen werden nicht unterstützt": "Desktop notifications are not supported",
    "Detailansicht bei CARRY Dispo generieren": "Generate detail page in CARRY Dispo",
    "Details": "Details",
    "Details des Erwerbs": "Acquisition details",
    "Diagnose": "Diagnosis",
    "Die Anwendung wurde aktualisiert.": "The application has been updated.",
    "Die AutoCRM Konfiguration für diesen Standort wird geprüft.": "The AutoCRM configuration for this location is being checked.",
    "Die Datei ist bis zum {0} verfügbar.": "The file is available until {0}.",
    "Die Datei muss vom Typ {0} sein.": "The file must be of type {0}.",
    "Die Datei wird auf unseren Server hochgeladen und anschließend ein Link zum Herunterladen in die E-Mail eingefügt.": "The file is uploaded to our server and then a download link is included in the email.",
    "Die Daten im DNS stimmen mit den erwarteten Werten überein.": "The data in the DNS matches the expected values.",
    "Die Daten im DNS stimmen nicht mit den erwarteten Werten überein.\\n\\n{0}": "The data in the DNS does not match the expected values.\\n\\n{0}",
    "Die Daten wurden erfolgreich am {0} aktualisiert!": "Data was successfully updated {0}",
    "Die Detailansicht konnte nicht generiert werden.": "The detail page could not be generated.",
    "Die Dublettenbereinigung wurde gestartet und läuft im Hintergrund solange, bis keine weiteren Dubletten mehr gefunden werden können. Dies kann mehrere Stunden in Anspruch nehmen.": "The duplicate removal has been started and runs in the background until no more duplicates can be found. This may take several hours.",
    "Die E-Mail wurde nicht gefunden.": "The email was not found.",
    "Die E-Mail wurde vom Empfänger als Spam markiert. ({0})": "Email was marked as spam by the receiver. ({0})",
    "Die E-Mail wurde vom Empfänger oder vom System des Empfängers als Spam markiert. ({0})": "Email was marked as spam by the receiver or his system. ({0})",
    "Die Einstellung betrifft sowohl eingehende als auch ausgehende Anrufe.": "The setting applies to both incoming as well as outgoing calls.",
    "Die Einstellung betrifft sowohl eingehende als auch ausgehende SMS und WhatsApp-Nachrichten.": "The setting applies to both incoming as well as outgoing text messages and WhatsApp messages.",
    "Die Einstellungen konnten nicht geladen werden.": "The settings could not be loaded.",
    "Die Empfängerwahl schränkt die Standortwahl weiter ein. Sollten keine Empfänger ausgewählt sein, wird die E-Mail an alle Benutzer der gewählten Standorte versendet.": "The receiver selection further restricts the location selection. If no receivers are selected, the email will be sent to all users of the selected locations.",
    "Die Eskalationsgruppe gehört nicht zum ausgewählten Standort.": "The escalation group does not belong to the selected location.",
    "Die Eskalationsgruppe konnte nicht geladen werden.": "The escalation group could not be loaded.",
    "Die Eskalationsgruppe wird noch in folgenden Einstellungen verwendet:\\n{0}\\n\\nSind Sie sicher, dass Sie die Eskalationsgruppe {1} löschen möchten?": "The escalation group is still in use in the following settings:\\n{0}\\n\\n Are you sure you want to remove the escalation group {1}?",
    "Die Fahrzeuge der versandten Exposés wurden zur Liste der angefragten Bestandsfahrzeuge hinzugefügt.": "The vehicles of the sent exposés have been added to the list of requested inventory vehicles.",
    "Die Gesamtgröße der E-Mail inkl. der Anhänge ist zu groß. Soll die E-Mail in mehreren Teilen verschickt werden?": "The total size of the email including the attachments is too large. Should the email be sent in several parts?",
    "Die Gesamtwartezeit (d.h. die kumulierte Wartezeit über alle Weiterleitungen hinweg) geht vollständig bei der Call-Tracking-Nummer ein, bei der der Anruf endete.": "The total wait time (i.e., the cumulative wait time across all forwardings) goes entirely to the call tracking number where the call ended.",
    "Die Gesprächsdauer geht vollständig bei der Call-Tracking-Nummer ein, bei der der Anruf endete.": "The duration of the call goes entirely to the call tracking number where the call ended.",
    "Die Nachricht ist zu lang.": "The message is too long.",
    "Die Nummer ist möglicherweise nicht gültig.": "The number might not be valid.",
    "Die Quelle ist bereits zugeordnet.": "The source is already mapped.",
    "Die Quelle, die erstellten Verkaufschancen zugeordnet werden soll.": "Opportunity source to be assigned to created opportunities.",
    "Die Rufnummer der Angerufenen.": "Phone number of the callee.",
    "Die Rufnummer des Empfängers.": "Phone number of the recipient",
    "Die Verbindung konnte erfolgreich hergestellt werden.": "The connection was successfully established.",
    "Die Verkaufschance ist Ihnen bereits zugeteilt.": "The opportunity is already assigned to you.",
    "Die Verkaufschance ist nur für Sie sichtbar.": "The opportunity is only visible to you.",
    "Die Verkaufschance konnte Ihnen nicht zugeteilt werden.": "The opportunity could not be assigned to you.",
    "Die Verkaufschance muss Ihnen zugeteilt sein": "The opportunity has to be assigned to you",
    "Die Verkaufschance wurde Ihnen zugeteilt.": "The opportunity has been assigned to you.",
    "Die Verkaufschance wurde bereits geschlossen.": "The opportunity has already been closed.",
    "Die Verkaufschance wurde erfolgreich übermittelt.": "The opportunity has been successfully submitted.",
    "Die Verkaufschance wurde nicht gefunden.": "The opportunity was not found.",
    "Die Zustellung ist aufgrund einer Virus-Warnung fehlgeschlagen. ({0})": "Delivery failed due to a virus notification. ({0})",
    "Die aufgerufene Seite wurde nicht gefunden.": "The requested page was not found",
    "Die ausgewählte Vorlage enthält Platzhalter, die nicht für automatische Antworten verwendet werden können.": "The selected template contains placeholders that cannot be used for automatic replies.",
    "Die eingegebenen Passwörter stimmen nicht überein.": "The entered passwords are different",
    "Die in dieser Schnellauswahl gespeicherten Werte sind nicht mehr verfügbar.": "The values stored in this quick selection are no longer available.",
    "Die maximale Anzahl an Zustellversuchen für den Token wurde erreicht.": "The maximum number of token delivery attempts has been reached.",
    "Die mit * markierten Platzhalter können nicht für automatische Antworten verwendet werden.": "The placeholders marked with * cannot be used for automatic replies.",
    "Die untere Grenze der Laufleistung übersteigt die gewählte obere Grenze.": "The lower mileage limit exceeds the chosen upper limit.",
    "Dienstkonten": "Service accounts",
    "Dienstkonto hinzufügen": "Add service account",
    "Dienstkonto löschen": "Delete service account",
    "Dienstleister": "Service provider",
    "Dienstleistung bearbeiten": "Edit service",
    "Dienstleistung hinzufügen": "Add service",
    "Dienstleistung löschen": "Delete service",
    "Dienstleistungen": "Services",
    "Diese Absenderkennung ist nicht gültig.": "This sender ID is not valid.",
    "Diese E-Mail-Adresse sieht der Empfänger als Absender.": "This email address is visible to the receiver as the sender.",
    "Diese Einstellungen gelten für die gesamte Händlergruppe.": "These settings apply for the entire dealer group.",
    "Diese Nachricht wird automatisch außerhalb der Geschäftszeiten als Antwort auf eingehende Chats gesendet.": "This message will automatically be sent outside office hours.",
    "Diese Nachricht wird automatisch innerhalb der Geschäftszeiten als Antwort auf eingehende Chats gesendet.": "This message will automatically be sent inside office hours.",
    "Diese Nachricht wurde vom Sender gelöscht.": "This message was deleted by the sender.",
    "Diese Rufnummer kann nicht gelöscht werden, da auf diese noch von anderen Rufnummern weitergeleitet wird.": "This phone number cannot be deleted because other phone numbers are being forwarded to it.",
    "Diese Rufnummer sieht der Angerufene auf dem Display.": "This phone number is visible on the display of the callee.",
    "Diese Seite steht nur mit einem ausgewählten Standort zur Verfügung.": "This page is only available with a selected location.",
    "Diese Verkaufschance ist nicht mehr verfügbar.": "This opportunity is not available anymore.",
    "Diesem Team priorisiert Verkaufschancen zuordnen": "Assign opportunities to this team as a priority",
    "Dieser Benutzer telefoniert gerade.": "This user is in an active call right now.",
    "Dieser Fall ist aktuell für keinen Benutzer aktiv.": "This case is currently not active for any user.",
    "Dieser Fall ist nicht mehr verfügbar.": "This case is not available anymore.",
    "Dieser Filter trifft auf alle Verkaufschancen zu.": "This filter matches all opportunities.",
    "Dieser Link ist abgelaufen.": "This link has expired.",
    "Dieser Woche": "This week",
    "Dieses Feld ist erforderlich.": "This field is required.",
    "Dieses Label wird im Homepage-Widget angezeigt. Falls Sie kein Label angeben, wird diese Nummer nicht im Widget angezeigt.": "This label will be shown on the homepage widget. If you do not specify a label, this number will not be shown on the widget.",
    "Dieses Verkäuferteam wird automatisch keine Verkaufschancen zugewiesen bekommen, da kein Filter vorhanden ist.": "No opportunities are automatically assigned to this sales team, as no filter is available.",
    "Domain": "Domain",
    "Dringlichkeit": "Urgency",
    "Drittanbieter": "Third parties",
    "Drucken": "Print",
    "Drücken Sie OK nachdem die Anruferkennung validiert wurde.": "Press OK after validating the caller ID.",
    "Dubletten entfernen": "Remove duplicates",
    "Dublettenbereinigung": "Duplicate removal",
    "Dunkel": "Dark",
    "Durchführungsmöglichkeit": "Execution possibility",
    "Durchführungstermine": "Execution dates",
    "Durchschnitt": "Average",
    "E-Mail": "Email",
    "E-Mail absenden": "Send email",
    "E-Mail außerhalb Anwesenheit": "Email outside presence",
    "E-Mail befindet sich in Zustellung.": "Email is being delivered.",
    "E-Mail innerhalb Anwesenheit": "Email within presence",
    "E-Mail wird gesendet": "Sending email",
    "E-Mail wurde 0 Mal geöffnet. | E-Mail wurde 1 Mal geöffnet. | E-Mail wurde {count} Mal geöffnet.": "Email was opened 0 times. | Email was opened 1 time. | Email was opened {count} times.",
    "E-Mail wurde erfolgreich zugestellt. ({0})": "Email was delivered successfully. ({0})",
    "E-Mail wurde versandt": "Email was sent",
    "E-Mail zu groß": "Email too large",
    "E-Mail-Adresse": "Email address",
    "E-Mail-Adresse hinzufügen": "Add email address",
    "E-Mail-Adresse ist erforderlich": "Email address is required",
    "E-Mail-Adresse löschen": "Delete email address",
    "E-Mail-Adresse oder Passwort sind nicht korrekt.": "Email address or password is incorrect.",
    "E-Mail-Adresse {0}": "Email address {0}",
    "E-Mail-Adresse öffentlich ?": "Email address publishable ?",
    "E-Mail-Adressen": "Email addresses",
    "E-Mail-Alias hinzufügen": "Add email alias",
    "E-Mail-Aliasse": "Email aliases",
    "E-Mail-Aliasse hinzufügen": "Add email aliases",
    "E-Mail-Anhänge mit diesen Dateiendungen werden herausgefiltert.": "Email attachments with these file extensions will be filtered out.",
    "E-Mail-Domain": "Email domain",
    "E-Mail-Format": "Email format",
    "E-Mail-Signatur": "Email signature",
    "E-Mail-Signatur auf E-Mail-Signatur-Vorlage zurücksetzen": "Reset email signature to email signature template",
    "E-Mail-Signatur-Vorlage": "Email signature template",
    "E-Mail-Signatur-Vorlage bearbeiten": "Edit email signature template",
    "E-Mail-Signaturen zurücksetzen?": "Reset email signatures?",
    "E-Mail-Textbaustein bearbeiten": "Edit email fragment",
    "E-Mail-Textbaustein hinzufügen": "Add email fragment",
    "E-Mail-Textbausteine": "Email fragments",
    "E-Mail-Vorlage bearbeiten": "Edit email template",
    "E-Mail-Vorlage hinzufügen": "Add email template",
    "E-Mail-Vorlagen": "Email template",
    "E-Mails": "Emails",
    "E-Mails empfangen": "Receive emails",
    "E-Mails heute": "Emails today",
    "E-Mails, die auf dieser Adresse eingehen, werden automatisch diesem Fall als Aktivität hinzugefügt.": "Emails which are received on this address, are automatically added to this case as an activity.",
    "E-Mails, die auf dieser Adresse eingehen, werden automatisch dieser Verkaufschance als Aktivität hinzugefügt.": "Emails which are received on this address, are automatically added to this opportunity as an activity.",
    "Eigene Schnellauswahl": "Custom quick selection",
    "Ein Benutzer darf nicht mehrfach Eskalationsmitglied sein.": "A user may not be an escalation member more than once.",
    "Ein Fahrzeug wurde aus dem Bestand entfernt.": "A vehicle was removed from inventory.",
    "Ein Fahrzeug wurde wieder in den Bestand hinzugefügt.": "A vehicle has been added back to inventory.",
    "Ein Fehler ist aufgetreten": "An error has occured",
    "Ein Nachtbriefkasten ist vorhanden.": "Drop box is available.",
    "Ein Zugriff auf die Anwendung ist aus Ihrem Netzwerk nicht gestattet.": "Access to the application is not permitted from your network.",
    "Ein- und ausgehende E-Mails weiterleiten ?": "Forward incoming and outgoing emails ?",
    "Eingaben zurücksetzen": "Reset input",
    "Eingehende Anrufe": "Incoming calls",
    "Eingehende Anrufentwicklung": "Incoming call performance",
    "Eingehende Anruferkennung beim Verbindungsaufbau": "Incoming caller ID during call establishment",
    "Eingehende Benutzer-E-Mails, für die kein Benutzer bzw. Vorgang selektiert werden kann, werden als neuer Fall in der ausgewählten Standard-Eskalationsgruppe erzeugt.": "Incoming user emails that could not be dispatched will create a new case at the selected default escalation group.",
    "Eingehende Benutzer-SMS, für die kein Benutzer bzw. Vorgang selektiert werden kann, werden als neuer Fall in der ausgewählten Standard-Eskalationsgruppe erzeugt.": "Incoming user text messages that could not be dispatched will create a new case at the selected default escalation group.",
    "Eingehende Dateien": "Incoming files",
    "Eingehende E-Mail": "Incoming email",
    "Eingehende E-Mail als Benutzer-E-Mail behandeln": "Treat incoming email as user email",
    "Eingehende E-Mail-Adresse bearbeiten": "Edit incoming email address",
    "Eingehende E-Mail-Adresse hinzufügen": "Add incoming email address",
    "Eingehende E-Mail-Adresse löschen": "Delete incoming email address",
    "Eingehende E-Mail-Adressen": "Incoming email addresses",
    "Eingehende E-Mails": "Incoming emails",
    "Eingehende E-Mails an Adressen, für die keine eingehende Adresse festgelegt ist, werden entsprechend der Regeln der ausgewählten Standard E-Mail-Adresse behandelt.": "Incoming emails for which no incoming email address exist will be handled according to the rules of the selected default incoming email address.",
    "Eingehende Rufnummer": "Incoming phone number",
    "Eingehende Rufnummer bearbeiten": "Edit incoming phone number",
    "Eingehende Rufnummer hinzufügen": "Add incoming phone number",
    "Eingehende Rufnummer löschen": "Delete incoming phone number",
    "Eingehende Rufnummern": "Incoming phone numbers",
    "Eingehende SMS": "Incoming text message",
    "Eingehende SMS-Rufnummern": "Incoming text message phone numbers",
    "Eingehende WhatsApp-Nachricht": "Incoming WhatsApp message",
    "Eingehende WhatsApp-Nachrichten": "Incoming WhatsApp messages",
    "Eingehender Anruf": "Incoming call",
    "Eingehender Anruf von {0}.": "Incoming call from {0}.",
    "Eingehender Videochat": "Incoming videochat",
    "Eingeladen von {0}": "Invited by {0}",
    "Einladen": "Invite",
    "Einparkhilfe": "Parking assistant",
    "Einrichtung": "Setup",
    "Einsatzort": "Location",
    "Einstellungen": "Settings",
    "Einstellungsimport": "Settings import",
    "Einträge je Seite": "Items per page",
    "Einzelempfänger": "Individual receiver",
    "Einzelgespräch (Median)": "Individual call (median)",
    "Empfangene Dateien": "Received files",
    "Empfänger": "Receiver",
    "Empfänger und Kategorie": "Receiver and category",
    "Empfänger vorhanden": "Receivers selected",
    "Ende der Abwesenheit (inklusive)": "End of absence (inclusive)",
    "Ende der Arbeitszeit": "Work time end",
    "Ende der Geschäftszeit": "End of office hours",
    "Ende der Lackgarantie": "End of painting warranty",
    "Ende der Rostgarantie": "End of rust warranty",
    "Entferne Dubletten von Fahrzeugen ohne Importquelle": "Remove duplicates of vehicles without import source",
    "Entferne Dubletten von allen Fahrzeugen": "Remove duplicates of all vehicles",
    "Entferne Fahrzeuge ohne Importquelle aus dem Fahrzeugbestand": "Remove vehicles without import source from inventory",
    "Entwurf herunterladen": "Download draft",
    "Erfolglos": "Unsuccessful",
    "Erfolglos (Gründe)": "Unsuccessful (reasons)",
    "Erfolglose Resultate": "Unsuccessful outcomes",
    "Erfolglose Verkaufschance": "Unsuccessful opportunity",
    "Erfolglose Verkaufschancen": "Unsuccessful opportunities",
    "Erfolgreich": "Successful",
    "Erfolgreich (Gründe)": "Successful (reasons)",
    "Erfolgreiche Resultate": "Successful outcomes",
    "Erfolgreiche Verkaufschance": "Successful opportunity",
    "Erfolgreiche Verkaufschancen": "Successful opportunities",
    "Ergebnis": "Outcome",
    "Ergebnisentwicklung": "Outcome performance",
    "Ergänzende Stammdaten": "Additional master data",
    "Erneut beitreten": "Rejoin",
    "Erneut versuchen": "Try again",
    "Erreichbar {firstDay} - {lastDay} von {startTime} bis {endTime} Uhr": "Available {firstDay} - {lastDay} between {startTime} and {endTime}",
    "Erreichbarkeit der Renault-RACE-API prüfen": "Check availability of the Renault RACE API",
    "Erste Rate": "First installment",
    "Erstellen": "Create",
    "Ersteller": "Creator",
    "Erstellt": "Created",
    "Erstellt am {0}": "Created at {0}",
    "Erstellt bis": "Created until",
    "Erstellt von": "Created from",
    "Erstellt zwischen": "Created between",
    "Erstellungszeitpunkt": "Creation date and time",
    "Erstreaktion aus Sicht des Kontakts": "First reaction from the contact's point of view",
    "Erstreaktion nach Zuteilung": "First reaction after assignment",
    "Erstzulassung": "First registration",
    "Erstzulassung bis": "First registration to",
    "Erstzulassung von": "First registration from",
    "Erweiterte Angaben": "Additional information",
    "Erweiterte Einstellungen": "Advanced options",
    "Erwerbsart": "Acquisition type",
    "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.": "An error occurred, please try again later.",
    "Es ist ein Fehler aufgetreten.": "An error occurred.",
    "Es ist ein Fehler beim Darstellen der E-Mail aufgetreten.": "An error occurred while displaying the email.",
    "Es ist ein Fehler beim Laden der E-Mail aufgetreten.": "An error occurred while loading the email.",
    "Es ist ein Fehler beim Laden des Suchauftrages aufgetreten.": "An error occurred while loading the search inquiry.",
    "Es ist ein Fehler beim Schließen der Verkaufschance aufgetreten.": "An error occurred while closing the opportunity.",
    "Es ist ein Fehler beim Schließen des Falles aufgetreten.": "An error occurred while closing the case.",
    "Es ist kein Benutzer bzw. keine Kategorie ausgewählt!": "No user or category selected!",
    "Es ist kein Empfänger ausgewählt!": "No receiver selected!",
    "Es ist kein Fahrzeug ausgewählt!": "No vehicle selected!",
    "Es ist kein Hinweis hinterlegt.": "No hint available.",
    "Es ist kein Kontakt ausgewählt!": "No contact selected!",
    "Es ist kein Link zu dem Angebot hinterlegt.": "No link to the offer available.",
    "Es ist kein Modell ausgewählt.": "No model selected.",
    "Es ist kein Nachtbriefkasten vorhanden.": "No drop box is available.",
    "Es ist kein Standard Absender für SMS festgelegt. Bitte wenden Sie sich an Ihren System-Administrator.": "No default sender for text messages is configured. Please contact your system administrator.",
    "Es ist kein Standort ausgewählt.": "No location selected.",
    "Es ist keine Gesprächsnotiz hinterlegt.": "No memo added.",
    "Es kann nicht an die eingehende Rufnummer weitergeleitet werden.": "No forwarding to the incoming number possible.",
    "Es klingelt bei Ihnen unter {0}.": "Your phone is ringing at {0}.",
    "Es klingelt bei {0}.": "Ringing at {0}.",
    "Es muss mindestens ein Standort ausgewählt werden.": "At least one location must be selected.",
    "Es muss wenigstens ein Händler konfiguriert werden.": "At least one dealer must be configured.",
    "Es sind keine Arbeitszeiten für diese Kalenderwoche hinterlegt.": "No working hours are set for this calendar week.",
    "Es sind keine Ausnahmen vorhanden.": "No exceptions found.",
    "Es sind keine Benutzer ausgewählt!": "No users selected!",
    "Es sind keine Benutzer für die Auswahl verfügbar.": "No users are available for the selection.",
    "Es sind keine Benutzer vorhanden.": "No users found.",
    "Es sind keine Benutzerzuordnungen vorhanden.": "No user mappings found.",
    "Es sind keine Bestandsfahrzeuge ausgewählt!": "No inventory vehicles selected!",
    "Es sind keine Dateien vorhanden.": "No files available.",
    "Es sind keine Daten zu einem angefragten Fahrzeug hinterlegt.": "No data on the requested vehicle available.",
    "Es sind keine Daten zum Kundenfahrzeug hinterlegt.": "No data on customer vehicle available.",
    "Es sind keine Details des Erwerbs hinterlegt.": "No acquisition details available.",
    "Es sind keine Einschränkungen vorhanden.": "No IP restrictions configured.",
    "Es sind keine Empfänger ausgewählt!": "No receivers selected!",
    "Es sind keine Empfänger ausgewählt. | Ausgewählter Empfänger: | Ausgewählte Empfänger:": "No receivers selected. | Selected receiver: | Selected receivers:",
    "Es sind keine Eskalationsgruppen ausgewählt!": "No escalation groups selected!",
    "Es sind keine Eskalationsgruppen für die Auswahl verfügbar.": "No escalation groups are available for the selection.",
    "Es sind keine Eskalationsmitglieder vorhanden.": "No escalation members found.",
    "Es sind keine Kategorien und keine Benutzer für die Auswahl verfügbar.": "No categories and users are available for the selection.",
    "Es sind keine Links vorhanden.": "No links found.",
    "Es sind keine Menüoptionen vorhanden.": "No menu options found.",
    "Es sind keine Mitglieder vorhanden.": "No members found.",
    "Es sind keine Quellenzuordnungen vorhanden.": "No source mappings found.",
    "Es sind keine Standortzuordnungen vorhanden.": "No location mappings found.",
    "Es sind keine Suchparameter definiert.": "No search parameters defined.",
    "Es sind keine Verkäuferteams ausgewählt!": "No sales team selected!",
    "Es sind keine Verkäuferteams für die Auswahl verfügbar.": "No sales teams are available for the selection.",
    "Es sind keine Verkäuferzuordnungen vorhanden.": "No salesperson mappings found.",
    "Es sind keine WhatsApp-Konten konfiguriert. Bitte wenden Sie sich an Ihren System-Administrator.": "No WhatsApp accounts are configured. Please contact your system administrator.",
    "Es sind keine freien Rufnummern mehr verfügbar.": "No more free phone numbers available.",
    "Es sind keine offenen Fälle vorhanden.": "No open cases found.",
    "Es sind keine offenen Verkaufschancen vorhanden.": "No open opportunities found.",
    "Es sind keine zugewiesenen Fälle vorhanden.": "No assigned cases found.",
    "Es sind keine zugewiesenen Verkaufschancen vorhanden.": "No assigned opportunities found.",
    "Es sind keine zurückgestellten Fälle vorhanden.": "No postponed cases found.",
    "Es sind keine zurückgestellten Verkaufschancen vorhanden.": "No postponed opportunities found.",
    "Es werden Standardeinstellungen gemäß der ausgewählten Call-Tracking-Gruppe verwendet.": "Default settings are used according to the selected call tracking group.",
    "Es wurde kein Abrechnungskonto gefunden. Bitte fügen Sie zunächst ein Abrechnungskonto hinzu und fahren Sie dann fort.": "No billing account was found. Please add a billing account and try again.",
    "Es wurde kein Einsatzort gefunden.": "No location found.",
    "Es wurde kein Kurzbericht und keine Diagnose gefunden.": "No short report and no diagnosis found.",
    "Es wurde kein Zielort gefunden.": "No destination found.",
    "Es wurde keine Notiz gefunden.": "No note found.",
    "Es wurde keine Notiz hinterlegt.": "No note content was set.",
    "Es wurde keine aktuelle Importdatei gefunden.": "No current import file found.",
    "Es wurden keine Abteilungen gefunden.": "No departments were found.",
    "Es wurden keine Adressen gefunden.": "No addresses were found.",
    "Es wurden keine Aktivitäten gefunden.": "No activities were found.",
    "Es wurden keine Ankündigungen gefunden.": "No announcements found.",
    "Es wurden keine Anruferkennungen gefunden.": "No caller IDs were found.",
    "Es wurden keine Anweisungen gefunden.": "No instructions were found.",
    "Es wurden keine Ausnahmen für Lesebestätigungen gefunden.": "No exceptions for read receipts were found.",
    "Es wurden keine Benachrichtigungsempfänger gefunden.": "No notification receivers were found.",
    "Es wurden keine Benutzer gefunden.": "No user were found.",
    "Es wurden keine Benutzer-E-Mail-Domains gefunden.": "No user email domains were found.",
    "Es wurden keine Call-Tracking-Gruppen gefunden.": "No call tracking groups were found.",
    "Es wurden keine Dienstkonten gefunden.": "No service accounts were found.",
    "Es wurden keine Dienstleistungen gefunden.": "No services offered were found.",
    "Es wurden keine Einträge gefunden.": "No entries were found.",
    "Es wurden keine Eskalationsgruppen gefunden.": "No escalation groups found.",
    "Es wurden keine Fahrzeugimport-Quellen gefunden.": "No inventory import sources found.",
    "Es wurden keine Geschäftszeiten für diesen Standort gefunden.": "No office hours found for this location.",
    "Es wurden keine Gründe gefunden.": "No reasons were found.",
    "Es wurden keine Kontaktdubletten erkannt. | Es wurde eine Kontaktdublette erkannt und entfernt. | Es wurden {count} Kontaktdubletten erkannt und entfernt.": "No contact duplicates have been detected. | One contact duplicate has been detected and removed. | {count} contact duplicates have been detected and removed.",
    "Es wurden keine Kontakte gefunden!": "No contacts were found!",
    "Es wurden keine Kontaktquellen gefunden.": "No contact sources were found.",
    "Es wurden keine Partner gefunden.": "No partners found.",
    "Es wurden keine Quellen für Bankdaten gefunden.": "No bank data sources were found.",
    "Es wurden keine Quellen gefunden.": "No sources found.",
    "Es wurden keine Renault-RACE-Accounts gefunden.": "No Renault RACE accounts found.",
    "Es wurden keine Resultate gefunden.": "No outcomes found.",
    "Es wurden keine Standorteinstellungen gefunden.": "No location settings found.",
    "Es wurden keine Verkäuferteams gefunden.": "No sales teams found.",
    "Es wurden keine Videochat-Gruppen gefunden.": "No videochat groups found.",
    "Es wurden keine Vorgänge gefunden.": "No issues found.",
    "Es wurden keine WhatsApp-Konten gefunden.": "No WhatsApp accounts were found.",
    "Es wurden keine Zielrufnummern gefunden.": "No target phone numbers found.",
    "Es wurden keine eingehenden E-Mail-Adressen gefunden.": "No incoming email addresses found.",
    "Es wurden keine eingehenden Rufnummern gefunden.": "No incoming phone numbers found.",
    "Es wurden keine Öffnungszeiten gefunden.": "No opening hours found.",
    "Es wurden {0} von {1} Bankdaten ignoriert, da sie schon verarbeitet wurden.": "Ignored bank data {0} of {1}, as they were already processed.",
    "Es wurden {0} von {1} Fällen ignoriert, da sie bereits geschlossen sind.": "Ignored {0} of {1} cases, as they are already closed.",
    "Es wurden {0} von {1} Fällen ignoriert.": "Ignored {0} of {1} cases.",
    "Es wurden {0} von {1} Verkaufschancen ignoriert, da sie bereits geschlossen sind.": "Ignored {0} of {1} opportunities, as they are already closed.",
    "Es wurden {0} von {1} Verkaufschancen ignoriert.": "Ignored {0} of {1} opportunities.",
    "Eskalation noch nicht gestartet": "Escalation not started yet",
    "Eskalationsbeginn": "Escalation begin",
    "Eskalationsgruppe": "Escalation group",
    "Eskalationsgruppe abwählen": "Deselect escalation group",
    "Eskalationsgruppe auswählen": "Select escalation group",
    "Eskalationsgruppe bearbeiten": "Edit escalation group",
    "Eskalationsgruppe hinzufügen": "Add escalation group",
    "Eskalationsgruppe in Verwendung": "Escalation group in use",
    "Eskalationsgruppe löschen": "Remove escalation group",
    "Eskalationsgruppen": "Escalation groups",
    "Eskalationsgruppen anderer Standorte": "Escalation groups of other locations",
    "Eskalationsmitglied hinzufügen": "Add escalation member",
    "Eskalationsmitglieder": "Escalation members",
    "Eskalationsstufen": "Escalation levels",
    "Eskalationszeit": "Escalation time",
    "Eskalationszeit in Minuten": "Escalation time in minutes",
    "Eskalationszeiten": "Escalation times",
    "Exportieren": "Export",
    "Exposé": "Exposé",
    "Exposé fehlt im Anhang": "Exposé missing in attachments",
    "Exposé im Anhang": "Exposé in attachments",
    "Extern": "External",
    "Externe Abwesenheitsquelle bearbeiten": "Edit external absence source",
    "Externe Abwesenheitsquelle hinzufügen": "Add external absence source",
    "Externe Abwesenheitsquelle löschen": "Delete external absence source",
    "Externe Abwesenheitsquellen": "External absence source",
    "Externe BDC-Ziele": "External BDC targets",
    "Externe Fahrzeugverwaltung": "External inventory management",
    "Externe Referenz": "External reference",
    "Externe Vorgangsnummer": "External incident number",
    "Externer Agent": "External agent",
    "Externer Link": "External link",
    "Externes BDC": "External BDC",
    "Externes BDC-Ziel": "External BDC target",
    "Externes BDC-Ziel hinzufügen": "Add external BDC target",
    "FIN": "VIN",
    "FTP-Benutzername": "FTP username",
    "FTP-Host": "FTP host",
    "FTP-Import": "FTP import",
    "FTP-Passwort": "FTP password",
    "FTP-URL": "FTP URL",
    "Fahrbereit": "Roadworthy",
    "Fahrer": "Driver",
    "Fahrzeug": "Vehicle",
    "Fahrzeug abwählen": "Deselect vehicle",
    "Fahrzeug ausgewählt": "Vehicle selected",
    "Fahrzeug bearbeiten": "Edit vehicle",
    "Fahrzeug hinzufügen": "Add vehicle",
    "Fahrzeug löschen": "Remove vehicle",
    "Fahrzeug-Identifizierungsnummer (FIN)": "Vehicle identification number (VIN)",
    "Fahrzeug-Klasse": "Vehicle class",
    "Fahrzeug-Suchindex neu aufbauen": "Recreate vehicle search index",
    "Fahrzeug-Suchkriterien": "Vehicle search criteria",
    "Fahrzeugbestand": "Vehicle inventory",
    "Fahrzeuge": "Vehicles",
    "Fahrzeuge hinzufügen": "Add vehicles",
    "Fahrzeuge löschen": "Delete vehicles",
    "Fahrzeugimport": "Inventory import",
    "Fahrzeugimport-Quelle bearbeiten": "Add inventory import source",
    "Fahrzeugimport-Quelle hinzufügen": "Edit inventory import source",
    "Fahrzeugimport-Quelle löschen": "Remove inventory import source",
    "Fahrzeugkategorie": "Vehicle category",
    "Fahrzeugkennung": "Vehicle identifier",
    "Fahrzeugklasse": "Vehicle class",
    "Fahrzeugnummer": "Vehicle number",
    "Fahrzeugnummerprefix": "Vehicle number prefix",
    "Fahrzeugnummersuffix": "Vehicle number suffix",
    "Fahrzeugtyp": "Vehicle type",
    "Fahrzeugverwaltungsanbieter": "Inventory management vendor",
    "Fall": "Case",
    "Fall auswählen": "Select case",
    "Fall erstellen": "Create case",
    "Fall freigeben": "Release case",
    "Fall löschen": "Delete case",
    "Fall schließen": "Close case",
    "Fall wird erstellt": "Case is being created",
    "Fall {0}": "Case {0}",
    "Fall {0} wurde bereits am {1} vom System geschlossen.": "Case {0} has already been closed on {1} by the system.",
    "Fall {0} wurde bereits am {1} von Ihnen geschlossen.": "Case {0} has already been closed on {1} by you.",
    "Fall {0} wurde bereits am {1} von einem externen Agenten geschlossen.": "Case {0} has already been closed on {1} by an external agent.",
    "Fall {0} wurde bereits am {1} von einem gelöschten Benutzer geschlossen.": "Case {0} has already been closed on {1} by an deleted user.",
    "Fall {0} wurde bereits am {1} von {2} geschlossen.": "Case {0} has already been closed on {1} by {2}.",
    "Fall {0} wurde geschlossen.": "Case {0} has been closed.",
    "Fall-Assistent": "Case assistant",
    "Fall-Kategorie": "Case category",
    "Fall-Suchindex neu aufbauen": "Recreate case search index",
    "Fall-Typ": "Case type",
    "Fall-Typen": "Case types",
    "Falleinstellungen": "Case settings",
    "Falls Ihr System bzw. Anbieter nicht in der Liste auftaucht, kontaktieren Sie uns bitte: ": "If your vendor does not appear in the list, please contact us: ",
    "Falls Sie Unterstützung bei der Einrichtung von Vorlagen benötigen, kontaktieren Sie uns bitte: ": "If you need help setting up templates, please contact us: ",
    "Falls Sie keinen API Schlüssel haben, nutzen Sie bitte den Onboarding-Link.": "If you do not have an API key please use the onboarding link.",
    "Falls Sie weitere Filterkriterien benötigen, kontaktieren Sie uns bitte: ": "If you need additional filters, please contact us: ",
    "Falls Sie weitere Platzhalter benötigen, kontaktieren Sie uns bitte: ": "If you need additional placeholders, please contact us: ",
    "Falls Sie weitere Rufnummern benötigen, kontaktieren Sie uns bitte: ": "If you need additional phone numbers, please contact us: ",
    "Falls Sie weitere SMS-Rufnummern benötigen, kontaktieren Sie uns bitte: ": "If you need additional text message phone numbers, please contact us: ",
    "Falls Sie weitere WhatsApp-Rufnummern benötigen, nutzen Sie bitte diesen Link: ": "If you need additional WhatsApp numbers, please use this link: ",
    "Falls angegeben, wird der Anruf nur innerhalb der Geschäftszeiten verteilt.": "If given, the call will only be distributed within business hours.",
    "Falls diese Einstellung gesetzt ist, wird diese Videochat-Gruppe bevorzugt für Fahrzeuganfragen dieses Standorts angezeigt.": "If this is set, this video group will be shown preferrably for vehicle requests for this location.",
    "Falls ein Fahrzeug erkannt wird, kann hier festgelegt werden, ob die Verkaufschance an den Standort des erkannten Fahrzeugs geleitet werden soll.": "If a vehicle is detected, this setting specifies if the opportunity should be forwarded to the location of the vehicle.",
    "Falls für die Rufnummer des Anrufers Vorgänge existieren, dann wird der Anruf ausschließlich an Benutzer verteilt, denen wenigstens einer dieser Vorgänge zugeteilt ist. Sollten keine dieser Benutzer verfügbar sein, dann wird der Anruf stattdessen an die Zielbenutzer verteilt.": "If there are issues for the caller's number, the call is only distributed to users who are assigned at least one of these issues. If none of these users are available, the call is distributed to the target users instead.",
    "Falls für eine Händlernummer kein Standort konfiguriert wurde, werden die Einstellungen dieses Händlers verwendet.": "If no location has been configured for a dealer number, the settings for this dealer are used.",
    "Falls in der ersten Nachricht ein Fahrzeug erkannt wird, wird eine Verkaufschance erstellt, andernfalls wird ein Fall erstellt.": "If a vehicle is detected in the first message, an opportunity will be created, otherwise a case will be created.",
    "Farbe": "Color",
    "Features": "Features",
    "Feedback absenden": "Submit feedback",
    "Feedback erstellt am {0}": "Feedback created at {0}",
    "Fehlende Kontakte löschen": "Delete missing contacts",
    "Fehlende Kontakte werden gelöscht": "Missing contacts will be deleted",
    "Fehler automatisch überspringen": "Automatically skip errors",
    "Fehler beim Laden des Videochats.": "Error loading videochat.",
    "Filter": "Filter",
    "Filter hinzufügen": "Add filter",
    "Filter zurücksetzen": "Reset filters",
    "Filter {0}": "Filter {0}",
    "Firma": "Company",
    "Flexibel": "Flexible",
    "Format": "Format",
    "Fortschritt": "Progress",
    "Fortsetzen": "Continue",
    "Frau": "Ms.",
    "Freigeben": "Release",
    "Frontendeinstellungen": "Frontend settings",
    "Frühestens": "Earliest",
    "Frühester Kontaktzeitpunkt": "Earliest contact date and time",
    "Fälle": "Cases",
    "Fälle ({0})": "Cases ({0})",
    "Fälle des Kontakts": "Cases of contact",
    "Fälle heute": "Cases today",
    "Fälle mit folgenden Resultaten in den Berichten ignorieren": "Ignore cases with the following outcomes in the reports",
    "Fälle zusammenführen": "Merge cases",
    "Für Berechnung der Annahmesperre ignorieren": "Ignore for calculation of the acceptance lock",
    "Für Berichte ignorieren": "Ignore for reports",
    "Für Fahrzeuganfragen bevorzugen": "Prefer for vehicle requests",
    "Für Kanal": "For channel",
    "Für Kanal und Quelle": "For channel and source",
    "Für Quelle": "For source",
    "Für alle Standorte sichtbar": "Visible for all locations",
    "Für die neu hinzugefügten Empfänger wird der Vorgang erst dann sichtbar, wenn die bestehenden Zuteilungen entfernt werden. Möchten Sie die Zuteilungen entfernen?": "The issue will only be visible to the newly added receivers when the existing assignments are removed. Do you want to remove the assignments?",
    "Für diesen Benutzer sind keine Schichtvorlagen vorhanden.": "No working hours templates found for this user.",
    "Für diesen Standort ist kein CARRY Dispo Zugang konfiguriert.": "No CARRY Dispo account is configured for this location.",
    "Für diesen Standort ist keine AutoCRM Konfiguration vorhanden.": "No AutoCRM configuration available.",
    "Ganztägig": "Whole day",
    "Garantie Ende (Datum)": "Warranty end (date)",
    "Garantie Ende (Laufleistung)": "Warranty end (mileage)",
    "Gebrauchtwagen": "Used vehicles",
    "Geburtsdatum": "Date of birth",
    "Gefilterte": "Filtered",
    "Gehe zu {0}": "Go to {0}",
    "Geltungsbereich": "Scope",
    "Gelöschte Eskalationsgruppe": "Deleted escalation group",
    "Gelöschte Rufnummer": "Deleted phone number",
    "Gelöschter Benutzer": "Deleted user",
    "Gelöschter Händler": "Deleted dealer",
    "Gelöschter Kontakt": "Deleted contact",
    "Gelöschtes Fahrzeug": "Deleted vehicle",
    "Gelöschtes Verkäuferteam": "Deleted sales team",
    "Gesamt": "Total",
    "Gesamt (Summe)": "Total (sum)",
    "Gesamtdauer": "Total duration",
    "Gesamte Händlergruppe": "Whole dealer group",
    "Gesamtspeicher": "Total storage",
    "Gesamtwartezeit (Median)": "Total wait time (median)",
    "Geschlecht": "Gender",
    "Geschlossen": "Closed",
    "Geschlossen am {0}": "Closed {0}",
    "Geschlossene Fälle": "Closed cases",
    "Geschlossene Verkaufschancen": "Closed opportunities",
    "Geschäftszeiten": "Office hours",
    "Geschäftszeiten bearbeiten": "Edit office hours",
    "Geschäftszeiten für automatische Antwort": "Office hours for automatic respond",
    "Geschäftszeiten für die Anrufverteilung": "Office hours for call distribution",
    "Geschäftszeiten für die Eskalation": "Office hours for escalation",
    "Geschäftszeiten hinzufügen": "Add office hours",
    "Geschäftszeiten löschen": "Remove office hours",
    "Geschätzte Ankunftszeit": "Estimated time of arrival",
    "Gesendet": "Sent",
    "Gesprochene Sprachen": "Spoken languages",
    "Gesprächsdauer": "Call duration",
    "Gesprächsnotiz": "Memo",
    "Gesprächsnotiz hinterlegen": "Add memo",
    "Gesprächsverbindung mit {0} ist aufgebaut.": "Connection with {0} is established.",
    "Gestern": "Yesterday",
    "Getriebe": "Gearbox",
    "Getriebeart": "Gearbox",
    "Gewonnen": "Won",
    "Gewonnene Verkaufschancen": "Won opportunities",
    "Gewünschte Anzahlung": "Desired first instalment",
    "Gewünschte Kontaktaufnahme": "Preferred approach",
    "Gewünschte Kontaktmethode": "Preferred contact method",
    "Gewünschte Laufleistung": "Desired mileage",
    "Gewünschte Laufzeit": "Desired payback period",
    "Gewünschte Monatsrate": "Desired monthly instalment",
    "Gewünschte Schlussrate": "Desired final instalment",
    "Gewünschter Kontaktzeitpunkt": "Preferred contact time",
    "Gewünschter Kontaktzeitpunkt: Orange bedeutet zu früh, rot bedeutet zu spät. Grün bedeutet, dass der aktuelle Zeitpunkt in der gewünschten Zeitspanne liegt.": "Preferred contact time: Orange means too early, red means too late. Green means that the current time is the within preferred contact time.",
    "Gewünschter Probefahrttermin": "Desired test drive date and time",
    "Geöffnet": "Opened",
    "Gibt an, wie lange maximal versucht wird, den Anrufer zu verbinden, bevor der Anruf automatisch beantwortet wird.": "Specifies the maximum time for which an attempt is made to connect the caller before the call is automatically answered.",
    "Gibt an, wie viel Zeit ein Teilnehmer hat, den Anruf entgegenzunehmen, bevor mit dem nächsten Teilnehmer fortgefahren wird.": "Specifies how much time a participant has to answer the call before continuing with the next participant.",
    "Grund": "Reason",
    "Grund bearbeiten": "Edit reason",
    "Grund der Aufnahme": "Record type",
    "Grund hinzufügen": "Add reason",
    "Gruppierung": "Grouping",
    "Gründe": "Reasons",
    "Gültig und aktiv": "Valid and active",
    "Gültige Dateiendung ist erforderlich": "Valid file extension is required",
    "Gültige E-Mail-Adresse ist erforderlich": "Valid email address is required",
    "Gültige Zeitzone ist erforderlich": "Valid time zone is required",
    "Gültiger Alias ist erforderlich": "Valid alias is required",
    "Gültiger Fall-Typ ist erforderlich": "Valid case type is required",
    "Gültiger Suffix ist erforderlich": "Valid suffix is required",
    "Gültigkeit": "Validity",
    "Gültigkeit festlegen": "Set validity",
    "HU {0}": "General inspection {0}",
    "HU/AU neu": "New legal inspection",
    "Hauptdaten": "Main data",
    "Hauptstandort": "Main location",
    "Hauptuntersuchung": "Legal inspection",
    "Hauptuntersuchungen können nicht durchgeführt werden.": "Legal inspections are not possible.",
    "Hauptuntersuchungen können nur mit Termin durchgeführt werden.": "Legal inspections are possible with an appointment only.",
    "Hauptuntersuchungen können ohne Termin durchgeführt werden.": "Legal inspections are possible without an appointment.",
    "Hausnummer": "House number",
    "Hell": "Light",
    "Herr": "Mr.",
    "Hersteller": "Make",
    "Herunterladen": "Download",
    "Herzlich Willkommen in der externen Telefonzentrale von {0}. Mein Name ist XXX. Was darf ich für Sie tun?": "Welcome to the external switchboard of {0}. My name is XXX. How can I help you?",
    "Heute": "Today",
    "Hinweis": "Hint",
    "Hinzufügen": "Add",
    "Historie": "History",
    "Hoch": "High",
    "Hochgeladene Dateien": "Uploaded files",
    "Hochladen und Download-Link erstellen": "Upload and create download link",
    "Hohe Priorität": "High priority",
    "Host": "Host",
    "Hubraum": "Cubic capacity",
    "Hubraum bis": "Cubic capacity from",
    "Hubraum von": "Cubic capacity to",
    "Händler": "Dealer",
    "Händler hinzufügen": "Add dealer",
    "Händler löschen": "Delete dealer",
    "Händler {0}": "Dealer {0}",
    "Händler-Logo": "Dealer logo",
    "Händlergruppe": "Dealer group",
    "Händlernummer (Renault)": "Dealer number (Renault)",
    "Händlernummer {0}": "Dealer number {0}",
    "Händlerverzeichnis": "Dealer Directory",
    "Händlerzuordnung": "Dealer assignment",
    "ID": "ID",
    "IP-Ausnahme hinzufügen": "Add IP exception",
    "IP-Ausnahme löschen": "Remove IP exception",
    "IP-Ausnahmen": "IP exceptions",
    "IP-Bereich (CIDR-Format)": "IP range (CIDR format)",
    "IP-Einschränkung hinzufügen": "Add IP restriction",
    "IP-Einschränkung löschen": "Remove IP restriction",
    "IP-Einschränkungen": "IP restrictions",
    "Ihr Passwort wurde erfolgreich geändert. Sie werden nun zur Login-Seite weitergeleitet.": "Your password was changed successfully. You will now be redirected to the login page.",
    "Ihre Anfrage": "Your request",
    "Ihre Anfrage wurde erfolgreich verarbeitet. Ein Mitarbeiter von uns meldet sich in Kürze bei Ihnen.": "You request was submitted successfully. A representative will get back to you shortly.",
    "Ihre Nachricht konnte aufgrund einer technischen Störung nicht verarbeitet werden. Bitte versuchen Sie es später nochmal.": "Your message could not be delivered due to a technical error. Please try again later.",
    "Ihre Sitzung ist abgelaufen": "Your session has expired",
    "Importiere Benutzer": "Import users",
    "Importiere Einstellungen": "Import settings",
    "Importiere Einstellungen für alle existierenden Standorte": "Import settings for all existing locations",
    "Importiere Schichtvorlagen": "Import working hours templates",
    "Importiere Standorteinstellungen": "Import location settings",
    "Importiere globale Einstellungen": "Import global settings",
    "Importieren": "Import",
    "Importierte Benutzer": "Imported users",
    "In Fall umwandeln": "Convert to case",
    "In Google Maps öffnen": "Open in Google Maps",
    "In Verkaufschance umwandeln": "Convert to opportunity",
    "In den hochgeladenen Daten fehlen mehr als 10% der derzeitigen Kontakte. Sind Sie sicher, dass Sie die Kontakte aktualisieren möchten?": "More than 10% of the current contacts are missing from the uploaded data. Are you sure you want to update the contacts?",
    "In die Zwischenablage kopieren": "Copy to clipboard",
    "In externer Fahrzeugverwaltung öffnen": "Open in external vehicle management",
    "In externer Kontaktverwaltung öffnen": "Open in external contact management",
    "In neuem Tab öffnen": "Open in new tab",
    "Inaktive Standorte": "Inactive locations",
    "Individuelle Ausnahmen": "Individual exceptions",
    "Individuelle Eskalation": "Individual escalation",
    "Individuelle Eskalationen": "Individual escalations",
    "Individuelle Zurückstellung": "Individual postponement",
    "Informationen anzeigen": "Show information",
    "Informationen zu Verkaufschancen": "Information on opportunities",
    "Informationen zu dieser Verkaufschance werden erst nach dessen Übernahme sichtbar.": "Information of this opportunity will be visible after it has been assigned.",
    "Inhalt": "Content",
    "Initiator": "Initiator",
    "Innenausstattung": "Interior type",
    "Installieren": "Install",
    "Intern": "Internal",
    "Interne Administration": "Internal administration",
    "Interne Anmerkungen": "Internal remarks",
    "Interne Notiz hinzufügen": "Add internal note",
    "Interne Notizen": "Internal note",
    "Interne Nummer": "Internal number",
    "Inzahlungnahme": "Trade-in",
    "Inzahlungnahme gewünscht": "Trade-in desired",
    "Ja": "Yes",
    "Jahr": "Year",
    "Jeder kann durch Öffnen dieses Links diesem Videochat beitreten.": "Everyone can join this videochat by opening this link.",
    "KW {0}": "Week {0}",
    "Kanal": "Channel",
    "Kanal der Erstreaktion": "Channel of the first reaction",
    "Kanal der Kontaktaufnahme": "Channel of contact",
    "Kanal der Verkaufschance": "Channel of the opportunity",
    "Kanal des Falles": "Channel of the case",
    "Kategorie": "Category",
    "Kategorie auswählen": "Select category",
    "Kategorie hinzufügen": "Add Category",
    "Kategorie {0}": "Category {0}",
    "Kategorien": "Categories",
    "Kategorien und Benutzer": "Categories and users",
    "Kein Datum vorhanden": "No date available",
    "Kein Grund angegeben": "No reason specified",
    "Kein Upload vorhanden": "No recent upload",
    "Keine Abwesenheiten vorhanden": "No absences found",
    "Keine Anhänge | 1 Anhang | {count} Anhänge": "No attachments | 1 attachment | {count} attachments",
    "Keine Bestätigungspflicht": "Not confirmable",
    "Keine Daten vorhanden": "No data available",
    "Keine Empfänger vorhanden": "No receivers selected",
    "Keine Eskalationsstufen vorhanden": "No escalation levels available",
    "Keine Gruppierung": "No grouping",
    "Keine Kategorien vorhanden": "No categories available",
    "Keine Lesebestätigungen vorhanden.": "No read receipts available.",
    "Keine Mitglieder auf dieser Stufe": "No members at this level",
    "Keine Zielrufnummern hinterlegt": "No target phone numbers assigned",
    "Keine gewonnenen Verkaufschancen | 1 gewonnene Verkaufschance | {count} gewonnene Verkaufschancen": "No won opportunities | 1 won opportunity | {count} won opportunities",
    "Keine offenen Verkaufschancen | 1 offene Verkaufschance | {count} offene Verkaufschancen": "No open opportunities | 1 open opportunity | {count} open opportunities",
    "Keine verlorenen Verkaufschancen | 1 verlorene Verkaufschance | {count} verlorene Verkaufschancen": "No lost opportunities | 1 lost opportunity | {count} lost opportunities",
    "Keine zugewiesenen Verkaufschancen | 1 zugewiesene Verkaufschance | {count} zugewiesene Verkaufschancen": "No assigned opportunities | 1 assigned opportunity | {count} assigned opportunities",
    "Keine zurückgestellten Verkaufschancen | 1 zurückgestellte Verkaufschance | {count} zurückgestellte Verkaufschancen": "No postponed opportunities | 1 postponed opportunity | {count} postponed opportunities",
    "Keine | 1 Monat | {count} Monate": "None | 1 month | {count} months",
    "Keine | 1 Woche | {count} Wochen": "None | 1 week | {count} weeks",
    "Kennzeichen": "License plate",
    "Key ID": "Key ID",
    "Klimatisierung": "Climatisation",
    "Kommentar": "Comment",
    "Kompakt": "Compact",
    "Kontakt": "Contact",
    "Kontakt abwählen": "Unselect contact",
    "Kontakt bearbeiten": "Edit contact",
    "Kontakt hinzufügen": "Add contact",
    "Kontakt löschen": "Delete contact",
    "Kontakt per E-Mail einladen": "Invite contact via email",
    "Kontakt per SMS einladen": "Invite contact via text message",
    "Kontakt und Fahrzeug": "Contact and vehicle",
    "Kontakt und Vorgang": "Contact and issue",
    "Kontakt wird geladen": "Loading contact",
    "Kontakt, Vorgang und Betreff": "Contact, issue und subject",
    "Kontakt-Suchindex neu aufbauen": "Recreate contact search index",
    "Kontaktdaten": "Contact details",
    "Kontaktdetails": "Contact details",
    "Kontakte": "Contacts",
    "Kontakte von FTP-Server importieren": "Import contacts from FTP server",
    "Kontaktimport": "Contact import",
    "Kontaktmethode": "Contact method",
    "Kontaktquelle bearbeiten": "Edit contact source",
    "Kontaktquelle hinzufügen": "Add contact source",
    "Kontaktquelle löschen": "Delete contact source",
    "Kontaktrufnummer": "Contact number",
    "Kontaktsuche": "Contact search",
    "Kontakttyp": "Contact type",
    "Kopieren Sie den Schlüssel. Er wird hier letztmalig angezeigt.": "Copy the key. It will be shown here for the last time.",
    "Kosten": "Costs",
    "Kostenhinweis erteilt": "Cost indication given",
    "Kraftstoffart": "Fuel type",
    "Kreditablösung gewünscht": "Loan redemption desired",
    "Kunde vor Ort": "Customer on site",
    "Kundenfahrzeug": "Customer vehicle",
    "Kundenname": "Customer name",
    "Kundennummer": "Customer ID",
    "Kurzbericht": "Short report",
    "Kurzbericht und Diagnose": "Short report and diagnosis",
    "Kurzzeitige Abwesenheiten werden berücksichtigt (d.h. Statusänderungen wie Pausen etc.).": "Short-term absences are taken into account (i.e., status changes such as breaks, etc.).",
    "LID": "LID",
    "Label": "Label",
    "Lade Daten": "Loading data",
    "Lade Elemente": "Loading elements",
    "Land": "Country",
    "Langzeitige Abwesenheiten werden berücksichtigt (d.h. Dienstreisen, Urlaub, Krankheit etc.).": "Long-term absences are taken into account (i.e., business trips, vacation, sickness, etc.).",
    "Laufleistung": "Mileage",
    "Laufleistung bis": "Mileage to",
    "Laufleistung hinzufügen": "Add mileage record",
    "Laufleistung von": "Mileage from",
    "Lautstärke": "Volume",
    "Leasing und Finanzierung": "Leasing and financing",
    "Legt fest welche Art von Vorgang bei eingehenden Chats erstellt wird.": "This setting specifies which issue types will be created on incoming chats.",
    "Legt fest, an welche Eskalationsgruppen der Fall zugeordnet werden soll.": "This setting specifies which escalation groups should be assigned to created cases.",
    "Legt fest, an welche Verkäuferteams die Verkaufschancen zugeordnet werden sollen. Falls Sie dieses Feld leer lassen, werden die Anfragen entsprechend Ihrer Verkäuferteam Filter verteilt.": "This setting specifies which sales teams should be assigned to created opportunties.",
    "Legt fest, an welchen Benutzer der Vorgang zugeordnet werden soll.": "This setting specifies which users should be assigned.",
    "Leistung": "Power",
    "Leistung bis": "Power to",
    "Leistung von": "Power from",
    "Leistungszeitraum": "Benefit period",
    "Lesebestätigungen": "Read receipts",
    "Letzte Aktivität": "Last activity",
    "Letzte Woche": "Last week",
    "Letzte erfolgreiche Aktualisierung": "Last successful update",
    "Letzten 10 Wochen": "Last 10 weeks",
    "Letzten 14 Tage": "Last 14 days",
    "Letzten 7 Tage": "Last 7 days",
    "Letzter Entwurf": "Last draft",
    "Letzter Fehler": "Last error",
    "Letzter Monat": "Last month",
    "Letzter Vorgang": "Last event",
    "Letztes Quartal": "Last quarter",
    "Lieferung gewünscht": "Delivery requested",
    "Link aus der E-Mail wurde 0 Mal geklickt. | Link aus der E-Mail wurde 1 Mal geklickt. | Link aus der E-Mail wurde {count} Mal geklickt.": "Link in the email was clicked 0 times. | Link in the email was clicked 1 time. | Link in the email was clicked {count} times.",
    "Link hinzufügen": "Add link",
    "Link löschen": "Delete link",
    "Link zum Angebot": "Link to the offer",
    "Link zum Dashboard": "Link to the dashboard",
    "Link zum Fahrzeug": "Link to the vehicle",
    "Link-Beschriftung": "Link label",
    "Links": "Links",
    "Login": "Login",
    "Logout": "Logout",
    "Lösche Bankdaten": "Delete bank data",
    "Lösche Fälle": "Delete cases",
    "Lösche Verkaufschancen": "Delete opportunities",
    "Löschen": "Delete",
    "Löschen nach": "Delete after",
    "MMM YYYY": "MMM YYYY",
    "MMMM YYYY": "MMMM YYYY",
    "Manuelle Abfertigung von Benutzer-E-Mails": "Manual dispatching of user emails.",
    "Manuelle Abfertigung von Benutzer-SMS": "Manual dispatching of user text messages",
    "Marke": "Make",
    "Marken- und Modelleinstellungen": "Make and model settings",
    "Massenbearbeitung": "Bulk processing",
    "Maximum": "Maximum",
    "Median": "Median",
    "Median {0}": "Median {0}",
    "Mehr anzeigen": "Show more",
    "Mein Videoanruf wurde nicht beantwortet. Ich bitte um Rückruf.": "My video call was not answered. Please call me back.",
    "Meine Aktivitäten": "My activities",
    "Meine Fälle": "My cases",
    "Meine Verkaufschancen": "My opportunities",
    "Meine Videochats": "My videochats",
    "Meine Vorgänge": "My issues",
    "Menüansage": "Menu announcement",
    "Menüoption": "Menu option",
    "Mindestanzahl Großbuchstaben": "Minimum required uppercase letters",
    "Mindestanzahl Kleinbuchstaben": "Minimum required lowercase letters",
    "Mindestanzahl Sonderzeichen": "Minimum required special characters",
    "Mindestanzahl Ziffern": "Minimum required digits",
    "Mindestlänge": "Minimum length",
    "Mit Daten im DNS vergleichen": "Compare with data from DNS",
    "Mit Erstreaktion": "With initial reaction",
    "Mitarbeiter (Renault)": "Employee (Renault)",
    "Mitglieder": "Members",
    "Modell": "Model",
    "Modell {0}": "Model {0}",
    "Modellbeschreibung": "Model description",
    "Modelljahr": "Model year",
    "Monat": "Month",
    "Monat des Jahres": "Month of the year",
    "Monate": "Months",
    "Monatliche Rate": "Monthly installment",
    "Morgen": "Tomorrow",
    "Möchten Sie dem Benutzer eine E-Mail mit dem Link zum Setzen seines Passworts senden?": "Do you want to send the user an email with a link to set his password?",
    "Möchten Sie sich den Fall direkt zuteilen?": "Would you like to assign the case directly to yourself?",
    "Möchten Sie sich die Verkaufschance direkt zuteilen?": "Would you like to assign the opportunity directly to yourself?",
    "Nach Abteilungen gruppieren": "Group by department",
    "Nach Bearbeiter": "By processor",
    "Nach Fall-Kategorie": "By case category",
    "Nach Fall-Typ": "By case type",
    "Nach Kanal": "By channel",
    "Nach Quelle": "By source",
    "Nach Verkäufer": "By salesperson",
    "Nach Verkäuferteam": "By sales team",
    "Nach erstem reagierenden Bearbeiter": "By first reacting processor",
    "Nach erstem reagierenden Verkäufer": "By first reacting salesman",
    "Nachname": "Family name",
    "Nachricht": "Message",
    "Nachricht befindet sich in Zustellung.": "Message is being delivered.",
    "Nachricht wurde gelesen. ({0})": "Message was read. ({0})",
    "Nachricht wurde zugestellt. ({0})": "Message was delivered. ({0})",
    "Nachtbriefkasten": "Drop box",
    "Name": "Name",
    "Name der Aktion": "Deal name",
    "Name des Dienstkontos": "Service account name",
    "Name des Einsatzortes": "Location name",
    "Name des Kunden": "Customer name",
    "Name des Zielortes": "Name des Zielortes",
    "Name und Anschrift": "Name and address",
    "Namensnachsatz": "Name suffix",
    "Nein": "No",
    "Nettobetrag": "Net amount",
    "Neuanlagedatum": "Creation date",
    "Neue CitNOW Videoanfrage": "New CitNOW video request",
    "Neue E-Mail": "New email",
    "Neue Notiz": "New note",
    "Neue SMS": "New text message",
    "Neue Schnellauswahl hinzufügen": "Add new quick selection",
    "Neue ausgehende E-Mail": "New outgoing email",
    "Neue ausgehende SMS": "New outgoing text message",
    "Neue ausgehende WhatsApp-Nachricht": "New outgoing WhatsApp message",
    "Neuen Kontakt anlegen": "Create new contact",
    "Neuen Videochat starten": "Start new videochat",
    "Neuer Anruf": "New call",
    "Neuer Auftrag": "New order",
    "Neuer Notdienstvorgang": "New emergency incident",
    "Neuer Suchauftrag": "New search inquiry",
    "Neuer ausgehender Anruf": "New outgoing call",
    "Neues Fahrzeug anlegen": "Create new vehicle",
    "Neues Passwort": "New password",
    "Neues Ticket": "New ticket",
    "Neues Ticket erstellen": "Create new ticket",
    "Neuwagen": "New vehicles",
    "Nicht angenommen": "Not answered",
    "Nicht ausblenden": "Do not hide",
    "Nicht blockieren": "Do not block",
    "Nicht blockiert": "Not blocked",
    "Nicht deaktivieren": "Do not disable",
    "Nicht fahrbereit": "Not roadworthy",
    "Nicht gespeicherte Änderungen": "Unsaved changes",
    "Nicht scheckheftgepflegt": "No full service history",
    "Nicht sichtbar für externe Agenten": "Not visible for external agents",
    "Nicht zugeteilt": "Not assigned",
    "Niemals": "Never",
    "Normal": "Normal",
    "Notdienstanweisungen": "Emergency incident instructions",
    "Notdienstvorgang": "Emergency incident",
    "Notdienstvorgang auswählen": "Select emergency incident",
    "Notdienstvorgang erstellen": "Create emergency incident",
    "Notdienstvorgang {0}": "Emergency incident {0}",
    "Notdienstvorgangs-Assistent": "Emergency incident assistant",
    "Notdienstvorgänge": "Emergency incidents",
    "Notdienstvorgänge ({0})": "Emergency incidents ({0})",
    "Notdienstvorgänge des Kontakts": "Emergency incidents of contact",
    "Notdienstvorgänge heute": "Emergency incidents today",
    "Notdienstvorgänge-Suchindex neu aufbauen": "Recreate emergency incidents search index",
    "Notiz": "Note",
    "Notiz-Vorlage bearbeiten": "Edit note template",
    "Notiz-Vorlage hinzufügen": "Add note template",
    "Notiz-Vorlagen": "Note templates",
    "Notizen": "Notes",
    "Notizen heute": "Notes today",
    "Nur Bild-, Ton- und PDF-Dateien anzeigen": "Show only image, audio and PDF files",
    "Nur Bild-, Ton- und PDF-Dateien erlauben": "Allow only image, audio and PDF files",
    "Nur Fahrzeuge dieser Filiale importieren": "Only import vehicles from this branch",
    "Nur Fälle mit demselben Kontakt anzeigen": "Show only cases with the same contact",
    "Nur Lesezugriff": "Read-only access",
    "Nur Verkaufschancen mit demselben Kontakt anzeigen": "Show only opportunities with the same contact",
    "Nur an Benutzer verteilen, die anwesend sind": "Distribute only to users who are present",
    "Nur an Benutzer verteilen, die online sind": "Distribute only to users who are online",
    "Nur an Benutzer verteilen, die sich innerhalb ihrer Arbeitszeit befinden": "Distribute only to users who are within their working hours",
    "Nur an Benutzer während ihrer Arbeitszeit verteilen": "Distribute only to users during their working hours",
    "Nur der Zeitraum innerhalb der gewählten Öffnungszeiten wird zur Berechnung der Reaktionszeit herangezogen. Falls keine Öffnungszeiten hinterlegt sind, wird der gesamte Tag berücksichtigt.": "Only the time span within the selected opening hours is used to calculate the reaction time. If no opening hours are available, the entire day is taken into account.",
    "Nur dieser Standort": "Only this location",
    "Nutzen Sie diesen Link, um einen Sofortkontakt per WhatsApp auf Ihrer Seite, E-Mail oder weiteren Medien einzubinden. Falls Sie zusätzlich das Fahrzeug erkennen möchten, kontaktieren Sie bitte unseren Support für eine spezielle Einbindung.": "Use this like to add a click-to-chat instant function to your website, email or other media. If you also need to detect the stock vehicle, please contact us for assistance.",
    "Nächste Hauptuntersuchung": "Next legal inspection",
    "Nächste Inspektion (Datum)": "Next inspection (date)",
    "Nächste Inspektion (Laufleistung)": "Next inspection (mileage)",
    "Nächste Woche": "Next week",
    "Nächster Schritt": "Next step",
    "Nächster Versuch": "Next try",
    "OEM": "OEM",
    "OK": "OK",
    "Offen": "Open",
    "Offen ohne Empfänger": "Open without receivers",
    "Offene Fälle": "Open cases",
    "Offene Notdienstvorgänge": "Open emergency incidents",
    "Offene Verkaufschancen": "Open opportunities",
    "Ohne Benutzer": "Without user",
    "Ohne Einzelempfänger": "Without individual receiver",
    "Ohne Erstreaktion": "Without initial reaction",
    "Ohne Eskalationsgruppe": "Without escalation group",
    "Ohne Feedback": "Without feedback",
    "Ohne Standort": "Without location",
    "Ohne Verkäuferteam": "Without sales team",
    "Ohne Zuteilung": "Unassigned",
    "Ohne einen Filter wird dieses Team automatisch keine Verkaufschancen zugewiesen bekommen.": "Without a filter, no opportunities are automatically assigned to this team.",
    "Onboarding": "Onboarding",
    "Open-Source-Lizenzen": "Open source licenses",
    "Optional": "Optional",
    "Ort": "City",
    "Partition": "Partition",
    "Partner": "Partners",
    "Partner bearbeiten": "Edit partner",
    "Partner hinzufügen": "Add partner",
    "Partner löschen": "Delete partner",
    "Passwort": "Password",
    "Passwort bestätigen": "Confirm password",
    "Passwort erfolgreich geändert": "Password has been changed successfully",
    "Passwort geändert": "Password changed",
    "Passwort ist erforderlich": "Password is required",
    "Passwort vergessen": "Forgot password",
    "Passwort zurücksetzen": "Reset password",
    "Passwort ändern": "Change password",
    "Passwortrichtlinie": "Password policy",
    "Pausieren": "Pause",
    "Personalnummer": "Employee number",
    "Pfad zum Verzeichnis": "Path to the directory",
    "Platzhalter": "Placeholder",
    "Position": "Position",
    "Postleitzahl": "ZIP code",
    "Preis": "Price",
    "Preis bis": "Price to",
    "Preis von": "Price from",
    "Preisvorschlag": "Price suggestion",
    "Priorität": "Priority",
    "Prioritätseinstellungen": "Priority settings",
    "Private Key": "Private Key",
    "Private Key überschreiben?": "Overwrite private key?",
    "Probefahrt gewünscht": "Test drive requested",
    "Profil bearbeiten": "Edit profile",
    "Profilbild": "Profile picture",
    "Prozentual": "Percentage",
    "Qo [Quartal] YYYY": "Qo [quarter] YYYY",
    "Qualitätssicherungsstufe": "Quality assurance level",
    "Quartal": "Quarter",
    "Quartal des Jahres": "Quarter of the year",
    "Quelle": "Source",
    "Quelle (Renault)": "Source (Renault)",
    "Quelle (Verkaufschance)": "Source (opportunity)",
    "Quelle bearbeiten": "Edit source",
    "Quelle hinzufügen": "Add source",
    "Quellen": "Sources",
    "Quellen zusammenführen": "Merge sources",
    "Quellenzuordnung löschen": "Delete source mapping",
    "Quellenzuordnungen": "Source mappings",
    "RACE-Konten": "RACE accounts",
    "Reaktionszeit": "Reaction time",
    "Reaktionszeit der Erstreaktion": "Reaction time of the initial reaction",
    "Reaktionszeit der Erstreaktion (Median)": "Reaction time of the initial reaction (median)",
    "Reaktionszeit der Erstreaktion aus Sicht des Kontakts": "Reaction time of the initial reaction from the contact's point of view",
    "Reaktivierung": "Reactivation",
    "Rechnung": "Invoice",
    "Rechnung öffnen": "Open invoice",
    "Rechnungen": "Invoices",
    "Rechnungsdetails": "Invoice details",
    "Region": "Region",
    "Renault": "Renault",
    "Renault-RACE-Account bearbeiten": "Edit Renault RACE account",
    "Renault-RACE-Account hinzufügen": "Add Renault RACE account",
    "Renault-RACE-Account löschen": "Delete Renault RACE account",
    "Renault-RACE-Accounts": "Renault RACE accounts",
    "Reparieren": "Fix",
    "Resultat": "Outcome",
    "Resultat bearbeiten": "Edit outcome",
    "Resultat hinzufügen": "Add outcome",
    "Resultat löschen": "Remove outcome",
    "Resultat und Conversion Rate": "Outcome and conversion rate",
    "Resultate": "Outcomes",
    "Resultateinstellungen": "Outcome settings",
    "Resultatgründe": "Outcome reasons",
    "Roh-E-Mail herunterladen": "Download raw email",
    "Rollen": "Roles",
    "Rufnummer": "Phone number",
    "Rufnummer bearbeiten": "Edit phone number",
    "Rufnummer des Anrufers": "Caller's phone number",
    "Rufnummer des Kunden": "Customer's phone number",
    "Rufnummer dieser Gruppe": "This group's phone number",
    "Rufnummer für Rückfragen": "Phone number for return queries",
    "Rufnummer hinzufügen": "Add phone number",
    "Rufnummer installieren": "Install phone number",
    "Rufnummer löschen": "Delete phone number",
    "Rufnummer nicht öffentlich": "Phone number not public",
    "Rufnummer {0}": "Phone number {0}",
    "Rufnummer öffentlich ?": "Phone number publishable ?",
    "Rufnummern": "Phone numbers",
    "Rufnummernsperre": "Phone number blocking",
    "Rufnummernsperre bearbeiten": "Edit phone number block",
    "Rufnummernsperre hinzufügen": "Add phone number block",
    "Rufnummernsperre löschen": "Delete phone number block",
    "Rufnummerntyp": "Phone number type",
    "Rufsignalisierung": "Call signaling",
    "Rufverteilung": "Call distribution",
    "Rundschreiben empfangen": "Receive broadcast messages",
    "Rückrufnummer": "Callback number",
    "SMS": "Text message",
    "SMS absenden": "Send text message",
    "SMS heute": "Text messages today",
    "SMS wird gesendet": "Sending text message",
    "SMS-Senderkennung": "Text message sender id",
    "SMS-Signatur": "Text message signature",
    "SMS-Signatur auf SMS-Signatur-Vorlage zurücksetzen": "Reset text message signature to text message signature template",
    "SMS-Signatur-Vorlage": "Text message signature template",
    "SMS-Signatur-Vorlage bearbeiten": "Edit text message signature template",
    "SMS-Signaturen zurücksetzen?": "Reset text message signatures?",
    "Schaden": "Damage",
    "Scheckheftgepflegt": "Full service history",
    "Scheinwerfer-Art": "Headlight type",
    "Schichtvorlage": "Working hours template",
    "Schichtvorlage bearbeiten": "Edit working hours template",
    "Schichtvorlage hinzufügen": "Add working hours template",
    "Schichtvorlage löschen": "Delete working hours template",
    "Schichtvorlagen von {0}": "Working hours templates of {0}",
    "Schließe Fälle": "Close cases",
    "Schließe Verkaufschancen": "Close opportunities",
    "Schließen": "Close",
    "Schließer": "Closer",
    "Schließgründe": "Closing reasons",
    "Schließrate": "Close rate",
    "Schlussrate": "Final installment",
    "Schlüssel": "Key",
    "Schnellauswahl": "Quick selection",
    "Schnellauswahl erstellen": "Create quick selection",
    "Schnellauswahl löschen": "Delete quick selection",
    "Schnittstellenart": "Interface type",
    "Schritt 1: Daten des Kontakts": "Step 1: contact data",
    "Schritt 2: Daten des Falles": "Step 2: case data",
    "Schritt 3: Empfänger auswählen": "Step 3: select receiver",
    "Sehr geehrte Damen und Herren": "Dear sir or madam",
    "Sekunden": "Seconds",
    "Senden": "Send",
    "Sender": "Sender",
    "Senderkennung": "Sender ID",
    "Server-Fehler": "Server Error",
    "Service": "Service",
    "Service level": "Service level",
    "Setze Verarbeitungsstatus auf unverarbeitet": "Set processing status to unprocessed",
    "Sicherheit": "Security",
    "Sichtbar ab": "Visible from",
    "Sichtbar bis": "Visible to",
    "Sichtbar für Standorte": "Visible for locations",
    "Sichtbar für externe Agenten": "Visible for external agents",
    "Sichtbar in Vertretung von": "Visible as substitute of",
    "Sichtbarkeit": "Visibility",
    "Sichtbarkeitsbeginn: Der Zeitpunkt an dem der Fall das erste Mal für Sie sichtbar war.": "Visibility begin: The time since the case was visible for you for the first time.",
    "Sichtbarkeitsbeginn: Der Zeitpunkt an dem der Fall das erste Mal für diese Person sichtbar war.": "Visibility begin: The time since the case was visible for that particular person for the first time.",
    "Sichtbarkeitsbeginn: Der Zeitpunkt an dem die Verkaufschance das erste Mal für Sie sichtbar war.": "Visibility begin: The time since the opportunity was visible for that particular person for the first time.",
    "Sie erhalten in den nächsten Minuten eine E-Mail zum Zurücksetzen des Passworts an: {0}": "You will receive a password reset email in the next couple of minutes to: {0}",
    "Sie haben den Videochat verlassen.": "You have left the videochat.",
    "Sie haben keine E-Mail-Aliasse in Ihrem Profil hinterlegt. Dies ist eine Voraussetzung für den E-Mail-Versand.": "No email alias was found in your profile. Setting up an email alias is necessary for sending an email.",
    "Sie haben keinen Zugriff auf die Lesebestätigungen.": "You do not have the permission to see these read receipts.",
    "Sie haben keinen Zugriff auf diese E-Mail.": "You do not have the permission to see this email.",
    "Sie haben keinen Zugriff auf diese Verkaufschance.": "You do not have the permission to see this opportunity.",
    "Sie haben keinen Zugriff auf diesen Fall oder der Fall ist nicht mehr verfügbar.": "You do not have the permission to see this case or the case is not available anymore.",
    "Sie haben keinen Zugriff auf diesen Fall.": "You do not have the permission to see this case.",
    "Sie haben keinen Zugriff auf diesen Notdienstvorgang.": "You do not have the permission to see this emergency incident.",
    "Sie haben nicht die Berechtigung, den Benutzer mit diesen Rollen anzulegen.": "You do not have the permission to create the user with these roles.",
    "Sie haben nicht die Berechtigung, den Benutzer zu löschen.": "You do not have the permission to delete the user.",
    "Sie haben nicht die Berechtigung, den Inhalt der E-Mail anzusehen.": "You do not have the permission to view the content of the email.",
    "Sie haben nicht die Berechtigung, den Inhalt der SMS anzusehen.": "You do not have the permission to view the content of the text message.",
    "Sie haben nicht die Berechtigung, den Inhalt der WhatsApp-Nachricht anzusehen.": "You do not have the permission to view the content of the WhatsApp message.",
    "Sie haben nicht die Berechtigung, die Benutzereinstellungen zu verändern.": "You do not have the permission to change the user settings.",
    "Sie haben nicht die Berechtigung, diese Seite mit dem ausgewählten Standort aufzurufen.": "You do not have the permission to open this page with the selected location.",
    "Sie haben nicht die Berechtigung, für diesen Vorgang eine E-Mail zu senden.": "You do not have the permission to send an email for this issue.",
    "Sie haben nicht die Berechtigung, für diesen Vorgang eine SMS zu senden.": "You do not have the permission to send a text message for this issue.",
    "Sie haben nicht die Berechtigung, für diesen Vorgang eine WhatsApp-Nachricht zu senden.": "You do not have the permission to send a WhatsApp message for this issue.",
    "Sie haben nicht die Berechtigung, für diesen Vorgang einen Anruf zu starten.": "You do not have the permission to start a call for this issue.",
    "Sie müssen die Anruferkennung für den Verbindungsaufbau in den allgemeinen Anrufeinstellungen hinterlegen, bevor Sie einen Anruf tätigen können.": "You have to specify the caller ID for the call establishment in the general call settings before you can proceed making a call.",
    "Sie müssen eine Anruferkennung und eine Anschlussnummer in Ihrem Profil hinterlegen, bevor Sie einen Anruf tätigen können.": "You have to specify a caller id and terminal number in your profile before you can proceed making a call.",
    "Sie sind der einzige Empfänger!": "You are the only receiver!",
    "Sie werden abgemeldet, bitte warten...": "You are being unsubscribed, please wait...",
    "Sie werden verbunden mit {0}.": "You are being connected to {0}.",
    "Sie wurden erfolgreich abgemeldet.": "You have been unsubscribed successfully.",
    "Signatur Vorschau": "Signature preview",
    "Signatur Vorschau-E-Mail senden": "Send signature preview email",
    "Signatur-Vorschau wurde erfolgreich versandt": "Signature preview email sent successfully",
    "Sind Sie sicher, dass Sie Fahrzeugdubletten entfernen möchten?": "Are you sure you want to remove vehicle duplicates?",
    "Sind Sie sicher, dass Sie Fahrzeuge ohne Importquelle aus dem Bestand entfernen möchten?": "Are you sure you want to remove vehicles without import source from the inventory.",
    "Sind Sie sicher, dass Sie Kontaktdubletten entfernen möchten?": "Are you sure you want to remove contact duplicates?",
    "Sind Sie sicher, dass Sie das Dienstkonto löschen möchten?": "Are you sure you want to remove the service account?",
    "Sind Sie sicher, dass Sie das Fahrzeug löschen möchten?": "Are you sure you want to remove the vehicle?",
    "Sind Sie sicher, dass Sie das Passwort für den Benutzer {0} zurücksetzen möchten?": "Are you sure you want to reset the password of the user {0}?",
    "Sind Sie sicher, dass Sie das Resultat löschen möchten?": "Are you sure you want to remove the outcome?",
    "Sind Sie sicher, dass Sie das Verkäuferteam löschen möchten?": "Are you sure you want to remove the sales team?",
    "Sind Sie sicher, dass Sie das WhatsApp-Konto löschen möchten?": "Are you sure you want to remove the WhatsApp account?",
    "Sind Sie sicher, dass Sie den Account löschen möchten?": "Are you sure you want to remove the account?",
    "Sind Sie sicher, dass Sie den Alias löschen möchten?": "Are you sure you want to remove the alias?",
    "Sind Sie sicher, dass Sie den Benachrichtigungsempfänger löschen möchten?": "Are you sure you want to remove the notification receiver?",
    "Sind Sie sicher, dass Sie den Benutzer {0} löschen möchten?": "Are you sure you want to remove the user {0}?",
    "Sind Sie sicher, dass Sie den Fall unwiderruflich löschen möchten?": "Are you sure you want delete this case? This cannot be undone.",
    "Sind Sie sicher, dass Sie den Händler löschen möchten?": "Are your sure you want to remove the dealer?",
    "Sind Sie sicher, dass Sie den Kontakt löschen möchten?": "Are you sure you want to remove the contact?",
    "Sind Sie sicher, dass Sie den Link löschen möchten?": "Are you sure you want to remove the link?",
    "Sind Sie sicher, dass Sie den Partner löschen möchten?": "Are you sure you want to remove the partner?",
    "Sind Sie sicher, dass Sie den Suchauftrag löschen möchten?": "Are you sure you want to remove the search inquiry?",
    "Sind Sie sicher, dass Sie den Verarbeitungsstatus auf unverarbeitet setzen möchten?": "Are you sure you want to set the processing status to unprocessed?",
    "Sind Sie sicher, dass Sie den Vorschlag für das Datum {0} ignorieren möchten?": "Are you sure you want to ignore the suggestion for the date {0}?",
    "Sind Sie sicher, dass Sie den soeben ausgewählten Fall löschen und dessen Inhalte in den aktuellen Fall übernehmen möchten?": "Are you sure you want to delete the just selected case and merge its contents into the current case?",
    "Sind Sie sicher, dass Sie die API Subscription löschen möchten?": "Are you sure you want to remove the API subscription?",
    "Sind Sie sicher, dass Sie die Abteilung {0} löschen möchten?": "Are you sure you want to remove the department {0}?",
    "Sind Sie sicher, dass Sie die Abwesenheit löschen möchten?": "Are you sure you want to remove the absence?",
    "Sind Sie sicher, dass Sie die Adresse löschen möchten?": "Are you sure you want to remove the address?",
    "Sind Sie sicher, dass Sie die Ankündigung löschen möchten?": "Are you sure you want to remove the announcement?",
    "Sind Sie sicher, dass Sie die Anruferkennung löschen möchten?": "Are you sure you want to remove the caller ID?",
    "Sind Sie sicher, dass Sie die Anweisung löschen möchten?": "Are you sure you want to remove the instruction?",
    "Sind Sie sicher, dass Sie die Bankdaten löschen möchten?": "Are you sure you want to remove the bank data?",
    "Sind Sie sicher, dass Sie die Bankdaten verarbeiten möchten?": "Are you sure you want to process the bank data?",
    "Sind Sie sicher, dass Sie die Bankdatenquelle {0} löschen möchten?": "Are you sure you want to remove the bank data source {0}?",
    "Sind Sie sicher, dass Sie die Benutzer-E-Mail-Domain löschen möchten?": "Are you sure you want to remove the user email domain?",
    "Sind Sie sicher, dass Sie die Benutzerzuordnung löschen möchten?": "Are you sure you want to remove the user mapping?",
    "Sind Sie sicher, dass Sie die Call-Tracking-Gruppe löschen möchten?": "Are you sure you want to remove the call tracking group?",
    "Sind Sie sicher, dass Sie die Datei löschen möchten?": "Are you sure you want to delete the file?",
    "Sind Sie sicher, dass Sie die Dienstleistung löschen möchten?": "Are you sure you want to remove the service offered?",
    "Sind Sie sicher, dass Sie die E-Mail trotz fehlendem Anhang verschicken möchten?": "Are you sure you want to send the email despite the missing attachment?",
    "Sind Sie sicher, dass Sie die E-Mail über ein Fremdsystem (z.B. eine Fahrzeugbörse) verschicken möchten?": "Are you sure that you want to send the email via a third-party system (e.g. a vehicle exchange)?",
    "Sind Sie sicher, dass Sie die E-Mail-Adresse löschen möchten?": "Are you sure you want to remove the email address?",
    "Sind Sie sicher, dass Sie die E-Mail-Signaturen auf die E-Mail-Signatur-Vorlage zurücksetzen möchten?": "Are you sure you want to reset the email signatures to the email signature template?",
    "Sind Sie sicher, dass Sie die E-Mail-Vorlage bzw. den E-Mail-Textbaustein löschen möchten?": "Are you sure you want to remove the email template or email fragment?",
    "Sind Sie sicher, dass Sie die Eingaben unwiderruflich löschen möchten?": "Are you sure you want to reset your input?",
    "Sind Sie sicher, dass Sie die Eskalationsgruppe {0} löschen möchten?": "Are you sure you want to remove the escalation group {0}?",
    "Sind Sie sicher, dass Sie die Fahrzeugimport-Quelle löschen möchten?": "Are you sure you want to remove the inventory import source?",
    "Sind Sie sicher, dass Sie die Geschäftszeiten {0} löschen möchten?": "Are you sure you want to remove the office hours {0}?",
    "Sind Sie sicher, dass Sie die IP-Ausnahme löschen möchten?": "Are you sure you want to remove the IP exception?",
    "Sind Sie sicher, dass Sie die IP-Einschränkung löschen möchten?": "Are you sure you want to remove the IP restriction?",
    "Sind Sie sicher, dass Sie die Kontaktquelle {0} löschen möchten?": "Are you sure you want to remove the contact source {0}?",
    "Sind Sie sicher, dass Sie die Notiz-Vorlage löschen möchten?": "Are you sure you want to remove the note template?",
    "Sind Sie sicher, dass Sie die Quellenzuordnung löschen möchten?": "Are you sure you want to remove the source mapping?",
    "Sind Sie sicher, dass Sie die Rufnummer löschen möchten?": "Are you sure you want to remove the phone number?",
    "Sind Sie sicher, dass Sie die SMS-Signaturen auf die SMS-Signatur-Vorlage zurücksetzen möchten?": "Are you sure you want to reset the text message signatures to the text message signature template?",
    "Sind Sie sicher, dass Sie die SMS-Vorlage bzw. den SMS-Textbaustein löschen möchten?": "Are you sure you want to remove the text message template or text message fragment?",
    "Sind Sie sicher, dass Sie die Schichtvorlage löschen möchten?": "Are you sure you want to remove the working hours template?",
    "Sind Sie sicher, dass Sie die Schnellauswahl löschen wollen?": "Are you sure you want to remove the quick selection?",
    "Sind Sie sicher, dass Sie die Sperre der Rufnummer {0} löschen wollen?": "Are you sure that you want to delete the block of the phone number {0}?",
    "Sind Sie sicher, dass Sie die Standortzuordnung löschen möchten?": "Are you sure you want to remove the location mapping?",
    "Sind Sie sicher, dass Sie die Verkaufschance unwiderruflich löschen möchten?": "Are you sure you want delete this opportunity? This cannot be undone.",
    "Sind Sie sicher, dass Sie die Verkäuferzuordnung löschen möchten?": "Are you sure you want to remove the salesperson mapping?",
    "Sind Sie sicher, dass Sie die Videochat-Gruppe löschen möchten?": "Are you sure you want delete this videochat group?",
    "Sind Sie sicher, dass Sie die WhatsApp-Nachricht trotz fehlendem Anhang verschicken möchten?": "Are you sure you want to send the WhatsApp message despite the missing attachment?",
    "Sind Sie sicher, dass Sie die Willkommens-E-Mail senden möchten?": "Are you sure you want to send the welcome email?",
    "Sind Sie sicher, dass Sie die ausgewählten Fälle löschen möchten?": "Are you sure you want to delete the selected cases?",
    "Sind Sie sicher, dass Sie die ausgewählten Verkaufschancen löschen möchten?": "Are you sure you want to delete the selected opportunities?",
    "Sind Sie sicher, dass Sie die automatische Verarbeitung verhindern möchten?": "Are you sure you want to prevent the automatic processing?",
    "Sind Sie sicher, dass Sie die eingehende Rufnummer löschen möchten?": "Are you sure you want to delete the incoming phone number?",
    "Sind Sie sicher, dass Sie die soeben ausgewählte Verkaufschance löschen und deren Inhalte in die aktuelle Verkaufschance übernehmen möchten?": "Are you sure you want to delete the just selected opportunity and merge its contents into the current opportunity?",
    "Sind Sie sicher, dass Sie diese Quelle löschen möchten?": "Are you sure you want to remove the source?",
    "Sind Sie sicher, dass Sie diese Verkaufschance in einen Fall umwandeln möchten?": "Are you sure you want to convert this opportunity to a case?",
    "Sind Sie sicher, dass Sie ohne Bestandsfahrzeug fortfahren möchten?": "Are you sure you want to continue without an inventory vehicle?",
    "Sind Sie sicher, dass Sie ohne Empfänger fortfahren möchten?": "Are you sure you want to continue without a receiver?",
    "Sind Sie sicher, dass Sie ohne Fahrzeug fortfahren möchten?": "Are you sure you want to continue without a vehicle?",
    "Sind Sie sicher, dass Sie ohne Kontakt fortfahren möchten?": "Are you sure you want to continue without a contact?",
    "Sind Sie sicher, dass Sie unbenutzte Kontakte aus Quelle \"{0}\" löschen möchten?": "Are you sure you want to delete unused contacts from source \"{0}\"?",
    "Sitzung abgelaufen": "Session timed out",
    "Sofort": "Immediately",
    "Soforteskalation": "Instant escalation",
    "Soforteskalation falls Offline": "Instant escalation if offline",
    "Software-Updates": "Software updates",
    "Sollten Sie keine E-Mail empfangen, prüfen Sie bitte die E-Mail-Adresse und ggf. den Spam-Ordner Ihres E-Mail-Programmes.": "In case you do not receive an email, please check the email address and possibly the spam folder of your email program.",
    "Sonstige": "Other",
    "Sonstige Modelle": "Other models",
    "Sonstige Quellen": "Other sources",
    "Sonstiges": "Miscellaneous",
    "Sortierung": "Sort",
    "Spam gemeldet": "Spam reported",
    "Spam gemeldet am {0}": "Spam reported at {0}",
    "Spam melden": "Report spam",
    "Spam wird gemeldet": "Reporting spam",
    "Speicher": "Storage",
    "Speicher aufräumen": "Clean up storage",
    "Speicherinformation": "Storage information",
    "Speichern": "Save",
    "Sprachausgabe": "Speech output",
    "Sprache/Region": "Language/Region",
    "Spätestens": "Latest",
    "Spätester Kontaktzeitpunkt": "Latest contact date and time",
    "Staat": "State",
    "Stammdaten": "Master data",
    "Standard": "Default",
    "Standard E-Mail-Adresse": "Standard email address",
    "Standard-Absender": "Default sender",
    "Standard-Antwortadresse": "Default Reply-To address",
    "Standard-Eskalationsgruppe für Benutzer-E-Mails": "Default user email escalation group",
    "Standard-Eskalationsgruppe für Benutzer-SMS": "Default user text message escalation group",
    "Standardeinstellungen": "Default settings",
    "Standardeinstellungen (falls die automatische Zuweisung fehlschlägt)": "Default settings (if the automatic assignment fails)",
    "Standardhändler": "Default dealer",
    "Standardvertretung": "Default substitute",
    "Standardvertretung entfernen": "Remove default substitute",
    "Standardvorlage": "Default template",
    "Standardvorlage ({0})": "Default template ({0})",
    "Standort": "Location",
    "Standort aktiv": "Active location",
    "Standort an den Fahrzeugstandort anpassen": "Override by vehicle location",
    "Standort bearbeiten": "Edit location",
    "Standort der Anwesenheit": "Location for presence",
    "Standort hinzufügen": "Add location",
    "Standortberichtseinstellungen": "Location report settings",
    "Standortbezogen": "Location-specific",
    "Standortdetails": "Location details",
    "Standorte": "Locations",
    "Standorte und Rufnummern": "Locations and phone numbers",
    "Standorteinstellung bearbeiten": "Edit location settings",
    "Standorteinstellung hinzufügen": "Add location settings",
    "Standortvergleich": "Location comparison",
    "Standortzuordnung": "Location mapping",
    "Standortzuordnung hinzufügen": "Add location assignment",
    "Standortzuordnung löschen": "Remove location mapping",
    "Standortzuordnung {0}": "Location assignment {0}",
    "Standortzuordnungen": "Location assignments",
    "Status": "Status",
    "Status Dashboard": "Status dashboard",
    "Status Dashboard öffnen": "Open status dashboard",
    "Status bearbeiten": "Edit status",
    "Status der unkontaktierten Fälle": "Status of the uncontacted cases",
    "Status der unkontaktierten Verkaufschancen": "Status of the uncontacted opportunities",
    "Status festlegen": "Set status",
    "Status löschen": "Remove status",
    "Stimmung": "Sentiment",
    "Stimmungsentwicklung": "Sentiment performance",
    "Stornieren": "Cancel",
    "Stornorechnung öffnen": "Open cancel invoice",
    "Stoßzeiten": "Busy times",
    "Straße": "Street",
    "Straße und Hausnummer": "Street and house number",
    "Strukturierte E-Mail herunterladen": "Download structured email",
    "Stufe hinzufügen": "Add level",
    "Stufe {0}": "Level {0}",
    "Stunde": "Hour",
    "Subdomain": "Subdomain",
    "Suchauftrag": "Search inquiry",
    "Suchauftrag aktivieren": "Activate search inquiry",
    "Suchauftrag deaktivieren": "Deactivate search inquiry",
    "Suchauftrag erstellen": "Create search inquiry",
    "Suchauftrag löschen": "Remove search inquiry",
    "Suchauftrag nach erstem Treffer deaktivieren": "Disable on first match",
    "Suchauftrag vom {0}": "Search inquiry from {0}",
    "Suchauftrag wird gespeichert": "Search inquiry is being saved",
    "Suchauftrag wurde erfolgreich erstellt.": "Search inquiry was created successfully.",
    "Suchauftrag wurde erfolgreich gespeichert.": "Search inquiry was saved successfully.",
    "Suchauftrags-Assistent": "Search inquiry assistant",
    "Suchaufträge": "Search inquiries",
    "Suche": "Search",
    "Suchen": "Search",
    "Suchergebnisse": "Search results",
    "Suchindex": "Search index",
    "Suffix": "Suffix",
    "Support": "Support",
    "System": "System",
    "System-E-Mail": "System email",
    "System-E-Mail-Aliasse": "System email aliases",
    "Tabellenansicht ausblenden": "Hide table view",
    "Tabellenansicht einblenden": "Show table view",
    "Tag": "Day",
    "Tag des Jahres": "Day of the year",
    "Tag des Monats": "Day of the month",
    "Tagesbericht": "Daily report",
    "Taste": "Key",
    "Technischer Support": "Technical support",
    "Teile und Zubehör": "Parts",
    "Teilnehmer": "Participants",
    "Telefonischer Support": "Phone support",
    "Telefonnummer": "Phone number",
    "Telefonnummern mit tel-Link darstellen": "Display phone numbers with tel link",
    "Tempomat": "Speed control",
    "Termin": "Appointment time",
    "Text": "Text",
    "Textbaustein bearbeiten": "Edit text message fragment",
    "Textbaustein hinzufügen": "Add text message fragment",
    "Textbausteine": "Fragments",
    "Textnachrichten": "Text messages",
    "Textnachrichten blockieren": "Block text messages",
    "Textnachrichten erlauben": "Allow text messages",
    "Textnachrichten-Bausteine": "Text message fragments",
    "Textnachrichten-Vorlagen": "Text message templates",
    "Thema": "Topic",
    "Timeout": "Timeout",
    "Timeout in Minuten": "Timeout in minutes",
    "Titel": "Title",
    "Token erneut senden": "Resend token",
    "Tracke, wann ein Link in einer E-Mail geklickt wird": "Track when a link in an email is clicked",
    "Tracke, wann eine E-Mail geöffnet wird": "Track when an email is opened",
    "Treffer für Suchauftrag": "Match for search inquiry",
    "Trennzeichen": "Delimiter",
    "Typ": "Type",
    "Typ des Notdienstvorgangs": "Emergency incident type",
    "Type ID": "Type ID",
    "URL": "URL",
    "URL des Endpunkts": "URL of the endpoint",
    "Uhrzeit": "Time",
    "Uhrzeit Beginn": "Begin time",
    "Uhrzeit Ende": "End time",
    "Um diese Verkaufschance zurückzustellen, müssen Sie sich erst zuteilen. Jetzt zuteilen?": "To postpone this opportunity, you must first assign yourself. Assign now?",
    "Um diesen Fall zurückzustellen, müssen Sie sich erst zuteilen. Jetzt zuteilen?": "To postpone this case, you must first allocate yourself. Allocate now?",
    "Umgewandelt aus": "Converted from",
    "Umwandeln": "Convert",
    "Unbegrenzte Gültigkeit": "Unlimited validity",
    "Unbekannt": "Unknown",
    "Unbekannte Abteilung": "Unknown department",
    "Unbekannte Bearbeitungsdauer": "Unknown processing time",
    "Unbekannte Eskalationsgruppe": "Unknown escalation group",
    "Unbekannte Quelle": "Unknown source",
    "Unbekannte Reaktionszeit": "Unknown reaction time",
    "Unbekannte Vorgänge": "Unknown issues",
    "Unbekannter Benutzer": "Unknown user",
    "Unbekannter Standort": "Unknown location",
    "Unbekanntes Fahrzeugmodell": "Unknown vehicle model",
    "Unbekanntes Verkäuferteam": "Unknown sales team",
    "Unbenannter Kontakt": "Unnamed contact",
    "Unbenanntes Fahrzeug": "Unnamed vehicle",
    "Unbenutzte Kontakte löschen": "Delete unused contacts",
    "Unbenutzter Speicher": "Unused storage",
    "Unbeschädigt": "Undamaged",
    "Unfallfrei": "Accident-free",
    "Unfallwagen": "Accident damaged",
    "Ungeschlossen": "Unclosed",
    "Ungeschlossene Verkaufschance": "Unclosed opportunity",
    "Ungültig oder inaktiv": "Invalid or inactive",
    "Ungültiger Wert": "Invalid value",
    "Unsichtbar": "Invisible",
    "Ursprung": "Origin",
    "Ursprünglicher Empfänger": "Original recipient",
    "Validierungscode": "Validation code",
    "Verarbeite Bankdaten": "Process bank data",
    "Verarbeite {0} von {1}": "Process {0} of {1}",
    "Verarbeitung anstoßen": "Trigger processing",
    "Verarbeitung forcieren": "Force processing",
    "Verarbeitungsstatus auf unverarbeitet setzen": "Set processing status to unprocessed",
    "Verarbeitungszeitpunkt": "Processed at",
    "Verbindung freigeben": "Release connection",
    "Verbindung zu Ihnen wird aufgebaut unter {0}.": "Connection is being established to you at {0}.",
    "Verbotene Wörter": "Forbidden words",
    "Verhindere automatische Verarbeitung": "Preventing automatic processing",
    "Verkaufschance": "Opportunity",
    "Verkaufschance auswählen": "Select opportunity",
    "Verkaufschance erstellen": "Create opportunity",
    "Verkaufschance freigeben": "Release opportunity",
    "Verkaufschance löschen": "Delete opportunity",
    "Verkaufschance schließen": "Close opportunity",
    "Verkaufschance vor Vertragsende anlegen": "Create opportunity before end of contract",
    "Verkaufschance wird erstellt": "Opportunity is being created",
    "Verkaufschance wird übermittelt": "Opportunity is being submitted",
    "Verkaufschance {0}": "Opportunity {0}",
    "Verkaufschance {0} wurde bereits am {1} vom System geschlossen.": "Opportunity {0} has already been closed on {1} by the system.",
    "Verkaufschance {0} wurde bereits am {1} von Ihnen geschlossen.": "Opportunity {0} has already been closed by you on {1}.",
    "Verkaufschance {0} wurde bereits am {1} von einem externen Agenten geschlossen.": "Opportunity {0} has already been closed on {1} by an external agent.",
    "Verkaufschance {0} wurde bereits am {1} von einem gelöschten Benutzer geschlossen.": "Opportunity {0} has already been closed on {1} by an deleted user.",
    "Verkaufschance {0} wurde bereits am {1} von {2} geschlossen.": "Opportunity {0} has already been closed on {1} by {2}.",
    "Verkaufschance {0} wurde geschlossen.": "Opportunity {0} has been closed.",
    "Verkaufschance übermitteln": "Submit opportunity",
    "Verkaufschancen": "Opportunities",
    "Verkaufschancen ({0})": "Opportunities ({0})",
    "Verkaufschancen des Kontakts": "Opportunities of contact",
    "Verkaufschancen für das Fahrzeug": "Opportunities for vehicle",
    "Verkaufschancen heute": "Opportunities today",
    "Verkaufschancen können außerhalb der Annahmezeiten nicht übernommen werden.": "You cannot assign to opportunities outside the acceptance hours.",
    "Verkaufschancen können während einer aktiven Annahmesperre nicht übernommen werden.": "You cannot assign to opportunities during an active acceptance lock.",
    "Verkaufschancen mit diesen Resultaten werden bei der Berechnung der Annahmesperre ignoriert": "Opportunities with these outcomes will be ignored when calculating the acceptance lock",
    "Verkaufschancen mit folgenden Resultaten in den Berichten ignorieren": "Ignore opportunities with the following outcomes in the reports",
    "Verkaufschancen zusammenführen": "Merge opportunities",
    "Verkaufschancen-Assistent": "Opportunity assistant",
    "Verkaufschancen-Suchindex neu aufbauen": "Recreate opportunity search index",
    "Verkaufschanceneinstellungen": "Opportunity settings",
    "Verkäufer": "Salesperson",
    "Verkäuferteam": "Sales team",
    "Verkäuferteam abwählen": "Deselect sales team",
    "Verkäuferteam auswählen": "Select sales team",
    "Verkäuferteam bearbeiten": "Edit sales team",
    "Verkäuferteam hinzufügen": "Add sales team",
    "Verkäuferteam löschen": "Remove sales team",
    "Verkäuferteams": "Sales teams",
    "Verkäuferteams anderer Standorte": "Sales teams of other locations",
    "Verkäuferzuordnung": "Salesperson mapping",
    "Verkäuferzuordnung löschen": "Remove salesperson mapping",
    "Verlauf": "History",
    "Verloren": "Lost",
    "Verpflichtend": "Mandatory",
    "Versand über ein Fremdsystem": "Sending via a third-party system",
    "Versendete Dateien": "Sent files",
    "Versicherungsberatung gewünscht": "Insurance advice requested",
    "Version {0}": "Version {0}",
    "Verteilungsmethode": "Distribution method",
    "Verteilungstimeout": "Distribution timeout",
    "Vertrag": "Contract",
    "Vertreter in der gesamten Händlergruppe suchen": "Find substitutes across the dealer group",
    "Vertretung": "Substitute",
    "Video-Seite": "Video page",
    "Videochat": "Videochat",
    "Videochat-Gruppe bearbeiten": "Edit videochat group",
    "Videochat-Gruppe hinzufügen": "Add videochat group",
    "Videochat-Gruppe löschen": "Delete videochat group",
    "Videochat-Gruppe {0}": "Videochat group {0}",
    "Videochat-Gruppen": "Videochat group",
    "Videochat-Link": "Videochat link",
    "Videochats": "Videochats",
    "Von Annahmesperre ausgenommene Resultate": "Outcomes exempted from acceptance lock",
    "Vor 7 Tagen": "7 days ago",
    "Voraussichtliches Auftragsdatum": "Projected close date",
    "Vorgang": "Issue",
    "Vorgang anlegen bei beantwortetem Anruf": "Create issue on answered call",
    "Vorgang anlegen bei unbeantwortetem Anruf": "Create issue on unanswered call",
    "Vorgang anlegen wenn": "Create issue if",
    "Vorgang dem Benutzer zuteilen, der den Anruf annimmt": "Assign the issue to the user who accepts the call",
    "Vorgänge": "Issues",
    "Vorgänge des Kontakts": "Issues of contact",
    "Vorherige Woche": "Previous week",
    "Vorheriger Monat": "Previous month",
    "Vorheriges Jahr": "Previous year",
    "Vorheriges Quartal": "Previous quarter",
    "Vorlage": "Template",
    "Vorlage bearbeiten": "Edit template",
    "Vorlage für Antwort": "Template for reply",
    "Vorlage für Antwort außerhalb der Geschäftszeiten": "Template for reply outside office hours",
    "Vorlage für Antwort innerhalb der Geschäftszeiten": "Template for reply inside office hours",
    "Vorlage hinzufügen": "Add template",
    "Vorlage übernehmen und Fall schließen.": "Apply template and close case.",
    "Vorlage übernehmen und Verkaufschance schließen.": "Apply template and close opportunity.",
    "Vorlagen": "Templates",
    "Vorlauf": "Pre-offer",
    "Vorname": "Given name",
    "Vorschau": "Preview",
    "Vorschlag ignorieren": "Ignore suggestion",
    "Vortag": "Previous day",
    "Wartezeit": "Wait time",
    "Wartezeit angenommener Anrufe": "Wait time of answered calls",
    "Wartezeit nicht angenommener Anrufe": "Wait time of unanswered calls",
    "Wartung": "Maintenance",
    "Webseite": "Website",
    "Wechsel zum Status Dashboard": "Switch to status dashboard",
    "Weiter": "Continue",
    "Weitere Marken": "More makes",
    "Weiteren Benutzer hinzufügen": "Add additional user",
    "Weitergeleitet": "Forwarded",
    "Weiterleitung an benutzerdefinierte Rufnummer": "Forwarding to custom phone number",
    "Weiterleitungsadresse": "Forwarding address",
    "Weiterleitungsrufnummer bei technischer Störung": "Forward phone number at technical error",
    "Wenn Sie eine E-Mail senden, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?": "When you send an email, this issue will be automatically assigned to you. Do you still want to continue?",
    "Wenn Sie eine SMS senden, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?": "When you send a text message, this issue will be automatically assigned to you. Do you still want to continue?",
    "Wenn Sie eine WhatsApp-Nachricht senden, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?": "When you send a WhatsApp message, this issue will be automatically assigned to you. Do you still want to continue?",
    "Wenn Sie einen Anruf starten, wird Ihnen dieser Vorgang automatisch zugeteilt. Möchten Sie trotzdem fortfahren?": "When you start a call, this issue will be automatically assigned to you. Do you still want to continue?",
    "Wenn aktiviert, werden Benutzer-E-Mails, die nicht eindeutig zugeordnet werden können, durch externe Agenten manuell abgefertigt.": "If activated, user emails which could not be dispatched, will be dispatched manually by external agents.",
    "Wenn aktiviert, werden Benutzer-SMS, die nicht eindeutig zugeordnet werden können, durch externe Agenten manuell abgefertigt.": "If activated, user text messages which could not be dispatched, will be dispatched manually by external agents.",
    "Wenn diese Option aktiviert ist, wird der Status-Code 180 oder 183 geschickt, sobald der eingehende Anruf eintrifft. Es wird erst dann Status-Code 200 zurückgeliefert, wenn der Anruf angenommen wird.": "If this option is activated, the status code 180 or 183 will be sent as soon as the incoming call arrives. Status code 200 is only returned if the call is accepted.",
    "Wert": "Value",
    "WhatsApp": "WhatsApp",
    "WhatsApp Business API Schnittstelle": "WhatsApp Business",
    "WhatsApp-Konten": "WhatsApp accounts",
    "WhatsApp-Konto bearbeiten": "Edit WhatsApp account",
    "WhatsApp-Konto hinzufügen": "Add WhatsApp account",
    "WhatsApp-Konto löschen": "Delete WhatsApp account",
    "WhatsApp-Nachricht": "WhatsApp message",
    "WhatsApp-Nachrichten": "WhatsApp messages",
    "WhatsApp-Nachrichten heute": "WhatsApp messages today",
    "WhatsApp-Rufnummer": "WhatsApp number",
    "Willkommens-E-Mail": "Welcome email",
    "Willkommens-E-Mail senden": "Send welcome email",
    "Willkommens-E-Mail versendet an: {0}": "Sent welcome email: {0}",
    "Wird verarbeitet bis": "Will be processed until",
    "Wird verarbeitet von": "Will be processed from",
    "Wo [Woche] GGGG": "Wo [week] GGGG",
    "Woche": "Week",
    "Woche des Jahres": "Week of the year",
    "Wochentag": "Day of the week",
    "Wunschpreis": "Desired price",
    "Zahlung der Schlussrate": "Payment of the final installment date",
    "Zahlung der Schlussrate am {0}": "Payment of the final installment on {0}",
    "Zahlung der Schlussrate bis": "Payment of the final installment until",
    "Zahlung der Schlussrate von": "Payment of the final installment from",
    "Zeichensatz": "Charset",
    "Zeige Verlauf": "Show history",
    "Zeit zwischen Erstellung und erster Kontaktaufnahme": "Time between creation and first contact",
    "Zeit zwischen Zuteilung und erster Kontaktaufnahme": "Time between assignment and first contact",
    "Zeitintervall": "Time interval",
    "Zeitlimit für den gesamten Verteilungsvorgang": "Time limit for the entire distribution process",
    "Zeitlimit für einen einzelnen Verbindungsversuch": "Time limit for a single connection attempt",
    "Zeitpunkt": "Date and time",
    "Zeitpunkt festlegen": "Determine date and time",
    "Zeitraum": "Time span",
    "Zeitzone": "Time zone",
    "Ziel-Quelle": "Target source",
    "Zielbenutzer": "Target users",
    "Zielort": "Destination",
    "Zielrufnummer": "Target phone number",
    "Zugangsbeschreibung": "Access instructions",
    "Zugangsdaten": "Credentials",
    "Zugeordnete Fall-Typen": "Assigned case types",
    "Zugeteilt": "Assigned",
    "Zugeteilt an": "Assigned to",
    "Zugewiesen": "Assigned",
    "Zugewiesene Fälle": "Assigned cases",
    "Zugewiesene Verkaufschancen": "Assigned opportunities",
    "Zugriffsschlüssel": "Access key",
    "Zulassung gewünscht": "Registration requested",
    "Zum Smartphone übertragen": "Transfer to smartphone",
    "Zuordnungseinstellungen": "Mapping settings",
    "Zur Anzeige auf dem Tablet": "For viewing on the tablet",
    "Zur Anzeige in einem neuen Tab": "For viewing in a new tab",
    "Zur Zeit steht kein Berater zur Verfügung. Bitte geben Sie Ihre Kontaktdaten an, wir melden uns schnellstmöglich bei Ihnen.": "There is currently no consultant available. Please enter your contact details and we will get back to you as soon as possible.",
    "Zurück": "Back",
    "Zurück zur Übersicht": "Back to the overview",
    "Zurückgestellt": "Postponed",
    "Zurückgestellt bis": "Postponed until",
    "Zurückgestellt bis {0}": "Postponed until {0}",
    "Zurückgestellte Fälle": "Postponed cases",
    "Zurückgestellte Verkaufschancen": "Postponed opportunities",
    "Zurücksetzen": "Reset",
    "Zurückstellen": "Postpone",
    "Zurückstellung beenden": "Cancel postponement",
    "Zurückstellung beendet": "Postponement ended",
    "Zurückstellungszeitpunkt auswählen": "Select postponement time",
    "Zusammenführen": "Merge",
    "Zusammengeführt mit": "Merged with",
    "Zusammenhängende Vorgänge": "Connected issues",
    "Zustand": "Condition",
    "Zustellung der E-Mail ist aus sonstigem Grund fehlgeschlagen. ({0})": "Delivery of email failed for a miscellaneous reason. ({0})",
    "Zustellung der E-Mail ist fehlgeschlagen. ({0})": "Delivery of email failed. ({0})",
    "Zustellung der E-Mail ist temporär fehlgeschlagen. ({0})": "Delivery of email failed temporarily. ({0})",
    "Zustellung der E-Mail konnte temporär nicht ausgeführt werden. ({0})": "Delivery of email count not be completed temporarily. ({0})",
    "Zustellung der E-Mail wurde durch den Provider blockiert. ({0})": "Delivery of email was blocked by the provider. ({0})",
    "Zustellung der Nachricht ist fehlgeschlagen. ({0})": "Delivery of message failed. ({0})",
    "Zusätzliche Informationen": "Additional information",
    "Zusätzliche Zielbenutzer der Call-Tracking-Gruppe": "Additional target users of the call tracking group",
    "Zuteilung": "Assignment",
    "Zuteilung aufgehoben": "Assignment cancelled",
    "Zuteilung erforderlich": "Assignment required",
    "Zuteilungen": "Assignments",
    "Zuteilungen entfernen ?": "Remove assignments ?",
    "Zwei-Faktor-Authentifizierung": "Two-factor authentication",
    "Zwei-Faktor-Authentifizierung notwendig": "Two-factor authentication required",
    "Zwischenname": "Middle name",
    "ab {from}": "from {from}",
    "absteigend": "descending",
    "abwesend": "away",
    "aktiv": "active",
    "aktuelle Woche": "current week",
    "alle Filter anzeigen": "show all filters",
    "alles aktivieren": "deactivate all",
    "alles deaktivieren": "activate all",
    "als Benutzer-E-Mail behandeln": "treat as user email",
    "als Empfänger entfernt": "removed as receiver",
    "als Empfänger hinzugefügt": "added as receiver",
    "als Spam gemeldet am {0}": "reported as spam at {0}",
    "angefragt": "requested",
    "aufsteigend": "ascending",
    "aus Bestand entfernt": "removed from inventory",
    "aus Zuteilung entfernt": "removed from assignment",
    "außerhalb der Arbeitszeit (bestätigt)": "outside of working hours (confirmed)",
    "außerhalb der Arbeitszeit (unbestätigt)": "outside of working hours (unconfirmed)",
    "außerhalb der Geschäftszeiten": "outside office hours",
    "baue Verbindung auf": "establishing connection",
    "bei beantwortetem Anruf": "on answered call",
    "bei unbeantwortetem Anruf": "on unanswered call",
    "bestätigt": "confirmed",
    "bestätigt am {0}": "read {0}",
    "bezahlt": "paid",
    "bis {0}": "until {0}",
    "bis {to}": "to {to}",
    "durch Rufnummernsperre blockiert": "blocked by phone number blocking",
    "erste Tageshälfte": "first half of the day",
    "erstellt am {0}": "created {0}",
    "flexibel": "flexible",
    "für nicht angenommene Anrufe": "for unanswered calls",
    "gelesen am {0}": "confirmed {0}",
    "geschlossen": "closed",
    "gewonnen": "won",
    "globale Vertreter": "global substitutes",
    "glücklich": "happy",
    "gültig bis {0}": "valid until {0}",
    "heute": "today",
    "hinzufügen": "add",
    "hochgeladen am {0}": "uploaded {0}",
    "hohe Priorität": "high priority",
    "ich": "me",
    "immer": "always",
    "inaktiv": "inactive",
    "inkl. MwSt.": "VAT included",
    "innerhalb der Arbeitszeit (bestätigt)": "within working hours (confirmed)",
    "innerhalb der Arbeitszeit (unbestätigt)": "within working hours (unconfirmed)",
    "innerhalb der Geschäftszeiten": "inside office hours",
    "ja": "yes",
    "keine Angabe": "N/A",
    "keine Arbeitszeiten hinterlegt": "no working hours found",
    "keine Benutzer | 1 Benutzer | {count} Benutzer": "no users | 1 user | {count} users",
    "keine Dateien | 1 Datei | {n} Dateien": "no files | 1 file | {n} files",
    "keine Einträge | 1 Eintrag | {count} Einträge": "no entries | 1 entry | {count} entries",
    "keine Sitze | 1 Sitz | {count} Sitze": "0 seats | 1 seat | {count} seats",
    "keine Türen | 1 Tür | {count} Türen": "0 doors | 1 door | {count} doors",
    "keine Vertretung": "no substitute",
    "keine Vorbesitzer | 1 Vorbesitzer | {count} Vorbesitzer": "0 previous owners | 1 previous owner | {count} previous owners",
    "keine ungelesenen Aktivitäten | 1 ungelesene Aktivität | {count} ungelesene Aktivitäten": "no unread activities | 1 unread activity | {count} unread activities",
    "letzte Aktivität am {0}": "last activity {0}",
    "mittlere Priorität": "medium priority",
    "nein": "no",
    "neutral": "neutral",
    "nicht aktiv": "not active",
    "nicht vorhanden": "not available",
    "nie": "never",
    "nur an Benutzer während Arbeitszeit": "only to users during working hours",
    "nur an verfügbare Benutzer": "only to available users",
    "nächste Woche": "next week",
    "offen": "open",
    "offener Vorgang": "open issue",
    "offline": "offline",
    "online": "online",
    "parallele Rufsignalisierung": "parallel call signaling",
    "priorisiert": "prioritized",
    "storniert": "cancelled",
    "unbekannt": "unknown",
    "unbestätigt": "unconfirmed",
    "ungelesen": "unread",
    "unglücklich": "unhappy",
    "verarbeitet am {0}": "processed at {0}",
    "verloren": "lost",
    "verschickt": "sent",
    "vorhanden": "available",
    "wieder im Bestand": "back in inventory",
    "wird an den Fahrzeugstandort angepasst": "overridden by vehicle location",
    "wird verarbeitet am {0}": "is processed on {0}",
    "zeitnah": "timely",
    "zugeteilt": "assigned",
    "zuletzt geklickt am {0}": "last clicked {0}",
    "zuletzt geöffnet am {0}": "last openend {0}",
    "zuletzt online am {0}": "last online {0}",
    "zur Zuteilung hinzugefügt": "added to assignment",
    "zurückgestellt": "postponed",
    "zweite Tageshälfte": "second half of the day",
    "{0} Bankdaten bearbeitet": "{0} bank data updated",
    "{0} Bankdaten hinzugefügt": "{0} bank data added",
    "{0} Bankdaten insgesamt": "{0} bank data total",
    "{0} Kontakte bearbeitet": "{0} contacts updated",
    "{0} Kontakte gelöscht": "{0} contacts removed",
    "{0} Kontakte hinzugefügt": "{0} contacts added",
    "{0} Kontakte insgesamt": "{0} contacts total",
    "{0} bis {1} Uhr": "{0} to {1} o'clock",
    "{0} geschlossene Verkaufschancen": "{0} closed opportunities",
    "{0} innerhalb von {1} Sek.": "{0} within {1} sec.",
    "{0} ist keine gültige E-Mail Adresse.": "{0} is no valid email address.",
    "{0} von {1} verarbeitet": "Processed {0} of {1}",
    "{0} wurde erfolgreich in die Zwischenablage kopiert.": "{0} was copied to clipboard successfully.",
    "{0}-{1} von {2}": "{0}-{1} of {2}",
    "{0}-{1} von {2}+": "{0}-{1} of {2}+",
    "{count}/{max} Einzelnachrichten": "{count}/{max} single messages",
    "{from} bis {to}": "{from} to {to}",
    "Ändere Zuteilung": "Change assignment",
    "Änderungen speichern": "Save changes",
    "Öffnen": "Open",
    "Öffnung hinzufügen": "Add opening",
    "Öffnungszeiten": "Opening hours",
    "Öffnungszeiten für die Bestimmung der Reaktionszeit": "Opening hours for determining the reaction time",
    "Öffnungszeiten für die Bestimmung der Reaktionszeit aus Sicht des Kontakts": "Opening hours for determining the reaction time from the contact's point of view",
    "Übernehmen": "Assign to me",
    "Übernehmen ({0})": "Assign to me ({0})",
    "Überschrift": "Headline",
    "Überspringen": "Skip",
    "ø {0}": "ø {0}",
    "über": "via",
    "validation": {
        "contact-upload-locked": "Operation in progress. Please try again later.",
        "contact-duplicate-removal-locked": "The contact duplicate removal is already in progress.",
        "inventory-import-locked": "Operation in progress. Please try again later.",
        "dealer-department-assignment-constraint-violation": "Each location and department must be assigned at most once and the main dealer must be assigned.",
        "duplicate-username": "Email address is already in use",
        "duplicate-alias": "Email alias is already in use",
        "incoming-phone-number-in-use": "Phone number is still in use",
        "duplicate-exceptional-day-date": "Exception for this date already exists",
        "email-signature-length-exceeded": "Email signature is too large",
        "invalid-ip-range": "IP range is invalid",
        "password-wrong": "The entered password is invalid",
        "domain-already-in-use": "The domain is already in use",
        "attachments-too-large": "Attachments are too large.",
        "forbidden-receiver": "The following receivers are not allowed be contacted: {0}.",
        "illegal-attachment-type": "Illegal attachment type.",
        "illegal-receiver": "The following receivers are invalid and cannot bet contacted: {0}.",
        "message-body-too-large": "The message is too long.",
        "pending-sender-domain": "The sender domain is not confirmed yet.",
        "too-many-login-attempts": "Captcha validation failed. Please try again.",
        "duplicate-system-alias-local-part": "Email alias is already in use",
        "cannot-assign-to-self": "Currently it is not possible to assign the opportunity to yourself.",
        "password-policy": {
            "TOO_SHORT": "Password must be {minimumLength} or more characters in length.",
            "INSUFFICIENT_UPPERCASE": "Password must contain {minimumRequired} or more uppercase characters.",
            "INSUFFICIENT_LOWERCASE": "Password must contain {minimumRequired} or more lowercase characters.",
            "INSUFFICIENT_DIGIT": "Password must contain {minimumRequired} or more digit characters.",
            "INSUFFICIENT_SPECIAL": "Password must contain {minimumRequired} or more special characters.",
            "ILLEGAL_WHITESPACE": "Password contains a whitespace character.",
            "ILLEGAL_WORD": "Password contains the forbidden word \"{matchingWord}\"."
        },
        "duplicate-whatsapp-account": "The WhatsApp account was already added.",
        "invalid-whatsapp-api-key": "The API key is not valid.",
        "invalid-sms-destination-number": "Text message cannot be sent to this number.",
        "xml-schema-validation-failed": "XML validation failed: \"{0}\".",
        "finance-contract-upload-locked": "Operation in progress. Please try again later.",
        "ftp-connection-failed": "Could not establish connection to FTP server.",
        "invalid-whatsapp-contact": "WhatsApp message cannot be sent to this number.",
        "whatsapp-unavailable": "WhatsApp for Business is currently unavailable. Please try again later.",
        "too-many-check-attempts": "You have entered an invalid token too many times.",
        "no-channel-selected": "You have to select exactly one new number.",
        "duplicate-blacklisted-number": "A phone number block already exists for this phone number.",
        "number-is-blocked-for-calls": "The phone number {0} is blocked for incoming and outgoing calls.",
        "number-is-blocked-for-text-messages": "The phone number {0} is blocked for sending and receiving text messages.",
        "duplicate-external-bdc-target-number": "The target number is already in use by another dealer.",
        "duplicate-citnow-video-lid": "The LID is already configured for this location.",
        "renault-race-api-error": "Accessing the Renault RACE API has failed.",
        "invalid-case-status": "The action cannot be executed as the case is in an invalid status.",
        "case-outcome-reason-required": "A reason must be specified.",
        "invalid-opportunity-status": "The action cannot be executed as the opportunity is in an invalid status.",
        "opportunity-outcome-reason-required": "A reason must be specified.",
        "invalid-emergency-incident-status": "The action cannot be executed because the emergency incident is in an invalid status.",
        "whatsapp-media-too-large": "The file exceeds the WhatsApp size limit for this file type.",
        "unsupported-whatsapp-media-type": "The file type is not supported by WhatsApp."
    },
    "locale": {
        "de": "Deutsch (Deutschland)",
        "de-CH": "Deutsch (Schweiz)",
        "en": "English (US)"
    },
    "localeFlag": {
        "de": "de",
        "de-CH": "ch",
        "en": "us"
    },
    "language": {
        "aa": "Afar",
        "ab": "Abkhaz",
        "ae": "Avestan",
        "af": "Afrikaans",
        "ak": "Akan",
        "am": "Amharic",
        "an": "Aragonese",
        "ar": "Arabic",
        "as": "Assamese",
        "av": "Avaric",
        "ay": "Aymara",
        "az": "Azerbaijani",
        "ba": "Bashkir",
        "be": "Belarusian",
        "bg": "Bulgarian",
        "bh": "Bihari",
        "bi": "Bislama",
        "bm": "Bambara",
        "bn": "Bengali",
        "bo": "Tibetan Standard",
        "br": "Breton",
        "bs": "Bosnian",
        "ca": "Catalan",
        "ce": "Chechen",
        "ch": "Chamorro",
        "co": "Corsican",
        "cr": "Cree",
        "cs": "Czech",
        "cu": "Old Church Slavonic",
        "cv": "Chuvash",
        "cy": "Welsh",
        "da": "Danish",
        "de": "German",
        "dv": "Divehi",
        "dz": "Dzongkha",
        "ee": "Ewe",
        "el": "Greek",
        "en": "English",
        "eo": "Esperanto",
        "es": "Spanish",
        "et": "Estonian",
        "eu": "Basque",
        "fa": "Persian",
        "ff": "Fula",
        "fi": "Finnish",
        "fj": "Fijian",
        "fo": "Faroese",
        "fr": "French",
        "fy": "Western Frisian",
        "ga": "Irish",
        "gd": "Scottish Gaelic",
        "gl": "Galician",
        "gn": "Guarani",
        "gu": "Gujarati",
        "gv": "Manx",
        "ha": "Hausa",
        "he": "Hebrew",
        "hi": "Hindi",
        "ho": "Hiri Motu",
        "hr": "Croatian",
        "ht": "Haitian",
        "hu": "Hungarian",
        "hy": "Armenian",
        "hz": "Herero",
        "ia": "Interlingua",
        "id": "Indonesian",
        "ie": "Interlingue",
        "ig": "Igbo",
        "ii": "Nuosu",
        "ik": "Inupiaq",
        "io": "Ido",
        "is": "Icelandic",
        "it": "Italian",
        "iu": "Inuktitut",
        "ja": "Japanese",
        "jv": "Javanese",
        "ka": "Georgian",
        "kg": "Kongo",
        "ki": "Kikuyu",
        "kj": "Kwanyama",
        "kk": "Kazakh",
        "kl": "Kalaallisut",
        "km": "Khmer",
        "kn": "Kannada",
        "ko": "Korean",
        "kr": "Kanuri",
        "ks": "Kashmiri",
        "ku": "Kurdish",
        "kv": "Komi",
        "kw": "Cornish",
        "ky": "Kyrgyz",
        "la": "Latin",
        "lb": "Luxembourgish",
        "lg": "Ganda",
        "li": "Limburgish",
        "ln": "Lingala",
        "lo": "Lao",
        "lt": "Lithuanian",
        "lu": "Luba-Katanga",
        "lv": "Latvian",
        "mg": "Malagasy",
        "mh": "Marshallese",
        "mi": "Māori",
        "mk": "Macedonian",
        "ml": "Malayalam",
        "mn": "Mongolian",
        "mr": "Marathi",
        "ms": "Malay",
        "mt": "Maltese",
        "my": "Burmese",
        "na": "Nauru",
        "nb": "Norwegian Bokmål",
        "nd": "Northern Ndebele",
        "ne": "Nepali",
        "ng": "Ndonga",
        "nl": "Dutch",
        "nn": "Norwegian Nynorsk",
        "no": "Norwegian",
        "nr": "Southern Ndebele",
        "nv": "Navajo",
        "ny": "Chichewa",
        "oc": "Occitan",
        "oj": "Ojibwe",
        "om": "Oromo",
        "or": "Oriya",
        "os": "Ossetian",
        "pa": "Panjabi",
        "pi": "Pāli",
        "pl": "Polish",
        "ps": "Pashto",
        "pt": "Portuguese",
        "qu": "Quechua",
        "rm": "Romansh",
        "rn": "Kirundi",
        "ro": "Romanian",
        "ru": "Russian",
        "rw": "Kinyarwanda",
        "sa": "Sanskrit",
        "sc": "Sardinian",
        "sd": "Sindhi",
        "se": "Northern Sami",
        "sg": "Sango",
        "si": "Sinhala",
        "sk": "Slovak",
        "sl": "Slovene",
        "sm": "Samoan",
        "sn": "Shona",
        "so": "Somali",
        "sq": "Albanian",
        "sr": "Serbian",
        "ss": "Swati",
        "st": "Southern Sotho",
        "su": "Sundanese",
        "sv": "Swedish",
        "sw": "Swahili",
        "ta": "Tamil",
        "te": "Telugu",
        "tg": "Tajik",
        "th": "Thai",
        "ti": "Tigrinya",
        "tk": "Turkmen",
        "tl": "Tagalog",
        "tn": "Tswana",
        "to": "Tonga",
        "tr": "Turkish",
        "ts": "Tsonga",
        "tt": "Tatar",
        "tw": "Twi",
        "ty": "Tahitian",
        "ug": "Uyghur",
        "uk": "Ukrainian",
        "ur": "Urdu",
        "uz": "Uzbek",
        "ve": "Venda",
        "vi": "Vietnamese",
        "vo": "Volapük",
        "wa": "Walloon",
        "wo": "Wolof",
        "xh": "Xhosa",
        "yi": "Yiddish",
        "yo": "Yoruba",
        "za": "Zhuang",
        "zh": "Chinese",
        "zu": "Zulu"
    },
    "caseTypeInitialNoteTemplate": {
        "WORKSHOP_APPOINTMENT": "The contact requests a workshop appointment for the named vehicle.",
        "INSPECTION_APPOINTMENT": "The contact requests an inspection appointment for the named vehicle.",
        "TYRE_CHANGE_APPOINTMENT": "The contact requests a tyre change for the vehicle in question.",
        "LEGAL_INSPECTION_APPOINTMENT": "The contact requests a legal inspection appointment for the named vehicle.",
        "APPOINTMENT_POSTPONEMENT": "The contact has made an appointment with you and would like to postpone it. Appointment: ",
        "APPOINTMENT_CANCELLATION": "The contact has to cancel the agreed appointment. Appointment: ",
        "SERVICE_STATUS": "The contact requests information on the current service status for the named vehicle.",
        "TECHNICAL_QUESTION": "The contact has a technical question: ",
        "SERVICE_AUTHORIZATION": "The contact has a query: ",
        "SERVICE_PRICE_INFORMATION": "The contact requests a price information on the following service: ",
        "WARRANTY_PROCESSING": "The contact has a query: ",
        "OTHER_SERVICE_REQUEST": "The contact has a query: ",
        "NEW_CARS": "The contact expressed interest in the following new car: ",
        "USED_CARS": "The contact expressed interest in the following used car: ",
        "OTHER_SALES_REQUEST": "The contact has a query: ",
        "PARTS_ORDER": "The contact would like to order the following spare part: ",
        "PARTS_STATUS": "The contact has a query about his spare parts order.",
        "PARTS_PRICE_INFORMATION": "The contact requests a price information about the following spare part: ",
        "ONLINE_SHOP": "The contact has a query: ",
        "OTHER_PARTS_REQUEST": "The contact has a query: ",
        "COMPLAINT": "The contact complains about the following issue: ",
        "ACCOUNTING": "The contact has a query about the following invoice: ",
        "CAR_RENTAL": "The contact needs a rental car.",
        "EMERGENCY": "The contact has the following problem and needs help: ",
        "PERSONAL_CONCERN": "The contact would like to discuss the matter with you personally. Note: ",
        "REAL_ESTATE": "The contact has a query about the following property: ",
        "NOTES": "",
        "JOB_APPLICATION": "The contact has a query about his job application: ",
        "OTHER_REQUEST": "The contact has a query about the following issue: "
    },
    "enum": {
        "Role": {
            "ACCOUNTANT": "Accountant",
            "CASE_VIEWER": "Case viewer",
            "CASE_ACTIVITY_VIEWER": "Case viewer (with activity content)",
            "CASE_ADMIN": "Case admin",
            "EMERGENCY_INCIDENT_ADMIN": "Emergency incident admin",
            "OPPORTUNITY_VIEWER": "Opportunity viewer",
            "OPPORTUNITY_ACTIVITY_VIEWER": "Opportunity viewer (with activity content)",
            "OPPORTUNITY_ADMIN": "Opportunity admin",
            "LOCAL_CONTROLLER": "Local controller",
            "GLOBAL_CONTROLLER": "Global controller",
            "LOCAL_ADMIN": "Local admin",
            "SUPER_ADMIN": "Super admin"
        },
        "PaymentMethod": {
            "BANK_TRANSFER": "Bank transfer",
            "SEPA_B2B_DIRECT_DEBIT": "SEPA B2B direct debit"
        },
        "AbsenceType": {
            "TRAINING_SCHOOL": "Training school",
            "BUSINESS_TRIP": "Business trip",
            "PARENTAL_LEAVE": "Parental leave",
            "HOME_OFFICE": "Home office",
            "VACATION": "Vacation",
            "SICKNESS": "Sickness",
            "FIELD_WORK": "Field work",
            "FURTHER_TRAINING": "Further training",
            "COMPENSATORY_TIME_OFF": "Compensatory time off",
            "EXHIBITION": "Exhibition"
        },
        "AwayType": {
            "BREAK": "Break",
            "MEETING": "Meeting",
            "CUSTOMER_TALK": "Customer talk",
            "OTHER": "Other"
        },
        "MileageUnit": {
            "KM": "km",
            "MI": "mi"
        },
        "InventoryImportSourceType": {
            "FTP": "FTP upload (CSV)",
            "FTP_STOCK2NET": "FTP upload (Stock2Net)",
            "MOBILE_DE": "Mobile.de Ad Integration"
        },
        "InventoryImportErrorType": {
            "GENERAL": "General Error",
            "WRONG_CREDENTIALS": "Wrong credentials",
            "FILE_NOT_FOUND": "File not found",
            "CORRUPT_FILE": "File is corrupt"
        },
        "CaseEmailFormat": {
            "REGULAR": "Regular format",
            "REGULAR_WITHOUT_CONTACT_DATA": "Regular format with hidden contact details",
            "REGULAR_WITH_CLOSE_LINK": "Regular format with direct close link"
        },
        "OpportunityNotificationEmailFormat": {
            "REGULAR": "Regular format",
            "REGULAR_WITHOUT_CONTACT_DATA": "Regular format with hidden contact details",
            "REGULAR_WITH_CLOSE_LINK": "Regular format with direct close link"
        },
        "EscalationGroupVisibility": {
            "DEALER": "Only this location",
            "SELECTED_DEALERS": "Only selected locations",
            "DEALERGROUP": "Whole dealergroup"
        },
        "PartnerType": {
            "EMERGENCY_MECHANIC": "Emergency mechanic",
            "ASSISTANCE": "Assistance",
            "TOWING_SERVICE": "Towing service",
            "CAR_RENTAL": "Car rental",
            "MOTOR_VEHICLE_ASSESSOR": "Motor vehicle assessor",
            "EMERGENCY_CONTACT": "Emergency contact",
            "OTHERS": "Others"
        },
        "CasesSort": {
            "LAST_ACTIVITY_DESC": "Last activity (descending)",
            "LAST_ACTIVITY_ASC": "Last activity (ascending)",
            "POSTPONED_UNTIL_DESC": "Postponed until (descending)",
            "POSTPONED_UNTIL_ASC": "Postponed until (ascending)",
            "CLOSED_DESC": "Closed (descending)",
            "CLOSED_ASC": "Closed (ascending)",
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "VISIBILITY_BEGIN_DESC": "Visibility begin (descending)",
            "VISIBILITY_BEGIN_ASC": "Visibility begin (ascending)",
            "VISIBILITY_END_DESC": "Visibility end (descending)",
            "VISIBILITY_END_ASC": "Visibility end (ascending)",
            "PRIORITY_DESC": "Priority (descending)"
        },
        "OpportunitiesSort": {
            "LAST_ACTIVITY_DESC": "Last activity (descending)",
            "LAST_ACTIVITY_ASC": "Last activity (ascending)",
            "POSTPONED_UNTIL_DESC": "Postponed until (descending)",
            "POSTPONED_UNTIL_ASC": "Postponed until (ascending)",
            "CLOSED_DESC": "Closed (descending)",
            "CLOSED_ASC": "Closed (ascending)",
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "VISIBILITY_BEGIN_DESC": "Visibility begin (descending)",
            "VISIBILITY_BEGIN_ASC": "Visibility begin (ascending)",
            "VISIBILITY_END_DESC": "Visibility end (descending)",
            "VISIBILITY_END_ASC": "Visibility end (ascending)",
            "PRIORITY_DESC": "Priority (descending)"
        },
        "LegalInspectionType": {
            "NOT_POSSIBLE": "Not possible",
            "POSSIBLE_WITH_APPOINTMENT_ONLY": "Possible with appointment only",
            "POSSIBLE_WITHOUT_APPOINTMENT": "Possible without appointment"
        },
        "FeedbackType": {
            "INFO": "General feedback (no mistake)",
            "ERROR_WRONG_LICENSE_PLATE": "Wrong license plate",
            "ERROR_WRONG_PHONE_NUMBER": "Wrong phone number",
            "ERROR_WRONG_RECEIVER": "Wrong receiver",
            "ERROR_OTHER": "Other mistake"
        },
        "IncomingPhoneNumberStatus": {
            "AVAILABLE": "Available",
            "INSTALLED": "Installed",
            "INSTALLED_INVALID": "Installed (invalid)",
            "INSTALLED_NOT_FOUND": "Installed (not found)",
            "INSTALLED_DEALERDESK_LEGACY": "Taken (Legacy)",
            "INSTALLED_OTHER": "Taken"
        },
        "ActorType": {
            "USER": "Internal User",
            "CT_USER": "External Agent",
            "SYSTEM": "System"
        },
        "CaseSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "LAST_ACTIVITY_DESC": "Last activity (descending)",
            "LAST_ACTIVITY_ASC": "Last activity (ascending)",
            "POSTPONED_UNTIL_DESC": "Postponed until (descending)",
            "POSTPONED_UNTIL_ASC": "Postponed until (ascending)",
            "CLOSED_DESC": "Closed (descending)",
            "CLOSED_ASC": "Closed (ascending)",
            "PRIORITY_DESC": "Priority (descending)"
        },
        "InventorySearchOrder": {
            "INTERNAL_ID_DESC": "Vehicle number (descending)",
            "INTERNAL_ID_ASC": "Vehicle number (ascending)",
            "FIRST_REGISTRATION_DESC": "First registration (descending)",
            "FIRST_REGISTRATION_ASC": "First registration (ascending)",
            "MILEAGE_DESC": "Mileage (descending)",
            "MILEAGE_ASC": "Mileage (ascending)",
            "PRICE_DESC": "Price (descending)",
            "PRICE_ASC": "Price (ascending)",
            "TOTAL_OPPORTUNITIES_DESC": "Opportunities count (descending)",
            "TOTAL_OPPORTUNITIES_ASC": "Opportunities count (ascending)"
        },
        "InventoryVehicleStatusType": {
            "AVAILABLE": "available",
            "RESERVED": "reserved",
            "SOLD": "sold"
        },
        "OpportunityTeamDistributionMethod": {
            "PARALLEL": "Parallel",
            "ROUND_ROBIN": "Round-robin",
            "RANDOM": "Random"
        },
        "OpportunityTeamVisibility": {
            "DEALER": "Only this location",
            "SELECTED_DEALERS": "Only selected locations",
            "DEALERGROUP": "Whole dealergroup"
        },
        "OpportunitySearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "LAST_ACTIVITY_DESC": "Last activity (descending)",
            "LAST_ACTIVITY_ASC": "Last activity (ascending)",
            "POSTPONED_UNTIL_DESC": "Postponed until (descending)",
            "POSTPONED_UNTIL_ASC": "Postponed until (ascending)",
            "CLOSED_DESC": "Closed (descending)",
            "CLOSED_ASC": "Closed (ascending)",
            "PRIORITY_DESC": "Priority (descending)"
        },
        "TypeHint": {
            "CASE": "Case",
            "OPPORTUNITY": "Opportunity",
            "AUTO_DETECT": "Automatic detection"
        },
        "QualityAssuranceLevel": {
            "NONE": "None",
            "DATA_MAINTENANCE": "Data maintenance",
            "DATA_MAINTENANCE_WITH_PRE_QUALIFICATION": "Data maintenance with pre-qualification"
        },
        "AutoAnswerType": {
            "NONE": "None",
            "PLAY_ANNOUNCEMENT": "Play announcement",
            "TRANSFER_TO_CUSTOM_NUMBER": "Transfer to custom phone number",
            "TRANSFER_TO_EXTERNAL_BDC": "Transfer to external call center",
            "TRANSFER_TO_INCOMING_PHONE_NUMBER": "Transfer to call tracking number",
            "TRANSFER_VIA_DTMF_MENU": "Transfer via speech menu"
        },
        "IncomingCallDistributionType": {
            "PARALLEL": "Parallel",
            "ROUND_ROBIN": "Round-robin"
        },
        "Gender": {
            "MALE": "male",
            "FEMALE": "female",
            "DIVERSE": "diverse"
        },
        "SentimentType": {
            "UNKNOWN": "Unknown sentiment",
            "POSITIVE": "Positive sentiment",
            "NEUTRAL": "Neutral sentiment",
            "NEGATIVE": "Negative sentiment"
        },
        "IssueType": {
            "UNKNOWN": "Unknown",
            "CARRY_DISPO": "CARRY Dispo",
            "CASE": "Case",
            "EMERGENCY_INCIDENT": "Emergency incident",
            "OPPORTUNITY": "Opportunity"
        },
        "EmergencyIncidentSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "LAST_ACTIVITY_DESC": "Last activity (descending)",
            "LAST_ACTIVITY_ASC": "Last activity (ascending)",
            "CLOSED_DESC": "Closed (descending)",
            "CLOSED_ASC": "Closed (ascending)"
        },
        "ContactParserFormat": {
            "GENERIC": "Generic format",
            "ALPHA_PLUS": "Alpha Plus",
            "AMIS": "AMIS",
            "APAS": "APAS",
            "AUDARIS": "Audaris",
            "AUTOSYS": "Autosys",
            "AUTOSYS2": "Autosys 2",
            "AUTRADO": "Autrado",
            "BUERO_PLUS": "büro+",
            "BUNGERTP2": "Bungert P2",
            "CARACAMP": "CaraCamp",
            "CARDIS": "CARDIS",
            "CARE": "CARE / VEACT",
            "CARIT": "Carit",
            "CAVIO": "caVio",
            "CDK": "CDK",
            "CDK_2": "CDK 2",
            "CIDCAR": "cidcar",
            "CROSS": "Cross",
            "CSB": "CSB",
            "CUSTOMER_ONE": "CustomerOne",
            "DRACAR": "Dracar",
            "ECAROS": "ecaros",
            "EVA": "EVA",
            "FILAKSPLUS": "FILAKS.PLUS",
            "FORMEL1": "Formel 1",
            "HUBSPOT": "HubSpot",
            "INCADEA": "incadea.dms",
            "INGOSOFT": "Ingo Software",
            "KAR2": "KAR/2",
            "KSRVIS": "KSR VIS",
            "LOCOSOFT": "Loco-Soft",
            "MACS": "MACS",
            "PROF4NET_CATCH": "CATCH (Prof4Net)",
            "SALES_ASSIST": "SalesAssist",
            "SALES_ASSIST_2": "SalesAssist 2",
            "SAMSURION": "SAMsurion",
            "SAP_PRO": "SAP Professional",
            "SYSCARA": "SYSCARA",
            "TELREP": "TELREP",
            "VAUDIS_CLASSIC": "VaudisClassic",
            "VAUDIS_PRO": "VaudisPro",
            "VAUDIS_X": "VaudisX",
            "VECTORCDP": "VECTOR CDP",
            "VERENDUS": "Verendus",
            "WEBCARE": "web.care",
            "WEBMOBIL24": "WebMobil24",
            "WERWISO": "werwiso",
            "ZAENCAR": "zänCAR",
            "UNKNOWN": "Unknown"
        },
        "ContactParserCharset": {
            "UTF_8": "UTF-8",
            "ISO_8859_1": "ISO 8859-1"
        },
        "ContactParserSeparator": {
            "COMMA": "Comma",
            "PIPE": "Pipe",
            "SEMICOLON": "Semicolon",
            "TAB": "Tab"
        },
        "EmergencyIncidentNotificationEmailFormat": {
            "REGULAR": "Regular format",
            "REGULAR_WITHOUT_CONTACT_DATA": "Regular format with hidden contact details",
            "REGULAR_WITHOUT_LINK": "Regular format without link to emergency incident"
        },
        "ProcessingState": {
            "UNPROCESSED": "Unprocessed",
            "UNPROCESSED_IGNORE_FILTER": "Unprocessed (forced)",
            "PROCESSED": "Processed",
            "PROCESS_MANUALLY": "Manual processing",
            "FILTERED": "Filtered"
        },
        "IncomingEmailSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "FROM_NAME_DESC": "Sender name (descending)",
            "FROM_NAME_ASC": "Sender name (ascending)",
            "FROM_ADDRESS_DESC": "Sender address (descending)",
            "FROM_ADDRESS_ASC": "Sender address (ascending)",
            "SUBJECT_DESC": "Subject (descending)",
            "SUBJECT_ASC": "Subject (ascending)"
        },
        "OutgoingSmsSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)"
        },
        "IncomingSmsSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)"
        },
        "OutgoingIncomingEmailSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "FROM_NAME_DESC": "Sender name (descending)",
            "FROM_NAME_ASC": "Sender name (ascending)",
            "FROM_ADDRESS_DESC": "Sender address (descending)",
            "FROM_ADDRESS_ASC": "Sender address (ascending)",
            "SUBJECT_DESC": "Subject (descending)",
            "SUBJECT_ASC": "Subject (ascending)"
        },
        "NoteSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)"
        },
        "OutgoingCallSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)"
        },
        "IncomingCallSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)"
        },
        "InventoryVehicleInquirySearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)"
        },
        "CarryDispo": {
            "OrderType": {
                "UNFALL": "Accident",
                "PANNE": "Breakdown",
                "VERBRINGUNGSFAHRT": "Shipment",
                "HOL_UND_BRINGDIENST": "Pickup and delivery service",
                "LACKFAHRT": "Paint drive",
                "FALSCHPARKER": "Parking offender",
                "UEBERFUEHRUNG": "Transfer"
            },
            "PropulsionType": {
                "DIESEL": "Diesel",
                "DIESEL_HYBRID": "Diesel hybrid",
                "ELECTRIC": "Electric",
                "GASOLINE": "Petrol",
                "GASOLINE_HYBRID": "Petrol hybrid",
                "OTHER": "Other",
                "UNKNOWN": "Unknown"
            },
            "Status": {
                "NO_CLEARANCE": "No clearance",
                "CLEARANCE": "Cleared",
                "CANCELLED": "Cancelled",
                "EMPTY_TRIP": "Empty trip",
                "TIMEOUT": "Expired",
                "ARCHIVED": "Archived"
            },
            "TargetType": {
                "SERVICE_POINT": "Location",
                "DELIVERY_POINT": "Destination"
            },
            "TransmissionType": {
                "AUTOMATIC": "Automatic",
                "AUTOMATIC_DUAL_CLUTCH": "Dual-clutch gear",
                "MANUAL": "Manual",
                "MANUMATIC": "Manumatic",
                "OTHER": "Other",
                "UNKNOWN": "Unknown"
            },
            "VehicleType": {
                "PKW": "Car",
                "VAN": "Van",
                "MOTORRAD": "Motorbike",
                "MOTORRAD_MIT_BEIWAGEN": "Motorbike with sidecar",
                "SUV": "SUV",
                "WOHNMOBIL": "Motorhome",
                "WOHNWAGEN": "Caravan",
                "ANHAENGER": "Trailer",
                "LKW": "Truck",
                "BUS": "Bus",
                "FLUGZEUG": "Airplane",
                "BOOTSANHAENGER": "Boat trailer",
                "ANHAENGER_MIT_BOOT": "Trailer with boat",
                "BOOT": "Boat",
                "CONTAINER": "Container"
            },
            "WhatsAppApiType": {
                "CLOUD_API": "Cloud API",
                "ON_PREMISES_API": "On-Premises API"
            }
        },
        "IncomingWhatsAppSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)"
        },
        "OutgoingWhatsAppSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)"
        },
        "OutgoingWhatsAppMessageStatus": {
            "SENT": "Sent",
            "DELIVERED": "Delivered",
            "READ": "Read",
            "FAILED": "Failed"
        },
        "ContactSource": {
            "UNKNOWN": "Unknown",
            "UPLOAD": "Import",
            "UI": "Manually created",
            "SYSTEM": "System",
            "API": "API",
            "DELETED_UPLOAD": "Import (deleted source)"
        },
        "FinanceContractParserFormat": {
            "GENERIC": "Generisches Format",
            "AKF": "AKF",
            "ALD_AUTOMOTIVE": "ALD Automotive",
            "BANK11": "Bank11",
            "BMW_FINANCING": "BMW financing",
            "BMW_LEASING": "BMW leasing",
            "CATCH": "Catch",
            "CREDIT_PLUS": "CreditPlus bank",
            "FCA": "FCA bank",
            "HIS": "HIS",
            "HYUNDAI": "Hyundai bank",
            "MERCEDES": "Mercedes",
            "OPEL": "Opel",
            "PEUGEOT_FINANCING": "Peugeot financing",
            "PEUGEOT_LEASING": "Peugeot leasing",
            "PSA_BANK": "Banque PSA Finance",
            "RCI": "RCI",
            "SANTANDER_FINANCING": "Santander financing",
            "SANTANDER_LEASING": "Santander leasing"
        },
        "FinanceContractParserCharset": {
            "UTF_8": "UTF-8",
            "ISO_8859_1": "ISO 8859-1"
        },
        "FinanceContractParserSeparator": {
            "COMMA": "Comma",
            "PIPE": "Pipe",
            "SEMICOLON": "Semicolon",
            "TAB": "Tab"
        },
        "FinanceContractType": {
            "LEASING": "Leasing",
            "FINANCING": "Financing"
        },
        "FinanceContractCustomerType": {
            "PRIVATE": "Private",
            "BUSINESS": "Business"
        },
        "FinanceContractVehicleUsageType": {
            "NEW": "New vehicle",
            "USED": "Used vehicle"
        },
        "ContactUploadType": {
            "EXTERNAL_API": "API upload",
            "FTP": "FTP upload",
            "UI": "Manual upload"
        },
        "TimelineItemType": {
            "AUDIT_EVENT": "Status change",
            "INCOMING_CALL": "Incoming call",
            "INCOMING_EMAIL": "Incoming email",
            "INCOMING_SMS": "Incoming text message",
            "INCOMING_WHATSAPP": "Incoming WhatsApp message",
            "INVENTORY_VEHICLE_INQUIRY_MATCH": "Match for search inquiry",
            "NOTE": "Note",
            "OUTGOING_CALL": "Outgoing call",
            "OUTGOING_EMAIL": "Outgoing email",
            "OUTGOING_SMS": "Outgoing text message",
            "OUTGOING_WHATSAPP": "Outgoing WhatsApp message"
        },
        "DealerInstructionPriority": {
            "LOW": "low",
            "MEDIUM": "medium",
            "HIGH": "high"
        },
        "DealerInstructionCategory": {
            "CONTACT_SEARCH": "Contact search",
            "DATA_ACQUISITION": "Data acquisition",
            "RECEIVER_SELECTION": "Receiver selection"
        },
        "FilterTimeRange": {
            "NONE": "No restriction",
            "TODAY": "Today",
            "YESTERDAY": "Yesterday",
            "THIS_WEEK": "Current week",
            "LAST_WEEK": "Last week",
            "THIS_MONTH": "Current month",
            "LAST_MONTH": "Last month",
            "THIS_YEAR": "Current year",
            "LAST_YEAR": "Last year"
        },
        "FinanceContractSearchOrder": {
            "FINAL_INSTALLMENT_PAYMENT_DATE_ASC": "Final installment payment date (ascending)",
            "FINAL_INSTALLMENT_PAYMENT_DATE_DESC": "Final installment payment date (descending)"
        },
        "ExternalAbsenceProviderType": {
            "ATOSS": "ATOSS",
            "ATOSS_DEFAULT": "ATOSS (default)",
            "AUTOSYS": "Autosys",
            "KDV_ZDE": "KDV-ZDE",
            "PERSONIO": "Personio",
            "SAGE_HR": "Sage HR",
            "SOFT_CLOCK": "soft-clock",
            "STARTIME": "StarTIME2",
            "TIME_IX": "Time iX",
            "TIMEBUTLER": "Timebutler",
            "TIMEMETER_PLUS": "TimeMeter Plus",
            "WEBCARE": "web.care"
        },
        "NumberBlacklistSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "NUMBER_DESC": "Phone number (descending)",
            "NUMBER_ASC": "Phone number (ascending)"
        },
        "RenaultRaceApiErrorType": {
            "FORBIDDEN": "The Renault RACE API reports that access to the called endpoint is not permitted.",
            "GENERAL": "General Error",
            "HTTP_BASIC_AUTH_CREDENTIALS_INCORRECT": "The credentials are incorrect.",
            "INTERNAL_SERVER_ERROR": "The Renault RACE API reports an internal server error.",
            "LEAD_NOT_FOUND": "The Renault RACE API reports that the lead could not be found.",
            "LEAD_PROCESSING_FAILED": "The retrieved lead could not be processed.",
            "TLS_CLIENT_CERTIFICATE_DECODING_FAILED": "The TLS client certificate could not be decrypted.",
            "TLS_CLIENT_CERTIFICATE_INCORRECT": "The TLS client certificate is incorrect.",
            "TLS_CLIENT_CERTIFICATE_INCORRECT_PASSWORD": "The password to the TLS client certificate is incorrect."
        }
    },
    "messagePlaceholder": {
        "SALUTATION": "Greeting",
        "CONTACT_SALUTATION": "Salutation",
        "CONTACT_GIVEN_NAME": "Given name",
        "CONTACT_FAMILY_NAME": "Family name",
        "CONTACT_FULL_NAME": "Name",
        "DEALER_NAME": "Name of the dealership",
        "DEALER_ADDRESS_1": "Supplement of the address",
        "DEALER_ADDRESS_2": "Street and house number of the dealership",
        "DEALER_ZIP": "ZIP Code",
        "DEALER_CITY": "City",
        "DEALER_STATE": "Region",
        "DEALER_COUNTRY": "Country",
        "USER_SALUTATION": "Salutation",
        "USER_GIVEN_NAME": "Given name",
        "USER_FAMILY_NAME": "Family name",
        "USER_FULL_NAME": "Name",
        "USER_EMAIL_ADDRESS": "Email address",
        "USER_PHONE": "Personal phone number",
        "USER_LANDLINE": "Personal landline number",
        "USER_MOBILE": "Personal mobile number",
        "USER_FAX": "Personal fax number",
        "USER_LANGUAGES": "Spoken languages",
        "USER_PROFILE_IMAGE_100": "Profile picture (small)",
        "USER_PROFILE_IMAGE_150": "Profile picture (medium)",
        "USER_PROFILE_IMAGE_200": "Profile picture (big)",
        "USER_DEALER_LANDLINE": "Landline number at the main location",
        "USER_DEALER_MOBILE": "Mobile number at the main location",
        "USER_DEALER_FAX": "Fax number at the main location",
        "USER_DEPARTMENT_NAME": "Department at the main location",
        "USER_DEPARTMENT_POSITION": "Position in the department",
        "USER_SIGNATURE": "Signature",
        "REQUESTED_VEHICLE_CAPTION": "Vehicle name",
        "REQUESTED_VEHICLE_MAKE": "Make",
        "REQUESTED_VEHICLE_MODEL": "Model",
        "REQUESTED_VEHICLE_MODEL_DESCRIPTION": "Model description",
        "REQUESTED_VEHICLE_INTERNAL_ID": "Vehicle number",
        "EMAIL_QUOTE": "Email quote",
        "ISSUE_ID": "Issue ID",
        "LINK_<key>": "Link"
    },
    "messagePlaceholderDescription": {
        "SALUTATION": "Formal salutation of the associated contact, e.g. Dear Mr. Smith",
        "CONTACT_SALUTATION": "Salutation of the user, e.g. Mr.",
        "CONTACT_GIVEN_NAME": "Given name of the user, e.g. Tom",
        "CONTACT_FAMILY_NAME": "Family name of the user, e.g. Smith",
        "CONTACT_FULL_NAME": "Name of the user, e.g. Tom Smith",
        "DEALER_NAME": "Name of the dealership, e.g. John Doe Automobiles Inc.",
        "DEALER_ADDRESS_1": "Supplement to the Address of the dealership",
        "DEALER_ADDRESS_2": "Street and house number of the dealership, e.g. 123 Main Street",
        "DEALER_ZIP": "Zip code of the dealership",
        "DEALER_CITY": "City of the dealership",
        "DEALER_STATE": "State of the dealership, e.g. Bavaria",
        "DEALER_COUNTRY": "Country of the dealership, e.g. Germany",
        "USER_SALUTATION": "Salutation of the user, e.g. Mr.",
        "USER_GIVEN_NAME": "Given name of the user, e.g. John",
        "USER_FAMILY_NAME": "Family name of the user, e.g. Doe",
        "USER_FULL_NAME": "Name of the user, e.g. John Doe",
        "USER_EMAIL_ADDRESS": "Email address of the user, e.g. john.doe@dealergroup.com",
        "USER_PHONE": "Personal phone number of the user (landline or mobile)",
        "USER_LANDLINE": "Personal landline number of the user, e.g. +49 800 6630000",
        "USER_MOBILE": "Personal mobile number of the user",
        "USER_FAX": "Personal fax number of the user",
        "USER_LANGUAGES": "Spoken languages of the user, e.g. English, German",
        "USER_PROFILE_IMAGE_100": "Profile picture of the user (100px wide)",
        "USER_PROFILE_IMAGE_150": "Profile picture of the user (150px wide)",
        "USER_PROFILE_IMAGE_200": "Profile picture of the user (200px wide)",
        "USER_DEALER_LANDLINE": "Landline number of the user at the main location",
        "USER_DEALER_MOBILE": "Mobile number of the user at the main location",
        "USER_DEALER_FAX": "Fax number of the user at the main location",
        "USER_DEPARTMENT_NAME": "Department of the user at the main location, e.g. Customer service",
        "USER_DEPARTMENT_POSITION": "Position of the user in the department at the main location, e.g. Service consultant",
        "USER_SIGNATURE": "Signature of the user",
        "REQUESTED_VEHICLE_CAPTION": "Name of the requested vehicle",
        "REQUESTED_VEHICLE_MAKE": "Make of the requested vehicle",
        "REQUESTED_VEHICLE_MODEL": "Model of the requested vehicle",
        "REQUESTED_VEHICLE_MODEL_DESCRIPTION": "Model description of the requested vehicle",
        "REQUESTED_VEHICLE_INTERNAL_ID": "Vehicle number of the requested vehicle",
        "EMAIL_QUOTE": "Quote of the last email",
        "ISSUE_ID": "Identifier of issue",
        "LINK_<key>": "Link to URL with appropriate key"
    },
    "welcomeEmailPlaceholders": {
        "SALUTATION": "Greeting",
        "USERNAME": "Username",
        "PASSWORD": "Password",
        "APP_NAME": "Name of the web portal",
        "APP_LINK": "Link to the web portal"
    },
    "welcomeEmailPlaceholdersDescription": {
        "SALUTATION": "Formal salutation of the associated contact, e.g. Dear Mr. Smith",
        "USERNAME": "Username for login",
        "PASSWORD": "Password of the user",
        "APP_NAME": "",
        "APP_LINK": ""
    },
    "externalInventoryManagementUserLinkPlaceholder": {
        "USER_ID": "User ID"
    },
    "externalInventoryManagementUserLinkPlaceholderDescription": {
        "USER_ID": "ID of the user"
    },
    "externalInventoryManagementVehicleLinkPlaceholder": {
        "USER_ID": "User ID",
        "INTERNAL_ID": "Vehicle number",
        "VIN": "VIN"
    },
    "externalInventoryManagementVehicleLinkPlaceholderDescription": {
        "USER_ID": "ID of the user",
        "INTERNAL_ID": "Number of the vehicle in the external inventory management system",
        "VIN": "Vehicle identification number"
    },
    "email": {
        "welcomeEmail": {
            "subject": "Welcome to %APP_NAME% 10! (Login credentials)",
            "content": "<p>%SALUTATION%,</p>\nplease find your login credentials for the %APP_NAME%-System in this email.</p>\n<p>\n<strong>Web portal: </strong><a href=\"%APP_LINK%\">%APP_LINK%</a><br />\n<strong>Username: </strong>%USERNAME%<br />\n<strong>Password: </strong>%PASSWORD%<br />\n</p>\n<p>\nWe support the following browsers:<br />\n- Mozilla Firefox<br />\n- Google Chrome<br />\n- Safari<br />\n- Microsoft Edge<br />\n</p>\n<p>Please note that we only support current versions of the browsers above.</p>\n<p><strong>IMPORTANT:</strong> Due to security reasons, we advise you to change your password. You can change your password via the top left menu item \"Change password\".</p>\n<p>Furthermore it is important that you update your profile (general settings, working hours, notifications).</p>\n<p>\nIf you have further questions, please do not hesitate to contact us.<br />\n<strong>Email: </strong>support@dealerdesk.de<br />\n<strong>Technical support: </strong>+49 40 33480668<br />\n<strong>Call center support: </strong>+49 40 33452744<br />\n</p>\n<p>\nBest regards<br />\nIhr %APP_NAME%-Team\n</p>"
        }
    }
};
