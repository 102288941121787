import { ContactSearchRequest } from "@/api/contactSearch";
import { ContactData, ContactMethod } from "@/api/contacts";
import { ExteriorColor, Fuel, UsageType } from "@/api/inventory";
import { VehicleClass } from "@/api/makeModels";
import { Bank, OpportunityChannel, OpportunityInventoryVehicle, OpportunityUrgency } from "@/api/opportunities";
import { AcquisitionType } from "@/api/opportunityTeams";
import { ContactAndVehicleId } from "@/app/contactUtils";
import { EMPTY_SEARCH_REQUEST } from "@/app/pages/contacts/contactSearchUtils";
import {
    EMPTY_PREFERRED_CONTACT,
    getContactDetailsOptions,
    PreferredContact,
} from "@/app/pages/contacts/preferredContact";
import { UserPickerData } from "@/app/pages/users/userPickerCardTypes";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { reactive } from "@/util/reactive";
import { YearMonthDay } from "@/util/types";

export interface OpportunityAssistantState {
    preferredContact: PreferredContact;
    preferredContactTimeZone: string | null;
    files: File[];
    dealerId: string | null;
    channel: OpportunityChannel | null;
    sourceId: string | null;
    opportunityIndividualReceivers: UserPickerData;
    sentiment: number | null;
    appointmentTime: Date | null;
    subject: string;
    opportunityTeamReceivers: string[];
    inventoryVehicles: OpportunityInventoryVehicle[];
    initialNoteContent: string;
    registrationRequested: boolean | null;
    insuranceRequested: boolean | null;
    deliveryRequested: boolean | null;
    testdriveRequested: boolean | null;
    preferredTestdriveDateTime: string;
    counterOffer: number | null;
    dealName: string;
    acquisitionType: AcquisitionType | null;
    bank: Bank | null;
    paybackPeriodMonths: number | null;
    totalMileage: number | null;
    firstInstallment: number | null;
    monthlyInstallment: number | null;
    finalInstallment: number | null;
    internalId: string;
    link: string;
    vehicleClass: string | null;
    make: string | null;
    model: string | null;
    modelDescription: string;
    usageType: UsageType | null;
    mileage: number | null;
    price: number | null;
    firstRegistration: YearMonthDay;
    fuel: Fuel | null;
    power: number | null;
    exteriorColor: ExteriorColor | null;
    cubicCapacity: number | null;
    preOffer: boolean | null;
    tradeInRequested: boolean | null;
    contactVehicleLink: string;
    contactVehicleClass: VehicleClass | null;
    contactVehicleMake: string | null;
    contactVehicleModel: string | null;
    contactVehicleModelDescription: string;
    contactVehicleLicensePlate: string;
    contactVehicleVin: string;
    contactVehicleModelYear: string | null;
    contactVehicleMileage: number | null;
    contactVehicleFirstRegistration: YearMonthDay;
    contactVehiclePower: number | null;
    contactVehicleColor: string;
    contactVehicleTransmission: string;
    contactVehicleFeatures: string;
    contactVehiclePreviousOwners: number | null;
    contactVehicleLoanRedemption: boolean | null;
    contactVehiclePriceSuggestion: number | null;
    contactAndVehicleId: ContactAndVehicleId | null;
    contactSearchRequest: ContactSearchRequest;
    contactData: ContactData | null;
    urgency: OpportunityUrgency;

    reset(contactId: string | null): void;
}

@reactive
class OpportunityAssistantStateImpl implements OpportunityAssistantState {
    preferredContact: PreferredContact = {
        ...EMPTY_PREFERRED_CONTACT,
        preferredContactMethod: ContactMethod.PHONE,
    };
    preferredContactTimeZone: string | null = null;
    files: File[] = [];
    dealerId: string | null = null;
    channel: OpportunityChannel | null = null;
    sourceId: string | null = null;
    opportunityIndividualReceivers = new UserPickerData();
    sentiment: number | null = null;
    appointmentTime: Date | null = null;
    subject = "";
    opportunityTeamReceivers: string[] = [];
    inventoryVehicles: OpportunityInventoryVehicle[] = [];
    initialNoteContent = "";
    registrationRequested: boolean | null = null;
    insuranceRequested: boolean | null = null;
    deliveryRequested: boolean | null = null;
    testdriveRequested: boolean | null = null;
    preferredTestdriveDateTime = "";
    counterOffer: number | null = null;
    dealName = "";
    acquisitionType: AcquisitionType | null = null;
    bank: Bank | null = null;
    paybackPeriodMonths: number | null = null;
    totalMileage: number | null = null;
    firstInstallment: number | null = null;
    monthlyInstallment: number | null = null;
    finalInstallment: number | null = null;
    internalId = "";
    link = "";
    vehicleClass: string | null = null;
    make: string | null = null;
    model: string | null = null;
    modelDescription = "";
    usageType: UsageType | null = null;
    mileage: number | null = null;
    price: number | null = null;
    firstRegistration: YearMonthDay = { year: null, month: null, day: null };
    fuel: Fuel | null = null;
    power: number | null = null;
    exteriorColor: ExteriorColor | null = null;
    cubicCapacity: number | null = null;
    preOffer: boolean | null = null;
    tradeInRequested: boolean | null = null;
    contactVehicleLink = "";
    contactVehicleClass: VehicleClass | null = null;
    contactVehicleMake: string | null = null;
    contactVehicleModel: string | null = null;
    contactVehicleModelDescription = "";
    contactVehicleLicensePlate = "";
    contactVehicleVin = "";
    contactVehicleModelYear: string | null = null;
    contactVehicleMileage: number | null = null;
    contactVehicleFirstRegistration: YearMonthDay = { year: null, month: null, day: null };
    contactVehiclePower: number | null = null;
    contactVehicleColor = "";
    contactVehicleTransmission = "";
    contactVehicleFeatures = "";
    contactVehiclePreviousOwners: number | null = null;
    contactVehicleLoanRedemption: boolean | null = null;
    contactVehiclePriceSuggestion: number | null = null;
    contactAndVehicleId: ContactAndVehicleId | null = null;
    contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);

    private contactData_: ContactData | null = null;
    private urgency_: OpportunityUrgency = OpportunityUrgency.NORMAL;

    get contactData() {
        return this.contactData_;
    }

    set contactData(contactData: ContactData | null) {
        this.contactData_ = contactData;

        const contactDetailsOptions = getContactDetailsOptions(
            this.preferredContact.preferredContactMethod,
            contactData?.emailAddresses ?? [],
            contactData?.numbers ?? []
        );

        if (!contactDetailsOptions.some((o) => o.value === this.preferredContact.preferredContactDetails)) {
            this.preferredContact.preferredContactDetails = null;
        }
    }

    get urgency() {
        return this.urgency_;
    }

    set urgency(newValue) {
        this.urgency_ = newValue;
        if (newValue !== OpportunityUrgency.PERSONAL_APPEARANCE) {
            this.appointmentTime = null;
        }
    }

    reset(contactId: string | null) {
        this.contactData = contactId !== this.contactAndVehicleId?.contactId ? null : this.contactData;
        this.contactAndVehicleId = contactId ? { contactId, contactVehicleId: null } : null;
        this.contactSearchRequest = cloneObject(EMPTY_SEARCH_REQUEST);

        this.preferredContact = {
            ...EMPTY_PREFERRED_CONTACT,
            preferredContactMethod: ContactMethod.PHONE,
        };
        this.preferredContactTimeZone = userSession.dealerId
            ? dealersStore.dealerById(userSession.dealerId)?.timeZone ?? null
            : null;
        this.files = [];
        this.dealerId = userSession.dealerId;
        this.channel = userSession.isCtUser() ? OpportunityChannel.PHONE_BDC : OpportunityChannel.PHONE_INTERNAL;
        this.sourceId = null;
        this.opportunityIndividualReceivers = new UserPickerData();
        this.sentiment = null;
        this.urgency_ = OpportunityUrgency.NORMAL;
        this.appointmentTime = null;
        this.subject = "";
        this.opportunityTeamReceivers = [];
        this.inventoryVehicles = [];
        this.initialNoteContent = "";
        this.registrationRequested = null;
        this.insuranceRequested = null;
        this.deliveryRequested = null;
        this.testdriveRequested = null;
        this.preferredTestdriveDateTime = "";
        this.counterOffer = null;
        this.dealName = "";
        this.acquisitionType = null;
        this.bank = null;
        this.paybackPeriodMonths = null;
        this.totalMileage = null;
        this.firstInstallment = null;
        this.monthlyInstallment = null;
        this.finalInstallment = null;
        this.internalId = "";
        this.link = "";
        this.vehicleClass = null;
        this.make = null;
        this.model = null;
        this.modelDescription = "";
        this.usageType = null;
        this.mileage = null;
        this.price = null;
        this.firstRegistration = { year: null, month: null, day: null };
        this.fuel = null;
        this.power = null;
        this.exteriorColor = null;
        this.cubicCapacity = null;
        this.preOffer = null;
        this.tradeInRequested = null;
        this.contactVehicleLink = "";
        this.contactVehicleClass = null;
        this.contactVehicleMake = null;
        this.contactVehicleModel = null;
        this.contactVehicleModelDescription = "";
        this.contactVehicleLicensePlate = "";
        this.contactVehicleVin = "";
        this.contactVehicleModelYear = null;
        this.contactVehicleMileage = null;
        this.contactVehicleFirstRegistration = { year: null, month: null, day: null };
        this.contactVehiclePower = null;
        this.contactVehicleColor = "";
        this.contactVehicleTransmission = "";
        this.contactVehicleFeatures = "";
        this.contactVehiclePreviousOwners = null;
        this.contactVehicleLoanRedemption = null;
        this.contactVehiclePriceSuggestion = null;
    }
}

export const opportunityAssistantState = new OpportunityAssistantStateImpl();
