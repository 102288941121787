var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('crud-page',{attrs:{"icon":"mdi-eye-off","no-items-text":_vm.$t('Es wurden keine Ausnahmen für Lesebestätigungen gefunden.'),"add-dialog-title":_vm.$t('Benutzer hinzufügen'),"page-title":_vm.$t('Ausnahmen für Lesebestätigungen'),"global":true,"callbacks":{
        load: (_) => _vm.api.getAllHiddenUsers(),
        add: (_, form) => _vm.api.addHiddenUser(form.userId),
        delete: (userId) => _vm.api.deleteHiddenUser(userId),
        key: (item) => item.userId,
        emptyForm: () => _vm.emptyForm(),
    }},scopedSlots:_vm._u([{key:"title",fn:function({ item }){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.getFullName(_vm.getUserById(item))))])]}},{key:"dialog",fn:function({ form }){return [_c('v-autocomplete',{attrs:{"hide-details":"auto","items":_vm.allUsersOptions,"label":_vm.$t('Benutzer'),"id":_vm.$id(),"autofocus":"","clearable":"","autocomplete":"no","rules":_vm.notEmpty()},model:{value:(form.userId),callback:function ($$v) {_vm.$set(form, "userId", $$v)},expression:"form.userId"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }