
import { inventoryApi, InventoryVehicle } from "@/api/inventory";
import { InventoryVehicleInquiryMatch } from "@/api/inventoryVehicleInquiries";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { renderInventoryVehicleTitle } from "@/app/inventoryUtils";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        inventoryVehicleInquiryMatch: {
            type: Object as () => InventoryVehicleInquiryMatch,
            required: true,
        },
    },

    data() {
        return {
            inventoryVehicle: null as InventoryVehicle | null,
            loadingInventoryVehicle: true,
            renderInventoryVehicleTitle,
        };
    },

    computed: {
        creationTimestamp(): string {
            return formatInstant(
                this.inventoryVehicleInquiryMatch.created,
                userSession.timeZone,
                userSession.locale,
                "S"
            );
        },
    },

    async mounted() {
        try {
            this.inventoryVehicle = await inventoryApi.getById(this.inventoryVehicleInquiryMatch.inventoryVehicleId);
        } finally {
            this.loadingInventoryVehicle = false;
        }
    },

    components: {
        CopyToClipboardIcon,
        TimeSpan,
    },
});
