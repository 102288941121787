
import { CaseChannel, CaseUrgency } from "@/api/cases";
import DateTimePicker from "@/app/components/DateTimePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import ExpansionPanel from "@/app/components/ExpansionPanel.vue";
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            CaseChannel,
            CaseUrgency,
        };
    },

    computed: {
        timeZone(): string {
            return dealersStore.dealerById(this.caseAssistantState.dealerId!)!.timeZone;
        },
    },

    components: {
        DateTimePicker,
        EnumField,
        ExpansionPanel,
    },
});
