
import { emailSignatureTemplateApi } from "@/api/emailSignatureTemplate";
import { initializationApi } from "@/api/initialization";
import { smsSignatureTemplateApi } from "@/api/smsSignatureTemplate";
import { userBulkProcessingApi } from "@/api/userBulkProcessing";
import { Permission } from "@/api/userSession";
import { PhoneNumberType, usersApi, UserSummary } from "@/api/users";
import { fileDialog } from "@/app/fileUtils";
import { showConfirm } from "@/app/messageUtil";
import DealerContextWarning from "@/app/pages/DealerContextWarning.vue";
import { renderEmailSignatureTemplatePlaceholders } from "@/app/pages/emailsignaturetemplate/emailSignatureTemplatePlaceholders";
import { renderSmsSignatureTemplatePlaceholders } from "@/app/pages/smssignaturetemplate/smsSignatureTemplatePlaceholders";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { departmentsStore } from "@/store/departments";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            getFullName,
            importingUsers: false,
            importingWorkingHoursTemplates: false,
            importedUsers: [] as UserSummary[],
            resettingEmailSignatures: false,
            resettingSmsSignatures: false,
        };
    },

    computed: {
        canInitializeWorkingHoursTemplates(): boolean {
            return userSession.hasPermission(Permission.INITIALIZE_PARTITION);
        },

        dealerId(): string | null {
            return userSession.dealerId;
        },

        dealerUserIds(): string[] {
            return usersStore.users.filter((user) => user.mainDealerId === this.dealerId).map((u) => u.id);
        },

        working(): boolean {
            return (
                this.importingUsers ||
                this.importingWorkingHoursTemplates ||
                this.resettingEmailSignatures ||
                this.resettingSmsSignatures
            );
        },
    },

    methods: {
        getDealerNameById(dealerId: string): string {
            return dealersStore.dealerById(dealerId)?.name || this.$t("Unbekannter Standort").toString();
        },

        getDepartmentNameById(departmentId: string): string {
            return departmentsStore.departmentById(departmentId)?.name || (this.$t("Unbekannte Abteilung") as string);
        },

        getPhoneNumberTypeIcon(phoneNumberType: PhoneNumberType): string {
            if (phoneNumberType === PhoneNumberType.MOBILE) {
                return "mdi-cellphone";
            } else if (phoneNumberType === PhoneNumberType.LANDLINE) {
                return "mdi-deskphone";
            } else if (phoneNumberType === PhoneNumberType.FAX) {
                return "mdi-fax";
            }

            return "mdi-phone-classic";
        },

        async importUsers() {
            fileDialog(
                false,
                async (files) => {
                    this.importingUsers = true;
                    this.importedUsers = [];

                    try {
                        const userIds = await userBulkProcessingApi.upsertUsersByCsv(this.dealerId!, files[0]);

                        this.importedUsers = (await usersApi.getAll()).filter((user) => userIds.includes(user.id));
                    } finally {
                        this.importingUsers = false;
                    }
                },
                [".csv"]
            );
        },

        async initializeWorkingHourTemplates() {
            fileDialog(
                false,
                async (files) => {
                    this.importingWorkingHoursTemplates = true;

                    try {
                        await initializationApi.initializeWorkingHoursTemplates(this.dealerUserIds, files[0], () => {
                            // empty
                        });
                    } finally {
                        this.importingWorkingHoursTemplates = false;
                    }
                },
                [".xml"]
            );
        },

        renderLanguages(languages: string[]): string {
            if (!languages) {
                return "";
            }

            return languages
                .map((k) => this.$t(`language.${k}`) as string)
                .sort((a, b) => a.localeCompare(b, userSession.locale))
                .join(", ");
        },

        async resetEmailSignatures() {
            if (
                !(await showConfirm(
                    this.$t("E-Mail-Signaturen zurücksetzen?") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie die E-Mail-Signaturen auf die E-Mail-Signatur-Vorlage zurücksetzen möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            this.resettingEmailSignatures = true;

            try {
                const emailSignatureTemplate =
                    (await emailSignatureTemplateApi.getByDealer(this.dealerId!)).content || "";

                const users = await usersApi.getByMainDealer(this.dealerId!);

                for (const user of users) {
                    const emailSignature = await renderEmailSignatureTemplatePlaceholders(emailSignatureTemplate, user);

                    await usersApi.editEmailSignature(user.id, emailSignature);
                }
            } finally {
                this.resettingEmailSignatures = false;
            }
        },

        async resetSmsSignatures() {
            if (
                !(await showConfirm(
                    this.$t("SMS-Signaturen zurücksetzen?") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie die SMS-Signaturen auf die SMS-Signatur-Vorlage zurücksetzen möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            this.resettingSmsSignatures = true;

            const smsSignatureTemplate = (await smsSignatureTemplateApi.getByDealer(this.dealerId!)).content || "";

            try {
                const users = await usersApi.getByMainDealer(this.dealerId!);

                for (const user of users) {
                    const smsSignature = renderSmsSignatureTemplatePlaceholders(smsSignatureTemplate, user);

                    await usersApi.editSmsSignature(user.id, smsSignature);
                }
            } finally {
                this.resettingSmsSignatures = false;
            }
        },
    },

    watch: {
        dealerId() {
            this.importedUsers = [];
        },
    },

    components: {
        DealerContextWarning,
    },
});
