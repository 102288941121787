
import InventoryVehicleImageAndCarousel, { InventoryVehicleImage } from "./InventoryVehicleImageAndCarousel.vue";
import InventoryVehicleStatusFormDialog from "./InventoryVehicleStatusFormDialog.vue";
import {
    Feature,
    inventoryApi,
    InventoryVehicle,
    InventoryVehicleStatus,
    InventoryVehicleStatusType,
    Price,
} from "@/api/inventory";
import { InventoryVehicleOpportunityStatus, InventoryVehicleResult } from "@/api/inventorySearch";
import { isInventoryVehicleStatusNotification, Notification, notificationEventSource } from "@/api/notifications";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { renderUnit } from "@/app/filters";
import {
    getLastInventoryVehicleStatus,
    getStatusColor,
    renderFirstRegistration,
    renderInventoryVehicleLatestConsumerPrice,
    renderInventoryVehicleTitle,
    renderMileage,
    renderPower,
} from "@/app/inventoryUtils";
import { showConfirm } from "@/app/messageUtil";
import { renderExternalInventoryManagementVehicleLink } from "@/app/pages/externalinventorymanagementsettings/externalInventoryManagementPlaceholders";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import { dealersStore } from "@/store/dealers";
import { externalInventoryManagementSettingsStore } from "@/store/externalInventoryManagementSettings";
import { opportunitySettingsStore } from "@/store/opportunitySettings";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        inventoryVehicleResultProp: {
            type: Object as () => InventoryVehicleResult,
            required: true,
        },

        selected: {
            type: Boolean,
            default: false,
        },

        selectable: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            statusDialogVisible: false,
            inventoryVehicleResult: cloneObject(this.inventoryVehicleResultProp) as InventoryVehicleResult,
            notificationHandler: null as ((n: Notification) => void) | null,
            opportunityBottomSheetOpportunityIds: [] as string[],
            opportunityBottomSheetVisible: false,
            selectEventEmitted: false,
            renderFirstRegistration,
            renderMileage,
            renderInventoryVehicleLatestConsumerPrice,
            renderInventoryVehicleTitle,
            renderPower,
            renderUnit,
            getLastInventoryVehicleStatus,
        };
    },

    computed: {
        canViewAllOpenOpportunities(): boolean {
            return (
                userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES) ||
                (userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES) &&
                    !opportunitySettingsStore.hideOpportunityInfo)
            );
        },

        canViewOpportunities(): boolean {
            return (
                userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES) ||
                userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES)
            );
        },

        dealerName(): string {
            return dealersStore.dealers.find((d) => d.id === this.inventoryVehicle.dealerId)!.name;
        },

        inventoryVehicle(): InventoryVehicle {
            return this.inventoryVehicleResultProp.inventoryVehicle;
        },

        inventoryVehiclesImages(): InventoryVehicleImage[] {
            return this.inventoryVehicle.data.images.map((image) => ({
                inventoryVehicleId: this.inventoryVehicle.id,
                image,
            }));
        },

        opportunityStatus(): InventoryVehicleOpportunityStatus {
            return this.inventoryVehicleResultProp.inventoryVehicleOpportunityStatus;
        },

        latestPrice(): null | Price {
            return !this.inventoryVehicle.data.prices.length
                ? null
                : this.inventoryVehicle.data.prices[this.inventoryVehicle.data.prices.length - 1];
        },

        latestStatus(): null | InventoryVehicleStatus {
            if (!this.inventoryVehicle.statuses.length) {
                return null;
            }
            return this.inventoryVehicle.statuses[this.inventoryVehicle.statuses.length - 1];
        },

        currentStatusType(): InventoryVehicleStatusType {
            if (!this.inventoryVehicle.statuses.length) {
                return InventoryVehicleStatusType.AVAILABLE;
            }
            return this.inventoryVehicle.statuses[this.inventoryVehicle.statuses.length - 1].type;
        },

        features(): string[] {
            const tags: string[] = [];

            for (const feature of Object.keys(this.inventoryVehicle.data.features) as Feature[]) {
                if (this.inventoryVehicle.data.features[feature]) {
                    tags.push(this.$t(`enum.Feature.${feature}`) as string);
                }
            }

            return tags
                .filter((feature, index, array) => array.indexOf(feature) === index)
                .sort((a, b) => a.localeCompare(b, userSession.locale));
        },

        exposeLink(): string {
            return inventoryApi.generateVehicleExposeLink(this.inventoryVehicle.id);
        },

        isSelected(): boolean {
            return this.selected || this.selectEventEmitted;
        },

        externalInventoryManagementVehicleLink(): string | null {
            return renderExternalInventoryManagementVehicleLink(
                externalInventoryManagementSettingsStore.settings.vehicleLinkPattern,
                this.inventoryVehicle.data
            );
        },

        externalInventoryManagementVehicleTooltip(): string | null {
            return externalInventoryManagementSettingsStore.settings.vehicleLinkLabel;
        },
    },

    methods: {
        hideOpportunityBottomSheet() {
            this.opportunityBottomSheetVisible = false;
            this.opportunityBottomSheetOpportunityIds = [];
        },

        selectInventoryVehicle(event: PointerEvent) {
            if (!this.selectable || this.isSelected) {
                return;
            }

            const row = this.$refs.row as EventTarget;
            const path = event.composedPath();
            const targets = path.slice(0, path.indexOf(row));

            for (const target of targets) {
                const classList = (target as Element).classList;
                for (let i = 0; i < classList.length; i++) {
                    const c = classList.item(i)!;

                    // ignore event if a vuetify component was clicked (but not a lazy wrapper)
                    if (c.startsWith("v-") && c !== "v-lazy") {
                        return;
                    }
                }
            }

            this.selectEventEmitted = true;
            this.$emit("select", this.inventoryVehicle);
        },

        showOpportunityBottomSheet(opportunityIds: string[]) {
            this.opportunityBottomSheetOpportunityIds = opportunityIds;
            this.opportunityBottomSheetVisible = true;
        },

        showStatusDialog() {
            this.statusDialogVisible = true;
        },

        getStatusColor(status: InventoryVehicleStatusType): string | null {
            return getStatusColor(status);
        },

        showAsPdf() {
            window.open(this.exposeLink);
        },

        showInExternalInventoryManagement() {
            window.open(this.externalInventoryManagementVehicleLink!);
        },

        async createInventoryInquiry(inventoryVehicle: InventoryVehicle) {
            if (
                await showConfirm(
                    this.$t("Suchauftrag erstellen") as string,
                    this.$t(
                        "Alle Daten, die aktuell im Suchauftrag-Assistenten gespeichert sind, gehen verloren. Möchten Sie fortfahren?"
                    ) as string
                )
            ) {
                await this.$router.push("/inventory-vehicle-inquiry-assistant?iv=" + inventoryVehicle.id);
            }
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isInventoryVehicleStatusNotification(n)) {
                if (n.inventoryVehicleId === this.inventoryVehicle.id) {
                    this.inventoryVehicle.statuses.push(n.inventoryVehicleStatus);
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        CopyToClipboardIcon,
        InventoryVehicleImageAndCarousel,
        InventoryVehicleStatusFormDialog,
        OpportunitiesBottomSheet,
    },
});
