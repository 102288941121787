
import { OpportunityInventoryVehicleWithInventoryVehicle } from "./opportunityUtils";
import { Contact } from "@/api/contacts";
import { InventoryVehicle, InventoryVehicleStatusForm, InventoryVehicleStatusType } from "@/api/inventory";
import { NoteTemplate, noteTemplatesApi } from "@/api/noteTemplates";
import {
    OpportunityCloseForm,
    OpportunityInventoryVehicle,
    OpportunityInventoryVehicleStatusForm,
    OpportunityOutcomeForm,
    OpportunityStatus,
} from "@/api/opportunities";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import EnumField from "@/app/components/EnumField.vue";
import { ContactNameData, EMPTY_CONTACT_NAME_DATA } from "@/app/contactUtils";
import { renderUnit } from "@/app/filters";
import {
    getLastInventoryVehicleStatus,
    getStatusColor,
    renderFirstRegistration,
    renderInventoryVehicleLatestConsumerPrice,
    renderInventoryVehicleTitle,
    renderMileage,
    renderPower,
} from "@/app/inventoryUtils";
import InventoryVehicleImageAndCarousel, {
    InventoryVehicleImage,
} from "@/app/pages/inventory/InventoryVehicleImageAndCarousel.vue";
import { renderNotePlaceholders } from "@/app/pages/notetemplates/notePlaceholders";
import { maxLength, notEmpty } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import { opportunityOutcomeReasonsStore } from "@/store/opportunityOutcomeReasons";
import { opportunitySettingsStore } from "@/store/opportunitySettings";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

interface OpportunityInventoryVehicleWithStatusForm {
    readonly opportunityInventoryVehicle: OpportunityInventoryVehicle;
    readonly inventoryVehicle: InventoryVehicle | null;
    readonly inventoryVehicleStatusForm: InventoryVehicleStatusForm;
}

export default Vue.extend({
    props: {
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        dealerId: {
            type: String,
            required: false,
        },
        opportunityInventoryVehiclesWithInventoryVehicleProp: {
            type: Array as () => OpportunityInventoryVehicleWithInventoryVehicle[],
            required: true,
        },
        opportunityStatus: {
            type: String as () => OpportunityStatus,
            required: true,
        },
        outcomeDetailsProp: {
            type: String,
            required: false,
        },
        outcomeProp: {
            type: Boolean,
            required: false,
        },
        outcomeReasonIdProp: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            commentRules: maxLength(8000),
            loadingTemplates: true,
            noteTemplates: [] as NoteTemplate[],
            opportunityInventoryVehicleWithStatusForm: cloneObject(
                this.opportunityInventoryVehiclesWithInventoryVehicleProp
            ).map((i) => ({
                ...i,
                inventoryVehicleStatusForm: {
                    type: i.inventoryVehicle
                        ? getLastInventoryVehicleStatus(i.inventoryVehicle)
                        : InventoryVehicleStatusType.AVAILABLE,
                    comment: null,
                } as InventoryVehicleStatusForm,
            })) as OpportunityInventoryVehicleWithStatusForm[],
            outcome: this.outcomeProp as boolean | null,
            outcomeDetails: this.outcomeDetailsProp as string | null,
            outcomeReasonId: this.outcomeReasonIdProp as string | null,
            maxLength,
            notEmpty,
            renderFirstRegistration,
            renderMileage,
            renderInventoryVehicleLatestConsumerPrice,
            renderInventoryVehicleTitle,
            renderPower,
            renderUnit,
            OpportunityStatus,
            InventoryVehicleStatusType,
            getLastInventoryVehicleStatus,
        };
    },

    computed: {
        isOutcomeReasonMandatory(): boolean {
            return this.dealerId
                ? opportunitySettingsStore.outcomeReasonMandatory(this.dealerId)
                : !!this.outcomeReasonOptions.length;
        },

        outcomeSelected(): boolean {
            return this.outcome !== null && this.outcome !== undefined;
        },

        outcomeReasonOptions(): SelectOptions {
            return opportunityOutcomeReasonsStore.opportunityOutcomeReasons
                .filter((r) => r.won === this.outcome)
                .map((r) => ({ value: r.id, text: r.name }));
        },

        outcomeReasonRules(): any {
            return this.isOutcomeReasonMandatory ? notEmpty() : [];
        },
    },

    methods: {
        applyNoteTemplate(template: NoteTemplate) {
            this.outcomeDetails = this.renderNoteTemplate(template);
        },

        applyNoteTemplateAndClose(template: NoteTemplate) {
            this.applyNoteTemplate(template);
            this.submitForm();
        },

        close() {
            this.$emit("close");
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },

        getInventoryVehicleImages(inventoryVehicle: InventoryVehicle): InventoryVehicleImage[] {
            return inventoryVehicle.data.images.map((image) => ({ inventoryVehicleId: inventoryVehicle.id, image }));
        },

        getStatusColor(status: InventoryVehicleStatusType): string | null {
            return getStatusColor(status);
        },

        renderNoteTemplate(template: NoteTemplate): string {
            const contactNameData: ContactNameData = this.contact?.deleted
                ? EMPTY_CONTACT_NAME_DATA
                : {
                      salutation: this.contact?.contactData.salutation || null,
                      companyName: this.contact?.contactData.companyName || null,
                      namePrefix: this.contact?.contactData.namePrefix || null,
                      givenName: this.contact?.contactData.givenName || null,
                      middleName: this.contact?.contactData.middleName || null,
                      familyName: this.contact?.contactData.familyName || null,
                      nameSuffix: this.contact?.contactData.nameSuffix || null,
                  };

            return renderNotePlaceholders(template.content, contactNameData, userSession.profile!);
        },

        submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            const outcomeForm: OpportunityOutcomeForm = {
                outcome: this.outcome!,
                outcomeReasonId: this.outcomeReasonId,
                outcomeDetails: this.outcomeDetails,
            };

            const statusForms: OpportunityInventoryVehicleStatusForm[] = this.opportunityInventoryVehicleWithStatusForm
                .filter((i) => i.inventoryVehicle)
                .filter((i) => i.inventoryVehicleStatusForm.type !== getLastInventoryVehicleStatus(i.inventoryVehicle!))
                .map((i) => ({
                    id: i.opportunityInventoryVehicle.inventoryVehicleId,
                    form: i.inventoryVehicleStatusForm,
                }));

            const form: OpportunityCloseForm = { outcomeForm, statusForms };

            this.$emit("close-opportunity", form);
        },
    },

    async mounted() {
        this.loadingTemplates = true;

        try {
            this.noteTemplates = await noteTemplatesApi.list();
        } finally {
            this.loadingTemplates = false;
        }
    },

    components: {
        CopyToClipboardIcon,
        EnumField,
        InventoryVehicleImageAndCarousel,
    },
});
