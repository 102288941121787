
import OpportunityContactFormCard from "./OpportunityContactFormCard.vue";
import { Contact } from "@/api/contacts";
import ContactCard from "@/app/pages/contacts/ContactCard.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        canSendEmail: {
            type: Boolean,
            default: false,
        },
        canSendSms: {
            type: Boolean,
            default: false,
        },
        canStartCall: {
            type: Boolean,
            default: false,
        },
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        opportunityId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            editMode: false,
        };
    },

    computed: {
        contactId(): string | null {
            if (!this.contact || this.contact.deleted) {
                return null;
            }

            return this.contact.id;
        },
    },

    components: {
        ContactCard,
        OpportunityContactFormCard,
    },
});
