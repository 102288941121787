import axios from "axios";

export interface MakeModelSettings {
    preferredMakes: string[];
}

interface MakeModelSettingsApi {
    getMakeModelSettings(): Promise<MakeModelSettings>;
    editMakeModelSettings(makeModelSettings: MakeModelSettings): Promise<void>;
}

export const makeModelSettingsApi: MakeModelSettingsApi = {
    async getMakeModelSettings() {
        return (await axios.get("/api/make-model-settings")).data;
    },

    async editMakeModelSettings(makeModelSettings) {
        await axios.put("/api/make-model-settings", makeModelSettings);
    },
};
