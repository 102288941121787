import { Case, CaseChannel, CaseResult, CaseStatus, CaseUrgency } from "@/api/cases";
import { BadRequest } from "@/api/errors";
import { Permission } from "@/api/userSession";
import { getContactVehicleIcon, renderContactCaption, renderVehicleTitle } from "@/app/contactUtils";
import { $t } from "@/app/i18n";
import { getFullName, getSortedByUserFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";

export function getCaseChannelColor(caseChannel: CaseChannel): string | null {
    if (caseChannel === CaseChannel.WHATSAPP) {
        return "green";
    } else {
        return null;
    }
}

export function getCaseChannelIcon(caseChannel: CaseChannel): string | null {
    if (caseChannel === CaseChannel.WHATSAPP) {
        return "mdi-whatsapp";
    } else {
        return null;
    }
}

export function getCaseStatus(caseObj: Case): CaseStatus {
    if (caseObj.closed) {
        return CaseStatus.CLOSED;
    } else if (caseObj.postponedUntil) {
        return CaseStatus.POSTPONED;
    } else if (caseObj.assigneeIds.length) {
        return CaseStatus.ASSIGNED;
    } else {
        return CaseStatus.OPEN;
    }
}

function getVisibleFor(caseObj: Case) {
    const userIds = cloneObject(caseObj.assigneeIds);

    const closed = !!caseObj.closed;
    for (const caseUser of caseObj.users) {
        if ((!closed && !!caseUser.visibilityBegin && !caseUser.visibilityEnd) || caseUser.creator || caseUser.closer) {
            if (!userIds.find((u) => u === caseUser.userId)) {
                userIds.push(caseUser.userId);
            }
        }
    }

    return userIds;
}

export function isVisibleFor(caseObject: Case, userId: string) {
    return getVisibleFor(caseObject).indexOf(userId) >= 0;
}

export function getContactDeleted(caseResult: CaseResult | null) {
    if (!caseResult) {
        return false;
    }

    return !!caseResult.contact?.deleted;
}

export function getContactCaption(caseResult: CaseResult | null) {
    if (!caseResult) {
        return null;
    }

    if (caseResult.contact) {
        return renderContactCaption(caseResult.contact.contactData) as string;
    }

    return null;
}

export function getVehicleDeleted(caseResult: CaseResult | null) {
    if (!caseResult) {
        return false;
    }

    return !!caseResult.contactVehicle?.deleted || getContactDeleted(caseResult);
}

export function getVehicleCaption(caseResult: CaseResult | null) {
    if (!caseResult) {
        return null;
    }

    if (caseResult.contactVehicle) {
        return renderVehicleTitle(caseResult.contactVehicle.contactVehicleData, true);
    }

    return null;
}

export function getVehicleIcon(caseResult: CaseResult | null): string {
    return getContactVehicleIcon(caseResult?.contactVehicle?.contactVehicleData?.vehicleClass ?? null);
}

export function getVehicleTooltip(caseResult: CaseResult | null) {
    if (getVehicleDeleted(caseResult) || !caseResult) {
        return $t("Fahrzeug") as string;
    }
    if (caseResult.contactVehicle!.contactVehicleData.vehicleClass) {
        return $t(`enum.VehicleClass.${caseResult.contactVehicle!.contactVehicleData.vehicleClass}`) as string;
    } else {
        return $t("Fahrzeug") as string;
    }
}

export function getSelectedReceiversCount(c: Case | null) {
    if (!c) {
        return 0;
    }

    return c.individualReceivers.length + c.escalationGroupReceivers.length;
}

export function getCaseStatusColor(caseStatus: CaseStatus): string | null {
    if (caseStatus === CaseStatus.CLOSED) {
        return null;
    } else if (caseStatus === CaseStatus.POSTPONED) {
        return "info";
    } else if (caseStatus === CaseStatus.ASSIGNED) {
        return "warning";
    } else {
        return "error";
    }
}

export function getStatusColor(c: Case | null): string | null {
    return getCaseStatusColor(c ? getCaseStatus(c) : CaseStatus.OPEN);
}

export function getStatusCaption(c: Case | null) {
    if (!c) {
        return $t("unbekannt") as string;
    }
    if (getCaseStatus(c) === CaseStatus.CLOSED) {
        return $t("geschlossen") as string;
    }
    if (getCaseStatus(c) === CaseStatus.POSTPONED) {
        return $t("zurückgestellt") as string;
    }
    if (getCaseStatus(c) === CaseStatus.ASSIGNED) {
        return $t("zugeteilt") as string;
    }
    return $t("offen") as string;
}

export function isCaseActive(c: Case | null) {
    if (!c) {
        return false;
    }

    const caseStatus = getCaseStatus(c);
    return caseStatus !== CaseStatus.CLOSED && caseStatus !== CaseStatus.POSTPONED;
}

export function getCreator(c: Case | null) {
    if (!c) {
        return "";
    }
    if (c.creatorUserId) {
        const user = usersStore.getUserById(c.creatorUserId);
        if (!user) {
            return $t("Gelöschter Benutzer") as string;
        }
        return getFullName(user);
    }
    if (c.creatorCtUserId) {
        return $t("Externer Agent") as string;
    }
    return $t("System") as string;
}

export function getCloser(c: Case | null) {
    if (!c) {
        return "";
    }
    if (c.closerUserId) {
        const user = usersStore.getUserById(c.closerUserId);
        if (!user) {
            return $t("Gelöschter Benutzer") as string;
        }
        return getFullName(user);
    }
    if (c.closerCtUserId) {
        return $t("Externer Agent") as string;
    }
    return $t("System") as string;
}

export function getAssigneeIdsSortedByUserFullName(c: Case | null): string[] {
    if (!c) {
        return [];
    }

    return getSortedByUserFullName(c.assigneeIds, (assigneeId) => assigneeId);
}

export function getUrgencyColor(c: Case | null): string | null {
    if (!c) {
        return null;
    }

    if (c.urgency === CaseUrgency.HIGH) {
        return "error";
    } else if (c.urgency === CaseUrgency.PERSONAL_APPEARANCE) {
        return "black";
    } else {
        return null;
    }
}

export function getUrgencyTextColor(c: Case | null): string | null {
    if (!c) {
        return null;
    }

    if (c.urgency === CaseUrgency.PERSONAL_APPEARANCE) {
        return "white";
    } else {
        return null;
    }
}

export function canManageCase(caseObj: Case): boolean {
    if (userSession.hasPermission(Permission.MANAGE_ALL_CASES)) {
        return true;
    }

    return userSession.hasPermission(Permission.MANAGE_OWN_CASES) && isVisibleFor(caseObj, userSession.userId!);
}

export function canAddNote(caseObj: Case): boolean {
    const caseStatus = getCaseStatus(caseObj);

    return canManageCase(caseObj) || caseStatus !== CaseStatus.CLOSED;
}

export function canSendEmail(caseObj: Case): boolean {
    return canManageCase(caseObj);
}

export function canSendSms(caseObj: Case): boolean {
    return canManageCase(caseObj);
}

export function canSendWhatsapp(caseObj: Case): boolean {
    return canManageCase(caseObj);
}

export function canStartCall(caseObj: Case): boolean {
    return canManageCase(caseObj);
}

export function isInvalidCaseStatusExceptionWithCaseStatus(e: BadRequest, caseStatus: CaseStatus): boolean {
    if (!e.details.length) {
        return false;
    }

    const badRequestDetails = e.details[0];

    return (
        badRequestDetails.messageKey === "validation.invalid-case-status" &&
        badRequestDetails.path === "caseStatus" &&
        badRequestDetails.rejectedValue === caseStatus
    );
}
