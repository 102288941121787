
import { ContactType } from "@/api/contacts";
import { EscalationGroupSummary, EscalationGroupVisibility } from "@/api/escalationGroups";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import DealerLink from "@/app/pages/DealerLink.vue";
import EscalationGroupLink from "@/app/pages/EscalationGroupLink.vue";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

function containsOrEmptyOrFalsy<T>(l: T[], e: T | null) {
    return !l.length || !e || l.indexOf(e) >= 0;
}

export default Vue.extend({
    props: {
        caseType: {
            type: String as () => string | null,
            required: false,
        },
        contactType: {
            type: String as () => ContactType | null,
            required: false,
        },
        dealerId: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Array as () => string[],
            required: true,
        },
        vehicleClass: {
            type: String as () => string | null,
            required: false,
        },
        vehicleMake: {
            type: String as () => string | null,
            required: false,
        },
    },

    data() {
        return {
            valueProp: cloneObject(this.value),
            escalationGroups: [] as EscalationGroupSummary[],
        };
    },

    computed: {
        visibleEscalationGroups(): EscalationGroupSummary[] {
            return escalationGroupsStore.escalationGroups
                .filter((e) => e.visibleForAgents || !userSession.isCtUser())
                .filter(
                    (e) =>
                        (e.visibility === EscalationGroupVisibility.DEALER && e.dealerId === this.dealerId) ||
                        (e.visibility === EscalationGroupVisibility.SELECTED_DEALERS &&
                            e.visibleForDealerIds.includes(this.dealerId)) ||
                        e.visibility === EscalationGroupVisibility.DEALERGROUP
                );
        },

        unselectedEscalationGroups(): EscalationGroupSummary[] {
            return this.visibleEscalationGroups.filter((e) => {
                return this.valueProp.indexOf(e.id) < 0;
            });
        },

        unselectedGlobalEscalationGroups(): EscalationGroupSummary[] {
            return this.unselectedEscalationGroups.filter((e) => e.dealerId !== this.dealerId);
        },

        unselectedLocalEscalationGroups(): EscalationGroupSummary[] {
            return this.unselectedEscalationGroups.filter((e) => e.dealerId === this.dealerId);
        },
    },

    methods: {
        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },

        addEscalationGroup(escalationGroupId: string) {
            this.valueProp.push(escalationGroupId);
            this.$emit("input", this.valueProp);
        },

        deleteEscalationGroup(escalationGroupId: string) {
            for (let i = 0; i < this.valueProp.length; i++) {
                if (this.valueProp[i] === escalationGroupId) {
                    this.valueProp.splice(i, 1);
                    this.$emit("input", this.valueProp);
                    return;
                }
            }
        },

        getEscalationGroupById(escalationGroupId: string) {
            return escalationGroupsStore.escalationGroupById(escalationGroupId)!;
        },

        isHighlighted(escalationGroupId: string) {
            const escalationGroup = this.getEscalationGroupById(escalationGroupId);
            return (
                !!this.caseType &&
                escalationGroup.caseTypeSuggestions.indexOf(this.caseType) >= 0 &&
                containsOrEmptyOrFalsy(escalationGroup.contactTypes, this.contactType) &&
                containsOrEmptyOrFalsy(escalationGroup.makes, this.vehicleMake) &&
                containsOrEmptyOrFalsy(escalationGroup.vehicleClasses, this.vehicleClass)
            );
        },
    },

    watch: {
        value() {
            this.valueProp = cloneObject(this.value);
        },
    },

    components: {
        DealerLink,
        DToolbarCard,
        EscalationGroupLink,
    },
});
