import { EmergencyIncidentResult, EmergencyIncidentType } from "./emergencyIncidents";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum ActorType {
    USER = "USER",
    CT_USER = "CT_USER",
    SYSTEM = "SYSTEM",
}

export enum EmergencyIncidentCountType {
    DEALER_ID = "DEALER_ID",
    TYPE = "TYPE",
    CREATOR_ACTOR_TYPE = "CREATOR_ACTOR_TYPE",
    CREATED_BY = "CREATED_BY",
    CLOSER_ACTOR_TYPE = "CLOSER_ACTOR_TYPE",
    CLOSED_BY = "CLOSED_BY",
    OPEN = "OPEN",
}

export enum EmergencyIncidentSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    LAST_ACTIVITY_DESC = "LAST_ACTIVITY_DESC",
    LAST_ACTIVITY_ASC = "LAST_ACTIVITY_ASC",
    CLOSED_DESC = "CLOSED_DESC",
    CLOSED_ASC = "CLOSED_ASC",
}

export interface EmergencyIncidentCount {
    readonly type: EmergencyIncidentCountType;
    readonly value: string | null;
    readonly count: number;
}

export interface EmergencyIncidentSearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

export interface EmergencyIncidentSearchRequest {
    readonly emergencyIncidentIds: string[];
    readonly excludedEmergencyIncidentIds: string[];
    readonly dealerIds: string[];
    readonly types: EmergencyIncidentType[];
    readonly creatorActorType: ActorType[];
    readonly createdBy: string[];
    readonly closerActorType: ActorType[];
    readonly closedBy: string[];
    readonly open: boolean | null;
    readonly contactId: string | null;
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly search: string | null;
    readonly sortBy: EmergencyIncidentSearchOrder;
}

export interface EmergencyIncidentSearchResults {
    readonly totalSize: number;
    readonly results: EmergencyIncidentResult[];
    readonly searchId: number;
}

interface EmergencyIncidentSearchApi {
    getCounts(
        searchRequest: EmergencyIncidentSearchRequest,
        emergencyIncidentCountTypes: EmergencyIncidentCountType[]
    ): Promise<EmergencyIncidentCount[]>;
    search(
        start: number,
        size: number,
        searchRequest: EmergencyIncidentSearchRequest,
        searchId: number
    ): Promise<EmergencyIncidentSearchResults>;
    rebuild(): Promise<void>;
    size(): Promise<EmergencyIncidentSearchIndexSize>;
}

export const emergencyIncidentSearchApi: EmergencyIncidentSearchApi = {
    async getCounts(searchRequest, emergencyIncidentCountTypes) {
        return (
            await axios.post("/api/emergency-incident-search/_counts", searchRequest, {
                params: {
                    emergencyIncidentCountType: emergencyIncidentCountTypes.join(","),
                },
            })
        ).data;
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/emergency-incident-search", searchRequest, {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },

    async rebuild() {
        await axios.post("/api/emergency-incident-search/_rebuild");
    },

    async size() {
        return (await axios.get("/api/emergency-incident-search/_size")).data;
    },
};
