
import { caseSettingsApi, CaseSettingsForm } from "@/api/caseSettings";
import { BadRequest } from "@/api/errors";
import { Permission } from "@/api/userSession";
import DealerContextGuard from "@/app/pages/DealerContextGuard.vue";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            Permission,
            saving: false,
            settingsForm: null as CaseSettingsForm | null,
        };
    },

    computed: {
        dealerId() {
            return userSession.dealerId;
        },

        outcomeReasonMandatoryOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Verpflichtend") },
                { value: false, text: this.$t("Optional") },
            ];
        },
    },

    methods: {
        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },

        async refresh() {
            if (!this.dealerId) {
                return;
            }

            this.loading = true;
            this.settingsForm = null;

            try {
                const settings = await caseSettingsApi.getCaseSettings(this.dealerId);

                this.settingsForm = {
                    outcomeReasonMandatory: settings.outcomeReasonMandatory,
                };
            } finally {
                this.loading = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate() || !this.settingsForm) {
                return;
            }

            this.saving = true;

            try {
                await caseSettingsApi.editCaseSettings(this.dealerId!, this.settingsForm);
            } catch (e) {
                this.saving = false;
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                return;
            }

            this.saving = false;
        },
    },

    watch: {
        async dealerId() {
            try {
                await this.refresh();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.refresh();
    },

    components: {
        DealerContextGuard,
    },
});
