
import { dealerOptions } from "@/app/dealerUtils";
import { CaseAssistantState } from "@/app/pages/cases/assistant/caseAssistant";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseAssistantState: {
            type: Object as () => CaseAssistantState,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        dealerOptions() {
            return dealerOptions();
        },
    },
});
