
import { Opportunity, OpportunityResult, OpportunityUser } from "@/api/opportunities";
import DLink from "@/app/components/DLink.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import UserLink from "@/app/pages/UserLink.vue";
import {
    getBankColor,
    getBankTextColor,
    getCloser,
    getCreator,
    getOpportunityChannelColor,
    getOpportunityChannelIcon,
} from "@/app/pages/opportunities/opportunityUtils";
import { getSentimentIcon, getSentimentIconColor, getSentimentText } from "@/app/sentimentUtils";
import { dealersStore } from "@/store/dealers";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        compact: {
            type: Boolean,
            required: true,
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            required: true,
        },
        opportunityResult: {
            type: Object as () => OpportunityResult,
            required: true,
        },
        opportunityUser: {
            type: Object as () => OpportunityUser,
            required: false,
        },
        showSubject: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            allVehiclesVisible: false,
            getBankColor,
            getBankTextColor,
        };
    },

    computed: {
        channelColor(): string | null {
            return getOpportunityChannelColor(this.opportunityObj.channel);
        },

        channelIcon(): string | null {
            return getOpportunityChannelIcon(this.opportunityObj.channel);
        },

        closedTimestamp(): string | null {
            if (!this.opportunityObj.closed) {
                return null;
            }

            return formatInstant(this.opportunityObj.closed, userSession.timeZone, userSession.locale, "S");
        },

        closer(): string {
            return getCloser(this.opportunityObj);
        },

        creationTimestamp(): string {
            return formatInstant(this.opportunityObj.created, userSession.timeZone, userSession.locale, "S");
        },

        creator(): string {
            return getCreator(this.opportunityObj);
        },

        dealerName(): string | null {
            return dealersStore.dealerById(this.opportunityObj.dealerId)?.name || null;
        },

        lastActivityWithTypeTimestamp(): string | null {
            if (!this.opportunityObj.lastActivityWithType) {
                return null;
            }

            return formatInstant(
                this.opportunityObj.lastActivityWithType,
                userSession.timeZone,
                userSession.locale,
                "S"
            );
        },

        opportunityObj(): Opportunity {
            return this.opportunityResult.opportunity;
        },

        postponedUntilTimestamp(): string | null {
            if (!this.opportunityObj?.postponedUntil) {
                return null;
            }

            return formatInstant(this.opportunityObj.postponedUntil, userSession.timeZone, userSession.locale, "S");
        },

        sentimentIcon(): string {
            return getSentimentIcon(this.opportunityObj.sentiment);
        },

        sentimentIconColor(): string | null {
            return getSentimentIconColor(this.opportunityObj.sentiment);
        },

        sentimentText(): string | null {
            return getSentimentText(this.opportunityObj.sentiment);
        },

        sourceName(): string {
            return (
                opportunitySourcesStore.getOpportunitySourceById(this.opportunityObj.sourceId)?.name ||
                (this.$t("Unbekannte Quelle") as string)
            );
        },

        visibilityBegin(): Date | null {
            return this.opportunityUser?.visibilityBegin ?? null;
        },

        visibilityBeginTimestamp(): string | null {
            if (!this.visibilityBegin) {
                return null;
            }

            return formatInstant(this.visibilityBegin, userSession.timeZone, userSession.locale, "S");
        },

        visibilityEnd(): Date | null {
            return this.opportunityUser?.visibilityEnd ?? null;
        },
    },

    components: {
        DLink,
        IconWithTooltip,
        TimeSpan,
        UserLink,
    },
});
