
import { opportunityTeamFilterMatches } from "./opportunityTeamFilter";
import { ContactType } from "@/api/contacts";
import { AcquisitionType, OpportunityTeam, OpportunityTeamVisibility } from "@/api/opportunityTeams";
import { VehicleSummary } from "@/app/inventoryUtils";
import DealerLink from "@/app/pages/DealerLink.vue";
import OpportunityTeamLink from "@/app/pages/OpportunityTeamLink.vue";
import { dealersStore } from "@/store/dealers";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        acquisitionType: {
            type: String as () => AcquisitionType | null,
            default: null,
        },
        contactType: {
            type: String as () => ContactType | null,
            default: null,
        },
        dealerId: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        opportunitySourceId: {
            type: String as () => string | null,
            default: null,
        },
        tradeInRequested: {
            type: Boolean as () => boolean | null,
            default: null,
        },
        value: {
            type: Array as () => string[],
            required: true,
        },
        vehicleSummaries: {
            type: Array as () => VehicleSummary[],
            default: () => [],
        },
    },

    data() {
        return {
            valueProp: cloneObject(this.value),
            opportunityTeams: [] as OpportunityTeam[],
        };
    },

    computed: {
        hasTeamWithMatchingFilter(): boolean {
            return !!this.visibleOpportunityTeams.find((t) => this.hasMatchingFilter(t));
        },

        hasPrioritizedTeamWithMatchingFilter(): boolean {
            return !!this.visibleOpportunityTeams.find((t) => t.prioritized && this.hasMatchingFilter(t));
        },

        visibleOpportunityTeams(): OpportunityTeam[] {
            return opportunityTeamsStore.opportunityTeams
                .filter((t) => t.visibleForAgents || !userSession.isCtUser())
                .filter(
                    (t) =>
                        (t.visibility === OpportunityTeamVisibility.DEALER && t.dealerId === this.dealerId) ||
                        (t.visibility === OpportunityTeamVisibility.SELECTED_DEALERS &&
                            t.visibleForDealerIds.includes(this.dealerId)) ||
                        t.visibility === OpportunityTeamVisibility.DEALERGROUP
                );
        },

        unselectedGlobalOpportunityTeams(): OpportunityTeam[] {
            return this.unselectedOpportunityTeams.filter((t) => t.dealerId !== this.dealerId);
        },

        unselectedLocalOpportunityTeams(): OpportunityTeam[] {
            return this.unselectedOpportunityTeams.filter((t) => t.dealerId === this.dealerId);
        },

        unselectedOpportunityTeams(): OpportunityTeam[] {
            return this.visibleOpportunityTeams.filter((e) => {
                return this.valueProp.indexOf(e.id) < 0;
            });
        },
    },

    methods: {
        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name;
        },

        addOpportunityTeam(opportunityTeamId: string) {
            this.valueProp.push(opportunityTeamId);
            this.$emit("input", this.valueProp);
        },

        deleteOpportunityTeam(opportunityTeamId: string) {
            for (let i = 0; i < this.valueProp.length; i++) {
                if (this.valueProp[i] === opportunityTeamId) {
                    this.valueProp.splice(i, 1);
                    this.$emit("input", this.valueProp);
                    return;
                }
            }
        },

        getOpportunityTeamById(opportunityTeamId: string) {
            return opportunityTeamsStore.getOpportunityTeamById(opportunityTeamId)!;
        },

        hasMatchingFilter(opportunityTeam: OpportunityTeam): boolean {
            if (!this.vehicleSummaries.length || !opportunityTeam.filters.length) {
                return false;
            }

            if (!opportunityTeam.prioritized && this.hasPrioritizedTeamWithMatchingFilter) {
                return false;
            }

            for (const vehicleSummary of this.vehicleSummaries) {
                for (const filter of opportunityTeam.filters) {
                    if (
                        opportunityTeamFilterMatches(
                            filter,
                            this.opportunitySourceId,
                            this.contactType,
                            vehicleSummary,
                            this.acquisitionType,
                            this.tradeInRequested
                        )
                    ) {
                        return true;
                    }
                }
            }

            return false;
        },

        isHighlighted(opportunityTeamId: string) {
            const opportunityTeam = this.getOpportunityTeamById(opportunityTeamId);

            if (!opportunityTeam || !this.vehicleSummaries.length) {
                return false;
            }

            return (
                this.hasMatchingFilter(opportunityTeam) ||
                (opportunityTeam.fallbackFilter && !this.hasTeamWithMatchingFilter)
            );
        },
    },

    watch: {
        async value() {
            this.valueProp = cloneObject(this.value);
        },
    },

    components: {
        DealerLink,
        OpportunityTeamLink,
    },
});
