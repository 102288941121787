
import CaseAttachmentsCard from "./CaseAttachmentsCard.vue";
import CaseContactVehicleCard from "./CaseContactVehicleCard.vue";
import CaseConvertDialog from "./CaseConvertDialog.vue";
import CaseMergeDialog from "./CaseMergeDialog.vue";
import CaseOutcomeDialog from "./CaseOutcomeDialog.vue";
import CaseReceiversCard from "./CaseReceiversCard.vue";
import CaseReleaseDialog from "./CaseReleaseDialog.vue";
import CaseTimelineCard from "./CaseTimelineCard.vue";
import CaseUsersCard from "./CaseUsersCard.vue";
import GeneralInfoCard from "./GeneralInfoCard.vue";
import PreferredContactCard from "./PreferredContactCard.vue";
import {
    canManageCase,
    canSendEmail,
    canSendSms,
    canSendWhatsapp,
    canStartCall,
    getAssigneeIdsSortedByUserFullName,
    getCaseChannelColor,
    getCaseChannelIcon,
    getCaseStatus,
    getCloser,
    getCreator,
    getStatusCaption,
    getStatusColor,
    getUrgencyColor,
    getUrgencyTextColor,
    isInvalidCaseStatusExceptionWithCaseStatus,
} from "./caseUtils";
import { CaseOutcomeReason } from "@/api/caseOutcomeReasons";
import {
    Case,
    CaseConversionForm,
    CaseOutcomeForm,
    CasePriority,
    CaseReceiversForm,
    CaseResult,
    casesApi,
    CaseStatus,
    CaseUrgency,
} from "@/api/cases";
import { Contact, ContactVehicle } from "@/api/contacts";
import { BadRequest, Forbidden } from "@/api/errors";
import { IncomingEmail } from "@/api/incomingEmails";
import { IncomingWhatsAppMessage } from "@/api/incomingWhatsApp";
import { isCaseUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import { OutgoingEmail } from "@/api/outgoingEmails";
import { OutgoingWhatsAppMessage } from "@/api/outgoingWhatsApp";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import PrintFrame from "@/app/components/PrintFrame.vue";
import { showConfirm, showError } from "@/app/messageUtil";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import PostponementDialog from "@/app/pages/PostponementDialog.vue";
import UserLink from "@/app/pages/UserLink.vue";
import ReadReceiptsCard from "@/app/pages/readreceipt/ReadReceiptsCard.vue";
import UserPickerDialog from "@/app/pages/users/UserPickerDialog.vue";
import { getSentimentIcon, getSentimentIconColor, getSentimentText } from "@/app/sentimentUtils";
import { caseOutcomeReasonsStore } from "@/store/caseOutcomeReasons";
import { configStore } from "@/store/config";
import { titleStore } from "@/store/title";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            CasePriority,
            CaseStatus,
            CaseUrgency,
            activeWorkers: 0,
            assigneePickerVisible: false,
            caseConvertDialogVisible: false,
            caseMergeDialogVisible: false,
            caseOutcomeDialogVisible: false,
            caseReleaseDialogVisible: false,
            caseResult: null as CaseResult | null,
            forbidden: false,
            hint: "",
            hintEditMode: false,
            incomingEmails: [] as IncomingEmail[],
            incomingWhatsAppMessages: [] as IncomingWhatsAppMessage[],
            loading: true,
            loadLimiter: new ActionLimiter(true),
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingEmails: [] as OutgoingEmail[],
            outgoingWhatsAppMessages: [] as OutgoingWhatsAppMessage[],
            postponementDialogVisible: false,
            printView: "",
            savingHint: false,
            subject: "",
            updateId: 0,
        };
    },

    computed: {
        assigneeIdsSortedByUserFullName(): string[] {
            return getAssigneeIdsSortedByUserFullName(this.caseObj);
        },

        canAssignYourself(): boolean {
            if (this.caseStatus !== CaseStatus.OPEN && this.caseStatus !== CaseStatus.ASSIGNED) {
                return false;
            }

            if (userSession.isCtUser() || this.caseObj!.assigneeIds.includes(userSession.userId!)) {
                return false;
            }

            return this.canManageCase;
        },

        canClose(): boolean {
            if (this.caseStatus !== CaseStatus.OPEN && this.caseStatus !== CaseStatus.ASSIGNED) {
                return false;
            }

            return this.canManageCase;
        },

        canConvert(): boolean {
            if (!this.caseObj) {
                return false;
            }

            const isOwnIssue =
                this.caseObj.creatorUserId === userSession.userId ||
                this.caseObj.assigneeIds.includes(userSession.userId!);

            if (
                !userSession.hasPermission(Permission.MANAGE_ALL_CASES) &&
                !(userSession.hasPermission(Permission.MANAGE_OWN_CASES) && isOwnIssue)
            ) {
                return false;
            }

            const isVisibleAfterConversion =
                userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES) ||
                (userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES) && isOwnIssue);

            return this.caseStatus !== CaseStatus.CLOSED && isVisibleAfterConversion;
        },

        canDelete(): boolean {
            return userSession.hasPermission(Permission.DELETE_CASE);
        },

        canEditAttachments(): boolean {
            return this.canManageCase;
        },

        canEditContactAndVehicle(): boolean {
            return this.canManageCase;
        },

        canEditGeneralInfo(): boolean {
            return this.canManageCase;
        },

        canEditPreferredContact(): boolean {
            return this.canManageCase;
        },

        canEditReceivers(): boolean {
            return this.canManageCase;
        },

        canManageCase(): boolean {
            if (!this.caseObj) {
                return false;
            }

            return canManageCase(this.caseObj);
        },

        canMerge(): boolean {
            return this.canManageCase && this.caseStatus !== CaseStatus.CLOSED;
        },

        canPostpone(): boolean {
            if (this.caseStatus !== CaseStatus.ASSIGNED) {
                return false;
            }

            if (this.canPostponeOnlyWhenAssignedToSelf && !this.isAssignedToSelf) {
                return this.canUpdateAssignees && this.canManageCase;
            }

            return this.canManageCase;
        },

        canPostponeOnlyWhenAssignedToSelf(): boolean {
            return !userSession.hasPermission(Permission.MANAGE_ALL_CASES);
        },

        canRemoveSelfAssignment(): boolean {
            if (this.caseStatus !== CaseStatus.ASSIGNED || !this.caseObj!.assigneeIds.includes(userSession.userId!)) {
                return false;
            }

            return this.canManageCase;
        },

        canReopen(): boolean {
            if (this.caseStatus !== CaseStatus.CLOSED) {
                return false;
            }

            return this.canManageCase;
        },

        canSendEmail(): boolean {
            if (!this.caseObj) {
                return false;
            }

            return canSendEmail(this.caseObj);
        },

        canSendSms(): boolean {
            if (!this.caseObj) {
                return false;
            }

            return canSendSms(this.caseObj);
        },

        canSendWhatsapp(): boolean {
            if (!this.caseObj) {
                return false;
            }

            return canSendWhatsapp(this.caseObj);
        },

        canStartCall(): boolean {
            if (!this.caseObj) {
                return false;
            }

            return canStartCall(this.caseObj);
        },

        canUnpostpone(): boolean {
            if (this.caseStatus !== CaseStatus.POSTPONED) {
                return false;
            }

            return this.canManageCase;
        },

        canUpdateAssignees(): boolean {
            if (this.caseStatus === CaseStatus.CLOSED) {
                return false;
            }

            return this.canManageCase;
        },

        canUpdateOutcome(): boolean {
            if (this.caseStatus !== CaseStatus.CLOSED) {
                return false;
            }

            return this.canManageCase;
        },

        canViewActivityContent(): boolean {
            return (
                (this.canManageCase && !userSession.isCtUser()) ||
                userSession.hasPermission(Permission.VIEW_ALL_CASES_ACTIVITY_CONTENT)
            );
        },

        caseObj(): Case | null {
            return this.caseResult?.caseObj ?? null;
        },

        caseOutcomeReason(): CaseOutcomeReason | null {
            if (!this.caseObj?.outcomeReasonId) {
                return null;
            }

            return caseOutcomeReasonsStore.getCaseOutcomeReasonById(this.caseObj.outcomeReasonId);
        },

        caseStatus(): CaseStatus {
            return getCaseStatus(this.caseObj!);
        },

        caseStatusCaption(): string {
            return getStatusCaption(this.caseObj);
        },

        caseStatusColor(): string | null {
            return getStatusColor(this.caseObj);
        },

        caseTargetEmailAddress(): string {
            return `${this.caseObj!.id}@${configStore.configuration.transactionEmailHost}`;
        },

        channelColor(): string | null {
            if (!this.caseObj) {
                return null;
            }

            return getCaseChannelColor(this.caseObj.channel);
        },

        channelIcon(): string | null {
            if (!this.caseObj) {
                return null;
            }

            return getCaseChannelIcon(this.caseObj.channel);
        },

        closedTimestamp(): string | null {
            if (!this.caseObj?.closed) {
                return null;
            }

            return formatInstant(this.caseObj.closed, userSession.timeZone, userSession.locale, "S");
        },

        closer(): string {
            return getCloser(this.caseObj);
        },

        contact(): Contact | null {
            return this.caseResult?.contact ?? null;
        },

        contactVehicle(): ContactVehicle | null {
            return this.caseResult?.contactVehicle ?? null;
        },

        creationTimestamp(): string | null {
            if (!this.caseObj) {
                return null;
            }

            return formatInstant(this.caseObj.created, userSession.timeZone, userSession.locale, "S");
        },

        creator(): string {
            return getCreator(this.caseObj);
        },

        isAssignedToSelf(): boolean {
            if (!this.caseObj) {
                return false;
            }

            return this.caseObj.assigneeIds.includes(userSession.userId!);
        },

        postponedUntilTimestamp(): string | null {
            if (!this.caseObj?.postponedUntil) {
                return null;
            }

            return formatInstant(this.caseObj.postponedUntil, userSession.timeZone, userSession.locale, "S");
        },

        sentimentIcon(): string {
            return getSentimentIcon(this.caseObj?.sentiment ?? null);
        },

        sentimentIconColor(): string | null {
            return getSentimentIconColor(this.caseObj?.sentiment ?? null);
        },

        sentimentText(): string | null {
            return getSentimentText(this.caseObj?.sentiment ?? null);
        },

        urgencyColor(): string | null {
            return getUrgencyColor(this.caseObj);
        },

        urgencyTextColor(): string | null {
            return getUrgencyTextColor(this.caseObj);
        },

        mandatoryAssignees(): string[] {
            return this.caseObj?.assigneeIds.includes(userSession.userId!) ? [userSession.userId!] : [];
        },
    },

    methods: {
        async assignYourself() {
            this.activeWorkers++;
            try {
                await casesApi.updateAssignees(this.caseObj!.id, [...this.caseObj!.assigneeIds, userSession.userId!]);
            } finally {
                this.activeWorkers--;
            }
        },

        async closeCase(caseOutcomeForm: CaseOutcomeForm) {
            this.caseOutcomeDialogVisible = false;
            this.activeWorkers++;
            try {
                if (this.caseStatus === CaseStatus.CLOSED) {
                    await casesApi.updateOutcome(this.caseObj!.id, caseOutcomeForm);
                } else {
                    await casesApi.closeCase(this.caseObj!.id, caseOutcomeForm);
                }
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }

                if (!isInvalidCaseStatusExceptionWithCaseStatus(e, CaseStatus.CLOSED)) {
                    throw e;
                }

                showError(this.$t("Der Fall wurde bereits geschlossen.") as string);
            } finally {
                this.activeWorkers--;
            }
        },

        async convertCase(caseConversionForm: CaseConversionForm) {
            const opportunityId = await casesApi.convertCase(this.caseObj!.id, caseConversionForm);
            try {
                await this.$router.replace(`/opportunity/${opportunityId}`);
            } catch (e) {
                // ignore
            }
        },

        async deleteCase() {
            if (
                await showConfirm(
                    this.$t("Fall löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Fall unwiderruflich löschen möchten?") as string
                )
            ) {
                this.activeWorkers++;
                try {
                    await casesApi.deleteCase(this.caseObj!.id);
                } finally {
                    this.activeWorkers--;
                }
            }
        },

        async mergeCase(sourceCaseId: string) {
            this.caseMergeDialogVisible = false;
            this.activeWorkers++;
            try {
                await casesApi.mergeCase(this.caseObj!.id, sourceCaseId);
            } finally {
                this.activeWorkers--;
            }
        },

        async postponeCase(postponedUntil: Date) {
            this.postponementDialogVisible = false;

            this.activeWorkers++;
            try {
                if (this.canPostponeOnlyWhenAssignedToSelf && !this.isAssignedToSelf) {
                    if (
                        !(await showConfirm(
                            this.$t("Zuteilung erforderlich") as string,
                            this.$t(
                                "Um diesen Fall zurückzustellen, müssen Sie sich erst zuteilen. Jetzt zuteilen?"
                            ) as string
                        ))
                    ) {
                        return;
                    }

                    await this.updateAssignees([...this.caseObj!.assigneeIds, userSession.userId!]);
                }

                await casesApi.postponeCase(this.caseObj!.id, postponedUntil);
            } finally {
                this.activeWorkers--;
            }
        },

        async removeSelfAssignment(receiversForm?: CaseReceiversForm, dealerId?: string) {
            const newAssignees = this.caseObj!.assigneeIds.filter((a) => a !== userSession.userId!);
            if (!newAssignees.length && !receiversForm) {
                this.caseReleaseDialogVisible = true;
                return;
            }

            this.caseReleaseDialogVisible = false;

            this.activeWorkers++;
            try {
                if (dealerId && this.caseObj!.dealerId !== dealerId) {
                    await casesApi.updateGeneralInfo(this.caseObj!.id, {
                        dealerId,
                        channel: this.caseObj!.channel,
                        caseType: this.caseObj!.caseType,
                        sentiment: this.caseObj!.sentiment,
                        urgency: this.caseObj!.urgency,
                        appointmentTime: this.caseObj!.appointmentTime,
                        subject: this.caseObj!.subject,
                        escalationBegin: this.caseObj!.escalationBegin,
                    });
                }
                if (receiversForm) {
                    await casesApi.editReceivers(this.caseObj!.id, receiversForm);
                }
                await casesApi.updateAssignees(this.caseObj!.id, newAssignees);
            } finally {
                this.activeWorkers--;
            }
        },

        async reopenCase() {
            this.activeWorkers++;
            try {
                await casesApi.reopenCase(this.caseObj!.id);
            } finally {
                this.activeWorkers--;
            }
        },

        async saveHint() {
            this.savingHint = true;
            try {
                await casesApi.updateGeneralInfo(this.caseObj!.id, { ...this.caseObj!, subject: this.hint });
                this.hintEditMode = false;
            } finally {
                this.savingHint = false;
            }
        },

        showOutgoingEmailForm(email: string | null) {
            if (!this.$refs.timeline) {
                return;
            }

            (this.$refs.timeline as any).showOutgoingEmailForm(email);
        },

        showOutgoingCallForm(phone: string | null) {
            if (!this.$refs.timeline) {
                return;
            }

            (this.$refs.timeline as any).showOutgoingCallForm(phone);
        },

        showOutgoingSmsForm(phone: string | null) {
            if (!this.$refs.timeline) {
                return;
            }

            (this.$refs.timeline as any).showOutgoingSmsForm(phone);
        },

        switchToHintEditMode() {
            this.hintEditMode = true;
            this.hint = this.caseObj?.subject || "";
        },

        async unpostponeCase() {
            this.activeWorkers++;
            try {
                await casesApi.unpostponeCase(this.caseObj!.id);
            } finally {
                this.activeWorkers--;
            }
        },

        async updateAssignees(assignees: string[]) {
            this.assigneePickerVisible = false;
            this.activeWorkers++;
            try {
                await casesApi.updateAssignees(this.caseObj!.id, assignees);
            } finally {
                this.activeWorkers--;
            }
        },

        async print() {
            this.activeWorkers++;
            try {
                this.printView = await casesApi.getPrintView(this.caseObj!.id);
            } finally {
                this.activeWorkers--;
            }
            await this.$nextTick();
            (this.$refs.printFrame as any).print();
        },
    },

    async mounted() {
        let caseId: string | null;

        try {
            const routeCaseId = this.$route.params.caseid;

            caseId = await casesApi.resolveAlias(routeCaseId);

            if (!caseId) {
                this.loading = false;
                return;
            }

            if (caseId !== routeCaseId) {
                try {
                    await this.$router.replace({ params: { caseid: caseId } });
                } catch (e) {
                    // ignore
                }
                return;
            }
        } catch (e) {
            this.loading = false;
            if (!(e instanceof Forbidden)) {
                throw e;
            }
            this.forbidden = true;
            return;
        }

        titleStore.title = this.$t("Fall {0}", [caseId]) as string;

        try {
            this.caseResult = await casesApi.getById(caseId);

            if (!this.caseResult?.caseObj) {
                return;
            }

            casesApi.updateRead(this.caseResult!.caseObj.id);
        } catch (e) {
            if (!(e instanceof Forbidden)) {
                throw e;
            }
            this.forbidden = true;
            return;
        } finally {
            this.loading = false;
        }

        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isCaseUpdatedNotification(n)) {
                if (this.caseObj?.id !== n.caseId) {
                    return;
                }

                await this.loadLimiter.execute(async () => {
                    try {
                        const updateId = ++this.updateId;
                        const caseResult = await casesApi.getById(this.caseObj!.id);

                        if (updateId === this.updateId) {
                            this.caseResult = caseResult;

                            this.forbidden = false;
                        }
                    } catch (e) {
                        if (!(e instanceof Forbidden)) {
                            throw e;
                        }
                        this.forbidden = true;
                    }
                });
            }
        });

        if (this.$route.query.action) {
            await this.$nextTick();

            const action = this.$route.query.action as string;
            const emailAddress = decodeURIComponent((this.$route.query.emailAddress as string) ?? "") || null;
            const phoneNumber = decodeURIComponent((this.$route.query.phoneNumber as string) ?? "") || null;

            if (action === "SEND_EMAIL") {
                this.showOutgoingEmailForm(emailAddress);
            } else if (action === "SEND_SMS") {
                this.showOutgoingSmsForm(phoneNumber);
            } else if (action === "START_CALL") {
                this.showOutgoingCallForm(phoneNumber);
            }

            await this.$router.replace({
                query: { ...this.$route.query, action: undefined, emailAddress: undefined, phoneNumber: undefined },
            });
        }
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        CaseAttachmentsCard,
        CaseContactVehicleCard,
        CaseConvertDialog,
        CaseMergeDialog,
        CaseReceiversCard,
        CaseReleaseDialog,
        CaseOutcomeDialog,
        CaseTimelineCard,
        CaseUsersCard,
        CopyToClipboardIcon,
        GeneralInfoCard,
        IconWithTooltip,
        PostponementDialog,
        PreferredContactCard,
        PrintFrame,
        ReadReceiptsCard,
        UserLink,
        UserPickerDialog,
    },
});
