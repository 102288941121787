
import { getStatusColor } from "./financeContractUtils";
import { FinanceContractUploadSource } from "@/api/financeContractUploads";
import { FinanceContract, ProcessingState } from "@/api/financeContracts";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DLink from "@/app/components/DLink.vue";
import { renderVehicleTitle } from "@/app/contactUtils";
import { userSession } from "@/store/userSession";
import { addDuration, formatInstant, formatLocalDate, getDate, toDateObject, UnitOfTime } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        compact: {
            type: Boolean,
            required: false,
            default: false,
        },
        financeContract: {
            type: Object as () => FinanceContract,
            required: true,
        },
        financeContractUploadSource: {
            type: Object as () => FinanceContractUploadSource | null,
            required: false,
            default: null,
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            ProcessingState,
        };
    },

    computed: {
        canViewAllOpportunities(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES);
        },

        processDate(): string | null {
            if (
                this.financeContract.processingState !== ProcessingState.UNPROCESSED ||
                !this.financeContract.finalInstallmentPaymentDate ||
                !this.financeContractUploadSource
            ) {
                return null;
            }

            const processDate = addDuration(
                toDateObject(userSession.timeZone, this.financeContract.finalInstallmentPaymentDate),
                userSession.timeZone,
                -this.financeContractUploadSource.processInAdvanceMonth,
                UnitOfTime.MONTH
            );

            return getDate(processDate, userSession.timeZone);
        },

        statusColor(): string | null {
            return getStatusColor(this.financeContract.processingState);
        },

        vehicleTitle(): string {
            return renderVehicleTitle(
                {
                    licensePlate: this.financeContract.licensePlate,
                    make: this.financeContract.make,
                    model: this.financeContract.model,
                    modelDescription: this.financeContract.modelDescription,
                    modelYear: null,
                },
                false
            );
        },

        verticalPadding(): number {
            return this.compact ? 1 : 3;
        },
    },

    methods: {
        formatInstant(ts: Date): string {
            return formatInstant(ts, userSession.timeZone, userSession.locale, "S");
        },

        formatLocalDate(date: Date): string {
            return formatLocalDate(getDate(date, userSession.timeZone), userSession.locale, "L");
        },
    },

    components: {
        CopyToClipboardIcon,
        DLink,
    },
});
