var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',_vm._b({attrs:{"outlined":"","disabled":_vm.disabled}},'v-card',_vm.$attrs,false),[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("Verkäuferteams"))+" ")]),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-account-group-outline")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-5",attrs:{"cols":"12"}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("Ausgewählte Verkäuferteams")))])]),(!this.valueProp.length)?_c('v-alert',{staticClass:"body-2 mt-2 mb-0",attrs:{"type":"error","elevation":"0","text":"","dense":"","colored-border":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("Es sind keine Verkäuferteams ausgewählt!"))+" ")]):_vm._e(),_vm._l((_vm.valueProp),function(opportunityTeamId){return _c('div',{key:opportunityTeamId},[_c('span',{staticClass:"pa-1",style:(_vm.isHighlighted(opportunityTeamId)
                                ? _vm.$vuetify.theme.dark
                                    ? 'background: #ffa73c'
                                    : 'background: #fcf003'
                                : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.deleteOpportunityTeam(opportunityTeamId)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Verkäuferteam abwählen")))])]),_c('opportunity-team-link',{staticClass:"mr-1",attrs:{"opportunity-team-id":opportunityTeamId}}),_c('small',[_vm._v(" ("),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-map-marker")]),_c('dealer-link',{attrs:{"dealer-id":_vm.getOpportunityTeamById(opportunityTeamId).dealerId}}),_vm._v(") ")],1)],1)])})],2),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("Verkäuferteams")))])]),(!_vm.unselectedLocalOpportunityTeams.length)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Es sind keine Verkäuferteams für die Auswahl verfügbar."))+" ")]):_vm._e(),_vm._l((_vm.unselectedLocalOpportunityTeams),function(opportunityTeam){return _c('div',{key:opportunityTeam.id},[_c('span',{staticClass:"pa-1",style:(_vm.isHighlighted(opportunityTeam.id)
                                ? _vm.$vuetify.theme.dark
                                    ? 'background: #ffa73c'
                                    : 'background: #fcf003'
                                : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.addOpportunityTeam(opportunityTeam.id)}}},on),[_vm._v(" mdi-plus ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Verkäuferteam auswählen")))])]),_c('opportunity-team-link',{attrs:{"opportunity-team-id":opportunityTeam.id}})],1)])})],2),(_vm.unselectedGlobalOpportunityTeams.length)?_c('v-col',{staticClass:"pt-3 pb-0",attrs:{"cols":"12"}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("Verkäuferteams anderer Standorte")))])]),_vm._l((_vm.unselectedGlobalOpportunityTeams),function(opportunityTeam){return _c('div',{key:opportunityTeam.id},[_c('span',{staticClass:"pa-1",style:(_vm.isHighlighted(opportunityTeam.id)
                                ? _vm.$vuetify.theme.dark
                                    ? 'background: #ffa73c'
                                    : 'background: #fcf003'
                                : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.addOpportunityTeam(opportunityTeam.id)}}},on),[_vm._v(" mdi-plus ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Verkäuferteam auswählen")))])]),_c('opportunity-team-link',{staticClass:"mr-1",attrs:{"opportunity-team-id":opportunityTeam.id}}),_c('small',[_vm._v(" ("),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-map-marker")]),_c('dealer-link',{attrs:{"dealer-id":opportunityTeam.dealerId}}),_vm._v(") ")],1)],1)])})],2):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }