
import { incomingWhatsAppApi, IncomingWhatsAppMessage } from "@/api/incomingWhatsApp";
import Vue from "vue";

export default Vue.extend({
    props: {
        message: {
            type: Object as () => IncomingWhatsAppMessage,
            required: true,
        },
    },

    computed: {
        audio(): boolean {
            return !!this.message.mediaType && this.message.mediaType.startsWith("audio/");
        },

        image(): boolean {
            return this.message.mediaType === "image/jpeg" || this.message.mediaType === "image/png";
        },

        video(): boolean {
            return !!this.message.mediaType && this.message.mediaType.startsWith("video/");
        },

        mediaUrl(): string | null {
            if (!this.message.mediaHash) {
                return null;
            }

            const issueId = this.message.caseId
                ? { caseId: this.message.caseId }
                : this.message.opportunityId
                ? { opportunityId: this.message.opportunityId }
                : {};

            return incomingWhatsAppApi.generateMediaLink(this.message, issueId);
        },
    },
});
