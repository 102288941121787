
import OpportunityInventoryVehiclesCardRow from "./OpportunityInventoryVehiclesCardRow.vue";
import { OpportunityInventoryVehicleWithInventoryVehicle } from "./opportunityUtils";
import { InventoryVehicle } from "@/api/inventory";
import { opportunitiesApi } from "@/api/opportunities";
import DToolbarCard from "@/app/components/DToolbarCard.vue";
import { showConfirm } from "@/app/messageUtil";
import InventoryVehiclePickerDialog from "@/app/pages/inventory/InventoryVehiclePickerDialog.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        canEdit: {
            type: Boolean,
            required: true,
        },
        contactId: {
            type: String,
            required: false,
        },
        opportunityId: {
            type: String,
            required: true,
        },
        opportunityInventoryVehiclesWithInventoryVehicleProp: {
            type: Array as () => OpportunityInventoryVehicleWithInventoryVehicle[],
            required: true,
        },
    },

    data() {
        return {
            adding: false,
            deleting: false,
            inventoryVehiclePickerDialogVisible: false,
            opportunityInventoryVehiclesWithInventoryVehicle: [
                ...this.opportunityInventoryVehiclesWithInventoryVehicleProp,
            ],
            expanded: null as boolean | null,
        };
    },

    computed: {
        selectedVehicleIds(): string[] {
            return this.opportunityInventoryVehiclesWithInventoryVehicle.map(
                (i) => i.opportunityInventoryVehicle.inventoryVehicleId
            );
        },
    },

    watch: {
        opportunityInventoryVehiclesWithInventoryVehicleProp() {
            this.opportunityInventoryVehiclesWithInventoryVehicle = [
                ...this.opportunityInventoryVehiclesWithInventoryVehicleProp,
            ];
        },

        opportunityInventoryVehiclesWithInventoryVehicle() {
            if (this.expanded === null && this.opportunityInventoryVehiclesWithInventoryVehicle.length) {
                // expand only if the user has not changed the expansion state manually
                this.expanded = true;
            }
        },
    },

    methods: {
        async addInventoryVehicle(inventoryVehicle: InventoryVehicle) {
            if (this.selectedVehicleIds.includes(inventoryVehicle.id)) {
                return;
            }

            this.adding = true;
            try {
                await opportunitiesApi.addInventoryVehicle(this.opportunityId, {
                    inventoryVehicleId: inventoryVehicle.id,
                    link: null,
                });
            } finally {
                this.adding = false;
            }
        },

        async removeInventoryVehicle(inventoryVehicleId: string) {
            if (
                !(await showConfirm(
                    this.$t("Angefragtes Bestandsfahrzeug löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie das Fahrzeug löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.deleting = true;
            try {
                await opportunitiesApi.deleteInventoryVehicle(this.opportunityId, inventoryVehicleId);

                // remove before remote update arrives to avoid that vehicle still remains briefly after load animation ends
                this.opportunityInventoryVehiclesWithInventoryVehicle = this.opportunityInventoryVehiclesWithInventoryVehicle.filter(
                    (i) => i.opportunityInventoryVehicle.inventoryVehicleId !== inventoryVehicleId
                );
            } finally {
                this.deleting = false;
            }
        },
    },

    created() {
        this.expanded = !!this.opportunityInventoryVehiclesWithInventoryVehicle.length || null;
    },

    components: {
        DToolbarCard,
        InventoryVehiclePickerDialog,
        OpportunityInventoryVehiclesCardRow,
    },
});
