
import NumberBlacklistFormDialog from "./NumberBlacklistFormDialog.vue";
import { NumberBlacklistEntry, numbersBlacklistApi } from "@/api/numbersBlacklist";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import { showConfirm } from "@/app/messageUtil";
import UserLink from "@/app/pages/UserLink.vue";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { formatInstant } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        numberBlacklistEntry: {
            type: Object as () => NumberBlacklistEntry,
            required: true,
        },
    },

    data() {
        return {
            deleting: false,
            numberBlacklistFormDialogVisible: false,
        };
    },

    computed: {
        creator(): string {
            if (this.numberBlacklistEntry.creatorUserId) {
                const user = usersStore.getUserById(this.numberBlacklistEntry.creatorUserId);

                return user ? getFullName(user) : (this.$t("Gelöschter Benutzer") as string);
            } else if (this.numberBlacklistEntry.creatorCtUserId) {
                return this.$t("Externer Agent") as string;
            } else {
                return this.$t("System") as string;
            }
        },
    },

    methods: {
        async deleteNumberBlacklistEntry() {
            if (
                !(await showConfirm(
                    this.$t("Rufnummernsperre löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Sperre der Rufnummer {0} löschen wollen?", [
                        parseAndFormatNumber(this.numberBlacklistEntry.number, "INTERNATIONAL") ??
                            this.numberBlacklistEntry.number,
                    ]) as string
                ))
            ) {
                return;
            }

            this.deleting = true;

            try {
                await numbersBlacklistApi.delete(this.numberBlacklistEntry.number);
                this.$emit("refresh");
            } finally {
                this.deleting = false;
            }
        },

        formatInstant(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        hideNumberBlacklistFormDialogAndRefresh() {
            this.numberBlacklistFormDialogVisible = false;
            this.$emit("refresh");
        },
    },

    components: {
        CopyToClipboardIcon,
        NumberBlacklistFormDialog,
        UserLink,
    },
});
