import axios from "axios";

export interface ReportingDealerSettings {
    readonly dealerId: string;
    readonly caseReactionTimeOfficeHoursId: string | null;
    readonly caseReactionTimeAsPerceivedExternallyOfficeHoursId: string | null;
    readonly opportunityReactionTimeOfficeHoursId: string | null;
    readonly opportunityReactionTimeAsPerceivedExternallyOfficeHoursId: string | null;
}

export interface ReportingSettings {
    readonly ignoreCaseOutcomeReasonIds: string[];
    readonly ignoreOpportunityOutcomeReasonIds: string[];
}

interface ReportingSettingsApi {
    editReportingDealerSettings(reportingDealerSettings: ReportingDealerSettings): Promise<void>;
    editReportingSettings(reportingSettings: ReportingSettings): Promise<void>;
    getAllReportingDealerSettings(): Promise<ReportingDealerSettings[]>;
    getReportingDealerSettingsByDealer(dealerId: string): Promise<ReportingDealerSettings>;
    getReportingSettings(): Promise<ReportingSettings>;
}

export const reportingSettingsApi: ReportingSettingsApi = {
    async editReportingDealerSettings(reportingDealerSettings) {
        await axios.put("/api/reporting-settings/_", reportingDealerSettings);
    },

    async editReportingSettings(reportingSettings: ReportingSettings) {
        await axios.put("/api/reporting-settings/_global", reportingSettings);
    },

    async getAllReportingDealerSettings() {
        return (await axios.get("/api/reporting-settings")).data;
    },

    async getReportingDealerSettingsByDealer(dealerId) {
        return (await axios.get(`/api/reporting-settings/${dealerId}`)).data;
    },

    async getReportingSettings() {
        return (await axios.get("/api/reporting-settings/_global")).data;
    },
};
