
import { ExteriorColor, Fuel, UsageType } from "@/api/inventory";
import EnumField from "@/app/components/EnumField.vue";
import ExpansionPanel from "@/app/components/ExpansionPanel.vue";
import NumberField from "@/app/components/NumberField.vue";
import PartialDatePicker from "@/app/components/PartialDatePicker.vue";
import MakePicker from "@/app/pages/MakePicker.vue";
import ModelPicker from "@/app/pages/ModelPicker.vue";
import { OpportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import { decimal, defaultCurrency, integer } from "@/app/validation";
import { configStore } from "@/store/config";
import { makeModelsStore } from "@/store/makeModels";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        opportunityAssistantState: {
            type: Object as () => OpportunityAssistantState,
            required: true,
        },
    },

    data() {
        return {
            ExteriorColor,
            Fuel,
            UsageType,
            currencyRules: decimal(defaultCurrency()),
            now: now(),
            positiveIntegerRules: integer(0),
        };
    },

    computed: {
        currentDate(): string {
            return getDate(this.now, userSession.timeZone);
        },

        defaultCurrency(): string {
            return configStore.configuration.defaultCurrency;
        },

        vehicleClassOptions(): SelectOption[] {
            return makeModelsStore.vehicleClasses
                .map((vc) => ({
                    value: vc,
                    text: this.$t(`vehicle.class.${vc}`).toString(),
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    components: {
        EnumField,
        ExpansionPanel,
        MakePicker,
        ModelPicker,
        NumberField,
        PartialDatePicker,
    },
});
