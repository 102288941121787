
import { casesApi } from "@/api/cases";
import { ContactAndVehicleId } from "@/app/contactUtils";
import ActionCard from "@/app/pages/ActionCard.vue";
import ContactAndVehiclePicker from "@/app/pages/contacts/ContactAndVehiclePicker.vue";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        caseId: {
            type: String,
            required: true,
        },
        contactId: {
            type: String,
            required: false,
        },
        vehicleId: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            working: false,
            contactAndVehicleId: (this.contactId
                ? {
                      contactId: this.contactId,
                      contactVehicleId: this.vehicleId ?? null,
                  }
                : null) as ContactAndVehicleId | null,
        };
    },

    computed: {
        isCtUser(): boolean {
            return userSession.isCtUser();
        },

        phoneticSpellingLocale(): string {
            return configStore.configuration.defaultLocale;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async save() {
            this.working = true;
            try {
                await casesApi.editContactAndVehicle(this.caseId, {
                    contactId: this.contactAndVehicleId?.contactId ?? null,
                    contactVehicleId: this.contactAndVehicleId?.contactVehicleId ?? null,
                });
                this.$emit("close");
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        ContactAndVehiclePicker,
    },
});
