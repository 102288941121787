import { CaseChannel, CasePriority, CaseResult, CaseStatus, CaseUrgency } from "./cases";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum ActorType {
    USER = "USER",
    CT_USER = "CT_USER",
    SYSTEM = "SYSTEM",
}

export enum CaseCountType {
    DEALER_ID = "DEALER_ID",
    CASE_TYPE = "CASE_TYPE",
    STATUS = "STATUS",
    CHANNEL = "CHANNEL",
    SENTIMENT_TYPE = "SENTIMENT_TYPE",
    URGENCY = "URGENCY",
    PRIORITY = "PRIORITY",
    CREATOR_ACTOR_TYPE = "CREATOR_ACTOR_TYPE",
    CREATED_BY = "CREATED_BY",
    CLOSER_ACTOR_TYPE = "CLOSER_ACTOR_TYPE",
    CLOSED_BY = "CLOSED_BY",
    OUTCOME_REASON_ID = "OUTCOME_REASON_ID",
    ASSIGNEE = "ASSIGNEE",
    INDIVIDUAL_RECEIVER = "INDIVIDUAL_RECEIVER",
    ESCALATION_GROUP_RECEIVER = "ESCALATION_GROUP_RECEIVER",
}

export enum CaseSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    LAST_ACTIVITY_DESC = "LAST_ACTIVITY_DESC",
    LAST_ACTIVITY_ASC = "LAST_ACTIVITY_ASC",
    POSTPONED_UNTIL_DESC = "POSTPONED_UNTIL_DESC",
    POSTPONED_UNTIL_ASC = "POSTPONED_UNTIL_ASC",
    CLOSED_DESC = "CLOSED_DESC",
    CLOSED_ASC = "CLOSED_ASC",
    PRIORITY_DESC = "PRIORITY_DESC",
}

export enum SentimentType {
    UNKNOWN = "UNKNOWN",
    POSITIVE = "POSITIVE",
    NEUTRAL = "NEUTRAL",
    NEGATIVE = "NEGATIVE",
}

export interface CaseCount {
    readonly type: CaseCountType;
    readonly value: string | null;
    readonly count: number;
}

export interface CaseSearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

export interface CaseSearchRequest {
    readonly caseIds: string[];
    readonly excludedCaseIds: string[];
    readonly dealerIds: string[];
    readonly caseTypes: string[];
    readonly status: CaseStatus[];
    readonly channels: CaseChannel[];
    readonly sentimentTypes: SentimentType[];
    readonly urgencies: CaseUrgency[];
    readonly priority: CasePriority[];
    readonly creatorActorType: ActorType[];
    readonly createdBy: string[];
    readonly closerActorType: ActorType[];
    readonly closedBy: string[];
    readonly outcomeReasonIds: (string | null)[];
    readonly assignees: (string | null)[];
    readonly contactIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly lastActivityFrom: Date | null;
    readonly lastActivityTo: Date | null;
    readonly closedFrom: Date | null;
    readonly closedTo: Date | null;
    readonly individualReceivers: (string | null)[];
    readonly escalationGroupReceivers: (string | null)[];
    readonly hasReceivers: boolean | null;
    readonly externalReference: string | null;
    readonly search: string | null;
    readonly sortBy: CaseSearchOrder;
}

export interface CaseSearchResult {
    readonly caseId: string;
    readonly caseResult: CaseResult | null;
}

export interface CaseSearchResults {
    readonly searchId: number;
    readonly totalSize: number;
    readonly results: CaseSearchResult[];
}

interface CaseSearchApi {
    downloadCsv(searchRequest: CaseSearchRequest): Promise<File>;
    getCounts(
        searchRequest: CaseSearchRequest,
        caseCountTypes: CaseCountType[],
        myCases: boolean
    ): Promise<CaseCount[]>;
    rebuild(): Promise<void>;
    search(
        start: number,
        size: number,
        searchRequest: CaseSearchRequest,
        searchId: number,
        myCases: boolean
    ): Promise<CaseSearchResults>;
    size(): Promise<CaseSearchIndexSize>;
}

export const caseSearchApi: CaseSearchApi = {
    async downloadCsv(searchRequest) {
        const response = await axios.post("/api/case-search/_csv", searchRequest, { responseType: "blob" });

        return new File(
            [response.data],
            response.headers["content-disposition"].replace("attachment; filename=", "").trim(),
            {
                type: response.headers["content-type"],
            }
        );
    },

    async getCounts(searchRequest, caseCountTypes, myCases) {
        return (
            await axios.post("/api/case-search/_counts", searchRequest, {
                params: {
                    caseCountType: caseCountTypes.join(","),
                    myCases,
                },
            })
        ).data;
    },

    async rebuild() {
        await axios.post("/api/case-search/_rebuild");
    },

    async search(start, size, searchRequest, searchId, myCases) {
        return cloneObject(
            (
                await axios.post("/api/case-search", searchRequest, {
                    params: {
                        start,
                        size,
                        searchId,
                        myCases,
                    },
                })
            ).data
        );
    },

    async size() {
        return (await axios.get("/api/case-search/_size")).data;
    },
};
