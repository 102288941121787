import axios from "axios";

interface IpRestrictionsApi {
    listAddressRanges(): Promise<string[]>;
    addAddressRange(range: string): Promise<void>;
    deleteAddressRange(range: string): Promise<void>;
    getExceptions(): Promise<string[]>;
    editExceptions(userIds: string[]): Promise<void>;
}

export const ipRestrictionsApi: IpRestrictionsApi = {
    async listAddressRanges() {
        return (await axios.get("/api/ip-restrictions")).data;
    },

    async addAddressRange(range) {
        await axios.post("/api/ip-restrictions", null, {
            params: {
                range,
            },
        });
    },

    async deleteAddressRange(range) {
        await axios.delete("/api/ip-restrictions", {
            params: {
                range,
            },
        });
    },

    async getExceptions() {
        return (await axios.get("/api/ip-restrictions/_exceptions")).data;
    },

    async editExceptions(userIds) {
        await axios.post("/api/ip-restrictions/_exceptions", userIds);
    },
};
