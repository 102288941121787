
import { smsPlaceholders } from "./smsPlaceholders";
import { SmsTemplate, SmsTemplateForm, smsTemplatesApi } from "@/api/smsTemplates";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        smsTemplate: {
            type: Object as () => SmsTemplate,
            required: false,
        },
        fragment: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isWorking: false,
            name: this.smsTemplate?.name || "",
            nameRules: notEmpty().maxLength(300),
            content: this.smsTemplate?.content || "",
            contentRules: notEmpty().maxLength(8000),
            locale: this.smsTemplate?.locale || configStore.configuration.defaultLocale,
        };
    },

    computed: {
        placeholders(): string[] {
            return [...smsPlaceholders, "LINK_<key>"];
        },

        locales(): SelectOptions {
            return this.$i18n.availableLocales.map((k) => ({ value: k, text: this.$t(`locale.${k}`) }));
        },
    },

    methods: {
        appendToContent(placeholder: string) {
            this.content += placeholder;
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            const form: SmsTemplateForm = {
                name: this.name,
                content: this.content,
                locale: this.locale,
            };

            try {
                if (this.smsTemplate) {
                    await smsTemplatesApi.edit(this.smsTemplate.id, form);
                } else {
                    await smsTemplatesApi.add(form, this.fragment);
                }
                this.$emit("success");
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },
    },
});
