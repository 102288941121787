var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('d-toolbar-card',_vm._b({attrs:{"disabled":_vm.disabled,"expandable":_vm.expandable,"expanded":_vm.expanded,"outlined":"","title":_vm.$t('Eskalationsgruppen')},scopedSlots:_vm._u([{key:"toolbar-buttons",fn:function(){return [_c('v-icon',[_vm._v("mdi-account-group")])]},proxy:true}])},'d-toolbar-card',_vm.$attrs,false),[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-5",attrs:{"cols":"12"}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("Ausgewählte Eskalationsgruppen")))])]),(!this.valueProp.length)?_c('v-alert',{staticClass:"body-2 mt-2 mb-0",attrs:{"type":"error","elevation":"0","text":"","dense":"","colored-border":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("Es sind keine Eskalationsgruppen ausgewählt!"))+" ")]):_vm._e(),_vm._l((_vm.valueProp),function(escalationGroupId){return _c('div',{key:escalationGroupId},[_c('span',{staticClass:"pa-1",style:(_vm.isHighlighted(escalationGroupId)
                                ? _vm.$vuetify.theme.dark
                                    ? 'background: #ffa73c'
                                    : 'background: #fcf003'
                                : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.deleteEscalationGroup(escalationGroupId)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Eskalationsgruppe abwählen")))])]),_c('escalation-group-link',{staticClass:"mr-1",attrs:{"escalation-group-id":escalationGroupId}}),_c('small',[_vm._v(" ("),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-map-marker")]),_c('dealer-link',{attrs:{"dealer-id":_vm.getEscalationGroupById(escalationGroupId).dealerId}}),_vm._v(") ")],1)],1)])})],2),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("Eskalationsgruppen")))])]),(!_vm.unselectedLocalEscalationGroups.length)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Es sind keine Eskalationsgruppen für die Auswahl verfügbar."))+" ")]):_vm._e(),_vm._l((_vm.unselectedLocalEscalationGroups),function(escalationGroup){return _c('div',{key:escalationGroup.id},[_c('span',{staticClass:"pa-1",style:(_vm.isHighlighted(escalationGroup.id)
                                ? _vm.$vuetify.theme.dark
                                    ? 'background: #ffa73c'
                                    : 'background: #fcf003'
                                : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.addEscalationGroup(escalationGroup.id)}}},on),[_vm._v(" mdi-plus ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Eskalationsgruppe auswählen")))])]),_c('escalation-group-link',{attrs:{"escalation-group-id":escalationGroup.id}})],1)])})],2),(_vm.unselectedGlobalEscalationGroups.length)?_c('v-col',{staticClass:"pt-3 pb-0",attrs:{"cols":"12"}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("Eskalationsgruppen anderer Standorte")))])]),_vm._l((_vm.unselectedGlobalEscalationGroups),function(escalationGroup){return _c('div',{key:escalationGroup.id},[_c('span',{staticClass:"pa-1",style:(_vm.isHighlighted(escalationGroup.id)
                                ? _vm.$vuetify.theme.dark
                                    ? 'background: #ffa73c'
                                    : 'background: #fcf003'
                                : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.addEscalationGroup(escalationGroup.id)}}},on),[_vm._v(" mdi-plus ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Eskalationsgruppe auswählen")))])]),_c('escalation-group-link',{attrs:{"escalation-group-id":escalationGroup.id}}),_c('small',[_vm._v(" ("),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-map-marker")]),_c('dealer-link',{attrs:{"dealer-id":escalationGroup.dealerId}}),_vm._v(") ")],1)],1)])})],2):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }