
import { Bank } from "@/api/opportunities";
import { AcquisitionType } from "@/api/opportunityTeams";
import EnumField from "@/app/components/EnumField.vue";
import ExpansionPanel from "@/app/components/ExpansionPanel.vue";
import NumberField from "@/app/components/NumberField.vue";
import { OpportunityAssistantState } from "@/app/pages/opportunities/assistant/opportunityAssistant";
import { decimal, defaultCurrency, integer } from "@/app/validation";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        opportunityAssistantState: {
            type: Object as () => OpportunityAssistantState,
            required: true,
        },
    },

    data() {
        return {
            AcquisitionType,
            Bank,
            positiveIntegerRules: integer(0),
            currencyRules: decimal(defaultCurrency()),
        };
    },

    computed: {
        defaultCurrency(): string {
            return configStore.configuration.defaultCurrency;
        },
    },

    methods: {
        resetBankIfIncorrectAcquisitionType() {
            if (
                this.opportunityAssistantState.acquisitionType !== AcquisitionType.FINANCE &&
                this.opportunityAssistantState.acquisitionType !== AcquisitionType.LEASE
            ) {
                this.opportunityAssistantState.bank = null;
            }
        },
    },

    components: {
        EnumField,
        ExpansionPanel,
        NumberField,
    },
});
