import axios from "axios";

export interface Holiday {
    readonly date: string;
    readonly name: string;
}

interface HolidaysApi {
    holidays(startDate: string, endDate: string, countryCode: string, regionCode: string | null): Promise<Holiday[]>;
}

export const holidaysApi: HolidaysApi = {
    async holidays(startDate, endDate, countryCode, regionCode) {
        return (
            await axios.get("/api/holidays", {
                params: {
                    startDate,
                    endDate,
                    countryCode,
                    regionCode,
                },
            })
        ).data;
    },
};
