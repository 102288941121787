
import { DtmfMenuOption, InternalIncomingPhoneNumber } from "@/api/incomingPhoneNumbers";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        internalIncomingPhoneNumber: {
            type: Object as () => InternalIncomingPhoneNumber | null,
            default: null,
        },
        phoneNumbers: {
            type: Array as () => string[],
            required: true,
        },
        value: {
            type: Array as () => DtmfMenuOption[],
            required: true,
        },
    },

    data() {
        return {
            notEmpty,

            options: [] as DtmfMenuOption[],
        };
    },

    computed: {
        availableOptionIndexes(): number[] {
            const indexes = [...Array(10).keys()];
            indexes.push(indexes.shift()!);
            const usedIndexes = this.options.map((o) => o.index);
            return indexes.filter((i) => !usedIndexes.includes(i));
        },

        defaultCountry(): string {
            return configStore.configuration.defaultCountry;
        },

        dtmfMenuOptionsRules(): Function[] {
            return [
                (options: DtmfMenuOption[] | undefined | null) =>
                    !!(options ?? []).length || this.$t("Dieses Feld ist erforderlich."),
            ];
        },

        phoneNumberRules(): Function[] {
            return [
                ...((notEmpty().e164() as unknown) as Function[]),
                (phoneNumber: string | null | undefined) =>
                    !phoneNumber ||
                    phoneNumber !== this.internalIncomingPhoneNumber?.number ||
                    this.$t("Es kann nicht an die eingehende Rufnummer weitergeleitet werden."),
            ];
        },
    },

    methods: {
        addOption() {
            this.options.push({ index: this.availableOptionIndexes[0], phoneNumber: "" });
            this.updateValue();
        },

        removeOption(index: number) {
            this.options.splice(index, 1);
            this.updateValue();
        },

        updateValue() {
            this.$emit("input", cloneObject(this.options));
        },
    },

    watch: {
        value: {
            handler(newValue) {
                this.options = cloneObject(newValue);
            },
            immediate: true,
            deep: true,
        },
    },

    components: {
        PhoneNumberField,
    },
});
