
import { ContactData } from "@/api/contacts";
import { SmsTemplate, smsTemplatesApi } from "@/api/smsTemplates";
import { renderSmsPlaceholders } from "@/app/pages/smstemplates/smsPlaceholders";
import { UrlWithKey } from "@/app/placeholderUtils";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        buttonClass: {
            type: String,
            default: "",
        },
        contactData: {
            type: Object as () => ContactData | null,
            required: false,
        },
        fragment: {
            type: Boolean,
            default: false,
        },
        issueId: {
            type: String as () => string | null,
            default: null,
        },
        urls: {
            type: Array as () => UrlWithKey[],
            default: () => [],
        },
    },

    data() {
        return {
            loading: true,
            templates: [] as SmsTemplate[],
        };
    },

    methods: {
        loadPreviewContent(e: Event, template: SmsTemplate) {
            if (!e.target) {
                return;
            }

            const iframe = (e.target as HTMLIFrameElement).contentWindow?.document!;

            iframe.write(`<pre>${this.renderTemplate(template)}</pre>`);

            const style = iframe.createElement("style");
            style.innerHTML =
                "body { font-family: sans-serif; font-size: 10.5pt; background: white; } body > pre { white-space: pre-wrap; }";

            const head = iframe.head;
            head.insertBefore(style, head.firstChild);
        },

        async loadTemplates() {
            this.loading = true;
            this.templates = [];
            try {
                this.templates = await smsTemplatesApi.list(this.fragment);
                this.$emit("loaded");
            } finally {
                this.loading = false;
            }
        },

        renderTemplate(template: SmsTemplate) {
            const contactNameData = {
                salutation: this.contactData?.salutation || null,
                companyName: this.contactData?.companyName || null,
                namePrefix: this.contactData?.namePrefix || null,
                givenName: this.contactData?.givenName || null,
                middleName: this.contactData?.middleName || null,
                familyName: this.contactData?.familyName || null,
                nameSuffix: this.contactData?.nameSuffix || null,
            };

            return renderSmsPlaceholders(
                template.content,
                template.locale,
                contactNameData,
                userSession.profile!,
                this.urls,
                this.issueId
            );
        },

        selectTemplate(template: SmsTemplate) {
            this.$emit("select:template", { template, renderedContent: this.renderTemplate(template) });
        },
    },

    async mounted() {
        await this.loadTemplates();
    },
});
