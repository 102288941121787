import { render, staticRenderFns } from "./ExpansionPanel.vue?vue&type=template&id=bbddb64c&scoped=true&"
import script from "./ExpansionPanel.vue?vue&type=script&lang=ts&"
export * from "./ExpansionPanel.vue?vue&type=script&lang=ts&"
import style0 from "./ExpansionPanel.vue?vue&type=style&index=0&id=bbddb64c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbddb64c",
  null
  
)

export default component.exports