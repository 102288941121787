
import { caseSearchApi, CaseSearchOrder } from "@/api/caseSearch";
import { CaseStatus } from "@/api/cases";
import { contactSearchApi, ContactSearchRequest } from "@/api/contactSearch";
import { OpportunityStatus } from "@/api/opportunities";
import { opportunitySearchApi, OpportunitySearchOrder } from "@/api/opportunitySearch";
import { Permission } from "@/api/userSession";
import PhoneticSpellingTooltip from "@/app/pages/PhoneticSpellingTooltip.vue";
import { EMPTY_SEARCH_REQUEST as EMPTY_CASE_SEARCH_REQUEST } from "@/app/pages/cases/caseSearchUtils";
import {
    ContactSearchResultIssueSummary,
    ContactSearchResultIssueType,
    EMPTY_SEARCH_REQUEST,
    SearchResult,
} from "@/app/pages/contacts/contactSearchUtils";
import { EMPTY_SEARCH_REQUEST as EMPTY_OPPORTUNITY_SEARCH_REQUEST } from "@/app/pages/opportunities/opportunitySearchUtils";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

const ITEMS_LIMIT = 50;

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        phoneticSpellingLocale: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
        showPhoneticSpelling: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object as () => ContactSearchRequest,
            required: true,
        },
        withIssueSummaries: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            searchCounter: 1,
            searching: true,
            searchRequest: cloneObject(this.value),
            searchResult: {
                isSearchRequestEmpty: true,
                items: [],
                itemsLimit: ITEMS_LIMIT,
                totalSize: 0,
            } as SearchResult,
        };
    },

    computed: {
        canViewCases(): boolean {
            return (
                userSession.hasPermission(Permission.MANAGE_OWN_CASES) ||
                userSession.hasPermission(Permission.VIEW_ALL_CASES)
            );
        },

        canViewOpportunities(): boolean {
            return (
                userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES) ||
                userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES)
            );
        },

        isEmptySearchRequest(): boolean {
            return JSON.stringify(EMPTY_SEARCH_REQUEST) === JSON.stringify(this.searchRequest);
        },

        isPhoneticSpellingTooltipVisible(): boolean {
            return this.showPhoneticSpelling && !!this.phoneticSpellingLocale;
        },
    },

    methods: {
        async clearCompanyName() {
            this.searchRequest = { ...this.searchRequest, companyName: null };
            await this.handleSearchInput();
        },

        async clearEmailAddress() {
            this.searchRequest = { ...this.searchRequest, emailAddress: null };
            await this.handleSearchInput();
        },

        async clearFullName() {
            this.searchRequest = { ...this.searchRequest, fullName: null };
            await this.handleSearchInput();
        },

        async clearLicensePlate() {
            this.searchRequest = { ...this.searchRequest, licensePlate: null };
            await this.handleSearchInput();
        },

        async clearNumber() {
            this.searchRequest = { ...this.searchRequest, number: null };
            await this.handleSearchInput();
        },

        async clearVin() {
            this.searchRequest = { ...this.searchRequest, vin: null };
            await this.handleSearchInput();
        },

        async handleSearchInput() {
            const searchRequest: ContactSearchRequest = {
                dealerIds: [],
                contactSources: [],
                fullName: trimAndReturnNullIfEmpty(this.searchRequest.fullName),
                companyName: trimAndReturnNullIfEmpty(this.searchRequest.companyName),
                number: trimAndReturnNullIfEmpty(this.searchRequest.number),
                emailAddress: trimAndReturnNullIfEmpty(this.searchRequest.emailAddress),
                licensePlate: trimAndReturnNullIfEmpty(this.searchRequest.licensePlate),
                vin: trimAndReturnNullIfEmpty(this.searchRequest.vin),
                address: trimAndReturnNullIfEmpty(this.searchRequest.address),
                externalReference: trimAndReturnNullIfEmpty(this.searchRequest.externalReference),
                search: null,
                updatedFrom: null,
                updatedTo: null,
            };

            this.$emit("input", searchRequest);

            await this.loadItems();
        },

        async loadItems() {
            if (this.isEmptySearchRequest) {
                this.searchResult = {
                    isSearchRequestEmpty: true,
                    items: [],
                    itemsLimit: ITEMS_LIMIT,
                    totalSize: 0,
                };
                this.searchCounter += 1;
                this.searching = false;
                return;
            }

            const searchId = ++this.searchCounter;
            this.searching = true;
            try {
                const contactSearchResults = await contactSearchApi.search(
                    0,
                    ITEMS_LIMIT,
                    { ...this.searchRequest, externalReference: null },
                    searchId,
                    true
                );

                if (contactSearchResults.searchId !== this.searchCounter) {
                    return;
                }

                let searchResult: SearchResult = {
                    isSearchRequestEmpty: false,
                    items: contactSearchResults.results.map((contactSearchResult) => ({
                        contactSearchResult,
                        issueSummaries: [],
                    })),
                    itemsLimit: ITEMS_LIMIT,
                    totalSize: contactSearchResults.totalSize,
                };

                if (searchResult.items.length && this.withIssueSummaries) {
                    const [caseSearchResults, opportunitySearchResults] = await Promise.all([
                        this.canViewCases
                            ? caseSearchApi.search(
                                  0,
                                  searchResult.items.length * 10,
                                  {
                                      ...EMPTY_CASE_SEARCH_REQUEST,
                                      status: [CaseStatus.OPEN, CaseStatus.ASSIGNED, CaseStatus.POSTPONED],
                                      contactIds: searchResult.items.map((i) => i.contactSearchResult.id),
                                      sortBy: CaseSearchOrder.LAST_ACTIVITY_DESC,
                                  },
                                  searchId,
                                  !userSession.hasPermission(Permission.VIEW_ALL_CASES)
                              )
                            : Promise.resolve(null),
                        this.canViewOpportunities
                            ? opportunitySearchApi.search(
                                  0,
                                  searchResult.items.length * 10,
                                  {
                                      ...EMPTY_OPPORTUNITY_SEARCH_REQUEST,
                                      status: [
                                          OpportunityStatus.OPEN,
                                          OpportunityStatus.ASSIGNED,
                                          OpportunityStatus.POSTPONED,
                                      ],
                                      contactIds: searchResult.items.map((i) => i.contactSearchResult.id),
                                      sortBy: OpportunitySearchOrder.LAST_ACTIVITY_DESC,
                                  },
                                  searchId,
                                  !userSession.hasPermission(Permission.VIEW_ALL_OPPORTUNITIES)
                              )
                            : Promise.resolve(null),
                    ]);

                    const issueSummaries: ContactSearchResultIssueSummary[] = [
                        ...(caseSearchResults?.results ?? [])
                            .filter((r) => r.caseResult)
                            .map((r) => ({
                                issueType: ContactSearchResultIssueType.CASE,
                                id: r.caseId,
                                contactId: r.caseResult!.contact!.id,
                                contactVehicleId: r.caseResult!.contactVehicle?.id ?? null,
                                lastActivity: r.caseResult!.caseObj.lastActivity,
                            })),
                        ...(opportunitySearchResults?.results ?? [])
                            .filter((r) => r.opportunityResult)
                            .map((r) => ({
                                issueType: ContactSearchResultIssueType.OPPORTUNITY,
                                id: r.opportunityId,
                                contactId: r.opportunityResult!.contact!.id,
                                contactVehicleId: null,
                                lastActivity: r.opportunityResult!.opportunity.lastActivity,
                            })),
                    ];

                    searchResult = {
                        ...searchResult,
                        items: searchResult.items.map((i) => ({
                            contactSearchResult: i.contactSearchResult,
                            issueSummaries: issueSummaries.filter((s) => s.contactId === i.contactSearchResult.id),
                        })),
                    };
                }

                if (contactSearchResults.searchId === this.searchCounter) {
                    this.searchResult = searchResult;
                }
            } finally {
                if (searchId === this.searchCounter) {
                    this.searching = false;
                }
            }
        },
    },

    watch: {
        searching() {
            this.$emit("searching", this.searching);
        },

        searchResult() {
            this.$emit("search-result", this.searchResult);
        },

        async value() {
            if (JSON.stringify(this.searchRequest) !== JSON.stringify(this.value)) {
                this.searchRequest = cloneObject(this.value);
                try {
                    await this.loadItems();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            }
        },
    },

    async mounted() {
        this.$emit("searching", this.searching);
        await this.loadItems();
    },

    components: {
        PhoneticSpellingTooltip,
    },
});
