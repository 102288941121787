
import { inventoryApi } from "@/api/inventory";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { getVehicleClassIcon } from "@/app/inventoryUtils";
import Vue from "vue";

export interface InventoryVehicleImage {
    readonly inventoryVehicleId: string;
    readonly image: string;
}

export default Vue.extend({
    props: {
        images: {
            type: Array as () => InventoryVehicleImage[],
            required: true,
        },
        width: {
            type: String,
            required: true,
        },
        vehicleClass: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            imageCarouselVisible: false,
        };
    },

    computed: {
        imageUrls(): string[] {
            return this.images.map((i) => inventoryApi.generateVehicleImageLink(i.inventoryVehicleId, i.image));
        },

        placeholderIcon(): string {
            return getVehicleClassIcon(this.vehicleClass ?? null);
        },
    },

    components: {
        ImageCarousel,
    },
});
