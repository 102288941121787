
import { OutgoingCallFormCardState } from "./outgoingCallFormCard";
import { callSettingsApi } from "@/api/callSettings";
import { BadRequest } from "@/api/errors";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import { showInfo } from "@/app/messageUtil";
import { notEmpty, ValidationHelper } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        defaultCountry: {
            type: String,
            required: true,
        },
        onSubmit: {
            type: Function,
            required: true,
        },
        outboundNumbers: {
            type: Array as () => string[],
            default: () => [],
        },
        outgoingCallFormCardState: {
            type: Object as () => OutgoingCallFormCardState,
            required: true,
        },
        receiver: {
            type: String,
            required: false,
        },
        working: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        const validationHelper = new ValidationHelper();

        return {
            checkingInboundCallerId: true,
            numberRules: notEmpty().e164(),
            outboundNumberRules: notEmpty()
                .e164()
                .and(validationHelper, "outboundNumber"),
            validationHelper,
        };
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async submit() {
            if (!(this.$refs.callform as any).validate()) {
                return;
            }

            try {
                await this.onSubmit(this.outgoingCallFormCardState.outgoingCallForm);
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    this.$nextTick(() => {
                        throw e;
                    });
                } else {
                    this.validationHelper.update(e, this.$refs.callform);
                }
            }
        },
    },

    watch: {
        "outgoingCallFormCardState.initialized"() {
            if (!this.outgoingCallFormCardState.initialized) {
                this.outgoingCallFormCardState.initialize(this.receiver);
            }
        },

        receiver(val: string | null) {
            if (val) {
                this.outgoingCallFormCardState.outboundNumber = val;
            }
        },
    },

    async mounted() {
        try {
            if (!(await callSettingsApi.isInboundCallerIdConfigured())) {
                showInfo(
                    this.$t(
                        "Sie müssen die Anruferkennung für den Verbindungsaufbau in den allgemeinen Anrufeinstellungen hinterlegen, bevor Sie einen Anruf tätigen können."
                    ) as string
                );
                this.$emit("close");
                return;
            }
        } catch (e) {
            this.$emit("close");
            throw e;
        } finally {
            this.checkingInboundCallerId = false;
        }

        this.outgoingCallFormCardState.initialize(this.receiver);

        if (!this.outgoingCallFormCardState.callerId || !this.outgoingCallFormCardState.inboundNumber) {
            showInfo(
                this.$t(
                    "Sie müssen eine Anruferkennung und eine Anschlussnummer in Ihrem Profil hinterlegen, bevor Sie einen Anruf tätigen können."
                ) as string
            );
            this.$emit("close");
        }
    },

    components: {
        PhoneNumberField,
    },
});
