
import { canAddNote, getStatusColor } from "./incomingCallUtils";
import { IncomingCallResult, incomingCallsApi } from "@/api/incomingCalls";
import { isIncomingCallUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { editModeState } from "@/app/editModeUtils";
import UserLink from "@/app/pages/UserLink.vue";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        incomingCall: {
            type: Object as () => IncomingCallResult,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            incomingCallObj: this.incomingCall,
            noteFormVisible: false,
            note: "",
            savingNote: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            notEmpty,
            parseAndFormatNumber,
        };
    },

    computed: {
        canAddNote(): boolean {
            return canAddNote(this.incomingCallObj);
        },

        creationTimestamp(): string {
            return formatInstant(this.incomingCallObj.created, userSession.timeZone, userSession.locale, "S");
        },

        statusClass(): string {
            const color = getStatusColor(this.incomingCallObj.incomingCallStatus);

            return color ? `${color}--text` : "";
        },
    },

    methods: {
        showNoteForm() {
            if (!this.noteFormVisible) {
                this.note = "";
                this.noteFormVisible = true;
                editModeState.enter();
            }
        },

        hideNoteForm() {
            this.noteFormVisible = false;
            editModeState.leave();
        },

        async saveNote() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.savingNote = true;
            try {
                await incomingCallsApi.setNote(this.incomingCallObj.id, this.note);
            } finally {
                this.noteFormVisible = false;
                this.savingNote = false;
                editModeState.leave();
            }
        },
    },

    async mounted() {
        this.notificationHandler = notificationEventSource.addDataHandler(async (n) => {
            if (isIncomingCallUpdatedNotification(n)) {
                if (n.incomingCall.id === this.incomingCallObj.id) {
                    this.incomingCallObj = n.incomingCall;
                }
            }
        });
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        TimeSpan,
        UserLink,
    },
});
