import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface OutgoingCitnowVideoCreationForm {
    readonly vehicleIdentifier: string;
    readonly contactName: string | null;
    readonly contactEmailAddress: string | null;
    readonly contactPhoneNumber: string | null;
    readonly additionalInfo: string | null;
}

export interface OutgoingCitnowVideo {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly opportunityId: string;
    readonly initiatorUserId: string | null;
    readonly initiatorCtUserId: string | null;
    readonly lid: string;
    readonly requestId: string;
    readonly vehicleIdentifier: string;
    readonly contactName: string | null;
    readonly contactEmailAddress: string | null;
    readonly contactPhoneNumber: string | null;
    readonly additionalInfo: string | null;
    readonly videoEmbed: string | null;
    readonly videoPage: string | null;
    readonly videoThumb: string | null;
}

interface OutgoingCitnowVideosApi {
    getOutgoingCitnowVideosByOpportunity(opportunityId: string): Promise<OutgoingCitnowVideo[]>;
}

export const outgoingCitnowVideosApi: OutgoingCitnowVideosApi = {
    async getOutgoingCitnowVideosByOpportunity(opportunityId) {
        return cloneObject((await axios.get(`/api/outgoing-citnow-videos/opportunities/${opportunityId}`)).data);
    },
};
