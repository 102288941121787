
import { Contact } from "@/api/contacts";
import { IncomingSms, IncomingSmsAndContact, incomingSmsApi, ProcessingState } from "@/api/incomingSms";
import { renderContactCaption } from "@/app/contactUtils";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        incomingSmsAndContact: {
            type: Object as () => IncomingSmsAndContact,
            required: true,
        },
    },

    data() {
        return {
            ProcessingState,
            reactivating: false,
        };
    },

    computed: {
        contact(): Contact | null {
            return this.incomingSmsAndContact.contact;
        },

        contactCaption(): string | null {
            if (!this.contact) {
                return null;
            }

            return renderContactCaption(this.contact.contactData);
        },

        incomingSms(): IncomingSms {
            return this.incomingSmsAndContact.incomingSms;
        },

        isContactDeleted(): boolean {
            return !!this.contact?.deleted;
        },

        statusClass(): string | null {
            if (this.incomingSms.processingState === ProcessingState.PROCESSED) {
                return "success--text";
            } else if (this.incomingSms.processingState === ProcessingState.PROCESS_MANUALLY) {
                return "warning--text";
            } else if (this.incomingSms.processingState === ProcessingState.FILTERED) {
                return "error--text";
            } else {
                return null;
            }
        },
    },

    methods: {
        formatInstant(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        async reactivate() {
            this.reactivating = true;
            try {
                await incomingSmsApi.reactivate(this.incomingSms.id);
            } finally {
                this.reactivating = false;
            }
        },
    },
});
