
import { OfficeHours } from "@/api/officeHours";
import OfficeHoursCard from "@/app/pages/officehours/OfficeHoursCard.vue";
import { dealersStore } from "@/store/dealers";
import { officeHoursStore } from "@/store/officeHours";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerId: {
            type: String,
            required: true,
        },
    },

    computed: {
        dealerTimeZone(): string {
            return dealersStore.dealerById(this.dealerId)!.timeZone;
        },

        items(): OfficeHours[] {
            return officeHoursStore.getOfficeHoursByDealerId(this.dealerId).filter((o) => o.openingHours);
        },
    },

    components: {
        OfficeHoursCard,
    },
});
