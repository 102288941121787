
import ContactSearchDataTableRow from "./ContactSearchDataTableRow.vue";
import { contactSearchApi, ContactSearchRequest, ContactSearchResult } from "@/api/contactSearch";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { PickMutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        contactSearchRequest: {
            type: Object as () => ContactSearchRequest,
            required: true,
        },
        itemsPerPage: {
            type: Number,
            default: 100,
        },
    },

    data() {
        return {
            items: [] as ContactSearchResult[],
            loading: true,
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                pageSizes: [this.itemsPerPage, this.itemsPerPage * 2.5, this.itemsPerPage * 5, this.itemsPerPage * 10],
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchId: 1,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Kontakt"),
                    width: "20%",
                },
                {
                    text: this.$t("Name und Anschrift"),
                    width: "20%",
                },
                {
                    text: this.$t("Kontaktdaten"),
                    width: "20%",
                },
                {
                    text: this.$t("Fahrzeuge"),
                    width: "40%",
                },
            ];
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            const searchId = ++this.searchId;

            try {
                const contactSearchResults = await contactSearchApi.search(
                    (this.paging.page - 1) * this.paging.pageSize,
                    this.paging.pageSize,
                    this.contactSearchRequest,
                    searchId,
                    false
                );

                if (searchId === this.searchId) {
                    this.items = contactSearchResults.results;
                    this.paging.totalSize = contactSearchResults.totalSize;
                }
            } catch (e) {
                this.paging.page = 1;

                throw e;
            } finally {
                if (searchId === this.searchId) {
                    this.loading = false;
                }
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },
    },

    watch: {
        contactSearchRequest: {
            deep: true,
            async handler() {
                this.paging.page = 1;
                try {
                    await this.loadItems();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        ContactSearchDataTableRow,
        DataTable,
    },
});
