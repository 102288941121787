
import { getModelOptions } from "@/app/makeModelsUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        make: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
        value: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
    },

    computed: {
        modelOptions(): SelectOptions {
            if (!this.make) {
                return [];
            }

            return getModelOptions([this.make]);
        },
    },
});
