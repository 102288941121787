
import OutgoingEmailReceiverViewChip from "./OutgoingEmailReceiverViewChip.vue";
import { OutgoingEmailReceiverView } from "./outgoingEmailReceiver";
import {
    getAttachments,
    getBccReceivers,
    getCcReceivers,
    getCreator,
    getIssueId,
    getToReceivers,
} from "./outgoingEmailUtils";
import { Contact } from "@/api/contacts";
import { Forbidden, NotFound } from "@/api/errors";
import { isOutgoingEmailEventsUpdatedNotification, Notification, notificationEventSource } from "@/api/notifications";
import {
    IssueId,
    OutgoingEmail,
    OutgoingEmailAndContact,
    OutgoingEmailAttachment,
    outgoingEmailsApi,
} from "@/api/outgoingEmails";
import { Permission } from "@/api/userSession";
import ImageCarousel from "@/app/components/ImageCarousel.vue";
import { renderContactCaption } from "@/app/contactUtils";
import { writeHtmlToIframe } from "@/app/emailUtils";
import UserLink from "@/app/pages/UserLink.vue";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

enum HtmlBodyError {
    LOADING_HTML_BODY_FAILED = "LOADING_HTML_BODY_FAILED",
    HTML_BODY_EMPTY = "HTML_BODY_EMPTY",
    WRITING_HTML_BODY_TO_IFRAME_FAILED = "WRITING_HTML_BODY_TO_IFRAME_FAILED",
}

export default Vue.extend({
    data() {
        return {
            forbidden: false,
            htmlBody: null as string | null,
            htmlBodyError: null as HtmlBodyError | null,
            htmlBodyLoaded: false,
            image: "",
            loading: true,
            loadingHtmlBody: true,
            notificationHandler: null as ((n: Notification) => void) | null,
            outgoingEmailAndContact: null as OutgoingEmailAndContact | null,
            writing: false,
        };
    },

    computed: {
        attachments(): OutgoingEmailAttachment[] {
            if (!this.outgoingEmail) {
                return [];
            }

            return getAttachments(this.outgoingEmail);
        },

        bccReceivers(): OutgoingEmailReceiverView[] {
            if (!this.outgoingEmail) {
                return [];
            }

            return getBccReceivers(this.outgoingEmail);
        },

        canManageOutgoingEmail(): boolean {
            if (!this.outgoingEmail) {
                return false;
            }

            return userSession.hasPermission(Permission.MANAGE_OUTGOING_EMAILS);
        },

        ccReceivers(): OutgoingEmailReceiverView[] {
            if (!this.outgoingEmail) {
                return [];
            }

            return getCcReceivers(this.outgoingEmail);
        },

        contact(): Contact | null {
            return this.outgoingEmailAndContact?.contact || null;
        },

        creationTimestamp(): string | null {
            if (!this.outgoingEmail) {
                return null;
            }

            return formatInstant(this.outgoingEmail.created, userSession.timeZone, userSession.locale, "S");
        },

        creator(): string | null {
            if (!this.outgoingEmail) {
                return null;
            }

            return getCreator(this.outgoingEmail);
        },

        htmlBodyErrorColor(): string | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return "error";
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return "info";
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return "error";
            } else {
                return null;
            }
        },

        htmlBodyErrorRetry(): Function | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return () => this.loadHtmlBody();
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return () => this.loadHtmlBody();
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return () => this.writeHtmlBodyToIframe();
            } else {
                return null;
            }
        },

        htmlBodyErrorRetrying(): boolean {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return this.loading;
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return this.loading;
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return this.writing;
            } else {
                return false;
            }
        },

        htmlBodyErrorText(): string | null {
            if (this.htmlBodyError === HtmlBodyError.LOADING_HTML_BODY_FAILED) {
                return this.$t("Es ist ein Fehler beim Laden der E-Mail aufgetreten.") as string;
            } else if (this.htmlBodyError === HtmlBodyError.HTML_BODY_EMPTY) {
                return this.$t("Der Inhalt der E-Mail wurde nicht gefunden.") as string;
            } else if (this.htmlBodyError === HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED) {
                return this.$t("Es ist ein Fehler beim Darstellen der E-Mail aufgetreten.") as string;
            } else {
                return null;
            }
        },

        issueId(): IssueId | null {
            if (!this.outgoingEmail) {
                return null;
            }

            return getIssueId(this.outgoingEmail);
        },

        outgoingEmail(): OutgoingEmail | null {
            return this.outgoingEmailAndContact?.outgoingEmail || null;
        },

        toReceivers(): OutgoingEmailReceiverView[] {
            if (!this.outgoingEmail) {
                return [];
            }

            return getToReceivers(this.outgoingEmail);
        },
    },

    methods: {
        getContactCaption(contact: Contact | null): string | null {
            if (!contact) {
                return null;
            }

            return renderContactCaption(contact.contactData);
        },

        async loadHtmlBody() {
            if (!this.outgoingEmail || this.htmlBody !== null) {
                return;
            }

            this.loadingHtmlBody = true;

            try {
                this.htmlBody = await outgoingEmailsApi.getHtmlBody(this.outgoingEmail.id, this.issueId!);
                this.htmlBodyError = !this.htmlBody ? HtmlBodyError.HTML_BODY_EMPTY : null;
            } catch (e) {
                this.htmlBodyError = HtmlBodyError.LOADING_HTML_BODY_FAILED;

                if (!(e instanceof NotFound)) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            } finally {
                this.loadingHtmlBody = false;
            }

            if (this.htmlBody !== null) {
                this.$nextTick(() => {
                    this.writeHtmlBodyToIframe();
                });
            }
        },

        isContactDeleted(contact: Contact | null): boolean {
            return !!contact?.deleted;
        },

        showAttachment(attachment: OutgoingEmailAttachment, download: boolean) {
            if (!this.outgoingEmail) {
                return;
            }

            const fname = attachment.filename.toLowerCase();

            if (!download && (fname.endsWith(".png") || fname.endsWith(".jpg") || fname.endsWith(".jpeg"))) {
                this.image = outgoingEmailsApi.generateAttachmentLink(this.outgoingEmail.id, attachment, this.issueId);
            } else {
                window.open(
                    outgoingEmailsApi.generateAttachmentLink(this.outgoingEmail.id, attachment, this.issueId, download)
                );
            }
        },

        writeHtmlBodyToIframe() {
            if (!this.htmlBody) {
                return;
            }

            this.writing = true;
            try {
                const success = writeHtmlToIframe(this.$refs.iframe as HTMLIFrameElement, this.htmlBody);

                this.htmlBodyError = !success ? HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED : null;
            } catch (e) {
                this.htmlBodyError = HtmlBodyError.WRITING_HTML_BODY_TO_IFRAME_FAILED;

                this.$nextTick(() => {
                    throw e;
                });
            } finally {
                this.writing = false;
            }
        },
    },

    async mounted() {
        const outgoingEmailId = this.$route.params.outgoingemailid;

        if (!outgoingEmailId) {
            this.loading = false;
            return;
        }

        try {
            this.outgoingEmailAndContact = await outgoingEmailsApi.getOutgoingEmailAndContact(outgoingEmailId);
        } catch (e) {
            if (!(e instanceof Forbidden)) {
                throw e;
            }
            this.forbidden = true;
        } finally {
            this.loading = false;
        }

        if (!this.outgoingEmail) {
            return;
        }

        this.notificationHandler = notificationEventSource.addDataHandler((n) => {
            if (isOutgoingEmailEventsUpdatedNotification(n) && n.id === this.outgoingEmail!.id) {
                this.outgoingEmailAndContact!.outgoingEmail.events.push(n.event);
            }
        });

        await this.loadHtmlBody();
    },

    beforeDestroy() {
        if (this.notificationHandler) {
            notificationEventSource.removeDataHandler(this.notificationHandler);
        }
    },

    components: {
        ImageCarousel,
        OutgoingEmailReceiverViewChip,
        UserLink,
    },
});
