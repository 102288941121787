import axios from "axios";

interface ReadReceiptSettingsApi {
    getAllHiddenUsers(): Promise<string[]>;
    addHiddenUser(userId: string): Promise<void>;
    deleteHiddenUser(userId: string): Promise<void>;
}

export const readReceiptSettingsApi: ReadReceiptSettingsApi = {
    async getAllHiddenUsers() {
        return (await axios.get(`/api/read-receipt-settings`)).data;
    },

    async addHiddenUser(userId) {
        await axios.post(`/api/read-receipt-settings`, null, {
            params: {
                userId,
            },
        });
    },

    async deleteHiddenUser(userId) {
        await axios.delete(`/api/read-receipt-settings`, {
            params: {
                userId,
            },
        });
    },
};
