
import { EmailAliasCreationForm, emailDomainsApi } from "@/api/emailDomains";
import { UserDirectoryEntry } from "@/api/users";
import { dealerOptions } from "@/app/dealerUtils";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        emailDomainId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            adding: false,
            form: {
                dealerIds: [],
                userIds: [],
            } as EmailAliasCreationForm,
            getFullName,
        };
    },

    computed: {
        dealerOptions(): SelectOptions {
            return dealerOptions();
        },

        userOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        usersToAddEmailAlias(): UserDirectoryEntry[] {
            return usersStore.users
                .filter((u) => !this.form.dealerIds.length || this.form.dealerIds.includes(u.mainDealerId))
                .filter((u) => !this.form.userIds.length || this.form.userIds.includes(u.id))
                .sort((a, b) => getFullName(a).localeCompare(getFullName(b), userSession.locale));
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        getDealerNameById(dealerId: string): string | null {
            return dealersStore.dealerById(dealerId)?.name ?? null;
        },

        async submitForm() {
            this.adding = true;

            try {
                await emailDomainsApi.addEmailAliases(this.emailDomainId, this.form);
                this.$emit("save");
            } finally {
                this.adding = false;
            }
        },
    },
});
