
import CaseCreationDialog from "./CaseCreationDialog.vue";
import { Videochat, VideochatAccessToken, videochatApi } from "@/api/videochats";
import JitsiClient from "@/app/components/JitsiClient.vue";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loadingVideochat: true,
            videochatId: "",
            videochat: null as Videochat | null,
            videochatAccessToken: null as VideochatAccessToken | null,
            closedVideochat: false,
            ringing: false,
            showDialog: false,
        };
    },

    methods: {
        async joined(displayName: string) {
            await videochatApi.addJoinEvent(this.videochatId, displayName);
        },

        left() {
            this.closedVideochat = true;
            this.ringing = false;
        },

        rejoin() {
            this.closedVideochat = false;
        },

        participantJoined() {
            this.ringing = false;
        },
    },

    async mounted() {
        try {
            this.videochatId = this.$route.params.videochatid;
            this.videochat = await videochatApi.getById(this.videochatId);
            if (this.videochat.joinEvents.length <= 1 && !!this.$route.query.groupId) {
                this.ringing = true;
            }
            this.videochatAccessToken = await videochatApi.generateAccessToken(this.videochatId);
        } catch (e) {
            // ignore
        } finally {
            this.loadingVideochat = false;
        }

        setTimeout(() => {
            if (this.ringing) {
                this.showDialog = true;
                this.ringing = false;
            }
        }, 30000);
    },

    components: {
        JitsiClient,
        CaseCreationDialog,
    },
});
