import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum Climatisation {
    NO_CLIMATISATION = "NO_CLIMATISATION",
    MANUAL_CLIMATISATION = "MANUAL_CLIMATISATION",
    AUTOMATIC_CLIMATISATION = "AUTOMATIC_CLIMATISATION",
    AUTOMATIC_CLIMATISATION_2_ZONES = "AUTOMATIC_CLIMATISATION_2_ZONES",
    AUTOMATIC_CLIMATISATION_3_ZONES = "AUTOMATIC_CLIMATISATION_3_ZONES",
    AUTOMATIC_CLIMATISATION_4_ZONES = "AUTOMATIC_CLIMATISATION_4_ZONES",
    AUTOMATIC_CLIMATISATION_5_ZONES = "AUTOMATIC_CLIMATISATION_5_ZONES",
}

export enum ExteriorColor {
    CHARCOAL = "CHARCOAL",
    BRONZE = " BRONZE",
    BLACK = "BLACK",
    GREY = "GREY",
    BEIGE = "BEIGE",
    BROWN = "BROWN",
    RED = "RED",
    GREEN = "GREEN",
    BLUE = "BLUE",
    PURPLE = "PURPLE",
    GOLD = "GOLD",
    WHITE = "WHITE",
    ORANGE = "ORANGE",
    SILVER = "SILVER",
    YELLOW = "YELLOW",
}

export enum InteriorColor {
    BLACK = "BLACK",
    GREY = "GREY",
    BEIGE = "BEIGE",
    BROWN = "BROWN",
    OTHER_INTERIOR_COLOR = "OTHER_INTERIOR_COLOR",
}

export enum InteriorType {
    LEATHER = "LEATHER",
    PARTIAL_LEATHER = "PARTIAL_LEATHER",
    FABRIC = "FABRIC",
    VELOUR = "VELOUR",
    ALCANTARA = "ACANTARA",
    OTHER_INTERIOR_TYPE = "OTHER_INTERIOR_TYPE",
}

export enum Fuel {
    PETROL = "PETROL",
    DIESEL = "DIESEL",
    LPG = "LPG",
    CNG = "CNG",
    GAS = "GAS",
    ELECTRICITY = "ELECTRICITY",
    HYBRID = "HYBRID",
    HYDROGENIUM = "HYDROGENIUM",
    ETHANOL = "ETHANOL",
    HYBRID_DIESEL = "HYBRID_DIESEL",
    HYBRID_ALL = "HYBRID_ALL",
    OTHER = "OTHER",
}

export enum Airbag {
    DRIVER_AIRBAG = "DRIVER_AIRBAG",
    FRONT_AIRBAGS = "FRONT_AIRBAGS",
    FRONT_AND_SIDE_AIRBAGS = "FRONT_AND_SIDE_AIRBAGS",
    FRONT_AND_SIDE_AND_MORE_AIRBAGS = "FRONT_AND_SIDE_AND_MORE_AIRBAGS",
}

export enum Radio {
    TUNER = "TUNER",
    DAB_RADIO = "DAB_RADIO",
}

export enum SpeedControl {
    CRUISE_CONTROL = "CRUISE_CONTROL",
    ADAPTIVE_CRUISE_CONTROL = "ADAPTIVE_CRUISE_CONTROL",
}

export enum UsageType {
    USED = "USED",
    NEW = "NEW",
    PRE_REGISTRATION = "PRE_REGISTRATION",
    EMPLOYEES_CAR = "EMPLOYEES_CAR",
    CLASSIC = "CLASSIC",
    DEMONSTRATION = "DEMONSTRATION",
}

export enum BendingLightsType {
    BENDING_LIGHTS = "BENDING_LIGHTS",
    ADAPTIVE_BENDING_LIGHTS = "ADAPTIVE_BENDING_LIGHTS",
}

export enum DaytimeRunningLamps {
    DAYTIME_RUNNING_LIGHTS = "DAYTIME_RUNNING_LIGHTS",
    LED_RUNNING_LIGHTS = "LED_RUNNING_LIGHTS",
}

export enum HeadlightType {
    XENON_HEADLIGHTS = "XENON_HEADLIGHTS",
    BI_XENON_HEADLIGHTS = "BI_XENON_HEADLIGHTS",
    LED_HEADLIGHTS = "LED_HEADLIGHTS",
    LASER_HEADLIGHTS = "LASER_HEADLIGHTS",
}
export enum ParkingAssistant {
    FRONT_SENSORS = "FRONT_SENSORS",
    REAR_SENSORS = "REAR_SENSORS",
    REAR_VIEW_CAM = "REAR_VIEW_CAM",
    AUTOMATIC_PARKING = "AUTOMATIC_PARKING",
    CAM_360_DEGREES = "CAM_360_DEGREES",
}
export enum ConsumptionUnit {
    LITER_PER_100_KM = "LITER_PER_100_KM",
    CUBIC_METER_PER_100_KM = "CUBIC_METER_PER_100_KM",
    KILOGRAMM_PER_100_KM = "KILOGRAMM_PER_100_KM",
    KILOWATT_HOUR_PER_100_KM = "KILOWATT_HOUR_PER_100_KM",
}

export enum EmissionClass {
    EURO1 = "EURO1",
    EURO2 = "EURO2",
    EURO3 = "EURO3",
    EURO4 = "EURO4",
    EURO5 = "EURO5",
    EURO6 = "EURO6",
    EURO6B = "EURO6B",
    EURO6C = "EURO6C",
    EURO6D_EVAP = "EURO6D_EVAP",
    EURO6D_TEMP = "EURO6D_TEMP",
    EURO6D = "EURO6D",
}

export enum EmissionSticker {
    EMISSIONSSTICKER_GREEN = "EMISSIONSSTICKER_GREEN",
    EMISSIONSSTICKER_YELLOW = "EMISSIONSSTICKER_YELLOW",
    EMISSIONSSTICKER_RED = "EMISSIONSSTICKER_RED",
    EMISSIONSSTICKER_NONE = "EMISSIONSSTICKER_NONE",
}

export enum Gearbox {
    MANUAL_GEAR = "MANUAL_GEAR",
    SEMIAUTOMATIC_GEAR = "SEMIAUTOMATIC_GEAR",
    AUTOMATIC_GEAR = "AUTOMATIC_GEAR",
}

export enum Battery {
    BATTERY_RENTED = "BATTERY_RENTED",
    BATTERY_PURCHASED = "BATTERY_PURCHASED",
    BATTERY_MISSING = "BATTERY_MISSING",
}

export enum Drive {
    FRONT_DRIVE = "FRONT_DRIVE",
    REAR_DRIVE = "REAR_DRIVE",
    ALL_WHEEL_DRIVE = "ALL_WHEEL_DRIVE",
    NO_ALL_WHEEL_DRIVE = "NO_ALL_WHEEL_DRIVE",
}

export enum WheelFormula {
    WHEEL_DRIVE_FOUR_TWO = "WHEEL_DRIVE_4x2",
    WHEEL_DRIVE_FOUR_FOUR = "WHEEL_DRIVE_4x4",
    WHEEL_DRIVE_SIX_TWO = "WHEEL_DRIVE_6x2",
    WHEEL_DRIVE_SIX_FOUR = "WHEEL_DRIVE_6x2",
    WHEEL_DRIVE_SIX_SIX = "WHEEL_DRIVE_6x6",
    WHEEL_DRIVE_EIGHT_FOUR = "WHEEL_DRIVE_8x4",
    WHEEL_DRIVE_EIGHT_SIX = "WHEEL_DRIVE_8x6",
    WHEEL_DRIVE_EIGHT_EIGHT = "WHEEL_DRIVE_8x8",
}

export enum EnergyEfficiencyClass {
    A_PLUS_PLUS_PLUS = "A_PLUS_PLUS_PLUS",
    A_PLUS_PLUS = "A_PLUS_PLUS",
    A_PLUS = "A_PLUS",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
}

export enum EnvkvPetrolType {
    SUPER_PLUS = "SUPER_PLUS",
    SUPER = "SUPER",
    NORMAL = "NORMAL",
}

export enum DrivingCab {
    DOUBLE_CABIN = "DOUBLE_CABIN",
    SLEEPING_BERTH = "SLEEPING_BERTH",
    LOCAL_TRAFFIC = "LOCAL_TRAFFIC",
    LONG_DISTANCE_TRAFFIC = "LONG_DISTANCE_TRAFFIC",
}

export enum DrivingMode {
    FULLY_SUSPENDED_DRIVE = "FULLY_SUSPENDED_DRIVE",
    CHAIN_DRIVE = "CHAIN_DRIVE",
    BELT_DRIVE = "BELT_DRIVE",
}

export enum BreakDownService {
    SPARE_WHEEL = "SPARE_WHEEL",
    EMERGENCY_WHEEL = "EMERGENCY_WHEEL",
    REPAIR_KIT = "REPAIR_KIT",
}

export enum HydraulicInstallation {
    TIPPER_HYDRAULIC_INSTALLATION = "TIPPER_HYDRAULIC_INSTALLATION",
    PUSH_FLOOR_HYDRAULIC_INSTALLATION = "PUSH_FLOOR_HYDRAULIC_INSTALLATION",
    TANKER_HYDRAULIC_INSTALLATION = "TANKER_HYDRAULIC_INSTALLATION",
    OTHER = "OTHER",
}

export enum SlidingDoorType {
    SLIDING_DOOR_RIGHT = "SLIDING_DOOR_RIGHT",
    SLIDING_DOOR_LEFT = "SLIDING_DOOR_LEFT",
    SLIDING_DOOR_BOTH_SIDED = "SLIDING_DOOR_BOTH_SIDED",
    SLIDING_DOOR_ANY = "SLIDING_DOOR_ANY",
}

export enum TrailerCouplingType {
    TRAILER_COUPLING_FIX = "TRAILER_COUPLING_FIX",
    TRAILER_COUPLING_SWIVELING = "TRAILER_COUPLING_SWIVELING",
    TRAILER_COUPLING_DETACHABLE = "TRAILER_COUPLING_DETACHABLE",
    TRAILER_COUPLING_ANY = "TRAILER_COUPLING_ANY",
}

export enum UsedCarSeal {
    ASTON_MARTIN = "ASTON_MARTIN", // Aston Martin Timeless
    AUDI = "AUDI", // Audi Gebrauchtwagen:plus
    BENTLEY = "BENTLEY", // Bentley PRE-OWNED
    BMW = "BMW", // BMW Premium Selection
    CITROEN = "CITROEN", // CITROËN select
    DEKRA = "DEKRA",
    DS_CERTIFIED = "DS_CERTIFIED", // DS Certified
    DS_ZERTIFIZIERTE_GEBRAUCHTWAGEN = "DS_ZERTIFIZIERTE_GEBRAUCHTWAGEN", // DS Zertifizierte Gebrauchtwagen
    FCA = "FCA", // Alfa Romeo & Jeep SELECTED4U
    FERRARI = "FERRARI", // Ferrari Approved
    HYUNDAI_PROMISE = "HYUNDAI_PROMISE", // Hyundai Promise
    JAGUAR = "JAGUAR", // Jaguar APPROVED
    LANDROVER = "LANDROVER", // Land Rover APPROVED
    MASERATI = "MASERATI", // Maserati Approved
    MERCEDES = "MERCEDES", // Mercedes-Benz Junge Sterne
    MERCEDES_TRANSPORTER = "MERCEDES_TRANSPORTER", // Mercedes-Benz Junge Sterne Transporter
    MINI = "MINI", // MINI Gebrauchtwagen Next
    MITSUBISHI = "MITSUBISHI",
    NISSAN = "NISSAN", // NISSANCOLLECTION
    OPEL = "OPEL", // OPEL ZERTIFIZIERTE GEBRAUCHTWAGEN
    PEUGEOT = "PEUGEOT", // PEUGEOT Qualitäts-Gebrauchtwagen
    PORSCHE = "PORSCHE", // Porsche Approved
    RENAULT = "RENAULT", // Renault 7 Sterne Gebrauchtwagen
    SEAT = "SEAT", // SEAT "Das Weltauto"
    SKODA = "SKODA", // ŠKODA Plus
    SMART = "SMART", // jung@smart
    TOYOTA = "TOYOTA",
    VOLVO = "VOLVO", // VOLVO SELEKT
    VW = "VW", // Volkswagen "Das WeltAuto"
    VW_TRADEPORT = "VW_TRADEPORT", // Volkswagen TradePort
}

export enum Warranty {
    NO_ADDITIONAL_WARRANTY = "NO_ADDITIONAL_WARRANTY",
    WARRANTY_OTHER = "WARRANTY_OTHER",
    WARRANTY_1_YEAR = "WARRANTY_1_YEAR",
    WARRANTY_2_YEARS = "WARRANTY_2_YEAR",
    WARRANTY_3_YEARS = "WARRANTY_3_YEAR",
    WARRANTY_4_YEARS = "WARRANTY_4_YEAR",
    WARRANTY_5_YEAR = "WARRANTY_5_YEAR",
}

export enum Feature {
    "abs" = "abs",
    "airSuspension" = "airSuspension",
    "alloyWheels" = "alloyWheels",
    "automaticRainSensor" = "automaticRainSensor",
    "auxiliaryHeating" = "auxiliaryHeating",
    "awning" = "awning",
    "bed" = "bed",
    "bluetooth" = "bluetooth",
    "box" = "box",
    "bss" = "bss",
    "bunkBed" = "bunkBed",
    "cabin" = "cabin",
    "cdMultichanger" = "cdMultichanger",
    "cdPlayer" = "cdPlayer",
    "centralLocking" = "centralLocking",
    "centralLubricantApplication" = "centralLubricantApplication",
    "circularSeatingArrangement" = "circularSeatingArrangement",
    "coldstore" = "coldstore",
    "compressor" = "compressor",
    "crane" = "crane",
    "disabledAccessible" = "disabledAccessible",
    "disabledConversion" = "disabledConversion",
    "discBrake" = "discBrake",
    "divider" = "divider",
    "driversSleepingCompartment" = "driversSleepingCompartment",
    "ebs" = "ebs",
    "electricAdjustableSeats" = "electricAdjustableSeats",
    "electricExteriorMirrors" = "electricExteriorMirrors",
    "electricHeatedSeats" = "electricHeatedSeats",
    "electricStarter" = "electricStarter",
    "electricWindows" = "electricWindows",
    "esp" = "esp",
    "frontFogLights" = "frontFogLights",
    "frontHydraulics" = "frontHydraulics",
    "frontJack" = "frontJack",
    "fullFairing" = "fullFairing",
    "handsFreePhoneSystem" = "handsFreePhoneSystem",
    "headUpDisplay" = "headUpDisplay",
    "immobilizer" = "immobilizer",
    "isofix" = "isofix",
    "kickstarter" = "kickstarter",
    "kitchen" = "kitchen",
    "lightSensor" = "lightSensor",
    "middleSeatingArrangement" = "middleSeatingArrangement",
    "multifunctionalWheel" = "multifunctionalWheel",
    "municipal" = "municipal",
    "navigationSystem" = "navigationSystem",
    "onBoardComputer" = "onBoardComputer",
    "panoramicGlassRoof" = "panoramicGlassRoof",
    "powerAssistedSteering" = "powerAssistedSteering",
    "protectionRoof" = "protectionRoof",
    "quickChangeAttachment" = "quickChangeAttachment",
    "rearGarage" = "rearGarage",
    "retarder" = "retarder",
    "roadLicence" = "roadLicence",
    "rollOverBar" = "rollOverBar",
    "roofRails" = "roofRails",
    "secondaryAirConditioning" = "secondaryAirConditioning",
    "sepShower" = "sepShower",
    "sideSeatingArrangement" = "sideSeatingArrangement",
    "skiBag" = "skiBag",
    "sleepSeats" = "sleepSeats",
    "solarEnergySystem" = "solarEnergySystem",
    "sportPackage" = "sportPackage",
    "sportSeats" = "sportSeats",
    "sunroof" = "sunroof",
    "superSingleWheels" = "superSingleWheels",
    "tailLift" = "tailLift",
    "tractionControlSystem" = "tractionControlSystem",
    "tv" = "tv",
    "ureaTankAdBlue" = "ureaTankAdBlue",
    "wc" = "wc",
    "windshield" = "windshield",
    "alarmSystem" = "alarmSystem",
    "armRest" = "armRest",
    "heatedWindshield" = "heatedWindshield",
    "heatedSteeringWheel" = "heatedSteeringWheel",
    "hillStartAssist" = "hillStartAssist",
    "electricTailgate" = "electricTailgate",
    "leatherSteeringWheel" = "leatherSteeringWheel",
    "lumbarSupport" = "lumbarSupport",
    "massageSeats" = "massageSeats",
    "fatigueWarningSystem" = "fatigueWarningSystem",
    "nightVisionAssist" = "nightVisionAssist",
    "emergencyCallSystem" = "emergencyCallSystem",
    "tirePressureMonitoring" = "tirePressureMonitoring",
    "paddleShifters" = "paddleShifters",
    "electricHeatedRearSeats" = "electricHeatedRearSeats",
    "soundSystem" = "soundSystem",
    "voiceControl" = "voiceControl",
    "touchscreen" = "touchscreen",
    "usb" = "usb",
    "trafficSignRecognition" = "trafficSignRecognition",
    "highBeamAssist" = "highBeamAssist",
    "summerTires" = "summerTires",
    "winterTires" = "winterTires",
    "allSeasonTires" = "allSeasonTires",
    "steelWheels" = "steelWheels",
    "glareFreeHighBeam" = "glareFreeHighBeam",
    "headlightWasherSystem" = "headlightWasherSystem",
    "winterPackage" = "winterPackage",
    "smokersPackage" = "smokersPackage",
    "distanceWarningSystem" = "distanceWarningSystem",
    "electricBackseatAdjustment" = "electricBackseatAdjustment",
    "ambientLighting" = "ambientLighting",
    "wifiHotspot" = "wifiHotspot",
    "carplay" = "carplay",
    "androidAuto" = "androidAuto",
    "digitalCockpit" = "digitalCockpit",
    "passengerSeatIsofixPoint" = "passengerSeatIsofixPoint",
    "wirelessCharging" = "wirelessCharging",
    "integratedMusicStreaming" = "integratedMusicStreaming",
    "dimmingInteriorMirror" = "dimmingInteriorMirror",
    "foldFlatPassengerSeat" = "foldFlatPassengerSeat",
    "cargoBarrier" = "cargoBarrier",
    "speedLimiter" = "speedLimiter",
    "rangeExtender" = "rangeExtender",
    "collisionAvoidance" = "collisionAvoidance",
    "keylessEntry" = "keylessEntry",
    "ventilatedSeats" = "ventilatedSeats",
    "laneDepartureWarning" = "laneDepartureWarning",
    "blindSpotMonitor" = "blindSpotMonitor",
}

export enum PriceType {
    FIXED = "FIXED",
    ON_REQUEST = "ON_REQUEST",
    NEGOTIABLE = "NEGOTIABLE",
}

export enum NominalInterestRateType {
    BOUND = "BOUND",
    VARIABLE = "VARIABLE",
    COMBINED = "COMBINED",
}

export enum LeasingAudience {
    PRIVATE = "PRIVATE",
    BUSINESS = "BUSINESS",
    PRIVATE_AND_BUSINESS = "PRIVATE_AND_BUSINESS",
}

export interface Price {
    readonly firstSeen: Date;
    readonly type: PriceType | null;
    readonly vatRate: number | null;
    readonly dealerPriceNet: number | null;
    readonly dealerPriceGross: number | null;
    readonly consumerPriceNet: number | null;
    readonly consumerPriceGross: number | null;
}

export type Features = {
    readonly [K in keyof typeof Feature]: boolean | null;
};

export interface FinancingExample {
    readonly annualPercentageRate: number | null;
    readonly nominalInterestRate: number | null;
    readonly nominalInterestRateType: NominalInterestRateType | null;
    readonly firstInstallment: number | null;
    readonly monthlyInstallment: number | null;
    readonly finalInstallment: number | null;
    readonly paybackPeriod: number | null;
    readonly netLoanAmount: number | null;
    readonly grossLoanAmount: number | null;
    readonly closingCosts: number | null;
    readonly paymentProtectionInsurance: number | null;
    readonly bank: string | null;
    readonly conditions: string | null;
    readonly totalMileage: number | null;
}

export interface LeasingExample {
    readonly annualPercentageRate: number | null;
    readonly nominalInterestRate: number | null;
    readonly nominalInterestRateType: NominalInterestRateType | null;
    readonly firstInstallment: number | null;
    readonly monthlyInstallment: number | null;
    readonly finalInstallment: number | null;
    readonly paybackPeriod: number | null;
    readonly netLoanAmount: number | null;
    readonly totalAmount: number | null;
    readonly bank: string | null;
    readonly conditions: string | null;
    readonly totalMileage: number | null;
    readonly audience: LeasingAudience | null;
    readonly residualValue: number | null;
    readonly residualValueDiscount: number | null;
    readonly costAdditionalMileage: number | null;
    readonly costUnunsedMileage: number | null;
}

export interface InventoryVehicleData {
    readonly sourceUrn: string | null;
    readonly internalId: string | null;
    readonly vehicleClass: string | null;
    readonly category: string | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly subtitle: string | null;
    readonly highlight1: string | null;
    readonly highlight2: string | null;
    readonly highlight3: string | null;
    readonly modelYear: string | null;
    readonly usageType: UsageType | null;
    readonly firstRegistrationDay: number | null;
    readonly firstRegistrationMonth: number | null;
    readonly firstRegistrationYear: number | null;
    readonly mileage: number | null;
    readonly vin: string | null;
    readonly cubicCapacity: number | null;
    readonly power: number | null;
    readonly gearbox: Gearbox | null;
    readonly drive: Drive | null;
    readonly fuel: Fuel | null;
    readonly axles: number | null;
    readonly cylinders: number | null;
    readonly gears: number | null;
    readonly wheelFormula: WheelFormula | null;
    readonly hybridPlugin: boolean | null;
    readonly e10Enabled: boolean | null;
    readonly catalyticConverter: boolean | null;
    readonly particulateFilterDiesel: boolean | null;
    readonly biodieselConversion: boolean | null;
    readonly biodieselSuitable: boolean | null;
    readonly vegetableoilfuelConversion: boolean | null;
    readonly vegetableoilfuelSuitable: boolean | null;
    readonly performanceHandlingSystem: boolean | null;
    readonly startStopSystem: boolean | null;
    readonly envkvCompliant: boolean | null;
    readonly energyEfficiencyClass: EnergyEfficiencyClass | null;
    readonly co2Emission: number | null;
    readonly wltpCo2Emission: number | null;
    readonly consumptionUnit: ConsumptionUnit | null;
    readonly consumptionInner: number | null;
    readonly consumptionOuter: number | null;
    readonly consumptionCombined: number | null;
    readonly wltpConsumptionSlow: number | null;
    readonly wltpConsumptionMedium: number | null;
    readonly wltpConsumptionFast: number | null;
    readonly wltpConsumptionVeryFast: number | null;
    readonly wltpConsumptionCombined: number | null;
    readonly consumptionPowerCombined: number | null;
    readonly envkvPetrolType: EnvkvPetrolType | null;
    readonly emissionSticker: EmissionSticker | null;
    readonly emissionClass: EmissionClass | null;
    readonly exteriorColor: ExteriorColor | null;
    readonly manufacturerColorName: string | null;
    readonly metallic: boolean | null;
    readonly doors: number | null;
    readonly seats: number | null;
    readonly interiorColor: InteriorColor | null;
    readonly interiorType: InteriorType | null;
    readonly interiorDescription: string | null;
    readonly generalInspection: string | null;
    readonly exhaustInspection: string | null;
    readonly newHuAu: boolean | null;
    readonly constructionYear: number | null;
    readonly constructionMonth: number | null;
    readonly constructionDay: number | null;
    readonly numberOfPreviousOwners: number | null;
    readonly fullServiceHistory: boolean | null;
    readonly nonSmokerVehicle: boolean | null;
    readonly countryVersion: string | null;
    readonly damageUnrepaired: boolean | null;
    readonly accidentDamaged: boolean | null;
    readonly roadworthy: boolean | null;
    readonly damageByHail: boolean | null;
    readonly taxi: boolean | null;
    readonly companyCar: boolean | null;
    readonly features: Features;
    readonly airbag: Airbag | null;
    readonly climatisation: Climatisation | null;
    readonly drivingCab: DrivingCab | null;
    readonly drivingMode: DrivingMode | null;
    readonly hydraulicInstallation: HydraulicInstallation | null;
    readonly parkingAssistants: ParkingAssistant[];
    readonly radios: Radio[];
    readonly speedControl: SpeedControl | null;
    readonly daytimeRunningLamps: DaytimeRunningLamps | null;
    readonly slidingDoorType: SlidingDoorType | null;
    readonly headlightType: HeadlightType | null;
    readonly bendingLightsType: BendingLightsType | null;
    readonly breakdownService: BreakDownService | null;
    readonly battery: Battery | null;
    readonly trailerCouplingType: TrailerCouplingType | null;
    readonly wheelBase: number | null;
    readonly vehicleLength: number | null;
    readonly vehicleWidth: number | null;
    readonly vehicleHeight: number | null;
    readonly europalletStorageSpaces: number | null;
    readonly installationHeight: number | null;
    readonly emptyWeight: number | null;
    readonly licensedWeight: number | null;
    readonly liftingCapacity: number | null;
    readonly liftingHeight: number | null;
    readonly loadCapacity: number | null;
    readonly loadingSpaceLength: number | null;
    readonly loadingSpaceWidth: number | null;
    readonly loadingSpaceHeight: number | null;
    readonly numberOfBunks: number | null;
    readonly operatingHours: number | null;
    readonly batteryCapacity: number | null;
    readonly shippingVolume: number | null;
    readonly trimLine: string | null;
    readonly modelRange: string | null;
    readonly firstModelsProductionDate: string | null;
    readonly description: string | null;
    readonly images: string[];
    readonly videoUrl: string | null;
    readonly twinnerWidget: string | null;
    readonly hsn: string | null;
    readonly tsn: string | null;
    readonly schwackeCode: string | null;
    readonly deliveryDate: string | null;
    readonly deliveryPeriodDays: number | null;
    readonly usedCarSeal: UsedCarSeal | null;
    readonly onCustomerBehalf: boolean | null;
    readonly preOffer: boolean | null;
    readonly dealerHomepage: boolean | null;
    readonly closedDomain: boolean | null;
    readonly reserved: boolean | null;
    readonly export: boolean | null;
    readonly warranty: Warranty | null;
    readonly rentingPossible: boolean | null;
    readonly prices: Price[];
    readonly leasingExample: LeasingExample;
    readonly financingExample: FinancingExample;
    readonly activeRentalCar: boolean | null;
    readonly grossRetailPrice: number | null;
    readonly costNet: number | null;
    readonly repairCostNet: number | null;
    readonly includedGrossOverpassCost: number | null;
    readonly checkInDate: string | null;
    readonly licensePlate: string | null;
    readonly location: string | null;
    readonly keyName: string | null;
    readonly modelBase: string | null;
    readonly modelText: string | null;
    readonly modelKey: string | null;
    readonly lastLargeInspectionDate: string | null;
    readonly lastLargeInspectionMileage: number | null;
    readonly lastSmallInspectionDate: string | null;
    readonly lastSmallInspectionMileage: number | null;
    readonly lastOilChangeDate: string | null;
    readonly lastOilChangeMileage: number | null;
    readonly lastBreakChangeDate: string | null;
    readonly lastBreakChangeMileage: number | null;
}

export interface InventoryVehicle {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly deleted: Date | null;
    readonly dealerId: string;
    readonly data: InventoryVehicleData;
    readonly statuses: InventoryVehicleStatus[];
}

export interface InventoryVehicleStatus {
    readonly created: Date;
    readonly type: InventoryVehicleStatusType;
    readonly userId: string | null;
    readonly ctUserId: string | null;
    readonly comment: string | null;
}

export interface InventoryVehicleStatusForm {
    readonly type: InventoryVehicleStatusType;
    readonly comment: string | null;
}

export enum InventoryVehicleStatusType {
    AVAILABLE = "AVAILABLE",
    RESERVED = "RESERVED",
    SOLD = "SOLD",
}

interface InventoryApi {
    addStatus(id: string, inventoryVehicleStatusForm: InventoryVehicleStatusForm): Promise<void>;
    generateVehicleImageLink(vehicleId: string, imageHash: string, width?: number, height?: number): string;
    generateVehicleImageAsBase64Link(vehicleId: string, imageHash: string, width?: number, height?: number): string;
    generateVehicleExposeLink(vehicleId: string): string;
    getById(id: string): Promise<InventoryVehicle | null>;
    getByIds(ids: string[]): Promise<InventoryVehicle[]>;
}

export const inventoryApi: InventoryApi = {
    async addStatus(id, inventoryVehicleStatusForm) {
        await axios.patch(`/api/inventory/${id}`, inventoryVehicleStatusForm);
    },

    generateVehicleImageLink(vehicleId, imageHash, width?: number, height?: number) {
        let params = "";
        let paramsSeperator = "?";

        if (width) {
            params += `${paramsSeperator}width=${width}`;
            paramsSeperator = "&";
        }

        if (height) {
            params += `${paramsSeperator}height=${height}`;
            paramsSeperator = "&";
        }

        return `/api/inventory/${vehicleId}/images/${imageHash}.jpg${params}`;
    },

    generateVehicleImageAsBase64Link(vehicleId, imageHash, width?: number, height?: number) {
        let params = "";
        let paramsSeperator = "?";

        if (width) {
            params += `${paramsSeperator}width=${width}`;
            paramsSeperator = "&";
        }

        if (height) {
            params += `${paramsSeperator}height=${height}`;
            paramsSeperator = "&";
        }

        return `/api/inventory/${vehicleId}/images/${imageHash}.b64${params}`;
    },

    generateVehicleExposeLink(vehicleId) {
        return `/api/inventory/${vehicleId}.pdf`;
    },

    async getById(id: string): Promise<InventoryVehicle | null> {
        const result: InventoryVehicle = (await axios.get(`/api/inventory/${id}`)).data;

        if (!result) {
            return null;
        }

        return cloneObject(result);
    },

    async getByIds(ids: string[]): Promise<InventoryVehicle[]> {
        const result: InventoryVehicle[] = (await axios.get(`/api/inventory`, { params: { id: ids.join(",") } })).data;

        return result.map((v) => cloneObject(v));
    },
};
