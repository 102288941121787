import { BadRequest } from "@/api/errors";
import { InventoryVehicle } from "@/api/inventory";
import {
    Bank,
    Opportunity,
    OpportunityChannel,
    OpportunityInventoryVehicle,
    OpportunityResult,
    OpportunityStatus,
    OpportunityUrgency,
} from "@/api/opportunities";
import { Permission } from "@/api/userSession";
import { renderContactCaption } from "@/app/contactUtils";
import { $t } from "@/app/i18n";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { cloneObject } from "@/util/cloneUtils";

export interface OpportunityInventoryVehicleWithInventoryVehicle {
    readonly opportunityInventoryVehicle: OpportunityInventoryVehicle;
    readonly inventoryVehicle: InventoryVehicle | null;
}

export function getBankColor(bank: Bank | null): string | null {
    if (bank === Bank.SANTANDER) {
        return "#ec0000";
    } else {
        return null;
    }
}

export function getBankTextColor(bank: Bank | null): string | null {
    if (bank === Bank.SANTANDER) {
        return "#ffffff";
    } else {
        return null;
    }
}

export function getOpportunityChannelColor(opportunityChannel: OpportunityChannel): string | null {
    if (opportunityChannel === OpportunityChannel.WHATSAPP) {
        return "green";
    } else {
        return null;
    }
}

export function getOpportunityChannelIcon(opportunityChannel: OpportunityChannel): string | null {
    if (opportunityChannel === OpportunityChannel.WHATSAPP) {
        return "mdi-whatsapp";
    } else {
        return null;
    }
}

export function getOpportunityStatus(opportunityObj: Opportunity) {
    if (opportunityObj.closed) {
        return OpportunityStatus.CLOSED;
    }
    if (opportunityObj.postponedUntil) {
        return OpportunityStatus.POSTPONED;
    }
    if (opportunityObj.assigneeIds.length) {
        return OpportunityStatus.ASSIGNED;
    }
    return OpportunityStatus.OPEN;
}

function getVisibleFor(opportunityObj: Opportunity) {
    const userIds = cloneObject(opportunityObj.assigneeIds);

    const closed = !!opportunityObj.closed;
    for (const opportunityUser of opportunityObj.opportunityUsers) {
        if (
            (!closed && !!opportunityUser.visibilityBegin && !opportunityUser.visibilityEnd) ||
            opportunityUser.creator ||
            opportunityUser.closer
        ) {
            if (!userIds.find((u) => u === opportunityUser.userId)) {
                userIds.push(opportunityUser.userId);
            }
        }
    }

    return userIds;
}

export function isVisibleFor(opportunityObj: Opportunity, userId: string) {
    return getVisibleFor(opportunityObj).indexOf(userId) >= 0;
}

export function getContactDeleted(opportunityResult: OpportunityResult | null) {
    if (!opportunityResult) {
        return false;
    }

    return !!opportunityResult.contact?.deleted;
}

export function getContactCaption(opportunityResult: OpportunityResult | null) {
    if (!opportunityResult) {
        return null;
    }

    if (opportunityResult.contact) {
        return renderContactCaption(opportunityResult.contact.contactData) as string;
    }

    return null;
}

export function getSelectedReceiversCount(c: Opportunity | null) {
    if (!c) {
        return 0;
    }

    return c.opportunityIndividualReceivers.length + c.opportunityTeamReceivers.length;
}

export function getOpportunityStatusColor(
    opportunityStatus: OpportunityStatus,
    outcome?: boolean | null
): string | null {
    if (opportunityStatus === OpportunityStatus.CLOSED) {
        if (outcome) {
            return "success";
        } else {
            return null;
        }
    } else if (opportunityStatus === OpportunityStatus.POSTPONED) {
        return "info";
    } else if (opportunityStatus === OpportunityStatus.ASSIGNED) {
        return "warning";
    } else {
        return "error";
    }
}

export function getOpportunitiesCountColor(statuses: OpportunityStatus[]): string | null {
    const earliest = [
        OpportunityStatus.OPEN,
        OpportunityStatus.ASSIGNED,
        OpportunityStatus.POSTPONED,
        OpportunityStatus.CLOSED,
        ...(Object.keys(OpportunityStatus) as OpportunityStatus[]),
    ].find((status) => statuses.includes(status));

    return getOpportunityStatusColor(earliest ?? OpportunityStatus.OPEN);
}

export function getStatusColor(o: Opportunity | null): string | null {
    if (!o) {
        return getOpportunityStatusColor(OpportunityStatus.OPEN);
    }

    return getOpportunityStatusColor(getOpportunityStatus(o), o.outcome);
}

export function getStatusCaption(c: Opportunity | null) {
    if (!c) {
        return $t("unbekannt") as string;
    }

    if (getOpportunityStatus(c) === OpportunityStatus.CLOSED) {
        if (c.outcome!) {
            return $t("gewonnen") as string;
        } else {
            return $t("verloren") as string;
        }
    } else if (getOpportunityStatus(c) === OpportunityStatus.POSTPONED) {
        return $t("zurückgestellt") as string;
    } else if (getOpportunityStatus(c) === OpportunityStatus.ASSIGNED) {
        return $t("zugeteilt") as string;
    } else {
        return $t("offen") as string;
    }
}

export function isOpportunityActive(c: Opportunity | null) {
    if (!c) {
        return false;
    }

    const opportunityStatus = getOpportunityStatus(c);
    return opportunityStatus !== OpportunityStatus.CLOSED && opportunityStatus !== OpportunityStatus.POSTPONED;
}

export function getCreator(c: Opportunity | null) {
    if (!c) {
        return "";
    }
    if (c.creatorUserId) {
        const user = usersStore.getUserById(c.creatorUserId);
        if (!user) {
            return $t("Gelöschter Benutzer") as string;
        }
        return getFullName(user);
    }
    if (c.creatorCtUserId) {
        return $t("Externer Agent") as string;
    }
    return $t("System") as string;
}

export function getCloser(c: Opportunity | null) {
    if (!c) {
        return "";
    }
    if (c.closerUserId) {
        const user = usersStore.getUserById(c.closerUserId);
        if (!user) {
            return $t("Gelöschter Benutzer") as string;
        }
        return getFullName(user);
    }
    if (c.closerCtUserId) {
        return $t("Externer Agent") as string;
    }
    return $t("System") as string;
}

export function getAssigneeIdsSortedByUserFullName(c: Opportunity | null): string[] {
    if (!c) {
        return [];
    }

    return [...c.assigneeIds].sort((userIdA, userIdB) => {
        const userA = usersStore.getUserById(userIdA);
        const userB = usersStore.getUserById(userIdB);

        if (userA === userB) {
            return 0;
        } else if (!userA) {
            return 1;
        } else if (!userB) {
            return -1;
        }

        return getFullName(userA).localeCompare(getFullName(userB), userSession.locale);
    });
}

export function getUrgencyColor(o: Opportunity | null): string | null {
    if (!o) {
        return null;
    }

    if (o.urgency === OpportunityUrgency.HIGH) {
        return "error";
    } else if (o.urgency === OpportunityUrgency.PERSONAL_APPEARANCE) {
        return "black";
    } else {
        return null;
    }
}

export function getUrgencyTextColor(o: Opportunity | null): string | null {
    if (!o) {
        return null;
    }

    if (o.urgency === OpportunityUrgency.PERSONAL_APPEARANCE) {
        return "white";
    } else {
        return null;
    }
}

export function canManageOpportunity(opportunityObj: Opportunity): boolean {
    if (userSession.hasPermission(Permission.MANAGE_ALL_OPPORTUNITIES)) {
        return true;
    }

    return (
        userSession.hasPermission(Permission.MANAGE_OWN_OPPORTUNITIES) &&
        isVisibleFor(opportunityObj, userSession.userId!)
    );
}

export function canAddNote(opportunityObj: Opportunity): boolean {
    const opportunityStatus = getOpportunityStatus(opportunityObj);

    return canManageOpportunity(opportunityObj) || opportunityStatus !== OpportunityStatus.CLOSED;
}

export function canRequestCitnowVideo(opportunityObj: Opportunity): boolean {
    return canManageOpportunity(opportunityObj);
}

export function canSendEmail(opportunityObj: Opportunity): boolean {
    return canManageOpportunity(opportunityObj);
}

export function canSendSms(opportunityObj: Opportunity): boolean {
    return canManageOpportunity(opportunityObj);
}

export function canSendWhatsapp(opportunityObj: Opportunity): boolean {
    return canManageOpportunity(opportunityObj);
}

export function canStartCall(opportunityObj: Opportunity): boolean {
    return canManageOpportunity(opportunityObj);
}

export function isInvalidOpportunityStatusExceptionWithOpportunityStatus(
    e: BadRequest,
    opportunityStatus: OpportunityStatus
): boolean {
    if (!e.details.length) {
        return false;
    }

    const badRequestDetails = e.details[0];

    return (
        badRequestDetails.messageKey === "validation.invalid-opportunity-status" &&
        badRequestDetails.path === "opportunityStatus" &&
        badRequestDetails.rejectedValue === opportunityStatus
    );
}
