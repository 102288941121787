import axios from "axios";

export interface SmsSettings {
    defaultSender: string | null;
    catchAllUserSmsEscalationGroupId: string | null;
    manualUserSmsDispatching: boolean | null;
}

interface SmsSettingsApi {
    getSmsSettings(): Promise<SmsSettings>;
    editSmsSettings(smsSettings: SmsSettings): Promise<void>;
}

export const smsSettingsApi: SmsSettingsApi = {
    async getSmsSettings() {
        return (await axios.get("/api/sms-settings")).data;
    },

    async editSmsSettings(smsSettings) {
        await axios.put("/api/sms-settings", smsSettings);
    },
};
