
import OfficeHoursFormPresenceCard from "./OfficeHoursFormPresenceCard.vue";
import { OfficeHours, officeHoursApi, OfficeHoursEntry, OfficeHoursForm } from "@/api/officeHours";
import { showInfo } from "@/app/messageUtil";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDaysOfWeek } from "@/util/dateTimeUtils";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        officeHours: {
            type: Object as () => OfficeHours | null,
            required: false,
        },
    },

    data() {
        return {
            valid: true,
            isWorking: false,
            visiblePresenceCards: [] as string[],

            name: this.officeHours?.name || name,
            openingHours: this.officeHours ? this.officeHours.openingHours : true,
            entries: cloneObject(this.officeHours?.entries || []),
            nameRules: notEmpty().maxLength(300),
        };
    },

    computed: {
        daysOfWeek(): SelectOptions {
            return getDaysOfWeek(configStore.configuration.defaultLocale, userSession.locale);
        },
    },

    methods: {
        addEntry(officeHoursEntry: OfficeHoursEntry) {
            this.entries.push(officeHoursEntry);
            this.hidePresenceCard();
        },

        hidePresenceCard() {
            this.visiblePresenceCards = [];
        },

        showPresenceCard(dayOfWeek: string) {
            this.visiblePresenceCards = [];
            this.visiblePresenceCards.push(dayOfWeek);
        },

        deleteEntry(officeHoursEntry: OfficeHoursEntry) {
            for (let i = 0; i < this.entries.length; i++) {
                if (JSON.stringify(officeHoursEntry) === JSON.stringify(this.entries[i])) {
                    this.entries.splice(i, 1);
                    return;
                }
            }
        },

        getEntriesForDayOfWeek(officeHours: OfficeHours, dayOfWeek: number): OfficeHoursEntry[] {
            const result = [] as OfficeHoursEntry[];

            for (const officeHoursEntry of this.entries) {
                if (officeHoursEntry.dayOfWeek === dayOfWeek) {
                    result.push(officeHoursEntry);
                }
            }

            return result.sort((a, b) => a.from.localeCompare(b.from, userSession.locale));
        },

        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            if (this.visiblePresenceCards.length > 0) {
                showInfo(this.$t("Bitte schließen oder speichern Sie Ihre Änderung bevor Sie fortfahren.") as string);
                return;
            }

            this.isWorking = true;

            try {
                const form: OfficeHoursForm = {
                    name: this.name,
                    openingHours: this.openingHours,
                    entries: this.entries,
                };

                if (this.officeHours) {
                    await officeHoursApi.edit(this.officeHours.id, form);
                } else {
                    await officeHoursApi.add(userSession.dealerId!, form);
                }
            } finally {
                this.isWorking = false;
            }
            this.$emit("success");
        },

        close() {
            this.$emit("close");
        },
    },

    components: {
        OfficeHoursFormPresenceCard,
    },
});
