import { $t } from "./i18n";
import { showConfirm } from "./messageUtil";
import { IncomingEmail } from "@/api/incomingEmails";
import { OutgoingEmail, TransactionEmailHeader } from "@/api/outgoingEmails";

export interface NameAndEmailAddress {
    readonly name: string | null;
    readonly address: string;
}

interface ReplyToBaseEmailHeader {
    readonly name: string;
    readonly value: string;
}

export interface ReplyToBaseEmail {
    readonly id: string;
    readonly fromName: string | null;
    readonly fromAddress: string;
    readonly replyToName: string | null;
    readonly replyToAddress: string | null;
    readonly toReceivers: NameAndEmailAddress[];
    readonly ccReceivers: NameAndEmailAddress[];
    readonly bccReceivers: NameAndEmailAddress[];
    readonly subject: string | null;
    readonly htmlBodyForQuote: string;
    readonly headers: ReplyToBaseEmailHeader[];
}

function reverseAndJoin(references: string[], maxLength: number) {
    references = references.reverse();
    while (true) {
        const r = references.join(" ");
        if (r.length <= maxLength) {
            return r;
        }
        references = references.slice(1);
    }
}

export function getReplyHeaders(
    emails: (IncomingEmail | OutgoingEmail | ReplyToBaseEmail)[]
): TransactionEmailHeader[] {
    const references: string[] = [];

    for (const email of emails) {
        const messageId = email.headers.find((h) => h.name.toLowerCase() === "message-id")?.value;
        if (messageId) {
            // incoming email
            references.push(messageId);
        } else {
            // outgoing email
            const domain = email.fromAddress.split("@", 2)[1];
            references.push(`<${email.id}@${domain}>`);
        }
    }

    if (references.length) {
        return [
            {
                name: "In-Reply-To",
                value: references[0],
            },
            {
                name: "References",
                value: reverseAndJoin(references, 980),
            },
        ];
    }
    return [];
}

export function getReplySubject(subject: string): string {
    return `Re: ${subject.replace(/(re:\s*)|(fwd:\s*)/i, "")}`;
}

export function asQuote(html: string) {
    if (!html.trim()) {
        return "";
    }
    const doc = new DOMParser().parseFromString(html, "text/html");
    return `<p></p><blockquote style="border-left: 2px solid blue; margin-left: 1.5rem; padding-left: 1rem;">${doc.body.innerHTML}</blockquote>`;
}

export function writeHtmlToIframe(elem: HTMLIFrameElement | undefined, html: string): boolean {
    const iframe = elem?.contentDocument ? elem.contentDocument : elem?.contentWindow?.document;

    if (!iframe) {
        return false;
    }

    iframe.write(html);
    const style = iframe.createElement("style");
    style.innerHTML = "body { font-family: sans-serif; font-size: 10.5pt; background: white; }";
    const head = iframe.head;
    head.insertBefore(style, head.firstChild);
    const base = iframe.createElement("base");
    base.target = "_blank";
    head.insertBefore(base, head.firstChild);

    const body = iframe.body;

    if (!body) {
        return true;
    }

    if (body.clientHeight < body.scrollHeight) {
        elem!.height = `${Math.min(1000, body.scrollHeight + 30)}px`;
    }

    body.querySelectorAll("a").forEach((link) => {
        link.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();
            showConfirm(
                $t("Externer Link") as string,
                $t("Der angeklickte Link führt auf eine externe Seite. Möchten Sie fortfahren?") as string
            ).then((confirmed) => confirmed && (link.cloneNode(false) as HTMLAnchorElement).click());
        });
    });

    return true;
}

export function canReportSpam(email: IncomingEmail): boolean {
    if (email.user || email.processContentTrusted) {
        return false;
    }

    return !email.headers.find((h) => h.name.toLowerCase() === "list-unsubscribe");
}
