
import StatusDashboardTable, { StatusDashboardTableItem } from "./StatusDashboardTable.vue";
import StatusDashboardTile from "./StatusDashboardTile.vue";
import { CaseUrgency } from "@/api/cases";
import { Unauthorized } from "@/api/errors";
import {
    isCaseVisibilityNotification,
    isOpportunityVisibilityNotification,
    isTimestampNotification,
    isUserChangedNotification,
    Notification,
} from "@/api/notifications";
import { OpportunityUrgency } from "@/api/opportunities";
import { statusDashboardApi, StatusDashboardConfiguration, StatusDashboardResult } from "@/api/statusDashboard";
import { getSentimentIcon, getSentimentIconColor } from "@/app/sentimentUtils";
import { formatInstant } from "@/util/dateTimeUtils";
import { ReconnectingEventSource } from "@/util/eventSource";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            authorized: false,
            connecting: true,
            configuration: null as StatusDashboardConfiguration | null,
            connectionHandler: null as ((connected: boolean) => void) | null,
            loading: false,
            notificationHandler: null as ((n: Notification) => void) | null,
            now: new Date() as Date,
            nowAtLastReload: new Date() as Date,
            nowRefreshInterval: {} as number,
            online: false,
            result: null as StatusDashboardResult | null,
            streamEventSource: null as ReconnectingEventSource<Notification> | null,
        };
    },

    computed: {
        assignedCaseItems(): StatusDashboardTableItem[] {
            if (!this.result) {
                return [];
            }

            return this.result.assignedCaseResults.map((c) => ({
                id: c.caseObj.id,
                contact: c.contact,
                deltaTimeDate: c.caseObj.lastActivity,
                sentiment:
                    c.caseObj.sentiment !== null
                        ? {
                              icon: getSentimentIcon(c.caseObj.sentiment),
                              iconColor: getSentimentIconColor(c.caseObj.sentiment),
                          }
                        : null,
                prioritized: c.caseObj.urgency !== CaseUrgency.NORMAL,
            }));
        },

        assignedOpportunityItems(): StatusDashboardTableItem[] {
            if (!this.result) {
                return [];
            }

            return this.result.assignedOpportunityResults.map((o) => ({
                id: o.opportunity.id,
                contact: o.contact,
                deltaTimeDate: o.opportunity.lastActivity,
                sentiment:
                    o.opportunity.sentiment !== null
                        ? {
                              icon: getSentimentIcon(o.opportunity.sentiment),
                              iconColor: getSentimentIconColor(o.opportunity.sentiment),
                          }
                        : null,
                prioritized: o.opportunity.urgency !== OpportunityUrgency.NORMAL,
            }));
        },

        getStatusColor(): string {
            if (!this.online) {
                return "error";
            }

            return "success";
        },

        getStatusText() {
            if (!this.online) {
                return this.$t("offline");
            }

            return this.$t("online");
        },

        openCaseItems(): StatusDashboardTableItem[] {
            if (!this.result) {
                return [];
            }

            return this.result.openCaseResults.map((c) => ({
                id: c.caseObj.id,
                contact: c.contact,
                deltaTimeDate: c.caseObj.lastActivity,
                sentiment:
                    c.caseObj.sentiment !== null
                        ? {
                              icon: getSentimentIcon(c.caseObj.sentiment),
                              iconColor: getSentimentIconColor(c.caseObj.sentiment),
                          }
                        : null,
                prioritized: c.caseObj.urgency !== CaseUrgency.NORMAL,
            }));
        },

        openOpportunityItems(): StatusDashboardTableItem[] {
            if (!this.result) {
                return [];
            }

            return this.result.openOpportunityResults.map((o) => ({
                id: o.opportunity.id,
                contact: o.contact,
                deltaTimeDate: o.opportunity.lastActivity,
                sentiment:
                    o.opportunity.sentiment !== null
                        ? {
                              icon: getSentimentIcon(o.opportunity.sentiment),
                              iconColor: getSentimentIconColor(o.opportunity.sentiment),
                          }
                        : null,
                prioritized: o.opportunity.urgency !== OpportunityUrgency.NORMAL,
            }));
        },

        postponedCaseItems(): StatusDashboardTableItem[] {
            if (!this.result) {
                return [];
            }

            return this.result.postponedCaseResults.map((c) => ({
                id: c.caseObj.id,
                contact: c.contact,
                deltaTimeDate: c.caseObj.postponedUntil,
                sentiment:
                    c.caseObj.sentiment !== null
                        ? {
                              icon: getSentimentIcon(c.caseObj.sentiment),
                              iconColor: getSentimentIconColor(c.caseObj.sentiment),
                          }
                        : null,
                prioritized: c.caseObj.urgency !== CaseUrgency.NORMAL,
            }));
        },

        postponedOpportunityItems(): StatusDashboardTableItem[] {
            if (!this.result) {
                return [];
            }

            return this.result.postponedOpportunityResults.map((o) => ({
                id: o.opportunity.id,
                contact: o.contact,
                deltaTimeDate: o.opportunity.postponedUntil,
                sentiment:
                    o.opportunity.sentiment !== null
                        ? {
                              icon: getSentimentIcon(o.opportunity.sentiment),
                              iconColor: getSentimentIconColor(o.opportunity.sentiment),
                          }
                        : null,
                prioritized: o.opportunity.urgency !== OpportunityUrgency.NORMAL,
            }));
        },

        sdt() {
            return (this.$route.query.sdt || "") as string;
        },
    },

    methods: {
        async handleNotificationDispatcher(n: Notification) {
            if (isCaseVisibilityNotification(n)) {
                await this.refresh();
            } else if (isOpportunityVisibilityNotification(n)) {
                await this.refresh();
            } else if (isUserChangedNotification(n) && this.configuration && this.configuration.userId === n.user.id) {
                await this.loadConfiguration();
            } else if (isTimestampNotification(n)) {
                this.now = n.timestamp;
            }
        },

        async loadConfiguration() {
            try {
                this.connecting = true;
                this.configuration = await statusDashboardApi.getConfiguration(this.sdt);
            } catch (e) {
                if (e instanceof Unauthorized) {
                    this.reset();
                } else {
                    throw e;
                }
            } finally {
                this.authorized = !!this.configuration;
                this.connecting = false;
            }
        },

        async refresh() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            try {
                this.result = await statusDashboardApi.getResult(this.sdt);
            } catch (e) {
                if (e instanceof Unauthorized) {
                    this.reset();
                } else {
                    throw e;
                }
            } finally {
                this.nowAtLastReload = this.now;
                this.loading = false;
            }
        },

        renderInstant(instant: Date): string | null {
            if (!this.configuration) {
                return null;
            }

            return formatInstant(instant, this.configuration.timeZone, this.configuration.locale, "L");
        },

        reset() {
            this.authorized = false;
            this.configuration = null;
            this.connecting = false;
            this.loading = false;
            this.result = null;
        },
    },

    watch: {
        configuration() {
            if (!this.configuration) {
                return;
            }

            this.now = this.configuration.serverTime;
            this.$i18n.locale = this.$vuetify.lang.current = this.configuration.locale;
            this.$vuetify.theme.dark = this.configuration.darkmode;
        },
    },

    async created() {
        await this.loadConfiguration();

        if (!this.authorized) {
            return;
        }

        this.streamEventSource = statusDashboardApi.getNewStream(this.sdt);
        this.connectionHandler = this.streamEventSource.addConnectionHandler(async (connected) => {
            this.online = connected;

            if (connected) {
                await this.refresh();
            }
        });

        this.notificationHandler = this.streamEventSource.addDataHandler((n: Notification) => {
            this.handleNotificationDispatcher(n);
        });

        this.streamEventSource.connect();

        this.nowRefreshInterval = setInterval(() => (this.now = new Date(this.now.getTime() + 1000)), 1000);
    },

    beforeDestroy() {
        if (this.nowRefreshInterval) {
            clearInterval(this.nowRefreshInterval);
        }

        if (this.streamEventSource) {
            if (this.notificationHandler) {
                this.streamEventSource.removeDataHandler(this.notificationHandler);
            }

            if (this.connectionHandler) {
                this.streamEventSource.removeConnectionHandler(this.connectionHandler);
            }

            this.streamEventSource.disconnect();
        }
    },

    components: {
        StatusDashboardTable,
        StatusDashboardTile,
    },
});
