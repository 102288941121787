import { $t } from "./i18n";
import { Absence } from "@/api/absences";
import { userSession } from "@/store/userSession";
import moment from "moment-timezone";

function getHalfDay(timeZone: string, absence: Absence): "AM" | "PM" | null {
    if (!absence.to) {
        return null;
    }

    const from = moment(absence.from.getTime())
        .tz(timeZone)
        .locale(userSession.locale);

    const to = moment(absence.to)
        .tz(timeZone)
        .locale(userSession.locale);

    if (
        from.hours() === 0 &&
        from.minutes() === 0 &&
        from.seconds() === 0 &&
        from.milliseconds() === 0 &&
        to.hours() === 12 &&
        to.minutes() === 0 &&
        to.seconds() === 0 &&
        to.milliseconds() === 0 &&
        from.format("L") === to.format("L")
    ) {
        return "AM";
    }

    if (
        from.hours() === 12 &&
        from.minutes() === 0 &&
        from.seconds() === 0 &&
        from.milliseconds() === 0 &&
        to.hours() === 0 &&
        to.minutes() === 0 &&
        to.seconds() === 0 &&
        to.milliseconds() === 0 &&
        from.format("L") ===
            to
                .clone()
                .subtract(1, "d")
                .format("L")
    ) {
        return "PM";
    }

    return null;
}

export function isFullDays(timeZone: string, absence: Absence) {
    const from = moment(absence.from.getTime())
        .tz(timeZone)
        .locale(userSession.locale);

    const to = !absence.to
        ? null
        : moment(absence.to)
              .tz(timeZone)
              .locale(userSession.locale);

    let fullDays = from.hours() === 0 && from.minutes() === 0 && from.seconds() === 0 && from.milliseconds() === 0;

    if (to) {
        fullDays = fullDays && to.hours() === 0 && to.minutes() === 0 && to.seconds() === 0 && to.milliseconds() === 0;
    }

    return fullDays;
}

export function renderAbsenceTime(timeZone: string, absence: Absence) {
    const from = moment(absence.from.getTime())
        .tz(timeZone)
        .locale(userSession.locale);

    let to = !absence.to
        ? null
        : moment(absence.to)
              .tz(timeZone)
              .locale(userSession.locale);

    const fullDays = isFullDays(timeZone, absence);

    if (!to) {
        if (fullDays) {
            return from.format("L") + " - ?";
        } else {
            return from.format("L") + " " + from.format("LT") + " - ?";
        }
    }

    const halfDay = getHalfDay(timeZone, absence);
    if (halfDay) {
        return `${from.format("L")} (${halfDay === "AM" ? $t("erste Tageshälfte") : $t("zweite Tageshälfte")})`;
    }

    if (!fullDays) {
        return from.format("L") + " " + from.format("LT") + " - " + to.format("L") + " " + to.format("LT");
    }

    to = to.subtract(1, "d");

    if (from.format("L") === to.format("L")) {
        return from.format("L");
    }
    return from.format("L") + " - " + to.format("L");
}
