
import { withinOfficeHours } from "@/app/officeHoursUtils";
import { now } from "@/store/now";
import { opportunitySettingsStore } from "@/store/opportunitySettings";
import { formatDifference } from "@/util/dateTimeUtils";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
    props: {
        targetClass: String,
        disabled: Boolean,
        ignoreRestrictions: Boolean,
    },

    computed: {
        caption(): TranslateResult {
            if (!this.ignoreRestrictions && this.withinOfficeHours) {
                const ts = now();
                const lockedUntil = opportunitySettingsStore.lockedUntil;
                if (lockedUntil && lockedUntil.getTime() >= ts.getTime()) {
                    return this.$t("Übernehmen ({0})", [formatDifference(ts, lockedUntil, true)]);
                }
            }
            return this.$t("Übernehmen");
        },

        tooltip(): TranslateResult | null {
            if (!this.ignoreRestrictions) {
                if (!this.withinOfficeHours) {
                    return this.$t("Verkaufschancen können außerhalb der Annahmezeiten nicht übernommen werden.");
                }
                const ts = now();
                const lockedUntil = opportunitySettingsStore.lockedUntil;
                if (lockedUntil && lockedUntil.getTime() >= ts.getTime()) {
                    return this.$t(
                        "Verkaufschancen können während einer aktiven Annahmesperre nicht übernommen werden."
                    );
                }
            }
            return null;
        },

        withinOfficeHours(): boolean {
            const officeHours = opportunitySettingsStore.assignmentHours;
            if (!officeHours) {
                return true;
            }
            return withinOfficeHours(officeHours, now());
        },
    },
});
