import { OutgoingSmsForm } from "@/api/outgoingSms";
import { reactive } from "@/util/reactive";

@reactive
export class OutgoingSmsFormCardState {
    private initialized_ = false;
    public from: string | null = null;
    public toNumber = "";
    public messageBody = "";

    get initialized() {
        return this.initialized_;
    }

    get outgoingSmsForm(): OutgoingSmsForm {
        return {
            fromNumber: (this.from || "").startsWith("+") ? this.from : null,
            fromAddress: (this.from || "").startsWith("+") ? null : this.from,
            toNumber: this.toNumber,
            messageBody: this.messageBody,
        };
    }

    initialize(smsSignature: string | null, defaultSender: string | null, receiver: string | null) {
        if (smsSignature) {
            this.messageBody += "\n\n" + smsSignature;
        }

        this.from = defaultSender;
        this.toNumber = receiver || "";

        this.initialized_ = true;
    }

    reset() {
        this.from = null;
        this.toNumber = "";
        this.messageBody = "";
        this.initialized_ = false;
    }
}
