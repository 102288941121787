
import { DealerSettingsParameters, DealerSystemEmailAliasParameters, initializationApi } from "@/api/initialization";
import { EMAIL_LOCAL_PART_REGEX } from "@/app/validation";
import { dealersStore } from "@/store/dealers";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerIds: {
            type: Array as () => string[],
            required: true,
        },
    },

    data() {
        return {
            dealerSettingsFile: null as File | null,
            dealerSettingsParameters: {
                dealerIds: this.dealerIds,
                systemEmailAliasParameters: this.dealerIds.map((dealerId) => ({
                    dealerId,
                    importSystemEmailAliases: true,
                    systemEmailAliasesSuffix: null,
                })),
            } as DealerSettingsParameters,
            initializingDealerSettings: false,
        };
    },

    computed: {
        dealerSettingsFileRules(): Function[] {
            return [
                (v: File | null | undefined) => !!v || this.$t("Dieses Feld ist erforderlich."),
                (v: File | null | undefined) =>
                    !!(v?.name ?? "").match(/\.xml$/) || this.$t("Die Datei muss vom Typ {0} sein.", ["XML"]),
            ];
        },

        isWorking(): boolean {
            return this.initializingDealerSettings;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        getDealerNameById(dealerId: string) {
            return dealersStore.dealerById(dealerId)?.name || this.$t("Unbekannter Standort");
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.initializingDealerSettings = true;

            try {
                await initializationApi.initializeDealers(
                    this.dealerSettingsParameters,
                    this.dealerSettingsFile!,
                    () => {
                        // empty
                    }
                );
            } finally {
                this.initializingDealerSettings = false;
            }

            this.$emit("success");
        },

        systemEmailAliasesRules(systemEmailAliasParameters: DealerSystemEmailAliasParameters): Function[] {
            return systemEmailAliasParameters.importSystemEmailAliases
                ? [
                      (v: string | null | undefined) => !!v || this.$t("Dieses Feld ist erforderlich."),
                      (v: string | null | undefined) =>
                          !v || !!v.match(EMAIL_LOCAL_PART_REGEX) || this.$t("Gültiger Suffix ist erforderlich"),
                  ]
                : [];
        },
    },

    mounted() {
        if (!this.dealerIds.length) {
            this.$emit("close");
        }
    },
});
