
import { configStore } from "@/store/config";
import Vue from "vue";
import VueRecaptcha from "vue-recaptcha";

export default Vue.extend({
    data() {
        return {
            initialized: false,
            resolve: null as ((response: string) => void) | null,
        };
    },

    computed: {
        sitekey(): string {
            return configStore.configuration.recaptchaWebsiteKey;
        },

        locale(): string {
            return this.$i18n.locale;
        },
    },

    methods: {
        getChallengeResponse(): Promise<string> {
            this.initialized = true;
            const captcha = this.$refs.captcha as VueRecaptcha | undefined;
            if (captcha) {
                captcha.reset();
            }
            return new Promise((resolve) => {
                this.resolve = resolve;
            });
        },

        verify(response: string) {
            this.resolve!(response);
            this.resolve = null;
        },
    },

    components: {
        VueRecaptcha,
    },
});
