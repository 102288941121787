
import { IncomingSms } from "@/api/incomingSms";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        canViewContent: {
            type: Boolean,
            required: true,
        },
        incomingSms: {
            type: Object as () => IncomingSms,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        creationTimestamp(): string {
            return formatInstant(this.incomingSms.created, userSession.timeZone, userSession.locale, "S");
        },
    },

    components: {
        TimeSpan,
    },
});
