import {
    Climatisation,
    Drive,
    ExteriorColor,
    Feature,
    Fuel,
    Gearbox,
    HeadlightType,
    InteriorType,
    InventoryVehicle,
    ParkingAssistant,
    SpeedControl,
    TrailerCouplingType,
    UsageType,
} from "./inventory";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum InventoryCountType {
    DEALER = "DEALER",
    VEHICLE_CLASS = "VEHICLE_CLASS",
    CATEGORY = "CATEGORY",
    MAKE = "MAKE",
    MODEL = "MODEL",
    USAGE_TYPE = "USAGE_TYPE",
    GEARBOX = "GEARBOX",
    DRIVE = "DRIVE",
    FUEL = "FUEL",
    EXTERIOR_COLOR = "EXTERIOR_COLOR",
    INTERIOR_TYPE = "INTERIOR_TYPE",
    CLIMATISATION = "CLIMATISATION",
    SPEED_CONTROL = "SPEED_CONTROL",
    HEADLIGHT_TYPE = "HEADLIGHT_TYPE",
    PARKING_ASSISTANTS = "PARKING_ASSISTANTS",
    TRAILER_COUPLING_TYPE = "TRAILER_COUPLING_TYPE",
    FEATURE = "FEATURE",
    DELETION_STATUS = "DELETION_STATUS",
}

export enum InventorySearchOrder {
    INTERNAL_ID_DESC = "INTERNAL_ID_DESC",
    INTERNAL_ID_ASC = "INTERNAL_ID_ASC",
    FIRST_REGISTRATION_DESC = "FIRST_REGISTRATION_DESC",
    FIRST_REGISTRATION_ASC = "FIRST_REGISTRATION_ASC",
    MILEAGE_DESC = "MILEAGE_DESC",
    MILEAGE_ASC = "MILEAGE_ASC",
    PRICE_DESC = "PRICE_DESC",
    PRICE_ASC = "PRICE_ASC",
    TOTAL_OPPORTUNITIES_DESC = "TOTAL_OPPORTUNITIES_DESC",
    TOTAL_OPPORTUNITIES_ASC = "TOTAL_OPPORTUNITIES_ASC",
}

export enum InventoryVehicleDeletionStatus {
    DELETED = "DELETED",
    ACTIVE = "ACTIVE",
}

export interface InventoryCount {
    readonly type: InventoryCountType;
    readonly value: string | null;
    readonly count: number;
}

export interface InventorySearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

export interface InventorySearchResult {
    readonly totalSize: number;
    readonly inventoryVehicleResults: InventoryVehicleResult[];
    readonly inventoryCounts: InventoryCount[];
    readonly searchId: number;
}

export interface InventorySearchRequest {
    readonly dealerIds: string[];
    readonly vehicleClasses: (string | null)[];
    readonly categories: (string | null)[];
    readonly makes: (string | null)[];
    readonly models: (string | null)[];
    readonly usageTypes: (UsageType | null)[];
    readonly firstRegistrationFrom: string | null;
    readonly firstRegistrationTo: string | null;
    readonly mileageFrom: number | null;
    readonly mileageTo: number | null;
    readonly cubicCapacityFrom: number | null;
    readonly cubicCapacityTo: number | null;
    readonly powerFrom: number | null;
    readonly powerTo: number | null;
    readonly gearboxes: (Gearbox | null)[];
    readonly drives: (Drive | null)[];
    readonly fuels: (Fuel | null)[];
    readonly exteriorColors: (ExteriorColor | null)[];
    readonly interiorTypes: (InteriorType | null)[];
    readonly climatisations: (Climatisation | null)[];
    readonly speedControls: (SpeedControl | null)[];
    readonly headlightTypes: (HeadlightType | null)[];
    readonly parkingAssistants: (ParkingAssistant | null)[];
    readonly trailerCouplingTypes: (TrailerCouplingType | null)[];
    readonly priceFrom: number | null;
    readonly priceTo: number | null;
    readonly features: Feature[];
    readonly deletionStatuses: (InventoryVehicleDeletionStatus | null)[];
    readonly search: string | null;
    readonly sortBy: InventorySearchOrder;
}

export interface InventoryVehicleOpportunityStatus {
    readonly openOpportunityIds: string[];
    readonly assignedOpportunityIds: string[];
    readonly postponedOpportunityIds: string[];
    readonly lostOpportunityIds: string[];
    readonly wonOpportunityIds: string[];
}

export interface InventoryVehicleResult {
    readonly inventoryVehicle: InventoryVehicle;
    readonly inventoryVehicleOpportunityStatus: InventoryVehicleOpportunityStatus;
}

interface InventorySearchApi {
    search(
        start: number,
        size: number,
        searchRequest: InventorySearchRequest,
        searchId: number
    ): Promise<InventorySearchResult>;
    rebuild(): Promise<void>;
    size(): Promise<InventorySearchIndexSize>;
}

export const inventorySearchApi: InventorySearchApi = {
    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (await axios.post(`/api/inventory-search`, searchRequest, { params: { start, size, searchId } })).data
        );
    },

    async rebuild() {
        await axios.post(`/api/inventory-search/_rebuild`);
    },

    async size() {
        return (await axios.get(`/api/inventory-search/_size`)).data;
    },
};
