
import EscalationGroupPickerCard from "./EscalationGroupPickerCard.vue";
import { CaseReceiversForm, casesApi, CaseStatus } from "@/api/cases";
import { showConfirm, showError } from "@/app/messageUtil";
import ActionCard from "@/app/pages/ActionCard.vue";
import UserPickerCard from "@/app/pages/users/UserPickerCard.vue";
import { UserPickerData } from "@/app/pages/users/userPickerCardTypes";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";

function containsOtherElements<T>(a: T[], b: T[]) {
    const s = new Set(a);
    for (const e of b) {
        s.delete(e);
    }
    return !!s.size;
}

export default Vue.extend({
    props: {
        caseId: {
            type: String,
            required: true,
        },
        individualReceivers: {
            type: Array as () => string[],
            required: true,
        },
        escalationGroupReceivers: {
            type: Array as () => string[],
            required: true,
        },
        dealerId: {
            type: String,
            required: true,
        },
        caseType: {
            type: String,
            required: true,
        },
        caseStatus: {
            type: String as () => CaseStatus,
            required: true,
        },
    },

    data() {
        return {
            working: false,
            caseReceiversForm: {
                individualReceivers: cloneObject(this.individualReceivers),
                escalationGroupReceivers: cloneObject(this.escalationGroupReceivers),
            } as CaseReceiversForm,
            userPickerData: new UserPickerData(cloneObject(this.individualReceivers)),
        };
    },

    methods: {
        setEscalationGroupReceivers(newValue: string[]) {
            this.caseReceiversForm.escalationGroupReceivers = newValue;
        },

        setIndividualReceivers(newValue: UserPickerData) {
            this.caseReceiversForm.individualReceivers = newValue.userIds;
            this.userPickerData = newValue;
        },

        close() {
            this.$emit("close");
        },

        async save() {
            if (
                !this.caseReceiversForm.escalationGroupReceivers.length &&
                !this.caseReceiversForm.individualReceivers.length
            ) {
                showError(this.$t("Es ist kein Empfänger ausgewählt!") as string);
                return;
            }

            const removeAssignees =
                this.caseStatus === CaseStatus.ASSIGNED &&
                (containsOtherElements(
                    this.caseReceiversForm.escalationGroupReceivers,
                    this.escalationGroupReceivers
                ) ||
                    containsOtherElements(this.caseReceiversForm.individualReceivers, this.individualReceivers)) &&
                (await showConfirm(
                    this.$t("Zuteilungen entfernen ?") as string,
                    this.$t(
                        "Für die neu hinzugefügten Empfänger wird der Vorgang erst dann sichtbar, wenn die bestehenden Zuteilungen entfernt werden. Möchten Sie die Zuteilungen entfernen?"
                    ) as string,
                    this.$t("Ja") as string,
                    this.$t("Nein") as string
                ));

            this.working = true;
            try {
                await casesApi.editReceivers(this.caseId, this.caseReceiversForm);
                this.$emit("close");
                if (removeAssignees) {
                    await casesApi.updateAssignees(this.caseId, []);
                }
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        ActionCard,
        EscalationGroupPickerCard,
        UserPickerCard,
    },
});
