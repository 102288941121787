import axios from "axios";

export interface ExternalInventoryManagementSettings {
    readonly userLinkPattern: string | null;
    readonly userLinkLabel: string | null;
    readonly vehicleLinkPattern: string | null;
    readonly vehicleLinkLabel: string | null;
}

interface ExternalInventoryManagementSettingsApi {
    getSettings(): Promise<ExternalInventoryManagementSettings>;
    updateSettings(settings: ExternalInventoryManagementSettings): Promise<void>;
}

export const externalInventoryManagementSettingsApi: ExternalInventoryManagementSettingsApi = {
    async getSettings() {
        return (await axios.get("/api/external-inventory-management-settings")).data;
    },

    async updateSettings(settings) {
        await axios.put("/api/external-inventory-management-settings", settings);
    },
};
