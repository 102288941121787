import axios from "axios";

export interface Country {
    readonly code: string;
    readonly states: string[];
}

export interface Language {
    readonly code: string;
    readonly nativeName: string;
}

export interface Configuration {
    readonly shortCommitId: string;
    readonly buildTime: Date;
    readonly ctUrl: string;
    readonly appName: string;
    readonly appHost: string;
    readonly defaultLocale: string;
    readonly defaultMileageUnit: string;
    readonly defaultCountry: string;
    readonly defaultTimeZone: string;
    readonly defaultCurrency: string;
    readonly orgCurrency: string;
    readonly orgQualityManagementPhone: string;
    readonly orgQualityManagementAvailabilityFirstDay: string | null;
    readonly orgQualityManagementAvailabilityLastDay: string | null;
    readonly orgQualityManagementAvailabilityStartTime: string | null;
    readonly orgQualityManagementAvailabilityEndTime: string | null;
    readonly orgSupportPhone: string;
    readonly orgSupportAvailabilityFirstDay: string | null;
    readonly orgSupportAvailabilityLastDay: string | null;
    readonly orgSupportAvailabilityStartTime: string | null;
    readonly orgSupportAvailabilityEndTime: string | null;
    readonly dealergroupName: string;
    readonly latestVersion: string;
    readonly transactionEmailHost: string;
    readonly emailDomain: string;
    readonly countries: Country[];
    readonly languages: Language[];
    readonly incomingEmailPseudonymizedContactEmailAddressPatterns: string[];
    readonly incomingEmailExternalSystemEmailAddresses: string[];
    readonly recaptchaWebsiteKey: string;
    readonly notificationSubscriptionKey: string;
    readonly carryDispoEndpointConfigured: boolean;
    readonly autoCrmLeadMailerConfigured: boolean;
    readonly extendedExternalBdcReporting: boolean;
    readonly canUseTelLink: boolean;
    readonly whitelistedOutgoingFileExtensions: string[];
    readonly videochatConfigured: boolean;
    readonly messages: { [locale: string]: { [key: string]: string } };
}

interface ConfigurationApi {
    config(): Promise<Configuration>;
}

export const configurationApi: ConfigurationApi = {
    async config() {
        const result: Configuration = (await axios.get("/api/frontend-config")).data;
        return {
            ...result,
            buildTime: new Date(result.buildTime),
        };
    },
};
