import { render, staticRenderFns } from "./ContactSearchResultPreviewCard.vue?vue&type=template&id=76f44da4&scoped=true&"
import script from "./ContactSearchResultPreviewCard.vue?vue&type=script&lang=ts&"
export * from "./ContactSearchResultPreviewCard.vue?vue&type=script&lang=ts&"
import style0 from "./ContactSearchResultPreviewCard.vue?vue&type=style&index=0&id=76f44da4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76f44da4",
  null
  
)

export default component.exports