import { renderDefaultCurrency, renderUnit } from "./filters";
import i18n from "./i18n";
import { Fuel, InventoryVehicle, InventoryVehicleStatusType, Price, UsageType } from "@/api/inventory";
import { configStore } from "@/store/config";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";

export interface VehicleSummary {
    readonly internalId: string | null;
    readonly vehicleClass: string | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly modelDescription: string | null;
    readonly usageType: UsageType | null;
    readonly mileage: number | null;
    readonly fuel: Fuel | null;
    readonly preOffer: boolean | null;
}

export function isEmptyVehicleSummary(vehicleSummary: VehicleSummary): boolean {
    return (
        vehicleSummary.internalId === null &&
        vehicleSummary.vehicleClass === null &&
        vehicleSummary.make === null &&
        vehicleSummary.model === null &&
        vehicleSummary.modelDescription === null &&
        vehicleSummary.usageType === null &&
        vehicleSummary.mileage === null &&
        vehicleSummary.fuel === null &&
        vehicleSummary.preOffer === null
    );
}

export function getStatusColor(status: InventoryVehicleStatusType): string | null {
    if (status === InventoryVehicleStatusType.SOLD) {
        return "error";
    } else if (status === InventoryVehicleStatusType.RESERVED) {
        return "warning";
    } else if (status === InventoryVehicleStatusType.AVAILABLE) {
        return "success";
    } else {
        return null;
    }
}

export function getVehicleClassIcon(vehicleClass: string | null): string {
    if (vehicleClass === "Car") {
        return "mdi-car";
    } else if (vehicleClass === "Motorbike") {
        return "mdi-motorbike";
    } else if (vehicleClass === "Motorhome") {
        return "mdi-rv-truck";
    } else if (vehicleClass === "Truck") {
        return "mdi-truck";
    } else {
        return "mdi-car";
    }
}

function escapeRegExp(s: string) {
    // https://stackoverflow.com/a/6969486
    return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function renderVehicleTitle(vehicle: Pick<VehicleSummary, "make" | "model" | "modelDescription">): string | null {
    const make = vehicle.make;
    const model = make ? vehicle.model : null;
    const modelDescription =
        model && vehicle.modelDescription
            ? vehicle.modelDescription.replace(new RegExp(`^${escapeRegExp(model)}\\b`, "i"), "").trim()
            : vehicle.modelDescription;

    const title = [make, model, modelDescription].filter((i) => !!i).join(" ");
    return trimAndReturnNullIfEmpty(title);
}

export function renderInventoryVehicleTitle(inventoryVehicle: InventoryVehicle): string | null {
    return renderVehicleTitle(inventoryVehicle.data);
}

export function renderVehicleSummaryTitle(vehicleSummary: VehicleSummary): string | null {
    const title = renderVehicleTitle(vehicleSummary);

    if (title && vehicleSummary.internalId) {
        return `[${vehicleSummary.internalId}] ${title}`;
    }
    if (title) {
        return title;
    }
    if (vehicleSummary.internalId) {
        return `[${vehicleSummary.internalId}]`;
    }

    return null;
}

export function getLastInventoryVehicleStatus(inventoryVehicle: InventoryVehicle): InventoryVehicleStatusType {
    if (inventoryVehicle.statuses.length === 0) {
        return InventoryVehicleStatusType.AVAILABLE;
    }

    return inventoryVehicle.statuses[inventoryVehicle.statuses.length - 1].type;
}

export function getInventoryVehicleLatestPrice(inventoryVehicle: InventoryVehicle): Price | null {
    return inventoryVehicle.data.prices.length
        ? inventoryVehicle.data.prices[inventoryVehicle.data.prices.length - 1]
        : null;
}

export function renderInventoryVehicleLatestConsumerPrice(
    inventoryVehicle: InventoryVehicle,
    defaultPriceString?: string
): string | null {
    const latestConsumerPrice = getInventoryVehicleLatestPrice(inventoryVehicle)?.consumerPriceGross;

    if (latestConsumerPrice !== 0 && !latestConsumerPrice) {
        return defaultPriceString
            ? (0)
                  .toLocaleString(i18n.locale, {
                      style: "currency",
                      currency: configStore.configuration.defaultCurrency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                  })
                  .replace(/\d+/g, defaultPriceString)
            : null;
    }

    return renderDefaultCurrency(latestConsumerPrice);
}

export function renderMileage(inventoryVehicle: InventoryVehicle): string | null {
    if (inventoryVehicle.data.mileage === null) {
        return null;
    }

    // TODO localize unit format
    return renderUnit(inventoryVehicle.data.mileage, "km");
}

export function renderFirstRegistration(inventoryVehicle: InventoryVehicle): string | null {
    // TODO

    if (inventoryVehicle.data.firstRegistrationYear) {
        let result = inventoryVehicle.data.firstRegistrationYear.toString();

        if (inventoryVehicle.data.firstRegistrationMonth) {
            result += "-" + inventoryVehicle.data.firstRegistrationMonth.toString().padStart(2, "0");

            if (inventoryVehicle.data.firstRegistrationDay) {
                result += "-" + inventoryVehicle.data.firstRegistrationDay.toString().padStart(2, "0");
            }
        }

        return result;
    }

    return null;
}

export function renderPower(power: number): string {
    // TODO localize unit format
    const kw = renderUnit(power, "kW");
    const ps = renderUnit(Math.round(power * 1.359621617), "PS");

    return `${kw} (${ps})`;
}
