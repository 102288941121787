
import { dealerOptions } from "@/app/dealerUtils";
import { EmergencyIncidentAssistantState } from "@/app/pages/emergencyincidents/assistant/emergencyIncidentAssistant";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        emergencyIncidentAssistantState: {
            type: Object as () => EmergencyIncidentAssistantState,
            required: true,
        },
    },

    computed: {
        dealerOptions(): SelectOptions {
            return dealerOptions();
        },
    },
});
