var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"value":true,"max-width":"800","persistent":_vm.saving,"scrollable":""},on:{"input":function($event){return _vm.$emit('close')}}},[_c('v-form',{ref:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',{attrs:{"disabled":_vm.saving}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.numberBlacklistEntry ? _vm.$t("Rufnummernsperre bearbeiten") : _vm.$t("Rufnummernsperre hinzufügen"))+" ")])],1),_c('v-card-text',[_c('phone-number-field',{attrs:{"autofocus":!_vm.numberBlacklistEntry,"default-country-code":_vm.defaultCountry,"disabled":!!_vm.numberBlacklistEntry,"label":_vm.$t('Rufnummer'),"rules":_vm.numberRules},model:{value:(_vm.form.number),callback:function ($$v) {_vm.$set(_vm.form, "number", $$v)},expression:"form.number"}}),_c('v-autocomplete',{attrs:{"autocomplete":"no","hint":_vm.$t('Die Einstellung betrifft sowohl eingehende als auch ausgehende Anrufe.'),"items":[
                        { value: true, text: _vm.$t('Blockieren') },
                        { value: false, text: _vm.$t('Nicht blockieren') },
                    ],"label":_vm.$t('Anrufe'),"persistent-hint":""},model:{value:(_vm.form.blockCalls),callback:function ($$v) {_vm.$set(_vm.form, "blockCalls", $$v)},expression:"form.blockCalls"}}),_c('v-autocomplete',{attrs:{"autocomplete":"no","hint":_vm.$t(
                            'Die Einstellung betrifft sowohl eingehende als auch ausgehende SMS und WhatsApp-Nachrichten.'
                        ),"items":[
                        { value: true, text: _vm.$t('Blockieren') },
                        { value: false, text: _vm.$t('Nicht blockieren') },
                    ],"label":_vm.$t('Textnachrichten'),"persistent-hint":""},model:{value:(_vm.form.blockTextMessages),callback:function ($$v) {_vm.$set(_vm.form, "blockTextMessages", $$v)},expression:"form.blockTextMessages"}}),_c('v-textarea',{attrs:{"auto-grow":"","label":_vm.$t('Anmerkungen'),"rows":"5","rules":_vm.maxLength(8000)},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("Abbrechen")))]),_c('v-btn',{attrs:{"type":"submit","loading":_vm.saving,"text":""}},[_vm._v(_vm._s(_vm.$t("Speichern")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }