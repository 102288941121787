
import OutgoingEmailFormCard from "./OutgoingEmailFormCard.vue";
import { OutgoingEmailFormCardState } from "./outgoingEmailFormCard";
import { Contact, ContactData } from "@/api/contacts";
import { InventoryVehicle } from "@/api/inventory";
import { TransactionEmailHeader, TransactionEmailReceiver } from "@/api/outgoingEmails";
import { EditModeMixin } from "@/app/editModeUtils";
import { VehicleSummary } from "@/app/inventoryUtils";
import { UrlWithKey } from "@/app/placeholderUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        automaticQuote: {
            type: Boolean,
            default: false,
        },
        contact: {
            type: Object as () => Contact | null,
            required: false,
        },
        dealerId: {
            type: String as () => string | null,
            default: null,
        },
        issueId: {
            type: String as () => string | null,
            default: null,
        },
        onSubmit: {
            type: Function,
            required: true,
        },
        working: {
            type: Boolean,
            required: true,
        },
        emailAttachmentsUploadProgress: {
            type: Number,
            required: true,
        },
        contentForQuote: {
            type: String,
            default: "",
        },
        defaultSubject: {
            type: String,
            required: false,
        },
        receivers: {
            type: Array as () => TransactionEmailReceiver[],
            required: false,
            default: () => [],
        },
        headers: {
            type: Array as () => TransactionEmailHeader[],
            required: false,
            default: () => [],
        },
        urls: {
            type: Array as () => UrlWithKey[],
            default: () => [],
        },
        vehicleSummaries: {
            type: Array as () => VehicleSummary[],
            default: () => [],
        },
        inventoryVehicles: {
            type: Array as () => InventoryVehicle[],
            required: false,
        },
    },

    data() {
        const outgoingEmailFormCardState = new OutgoingEmailFormCardState();
        outgoingEmailFormCardState.headers = this.headers;

        return {
            outgoingEmailFormCardState,
        };
    },

    computed: {
        contactData(): ContactData | null {
            if (this.contact?.deleted) {
                return null;
            }

            return this.contact?.contactData || null;
        },
    },

    methods: {
        async submit() {
            await (this.$refs.outgoingEmailFormCard as any).submit();
        },
    },

    mixins: [EditModeMixin],

    components: {
        OutgoingEmailFormCard,
    },
});
